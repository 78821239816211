import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PropTypes from 'prop-types';
import {AZURE_SERVICE_ACCOUNT_PERMISSION_LINK} from 'utils/constants';
import {formatDateTime} from 'utils/utils';
import GenericLink from './GenericLink';

const PREFIX = 'AzureExpiryClientSecretDialog';

const classes = {
  disabled: `${PREFIX}-disabled`,
  keySubTitle: `${PREFIX}-keySubTitle`,
  keyEntry: `${PREFIX}-keyEntry`,
  stepEntry: `${PREFIX}-stepEntry`,
  greyText: `${PREFIX}-greyText`,
  warningText: `${PREFIX}-warningText`,
  expiredText: `${PREFIX}-expiredText`,
  whyUpdateSecret: `${PREFIX}-whyUpdateSecret`,
  errorIcon: `${PREFIX}-errorIcon`,
  description: `${PREFIX}-description`,
  flexAlignCenter: `${PREFIX}-flexAlignCenter`,
};

const StyledDialog = styled(Dialog)(({theme}) => ({
  [`& .${classes.disabled}`]: {color: theme.palette.error.main},

  [`& .${classes.keySubTitle}`]: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '500',
    textAlign: 'left',
    color: theme.palette.surface.grey,
    marginBottom: 8,
  },

  [`& .${classes.keyEntry}`]: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '0.875rem',
    textAlign: 'left',
  },

  [`& .${classes.stepEntry}`]: {
    marginBottom: 12,
  },

  [`& .${classes.greyText}`]: {
    color: '#626262',
  },

  [`& .${classes.warningText}`]: {
    color: '#FF9600',
  },

  [`& .${classes.expiredText}`]: {
    color: 'red',
  },

  [`& .${classes.whyUpdateSecret}`]: {
    marginTop: 33,
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.errorIcon}`]: {fontSize: 'small', marginRight: 5},
  [`& .${classes.description}`]: {paddingLeft: 20, color: '#626262'},
  [`& .${classes.flexAlignCenter}`]: {display: 'flex', alignItems: 'center'},
}));

function AzureExpiryClientSecretDialog({open, onClose, expiryInfo}) {
  const keysExpiryReport = Object.entries(expiryInfo).map((entry) => {
    const [keyId, keyExpireInfo] = entry;
    if (keyExpireInfo.expiresSoon || keyExpireInfo.expired) {
      return (
        <Grid
          container
          alignItems="center"
          key={keyId}
          className={classes.keyEntry}
        >
          <Grid item xs={6} className={classes.greyText}>
            {keyId}
          </Grid>
          <Grid
            item
            xs={4}
            className={
              keyExpireInfo.expiresSoon
                ? classes.warningText
                : classes.expiredText
            }
          >
            {formatDateTime(keyExpireInfo.endDate)}
          </Grid>
          <Grid
            item
            xs={2}
            className={
              keyExpireInfo.expiresSoon
                ? classes.warningText
                : classes.expiredText
            }
          >
            {keyExpireInfo.expiresSoon && 'Expires soon'}
            {keyExpireInfo.expired && 'Expired'}
          </Grid>
        </Grid>
      );
    }
    return <></>;
  });

  if (!open) return null;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      data-testid="azure-client-secret-dialog"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Azure client secret information</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.keySubTitle}>Key ID</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" className={classes.keySubTitle}>
              Expire date
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography align="center" className={classes.keySubTitle}>
              Status
            </Typography>
          </Grid>
        </Grid>
        {keysExpiryReport}

        <p className={classes.whyUpdateSecret}>
          <ErrorOutlineIcon className={classes.errorIcon} />
          Why does Azure client secret need to be updated?
        </p>

        <p className={classes.description}>
          A valid client secret is necessary to add existing Azure remote
          workstations to Anyware Manager and power manage those remote
          workstations.
        </p>
        <hr />

        <p className={classes.flexAlignCenter}>
          <ErrorOutlineIcon className={classes.errorIcon} />
          What you have to do?
        </p>

        <ul className={classes.greyText}>
          <li className={classes.stepEntry}>
            <GenericLink url={AZURE_SERVICE_ACCOUNT_PERMISSION_LINK}>
              Create a new client secret
            </GenericLink>
            {' for the existing Azure service account. '}
          </li>

          <li className={classes.stepEntry}>
            Delete the Azure provider service account from this deployment and
            add it again with the new client secret.
          </li>

          <li className={classes.stepEntry}>
            In order to remove this warning, you also need to delete the expired
            client secrets from your Azure service account.
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="azure-dialog-button-ok"
          onClick={onClose}
          color="primary"
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

AzureExpiryClientSecretDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  expiryInfo: PropTypes.object.isRequired,
};

export default AzureExpiryClientSecretDialog;
