import PropTypes from 'prop-types';
import CopyToClipboard from 'components/CAM/buttons/CopyToClipboard/CopyToClipboard';

function CopyButton({textToCopy, title, onCopy}) {
  return (
    <CopyToClipboard
      text={textToCopy}
      onCopy={onCopy}
      id="copy-to-clipboard"
      tooltipProps={{
        text: title,
        placement: 'right',
      }}
      buttonProps={{size: 'small'}}
      iconVariant="CopyIcon"
    />
  );
}

CopyButton.propTypes = {
  textToCopy: PropTypes.string.isRequired,
  title: PropTypes.string,
  onCopy: PropTypes.func,
};

CopyButton.defaultProps = {
  title: 'Copy to clipboard',
  onCopy: () => {},
};

export default CopyButton;
