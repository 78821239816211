import {CircularProgress} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {useQueryClient} from 'react-query';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import {
  linkServiceQueryKey,
  useCreateLinkService,
  useQueryLinkService,
} from 'hooks/linkServiceHooks';
import {handleApiError} from 'redux/actions/dataActions';
import useSnackbar from 'hooks/useSnackbar';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import LinkServiceInfoTab from './LinkServiceInfoTab';

const PREFIX = 'LinkServiceTab';
const classes = {
  inputTextFieldLabel: `${PREFIX}-inputTextFieldLabel`,
  formControl: `${PREFIX}-formControl`,
  itemButton: `${PREFIX}-itemButton`,
  sectionHeader: `${PREFIX}-sectionHeader`,
  actionButtonDiv: `${PREFIX}-actionButtonDiv`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.inputTextFieldLabel}`]: theme.createPage.inputTextFieldLabel,
  [`& .${classes.sectionHeader}`]: {
    ...stylesFromFigma.typographyH6,
    marginTop: '16px',
  },
  [`& .${classes.actionButtonDiv}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '24px',
  },
  [`& .${classes.formControl}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },

  [`& .${classes.itemButton}`]: {
    marginRight: '12px',
    height: '36px',
    width: '96px',
  },
}));

function LinkServiceTab({deployment}) {
  const queryClient = useQueryClient();
  const {successSnackbar} = useSnackbar();

  const {deploymentId} = deployment;
  const dispatch = useDispatch();
  // data is null if there is no deployment SA
  const {data, isFetched} = useQueryLinkService(deploymentId);
  const {mutate: createLinkService} = useCreateLinkService({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [linkServiceQueryKey],
      });
      successSnackbar('Link service is created');
    },
    onError: (err) => {
      dispatch(handleApiError(err));
    },
  });
  const loadingScreen = () => {
    return (
      <Box
        height={400}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <CircularProgress />
      </Box>
    );
  };

  const [createLinkSrvName, setCreateLinkSrvName] = useState(deploymentId);
  const [createLinkSrvNameError, setCreateLinkSrvNameError] = useState('');
  useEffect(() => {
    if (createLinkSrvName?.length < 3) {
      setCreateLinkSrvNameError(`Minimum 3 characters`);
      return;
    }
    const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(createLinkSrvName);
    if (!isAlphanumeric) {
      setCreateLinkSrvNameError('Alphanumeric characters only');
      return;
    }
    setCreateLinkSrvNameError('');
  }, [createLinkSrvName]);

  useEffect(() => {
    setCreateLinkSrvName(deploymentId);
  }, [deploymentId]);
  const notFoundLinkService = () => {
    return (
      <Box minHeight={400} maxWidth={1136}>
        <Typography className={classes.pageTitle} variant="h4" component="h1">
          Create Link Service
        </Typography>
        <Box sx={{p: '0px 40px 40px 40px'}}>
          <Typography
            className={classes.sectionHeader}
            variant="h6"
            component="h6"
          >
            Link Service Name
          </Typography>
          <Typography component="span" variant="body1" className={classes.body}>
            <ul className={classes.helperList}>
              <li>Alphanumeric characters only</li>
              <li>Minimum 3 characters</li>
            </ul>
          </Typography>
          <TextField
            value={createLinkSrvName}
            id="link-srv-name-input"
            label="Link Service Name"
            variant="outlined"
            className={classes.formControl}
            error={!!createLinkSrvNameError}
            helperText={createLinkSrvNameError}
            onChange={(event) => {
              setCreateLinkSrvName(event.target.value);
            }}
          />
          <Box className={classes.actionButtonDiv}>
            <SaveButton
              disabled={!!createLinkSrvNameError}
              buttonText="Create"
              onClick={() => {
                createLinkService({deploymentId, name: createLinkSrvName});
              }}
            >
              Create
            </SaveButton>
          </Box>
        </Box>
      </Box>
    );
  };

  const displayContent = () => {
    if (!deploymentId) {
      return loadingScreen();
    }
    if (data) {
      return <LinkServiceInfoTab deploymentId={deploymentId} />;
    }
    if (isFetched) {
      return notFoundLinkService();
    }
    return loadingScreen();
  };
  return (
    <Root>
      <CAMCard>{displayContent()}</CAMCard>
    </Root>
  );
}

LinkServiceTab.propTypes = {
  deployment: PropTypes.object,
};

LinkServiceTab.defaultProps = {
  deployment: {
    deploymentId: '',
    deploymentName: '',
  },
};

export default LinkServiceTab;
