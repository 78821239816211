import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import GenericLink from 'components/common/GenericLink';
import TextPage from 'components/common/TextPage';
import {LINK_TERADICI_LIFECYCLE} from 'utils/constants';

const PREFIX = 'ServiceLevelPage';

const classes = {
  mainText: `${PREFIX}-mainText`,
  subText: `${PREFIX}-subText`,
  doubleSubText: `${PREFIX}-doubleSubText`,
};

const Root = styled('div')(() => ({
  color: '#000000',
  [`& .${classes.mainText}`]: {
    padding: '8px 0',
    textAlign: 'justify',
  },

  [`& .${classes.subText}`]: {
    padding: '8px 0 8px 20px',
    textAlign: 'justify',
  },

  [`& .${classes.doubleSubText}`]: {
    padding: '8px 0 8px 40px',
    textAlign: 'justify',
  },
}));

function ServiceLevelPage() {
  return (
    <Root>
      <TextPage pageTitle="Service Level Objectives (SLO)">
        <p>Effective Date: November 9, 2022</p>

        <Typography variant="body2" className={classes.mainText}>
          1. Anyware Manager as a Service is designed for scalability,
          resiliency and high availability. This Service Level Objective (“SLO”)
          applies only to Anyware Manager as a Service operating on the Azure
          Public Cloud. The SLO is Teradici’s commitment of Anyware Manager as a
          Service availability to establish Remote Anyware Sessions. Existing
          Anyware sessions do not depend on Anyware Manager as a Service so they
          will continue to function if Anyware Manager as a Service experiences
          an outage.
        </Typography>

        <Typography variant="body2" className={classes.mainText}>
          2. Session Establishment
        </Typography>

        <Typography variant="body2" className={classes.subText}>
          a. Teradici’s SLO for Anyware Manager as a Service is to maintain at
          least a 99.5% availability for Session Establishment measured over a
          month long period. Monthly Availability is measured as the percentage
          of minutes of Unavailability per month subtracted from 100%.
          Unavailable minutes do not include Session Establishment Failures that
          are the resulting from issues outside the control of Anyware Manager
          as a Service, the exclusions include the following:
        </Typography>

        <Typography variant="body2" className={classes.doubleSubText}>
          i. Scheduled maintenance windows, defined as a maintenance window that
          was provided at least 7 days advance notice on the Anyware Manager
          Status Portal. Customers can subscribe to email notifications from the
          status portal. The maximum total maintenance window hours allowed in a
          single monthly period is 5 hours. Any maintenance beyond 5 hours per
          month will counted as Unavailable minutes.
        </Typography>
        <Typography variant="body2" className={classes.doubleSubText}>
          ii. Solution components not meeting the compatibility and support
          policy, found{' '}
          <GenericLink url={LINK_TERADICI_LIFECYCLE}>here</GenericLink>.
        </Typography>
        <Typography variant="body2" className={classes.doubleSubText}>
          iii. Session Establishment failures caused by components not managed
          by Teradici including, but not limited to, Customer controlled network
          and network equipment; Customer controlled on-premises physical and
          virtual machines; Customer Public Cloud virtual machines; Expiry or
          Modification of Customer service accounts preventing management of
          physical or virtual resources; Customer changes preventing
          Identification or Authentication of Users; Customer defined security
          settings; Customer defined group policies or other configuration
          policies; Internet Service Provider failures.
        </Typography>
        <Typography variant="body2" className={classes.doubleSubText}>
          iv. Some components may require licenses to become active in a
          session. Session Establishment failures due to missing or expired
          licenses do not contribute to Unavailable minutes.
        </Typography>
        <Typography variant="body2" className={classes.doubleSubText}>
          v. Customer’s failure to comply with appropriate security practices.
        </Typography>

        <Typography variant="body2" className={classes.subText}>
          b. Successful session establishment typically succeeds in less than 10
          seconds, however success can take up to 60 seconds in some cases.
        </Typography>
        <Typography variant="body2" className={classes.subText}>
          c. Session establishment includes situations where virtual workstation
          resources need to be restarted and may require the user to try again
          after a short period of time. This is also counted as successful.
        </Typography>

        <Typography variant="body2" className={classes.mainText}>
          3. Activity Log
        </Typography>

        <Typography variant="body2" className={classes.subText}>
          a. Teradici&apos;s SLO for the Activity Log on Anyware Manager as a
          Service is to maintain a 99.5% log write success measured over a month
          long period. Write success is measured as a percentage of successful
          and failed logging operations at the point where an operation to
          Anyware Manager as a Service has been successfully received.
        </Typography>
        <Typography variant="body2" className={classes.subText}>
          b. Activity log contents are maintained within Anyware Manager as a
          Service for up to 7 days after which they are deleted.
        </Typography>
        <Typography variant="body2" className={classes.subText}>
          c. Activity log contents are stored in long term storage for up to 2
          years after which they are deleted.
        </Typography>

        <Typography variant="body2" className={classes.mainText}>
          4. No Service Level Objective is offered for Technology Previews or
          Beta channel subscription to Anyware Manager as a Service. Nor is any
          SLO offered to customers trialing or evaluating Anyware Manager as a
          Service, or any customer that has less than 10 active Anyware Manager
          licenses or less than 500 Sessions in the monthly SLO period.
        </Typography>
      </TextPage>
    </Root>
  );
}

export default ServiceLevelPage;
