import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import CAMPagination from 'components/common/Pagination';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {replace} from 'redux/ReduxHistory';
import {
  MACHINE_SESSION_ATTEMPTS,
  MONITOR_TELEMETRY_LATEST,
  USER_GROUPS,
  USER_SESSIONS,
  USER_SESSION_ATTEMPTS,
} from 'utils/constants';
import {mapResourceToIdField} from 'utils/Mappings';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {convertSecondsToDisplay, formatDateTime} from 'utils/utils';
import CertExpiryStatus from '../connectors/CertExpiryStatus';
import MiniNormalTable from './MiniNormalTable';

const PREFIX = 'MiniTable';

const classes = {
  headerCell: `${PREFIX}-headerCell`,
  loadingTableBody: `${PREFIX}-loadingTableBody`,
  link: `${PREFIX}-link`,
  success: `${PREFIX}-success`,
  failure: `${PREFIX}-failure`,
  rightMargin: `${PREFIX}-rightMargin`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.headerCell}`]: {
    color: '#080707',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '20px',
    position: 'sticky',
    top: 0,
    borderBottom: '1px solid lightgrey',
    backgroundColor: '#FAFAF9',
    paddingTop: '4px',
    paddingBottom: '4px',
  },

  [`& .${classes.loadingTableBody}`]: {
    backgroundColor: '#FAFAF9',
    padding: '50px 40px',
    textAlign: 'center',
    color: '#0076A9',
  },

  [`& .${classes.link}`]: {
    color: '#0076a9',
    cursor: 'pointer',
    textDecoration: 'none',
  },

  [`& .${classes.success}`]: {color: 'green'},
  [`& .${classes.failure}`]: {color: 'red'},
  [`& .${classes.rightMargin}`]: {marginRight: '10px'}
}));

function MiniTable({
  headers,
  resource,
  data,
  total,
  isLoading,
  usePagination,
  apiParams: params,
  noDataMessage,
}) {

  const dispatch = useDispatch();
  const selectedDeployment = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {deploymentId} = selectedDeployment;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const shouldFetchData = () =>
    deploymentId &&
    ![
      USER_SESSIONS,
      USER_SESSION_ATTEMPTS,
      MACHINE_SESSION_ATTEMPTS,
      USER_GROUPS,
      MONITOR_TELEMETRY_LATEST,
    ].includes(resource);

  useEffect(() => {
    if (shouldFetchData()) {
      dispatch(fetchTableData(resource, page, rowsPerPage, params));
    }
  }, [deploymentId]);

  const handleCellClick = (row, header) => {
    const type = header.type || '';
    switch (type) {
      case 'link':
        dispatch(replace(row.link));
        break;
      default:
        break;
    }
  };

  const displayField = (row, header) => {
    const type = header.type || '';
    const fieldId = `${row[mapResourceToIdField(resource)]}-${header.id}`;
    switch (type) {
      case 'date':
        return formatDateTime(row[header.id]) || row[header.id];
      case 'link':
        return (
          <Link to={row.link} className={classes.link}>
            {row[header.id]}
          </Link>
        );
      case 'certExpiry':
        return <CertExpiryStatus fieldId={fieldId} connector={row.data} />;
      case 'durationSeconds':
        return convertSecondsToDisplay(row[header.id]);
      case 'success':
        if (row[header.id]) {
          return <div className={classes.success}>Success</div>;
        }
        return <div className={classes.failure}>Failure</div>;
      default:
        return row[header.id];
    }
  };

  const loadingTableBody = (
    <div className={classes.loadingTableBody}>
      <CircularProgress className={classes.rightMargin} size={14} />
      Loading data...
    </div>
  );

  const noDataBody = (
    <div className={classes.loadingTableBody}>{noDataMessage}</div>
  );

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    dispatch(fetchTableData(resource, newPage, rowsPerPage, params));
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    setPage(0);
    setRowsPerPage(newRowsPerPage);
    dispatch(fetchTableData(resource, 0, newRowsPerPage, params));
  };

  const renderTable = () => {
    if (!isLoading) {
      return (
        <MiniNormalTable
          headers={headers}
          data={data}
          displayField={displayField}
          handleCellClick={handleCellClick}
        />
      );
    }
    return false;
  };

  const renderPagination = () => {
    if (usePagination) {
      return (
        <CAMPagination
          dataTestId={`${resource}-pagination`}
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          total={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      );
    }
    return false;
  };

  return (
    (<Root>
      <div>
        <Table size="small">
          <TableHead>
            <TableRow className={classes.tableHead}>
              {headers.map((header) => (
                <TableCell
                  key={header.id}
                  className={classNames(classes.headerCell, classes.tableCell)}
                >
                  {header.value}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {renderTable()}
        </Table>
        {isLoading && loadingTableBody}
        {!isLoading && data.length === 0 && noDataBody}
      </div>
      {renderPagination()}
    </Root>)
  );
}

MiniTable.propTypes = {
  headers: PropTypes.array,
  resource: PropTypes.string,
  data: PropTypes.array,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  usePagination: PropTypes.bool,
  apiParams: PropTypes.object,
  noDataMessage: PropTypes.string,
};

MiniTable.defaultProps = {
  headers: [],
  resource: '',
  data: [],
  total: 0,
  isLoading: false,
  usePagination: false,
  apiParams: {},
  noDataMessage: 'No data found',
};

export default MiniTable;
