import {
  DialogContentText,
  Grid,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {styled} from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import MachineName from 'components/CAM/display/MachineName/MachineName';
import CAMDialog from 'components/CAM/dialogs/CAMDialog/CAMDialog';
import {monitorUserLogout, saveVariable} from 'redux/actions/dataActions';
import {mapProvidersToEnum} from 'utils/Mappings';
import {
  LOGOUT_OPTIONS,
  MONITOR_IN_SESSION,
  MONITOR_TELEMETRY_LATEST,
} from 'utils/constants';
import {selectResource} from 'utils/reduxSelectors';
import {truncateLabelAndAddSuffix} from 'utils/utils';

const PREFIX = 'MonitorLogoutDialog';

const classes = {
  paragraph: `${PREFIX}-paragraph`,
  warningLine: `${PREFIX}-warningLine`,
  logoutWarning: `${PREFIX}-logoutWarning`,
  icon: `${PREFIX}-icon`,
  logoutTopGrid: `${PREFIX}-logoutTopGrid`,
  logoutLine: `${PREFIX}-logoutLine`,
  infoIcon: `${PREFIX}-infoIcon`,
  dialogContentText: `${PREFIX}-dialogContentText`,
  workstationList: `${PREFIX}-workstationList`,
};

const StyledLogoutContent = styled('span')({
  [`& .${classes.logoutTopGrid}`]: {
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '10px',
  },
  [`& .${classes.dialogContentText}`]: {
    margin: '0px',
  },
  [`& .${classes.workstationList}`]: {
    marginTop: '10px',
  },
  [`& .${classes.paragraph}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.warningLine}`]: {
    color: '#FF9600',
    marginBottom: '2px',
    marginTop: '0px',
  },
  [`& .${classes.logoutWarning}`]: {
    marginBottom: '5px',
  },
  [`& .${classes.icon}`]: {
    verticalAlign: '-4px',
    paddingRight: '5px',
  },
  [`& .${classes.infoIcon}`]: {
    color: '#0076A9',
  },
  [`& .${classes.logoutLine}`]: {
    fontSize: '16px',
    marginTop: '4px',
    paddingRight: '5px',
  },
});

const MAX_LENGTH_STRING = 15;

const ACTION_CANNOT_BE_CANCELED_MESSAGE =
  'This action cannot be canceled once submitted.';
const NO_NOTICE_FOR_USER_MESSAGE =
  'User will NOT receive a warning before the logout.';
const NOTICE_FOR_USER_MESSAGE =
  'A warning message will be displayed to the user before the logout operation.';

function MonitorLogoutDialog({isOpen, username, workstations, onClose}) {
  const dispatch = useDispatch();

  const [logoutTimeDelay, setLogoutTimeDelay] = useState(
    LOGOUT_OPTIONS.In_5_min.value
  );
  const {data: monitorTelemetry} = useSelector((state) =>
    selectResource(state, MONITOR_TELEMETRY_LATEST)
  );

  const prepareWorkstationData = () => {
    const workstationData = [];
    if (username) {
      workstationData.push({
        machineId: workstations[0].machineId,
        userList: [username],
        provider: mapProvidersToEnum(workstations[0].provider),
        instanceId: workstations[0].instanceId,
        machineName: workstations[0].machineName,
      });
    } else {
      workstations.forEach((workstation) => {
        const {loggedInUsers} = monitorTelemetry[workstation.machineId];
        const usersInSession = loggedInUsers
          ?.filter((user) => user.status === MONITOR_IN_SESSION)
          .map((user) => user.userName);

        if (usersInSession?.length > 0) {
          workstationData.push({
            machineId: workstation.machineId,
            userList: usersInSession,
            provider: mapProvidersToEnum(workstation.provider),
            instanceId: workstation.instanceId,
            machineName: workstation.machineName,
          });
        }
      });
    }
    return workstationData;
  };

  const workstationData = prepareWorkstationData();

  useEffect(() => {
    if (workstationData.length === 0 && isOpen) {
      onClose();
    }
  }, [workstationData, isOpen]);

  const isSingleLogout =
    workstationData.length === 1 && workstationData[0]?.userList.length === 1;

  const handleChangeLogoutSelect = ({target: {value}}) => {
    setLogoutTimeDelay(value);
  };

  const handleCloseLogoutDialog = () => {
    onClose();
    setLogoutTimeDelay(LOGOUT_OPTIONS.In_5_min.value);
    dispatch(
      saveVariable('monitorLogoutInfo', {
        isLogoutDialogOpen: false,
      })
    );
  };

  const handleConfirmLogoutDialog = () => {
    workstationData.forEach((workstation) => {
      const {machineId, userList} = workstation;
      userList.forEach((user) =>
        dispatch(monitorUserLogout(machineId, user, logoutTimeDelay))
      );
    });
    handleCloseLogoutDialog();
  };

  const renderDialogContextText = () => {
    const dialogText = isSingleLogout
      ? `Unsaved work on workstation ${workstationData[0].machineName} will be lost.`
      : 'Unsaved work will be lost for the following workstation users:';
    return (
      <DialogContentText className={classes.dialogContentText}>
        {dialogText}
      </DialogContentText>
    );
  };

  const renderWorkstationUserList = () => {
    const workstationUserList = workstationData.map((workstation) => {
      const userNameList = workstation.userList;
      const usernamesLengthLimited = truncateLabelAndAddSuffix(
        userNameList.toString(),
        MAX_LENGTH_STRING
      );
      const usersList = `: ${usernamesLengthLimited.replace(/,/g, ', ')}`;

      return (
        <ListItem key={workstation.machineId}>
          <MachineName
            testId={`logout-action-${workstation.machineId}`}
            machineName={workstation.machineName}
            provider={mapProvidersToEnum(workstation.provider)}
            instanceId={workstation.instanceId}
          />
          <Typography>{usersList}</Typography>
        </ListItem>
      );
    });
    return <div className={classes.workstationList}>{workstationUserList}</div>;
  };

  const renderSelectTimeDelay = () => (
    <Grid
      container
      direction="row"
      spacing={0.5}
      className={classes.logoutTopGrid}
    >
      <Grid item>
        <p className={classes.logoutLine}>
          {isSingleLogout ? 'Logout the user' : 'Logout all users'}
        </p>
      </Grid>
      <Grid item>
        <FormControl size="small">
          <Select
            data-testid="select-time-delay"
            defaultValue={LOGOUT_OPTIONS.In_5_min.value}
            onChange={handleChangeLogoutSelect}
          >
            <MenuItem value={LOGOUT_OPTIONS.Immediately.value}>
              {LOGOUT_OPTIONS.Immediately.text}
            </MenuItem>
            <MenuItem value={LOGOUT_OPTIONS.In_5_min.value}>
              {LOGOUT_OPTIONS.In_5_min.text}
            </MenuItem>
            <MenuItem value={LOGOUT_OPTIONS.In_10_min.value}>
              {LOGOUT_OPTIONS.In_10_min.text}
            </MenuItem>
            <MenuItem value={LOGOUT_OPTIONS.In_15_min.value}>
              {LOGOUT_OPTIONS.In_15_min.text}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  const renderLogoutWarning = () => {
    const isImmediateLogout =
      logoutTimeDelay === LOGOUT_OPTIONS.Immediately.value;
    const paragraphClassName = isImmediateLogout
      ? classNames(
          classes.warningLine,
          classes.paragraph,
          classes.logoutWarning
        )
      : classNames(classes.paragraph, classes.logoutWarning);
    const paragraphIcon = isImmediateLogout ? (
      <WarningIcon className={classes.icon} />
    ) : (
      <InfoIcon className={classNames(classes.icon, classes.infoIcon)} />
    );
    const logoutWarningMessage = isImmediateLogout
      ? NO_NOTICE_FOR_USER_MESSAGE
      : NOTICE_FOR_USER_MESSAGE;

    return (
      <p className={paragraphClassName}>
        {paragraphIcon}
        {logoutWarningMessage}
      </p>
    );
  };

  const renderActionCannotBeCanceledWarning = () => (
    <p className={classNames(classes.warningLine, classes.paragraph)}>
      <WarningIcon className={classes.icon} />
      {ACTION_CANNOT_BE_CANCELED_MESSAGE}
    </p>
  );

  const logoutContent = (
    <StyledLogoutContent>
      {renderDialogContextText()}
      {!isSingleLogout && renderWorkstationUserList()}
      {renderSelectTimeDelay()}
      {renderLogoutWarning()}
      {renderActionCannotBeCanceledWarning()}
    </StyledLogoutContent>
  );

  const singleLogoutUsername =
    username === '' ? workstationData[0]?.userList[0] : username;

  return (
    <CAMDialog
      content={logoutContent}
      open={isOpen}
      onCancel={handleCloseLogoutDialog}
      onOk={handleConfirmLogoutDialog}
      title={
        isSingleLogout
          ? `Logout the user ${singleLogoutUsername}?`
          : 'Logout all users?'
      }
    />
  );
}

MonitorLogoutDialog.propTypes = {
  isOpen: PropTypes.bool,
  username: PropTypes.string,
  workstations: PropTypes.array,
  onClose: PropTypes.func.isRequired,
};

MonitorLogoutDialog.defaultProps = {
  isOpen: false,
  username: '',
  workstations: [],
};

export default MonitorLogoutDialog;
