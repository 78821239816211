import {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {Button, Grid, CircularProgress} from '@mui/material/index';
import HorizontalTab from 'components/CAM/tabs/HorizontalTab/HorizontalTab';
import CodeBoxWithClipboard from 'components/CAM/display/CodeBoxWithClipboard/CodeBoxWithClipboard';
import useSnackbar from 'hooks/useSnackbar';
import {getDownloadTokenWithFallback} from 'utils/apiUtils';
import {getUpdateCommand} from 'utils/utils';
import config from 'config';
import ToggleSwitchBox from 'components/CAM/buttons/ToggleSwitchBox/ToggleSwitchBox';
import {COLUMN_TOOLTIP_VALIDATE_CERTIFICATE} from 'utils/constants';

const OS_LIST = ['windows', 'linux'];

const PREFIX = 'MonitorUpdateInstructions';

const classes = {
  updateInstructionsContainer: `${PREFIX}-updateInstructionsContainer`,
  title: `${PREFIX}-title`,
  moreInfoText: `${PREFIX}-moreInfoText`,
  circularProgress: `${PREFIX}-circularProgress`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.updateInstructionsContainer}`]: {
    maxWidth: '45.875rem',
  },
  [`& .${classes.title}`]: {
    color: theme.palette.primary.main,
    lineHeight: '20px',
    marginBottom: '6px',
  },
  [`& .${classes.moreInfoText}`]: {
    color: theme.palette.info.main,
    lineHeight: '20px',
  },
  [`& .${classes.circularProgress}`]: {
    display: 'flex',
    marginTop: '1rem',
    marginBottom: '1rem',
    marginLeft: '1rem',
  },
}));

function MonitorUpdateInstructions({
  deploymentId,
  showTitle,
  setShowUpdateInstructions,
}) {
  const [selectedOsIndex, setSelectedOsIndex] = useState(0);
  const [downloadToken, setDownloadToken] = useState('');
  const [useSecureConnection, setUseSecureConnection] = useState(true);
  const {errorSnackbar} = useSnackbar();

  useEffect(() => {
    const getDownloadRepository = async () => {
      try {
        const token = await getDownloadTokenWithFallback(
          deploymentId,
          config.getDownloadRepositoryName(config.getCloudsmithChannel(true))
        );
        setDownloadToken(token);
      } catch (error) {
        errorSnackbar('Failed generate the download token.');
        setDownloadToken('CHANGEME');
      }
    };
    getDownloadRepository();
  }, []);

  return (
    <Root className={classes.updateInstructionsContainer}>
      {showTitle && (
        <div className={classes.title}>Update the Anyware Monitor</div>
      )}
      <div className={classes.moreInfoText}>
        To install the latest version of the Anyware Monitor, copy and run the
        generated command in a terminal with administrator privileges.
        <br />
        Please note that this update command won't change the register state of
        the Anyware Monitor.
        <br />
        This same command can be used in multiple workstations.
      </div>
      {config.STANDALONE && (
        <div className={classes.secureConnectionToggle}>
          <ToggleSwitchBox
            isOn={useSecureConnection}
            onClick={() => setUseSecureConnection(!useSecureConnection)}
            isSaving={false}
            disabled={false}
            displayText="Validate TLS certificate"
            tooltipText={COLUMN_TOOLTIP_VALIDATE_CERTIFICATE}
            testId="validate-tls-certificate"
          />
        </div>
      )}
      {downloadToken ? (
        <>
          <HorizontalTab
            tabs={OS_LIST}
            selectedTab={selectedOsIndex}
            setSelectedTab={setSelectedOsIndex}
          />
          <CodeBoxWithClipboard
            id="copy-to-clipboard"
            code={getUpdateCommand({
              os: OS_LIST[selectedOsIndex],
              downloadToken,
              useSecureConnection,
              skipRegistration: true,
            })}
            snackbarMessage="The Anyware Monitor update command has been copied to your clipboard."
            tooltipText="Click to copy the update command to your clipboard."
          />
        </>
      ) : (
        <CircularProgress size={40} className={classes.circularProgress} />
      )}
      {showTitle && (
        <Grid container item xs={6}>
          <Button
            onClick={() => setShowUpdateInstructions(false)}
            className={classes.button}
            variant="text"
          >
            Back
          </Button>
        </Grid>
      )}
    </Root>
  );
}

MonitorUpdateInstructions.propTypes = {
  deploymentId: PropTypes.string.isRequired,
  showTitle: PropTypes.bool,
  setShowUpdateInstructions: PropTypes.func,
};

MonitorUpdateInstructions.defaultProps = {
  showTitle: false,
  setShowUpdateInstructions: () => {},
};

export default MonitorUpdateInstructions;
