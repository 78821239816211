import Divider from '@mui/material/Divider';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import {openDeleteConnectorsDialog} from 'redux/actions/DeleteConnectorsDialogActions';
import {saveVariable} from 'redux/actions/dataActions';

import {isItemActive} from 'utils/utils';

const PREFIX = 'ConnectorsActions';

const classes = {
  lineDivider: `${PREFIX}-lineDivider`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.lineDivider}`]: {height: '2px'},
}));

function ConnectorsActions({item: connector, closeMenu}) {
  const dispatch = useDispatch();
  const itemIsActive = isItemActive(connector);

  const width = '17em';
  const handleEditConnector = () => {
    dispatch(saveVariable('selectedConnector', connector));
  };

  const handleDeleteConnector = () => {
    closeMenu();
    dispatch(openDeleteConnectorsDialog({connectors: [connector]}));
  };

  return (
    <Root>
      <Link
        id={`edit-connector-${connector.connectorId}-link`}
        to={`/app/connectors/edit/${connector.connectorId}`}
      >
        <KebabMenuItem
          id={`edit-connector-${connector.connectorId}`}
          dataTestId="edit-connector"
          enableClick={itemIsActive}
          menuText="Edit"
          width={width}
          onClick={handleEditConnector}
        />
      </Link>
      <Divider className={classes.lineDivider} />
      <KebabMenuItem
        id={`delete-connector-${connector.connectorId}`}
        dataTestId="delete-connector"
        enableClick={itemIsActive}
        menuText="Delete"
        width={width}
        onClick={handleDeleteConnector}
      />
    </Root>
  );
}

ConnectorsActions.propTypes = {
  item: PropTypes.object,
  closeMenu: PropTypes.func,
};

ConnectorsActions.defaultProps = {
  item: {},
  closeMenu: () => {},
};

export default ConnectorsActions;
