import {useState} from 'react';
import propTypes from 'prop-types';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import Note from 'components/common/Note';
import ConnectorReqInfo from 'components/CAM/icons/ConnectorReqInfo/ConnectorReqInfo';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import {copyToClipboard} from 'utils/utils';
import {ApplyConnectorConfiguration} from './ApplyConfiguration';

const PREFIX = 'CopyAwcCommand';

const classes = {
  internalContainer: `${PREFIX}-internalContainer`,
  instructionsList: `${PREFIX}-instructionsList`,
  mainButton: `${PREFIX}-mainButton`,
};

const Root = styled(Grid)(() => ({
  [`&.${classes.internalContainer}`]: {
    margin: '40px 0px',
  },
  [`& .${classes.instructionsList}`]: {
    ...stylesFromFigma.textSubtitle2Styles,
    color: '#555967',
    marginLeft: '0px',
    paddingLeft: '20px',
  },
  [`& .${classes.mainButton}`]: {
    color: '#FFFFFF',
    background: '#0D47A1',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#0D47A1',
    },
    '&:disabled': {
      color: '#00000042',
      boxShadow: 'none',
      backgroundColor: '#0000001F',
    },
  },
}));
function CopyAwcCommand({
  isCopyEnabled,
  commandToCopy,
  commandType,
  alternateCopyFunction,
  isApplyEnabled,
  ...props
}) {
  const [commandCopyTooltipMessage, setCommandCopyTooltipMessage] =
    useState('');

  const shouldDisplayNote = commandType === 'install' && commandToCopy;

  const handleCopyClick = () => {
    if (alternateCopyFunction && typeof alternateCopyFunction === 'function') {
      alternateCopyFunction();
      return;
    }

    copyToClipboard(commandToCopy);
    setCommandCopyTooltipMessage('Copied to your clipboard');
  };

  return (
    <Root container className={classes.internalContainer}>
      <Stack direction="row">
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {commandType === 'install'
                ? 'Manual Installation'
                : 'Manual Configuration'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ol className={classes.instructionsList}>
              <li>Copy the command provided below.</li>
              <li>
                <Grid container direction="row" alignItems="center">
                  Log into your Linux machine and run the command in the
                  terminal. <ConnectorReqInfo />
                </Grid>
              </li>
              <li>
                {commandType === 'install'
                  ? 'The page will refresh automatically once the installation is successfully completed.'
                  : 'You can monitor the progress in the installation tab.'}
              </li>
            </ol>
            {shouldDisplayNote && (
              <Note title="You will receive an update automatically once installation starts." />
            )}
          </Grid>
          <Grid item xs={12}>
            <Tooltip
              title={commandCopyTooltipMessage}
              placement="top"
              leaveDelay={2000}
              onClose={() => setCommandCopyTooltipMessage('')}
            >
              <Button
                onClick={handleCopyClick}
                disabled={!isCopyEnabled}
                variant="contained"
                className={classes.mainButton}
              >
                Copy&nbsp;
                {`${
                  commandType === 'install' ? 'installation' : 'configuration'
                }`}
                &nbsp;command
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        {isApplyEnabled && (
          <ApplyConnectorConfiguration
            style={{marginLeft: '6px'}}
            variant="contained"
            className={classes.mainButton}
            disabled={!isCopyEnabled}
            {...props}
          />
        )}
      </Stack>
    </Root>
  );
}

CopyAwcCommand.propTypes = {
  isCopyEnabled: propTypes.bool,
  commandToCopy: propTypes.string,
  commandType: propTypes.oneOf(['install', 'configure']),
  alternateCopyFunction: propTypes.func,
  isApplyEnabled: propTypes.bool,
  configParamArray: propTypes.array,
};

CopyAwcCommand.defaultProps = {
  isCopyEnabled: false,
  commandToCopy: '',
  commandType: 'install',
  alternateCopyFunction: null,
  isApplyEnabled: false,
  configParamArray: [],
};

export default CopyAwcCommand;
