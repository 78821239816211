import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import classnames from 'classnames';
import QueryString from 'query-string';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import ErrorDialog from 'components/common/ErrorDialog';
import TextInput from 'components/standalone/TextInput';
import config from 'config';
import {
  useStandaloneLogin,
  useStandaloneUpdatePassword,
} from 'hooks/standaloneHooks';
import usePendingChanges from 'hooks/usePendingChanges';
import {openErrorDialog} from 'redux/actions/errorDialogActions';
import {
  allowListErrorLinkText,
  AWM_SERVICE_STATUS,
  contactTechnicalSupportLink,
  samlAllowListError,
  samlInvalidConfigError,
} from 'utils/constants';
import CasmCookies from 'utils/cookies';
import {isEmpty} from 'utils/utils';
import GoogleLoginButton from './GoogleLoginButton';
import MicrosoftLoginButton from './MicrosoftLoginButton';

const PREFIX = 'LoginPanel';

const classes = {
  internalWrapper: `${PREFIX}-internalWrapper`,
  root: `${PREFIX}-root`,
  rootBorder: `${PREFIX}-rootBorder`,
  rootShadow: `${PREFIX}-rootShadow`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
  underlineLink: `${PREFIX}-underlineLink`,
  statusLink: `${PREFIX}-statusLink`,
  statusSection: `${PREFIX}-statusSection`,
  loginButton: `${PREFIX}-loginButton`,
  standaloneButton: `${PREFIX}-standaloneButton`,
  samlText: `${PREFIX}-samlText`,
  loadingSpinner: `${PREFIX}-loadingSpinner`,
  panelTitle: `${PREFIX}-panelTitle`,
  validationSuccess: `${PREFIX}-validationSuccess`,
  validationError: `${PREFIX}-validationError`,
  validationText: `${PREFIX}-validationText`,
  updateButton: `${PREFIX}-updateButton`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.internalWrapper}`]: {
    margin: '0px auto',
    maxWidth: '350px',
    minWidth: '300px',
    padding: '40px 0px',
    width: '100%',
  },

  [`& .${classes.root}`]: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    width: 'calc(100% - 150px)',
    margin: '0px auto',
    minWidth: '370px',
    maxWidth: '600px',
  },

  [`& .${classes.rootBorder}`]: {
    border: '1px solid rgba(0,0,0,0.2)',
  },

  [`& .${classes.rootShadow}`]: {
    boxShadow: '0px 0px 10px #fff',
  },

  [`& .${classes.buttonWrapper}`]: {
    color: theme.palette.surface.grey,
    fontSize: '12px',
    marginTop: '16px',
  },

  [`& .${classes.underlineLink}`]: {
    borderBottom: '1.5px dotted rgba(0,0,0,0.4)',
    color: theme.palette.surface.grey,
  },

  [`& .${classes.statusLink}`]: {
    color: '#023E51',
    fontSize: '16px',
    fontWeight: 500,
  },

  [`& .${classes.statusSection}`]: {
    marginTop: '60px',
  },

  [`& .${classes.loginButton}`]: {
    textTransform: 'none',
    width: '100%',
    minHeight: '3.1rem',
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    padding: '10px',
    justifyContent: 'center',
    borderRadius: 2,
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
    },
  },

  [`& .${classes.standaloneButton}`]: {
    marginTop: '40px',
  },

  [`& .${classes.samlText}`]: {
    color: '#fff',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },

  [`& .${classes.loadingSpinner}`]: {
    color: 'white',
  },

  [`& .${classes.panelTitle}`]: {
    fontSize: '30px',
    fontWeight: 600,
  },

  [`& .${classes.validationSuccess}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.validationError}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.validationText}`]: {
    marginLeft: '0.5rem',
  },

  [`& .${classes.updateButton}`]: {
    width: '100%',
    marginTop: '3rem',
  },
}));

const minimumLengthMessage = '8 characters minimum';
const mismatchedPasswordError = 'Passwords must match';

function LoginPanel() {
  const dispatch = useDispatch();
  const {setPendingChanges} = usePendingChanges();

  const {
    handleStandaloneLogin,
    handleLoginChange,
    loginData,
    loginErrorMessage,
    loginLoading,
    showUpdatePassword,
  } = useStandaloneLogin({dispatch});
  const loginError = Boolean(loginErrorMessage.length);

  const {configurationId} = QueryString.parse(window.location.search);
  const isSamlLogin = () => !isEmpty(configurationId);
  const isDisplaySmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const {
    handlePasswordChange,
    handleUpdatePassword,
    passwordErrorMessage,
    passwordMeetsMinLength,
    passwordsMatch,
    passwordUpdateData,
    savingPassword,
  } = useStandaloneUpdatePassword({dispatch});

  const updateDisabled =
    savingPassword || !passwordMeetsMinLength || !passwordsMatch;
  const updatePasswordError = Boolean(passwordErrorMessage.length);

  useEffect(() => {
    setPendingChanges(false);
    localStorage.setItem('betaAccepted', false);
  }, []);

  useEffect(() => {
    const samlLoginError = CasmCookies.getItem('loginError');
    CasmCookies.removeItem('loginError');
    if (!isEmpty(samlLoginError)) {
      switch (samlLoginError) {
        case 'NOT_IN_ALLOW_LIST':
          dispatch(openErrorDialog('Login Error', samlAllowListError));
          break;
        case 'CONFIGURATION_INVALID':
          dispatch(openErrorDialog('Login Error', samlInvalidConfigError));
          break;
        default:
          break;
      }
    }
  }, []);

  const renderStandaloneLogin = () => (
    <div>
      <Typography className={classes.panelTitle}>Login</Typography>
      <form onSubmit={handleStandaloneLogin}>
        <TextInput
          label="Username"
          value={loginData.username}
          onChange={handleLoginChange('username')}
          disabled={loginLoading}
          error={loginError}
          InputProps={{'data-testid': 'username-field'}}
        />
        <TextInput
          label="Password"
          value={loginData.password}
          onChange={handleLoginChange('password')}
          type="password"
          disabled={loginLoading}
          error={loginError}
          helperText={loginErrorMessage}
          InputProps={{'data-testid': 'password-field'}}
        />
        <Button
          id="standaloneLogin"
          data-testid="standaloneLogin"
          variant="outlined"
          color="primary"
          type="submit"
          disabled={loginLoading}
          className={classnames(classes.loginButton, classes.standaloneButton)}
        >
          {loginLoading ? (
            <CircularProgress className={classes.loadingSpinner} size={20} />
          ) : (
            <Typography className={classes.samlText}>LOG IN</Typography>
          )}
        </Button>
      </form>
    </div>
  );

  const renderUpdatePassword = () => (
    <>
      <Typography className={classes.panelTitle}>Update Password</Typography>
      <form onSubmit={handleUpdatePassword}>
        <Grid container spacing={3}>
          {/* Input container */}
          <Grid item container>
            <TextInput
              label="New Password"
              value={passwordUpdateData.password}
              onChange={handlePasswordChange('password')}
              type="password"
              disabled={savingPassword}
              InputProps={{'data-testid': 'password-field'}}
            />
            <TextInput
              label="Confirm Password"
              value={passwordUpdateData.confirmPassword}
              onChange={handlePasswordChange('confirmPassword')}
              type="password"
              disabled={savingPassword}
              error={updatePasswordError}
              helperText={passwordErrorMessage}
              InputProps={{'data-testid': 'confirm-password-field'}}
            />
          </Grid>

          {/* Validation container */}
          <Grid item container spacing={2}>
            {/* Validate password length */}
            <Grid
              item
              container
              alignItems="center"
              className={classnames({
                [classes.validationSuccess]: passwordMeetsMinLength,
                [classes.validationError]: !passwordMeetsMinLength,
              })}
            >
              {passwordMeetsMinLength ? <CheckIcon /> : <ErrorIcon />}
              <Typography className={classes.validationText}>
                {minimumLengthMessage}
              </Typography>
            </Grid>

            {/* Validate passwords match */}
            <Grid
              item
              container
              alignItems="center"
              className={classnames({
                [classes.validationSuccess]: passwordsMatch,
                [classes.validationError]: !passwordsMatch,
              })}
            >
              {passwordsMatch ? <CheckIcon /> : <ErrorIcon />}
              <Typography className={classes.validationText}>
                {mismatchedPasswordError}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Button
          className={classes.updateButton}
          color="primary"
          variant="contained"
          type="submit"
          disabled={updateDisabled}
        >
          {savingPassword ? <CircularProgress size={24} /> : 'Update'}
        </Button>
      </form>
    </>
  );

  const renderLoginForm = () => {
    // SAML Login
    if (isSamlLogin()) {
      return (
        <>
          Log in using a SAML configuration set up by your organization
          <br />
          <br />
          <Button
            id="samlLogin"
            data-testid="samlLogin"
            variant="outlined"
            color="inherit"
            className={classes.loginButton}
            href={`/api/v1/auth/saml/${configurationId}/login`}
          >
            <Typography className={classes.samlText}>
              Sign in with your organization
            </Typography>
          </Button>
        </>
      );
    }

    // In the case where user logs out of SAML and into other login pages, delete stored configuration ID
    localStorage.removeItem('samlConfigurationId');

    // Standalone Login
    if (config.STANDALONE) {
      return showUpdatePassword
        ? renderUpdatePassword()
        : renderStandaloneLogin();
    }

    // SaaS Login
    return (
      <>
        Sign in using an Microsoft Office 365 Enterprise, Google Workspace or
        Cloud Identity account
        <div className={classes.buttonWrapper}>
          <MicrosoftLoginButton />
          Microsoft personal accounts are not supported.
        </div>
        <div className={classes.buttonWrapper}>
          <GoogleLoginButton />
          {'To sign in with a Gmail personal account, contact '}
          <a
            href={contactTechnicalSupportLink}
            rel="noopener noreferrer"
            target="_blank"
            className={classes.underlineLink}
          >
            {allowListErrorLinkText}
          </a>
        </div>
      </>
    );
  };

  return (
    <Root>
      <div
        className={classnames(
          classes.root,
          isDisplaySmall ? classes.rootShadow : classes.rootBorder
        )}
      >
        <div className={classes.internalWrapper}>
          {renderLoginForm()}
          {!config.STANDALONE && (
            <div className={classes.statusSection}>
              <a
                href={AWM_SERVICE_STATUS}
                rel="noopener noreferrer"
                target="_blank"
                className={classes.statusLink}
              >
                Check status updates
              </a>
              <br />
              View status overview and metrics for Anyware Manager as a Service.
            </div>
          )}
        </div>
      </div>
      <ErrorDialog />
    </Root>
  );
}

export default LoginPanel;
