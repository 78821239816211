import {Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import {useSelector} from 'react-redux';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import FedAuthForm from 'components/deployments/FedAuthForm';
import config from 'config';
import {selectSelectedDeployment} from 'utils/reduxSelectors';

const PREFIX = 'EditConnectorSettingsTab';

const classes = {
  description: `${PREFIX}-description`,
  primaryTag: `${PREFIX}-primaryTag`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.description}`]: {
    color: '#514F4D',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '18px',
    margin: '10px 0px',
  },

  [`& .${classes.primaryTag}`]: {
    marginLeft: '8px',
    background: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  }
}));

function EditConnectorSettingsTab({
  deploymentSettings,
  connectorSettings,
  connectorId,
}) {


  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );

  const renderFedAuthCard = () => {
    let inheritDeploymentSettings = false;
    let canResetSettings = false;
    if (!connectorSettings?.oauth && deploymentSettings?.oauth) {
      inheritDeploymentSettings = true;
    }
    if (connectorSettings?.oauth && deploymentSettings?.oauth) {
      canResetSettings = true;
    }

    return (
      <FedAuthForm
        canResetSettings={canResetSettings}
        inheritDeploymentSettings={inheritDeploymentSettings}
        settings={
          inheritDeploymentSettings ? deploymentSettings : connectorSettings
        }
        connectorId={connectorId}
      />
    );
  };

  const renderDeploymentSettingCard = () => (
    <CAMCard>
      <GridN singleColumn>
        <SectionHeader displayText="DEPLOYMENT SETTINGS" />
        <Typography className={classes.description}>
          Both connector settings and deployment settings are applied to the
          connector, but connector settings take higher precedence over
          deployment settings. Use deployment settings to configure shared
          settings for all connectors. See{' '}
          <a
            href={`${
              config.isBeta() ? '/beta-ui' : ''
            }/app/deployments/edit/${deploymentId}/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            deployment settings
          </a>
          .
        </Typography>
      </GridN>
    </CAMCard>
  );

  const renderJsonConnectorSettings = () => (
    <CAMCard>
      <GridN singleColumn>
        <SectionHeader displayText="CONNECTOR SETTINGS" />
      </GridN>
      <GridN singleColumn>
        <ReactJson
          src={connectorSettings || {}}
          name={false}
          displayObjectSize={false}
          displayDataTypes={false}
          displayArrayKey={false}
          quotesOnKeys={false}
        />
      </GridN>
    </CAMCard>
  );

  return (
    (<Root>
      {renderDeploymentSettingCard()}
      {renderFedAuthCard()}
      {renderJsonConnectorSettings()}
    </Root>)
  );
}

EditConnectorSettingsTab.propTypes = {
  connectorId: PropTypes.string.isRequired,
  connectorSettings: PropTypes.object,
  deploymentSettings: PropTypes.object,
};

EditConnectorSettingsTab.defaultProps = {
  connectorSettings: {},
  deploymentSettings: {},
};

export default EditConnectorSettingsTab;
