import {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Typography,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import IconButton from '@mui/material/IconButton';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {copyToClipboard, isEmpty} from 'utils/utils';
import {post} from 'api/Api';
import {handleApiError} from 'redux/actions/dataActions';
import CopyIcon from 'icons/CopyIcon';
import {
  generateConnectorToken,
  verifyIfConnectorNameIsUnique,
} from 'utils/apiUtils';
import {ReactComponent as WorldMapIcon} from 'icons/PixelWorldMap.svg';
import {ReactComponent as TroubleSigningIn} from 'icons/TroubleSigningIn.svg';
import {ReactComponent as KeyIcon} from 'icons/key.svg';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {CONNECTORS, CONNECTOR_STATUS_PENDING} from 'utils/constants';
import useNameValidation from 'hooks/useNameValidation';
import ConnectorNameField from './ConnectorNameField';
import {stylesFromFigma} from '../../themes/stylesFromFigma';

const PREFIX = 'TokenDialog';

const classes = {
  spacing: `${PREFIX}-spacing`,
  bannerRoot: `${PREFIX}-bannerRoot`,
  pixelMap: `${PREFIX}-pixelMap`,
  troubleSigningIn: `${PREFIX}-troubleSigningIn`,
  title: `${PREFIX}-title`,
  body: `${PREFIX}-body`,
  label: `${PREFIX}-label`,
  tokenField: `${PREFIX}-tokenField`,
  disclaimer: `${PREFIX}-disclaimer`,
  containedButton: `${PREFIX}-containedButton`,
  dialog: `${PREFIX}-dialog`,
};

const Root = styled('div')(() => ({
  [`& .${classes.spacing}`]: {
    padding: '8px 24px 20px 24px',
  },

  [`& .${classes.bannerRoot}`]: {
    backgroundColor: '#1B4A8C',
    overflow: 'hidden',
    height: '128px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },

  [`& .${classes.pixelMap}`]: {
    top: '-6px',
    left: '-63px',
    width: '657px',
    height: '323px',
    position: 'relative',
  },

  [`& .${classes.troubleSigningIn}`]: {
    top: '9px',
    left: '-54px',
    position: 'relative',
  },

  [`& .${classes.title}`]: {
    ...stylesFromFigma.dialogTitle,
  },

  [`& .${classes.body}`]: {
    ...stylesFromFigma.typographyBody2,
    color: '#23242A',
  },

  [`& .${classes.label}`]: {
    ...stylesFromFigma.labelText,
  },

  [`& .${classes.tokenField}`]: {
    color: '#23242A',
    backgroundColor: '#F8F8FB',
  },

  [`& .${classes.disclaimer}`]: {
    color: '#2D74D8',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '14px',
  },

  [`& .${classes.containedButton}`]: {
    backgroundColor: '#0D47A1',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#0D47A1',
      opacity: 0.8,
    },
    paddingRight: '20px',
  },

  [`& .${classes.dialog}`]: {
    padding: '20px',
  },
}));

function TokenDialog({open, onClose}) {
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );

  const [connectorName, setConnectorName] = useState('');
  const [connectorToken, setConnectorToken] = useState('');
  const [connectorId, setConnectorId] = useState('');
  const [tooltipMessage, setTooltipMessage] = useState('');
  const [isVerifyingName, setIsVerifyingName] = useState(false);
  const [nameIsUnique, setNameIsUnique] = useState(true);
  const [nameValidationResult, setNameValidationResult] = useState(false);
  const [nameValidationError, validateName, nameHelperText] =
    useNameValidation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (connectorName) {
      validateName(connectorName);
    }
  }, [connectorName]);

  const generateDisabled =
    // Case where name is not provided
    !connectorName ||
    // Case where name is being verified with the backend
    isVerifyingName ||
    // Case where name is not unique
    !nameIsUnique ||
    // Case where name failed validation
    nameValidationResult.error;

  const createConnector = async (token) => {
    // Do not perform again if connector was previously created
    if (connectorId) {
      return;
    }

    try {
      const data = {connectorToken: token, status: CONNECTOR_STATUS_PENDING};
      const resp = await post({path: 'deployments/connectors', data});
      setConnectorId(resp.data.connectorId);
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };

  const handleCopyClick = () => {
    copyToClipboard(connectorToken);
    setTooltipMessage('Command copied to your clipboard');
  };

  const handleGenerateClick = async () => {
    if (nameValidationError) {
      setIsVerifyingName(false);
      return;
    }

    setIsVerifyingName(true);
    const result = await verifyIfConnectorNameIsUnique(
      connectorName,
      deploymentId
    );

    if (result.error != null) {
      dispatch(handleApiError(result.error));
      setIsVerifyingName(false);
      return;
    }

    if (!result.isUnique) {
      setNameIsUnique(false);
      setIsVerifyingName(false);
      return;
    }

    setNameIsUnique(true);
    const tokenResult = await generateConnectorToken(
      connectorName,
      deploymentId
    );

    if (!tokenResult.error) {
      setConnectorToken(tokenResult.token);
      await createConnector(tokenResult.token);
      dispatch(fetchTableData(CONNECTORS));
    } else {
      dispatch(handleApiError(tokenResult.error));
    }

    setIsVerifyingName(false);
  };

  const handleNameValidationResult = (name, result) => {
    setConnectorName(name);
    setNameValidationResult(result);

    setNameIsUnique(true);
  };

  const cleanup = () => {
    setConnectorName('');
    setConnectorToken('');
    setConnectorId('');
    setIsVerifyingName(false);
    setNameIsUnique(true);
    setNameValidationResult(false);
    setTooltipMessage('');
    onClose();
  };

  if (!open) return null;

  return (
    <Dialog open={open} onClose={cleanup}>
      <Root>
        <div className={classes.bannerRoot}>
          <div className={classes.pixelMap}>
            <WorldMapIcon alt="Pixel world map" />
          </div>
          <div className={classes.troubleSigningIn}>
            <TroubleSigningIn />
          </div>
        </div>
        <DialogTitle className={classes.title}>Generate Token</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.body}>
            Choose a unique name for your new Connector. Pick something that
            will help you identify it in the future.
          </DialogContentText>
          <ConnectorNameField
            connectorName={connectorName}
            onNameValidate={handleNameValidationResult}
            error={
              nameIsUnique
                ? ((validationError, errorMessage) => {
                    return !validationError ? '' : errorMessage;
                  })(nameValidationError, nameHelperText)
                : 'A Connector with that name already exists in this deployment.'
            }
            disabled={!isEmpty(connectorId)}
          />
          {connectorToken && (
            <>
              <Typography className={classes.label}>Token</Typography>
              <TextField
                margin="dense"
                id="name"
                hiddenLabel
                type="text"
                fullWidth
                variant="outlined"
                value={connectorToken}
                disabled
                className={classes.tokenField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={tooltipMessage}
                        placement="top"
                        leaveDelay={2000}
                        onClose={() => setTooltipMessage('')}
                      >
                        <IconButton
                          style={{width: '40px', height: '40px'}}
                          onClick={handleCopyClick}
                          size="large"
                        >
                          <CopyIcon size="small" iconColor="#0000008F" />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography className={classes.disclaimer}>
                * The token is only valid for 1 hour.
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.spacing}>
          <Button onClick={cleanup} variant="outlined">
            Close
          </Button>
          {connectorId ? (
            <Tooltip
              title={tooltipMessage}
              placement="top"
              leaveDelay={2000}
              onClose={() => setTooltipMessage('')}
            >
              <Button
                onClick={handleCopyClick}
                variant="contained"
                className={classes.containedButton}
                data-testid="copy-token-button"
              >
                <KeyIcon />
                &nbsp;Copy token
              </Button>
            </Tooltip>
          ) : (
            <Button
              onClick={handleGenerateClick}
              disabled={generateDisabled}
              variant="contained"
              className={classes.containedButton}
            >
              Generate token
            </Button>
          )}
        </DialogActions>
      </Root>
    </Dialog>
  );
}

TokenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TokenDialog;
