import {IconButton} from '@mui/material';
import {styled} from '@mui/material/styles';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PropTypes from 'prop-types';

const PREFIX = 'DeleteIcon';

const classes = {
  iconColor: `${PREFIX}-iconColor`,
};

const Root = styled('span')(({theme: {palette}}) => ({
  [`& .${classes.iconColor}`]: {
    color: palette.error.main,
  },
}));

export default function DeleteIcon({onClick, isDeleting, testId}) {
  return (
    <Root>
      <IconButton
        data-testid={testId}
        size="small"
        onClick={onClick}
        disabled={isDeleting}
        classes={{root: classes.iconColor}}
      >
        <DeleteForeverIcon />
      </IconButton>
    </Root>
  );
}

DeleteIcon.propTypes = {
  onClick: PropTypes.func,
  isDeleting: PropTypes.bool,
  testId: PropTypes.string,
};

DeleteIcon.defaultProps = {
  onClick: () => {},
  isDeleting: false,
  testId: '',
};
