import {useMutation} from 'react-query';
import {post} from 'api/Api';

const createDeploymentSA = async (deploymentId, keyName, roleId, expiresOn) => {
  const response = await post({
    path: 'keys',
    data: {keyName, deploymentId, roleId, expiresOn},
  });
  return response?.data;
};

const useCreateDeploymentSA = (options = {}) =>
  useMutation(
    ({deploymentId, keyName, roleId, expiresOn}) =>
      createDeploymentSA(deploymentId, keyName, roleId, expiresOn),
    options
  );

export default useCreateDeploymentSA;
