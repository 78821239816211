import {useMediaQuery} from '@mui/material';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import CAMTooltip from 'components/CAM/display/CAMTooltip/CAMTooltip';
import Tag from 'components/CAM/display/Tag/Tag';
import EnrollmentCommandsButtons from 'components/deployments/EnrollmentCommandsButtons';
import EnrollmentDeleteCommand from 'components/deployments/EnrollmentDeleteCommand';
import EnrollmentTLSToggle from 'components/deployments/EnrollmentTLSToggle';
import MonitorEnrollmentsButtons from 'components/monitorEnrollments/MonitorEnrollmentsButtons';
import StatusIconWorkstation from 'components/remoteWorkstations/statusIconWorkstation/StatusIconWorkstation';
import WebhookStatus from 'components/webhooks/components/WebhookStatus';
import config from 'config';
import useInterval from 'hooks/useInterval';
import {fetchTableData, updateTableData} from 'redux/actions/tableDataActions';
import {
  checkItem,
  checkItems,
  uncheckAll,
  uncheckItem,
} from 'redux/actions/tableSelectActions';
import {
  mapFieldToLink,
  mapFieldToLinkPath,
  mapResourceToIdField,
  mapResourceToSubtitle,
  mapResourceToTableTitle,
  resourceToSearchInfoMap,
} from 'utils/Mappings';
import {
  ACTIVITY_LOGS,
  AD_USERS,
  CONNECTORS,
  DEFAULT_RESOURCE_ROWS_PER_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  ENROLLMENT_COMMANDS,
  ENROLLMENT_STATUS_MESSAGE_WORKSTATION_NOT_FOUND,
  ENROLLMENT_STATUS_WAITING_WORKER_ACCEPT,
  MONITOR_ENROLLMENTS,
  POOLS,
  POOL_GROUPS,
  POOL_MACHINES,
  WEBHOOKS,
  REMOTE_WORKSTATIONS,
  SESSION_AUDIT,
} from 'utils/constants';
import CasmCookies from 'utils/cookies';
import {
  selectDataIsFetching,
  selectDeletingResource,
  selectTableParams,
  selectVariable,
} from 'utils/reduxSelectors';
import {formatDateTime} from 'utils/utils';
import ConnectorStatus from 'components/connectors/ConnectorStatus';
import SessionStatus from 'components/sessions/SessionStatus';
import WebhookActions from 'components/webhooks/WebhookActions';
import AdUsersActions from '../adUsers/AdUsersActions';
import CertExpiryStatus from '../connectors/CertExpiryStatus';
import ConfigServiceStatus from '../connectors/ConfigServiceStatus';
import ConnectorHealth from '../connectors/ConnectorHealth';
import ConnectorsActions from '../connectors/ConnectorsActions';
import ExpansionTable from '../expansionTable/ExpansionTable';
import CAMMenu from '../menu/Menu';
import PoolGroupsActions from '../pools/PoolGroupsActions';
import PoolMachinesActions from '../pools/PoolMachinesActions';
import PoolsActions from '../pools/PoolsActions';
import RemoteWorkstationActions from '../remoteWorkstations/RemoteWorkstationActions';
import NormalTable from './NormalTable';
import TableTitle from './TableTitle';
import TableToolbar from './TableToolbar';

const PREFIX = 'Table';

const classes = {
  root: `${PREFIX}-root`,
  rootCommand: `${PREFIX}-rootCommand`,
  tableRow: `${PREFIX}-tableRow`,
  rowText: `${PREFIX}-rowText`,
  rowActionCell: `${PREFIX}-rowActionCell`,
  pagination: `${PREFIX}-pagination`,
  rowsPerPage: `${PREFIX}-rowsPerPage`,
  rowsPerPageIcon: `${PREFIX}-rowsPerPageIcon`,
  statusIcon: `${PREFIX}-statusIcon`,
  warningIcon: `${PREFIX}-warningIcon`,
  loadingTableBody: `${PREFIX}-loadingTableBody`,
  linearProgress: `${PREFIX}-linearProgress`,
  array: `${PREFIX}-array`,
  link: `${PREFIX}-link`,
  deletingElement: `${PREFIX}-deletingElement`,
  name: `${PREFIX}-name`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  wrapper: `${PREFIX}-wrapper`,
};

const StyledPaper = styled(Paper)(({theme: {spacing, palette}}) => ({
  [`&.${classes.root}`]: {
    margin: spacing(4),
    marginTop: spacing(2),
    backgroundColor: 'transparent',
  },

  [`& .${classes.rootCommand}`]: {
    margin: '0px',
  },

  [`& .${classes.tableRow}`]: {
    backgroundColor: 'white',
  },

  [`& .${classes.rowText}`]: {
    color: 'rgba(0, 0, 0, 0.7)',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: '1.75rem',
    letterSpacing: '0.03125rem',
  },

  [`& .${classes.rowActionCell}`]: {
    textAlign: 'center',
  },

  [`& .${classes.pagination}`]: {
    color: palette.surface.grey,
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.1px',
    lineHeight: '1.5rem',
  },

  [`& .${classes.rowsPerPage}`]: {
    color: palette.primary.main,
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.1px',
    lineHeight: '1.5rem',
  },

  [`& .${classes.rowsPerPageIcon}`]: {
    top: '7px',
  },

  [`& .${classes.statusIcon}`]: {
    marginRight: '0.5rem',
    width: '0.75 rem',
  },

  [`& .${classes.warningIcon}`]: {
    color: palette.warning.main,
    cursor: 'pointer',
    verticalAlign: 'middle',
  },

  [`& .${classes.loadingTableBody}`]: {
    position: 'absolute',
    top: 0,
    backgroundColor: '#fafaf9d1',
    color: palette.primary.main,
    height: '100%',
    fontSize: '1rem',
  },

  [`& .${classes.linearProgress}`]: {
    width: '20rem',
    marginTop: '1rem',
  },

  [`& .${classes.array}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '16rem',
  },

  [`& .${classes.link}`]: {
    color: palette.surface.grey,
    '&:hover': {
      color: palette.primary.main,
    },
    cursor: 'pointer',
    textDecoration: 'none',
  },

  [`& .${classes.deletingElement}`]: {
    color: palette.surface.grey,
    cursor: 'default',
    textDecoration: 'none',
  },

  [`& .${classes.name}`]: {
    color: palette.primary.main,
    fontWeight: 500,
    letterSpacing: '0.015625rem',
  },

  [`& .${classes.tableWrapper}`]: {
    overflow: 'auto',
  },

  [`& .${classes.wrapper}`]: {
    position: 'relative',
  },
}));

export function UnwrappedCAMTable({
  resource,
  data,
  total,
  onAdd,
  loadingData,
  tableSelect,
  dispatch,
  page,
  rowsPerPage,
  hideAddButton,
  hideActions,
  hideBulkActions,
  hideTitle,
  hideConfigureTable,
  useExpansionTable,
  selectedDeployment,
  showDownloadButton,
  expansionTableDrawer,
  noDataMessage,
  showEnrollmentsButton,
  rowsPerPageOptions,
  hideSearch,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [kebabItem, setKebabItem] = useState({});
  const [lastChecked, setLastChecked] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const deletingResource = useSelector((state) =>
    selectDeletingResource(state, resource)
  );
  const {params: filterParams} = useSelector((state) =>
    selectTableParams(state, resource)
  );
  const isFetching = useSelector((state) =>
    selectDataIsFetching(state, resource)
  );

  const isDisplaySmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const usePartialSearch =
    (useSelector((state) =>
      selectVariable(state, `usePartialSearchBy${resource}`)
    ) ??
      resourceToSearchInfoMap[resource]?.defaultUsePartialSearch) ||
    false;

  const checkedRows = tableSelect[resource] || {};

  useInterval(
    () => {
      if (!resource) {
        return;
      }

      if (!CasmCookies.isTokenValid()) {
        return;
      }

      if (
        [
          REMOTE_WORKSTATIONS,
          ENROLLMENT_COMMANDS,
          POOL_MACHINES,
          CONNECTORS,
          MONITOR_ENROLLMENTS,
          SESSION_AUDIT,
        ].includes(resource)
      ) {
        dispatch(
          fetchTableData(
            resource,
            page,
            rowsPerPage,
            filterParams,
            searchTerm,
            usePartialSearch
          )
        );
      }

      if (![AD_USERS, REMOTE_WORKSTATIONS, SESSION_AUDIT].includes(resource)) {
        dispatch(updateTableData(resource, page, rowsPerPage, filterParams));
      }
    },
    (Math.random() * 30 + 30) * 1000
  );

  useEffect(() => {
    if (
      selectedDeployment.deploymentId &&
      !isFetching &&
      ![ACTIVITY_LOGS, REMOTE_WORKSTATIONS, AD_USERS].includes(resource)
    ) {
      dispatch(
        fetchTableData(
          resource,
          0,
          rowsPerPage,
          filterParams,
          searchTerm,
          usePartialSearch
        )
      );
    }
  }, [selectedDeployment.deploymentId]);

  const mapDataCheckedRows = () => {
    // Get the machineID of the outdated checkedRows
    const checkedKeys = Object.keys(checkedRows);

    const selectedRowsUpdated = data.filter((t) =>
      checkedKeys.includes(t.machineId)
    );

    // Return selected machines with updated data
    return selectedRowsUpdated;
  };

  const refreshCheckedRows = () => {
    const selectedRowsUpdated = mapDataCheckedRows();
    // Select machines with updated data
    selectedRowsUpdated.forEach((row, index) => {
      dispatch(uncheckItem(resource, row));
      dispatch(checkItem(resource, row));
      setLastChecked(index);
    });
  };

  useEffect(() => {
    if (
      !loadingData &&
      data.length &&
      [REMOTE_WORKSTATIONS, POOL_MACHINES].includes(resource)
    ) {
      refreshCheckedRows();
    }
  }, [loadingData]);

  const handleChangePage = (_, newPage) => {
    dispatch(uncheckAll(resource));
    dispatch(
      fetchTableData(
        resource,
        newPage,
        rowsPerPage,
        filterParams,
        searchTerm,
        usePartialSearch
      )
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(uncheckAll(resource));
    const newRowsPerPage = event.target.value;
    dispatch(
      fetchTableData(
        resource,
        0,
        newRowsPerPage,
        filterParams,
        searchTerm,
        usePartialSearch
      )
    );
  };

  const handleCheckbox = (row, checked, event, rowNumber) => {
    if (event.nativeEvent.shiftKey) {
      const start = Math.min(lastChecked, rowNumber);
      const end = Math.max(lastChecked, rowNumber);

      data
        .slice(start, end)
        .forEach((item) => dispatch(checkItem(resource, item)));
    }
    if (checked) {
      dispatch(checkItem(resource, row));
      setLastChecked(rowNumber);
    } else {
      dispatch(uncheckItem(resource, row));
    }
  };

  const handleCheckAll = (event, checked) => {
    if (checked) {
      dispatch(
        checkItems(
          resource,
          data.filter((item) => !['deleting', 'deleted'].includes(item.status))
        )
      );
    } else {
      dispatch(uncheckAll(resource));
    }
  };

  const idField = mapResourceToIdField(resource);

  const mapResourceToActions = () => {
    switch (resource) {
      case CONNECTORS:
        return ConnectorsActions;
      case REMOTE_WORKSTATIONS:
        return RemoteWorkstationActions;
      case AD_USERS:
        return AdUsersActions;
      case POOLS:
        return PoolsActions;
      case POOL_MACHINES:
        return PoolMachinesActions;
      case POOL_GROUPS:
        return PoolGroupsActions;
      case WEBHOOKS:
        return WebhookActions;
      default:
        return null;
    }
  };

  const Actions = mapResourceToActions();

  const openKebabMenu = (event, item) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setKebabItem(item);
  };

  const numberChecked = Object.keys(checkedRows).length;
  const allChecked = numberChecked === data.length && data.length > 0;
  const indeterminateChecked = numberChecked > 0 && numberChecked < data.length;

  const getEnrollmentTagColor = (statusMessage) => {
    if (statusMessage === ENROLLMENT_STATUS_MESSAGE_WORKSTATION_NOT_FOUND) {
      return 'warning';
    }
    return 'success';
  };

  const getEnrollmentTagDisplayText = (statusMessage, matchedMachineName) => {
    if (statusMessage === ENROLLMENT_STATUS_MESSAGE_WORKSTATION_NOT_FOUND) {
      return 'updating';
    }
    if (matchedMachineName) {
      return 'LINKING';
    }
    return 'ADDING';
  };

  const displayField = (row, header) => {
    const {type, id} = header;

    const field = row[id];

    const isDeleting = deletingResource[idField] || row.status === 'deleting';
    const isAcceptingEnrollment =
      row.status === ENROLLMENT_STATUS_WAITING_WORKER_ACCEPT;

    switch (type) {
      case 'array':
        return field.join(', ');
      case 'date':
        return formatDateTime(field);
      case 'boolean':
      case 'bool':
        return field ? 'true' : 'false';
      case 'link':
      case 'name-link': {
        const mappedFieldToLink = mapFieldToLink(resource, id, row);
        const isLinkInvalid = !mappedFieldToLink || mappedFieldToLink === '-';
        return (
          <Grid
            container
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              {isDeleting || isLinkInvalid ? (
                <span className={classes.deletingElement}>{field}</span>
              ) : (
                <Link
                  to={mapFieldToLinkPath(resource, id, row)}
                  className={classes.link}
                >
                  {field}
                </Link>
              )}
            </Grid>
            {
              // connector show warning to see if setting is in sync with beta
              row.warning && resource === CONNECTORS && config.isBeta() && (
                <Tooltip
                  placement="right"
                  title={
                    <div style={{whiteSpace: 'pre-line'}}>{row.warning}</div>
                  }
                >
                  <Grid item>
                    <WarningIcon
                      data-testid={`${row[idField]}-warning`}
                      className={classes.warningIcon}
                    />
                  </Grid>
                </Tooltip>
              )
            }
            {isDeleting && (
              <Grid item>
                <Tag color="error" displayText="DELETING" small />
              </Grid>
            )}
            {isAcceptingEnrollment && (
              <Grid item>
                <Tag
                  color={getEnrollmentTagColor(row.statusMessage)}
                  displayText={getEnrollmentTagDisplayText(
                    row.statusMessage,
                    row.matchedMachineName
                  )}
                  small
                />
              </Grid>
            )}
          </Grid>
        );
      }
      case 'name-status':
        return (
          <Grid
            container
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              {/* if resource is monitor enrollments should be able to show status messages */}
              {resource === MONITOR_ENROLLMENTS && (
                <span>
                  {field}
                  {row.statusMessage && (
                    <CAMTooltip
                      text={row.statusMessage}
                      isWarning
                      testIdPrefix={`${row[idField]}-status-icon`}
                    />
                  )}
                </span>
              )}
            </Grid>
          </Grid>
        );
      case 'cert-expiry':
        return <CertExpiryStatus connector={row} />;
      case 'connector-status':
        return <ConnectorStatus connector={row} />;
      case 'connector-health':
        return <ConnectorHealth connector={row} />;
      case 'object':
        return JSON.stringify(field);
      case 'node':
        return field;
      case 'icon':
        return (
          <>
            {resource === REMOTE_WORKSTATIONS && (
              <StatusIconWorkstation
                workstation={row}
                testIdPrefix={`${row[idField]}-status`}
              />
            )}
          </>
        );
      case 'session-status':
        return <SessionStatus sessionStat={row} />;
      case 'enrollment-button':
        return <MonitorEnrollmentsButtons enrollment={row} />;
      case 'command-button':
        return <EnrollmentCommandsButtons enrollmentKeyId={field} />;
      case 'iconDelete':
        return <EnrollmentDeleteCommand command={row} />;
      case 'tlsToggle':
        return <EnrollmentTLSToggle enrollment={row} />;
      case 'webhook-status':
        return <WebhookStatus connector={row} webhook={row} />;
      case 'config-service-status':
        return <ConfigServiceStatus connector={row} />;
      default:
        return field;
    }
  };

  const renderNormalTable = () => (
    <NormalTable
      data={data}
      resource={resource}
      displayField={displayField}
      hideActions={hideActions}
      hideBulkActions={hideBulkActions}
      handleCheckbox={handleCheckbox}
      handleCheckAll={handleCheckAll}
      onClickKebab={openKebabMenu}
      checkedRows={checkedRows}
      allChecked={allChecked}
      indeterminateChecked={indeterminateChecked}
      useExpansionTable={useExpansionTable}
      renderCustomDrawer={expansionTableDrawer}
      noDataMessage={!loadingData ? noDataMessage : ''}
    />
  );

  const renderExpansionTable = () => (
    <ExpansionTable
      data={data}
      resource={resource}
      displayField={displayField}
      hideActions={hideActions}
      hideBulkActions={hideBulkActions}
      onClickKebab={openKebabMenu}
      checkedRows={checkedRows}
      handleCheckbox={handleCheckbox}
      handleCheckAll={handleCheckAll}
      allChecked={allChecked}
      indeterminateChecked={indeterminateChecked}
      renderCustomDrawer={expansionTableDrawer}
    />
  );

  const isEnrollmentCommand = resource === ENROLLMENT_COMMANDS;
  const rootClass = isEnrollmentCommand ? classes.rootCommand : classes.root;

  return (
    <StyledPaper className={rootClass} elevation={0}>
      {!hideTitle && (
        <TableTitle
          tableTitle={mapResourceToTableTitle(resource)}
          hideAddButton={hideAddButton}
          showDownloadButton={showDownloadButton}
          onAdd={onAdd}
          resource={resource}
          showEnrollmentsButton={showEnrollmentsButton}
          subtitle={mapResourceToSubtitle(resource)}
        />
      )}
      <TableToolbar
        items={checkedRows}
        resource={resource}
        hideBulkActions={hideBulkActions}
        hideConfigureTable={hideConfigureTable}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        hideSearch={hideSearch}
      />
      <div className={classes.wrapper}>
        <div className={classes.tableWrapper}>
          {isDisplaySmall ? renderExpansionTable() : renderNormalTable()}
        </div>
        {loadingData && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.loadingTableBody}
          >
            <Grid item>Loading data...</Grid>
            <Grid item className={classes.linearProgress}>
              <LinearProgress />
            </Grid>
          </Grid>
        )}
      </div>
      {!hideActions && (
        <CAMMenu
          anchorEl={anchorEl}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 10,
          }}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <Actions item={kebabItem} closeMenu={() => setAnchorEl(null)} />
        </CAMMenu>
      )}
      <TablePagination
        data-testid="pagination"
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        // Use server-side pagination if there is data, but no total for Adusers
        count={
          resource === AD_USERS && data.length > 0 && total === 0 ? -1 : total
        }
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        classes={{
          caption: classes.pagination,
          select: classes.rowsPerPage,
          selectIcon: classes.rowsPerPageIcon,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledPaper>
  );
}

UnwrappedCAMTable.propTypes = {
  resource: PropTypes.string,
  data: PropTypes.array,
  total: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  loadingData: PropTypes.bool,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  hideAddButton: PropTypes.bool,
  hideActions: PropTypes.bool,
  hideBulkActions: PropTypes.bool,
  hideConfigureTable: PropTypes.bool,
  hideTitle: PropTypes.bool,
  useExpansionTable: PropTypes.bool,
  tableSelect: PropTypes.object,
  selectedDeployment: PropTypes.object,
  showDownloadButton: PropTypes.bool,
  expansionTableDrawer: PropTypes.func,
  noDataMessage: PropTypes.string,
  showEnrollmentsButton: PropTypes.bool,
  rowsPerPageOptions: PropTypes.array,
  hideSearch: PropTypes.bool,
};

UnwrappedCAMTable.defaultProps = {
  resource: '',
  data: [],
  total: 0,
  onAdd: () => {},
  loadingData: false,
  hideAddButton: false,
  hideActions: false,
  hideBulkActions: false,
  hideConfigureTable: true,
  hideTitle: false,
  useExpansionTable: false,
  tableSelect: {},
  selectedDeployment: {},
  showDownloadButton: false,
  expansionTableDrawer: undefined,
  noDataMessage: 'No data available',
  showEnrollmentsButton: false,
  rowsPerPageOptions: [15, 25, 50, 200],
  hideSearch: false,
};

function mapStateToProps(state, props) {
  const {resource} = props;

  const {tableSelect, tableData, data} = state;

  const {page, rowsPerPage} = tableData[resource] ||
    DEFAULT_RESOURCE_ROWS_PER_PAGE[resource] || {
      page: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    };

  const {dataByResource} = data || {dataByResource: {}};
  const {selectedDeployment} = dataByResource;

  return {
    tableSelect,
    page,
    rowsPerPage,
    selectedDeployment,
  };
}

export default connect(mapStateToProps)(UnwrappedCAMTable);
