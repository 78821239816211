import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Tag from 'components/CAM/display/Tag/Tag';
import ProviderIcon from 'components/CAM/icons/ProviderIcon/ProviderIcon';
import CAMTable from 'components/table/Table';
import {capitalize} from 'helpers/core';
import {
  AMT,
  EDIT_REMOTE_WORKSTATION_LINK,
  POOL_MACHINES,
} from 'utils/constants';
import {
  mapAmtPowerStateToHumanReadable,
  mapCamelCaseToDisplay,
  mapProviders,
  mapRemoteWorkstationStatus,
} from 'utils/Mappings';
import {
  formatDateTime,
  getAmtWorkstationPowerState,
  isEmpty,
  linkTo,
} from 'utils/utils';

function PoolMachines({poolMachines, poolMachineUsers, isFetching, total}) {
  const isMachineProvisioningStateValid = (machine) =>
    machine.managed && typeof machine?.provisioningStatus?.state === 'string';

  const getMachineProvisioningState = (machine) =>
    isMachineProvisioningStateValid(machine)
      ? capitalize(machine.provisioningStatus.state)
      : '';

  const usersHash = {};
  if (!isEmpty(poolMachineUsers)) {
    poolMachineUsers.forEach((user) => {
      usersHash[user.userGuid] = user.name;
    });
  }

  const renderAvailableTag = () => (
    <Tag displayText="AVAILABLE" small color="success" />
  );

  const renderLoadingTag = () => <Tag displayText="LOADING" small />;

  const getSessionLastChangedDate = (poolMachine) =>
    poolMachine.pcoipSessionStateLastChangedOn
      ? formatDateTime(poolMachine.pcoipSessionStateLastChangedOn)
      : 'Unknown';

  const getSessionState = (poolMachine) =>
    poolMachine.pcoipSessionState
      ? mapCamelCaseToDisplay(poolMachine.pcoipSessionState)
      : 'Unknown';

  const prepareData = (data) => {
    if (data && data instanceof Array) {
      const newData = [];
      data.forEach((machine) => {
        let newMachine = machine;
        if (machine.provider === AMT) {
          newMachine = getAmtWorkstationPowerState(machine);
          newMachine.powerState = mapAmtPowerStateToHumanReadable(
            machine.powerState
          );
        }
        newData.push(newMachine);
      });

      return newData.map((machine) => ({
        ...machine,
        error: machine.managed ? machine.error : null,
        link: linkTo(`${EDIT_REMOTE_WORKSTATION_LINK}/${machine.machineId}`),
        displayManaged: machine.managed ? 'Yes' : 'No',
        displayProvider: (
          <Grid container alignItems="center">
            <ProviderIcon provider={machine.provider} />
            {mapProviders(machine.provider)}
          </Grid>
        ),
        provider: mapProviders(machine.provider),
        powerStatus: mapRemoteWorkstationStatus(
          machine.powerState,
          machine.provisioningStatus,
          machine.managed
        ),
        powerState:
          typeof machine.powerState === 'string'
            ? capitalize(machine.powerState)
            : '',
        provisioningState: getMachineProvisioningState(machine),
        displayStatus: capitalize(machine.status || 'active'),
        currentUser: isFetching
          ? renderLoadingTag()
          : usersHash[machine.assignedTo] || renderAvailableTag(),
        pcoipSessionState: getSessionState(machine),
        pcoipSessionStateLastChangedOn: getSessionLastChangedDate(machine),
      }));
    }
    return [];
  };

  return (
    <CAMTable
      id="pool-machines-table"
      tableTitle="Remote Workstations"
      resource={POOL_MACHINES}
      idField="machineId"
      data={prepareData(poolMachines)}
      total={total}
      loadingData={isFetching}
      hideAddButton
      hideTitle
    />
  );
}

PoolMachines.propTypes = {
  poolMachines: PropTypes.array,
  poolMachineUsers: PropTypes.array,
  isFetching: PropTypes.bool,
  total: PropTypes.number,
};

PoolMachines.defaultProps = {
  poolMachines: [],
  poolMachineUsers: [],
  isFetching: false,
  total: 0,
};

export default PoolMachines;
