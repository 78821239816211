import {del} from 'api/Api';
import {doSafeBulkRequest} from 'utils/apiUtils';
import {POOLS} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {addPollingItem} from './pollingActions';
import {enqueueSnackbar} from './snackbarActions';

export const OPEN_DELETE_POOLS_DIALOG = 'OPEN_DELETE_POOLS_DIALOG';

export function openDeletePoolsDialog({pools}) {
  return {
    type: OPEN_DELETE_POOLS_DIALOG,
    pools,
  };
}

export const CLOSE_DELETE_POOLS_DIALOG = 'CLOSE_DELETE_POOLS_DIALOG';

export function closeDeletePoolsDialog() {
  return {
    type: CLOSE_DELETE_POOLS_DIALOG,
  };
}

export const SET_REMOVE_FROM_CAM = 'SET_REMOVE_FROM_CAM';

export function setRemoveFromCam({shouldRemoveFromCam}) {
  return {
    type: SET_REMOVE_FROM_CAM,
    shouldRemoveFromCam,
  };
}

export const SET_REMOVE_FROM_PROVIDER = 'SET_REMOVE_FROM_PROVIDER';

export function setRemoveFromProvider({shouldRemoveFromProvider}) {
  return {
    type: SET_REMOVE_FROM_PROVIDER,
    shouldRemoveFromProvider,
  };
}

export const SET_IS_DELETING = 'SET_IS_DELETING';

function setIsDeleting({isDeleting}) {
  return {
    type: SET_IS_DELETING,
    isDeleting,
  };
}
export function deletePools() {
  return async (dispatch, getState) => {
    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);
    const {pools, shouldRemoveFromCam, shouldRemoveFromProvider} =
      state.deletePoolsDialog;

    dispatch(setIsDeleting({isDeleting: true}));

    const buildDeletePoolRequest = (pool) => {
      const basePath = mapResourceToPath(POOLS, {deploymentId});
      const path = `${basePath}${pool.poolId}`;
      const data = {
        deleteMachines: shouldRemoveFromCam,
        deleteFromProvider: shouldRemoveFromProvider,
      };
      return {path, data};
    };

    const responses = await doSafeBulkRequest(
      del,
      pools,
      buildDeletePoolRequest
    );
    const failedResponses = responses.filter(
      (response) => response.status !== 'success'
    );

    if (!failedResponses.length) {
      dispatch(
        enqueueSnackbar({
          message:
            responses.length > 1
              ? 'Selected pools are being deleted.'
              : 'Selected pool is being deleted.',
          options: {
            variant: 'success',
          },
        })
      );
    } else {
      dispatch(
        enqueueSnackbar({
          message: 'An error occurred while deleting the selected pools.',
          options: {
            variant: 'error',
          },
        })
      );
    }

    responses.forEach((response, index) => {
      if (response.status === 'success') {
        const responsePool = pools[index] || {};
        dispatch(
          addPollingItem({
            endStates: ['error'],
            key: `${POOLS}-${responsePool.poolId}-delete`,
            path: `${mapResourceToPath(POOLS, {deploymentId})}${
              responsePool.poolId
            }`,
            property: 'status',
            resourceId: responsePool.poolId,
            resourceType: POOLS,
          })
        );
      }
    });

    dispatch(setIsDeleting({isDeleting: false}));
    dispatch(closeDeletePoolsDialog());
  };
}

export const REMOVE_POLLING_POOL_DELETION = 'REMOVE_POLLING_POOL_DELETION';

export function removePollingPoolDeletion({poolId}) {
  return {
    type: REMOVE_POLLING_POOL_DELETION,
    poolId,
  };
}
