import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {del, post} from 'api/Api';
import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import config from 'config';
import useDialog from 'hooks/useDialog';
import useSnackbar from 'hooks/useSnackbar';
import {openDeleteConnectorsDialog} from 'redux/actions/DeleteConnectorsDialogActions';
import {openDeletePoolsDialog} from 'redux/actions/DeletePoolsDialogActions';
import {openDeleteWebhooksDialog} from 'redux/actions/DeleteWebhooksDialogActions';
import {openBulkActionsDialog} from 'redux/actions/bulkActionsDialogActions';
import {openBulkDeleteWorkstationsDialog} from 'redux/actions/bulkDeleteRemoteWorkstationsDialogActions';
import {
  receiveDeleteResource,
  requestDeleteResource,
  saveVariable,
  updatePoolWorkstation,
  updateWorkstation,
} from 'redux/actions/dataActions';
import {
  pollForPoolMachineRunning,
  pollForPoolMachineStopped,
  pollForWorkstationRunning,
  pollForWorkstationStopped,
} from 'redux/actions/remoteWorkstationActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {uncheckAll} from 'redux/actions/tableSelectActions';
import {
  mapResourceToBulkDeleteDialogMessage,
  mapResourceToBulkDeleteDialogTitle,
  mapResourceToIdField,
  mapResourceToPath,
  mapResourceToText,
} from 'utils/Mappings';
import {
  ACCEPT_ENROLLMENTS,
  AD_USERS,
  CLOUD_SERVICE_ACCOUNTS,
  CONNECTORS,
  ENROLLMENT_ACCEPT,
  ENROLLMENT_REJECT,
  ENROLLMENT_STATUS_MESSAGE_WORKSTATION_NOT_FOUND,
  ENTITLEMENTS,
  MONITOR_ENROLLMENTS,
  MONITOR_IN_SESSION,
  MONITOR_TELEMETRY_LATEST,
  POOLS,
  POOL_GROUPS,
  POOL_MACHINES,
  POOL_USERS,
  WEBHOOKS,
  REJECT_ENROLLMENTS,
  REMOTE_WORKSTATIONS,
  RESTART,
  START,
  STOP,
  TABLE_CELL_TEXT,
  UPDATE_ENROLLMENTS,
  WORKSTATION_POWERSTATE_RESTARTING,
  WORKSTATION_POWERSTATE_STARTING,
  WORKSTATION_POWERSTATE_STOPPING,
  dataLossWarningMsg,
} from 'utils/constants';
import {
  selectData,
  selectDataForBulkActions,
  selectResource,
  selectSelectedDeployment,
  selectSelectedPool,
  selectVariable,
} from 'utils/reduxSelectors';
import {
  canPerformPowerAction,
  capitalizeFirstLetter,
  enrollmentToActionType,
  getItemName,
  getMultipleEnrollmentSnackbarMessage,
  getMultipleWorkstationSnackbarMessage,
  getSingleEnrollmentSnackbarMessage,
  getSingleWorkstationSnackbarMessage,
  isLatestMonitorTelemetryValid,
} from 'utils/utils';

const entitleUsersDialogFields = [
  {
    id: 'name',
    text: 'Name',
    type: TABLE_CELL_TEXT,
    sortable: true,
  },
  {
    id: 'userName',
    text: 'User Name',
    type: TABLE_CELL_TEXT,
    sortable: true,
  },
];

const entitleWorkstationsDialogFields = [
  {
    id: 'machineName',
    text: 'Name',
    type: TABLE_CELL_TEXT,
    sortable: true,
  },
  {
    id: 'status',
    text: 'Status',
    type: TABLE_CELL_TEXT,
    sortable: false,
  },
  {
    id: 'location',
    text: 'Location',
    type: TABLE_CELL_TEXT,
    sortable: false,
  },
];

function BulkActions({resource, handleClose}) {
  const dispatch = useDispatch();

  const {triggerDialog} = useDialog();

  const {successSnackbar, errorSnackbar} = useSnackbar();
  const {page, rowsPerPage} = useSelector((state) => state.tableData[resource]);
  const items = useSelector((state) =>
    Object.values(state.tableSelect[resource] || {})
  );
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {data: dialogAdUsers, isFetching: dialogIsFetchingAdUsers} =
    useSelector((state) => selectDataForBulkActions(state, AD_USERS));

  const {data: dialogMachines, isFetching: dialogIsFetchingMachines} =
    useSelector((state) =>
      selectDataForBulkActions(state, REMOTE_WORKSTATIONS)
    );
  const {data: entitlements, isFetching: fetchingEntitlements} = useSelector(
    (state) => selectData(state, ENTITLEMENTS)
  );
  const {poolId} = useSelector((state) => selectSelectedPool(state));

  const {data: monitorTelemetry, isFetching: isFetchingMonitorTelemetry} =
    useSelector((state) => selectResource(state, MONITOR_TELEMETRY_LATEST));

  const getDeletionText = () =>
    [POOL_MACHINES, POOL_USERS, POOL_GROUPS].includes(resource)
      ? 'Remove from pool'
      : 'Delete';

  const {data: cloudServiceAccounts} = useSelector((state) =>
    selectData(state, CLOUD_SERVICE_ACCOUNTS)
  ) || {data: []};

  const isDoingBulkEnrollmentAction = useSelector(
    (state) => selectVariable(state, 'isDoingBulkEnrollmentAction') || false
  );

  const canDeleteItem = (item) =>
    !['deleting', 'deleted'].includes(item.status);

  const deleteItem = async (item) => {
    const itemId = item[mapResourceToIdField(resource)];
    const path = mapResourceToPath(resource, {deploymentId, poolId});
    await del(
      resource === POOL_MACHINES
        ? {path, data: {machineId: itemId}}
        : {path: `${path}${itemId}`}
    );
  };

  const bulkDelete = async () => {
    const promises = [];

    items.forEach((item) => {
      if (canDeleteItem(item)) {
        dispatch(
          requestDeleteResource(resource, item[mapResourceToIdField(resource)])
        );
        const promise = deleteItem(item)
          .then(() => {})
          .catch(() => item);
        promises.push(promise);
        dispatch(
          receiveDeleteResource(resource, item[mapResourceToIdField(resource)])
        );
      }
    });

    return Promise.all(promises);
  };

  const handleDeleteWorkstations = () => {
    dispatch(openBulkDeleteWorkstationsDialog(items));
  };

  const handleConfirmBulkDelete = async () => {
    const failed = (await bulkDelete()).filter((item) => Boolean(item));
    if (failed.length > 0) {
      const itemNames = failed
        .map((item) => getItemName(resource, item))
        .join(', ');
      triggerDialog({
        title: 'Error',
        message: `An error occurred while deleting the following ${mapResourceToText(
          resource
        )}s: ${itemNames}`,
      });
    }
    dispatch(uncheckAll(resource));
    dispatch(fetchTableData(resource, page, rowsPerPage));
  };

  const handleDeletePools = () =>
    dispatch(openDeletePoolsDialog({pools: items}));

  const handleDeleteConnectors = () =>
    dispatch(openDeleteConnectorsDialog({connectors: items}));

  const handleDeleteWebhooks = () =>
    dispatch(openDeleteWebhooksDialog({webhooks: items}));

  const handleBulkDeleteButtonClick = () => {
    if (resource === REMOTE_WORKSTATIONS) {
      handleDeleteWorkstations();
      return;
    }
    if (resource === POOLS) {
      handleDeletePools();
      return;
    }
    if (resource === CONNECTORS) {
      handleDeleteConnectors();
      return;
    }

    if (resource === WEBHOOKS) {
      handleDeleteWebhooks();
      return;
    }

    handleClose();
    triggerDialog({
      title: mapResourceToBulkDeleteDialogTitle(resource, items),
      message: mapResourceToBulkDeleteDialogMessage(resource, items),
      onConfirm: handleConfirmBulkDelete,
    });
  };

  // Power Actions

  const doPowerAction = async (workstation, action) => {
    const path = `${mapResourceToPath(REMOTE_WORKSTATIONS)}${
      workstation.machineId
    }/${action}`;
    return post({path});
  };

  const doEnrollmentAction = (enrollmentId, actionType) => {
    const path = `${mapResourceToPath(actionType, {
      deploymentId,
      enrollmentId,
    })}`;
    return post({path});
  };

  // Perform the specified power action
  // Save successful and failed requests and return them
  const bulkPowerAction = async (action) => {
    const promises = [];
    const result = {
      success: [],
      failed: [],
    };

    items.forEach((workstation) => {
      if (canPerformPowerAction(workstation, cloudServiceAccounts, action)) {
        const promise = doPowerAction(workstation, action)
          .then(() => result.success.push(workstation))
          .catch(() => result.failed.push(workstation));
        promises.push(promise);
      }
    });

    await Promise.all(promises);

    return result;
  };

  // Perform the specified enrollment action
  // Save successful and failed requests and return them
  const bulkActionEnrollments = async (actionType) => {
    let action = actionType;
    const result = {
      success: [],
      failed: [],
    };

    dispatch(saveVariable('isDoingBulkEnrollmentAction', true));

    const promises = items.map((enrollment) => {
      const {enrollmentId} = enrollment;
      // Check if it should update or acceptEnrollment
      if (actionType !== REJECT_ENROLLMENTS) {
        action =
          enrollment.statusMessage ===
          ENROLLMENT_STATUS_MESSAGE_WORKSTATION_NOT_FOUND
            ? UPDATE_ENROLLMENTS
            : ACCEPT_ENROLLMENTS;
      }
      return doEnrollmentAction(enrollmentId, action)
        .then(() => result.success.push(enrollment))
        .catch(() => result.failed.push(enrollment));
    });

    await Promise.all(promises);
    dispatch(saveVariable('isDoingBulkEnrollmentAction', false));
    return result;
  };

  const showSnackbarSuccessMessage = (message) => {
    successSnackbar(message);
  };

  const handleBulkStartWorkstations = async () => {
    handleClose();
    const {failed, success} = await bulkPowerAction(START);
    if (failed.length > 0) {
      const itemNames = failed
        .map((item) => getItemName(resource, item))
        .join(', ');
      triggerDialog({
        title: 'Error',
        message: `An error occurred while starting the following workstations: ${itemNames}`,
      });
    }

    if (success.length > 0) {
      const isSingleWorkstationOperation =
        success.length === 1 && items.length === 1;
      const snackbarMessage = isSingleWorkstationOperation
        ? getSingleWorkstationSnackbarMessage('starting', items[0].machineName)
        : getMultipleWorkstationSnackbarMessage('Starting', success.length);
      showSnackbarSuccessMessage(snackbarMessage);
    }

    success.forEach((workstation) => {
      if (resource === REMOTE_WORKSTATIONS) {
        dispatch(
          updateWorkstation({
            ...workstation,
            powerState: WORKSTATION_POWERSTATE_STARTING,
          })
        );
        dispatch(pollForWorkstationRunning(workstation));
      } else {
        dispatch(
          updatePoolWorkstation({
            ...workstation,
            powerState: WORKSTATION_POWERSTATE_STARTING,
          })
        );
        dispatch(pollForPoolMachineRunning(workstation));
      }
    });
  };

  const handleBulkStopWorkstations = async () => {
    const {failed, success} = await bulkPowerAction(STOP);
    if (failed.length > 0) {
      const itemNames = failed
        .map((item) => getItemName(resource, item))
        .join(', ');
      triggerDialog({
        title: 'Error',
        message: `An error occurred while stopping the following workstations: ${itemNames}`,
      });
    }

    if (success.length > 0) {
      const isSingleWorkstationOperation =
        success.length === 1 && items.length === 1;
      const snackbarMessage = isSingleWorkstationOperation
        ? getSingleWorkstationSnackbarMessage('stopping', items[0].machineName)
        : getMultipleWorkstationSnackbarMessage('Stopping', success.length);
      showSnackbarSuccessMessage(snackbarMessage);
    }

    success.forEach((workstation) => {
      if (resource === REMOTE_WORKSTATIONS) {
        dispatch(
          updateWorkstation({
            ...workstation,
            powerState: WORKSTATION_POWERSTATE_STOPPING,
          })
        );
        dispatch(pollForWorkstationStopped(workstation));
      } else {
        dispatch(
          updatePoolWorkstation({
            ...workstation,
            powerState: WORKSTATION_POWERSTATE_STOPPING,
          })
        );
        dispatch(pollForPoolMachineStopped(workstation));
      }
    });
  };

  const handleBulkStopWorkstationButtonClick = () => {
    handleClose();
    triggerDialog({
      title: 'Stop remote workstations?',
      message:
        'Are you sure you want to stop the selected remote workstations?',
      onConfirm: () => handleBulkStopWorkstations(),
      warningMessage: dataLossWarningMsg,
    });
  };

  const handleBulkRestartWorkstations = async () => {
    const {failed, success} = await bulkPowerAction(RESTART);
    if (failed.length > 0) {
      const itemNames = failed
        .map((item) => getItemName(resource, item))
        .join(', ');
      triggerDialog({
        title: 'Error',
        message: `An error occurred while restarting the following workstations: ${itemNames}`,
      });
    }

    if (success.length > 0) {
      const isSingleWorkstationOperation =
        success.length === 1 && items.length === 1;
      const snackbarMessage = isSingleWorkstationOperation
        ? getSingleWorkstationSnackbarMessage(
            'restarting',
            items[0].machineName
          )
        : getMultipleWorkstationSnackbarMessage('Restarting', success.length);
      showSnackbarSuccessMessage(snackbarMessage);
    }

    success.forEach((workstation) => {
      if (resource === REMOTE_WORKSTATIONS) {
        dispatch(
          updateWorkstation({
            ...workstation,
            powerState: WORKSTATION_POWERSTATE_RESTARTING,
          })
        );
        dispatch(pollForWorkstationRunning(workstation));
      } else {
        dispatch(
          updatePoolWorkstation({
            ...workstation,
            powerState: WORKSTATION_POWERSTATE_RESTARTING,
          })
        );
        dispatch(pollForPoolMachineRunning(workstation));
      }
    });
  };

  const handleBulkRestartWorkstationButtonClick = () => {
    handleClose();
    triggerDialog({
      title: 'Restart remote workstations?',
      message:
        'Are you sure you want to restart the selected remote workstations?',
      onConfirm: () => handleBulkRestartWorkstations(),
      warningMessage: dataLossWarningMsg,
    });
  };

  const enablePowerAction = (action) =>
    items.some((item) =>
      canPerformPowerAction(item, cloudServiceAccounts, action)
    );

  const enableDelete = () => items.some((item) => canDeleteItem(item));

  const bulkCreateEntitlements = (newItems) => async () => {
    const promises = [];

    // Sets lists of machines & users to correct values based on what page the bulk action came from
    let machineList;
    let userList;
    switch (resource) {
      case REMOTE_WORKSTATIONS:
        machineList = items;
        userList = newItems;
        break;
      case AD_USERS:
        machineList = newItems;
        userList = items;
        break;
      default:
        machineList = [];
        userList = [];
        break;
    }

    const result = {
      success: 0,
      failed: 0,
    };

    machineList.forEach((machine) => {
      userList.forEach((user) => {
        const entitlementPromise = async () => {
          try {
            await post({
              path: `deployments/${deploymentId}/entitlements`,
              data: {
                machineId: machine.machineId,
                userGuid: user.userGuid,
              },
            });
            result.success += 1;
          } catch (error) {
            if (error.code === 409) {
              result.success += 1;
              return;
            }
            result.failed += 1;
          }
        };
        promises.push(entitlementPromise());
      });
    });

    await Promise.all(promises);
    if (result.failed > 0) {
      errorSnackbar(
        `Failed to create ${result.failed} entitlement${
          result.failed > 1 ? 's' : ''
        }.`
      );
    } else if (result.success > 0) {
      successSnackbar(
        `Successfully created ${result.success} entitlement${
          result.success > 1 ? 's' : ''
        }.`
      );
    }
    dispatch(fetchTableData(resource, page, rowsPerPage));
  };

  const canLogoutItem = (item) =>
    isLatestMonitorTelemetryValid(monitorTelemetry[item.machineId]) &&
    monitorTelemetry[item.machineId]?.loggedInUsers.length > 0 &&
    monitorTelemetry[item.machineId]?.loggedInUsers
      .map((user) => user.status !== MONITOR_IN_SESSION)
      .includes(false);

  const enableLogout = () =>
    !isFetchingMonitorTelemetry && items.some((item) => canLogoutItem(item));

  const handleBulkLogoutClick = async () => {
    const ableToLogoutWorkstations = items.filter((item) =>
      canLogoutItem(item)
    );
    handleClose();
    dispatch(
      saveVariable('monitorLogoutInfo', {
        isLogoutDialogOpen: true,
        logoutWorkstations: ableToLogoutWorkstations,
      })
    );
  };

  const handleBulkEntitleUsersClick = async () => {
    handleClose();
    const machineIds = items.map((machine) => machine.machineId);
    // Get the list of users which are entitled to all selected workstations
    const entitledUsers = dialogAdUsers.filter((user) => {
      const machineEntitlements = entitlements.filter((entitlement) =>
        machineIds.includes(entitlement.resourceId)
      );
      const userEntitlements = machineEntitlements.filter(
        (entitlement) => entitlement.userGuid === user.userGuid
      );
      if (items.length === userEntitlements.length) {
        return true;
      }
      return false;
    });

    dispatch(
      openBulkActionsDialog({
        bulkAction: bulkCreateEntitlements,
        defaultParams: {limit: 15, offset: 0, deploymentId},
        existingItems: entitledUsers,
        resource: AD_USERS,
        searchPlaceholder: 'Search by name',
        tableFields: entitleUsersDialogFields,
        title: 'Entitle Users',
      })
    );
  };

  const handleBulkEntitleToMachineClick = () => {
    handleClose();
    const userGuids = items.map((user) => user.userGuid);
    // Get the list of workstations which all selected users are entitled to
    const entitledMachines = dialogMachines.filter((machine) => {
      const userEntitlements = entitlements.filter((entitlement) =>
        userGuids.includes(entitlement.userGuid)
      );
      const machineEntitlements = userEntitlements.filter(
        (entitlement) => entitlement.resourceId === machine.machineId
      );
      if (items.length === machineEntitlements.length) {
        return true;
      }
      return false;
    });

    dispatch(
      openBulkActionsDialog({
        bulkAction: bulkCreateEntitlements,
        defaultParams: {limit: 15, offset: 0, deploymentId},
        existingItems: entitledMachines,
        resource: REMOTE_WORKSTATIONS,
        searchPlaceholder: 'Search by machine name',
        tableFields: entitleWorkstationsDialogFields,
        title: 'Entitle Remote Workstations',
      })
    );
  };

  const handleBulkActionEnrollmentsClick = async (actionType) => {
    handleClose();
    const {failed, success} = await bulkActionEnrollments(actionType);

    const actionString =
      actionType === ACCEPT_ENROLLMENTS ? ENROLLMENT_ACCEPT : ENROLLMENT_REJECT;

    if (failed.length > 0) {
      const itemNames = failed
        .map((item) => getItemName(resource, item))
        .join(', ');
      const numberOfEnrollments = failed.length;
      triggerDialog({
        title: 'Error',
        message: `An error occurred while executing the action to the following enrollment${
          numberOfEnrollments === 1 ? '' : 's'
        }: ${itemNames}`,
      });
    }

    if (success.length > 0) {
      const isSingleEnrollmentOperation =
        success.length === 1 && items.length === 1;
      const snackbarMessage = isSingleEnrollmentOperation
        ? getSingleEnrollmentSnackbarMessage(
            `${actionString}ed`,
            items[0].hostName
          )
        : getMultipleEnrollmentSnackbarMessage(
            `${capitalizeFirstLetter(actionString)}ed`,
            success.length
          );
      showSnackbarSuccessMessage(snackbarMessage);
    }
    dispatch(uncheckAll(resource));
    dispatch(fetchTableData(resource, page, rowsPerPage));
  };

  const handleBulkAcceptEnrollmentsClick = async () => {
    await handleBulkActionEnrollmentsClick(ACCEPT_ENROLLMENTS);
  };

  const handleBulkRejectEnrollmentsClick = async () => {
    await handleBulkActionEnrollmentsClick(REJECT_ENROLLMENTS);
  };

  const powerManagementActions = (
    <>
      <KebabMenuItem
        id="bulk-start-workstation"
        dataTestId="bulk-start-workstation"
        menuText="Start"
        onClick={handleBulkStartWorkstations}
        enableClick={enablePowerAction(START)}
      />
      <KebabMenuItem
        id="bulk-stop-workstation"
        dataTestId="bulk-stop-workstation"
        menuText="Stop"
        onClick={handleBulkStopWorkstationButtonClick}
        enableClick={enablePowerAction(STOP)}
      />
      <KebabMenuItem
        id="bulk-restart-workstation"
        dataTestId="bulk-restart-workstation"
        menuText="Restart"
        onClick={handleBulkRestartWorkstationButtonClick}
        enableClick={enablePowerAction(RESTART)}
      />
    </>
  );
  const deleteAction = (
    <KebabMenuItem
      id={`bulk-delete-${resource}`}
      dataTestId={`bulk-delete-${resource}`}
      menuText={getDeletionText()}
      onClick={handleBulkDeleteButtonClick}
      enableClick={enableDelete()}
    />
  );

  const bulkLogoutAction = (
    <KebabMenuItem
      id={`bulk-logout-${resource}`}
      dataTestId={`bulk-logout-${resource}`}
      menuText="Logout users"
      onClick={handleBulkLogoutClick}
      enableClick={enableLogout()}
    />
  );

  const renderBulkActions = () => {
    switch (resource) {
      case AD_USERS:
        return (
          <KebabMenuItem
            id="bulk-entitle-to-workstation"
            dataTestId="bulk-entitle-to-workstation"
            menuText="Entitle workstations"
            onClick={handleBulkEntitleToMachineClick}
            enableClick={!(fetchingEntitlements || dialogIsFetchingMachines)}
          />
        );
      case POOL_MACHINES:
        return (
          <>
            {powerManagementActions}
            {deleteAction}
          </>
        );
      case REMOTE_WORKSTATIONS:
        return (
          <>
            {powerManagementActions}
            <KebabMenuItem
              id="bulk-entitle-users"
              dataTestId="bulk-entitle-users"
              menuText="Entitle users"
              onClick={handleBulkEntitleUsersClick}
              enableClick={!(fetchingEntitlements || dialogIsFetchingAdUsers)}
            />
            {config.isMonitorEnabled() && bulkLogoutAction}
            {deleteAction}
          </>
        );
      case MONITOR_ENROLLMENTS:
        // Checked actions
        const actions = [];
        items.forEach((item) => {
          actions.push(enrollmentToActionType(item));
        });
        const uniqueActions = [...new Set(actions)];

        // Capitalize first letter of the first action
        if (uniqueActions.length > 0) {
          uniqueActions[0] =
            uniqueActions[0].charAt(0).toUpperCase() +
            uniqueActions[0].slice(1);
        }
        const actionMenuText = `${uniqueActions.join(
          '/'
        )} selected enrollments`;
        return (
          <>
            <KebabMenuItem
              id="bulk-accept-enrollments"
              dataTestId="bulk-accept-enrollments"
              menuText={actionMenuText}
              onClick={handleBulkAcceptEnrollmentsClick}
              enableClick={!isDoingBulkEnrollmentAction}
            />
            <KebabMenuItem
              id="bulk-reject-enrollments"
              dataTestId="bulk-reject-enrollments"
              menuText="Reject selected enrollments"
              onClick={handleBulkRejectEnrollmentsClick}
              enableClick={!isDoingBulkEnrollmentAction}
            />
          </>
        );
      default:
        return deleteAction;
    }
  };

  return <>{renderBulkActions()}</>;
}

BulkActions.propTypes = {
  resource: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
};

BulkActions.defaultProps = {
  handleClose: () => {},
};

export default BulkActions;
