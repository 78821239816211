import IconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Grid} from '@mui/material';
import {get} from 'api/Api';
import ProviderIcon from 'components/CAM/icons/ProviderIcon/ProviderIcon';
import AppBar from 'components/common/AppBar';
import CAMTable from 'components/table/Table';
import {handleApiError} from 'redux/actions/dataActions';
import {mapProviders} from 'utils/Mappings';
import {
  ENROLLMENT_STATUS_MESSAGE_WORKSTATION_NOT_FOUND,
  ENROLLMENT_STATUS_WAITING_WORKER_ACCEPT,
  MONITOR_ENROLLMENTS,
  WORKSTATIONS_LINK,
} from 'utils/constants';
import {selectDataForTable, selectVariable} from 'utils/reduxSelectors';
import {
  formatDateTime,
  isItemActive,
  truncateLabelAndAddSuffix,
} from 'utils/utils';

const PREFIX = 'MonitorEnrollments';

const classes = {
  createPageContainer: `${PREFIX}-createPageContainer`,
  displayName: `${PREFIX}-displayName`,
  networkInterface: `${PREFIX}-networkInterface`,
  flexGrowOne: `${PREFIX}-flexGrowOne`,
  appBar: `${PREFIX}-appBar`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.createPageContainer}`]: theme.createPage.createPageContainer,

  [`& .${classes.displayName}`]: {
    color: theme.palette.primary.main,
    fontSize: '1.125rem',
    fontWeight: 500,
    letterSpacing: '0.014375rem',
  },

  [`& .${classes.networkInterface}`]: {
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: '0.014375rem',
  },

  [`& .${classes.flexGrowOne}`]: {flexGrow: 1},

  [`&.${classes.appBar}`]: {
    margin: '0px 32px',
    backgroundColor: 'transparent',
  },
}));

function MonitorEnrollments() {
  const dispatch = useDispatch();

  const isDoingBulkEnrollmentAction = useSelector(
    (state) => selectVariable(state, 'isDoingBulkEnrollmentAction') || false
  );
  const doingSingleEnrollmentAction = useSelector(
    (state) => selectVariable(state, 'doingSingleEnrollmentAction') || false
  );

  const [machineList, setMachineList] = useState([]);
  const [fetchingMachineList, setFetchingMachineList] = useState(false);

  const titleText = 'Pending Monitor Provisions';
  const noMatchingMachinesMessage = 'No matching workstation found';
  const maxLimitInterfacesLabel = 50;

  const {
    data: monitorEnrollments,
    isFetching: fetchingMonitorEnrollments,
    total,
  } = useSelector((state) => selectDataForTable(state, MONITOR_ENROLLMENTS));

  useEffect(() => {
    const fetchData = async () => {
      if (monitorEnrollments) {
        const matchedMachineIds = monitorEnrollments
          .filter((enrollment) => enrollment.matchedMachineId)
          .map((enrollment) => enrollment.matchedMachineId);

        if (matchedMachineIds.length > 0) {
          const params = {
            limit: matchedMachineIds.length,
            machineId: matchedMachineIds.join(','),
          };

          setFetchingMachineList(true);
          try {
            const {data} = await get({path: 'machines', params});
            setMachineList(data);
          } catch (error) {
            dispatch(handleApiError(error));
          }
          setFetchingMachineList(false);
        }
      }
    };

    fetchData();
  }, [fetchingMonitorEnrollments, JSON.stringify(monitorEnrollments)]);

  const renderBackButton = () => (
    <IconButton
      component={Link}
      to={WORKSTATIONS_LINK}
      data-testid="pending-monitor-provisions-appbar-back-button"
      size="large"
    >
      <KeyboardArrowLeft />
    </IconButton>
  );

  const renderAppBar = () => (
    <div className={classes.appBar}>
      <AppBar
        loading={
          fetchingMonitorEnrollments ||
          isDoingBulkEnrollmentAction ||
          doingSingleEnrollmentAction
        }
      >
        {renderBackButton()}
        <div className={classes.displayName}>{titleText}</div>
        <div className={classes.flexGrowOne} />
      </AppBar>
    </div>
  );

  const prepareProvider = (provider) => (
    <Grid container direction="row" alignItems="center">
      <ProviderIcon provider={provider} />
      {mapProviders(provider)}
    </Grid>
  );

  const getEnrollmentDetailsKeyValue = (data, key) => data[key];

  const prepareWorkstationLink = (enrollment) => {
    const {matchedMachineId} = enrollment;
    if (isItemActive(enrollment)) {
      return `/app/remoteWorkstations/edit/${matchedMachineId}`;
    }
    return '';
  };

  const prepareIpAddresses = (networkInterfaces) => {
    const interfacesLabel = networkInterfaces.map(({ipv4}) => ipv4).join(', ');
    return truncateLabelAndAddSuffix(interfacesLabel, maxLimitInterfacesLabel);
  };

  const machineExists = (matchedMachineId) =>
    machineList.some((e) => e.machineId === matchedMachineId);

  // Prepare an enrollment object
  const prepareEnrollment = (enrollment) => {
    const networkInterfaces = getEnrollmentDetailsKeyValue(
      enrollment.enrollmentDetails,
      'networkInterfaces'
    );
    const hasMatchingMachine = machineExists(enrollment.matchedMachineId);
    const hostName = getEnrollmentDetailsKeyValue(
      enrollment.enrollmentDetails,
      'hostName'
    );
    const providerName =
      enrollment.matchedMachineProvider ??
      getEnrollmentDetailsKeyValue(enrollment.enrollmentDetails, 'provider')
        .name;
    const createdOn = formatDateTime(enrollment.createdOn);

    return {
      ...enrollment,
      hostName,
      statusMessage:
        !hasMatchingMachine && enrollment.matchedMachineId
          ? ENROLLMENT_STATUS_MESSAGE_WORKSTATION_NOT_FOUND
          : enrollment.statusMessage,
      displayProvider: prepareProvider(providerName),
      workstationName:
        hasMatchingMachine || enrollment.matchedMachineProvider
          ? enrollment.matchedMachineName
          : noMatchingMachinesMessage,
      link: hasMatchingMachine ? prepareWorkstationLink(enrollment) : '',
      ipAddress: prepareIpAddresses(networkInterfaces),
      isDisabled: enrollment.status === ENROLLMENT_STATUS_WAITING_WORKER_ACCEPT,
      createdOn,
    };
  };

  const prepareData = (data) => {
    const enrollments = [];

    // Iterate over the data array and prepare each enrollment object
    data?.forEach((enrollment) => {
      enrollments.push(prepareEnrollment(enrollment));
    });

    return enrollments;
  };

  return (
    <Root className={classes.createPageContainer}>
      {renderAppBar()}
      <CAMTable
        id="monitor-enrollments-table"
        tableTitle="Monitor Enrollments"
        resource={MONITOR_ENROLLMENTS}
        data={fetchingMachineList ? [] : prepareData(monitorEnrollments)}
        idField="enrollmentId"
        total={total}
        loadingData={fetchingMachineList || fetchingMonitorEnrollments}
        hideAddButton
        hideTitle
        hideActions
      />
    </Root>
  );
}

export default MonitorEnrollments;
