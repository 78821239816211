import {del} from 'api/Api';
import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import useDialog from 'hooks/useDialog';
import useSnackbar from 'hooks/useSnackbar';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  handleApiError,
  receiveDeleteResource,
  requestDeleteResource,
} from 'redux/actions/dataActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {uncheckItem} from 'redux/actions/tableSelectActions';
import {POOL_GROUPS} from 'utils/constants';
import {selectSelectedDeployment} from 'utils/reduxSelectors';

function PoolGroupsActions({item: poolGroup, onClose}) {
  const dispatch = useDispatch();
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {poolName} = useSelector(
    (state) => state.data.dataByResource.selectedPool
  );
  const {page, rowsPerPage} = useSelector(
    (state) => state.tableData[POOL_GROUPS]
  );
  const {triggerDialog} = useDialog();
  const {successSnackbar} = useSnackbar();

  const removePoolGroup = async () => {
    dispatch(requestDeleteResource(POOL_GROUPS, poolGroup.groupGuid));
    try {
      await del({
        path: `deployments/${deploymentId}/entitlements/${poolGroup.entitlementId}`,
      });
      dispatch(receiveDeleteResource(POOL_GROUPS, poolGroup.groupGuid));
      successSnackbar(
        `Group ${
          poolGroup.groupDN || poolGroup.groupGuid
        } was removed from the pool.`
      );
    } catch (err) {
      handleApiError(err);
    }
    dispatch(fetchTableData(POOL_GROUPS, page, rowsPerPage));
    dispatch(uncheckItem(POOL_GROUPS, poolGroup));
  };

  const handleRemoveClick = () => {
    triggerDialog({
      title: 'Remove user group from pool?',
      message: `Are you sure you want to remove ${
        poolGroup.groupDN || poolGroup.groupGuid
      } from ${poolName}?
    This will revoke access to the pool for any users in the group.`,
      onConfirm: removePoolGroup,
    });
  };

  return (
    <KebabMenuItem
      id={`remove-pool-group-${poolGroup.groupGuid}`}
      dataTestId={`remove-${poolGroup.groupGuid}`}
      menuText="Remove"
      onClick={() => {
        onClose();
        handleRemoveClick();
      }}
    />
  );
}
PoolGroupsActions.propTypes = {
  item: PropTypes.object,
  onClose: PropTypes.func,
};

PoolGroupsActions.defaultProps = {
  item: {},
  onClose: () => {},
};

export default PoolGroupsActions;
