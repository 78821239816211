import {Button, Grid, Typography, MenuItem, Select, Card} from '@mui/material';
import {styled} from '@mui/material/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import FormControl from '@mui/material/FormControl';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import AssignmentPolicyIcon from 'components/CAM/icons/AssignmentPolicyIcon/AssignmentPolicyIcon';
import GridN from 'components/CAM/layout/GridN/GridN';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';
import KeyValueInfo from 'components/CAM/text/KeyValueInfo/KeyValueInfo';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import CAMTextField from 'components/common/TextField';
import {capitalize} from 'helpers/core';
import useNameValidation from 'hooks/useNameValidation';
import usePendingChanges from 'hooks/usePendingChanges';
import {goBack} from 'redux/ReduxHistory';
import {
  DEFAULT_ASSIGNMENT_HOLDING_TIME,
  FLOATING_POOL_POLICY,
  FLOATING_POOL_POLICY_TOOLTIP,
  MAX_ASSIGNMENT_HOLDING_TIME,
  TIME_MULTIPLIER_OPTIONS,
} from 'utils/constants';
import {formatDateTime, validateHoldingTime} from 'utils/utils';
import config from 'config';
import PoolInsights from './PoolInsights';

const PREFIX = 'EditPoolOverviewTab';

const classes = {
  inputTextFieldLabel: `${PREFIX}-inputTextFieldLabel`,
  formControl: `${PREFIX}-formControl`,
  itemButton: `${PREFIX}-itemButton`,
  itemButtonRow: `${PREFIX}-itemButtonRow`,
  holdingTimeDiv: `${PREFIX}-holdingTimeDiv`,
  unitOfTimeSelect: `${PREFIX}-unitOfTimeSelect`,
  unitOfTimeDiv: `${PREFIX}-unitOfTimeDiv`,
  displayFlex: `${PREFIX}-displayFlex`,
  warningLine: `${PREFIX}-warningLine`,
  helperTextProps: `${PREFIX}-helperTextProps`,
  root: `${PREFIX}-root`,
};

const Root = styled(Card)(({theme}) => ({
  [`&.${classes.root}`]: {
    margin: '0.6em 0',
    maxWidth: '1790px',
    overflow: 'visible',
    padding: '16px',
  },
  [`& .${classes.inputTextFieldLabel}`]: theme.createPage.inputTextFieldLabel,

  [`& .${classes.formControl}`]: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '10%',
    },
  },

  [`& .${classes.itemButton}`]: {
    marginRight: '12px',
    height: '36px',
    width: '96px',
  },

  [`& .${classes.itemButtonRow}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.holdingTimeDiv}`]: {
    maxWidth: '215px',
    marginRight: '10px',
  },

  [`& .${classes.unitOfTimeSelect}`]: {
    height: '36px',
  },

  [`& .${classes.unitOfTimeDiv}`]: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
  },

  [`& .${classes.displayFlex}`]: {
    display: 'flex',
  },

  [`& .${classes.warningLine}`]: {
    color: '#FF9600',
  },

  [`& .${classes.helperTextProps}`]: {
    display: 'inline-block',
    width: '325px',
    textAlign: 'justify',
  },
}));

const calculateIncomingTimeMultiplier = (holdingTime) => {
  if (Number.isInteger(holdingTime / TIME_MULTIPLIER_OPTIONS.Days.value)) {
    return TIME_MULTIPLIER_OPTIONS.Days.value;
  }
  if (Number.isInteger(holdingTime / TIME_MULTIPLIER_OPTIONS.Hours.value)) {
    return TIME_MULTIPLIER_OPTIONS.Hours.value;
  }
  return TIME_MULTIPLIER_OPTIONS.Minutes.value;
};

function EditPoolOverviewTab({pool, onSavePoolInfo}) {
  const {poolId, poolName, createdOn, policies, insights} = pool;
  const dispatch = useDispatch();

  const policy = policies?.assignment?.policyType;
  let incomingTimeMultiplier;
  let holdingTime;
  let incomingHoldingTime;
  if (policy === FLOATING_POOL_POLICY) {
    incomingHoldingTime =
      policies?.assignment?.holdingTime || DEFAULT_ASSIGNMENT_HOLDING_TIME;
    incomingTimeMultiplier =
      calculateIncomingTimeMultiplier(incomingHoldingTime);
    holdingTime = incomingHoldingTime / incomingTimeMultiplier;
  }

  const {setPendingChanges} = usePendingChanges();

  // Component states
  const [isSavingPool, setIsSavingPool] = useState(false);
  const [isPoolNameErr, validateName, nameHelperText] = useNameValidation(
    2,
    64
  );
  const [editPoolName, setEditPoolName] = useState('');
  const [editHoldingTime, setEditHoldingTime] = useState(holdingTime);
  const [holdingTimeMessageType, setHoldingTimeMessageType] = useState('');
  const [holdingTimeErrorMessage, setHoldingTimeErrorMessage] = useState('');
  const [timeMultiplier, setTimeMultiplier] = useState(incomingTimeMultiplier);
  const [calculatedHoldingTime, setCalculatedHoldingTime] =
    useState(incomingHoldingTime);
  const [isEditTimeMultiplier, setIsEditTimeMultiplier] = useState(false);

  const isEditingFields =
    editPoolName !== poolName ||
    calculatedHoldingTime !== incomingHoldingTime ||
    (editHoldingTime !== holdingTime && isEditTimeMultiplier);

  // Helper functions
  const getHelperText = () => (isPoolNameErr ? nameHelperText : '');

  const isSaveButtonDisabled = () =>
    isSavingPool ||
    isPoolNameErr ||
    holdingTimeMessageType === 'error' ||
    !isEditingFields;

  const validatePoolName = (name) => validateName(name.trim());

  useEffect(() => {
    if (poolName) {
      setEditPoolName(poolName);
    }
  }, [poolName, holdingTime]);

  useEffect(() => {
    setPendingChanges(!isSaveButtonDisabled() || isEditingFields);
  }, [editPoolName, editHoldingTime, isSavingPool]);

  useEffect(() => {
    if (policy === FLOATING_POOL_POLICY) {
      const {messageType, message} = validateHoldingTime(
        calculatedHoldingTime,
        timeMultiplier
      );
      setHoldingTimeMessageType(messageType);
      setHoldingTimeErrorMessage(message);
    }
  }, [calculatedHoldingTime]);

  useEffect(() => {
    if (
      policy === FLOATING_POOL_POLICY &&
      calculatedHoldingTime !== editHoldingTime * timeMultiplier
    ) {
      setCalculatedHoldingTime(editHoldingTime * timeMultiplier);
    }
  }, [editHoldingTime, timeMultiplier]);

  useEffect(() => {
    if (
      policy === FLOATING_POOL_POLICY &&
      calculatedHoldingTime !== editHoldingTime * timeMultiplier
    ) {
      setIsEditTimeMultiplier(true);
    }
  }, [timeMultiplier]);

  // Event handlers
  const handleSave = async () => {
    setIsSavingPool(true);
    setIsEditTimeMultiplier(false);
    await onSavePoolInfo({
      poolName: editPoolName,
      assignmentHoldingTime: calculatedHoldingTime,
    });
    setIsSavingPool(false);
  };

  const handleCancel = () => {
    setPendingChanges(false);
    dispatch(goBack());
  };

  const handleNameChange = ({target: {value: newName}}) => {
    setEditPoolName(newName);
    validatePoolName(newName);
  };

  const handleAssignmentTimeChange = ({target: {value: newTime}}) => {
    setEditHoldingTime(newTime);
  };

  const renderAssignmentPolicy = (policyType) => (
    <Grid container alignItems="center" direction="row">
      <AssignmentPolicyIcon policyType={policyType} />
      {capitalize(policyType)}
    </Grid>
  );

  // Pool info to display
  const poolInfo = [
    {
      label: 'Pool ID',
      value: poolId,
      shouldEnableCopy: true,
    },
    {
      label: 'Created On',
      value: formatDateTime(createdOn),
      shouldEnableCopy: true,
    },
    {
      label: 'Assignment Policy',
      value: renderAssignmentPolicy(policy),
      shouldEnableCopy: false,
    },
  ];

  if (pool?.policies?.assignment?.policyType === FLOATING_POOL_POLICY) {
    poolInfo.unshift({
      label: 'Insights',
      value: <PoolInsights status={insights} />,
      shouldEnableCopy: false,
    });
  }

  const renderPoolInfo = () =>
    poolInfo.map(({label, value, shouldEnableCopy}) => (
      <KeyValueInfo
        key={label}
        label={label}
        value={value}
        enableCopy={shouldEnableCopy}
      />
    ));

  const handleTimeChange = (event) => {
    setTimeMultiplier(event.target.value);
  };

  const assignmentHoldingTimeInputProps = () => ({
    max: MAX_ASSIGNMENT_HOLDING_TIME / timeMultiplier,
    min: Math.ceil(config.FLOATING_POOL_IDLE_MINUTES / timeMultiplier),
  });

  const renderFormField = () => (
    <GridN>
      <GridN singleColumn>
        <Typography className={classes.inputTextFieldLabel}>
          Pool Name
        </Typography>
        <CAMTextField
          value={editPoolName}
          helperText={getHelperText()}
          placeholderText="Edit pool name"
          fieldType="text"
          dataTestId="field-pool-name"
          isRequired
          isError={isPoolNameErr}
          onChange={handleNameChange}
        />
      </GridN>
    </GridN>
  );

  const renderEditHoldingTime = () => {
    if (policy === FLOATING_POOL_POLICY) {
      return (
        <div className={classes.displayFlex}>
          <div className={classes.holdingTimeDiv}>
            <Typography className={classes.inputTextFieldLabel}>
              <InputLabel
                displayText="Assignment Holding Time"
                tooltipText={`${FLOATING_POOL_POLICY_TOOLTIP} This time period must be longer than ${config.FLOATING_POOL_IDLE_MINUTES} minutes. Small variations to the specified time are possible due to the multiple factors outside of our direct control. `}
              />
            </Typography>
            <CAMTextField
              value={editHoldingTime}
              helperText={
                <span
                  className={
                    holdingTimeMessageType === 'warning'
                      ? classNames(classes.helperTextProps, classes.warningLine)
                      : classes.helperTextProps
                  }
                >
                  {holdingTimeErrorMessage}
                </span>
              }
              placeholderText="Edit holding time"
              fieldType="number"
              dataTestId="field-pool-holding-time"
              isRequired
              isError={holdingTimeMessageType === 'error'}
              onChange={handleAssignmentTimeChange}
              inputProps={{
                ...assignmentHoldingTimeInputProps(),
              }}
              extraProps={{
                FormHelperTextProps: {
                  style: {
                    margin: 0,
                    padding: 0,
                  },
                },
              }}
            />
          </div>
          <div
            className={classNames(classes.displayFlex, classes.unitOfTimeDiv)}
          >
            <FormControl size="small">
              <Typography className={classes.inputTextFieldLabel}>
                Unit of time
              </Typography>
              <Select
                data-testid="select-time-multiplier"
                onChange={handleTimeChange}
                value={timeMultiplier}
                className={classes.unitOfTimeSelect}
              >
                <MenuItem
                  data-testid="select-time-multiplier-minutes"
                  value={TIME_MULTIPLIER_OPTIONS.Minutes.value}
                >
                  {TIME_MULTIPLIER_OPTIONS.Minutes.text}
                </MenuItem>
                <MenuItem
                  data-testid="select-time-multiplier-hours"
                  value={TIME_MULTIPLIER_OPTIONS.Hours.value}
                >
                  {TIME_MULTIPLIER_OPTIONS.Hours.text}
                </MenuItem>
                <MenuItem
                  data-testid="select-time-multiplier-days"
                  value={TIME_MULTIPLIER_OPTIONS.Days.value}
                >
                  {TIME_MULTIPLIER_OPTIONS.Days.text}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderFormActions = () => (
    <Grid container>
      <div className={classes.itemButtonRow}>
        <Button
          className={classes.itemButton}
          variant="outlined"
          color="primary"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <SaveButton
          onClick={handleSave}
          disabled={isSaveButtonDisabled()}
          saving={isSavingPool}
        />
      </div>
    </Grid>
  );

  return (
    <Root className={classes.root}>
      <GridN singleColumn>
        <SectionHeader displayText="POOL INFORMATION" />
      </GridN>
      <GridN>{renderPoolInfo()}</GridN>

      <form onSubmit={handleSave}>
        <GridN singleColumn>
          {renderFormField()}
          {renderEditHoldingTime()}
          {renderFormActions()}
        </GridN>
      </form>
    </Root>
  );
}

EditPoolOverviewTab.propTypes = {
  pool: PropTypes.object,
  onSavePoolInfo: PropTypes.func,
};

EditPoolOverviewTab.defaultProps = {
  pool: {
    poolId: '',
    deploymentId: '',
    poolName: '',
    createdOn: '',
    updatedOn: '',
    policies: {
      assignment: {
        policyType: '',
        holdingTime: 0,
      },
    },
  },
  onSavePoolInfo: () => {},
};

export default EditPoolOverviewTab;
