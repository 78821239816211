import {Button, Menu, MenuItem, Tooltip} from '@mui/material';
import {styled} from '@mui/material/styles';
import {AccessTime, ExitToApp} from '@mui/icons-material';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {get} from 'api/Api';
import CAMTooltip from 'components/CAM/display/CAMTooltip/CAMTooltip';
import AssignmentPolicyIcon from 'components/CAM/icons/AssignmentPolicyIcon/AssignmentPolicyIcon';
import ProviderIcon from 'components/CAM/icons/ProviderIcon/ProviderIcon';
import CAMTable from 'components/table/Table';
import config from 'config';
import {capitalize} from 'helpers/core';
import {loadBulkActionsData} from 'redux/actions/bulkActionsDialogActions';
import {
  fetchCloudServiceAccounts,
  fetchData,
  fetchResource,
  handleApiError,
  receiveResource,
  requestResource,
  saveVariable,
} from 'redux/actions/dataActions';
import {mapProviders, mapResourceToPath} from 'utils/Mappings';
import {doSafeRequest} from 'utils/apiUtils';
import {
  ADD_REMOTE_WORKSTATION_LINK,
  AD_USERS,
  CLOUD_SERVICE_ACCOUNTS,
  CONNECTORS,
  CREATE_REMOTE_WORKSTATION_LINK,
  DEPLOYMENTS,
  EDIT_REMOTE_WORKSTATION_LINK,
  ENTITLEMENTS,
  IN_SESSION,
  MACHINE_SESSIONS,
  MONITOR_ENROLLMENTS,
  MONITOR_IN_SESSION,
  MONITOR_TELEMETRY_LATEST,
  NOT_IN_SESSION,
  POOLS,
  REMOTE_WORKSTATIONS,
  VIEW_AD_USER_LINK,
} from 'utils/constants';
import {
  selectBulkActionsDialogTableParams,
  selectData,
  selectDataForTable,
  selectResource,
  selectSelectedDeployment,
  selectVariable,
} from 'utils/reduxSelectors';
import {
  deploymentHasConnectors,
  isCreateWorkstationAvailable,
  isEmpty,
  isItemActive,
  isLatestMonitorTelemetryValid,
  linkTo,
} from 'utils/utils';
import MonitorLogoutDialog from './LogoutDialogContent';
import {RemoteWorkstationErrorIndicator} from './utils';

const PREFIX = 'RemoteWorkstations';

const classes = {
  tooltip: `${PREFIX}-tooltip`,
  instanceId: `${PREFIX}-instanceId`,
  disabledText: `${PREFIX}-disabledText`,
  tableLink: `${PREFIX}-tableLink`,
  success: `${PREFIX}-success`,
  warning: `${PREFIX}-warning`,
  usersDiv: `${PREFIX}-usersDiv`,
  buttonLogout: `${PREFIX}-buttonLogout`,
  logoutIcon: `${PREFIX}-logoutIcon`,
  userNameParagraph: `${PREFIX}-userNameParagraph`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.instanceId}`]: {
    color: 'rgba(0,0,0,0.38)',
    fontWeight: 400,
  },

  [`& .${classes.disabledText}`]: {
    margin: 0,
    color: '#ccc',
  },

  [`& .${classes.tableLink}`]: {
    color: '#0076a9',
    cursor: 'pointer',
    textDecoration: 'none',
  },

  [`& .${classes.success}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.warning}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.usersDiv}`]: {
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'none',
    color: '#0076a9',
    padding: '0',
  },

  [`& .${classes.buttonLogout}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: '#0076a9',
    height: '30px',
    padding: '0',
    marginRight: '2px',
    paddingRight: '2px',
    paddingLeft: '0px',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#ADE1F7',
      opacity: 1.0,
    },
    '&:disabled': {
      color: '#FF9600',
    },
  },

  [`& .${classes.logoutIcon}`]: {
    fontSize: '20px',
    marginRight: '3px',
  },

  [`& .${classes.userNameParagraph}`]: {
    padding: '0',
    margin: '0',
  },
}));

// Styles add-button menu tooltips to increase font size
const AddButtonTooltip = Tooltip;

export default function RemoteWorkstations() {
  const dispatch = useDispatch();
  const [menuAnchorEl, setMenuAnchorEl] = useState(false);
  const [hasEnrollment, setHasEnrollment] = useState(false);
  const [addRwAvailable, setAddRwAvailable] = useState(
    // if pothos is available, user should be able to create remote workstation
    // without connector
    config.pothosSupported() //
  );
  const [isMonitorLogoutDialogOpen, setIsMonitorLogoutDialogOpen] =
    useState(false);
  const [monitorLogoutWorkstations, setMonitorLogoutWorkstations] = useState(
    []
  );
  const [monitorUserInSession, setMonitorUserInSession] = useState('');

  // Data for associated pool column
  const [poolsByMachines, setPoolsByMachines] = useState([]);
  const [fetchingPoolsByMachines, setFetchingPoolsByMachines] = useState(false);

  const {data: deployments} = useSelector((state) =>
    selectData(state, DEPLOYMENTS)
  );
  const {data: adUsers} = useSelector((state) => selectData(state, AD_USERS));
  const {data: entitlements, isFetching: isFetchingEntitlements} = useSelector(
    (state) => selectData(state, ENTITLEMENTS)
  );
  const {data: sessions, isFetching: fetchingSessions} = useSelector((state) =>
    selectData(state, MACHINE_SESSIONS)
  );
  const {data: monitorTelemetry, isFetching: fetchingMonitorTelemetry} =
    useSelector((state) => selectResource(state, MONITOR_TELEMETRY_LATEST));
  const monitorLogoutInfo = useSelector((state) =>
    selectVariable(state, 'monitorLogoutInfo')
  );

  const {
    data: remoteWorkstations,
    isFetching: fetchingRemoteWorkstations,
    total,
  } = useSelector((state) => selectDataForTable(state, REMOTE_WORKSTATIONS));

  const adUsersDialogParams = useSelector((state) =>
    selectBulkActionsDialogTableParams(state, AD_USERS)
  );
  const {resource: bulkDialogResource} = useSelector(
    (state) => state.bulkActionsDialog
  );

  const selectedDeployment = useSelector(selectSelectedDeployment);
  const tableButtonData = useSelector((state) => {
    const accounts = selectData(state, CLOUD_SERVICE_ACCOUNTS);
    const connectors = selectData(state, CONNECTORS);
    return {
      cloudServiceAccounts: accounts.data,
      connectors: connectors.data,
    };
  });

  const addButtonMenuItems = [
    {
      id: 'add-workstation',
      menuText: 'Add existing remote workstation',
      link: `${ADD_REMOTE_WORKSTATION_LINK}`,
      requiredResource: 'connector',
      enabled: addRwAvailable,
    },
  ];

  const openLogoutDialog = (userName, machine) => {
    setIsMonitorLogoutDialogOpen(true);
    setMonitorLogoutWorkstations([machine]);
    setMonitorUserInSession(userName);
  };

  const updateDialogAdUsers = async (params) => {
    const path = mapResourceToPath(AD_USERS);
    const adUserParams = {
      ...params,
      name: params.searchKey,
      nameFilter: 'includes',
    };

    // fetch AD users, wait for the async action's promise to resolve, and
    // inform the BulkActionsDialog which AD users to display in table
    const {data: fetchedAdUsers, total: totalAdUsers} = await dispatch(
      fetchData(AD_USERS, {path, params: adUserParams})
    );
    const adUserIds = fetchedAdUsers.map((adUser) => adUser._id);

    dispatch(
      loadBulkActionsData({
        data: adUserIds,
        resource: AD_USERS,
        total: totalAdUsers,
      })
    );
  };

  const buildMachineIdQueryParams = () => {
    if (remoteWorkstations.length) {
      return {
        machineId: remoteWorkstations.map(({machineId}) => machineId).join(','),
      };
    }
    return {};
  };

  const fetchEntitlements = async () => {
    const {deploymentId} = selectedDeployment;
    const path = mapResourceToPath(ENTITLEMENTS, {deploymentId});
    const params = buildMachineIdQueryParams();
    let fetchedEntitlements = [];
    dispatch(requestResource(ENTITLEMENTS));
    const resp = await doSafeRequest(get, {path, params, limit: 1000});
    if (resp.status === 'success') {
      fetchedEntitlements = resp.data;
    } else {
      dispatch(handleApiError(resp));
    }
    dispatch(receiveResource(ENTITLEMENTS, fetchedEntitlements));
  };

  const fetchAllPoolsForDeployment = async (deploymentId) => {
    const path = `${mapResourceToPath(POOLS, {deploymentId})}`;
    const resp = await doSafeRequest(get, {path});
    if (resp.status === 'success') {
      return resp.data;
    }
    dispatch(handleApiError(resp));
    setFetchingPoolsByMachines(false);
    return [];
  };

  const fetchPoolsForMachines = async () => {
    const {deploymentId} = selectedDeployment;
    if (deploymentId) {
      // Request options
      const path = `${mapResourceToPath(POOLS, {deploymentId})}machines`;
      const params = buildMachineIdQueryParams();
      setFetchingPoolsByMachines(true);
      // Make request to get machine pools
      const resp = await doSafeRequest(get, {path, params});
      if (resp.status === 'success') {
        let mPools = resp.data.map(({poolId, machineId}) => ({
          poolId,
          machineId,
        }));
        // Fetch data for all pools in deployment
        const pools = await fetchAllPoolsForDeployment(deploymentId);
        if (pools.length) {
          // Find pool name and policy for each machine pool
          mPools = mPools.map((machinePool) => {
            const pool = pools.find(
              ({poolId}) => machinePool.poolId === poolId
            );
            if (pool) {
              const {
                poolName,
                policies: {
                  assignment: {policyType},
                },
              } = pool;
              return {
                ...machinePool,
                associatedPool: poolName,
                assignmentPolicy: policyType,
              };
            }
            return machinePool;
          });
          setPoolsByMachines(mPools);
        }
      } else {
        dispatch(handleApiError(resp));
      }
      setFetchingPoolsByMachines(false);
    }
  };

  const fetchEnrollments = async () => {
    const {deploymentId} = selectedDeployment;
    const path = `${mapResourceToPath(MONITOR_ENROLLMENTS, {deploymentId})}`;

    const resp = await doSafeRequest(get, {path});
    if (resp.status === 'success') {
      setHasEnrollment(resp.data.length > 0);
      dispatch(saveVariable('pendentMonitorsLength', resp.total));
      return resp.data;
    }
    dispatch(handleApiError(resp));
    return [];
  };

  useEffect(() => {
    if (selectedDeployment.deploymentId) {
      fetchPoolsForMachines();
      fetchEntitlements();
    }
  }, [
    selectedDeployment?.deploymentId,
    JSON.stringify(remoteWorkstations.map((machine) => machine.machineId)),
  ]);

  useEffect(() => {
    if (config.isMonitorEnabled() && selectedDeployment.deploymentId) {
      fetchEnrollments();
    }
  }, [fetchingRemoteWorkstations]);

  useEffect(() => {
    const {deploymentId} = selectedDeployment;
    if (deploymentId) {
      dispatch(fetchResource(CONNECTORS, {deploymentId}));
      dispatch(fetchCloudServiceAccounts({deploymentId}));
    }
  }, [selectedDeployment]);

  useEffect(() => {
    if (config.pothosSupported()) {
      return;
    }
    const {connectors} = tableButtonData;
    setAddRwAvailable(deploymentHasConnectors(connectors, selectedDeployment));
  }, [JSON.stringify(tableButtonData), selectedDeployment?.deploymentId]);

  useEffect(() => {
    if (!isEmpty(adUsersDialogParams) && bulkDialogResource === AD_USERS) {
      updateDialogAdUsers(adUsersDialogParams);
    }
  }, [JSON.stringify(adUsersDialogParams), bulkDialogResource]);

  useEffect(() => {
    if (monitorLogoutInfo) {
      setIsMonitorLogoutDialogOpen(monitorLogoutInfo.isLogoutDialogOpen);
      setMonitorUserInSession(monitorLogoutInfo.usersInSession);
      setMonitorLogoutWorkstations(monitorLogoutInfo.logoutWorkstations);
    }
  }, [monitorLogoutInfo]);

  const getRWEditLink = (machine) => {
    const {machineId} = machine;
    if (isItemActive(machine) || machine.status === 'deleting') {
      return `/app/remoteWorkstations/edit/${machineId}`;
    }
    return '';
  };

  const disabledText = (text) => <p className={classes.disabledText}>{text}</p>;

  const prepareEntitlements = (machineId) => {
    const {deploymentId} = selectedDeployment;
    if (isFetchingEntitlements) {
      return ['Loading...'];
    }

    const results = [];

    entitlements.forEach((e) => {
      if (e.resourceType === 'machine' && e.resourceId === machineId) {
        const adUser = adUsers.find((u) => u.userGuid === e.userGuid);
        if (adUser) {
          results.push(
            <Link
              className={classes.tableLink}
              to={linkTo(
                `${VIEW_AD_USER_LINK}/${adUser.userGuid}/${deploymentId}`
              )}
            >
              {adUser.name || adUser.userName}
            </Link>
          );
        }
      }
    });

    if (results.length === 0) {
      return [disabledText('None')];
    }

    if (results.length > 1) {
      return [
        results[0],
        ` +${results.length - 1} `,
        <Link
          className={classes.tableLink}
          to={linkTo(
            `${EDIT_REMOTE_WORKSTATION_LINK}/${machineId}/userManagement`
          )}
        >
          {'  (view all)'}
        </Link>,
      ];
    }

    return results;
  };

  const renderUserLogoutButton = (machine, user) => (
    <div className={classes.usersDiv}>
      <CAMTooltip
        text={user.status}
        placement="right"
        isWarning={user.status !== MONITOR_IN_SESSION}
        data-testid={`tooltip-${machine.machineId}-${user.userName}`}
      >
        <Button
          className={classes.buttonLogout}
          onClick={() => openLogoutDialog(user.userName, machine)}
          data-testid={`logout-button-${machine.machineId}-${user.userName}`}
          disabled={user.status !== MONITOR_IN_SESSION}
        >
          {user.status === MONITOR_IN_SESSION ? (
            <ExitToApp className={classes.logoutIcon} />
          ) : (
            <AccessTime className={classes.logoutIcon} />
          )}
          <p className={classes.userNameParagraph}>{user.userName}</p>
        </Button>
      </CAMTooltip>
    </div>
  );

  const prepareSessions = (machine) => {
    if (fetchingSessions || fetchingMonitorTelemetry) {
      return 'Loading...';
    }

    // Monitor Telemetry takes priority.
    if (
      config.isMonitorEnabled() &&
      isLatestMonitorTelemetryValid(monitorTelemetry[machine.machineId])
    ) {
      const users = monitorTelemetry[machine.machineId].loggedInUsers;
      if (users.length > 0) {
        return users.map((user) => renderUserLogoutButton(machine, user));
      }
      return disabledText('Not in session');
    }

    const machineSession = sessions.find(
      (session) => session.machineId === machine.machineId
    );
    if (machineSession) {
      if (machineSession.pcoipSessionState === IN_SESSION) {
        const adUser = adUsers.find(
          (u) => u.userGuid === machineSession.userGuid
        );
        if (adUser) {
          return <p>{adUser.name || adUser.userName}</p>;
        }
      }
      if (machineSession.pcoipSessionState === NOT_IN_SESSION) {
        return disabledText('Not in session');
      }
    }

    return disabledText('Unknown');
  };

  const prepareAwmMonitorStatus = (machine) => {
    if (fetchingMonitorTelemetry) {
      return 'Loading...';
    }

    if (!machine.agentMonitored) {
      return disabledText('Disabled');
    }
    if (isLatestMonitorTelemetryValid(monitorTelemetry[machine.machineId])) {
      return <span className={classes.success}>Healthy</span>;
    }
    return <span className={classes.warning}>Unhealthy</span>;
  };

  const preparePcoipAgentStatus = (machine) => {
    if (fetchingMonitorTelemetry) {
      return 'Loading...';
    }

    if (
      machine.agentMonitored &&
      isLatestMonitorTelemetryValid(monitorTelemetry[machine.machineId])
    ) {
      if (
        monitorTelemetry[machine.machineId].numberActivePcoipSessions === null ||
        monitorTelemetry[machine.machineId].numberActivePcoipSessions >= 0
      ) {
        return <span className={classes.success}>Running</span>;
      } else if (monitorTelemetry[machine.machineId].numberActivePcoipSessions === -1) {
        return <span className={classes.warning}>Not Running</span>;
      }
    }
    return disabledText('Unknown');
  };

  const prepareProvider = (provider) => (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <ProviderIcon provider={provider} />
      {mapProviders(provider)}
    </Grid>
  );

  const prepareProvisioningState = ({managed, provisioningStatus}) => {
    if (
      managed &&
      provisioningStatus &&
      typeof provisioningStatus.state === 'string'
    ) {
      return capitalize(provisioningStatus.state);
    }
    return '';
  };

  const prepareAssociatedPool = (machineId) => {
    const mPool = poolsByMachines.find(({machineId: mid}) => machineId === mid);
    if (fetchingPoolsByMachines) {
      return 'Loading...';
    }
    if (mPool && mPool.assignmentPolicy && mPool.associatedPool) {
      return (
        <div>
          <AssignmentPolicyIcon policyType={mPool.assignmentPolicy} />
          {capitalize(mPool.associatedPool)}
        </div>
      );
    }
    return disabledText('None');
  };

  const getDeploymentName = (depId) => {
    if (deployments[depId] && deployments[depId].deploymentName) {
      return deployments[depId].deploymentName;
    }
    return depId;
  };

  const prepareMachineName = ({machineName, instanceId}) => {
    if (instanceId && instanceId !== machineName) {
      return (
        <>
          {machineName}
          <br />
          <span
            className={classes.instanceId}
          >{`Instance ID: ${instanceId}`}</span>
        </>
      );
    }
    return machineName;
  };

  const prepareData = (data) => {
    if (data) {
      return data.map((machine) => ({
        ...machine,
        error: machine.managed ? machine.error : null,
        displayMachineName: prepareMachineName(machine),
        displayManaged: machine.managed ? 'Yes' : 'No',
        displayProvider: prepareProvider(machine.provider),
        powerStatus: RemoteWorkstationErrorIndicator(machine),
        powerState:
          typeof machine.powerState === 'string'
            ? capitalize(machine.powerState)
            : '',
        deployment: getDeploymentName(machine.deploymentId),
        provisioningState: prepareProvisioningState(machine),
        entitledUsers: prepareEntitlements(machine.machineId),
        sessions: prepareSessions(machine),
        awmMonitorStatus: prepareAwmMonitorStatus(machine),
        pcoipAgentStatus: preparePcoipAgentStatus(machine),
        link: getRWEditLink(machine),
        displayStatus: capitalize(machine.status || 'active'),
        associatedPool: prepareAssociatedPool(machine.machineId),
      }));
    }
    return [];
  };

  const disabledCreateRwButtonTooltipMessage = () => {
    if (!addRwAvailable) {
      return 'No connector found for this deployment.';
    }
    return 'Creating a remote workstation is only supported for Azure and GCP but no associated service accounts were found in this deployment.';
  };

  const renderAddRwMenu = () => (
    <Menu
      anchorEl={menuAnchorEl}
      open={Boolean(menuAnchorEl)}
      onClose={() => setMenuAnchorEl(null)}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      disableAutoFocusItem
    >
      {addButtonMenuItems.map((item) => (
        <AddButtonTooltip
          key={`${item.id}-menu-tooltip`}
          title={
            item.id === 'create-workstation'
              ? disabledCreateRwButtonTooltipMessage()
              : 'No connector found for this deployment.'
          }
          disableFocusListener={item.enabled}
          disableHoverListener={item.enabled}
          disableTouchListener={item.enabled}
          placement="right"
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          <div>
            <MenuItem
              key={`${item.id}-menu-item`}
              dense
              component={Link}
              to={item.link}
              disabled={!item.enabled}
            >
              {item.menuText}
            </MenuItem>
          </div>
        </AddButtonTooltip>
      ))}
    </Menu>
  );

  // Sets add button as anchor element for menu when it's clicked
  const handleAddClick = (event) => {
    event.preventDefault();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMonitorLogoutDialog = () => {
    setIsMonitorLogoutDialogOpen(false);
  };

  return (
    <Root>
      {config.isMonitorEnabled() && (
        <MonitorLogoutDialog
          isOpen={isMonitorLogoutDialogOpen}
          username={monitorUserInSession}
          workstations={monitorLogoutWorkstations}
          onClose={handleCloseMonitorLogoutDialog}
        />
      )}
      <CAMTable
        id="workstations-table"
        tableTitle="Remote Workstations"
        resource={REMOTE_WORKSTATIONS}
        idField="machineId"
        data={prepareData(remoteWorkstations)}
        total={total}
        loadingData={fetchingRemoteWorkstations}
        onAdd={handleAddClick}
        hideConfigureTable={false}
        showEnrollmentsButton={config.isMonitorEnabled() && hasEnrollment}
      />
      {menuAnchorEl && renderAddRwMenu()}
    </Root>
  );
}
