import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {post} from 'api/Api';
import ErrorModal from 'components/common/ErrorModal';
import GenericLink from 'components/common/GenericLink';
import {capitalize} from 'helpers/core';
import useAcceptPolicyTracking from 'hooks/useAcceptPolicyTracking';
import useVerifyExistingDeployments from 'hooks/useVerifyExistingDeployments';
import {fetchResource, saveVariable} from 'redux/actions/dataActions';
import {push} from 'redux/actions/HistoryActions';
import {
  AWM_EULA_LINK,
  AWM_PRIVACY_STATEMENT,
  AWM_SERVICE_LEVEL,
  DASHBOARD_LINK,
  DEPLOYMENTS,
  LINK_CONTACT_ANYWARE_SUPPORT,
  LINK_GET_REGISTRATION_CODE,
} from 'utils/constants';
import {isEmpty, stripNull} from 'utils/utils';
import config from '../../config';

const PREFIX = 'UnlockCAM';

const classes = {
  dialogTitleBar: `${PREFIX}-dialogTitleBar`,
  dialogTitleText: `${PREFIX}-dialogTitleText`,
  closeButton: `${PREFIX}-closeButton`,
  dialogContent: `${PREFIX}-dialogContent`,
  dialogContentText: `${PREFIX}-dialogContentText`,
  inputBox: `${PREFIX}-inputBox`,
  inputText: `${PREFIX}-inputText`,
  talkToUs: `${PREFIX}-talkToUs`,
  buttons: `${PREFIX}-buttons`,
  buttonUnlockLoading: `${PREFIX}-buttonUnlockLoading`,
  loadingSpinner: `${PREFIX}-loadingSpinner`,
};

const StyledDialog = styled(Dialog)(({theme}) => ({
  [`& .${classes.dialogTitleBar}`]: {
    backgroundColor: '#164A85',
    padding: 0,
  },

  [`& .${classes.dialogTitleText}`]: {
    color: '#FFF',
    fontSize: '1.25rem',
    lineHeight: '25px',
    textShadow: '0 0 4px 0 rgba(3,46,97,0.5)',
    margin: '12px 25px',
  },

  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: '2px',
    top: '2px',
    color: '#FFF',
  },

  [`& .${classes.dialogContent}`]: {
    backgroundColor: '#FFFFFF',
  },

  [`& .${classes.dialogContentText}`]: {
    fontSize: '1rem',
  },

  [`& .${classes.inputBox}`]: {
    backgroundColor: '#FFF',
    borderRadius: '6px',
    fontSize: '0.8125rem',
    color: '#000',
    marginTop: '24px',
    height: '2rem',
  },

  [`& .${classes.inputText}`]: {
    backgroundColor: theme.palette.surface.white,
    height: '2rem',
    borderRadius: '6px',
  },

  [`& .${classes.talkToUs}`]: {
    fontSize: '0.75rem',
    marginBottom: '16px',
  },

  [`& .${classes.buttons}`]: {
    textTransform: 'none',
    fontWeight: 'normal',
    letterSpacing: '0',
    lineHeight: '0.75rem',
    padding: '9px 2px',
    fontSize: '0.8125rem',
  },

  [`& .${classes.buttonUnlockLoading}`]: {
    backgroundColor: '#FFF',
    color: '#0070D2',
    padding: '9px 14px',
    cursor: 'wait',
    '&:hover': {
      backgroundColor: '#FFF',
      color: '#0070D2',
    },
  },

  [`& .${classes.loadingSpinner}`]: {
    width: '12px',
    height: '12px',
    marginRight: '10px',
  },
}));

function UnlockCAM({handleSignout}) {
  const dispatch = useDispatch();

  const [registrationCode, setRegistrationCode] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [registrationCodeInputDisabled, setRegistrationCodeInputDisabled] =
    useState(false);
  const [regCodeEntered, setRegCodeEntered] = useState(false);
  const [unlockButtonLoading, setUnlockButtonLoading] = useState(false);
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
  const [acceptedEula, setAcceptedEula] = useState(false);
  const [acceptedDataExport, setAcceptedDataExport] = useState(false);

  const [privacyPolicyRequired, setPrivacyPolicyRequired] = useState(
    config.PRIVACY_POLICY_TRACKING_ENABLED
  );
  const [eulaRequired, setEulaRequired] = useState(true);
  const [dataExportRequired, setDataExportRequired] = useState(false);

  const verifyExistingDeployments = useVerifyExistingDeployments();
  const {currentUser, updateCurrentUser} = useAcceptPolicyTracking();
  const getNewDeploymentName = () =>
    `default-${Math.trunc(Math.random() * 10000)}`;
  const isTenantUser = currentUser !== null;

  useEffect(() => {
    if (isTenantUser) {
      if (config.PRIVACY_POLICY_TRACKING_ENABLED) {
        setPrivacyPolicyRequired(
          !currentUser.privacyPolicyAcceptedOn ||
            (currentUser.privacyPolicyAcceptedOn &&
              currentUser.privacyPolicyAcceptedOn <
                config.PRIVACY_POLICY_UPDATED_ON)
        );
      }
      setEulaRequired(
        !currentUser.eulaAcceptedOn ||
          (currentUser.eulaAcceptedOn &&
            currentUser.eulaAcceptedOn < config.EULA_UPDATED_ON)
      );
      if (
        currentUser.requestCountry &&
        currentUser.requestCountry.toUpperCase() === 'CN'
      ) {
        setDataExportRequired(!currentUser.dataExportAcceptedOn);
      }
    } else {
      setPrivacyPolicyRequired(false);
      setEulaRequired(false);
      setDataExportRequired(false);
    }
  }, [JSON.stringify(currentUser)]);

  const handleRegCodeChange = (event) => {
    const newRegCode = stripNull(event.target.value.trim());
    const regex = /^[A-Z0-9]{12}@([A-F0-9]{4}-){3}[A-F0-9]{4}$/;
    setRegistrationCode(newRegCode);
    setRegCodeEntered(regex.test(newRegCode));
  };

  const handleErrorCreatingDeployment = (error) => {
    const {code, message} = error;
    let displayedMessage;
    switch (code) {
      case 400:
        displayedMessage = 'Invalid registration code.';
        break;
      case 500:
        displayedMessage = capitalize(message);
        break;
      default:
        displayedMessage = 'Something went wrong. Please try again';
    }
    setErrorMessage(displayedMessage);
    setShowError(true);
  };

  const handleCreateDeployment = async () => {
    const deploymentName = getNewDeploymentName();

    try {
      const deploymentResponse = await post({
        path: 'deployments',
        data: {deploymentName, registrationCode},
      });
      const newAddedDeployment = deploymentResponse.data;

      // Reload deployments for global filter
      dispatch(fetchResource(DEPLOYMENTS, {page: 0, rowsPerPage: 200}));
      // Set new deployment selected in filter
      dispatch(saveVariable('selectedDeployment', newAddedDeployment));
    } catch (error) {
      // If a default deployment is already created in another screen, do not show error.
      if (error.code === 409) {
        setErrorMessage(
          'An error occurred creating your deployment. Please try again later. If this issue persists contact Teradici support.'
        );
        setShowError(true);
      } else {
        handleErrorCreatingDeployment(error);
      }
      throw error;
    }
  };

  const handleUnlock = async (event) => {
    event.preventDefault();

    setRegistrationCodeInputDisabled(true);
    setUnlockButtonLoading(true);

    if (!verifyExistingDeployments()) {
      try {
        await handleCreateDeployment();
      } catch (error) {
        // Error handling performed by function, exit early
        setRegistrationCodeInputDisabled(false);
        setUnlockButtonLoading(false);
        return;
      }
    }

    const acceptanceRequestBody = {};
    if (privacyPolicyRequired) {
      acceptanceRequestBody.privacyPolicyAccepted = acceptedPrivacyPolicy;
    }
    if (eulaRequired) {
      acceptanceRequestBody.eulaAccepted = acceptedEula;
    }
    if (dataExportRequired) {
      acceptanceRequestBody.dataExportAccepted = acceptedDataExport;
    }
    if (!isEmpty(acceptanceRequestBody)) {
      try {
        await updateCurrentUser(acceptanceRequestBody);
      } catch (error) {
        setErrorMessage(
          'An error occurred while accepting our policies. Please try again later. If this issue persists contact Teradici support.'
        );
        setShowError(true);

        setRegistrationCodeInputDisabled(false);
        setUnlockButtonLoading(false);
        return;
      }
    }

    dispatch(push(DASHBOARD_LINK));
  };

  const closeErrorMessage = () => {
    setShowError(false);
    setErrorMessage('');
  };

  const toggleAcceptedPrivacyPolicy = () =>
    setAcceptedPrivacyPolicy(!acceptedPrivacyPolicy);
  const toggleAcceptedEula = () => setAcceptedEula(!acceptedEula);
  const toggleAcceptedDataExport = () =>
    setAcceptedDataExport(!acceptedDataExport);

  const enableUnlock =
    (verifyExistingDeployments() || regCodeEntered) &&
    (!privacyPolicyRequired || acceptedPrivacyPolicy) &&
    (!eulaRequired || acceptedEula) &&
    (!dataExportRequired || acceptedDataExport);

  const renderDeploymentReq = () => (
    <>
      <Typography className={classes.dialogContentText}>
        You need a registration code to activate your PCoIP Agent and to use it
        in conjunction with Anyware Manager. Once you subscribe to an Anyware
        subscription your registration code will be sent to the registered
        email.
        <br />
        <br />
        If you are an existing customer and have a subscription but have lost
        your registration code, then you need to
        <GenericLink url={LINK_CONTACT_ANYWARE_SUPPORT}>
          {' submit a ticket '}
        </GenericLink>
        with support.
      </Typography>

      <TextField
        className={classes.inputBox}
        data-testid="registration-code"
        variant="outlined"
        disabled={registrationCodeInputDisabled}
        required
        fullWidth
        autoComplete="off"
        placeholder="Anyware Manager registration code"
        value={registrationCode}
        onChange={(event) => handleRegCodeChange(event)}
        onKeyPress={(event) => {
          if (event.key === 'Enter' && enableUnlock) {
            handleUnlock(event);
          }
        }}
        InputProps={{className: classes.inputText}}
        type="password"
      />
      <GenericLink url={LINK_GET_REGISTRATION_CODE}>
        <Typography className={classes.talkToUs}>
          Don't have the code? Find out how to get one!
        </Typography>
      </GenericLink>
    </>
  );

  const renderPrivacyPolicyReq = () => (
    <>
      <Checkbox
        checked={acceptedPrivacyPolicy}
        onChange={toggleAcceptedPrivacyPolicy}
        color="primary"
        inputProps={{'data-testid': 'accept-privacy-policy'}}
      />
      {"I have read and accepted Teradici's "}
      <GenericLink url={AWM_PRIVACY_STATEMENT}>Privacy Policy</GenericLink>
      {" and acknowledged Teradici's "}
      <GenericLink url={AWM_SERVICE_LEVEL}>
        Service Level Objectives
      </GenericLink>
      .
      <br />
    </>
  );

  const renderEulaReq = () => (
    <>
      <Checkbox
        checked={acceptedEula}
        onChange={toggleAcceptedEula}
        color="primary"
        inputProps={{'data-testid': 'accept-eula'}}
      />
      {"I have read and accepted HP Anyware's "}
      <GenericLink url={AWM_EULA_LINK}>End User License Agreement</GenericLink>.
    </>
  );

  const renderDataExportReq = () => (
    <>
      <br />
      <Checkbox
        checked={acceptedDataExport}
        onChange={toggleAcceptedDataExport}
        color="primary"
        inputProps={{'data-testid': 'accept-dataexport'}}
      />
      HP may send my information outside my country.
    </>
  );

  const handleClose = (event, reason) => {
    // if (reason !== 'backdropClick' && cancelEnabled) {
    //   setOpen(false);
    // }
  };

  return (
    <StyledDialog open onClose={handleClose}>
      <MuiDialogTitle disableTypography className={classes.dialogTitleBar}>
        <Typography className={classes.dialogTitleText}>
          Unlock Anyware Manager
        </Typography>
      </MuiDialogTitle>

      <form onSubmit={handleUnlock}>
        <MuiDialogContent className={classes.dialogContent}>
          <Typography className={classes.dialogContentText}>
            Please complete the following actions in order to unlock the Anyware
            Manager:
            <br />
            <br />
          </Typography>
          {!verifyExistingDeployments() && renderDeploymentReq()}
          {privacyPolicyRequired && renderPrivacyPolicyReq()}
          {eulaRequired && renderEulaReq()}
          {dataExportRequired && renderDataExportReq()}
        </MuiDialogContent>

        <MuiDialogActions>
          {!unlockButtonLoading && (
            <Button onClick={handleSignout} variant="contained">
              Log out
            </Button>
          )}

          {!unlockButtonLoading && (
            <Button
              type="submit"
              disabled={!enableUnlock}
              color="primary"
              variant="contained"
            >
              Unlock
            </Button>
          )}

          {unlockButtonLoading && (
            <Button
              className={classNames(
                classes.buttons,
                classes.buttonUnlockLoading
              )}
              variant="contained"
            >
              <>
                <CircularProgress className={classes.loadingSpinner} />
                Unlocking Anyware Manager
              </>
            </Button>
          )}
        </MuiDialogActions>
      </form>

      <ErrorModal
        open={showError}
        errorHeader="Error"
        errorMessage={errorMessage}
        handleClose={closeErrorMessage}
      />
    </StyledDialog>
  );
}

UnlockCAM.propTypes = {
  handleSignout: PropTypes.func.isRequired,
};

export default UnlockCAM;
