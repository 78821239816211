/* eslint-disable no-param-reassign */
import {Buffer} from 'buffer';
import PropTypes from 'prop-types';
import {useState, useEffect, useContext} from 'react';
import {Chip, Divider} from '@mui/material/index';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useSelector} from 'react-redux';
import * as openpgp from 'openpgp';
import {v4 as uuid} from 'uuid';
import {get} from 'api/Api';

import {CONFIGURATION_CAPABILITY_NAME} from 'utils/constants';
import useSnackbar from 'hooks/useSnackbar';
import {ConnectorMqttContext} from '../edit/MqttContext';

function isCapabilityEnabled(capabilityList, capabilityName) {
  return (
    capabilityList.find((cap) => cap?.capabilityName === capabilityName)
      ?.enabled === true
  );
}

// this function is used to convert the keys to nested object structure for the config
function keys2Object(result, keys, val) {
  if (keys.length > 1) {
    const key = keys.splice(0, 1);
    if (!result[key]) {
      result[key] = {};
    }
    result[key] = keys2Object(result[key], keys, val);
  } else {
    result[keys[0]] = val;
  }
  return result;
}

/* this function is used to build the config for the connector based on 
  the desired state and the Param array of the respective capability
  configParamArray=[{
    fieldName: 'plaintextLdap', paramType: 'boolean', flag: '--enable-plaintext-ldap',default: true, keys: 'domain.enableLdapMode',
  }]
  desiredState is state={
      plaintextLdap: true, radiusPort: '', radiusSecret: '', enableOauth: false,
    };
    Output will be config = {
      domain: {
        enableLdapMode: true,
      },
    }; */

export function makeConnectorConfig(configParamArray, state) {
  const config = {};
  const arrayFields = [
    'computersDn',
    'computersFilter',
    'usersDn',
    'usersFilter',
  ];
  // parse the value based on the field type
  const parseValue = (fieldName, value, paramtype) => {
    if (arrayFields.includes(fieldName)) {
      try {
        return Array.isArray(value) ? value : [value];
      } catch (error) {
        console.error(`Parsing error for ${fieldName}:`, error);
        return value;
      }
    } else if (paramtype === 'boolean' && typeof value === 'string') {
      return value.toLowerCase() === 'true';
    } else if (paramtype === 'number' && typeof value === 'string') {
      return parseInt(value, 10);
    }
    return value;
  };

  // checks if the requirewith fields are all empty or all set with values
  const validateRequiredWith = (setting) => {
    if (!setting.requiredWith) {
      return true;
    }

    const requiredFields = [setting.fieldName, ...setting.requiredWith];
    const unsetFields = requiredFields.filter(
      (fieldName) => state[fieldName] === '' || state[fieldName] === null
    );
    if (unsetFields.length === requiredFields.length) {
      return false;
    }
    if (unsetFields.length > 0) {
      const unsetFieldLabels = unsetFields.map((fieldName) => {
        const findSettingInConfig = (configParams) => {
          for (const param of configParams) {
            if (param.fieldName === fieldName) {
              return param;
            }
            if (param.subConfig) {
              const subSetting = param.subConfig.find(
                (sub) => sub.fieldName === fieldName
              );
              if (subSetting) {
                return subSetting;
              }
            }
          }
          return null;
        };
        const foundSetting = findSettingInConfig(configParamArray);
        return foundSetting && foundSetting?.label
          ? foundSetting.label
          : fieldName;
      });
      throw new Error(`The fields ${unsetFieldLabels.join(', ')} are required`);
    }
    return true;
  };

  // process the setting and build the config
  const processSetting = (setting) => {
    if (!setting?.keys) return;

    if (
      setting.required === false &&
      (state[setting.fieldName] === null || state[setting.fieldName] === '')
    ) {
      return;
    }

    if (!validateRequiredWith(setting)) return;

    const value = parseValue(
      setting.fieldName,
      state[setting.fieldName],
      setting.paramType
    );
    keys2Object(config, setting.keys.split('.'), value);

    setting.subConfig?.forEach(processSetting);
  };

  configParamArray.forEach(processSetting);
  return config;
}

export function ApplyConnectorConfiguration({
  desiredState,
  configParamArray,
  ...props
}) {
  const {infoSnackbar, errorSnackbar} = useSnackbar();
  const selectedConnector = useSelector(
    (state) => state?.data?.dataByResource?.selectedConnector
  );
  const [publicKey, setPublicKey] = useState(null);
  const [
    isConfigurationCapabilityEnabled,
    setIsConfigurationCapabilityEnabled,
  ] = useState(false);

  // Get MQTT client for the connector
  const connectorMqttClient = useContext(ConnectorMqttContext);

  // Get the public encryption key for the connector once connectorId is available
  const getPublicKey = async () => {
    if (!(selectedConnector?.deploymentId && selectedConnector?.connectorId)) {
      return null;
    }

    // TODO: This is temporary API for where Public Key is, subject to change
    const resp = await get({
      path: `deployments/${selectedConnector?.deploymentId}/settings/connector-${selectedConnector?.connectorId}-pub-key`,
    });
    // Convert the base64 encoded public key to a string and then load as OpenPGP Key
    const tmpPublicKey = Buffer.from(resp?.data, 'base64').toString('utf-8');
    const key = await openpgp.key.readArmored(tmpPublicKey);
    return key.keys;
  };

  // Encrypt the desired configuration
  const encryptConfig = async (config) => {
    const msg = await openpgp.message.fromText(JSON.stringify(config));
    const encryptedMsg = await openpgp.encrypt({
      message: msg,
      publicKeys: publicKey,
    });

    return encryptedMsg.data;
  };

  // Once connector is selected, check if configuration capability is enabled
  useEffect(() => {
    // Check if the connector has the capability to be configured
    setIsConfigurationCapabilityEnabled(
      isCapabilityEnabled(
        selectedConnector?.capabilities || [],
        CONFIGURATION_CAPABILITY_NAME
      )
    );
  }, [selectedConnector?.connectorId]);

  // Get public key of the connector if configuration is enabled
  useEffect(() => {
    if (isConfigurationCapabilityEnabled) {
      // Get the PGP Public key of the connector
      getPublicKey()
        .then((key) => {
          setPublicKey(key);
        })
        .catch((err) => {
          errorSnackbar(
            `Failed to get public key for the connector due to ${err}`
          );
        });
    }
  }, [isConfigurationCapabilityEnabled]);

  const onClick = async () => {
    try {
      const encryptedMessage = await encryptConfig(
        makeConnectorConfig(configParamArray, desiredState)
      );
      // Create a responseTopic and callback to handle messages to responseTopic
      const responseTopic = `deployment/${
        selectedConnector?.deploymentId
      }/connector/${
        selectedConnector?.connectorId
      }/configuration/${uuid()}/events`;
      await connectorMqttClient.subscribe(responseTopic, (_, message) => {
        // Decode the message to text
        const event = JSON.parse(message.toString());
        infoSnackbar(event.summary);
      });
      // Publish the encrypted configuration to the connector
      await connectorMqttClient.publish({
        topic: `deployment/${selectedConnector?.deploymentId}/connector/${selectedConnector?.connectorId}/configuration`,
        message: encryptedMessage,
        options: {
          qos: 2, // Exactly once
          properties: {
            responseTopic,
            messageExpiryInterval: 60, // expires after 1 minute
          },
        },
      });
    } catch (err) {
      errorSnackbar(
        `Failed to apply configuration to the connector due to ${err}`
      );
    }
  };
  return (
    isConfigurationCapabilityEnabled &&
    connectorMqttClient && (
      <>
        <Divider orientation="vertical">
          <Chip label="Or" size="small" />
        </Divider>
        <Grid item xs={12}>
          <Typography variant="h6">Automatic Configuration</Typography>
          <Typography
            color="#555967"
            fontFamily="Roboto"
            fontSize="14px"
            fontWeight="400"
            lineHeight="46px"
            letterSpacing="0.1px"
            textAlign="left"
          >
            Apply the configuration to the connector using the Configuration
            Service.
          </Typography>
          <Button onClick={onClick} {...props}>
            Apply
          </Button>
        </Grid>
      </>
    )
  );
}

ApplyConnectorConfiguration.propTypes = {
  desiredState: PropTypes.object.isRequired,
  configParamArray: PropTypes.array.isRequired,
};
