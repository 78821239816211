import {Card, CardContent, LinearProgress, Toolbar} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';

const PREFIX = 'AppBar';

const classes = {
  appBar: `${PREFIX}-appBar`,
  toolbar: `${PREFIX}-toolbar`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.appBar}`]: {
    minWidth: '750px',
    margin: '0.6em 0',
    overflow: 'visible',
  },

  [`& .${classes.toolbar}`]: {
    paddingLeft: '5px',
    paddingRight: '1.25rem',
  },
}));

function AppBar({loading, children}) {
  return (
    <Root>
      <Card className={classes.appBar}>
        <CardContent>
          <Toolbar className={classes.toolbar}>{children}</Toolbar>
          {loading && <LinearProgress />}
        </CardContent>
      </Card>
    </Root>
  );
}

AppBar.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

AppBar.defaultProps = {
  loading: false,
  children: null,
};

export default AppBar;
