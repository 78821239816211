import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
import {useState} from 'react';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';

const PREFIX = 'TextInput';

const classes = {
  root: `${PREFIX}-root`,
  inputProps: `${PREFIX}-inputProps`,
  inputSectionTextField: `${PREFIX}-inputSectionTextField`,
  eyeIcon: `${PREFIX}-eyeIcon`,
  eyeball: `${PREFIX}-eyeball`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    // border: '1px solid orange',
  },

  [`& .${classes.inputProps}`]: {
    height: '36px',
    padding: '0px',
  },

  [`& .${classes.inputSectionTextField}`]: {
    backgroundColor: theme.palette.surface.white,
    minWidth: '100%',
  },

  [`& .${classes.eyeIcon}`]: {
    marginRight: '5px',
  },

  [`& .${classes.eyeball}`]: {
    fontSize: '18px',
  }
}));

function TextInput({
  label,
  value,
  onChange,
  placeholder,
  helperText,
  tooltipText,
  isError,
  disabled,
  onEnterKey,
  secret,
}) {

  const [isSecretVisible, setIsSecretVisible] = useState(false);
  const inputProps = {
    className: classes.inputProps,
  };

  if (secret) {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <IconButton
          className={classes.eyeIcon}
          size="small"
          onClick={() => setIsSecretVisible(!isSecretVisible)}
          onMouseDown={(event) => event.preventDefault()}
        >
          {isSecretVisible ? (
            <VisibilityOff className={classes.eyeball} />
          ) : (
            <Visibility className={classes.eyeball} />
          )}
        </IconButton>
      </InputAdornment>
    );
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onEnterKey();
    }
  };

  return (
    <Root className={classes.root}>
      {label && <InputLabel displayText={label} tooltipText={tooltipText} />}
      <TextField
        disabled={disabled}
        variant="outlined"
        autoComplete="off"
        required
        type={secret && !isSecretVisible ? 'password' : 'text'}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        error={isError}
        helperText={helperText}
        InputProps={inputProps}
        className={classes.inputSectionTextField}
        placeholder={placeholder}
        onKeyPress={handleKeyPress}
      />
    </Root>
  );
}

TextInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  tooltipText: PropTypes.string,
  isError: PropTypes.bool,
  disabled: PropTypes.bool,
  onEnterKey: PropTypes.func,
  secret: PropTypes.bool,
};

TextInput.defaultProps = {
  label: '',
  placeholder: '',
  helperText: '',
  tooltipText: '',
  isError: false,
  disabled: false,
  onEnterKey: () => {},
  secret: false,
};

export default TextInput;
