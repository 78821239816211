import {
  RECEIVE_DELETE_RESOURCE,
  RECEIVE_DELETE_RESOURCE_ERROR,
  REQUEST_DELETE_RESOURCE,
} from 'redux/actions/dataActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';

const initialState = {};

function deletingByResource(state = initialState, action) {
  switch (action.type) {
    case REQUEST_DELETE_RESOURCE:
      return {
        ...state,
        [action.id]: true,
      };
    case RECEIVE_DELETE_RESOURCE:
    case RECEIVE_DELETE_RESOURCE_ERROR:
      return {
        ...state,
        [action.id]: false,
      };
    default:
      return state;
  }
}

function deleting(state = initialState, action) {
  switch (action.type) {
    case REQUEST_DELETE_RESOURCE:
    case RECEIVE_DELETE_RESOURCE:
    case RECEIVE_DELETE_RESOURCE_ERROR:
      return {
        ...state,
        [action.resource]: deletingByResource(state[action.resource], action),
      };
    case CLEAR_STORE:
      return initialState;
    default:
      return state;
  }
}

export default deleting;
