import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import {
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CERTIFICATES_EXTENSIONS} from 'utils/constants';

import {stylesFromFigma} from 'themes/stylesFromFigma';
import {isEmpty, validateCertificate} from 'utils/utils';
import {ReactComponent as DirectoryIcon} from 'icons/directory.svg';
import {openErrorDialog} from 'redux/actions/errorDialogActions';
import FileInputDropbox from '../createWizard/FileDropBox';
import CopyAwcCommand from '../createWizard/CopyAwcCommand';
import {activeDirectoryConfigParams} from '../createWizard/wizardConstants';
import CapabilityStatusDisplay from './CapabilityStatus';
import {
  useWizardState,
  generateAwcCommand,
} from '../createWizard/wizardConfigState';

const PREFIX = 'ADIntegrationConfiguration';

const classes = {
  fileRow: `${PREFIX}-fileRow`,
  flexRow: `${PREFIX}-flexRow`,
  bodyText: `${PREFIX}-bodyText`,
  header: `${PREFIX}-header`,
  subheader: `${PREFIX}-subheader`,
  form: `${PREFIX}-form`,
  spacing: `${PREFIX}-spacing`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.fileRow}`]: {
    display: 'flex',
    flexDirection: 'row',
  },

  [`& .${classes.flexRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '32px',
    marginBottom: '16px',
  },

  [`& .${classes.bodyText}`]: {
    ...stylesFromFigma.connectorPanelBodyText,
    color: '#555967',
    padding: '16px 0px',
  },

  [`& .${classes.header}`]: {
    ...stylesFromFigma.capabilitySectionTitle,
    marginLeft: '16px',
  },

  [`& .${classes.subheader}`]: {
    ...stylesFromFigma.capabilitySubHeader,
    marginTop: '16px',
  },

  [`& .${classes.form}`]: {marginTop: '12px'},

  [`& .${classes.spacing}`]: {
    margin: '16px',
  },
}));

function ADIntegrationConfiguration({adConfigCapData}) {
  const {configState, setConfigState} = useWizardState();
  const selectedConnector = useSelector(
    (state) => state?.data?.dataByResource?.selectedConnector
  );
  const [configureCommand, setConfigureCommand] = useState('');
  const [certName, setCertName] = useState('');
  const dispatch = useDispatch();

  const createConfigureCommand = () => {
    const baseCommand = 'sudo /usr/local/bin/anyware-connector configure';
    const fullCommand = generateAwcCommand({
      baseCommand,
      configParamArray: activeDirectoryConfigParams,
      configState,
    });
    setConfigureCommand(fullCommand);
  };

  const displayCertLoadErrorMessage = (errorMessage) => {
    const defaultErrorMessage = 'Invalid certificate file';
    dispatch(
      openErrorDialog(
        'File does not contain a valid PEM formatted certificate ',
        errorMessage || defaultErrorMessage
      )
    );
  };

  const handleCertString = (file, fileName) => {
    const updatedState = {...configState};
    updatedState.ldapsCaCert = file;
    updatedState.ldapsInsecure = 'false';
    setConfigState(updatedState);
    setCertName(fileName);
  };

  useEffect(() => {
    createConfigureCommand();
  }, [
    ...activeDirectoryConfigParams.map((param) => configState[param.fieldName]),
  ]);

  return (
    <Root>
      <div className={classes.flexRow}>
        <DirectoryIcon />
        <Typography className={classes.header}>
          Active Directory Setup
        </Typography>
      </div>
      <Divider />
      <CapabilityStatusDisplay
        className={classes.spacing}
        capabilityData={adConfigCapData}
        isLoading={isEmpty(selectedConnector)}
      />
      <Typography className={classes.bodyText}>
        Configure the Active Directory for your connector to manage network
        resources, simplify user administration, and enhance security. Once
        completed, run the provided command on the connector server to set up
        Active Directory.
      </Typography>
      <Typography className={classes.subheader}>Integration Method</Typography>
      <Typography className={classes.bodyText}>
        Select your preferred integration method:
      </Typography>
      <FormControl className={classes.form}>
        <RadioGroup
          name="plaintextLdap"
          onChange={(event) => {
            const updatedState = {...configState};
            const {name, value} = event.target;
            updatedState[name] = value;
            // Also need to update ldapsInsecure, as there is no way to explicitly set this for now
            updatedState.ldapsInsecure = String(value !== 'true');
            setConfigState(updatedState);
          }}
          value={String(configState.plaintextLdap)}
        >
          <FormControlLabel
            className={classes.radio}
            control={<Radio color="primary" />}
            label="LDAPS"
            value="false"
          />
          <Typography variant="body2" className={classes.secondaryText}>
            Choose LDAPS for secure, encrypted communication with your Active
            Directory. Ideal for production deployments.
          </Typography>
          <FormControlLabel
            className={classes.radio}
            control={<Radio color="primary" />}
            label="LDAP"
            value="true"
          />
          <Typography className={classes.secondaryText} variant="body2">
            Choose LDAP for unencrypted communication. Not recommended for
            production; use for testing or non-sensitive environments.
          </Typography>
        </RadioGroup>
      </FormControl>
      <Typography className={classes.subheader}>Certificate</Typography>
      <Typography className={classes.bodyText}>
        Please select your PEM formatted LDAPS certificate. This certificate is
        used to trust the connection to the Active Directory server.
      </Typography>
      <FileInputDropbox
        acceptedMimeType="application/x-pem-file"
        acceptedExtensions={CERTIFICATES_EXTENSIONS}
        fileResultCallback={handleCertString}
        validateAcceptedFile={validateCertificate}
        onAcceptedFileError={displayCertLoadErrorMessage}
      />
      {certName && (
        <div className={classes.fileRow}>
          <Typography className={classes.bodyText}>
            Certificate file: {certName || 'not selected'}
          </Typography>
        </div>
      )}
      <CopyAwcCommand
        isCopyEnabled
        commandToCopy={configureCommand}
        desiredState={configState}
        isApplyEnabled={true}
        commandType="configure"
        configParamArray={activeDirectoryConfigParams}
      />
    </Root>
  );
}

ADIntegrationConfiguration.propTypes = {
  adConfigCapData: PropTypes.shape({
    enabled: PropTypes.bool,
    enterpriseReadiness: PropTypes.bool,
    recommendedActions: PropTypes.arrayOf(PropTypes.string),
  }),
};

ADIntegrationConfiguration.defaultProps = {
  adConfigCapData: {
    enabled: false,
    enterpriseReadiness: false,
    recommendedActions: [],
  },
};

export default ADIntegrationConfiguration;
