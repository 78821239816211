import IconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import {Grid} from '@mui/material';
import IconTextButtonWarning from 'components/common/IconTextButtonWarning';
import AzureExpiryClientSecretDialog from 'components/common/AzureExpiryClientSecretDialog';
import AppBar from 'components/common/AppBar';
import {openErrorDialog} from 'redux/actions/errorDialogActions';
import {DASHBOARD_LINK, DEPLOYMENT_DELETE_ERROR_MESSAGE} from 'utils/constants';

const PREFIX = 'EditDeploymentAppBar';

const classes = {
  appBar: `${PREFIX}-appBar`,
  toolbarExpanded: `${PREFIX}-toolbarExpanded`,
  grow: `${PREFIX}-grow`,
  actionButton: `${PREFIX}-actionButton`,
  statusText: `${PREFIX}-statusText`,
  deploymentName: `${PREFIX}-deploymentName`,
  disabled: `${PREFIX}-disabled`,
  errorIcon: `${PREFIX}-errorIcon`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.appBar}`]: {
    minWidth: '750px',
  },
  [`& .${classes.toolbarExpanded}`]: {
    height: '72px',
    paddingLeft: '5px',
    paddingRight: '1.25rem',
  },
  [`& .${classes.grow}`]: {
    flexGrow: 1,
    fontSize: '14px',
    marginLeft: '5px',
  },
  [`& .${classes.actionButton}`]: {
    marginLeft: '1.2rem',
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 0.8,
    },
  },
  [`& .${classes.statusText}`]: {
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.078125rem',
  },
  [`& .${classes.deploymentName}`]: {
    color: theme.palette.primary.main,
    fontSize: '1.125rem',
    fontWeight: 500,
    letterSpacing: '0.014375rem',
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.disabled}`]: {
    backgroundColor: 'transparent',
    opacity: 0.5,
  },
  [`& .${classes.errorIcon}`]: {
    color: 'red',
    cursor: 'pointer',
    marginLeft: '10px',
  },
}));

function EditDeploymentAppBar({
  deploymentName,
  deploymentStatus,
  fetchingDeployment,
  onBackHistory,
  onDelete,
}) {
  const dispatch = useDispatch();
  const [showAzureExpiryDialog, setShowAzureExpiryDialog] = useState(false);

  const {data: cloudServiceAccountsExpiryInfo} = useSelector(
    (state) =>
      state.data.dataByResource.cloudServiceAccountsExpiryInfo || {data: {}}
  );

  const {azureSecretsExpiring} = useSelector(
    (state) => state.data.dataByResource || {azureSecretsExpiring: 0}
  );

  const handleErrorIconClick = () =>
    dispatch(
      openErrorDialog(
        'There was an error deleting this deployment',
        DEPLOYMENT_DELETE_ERROR_MESSAGE
      )
    );

  return (
    <Root>
      <AppBar loading={fetchingDeployment}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <IconButton
                component={Link}
                to={DASHBOARD_LINK}
                data-testid="back-arrow-button"
                onClick={onBackHistory}
                size="large"
              >
                <KeyboardArrowLeft />
              </IconButton>

              <div className={classes.deploymentName}>
                {fetchingDeployment ? 'Loading deployment...' : deploymentName}

                {deploymentStatus === 'error' && (
                  <ErrorIcon
                    className={classes.errorIcon}
                    onClick={handleErrorIconClick}
                  />
                )}

                {azureSecretsExpiring > 0 && (
                  <IconTextButtonWarning
                    icon={<WarningIcon color="error" />}
                    buttonText="Details"
                    onClick={() => setShowAzureExpiryDialog(true)}
                  >
                    Azure client secret will expire soon
                  </IconTextButtonWarning>
                )}
              </div>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              onClick={onDelete}
              disabled={fetchingDeployment}
              className={classes.actionButton}
              startIcon={<DeleteIcon />}
              color="info"
            >
              <span className={classes.statusText}>DELETE</span>
            </Button>
          </Grid>
        </Grid>

        <AzureExpiryClientSecretDialog
          open={showAzureExpiryDialog}
          onClose={() => setShowAzureExpiryDialog(false)}
          expiryInfo={cloudServiceAccountsExpiryInfo}
        />
      </AppBar>
    </Root>
  );
}

EditDeploymentAppBar.propTypes = {
  deploymentName: PropTypes.string,
  deploymentStatus: PropTypes.string.isRequired,
  fetchingDeployment: PropTypes.bool,
  onBackHistory: PropTypes.func,
  onDelete: PropTypes.func,
};

EditDeploymentAppBar.defaultProps = {
  deploymentName: '',
  fetchingDeployment: false,
  onBackHistory: () => {},
  onDelete: () => {},
};

export default EditDeploymentAppBar;
