import {styled} from '@mui/material/styles';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';
import {isEmpty} from 'utils/utils';

const PREFIX = 'CAMTooltip';

const classes = {
  infoIcon: `${PREFIX}-infoIcon`,
  warningIcon: `${PREFIX}-warningIcon`,
  infoTooltip: `${PREFIX}-infoTooltip`,
  warningTooltip: `${PREFIX}-warningTooltip`,
  infoArrow: `${PREFIX}-infoArrow`,
  warningArrow: `${PREFIX}-warningArrow`,
};

const customTooltipProps = {
  isWarning: 'isWarning',
};

const commonStyles = {
  icon: {
    cursor: 'pointer',
    margin: '0 6px',
    verticalAlign: 'text-bottom',
    overflow: 'hidden',
    height: '18px',
  },
  tooltip: {
    fontSize: '0.75rem',
  },
};

const CustomTooltip = styled(
  ({className, ...props}) => (
    <Tooltip {...props} arrow classes={{popper: className}} />
  ),
  {
    shouldForwardProp: (prop) =>
      !Object.values(customTooltipProps).includes(prop),
  }
)(({theme, ...props}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: props.isWarning
      ? theme.palette.warning.main
      : theme.palette.primary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    ...commonStyles.tooltip,
    backgroundColor: props.isWarning
      ? theme.palette.warning.main
      : theme.palette.primary.main,
  },
}));

const Root = styled('span')(({theme}) => ({
  [`& .${classes.infoIcon}`]: {
    ...commonStyles.icon,
    color: theme.palette.primary.main,
  },
  [`& .${classes.warningIcon}`]: {
    ...commonStyles.icon,
    color: theme.palette.warning.main,
  },
}));

function CAMTooltip({text, placement, children, isWarning, testIdPrefix}) {
  let Icon;

  const customProps = {
    [customTooltipProps.isWarning]: isWarning,
  };

  if (isWarning) {
    Icon = (
      <ErrorIcon
        className={classes.warningIcon}
        data-testid={`${testIdPrefix}-warning`}
      />
    );
  } else {
    Icon = (
      <HelpIcon
        className={classes.infoIcon}
        data-testid={`${testIdPrefix}-help`}
      />
    );
  }

  return (
    <Root>
      <CustomTooltip arrow placement={placement} title={text} {...customProps}>
        {isEmpty(children) ? Icon : <div>{children}</div>}
      </CustomTooltip>
    </Root>
  );
}

CAMTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  placement: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  isWarning: PropTypes.bool,
  testIdPrefix: PropTypes.string,
};

CAMTooltip.defaultProps = {
  children: undefined,
  placement: 'right',
  isWarning: false,
  testIdPrefix: 'tooltip',
};

export default CAMTooltip;
