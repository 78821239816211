import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const PREFIX = 'LearnMoreCard';

const classes = {
  card: `${PREFIX}-card`
};

const StyledTypography = styled(Typography)(() => ({
  [`&.${classes.card}`]: {
    padding: '5px',
    fontSize: '12px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    color: 'black',
  }
}));

function LearnMoreCard({children}) {


  return <StyledTypography className={classes.card}>{children}</StyledTypography>;
}

LearnMoreCard.propTypes = {
  children: PropTypes.string,
};

LearnMoreCard.defaultProps = {
  children: '',
};

export default LearnMoreCard;
