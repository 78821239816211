import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {loadItemsFromFeed} from 'components/releaseNotes/releaseNotesParser';
import config from 'config';
import NotificationSvg from 'icons/notification.svg';
import {
  CAC_V2_RELEASE_NOTES_FEED_URL,
  AWM_RELEASE_NOTES_FEED_URL,
  RELEASE_NOTES_BETA,
  RELEASE_NOTES_CAC,
  RELEASE_NOTES_GA,
} from 'utils/constants';
import CasmCookies from 'utils/cookies';
import {getLastLoginDate} from 'utils/utils';
import NotificationList, {unreadNotificationCount} from './NotificationList';

const formatString = 'MMMM DD, YYYY';

function NotificationsDropDown() {
  const decodedToken = CasmCookies.getDecodedAuthToken();
  const {notifications} = useSelector((state) => state) || {notifications: []};
  const [anchorEl, setAnchorEl] = useState(null);
  const [newReleaseNotes, setNewReleaseNotes] = useState([]);
  const [notesAvailable, setNotesAvailable] = useState(false);
  const lastLogin = getLastLoginDate().valueOf();
  const releaseNotesViewed =
    !!window.sessionStorage.getItem('releaseNotesViewed');

  let notificationsForCurrentUser = [];
  if (decodedToken?.oid) {
    notificationsForCurrentUser = notifications
      .filter(({oid}) => oid === decodedToken.oid)
      .map(({oid, ...n}) => n);
  }

  function handleOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  useEffect(() => {
    const loadFeed = async () => {
      const feedResponses = await Promise.all([
        loadItemsFromFeed(AWM_RELEASE_NOTES_FEED_URL, RELEASE_NOTES_GA),
        loadItemsFromFeed(CAC_V2_RELEASE_NOTES_FEED_URL, RELEASE_NOTES_CAC),
        loadItemsFromFeed(AWM_RELEASE_NOTES_FEED_URL, RELEASE_NOTES_BETA),
      ]);
      const releaseNotesDates = [].concat(...feedResponses);
      if (releaseNotesDates) {
        setNewReleaseNotes(
          releaseNotesDates.filter((element) => {
            const releaseDate = moment(element.date, formatString);
            return lastLogin <= releaseDate.valueOf();
          })
        );
      }
    };
    if (!config.STANDALONE) {
      loadFeed();
    }
  }, [lastLogin]);

  useEffect(() => {
    if (newReleaseNotes.length > 0) {
      newReleaseNotes.sort((a, b) => b.timestamp - a.timestamp);
    }
    setNotesAvailable(!releaseNotesViewed && newReleaseNotes.length > 0);
  }, [releaseNotesViewed, newReleaseNotes]);

  const sanitizeNewReleaseNotes = ({date, timestamp, releaseType}) => ({
    key: `${releaseType}-${timestamp}`,
    date,
    timestamp,
    releaseType,
  });

  return <>
    <IconButton
      id="bell-icon-button"
      data-testid="bell-icon-button"
      onClick={handleOpen}
      size="large">
      <Badge
        color="secondary"
        variant="dot"
        overlap="circular"
        invisible={
          (config.STANDALONE || releaseNotesViewed || !notesAvailable) &&
          unreadNotificationCount(notificationsForCurrentUser) === 0
        }
      >
        <img src={NotificationSvg} alt="" />
      </Badge>
    </IconButton>
    <NotificationList
      anchorEl={anchorEl}
      onClose={handleClose}
      notifications={notificationsForCurrentUser}
      releaseNotes={newReleaseNotes.map(sanitizeNewReleaseNotes)}
      releaseNotesAvailable={!releaseNotesViewed && notesAvailable}
    />
  </>;
}

export default NotificationsDropDown;
