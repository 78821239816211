import {CLEAR_STORE} from 'redux/actions/globalActions';
import {
  CHECK_ITEM,
  CHECK_ITEMS,
  UNCHECK_ALL,
  UNCHECK_ITEM,
} from 'redux/actions/tableSelectActions';
import {mapResourceToIdField} from 'utils/Mappings';

const defaultState = {};

function getItemId(action) {
  const idField = mapResourceToIdField(action.resource);
  return action.item[idField];
}

function checkItem(state, action) {
  const itemId = getItemId(action);

  return {...state, [itemId]: action.item};
}

function uncheckItem(state, action) {
  const itemId = getItemId(action);

  const {[itemId]: uncheckedItem, ...newState} = state;
  return newState;
}

function checkItems(state, action) {
  const {items} = action;
  const idField = mapResourceToIdField(action.resource);

  const toAdd = {};

  items.forEach((item) => {
    const id = item[idField];
    toAdd[id] = item;
  });

  return {...state, ...toAdd};
}

function tableSelectByResource(state = {}, action) {
  switch (action.type) {
    case CHECK_ITEM:
      return checkItem(state, action);
    case UNCHECK_ITEM:
      return uncheckItem(state, action);
    case CHECK_ITEMS:
      return checkItems(state, action);
    case UNCHECK_ALL:
      return {};
    default:
      return state;
  }
}

function tableSelect(state = defaultState, action) {
  switch (action.type) {
    case CHECK_ITEM:
    case UNCHECK_ITEM:
    case CHECK_ITEMS:
    case UNCHECK_ALL:
      return {
        ...state,
        [action.resource]: tableSelectByResource(
          state[action.resource],
          action
        ),
      };
    case CLEAR_STORE:
      return defaultState;
    default:
      return state;
  }
}

export default tableSelect;
