import {styled} from '@mui/material/styles';
import classNames from 'classnames';
import {useEffect, useState} from 'react';
import {linkTo} from 'utils/utils';

const PREFIX = 'BetaToggle';

const classes = {
  checkIcon: `${PREFIX}-checkIcon`,
  cloudIcon: `${PREFIX}-cloudIcon`,
  input: `${PREFIX}-input`,
  root: `${PREFIX}-root`,
  rootDisabled: `${PREFIX}-rootDisabled`,
  rootEnabled: `${PREFIX}-rootEnabled`,
  slider: `${PREFIX}-slider`,
  sliderDisabled: `${PREFIX}-sliderDisabled`,
  sliderEnabled: `${PREFIX}-sliderEnabled`,
  text: `${PREFIX}-text`,
  textDisabled: `${PREFIX}-textDisabled`,
  textEnabled: `${PREFIX}-textEnabled`,
};

const Root = styled('a')(({theme}) => ({
  [`& .${classes.checkIcon}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.cloudIcon}`]: {
    height: '75%',
    width: '75%',
  },

  [`& .${classes.input}`]: {
    display: 'none',
  },

  [`&.${classes.root}`]: {
    backgroundColor: '#8d8d8d',
    borderRadius: '2rem',
    cursor: 'pointer',
    display: 'inline-block',
    height: '2rem',
    paddingRight: '1rem',
    position: 'relative',
    width: '5rem',
  },

  [`&.${classes.rootDisabled}`]: {
    backgroundColor: '#8d8d8d',
  },

  [`&.${classes.rootEnabled}`]: {
    backgroundColor: '#0076a9',
  },

  [`& .${classes.slider}`]: {
    alignItems: 'center',
    backgroundColor: '#e0e0e0',
    borderRadius: '50%',
    display: 'flex',
    height: '1.6rem',
    justifyContent: 'center',
    position: 'absolute',
    top: '0.2rem',
    width: '1.6rem',
  },

  [`& .${classes.sliderDisabled}`]: {
    left: '0.2rem',
  },

  [`& .${classes.sliderEnabled}`]: {
    right: '0.2rem',
  },

  [`& .${classes.text}`]: {
    alignItems: 'center',
    color: '#ffffff',
    display: 'flex',
    fontSize: '0.9rem',
    height: '100%',
    justifyContent: 'right',
    position: 'absolute',
  },

  [`& .${classes.textDisabled}`]: {
    justifyContent: 'right',
    right: '1rem',
  },

  [`& .${classes.textEnabled}`]: {
    justifyContent: 'left',
    left: '1rem',
  },
}));

function BetaToggle() {
  const [isBetaEnabled, setIsBetaEnabled] = useState(false);

  const getLink = () => {
    const currentPath = window.location.pathname;

    if (isBetaEnabled) {
      return linkTo(currentPath.replace('/beta-ui', ''));
    }
    return linkTo(`/beta-ui${currentPath}`);
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    setIsBetaEnabled(currentPath.includes('/beta-ui/app'));
  }, []);

  return (
    <Root
      data-testid="beta-toggle-switch"
      href={getLink()}
      className={classNames(classes.root, {
        [classes.rootDisabled]: !isBetaEnabled,
        [classes.rootEnabled]: isBetaEnabled,
      })}
    >
      <span
        className={classNames(classes.text, {
          [classes.textDisabled]: !isBetaEnabled,
          [classes.textEnabled]: isBetaEnabled,
        })}
      >
        Beta
      </span>
      <span
        className={classNames(classes.slider, {
          [classes.sliderDisabled]: !isBetaEnabled,
          [classes.sliderEnabled]: isBetaEnabled,
        })}
      />
    </Root>
  );
}

export default BetaToggle;
