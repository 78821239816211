import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import CAMPagination from 'components/common/Pagination';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import ServiceAccountsTable from '../common/ServiceAccountsTable';

const headers = [
  {
    id: 'machineId',
    label: 'Workstation ID',
  },
  {
    id: 'keyName',
    label: 'Account Name',
  },
  {
    id: 'createdOn',
    label: 'Created on',
  },
];

function EditDeploymentWorkstationsServiceAccountsTab({
  serviceAccounts,
  total,
  rowsPerPage,
  page,
  isLoadingServiceAccounts,
  onDeleteServiceAccount,
  onPageChange,
  onRowsPerPageChange,
}) {
  const dispatch = useDispatch();

  const [filteredServiceAccounts, setFilteredServiceAccounts] =
    useState(serviceAccounts);
  const [serviceAccountsSearchTerm, setServiceAccountsSearchTerm] =
    useState('');

  const handleSearchInputChange = (event) => {
    const searchInputText = event.target.value || '';
    setServiceAccountsSearchTerm(searchInputText.trim().toLowerCase());
  };

  const handleDeleteServiceAccount = (serviceAcc) => {
    dispatch(
      openDialog(
        'Delete Service Account?',
        `Are you sure you want to delete Service Account ${serviceAcc.keyName}?`,
        () => onDeleteServiceAccount(serviceAcc)
      )
    );
  };

  useEffect(() => {
    // Filter by keyName or machineId
    setFilteredServiceAccounts(
      serviceAccounts.filter(
        (key) =>
          key.keyName.toLowerCase().includes(serviceAccountsSearchTerm) ||
          key.machineId.toLowerCase().includes(serviceAccountsSearchTerm)
      )
    );
  }, [serviceAccounts, serviceAccountsSearchTerm]);

  const renderPagination = () => (
    <CAMPagination
      dataTestId="dsa-table-pagination"
      rowsPerPageOptions={[10, 25, 50]}
      component="div"
      total={total}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );

  return (
    <CAMCard>
      <GridN singleColumn>
        <SectionHeader displayText="APPROVED MONITOR INSTALLATIONS" />
      </GridN>
      <GridN singleColumn>
        <ServiceAccountsTable
          handleSearchInputChange={handleSearchInputChange}
          serviceAccounts={filteredServiceAccounts}
          onRemove={handleDeleteServiceAccount}
          isLoading={isLoadingServiceAccounts}
          showCreateServiceAccountTip={false}
          headers={headers}
        />
        {renderPagination()}
      </GridN>
    </CAMCard>
  );
}

EditDeploymentWorkstationsServiceAccountsTab.propTypes = {
  serviceAccounts: PropTypes.array,
  total: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  isLoadingServiceAccounts: PropTypes.bool,
  onDeleteServiceAccount: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
};

EditDeploymentWorkstationsServiceAccountsTab.defaultProps = {
  serviceAccounts: {},
  total: 0,
  rowsPerPage: 0,
  page: 0,
  isLoadingServiceAccounts: false,
  onDeleteServiceAccount: () => {},
  onPageChange: () => {},
  onRowsPerPageChange: () => {},
};

export default EditDeploymentWorkstationsServiceAccountsTab;
