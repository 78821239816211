export const TOGGLE_DATE_FILTER = 'TOGGLE_DATE_FILTER';
export function toggleDateFilter() {
  return {
    type: TOGGLE_DATE_FILTER,
  };
}

export const SET_DATE_FILTER_PARAMS = 'SET_DATE_FILTER_PARAMS';
export const setDateFilterParams = (params) => ({
  type: SET_DATE_FILTER_PARAMS,
  params,
});
