import PropTypes from 'prop-types';

const defaultColor = '#1B1C20';

function DateIcon({iconColor}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={iconColor}
        d="M20 3.81818H19V2H17V3.81818H7V2H5V3.81818H4C2.9 3.81818 2 4.63636 2 5.63636V20.1818C2 21.1818 2.9 22 4 22H20C21.1 22 22 21.1818 22 20.1818V5.63636C22 4.63636 21.1 3.81818 20 3.81818ZM20 20.1818H4V10.1818H20V20.1818ZM20 8.36364H4V5.63636H20V8.36364Z"
      />
    </svg>
  );
}

DateIcon.propTypes = {
  iconColor: PropTypes.string,
};

DateIcon.defaultProps = {
  iconColor: defaultColor,
};

export default DateIcon;
