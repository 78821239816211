import {useState, useEffect, Fragment} from 'react';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import shellEscape from 'shell-escape';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import SuccessIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';

import {useDispatch, useSelector} from 'react-redux';
import useInterval from 'hooks/useInterval';
import {
  CONNECTOR_EVENT_TYPE_CONFIGURATION_FAILED,
  CONNECTOR_EVENT_TYPE_CONFIGURATION_SUCCESSFUL,
  CONNECTOR_EVENT_TYPE_TIMEOUT,
  CONNECTOR_EVENT_VERIFICATION_INTERVAL,
  EXISTING_CONNECTOR_TOKEN,
} from 'utils/constants';
import {checkEventsInstallStatus, copyToClipboard} from 'utils/utils';
import useBetaDialog from 'hooks/useBetaDialog';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import {ReactComponent as InstallSuccessIcon} from 'icons/connectorSuccess.svg';
import {ReactComponent as WorldMapIcon} from 'icons/PixelWorldMap.svg';
import {post} from 'api/Api';
import {handleApiError} from 'redux/actions/dataActions';
import config from 'config';
import useSnackbar from 'hooks/useSnackbar';
import {mapResourceToPath} from 'utils/Mappings';
import Alert from './Alert';

const PREFIX = 'InstallationDetails';

const classes = {
  card: `${PREFIX}-card`,
  installingHeaderRoot: `${PREFIX}-installingHeaderRoot`,
  cardTitle: `${PREFIX}-cardTitle`,
  pixelMap: `${PREFIX}-pixelMap`,
  eventsSection: `${PREFIX}-eventsSection`,
  eventRow: `${PREFIX}-eventRow`,
  successCheck: `${PREFIX}-successCheck`,
  errorCross: `${PREFIX}-errorCross`,
  warningTriangle: `${PREFIX}-warningTriangle`,
  loadingIcon: `${PREFIX}-loadingIcon`,
  installSuccessDisplay: `${PREFIX}-installSuccessDisplay`,
  installSuccessIcon: `${PREFIX}-installSuccessIcon`,
  installSuccessTitleText: `${PREFIX}-installSuccessTitleText`,
  installSuccessBodyText: `${PREFIX}-installSuccessBodyText`,
  summaryList: `${PREFIX}-summaryList`,
  actionList: `${PREFIX}-actionList`,
  errorActionText: `${PREFIX}-errorActionText`,
  errorErrorText: `${PREFIX}-errorErrorText`,
  noPaddingTopBottom: `${PREFIX}-noPaddingTopBottom`,
  errorDetailList: `${PREFIX}-errorDetailList`,
  subheader: `${PREFIX}-subheader`,
  bodyText: `${PREFIX}-bodyText`,
  quickStartButton: `${PREFIX}-quickStartButton`,
};
const errorTextStyles = {
  color: '#5F2120',
};
const iconStyles = {
  height: '22px',
  width: '22px',
  margin: '0px 44px',
};
const Root = styled('div')(() => ({
  [`&.${classes.card}`]: {
    marginTop: '60px',
    borderRadius: '4px',
    overflow: 'hidden',
    width: '100%',
    color: 'white',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.12)',
  },

  [`& .${classes.installingHeaderRoot}`]: {
    backgroundColor: '#9C27B0',
    height: '56px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },

  [`& .${classes.cardTitle}`]: {
    marginLeft: '24px',
    alignSelf: 'center',
    fontSize: '20px',
    fontWeight: '700',
    letterSpacing: '0.5px',
    lineHeight: '32px',
  },

  [`& .${classes.pixelMap}`]: {
    width: '720px',
    marginLeft: 'auto',
    marginTop: '-70px',
  },

  [`& .${classes.eventsSection}`]: {
    margin: '24px 0px',
    color: 'black',
  },

  [`& .${classes.eventRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 0px',
  },

  [`& .${classes.successCheck}`]: {
    ...iconStyles,
    color: '#1D855A',
  },

  [`& .${classes.errorCross}`]: {
    ...iconStyles,
    color: '#D32F2F',
  },

  [`& .${classes.warningTriangle}`]: {
    ...iconStyles,
    color: '#ED6C02',
  },

  [`& .${classes.loadingIcon}`]: {...iconStyles},

  [`&.${classes.installSuccessDisplay}`]: {
    margin: 'auto',
    textAlign: 'center',
  },

  [`& .${classes.installSuccessIcon}`]: {
    height: '80px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  [`& .${classes.installSuccessTitleText}`]: {
    marginTop: '8px',
    color: '#000000',
    fontSize: '18px',
    fontWeight: '700',
    height: '23px',
    lineHeight: '23.4px',
  },

  [`& .${classes.installSuccessBodyText}`]: {
    marginTop: '8px',
    color: '#6b7082',
    fontSize: '14px',
    fontWeight: '400',
    height: '18px',
    lineHeight: '18.2px',
  },

  [`& .${classes.summaryList}`]: {
    ...errorTextStyles,
    paddingLeft: '8px',
    listStylePosition: 'outside',
  },

  [`& .${classes.actionList}`]: {
    padding: '8px 8px 8px 16px',
    margin: '0px',
  },

  [`& .${classes.errorActionText}`]: {
    fontSize: '14px',
    fontWeight: '400',
  },

  [`& .${classes.errorErrorText}`]: {
    fontSize: '14px',
    fontWeight: '300',
  },

  [`& .${classes.noPaddingTopBottom}`]: {
    paddingTop: '0',
    paddingBottom: '0',
  },

  [`& .${classes.errorDetailList}`]: {
    margin: '8px',
    padding: '8px',
  },

  [`& .${classes.subheader}`]: {...stylesFromFigma.connectorPanelSubheader},
  [`& .${classes.bodyText}`]: {...stylesFromFigma.connectorPanelBodyText},
  [`& .${classes.quickStartButton}`]: {
    ...stylesFromFigma.primaryActionButton,
    marginTop: '16px',
  },
}));

const INSTALL_STATE = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  IN_PROGRESS: 'INPROGRESS',
  TIMEOUT: 'TIMEOUT',
};

const TIMEOUT_ERROR = {
  summary: 'Connector installation timed out',
  action: 'Check the Connector logs for more details',
  error:
    'No installation updates were received from the connector in the last 25 minutes. ' +
    'This may indicate that the Connector lost the ability to communicate with Anyware Manager.',
};
function InstallationDetails({events}) {
  const dispatch = useDispatch();
  const {successSnackbar} = useSnackbar();
  const [stateType, setStateType] = useState(INSTALL_STATE.IN_PROGRESS);
  const [dialogDomainName, setDialogDomainName] = useState('');

  const selectedConnector = useSelector(
    (state) => state?.data?.dataByResource?.selectedConnector
  );

  const createCommand = async () => {
    const generateExistingConnectorToken = async () => {
      let token;
      const path = mapResourceToPath(EXISTING_CONNECTOR_TOKEN, {
        connectorId: selectedConnector?.connectorId,
      });
      try {
        const resp = await post({path});
        token = resp.data.token;
      } catch (error) {
        dispatch(handleApiError(error));
      }
      return token;
    };

    const token = await generateExistingConnectorToken();
    if (token) {
      const minimalCommand = `sudo /usr/local/bin/anyware-connector configure --manager-url ${
        config.GATEWAY_ADDRESS
      } --accept-policies --token ${token} --domain ${shellEscape([
        dialogDomainName,
      ])} --enable-plaintext-ldap=true --ldaps-insecure=false --enable-ad-sync=false`;
      copyToClipboard(minimalCommand);
      successSnackbar('Quick start command copied to clipboard');
    }

    setDialogDomainName('');
  };

  const {toggleDialog, renderDialog} = useBetaDialog({
    title: 'Quick start installation command',
    message:
      'This command will install the connector with minimal configuration. You can configure the connector later.',
    primaryActionText: 'Generate command',
    userInputForm: (
      <TextField
        className={classes.textField}
        value={dialogDomainName}
        label="Domain name"
        type="text"
        onChange={(event) => setDialogDomainName(event.target.value)}
        variant="outlined"
        autoComplete="off"
        fullWidth
      />
    ),
    onPrimaryAction: createCommand,
    userInputValidated: Boolean(dialogDomainName),
  });

  function getConfigureStatus() {
    const installStatus = checkEventsInstallStatus(events);
    switch (installStatus) {
      case CONNECTOR_EVENT_TYPE_CONFIGURATION_SUCCESSFUL:
        return INSTALL_STATE.SUCCESS;
      case CONNECTOR_EVENT_TYPE_CONFIGURATION_FAILED:
        return INSTALL_STATE.FAILED;
      case CONNECTOR_EVENT_TYPE_TIMEOUT:
        return INSTALL_STATE.TIMEOUT;
      default:
        return INSTALL_STATE.IN_PROGRESS;
    }
  }

  const generateErrorDescriptions = () => {
    function ErrorDetails({error}) {
      const {summary, data: {errors = []} = {}} = error;
      return (
        <li className={classes.summaryList}>
          {summary}
          <ul className={classes.actionList}>
            {errors.map((errorItem) => (
              <Fragment key={`error-details-${summary}-${errorItem.action}`}>
                <li className={classes.errorActionText}>{errorItem.action}</li>
                <li className={classes.errorErrorText}>{errorItem.error}</li>
              </Fragment>
            ))}
          </ul>
        </li>
      );
    }

    const errorEvents = events.filter(
      (event) =>
        event.eventType === 'event' &&
        event.level === 'error' &&
        event.data?.errors
    );

    return errorEvents.map((errorEvent) => (
      <ul key={`error-summary-${errorEvent?.summary}`}>
        <ErrorDetails error={errorEvent} className={classes.errorDetailList} />
      </ul>
    ));
  };

  useInterval(
    () => {
      const state = getConfigureStatus(events);
      setStateType(state);
    },
    CONNECTOR_EVENT_VERIFICATION_INTERVAL,
    true
  ); // true to indicate immediate polling

  useEffect(() => {
    const state = getConfigureStatus(events);
    setStateType(state);
  }, [JSON.stringify(events)]);

  const inProgressStateDisplay = (
    <Root className={classes.card}>
      <div className={classes.installingHeaderRoot}>
        <div className={classes.cardTitle}>Installing the Connector</div>
        <div className={classes.pixelMap}>
          <WorldMapIcon alt="Pixel world map" />
        </div>
      </div>
      <LinearProgress />
      <div className={classes.eventsSection}>
        {events.map((event) => {
          let eventIcon;
          switch (event.level) {
            case 'warn':
              eventIcon = <WarningIcon className={classes.warningTriangle} />;
              break;
            case 'error':
              eventIcon = <ErrorIcon className={classes.errorCross} />;
              break;
            default:
              // Default case handles info and debug
              eventIcon = <SuccessIcon className={classes.successCheck} />;
              break;
          }
          return (
            <div className={classes.eventRow} key={JSON.stringify(event)}>
              {eventIcon}
              <Typography>{event.summary}</Typography>
            </div>
          );
        })}

        <div className={classes.eventRow}>
          <CircularProgress size={24} className={classes.loadingIcon} />
          <Typography>Waiting for new events...</Typography>
        </div>
      </div>
    </Root>
  );

  const successStateDisplay = (
    <Root className={classes.installSuccessDisplay}>
      <InstallSuccessIcon className={classes.installSuccessIcon} />
      <Typography variant="h1" className={classes.installSuccessTitleText}>
        Installation Successful!
      </Typography>
      <Typography variant="body1" className={classes.installSuccessBodyText}>
        Your connector is set up. Now you can manage configurations.
      </Typography>
    </Root>
  );

  const failedStateDisplay = (
    <Root>
      <Alert
        title="Installation not completed"
        subtitle="Address the following issues and try installation again:"
        alertType="error"
      >
        {generateErrorDescriptions()}
      </Alert>

      {
        // Check installed property of connector so we don't display this after a failed reconfigure
        selectedConnector?.installationStatus === 'notInstalled' && (
          <>
            <Typography className={classes.subheader}>
              Installation quick start
            </Typography>
            <Typography className={classes.bodyText}>
              If your initial Connector installation failed and you want to try
              again, you can copy a quick start command to install the connector
              with minimal configuration.
              <br />
              Once the Connector is installed & running you can return to this
              page to configure it further.
            </Typography>
            <Button
              className={classes.quickStartButton}
              onClick={toggleDialog}
              variant="contained"
            >
              Generate quick start command
            </Button>
            {renderDialog()}
          </>
        )
      }
    </Root>
  );

  const timeoutStateDisplay = (
    // TODO: Should we display other error information if they occurred in addition to timeout?
    // TODO: Should we display a command the user can run to clear any errors by checking health status?
    <Alert
      title="Installation not completed"
      subtitle="Address the following issues and try installation again:"
      alertType="error"
    >
      <li className={classes.summaryList}>
        {TIMEOUT_ERROR.summary}
        <ul className={classes.actionList}>
          <li className={classes.errorActionText}>{TIMEOUT_ERROR.action}</li>
          <li className={classes.errorErrorText}>{TIMEOUT_ERROR.error}</li>
        </ul>
      </li>
    </Alert>
  );

  const renderInstallationDetails = () => {
    if (stateType === INSTALL_STATE.FAILED) {
      return failedStateDisplay;
    }

    /**
     * TODO: If the user upgrades their connector to a version that supports installation events,
     * we should display the success state even if no events have been received.
     * */
    if (stateType === INSTALL_STATE.SUCCESS) {
      return successStateDisplay;
    }
    if (stateType === INSTALL_STATE.TIMEOUT) {
      return timeoutStateDisplay;
    }
    // Otherwise return in progress state
    return inProgressStateDisplay;
  };

  return renderInstallationDetails();
}

InstallationDetails.propTypes = {
  events: PropTypes.array,
};

InstallationDetails.defaultProps = {
  events: [],
};

export default InstallationDetails;
