import {CLEAR_STORE} from 'redux/actions/globalActions';
import {
  PUSH_NOTIFICATION,
  READ_NOTIFICATION,
  READ_NOTIFICATION_ALL,
  REMOVE_NOTIFICATION,
} from 'redux/actions/notificationActions';

/* Example notification:
  {
    title: 'Workstation 3 started',
    type: 'Remote Workstations',
    timePosted: Date.now() - 2 * 60 * 60 * 1000,
    moreInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fermentum ante vitae vulputate posuere. Suspendisse potenti. Etiam leo nunc, lacinia ac rutrum et, congue eget enim. Sed egestas condimentum mi, vel fringilla augue efficitur sit amet. Ut pellentesque est non mauris suscipit semper. Quisque et metus porttitor, vehicula magna ut, vehicula risus. Donec euismod pharetra leo nec tempus. Pellentesque eleifend dignissim nisi, sit amet hendrerit orci ultrices ut. In laoreet lectus a tellus sollicitudin, vitae pharetra tortor varius. Integer sapien lacus, varius nec massa vitae, vulputate tristique velit.',
    actionText: 'Mark as read',
    onClickAction: handleClickAction,
    key: 3,
  },
*/

const defaultState = [];

export default function notificationReducer(state = defaultState, action) {
  switch (action.type) {
    case PUSH_NOTIFICATION:
      return [
        {...action.notification, key: new Date().getTime() + Math.random()},
        ...state,
      ];
    case REMOVE_NOTIFICATION:
      return state.filter((notification) => notification.key !== action.key);
    case READ_NOTIFICATION:
      return state.map((notification) =>
        notification.key === action.key
          ? {...notification, read: true}
          : notification
      );
    case READ_NOTIFICATION_ALL:
      return state.map((notification) => ({...notification, read: true}));
    case CLEAR_STORE:
      return defaultState;
    default:
      return state;
  }
}
