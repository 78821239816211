import {Card, CardContent, Grid, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {put} from 'api/Api';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';
import CamSwitch from 'components/common/CamSwitch';
import {
  fetchTelemetrySettings,
  handleApiError,
} from 'redux/actions/dataActions';
import {TELEMETRY_SETTINGS} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {
  selectResourceItem,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import {isEmpty} from 'utils/utils';

const PREFIX = 'SessionTrackingSwitch';

const classes = {
  sessionTrackingCard: `${PREFIX}-sessionTrackingCard`,
  gridItem: `${PREFIX}-gridItem`,
  intervalRow: `${PREFIX}-intervalRow`,
  intervalLabel: `${PREFIX}-intervalLabel`,
  intervalValue: `${PREFIX}-intervalValue`,
};

const Root = styled(Card)(() => ({
  [`&.${classes.sessionTrackingCard}`]: {
    width: '280px',
  },
  [`& .${classes.gridItem}`]: {
    alignSelf: 'center',
  },
  [`& .${classes.intervalRow}`]: {
    marginTop: '8px',
  },
  [`& .${classes.intervalLabel}`]: {
    height: '24px',
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '0.09px',
    lineHeight: '24px',
  },
  [`& .${classes.intervalValue}`]: {
    height: '14px',
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '0.09px',
    lineHeight: '14px',
    marginLeft: '40px',
  },
}));

function SessionTrackingSwitch() {
  const dispatch = useDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [telemetryEnabled, setTelemetryEnabled] = useState(false);
  const [telemetrySyncInterval, setTelemetrySyncInterval] = useState(0);
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {item: telemetrySettings, isFetching: fetchingTelemetrySettings} =
    useSelector((state) =>
      selectResourceItem(state, TELEMETRY_SETTINGS, deploymentId)
    );

  const isTelemetryToggleDisabled = () =>
    isEmpty(telemetrySettings) || fetchingTelemetrySettings || isSaving;

  useEffect(() => {
    if (deploymentId) {
      dispatch(fetchTelemetrySettings());
    }
  }, [deploymentId]);

  useEffect(() => {
    if (!fetchingTelemetrySettings && !isEmpty(telemetrySettings)) {
      setTelemetryEnabled(telemetrySettings.enabled);
      setTelemetrySyncInterval(telemetrySettings.interval);
    }
  }, [JSON.stringify(telemetrySettings)]);

  const handleTelemetryToggle = async (e) => {
    setIsSaving(true);
    const data = {enabled: e.target.checked};
    const path = mapResourceToPath(TELEMETRY_SETTINGS, {deploymentId});

    try {
      await put({path, data});
      dispatch(fetchTelemetrySettings());
    } catch (err) {
      handleApiError(err);
    }
    setIsSaving(false);
  };

  return (
    <Root
      elevation={0}
      variant="outlined"
      className={classes.sessionTrackingCard}
    >
      <CardContent>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item className={classes.gridItem}>
            <InputLabel
              displayText="Session Tracking"
              tooltipText="No session information is collected when this option is disabled."
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            <CamSwitch
              checked={telemetryEnabled}
              onChange={(event) => handleTelemetryToggle(event)}
              inputProps={{
                'data-testid': 'enable-session-stats-switch',
              }}
              disabled={isTelemetryToggleDisabled()}
            />
          </Grid>
        </Grid>
        {telemetryEnabled && (
          <Grid container direction="row" className={classes.intervalRow}>
            <Grid item className={classes.gridItem}>
              <Typography className={classes.intervalLabel}>
                Sync interval
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Typography className={classes.intervalValue}>
                <strong>{`${telemetrySyncInterval} sec`}</strong>
              </Typography>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Root>
  );
}

export default SessionTrackingSwitch;
