import PropTypes from 'prop-types';
import {useCallback} from 'react';
import CAMTable from 'components/table/Table';
import {
  EDIT_WEBHOOK_LINK,
  WEBHOOKS,
  WEBHOOKS_EVENT_TYPE_ALLOCATE_RESOURCE,
  WEBHOOKS_EVENT_TYPE_AUTHENTICATE,
  WEBHOOKS_EVENT_TYPE_GET_RESOURCE_LIST,
  WEBHOOKS_EVENT_TYPE_SESSION_END,
  WEBHOOKS_EVENT_TYPE_SESSION_START,
} from 'utils/constants';
import {linkTo} from 'utils/utils';
import AssociatedResourcesChipDropdownSelector from 'components/webhooks/AssociatedResourcesChipDropdownSelector';
import DeleteWebhooksDialog from 'components/webhooks/DeleteWebhooksDialog';

function PoolWebhooksTab({poolWebhooks, isFetching, total}) {
  const mapEventTypeToLabel = (eventType) => {
    switch (eventType) {
      case WEBHOOKS_EVENT_TYPE_AUTHENTICATE:
        return 'Authentication';
      case WEBHOOKS_EVENT_TYPE_GET_RESOURCE_LIST:
        return 'Resource List';
      case WEBHOOKS_EVENT_TYPE_ALLOCATE_RESOURCE:
        return 'Allocate Resource';
      case WEBHOOKS_EVENT_TYPE_SESSION_END:
        return 'Session End';
      case WEBHOOKS_EVENT_TYPE_SESSION_START:
        return 'Session Start';
      default:
        return '';
    }
  };
  const getEditWebhooksLink = (webhook) =>
    linkTo(`${EDIT_WEBHOOK_LINK}/${webhook.id}`);

  const renderAssociatedResourcesChipDropdownSelector = (
    associatedResources
  ) => {
    return (
      <AssociatedResourcesChipDropdownSelector
        associatedResources={associatedResources}
      />
    );
  };

  const prepareWebhookConfig = useCallback(
    (webhook) => ({
      ...webhook,
      eventType: mapEventTypeToLabel(webhook.eventType),
      associatedResources: renderAssociatedResourcesChipDropdownSelector(
        webhook.associatedResources
      ),
      link: getEditWebhooksLink(webhook),
    }),
    []
  );

  const prepareData = (data) =>
    data?.map((webhook) => prepareWebhookConfig(webhook));

  return (
    <>
      <CAMTable
        id="pool-webhooks-table"
        tableTitle="Webhooks"
        resource={WEBHOOKS}
        idField="id"
        data={prepareData(poolWebhooks)}
        total={total}
        loadingData={isFetching}
        hideAddButton
        hideTitle
        hideSearch
      />
      <DeleteWebhooksDialog />
    </>
  );
}

PoolWebhooksTab.propTypes = {
  poolWebhooks: PropTypes.array,
  isFetching: PropTypes.bool,
  total: PropTypes.number,
};

PoolWebhooksTab.defaultProps = {
  poolWebhooks: [],
  isFetching: false,
  total: 0,
};

export default PoolWebhooksTab;
