import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {del, get, post, put} from 'api/Api';
import CAMDeleteDialog from 'components/CAM/dialogs/CAMDeleteDialog/CAMDeleteDialog';
import HorizontalTab from 'components/CAM/tabs/HorizontalTab/HorizontalTab';
import config from 'config';
import {capitalize} from 'helpers/core';
import useSnackbar from 'hooks/useSnackbar';
import {
  clearResource,
  deleteDeployment,
  fetchCloudServiceAccounts,
  fetchConnectorSettings,
  fetchEditDeployment,
  fetchResource,
  fetchTelemetrySettings,
  handleApiError,
  saveVariable,
} from 'redux/actions/dataActions';
import {push} from 'redux/actions/HistoryActions';
import {
  AD_GROUPS,
  CLOUD_SERVICE_ACCOUNTS,
  CONNECTOR_SETTINGS,
  DASHBOARD_LINK,
  DEPLOYMENTS,
  DEPLOYMENT_DELETE_CONFIRMATION_MESSAGE,
  GCP,
  TEXT_REG_CODE_DEFAULT,
  TAB_DEPLOYMENT_OVERVIEW,
  TAB_DEPLOYMENT_SERVICE_ACCOUNTS,
  TAB_CLOUD_SERVICE_ACCOUNTS,
  TAB_CONNECTOR_SETTINGS,
  TAB_ANYWARE_MONITOR,
  TAB_LINK_SERVICE,
} from 'utils/constants';
import {getDeploymentPath} from 'utils/paths';
import {
  selectData,
  selectResourceItem,
  selectSelectedDeployment,
  selectVariable,
} from 'utils/reduxSelectors';
import {isEmpty} from 'utils/utils';
import useAcceptPolicyTracking from 'hooks/useAcceptPolicyTracking';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import MonitorUpdateInstructions from 'components/remoteWorkstations/edit/MonitorUpdateInstructions';
import EditDeploymentAppBar from './EditDeploymentAppBar';
import EditDeploymentCloudServiceAccountsTab from './EditDeploymentCloudServiceAccountsTab';
import EditDeploymentConnectorSettingsTab from './EditDeploymentConnectorSettingsTab';
import EditDeploymentDeploymentServiceAccountsTab from './EditDeploymentDeploymentServiceAccountsTab';
import EditDeploymentBulkMonitorProvisioning from './EditDeploymentBulkMonitorProvisioning';
import EditDeploymentOverviewTab from './EditDeploymentOverviewTab';
import EditDeploymentWorkstationsServiceAccountsTab from './EditDeploymentWorkstationsServiceAccounts';
import MonitorEulaDialog from './MonitorEulaDialog';
import LinkServiceTab from './linkService/LinkServiceTab';

const PREFIX = 'EditDeployment';

const classes = {
  createPageContainer: `${PREFIX}-createPageContainer`,
  bodyText: `${PREFIX}-bodyText`,
  link: `${PREFIX}-link`,
  rootContainer: `${PREFIX}-rootContainer`,
  checkboxLabel: `${PREFIX}-checkboxLabel`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.createPageContainer}`]: theme.createPage.createPageContainer,

  [`& .${classes.bodyText}`]: {
    color: '#D8D8D8',
    fontWeight: 500,
  },

  [`& .${classes.link}`]: {
    textDecoration: 'underline',
    color: 'white',
  },

  [`& .${classes.rootContainer}`]: {
    paddingTop: '0.5rem',
  },

  [`& .${classes.checkboxLabel}`]: {
    fontSize: '0.875em',
  },
}));

function EditDeployment({match}) {
  const {tabId} = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const {successSnackbar, errorSnackbar} = useSnackbar();

  const selectedDeployment = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {item: connectorSettings} = useSelector((state) =>
    selectResourceItem(
      state,
      CONNECTOR_SETTINGS,
      selectedDeployment.deploymentId
    )
  );

  const [gcpCredentials, setGcpCredentials] = useState({});
  const [deploymentId, setDeploymentId] = useState('');
  const [deploymentNameSaved, setDeploymentNameSaved] = useState('');
  const [serviceAccounts, setServiceAccounts] = useState([]);
  const [isLoadingServiceAccounts, setLoadingServiceAccounts] = useState(true);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const deleteFromProvider = useSelector((state) =>
    selectVariable(state, 'deleteFromProvider')
  );

  // Monitor Eula
  const {currentUser} = useAcceptPolicyTracking();
  const isTenantUser = currentUser !== null;
  const [monitorEulaRequired, setMonitorEulaRequired] = useState(true);

  // Deployment service accounts (DSA) table props
  const [dsaRowsPerPage, setDsaRowsPerPage] = useState(10);
  const [dsaTotal, setDsaTotal] = useState(0);
  const [dsaCurrentPage, setDsaCurrentPage] = useState(0);

  // Deployment Service Account table event handlers
  const handleDsaPageChange = (_, newPage) => setDsaCurrentPage(newPage);

  const handleDsaRowsPerPageChange = ({target: {value}}) => {
    const newRowsPerPage = parseInt(value, 10);
    setDsaRowsPerPage(newRowsPerPage);
    setDsaCurrentPage(0);
  };

  // Workstation Service Accounts
  const [workstationServiceAccounts, setWorkstationServiceAccounts] = useState(
    []
  );
  const [
    isLoadingWorkstationServiceAccounts,
    setLoadingWorkstationServiceAccounts,
  ] = useState(true);

  // Workstation service accounts (WSA) table props
  const [wsaRowsPerPage, setWsaRowsPerPage] = useState(10);
  const [wsaTotal, setWsaTotal] = useState(0);
  const [wsaCurrentPage, setWsaCurrentPage] = useState(0);

  // Workstation Service Account table event handlers
  const handleWsaPageChange = (_, newPage) => setWsaCurrentPage(newPage);

  const handleWsaRowsPerPageChange = ({target: {value}}) => {
    const newRowsPerPage = parseInt(value, 10);
    setWsaRowsPerPage(newRowsPerPage);
    setWsaCurrentPage(0);
  };

  const {
    data: cloudServiceAccounts,
    isFetching: isFetchingCloudServiceAccounts,
  } = useSelector((state) => selectData(state, CLOUD_SERVICE_ACCOUNTS));

  const {createdOn} = selectedDeployment;

  const selectedDeploymentName = selectedDeployment
    ? selectedDeployment.deploymentName || selectedDeployment.deploymentId
    : '';

  const putErrorHandler = (error, setRegCodeError) => {
    const {code} = error;
    switch (code) {
      case 400:
        if (error.data.reason) {
          let errorMessage = error.data.reason;
          if (errorMessage === 'invalid registration code') {
            setRegCodeError(true);
          }
          errorMessage = capitalize(errorMessage);
          errorSnackbar(errorMessage);
          return true;
        }
        break;
      case 409:
        errorSnackbar('A deployment with this name already exists.');
        return true;
      default:
        // Error was NOT caught, so the default API error handler will run
        return false;
    }
    return false;
  };

  const getDeploymentIdFromUrl = () =>
    (match && match.params && match.params.deploymentId) || '';

  const getDeploymentIdSelected = () =>
    (selectedDeployment && selectedDeployment.deploymentId) || '';

  const getServiceAccounts = async () => {
    try {
      const offset = dsaRowsPerPage * dsaCurrentPage;
      const limit = dsaRowsPerPage;
      setLoadingServiceAccounts(true);
      // Sort service accounts by createdOn, last created first
      const response = await get({
        path: config.isRBACSupported() ? 'keys' : 'auth/keys',
        params: {deploymentId: getDeploymentIdFromUrl(), limit, offset},
      });
      setDsaTotal(response.total);

      const receivedServiceAccounts = response.data.map((account) => {
        const newAccount = account;
        newAccount.keyName = account.apiKeyName || account.keyName || '';
        return newAccount;
      });

      receivedServiceAccounts
        .sort((a, b) => b.createdOn.localeCompare(a.createdOn))
        .filter((acct) => acct.deploymentId === getDeploymentIdFromUrl());
      setServiceAccounts(receivedServiceAccounts);
      setLoadingServiceAccounts(false);
    } catch (error) {
      setLoadingServiceAccounts(false);
      dispatch(handleApiError(error));
    }
  };

  const getWorkstationServiceAccounts = async () => {
    try {
      const offset = wsaRowsPerPage * wsaCurrentPage;
      const limit = wsaRowsPerPage;
      setLoadingWorkstationServiceAccounts(true);
      // Sort service accounts by createdOn, last created first
      const response = await get({
        path: 'auth/keys/messaging/registration',
        params: {
          deploymentId: getDeploymentIdFromUrl(),
          limit,
          offset,
        },
      });
      setWsaTotal(response.total);

      const receivedServiceAccounts = response.data.map((account) => {
        const newAccount = account;
        newAccount.keyName = account.apiKeyName || account.keyName || '';
        return newAccount;
      });

      receivedServiceAccounts
        .sort((a, b) => b.createdOn.localeCompare(a.createdOn))
        .filter((acct) => acct.deploymentId === deploymentId);
      setWorkstationServiceAccounts(receivedServiceAccounts);
      setLoadingWorkstationServiceAccounts(false);
    } catch (error) {
      setLoadingWorkstationServiceAccounts(false);
      dispatch(handleApiError(error));
    }
  };

  const handleCancelEdit = () => dispatch(push(DASHBOARD_LINK));

  const handleDeleteServiceAccount = async ({keyId, keyName}) => {
    try {
      await del({
        path: config.isRBACSupported() ? `keys/${keyId}` : `auth/keys/${keyId}`,
      });
    } catch (error) {
      dispatch(handleApiError(error));
      return;
    }
    successSnackbar(`Service Account ${keyName} was deleted.`);
    getServiceAccounts();
    getWorkstationServiceAccounts();
  };

  const handleAddServiceAccount = async (keyName, roleId) => {
    let newServiceAccount;
    try {
      const data = {deploymentId, keyName};
      if (config.isRBACSupported()) {
        data.roleId = roleId;
      }
      const response = await post({
        path: config.isRBACSupported() ? 'keys' : 'auth/keys',
        data,
      });
      newServiceAccount = response.data;
      getServiceAccounts();
    } catch (error) {
      newServiceAccount = {};
      dispatch(handleApiError(error));
    }
    return newServiceAccount;
  };

  const handleAddCommands = async (keyName) => {
    try {
      await post({
        path: 'auth/keys/messaging/enrollment',
        data: {deploymentId, keyName},
      });
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };

  const handleGcpCredentialsChange = (credentials, id) =>
    setGcpCredentials({...credentials, id});

  const handleSave = async (
    deploymentName,
    registrationCode,
    setRegCodeError
  ) => {
    // Only update deployment properties that have changed
    const data = {};
    if (deploymentName !== selectedDeployment.deploymentName) {
      data.deploymentName = deploymentName;
    }

    if (registrationCode !== TEXT_REG_CODE_DEFAULT) {
      data.registrationCode = registrationCode;
    }

    try {
      const response = await put({
        path: `deployments/${selectedDeployment.deploymentId}`,
        data,
      });

      dispatch(saveVariable('selectedDeployment', response.data));

      // Set the base values to determine if page was edited
    } catch (error) {
      if (!putErrorHandler(error, setRegCodeError)) {
        dispatch(handleApiError(error));
      }
      return;
    }

    setDeploymentNameSaved(deploymentName);

    successSnackbar(`Deployment "${deploymentNameSaved}" has been updated.`);
    dispatch(fetchResource(DEPLOYMENTS, {page: 0, rowsPerPage: 200}));
  };

  const handleDeleteButtonClick = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteDeployment = () => {
    setDeleteConfirmationOpen(false);
    dispatch(deleteDeployment({...selectedDeployment, deleteFromProvider}));
  };

  const handleMonitorEulaCancel = () => {
    history.push(getDeploymentPath(deploymentId, TAB_DEPLOYMENT_OVERVIEW));
  };

  useEffect(() => {
    if (isTenantUser) {
      setMonitorEulaRequired(
        !currentUser.monitorEulaAcceptedOn ||
          (currentUser.monitorEulaAcceptedOn &&
            currentUser.monitorEulaAcceptedOn < config.MONITOR_EULA_UPDATED_ON)
      );
    } else {
      setMonitorEulaRequired(false);
    }
  }, [JSON.stringify(currentUser)]);

  useEffect(() => {
    const deploymentIdParam = getDeploymentIdFromUrl();
    const selectedDeploymentId = getDeploymentIdSelected();
    setDeploymentId(deploymentIdParam);
    dispatch(fetchCloudServiceAccounts({deploymentId: deploymentIdParam}));
    if (deploymentIdParam !== selectedDeploymentId) {
      dispatch(fetchEditDeployment(deploymentIdParam));
    }
  }, []);

  useEffect(() => {
    const account =
      cloudServiceAccounts.find((acc) => acc.provider === GCP) || {};
    if (isEmpty(account)) {
      setGcpCredentials({});
    } else {
      setGcpCredentials({
        clientEmail: account.clientEmail,
        projectId: account.projectId,
        id: account.id,
      });
    }
  }, [JSON.stringify(cloudServiceAccounts)]);

  const getDeploymentSettings = () => {
    dispatch(clearResource(AD_GROUPS));
    dispatch(fetchConnectorSettings());
    if (config.isBetaOrStandalone()) {
      dispatch(fetchTelemetrySettings());
    }
  };

  useEffect(() => {
    if (selectedDeployment?.deploymentId) {
      setDeploymentId(selectedDeployment.deploymentId);
      setDeploymentNameSaved(
        selectedDeployment.deploymentName || selectedDeployment.deploymentId
      );
      getDeploymentSettings();
    }
  }, [selectedDeployment.deploymentId]);

  useEffect(() => {
    getServiceAccounts();
  }, [dsaCurrentPage, dsaRowsPerPage]);

  useEffect(() => {
    getWorkstationServiceAccounts();
  }, [wsaCurrentPage, wsaRowsPerPage]);

  const renderOverviewTab = () => (
    <EditDeploymentOverviewTab
      deployment={selectedDeployment}
      createdOn={createdOn}
      connectorSettings={connectorSettings}
      onSaveDeploymentInfo={handleSave}
    />
  );

  const renderDeploymentServiceAccountsTab = () => (
    <EditDeploymentDeploymentServiceAccountsTab
      serviceAccounts={serviceAccounts}
      total={dsaTotal}
      page={dsaCurrentPage}
      rowsPerPage={dsaRowsPerPage}
      isLoadingServiceAccounts={isLoadingServiceAccounts}
      onAddServiceAccount={handleAddServiceAccount}
      onDeleteServiceAccount={handleDeleteServiceAccount}
      onPageChange={handleDsaPageChange}
      onRowsPerPageChange={handleDsaRowsPerPageChange}
    />
  );

  const renderAnywareMonitorTab = () => (
    <>
      {monitorEulaRequired && (
        <MonitorEulaDialog handleCancel={handleMonitorEulaCancel} />
      )}
      <EditDeploymentBulkMonitorProvisioning onAddCommand={handleAddCommands} />
      <CAMCard>
        <SectionHeader displayText="ANYWARE MONITOR UPDATE INSTRUCTIONS" />
        {deploymentId && (
          <MonitorUpdateInstructions deploymentId={deploymentId} />
        )}
      </CAMCard>
      <EditDeploymentWorkstationsServiceAccountsTab
        serviceAccounts={workstationServiceAccounts}
        total={wsaTotal}
        page={wsaCurrentPage}
        rowsPerPage={wsaRowsPerPage}
        isLoadingServiceAccounts={isLoadingWorkstationServiceAccounts}
        onDeleteServiceAccount={handleDeleteServiceAccount}
        onPageChange={handleWsaPageChange}
        onRowsPerPageChange={handleWsaRowsPerPageChange}
      />
    </>
  );

  const renderCloudServiceAccountsTab = () => (
    <EditDeploymentCloudServiceAccountsTab
      deploymentId={deploymentId}
      gcpCredentials={gcpCredentials}
      isLoadingGcpCredentials={isFetchingCloudServiceAccounts}
      onGcpChange={handleGcpCredentialsChange}
    />
  );

  const renderConnectorSettingsTab = () => (
    <EditDeploymentConnectorSettingsTab connectorSettings={connectorSettings} />
  );

  const editDeploymentTabs = () => {
    const tabs = {
      [TAB_DEPLOYMENT_OVERVIEW]: renderOverviewTab(),
      [TAB_CLOUD_SERVICE_ACCOUNTS]: renderCloudServiceAccountsTab(),
      [TAB_CONNECTOR_SETTINGS]: renderConnectorSettingsTab(),
    };
    // in the new view, deployment SA is a new page rather than a tab under deployment
    if (!config.isRBACSupported()) {
      tabs[TAB_DEPLOYMENT_SERVICE_ACCOUNTS] =
        renderDeploymentServiceAccountsTab();
    }
    if (config.isMonitorEnabled()) {
      tabs[TAB_ANYWARE_MONITOR] = renderAnywareMonitorTab();
    }
    if (config.pothosSupported()) {
      tabs[TAB_LINK_SERVICE] = (
        <LinkServiceTab deployment={selectedDeployment} />
      );
    }
    return tabs;
  };

  const renderTabs = () => {
    const tabs = [
      {
        label: 'Overview',
        value: TAB_DEPLOYMENT_OVERVIEW,
        key: TAB_DEPLOYMENT_OVERVIEW,
      },
      {
        label: 'Provider Service Accounts',
        value: TAB_CLOUD_SERVICE_ACCOUNTS,
        key: TAB_CLOUD_SERVICE_ACCOUNTS,
      },
      {
        label: 'Connector Settings',
        value: TAB_CONNECTOR_SETTINGS,
        key: TAB_CONNECTOR_SETTINGS,
      },
    ];
    if (!config.isRBACSupported()) {
      tabs.splice(1, 0, {
        label: 'Deployment Service Accounts',
        value: TAB_DEPLOYMENT_SERVICE_ACCOUNTS,
        key: TAB_DEPLOYMENT_SERVICE_ACCOUNTS,
      });
    }
    if (config.isMonitorEnabled()) {
      tabs.push({
        label: 'Anyware Monitor',
        value: TAB_ANYWARE_MONITOR,
        key: TAB_ANYWARE_MONITOR,
      });
    }
    if (config.pothosSupported()) {
      tabs.push({
        label: 'Link Service',
        value: TAB_LINK_SERVICE,
        key: TAB_LINK_SERVICE,
      });
    }
    const _tabId = tabs.find((tab) => tab.value === tabId)
      ? tabId
      : TAB_DEPLOYMENT_OVERVIEW;
    return (
      <>
        <HorizontalTab
          tabs={tabs}
          selectedTab={_tabId}
          setSelectedTab={(newTab) => {
            history.push(getDeploymentPath(deploymentId, newTab));
          }}
        />

        {editDeploymentTabs()[_tabId]}
      </>
    );
  };

  return (
    <Root className={classes.createPageContainer}>
      <EditDeploymentAppBar
        deploymentName={deploymentNameSaved}
        deploymentStatus={selectedDeployment.status || ''}
        fetchingDeployment={isEmpty(selectedDeployment)}
        onBackHistory={handleCancelEdit}
        onDelete={handleDeleteButtonClick}
      />
      <div className={classes.rootContainer}>{renderTabs()}</div>
      <CAMDeleteDialog
        requireTextInputToDelete
        resourceName={selectedDeploymentName}
        open={deleteConfirmationOpen}
        onOk={handleDeleteDeployment}
        onCancel={() => setDeleteConfirmationOpen(false)}
        content={DEPLOYMENT_DELETE_CONFIRMATION_MESSAGE}
      />
    </Root>
  );
}

EditDeployment.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EditDeployment;
