import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import Customize from '@mui/icons-material/Build';
import Cloud from '@mui/icons-material/Cloud';
import LearnMore from '@mui/icons-material/Description';
import Computer from '@mui/icons-material/DesktopWindowsOutlined';
import Connector from '@mui/icons-material/DeviceHub';
import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import WelcomeCardItem from 'components/CAM/display/WelcomeCardItem/WelcomeCardItem';
import WelcomeCardItemWarning from 'components/common/WelcomeCardItemWarning';
import {getEditDeploymentLink} from 'helpers/core';
import anywareLogo from 'icons/anyware-icon.svg';
import {fetchResource} from 'redux/actions/dataActions';
import {
  ADD_REMOTE_WORKSTATION_LINK,
  CONNECTORS,
  CONNECTORS_LINK,
  CONNECTOR_SELECT_TYPE_LINK,
  DASHBOARD_LINK,
  DEPLOYMENTS,
  PCOIP_CLIENT_SESSION,
  TAB_CLOUD_SERVICE_ACCOUNTS,
  UPDATE_CAC_LINK,
} from 'utils/constants';
import {selectData} from 'utils/reduxSelectors';
import {
  deploymentHasConnectors,
  getDocumentationLink,
  isEmpty,
} from 'utils/utils';

import config from 'config';

const PREFIX = 'WelcomeCard';

const classes = {
  card: `${PREFIX}-card`,
  content: `${PREFIX}-content`,
  left: `${PREFIX}-left`,
  right: `${PREFIX}-right`,
  header: `${PREFIX}-header`,
  subHeader: `${PREFIX}-subHeader`,
  icon: `${PREFIX}-icon`,
  name: `${PREFIX}-name`,
  help: `${PREFIX}-help`,
  infoBanner: `${PREFIX}-infoBanner`,
  link: `${PREFIX}-link`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.card}`]: {
    maxWidth: '830px',
    height: '600px',
    margin: '100px auto',
  },

  [`& .${classes.content}`]: {
    height: '-webkit-fill-available',
  },

  [`& .${classes.left}`]: {
    backgroundColor: '#CAE6F1',
    color: '#2A426C',
    textAlign: 'center',
    padding: '32px',
  },

  [`& .${classes.right}`]: {
    backgroundColor: '#F3F2F2',
  },

  [`& .${classes.header}`]: {
    color: '#00467E',
    width: '22rem',
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '28px',
    marginTop: '46px',
    marginBottom: '0',
  },

  [`& .${classes.subHeader}`]: {
    width: '20rem',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '20px',
    marginBottom: 0,
  },

  [`& .${classes.icon}`]: {
    height: '108px',
    marginTop: '24px',
  },

  [`& .${classes.name}`]: {
    marginTop: '24px',
    marginBottom: 0,
  },

  [`& .${classes.help}`]: {
    width: '15.5rem',
    fontSize: '13px',
    lineHeight: '23px',
    margin: '0px auto',
  },

  [`& .${classes.infoBanner}`]: {
    margin: '80px auto 0 auto',
    maxWidth: '1000px',
  },

  [`& .${classes.link}`]: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
}));

const header = 'Getting Started';
const subHeader =
  'Welcome to the Anyware Manager! The Anyware Manager is a single interface for managing and deploying your PCoIP Remote Workstation environment.';
const help =
  'Click on any of the cards to get started deploying your PCoIP environment.';

function WelcomeCard({
  deploymentId,
  fetchingDeployments,
  disableAddWorkstation,
  fetchingConnectors,
  connectors,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    // fetch connectors when deployment id loads or changes
    // if no connectors, disable add workstation
    if (deploymentId) {
      dispatch(fetchResource(CONNECTORS, {deploymentId}));
    }
  }, [deploymentId]);

  const MINIMUM_CONNECTOR_VERSION = 115;

  const getConnectorVersion = (connector) =>
    connector?.components?.version || connector?.components?.cacVersion;

  const anyConnectorBelowMinimumVersion = (minimumVersion) => {
    if (isEmpty(connectors)) {
      return false;
    }
    try {
      return !isEmpty(
        connectors.filter((connector) => {
          const connectorVersion = getConnectorVersion(connector);
          return (
            !isEmpty(connectorVersion) && connectorVersion < minimumVersion
          );
        })
      );
    } catch {
      // Failed to find connector versions
      return false;
    }
  };

  const infoBanner = (
    <div className={classes.infoBanner}>
      <WelcomeCardItemWarning
        title="Upgrade your connectors"
        Icon={WarningIcon}
        iconColor="orange"
        isLink={false}
        isCloseable
      >
        <div>
          At least one of{' '}
          <Link to={CONNECTORS_LINK} className={classes.link}>
            your connectors
          </Link>{' '}
          is running an old version that has known vulnerabilities as per{' '}
          <a
            className={classes.link}
            href="https://support.hp.com/us-en/document/ish_5268006-5268030-16"
            target="_blank"
            rel="noreferrer noopener"
          >
            this HP security bulletin
          </a>
          . We recommend that you{' '}
          <a
            href={UPDATE_CAC_LINK}
            className={classes.link}
            target="_blank"
            rel="noreferrer noopener"
          >
            upgrade your connectors
          </a>{' '}
          immediately to the latest version.{' '}
        </div>
      </WelcomeCardItemWarning>
    </div>
  );

  // This allows to quick display a banner in the dashboard
  const infoBannerEnabled = anyConnectorBelowMinimumVersion(
    MINIMUM_CONNECTOR_VERSION
  );

  return (
    <Root>
      {!config.STANDALONE && infoBannerEnabled && infoBanner}

      <Paper className={classes.card}>
        <Grid container className={classes.content}>
          <Grid item xs={12} sm={6} className={classes.left}>
            <p className={classes.header}>{header}</p>
            <p className={classes.subHeader}>{subHeader}</p>
            <Hidden smDown>
              <img
                className={classes.icon}
                src={anywareLogo}
                alt="teradici-cloud"
              />
            </Hidden>
            <p className={classes.name}>Anyware Manager</p>
            <p className={classes.help}>{help}</p>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.right}>
            <a
              data-testid="learn-more-link"
              href={getDocumentationLink()}
              target="_blank"
              rel="noreferrer noopener"
            >
              <WelcomeCardItem
                id="learn-more-card"
                title="Learn more"
                text="Information on key concepts, installation and use of the Anyware Manager."
                Icon={LearnMore}
                isLink
              />
            </a>
            <Link
              data-testid="add-cloud-credentials-link"
              to={
                deploymentId
                  ? `${getEditDeploymentLink(
                      deploymentId
                    )}/${TAB_CLOUD_SERVICE_ACCOUNTS}`
                  : DASHBOARD_LINK
              }
            >
              <WelcomeCardItem
                id="add-cloud-credentials-card"
                data-testid="add-cloud-credentials-card"
                title="Add provider credentials"
                text="To enable interactions with workstation providers for capabilities such as power management."
                Icon={Cloud}
                loading={fetchingDeployments}
                disabled={!deploymentId}
                disabledMessage={
                  fetchingDeployments
                    ? 'Loading deployments...'
                    : 'Must have at least one deployment'
                }
              />
            </Link>
            <Link
              data-testid="create-connector-link"
              to={`${CONNECTOR_SELECT_TYPE_LINK}`}
            >
              <WelcomeCardItem
                id="create-connector-card"
                title="Create connector"
                text="Connectors are required to connect users to their workstations."
                Icon={Connector}
              />
            </Link>
            <Link
              data-testid="add-workstation-link"
              to={
                disableAddWorkstation
                  ? DASHBOARD_LINK
                  : `${ADD_REMOTE_WORKSTATION_LINK}`
              }
            >
              <WelcomeCardItem
                id="add-workstation-card"
                data-testid="add-workstation-card"
                title="Add remote workstation"
                text="A connector must be configured before workstations can be added."
                Icon={Computer}
                disabled={disableAddWorkstation}
                loading={fetchingConnectors}
                disabledMessage={
                  fetchingConnectors
                    ? 'Loading connectors...'
                    : 'Must have at least one connector'
                }
              />
            </Link>
            <a
              href={PCOIP_CLIENT_SESSION}
              target="_blank"
              rel="noreferrer noopener"
            >
              <WelcomeCardItem
                title="Set up PCoIP connection"
                text="Learn how to connect to a remote workstation using a PCoIP client."
                Icon={Customize}
                isLink
              />
            </a>
          </Grid>
        </Grid>
      </Paper>
    </Root>
  );
}

WelcomeCard.propTypes = {
  deploymentId: PropTypes.string.isRequired,
  disableAddWorkstation: PropTypes.bool.isRequired,
  fetchingConnectors: PropTypes.bool.isRequired,
  fetchingDeployments: PropTypes.bool.isRequired,
  connectors: PropTypes.array.isRequired,
};

function getDeploymentId(selectedDeployment, deployments) {
  if (selectedDeployment && 'deploymentId' in selectedDeployment) {
    return selectedDeployment.deploymentId;
  }

  if (deployments && deployments.length !== 0) {
    return deployments[0].deploymentId;
  }

  return '';
}

function mapStateToProps(state) {
  const {dataByResource} = state.data;
  const {selectedDeployment} = dataByResource;
  const {data: deployments, isFetching: fetchingDeployments} = selectData(
    state,
    DEPLOYMENTS
  );

  const deploymentId = getDeploymentId(selectedDeployment, deployments);

  const connectors = selectData(state, CONNECTORS) || {
    data: {},
    isFetching: false,
  };

  let disableAddWorkstation = true;
  if (!connectors.isFetching) {
    disableAddWorkstation = !deploymentHasConnectors(
      connectors.data,
      selectedDeployment
    );
  }

  return {
    deploymentId,
    fetchingDeployments,
    disableAddWorkstation,
    fetchingConnectors: connectors.isFetching,
    connectors: connectors.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchConnectors: () =>
      dispatch(fetchResource('connectors', {page: 0, rowsPerPage: 1})),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeCard);
