import {useDispatch, useSelector} from 'react-redux';
import {saveVariable} from 'redux/actions/dataActions';
import {selectVariable} from 'utils/reduxSelectors';

const usePendingChanges = () => {
  const dispatch = useDispatch();
  const currentValue = useSelector(
    (state) => selectVariable(state, 'pendingChanges') || false
  );

  const setPendingChanges = (value) => {
    if (JSON.stringify(currentValue) !== JSON.stringify(value)) {
      dispatch(saveVariable('pendingChanges', value));
    }
  };

  return {setPendingChanges};
};

export default usePendingChanges;
