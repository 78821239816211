import {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import {useDispatch} from 'react-redux';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import useInterval from 'hooks/useInterval';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';

import {goBack, push} from 'redux/ReduxHistory';
import {
  CONNECTORS_LINK,
  CONNECTOR_EVENTS,
  CONNECTOR_EVENT_POLLING_INTERVAL_MS,
} from 'utils/constants';
import {get} from 'api/Api';
import {handleApiError} from 'redux/actions/dataActions';

import {stylesFromFigma} from 'themes/stylesFromFigma';
import {mapResourceToPath} from 'utils/Mappings';

import ActiveDirectoryConfigCard from './ActiveDirectoryConfigCard';
import ConnectorNameConfigCard from './ConnectorNameConfigCard';
import DomainConfigCard from './DomainConfigCard';
import MfaConfigCard from './MfaConfigCard';
import GenerateCommandCard from './GenerateCommandCard';
import ConfigurationServiceCommandCard from './ConfigurationServiceCommandCard';
import {getClearedConfigState, useWizardState} from './wizardConfigState';

import {configParams} from './wizardConstants';

const PREFIX = 'WizardConfigureConnector';

const classes = {
  cardSubtitle: `${PREFIX}-cardSubtitle`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  spacing: `${PREFIX}-spacing`,
  connectorLink: `${PREFIX}-connectorLink`,
  currentBreadcrumb: `${PREFIX}-currentBreadcrumb`,
  pageTitle: `${PREFIX}-pageTitle`,
  buttonMargin: `${PREFIX}-buttonMargin`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.cardSubtitle}`]: {
    marginTop: '24px',
    marginBottom: '16px',
  },

  [`& .${classes.sectionTitle}`]: {
    marginTop: '24px',
  },

  [`&.${classes.spacing}`]: {
    margin: theme.spacing(4),
  },

  [`& .${classes.connectorLink}`]: {
    ...stylesFromFigma.typographyBody1,
    color: 'inherit',
  },

  [`& .${classes.currentBreadcrumb}`]: {
    color: 'rgba(0, 0, 0, 0.87)',
  },

  [`& .${classes.pageTitle}`]: {
    ...stylesFromFigma.typographyH4,
    marginTop: '8px',
  },

  [`& .${classes.buttonMargin}`]: {margin: '16px'},
}));

/** TODO: make one of the following changes
 *        - prevent going back to cards after install command has been locked
 *        - allow going back & forward to previous cards but prevent editing
 */
// TODO: check for outdated logic to remove
// TODO: Change behavior to delete Connector & token on Cancel click after connector is created
function WizardConfigureConnector() {
  const dispatch = useDispatch();
  const {
    connectorId,
    configState,
    setConfigState,
    commandCopied,
    setInstallCommand,
    setConnectorName,
    resetWizardState,
  } = useWizardState();

  const CONNECTOR_PANEL_LINK = `/app/connectors/edit/${connectorId}/installation`;

  const [eventPollingInterval, setEventPollingInterval] = useState(
    CONNECTOR_EVENT_POLLING_INTERVAL_MS
  );

  let isPolling = false;

  useEffect(() => {
    setConfigState(getClearedConfigState(configParams, configState));
    resetWizardState();
    return function cleanup() {
      setConfigState(getClearedConfigState(configParams, configState));
      resetWizardState();
    };
  }, []);

  useEffect(() => {
    setInstallCommand('');
  }, [JSON.stringify(configState)]);

  // Can take a single or multiple events
  const handleChange = (events) => {
    const eventArray = Array.isArray(events) ? events : [events];

    const updatedState = {...configState};
    eventArray.forEach((event) => {
      const {name, value} = event.target;
      updatedState[name] = value;
    });
    setConfigState(updatedState);
  };

  useInterval(() => {
    const checkForEventsAndRedirect = async () => {
      if (isPolling) return;

      isPolling = true;

      try {
        const resp = await get({
          path: mapResourceToPath(CONNECTOR_EVENTS, {connectorId}),
        });
        if (resp && resp.total > 0) {
          dispatch(push(CONNECTOR_PANEL_LINK));
        }
      } catch (error) {
        if (error?.code === 401) {
          // Stop the polling if the user is unauthorized
          setEventPollingInterval(null);
          dispatch(handleApiError(error));
        } else {
          // Silently fail on other errors
          console.error('Error polling for connector events. ', error);
        }
      } finally {
        isPolling = false;
      }
    };

    if (commandCopied) {
      checkForEventsAndRedirect();
    }
  }, eventPollingInterval);

  return (
    <Root className={classes.spacing}>
      <Breadcrumbs separator=">">
        <Link
          underline="hover"
          href={CONNECTORS_LINK}
          onClick={(event) => {
            event.preventDefault();
            dispatch(push(CONNECTORS_LINK));
          }}
          className={classes.connectorLink}
        >
          Connectors
        </Link>
        <Typography className={classes.currentBreadcrumb}>
          {' '}
          Add Connector
        </Typography>
      </Breadcrumbs>

      <Typography className={classes.pageTitle}>Add Connector</Typography>

      <form
        className={classes.sectionTitle}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ConnectorNameConfigCard
          onChange={(event) => setConnectorName(event.target.value)}
        />

        <DomainConfigCard onChange={handleChange} />

        <ActiveDirectoryConfigCard onChange={handleChange} />

        <MfaConfigCard onChange={handleChange} />

        <ConfigurationServiceCommandCard />

        <GenerateCommandCard />
      </form>
      <CAMButton
        onClick={() => dispatch(goBack())}
        buttonText="Cancel"
        variant="text"
        customClass={classes.buttonMargin}
      />
    </Root>
  );
}

export default WizardConfigureConnector;
