import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import useMediaQuery from '@mui/material/useMediaQuery';
import {FaFileAlt, FaShoppingCart} from 'react-icons/fa';
import {useSelector} from 'react-redux';
import LoadingDots from 'components/common/LoadingDots';
import anywareLogoWhite from 'icons/anyware-logo-white.svg';
import anywareIconWhite from 'icons/anyware-only-logo-white.svg';
import {AWM_PRIVACY_STATEMENT, HOME_PAGE_INFO_TEXT} from 'utils/constants';
import {selectVariable} from 'utils/reduxSelectors';
import {getDocumentationLink} from 'utils/utils';
import LoginPanel from './LoginPanel';

const PREFIX = 'UnauthenticatedApp';

const classes = {
  rootSmall: `${PREFIX}-rootSmall`,
  casIconSmall: `${PREFIX}-casIconSmall`,
  awmIconLarge: `${PREFIX}-awmIconLarge`,
  gridContainer: `${PREFIX}-gridContainer`,
  descriptionText: `${PREFIX}-descriptionText`,
  sidebar: `${PREFIX}-sidebar`,
  flexGrow: `${PREFIX}-flexGrow`,
  link: `${PREFIX}-link`,
  sidebarItem: `${PREFIX}-sidebarItem`,
  sidebarTextOffset: `${PREFIX}-sidebarTextOffset`,
  sidebarItemTitle: `${PREFIX}-sidebarItemTitle`,
  sidebarItemSubtext: `${PREFIX}-sidebarItemSubtext`,
  privacyStatement: `${PREFIX}-privacyStatement`,
  backdrop: `${PREFIX}-backdrop`,
};

const Root = styled(Grid)(({theme}) => ({
  [`&.${classes.gridContainer}`]: {
    height: '100vh',
    minHeight: '100vh',
  },

  [`& .${classes.rootSmall}`]: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    minWidth: '400px',
  },

  [`& .${classes.casIconSmall}`]: {
    height: '60px',
    marginLeft: '10px',
    marginTop: '10px',
  },

  [`& .${classes.awmIconLarge}`]: {
    maxWidth: '300px',
    width: '100%',
  },

  [`& .${classes.descriptionText}`]: {
    marginTop: '40px',
  },

  [`& .${classes.sidebar}`]: {
    alignItems: 'flex-start',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '20px 10px',
  },

  [`& .${classes.flexGrow}`]: {flexGrow: 1},

  [`& .${classes.link}`]: {
    color: '#fff',
    textDecoration: 'underline dotted',
  },

  [`& .${classes.sidebarItem}`]: {
    alignItems: 'center',
    color: '#fff',
    display: 'flex',
    fontSize: '22px',
    marginTop: '20px',
  },

  [`& .${classes.sidebarTextOffset}`]: {
    paddingLeft: '6px',
  },

  [`& .${classes.sidebarItemTitle}`]: {
    fontWeight: 500,
    marginLeft: '6px',
  },

  [`& .${classes.sidebarItemSubtext}`]: {
    fontSize: '14px',
    marginLeft: '30px',
  },

  [`& .${classes.privacyStatement}`]: {
    marginTop: '70px',
  },

  [`& .${classes.backdrop}`]: {
    backgroundColor: 'rgba(0,0,0,0.05)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

const sidebarItems = [
  {
    title: 'Purchase',
    subtext: 'Check out subscription prices for Anyware Manager',
    icon: FaShoppingCart,
    link: 'https://teradici.com/pricing',
  },
  {
    title: 'Documentation',
    subtext: 'Support for setting up your deployments',
    icon: FaFileAlt,
    link: getDocumentationLink(),
  },
];

function UnauthenticatedApp() {
  const isDisplaySmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const isLoading =
    useSelector((state) => selectVariable(state, 'loginLoading')) || false;

  const renderSidebarItem = (item) => {
    const Icon = item.icon;
    return (
      <div key={item.title}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={item.link}
          className={classes.sidebarItem}
        >
          <Icon />
          <div className={classes.sidebarItemTitle}>{item.title}</div>
        </a>
        <div className={classes.sidebarItemSubtext}>{item.subtext}</div>
      </div>
    );
  };

  if (isLoading) {
    return <LoadingDots className="loginLoadingDots" />;
  }

  return (
    <Root container direction="row" className={classes.gridContainer}>
      <Hidden only="xs">
        <Grid item sm={4} lg={3}>
          <div className={classes.sidebar}>
            <img
              className={classes.awmIconLarge}
              alt="Anyware Logo"
              src={anywareLogoWhite}
            />
            <div className={classes.descriptionText}>{HOME_PAGE_INFO_TEXT}</div>

            <div className={classes.flexGrow} />
            {sidebarItems.map((item) => renderSidebarItem(item))}

            <p className={classes.privacyStatement}>
              This application uses cookies. Read our{' '}
              <a
                href={AWM_PRIVACY_STATEMENT}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                privacy policy
              </a>
              {' here.'}
            </p>
          </div>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={8} lg={9}>
        <div className={isDisplaySmall ? classes.rootSmall : classes.backdrop}>
          <div className={classes.flexGrow} />
          {isDisplaySmall && (
            <img
              className={classes.casIconSmall}
              alt="CAS Logo"
              src={anywareIconWhite}
            />
          )}
          <LoginPanel />
          <div className={classes.flexGrow} />
        </div>
      </Grid>
    </Root>
  );
}

export default UnauthenticatedApp;
