import {
  CLOSE_DIALOG,
  OPEN_DIALOG,
} from 'redux/actions/confirmationDialogActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';

const defaultState = {
  open: false,
  title: '',
  message: '',
  handleConfirm: () => {},
  warningMessage: '',
};

function confirmationDialog(state = defaultState, action) {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        title: action.title,
        message: action.message,
        handleConfirm: action.confirmHandler,
        handleCancel: action.cancelHandler,
        open: true,
        cancelEnabled: action.cancelEnabled,
        displayDontShowAgainCheckbox: action.displayDontShowAgainCheckbox,
        handleCheckboxChange: action.handleCheckboxChange,
        confirmationText: action.confirmationText,
        warningMessage: action.warningMessage,
      };
    case CLEAR_STORE:
    case CLOSE_DIALOG:
      return defaultState;
    default:
      return state;
  }
}

export default confirmationDialog;
