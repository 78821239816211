import PropTypes from 'prop-types';

import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ChevronIcon from '@mui/icons-material/ChevronRight';
import Skeleton from '@mui/material/Skeleton';

import {getCapabilityStatus} from 'utils/utils';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import {
  AD_CONFIG_CAPABILITY_NAME,
  AD_SYNC_CAPABILITY_NAME,
  MFA_CAPABILITY_NAME,
  CAPABILITY_ENABLED_STATE,
} from 'utils/constants';

import {StatusIconText} from 'components/connectors/edit/StatusIconText';

const PREFIX = 'CapabilityInfo';

const classes = {
  title: `${PREFIX}-title`,
  secondaryText: `${PREFIX}-secondaryText`,
  row: `${PREFIX}-row`,
  buttonRow: `${PREFIX}-buttonRow`,
  actionButton: `${PREFIX}-actionButton`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.title}`]: {
    color: '#23242A',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '160%',
    letterSpacing: '0.15px',
  },

  [`& .${classes.secondaryText}`]: {
    ...stylesFromFigma.typographyBody1,
    color: '#555967',
    marginTop: '16px',
    height: '75px',
  },

  [`& .${classes.row}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },

  [`& .${classes.buttonRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexGrow: 1,
    alignItems: 'flex-end',
    paddingTop: '38px',
  },

  [`& .${classes.actionButton}`]: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
    color: '#0288D1',
    alignSelf: 'flex-end',
    marginLeft: 'auto',
  },
}));

const capabilityDescriptionMap = {
  [AD_CONFIG_CAPABILITY_NAME]:
    'To enable authentication with Active Directory, configuring the domain and relevant settings is needed.',
  [AD_SYNC_CAPABILITY_NAME]:
    'To automatically sync Users and Computers information to the UI, enable Active Directory Sync.',
  [MFA_CAPABILITY_NAME]:
    'To add extra security for PCoIP connections, enable Multi-Factor Authentication',
};

function CapabilityInfo({capabilityData = {}, isLoading, onAction}) {
  const {displayName, enabled, enterpriseReadiness} = capabilityData || {};

  const title = displayName || '';
  const secondaryText =
    capabilityDescriptionMap[capabilityData?.capabilityName] || '';

  let actionText = '';
  const capabilityStatus = getCapabilityStatus(enabled, enterpriseReadiness);
  if (isLoading || capabilityStatus === CAPABILITY_ENABLED_STATE) {
    actionText = 'Details';
  } else {
    actionText = 'Update configuration';
  }

  return (
    <Root>
      <Typography variant="subtitle1" className={classes.title}>
        {title || <Skeleton />}
      </Typography>
      <div className={classes.row}>
        {isLoading ? (
          <Skeleton width="inherit" />
        ) : (
          <StatusIconText capabilityStatus={capabilityStatus} />
        )}
      </div>
      <Typography variant="body1" className={classes.secondaryText}>
        {secondaryText || <Skeleton />}
      </Typography>
      <div className={classes.buttonRow}>
        <Button
          variant="text"
          size="medium"
          color="primary"
          className={classes.actionButton}
          onClick={onAction}
          disabled={isLoading}
        >
          <div className={classes.row}>
            {actionText}
            <ChevronIcon />
          </div>
        </Button>
      </div>
    </Root>
  );
}

CapabilityInfo.propTypes = {
  capabilityData: PropTypes.shape({
    enabled: PropTypes.bool,
    enterpriseReadiness: PropTypes.bool,
    recommendedActions: PropTypes.arrayOf(PropTypes.string),
  }),
  isLoading: PropTypes.bool,
  onAction: PropTypes.func,
};

CapabilityInfo.defaultProps = {
  capabilityData: {},
  isLoading: true,
  onAction: () => {},
};

export default CapabilityInfo;
