import {Typography, Grid} from '@mui/material';
import PropTypes from 'prop-types';
import ProviderIcon from 'components/CAM/icons/ProviderIcon/ProviderIcon';
import {AMT, AWS, AZURE, GCP, ONPREM} from '../../../../utils/constants';
import Tag from '../Tag/Tag';

function MachineName({machineName, provider, instanceId, testId}) {
  return (
    <span data-testid={testId}>
      <Grid container direction="row" alignItems="center">
        <ProviderIcon provider={provider} />
        <Typography display="inline">{machineName}</Typography>
        {instanceId && instanceId !== machineName && (
          <Tag displayText={instanceId} idTag small />
        )}
      </Grid>
    </span>
  );
}

MachineName.propTypes = {
  machineName: PropTypes.string.isRequired,
  instanceId: PropTypes.string,
  testId: PropTypes.string,
  provider: PropTypes.oneOf([AZURE, AWS, GCP, AMT, ONPREM, '']),
};

MachineName.defaultProps = {
  provider: '',
  instanceId: '',
  testId: 'machine-name',
};

export default MachineName;
