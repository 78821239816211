/* eslint-disable max-lines */
import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/AddBox';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import hash from 'object-hash';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CamButton from 'components/CAM/buttons/CAMButton/CAMButton';
import IconButton from 'components/CAM/buttons/IconButton/IconButton';
import MachineSelectLabel from 'components/CAM/display/MachineSelectLabel/MachineSelectLabel';
import ProviderIcon from 'components/CAM/icons/ProviderIcon/ProviderIcon';
import SearchableSelect from 'components/CAM/inputs/SearchableSelect/SearchableSelect';
import TextInput from 'components/CAM/inputs/TextInput/TextInput';
import FlippableCard from 'components/CAM/surfaces/FlippableCard/FlippableCard';
import {useAbortController} from 'hooks/useAbortController';
import useDebounce from 'hooks/useDebounce';
import {
  clearDropdownData,
  clearResource,
  fetchAwsInstances,
  fetchAzureInstances,
  fetchAzureResourceGroups,
  fetchData,
  fetchGcpInstances,
  fetchGcpRegions,
  fetchNextPage,
  fetchResource,
  searchAdComputers,
} from 'redux/actions/dataActions';
import {
  AD_COMPUTERS,
  AMT,
  AWS,
  AWS_INSTANCES,
  AWS_REGIONS,
  AZURE,
  AZURE_INSTANCES,
  AZURE_RESOURCE_GROUPS,
  CLOUD_PROVIDERS,
  CLOUD_SERVICE_ACCOUNTS,
  GCP,
  GCP_INSTANCES,
  GCP_REGIONS,
  ONPREM,
  REMOTE_WORKSTATIONS,
  RSM,
} from 'utils/constants';
import {mapProviders, mapResourceToPath} from 'utils/Mappings';
import {selectData, selectDropdownData} from 'utils/reduxSelectors';
import {isEmpty, sanitizeValues, validateMachineName} from 'utils/utils';
import {useQueryRsmDevices} from 'hooks/rsmHooks';
import config from '../../../config';

const PREFIX = 'PageChooseRemoteWorkstations';

const classes = {
  pageContainer: `${PREFIX}-pageContainer`,
  pageTitleContainer: `${PREFIX}-pageTitleContainer`,
  createPageContainer: `${PREFIX}-createPageContainer`,
  bodyText: `${PREFIX}-bodyText`,
  link: `${PREFIX}-link`,
  rootContainer: `${PREFIX}-rootContainer`,
  dropdownMenuItem: `${PREFIX}-dropdownMenuItem`,
  inputSectionTextField: `${PREFIX}-inputSectionTextField`,
  inputOutputGroups: `${PREFIX}-inputOutputGroups`,
};

const StyledGrid = styled(Grid)(({theme}) => ({
  [`& .${classes.pageContainer}`]: theme.createPage.createPageContainer,
  [`& .${classes.pageTitleContainer}`]:
    theme.createPage.createPageTitleContainer,
  [`& .${classes.createPageContainer}`]: theme.createPage.createPageContainer,

  [`& .${classes.bodyText}`]: {
    color: '#D8D8D8',
    fontWeight: 500,
  },

  [`& .${classes.link}`]: {
    textDecoration: 'underline',
    color: 'white',
  },

  [`& .${classes.rootContainer}`]: {
    paddingTop: '0.5rem',
  },

  [`& .${classes.dropdownMenuItem}`]: {
    ...theme.dropDown,
    paddingLeft: '8px',
  },

  [`& .${classes.inputSectionTextField}`]: {
    backgroundColor: theme.palette.surface.white,
    marginRight: '20px',
  },

  [`& .${classes.inputOutputGroups}`]: {
    padding: '20px',
    height: '100%',
  },
}));

const adProvider = 'ADPROVIDER';

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  style: {maxHeight: '50vh'},
};

const selectProps = {
  MenuProps: menuProps,
  IconComponent: KeyboardArrowDown,
  displayEmpty: true,
};

const inputProps = {
  style: {
    minWidth: '10rem',
    height: '36px',
    backgroundColor: '#fff',
  },
};

function isAdProvider(provider) {
  return provider === ONPREM || provider === AMT;
}

function PageChooseRemoteWorkstations({
  visible,
  deploymentId,
  selectedProvider,
  setSelectedProvider,
  selectedInstances,
  setSelectedInstances,
}) {
  const [abortController, resetAbortController] = useAbortController();

  const dispatch = useDispatch();

  const MAX_ADDED_MACHINES_AT_A_TIME = 15;

  const [isCardFlipped, setIsCardFlipped] = useState(false);
  const [machineNameError, setMachineNameError] = useState('');

  const [selectedAwsRegion, setSelectedAwsRegion] = useState('');
  const [selectedAzureResourceGroup, setSelectedAzureResourceGroup] =
    useState('');

  const [selectedGcpRegion, setSelectedGcpRegion] = useState('');
  const [selectedGcpZone, setSelectedGcpZone] = useState('');

  const [textInputFilter, setTextInputFilter] = useState('');

  const [inputMachineName, setInputMachineName] = useState('');
  const [inputMachineNameDebounced] = useDebounce(inputMachineName, 1500);

  const {data: cloudServiceAccounts, isFetching: fetchingCloudServiceAccounts} =
    useSelector((state) => selectData(state, CLOUD_SERVICE_ACCOUNTS));
  const {data: existingRW, isFetching: isFetchingExistingRW} = useSelector(
    (state) => selectData(state, REMOTE_WORKSTATIONS)
  );

  const getProviderConfig = (provider) => {
    const value = provider;
    const name = mapProviders(provider);
    let disabled = false;
    if (provider !== ONPREM) {
      disabled =
        isEmpty(
          cloudServiceAccounts.find(
            (account) =>
              account.provider === provider &&
              account.deploymentId === deploymentId
          )
        ) || fetchingCloudServiceAccounts;
    }
    return {
      value,
      name,
      disabled,
    };
  };

  const providers = CLOUD_PROVIDERS.filter((value) => {
    if (
      value === '-' ||
      (value === AMT && !config.AMT_SUPPORTED) ||
      (value === RSM && !config.RSM_SUPPORTED)
    ) {
      return false;
    }
    return true;
  }).map(getProviderConfig);

  const {data: adInstances, isFetching: fetchingAdInstances} = useSelector(
    (state) => selectDropdownData(state, AD_COMPUTERS)
  );
  const {data: awsInstances, isFetching: fetchingAwsInstances} = useSelector(
    (state) => selectData(state, AWS_INSTANCES)
  );
  const {data: azureInstances, isFetching: fetchingAzureInstances} =
    useSelector((state) => selectData(state, AZURE_INSTANCES));
  const {data: gcpInstances, isFetching: fetchingGcpInstances} = useSelector(
    (state) => selectData(state, GCP_INSTANCES)
  );
  const {data: azureResourceGroups, isFetching: fetchingAzureResourceGroups} =
    useSelector((state) => selectData(state, AZURE_RESOURCE_GROUPS));
  const {data: gcpRegions, isFetching: fetchingGcpRegions} = useSelector(
    (state) => selectData(state, GCP_REGIONS)
  );
  const {data: _rsmInstances, isFetching: fetchingRsmInstances} =
    useQueryRsmDevices(deploymentId, {enabled: selectedProvider === RSM});
  let rsmInstances = [];
  if (Array.isArray(_rsmInstances)) {
    rsmInstances = _rsmInstances;
  }

  let fetchingInstances =
    fetchingAdInstances ||
    fetchingAwsInstances ||
    fetchingAzureInstances ||
    fetchingGcpInstances;
  if (selectedProvider === RSM) {
    fetchingInstances = fetchingRsmInstances;
  }

  const instancesToSelectFrom = [
    ...adInstances.map((instance) => ({...instance, provider: adProvider})),
    ...awsInstances,
    ...azureInstances,
    ...gcpInstances,
    ...rsmInstances.map((rsmInstance) => ({...rsmInstance, provider: RSM})),
  ].map((instance) => ({...instance, hash: hash(instance)}));

  const filterInstanceInputPlaceholder = () => {
    if (fetchingInstances) {
      return `Fetching ${mapProviders(selectedProvider)} instances...`;
    }

    switch (selectedProvider) {
      case AWS:
        if (!selectedAwsRegion) {
          return 'Please select AWS region to display instances';
        }
        break;

      case AZURE:
        if (!selectedAzureResourceGroup) {
          return 'Please select Azure resource group to display instances';
        }
        break;

      case GCP:
        if (!selectedGcpRegion) {
          return 'Please select GCP region to display instances';
        }
        if (!selectedGcpZone) {
          return 'Please select GCP zone to display instances';
        }
        break;

      case AMT:
        break;

      case RSM:
        break;

      case ONPREM:
        break;

      default:
        return 'Please select a provider to display instances';
    }

    return `Type to search or click to select ${mapProviders(
      selectedProvider
    )} remote workstations.`;
  };

  const renderProviderSelection = () => (
    <Grid item>
      <Typography className={classes.inputTextFieldLabel}>Provider</Typography>
      <TextField
        data-testid="select-provider"
        select
        variant="outlined"
        SelectProps={selectProps}
        InputProps={inputProps}
        value={selectedProvider || ''}
        onChange={(event) => setSelectedProvider(event.target.value)}
        className={classes.inputSectionTextField}
      >
        {providers.map((provider) => (
          <MenuItem
            key={provider.value}
            value={provider.value}
            disabled={provider.disabled}
            className={classes.dropdownMenuItem}
            data-testid={`select-provider-${provider.value}`}
          >
            <Grid container alignItems="center">
              <ProviderIcon provider={provider.value} />
              {provider.name}
            </Grid>
            {fetchingCloudServiceAccounts && !isAdProvider(provider.value)
              ? ' (Verifying credentials...)'
              : ''}
          </MenuItem>
        ))}
      </TextField>
      {isAdProvider(selectedProvider) && (
        <CamButton
          buttonText="Define your own machines"
          onClick={() => setIsCardFlipped(true)}
        />
      )}
    </Grid>
  );

  const renderAwsSpecifSelection = () => (
    <Grid item>
      <Typography className={classes.inputTextFieldLabel}>
        AWS Region
      </Typography>
      <TextField
        data-testid="select-aws-region"
        select
        variant="outlined"
        SelectProps={selectProps}
        InputProps={inputProps}
        value={selectedAwsRegion || ''}
        onChange={(event) => setSelectedAwsRegion(event.target.value)}
        className={classes.inputSectionTextField}
      >
        {Object.entries(AWS_REGIONS).map(([reg, regionName]) => (
          <MenuItem key={reg} value={reg} className={classes.dropdownMenuItem}>
            {`${reg} - ${regionName}`}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );

  const renderAzureSpecifSelection = () => (
    <Grid>
      <Typography className={classes.inputTextFieldLabel}>
        Azure Resource group
      </Typography>
      {fetchingAzureResourceGroups ? (
        'Loading ...'
      ) : (
        <TextField
          data-testid="select-azure-resource-group"
          select
          SelectProps={selectProps}
          InputProps={inputProps}
          variant="outlined"
          value={selectedAzureResourceGroup || ''}
          onChange={(event) =>
            setSelectedAzureResourceGroup(event.target.value)
          }
          className={classes.inputSectionTextField}
        >
          {azureResourceGroups.map((rg) => (
            <MenuItem
              key={rg.name}
              value={rg.name}
              className={classes.dropdownMenuItem}
            >
              {`${rg.name} (${rg.location})`}
            </MenuItem>
          ))}
        </TextField>
      )}
    </Grid>
  );

  const renderGcpSpecifSelection = () => {
    const gcpZones =
      selectedGcpRegion &&
      gcpRegions.find((region) => region.name === selectedGcpRegion).zones;
    return (
      <>
        <Grid>
          <Typography className={classes.inputTextFieldLabel}>
            GCP Region
          </Typography>
          {fetchingGcpRegions ? (
            'Loading ...'
          ) : (
            <TextField
              data-testid="select-gcp-region"
              select
              SelectProps={selectProps}
              InputProps={inputProps}
              variant="outlined"
              value={selectedGcpRegion || ''}
              onChange={(event) => setSelectedGcpRegion(event.target.value)}
              className={classes.inputSectionTextField}
              placeholder="select gcp region"
            >
              {gcpRegions.map((region) => (
                <MenuItem
                  key={region.name}
                  value={region.name}
                  className={classes.dropdownMenuItem}
                >
                  {region.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>

        {selectedGcpRegion && (
          <Grid>
            <Typography className={classes.inputTextFieldLabel}>
              GCP Zone
            </Typography>
            <TextField
              data-testid="select-gcp-zone"
              select
              variant="outlined"
              value={selectedGcpZone || ''}
              onChange={(event) => setSelectedGcpZone(event.target.value)}
              className={classes.inputSectionTextField}
              SelectProps={selectProps}
              InputProps={inputProps}
            >
              {gcpZones.map((zone) => (
                <MenuItem
                  key={zone}
                  value={zone}
                  className={classes.dropdownMenuItem}
                >
                  {zone}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
      </>
    );
  };

  const handleAddInstance = (clickedInstance) => {
    if (!clickedInstance) {
      return;
    }

    const selectedInstance = clickedInstance;

    // OnPrem and Intel AMT providers share AD machines so for the filters to work
    // correctly the provider must be set when the workstation is selected
    if (selectedInstance.length > 0) {
      if (
        isAdProvider(selectedProvider) ||
        selectedInstance[0].data.provider === adProvider
      ) {
        selectedInstance[0].data.provider = selectedProvider;
      }
    }

    const newSelection = selectedInstances.concat(selectedInstance);
    setSelectedInstances(newSelection);
  };

  const handleRemoveInstance = (remainingInstances) => {
    setSelectedInstances(remainingInstances || []);
  };

  const isExistingMachine = (instanceName) => {
    const machineNames = [
      ...selectedInstances.map(({data: {name}}) => name),
      ...existingRW
        .filter(
          ({deploymentId: machineDeplId}) => machineDeplId === deploymentId
        )
        .map(({machineName}) => machineName),
    ];
    return machineNames.includes(instanceName);
  };

  const handleMachineNameUpdate = (value) => {
    const {machineNameErrorMessage} = validateMachineName(
      value,
      selectedProvider
    );
    setMachineNameError(machineNameErrorMessage);

    setInputMachineName(value);
  };

  const handleOnTextInputInstances = (text) => {
    if (isAdProvider(selectedProvider)) {
      dispatch(clearDropdownData(AD_COMPUTERS));
      resetAbortController();
      dispatch(
        searchAdComputers(
          text,
          {deploymentId, filterAdded: true},
          {signal: abortController.current.signal}
        )
      );
    } else {
      setTextInputFilter(text || '');
    }
  };

  const handleScrollWorkstationsToBottom = (text) => {
    if (!isAdProvider(selectedProvider)) {
      return;
    }
    resetAbortController();
    dispatch(
      fetchNextPage(
        AD_COMPUTERS,
        {
          computerName: `includes:${text}`,
          deploymentId,
          filterAdded: true,
        },
        25,
        {signal: abortController.current.signal}
      )
    );
  };

  const filterInstancesByTextInput = (instances) =>
    instances.filter((instance) => {
      if (isAdProvider(instance.data.provider)) {
        return true;
      }
      if (!textInputFilter) {
        return true;
      }
      if (
        instance.data.displayName
          .toLowerCase()
          .includes(textInputFilter.toLowerCase())
      ) {
        return true;
      }
      return false;
    });

  const filterByHashAndProvider = ({hash: instanceHash, provider}) => {
    const machineAlreadySelected = !selectedInstances
      .map((i) => i.data.hash)
      .includes(instanceHash);

    // Intel AMT and OnPrem share AD workstations
    if (
      (provider === adProvider || isAdProvider(provider)) &&
      isAdProvider(selectedProvider)
    ) {
      return machineAlreadySelected;
    }
    return machineAlreadySelected && provider === selectedProvider;
  };

  // Helper methods for instance details
  const getInstanceName = (instance) =>
    instance.name ||
    instance.instanceName ||
    instance.instanceId ||
    instance.computerName;
  const getInstanceConnectionInfo = (instance) =>
    instance.privateIp ||
    instance.privateIP ||
    instance.computerHostname ||
    instance.computerName;
  const getInstanceSelectValue = (instance) => {
    const {provider, name, instanceName, instanceId, projectId, resourceGroup} =
      instance;

    const selectValueElements = [provider];
    switch (provider) {
      case AWS:
        selectValueElements.push(instanceName, instanceId);
        break;
      case AZURE:
        selectValueElements.push(name, resourceGroup);
        break;
      case GCP:
        selectValueElements.push(name, projectId);
        break;
      case AMT:
      case ONPREM:
      default:
        selectValueElements.push(
          getInstanceName(instance),
          getInstanceConnectionInfo(instance)
        );
        break;
    }
    return selectValueElements.join(':');
  };

  const prepareInstance = (instance) => {
    const displayName = getInstanceName(instance);
    const originalConnectionInfo = getInstanceConnectionInfo(instance);
    const data = {
      ...instance,
      displayName,
      originalConnectionInfo,
      connectionInfo: originalConnectionInfo,
      region: selectedGcpRegion || selectedAwsRegion,
    };
    return {
      label: <MachineSelectLabel instance={instance} />,
      value: getInstanceSelectValue(instance),
      data,
      isDisabled: selectedInstances.length >= MAX_ADDED_MACHINES_AT_A_TIME,
    };
  };

  const prepareInstances = (instances) => {
    const instancesToDisplay = instances
      .filter(filterByHashAndProvider)
      .map(prepareInstance);
    const filteredInstances = filterInstancesByTextInput(instancesToDisplay);
    return filteredInstances;
  };

  const inputEmptyMessage = () => {
    if (!selectedProvider) {
      return 'Please select a provider to display instances';
    }
    return `No more ${mapProviders(
      selectedProvider
    )} remote workstations to display.`;
  };

  const renderInstanceSelectionInput = () => (
    <SearchableSelect
      id="search-instance"
      data-testid="search-instance"
      emptyMessage={inputEmptyMessage()}
      isLoading={fetchingInstances}
      onChange={handleAddInstance}
      onTextInput={handleOnTextInputInstances}
      onMenuScrollToBottom={handleScrollWorkstationsToBottom}
      options={prepareInstances(instancesToSelectFrom)}
      isMulti
      value={[]}
      placeholderText={filterInstanceInputPlaceholder()}
    />
  );

  const renderInstanceSelectionOutput = () => (
    <SearchableSelect
      id="instances-output"
      data-testid="selected-instances-output"
      disabledMessage=""
      emptyMessage=""
      isDisabled={false}
      isLoading={false}
      isSearchable={false}
      onChange={handleRemoveInstance}
      onTextInput={() => {}}
      isMulti
      value={selectedInstances}
      placeholderText="Selected workstations will be displayed here..."
    />
  );

  useEffect(() => {
    switch (selectedProvider) {
      case AZURE:
        dispatch(
          fetchAzureResourceGroups(
            deploymentId,
            cloudServiceAccounts.find((account) => account.provider === AZURE)
              .subscriptionId
          )
        );
        break;

      case GCP:
        if (isEmpty(gcpRegions)) {
          dispatch(fetchGcpRegions(deploymentId));
        }
        break;

      case AMT:
      case ONPREM:
        dispatch(clearDropdownData(AD_COMPUTERS));
        resetAbortController();
        dispatch(
          fetchNextPage(AD_COMPUTERS, {deploymentId, filterAdded: true}, 25, {
            signal: abortController.current.signal,
          })
        );
        break;

      default:
        break;
    }
    setSelectedGcpRegion('');
    setSelectedGcpZone('');
    setSelectedAwsRegion('');
    setSelectedAzureResourceGroup('');
    dispatch(clearResource(AWS_INSTANCES));
    dispatch(clearResource(GCP_INSTANCES));
    dispatch(clearResource(AZURE_INSTANCES));
  }, [selectedProvider]);

  useEffect(() => {
    setSelectedGcpZone('');
  }, [selectedGcpRegion]);

  useEffect(() => {
    const path = mapResourceToPath(REMOTE_WORKSTATIONS);
    dispatch(clearResource(REMOTE_WORKSTATIONS));
    dispatch(
      fetchResource(REMOTE_WORKSTATIONS, {
        path,
        limit: 200,
        machineName: inputMachineNameDebounced,
        machineNameFilter: 'includes',
      })
    );
  }, [inputMachineNameDebounced]);

  useEffect(() => {
    if (!isEmpty(inputMachineName) && isExistingMachine(inputMachineName)) {
      setMachineNameError('Machine with this name already exists.');
    }
  }, [existingRW]);

  useEffect(() => {
    switch (selectedProvider) {
      case AWS:
        if (selectedAwsRegion) {
          dispatch(fetchAwsInstances(deploymentId, selectedAwsRegion));
        }
        break;
      case AZURE:
        if (selectedAzureResourceGroup) {
          dispatch(
            fetchAzureInstances(deploymentId, selectedAzureResourceGroup)
          );
        }
        break;
      case GCP:
        if (selectedGcpZone) {
          dispatch(fetchGcpInstances(deploymentId, selectedGcpZone));
        }
        break;
      default:
        break;
    }
  }, [selectedGcpZone, selectedAwsRegion, selectedAzureResourceGroup]);

  useEffect(() => {
    if (!visible) {
      setIsCardFlipped(false);
    }
  }, [visible]);

  useEffect(() => {
    const path = mapResourceToPath(REMOTE_WORKSTATIONS);
    dispatch(fetchData(REMOTE_WORKSTATIONS, {path, limit: 200}));
  }, []);

  if (!visible) {
    return null;
  }

  const addInputMachine = () => {
    const sanitizedName = sanitizeValues(inputMachineName);
    if (!sanitizedName) {
      return;
    }

    if (!isExistingMachine(sanitizedName)) {
      const instance = {
        name: sanitizedName,
        provider: selectedProvider,
        computerHostname: sanitizedName,
      };
      instance.hash = hash(instance);
      const newSelection = selectedInstances.concat(
        prepareInstances([instance])
      );
      setSelectedInstances(newSelection);
      setInputMachineName('');
    }
  };

  const renderFrontInputCard = () => (
    <>
      <Typography variant="h6">Where are the remote workstations?</Typography>
      <br />
      <Grid container>
        {renderProviderSelection()}
        {selectedProvider === AWS && renderAwsSpecifSelection()}
        {selectedProvider === AZURE && renderAzureSpecifSelection()}
        {selectedProvider === GCP && renderGcpSpecifSelection()}
      </Grid>
      <br />
      You can add multiple remote workstations at a time. They will have the
      same pool membership and entitled users. They can also be edited later.
      <br />
      <br />
      {selectedProvider && !isAdProvider(selectedProvider) && (
        <>
          {`The following workstations were found on ${mapProviders(
            selectedProvider
          )} which may not be configured for PCoIP access.
        Please only add workstations intended for remote access via PCoIP.`}
          <br />
          <br />
        </>
      )}
      {selectedProvider && selectedProvider === ONPREM && (
        <>
          {`The workstations below were found in Active Directory.
          They can also be added by selecting the provider where they are hosted.
          If added using a provider instead, you will be able to power manage the workstations via Anyware Manager.`}
          <br />
          <br />
        </>
      )}
      {selectedProvider && selectedProvider === AMT && (
        <>
          {`The workstations below were found in Active Directory.
          If they support Intel AMT and the required credentials are configured
          you will be able to power manage the workstations via Anyware Manager.`}
          <br />
          <br />
        </>
      )}
      {renderInstanceSelectionInput()}
    </>
  );

  const renderBackInputCard = () => (
    <>
      <Typography variant="h6">{`Define ${mapProviders(
        selectedProvider
      )} workstations`}</Typography>
      <br />
      The machines defined here must be domain joined &amp; have a PCoIP agent
      installed in order for users to connect.
      {selectedProvider && selectedProvider === AMT && (
        <>
          {` If they support Intel AMT and the required credentials are configured
          you will be able to power manage the workstations via Anyware Manager.`}
        </>
      )}
      <br />
      <br />
      Pressing &apos;Enter&apos; or clicking the &apos;Add&apos; button will
      confirm the machine to be added.
      <br />
      <br />
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={11}>
          <TextInput
            label="Workstation name"
            value={inputMachineName}
            onChange={handleMachineNameUpdate}
            onEnterKey={addInputMachine}
            placeholder="Enter the workstation name"
            isError={!isEmpty(machineNameError)}
            helperText={machineNameError}
          />
        </Grid>
        <Grid item xs={1}>
          {/* FIXME: the br attempts to keep the add button in place based on error state of the text input */}
          {!machineNameError && <br />}
          <IconButton
            icon={AddIcon}
            disabled={
              !inputMachineName ||
              isExistingMachine(inputMachineName) ||
              isFetchingExistingRW
            }
            onClick={addInputMachine}
            size="large"
          />
        </Grid>
      </Grid>
      <br />
      <br />
      <CamButton
        buttonText="return to workstation selection"
        onClick={() => setIsCardFlipped(false)}
      />
    </>
  );

  return (
    <StyledGrid container spacing={1}>
      <Grid item xs={12} lg={5}>
        <FlippableCard isFlipped={isCardFlipped}>
          {renderFrontInputCard()}
          {renderBackInputCard()}
        </FlippableCard>
      </Grid>
      <Grid item xs={12} lg={7}>
        <Paper className={classes.inputOutputGroups} variant="outlined">
          <Typography variant="h6">
            Remote workstations to be added ({selectedInstances.length})
          </Typography>
          <br />

          <Typography>
            You have {selectedInstances.length} remote workstations selected.
            <br />
          </Typography>

          {renderInstanceSelectionOutput()}
        </Paper>
      </Grid>
    </StyledGrid>
  );
}

PageChooseRemoteWorkstations.propTypes = {
  visible: PropTypes.bool.isRequired,
  setSelectedProvider: PropTypes.func.isRequired,
  selectedProvider: PropTypes.string.isRequired,
  setSelectedInstances: PropTypes.func.isRequired,
  selectedInstances: PropTypes.array.isRequired,
  deploymentId: PropTypes.string.isRequired,
};

export default PageChooseRemoteWorkstations;
