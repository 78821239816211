import {
  CLOSE_DELETE_WORKSTATION_DIALOG,
  OPEN_DELETE_WORKSTATION_DIALOG,
  TOGGLE_DELETE_WORKSTATION_FROM_CSP,
} from 'redux/actions/deleteRemoteWorkstationDialogActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';

const defaultState = {
  open: false,
  deleteFromCspEnabled: false,
  machineId: '',
};

function deleteRemoteWorkstationDialog(state = defaultState, action) {
  switch (action.type) {
    case OPEN_DELETE_WORKSTATION_DIALOG:
      return {
        ...state,
        open: true,
        machineId: action.machineId,
      };
    case TOGGLE_DELETE_WORKSTATION_FROM_CSP:
      return {
        ...state,
        deleteFromCspEnabled: !state.deleteFromCspEnabled,
      };
    case CLEAR_STORE:
    case CLOSE_DELETE_WORKSTATION_DIALOG:
      return defaultState;
    default:
      return state;
  }
}

export default deleteRemoteWorkstationDialog;
