import {Grid, IconButton} from '@mui/material';
import {styled} from '@mui/material/styles';
import AddMachinesIcon from '@mui/icons-material/AddToQueue';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AddAdGroupsIcon from '@mui/icons-material/GroupAdd';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LeftArrowIcon from '@mui/icons-material/KeyboardArrowLeft';
import AddUsersIcon from '@mui/icons-material/PersonAdd';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import CAMTooltip from 'components/CAM/display/CAMTooltip/CAMTooltip';
import HorizontalTab from 'components/CAM/tabs/HorizontalTab/HorizontalTab';
import AppBar from 'components/common/AppBar';
import ConditionalWrapper from 'components/common/ConditionalWrapper';
import useBulkActionDialog from 'hooks/useBulkActionDialog';
import usePool from 'hooks/usePool';
import {loadBulkActionsData} from 'redux/actions/bulkActionsDialogActions';
import {
  clearResource,
  fetchAdGroups,
  fetchConnectorSettings,
  fetchData,
  fetchResource,
  receiveResource,
  requestResource,
  saveVariable,
} from 'redux/actions/dataActions';
import {openDeletePoolsDialog} from 'redux/actions/DeletePoolsDialogActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {push} from 'redux/ReduxHistory';
import {
  AD_GROUPS,
  AD_USERS,
  CONNECTOR_SETTINGS,
  POOLS_LINK,
  POOL_GROUPS,
  POOL_MACHINES,
  POOL_MACHINES_USERS,
  POOL_USERS,
  WEBHOOKS,
  REMOTE_WORKSTATIONS,
  TABLE_CELL_TEXT,
} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {
  selectBulkActionsDialogTableParams,
  selectData,
  selectDataForTable,
  selectResource,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import {isEmpty, linkTo} from 'utils/utils';
import config from 'config';
import DeletePoolsDialog from './DeletePoolsDialog';
import EditPoolOverviewTab from './EditPoolOverviewTab';
import PoolMachinesTab from './PoolMachinesTab';
import PoolUsersGroupsTab from './PoolUsersGroupsTab';
import PoolWebhooksTab from './PoolWebhooksTab';

const PREFIX = 'EditPool';

const classes = {
  createPageContainer: `${PREFIX}-createPageContainer`,
  rootContainer: `${PREFIX}-rootContainer`,
  displayName: `${PREFIX}-displayName`,
  actionButton: `${PREFIX}-actionButton`,
  toolbarText: `${PREFIX}-toolbarText`,
  appBarSpacer: `${PREFIX}-appBarSpacer`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.createPageContainer}`]: theme.createPage.createPageContainer,

  [`& .${classes.rootContainer}`]: {
    paddingTop: '0.5rem',
  },

  [`& .${classes.displayName}`]: {
    color: theme.palette.primary.main,
    fontSize: '1.125rem',
    fontWeight: 500,
    letterSpacing: '0.014375rem',
    marginRight: theme.spacing(1),
  },

  [`& .${classes.actionButton}`]: {
    marginLeft: '1.2rem',
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 0.8,
    },
  },

  [`& .${classes.toolbarText}`]: {
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.078125rem',
    marginLeft: '0.5rem',
  },

  [`& .${classes.appBarSpacer}`]: {
    flexGrow: 1,
  },
}));

const POOL_OVERVIEW_TAB = 0;
const POOL_MACHINES_TAB = 1;
const POOL_USERS_TAB = 2;
const POOL_WEBHOOKS_TAB = 3;

const addWorkstationsDialogFields = [
  {
    id: 'machineName',
    text: 'Name',
    type: TABLE_CELL_TEXT,
    sortable: true,
  },
  {
    id: 'status',
    text: 'Status',
    type: TABLE_CELL_TEXT,
  },
  {
    id: 'location',
    text: 'Location',
    type: TABLE_CELL_TEXT,
  },
];

const addUsersDialogFields = [
  {
    id: 'name',
    text: 'Name',
    type: TABLE_CELL_TEXT,
    sortable: true,
  },
  {
    id: 'userName',
    text: 'User Name',
    type: TABLE_CELL_TEXT,
    sortable: true,
  },
  {
    id: 'userGuid',
    text: 'User GUID',
    type: TABLE_CELL_TEXT,
  },
  {
    id: 'status',
    text: 'Status',
    type: TABLE_CELL_TEXT,
  },
];

const addGroupsDialogFields = [
  {id: 'groupDN', text: 'Name', type: TABLE_CELL_TEXT},
  {id: 'groupGuid', text: 'GUID', type: TABLE_CELL_TEXT},
];

const addWebhooksDialogFields = [
  {
    id: 'name',
    text: 'Name',
    type: TABLE_CELL_TEXT,
    sortable: true,
  },
  {
    id: 'eventType',
    text: 'Trigger',
    type: TABLE_CELL_TEXT,
  },
];

function EditPool() {
  const dispatch = useDispatch();

  const {poolId} = useParams();
  const {
    pool,
    isFetchingPool,
    updatePoolInfo,
    savePoolGroups,
    savePoolMachines,
    savePoolUsers,
    savePoolWebhooks,
  } = usePool(poolId);

  const {bulkActionDialog} = useBulkActionDialog();

  const [selectedTab, setSelectedTab] = useState(POOL_OVERVIEW_TAB);

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {
    data: poolMachines,
    isFetching: isFetchingPoolMachines,
    total: totalPoolMachines,
  } = useSelector((state) => selectDataForTable(state, POOL_MACHINES));

  const {data: connectorSettings} = useSelector((state) =>
    selectResource(state, CONNECTOR_SETTINGS)
  );

  // Workstations fetched in this table are excluding ones already added to a pool
  const {data: workstations, isFetching: isFetchingWorkstations} = useSelector(
    (state) => selectData(state, REMOTE_WORKSTATIONS)
  );
  const {data: adUsers, isFetching: isFetchingAdUsers} = useSelector((state) =>
    selectData(state, AD_USERS)
  );
  const {data: adGroups, isFetching: isFetchingAdGroups} = useSelector(
    (state) => selectData(state, AD_GROUPS)
  );

  const {data: poolUsers} = useSelector((state) =>
    selectDataForTable(state, POOL_USERS)
  );
  const {data: poolMachineUsers, isFetching: isFetchingPoolMachineUsers} =
    useSelector((state) => selectData(state, POOL_MACHINES_USERS));

  const {data: poolGroups} = useSelector((state) =>
    selectDataForTable(state, POOL_GROUPS)
  );

  const {
    data: webhooks,
    isFetching: isFetchingWebhooks,
    total: totalWebhooks,
  } = useSelector((state) => selectData(state, WEBHOOKS));

  const machinesDialogParams = useSelector((state) =>
    selectBulkActionsDialogTableParams(state, REMOTE_WORKSTATIONS)
  );
  const adUsersDialogParams = useSelector((state) =>
    selectBulkActionsDialogTableParams(state, AD_USERS)
  );
  const adGroupsDialogParams = useSelector((state) =>
    selectBulkActionsDialogTableParams(state, AD_GROUPS)
  );

  const webhookDialogParams = useSelector((state) =>
    selectBulkActionsDialogTableParams(state, WEBHOOKS)
  );

  const {resource: bulkDialogResource} = useSelector(
    (state) => state.bulkActionsDialog
  );
  const {isDeleting: isPoolDeleting} = useSelector(
    (state) => state.deletePoolsDialog
  );

  const isPoolLoading = () => isFetchingPool;
  const goToPools = () => dispatch(push(linkTo(POOLS_LINK)));

  // async action passed to BulkActionsDialog to save machine selection
  const onSavePoolMachines = (newMachines) => async () => {
    await savePoolMachines(newMachines);
    dispatch(fetchTableData(POOL_MACHINES));
    dispatch(
      fetchResource(REMOTE_WORKSTATIONS, {
        filterPoolMachines: true,
        deploymentId,
      })
    );
  };

  // async action passed to BulkActionsDialog to save user selection
  const onSavePoolUsers = (newUsers) => async () => {
    await savePoolUsers(newUsers);
    dispatch(fetchTableData(POOL_USERS));
    dispatch(fetchResource(AD_USERS, {deploymentId}));
  };

  // async action passed to BulkActionsDialog to save group selection
  const onSavePoolGroups = (newGroups) => async () => {
    await savePoolGroups(newGroups);
    dispatch(fetchTableData(POOL_GROUPS));
  };

  // async action passed to BulkActionsDialog to save webhooks selection
  const onSavePoolWebhooks = (newWebhooks) => async () => {
    await savePoolWebhooks(newWebhooks);
    dispatch(fetchTableData(WEBHOOKS));
  };

  const updateBulkPoolMachines = async (params) => {
    if (deploymentId && poolId) {
      const path = mapResourceToPath(REMOTE_WORKSTATIONS, {
        deploymentId,
        poolId,
      });
      const machineParams = {
        ...params,
        filterPoolMachines: true,
        machineName: params.searchKey,
        machineNameFilter: 'includes',
      };

      // fetch remote workstations that are not assigned to a pool, wait for the async action's promise
      // to resolve, and inform the BulkActionsDialog which remote workstations to display in table
      const {data: fetchedMachines, total} = await dispatch(
        fetchData(REMOTE_WORKSTATIONS, {path, params: machineParams})
      );
      const machineIds = fetchedMachines.map((machine) => machine.machineId);

      dispatch(
        loadBulkActionsData({
          data: machineIds,
          resource: REMOTE_WORKSTATIONS,
          total,
        })
      );
    }
  };

  const updateBulkPoolUsers = async (params) => {
    if (deploymentId) {
      const path = mapResourceToPath(AD_USERS, {deploymentId});
      const adUserParams = {
        ...params,
        name: params.searchKey,
        nameFilter: 'includes',
      };

      // fetch AD users, wait for the async action's promise to resolve, and
      // inform the BulkActionsDialog which AD users to display in table
      const {data: fetchedAdUsers, total} = await dispatch(
        fetchData(AD_USERS, {path, params: adUserParams})
      );
      const adUserIds = fetchedAdUsers.map((adUser) => adUser._id);

      dispatch(
        loadBulkActionsData({
          data: adUserIds,
          resource: AD_USERS,
          total,
        })
      );
    }
  };

  const updateBulkPoolGroups = async () => {
    if (deploymentId) {
      const {data: fetchedAdGroups, total} = await dispatch(fetchAdGroups());
      const adGroupGuids = fetchedAdGroups.map((adGroup) => adGroup.groupGuid);

      dispatch(
        loadBulkActionsData({
          data: adGroupGuids,
          resource: AD_GROUPS,
          total,
        })
      );
    }
  };

  const updateBulkWebhooks = async () => {
    if (deploymentId) {
      const path = mapResourceToPath(WEBHOOKS, {deploymentId});
      const {data: fetchedWebhooks, total} = await dispatch(
        fetchData(WEBHOOKS, {path})
      );
      const webhookIds = fetchedWebhooks.reduce((ids, webhook) => {
        // Check if any associated resource has resourceId equal to poolId
        const hasAssociatedResourceWithPoolId =
          webhook.associatedResources.some(
            (resource) => resource.resourceId === pool.poolId
          );
        // Only include the webhook if it does not have any associated resource with resourceId equal to poolId
        if (!hasAssociatedResourceWithPoolId) {
          ids.push(webhook.id);
        }
        return ids;
      }, []);
      dispatch(
        loadBulkActionsData({
          data: webhookIds,
          resource: WEBHOOKS,
          total,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(requestResource(AD_GROUPS));
    const groups =
      connectorSettings[deploymentId]?.deployment?.poolsGroups || [];
    dispatch(receiveResource(AD_GROUPS, groups));
  }, [JSON.stringify(connectorSettings)]);

  useEffect(() => {
    if (deploymentId && pool.poolId) {
      dispatch(fetchConnectorSettings());
      dispatch(fetchResource(AD_USERS, {deploymentId}));
      dispatch(
        fetchResource(REMOTE_WORKSTATIONS, {
          filterPoolMachines: true,
          deploymentId,
        })
      );
      dispatch(fetchTableData(POOL_USERS));
      dispatch(fetchTableData(POOL_GROUPS));
      dispatch(fetchTableData(POOL_MACHINES));
      dispatch(fetchTableData(WEBHOOKS));
    }
    return () => {
      dispatch(clearResource(POOL_GROUPS));
      dispatch(clearResource(POOL_USERS));
      dispatch(clearResource(POOL_MACHINES));
      dispatch(clearResource(WEBHOOKS));
      dispatch(saveVariable('selectedPool', {}));
    };
  }, [deploymentId, pool.poolId]);

  useEffect(() => {
    if (isPoolDeleting) {
      goToPools();
    }
  }, [isPoolDeleting]);

  useEffect(() => {
    if (
      !isEmpty(machinesDialogParams) &&
      bulkDialogResource === REMOTE_WORKSTATIONS
    ) {
      updateBulkPoolMachines(machinesDialogParams);
    }
  }, [
    machinesDialogParams.limit,
    machinesDialogParams.offset,
    machinesDialogParams.searchKey,
    machinesDialogParams.sortAsc,
    machinesDialogParams.sortKey,
    bulkDialogResource,
  ]);

  useEffect(() => {
    if (!isEmpty(adUsersDialogParams) && bulkDialogResource === AD_USERS) {
      updateBulkPoolUsers(adUsersDialogParams);
    }
  }, [
    adUsersDialogParams.limit,
    adUsersDialogParams.offset,
    adUsersDialogParams.searchKey,
    adUsersDialogParams.sortAsc,
    adUsersDialogParams.sortKey,
    bulkDialogResource,
  ]);

  useEffect(() => {
    if (!isEmpty(adGroupsDialogParams) && bulkDialogResource === AD_GROUPS) {
      updateBulkPoolGroups(adGroupsDialogParams);
    }
  }, [
    adGroupsDialogParams.limit,
    adGroupsDialogParams.offset,
    adGroupsDialogParams.searchKey,
    bulkDialogResource,
  ]);

  useEffect(() => {
    if (!isEmpty(webhookDialogParams) && bulkDialogResource === WEBHOOKS) {
      updateBulkWebhooks(webhookDialogParams);
    }
  }, [
    webhookDialogParams.limit,
    webhookDialogParams.offset,
    webhookDialogParams.searchKey,
    bulkDialogResource,
  ]);

  const prepareBulkMachines = (data) =>
    data.map((machine) => ({...machine, id: machine.machineId}));

  const prepareBulkUsers = (data) =>
    data
      .filter(({poolId: userPoolId}) => poolId === userPoolId)
      .map((user) => ({...user, id: user._id}));

  const prepareBulkGroups = (data) =>
    data.map((group) => ({...group, id: group.groupGuid}));

  const prepareBulkWebhooks = (data) =>
    data.reduce((result, webhook) => {
      const hasAssociatedResourceWithPoolId = webhook.associatedResources.some(
        (resource) => resource.resourceId === pool.poolId
      );
      if (hasAssociatedResourceWithPoolId) {
        result.push({...webhook, id: webhook.id});
      }
      return result;
    }, []);

  const handleAddMachinesClick = () =>
    bulkActionDialog({
      bulkAction: onSavePoolMachines,
      params: {sortKey: 'machineName'},
      existingItems: prepareBulkMachines(poolMachines),
      infoText:
        "A machine's entitled users will not be migrated to the pool. Visit a machine's page to migrate its entitled users.",
      resource: REMOTE_WORKSTATIONS,
      searchPlaceholder: 'Search by machine name',
      tableFields: addWorkstationsDialogFields,
      title: 'Add Remote Workstations',
    });

  const handleAddUsersClick = () =>
    bulkActionDialog({
      bulkAction: onSavePoolUsers,
      params: {sortKey: 'name'},
      existingItems: prepareBulkUsers(poolUsers),
      resource: AD_USERS,
      searchPlaceholder: 'Search by name',
      tableFields: addUsersDialogFields,
      title: 'Add Users',
    });

  const handleAddAdGroupsClick = () =>
    bulkActionDialog({
      bulkAction: onSavePoolGroups,
      existingItems: prepareBulkGroups(poolGroups),
      resource: AD_GROUPS,
      tableFields: addGroupsDialogFields,
      title: 'Add Groups',
    });

  const handleAddWebhooksClick = () =>
    bulkActionDialog({
      bulkAction: onSavePoolWebhooks,
      existingItems: prepareBulkWebhooks(webhooks),
      resource: WEBHOOKS,
      tableFields: addWebhooksDialogFields,
      title: 'Add Webhooks',
    });

  const handleChangeTabs = (newTab) => setSelectedTab(newTab);
  const handleDeleteClick = () => {
    dispatch(openDeletePoolsDialog({pools: [pool]}));
  };

  const areAllUsersInPool = () => {
    const sortFn = (a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };

    const adUserGuids = adUsers.map((user) => user.userGuid).sort(sortFn);
    const poolUserGuids = poolUsers.map((user) => user.userGuid).sort(sortFn);
    return JSON.stringify(adUserGuids) === JSON.stringify(poolUserGuids);
  };

  const appBarButtons = [
    {
      id: 'add-machine-button',
      handler: handleAddMachinesClick,
      icon: <AddMachinesIcon />,
      text: 'ADD REMOTE WORKSTATIONS',
      disabled: isFetchingWorkstations || isEmpty(workstations),
      disabledMessage:
        (isFetchingWorkstations && 'Loading workstations') ||
        (isEmpty(workstations) && 'No available Workstations in deployment') ||
        '',
    },
    {
      id: 'add-user-button',
      handler: handleAddUsersClick,
      icon: <AddUsersIcon />,
      text: 'ADD USERS',
      disabled: isFetchingAdUsers || isEmpty(adUsers) || areAllUsersInPool(),
      disabledMessage:
        (isFetchingAdUsers && 'Loading users') ||
        (isEmpty(adUsers) && 'No Users in deployment') ||
        (areAllUsersInPool() && 'No available users in deployment') ||
        '',
    },
    {
      id: 'add-ad-group-button',
      handler: handleAddAdGroupsClick,
      icon: <AddAdGroupsIcon />,
      text: 'ADD GROUPS',
      disabled: isFetchingAdGroups || isEmpty(adGroups),
      disabledMessage:
        (isFetchingAdGroups && 'Loading groups') ||
        (isEmpty(adGroups) && 'No AD Groups in deployment') ||
        '',
    },
    {
      id: 'delete-button',
      handler: handleDeleteClick,
      icon: <DeleteIcon />,
      text: 'DELETE',
      disabled: isPoolLoading(),
      disabledMessage:
        (isPoolLoading() && 'Pool is still loading') ||
        (isPoolDeleting && 'Pool is currently being deleted') ||
        '',
    },
  ];

  if (config.isWebhooksEnabled()) {
    appBarButtons.splice(3, 0, {
      id: 'add-ad-webhooks-button',
      handler: handleAddWebhooksClick,
      icon: <AddCircleIcon />,
      text: 'ADD WEBHOOKS',
      disabled: isFetchingWebhooks || isEmpty(webhooks),
      disabledMessage:
        (isFetchingWebhooks && 'Loading webhooks') ||
        (isEmpty(webhooks) && 'No Webhooks in deployment') ||
        '',
    });
  }

  const renderPoolOverviewTab = () => (
    <EditPoolOverviewTab pool={pool} onSavePoolInfo={updatePoolInfo} />
  );

  const renderPoolMachinesTab = () => (
    <PoolMachinesTab
      poolMachineUsers={poolMachineUsers}
      poolMachines={poolMachines}
      isFetching={
        isFetchingPoolMachines || isFetchingPool || isFetchingPoolMachineUsers
      }
      total={totalPoolMachines}
    />
  );

  const renderPoolUsersGroupsTab = () => <PoolUsersGroupsTab />;

  const poolWebhooks = webhooks.filter((webhook) => {
    return (
      webhook.associatedResources &&
      webhook.associatedResources.some(
        (resource) => resource.resourceId === pool.poolId
      )
    );
  });

  const renderPoolWebhooksTab = () => (
    <PoolWebhooksTab
      poolWebhooks={poolWebhooks}
      isFetching={
        isFetchingPoolMachines || isFetchingPool || isFetchingWebhooks
      }
      total={totalWebhooks}
    />
  );

  const poolTabs = () => {
    const tabs = {
      [POOL_OVERVIEW_TAB]: renderPoolOverviewTab(),
      [POOL_MACHINES_TAB]: renderPoolMachinesTab(),
      [POOL_USERS_TAB]: renderPoolUsersGroupsTab(),
    };
    if (config.isWebhooksEnabled()) {
      tabs[POOL_WEBHOOKS_TAB] = renderPoolWebhooksTab();
    }
    return tabs;
  };

  const renderAppBarButton = (button) => (
    <ConditionalWrapper
      key={button.id}
      condition={button.disabled}
      wrapper={(children) => (
        <CAMTooltip text={button.disabledMessage} placement="bottom">
          {children}
        </CAMTooltip>
      )}
    >
      <IconButton
        id={button.id}
        onClick={button.handler}
        data-testid={`test-${button.id}`}
        className={classes.actionButton}
        disabled={isPoolLoading() || button.disabled}
        size="large"
      >
        {button.icon}
        <div className={classes.toolbarText}>{button.text}</div>
      </IconButton>
    </ConditionalWrapper>
  );

  const renderAppBar = () => (
    <AppBar loading={isPoolLoading()}>
      <IconButton
        component={Link}
        to={POOLS_LINK}
        id="back-arrow-button"
        data-testid="back-arrow-button"
        size="large"
      >
        <LeftArrowIcon />
      </IconButton>
      {isPoolLoading() ? (
        'Loading pool...'
      ) : (
        <div className={classes.displayName}>{pool.poolName || ''}</div>
      )}
      <div className={classes.appBarSpacer} />
      {appBarButtons.map((button) => renderAppBarButton(button))}
    </AppBar>
  );

  const renderTabs = () => {
    const tabsToRender = ['Overview', 'Remote Workstations', 'Users & Groups'];

    if (config.isWebhooksEnabled()) {
      tabsToRender.push('Webhooks');
    }

    return (
      <div>
        <HorizontalTab
          tabs={tabsToRender}
          selectedTab={selectedTab}
          setSelectedTab={handleChangeTabs}
        />
        {pool && pool.poolId && poolTabs()[selectedTab]}
      </div>
    );
  };

  return (
    <Root>
      <div className={classes.createPageContainer}>
        {renderAppBar()}
        <Grid className={classes.rootContainer}>{renderTabs()}</Grid>
      </div>
      <DeletePoolsDialog />
    </Root>
  );
}

export default EditPool;
