import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
// import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

import ToggleSwitch from 'components/CAM/buttons/ToggleSwitch/ToggleSwitch';
import {ReactComponent as SyncIcon} from 'icons/sync.svg';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import {isEmpty} from 'utils/utils';
import CopyAwcCommand from '../createWizard/CopyAwcCommand';
import {
  activeDirectoryFilterParams,
  activeDirectorySACredsParams,
  activeDirectorySyncParams,
} from '../createWizard/wizardConstants';
import CapabilityStatusDisplay from './CapabilityStatus';
import {
  useWizardState,
  checkRequiredParams,
  generateAwcCommand,
} from '../createWizard/wizardConfigState';

const PREFIX = 'ADSyncConfiguration';

const classes = {
  root: `${PREFIX}-root`,
  fileRow: `${PREFIX}-fileRow`,
  flexRow: `${PREFIX}-flexRow`,
  propTitle: `${PREFIX}-propTitle`,
  statusIcon: `${PREFIX}-statusIcon`,
  statusChip: `${PREFIX}-statusChip`,
  label: `${PREFIX}-label`,
  bodyText: `${PREFIX}-bodyText`,
  header: `${PREFIX}-header`,
  subheader: `${PREFIX}-subheader`,
  form: `${PREFIX}-form`,
  textField: `${PREFIX}-textField`,
  spacing: `${PREFIX}-spacing`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    margin: 'auto',
    maxWidth: '1220px',
  },

  [`& .${classes.fileRow}`]: {
    display: 'flex',
    flexDirection: 'row',
  },

  [`& .${classes.flexRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '32px',
    marginBottom: '16px',
  },

  [`& .${classes.propTitle}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '200px',
  },

  [`& .${classes.statusIcon}`]: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginRight: '16.5px',
  },

  [`& .${classes.statusChip}`]: {
    backgroundColor: '#EBF3FA',
    color: '#1E3BB8',
  },

  [`& .${classes.label}`]: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: 'rgba(0, 0, 0, 0.87)',
  },

  [`& .${classes.bodyText}`]: {
    ...stylesFromFigma.connectorPanelBodyText,
    color: '#555967',
    padding: '16px 0px',
  },

  [`& .${classes.header}`]: {
    ...stylesFromFigma.capabilitySectionTitle,
    marginLeft: '16px',
  },

  [`& .${classes.subheader}`]: {
    ...stylesFromFigma.capabilitySubHeader,
    marginTop: '16px',
  },

  [`& .${classes.form}`]: {marginTop: '12px'},

  [`& .${classes.textField}`]: {
    width: '100%',
    maxWidth: '1000px',
    margin: '6px 0px',
  },

  [`& .${classes.spacing}`]: {
    margin: '16px',
  }
}));

function ADSyncConfiguration({adSyncCapData}) {

  const {configState, setConfigState} = useWizardState();
  const selectedConnector = useSelector(
    (state) => state?.data?.dataByResource?.selectedConnector
  );
  const allFieldsFilled = checkRequiredParams(
    activeDirectorySyncParams,
    configState
  );
  const [configureCommand, setConfigureCommand] = useState('');
  const [allowCommandCopy, setAllowCommandCopy] = useState(false);

  const createConfigureCommand = () => {
    const baseCommand = 'sudo /usr/local/bin/anyware-connector configure';
    const fullCommand = generateAwcCommand({
      baseCommand,
      configParamArray: activeDirectorySyncParams,
      configState,
    });
    setConfigureCommand(fullCommand);
  };

  // Update configure command any time one of the AD sync parameters changes
  useEffect(() => {
    if (allFieldsFilled) {
      createConfigureCommand();
      setAllowCommandCopy(true);
    } else {
      setAllowCommandCopy(false);
    }
  }, [
    ...activeDirectorySyncParams.map((param) => configState[param.fieldName]),
    ...activeDirectorySACredsParams.map(
      (param) => configState[param.fieldName]
    ),
    ...activeDirectoryFilterParams.map((param) => configState[param.fieldName]),
  ]);

  const handleChange = (events) => {
    const eventArray = Array.isArray(events) ? events : [events];

    const updatedState = {...configState};
    eventArray.forEach((event) => {
      const {name, value} = event.target;
      updatedState[name] = value;
    });
    setConfigState(updatedState);
  };

  return (
    (<Root>
      <div className={classes.flexRow}>
        <SyncIcon />
        <Typography className={classes.header}>
          Active Directory Sync
        </Typography>
      </div>
      <Divider />
      <CapabilityStatusDisplay
        classes={classes.spacing}
        capabilityData={adSyncCapData}
        isLoading={isEmpty(selectedConnector)}
      />
      <Typography className={classes.bodyText}>
        Active Directory sync is a capability of the Anyware Connector which
        pushes changes to users & workstations from your domain into Anyware
        Manager.
      </Typography>
      <Typography className={classes.subheader}>
        Active Directory Sync
      </Typography>
      <Typography className={classes.bodyText}>
        Enable Active Directory Sync to sync your user and workstation
        information from your Active Directory into Anyware Manager.
      </Typography>
      <FormControl>
        <FormControlLabel
          label="Enable AD Sync"
          control={
            <ToggleSwitch
              name="enableAdSync"
              isOn={configState.enableAdSync}
              onClick={(event) => {
                const updatedState = {...configState};
                const {name, checked} = event.target;
                updatedState[name] = checked;
                setConfigState(updatedState);
              }}
            />
          }
        />
      </FormControl>
      <Typography className={classes.subheader} variant="h6">
        Service Account details
      </Typography>
      <Typography className={classes.bodyText} variant="body2">
        Enter the username and password for your Active Directory service
        account. This account should have the necessary permissions to access
        and manage objects within your Active Directory domain.
      </Typography>
      <Grid container spacing={2}>
        {activeDirectorySACredsParams.map((param) => (
          <Grid item xs={6} key={param.fieldName}>
            <TextField
              disabled={!configState.enableAdSync}
              required
              className={classes.textField}
              label={param.label}
              value={configState[param.fieldName]}
              placeholder={param.placeholder}
              type={param.isSecret ? 'password' : 'text'}
              name={param.fieldName}
              data-testid={`field - ${param.fieldName} `}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
        ))}
      </Grid>
      <Typography className={classes.subheader} variant="h6">
        Active Directory Filters (Optional)
      </Typography>
      <Typography variant="body2" className={classes.bodyText}>
        To find specific users and computers in AD, use the DN to indicate who
        to search for. You can also narrow down the results by applying a
        filter.
        <br />
        <strong>
          If you have not specified any filters, all Active Directory users and
          computers will be synced.
        </strong>
      </Typography>
      <Grid container spacing={2}>
        {activeDirectoryFilterParams.map((param) => (
          <Grid key={`grid - ${param.fieldName} `} item xs={6}>
            <TextField
              disabled={!configState.enableAdSync}
              className={classes.textField}
              label={param.label}
              value={configState[param.fieldName]}
              placeholder={param.placeholder}
              type={param.isSecret ? 'password' : 'text'}
              name={param.fieldName}
              data-testid={`field - ${param.fieldName} `}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
        ))}
      </Grid>
      <CopyAwcCommand
        isCopyEnabled={allowCommandCopy}
        commandToCopy={configureCommand}
        commandType="configure"
        configParamArray={activeDirectorySyncParams}
        desiredState={configState}
        isApplyEnabled={true}
      />
    </Root>)
  );
}

ADSyncConfiguration.propTypes = {
  adSyncCapData: PropTypes.shape({
    enabled: PropTypes.bool,
    enterpriseReadiness: PropTypes.bool,
    recommendedActions: PropTypes.arrayOf(PropTypes.string),
  }),
};

ADSyncConfiguration.defaultProps = {
  adSyncCapData: {
    enabled: false,
    enterpriseReadiness: false,
    recommendedActions: [],
  },
};

export default ADSyncConfiguration;
