import {Card, CardContent, Grid} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';
import CamSwitch from 'components/common/CamSwitch';

const PREFIX = 'ToggleSwitchBox';

const classes = {
  sessionTrackingCard: `${PREFIX}-sessionTrackingCard`,
  gridItem: `${PREFIX}-gridItem`,
  intervalRow: `${PREFIX}-intervalRow`,
  intervalLabel: `${PREFIX}-intervalLabel`,
  intervalValue: `${PREFIX}-intervalValue`,
};

const StyledCard = styled(Card)(() => ({
  [`&.${classes.sessionTrackingCard}`]: {
    width: '300px',
  },

  [`& .${classes.gridItem}`]: {
    alignSelf: 'center',
  },

  [`& .${classes.intervalRow}`]: {
    marginTop: '8px',
  },

  [`& .${classes.intervalLabel}`]: {
    height: '24px',
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '0.09px',
    lineHeight: '24px',
  },

  [`& .${classes.intervalValue}`]: {
    height: '14px',
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '0.09px',
    lineHeight: '14px',
    marginLeft: '40px',
  },
}));

function ToggleSwitchBox({
  isOn,
  displayText,
  onClick,
  isSaving,
  disabled,
  tooltipText,
  testId,
}) {
  return (
    <StyledCard
      elevation={0}
      variant="outlined"
      className={classes.sessionTrackingCard}
    >
      <CardContent>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item className={classes.gridItem} sx={{flex: 1}}>
            <InputLabel displayText={displayText} tooltipText={tooltipText} />
          </Grid>
          <Grid item className={classes.gridItem}>
            <CamSwitch
              checked={isOn}
              onChange={(event) => onClick(event.target.checked)}
              inputProps={{
                'data-testid': `${testId}-switch`,
              }}
              disabled={isSaving || disabled}
            />
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
}

ToggleSwitchBox.propTypes = {
  isSaving: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isOn: PropTypes.bool,
  disabled: PropTypes.bool,
  displayText: PropTypes.string,
  tooltipText: PropTypes.string,
  testId: PropTypes.string,
};

ToggleSwitchBox.defaultProps = {
  isSaving: true,
  isOn: false,
  disabled: false,
  displayText: '',
  tooltipText: '',
  testId: '',
};

export default ToggleSwitchBox;
