import { styled } from '@mui/material/styles';
import OkIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import UnknownIcon from '@mui/icons-material/Help';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {openConnectorHealthDialog} from 'redux/actions/connectorHealthDialogActions';

const PREFIX = 'ConnectorHealth';

const classes = {
  root: `${PREFIX}-root`,
  statusIcon: `${PREFIX}-statusIcon`,
  healthy: `${PREFIX}-healthy`,
  unhealthy: `${PREFIX}-unhealthy`,
  unknown: `${PREFIX}-unknown`
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  [`& .${classes.statusIcon}`]: {
    marginRight: '0.5rem',
    width: '0.75 rem',
  },

  [`& .${classes.healthy}`]: {
    color: '#04844B',
  },

  [`& .${classes.unhealthy}`]: {
    color: '#C23934',
  },

  [`& .${classes.unknown}`]: {
    color: '#706E6B',
  }
}));

function getIcon(healthStatus) {
  switch (healthStatus) {
    case 'Healthy':
      return OkIcon;
    case 'Unhealthy':
      return ErrorIcon;
    case 'Unknown':
      return UnknownIcon;
    default:
      return UnknownIcon;
  }
}

function ConnectorHealth({connector}) {

  const dispatch = useDispatch();
  const {healthStatus} = connector;

  const Icon = getIcon(healthStatus);

  return (
    <Root
      data-testid={`${connector.connectorId}-health-status`}
      className={classes.root}
      onClick={() => dispatch(openConnectorHealthDialog(connector))}
      role="button"
    >
      <Icon
        className={classNames(classes.statusIcon, {
          [classes.healthy]: healthStatus === 'Healthy',
          [classes.unhealthy]: healthStatus === 'Unhealthy',
          [classes.unknown]: healthStatus === 'Unknown' || !healthStatus,
        })}
      />
      {healthStatus}
    </Root>
  );
}

ConnectorHealth.propTypes = {
  connector: PropTypes.object,
};

ConnectorHealth.defaultProps = {
  connector: {
    healthStatus: 'Unknown',
  },
};

export default ConnectorHealth;
