import {
  ADD_POLLING_ITEM,
  REMOVE_POLLING_ITEM,
  CLEAR_POLLING_ITEMS,
} from 'redux/actions/pollingActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';

const defaultState = {};

function removeItem(state, key) {
  const {[key]: removedItem, ...newState} = state;
  return newState;
}

export default function pollingReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_POLLING_ITEM:
      return {...state, [action.item.key]: {...action.item}};
    case REMOVE_POLLING_ITEM:
      return removeItem(state, action.key);
    case CLEAR_STORE:
    case CLEAR_POLLING_ITEMS:
      return defaultState;
    default:
      return state;
  }
}
