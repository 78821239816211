import {
  Button,
  CircularProgress,
  Paper,
  Popover,
  TextField,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import classnames from 'classnames';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {ENROLLMENT_COMMANDS} from 'utils/constants';

const PREFIX = 'AddMonitorProvisioningCommand';

const classes = {
  textField: `${PREFIX}-textField`,
  addCommandButton: `${PREFIX}-addCommandButton`,
  createButton: `${PREFIX}-createButton`,
  commandPopup: `${PREFIX}-commandPopup`,
  disabled: `${PREFIX}-disabled`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.textField}`]: {
    margin: theme.spacing(1),
    width: 200,
    height: 56,
    padding: 3,
  },

  [`& .${classes.addCommandButton}`]: {
    marginLeft: '10px',
    fontSize: '28px',
    '&:hover': {
      cursor: 'pointer',
      color: '#005788',
    },
  },

  [`& .${classes.createButton}`]: {
    margin: '20px 12px',
    height: '36px',
    width: '96px',
  },

  [`& .${classes.commandPopup}`]: {
    paddingTop: 15,
    paddingBottom: 5,
  },

  [`& .${classes.disabled}`]: {
    pointerEvents: 'none',
  },
}));

function AddMonitorProvisioningCommand({createCallback}) {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showNameInput, setShowNameInput] = useState(false);
  const [errorHelperText, setErrorHelperText] = useState('');
  const [commandName, setCommandName] = useState('');

  const handleCloseInput = () => {
    setAnchorEl(null);
    setShowNameInput(false);
    setCommandName('');
    setErrorHelperText('');
  };

  const handleCreate = async () => {
    setIsSaving(true);
    setShowNameInput(false);
    await createCallback(commandName);
    dispatch(fetchTableData(ENROLLMENT_COMMANDS));
    setCommandName('');
    setIsSaving(false);
  };

  const handleChange = (event) => {
    const maxLength = 64;
    if (event.target.value.length > maxLength) {
      setErrorHelperText(`Maximum number characters is ${maxLength}`);
    } else {
      setErrorHelperText('');
      setCommandName(event.target.value);
    }
  };

  const nameInput = (
    <Popover
      anchorOrigin={{vertical: 'center', horizontal: 'right'}}
      transformOrigin={{vertical: 'center', horizontal: 'left'}}
      onClose={handleCloseInput}
      open={showNameInput}
      anchorEl={anchorEl}
    >
      <Root>
        <Paper className={classes.commandPopup}>
          <TextField
            data-testid="enrollment-command-name"
            value={commandName}
            required
            error={!!errorHelperText}
            helperText={errorHelperText}
            label="Command Name"
            variant="outlined"
            onChange={handleChange}
            InputProps={{className: classes.textField}}
          />
          <Button
            className={classes.createButton}
            variant="outlined"
            color="primary"
            onClick={handleCreate}
            disabled={commandName.length === 0 || !!errorHelperText}
          >
            Create
          </Button>
        </Paper>
      </Root>
    </Popover>
  );

  return (
    <Root>
      <AddCircleIcon
        data-testid="add-command-button2"
        onClick={
          isSaving
            ? null
            : (event) => {
                setShowNameInput(true);
                setAnchorEl(event.currentTarget);
              }
        }
        size="small"
        color={isSaving ? 'disabled' : 'primary'}
        className={classnames(classes.addCommandButton, {
          [classes.disabled]: isSaving,
        })}
      />
      {isSaving && (
        <CircularProgress data-testid="add-command-spinner" size={14} />
      )}
      {showNameInput && nameInput}
    </Root>
  );
}

AddMonitorProvisioningCommand.propTypes = {
  createCallback: PropTypes.func,
};

AddMonitorProvisioningCommand.defaultProps = {
  createCallback: () => {},
};

export default AddMonitorProvisioningCommand;
