import {AppBar, Button, Hidden, IconButton, Toolbar} from '@mui/material/';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import WarningIcon from '@mui/icons-material/Warning';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import config from 'config';
import IconTextButtonWarning from 'components/common/IconTextButtonWarning';
import {
  fetchCloudServiceAccounts,
  fetchData,
  setDeletedDeploymentPolling,
} from 'redux/actions/dataActions';
import {setDeploymentFilter} from 'redux/actions/deploymentFilterActions';
import {openDeploymentFilterDialog} from 'redux/actions/deploymentFilterDialogActions';
import {toggleSidebar} from 'redux/actions/sidebarActions';
import {push} from 'redux/ReduxHistory';
import {
  AD_USERS_LINK,
  AWM_SERVICE_ACCOUNT_LINK,
  CONNECTORS_LINK,
  DASHBOARD_LINK,
  DEPLOYMENTS,
  EDIT_DEPLOYMENT_LINK,
  POOLS_LINK,
  WORKSTATIONS_LINK,
} from 'utils/constants';
import CasmCookies from 'utils/cookies';
import {mapResourceToPath} from 'utils/Mappings';
import {selectData} from 'utils/reduxSelectors';
import {isEmpty, linkTo} from 'utils/utils';
import NotificationsDropDown from '../notifications/NotificationsDropDown';
import BetaToggle from './BetaToggle';
import DeploymentKebab from './DeploymentKebab';
import HelpDropDown from './HelpDropDown';
import UserMenu from './UserMenu';

const PREFIX = 'TopBar';

const classes = {
  appBar: `${PREFIX}-appBar`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarExpanded: `${PREFIX}-toolbarExpanded`,
  toolbarCollapsed: `${PREFIX}-toolbarCollapsed`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  searchInputRoot: `${PREFIX}-searchInputRoot`,
  searchInputText: `${PREFIX}-searchInputText`,
  grow: `${PREFIX}-grow`,
  deploymentFilterButton: `${PREFIX}-deploymentFilterButton`
};

const StyledAppBar = styled(AppBar)((
  {
    theme
  }
) => ({
  [`&.${classes.appBar}`]: {
    backgroundColor: config.isUpdatedStylingEnabled()
      ? theme.palette.surface.white
      : theme.palette.surface.light,
    borderBottom: '1px solid rgba(0,0,0,0.12)',
    boxShadow: 'none',
  },

  [`& .${classes.toolbar}`]: {
    height: theme.sizes.topBarHeight,
    transition: theme.transitions.create(['width'], {
      delay: 150,
      easing: theme.transitions.easing.ease,
      duration: 200,
    }),
  },

  [`& .${classes.toolbarExpanded}`]: {
    height: theme.sizes.topBarHeight,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.sizes.sidebarExpandedWidth,
      transition: theme.transitions.create(['padding-left'], {
        delay: 150,
        easing: theme.transitions.easing.ease,
        duration: 200,
      }),
    },
  },

  [`& .${classes.toolbarCollapsed}`]: {
    height: theme.sizes.topBarHeight,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.sizes.sidebarCollapsedWidth,
      transition: theme.transitions.create(['padding-left'], {
        delay: 150,
        easing: theme.transitions.easing.ease,
        duration: 200,
      }),
    },
  },

  [`& .${classes.search}`]: {
    position: 'relative',
    border: '1px solid rgba(0,0,0,0.12)',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.surface.white,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '360px',
  },

  [`& .${classes.searchIcon}`]: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    color: theme.palette.primary.main,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.searchInputRoot}`]: {
    color: theme.palette.primary.main,
    width: '100%',
    height: theme.sizes.topBarElementHeight,
  },

  [`& .${classes.searchInputText}`]: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    width: '100%',
    height: '100%',
  },

  [`& .${classes.grow}`]: {flexGrow: 1},

  [`& .${classes.deploymentFilterButton}`]: {
    marginLeft: '40px',
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '1.25px',
  }
}));

function TopBar({handleSignout}) {

  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState('');
  const [userName, setUserName] = useState('');
  const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);
  const [redirectContextLink, setRedirectContextLink] = useState('');
  const [loadingDeployments, setLoadingDeployments] = useState(true);
  const {pathname} = useSelector((state) => state.router);
  const {open} = useSelector((state) => state.sidebar);
  const {data: deployments} = useSelector((state) =>
    selectData(state, DEPLOYMENTS)
  );

  const {azureSecretsExpiring} = useSelector(
    (state) => state.data.dataByResource || {azureSecretsExpiring: 0}
  );

  useEffect(() => {
    const path = mapResourceToPath(DEPLOYMENTS);
    dispatch(
      fetchData(DEPLOYMENTS, {
        path,
        params: {
          offset: 0,
          limit: 400,
          showdeleting: true,
        },
      })
    );
  }, []);

  const selectedDeployment = useSelector(
    (state) => state.data.dataByResource.selectedDeployment || {}
  );

  function setRedirectContext() {
    if (
      pathname.includes('deployments/edit') ||
      pathname.includes('remoteWorkstations/addExisting')
    ) {
      setRedirectContextLink(DASHBOARD_LINK);
    } else if (pathname.includes('connectors/edit')) {
      setRedirectContextLink(CONNECTORS_LINK);
    } else if (pathname.includes('remoteWorkstations/edit')) {
      setRedirectContextLink(WORKSTATIONS_LINK);
    } else if (pathname.includes('adUsers/view')) {
      setRedirectContextLink(AD_USERS_LINK);
    } else if (pathname.includes('pools/edit')) {
      setRedirectContextLink(POOLS_LINK);
    } else {
      setRedirectContextLink('');
    }
  }

  function redirectBasedOnContext() {
    dispatch(push(redirectContextLink));
    setRedirectContextLink('');
  }

  const getUserName = () => {
    const userToken = CasmCookies.getDecodedAuthToken();
    if (userToken && userToken.upn) {
      setUserName(userToken.upn);
    }
  };

  useEffect(() => {
    setRedirectContext();
  }, [pathname]);

  useEffect(() => {
    getUserName();
  }, []);

  useEffect(() => {
    if (isEmpty(selectedDeployment) && deployments.length > 0) {
      try {
        const localState = JSON.parse(localStorage.state);
        const newDeployment = deployments.find(
          (deployment) =>
            deployment.deploymentId === localState.selectedDeploymentId
        );
        if (!isEmpty(newDeployment)) {
          dispatch(setDeploymentFilter(newDeployment));
        } else {
          dispatch(setDeploymentFilter(deployments[0]));
        }
      } catch {
        dispatch(setDeploymentFilter(deployments[0]));
      }
    }
  }, [JSON.stringify(deployments), selectedDeployment.deploymentId]);

  useEffect(() => {
    setLoadingDeployments(!(deployments && deployments.length > 0));

    if (isEmpty(deployments)) {
      return;
    }
    deployments.forEach((dep) => {
      if (dep.status === 'deleting') {
        dispatch(setDeletedDeploymentPolling(dep));
      }
    });
  }, [JSON.stringify(deployments)]);

  useEffect(() => {
    const {deploymentId} = selectedDeployment;
    if (!isEmpty(deploymentId)) {
      dispatch(fetchCloudServiceAccounts({deploymentId}));
      if (!isFirstPageLoad && redirectContextLink) {
        redirectBasedOnContext();
      }
      setIsFirstPageLoad(false);
    }
  }, [selectedDeployment.deploymentId]);

  const handleSearchInputChange = (event) => setSearchInput(event.target.value);

  const hideDeploymentSelect =
    pathname.includes(AWM_SERVICE_ACCOUNT_LINK) || loadingDeployments;

  const handleOpenDeploymentFilterClick = (e) => {
    // Only open dialog if the event was triggered by an actual mouse click
    // https://stackoverflow.com/a/35381564/11591930
    if (e.detail) {
      dispatch(openDeploymentFilterDialog());
    }
  };

  const clientSecretExpireWarning = (
    <IconTextButtonWarning
      responsive
      icon={<WarningIcon color="error" />}
      buttonText="Fix"
      onClick={() => {
        dispatch(
          push(
            linkTo(`${EDIT_DEPLOYMENT_LINK}/${selectedDeployment.deploymentId}`)
          )
        );
      }}
    >
      Azure client secret will expire soon
    </IconTextButtonWarning>
  );

  return (
    <StyledAppBar className={classes.appBar}>
      <Toolbar
        className={classNames(classes.toolbar, {
          [classes.toolbarExpanded]: open,
          [classes.toolbarCollapsed]: !open,
        })}
      >
        <Hidden mdUp>
          <IconButton onClick={() => dispatch(toggleSidebar())} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>

        {!hideDeploymentSelect && (
          <>
            <Button
              disabled={loadingDeployments}
              onClick={handleOpenDeploymentFilterClick}
              endIcon={<ArrowDropDownIcon />}
              size="small"
              className={classes.deploymentFilterButton}
              color="primary"
              data-testid="select-deployment"
            >
              {loadingDeployments
                ? 'Loading...'
                : selectedDeployment.deploymentName || 'Select deployment'}
            </Button>
            <DeploymentKebab />
          </>
        )}

        {azureSecretsExpiring && !pathname.includes('deployments/edit')
          ? clientSecretExpireWarning
          : null}

        <div className={classes.grow} />
        {!config.STANDALONE && <BetaToggle />}
        <NotificationsDropDown />
        <HelpDropDown />
        <UserMenu handleSignout={handleSignout} userName={userName} />
      </Toolbar>
    </StyledAppBar>
  );
}

TopBar.propTypes = {
  handleSignout: PropTypes.func.isRequired,
};

export default TopBar;
