import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {del, get, post} from 'api/Api';
import CAMPagination from 'components/common/Pagination';
import AddServiceAccount from 'components/deployments/AddServiceAccount';
import useSnackbar from 'hooks/useSnackbar';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {handleApiError} from 'redux/actions/dataActions';
import ServiceAccountsTable from '../common/ServiceAccountsTable';

const PREFIX = 'CamServiceAccount';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
};

const StyledGrid = styled(Grid)(({theme}) => ({
  [`&.${classes.root}`]: {
    margin: `${theme.spacing(4)} ${theme.spacing(2)}`,
    backgroundColor: 'transparent',
    padding: '2px',
    color: theme.palette.common.black,
  },

  [`& .${classes.container}`]: {
    flexWrap: 'wrap',
    padding: '6px 3px',
    marginRight: theme.spacing(8),
  },
}));

function CamServiceAccount() {
  const dispatch = useDispatch();
  const {successSnackbar} = useSnackbar();

  const [camServiceAccounts, setCamServiceAccounts] = useState([]);
  const [isLoadingServiceAccounts, setLoadingServiceAccounts] = useState(true);
  // Search
  const [filteredServiceAccounts, setFilteredServiceAccounts] =
    useState(camServiceAccounts);
  const [accountSearchTerm, setAccountSearchTerm] = useState('');
  // Pagination
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (_, newPage) => setCurrentPage(newPage);
  const handleRowsPerPageChange = ({target: {value}}) => {
    const newRowsPerPage = parseInt(value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0);
  };

  const getCamServiceAccounts = async () => {
    try {
      const limit = rowsPerPage;
      const offset = rowsPerPage * currentPage;
      // Sort cam service accounts by createdOn, last created first
      setLoadingServiceAccounts(true);
      const response = await get({path: 'keys', params: {limit, offset}});
      const receivedCamServiceAccounts = response.data.map((account) => {
        const newAccount = account;
        newAccount.keyName = account.apiKeyName || account.keyName || '';
        return newAccount;
      });
      setTotal(response.total);
      receivedCamServiceAccounts.sort((a, b) =>
        b.createdOn.localeCompare(a.createdOn)
      );
      setCamServiceAccounts(receivedCamServiceAccounts);
    } catch (error) {
      setLoadingServiceAccounts(false);
      dispatch(handleApiError(error));
    }
    setLoadingServiceAccounts(false);
  };

  useEffect(() => {
    getCamServiceAccounts();
  }, [currentPage, rowsPerPage]);

  const handleCreateCamServiceAccount = async (keyName) => {
    let newServiceAccount;
    try {
      const response = await post({
        path: 'keys',
        data: {keyName},
      });
      newServiceAccount = response.data;
      getCamServiceAccounts();
    } catch (error) {
      newServiceAccount = {};
      dispatch(handleApiError(error));
    }
    return newServiceAccount;
  };

  const deleteCamServiceAccount = async ({keyId, keyName}) => {
    try {
      await del({path: `keys/${keyId}`});
    } catch (error) {
      dispatch(handleApiError(error));
      return;
    }
    successSnackbar(`Anyware Manager Service Account ${keyName} was deleted.`);
    getCamServiceAccounts();
  };

  const handleDeleteCamServiceAccountButtonClick = (serviceAcc) => {
    dispatch(
      openDialog(
        'Delete Anyware Manager Service Account?',
        `Are you sure you want to delete this Anyware Manager Service Account ${serviceAcc.keyName}?`,
        () => deleteCamServiceAccount(serviceAcc)
      )
    );
  };

  const handleSearchInputChange = (event) => {
    const searchInputText = event.target.value || '';
    setAccountSearchTerm(searchInputText);
  };

  // Filter Service Accounts for search
  useEffect(() => {
    setFilteredServiceAccounts(
      camServiceAccounts.filter((key) =>
        key.keyName.includes(accountSearchTerm.trim())
      )
    );
  }, [camServiceAccounts, accountSearchTerm]);

  const renderPagination = () => (
    <CAMPagination
      dataTestId="csa-table-pagination"
      rowsPerPageOptions={[10, 25, 50]}
      component="div"
      total={total}
      rowsPerPage={rowsPerPage}
      page={currentPage}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  );

  return (
    <StyledGrid container className={classes.root}>
      <Grid container justifyContent="flex-start" alignItems="center">
        <Typography variant="h5" id="tableTitle">
          Anyware Manager Service Accounts
        </Typography>
        <AddServiceAccount
          createCallback={handleCreateCamServiceAccount}
          serviceAccountType="awm"
        />
      </Grid>

      <Grid container data-testid="service-accounts-table">
        <Grid item xs={12} className={classes.container}>
          <ServiceAccountsTable
            serviceAccounts={filteredServiceAccounts}
            onRemove={handleDeleteCamServiceAccountButtonClick}
            isLoading={isLoadingServiceAccounts}
            handleSearchInputChange={handleSearchInputChange}
          />
          {renderPagination()}
        </Grid>
      </Grid>
    </StyledGrid>
  );
}

export default CamServiceAccount;
