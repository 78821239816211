export const TOGGLE_COLUMN = 'TOGGLE_COLUMN';

export function toggleColumn(resource, columnId) {
  return {
    type: TOGGLE_COLUMN,
    resource,
    columnId,
  };
}

export const MOVE_COLUMN = 'MOVE_COLUMN';

export function moveColumn(resource, columnId, newIndex) {
  return {
    type: MOVE_COLUMN,
    resource,
    columnId,
    newIndex,
  };
}
