import {useRef, useState} from 'react';
import {styled} from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import Popper from '@mui/material/Popper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';

import GenericLink from 'components/common/GenericLink';
import {
  AWC_NETWORK_REQUIREMENTS_LINK,
  AWC_SYSTEM_REQUIREMENTS_LINK,
} from 'utils/constants';

const PREFIX = 'ConnectorReqInfo';

const classes = {
  card: `${PREFIX}-card`,
  list: `${PREFIX}-list`,
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  infoButton: `${PREFIX}-infoButton`,
  closeButton: `${PREFIX}-closeButton`,
  flexRow: `${PREFIX}-flexRow`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.card}`]: {
    border: '1px solid #EDEDF2',
    borderRadius: '8px',
    width: '300px',
    filter:
      'drop-shadow(0px 8px 8px rgba(50, 50, 71, 0.08)) drop-shadow(0px 8px 16px rgba(50, 50, 71, 0.06))',
  },

  [`& .${classes.list}`]: {
    margin: '9px 0px 0px',
    paddingLeft: 'inherit',
    paddingRight: 'inherit',
    // List text
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.1px',
    color: '#555967',
  },

  [`& .${classes.container}`]: {padding: '16px'},

  [`& .${classes.title}`]: {
    fontFamily: 'Forma DJR UI, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '130%',
    color: '#353740',
  },
  [`& .${classes.closeButton}`]: {
    padding: '0px',
    marginLeft: 'auto',
  },

  [`& .${classes.flexRow}`]: {display: 'flex', alignItems: 'center'},
}));

const StyledIconButton = styled(IconButton)(() => ({
  [`&.${classes.infoButton}`]: {
    color: 'rgba(0, 0, 0, 0.56)',
    padding: '0px',
    margin: '0px 0px 4px 4px',
  },
}));

const popperModifiers = {
  offset: {
    enabled: true,
    offset: '0,10',
  },
};

function ConnectorReqInfo() {
  const anchorRef = useRef();
  const [open, setOpen] = useState(false);
  const handleClosePopper = () => setOpen(false);
  const togglePopper = () => setOpen(!open);

  return (
    <>
      <StyledIconButton
        ref={anchorRef}
        onClick={togglePopper}
        className={classes.infoButton}
        size="large"
      >
        <InfoIcon className={classes.icon} />
      </StyledIconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="right"
        transition
        modifiers={popperModifiers}
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <Root>
              <Paper className={classes.card}>
                <ClickAwayListener onClickAway={handleClosePopper}>
                  <div className={classes.container}>
                    <div className={classes.flexRow}>
                      <Typography className={classes.title}>
                        Linux machine requirements
                      </Typography>
                      <IconButton
                        onClick={handleClosePopper}
                        className={classes.closeButton}
                        size="large"
                      >
                        <CancelIcon />
                      </IconButton>
                    </div>
                    <ol className={classes.list}>
                      <li>
                        A Linux based machine (physical or virtual) - Rocky or
                        RHEL distributions are supported. &nbsp;
                        <GenericLink
                          url={AWC_SYSTEM_REQUIREMENTS_LINK}
                          underline="always"
                        >
                          Learn more about supported distributions.
                        </GenericLink>
                      </li>
                      <li>
                        The machine must be on a domain with communication
                        access to Anyware Manager.
                      </li>
                      <li>Administrator account access on the machine.</li>
                      <li>
                        Required ports are opened on the machine. &nbsp;
                        <GenericLink
                          url={AWC_NETWORK_REQUIREMENTS_LINK}
                          underline="always"
                        >
                          Learn more about port requirements
                        </GenericLink>
                      </li>
                    </ol>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Root>
          </Fade>
        )}
      </Popper>
    </>
  );
}

export default ConnectorReqInfo;
