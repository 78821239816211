import {TOGGLE_DEPLOYMENT_FILTER} from 'redux/actions/deploymentFilterActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';

const defaultState = {
  enabled: true,
};

function deploymentFilterReducer(state = defaultState, action) {
  switch (action.type) {
    case TOGGLE_DEPLOYMENT_FILTER:
      return {
        ...state,
        enabled: !state.enabled,
      };
    case CLEAR_STORE:
      return defaultState;
    default:
      return state;
  }
}

export default deploymentFilterReducer;
