import {useDispatch} from 'react-redux';
import {useIdleTimer} from 'react-idle-timer';

import CasmCookies from 'utils/cookies';
import {logoutUserFromCAM, showSessionExpired} from 'redux/actions/dataActions';

export default function useIdleMonitor() {
  const dispatch = useDispatch();

  const _1_MINUTE = 60 * 1000;
  const timeout = 30 * _1_MINUTE;

  const expireSession = () => {
    dispatch(logoutUserFromCAM());
    dispatch(showSessionExpired());
  };

  const handleOnAction = () => {
    if (!CasmCookies.isTokenValid()) {
      expireSession();
    }
  };

  const handleOnIdle = () => expireSession();

  useIdleTimer({
    timeout,
    capture: false,
    crossTab: true,
    onAction: handleOnAction,
    onIdle: handleOnIdle,
    throttle: 1000,
  });
}
