import CircularProgress from '@mui/material/CircularProgress';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {isEmpty} from 'utils/utils';

const PREFIX = 'AddLicense';

const classes = {
  textField: `${PREFIX}-textField`,
  addLicenseButton: `${PREFIX}-addLicenseButton`,
  itemButton: `${PREFIX}-itemButton`,
  licensePopup: `${PREFIX}-licensePopup`,
  disabled: `${PREFIX}-disabled`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.textField}`]: {
    margin: theme.spacing(1),
    width: 200,
    height: 56,
    padding: 3,
  },

  [`& .${classes.addLicenseButton}`]: {
    marginLeft: '10px',
    fontSize: '28px',
    '&:hover': {
      cursor: 'pointer',
      color: '#005788',
    },
  },

  [`& .${classes.itemButton}`]: {
    margin: '20px 12px',
    height: '36px',
    width: '96px',
  },

  [`& .${classes.licensePopup}`]: {
    paddingTop: 15,
    paddingBottom: 5,
  },

  [`& .${classes.disabled}`]: {pointerEvents: 'none'},
}));

function AddLicense({addCallback}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [regcode, setRegcode] = useState('');
  const [showNameInput, setShowNameInput] = useState(false);

  const handleCloseInput = () => {
    setAnchorEl(null);
    setShowNameInput(false);
    setRegcode('');
  };

  const handleCreate = async () => {
    setIsSaving(true);
    setShowNameInput(false);

    const svcAcc = await addCallback(regcode);

    if (!isEmpty(svcAcc)) {
      setRegcode('');
    }

    setIsSaving(false);
  };

  const nameInput = (
    <Popover
      anchorOrigin={{vertical: 'center', horizontal: 'right'}}
      transformOrigin={{vertical: 'center', horizontal: 'left'}}
      onClose={handleCloseInput}
      open={showNameInput}
      anchorEl={anchorEl}
    >
      <Paper className={classes.licensePopup}>
        <TextField
          data-testid="license-regcode"
          value={regcode}
          required
          inputProps={{'data-testid': 'license-regcode-input'}}
          label="Registration Code"
          variant="outlined"
          onChange={(event) => setRegcode(event.target.value)}
          InputProps={{className: classes.textField}}
        />
        <Button
          data-testid="add-new-license-button"
          className={classes.itemButton}
          variant="outlined"
          color="primary"
          onClick={handleCreate}
          disabled={regcode.length === 0}
        >
          Add
        </Button>
      </Paper>
    </Popover>
  );

  return (
    <Root>
      <AddCircleIcon
        data-testid="add-license-button"
        onClick={
          isSaving
            ? null
            : (event) => {
                setShowNameInput(true);
                setAnchorEl(event.currentTarget);
              }
        }
        size="small"
        color={isSaving ? 'disabled' : 'primary'}
        className={classnames(classes.addLicenseButton, {
          [classes.disabled]: isSaving,
        })}
      />
      {isSaving && (
        <CircularProgress data-testid="add-license-spinner" size={14} />
      )}
      {showNameInput && nameInput}
    </Root>
  );
}

AddLicense.propTypes = {
  addCallback: PropTypes.func.isRequired,
};

export default AddLicense;
