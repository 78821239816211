import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import MachineSelectLabel from 'components/CAM/display/MachineSelectLabel/MachineSelectLabel';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';
import CAMTextField from 'components/common/TextField';
import {IP_FQDN_TOOLTIP} from 'utils/constants';
import {hostNameErrorMessage, validateHostname} from 'utils/utils';

const PREFIX = 'PageHowToConnect';

const classes = {
  machinesContainer: `${PREFIX}-machinesContainer`,
  machineElement: `${PREFIX}-machineElement`,
  inputOutputGroups: `${PREFIX}-inputOutputGroups`
};

const StyledPaper = styled(Paper)(() => ({
  [`& .${classes.machinesContainer}`]: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  [`& .${classes.machineElement}`]: {
    minWidth: '250px',
    maxWidth: '600px',
    margin: '8px',
    boxShadow: '1px 1px 2px #888888',
  },

  [`&.${classes.inputOutputGroups}`]: {
    padding: '20px',
  }
}));

function PageHowToConnect({selectedInstances, setSelectedInstances, visible}) {


  const updateInstanceConnectionInfo = (newConnectionInfo, instance) => {
    const newInstances = selectedInstances.map((previousInstance) => {
      if (previousInstance.data.hash === instance.data.hash) {
        const updatedInstance = previousInstance;
        updatedInstance.data.connectionInfo = newConnectionInfo;
        updatedInstance.data.validConnectionInfo =
          validateHostname(newConnectionInfo);
        updatedInstance.data.connectionInfoErrorMessage =
          hostNameErrorMessage(newConnectionInfo);
        return updatedInstance;
      }
      return previousInstance;
    });
    setSelectedInstances(newInstances);
  };

  const renderSelectedInstances = () => {
    const children = selectedInstances.map((instance) => {
      const {data} = instance;
      const {
        originalConnectionInfo,
        connectionInfo,
        hash,
        validConnectionInfo,
        connectionInfoErrorMessage,
      } = data;

      let helperText = '';
      if (validConnectionInfo && originalConnectionInfo) {
        helperText = `default: ${originalConnectionInfo}`;
      }
      if (!validConnectionInfo && connectionInfoErrorMessage) {
        helperText = connectionInfoErrorMessage;
      }

      return (
        <Card className={classes.machineElement} key={`connect-card-${hash}`}>
          <CardContent>
            <MachineSelectLabel instance={data} />
            <br />

            <InputLabel
              tooltipText={IP_FQDN_TOOLTIP}
              displayText="LAN IP/FQDN"
            />

            <CAMTextField
              dataTestId={`edit-hostname-${hash}`}
              isError={!validConnectionInfo}
              isRequired
              fieldType="text"
              helperText={helperText}
              onChange={(event) =>
                updateInstanceConnectionInfo(event.target.value, instance)
              }
              placeholderText="Inform IP or FQDN"
              value={
                connectionInfo !== undefined
                  ? connectionInfo
                  : originalConnectionInfo
              }
            />
          </CardContent>
        </Card>
      );
    });
    return <div className={classes.machinesContainer}>{children}</div>;
  };

  if (!visible) {
    return null;
  }

  return (
    <StyledPaper className={classes.inputOutputGroups} variant="outlined">
      <Typography variant="h6">
        How to connect to each remote workstation?
      </Typography>
      <br />
      <p>
        Anyware Connector can connect to workstations by a Fully Qualified
        Domain Name (FQDN) or an IP Address.
        <br />
        It must to be able to resolve the name and reach the remote workstation
        via what has been specified.
      </p>
      <br />
      {renderSelectedInstances()}
    </StyledPaper>
  );
}

PageHowToConnect.propTypes = {
  selectedInstances: PropTypes.array.isRequired,
  setSelectedInstances: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default PageHowToConnect;
