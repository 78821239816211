import Chip from '@mui/material/Chip';
import {styled} from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import StopIcon from '@mui/icons-material/Stop';
import UpdateIcon from '@mui/icons-material/Update';
import PropTypes from 'prop-types';
import {
  AMT_POWERSTATE_DIAGN_INIT,
  AMT_POWERSTATE_DIAGN_NMI,
  AMT_POWERSTATE_HIBERNATE_OFF_SOFT,
  AMT_POWERSTATE_MASTERBUS_RESET,
  AMT_POWERSTATE_MASTERBUS_RESET_GRACEFUL,
  AMT_POWERSTATE_OFF_HARD,
  AMT_POWERSTATE_OFF_HARD_GRACEFUL,
  AMT_POWERSTATE_OFF_SOFT,
  AMT_POWERSTATE_OFF_SOFT_GRACEFUL,
  AMT_POWERSTATE_ON,
  AMT_POWERSTATE_OTHER,
  AMT_POWERSTATE_PC_OFF_HARD,
  AMT_POWERSTATE_PC_OFF_HARD_GRACEFUL,
  AMT_POWERSTATE_PC_OFF_SOFT,
  AMT_POWERSTATE_PC_OFF_SOFT_GRACEFUL,
  AMT_POWERSTATE_SLEEP_DEEP,
  AMT_POWERSTATE_SLEEP_LIGHT,
  AMT_POWERSTATE_UNKNOWN,
  AZURE_WORKSTATION_POWERSTATE_DEALLOCATED,
  AZURE_WORKSTATION_POWERSTATE_DEALLOCATING,
  WORKSTATION_POWERSTATE_RESTARTING,
  WORKSTATION_POWERSTATE_RUNNING,
  WORKSTATION_POWERSTATE_STARTING,
  WORKSTATION_POWERSTATE_STOPPED,
  WORKSTATION_POWERSTATE_STOPPING,
  WORKSTATION_POWERSTATE_UPDATING,
} from 'utils/constants';
import {mapAmtPowerStateToHumanReadable} from 'utils/Mappings';

const PREFIX = 'MachineStatus';

const classes = {
  root: `${PREFIX}-root`,
  outlined: `${PREFIX}-outlined`,
  icon: `${PREFIX}-icon`,
};

// DUMMY variables created to make the code compile and allow the work on fixing React18 styles
const customColor = 'white';
const customSize = 'small';
const variant = 'outlined';

const Root = styled(({chip: StyledChip, ...props}) => (
  <StyledChip {...props} />
))(() => ({
  [`& .${classes.root}`]: {
    marginLeft: '-10px',
    color: 'white',
    backgroundColor: `${customColor} !important`,
    '&:hover': {
      backgroundColor: customColor,
      filter: 'brightness(120%)',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: customColor,
      borderColor: customColor,
    },
  },
  [`& .${classes.outlined}`]: {
    color: '#000',
    border: `2px solid ${customColor}`,
    backgroundColor: 'transparent !important',
    width: customSize === 'small' ? '110px' : '130px',
    textAlign: 'left',
  },
  [`& .${classes.icon}`]: {
    color: variant === 'outlined' ? customColor : 'white',
  },
}));

const unknownStyleKey = 'unknown';
const unavailableStyleKey = 'unavailable';

const styleOptions = {
  [WORKSTATION_POWERSTATE_RUNNING]: {
    color: '#0D9401',
    icon: <PlayCircleFilledWhiteIcon />,
  },
  [WORKSTATION_POWERSTATE_STOPPED]: {
    color: 'black',
    icon: <StopIcon />,
  },
  [WORKSTATION_POWERSTATE_STARTING]: {
    color: '#0FB700',
    icon: <SlowMotionVideoIcon />,
  },
  [WORKSTATION_POWERSTATE_UPDATING]: {
    color: '#0052FF',
    icon: <UpdateIcon />,
  },
  [WORKSTATION_POWERSTATE_STOPPING]: {
    color: 'orange',
    icon: <StopIcon />,
  },
  [unknownStyleKey]: {
    color: 'grey',
    icon: <HelpOutlineIcon />,
  },
  [unavailableStyleKey]: {
    color: '#AAAAAA',
    icon: <HighlightOffIcon />,
    title: 'Power status can not be determined when machine is unmanaged.',
  },
};

const mapPowerStateToStyleKey = (powerState = '') => {
  let comparisonString = powerState;
  if (!powerState || typeof powerState !== 'string') {
    comparisonString = '';
  }
  comparisonString = comparisonString.toLowerCase();

  const runningCases = [
    WORKSTATION_POWERSTATE_RUNNING,
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_ON),
  ].map((string) => string.toLowerCase());

  const stoppedCases = [
    WORKSTATION_POWERSTATE_STOPPED,
    AZURE_WORKSTATION_POWERSTATE_DEALLOCATED,
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_SLEEP_LIGHT),
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_SLEEP_DEEP),
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_OFF_HARD),
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_HIBERNATE_OFF_SOFT),
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_OFF_SOFT),
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_OFF_SOFT_GRACEFUL),
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_OFF_HARD_GRACEFUL),
  ].map((string) => string.toLowerCase());

  const startingCases = [
    WORKSTATION_POWERSTATE_STARTING,
    WORKSTATION_POWERSTATE_RESTARTING,
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_PC_OFF_SOFT),
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_PC_OFF_HARD),
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_PC_OFF_SOFT_GRACEFUL),
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_PC_OFF_HARD_GRACEFUL),
    'Pending',
  ].map((string) => string.toLowerCase());

  const stoppingCases = [
    WORKSTATION_POWERSTATE_STOPPING,
    AZURE_WORKSTATION_POWERSTATE_DEALLOCATING,
  ].map((string) => string.toLowerCase());

  const unknownCases = [
    'Unknown',
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_UNKNOWN),
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_OTHER),
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_MASTERBUS_RESET),
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_DIAGN_NMI),
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_MASTERBUS_RESET_GRACEFUL),
    mapAmtPowerStateToHumanReadable(AMT_POWERSTATE_DIAGN_INIT),
  ].map((string) => string.toLowerCase());

  const unavailableCases = ['Unavailable', ''].map((string) =>
    string.toLowerCase()
  );

  if (runningCases.includes(comparisonString)) {
    return WORKSTATION_POWERSTATE_RUNNING;
  }

  if (stoppedCases.includes(comparisonString)) {
    return WORKSTATION_POWERSTATE_STOPPED;
  }

  if (startingCases.includes(comparisonString)) {
    return WORKSTATION_POWERSTATE_STARTING;
  }

  if (stoppingCases.includes(comparisonString)) {
    return WORKSTATION_POWERSTATE_STOPPING;
  }

  if (unknownCases.includes(comparisonString)) {
    return unknownStyleKey;
  }

  if (unavailableCases.includes(comparisonString)) {
    return unavailableStyleKey;
  }
  return unavailableStyleKey;
};

function MachineStatus({statusText, size}) {
  const style = styleOptions[mapPowerStateToStyleKey(statusText)];
  const {icon, color} = style;
  const title = style.title ? style.title : '';

  return (
    <Root
      chip={Chip}
      className={classes.root}
      label={statusText}
      size={size}
      icon={icon}
      style={{color}}
      title={title}
      variant={variant}
    />
  );
}

MachineStatus.propTypes = {
  statusText: PropTypes.string,
  size: PropTypes.string,
};

MachineStatus.defaultProps = {
  statusText: 'Unknown',
  size: 'small',
};

export default MachineStatus;
