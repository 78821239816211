import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Launch from '@mui/icons-material/Launch';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Tag from 'components/CAM/display/Tag/Tag';
import {RELEASE_NOTES_LINK} from 'utils/constants';

const PREFIX = 'ReleaseNoteItem';

const classes = {
  root: `${PREFIX}-root`,
  infoText: `${PREFIX}-infoText`,
  readLinkIcon: `${PREFIX}-readLinkIcon`,
  readLink: `${PREFIX}-readLink`
};

const Root = styled('div')(({
  theme: {
    palette: {
      primary: {main},
    },
  }
}) => ({
  [`&.${classes.root}`]: {
    marginBottom: '0.5em',
    marginTop: '1em',
  },

  [`& .${classes.infoText}`]: {
    fontSize: '0.7em',
    fontWeight: 400,
  },

  [`& .${classes.readLinkIcon}`]: {
    fontSize: '0.8em',
    color: '#757575',
    marginLeft: '1px',
  },

  [`& .${classes.readLink}`]: {
    fontSize: '1em',
    cursor: 'pointer',
    color: main,
  }
}));

export const getReleaseNoteTestId = (releaseType, date) => {
  const dateKey = date.replace(',', '').replace(' ', '-');
  return `${releaseType}-${dateKey}`;
};
function ReleaseNoteItem({releaseNote, onClick}) {
  const {date, releaseType} = releaseNote;

  const getReleaseTypeLabel = (type) =>
    type.split('release-notes-')[1].toUpperCase();

  const renderChips = () => (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Tag displayText="new" color="primary" small />
      </Grid>
      <Grid item xs={6}>
        <Tag
          displayText={getReleaseTypeLabel(releaseType)}
          color="secondary"
          small
        />
      </Grid>
    </Grid>
  );

  const handleReadClick = () => {
    onClick();
    window.sessionStorage.setItem('releaseNotesViewed', 'true');
  };

  return (
    <Root className={classes.root}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={6} data-testid={getReleaseNoteTestId(releaseType, date)}>
          {date}
        </Grid>
        <Grid item xs={3}>
          <Link
            to={`${RELEASE_NOTES_LINK}/${releaseType}`}
            onClick={handleReadClick}
          >
            <div
              className={classes.readLink}
              data-testid={`${getReleaseNoteTestId(releaseType, date)}-link`}
            >
              Read
              <Launch className={classes.readLinkIcon} />
            </div>
          </Link>
        </Grid>
        <Grid item xs={3}>
          {renderChips()}
        </Grid>
      </Grid>
    </Root>
  );
}

ReleaseNoteItem.propTypes = {
  releaseNote: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

ReleaseNoteItem.defaultProps = {
  onClick: () => {},
};

export default ReleaseNoteItem;
