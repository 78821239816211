import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Cancel';
import {useDispatch, useSelector} from 'react-redux';
import SuccessIcon from 'icons/SuccessIcon';
import {closeAzureRoleDialog} from 'redux/actions/azureRoleDialogActions';
import {
  azureSpMissingRoles,
  azureSpMissingRolesInstructions,
  AZURE_SP_ROLE_ASSIGNMENT_LINK,
} from 'utils/constants';

const PREFIX = 'AzureRoleDialog';

const classes = {
  disabled: `${PREFIX}-disabled`,
  svcAccSubtitle: `${PREFIX}-svcAccSubtitle`,
  svcPermissionsItem: `${PREFIX}-svcPermissionsItem`,
  instructions: `${PREFIX}-instructions`,
  centerText: `${PREFIX}-centerText`,
};

const StyledDialog = styled(Dialog)(({theme}) => ({
  [`& .${classes.disabled}`]: {color: theme.palette.error.main},

  [`& .${classes.svcAccSubtitle}`]: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '500',
    color: theme.palette.surface.grey,
  },

  [`& .${classes.svcPermissionsItem}`]: {
    color: 'rgba(0,0,0,0.6)',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
  },

  [`& .${classes.instructions}`]: {
    color: 'rgba(0,0,0,0.6)',
    fontWeight: '400',
    margin: '12px 0px',
  },

  [`& .${classes.centerText}`]: {textAlign: 'center'},
}));

function AzureRoleDialog() {
  const dispatch = useDispatch();

  let {open, roleReport, canManage} = useSelector(
    (state) => state.azureRoleDialog
  );

  open = open || false;
  roleReport = roleReport || {};
  canManage = canManage || false;

  const displayRoleReport = Object.keys(roleReport).map((role) => (
    <Grid container alignItems="center" key={role}>
      <Grid item xs={8}>
        <Typography
          className={classes.svcPermissionsItem}
          data-testid={`${role}-role-title`}
        >
          {role}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.centerText}>
        {roleReport[role] ? (
          <SuccessIcon />
        ) : (
          <ErrorIcon
            className={classes.disabled}
            data-testid={`${role}-not-satisfied`}
          />
        )}
      </Grid>
    </Grid>
  ));

  const dialogTitle = canManage
    ? 'Validated service principal'
    : azureSpMissingRoles;

  if (!open) return null;

  return (
    <StyledDialog
      open={open}
      onClose={() => dispatch(closeAzureRoleDialog())}
      data-testid="azure-dialog"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {dialogTitle}
        {!canManage && (
          <Typography className={classes.instructions}>
            {azureSpMissingRolesInstructions}
            <a
              href={AZURE_SP_ROLE_ASSIGNMENT_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              here.
            </a>
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={8}>
            <Typography className={classes.svcAccSubtitle}>
              Required Role Assignments
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" className={classes.svcAccSubtitle}>
              Assigned
            </Typography>
          </Grid>
        </Grid>
        {displayRoleReport}
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="azure-dialog-button-ok"
          onClick={() => dispatch(closeAzureRoleDialog())}
          color="primary"
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default AzureRoleDialog;
