import {Checkbox, FormControlLabel} from '@mui/material';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import useUpdateCacSettings from 'hooks/useUpdateCacSettings';
import {fetchConnectorSettings} from 'redux/actions/dataActions';

function SearchByUpnSettingForm({deploymentSettings}) {
  const dispatch = useDispatch();
  const {enableEntitlementsByUpn} = deploymentSettings;
  const [enabled, setEnabled] = useState(Boolean(enableEntitlementsByUpn));

  useEffect(() => {
    setEnabled(Boolean(enableEntitlementsByUpn));
  }, [enableEntitlementsByUpn]);

  const {mutate: updateDeploymentCacSettings} = useUpdateCacSettings('', {
    onSuccess: () => {
      dispatch(fetchConnectorSettings(''));
    },
  });

  const isOAuthEnabled = () => {
    if (deploymentSettings.oauth?.enabled === true) {
      return true;
    }
    return false;
  };

  const setEnableSearchByUpn = async (event) => {
    if (isOAuthEnabled()) {
      // this should not get triggered since the checkbox disabled when oauth is enabled.
      // But the test can click the checkbox somehow, so it might be possible to click the checkbox in some device.
      return;
    }
    const {checked} = event.target;
    setEnabled(checked);
    await updateDeploymentCacSettings({
      deployment: {enableEntitlementsByUpn: checked},
    });
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            disabled={isOAuthEnabled()}
            inputProps={{
              'data-testid': 'enable-search-by-upn-checkbox',
            }}
            color="primary"
            checked={enabled}
            onChange={setEnableSearchByUpn}
            name="search-by-upn-enabled"
          />
        }
        label="Enabled"
      />
      {isOAuthEnabled() && (
        <div>
          <strong>
            Entitlements by UPN is required for OAuth Authentication
          </strong>
        </div>
      )}
    </>
  );
}

SearchByUpnSettingForm.propTypes = {
  deploymentSettings: PropTypes.object.isRequired,
};

export default SearchByUpnSettingForm;
