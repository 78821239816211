import {TextField as MuiTextField} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {isEmpty} from 'utils/utils';

const PREFIX = 'TextField';

const classes = {
  inputProps: `${PREFIX}-inputProps`,
  inputSectionTextField: `${PREFIX}-inputSectionTextField`,
};

const StyledMuiTextField = styled(MuiTextField)(({theme}) => ({
  [`& .${classes.inputProps}`]: {
    backgroundColor: theme.palette.surface.white,
    height: '2.25rem',
    width: '100%',
  },

  [`&.${classes.inputSectionTextField}`]: {
    backgroundColor: 'inherit',
    width: '100%',
  },
}));

function TextField({
  value,
  helperText,
  placeholderText,
  fieldType,
  dataTestId,
  isRequired,
  isError,
  onChange,
  disabled,
  inputProps,
  fieldName,
  label,
  extraProps,
}) {
  let InputProps = {
    className: classes.inputProps,
  };

  if (!isEmpty(inputProps)) {
    InputProps = {
      ...InputProps,
      inputProps,
    };
  }

  const textFieldProps = {...extraProps};
  if (fieldName) {
    textFieldProps.name = fieldName;
  }

  return (
    <StyledMuiTextField
      variant="outlined"
      autoComplete="off"
      required={isRequired}
      data-testid={dataTestId}
      inputProps={{
        'data-testid': `${dataTestId}-input`,
      }}
      value={value}
      onChange={onChange}
      error={isError}
      helperText={helperText}
      placeholder={placeholderText}
      type={fieldType}
      className={classes.inputSectionTextField}
      disabled={disabled}
      InputProps={InputProps}
      label={label}
      {...textFieldProps}
    />
  );
}

TextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  helperText: PropTypes.string,
  placeholderText: PropTypes.string,
  fieldType: PropTypes.string,
  dataTestId: PropTypes.string,
  isRequired: PropTypes.bool,
  isError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  extraProps: PropTypes.object,
};

TextField.defaultProps = {
  helperText: '',
  placeholderText: '',
  fieldType: 'text',
  dataTestId: 'cam-text-field',
  isRequired: false,
  isError: false,
  disabled: false,
  inputProps: {},
  fieldName: '',
  label: '',
  extraProps: {},
};

export default TextField;
