import {
  OPEN_DEPLOYMENT_FILTER_DIALOG,
  CLOSE_DEPLOYMENT_FILTER_DIALOG,
} from 'redux/actions/deploymentFilterDialogActions';

const initialState = {
  open: false,
};

const deploymentFilterDialog = (state = initialState, action) => {
  const {type} = action;
  switch (type) {
    case OPEN_DEPLOYMENT_FILTER_DIALOG:
      return {
        open: true,
      };
    case CLOSE_DEPLOYMENT_FILTER_DIALOG:
      return {
        open: false,
      };
    default:
      return state;
  }
};

export default deploymentFilterDialog;
