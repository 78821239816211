import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useState} from 'react';
import linkIcon from 'icons/new_window.svg';

const PREFIX = 'WelcomeCardItemWarning';

const classes = {
  card: `${PREFIX}-card`,
  icon: `${PREFIX}-icon`,
  textContainer: `${PREFIX}-textContainer`,
  title: `${PREFIX}-title`,
  text: `${PREFIX}-text`,
  disabled: `${PREFIX}-disabled`,
  loading: `${PREFIX}-loading`,
  tooltip: `${PREFIX}-tooltip`,
  link: `${PREFIX}-link`,
  closeButton: `${PREFIX}-closeButton`
};

const StyledPaper = styled(Paper)(() => ({
  [`&.${classes.card}`]: {
    margin: '16px',
    display: 'flex',
    position: 'relative',
    height: '150px',
  },

  [`& .${classes.icon}`]: ({iconColor}) => ({
    width: '48px',
    height: '48px',
    color: iconColor || '#0076A9',
  }),

  [`& .${classes.textContainer}`]: {
    padding: '0 16px',
    borderLeft: '1px solid #DDDBDA',
    color: '#706E6B',
  },

  [`& .${classes.title}`]: {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 500,
    margin: 0,
    marginBottom: '5px',
    color: 'rgba(0,0,0,0.87)',
  },

  [`& .${classes.text}`]: {
    fontSize: '14px',
    lineHeight: '18px',
    margin: 0,
  },

  [`& .${classes.disabled}`]: {
    color: 'rgba(0,0,0,0.24)',
  },

  [`& .${classes.loading}`]: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },

  [`& .${classes.tooltip}`]: {
    fontSize: '14px',
  },

  [`& .${classes.link}`]: {
    position: 'absolute',
    width: '14px',
    height: '14px',
    opacity: '0.6',
    bottom: '0.5rem',
    right: '0.5rem',
  },

  [`& .${classes.closeButton}`]: {
    width: '28px',
    height: '28px',
    opacity: '0.6',
    margin: '15px',
    cursor: 'pointer',
  }
}));

function WelcomeCardItemWarning({
  title,
  children,
  Icon,
  iconColor,
  onClick,
  loading,
  isLink,
  isCloseable,
}) {
  const CLOSE_WARNING_LOCAL_STORAGE_KEY = 'dashboardWarningDismissed';


  const [hover, setHover] = useState(false);
  const [isClosed, setClosed] = useState(
    localStorage.getItem(CLOSE_WARNING_LOCAL_STORAGE_KEY) === 'true'
  );

  const closeWelcomeCard = () => {
    localStorage.setItem(CLOSE_WARNING_LOCAL_STORAGE_KEY, true);
    setClosed(true);
  };

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  if (isClosed) {
    return null;
  }

  return (
    <StyledPaper
      className={classes.card}
      elevation={hover ? 4 : 2}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Icon className={classNames(classes.icon)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.textContainer} item xs={10}>
          <p className={classes.title}>{title}</p>
          <p className={classes.text}>{children}</p>
        </Grid>
      </Grid>
      {isLink && <img className={classes.link} src={linkIcon} alt="link" />}
      {loading && (
        <LinearProgress
          className={classes.loading}
          value={50}
          color="primary"
        />
      )}
      {isCloseable && (
        <CloseSharpIcon
          className={classes.closeButton}
          src={CloseSharpIcon}
          onClick={closeWelcomeCard}
          alt="close"
        />
      )}
    </StyledPaper>
  );
}

WelcomeCardItemWarning.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  iconColor: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  isLink: PropTypes.bool,
  isCloseable: PropTypes.bool,
};

WelcomeCardItemWarning.defaultProps = {
  title: 'Title',
  Icon: null,
  iconColor: '',
  onClick: () => {},
  loading: false,
  isLink: false,
  isCloseable: false,
};

export default WelcomeCardItemWarning;
