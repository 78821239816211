import PropTypes from 'prop-types';
import DateRangeSelect from 'components/CAM/inputs/DateRangeSelect/DateRangeSelect';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import {
  EDIT_REMOTE_WORKSTATION_LINK,
  USER_SESSIONS,
  USER_SESSION_ATTEMPTS,
} from 'utils/constants';
import {isEmpty} from 'utils/utils';
import MiniTable from '../miniTable/MiniTable';
import config from 'config';

const activeSessionTableFields = [
  {id: 'machineName', value: 'Workstation name', type: 'link'},
  {id: 'pcoipSessionId', value: 'Session ID'},
  {id: 'clientSessionTime', value: 'Session Length', type: 'durationSeconds'},
  {id: 'sessionAttemptedOn', value: 'Start time', type: 'date'},
];

const pastSessionAttemptsTableFields = [
  {id: 'machineName', value: 'Machine', type: 'link'},
  {id: 'success', value: 'Status', type: 'success'},
  {id: 'sessionAttemptedOn', value: 'Attempt Time', type: 'date'},
  {id: 'pcoipSessionId', value: 'Session ID'},
];

function UserSessionCard({
  user,
  loadingSessions,
  loadingWorkstations,
  sessionStats,
  workstations,
  sessionAttempts,
  loadingSessionAttempts,
  setDateRange,
}) {
  const getWorkstationName = (machineId) => {
    if (loadingWorkstations) {
      return 'Loading...';
    }
    if (
      (isEmpty(sessionStats) || isEmpty(sessionAttempts)) &&
      isEmpty(workstations)
    ) {
      return 'Unknown';
    }
    const currentWorkstation = workstations.find(
      (ws) => ws.machineId === machineId
    );
    if (currentWorkstation) {
      return currentWorkstation.machineName || 'Unknown';
    }
    return 'Unknown';
  };

  const formatActiveSessionData = (session) => ({
    ...session,
    machineName: getWorkstationName(session.machineId),
    link: `${EDIT_REMOTE_WORKSTATION_LINK}/${session.machineId}`,
  });

  const formatSessionAttemptData = (attempt) => ({
    machineName: getWorkstationName(attempt.machineId),
    link: `${EDIT_REMOTE_WORKSTATION_LINK}/${attempt.machineId}`,
    sessionAttemptedOn: attempt.sessionAttemptedOn,
    pcoipSessionId: attempt.pcoipSessionId,
    success: attempt.clientSessionTime,
  });

  const prepareActiveSessionsData = (sessions = []) =>
    sessions.map(formatActiveSessionData);
  const prepareSessionAttemptData = (attempts = []) =>
    attempts.map(formatSessionAttemptData);

  const noActiveSessionsPrompt = `${user.name} has no active sessions`;
  const noSessionAttemptsPrompt = `${user.name} has no past session attempts`;

  const renderActiveSessionsTable = () => (
    <MiniTable
      resource={USER_SESSIONS}
      headers={activeSessionTableFields}
      data={prepareActiveSessionsData(sessionStats)}
      isLoading={loadingSessions || loadingWorkstations}
      total={sessionStats.length}
      noDataMessage={noActiveSessionsPrompt}
    />
  );

  const renderSessionAttemptsTable = () => (
    <MiniTable
      resource={USER_SESSION_ATTEMPTS}
      headers={pastSessionAttemptsTableFields}
      data={prepareSessionAttemptData(sessionAttempts)}
      isLoading={loadingSessionAttempts || loadingWorkstations}
      total={sessionAttempts.length}
      noDataMessage={noSessionAttemptsPrompt}
    />
  );

  const renderSectionHeader = (displayText) => (
    <SectionHeader displayText={displayText} />
  );

  return (
    <>
      <CAMCard>
        <GridN singleColumn>
          {renderSectionHeader('ACTIVE PCoIP SESSIONS')}
          {renderActiveSessionsTable()}
        </GridN>
      </CAMCard>
      {config.isBeta() && (
        <CAMCard>
          <GridN singleColumn>
            {renderSectionHeader('PCoIP SESSION ATTEMPTS')}
            <DateRangeSelect setDateRange={setDateRange} />
            {renderSessionAttemptsTable()}
          </GridN>
        </CAMCard>
      )}
    </>
  );
}

UserSessionCard.propTypes = {
  loadingSessions: PropTypes.bool,
  loadingWorkstations: PropTypes.bool,
  loadingSessionAttempts: PropTypes.bool,
  sessionStats: PropTypes.array,
  workstations: PropTypes.array,
  sessionAttempts: PropTypes.array,
  user: PropTypes.object,
  setDateRange: PropTypes.func.isRequired,
};

UserSessionCard.defaultProps = {
  loadingSessions: true,
  loadingWorkstations: true,
  loadingSessionAttempts: true,
  sessionStats: [],
  workstations: [],
  sessionAttempts: [],
  user: {},
};

export default UserSessionCard;
