import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ReactJson from 'react-json-view';
import {useDispatch, useSelector} from 'react-redux';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import CopyButton from 'components/CAM/buttons/CopyButton/CopyButton';
import {closeWorkstationDialogError} from 'redux/actions/remoteWorkstationErrorDialogActions';
import {enqueueSnackbar} from 'redux/actions/snackbarActions';
import {AZURE, GCP, AMT} from 'utils/constants';
import DetailedErrorCardItem from 'components/detailedErrorCard/DetailedErrorCardItem';
import {isEmpty} from 'utils/utils';
import {mapProvidersToEnum} from 'utils/Mappings';
import {
  mapErrorTypeToErrorName,
  sortErrors,
} from 'components/remoteWorkstations/utils';

const PREFIX = 'RemoteWorkstationErrorDialog';

const classes = {
  title: `${PREFIX}-title`,
  message: `${PREFIX}-message`,
  button: `${PREFIX}-button`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.title}`]: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '20px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: '24px',
  },

  [`& .${classes.message}`]: {
    color: 'rgba(0,0,0,0.6)',
    letterSpacing: '0.5px',
    lineHeight: '22px',
  },

  [`& .${classes.button}`]: {
    width: '80px',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
  },
}));

const MAX_STRING_LENGTH_BEFORE_COLLAPSING = 2048;

export const getWorkstationErrorSnippet = (workstation) => {
  // Only display the most critical error
  const criticalError = workstation?.detailedError.sort(sortErrors)[0];
  let snippet = mapErrorTypeToErrorName(criticalError.errorType);

  switch (workstation?.provider?.toLowerCase()) {
    case GCP:
      // Nothing specific for GCP, only display error message.
      break;
    case AZURE:
      if (
        workstation?.provisioned &&
        workstation?.provisioningStatus?.state !== 'succeeded'
      ) {
        const deploymentError =
          workstation?.provisioningStatus?.deployment?.properties?.error;
        if (deploymentError?.code) {
          snippet = `${deploymentError?.code}`;
        }

        const deploymentDetails =
          deploymentError?.details &&
          deploymentError.details.length > 0 &&
          deploymentError.details;
        if (
          !isEmpty(deploymentDetails) &&
          !isEmpty(deploymentDetails[0].code)
        ) {
          snippet = `${snippet} - ${deploymentDetails[0].code}`;
        }
      }
      break;
    default:
      return snippet;
  }
  return snippet;
};

function RemoteWorkstationErrorDialog() {
  const dispatch = useDispatch();

  const {open, workstation} = useSelector(
    (state) => state.remoteWorkstationErrorDialog
  );

  const onCopy = () => {
    dispatch(
      enqueueSnackbar({
        message: 'JSON copied to clipboard.',
        options: {
          variant: 'success',
        },
      })
    );
  };

  const copyButton = (textToCopy) =>
    CopyButton({
      textToCopy,
      title: 'Copy JSON to clipboard',
      onCopy,
    });

  const renderErrorBox = (error) => {
    return <DetailedErrorCardItem errorObject={error} />;
  };

  const remoteWorkstationErrorMessage = () => {
    let errorMessage = [];
    const messages = [];

    workstation?.detailedError?.forEach((error) => {
      messages.push(error.message);
    });
    if (isEmpty(messages)) {
      return errorMessage;
    }

    errorMessage = <p>{messages}</p>;

    if (mapProvidersToEnum(workstation?.provider?.toLowerCase()) === AMT) {
      errorMessage = [];
      workstation?.detailedError?.forEach((error) => {
        errorMessage.push(<>{renderErrorBox(error)}</>);
      });
    }

    if (workstation?.provider?.toLowerCase() === GCP) {
      if (workstation?.provisioningStatus?.state === 'failed') {
        const {provisioningStatus} = workstation;
        if (provisioningStatus?.deployment) {
          const {deployment} = provisioningStatus;
          if (deployment.status === 'DONE') {
            errorMessage = [
              <p>
                The deployment of the remote workstation{' '}
                {workstation.machineName} failed. <br />
                <br />
                <strong>{workstation.provisioningStatus.message}</strong>
              </p>,
              'A deployment was created on GCP but the remote workstation provisioning failed on a subsequent step. You can later delete this remote workstation from Anyware Manager and from GCP and try again.',
            ];
          } else {
            errorMessage = [
              'The remote workstation deployment failed. You can later delete this remote workstation from Anyware Manager and try again.',
            ];
          }

          // Add the provisioning object to the message
          errorMessage.push(
            <p>
              <strong>More details from GCP:</strong>
              {copyButton(
                JSON.stringify(
                  workstation.provisioningStatus.deployment,
                  null,
                  1
                )
              )}
              <br />
              <br />

              <ReactJson
                src={workstation.provisioningStatus.deployment}
                name={false}
                displayObjectSize={false}
                displayDataTypes={false}
                displayArrayKey={false}
                enableClipboard={false}
                collapseStringsAfterLength={MAX_STRING_LENGTH_BEFORE_COLLAPSING}
              />
              <br />
              <p>
                You can find more details about the GCP deployment above on the
                activity log of your GCP project{' '}
                <strong>{workstation.projectId}</strong>.
              </p>
            </p>
          );
        }
      }
    }

    let azureDeployment;
    if (workstation?.provider?.toLowerCase() === AZURE) {
      if (workstation?.provisioningStatus?.state === 'failed') {
        const {provisioningStatus} = workstation;
        azureDeployment = provisioningStatus?.deployment;

        // this is the Azure deployment without the error information as it was already displayed before.
        const deploymentInfo = {...workstation.provisioningStatus.deployment};
        delete deploymentInfo.properties;

        if (azureDeployment) {
          errorMessage = [
            <p>
              The provisioning of machine {workstation.machineName} failed.
              <br />
              {messages}{' '}
            </p>,
            'A deployment was created on Azure but it failed on a subsequent step. You can later delete the remote workstation from Anyware Manager and from Azure and try again.',
          ];

          // Add the provisioning object to the message
          if (
            !isEmpty(
              workstation?.provisioningStatus?.deployment?.properties?.error
            )
          ) {
            errorMessage.push(
              <p>
                Error details from Azure:{' '}
                {copyButton(
                  JSON.stringify(
                    workstation?.provisioningStatus?.deployment?.properties
                      ?.error,
                    null,
                    1
                  )
                )}
                <br />
                <ReactJson
                  src={
                    workstation?.provisioningStatus?.deployment?.properties
                      ?.error
                  }
                  name={false}
                  displayObjectSize={false}
                  displayDataTypes={false}
                  displayArrayKey={false}
                  enableClipboard={false}
                  collapseStringsAfterLength={
                    MAX_STRING_LENGTH_BEFORE_COLLAPSING
                  }
                />
              </p>
            );
          }
          if (!isEmpty(deploymentInfo)) {
            errorMessage.push(
              <>
                <br />
                <p>
                  Expand below for more details on the Azure deployment:
                  {copyButton(JSON.stringify(deploymentInfo, null, 1))}
                </p>
                <p>
                  <ReactJson
                    src={deploymentInfo}
                    name={false}
                    displayObjectSize={false}
                    displayDataTypes={false}
                    displayArrayKey={false}
                    enableClipboard={false}
                    collapsed
                  />
                </p>
              </>
            );
          }
        }
      }
    }
    return errorMessage;
  };

  const handleClose = () => {
    dispatch(closeWorkstationDialogError());
  };

  const title = `Error details on ${workstation.machineName}`;

  if (!open) return null;

  return (
    <StyledDialog
      open={open}
      data-testid="remote-workstation-error-dialog"
      maxWidth="lg"
      onClose={handleClose}
    >
      <DialogTitle>
        <Typography className={classes.title}>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.message} gutterBottom>
          {remoteWorkstationErrorMessage()}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CAMButton
          className={classes.button}
          onClick={handleClose}
          variant="contained"
          color="primary"
        >
          Ok
        </CAMButton>
      </DialogActions>
    </StyledDialog>
  );
}

export default RemoteWorkstationErrorDialog;
