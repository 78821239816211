import {styled} from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {saveVariable} from 'redux/actions/dataActions';
import {
  UNSAVED_DATA_DIALOG_MESSAGE,
  UNSAVED_DATA_DIALOG_TITLE,
} from 'utils/constants';
import {selectVariable} from 'utils/reduxSelectors';
import SamlGroupsConfiguration from './samlGroupsConfiguration';
import SamlIdpSettings from './SamlIdpSettings';
import SamlSettingsConfiguration from './samlSettingsConfiguration';
import SamlUsersConfiguration from './samlUsersConfiguration';

const PREFIX = 'SamlConfigTab';

const classes = {
  buttonGroup: `${PREFIX}-buttonGroup`,
  toggleButton: `${PREFIX}-toggleButton`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.buttonGroup}`]: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent',
    padding: '2px',
    display: 'flex',
    width: '100%',
  },

  [`& .${classes.toggleButton}`]: {
    textTransform: 'none',
    paddingLeft: '15px',
    paddingRight: '15px',
    fontWeight: 'inherit',
  },
}));

export default function SamlConfigTab({configurationId}) {
  const dispatch = useDispatch();
  const [selectedButtonGroup, setSelectedButtonGroup] = useState(0);

  const pendingChanges = useSelector((state) =>
    selectVariable(state, 'pendingChanges')
  );

  const conditionalChangeButtonGroup = (newSelection) => {
    // prevents user from "de-selecting" the tabs, causing nothing to render
    if (newSelection === null) {
      return;
    }

    if (pendingChanges) {
      dispatch(
        openDialog(
          UNSAVED_DATA_DIALOG_TITLE,
          UNSAVED_DATA_DIALOG_MESSAGE,
          async () => {
            await dispatch(saveVariable('pendingChanges', false));
            setSelectedButtonGroup(newSelection);
          },
          () => {}
        )
      );
    } else {
      setSelectedButtonGroup(newSelection);
    }
  };

  const buttonsDisabled = () => !configurationId;

  const renderButtonGroupContent = (index) => {
    // only first tab is displayed if no saml configuration exist
    if (!configurationId) {
      return <SamlSettingsConfiguration configurationId={configurationId} />;
    }

    switch (index) {
      case 0:
        return <SamlSettingsConfiguration configurationId={configurationId} />;
      case 1:
        return <SamlIdpSettings configurationId={configurationId} />;
      case 2:
        return <SamlUsersConfiguration configurationId={configurationId} />;
      case 3:
        return <SamlGroupsConfiguration configurationId={configurationId} />;
      default:
        return null;
    }
  };

  return (
    <Root>
      <ToggleButtonGroup
        exclusive
        color="primary"
        className={classes.buttonGroup}
        value={selectedButtonGroup}
        onChange={(event, selection) => conditionalChangeButtonGroup(selection)}
      >
        <ToggleButton
          className={classes.toggleButton}
          data-testid="saml-config-button"
          color="primary"
          variant="outlined"
          size="small"
          value={0}
        >
          Configuration info
        </ToggleButton>

        <ToggleButton
          className={classes.toggleButton}
          data-testid="saml-idp-settings-button"
          color="primary"
          variant="outlined"
          size="small"
          value={1}
          disabled={buttonsDisabled()}
        >
          IDP settings
        </ToggleButton>

        <ToggleButton
          className={classes.toggleButton}
          data-testid="saml-users-button"
          color="primary"
          variant="outlined"
          size="small"
          value={2}
          disabled={buttonsDisabled()}
        >
          Allowed admins
        </ToggleButton>

        <ToggleButton
          className={classes.toggleButton}
          data-testid="saml-groups-button"
          color="primary"
          variant="outlined"
          size="small"
          value={3}
          disabled={buttonsDisabled()}
        >
          Allowed groups
        </ToggleButton>
      </ToggleButtonGroup>

      {renderButtonGroupContent(selectedButtonGroup)}
    </Root>
  );
}

SamlConfigTab.propTypes = {
  configurationId: PropTypes.string,
};

SamlConfigTab.defaultProps = {
  configurationId: null,
};
