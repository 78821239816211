import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Grid from '@mui/material/Grid';
import SuccessIcon from '@mui/icons-material/CheckCircleOutlined';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {Typography} from '@mui/material';

const PREFIX = 'Alert';

const classes = {
  alert: `${PREFIX}-alert`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  gridColumn: `${PREFIX}-gridColumn`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`&.${classes.alert}`]: {
    borderRadius: '4px',
    overflow: 'hidden',
    padding: `${theme.spacing(0.75)} ${theme.spacing(1)}`,
    position: 'relative',
    width: '1086px',
    backgroundColor: '#fdeded',
  },

  [`& .${classes.title}`]: {
    color: '#5f2120',
    fontSize: '16px',
    fontWeight: '500',
  },

  [`& .${classes.subtitle}`]: {
    color: '#5F2120',
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.gridColumn}`]: {
    padding: `${theme.spacing(0.75)} ${theme.spacing(1)}`,
  },
}));

const TYPE_WARNING = 'warning';
const TYPE_INFO = 'info';
const TYPE_SUCCESS = 'success';
const TYPE_ERROR = 'error';
const alertTypes = [TYPE_WARNING, TYPE_INFO, TYPE_SUCCESS, TYPE_ERROR];

function Alert({title = '{Title}', children, subtitle = '', alertType}) {
  const renderIcon = () => {
    switch (alertType) {
      case TYPE_WARNING:
        return (
          <WarningIcon
            style={{
              color: '#ED6C02',
              height: '22px',
              width: '22px',
            }}
          />
        );

      case TYPE_ERROR:
        return (
          <ErrorIcon
            style={{
              color: '#D32F2F',
              height: '22px',
              minWidth: '22px',
            }}
          />
        );
      case TYPE_SUCCESS:
        return (
          <SuccessIcon
            style={{
              color: '#2E7D32',
              height: '22px',
              minWidth: '22px',
            }}
          />
        );
      case TYPE_INFO:
      default:
        return (
          <InfoIcon
            style={{
              color: '#0288D1',
              height: '22px',
              minWidth: '22px',
            }}
          />
        );
    }
  };

  return (
    // TODO: make the background colors dependent on the alert type. Right now it is always red for error
    <Root className={classes.alert}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        wrap="nowrap"
      >
        <Grid item className={classes.gridColumn}>
          <div>{renderIcon()}</div>
        </Grid>
        <Grid item className={classes.gridColumn}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Typography className={classes.title} variant="subtitle1">
                {title}
              </Typography>
              <Typography className={classes.subtitle} variant="body2">
                {subtitle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className="text">
                <div>{children}</div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
}

Alert.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  alertType: PropTypes.oneOf(alertTypes),
};

Alert.defaultProps = {
  alertType: TYPE_INFO,
  subtitle: '',
  children: <Root />,
};

export default Alert;
