import {useMutation} from 'react-query';
import {put} from 'api/Api';
import {mapResourceToAPI} from 'redux/actions/dataActions';
import {SAML_CONFIGURATION} from 'utils/constants';

const updateSamlConfig = async (configurationId, config) => {
  const path = `${mapResourceToAPI(SAML_CONFIGURATION)}/${configurationId}`;
  await put({path, data: config});
};

const useUpdateSamlConfig = (configurationId, options = {}) =>
  useMutation((config) => updateSamlConfig(configurationId, config), options);

export default useUpdateSamlConfig;
