import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import moment from 'moment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TableContainer from '@mui/material/TableContainer';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import useRoles from 'hooks/useRoles';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import useCreateDeploymentSA from 'hooks/useCreateDeploymentSA';
import useSnackbar from 'hooks/useSnackbar';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import {updateDeploymentSACred} from 'redux/actions/deploymentSACredActions';
import {handleApiError} from 'redux/actions/dataActions';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {DEPLOYMENT_SERVICE_ACCOUNTS_LINK} from 'utils/constants';
import PermissionTable from './PermissionTable';

const PREFIX = 'AddDeploymentSAPage';

const classes = {
  mainContainer: `${PREFIX}-mainContainer`,
  pageTitle: `${PREFIX}-pageTitle`,
  sectionHeader: `${PREFIX}-sectionHeader`,
  formControl: `${PREFIX}-formControl`,
  link: `${PREFIX}-link`,
  spacing: `${PREFIX}-spacing`,
  currentBreadcrumb: `${PREFIX}-currentBreadcrumb`,
  bodyDiv: `${PREFIX}-bodyDiv`,
  body: `${PREFIX}-body`,
  helperList: `${PREFIX}-helperList`,
  actionButtonDiv: `${PREFIX}-actionButtonDiv`,
  buttonMargin: `${PREFIX}-buttonMargin`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.mainContainer}`]: {
    padding: '0px 40px 40px 40px',
  },

  [`& .${classes.pageTitle}`]: {
    ...stylesFromFigma.typographyH4,
    marginTop: '8px',
  },

  [`& .${classes.sectionHeader}`]: {
    ...stylesFromFigma.typographyH6,
    marginTop: '16px',
  },

  [`& .${classes.formControl}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },

  [`& .${classes.link}`]: {
    color: 'inherit',
  },

  [`&.${classes.spacing}`]: {
    margin: theme.spacing(4),
    maxWidth: '1136px',
  },

  [`& .${classes.currentBreadcrumb}`]: {
    color: 'rgba(0, 0, 0, 0.87)',
  },

  [`& .${classes.bodyDiv}`]: {
    //  paddingTop: '40px',
    // paddingLeft: '76px',
  },

  [`& .${classes.body}`]: {
    fontSize: '0.875rem',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },

  [`& .${classes.helperList}`]: {
    padding: 'inherit',
    paddingTop: '0px',
    paddingBottom: '0px',
    listStylePosition: 'inside',
  },

  [`& .${classes.actionButtonDiv}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '24px',
  },

  [`& .${classes.buttonMargin}`]: {margin: '0px 12px'},
}));

const nameMaxLength = 64;
// this need to be in format 'x unit' and compatible with moment js add function
// See https://momentjs.com/docs/#/manipulating/add/
const expiresOptions = {
  threeMonth: '3 M',
  sixMonth: '6 M',
  oneYear: '1 y',
  twoYear: '2 y',
  threeYear: '3 y',
};
function AddDeploymentSAPage() {
  const dispatch = useDispatch();
  useSnackbar();
  const history = useHistory();
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );

  const {data: roles} = useRoles();
  const {mutate: createDeploymentSA, isLoading} = useCreateDeploymentSA();

  const [accountName, setAccountName] = useState('');
  const [accountNameError, setAccountNameError] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState('');

  const [selectedExpiresIn, setSelectedExpiresIn] = useState(
    expiresOptions.oneYear
  );

  const handleChangeRole = (event) => {
    setSelectedRoleId(event.target.value);
  };

  useEffect(() => {
    if (accountName?.length > nameMaxLength) {
      setAccountNameError(`Maximum characters is ${nameMaxLength}`);
      return;
    }
    setAccountNameError('');
  }, [accountName]);

  let validPayload = false;
  if (accountName && !accountNameError && selectedRoleId) {
    validPayload = true;
  }

  const deploymentSAPageUrl = DEPLOYMENT_SERVICE_ACCOUNTS_LINK;

  function getRoles() {
    if (roles) {
      // Hide Anyware Monitor role since it requires machine id
      // Hide Anyware Monitor Bulk Installer role since it is managed by Bulk Monitor Provisioning
      return roles.filter(
        (role) =>
          role.roleName !== 'Anyware Monitor' &&
          role.roleName !== 'Anyware Monitor Bulk Installer'
      );
    }
    return [];
  }

  const _roles = getRoles();
  const selectedRole = _roles.find((role) => role.roleId === selectedRoleId);

  const roleSection = (
    <>
      <Typography className={classes.sectionHeader} variant="h6" component="h6">
        Role and Permissions
      </Typography>
      <Typography component="span" variant="body1" className={classes.body}>
        <ul className={classes.helperList}>
          <li>
            Please select a role to give the service account a set of predefined
            permissions.
          </li>
          <li>
            The role cannot be changed after the deployment service account is
            created.
          </li>
        </ul>
      </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="role-label">Role</InputLabel>
        <Select
          disabled={isLoading}
          labelId="role-label"
          id="input-select-role"
          value={selectedRoleId}
          onChange={handleChangeRole}
          label="Role"
        >
          {_roles.map((role) => (
            <MenuItem key={role.roleId} value={role.roleId}>
              {role.roleName}
            </MenuItem>
          ))}
        </Select>
        {selectedRole && (
          <Box mt={0.5} data-test-id="div-role-description">
            <Typography
              component="span"
              variant="body1"
              className={classes.body}
            >
              {selectedRole.description}
            </Typography>
          </Box>
        )}
      </FormControl>
      {selectedRole && (
        <TableContainer>
          <PermissionTable permissions={selectedRole.permissions} />
        </TableContainer>
      )}
    </>
  );

  const expireSection = (
    <>
      <Typography className={classes.sectionHeader} variant="h6" component="h6">
        Expires
      </Typography>
      <Typography component="span" variant="body1" className={classes.body}>
        <ul className={classes.helperList}>
          <li>
            Please select the time period after which the service account will
            expire
          </li>
        </ul>
      </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="expiry-date-label">Expires</InputLabel>
        <Select
          disabled={isLoading}
          labelId="expiry-date-label"
          id="input-expiry-date"
          value={selectedExpiresIn}
          onChange={(event) => {
            setSelectedExpiresIn(event.target.value);
          }}
          label="Expiry"
        >
          <MenuItem value={expiresOptions.threeMonth}>3 months</MenuItem>
          <MenuItem value={expiresOptions.sixMonth}>6 months</MenuItem>
          <MenuItem value={expiresOptions.oneYear}>
            1 year (Recommended)
          </MenuItem>
          <MenuItem value={expiresOptions.twoYear}>2 years</MenuItem>
          <MenuItem value={expiresOptions.threeYear}>3 years</MenuItem>
        </Select>
      </FormControl>
    </>
  );

  return (
    <Root className={classes.spacing}>
      <Breadcrumbs separator=">">
        <Link
          underline="hover"
          href={deploymentSAPageUrl}
          className={classes.link}
          onClick={(event) => {
            event.preventDefault();
            history.push(deploymentSAPageUrl);
          }}
        >
          Deployment Service Account
        </Link>
        <Typography className={classes.currentBreadcrumb}>
          Create Deployment Service Account
        </Typography>
      </Breadcrumbs>

      <Typography className={classes.pageTitle} variant="h4" component="h1">
        Create Deployment Service Account
      </Typography>

      <div className={classes.mainContainer}>
        <Typography
          className={classes.sectionHeader}
          variant="h6"
          component="h6"
        >
          Deployment Service Account Name
        </Typography>
        <Typography component="span" variant="body1" className={classes.body}>
          <ul className={classes.helperList}>
            <li>
              Enter a unique name for the service account that will help you
              identify it in the future.
            </li>
            <li>{`Maximum ${nameMaxLength} characters`}</li>
          </ul>
        </Typography>
        <TextField
          disabled={isLoading}
          error={!!accountNameError}
          helperText={accountNameError}
          id="sa-name-input"
          label="Deployment Service Account Name"
          data-testid="sa-name-input"
          variant="outlined"
          className={classes.formControl}
          onChange={(event) => {
            setAccountName(event.target.value);
          }}
        />
        {expireSection}
        {roleSection}

        <div className={classes.actionButtonDiv}>
          <SaveButton
            onClick={async () => {
              const [expiresOnValue, expiresOnUnit] =
                selectedExpiresIn.split(' ');
              const expiresOn = moment()
                .add(expiresOnValue, expiresOnUnit)
                .toISOString();
              createDeploymentSA(
                {
                  deploymentId,
                  keyName: accountName,
                  roleId: selectedRoleId,
                  expiresOn,
                },
                {
                  onSuccess: (credential) => {
                    dispatch(updateDeploymentSACred(credential));
                    history.push({
                      pathname: deploymentSAPageUrl,
                    });
                  },
                  onError: (e) => {
                    dispatch(handleApiError(e));
                  },
                }
              );
            }}
            buttonText="Create"
            disabled={!validPayload}
            saving={isLoading}
            dataTestId="create-deployment-sa-button"
            customClass={classes.buttonMargin}
          />
          <CAMButton
            onClick={() => {
              history.push(deploymentSAPageUrl);
            }}
            variant="text"
            buttonText="Cancel"
            testId="dialog-cancel-button"
            disabled={isLoading}
            customClass={classes.buttonMargin}
          />
        </div>
      </div>
    </Root>
  );
}

export default AddDeploymentSAPage;
