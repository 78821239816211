import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import BackwardIcon from '@mui/icons-material/ChevronLeft';
import Skeleton from '@mui/material/Skeleton';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import StatusChip from 'components/CAM/display/StatusChip/StatusChip';
import {ReactComponent as CertificateIcon} from 'icons/cert-badge.svg';
import {isEmpty, isCertAboutToExpire, isCertExpired} from 'utils/utils';

const PREFIX = 'BetaCertificatesSection';

const classes = {
  card: `${PREFIX}-card`,
  header: `${PREFIX}-header`,
  title: `${PREFIX}-title`,
  certificateSubtitle: `${PREFIX}-certificateSubtitle`,
  certificateName: `${PREFIX}-certificateName`,
  leftColumn: `${PREFIX}-leftColumn`,
  centerColumn: `${PREFIX}-centerColumn`,
  rightColumn: `${PREFIX}-rightColumn`,
  row: `${PREFIX}-row`,
  certificateDetails: `${PREFIX}-certificateDetails`,
  certificateDetailsBody: `${PREFIX}-certificateDetailsBody`,
};

const StyledCard = styled(Card)(() => ({
  [`&.${classes.card}`]: {
    marginTop: '24px',
    width: '40%',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderWidth: '1px',
    borderRadius: '8px',
  },

  [`& .${classes.header}`]: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderWidth: '0px 0px 1px 0px',
    padding: '20px',
  },

  [`& .${classes.title}`]: {
    ...stylesFromFigma.typographyH6,
    color: '#23242A',
  },

  [`& .${classes.certificateSubtitle}`]: {
    ...stylesFromFigma.typographyBody2,
    color: '#8E93A4',
  },

  [`& .${classes.certificateName}`]: {
    color: '#23242A',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '130%',
  },

  [`& .${classes.leftColumn}`]: {
    justifyContent: 'flex-start',
    display: 'flex',
  },

  [`& .${classes.centerColumn}`]: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  [`& .${classes.rightColumn}`]: {
    justifyContent: 'flex-end',
    display: 'flex',
  },

  [`& .${classes.row}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
  },

  [`& .${classes.certificateDetails}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },

  [`& .${classes.certificateDetailsBody}`]: {
    ...stylesFromFigma.typographyBody2,
    color: '#555967',
  },
}));

function BetaCertificatesSection({certificates}) {
  const selectedConnector = useSelector(
    (state) => state?.data?.dataByResource?.selectedConnector
  );

  const [selectedCertificate, setSelectedCertificate] = useState(null);

  const isLoading = isEmpty(selectedConnector);

  /*
   * getCertChipType
   * Returns the type of chip to display for the certificate
   * */
  const getCertChipType = (expiry) => {
    if (!expiry) return 'info';

    if (isCertExpired(expiry)) {
      return 'error';
    }
    if (isCertAboutToExpire(expiry)) {
      return 'warning';
    }
    return 'ok';
  };

  /*
   * getCertExpiryStatus
   * Returns the status of the certificate
   * */
  const getCertExpiryStatus = (expiry) => {
    if (!expiry) return 'Unknown';

    if (isCertExpired(expiry)) {
      return 'Expired';
    }
    if (isCertAboutToExpire(expiry)) {
      return 'Expiring Soon';
    }
    return 'Valid';
  };

  function Title() {
    return <Typography className={classes.title}>Certificates</Typography>;
  }
  function Subtitle() {
    return (
      <Typography className={classes.certificateSubtitle}>
        Connector Certificates
      </Typography>
    );
  }

  /*
   * CertificateRow
   * Displays a single row in the certificate table
   * */
  function CertificateRow({certificate}) {
    return (
      <Grid container spacing={3} direction="row" className={classes.row}>
        <Grid
          container
          item
          xs={4}
          className={classes.leftColumn}
          direction="column"
        >
          <Typography className={classes.certificateName}>
            {certificate.name}
          </Typography>
          <Typography className={classes.certificateSubtitle}>
            {certificate.host}
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.centerColumn}>
          <StatusChip
            label={getCertExpiryStatus(certificate.expiry)}
            type={getCertChipType(certificate.expiry)}
          />
        </Grid>
        <Grid item xs={4} className={classes.rightColumn}>
          {certificate.expiry && (
            <Typography className={classes.certificateDetailsBody}>
              Valid until {certificate.expiry}
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  }

  /*
   * CertificateDetails
   * Displays the details of a single certificate
   * */
  function CertificateDetails({certificate}) {
    // Include a button to clear the selected certificate
    return (
      <Grid container>
        <Grid item xs={2} spacing={4} style={{padding: '8px'}}>
          <IconButton onClick={() => setSelectedCertificate(null)} size="large">
            <BackwardIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} className={classes.certificateDetails}>
          <Typography className={classes.certificateName}>
            {certificate.name}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  /*
   * CertificateTable
   * Displays the table of certificates
   * */
  const CertificateTable = () => {
    return certificates.map((certificate, index) => (
      <Fragment key={index}>
        <CertificateRow certificate={certificate} />
        {index < certificates.length - 1 && (
          <Divider className={classes.divider} />
        )}
      </Fragment>
    ));
  };

  return (
    <StyledCard className={classes.card} elevation="0">
      <CardHeader
        className={classes.header}
        title={<Title />}
        subheader={<Subtitle />}
        avatar={<CertificateIcon />}
      />
      {isLoading && <Skeleton />}
      {!isLoading && certificates.length === 0 && (
        <CardContent>
          <Typography align="center">No certificates found</Typography>
        </CardContent>
      )}
      {selectedCertificate ? (
        <CertificateDetails certificate={selectedCertificate} />
      ) : (
        <CertificateTable />
      )}
    </StyledCard>
  );
}

BetaCertificatesSection.propTypes = {
  certificates: PropTypes.array,
};

BetaCertificatesSection.defaultProps = {
  certificates: [],
};

export default BetaCertificatesSection;
