import {CLEAR_STORE} from 'redux/actions/globalActions';
import {
  ERROR_RESTART_MACHINE,
  ERROR_START_MACHINE,
  ERROR_STOP_MACHINE,
  REQUEST_RESTART_MACHINE,
  REQUEST_START_MACHINE,
  REQUEST_STOP_MACHINE,
  RESOLVE_RESTART_MACHINE,
  RESOLVE_START_MACHINE,
  RESOLVE_STOP_MACHINE,
} from 'redux/actions/remoteWorkstationActions';

const defaultState = {};

function stateByWorkstation(
  state = {
    powerStateChanging: false,
  },
  action
) {
  switch (action.type) {
    case REQUEST_START_MACHINE:
    case REQUEST_STOP_MACHINE:
    case REQUEST_RESTART_MACHINE:
      return {powerStateChanging: true};
    case RESOLVE_START_MACHINE:
    case RESOLVE_STOP_MACHINE:
    case RESOLVE_RESTART_MACHINE:
    case ERROR_START_MACHINE:
    case ERROR_STOP_MACHINE:
    case ERROR_RESTART_MACHINE:
      return {powerStateChanging: false};
    default:
      return state;
  }
}

function remoteWorkstations(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_START_MACHINE:
    case REQUEST_STOP_MACHINE:
    case REQUEST_RESTART_MACHINE:
    case RESOLVE_START_MACHINE:
    case RESOLVE_STOP_MACHINE:
    case RESOLVE_RESTART_MACHINE:
    case ERROR_START_MACHINE:
    case ERROR_STOP_MACHINE:
    case ERROR_RESTART_MACHINE:
      return {
        ...state,
        [action.machineId]: stateByWorkstation(state[action.machineId], action),
      };
    case CLEAR_STORE:
      return defaultState;
    default:
      return state;
  }
}

export default remoteWorkstations;
