import {styled} from '@mui/material/styles';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
  Button,
  Typography,
  Grid,
  Chip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import {connectorIsCACv2} from 'utils/utils';
import {mapResourceToPath} from 'utils/Mappings';
import useInterval from 'hooks/useInterval';
import {ReactComponent as RhelConnectorUpgrade} from 'icons/rhel-conector-upgrade.svg';
import {
  INSTALL_RHEL_CONNECTOR_LINK,
  CONNECTOR_EVENTS_LATEST,
  CONNECTOR_EVENT_POLLING_INTERVAL_MS,
} from 'utils/constants';
import InstallationDetails from './InstallationDetails';
import {get} from 'api/Api';

const PREFIX = 'InstallationTab';

const classes = {
  root: `${PREFIX}-root`,
  notAvailableCard: `${PREFIX}-notAvailableCard`,
  leftCard: `${PREFIX}-leftCard`,
  rightCard: `${PREFIX}-rightCard`,
  screenMediumModifier: `${PREFIX}-screenMediumModifier`,
  screenSmallModifier: `${PREFIX}-screenSmallModifier`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  buttonRow: `${PREFIX}-buttonRow`,
  currentVersionText: `${PREFIX}-currentVersionText`,
  versionLabel: `${PREFIX}-versionLabel`,
  listWrapper: `${PREFIX}-listWrapper`,
  chip: `${PREFIX}-chip`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    margin: 'auto',
    width: '1152px',
  },
  [`& .${classes.notAvailableCard}`]: {
    maxWidth: '804px',
    borderRadius: '8px',
    border: '1px solid #E3E4E8',
    marginTop: '40px',
    // Overrides MUI rules which applies a negative margin & breaks alignment from the rest of the page
    marginLeft: '0px',
  },
  [`& .${classes.leftCard}`]: {
    padding: '32px',
  },
  [`& .${classes.rightCard}`]: {
    padding: '32px',
    backgroundColor: '#F8F8FB',
  },
  [`& .${classes.screenMediumModifier}`]: {
    borderLeft: '1px solid #E3E4E8',
  },
  [`& .${classes.screenSmallModifier}`]: {
    borderTop: '1px solid #E3E4E8',
  },
  [`& .${classes.title}`]: {
    fontFamily: 'Forma DJR UI, sans-serif',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '31px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  [`& .${classes.description}`]: {
    color: '#23242A',
    fontFamily: 'Forma DJR UI, sans-serif',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
  },
  [`& .${classes.buttonRow}`]: {
    marginTop: '8px',
  },
  [`& .${classes.currentVersionText}`]: {
    fontFamily: 'Forma DJR UI, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '140%',
    color: '#8E93A4',
  },
  [`& .${classes.versionLabel}`]: {
    color: '#23242A',
    fontFamily: 'Forma DJR UI, sans-serif',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '140%',
  },
  [`& .${classes.listWrapper}`]: {
    padding: '0px 28px',
    margin: '0px',
  },
  [`& .${classes.chip}`]: {
    backgroundColor: 'blue',
  },
}));

function InstallationTab() {
  const theme = useTheme();

  const mediumOrLargerScreen = useMediaQuery(theme.breakpoints.up('md'));
  const selectedConnector = useSelector(
    (state) => state?.data?.dataByResource?.selectedConnector
  );
  const { connectorId } = useParams();
  const [latestEvents, setLatestEvents] = useState([]);
  
  let isPolling = false;

  const [eventPollingInterval, setEventPollingInterval] = useState(
    CONNECTOR_EVENT_POLLING_INTERVAL_MS
  );

  const pollForConnectorEvents = async () => {
    if (isPolling) return;

    isPolling = true;
    const eventsUrl = mapResourceToPath(CONNECTOR_EVENTS_LATEST, { connectorId });
    try {
      const response = await get({ path: eventsUrl, params: { sortAsc: 'true' } });
      if (response.code === 200 && response.data.length > 0) {
        if (JSON.stringify(response.data) !== JSON.stringify(latestEvents)) {
          setLatestEvents(response.data);
        }
      }
    } catch (error) {
      if (error?.code === 401) {
        // Stop the polling if the user is unauthorized
        setEventPollingInterval(null);
        dispatch(handleApiError(error));
      } else {
        // Silently fail on other errors
        console.error('Error polling for connector events. ', error);
      }
    } finally {
      isPolling = false;
    }
  };

  useInterval(
    () => {
      pollForConnectorEvents();
    },
    eventPollingInterval,
    true
  ); // true to indicate immediate polling

  const connectorVersion =
    selectedConnector?.components?.version ||
    selectedConnector?.components?.cacVersion;

  const rightCardClass = classNames({
    [classes.rightCard]: true,
    [classes.screenMediumModifier]: mediumOrLargerScreen,
    [classes.screenSmallModifier]: !mediumOrLargerScreen,
  });

  const installDetailsNotAvailable = (
    <Root>
      <Grid container className={classes.notAvailableCard}>
        <Grid item xs={12} md={6} className={classes.leftCard}>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item>
              <RhelConnectorUpgrade alt="RHEL Connector upgrade" />
            </Grid>
            <Grid item>
              <Typography
                className={classes.title}
                variant="h5"
                component="div"
              >
                View step by step installation progress
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.description}>
                Updating this Connector from Ubuntu to RHEL will unlock many new
                features including the ability to view step by step installation
                progress.
              </Typography>
            </Grid>
            <Grid item className={classes.buttonRow}>
              <Button
                variant="contained"
                color="primary"
                href={INSTALL_RHEL_CONNECTOR_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn how to upgrade
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={rightCardClass}>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <Typography className={classes.versionLabel}>
                    Ubuntu 18.04
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.currentVersionText}>
                    (current version)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ul className={classes.listWrapper}>
                <li>
                  Platform has reached end of standard support from Canonical
                </li>
                <li>Limited security updates</li>
                <li>No new feature updates</li>
              </ul>
            </Grid>
            <Grid item>
              <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item>
                  <Typography className={classes.versionLabel}>
                    RHEL / Rocky 8
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label="Recommended"
                    sx={{
                      bgcolor: '#E5F0FF',
                      border: 1,
                      borderColor: '#C9E0FF',
                      color: '#1B4A8C',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ul className={classes.listWrapper}>
                <li>Improved installation experience</li>
                <li>Installation feedback within Admin Console </li>
                <li>Security updates</li>
                <li>Feature updates</li>
                <li>Improved error troubleshooting</li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );

  return connectorVersion && connectorIsCACv2(connectorVersion) ? (
    installDetailsNotAvailable
  ) : (
    <Root className={classes.root}>
        <InstallationDetails events={latestEvents} />
    </Root>
  );
}

InstallationTab.propTypes = {
  events: PropTypes.array,
};
export default InstallationTab;
