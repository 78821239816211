import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {
  deleteAdGroups,
  fetchConnectorSettings,
} from 'redux/actions/dataActions';
import {selectSelectedDeployment} from 'utils/reduxSelectors';

function AdGroupsActions({item: adGroup, onClose, onConfirm}) {
  const dispatch = useDispatch();

  const deployment = useSelector((state) => selectSelectedDeployment(state));

  const getGroupDisplayText = () =>
    adGroup ? adGroup.groupDN || adGroup.groupGuid : '';

  const getDeploymentDisplayText = () =>
    deployment ? deployment.deploymentName || deployment.deploymentId : '';

  const handleDeleteAdGroup = async () => {
    await dispatch(deleteAdGroups([adGroup]));
    onConfirm();
    dispatch(fetchConnectorSettings());
  };

  const handleDeleteClick = () => {
    onClose();
    dispatch(
      openDialog(
        'Delete AD group from deployment?',
        `Are you sure you want to remove ${getGroupDisplayText()} from deployment
        ${getDeploymentDisplayText()}? This group will also be removed from any pool assignments it is part of. This operation cannot be undone. `,
        handleDeleteAdGroup
      )
    );
  };

  return (
    <KebabMenuItem
      id={`delete-ad-group-${adGroup.groupGuid}`}
      dataTestId={`delete-${adGroup.groupGuid}`}
      menuText="Delete"
      onClick={handleDeleteClick}
    />
  );
}

AdGroupsActions.propTypes = {
  item: PropTypes.object,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

AdGroupsActions.defaultProps = {
  item: {},
  onClose: () => {},
  onConfirm: () => {},
};

export default AdGroupsActions;
