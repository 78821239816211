import {useState} from 'react';
import { styled } from '@mui/material/styles';
import fileDownload from 'js-file-download';
import ReactSelect from 'react-select';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Link';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import config from 'config';
import CasmCookies from 'utils/cookies';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import useSnackbar from 'hooks/useSnackbar';
import {isEmpty} from 'utils/utils';
import {get} from 'api/Api';

const PREFIX = 'Installers';

const classes = {
  description: `${PREFIX}-description`,
  buttonStyle: `${PREFIX}-buttonStyle`,
  installerParagraph: `${PREFIX}-installerParagraph`,
  downloadGrid: `${PREFIX}-downloadGrid`,
  card: `${PREFIX}-card`,
  link: `${PREFIX}-link`,
  moreInfoText: `${PREFIX}-moreInfoText`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.description}`]: theme.createPage.inputTextFieldLabel,
  [`& .${classes.buttonStyle}`]: theme.createPage.inputTextFieldLabel,

  [`& .${classes.installerParagraph}`]: {
    width: '600px',
    marginRight: '30px',
  },

  [`& .${classes.downloadGrid}`]: {
    padding: '10px 0px',
  },

  [`& .${classes.card}`]: {
    width: '700px',
  },

  [`& .${classes.link}`]: {
    marginRight: '5px',
  },

  [`& .${classes.moreInfoText}`]: {
    color: theme.palette.info.main,
    fontFamily: 'Roboto',
    fontSize: '0.8125rem',
    lineHeight: '15px',
    paddingLeft: '16px',
    paddingTop: '5px',
  }
}));

function Installers() {

  const {errorSnackbar} = useSnackbar();
  const [hwDownloadSelection, setHwDownloadSelection] = useState(null);

  const onHwDownloadSelection = (value) => {
    setHwDownloadSelection(value);
  };

  const hwMonitorButtonClick = async (endpoint, fileName) => {
    try {
      const installerBlob = await get({
        absolutePath: `${config.GATEWAY_ADDRESS}/${endpoint}`,
        isDownload: true,
      });
      fileDownload(installerBlob, fileName);
    } catch (error) {
      errorSnackbar(`Failed to download installer file: ${error}`);
    }
  };

  const hwMonitorDownloadSignatureClick = async (endpoint, fileName) => {
    try {
      const signatureBlob = await get({
        absolutePath: `${config.GATEWAY_ADDRESS}/${endpoint}_asc`,
        isDownload: true,
      });
      fileDownload(signatureBlob, `${fileName}.asc`);
    } catch (error) {
      errorSnackbar(`Failed to download signature file: ${error}`);
    }
  };

  const gpgKeyDownloadClick = async () => {
    try {
      const gpgKeyBlob = await get({
        absolutePath: `${config.GATEWAY_ADDRESS}/hw_gpg`,
        isDownload: true,
      });
      fileDownload(gpgKeyBlob, 'GPG-KEY-hpzcentralconnect');
    } catch (error) {
      errorSnackbar(`Failed to download gpg key: ${error}`);
    }
  };

  const onHwDownloadButtonClick = (selection) => {
    if (selection && selection.value === 'windows') {
      hwMonitorButtonClick('hw_win', 'HP_ZCentral_Hardware_Monitor.zip');
    } else if (selection && selection.value === 'ubuntu') {
      hwMonitorButtonClick(
        'hw_ubuntu',
        'hp_zcentral_hardware_monitor_ubuntu.tar.gz'
      );
    } else if (selection && selection.value === 'rhel') {
      hwMonitorButtonClick(
        'hw_rhel',
        'hp_zcentral_hardware_monitor_rhel.tar.gz'
      );
    }
  };

  const onHwSignatureDownloadLinkClick = (selection) => {
    if (selection && selection.value === 'windows') {
      hwMonitorDownloadSignatureClick(
        'hw_win',
        'HP_ZCentral_Hardware_Monitor.zip'
      );
    } else if (selection && selection.value === 'ubuntu') {
      hwMonitorDownloadSignatureClick(
        'hw_ubuntu',
        'hp_zcentral_hardware_monitor_ubuntu.tar.gz'
      );
    } else if (selection && selection.value === 'rhel') {
      hwMonitorDownloadSignatureClick(
        'hw_rhel',
        'hp_zcentral_hardware_monitor_rhel.tar.gz'
      );
    }
  };

  const options = [
    {value: 'windows', label: 'Windows'},
    {value: 'rhel', label: 'Red Hat 7 & 8'},
    {value: 'ubuntu', label: 'Ubuntu 18.04 & 20.04'},
  ];

  return (
    (<Root>
      <Toolbar disableGutters>
        <Typography variant="h5">Installers</Typography>
      </Toolbar>
      <div className={classes.card}>
        <CAMCard>
          <div className={classes.installerParagraph}>
            <SectionHeader displayText="HARDWARE MONITOR" />
            <Typography className={classes.inputTextFieldLabel} display="block">
              Install the HP Hardware Monitor on ZCentral 4R platforms to detect
              power supply failures. Choose the operating system below to
              download the appropriate installer and corresponding signature
              file.
            </Typography>
            <Grid container spacing={1} className={classes.downloadGrid}>
              <Grid item xs={6}>
                <div data-testid="container-download-installer-select">
                  <ReactSelect
                    data-testid="download-installer-select"
                    placeholder="Choose operating system"
                    isMulti={false}
                    isSearchable={false}
                    options={options}
                    onChange={onHwDownloadSelection}
                    value={hwDownloadSelection}
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <CAMButton
                  buttonText="Download Installer"
                  testId="download-installer-button"
                  onClick={() => onHwDownloadButtonClick(hwDownloadSelection)}
                  disabled={isEmpty(hwDownloadSelection)}
                />
              </Grid>
            </Grid>
            {!isEmpty(hwDownloadSelection) && (
              <>
                <Button
                  className={classes.buttonStyle}
                  component="button"
                  testId="download-signature-link"
                  onClick={() =>
                    onHwSignatureDownloadLinkClick(hwDownloadSelection)
                  }
                >
                  Download installer signature
                </Button>
                <div>
                  <Button
                    component="button"
                    className={classes.buttonStyle}
                    testId="download-gpg-key-link"
                    onClick={() => gpgKeyDownloadClick()}
                  >
                    Download signature key
                  </Button>
                  <Typography className={classes.inputTextFieldLabel}>
                    Key ID: HP ZCentral Connect hpss-admin@hp.com
                  </Typography>
                  <Typography className={classes.inputTextFieldLabel}>
                    Fingerprint: 66CF 5025 AA35 1EB7 CC78 136F C75F B106 E198
                    51F0
                  </Typography>
                </div>
              </>
            )}
          </div>
        </CAMCard>
      </div>
    </Root>)
  );
}

export default Installers;
