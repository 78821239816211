import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import SuccessIcon from '@mui/icons-material/CheckCircleOutlined';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import DisabledIcon from '@mui/icons-material/Cancel';
import {Typography, Grid} from '@mui/material';
import {
  CAPABILITY_DISABLED_STATE,
  CAPABILITY_ENABLED_STATE,
  CAPABILITY_INCOMPLETE_STATE,
  CAC_HEALTH_FAIL,
  CAC_HEALTH_SUCCESS,
} from 'utils/constants';

const iconColorMap = {
  [CAPABILITY_ENABLED_STATE]: '#1D855A',
  [CAPABILITY_INCOMPLETE_STATE]: '#E65100',
  [CAPABILITY_DISABLED_STATE]: '#2D74D8',
  [CAC_HEALTH_FAIL]: '#E65100',
  [CAC_HEALTH_SUCCESS]: '#1D855A',
};

const statusPropTypes = {
  capabilityStatus: PropTypes.oneOf([
    CAPABILITY_ENABLED_STATE,
    CAPABILITY_INCOMPLETE_STATE,
    CAPABILITY_DISABLED_STATE,
    CAC_HEALTH_FAIL,
    CAC_HEALTH_SUCCESS,
  ]).isRequired,
};
const PREFIX = 'StatusIconText';

const classes = {
  icon: `${PREFIX}-icon`,
  statusText: `${PREFIX}-statusText`,
};

const Root = styled('div')(({capabilityStatus}) => ({
  [`& .${classes.statusText}`]: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color:
      iconColorMap[capabilityStatus] || iconColorMap[CAPABILITY_DISABLED_STATE],
  },
}));

const StyledIcon = styled(({icon: Icon, ...props}) => <Icon {...props} />)(
  (props) => ({
    [`&.${classes.icon}`]: {
      marginRight: '6.5px',
      fontSize: '16px',
      color:
        iconColorMap[props.capabilityStatus] ||
        iconColorMap[CAPABILITY_DISABLED_STATE],
    },
  })
);
export function StatusIcon({capabilityStatus}) {
  const iconProps = {
    alt: capabilityStatus,
    className: classes.icon,
    capabilityStatus,
  };

  switch (capabilityStatus) {
    case CAPABILITY_ENABLED_STATE:
    case CAC_HEALTH_SUCCESS:
      return <StyledIcon icon={SuccessIcon} {...iconProps} />;
    case CAPABILITY_INCOMPLETE_STATE:
    case CAC_HEALTH_FAIL:
      return <StyledIcon icon={WarningIcon} {...iconProps} />;
    case CAPABILITY_DISABLED_STATE:
    default:
      return <StyledIcon icon={DisabledIcon} {...iconProps} />;
  }
}
StatusIcon.propTypes = statusPropTypes;

export function StatusIconText({capabilityStatus}) {
  return (
    <Root capabilityStatus={capabilityStatus}>
      <Grid container direction="row" alignItems="center">
        <StatusIcon capabilityStatus={capabilityStatus} />
        <Typography variant="subtitle2" className={classes.statusText}>
          {capabilityStatus}
        </Typography>
      </Grid>
    </Root>
  );
}
StatusIconText.propTypes = statusPropTypes;
