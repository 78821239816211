import {Tab, Tabs} from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {Fragment, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import config from 'config';
import {readNotificationAll} from 'redux/actions/notificationActions';
import {RELEASE_NOTES_LINK} from 'utils/constants';
import NotificationItem from './NotificationItem';
import ReleaseNoteItem from './ReleaseNoteItem';

const PREFIX = 'NotificationList';

const classes = {
  root: `${PREFIX}-root`,
  closed: `${PREFIX}-closed`,
  cardContent: `${PREFIX}-cardContent`,
  markAllRead: `${PREFIX}-markAllRead`,
  notificationListTitle: `${PREFIX}-notificationListTitle`,
  tabTitle: `${PREFIX}-tabTitle`
};

const StyledPopover = styled(Popover)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    width: '20rem',
    maxHeight: '24em',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  [`& .${classes.closed}`]: {
    display: 'none',
  },

  [`& .${classes.cardContent}`]: {
    padding: theme.spacing(2),
    overflowX: 'hidden',
  },

  [`& .${classes.markAllRead}`]: {
    fontSize: '0.875em',
    fontWeight: 500,
    color: '#0076A9',
    padding: '16px 22px',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    letterSpacing: '0.17px',
  },

  [`& .${classes.notificationListTitle}`]: {
    textTransform: 'uppercase',
    padding: '12px 0 10px 18px',
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontSize: '0.875em',
    fontWeight: 500,
  },

  [`& .${classes.tabTitle}`]: {
    paddingRight: '4px',
  }
}));

const buttonOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const listOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

export const unreadNotificationCount = (notifications) =>
  notifications.filter((notification) => notification.read !== true).length;

function TabTitleWithBadge(title, invisible, classes) {
  return (
    <Badge
      overlap="rectangular"
      color="secondary"
      variant="dot"
      invisible={invisible}
    >
      <span className={classes.tabTitle}>{title}</span>
    </Badge>
  );
}

function NotificationList({
  anchorEl,
  notifications,
  releaseNotes,
  releaseNotesAvailable,
  onClose,
}) {

  const dispatch = useDispatch();

  const NOTIFICATIONS_TAB = 0;
  const RELEASE_NOTES_TAB = 1;

  const [selectedTab, setSelectedTab] = useState(NOTIFICATIONS_TAB);
  const notificationsUnread = unreadNotificationCount(notifications);

  const readNotificationAllAction = () => dispatch(readNotificationAll());
  const handleTabChange = (_, newTab) => setSelectedTab(newTab);

  const renderEmptyMessage = () => (
    <Typography>You&apos;re all caught up!</Typography>
  );

  const renderNotificationTab = () => {
    let notificationContent = renderEmptyMessage();

    if (notifications.length) {
      notificationContent = notifications.map((notification, index) => (
        <Fragment key={notification.key}>
          <NotificationItem notification={notification} />
          {index !== notifications.length - 1 && <Divider />}
        </Fragment>
      ));
    }
    return (
      <>
        <CardContent className={classes.cardContent}>
          {notificationContent}
        </CardContent>
        {notificationsUnread > 0 && (
          <>
            <Divider />
            <Typography
              data-testid="read-all-notifications"
              className={classes.markAllRead}
              onClick={() => readNotificationAllAction()}
            >
              Mark all as read
            </Typography>
          </>
        )}
      </>
    );
  };

  const renderReleaseNotesTab = () => {
    let releaseNotesContent = renderEmptyMessage();

    if (releaseNotes.length && releaseNotesAvailable) {
      releaseNotesContent = releaseNotes.map((rn, index) => (
        <Fragment key={rn.key}>
          <ReleaseNoteItem releaseNote={rn} onClick={onClose} />
          {index !== releaseNotes.length - 1 && <Divider />}
        </Fragment>
      ));
    }
    return (
      <>
        <CardContent className={classes.cardContent}>
          {releaseNotesContent}
        </CardContent>
        <Divider />
        <Link to={RELEASE_NOTES_LINK}>
          <Typography className={classes.markAllRead} onClick={onClose}>
            All Release Notes
          </Typography>
        </Link>
      </>
    );
  };

  const tabs = {
    [NOTIFICATIONS_TAB]: renderNotificationTab(),
  };

  if (!config.STANDALONE) {
    tabs[RELEASE_NOTES_TAB] = renderReleaseNotesTab();
  }

  const renderSelectedTab = () => tabs[selectedTab];

  const renderTabsBar = () => (
    <Tabs
      variant="fullWidth"
      value={selectedTab}
      onChange={handleTabChange}
      TabIndicatorProps={{
        style: {
          left: selectedTab === NOTIFICATIONS_TAB ? 0 : '159px',
          width: config.STANDALONE ? '100%' : '159px',
        },
      }}
      indicatorColor="primary"
    >
      <Tab
        label={TabTitleWithBadge(
          'Notifications',
          notificationsUnread === 0,
          classes
        )}
        data-testid="notifications-tab"
      />
      {!config.STANDALONE && (
        <Tab
          label={TabTitleWithBadge(
            'Release Notes',
            config.STANDALONE || !releaseNotesAvailable,
            classes
          )}
          data-testid="release-notes-tab"
        />
      )}
    </Tabs>
  );

  return (
    <StyledPopover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={buttonOrigin}
      transformOrigin={listOrigin}
      onClose={onClose}
    >
      <Card className={classes.root}>
        {renderTabsBar()}
        {renderSelectedTab()}
      </Card>
    </StyledPopover>
  );
}

NotificationList.propTypes = {
  anchorEl: PropTypes.object,
  notifications: PropTypes.array,
  releaseNotes: PropTypes.array,
  releaseNotesAvailable: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

NotificationList.defaultProps = {
  anchorEl: null,
  notifications: [],
  releaseNotes: [],
};

export default NotificationList;
