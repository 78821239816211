import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import TextInput from 'components/CAM/inputs/TextInput/TextInput';
import usePendingChanges from 'hooks/usePendingChanges';

const PREFIX = 'TextInputSubmission';

const customSubmissionProps = {
  label: 'label',
};

const classes = {
  root: `${PREFIX}-root`,
  inputProps: `${PREFIX}-inputProps`,
  inputSectionTextField: `${PREFIX}-inputSectionTextField`,
  eyeIcon: `${PREFIX}-eyeIcon`,
  eyeball: `${PREFIX}-eyeball`,
  buttonSpacer: `${PREFIX}-buttonSpacer`,
  fieldContainer: `${PREFIX}-fieldContainer`,
};

const Root = styled('div', {
  shouldForwardProp: (prop) =>
    !Object.values(customSubmissionProps).includes(prop),
})(({theme, ...props}) => ({
  [`&.${classes.root}`]: {
    // border: '1px solid orange',
    display: 'flex',
    flexDirection: 'row',
  },

  [`& .${classes.inputProps}`]: {
    height: '36px',
    padding: '0px',
  },

  [`& .${classes.inputSectionTextField}`]: {
    backgroundColor: theme.palette.surface.white,
    minWidth: '100%',
  },

  [`& .${classes.eyeIcon}`]: {
    marginRight: '5px',
  },

  [`& .${classes.eyeball}`]: {
    fontSize: '18px',
  },

  [`& .${classes.buttonSpacer}`]: {
    marginTop: props.label ? '24px' : '0px',
    marginLeft: '8px',
  },

  [`& .${classes.fieldContainer}`]: {
    width: '400px',
  },
}));

function TextInputSubmission({label, tooltip, defaultValue, onSubmit}) {
  const {setPendingChanges} = usePendingChanges();
  const [value, setValue] = useState(defaultValue);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setPendingChanges(defaultValue !== value && !isSubmitting);
  }, [defaultValue, value, isSubmitting]);

  const textInputProps = {
    disabled: isSubmitting,
    onChange: (newValue) => setValue(newValue),
    value,
  };
  if (label) {
    textInputProps.label = label;
  }
  if (tooltip) {
    textInputProps.tooltipText = tooltip;
  }

  const handleSubmitClick = async () => {
    setIsSubmitting(true);

    await onSubmit(value);

    setIsSubmitting(false);
  };

  return (
    <Root className={classes.root} label={label}>
      <div className={classes.fieldContainer}>
        <TextInput {...textInputProps} />
      </div>

      <div className={classes.buttonSpacer}>
        <CAMButton
          buttonText="Save"
          onClick={handleSubmitClick}
          disabled={isSubmitting || defaultValue === value}
        />
      </div>
    </Root>
  );
}

TextInputSubmission.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
  defaultValue: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

TextInputSubmission.defaultProps = {
  label: '',
  defaultValue: '',
  tooltip: '',
};

export default TextInputSubmission;
