import {
  CLOSE_DELETE_POOLS_DIALOG,
  OPEN_DELETE_POOLS_DIALOG,
  REMOVE_POLLING_POOL_DELETION,
  SET_IS_DELETING,
  SET_REMOVE_FROM_CAM,
  SET_REMOVE_FROM_PROVIDER,
} from 'redux/actions/DeletePoolsDialogActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';
import {
  ADD_POLLING_ITEM,
  REMOVE_POLLING_ITEM,
} from 'redux/actions/pollingActions';

const defaultState = {
  open: false,
  pools: [],
  shouldRemoveFromCam: false,
  shouldRemoveFromProvider: false,
  isDeleting: false,
  pollingDeletions: {},
};

function openDialog(state, action) {
  return {
    ...state,
    open: true,
    pools: action.pools,
  };
}

function closeDialog(state) {
  return {
    ...defaultState,
    pollingDeletions: state.pollingDeletions,
  };
}

function setRemoveFromCam(state, action) {
  return {
    ...state,
    shouldRemoveFromCam: action.shouldRemoveFromCam,
  };
}

function setRemoveFromProvider(state, action) {
  return {
    ...state,
    shouldRemoveFromProvider: action.shouldRemoveFromProvider,
  };
}

function setIsDeleting(state, action) {
  return {
    ...state,
    isDeleting: action.isDeleting,
  };
}

function isDeletePoolPoll(pollItem) {
  const pollingKey = pollItem.key || '';
  return pollingKey.includes('delete-pool');
}

function addPollingDeletion(state, action) {
  const pollItem = action.item || {};
  if (isDeletePoolPoll(pollItem)) {
    return {
      ...state,
      pollingDeletions: {
        ...state.pollingDeletions,
        [pollItem.resourceId]: {
          status: 'active',
          info: {},
        },
      },
    };
  }
  return state;
}

function setCompletedPollingDeletion(state, action) {
  if (isDeletePoolPoll(action)) {
    return {
      ...state,
      pollingDeletions: {
        ...state.pollingDeletions,
        [action.resourceId]: {
          ...state.pollingDeletions[action.resourceId],
          status: 'complete',
          info: action.info,
        },
      },
    };
  }
  return state;
}

function removePollingDeletion(state, action) {
  const {[action.poolId]: omitted, ...restData} = state.pollingDeletions;
  return {
    ...state,
    pollingDeletions: restData,
  };
}

function clearDialog() {
  return defaultState;
}

const stateChanges = {
  [OPEN_DELETE_POOLS_DIALOG]: openDialog,
  [CLOSE_DELETE_POOLS_DIALOG]: closeDialog,
  [SET_REMOVE_FROM_CAM]: setRemoveFromCam,
  [SET_REMOVE_FROM_PROVIDER]: setRemoveFromProvider,
  [SET_IS_DELETING]: setIsDeleting,
  [ADD_POLLING_ITEM]: addPollingDeletion,
  [REMOVE_POLLING_ITEM]: setCompletedPollingDeletion,
  [REMOVE_POLLING_POOL_DELETION]: removePollingDeletion,
  [CLEAR_STORE]: clearDialog,
};

function deletePoolsDialog(state = defaultState, action) {
  const stateChange = stateChanges[action.type];
  return stateChange ? stateChange(state, action) : state;
}

export default deletePoolsDialog;
