import {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import PageHeader from 'components/CAM/layout/PageHeader/PageHeader';
import CAMTable from 'components/table/Table';
import {
  SESSION_AUDIT,
  SESSION_HISTORY_DISCLAIMER_MESSAGE,
} from 'utils/constants';
import {selectDataForTable} from 'utils/reduxSelectors';
import {
  millisecondsToReadableTime,
  truncateLabelAndAddSuffix,
} from 'utils/utils';

const MAX_LENGTH_STRING = 20;

function Sessions() {
  const {
    data: sessions,
    isFetching: isFetchingSessions,
    total,
  } = useSelector((state) => selectDataForTable(state, SESSION_AUDIT));

  const renderUser = (user) => (
    <div>{truncateLabelAndAddSuffix(user, MAX_LENGTH_STRING)}</div>
  );

  const prepareDetectedUsers = (detectedUsers) => {
    if (detectedUsers && detectedUsers.length > 0) {
      return detectedUsers.map((user) => renderUser(user));
    }
    return '-';
  };

  const prepareSession = useCallback(
    (session) => ({
      ...session,
      approximateDurationInMs: millisecondsToReadableTime(
        session.approximateDurationInMs
      ),
      machineName: truncateLabelAndAddSuffix(
        session.machineName,
        MAX_LENGTH_STRING
      ),
      connectorId: session.connectorId || '-',
      endedOn: session.endedOn || '-',
      connectorName:
        truncateLabelAndAddSuffix(session.connectorName, MAX_LENGTH_STRING) ||
        '-',
      userName:
        truncateLabelAndAddSuffix(session.userName, MAX_LENGTH_STRING) || '-',
      userUsername:
        truncateLabelAndAddSuffix(session.userUsername, MAX_LENGTH_STRING) || '-',
      userUpn:
        truncateLabelAndAddSuffix(session.userUpn, MAX_LENGTH_STRING) || '-',
      detectedUsers: prepareDetectedUsers(session.loggedInUsers),
    }),
    []
  );

  const prepareData = (data) => data?.map((session) => prepareSession(session));

  return (
    <>
      <PageHeader
        titleText="Sessions"
        descriptionText={SESSION_HISTORY_DISCLAIMER_MESSAGE}
      />
      <CAMTable
        id="sessions"
        hideTitle
        tableTitle="Sessions"
        resource={SESSION_AUDIT}
        idField="sessionId"
        data={prepareData(sessions)}
        total={total}
        loadingData={isFetchingSessions}
        hideAddButton
        hideActions
        hideBulkActions
        rowsPerPageOptions={[10, 20, 50, 100]}
        hideConfigureTable={false}
        dateFilter
      />
    </>
  );
}

export default Sessions;
