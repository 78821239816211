import {useDispatch} from 'react-redux';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {createOptionsObject} from 'utils/utils';

const defaultConfig = {
  onConfirm: () => {},
  cancelEnabled: true,
  onCancel: () => {},
  dontShowAgainCheckbox: false,
  handleCheckboxChange: () => {},
  confirmationText: 'OK',
  warningMessage: '',
};

const useDialog = () => {
  const dispatch = useDispatch();

  const triggerDialog = (config) => {
    const {
      title,
      message,
      onConfirm,
      onCancel,
      cancelEnabled,
      dontShowAgainCheckbox,
      handleCheckboxChange,
      confirmationText,
      warningMessage,
    } = createOptionsObject(defaultConfig, config);

    dispatch(
      openDialog(
        title,
        message,
        onConfirm,
        cancelEnabled,
        onCancel,
        dontShowAgainCheckbox,
        handleCheckboxChange,
        confirmationText,
        warningMessage
      )
    );
  };

  return {triggerDialog};
};

export default useDialog;
