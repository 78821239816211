import {Checkbox, Grid, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CAMDeleteDialog from 'components/CAM/dialogs/CAMDeleteDialog/CAMDeleteDialog';
import {
  closeDeletePoolsDialog,
  deletePools,
  removePollingPoolDeletion,
  setRemoveFromCam,
  setRemoveFromProvider,
} from 'redux/actions/DeletePoolsDialogActions';
import {pushNotification} from 'redux/actions/notificationActions';
import {uncheckAll} from 'redux/actions/tableSelectActions';
import {EDIT_POOL_LINK, POOLS} from 'utils/constants';
import {selectDeletingPoolPolls} from 'utils/reduxSelectors';
import {linkTo} from 'utils/utils';

const PREFIX = 'DeletePoolsDialog';

const classes = {
  checkboxContainer: `${PREFIX}-checkboxContainer`,
  checkboxText: `${PREFIX}-checkboxText`,
  dialogRoot: `${PREFIX}-dialogRoot`,
  infoText: `${PREFIX}-infoText`,
};

const StyledAMDeleteDialog = styled(CAMDeleteDialog)(() => ({
  [`& .${classes.checkboxContainer}`]: {
    width: '100%',
  },

  [`& .${classes.checkboxText}`]: {
    fontSize: '0.875rem',
  },

  [`& .${classes.dialogRoot}`]: {
    minWidth: 500,
  },

  [`& .${classes.infoText}`]: {
    paddingBottom: '1rem',
  },
}));

function DeletePoolsDialog() {
  const dispatch = useDispatch();
  const {open, pools, shouldRemoveFromCam, shouldRemoveFromProvider} =
    useSelector((state) => state.deletePoolsDialog);

  const pollingItems = useSelector((state) => selectDeletingPoolPolls(state));

  const poolsDisplayText = pools && pools.length > 1 ? 'pools' : 'pool';

  // if disabling permission to remove machines from Anyware Manager, also disable
  // permission to remove machines from provider
  const handleCheckRemoveFromCam = () => {
    if (shouldRemoveFromCam) {
      dispatch(setRemoveFromProvider({shouldRemoveFromProvider: false}));
    }
    dispatch(
      setRemoveFromCam({
        shouldRemoveFromCam: !shouldRemoveFromCam,
      })
    );
  };

  // only allow deletion from provider if deletion from Anyware Manager is also selected
  const handleCheckRemoveFromProvider = () => {
    if (shouldRemoveFromCam) {
      dispatch(
        setRemoveFromProvider({
          shouldRemoveFromProvider: !shouldRemoveFromProvider,
        })
      );
    }
  };

  const handleClose = () => {
    dispatch(closeDeletePoolsDialog());
  };

  const handleConfirm = () => {
    dispatch(deletePools());
    dispatch(uncheckAll(POOLS));
  };

  const getPoolName = (poolId) => {
    const poolToFind = pools.find((pool) => pool.poolId === poolId) || {};
    return poolToFind.poolName || poolId;
  };

  const displayErrorNotification = (poolId, message) => {
    const errorMessage =
      message ||
      `Deletion of pool ${getPoolName(poolId)} failed due to an unknown error.`;
    dispatch(
      pushNotification({
        title: 'Pool deletion failed',
        type: 'Pools',
        moreInfo: errorMessage,
        timePosted: Date.now(),
        actionText: 'Mark as read',
        link: linkTo(`${EDIT_POOL_LINK}/${poolId}`),
      })
    );
  };

  const displaySuccessNotification = (poolId) => {
    dispatch(
      pushNotification({
        title: 'Pool successfully deleted',
        type: 'Pools',
        moreInfo: `Pool ${getPoolName(poolId)} was deleted successfully.`,
        timePosted: Date.now(),
        actionText: 'Mark as read',
      })
    );
  };

  const getCompletePollingItems = (items) =>
    Object.keys(items)
      .filter((key) => items[key].status === 'complete')
      .map((key) => ({...items[key], poolId: key}));

  // only happens if the pools worker sets pool status to 'error'
  const handleDeletionError = (poolId) => {
    displayErrorNotification(poolId);
    dispatch(removePollingPoolDeletion({poolId}));
  };

  const handleDeletionSuccess = (poolId, error) => {
    if (error.code === 404) {
      // polling couldn't find the pool, so it was deleted. display a success notification
      displaySuccessNotification(poolId);
    } else {
      // some other error has occurred, display an error notification
      const errorData = error.data || {};
      const errorMessage = errorData.reason || '';
      displayErrorNotification(poolId, errorMessage);
    }
    dispatch(removePollingPoolDeletion({poolId}));
  };

  useEffect(() => {
    if (pollingItems) {
      const completedPollingItems = getCompletePollingItems(pollingItems);
      completedPollingItems.forEach((item) =>
        item.info.value
          ? handleDeletionError(item.poolId)
          : handleDeletionSuccess(item.poolId, item.info.error)
      );
    }
  }, [pollingItems]);

  const renderPoolDeletionText = () => {
    if (!pools) {
      return '';
    }

    return (
      <Typography component={Grid} item className={classes.infoText}>
        {`This will remove all remote workstations,
        users, and groups from the ${poolsDisplayText} and delete all assignments. This operation cannot be undone.`}
      </Typography>
    );
  };

  const renderRemoveFromCamOption = () => (
    <Grid item container direction="row" alignItems="center" wrap="nowrap">
      <Checkbox
        checked={shouldRemoveFromCam}
        onChange={handleCheckRemoveFromCam}
        color="primary"
        size="small"
        inputProps={{
          'data-testid': 'check-remove-from-cam',
        }}
      />
      <Typography className={classes.checkboxText}>
        Also remove remote workstations from Anyware Manager
      </Typography>
    </Grid>
  );

  const renderRemoveFromProviderOption = () => (
    <Grid item container direction="row" alignItems="center" wrap="nowrap">
      <Checkbox
        checked={shouldRemoveFromProvider}
        onChange={handleCheckRemoveFromProvider}
        color="primary"
        size="small"
        disabled={!shouldRemoveFromCam}
        inputProps={{
          'data-testid': 'check-remove-from-provider',
        }}
      />
      <Typography className={classes.checkboxText}>
        Also delete remote workstations from public cloud provider
      </Typography>
    </Grid>
  );

  const renderContent = () => (
    <Grid>
      {renderPoolDeletionText()}
      {renderRemoveFromCamOption()}
      {renderRemoveFromProviderOption()}
    </Grid>
  );

  const resourceNameForDeleteDialog =
    pools.length === 1
      ? pools[0].poolName
      : `${pools.length} ${poolsDisplayText}`;

  return (
    <StyledAMDeleteDialog
      resourceName={resourceNameForDeleteDialog}
      open={open}
      onOk={handleConfirm}
      onCancel={handleClose}
      requireTextInputToDelete={shouldRemoveFromCam || pools.length > 1}
      content={renderContent()}
    />
  );
}

export default DeletePoolsDialog;
