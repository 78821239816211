import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {useDispatch} from 'react-redux';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {List, ListItem, CircularProgress} from '@mui/material';
import {useState} from 'react';
import {del} from 'api/Api';
import {fetchTableData} from 'redux/actions/tableDataActions';
import useSnackbar from 'hooks/useSnackbar';
import CAMDeleteDialog from 'components/CAM/dialogs/CAMDeleteDialog/CAMDeleteDialog';
import MachineName from 'components/CAM/display/MachineName/MachineName';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import {mapProvidersToEnum} from 'utils/Mappings';
import {handleApiError} from 'redux/actions/dataActions';
import {ENROLLMENT_COMMANDS} from 'utils/constants';

const PREFIX = 'EnrollmentDeleteCommand';

const classes = {
  createPageContainer: `${PREFIX}-createPageContainer`,
  removeIcon: `${PREFIX}-removeIcon`,
  loading: `${PREFIX}-loading`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.createPageContainer}`]: theme.createPage.createPageContainer,

  [`& .${classes.removeIcon}`]: {
    width: '22px',
    height: '22px',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },

  [`& .${classes.loading}`]: {
    verticalAlign: 'middle',
  },
}));

function EnrollmentDeleteCommand({command}) {
  const [messageForDeleteDialog, setMessageForDeleteDialog] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [commandToDelete, setCommandToDelete] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useDispatch();
  const {successSnackbar} = useSnackbar();
  const generateDeleteMessage = (com) => {
    const message =
      `Are you sure you want to delete command ${com.keyName}? ` +
      `${
        com.pendingApprovals === 0
          ? ''
          : 'The following workstations are enrolled with this command and are pending for approval, Revoking the command will reject these workstations immediately.'
      }`;
    setMessageForDeleteDialog(message);
  };

  const handleDeleteCommand = (com) => {
    generateDeleteMessage(com);
    setCommandToDelete(com);
    setOpenDeleteDialog(true);
  };

  const refreshDeleteDialog = () => {
    setCommandToDelete({});
    setMessageForDeleteDialog('');
    setOpenDeleteDialog(false);
  };

  const onDeleteCommand = async ({enrollmentKeyId, keyName}) => {
    try {
      refreshDeleteDialog();
      setIsDeleting(true);
      await del({path: `auth/keys/messaging/enrollment/${enrollmentKeyId}`});
    } catch (error) {
      setIsDeleting(false);
      dispatch(handleApiError(error));
      return;
    }
    successSnackbar(`Enrollment Command ${keyName} was deleted.`);
    setIsDeleting(false);
    dispatch(fetchTableData(ENROLLMENT_COMMANDS));
  };

  const renderListItem = (w) => (
    <ListItem key={w.enrollmentId}>
      <MachineName
        testId={`bulk-delete-${w.enrollmentId}`}
        machineName={w.enrollmentDetails.hostName}
        provider={mapProvidersToEnum(w.enrollmentDetails.provider.name)}
        instanceId={w.instanceId}
      />
    </ListItem>
  );

  const sortMachinesByProvider = (
    {enrollmentDetails: a},
    {enrollmentDetails: b}
  ) => {
    const providerA = mapProvidersToEnum(a.provider.name);
    const providerB = mapProvidersToEnum(b.provider.name);
    return providerA.localeCompare(providerB);
  };

  const pendingMachinesList = ({pendingApprovalWS, pendingApprovals}) => {
    if (
      typeof pendingApprovalWS === 'undefined' ||
      typeof pendingApprovals === 'undefined' ||
      pendingApprovals === 0
    ) {
      return null;
    }

    const longList = pendingApprovals > 5;
    let listItems = [...pendingApprovalWS]
      .sort(sortMachinesByProvider)
      .map(renderListItem);
    if (longList && !expanded) {
      listItems = listItems.slice(0, 5);
    }

    return (
      <List dense>
        {longList && !expanded ? listItems.slice(0, 5) : listItems}
        {longList && (
          <CAMButton
            onClick={() => setExpanded(!expanded)}
            textOnly
            size="small"
            buttonText={
              expanded ? 'Show Less' : `+${pendingApprovals - 5} more`
            }
            data-testid="bulk-delete-show-more"
          />
        )}
      </List>
    );
  };

  const renderDeleteIcon = () => (
    <DeleteOutlinedIcon
      data-testid={`enrollment-${command.keyName}-delete-icon`}
      id={`remove-${command.keyName}`}
      className={classes.removeIcon}
      onClick={() => handleDeleteCommand(command)}
    />
  );

  const renderCircularProgress = () => (
    <CircularProgress
      data-testid="delete-command-spinner"
      className={classes.loading}
      size={14}
    />
  );

  const renderCAMDeleteDialog = () => (
    <CAMDeleteDialog
      open={openDeleteDialog}
      onCancel={refreshDeleteDialog}
      onOk={() => {
        onDeleteCommand(commandToDelete);
      }}
      resourceName={`Enrollment Command ${commandToDelete.keyName}`}
      requireTextInputToDelete={false}
      content={
        <div>
          {messageForDeleteDialog}
          {pendingMachinesList(commandToDelete)}
        </div>
      }
    />
  );

  return (
    <Root>
      {isDeleting ? renderCircularProgress() : renderDeleteIcon()}
      {renderCAMDeleteDialog()}
    </Root>
  );
}

EnrollmentDeleteCommand.propTypes = {
  command: PropTypes.object,
};

EnrollmentDeleteCommand.defaultProps = {
  command: {},
};

export default EnrollmentDeleteCommand;
