import {Button, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

const PREFIX = 'IconTextButtonWarning';

const classes = {
  warningText: `${PREFIX}-warningText`,
  warningDiv: `${PREFIX}-warningDiv`,
  buttonMargin: `${PREFIX}-buttonMargin`,
  cursorPointer: `${PREFIX}-cursorPointer`,
  dashUnderline: `${PREFIX}-dashUnderline`,
};

const Root = styled('div')(() => ({
  [`& .${classes.warningText}`]: {
    marginLeft: 5,
    color: '#626262',
    display: 'flex',
    alignItems: 'center',
  },

  [`&.${classes.warningDiv}`]: {
    marginLeft: 14,
    display: 'flex',
  },

  [`& .${classes.buttonMargin}`]: {marginLeft: '8px'},
  [`& .${classes.cursorPointer}`]: {cursor: 'pointer'},
  [`& .${classes.dashUnderline}`]: {borderBottom: 'dashed 1px'},
}));

export default function IconTextButtonWarning({
  buttonText,
  icon,
  onClick,
  children,
  responsive,
}) {
  // const theme = useTheme();
  // const md = useMediaQuery(theme.breakpoints.only('md'));

  const {open: sidebarOpen} = useSelector((state) => state.sidebar);

  const warning = (
    <div className={classes.warningDiv} data-testid="text-warning">
      {icon}
      <Typography variant="caption" className={classes.warningText}>
        {children}
        <Button
          variant="outlined"
          size="small"
          className={classes.buttonMargin}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </Typography>
    </div>
  );

  const responsiveWarning = (
    <div
      role="button"
      className={classnames(classes.warningDiv, classes.cursorPointer)}
      data-testid="text-warning"
      onClick={onClick}
    >
      {icon}
      <Typography
        variant="caption"
        className={classnames(classes.warningText, classes.dashUnderline)}
      >
        {/* Always Show text for above 1280p */}
        <Hidden lgDown>{children}</Hidden>

        {/* from 960p to 1280p, show text only when sidebar is not opened */}
        {sidebarOpen && children}
      </Typography>
    </div>
  );

  return <Root>{responsive ? responsiveWarning : warning}</Root>;
}

IconTextButtonWarning.propTypes = {
  children: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  responsive: PropTypes.bool,
};

IconTextButtonWarning.defaultProps = {
  responsive: false,
};
