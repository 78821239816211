import PropTypes from 'prop-types';
import {linkTo} from 'utils/utils';
import {EDIT_POOL_LINK} from 'utils/constants';
import ChipDropdownSelector from 'components/CAM/display/ChipDropdownSelector/ChipDropdownSelector';

function AssociatedResourcesChipDropdownSelector({
  associatedResources,
  toggleClickable,
}) {
  const getPoolLink = (poolId) => linkTo(`${EDIT_POOL_LINK}/${poolId}`);

  const items = associatedResources.map((associatedResource) => ({
    id: associatedResource.resourceId,
    label: associatedResource.resourceName,
    link: getPoolLink(associatedResource.resourceId),
  }));

  return (
    <ChipDropdownSelector items={items} toggleClickable={toggleClickable} />
  );
}

AssociatedResourcesChipDropdownSelector.propTypes = {
  associatedResources: PropTypes.array.isRequired,
  toggleClickable: PropTypes.bool,
};

AssociatedResourcesChipDropdownSelector.defaultProps = {
  toggleClickable: false,
};

export default AssociatedResourcesChipDropdownSelector;
