import {styled} from '@mui/material/styles';
import {Button, Checkbox, Grid, Link, CircularProgress} from '@mui/material';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import useSnackbar from 'hooks/useSnackbar';
import config from 'config';
import {post} from 'api/Api';
import {fetchLatestMonitorTelemetry} from 'redux/actions/dataActions';
import {
  isLatestMonitorTelemetryValid,
  isEmpty,
  getInstallCommand,
} from 'utils/utils';
import ToggleSwitchBox from 'components/CAM/buttons/ToggleSwitchBox/ToggleSwitchBox';
import useAcceptPolicyTracking from 'hooks/useAcceptPolicyTracking';
import {COLUMN_TOOLTIP_VALIDATE_CERTIFICATE} from 'utils/constants';
import {getDownloadTokenWithFallback} from 'utils/apiUtils';
import CodeBoxWithClipboard from 'components/CAM/display/CodeBoxWithClipboard/CodeBoxWithClipboard';
import HorizontalTab from 'components/CAM/tabs/HorizontalTab/HorizontalTab';

const PREFIX = 'MonitorInstallInstructions';

const classes = {
  installInstructionsContainer: `${PREFIX}-installInstructionsContainer`,
  stepsGrid: `${PREFIX}-stepsGrid`,
  stepsGridRow: `${PREFIX}-stepsGridRow`,
  stepTitle: `${PREFIX}-stepTitle`,
  stepLine: `${PREFIX}-stepLine`,
  stepCircle: `${PREFIX}-stepCircle`,
  stepInfo: `${PREFIX}-stepInfo`,
  successBg: `${PREFIX}-successBg`,
  warningBg: `${PREFIX}-warningBg`,
  moreInfoText: `${PREFIX}-moreInfoText`,
  commandField: `${PREFIX}-commandField`,
  tokenSubtext: `${PREFIX}-tokenSubtext`,
  copyButton: `${PREFIX}-copyButton`,
  eulaContent: `${PREFIX}-eulaContent`,
  eula: `${PREFIX}-eula`,
  eulaMessage: `${PREFIX}-eulaMessage`,
  eulaButton: `${PREFIX}-eulaButton`,
  commandContent: `${PREFIX}-commandContent`,
  secureConnectionToggle: `${PREFIX}-secureConnectionToggle`,
  circularProgress: `${PREFIX}-circularProgress`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.installInstructionsContainer}`]: {
    maxWidth: '50.875rem',
  },

  [`& .${classes.stepsGrid}`]: {
    padding: '1rem 0rem',
    flexWrap: 'nowrap',
  },

  [`& .${classes.stepsGridRow}`]: {
    marginTop: '5.5px',
    overflowY: 'hidden',
  },

  [`& .${classes.stepTitle}`]: {
    color: theme.palette.primary.main,
    lineHeight: '20px',
  },

  [`& .${classes.stepLine}`]: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    minHeight: '34px',
    margin: '4.5px auto 0px',
    width: '2px',
  },

  [`& .${classes.stepCircle}`]: {
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '100%',
    boxSizing: 'border-box',
    height: '13px',
    margin: 'auto',
    width: '13px',
    marginTop: '2.7px',
  },

  [`& .${classes.stepInfo}`]: {
    paddingTop: '5px',
    paddingLeft: '16px',
  },

  [`& .${classes.successBg}`]: {
    backgroundColor: theme.palette.success.main,
  },

  [`& .${classes.warningBg}`]: {
    backgroundColor: theme.palette.warning.main,
  },

  [`& .${classes.moreInfoText}`]: {
    color: theme.palette.info.main,
    lineHeight: '15px',
  },

  [`& .${classes.commandField}`]: {
    border: '1px solid #DDDBDA',
    color: theme.palette.info.main,
    lineHeight: '24px',
    marginTop: '0px',
    height: '50px',
    maxHeight: '150px',
    minHeight: '50px',
    resize: 'vertical',
    width: '100%',
    '&:focus': {
      borderRadius: '0',
    },
  },

  [`& .${classes.tokenSubtext}`]: {
    color: theme.palette.info.main,
    fontSize: '0.625rem',
    lineHeight: '11px',
    marginTop: '3px',
  },

  [`& .${classes.copyButton}`]: {
    padding: '7px',
    height: '100%',
    align: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '0 15% 15% 0',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}; opacity: 0.8;`,
      color: theme.palette.common.white,
    },
  },

  [`&.${classes.eulaContent}`]: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderTop: `7px solid ${theme.palette.primary.main}`,
    borderRadius: '1%',
    overflow: 'hidden',
  },

  [`& .${classes.eula}`]: {
    height: '20rem',
    overflow: 'auto',
    padding: '0px',
    width: '100%',
    borderWidth: '0px',
    marginBottom: '-6px', // remove white space between eula and message
  },

  [`& .${classes.eulaMessage}`]: {
    display: 'flex',
    color: theme.palette.info.main,
    backgroundColor: '#ede8e8',
    margin: '0',
    padding: '8px',
  },

  [`& .${classes.eulaButton}`]: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '0 0 1% 1%',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}; opacity: 0.8;`,
      color: theme.palette.common.white,
    },
    '&:disabled': {
      backgroundColor: `${theme.palette.primary.main}; opacity: 0.7;`,
      color: theme.palette.common.white,
    },
  },

  [`& .${classes.commandContent}`]: {
    display: 'flex',
    width: '100%',
    borderRadius: '2% 15% 15% 2%',
  },

  [`& .${classes.secureConnectionToggle}`]: {
    marginTop: '5px',
  },

  [`& .${classes.circularProgress}`]: {
    display: 'flex',
    marginTop: '1rem',
    marginBottom: '1rem',
    marginLeft: '1rem',
  },
}));

const ACCEPT_EULA_STEP = 0;
const INSTALL_ANYWARE_MONITOR_STEP = 1;
const CONNECT_TO_MONITOR_STEP = 2;
const FINISHED_STEP = 3;

const OS_LIST = ['windows', 'linux'];

function MonitorInstallInstructions({
  selectedWorkstation,
  latestMonitorTelemetry,
  workstationServiceAccounts,
  getWorkstationServiceAccounts,
  setShowInstallInstructions,
}) {
  const dispatch = useDispatch();

  const [selectedOsIndex, setSelectedOsIndex] = useState(0);
  const [generatedTokenTime, setGeneratedTokenTime] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [userAcceptedEula, setUserAcceptedEula] = useState(false);
  const [useSecureConnection, setUseSecureConnection] = useState(true);
  const [monitorToken, setMonitorToken] = useState('');
  const [downloadToken, setDownloadToken] = useState('');
  const {errorSnackbar} = useSnackbar();
  const {currentUser, updateCurrentUser} = useAcceptPolicyTracking();
  const isTenantUser = currentUser !== null;

  const handleErrorResponse = (error) => {
    let errorMessage = '';
    if (!error) {
      errorMessage = 'Unknown error occurred.';
    } else if (error.data) {
      errorMessage = error.data.reason;
    } else {
      errorMessage = error.message;
    }

    if (!errorMessage && error.code) {
      switch (error.code) {
        case 403:
          errorMessage = 'You do not have sufficient privileges.';
          break;
        case 500:
          errorMessage = 'Internal error occurred. Please try again.';
          break;
        default:
          errorMessage = 'Unknown error occurred.';
          break;
      }
    }
    errorSnackbar(errorMessage);
  };

  const generateToken = async () => {
    const data = {
      deploymentId: selectedWorkstation.deploymentId,
      machineId: selectedWorkstation.machineId,
    };
    try {
      const resp = await post({
        path: 'auth/tokens/agent',
        data,
      });
      setMonitorToken(resp.data.token);
      // remove token after 1 hour
      setTimeout(
        () => {
          setMonitorToken('');
        },
        60 * 60 * 1000
      );
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const sendEulaAcceptance = async () => {
    const acceptanceRequestBody = {};

    if (userAcceptedEula) {
      acceptanceRequestBody.monitorEulaAccepted = userAcceptedEula;
    }

    if (!isEmpty(acceptanceRequestBody)) {
      try {
        await updateCurrentUser(acceptanceRequestBody);
      } catch (error) {
        handleErrorResponse(error);
        return;
      }
    }
    generateToken();
  };

  const isMonitorRegistered = () =>
    workstationServiceAccounts.some((key) =>
      moment(key.createdOn).isSameOrAfter(generatedTokenTime)
    );

  const stepFinished = {
    [ACCEPT_EULA_STEP]: () => !!monitorToken,
    [INSTALL_ANYWARE_MONITOR_STEP]: () => isMonitorRegistered(),
    [CONNECT_TO_MONITOR_STEP]: () =>
      isLatestMonitorTelemetryValid(latestMonitorTelemetry, generatedTokenTime),
    [FINISHED_STEP]: () => stepFinished[CONNECT_TO_MONITOR_STEP],
  };
  const isStepFinished = (index) => stepFinished[index]();

  const stepClass = (index) => {
    if (index === ACCEPT_EULA_STEP)
      return isStepFinished(ACCEPT_EULA_STEP) ? classes.successBg : '';
    return currentStep > index ? classes.successBg : classes.warningBg;
  };

  const installSteps = [
    {
      title: '1. Accept the HP Anyware End User License Agreement',
      waiting: (
        <div className={classes.eulaContent}>
          <iframe
            title="EULA"
            src="/EULA.html"
            className={classes.eula}
            data-testid="eula-iframe"
          />
          <div className={classes.eulaMessage}>
            <Checkbox
              color="primary"
              onChange={() => setUserAcceptedEula(!userAcceptedEula)}
              checked={userAcceptedEula}
              size="small"
              inputProps={{'data-testid': 'accept-eula-checkbox'}}
            />
            <p>
              I have read and accepted the terms of the HP Anyware end-user
              license agreement.
            </p>
          </div>

          <Button
            className={classes.eulaButton}
            data-testid="generate-button"
            disabled={!userAcceptedEula}
            onClick={sendEulaAcceptance}
          >
            CONTINUE
          </Button>
        </div>
      ),
      finished: 'The terms and conditions were accepted.',
    },
    {
      title: '2. Install and register the Anyware Monitor',
      waiting: (
        <>
          <div className={classes.moreInfoText}>
            Copy and run the generated command in a terminal with administrator
            privileges. This will install and register the Anyware Monitor. When
            the process is complete, we will attempt to establish a connection.
          </div>
          {config.STANDALONE && (
            <div className={classes.secureConnectionToggle}>
              <ToggleSwitchBox
                isOn={useSecureConnection}
                onClick={() => setUseSecureConnection(!useSecureConnection)}
                isSaving={false}
                disabled={false}
                displayText="Validate TLS certificate"
                tooltipText={COLUMN_TOOLTIP_VALIDATE_CERTIFICATE}
                testId="validate-tls-certificate"
              />
            </div>
          )}
          {downloadToken ? (
            <>
              <HorizontalTab
                tabs={OS_LIST}
                selectedTab={selectedOsIndex}
                setSelectedTab={setSelectedOsIndex}
              />
              <CodeBoxWithClipboard
                id="copy-to-clipboard"
                code={getInstallCommand({
                  os: OS_LIST[selectedOsIndex],
                  downloadToken,
                  useSecureConnection,
                  skipRegistration: true,
                  monitorToken,
                })}
                snackbarMessage="The Anyware Monitor update command has been copied to your clipboard. This command is only valid for 1 hour."
                tooltipText="Click to copy the install command to your clipboard."
              />
            </>
          ) : (
            <CircularProgress size={40} className={classes.circularProgress} />
          )}
          <div className={classes.tokenSubtext}>
            (command is only valid for 1 hour.)
          </div>
        </>
      ),
      finished: 'Installation and registration successful.',
    },
    {
      title: '3. Connect to the Anyware Monitor',
      waiting: 'Waiting the for the Anyware Monitor to connect...',
      finished:
        'Connection successful. You can now track and manage the users sessions.',
    },
  ];
  const lastStep = installSteps.length - 1;

  useEffect(() => {
    const getDownloadRepository = async () => {
      try {
        const token = await getDownloadTokenWithFallback(
          selectedWorkstation.deploymentId,
          config.getDownloadRepositoryName(config.getCloudsmithChannel(true))
        );
        setDownloadToken(token);
      } catch (error) {
        errorSnackbar('Failed generate the download token.');
        setDownloadToken('CHANGEME');
      }
    };

    if (isTenantUser) {
      if (
        currentUser.monitorEulaAcceptedOn &&
        currentUser.monitorEulaAcceptedOn > config.MONITOR_EULA_UPDATED_ON
      ) {
        setUserAcceptedEula(true);
        if (!monitorToken) {
          generateToken();
        }
      }
    } else {
      setUserAcceptedEula(false);
    }

    getDownloadRepository();
  }, [JSON.stringify(currentUser)]);

  useEffect(() => {
    if (currentStep === INSTALL_ANYWARE_MONITOR_STEP) {
      // Get the workstation service accounts every 10 seconds to check if the installation was successful
      getWorkstationServiceAccounts();
      const interval = setInterval(
        () => {
          getWorkstationServiceAccounts();
        },
        (Math.random() * 5 + 5) * 1000
      );
      return () => clearInterval(interval);
    }
    if (currentStep === CONNECT_TO_MONITOR_STEP) {
      // Get the latest monitor telemetry every 10 seconds to check the connection
      dispatch(fetchLatestMonitorTelemetry([selectedWorkstation.machineId]));
      const interval = setInterval(
        () => {
          dispatch(
            fetchLatestMonitorTelemetry([selectedWorkstation.machineId])
          );
        },
        (Math.random() * 5 + 5) * 1000
      );
      return () => clearInterval(interval);
    }
    return () => {};
  }, [currentStep]);

  useEffect(() => {
    if (
      currentStep === CONNECT_TO_MONITOR_STEP &&
      isStepFinished(CONNECT_TO_MONITOR_STEP)
    ) {
      setCurrentStep(FINISHED_STEP);
    }
  }, [latestMonitorTelemetry]);

  useEffect(() => {
    if (
      currentStep === INSTALL_ANYWARE_MONITOR_STEP &&
      isStepFinished(INSTALL_ANYWARE_MONITOR_STEP)
    ) {
      setCurrentStep(CONNECT_TO_MONITOR_STEP);
    }
  }, [workstationServiceAccounts]);

  useEffect(() => {
    if (currentStep === ACCEPT_EULA_STEP && isStepFinished(ACCEPT_EULA_STEP)) {
      // User clicked the generate install command
      setGeneratedTokenTime(moment().toISOString());
      setCurrentStep(INSTALL_ANYWARE_MONITOR_STEP);
    } else if (
      currentStep === INSTALL_ANYWARE_MONITOR_STEP &&
      !isStepFinished(ACCEPT_EULA_STEP)
    ) {
      // Token expired and no installation was detected
      setCurrentStep(ACCEPT_EULA_STEP);
      errorSnackbar(
        'Install command expired, please generate a new install command.'
      );
    } else if (
      currentStep > INSTALL_ANYWARE_MONITOR_STEP &&
      !isStepFinished(ACCEPT_EULA_STEP)
    ) {
      // Token expired but a successful installation was detected
      setMonitorToken('<Expired Token>');
    }
  }, [monitorToken]);

  return (
    <Root className={classes.installInstructionsContainer}>
      <Grid container direction="column" className={classes.stepsGrid}>
        {installSteps.map(
          (step, index) =>
            currentStep >= index && (
              <Grid item className={classes.stepsGridRow} key={step.title}>
                <Grid container>
                  <Grid item xs={1}>
                    {currentStep > ACCEPT_EULA_STEP && (
                      <div
                        className={classNames(
                          classes.stepCircle,
                          stepClass(index)
                        )}
                      />
                    )}
                    {index !== lastStep && currentStep > index && (
                      <div className={classes.stepLine} />
                    )}
                  </Grid>
                  <Grid item xs={10} className={classes.stepTitle}>
                    {step.supportLink ? (
                      <Link
                        href={step.supportLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {step.title}
                      </Link>
                    ) : (
                      step.title
                    )}
                    <div className={classes.stepInfo}>
                      {currentStep <= index &&
                        (typeof step.waiting === 'string' ? (
                          <div className={classes.moreInfoText}>
                            {step.waiting}
                          </div>
                        ) : (
                          step.waiting
                        ))}
                      {currentStep > index &&
                        (typeof step.finished === 'string' ? (
                          <div className={classes.moreInfoText}>
                            {step.finished}
                          </div>
                        ) : (
                          step.finished
                        ))}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )
        )}
      </Grid>
      {Object.keys(latestMonitorTelemetry).length > 0 && (
        <Grid container item xs={6}>
          <Button
            onClick={() => setShowInstallInstructions(false)}
            className={classes.button}
            variant="text"
          >
            Back
          </Button>
        </Grid>
      )}
    </Root>
  );
}

MonitorInstallInstructions.propTypes = {
  latestMonitorTelemetry: PropTypes.object.isRequired,
  workstationServiceAccounts: PropTypes.array,
  getWorkstationServiceAccounts: PropTypes.func.isRequired,
  selectedWorkstation: PropTypes.object.isRequired,
  setShowInstallInstructions: PropTypes.func,
};

MonitorInstallInstructions.defaultProps = {
  workstationServiceAccounts: [],
  setShowInstallInstructions: () => {},
};

export default MonitorInstallInstructions;
