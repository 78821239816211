import ClickAwayListener from '@mui/material/ClickAwayListener';
import {styled} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useState} from 'react';

const PREFIX = 'ArrowTooltip';

const classes = {
  arrow: `${PREFIX}-arrow`,
  popper: `${PREFIX}-popper`,
  tooltip: `${PREFIX}-tooltip`,
  tooltipPlacementLeft: `${PREFIX}-tooltipPlacementLeft`,
  tooltipPlacementRight: `${PREFIX}-tooltipPlacementRight`,
  tooltipPlacementTop: `${PREFIX}-tooltipPlacementTop`,
  tooltipPlacementBottom: `${PREFIX}-tooltipPlacementBottom`,
  flex: `${PREFIX}-flex`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.arrow}`]: {
    position: 'absolute',
    fontSize: 6,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
    tooltip: {
      fontSize: 300,
    },
  },

  [`& .${classes.popper}`]: arrowGenerator(theme.palette.primary.main),

  [`& .${classes.tooltip}`]: {
    position: 'relative',
    borderStyle: 'solid',
    borderWidth: '1px',
    fontSize: 14,
    maxWidth: 300,
  },

  [`& .${classes.tooltipPlacementLeft}`]: {
    margin: '0 8px',
  },

  [`& .${classes.tooltipPlacementRight}`]: {
    margin: '0 8px',
  },

  [`& .${classes.tooltipPlacementTop}`]: {
    margin: '8px 0',
  },

  [`& .${classes.tooltipPlacementBottom}`]: {
    margin: '8px 0',
  },

  [`& .${classes.flex}`]: {display: 'flex'},
}));

const arrowGenerator = (color) => ({
  '&[x-placement*="bottom"] $arrow': {
    top: 0,
    left: 0,
    marginTop: '-0.95em',
    width: '2em',
    height: '1em',
    '&::before': {
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent ${color} transparent`,
    },
  },
  '&[x-placement*="top"] $arrow': {
    bottom: 0,
    left: 0,
    marginBottom: '-0.95em',
    width: '2em',
    height: '1em',
    '&::before': {
      borderWidth: '1em 1em 0 1em',
      borderColor: `${color} transparent transparent transparent`,
    },
  },
  '&[x-placement*="right"] $arrow': {
    left: 0,
    marginLeft: '-0.95em',
    height: '2em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 1em 1em 0',
      borderColor: `transparent ${color} transparent transparent`,
    },
  },
  '&[x-placement*="left"] $arrow': {
    right: 0,
    marginRight: '-0.95em',
    height: '2em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 0 1em 1em',
      borderColor: `transparent transparent transparent ${color}`,
    },
  },
});

function ArrowTooltip(props) {
  const {title, onClose, overrides, enterDelay} = props;

  const [arrowRef, setArrowRef] = useState(null);

  return (
    <Root>
      <ClickAwayListener onClickAway={onClose}>
        <div
        // TODO react18
        // Commented out as this was undefined when migrating to react18 and causing errors.
        // className={flexClass}
        >
          <Tooltip
            enterDelay={enterDelay}
            classes={{
              ...classes,
              tooltip: classNames(classes.tooltip, overrides.tooltip),
            }}
            interactive="true"
            PopperProps={{
              disablePortal: true,
              popperOptions: {
                modifiers: {
                  arrow: {
                    enabled: Boolean(arrowRef),
                    element: arrowRef,
                  },
                },
              },
            }}
            {...props}
            title={
              <>
                {title}
                <span
                  // TODO react18
                  // Commented out as this was undefined when migrating to react18 and causing errors.
                  // className={arrow}
                  ref={setArrowRef}
                />
              </>
            }
          />
        </div>
      </ClickAwayListener>
    </Root>
  );
}

ArrowTooltip.propTypes = {
  title: PropTypes.node,
  fontWeight: PropTypes.string,
  onClose: PropTypes.func,
  overrides: PropTypes.object,
};

ArrowTooltip.defaultProps = {
  title: {},
  fontWeight: '10',
  onClose: () => {},
  overrides: {},
};

export default ArrowTooltip;
