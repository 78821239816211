import {IconButton} from '@mui/material';
import KebabIcon from '@mui/icons-material/MoreVert';

import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import CAMDeleteDialog from 'components/CAM/dialogs/CAMDeleteDialog/CAMDeleteDialog';
import {deleteDeployment, saveVariable} from 'redux/actions/dataActions';
import {
  CREATE_DEPLOYMENT_LINK,
  DEPLOYMENT_DELETE_CONFIRMATION_MESSAGE,
} from 'utils/constants';
import {selectVariable} from 'utils/reduxSelectors';
import {isEmpty, isItemActive} from 'utils/utils';
import CAMMenu from '../menu/Menu';

function DeploymentKebab() {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const selectedDeployment = useSelector(
    (state) => state.data.dataByResource.selectedDeployment || {}
  );

  const deleteFromProvider = useSelector((state) =>
    selectVariable(state, 'deleteFromProvider')
  );

  const deploymentName = selectedDeployment
    ? selectedDeployment.deploymentName || selectedDeployment.deploymentId
    : '';

  const isActive = isItemActive(selectedDeployment);
  const menuWidth = '13em';

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(saveVariable('deleteFromProvider', false));
  };

  const handleClose = () => setAnchorEl(null);

  const createClick = () => {
    handleClose();
  };

  const handleEditLinkClick = (e) => {
    if (!isItemActive(selectedDeployment)) {
      e.preventDefault();
    } else {
      handleClose();
    }
  };

  const deleteClick = () => {
    handleClose();
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteDeployment = () => {
    setDeleteConfirmationOpen(false);
    handleClose();
    dispatch(deleteDeployment({...selectedDeployment, deleteFromProvider}));
  };

  return <>
    <IconButton
      id="deployment-kebab-icon"
      data-testid="deployment-kebab"
      aria-owns="deployment-menu"
      aria-haspopup="true"
      onClick={handleOpen}
      size="large">
      <KebabIcon />
    </IconButton>
    <CAMMenu
      id="deployment-menu"
      testId="deployment-kebab-menu"
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <Link to={`${CREATE_DEPLOYMENT_LINK}`}>
        <KebabMenuItem
          enableClick
          menuText="Create deployment"
          onClick={createClick}
          width={menuWidth}
        />
      </Link>
      <Link
        to={`/app/deployments/edit/${selectedDeployment.deploymentId}`}
        onClick={handleEditLinkClick}
      >
        <KebabMenuItem
          enableClick={!isEmpty(selectedDeployment) && isActive}
          menuText="Edit deployment"
          width={menuWidth}
        />
      </Link>
      <KebabMenuItem
        enableClick={!isEmpty(selectedDeployment) && isActive}
        menuText="Delete deployment"
        onClick={deleteClick}
        width={menuWidth}
      />
    </CAMMenu>

    <CAMDeleteDialog
      requireTextInputToDelete
      resourceName={deploymentName}
      open={deleteConfirmationOpen}
      onOk={handleDeleteDeployment}
      onCancel={() => setDeleteConfirmationOpen(false)}
      content={<>{DEPLOYMENT_DELETE_CONFIRMATION_MESSAGE}</>}
    />
  </>;
}

export default DeploymentKebab;
