import Card from '@mui/material/Card';
import {styled} from '@mui/material/styles';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import {SnackbarContent, useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {forwardRef, useCallback} from 'react';
import {useSelector} from 'react-redux';
import FeedbackIcon from 'components/CAM/icons/FeedbackIcon/FeedbackIcon';
import {theme} from 'themes/default';

const PREFIX = 'Snackbar';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  typography: `${PREFIX}-typography`,
  actionRoot: `${PREFIX}-actionRoot`,
  closeIcon: `${PREFIX}-closeIcon`,
};

const StyledSnackbar = styled(SnackbarContent)(({variant, open}) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      minWidth: open ? '344px' : 'auto',
    },
  },

  [`& .${classes.card}`]: {
    backgroundColor: theme.palette[variant].main,
    width: '100%',
  },

  [`& .${classes.typography}`]: {
    fontWeight: 'bold',
    color: theme.palette.getContrastText(theme.palette[variant].main),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },

  [`& .${classes.actionRoot}`]: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },

  [`& .${classes.closeIcon}`]: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.getContrastText(theme.palette[variant].main),
  },
}));

const Snackbar = forwardRef(({message, variant, keyId, onClose}, ref) => {
  const open = useSelector((state) => state.sidebar.open);

  return (
    <StyledSnackbar
      ref={ref}
      key={keyId}
      className={classes.root}
      variant={variant}
      open={open}
    >
      <Card className={classes.card}>
        <CardActions classes={{root: classes.actionRoot}}>
          <FeedbackIcon variant={variant} />
          <Typography variant="subtitle2" className={classes.typography}>
            {message}
          </Typography>
          <IconButton
            className={classes.closeIcon}
            onClick={() => {
              onClose();
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </CardActions>
      </Card>
    </StyledSnackbar>
  );
});

Snackbar.propTypes = {
  keyId: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'error']).isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Snackbar;
