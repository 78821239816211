import TextField from '@mui/material/TextField';

function TextInput(props) {
  return (
    <TextField
      autoComplete="off"
      required
      margin="normal"
      variant="outlined"
      size="small"
      fullWidth
      {...props}
    />
  );
}

export default TextInput;
