import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {capitalize} from 'helpers/core';
import CAMTable from 'components/table/Table';
import {
  fetchCloudServiceAccounts,
  fetchConnectorSettings,
} from 'redux/actions/dataActions';
import {
  CONNECTORS,
  CONNECTOR_OVERVIEW_TAB_STRING,
  CONNECTOR_SETTINGS,
  DEPLOYMENTS,
  AUTH_CAPABILITY_NAME,
  MFA_CAPABILITY_NAME,
  FED_AUTH_CAPABILITY_NAME,
  AD_CONFIG_CAPABILITY_NAME,
} from 'utils/constants';
import {selectDataForTable, selectResourceItem} from 'utils/reduxSelectors';
import {isEmpty, isItemActive, connectorIsCACv2} from 'utils/utils';

import DeleteConnectorsDialog from 'components/connectors/DeleteConnectorsDialog';
import PageHeader from 'components/CAM/layout/PageHeader/PageHeader';
import AddConnectorButton from 'components/connectors/table/AddConnectorButton';
import config from 'config';
import {getEditConnectorTabLink} from 'utils/Mappings';
import ConnectorOverview from './ConnectorOverview';

const PREFIX = 'Connectors';

const classes = {
  connectorDetails: `${PREFIX}-connectorDetails`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.connectorDetails}`]: {
    width: '75%',
    minWidth: '1150px',
    margin: '0 auto',
  },
}));

export function UnwrappedConnectors({
  dispatch,
  connectors,
  deployments,
  isFetching,
  selectedDeployment,
  total,
}) {
  const {item: connectorSettings} = useSelector((state) =>
    selectResourceItem(
      state,
      CONNECTOR_SETTINGS,
      selectedDeployment.deploymentId
    )
  );

  useEffect(() => {
    if (!isEmpty(selectedDeployment)) {
      // Get provider service accounts so kebab menu can check if create RW access is allowed
      dispatch(
        fetchCloudServiceAccounts({
          deploymentId: selectedDeployment.deploymentId,
        })
      );
      // get deployment settings to compare auth setting and display warning if they don't match
      dispatch(fetchConnectorSettings());
    }
  }, [selectedDeployment]);

  const getEditLink = (connector) => {
    const {connectorId} = connector;
    if (isItemActive(connector)) {
      return config.isConnectorWizardEnabled()
        ? getEditConnectorTabLink(CONNECTOR_OVERVIEW_TAB_STRING, connectorId)
        : `/app/connectors/edit/${connectorId}`;
    }
    return '';
  };

  /**
   * Compare the connector setting that is used by the connector with the deployment setting.
   * Note: Here, the connector setting refers the actual setting used by the connector, and not the setting that is stored in Anyware Manager (/setting/connectors/connector_id).
   * Return false if setting is not defined in manager or it is not reported by the connector.
   * Return true if the setting does not match.
   */
  const settingIsDifferentInDeployment = (
    connectorSetting,
    deploymentSetting
  ) => {
    // if deployment setting is undefined, then it can't compare
    if (!deploymentSetting && deploymentSetting !== false) {
      return false;
    }

    // if connector setting is undefined, then it can't compare
    if (typeof connectorSetting === 'undefined') {
      return false;
    }

    if (deploymentSetting === connectorSetting) {
      return false;
    }
    return true;
  };

  const getConnectorWarning = (connector) => {
    let msg = '';

    if (
      settingIsDifferentInDeployment(
        connector.components?.oauth?.enableOauth,
        connectorSettings?.deployment?.oauth?.enabled
      )
    ) {
      msg += '\n- Enable OAuth';
    } else if (connector.components?.oauth?.enableOauth === true) {
      // if oauth is enabled on both side, check the rest of the oauth config
      if (
        settingIsDifferentInDeployment(
          connector.components?.oauth?.idProviderUrl,
          connectorSettings?.deployment?.oauth?.idProviderUrl
        )
      ) {
        msg += '\n- OAuth Authorization URL';
      }
      if (
        settingIsDifferentInDeployment(
          connector.components?.oauth?.idpAppClientId,
          connectorSettings?.deployment?.oauth?.idpAppClientId
        )
      ) {
        msg += '\n- OAuth Client ID';
      }
      if (
        settingIsDifferentInDeployment(
          connector.components?.oauth?.enableSSO,
          connectorSettings?.deployment?.oauth?.enableSSO
        )
      ) {
        msg += '\n- Enable SSO';
      }
    }

    if (
      settingIsDifferentInDeployment(
        connector.components?.enableEntitlementsByUpn,
        connectorSettings?.deployment?.enableEntitlementsByUpn
      )
    ) {
      msg += '\n- Enable entitlements By UPN';
    }

    if (msg.length > 0) {
      return `The following settings are different from what are defined in the deployment,\
      please ensure the following settings are correct or update the connector to fetch the settings.${msg}`;
    }
    return false;
  };

  const getAuthenticationMethodsCACv2 = (components) => {
    // For CACv2 Authentication is in health components :(
    if (typeof components.oauth?.enableOauth !== 'undefined') {
      if (components.oauth?.enableOauth) {
        return 'Federated Authentication';
      }
    }
    return 'Not Available';
  };

  const getAuthenticationMethodsAWC = (capabilities) => {
    let authentication = 'Not Available';
    let authEnabled = false;
    let mfaEnabled = false;
    let fedAuthEnabled = false;
    let adEnabled = false;

    // Check which authentication methods are enabled
    capabilities.forEach((capability) => {
      if (capability.capabilityName === AUTH_CAPABILITY_NAME) {
        authEnabled = capability.enabled;
      }
      if (capability.capabilityName === MFA_CAPABILITY_NAME) {
        mfaEnabled = capability.enabled;
      }
      if (capability.capabilityName === FED_AUTH_CAPABILITY_NAME) {
        fedAuthEnabled = capability.enabled;
      }
      if (capability.capabilityName === AD_CONFIG_CAPABILITY_NAME) {
        adEnabled = capability.enabled;
      }
    });

    if (authEnabled) {
      authentication = '';
      if (fedAuthEnabled) {
        authentication = 'Federated Authentication';
      }
      if (adEnabled) {
        if (authentication.length > 0) {
          authentication += ' and ';
        }
        authentication += 'Active Directory';
        if (mfaEnabled) {
          authentication += ' with RADIUS Multi-Factor Authentication';
        }
      }
    }

    return authentication;
  };
  const prepareData = () =>
    connectors
      .map((connector) => {
        const {deploymentId} = connector;
        const deployment = deployments[deploymentId];
        const deploymentName =
          deployment && deployment.deploymentName
            ? deployment.deploymentName
            : deploymentId;

        const components = connector.components || {};
        const capabilities = connector.capabilities || [];

        const version =
          components.version || components.cacVersion || 'Not Available';

        const connectorData = {
          link: getEditLink(connector),
          ...connector,
          connectorName: connector.connectorName
            ? connector.connectorName
            : connector.connectorId,
          internalIp: components.internalIp
            ? components.internalIp
            : 'Not Available',
          externalIp: components.externalIp
            ? components.externalIp
            : 'Not Available',
          version,
          warning: getConnectorWarning(connector),
          deployment: deploymentName,
          displayStatus: capitalize(connector.status || 'active'),
          displayAuthentication: connectorIsCACv2(version)
            ? getAuthenticationMethodsCACv2(components)
            : getAuthenticationMethodsAWC(capabilities),
        };

        return connectorData;
      })
      .filter((connector) => {
        if (isEmpty(selectedDeployment)) {
          return true;
        }
        return selectedDeployment.deploymentId === connector.deploymentId;
      });

  const renderConnectorDetails = (connector) => (
    <div className={classes.connectorDetails}>
      <ConnectorOverview connector={connector} />
    </div>
  );

  return (
    <Root>
      {config.isConnectorWizardEnabled() && (
        <PageHeader
          titleText="Connectors"
          descriptionText="Add, manage, and monitor connectors in this section."
          actionButton={<AddConnectorButton />}
        />
      )}
      {
        // TODO: Add an add connector/generate token button here, if the connectors data is empty
      }
      <CAMTable
        id="connectors-table"
        hideTitle={config.isConnectorWizardEnabled()}
        tableTitle="Connectors"
        resource={CONNECTORS}
        idField="connectorId"
        data={prepareData(connectors)}
        total={total}
        loadingData={isFetching}
        useExpansionTable={!config.isConnectorWizardEnabled()}
        expansionTableDrawer={renderConnectorDetails}
      />
      <DeleteConnectorsDialog />
    </Root>
  );
}

UnwrappedConnectors.propTypes = {
  dispatch: PropTypes.func.isRequired,
  connectors: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  selectedDeployment: PropTypes.object.isRequired,
  deployments: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {dataByResource} = state.data;

  const {
    isFetching,
    lastUpdated,
    data: connectors,
    total,
  } = selectDataForTable(state, CONNECTORS);

  const selectedDeployment = dataByResource.selectedDeployment || {};

  const {data: deployments} = dataByResource[DEPLOYMENTS] || {data: {}};

  return {
    connectors,
    isFetching,
    lastUpdated,
    total,
    selectedDeployment,
    deployments,
  };
}

export default connect(mapStateToProps)(UnwrappedConnectors);
