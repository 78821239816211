import {
  CLOSE_ERROR_DIALOG,
  CLOSE_WARNING_DIALOG,
  OPEN_ERROR_DIALOG,
  OPEN_WARNING_DIALOG,
} from 'redux/actions/errorDialogActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';

const defaultState = {
  open: false,
  title: '',
  message: '',
  status: '',
};

function confirmationDialog(state = defaultState, action) {
  switch (action.type) {
    case OPEN_ERROR_DIALOG:
    case OPEN_WARNING_DIALOG:
      return {
        title: action.title,
        message: action.message,
        open: true,
        status: action.status,
      };
    case CLEAR_STORE:
    case CLOSE_ERROR_DIALOG:
    case CLOSE_WARNING_DIALOG:
      return defaultState;
    default:
      return state;
  }
}

export default confirmationDialog;
