import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {useDispatch} from 'react-redux';
import {openWorkstationDialogError} from 'redux/actions/remoteWorkstationErrorDialogActions';
import {getWorkstationErrorSnippet} from '../RemoteWorkstationErrorDialog';
import {mapErrorArrayToIcon} from '../utils';

const PREFIX = 'StatusIconWorkstation';

const classes = {
  errorIcon: `${PREFIX}-errorIcon`,
  statusErrorIcon: `${PREFIX}-statusErrorIcon`,
  statusOkIcon: `${PREFIX}-statusOkIcon`,
};

const StyledTooltip = styled(Tooltip)({
  [`&.${classes.errorIcon}`]: {
    color: '#C23934',
  },
  [`&.${classes.statusErrorIcon}`]: {
    color: '#C23934',
    cursor: 'pointer',
  },
  [`&.${classes.statusOkIcon}`]: {
    color: '#04844B',
  },
});

function StatusIconWorkstation({workstation, testIdPrefix}) {
  const dispatch = useDispatch();

  const handleErrorClick = () => {
    dispatch(openWorkstationDialogError(workstation));
  };

  let dataTestId;
  let className;
  let tooltipMessage;
  let onClick;
  const detailedErrorArray = workstation.detailedError;
  if (detailedErrorArray) {
    dataTestId = `${testIdPrefix}-error`;
    className = classes.statusErrorIcon;
    tooltipMessage = getWorkstationErrorSnippet(workstation);
    onClick = handleErrorClick;
  } else {
    dataTestId = `${testIdPrefix}-ok`;
    className = classes.statusOkIcon;
    tooltipMessage = 'Workstation OK';
    onClick = () => {};
  }

  const Icon = mapErrorArrayToIcon(detailedErrorArray);

  return (
    <StyledTooltip placement="top" title={tooltipMessage}>
      <IconButton
        size="small"
        data-testid={dataTestId}
        className={className}
        onClick={onClick}
      >
        <Icon />
      </IconButton>
    </StyledTooltip>
  );
}

StatusIconWorkstation.propTypes = {
  workstation: PropTypes.shape({
    detailedError: PropTypes.arrayOf(
      PropTypes.shape({
        errorType: PropTypes.string,
        message: PropTypes.string,
      })
    ),
  }).isRequired,
  testIdPrefix: PropTypes.string,
};

StatusIconWorkstation.defaultProps = {
  testIdPrefix: 'workstations-status-icon',
};

export default StatusIconWorkstation;
