import {useDispatch, useSelector} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import CamServiceAccount from 'components/CAMServiceAccount/CAMServiceAccount';
import AccountInformation from 'components/accountInformation/AccountInformation';
import ActivityLog from 'components/activityLog/ActivityLog';
import AdUserView from 'components/adUsers/AdUserView';
import AdUsers from 'components/adUsers/AdUsers';
import Connectors from 'components/connectors/Connectors';
import CreateConnector from 'components/connectors/CreateConnector';
import EditConnector from 'components/connectors/EditConnector';
import WizardConfigureConnector from 'components/connectors/createWizard/WizardConfigureConnector';
import WizardGettingStarted from 'components/connectors/createWizard/WizardGettingStarted';
import CacV2GettingStarted from 'components/connectors/createWizard/CacV2GettingStarted';
import ConnectorTypeSelection from 'components/connectors/createWizard/ConnectorTypeSelection';
import ConnectorPanel from 'components/connectors/edit/ConnectorPanel';
import DefaultPage from 'components/defaultPage/DefaultPage';
import AddDeploymentSAPage from 'components/deployments/AddDeploymentSAPage';
import CreateDeployment from 'components/deployments/CreateDeployment';
import EditDeployment from 'components/deployments/EditDeployment';
import Installers from 'components/installers/Installers';
import MonitorEnrollments from 'components/monitorEnrollments/MonitorEnrollments';
import CreatePool from 'components/pools/CreatePool';
import EditPool from 'components/pools/EditPool';
import Pools from 'components/pools/Pools';
import AddSamlConfigPage from 'components/preferences/AddSamlConfigPage';
import DateTimeTab from 'components/preferences/DateTimeTab';
import MultiAdminTab from 'components/preferences/MultiAdminSettingsTab';
import Licenses from 'components/licenses/Licenses';
import ReleaseNotesPage from 'components/releaseNotes/ReleaseNotesPage';
import RemoteWorkstations from 'components/remoteWorkstations/RemoteWorkstations';
import AddExistingRemoteWorkstation from 'components/remoteWorkstations/add/AddExistingRemoteWorkstation';
import EditRemoteWorkstation from 'components/remoteWorkstations/edit/EditRemoteWorkstation';
import AdConfiguration from 'components/standalone/AdConfiguration';
import config from 'config';
import useDialog from 'hooks/useDialog';
import {saveVariable} from 'redux/actions/dataActions';
import {
  ACCOUNT_INFORMATION_LINK,
  ACTIVITY_LOG_LINK,
  ADD_REMOTE_WORKSTATION_LINK,
  ADD_SAML_CONFIG_LINK,
  AD_CONFIG,
  AWM_SERVICE_ACCOUNT_LINK,
  CONNECTOR_SELECT_TYPE_LINK,
  CONNECTOR_WIZARD_CONFIGURE_LINK,
  CONNECTOR_WIZARD_CONFIGURE_CAC_LINK,
  CONNECTOR_WIZARD_GETTING_STARTED_LINK,
  CREATE_CONNECTOR_LINK,
  CREATE_DEPLOYMENT_LINK,
  CREATE_POOL_LINK,
  DASHBOARD_LINK,
  DATE_TIME,
  EDIT_CONNECTOR_LINK,
  EDIT_POOL_LINK,
  EDIT_REMOTE_WORKSTATION_LINK,
  INSTALLERS_LINK,
  MULTI_ADMIN_LINK,
  PENDING_MONITOR_PROVISIONS_LINK,
  POOLS_LINK,
  RELEASE_NOTES_LINK,
  SESSION_HISTORY_LINK,
  UNSAVED_DATA_DIALOG_MESSAGE,
  UNSAVED_DATA_DIALOG_TITLE,
  USER_PREFERENCES_LINK,
  PROVISION_CONNECTOR_LINK,
  USER_LICENSES_LINK,
  EDIT_WEBHOOK_LINK,
  CREATE_WEBHOOK_LINK,
  WEBHOOKS_LINK,
  DEPLOYMENT_SERVICE_ACCOUNTS_LINK,
  CREATE_DEPLOYMENT_SERVICE_ACCOUNTS_LINK,
} from 'utils/constants';
import {LINK_EDIT_DEPLOYMENT} from 'utils/paths';
import {selectVariable} from 'utils/reduxSelectors';
import Sessions from 'components/sessions/Sessions';
import Webhooks from 'components/webhooks/Webhooks';
import DeploymentServiceAccountPage from 'components/deploymentServiceAccount/deploymentServiceAccountPage';
import {EditWebhook} from 'components/webhooks/EditWebhook';
import {CreateWebhook} from 'components/webhooks/CreateWebhook';
import WelcomeCard from './WelcomeCard';

function MainPane(props) {
  const pendingChanges = useSelector((state) =>
    selectVariable(state, 'pendingChanges')
  );
  const {open} = useSelector((state) => state.confirmationDialog);
  const dispatch = useDispatch();
  const {triggerDialog} = useDialog();

  // fix an odd behavior where using fragments to group the routes below under a single parent
  // causes other routes to not render properly
  const webhooksRoutes = config.isWebhooksEnabled()
    ? [
        <Route
          key="webhooks"
          path={WEBHOOKS_LINK}
          render={(routeProps) => <Webhooks {...routeProps} {...props} />}
          exact
        />,
        <Route
          key="editWebhook"
          path={`${EDIT_WEBHOOK_LINK}/:webhookId`}
          render={(routeProps) => <EditWebhook {...routeProps} {...props} />}
          exact
        />,
        <Route
          key="createWebhook"
          path={CREATE_WEBHOOK_LINK}
          render={(routeProps) => <CreateWebhook {...routeProps} {...props} />}
          exact
        />,
      ]
    : [];

  return (
    <>
      <NavigationPrompt when={pendingChanges === true}>
        {({onConfirm, onCancel}) => {
          if (!open) {
            triggerDialog({
              title: UNSAVED_DATA_DIALOG_TITLE,
              message: UNSAVED_DATA_DIALOG_MESSAGE,
              onConfirm: () => {
                dispatch(saveVariable('pendingChanges', false));
                onConfirm();
              },
              cancelEnabled: true,
              onCancel,
            });
          }
        }}
      </NavigationPrompt>

      <Switch>
        <Route
          path="/app"
          render={(routeProps) => <WelcomeCard {...routeProps} {...props} />}
          exact
        />
        <Route
          path={DASHBOARD_LINK}
          render={(routeProps) => <WelcomeCard {...routeProps} {...props} />}
          exact
        />
        <Route
          path={ADD_REMOTE_WORKSTATION_LINK}
          render={(routeProps) => (
            <AddExistingRemoteWorkstation {...routeProps} {...props} />
          )}
          exact
        />
        <Route
          path={PENDING_MONITOR_PROVISIONS_LINK}
          render={(routeProps) => (
            <MonitorEnrollments {...routeProps} {...props} />
          )}
          exact
        />
        <Route
          path={CREATE_DEPLOYMENT_LINK}
          render={(routeProps) => (
            <CreateDeployment {...routeProps} {...props} />
          )}
          exact
        />
        {config.isRBACSupported() && (
          <Route
            path={CREATE_DEPLOYMENT_SERVICE_ACCOUNTS_LINK}
            render={(routeProps) => (
              <AddDeploymentSAPage {...routeProps} {...props} />
            )}
            exact
          />
        )}
        <Route
          path={LINK_EDIT_DEPLOYMENT}
          render={(routeProps) => <EditDeployment {...routeProps} {...props} />}
          exact
        />
        {config.isRBACSupported() && (
          <Route
            path={DEPLOYMENT_SERVICE_ACCOUNTS_LINK}
            render={(routeProps) => (
              <DeploymentServiceAccountPage {...routeProps} {...props} />
            )}
            exact
          />
        )}

        <Route
          path="/app/remoteWorkstations"
          render={(routeProps) => (
            <RemoteWorkstations {...routeProps} {...props} />
          )}
          exact
        />
        <Route
          path={`${EDIT_REMOTE_WORKSTATION_LINK}/:machineId/:tab?`}
          render={(routeProps) => (
            <EditRemoteWorkstation {...routeProps} {...props} />
          )}
          exact
        />
        <Route
          path={EDIT_CONNECTOR_LINK}
          render={(routeProps) =>
            config.isConnectorWizardEnabled() ? (
              <ConnectorPanel {...routeProps} {...props} />
            ) : (
              <EditConnector {...routeProps} {...props} />
            )
          }
        />
        <Route
          path="/app/connectors"
          render={(routeProps) => <Connectors {...routeProps} {...props} />}
          exact
        />
        <Route
          path="/app/adUsers"
          render={(routeProps) => <AdUsers {...routeProps} {...props} />}
          exact
        />
        <Route
          path="/app/adUsers/view/:userGuid/:deploymentId"
          render={(routeProps) => <AdUserView {...routeProps} {...props} />}
          exact
        />
        <Route
          exact={false}
          path={`${RELEASE_NOTES_LINK}/:releaseNoteType?`}
          render={(routeProps) => (
            <ReleaseNotesPage {...routeProps} {...props} />
          )}
        />
        <Route
          path={`${USER_PREFERENCES_LINK}/${DATE_TIME}`}
          render={(routeProps) => <DateTimeTab {...routeProps} {...props} />}
        />
        {config.multiSamlSupported() && (
          <Route
            path={ADD_SAML_CONFIG_LINK}
            render={(routeProps) => (
              <AddSamlConfigPage {...routeProps} {...props} />
            )}
          />
        )}
        <Route
          path={MULTI_ADMIN_LINK}
          render={(routeProps) => <MultiAdminTab {...routeProps} {...props} />}
        />
        <Route
          path={`${USER_PREFERENCES_LINK}/${AD_CONFIG}`}
          render={(routeProps) => (
            <AdConfiguration {...routeProps} {...props} />
          )}
        />
        <Route
          path={ACCOUNT_INFORMATION_LINK}
          render={(routeProps) => (
            <AccountInformation {...routeProps} {...props} />
          )}
          exact
        />
        <Route
          path={ACTIVITY_LOG_LINK}
          render={(routeProps) => <ActivityLog {...routeProps} {...props} />}
        />
        <Route
          path={POOLS_LINK}
          render={(routeProps) => <Pools {...routeProps} {...props} />}
          exact
        />
        <Route
          path={CREATE_POOL_LINK}
          render={(routeProps) => <CreatePool {...routeProps} {...props} />}
          exact
        />
        <Route
          path={`${EDIT_POOL_LINK}/:poolId`}
          render={(routeProps) => <EditPool {...routeProps} {...props} />}
          exact
        />
        {webhooksRoutes}
        <Route
          path={AWM_SERVICE_ACCOUNT_LINK}
          render={(routeProps) => (
            <CamServiceAccount {...routeProps} {...props} />
          )}
        />
        {config.isBeta() && config.isLicensingEnabled() && (
          <Route
            path={USER_LICENSES_LINK}
            render={(routeProps) => <Licenses {...routeProps} {...props} />}
            exact
          />
        )}
        {config.STANDALONE && (
          <Route
            path={INSTALLERS_LINK}
            render={(routeProps) => <Installers {...routeProps} {...props} />}
            exact
          />
        )}
        {config.isSessionAuditEnabled() && (
          <Route
            path={SESSION_HISTORY_LINK}
            render={(routeProps) => <Sessions {...routeProps} {...props} />}
            exact
          />
        )}

        <Route
          path={CREATE_CONNECTOR_LINK}
          render={(routeProps) => {
            if (config.isConnectorWizardEnabled()) {
              return <WizardGettingStarted {...routeProps} {...props} />;
            }
            return <CreateConnector {...routeProps} {...props} />;
          }}
          exact
        />
        {
          // Create Connector Wizard and duplicate connector routes
          config.isConnectorWizardEnabled() ? (
            <>
              <Route
                path={CONNECTOR_SELECT_TYPE_LINK}
                render={(routeProps) => (
                  <ConnectorTypeSelection {...routeProps} {...props} />
                )}
                exact
              />
              <Route
                path={CONNECTOR_WIZARD_GETTING_STARTED_LINK}
                render={(routeProps) => (
                  <WizardGettingStarted {...routeProps} {...props} />
                )}
                exact
              />
              <Route
                path={CONNECTOR_WIZARD_CONFIGURE_LINK}
                render={(routeProps) => (
                  <WizardConfigureConnector {...routeProps} {...props} />
                )}
                exact
              />
              <Route
                path={CONNECTOR_WIZARD_CONFIGURE_CAC_LINK}
                render={(routeProps) => (
                  <CacV2GettingStarted {...routeProps} {...props} />
                )}
                exact
              />
            </>
          ) : (
            <Route
              path={CREATE_CONNECTOR_LINK}
              render={(routeProps) => (
                <CreateConnector {...routeProps} {...props} />
              )}
              exact
            />
          )
        }
        <Route path="*" component={DefaultPage} />
      </Switch>
    </>
  );
}

export default MainPane;
