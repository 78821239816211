import {combineReducers} from 'redux';
import {
  CLEAR_RESOURCE,
  RECEIVE_DELETE_RESOURCE,
  RECEIVE_RESOURCE,
  REQUEST_DELETE_RESOURCE,
  REQUEST_RESOURCE,
  SAVE_VARIABLE,
  UPDATE_POOL_WORKSTATION,
  UPDATE_WORKSTATION,
} from 'redux/actions/dataActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';
import {RETAIN_MACHINE_ERRORS} from 'redux/actions/tableDataActions';
import {REMOTE_WORKSTATIONS} from 'utils/constants';
import {mapResourceToIdField} from 'utils/Mappings';
import {convertArrayToObject} from 'utils/utils';

const defaultState = {};

// replace old data with new data,
// but keep the error field of the old data intact
function retainErrors(oldData, newData) {
  const result = {...oldData};
  newData.forEach((newMach) => {
    result[newMach.machineId] = {
      ...newMach,
      error: result[newMach.machineId]
        ? result[newMach.machineId].error
        : newMach.error,
    };
  });
  return result;
}

function data(
  state = {
    isFetching: false,
    data: {},
  },
  action
) {
  switch (action.type) {
    case CLEAR_RESOURCE:
      return {
        ...state,
        isFetching: false,
        data: {},
      };
    case REQUEST_RESOURCE:
      return {...state, isFetching: true};
    case RECEIVE_RESOURCE:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          ...convertArrayToObject(
            action.data,
            mapResourceToIdField(action.resource)
          ),
        },
        lastUpdated: action.receivedAt,
      };
    case RECEIVE_DELETE_RESOURCE: {
      const {[action.id]: omitted, ...newData} = state.data;
      return {
        ...state,
        data: newData,
      };
    }
    default:
      return state;
  }
}

function updateRemoteWorkstations(remoteWorkstations = {}, updatedMachine) {
  if (remoteWorkstations[updatedMachine.machineId]) {
    return {
      ...remoteWorkstations,
      [updatedMachine.machineId]: {
        ...updatedMachine,
        entitlements: remoteWorkstations[updatedMachine.machineId].entitlements,
      },
    };
  }
  return {
    ...remoteWorkstations,
    [updatedMachine.machineId]: updatedMachine,
  };
}

function dataByResource(state = defaultState, action) {
  switch (action.type) {
    case RECEIVE_RESOURCE:
    case REQUEST_RESOURCE:
    case CLEAR_RESOURCE:
    case REQUEST_DELETE_RESOURCE:
    case RECEIVE_DELETE_RESOURCE:
      return {
        ...state,
        [action.resource]: data(state[action.resource], action),
      };
    case SAVE_VARIABLE:
      return {...state, [action.name]: action.value};
    case RETAIN_MACHINE_ERRORS:
      return {
        ...state,
        [REMOTE_WORKSTATIONS]: {
          ...state[REMOTE_WORKSTATIONS],
          isFetching: false,
          data: retainErrors(state[REMOTE_WORKSTATIONS].data, action.data),
        },
      };
    case UPDATE_WORKSTATION:
      return {
        ...state,
        remoteWorkstations: {
          ...state.remoteWorkstations,
          data: updateRemoteWorkstations(
            state.remoteWorkstations.data,
            action.updatedMachine
          ),
        },
      };
    case UPDATE_POOL_WORKSTATION:
      return {
        ...state,
        poolMachines: {
          ...state.poolMachines,
          data: updateRemoteWorkstations(
            state.poolMachines.data,
            action.updatedMachine
          ),
        },
      };
    case CLEAR_STORE:
      return defaultState;
    default:
      return state;
  }
}

const dataReducer = combineReducers({
  dataByResource,
});

export default dataReducer;
