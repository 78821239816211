import {
  OPEN_BULK_DELETE_WORKSTATIONS_DIALOG,
  CLOSE_BULK_DELETE_WORKSTATIONS_DIALOG,
  TOGGLE_BULK_DELETE_WORKSTATIONS_FROM_CSP,
} from 'redux/actions/bulkDeleteRemoteWorkstationsDialogActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';

const defaultState = {
  open: false,
  deleteFromCspEnabled: {},
  workstations: [],
};

function bulkDeleteRemoteWorkstationsDialog(state = defaultState, action) {
  switch (action.type) {
    case OPEN_BULK_DELETE_WORKSTATIONS_DIALOG:
      return {
        ...state,
        open: true,
        workstations: action.workstations,
      };
    case TOGGLE_BULK_DELETE_WORKSTATIONS_FROM_CSP:
      return {
        ...state,
        deleteFromCspEnabled: {
          ...state.deleteFromCspEnabled,
          [action.provider]: !state.deleteFromCspEnabled[action.provider],
        },
      };
    case CLEAR_STORE:
    case CLOSE_BULK_DELETE_WORKSTATIONS_DIALOG:
      return defaultState;
    default:
      return state;
  }
}

export default bulkDeleteRemoteWorkstationsDialog;
