export const OPEN_DELETE_WORKSTATION_DIALOG = 'OPEN_DELETE_WORKSTATION_DIALOG';

export function openDeleteWorkstationDialog(machineId) {
  return {
    type: OPEN_DELETE_WORKSTATION_DIALOG,
    machineId,
  };
}

export const CLOSE_DELETE_WORKSTATION_DIALOG =
  'CLOSE_DELETE_WORKSTATION_DIALOG';

export function closeDeleteWorkstationDialog() {
  return {
    type: CLOSE_DELETE_WORKSTATION_DIALOG,
  };
}

export const TOGGLE_DELETE_WORKSTATION_FROM_CSP =
  'TOGGLE_DELETE_WORKSTATION_FROM_CSP';

export function toggleDeleteWorkstationFromCsp() {
  return {
    type: TOGGLE_DELETE_WORKSTATION_FROM_CSP,
  };
}
