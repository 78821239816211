import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import ReactCardFlip from 'react-card-flip';

const StyledPaper = styled(Paper)({
  padding: '20px',
});

const flippableCardStyles = {
  front: {
    transformStyle: 'unset',
    zIndex: 'auto',
  },
  back: {
    transformStyle: 'unset',
    zIndex: 'auto',
  },
};

function FlippableCard({isFlipped, children}) {
  return (
    <ReactCardFlip
      isFlipped={isFlipped}
      cardStyles={flippableCardStyles}
      infinite
    >
      {/* Card front face */}
      <StyledPaper variant="outlined">{children[0]}</StyledPaper>

      {/* Card back face */}
      <StyledPaper variant="outlined">{children[1]}</StyledPaper>
    </ReactCardFlip>
  );
}

FlippableCard.propTypes = {
  isFlipped: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

FlippableCard.defaultProps = {
  isFlipped: false,
};

export default FlippableCard;
