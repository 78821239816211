import {IconButton, TextField} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import {ENTER_KEY, ESCAPE_KEY} from 'utils/constants';

const PREFIX = 'SearchField';

const classes = {
  clearIcon: `${PREFIX}-clearIcon`,
  muiSearchBarInput: `${PREFIX}-muiSearchBarInput`,
  searchBar: `${PREFIX}-searchBar`,
  searchIcon: `${PREFIX}-searchIcon`,
};
function SearchField({id, value, placeholder, onChange, onSearch, onClear}) {
  const keyActions = {
    [ENTER_KEY]: onSearch,
    [ESCAPE_KEY]: onClear,
  };

  const handleKeyDown = (event) => {
    const keyPressed = event.keyCode;
    const action = keyActions[keyPressed];
    if (action) {
      action();
    }
  };

  const renderClearIcon = () => {
    if (value) {
      return (
        <IconButton
          data-testid={`clear-icon-${id}`}
          className={classes.clearIcon}
          onClick={onClear}
          size="small"
        >
          <ClearIcon />
        </IconButton>
      );
    }
    return null;
  };

  const renderSearchIcon = () => (
    <IconButton
      data-testid={`search-icon-${id}`}
      className={classes.searchIcon}
      onClick={onSearch}
      size="small"
    >
      <SearchIcon />
    </IconButton>
  );

  const renderIcons = () => (
    <>
      {renderClearIcon()}
      {renderSearchIcon()}
    </>
  );

  return (
    <TextField
      id={id}
      data-testid={`test-${id}`}
      color="primary"
      variant="outlined"
      size="small"
      fullWidth
      value={value}
      className={classes.searchBar}
      placeholder={placeholder}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        className: classes.muiSearchBarInput,
        endAdornment: renderIcons(),
      }}
    />
  );
}

SearchField.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
};

SearchField.defaultProps = {
  id: 'search-field',
  value: '',
  placeholder: '',
  onChange: () => {},
  onSearch: () => {},
  onClear: () => {},
};

export default SearchField;
