import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import CAMTable from 'components/table/Table';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import AddMonitorProvisioningCommand from 'components/deployments/AddMonitorProvisioningCommand';
import lightbulbSvg from 'icons/lightbulb_24px_outlined.svg';
import {selectDataForTable} from 'utils/reduxSelectors';
import {
  ENROLLMENT_COMMAND_TEXT_DISCLAIMER,
  ENROLLMENT_COMMANDS,
} from 'utils/constants';

const PREFIX = 'EditDeploymentBulkMonitorProvisioning';

const classes = {
  disclaimerText: `${PREFIX}-disclaimerText`,
  fillSpace: `${PREFIX}-fillSpace`,
  helpfulTipContainer: `${PREFIX}-helpfulTipContainer`,
  helpfulTipIconContainer: `${PREFIX}-helpfulTipIconContainer`,
  helpfulTipText: `${PREFIX}-helpfulTipText`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.disclaimerText}`]: {
    color: theme.palette.surface.grey,
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    letterSpacing: '0.4px',
    lineHeight: '16px',
    marginLeft: '12px',
  },

  [`& .${classes.fillSpace}`]: {
    flexGrow: 1,
    width: '100%',
    margin: '10px 0',
  },

  [`& .${classes.helpfulTipContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    marginTop: '4px',
  },

  [`& .${classes.helpfulTipIconContainer}`]: {
    alignItems: 'center',
    display: 'flex',
  },

  [`& .${classes.helpfulTipText}`]: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '12px',
    letterSpacing: '0.4px',
    lineHeight: '16px',
    marginLeft: '4px',
    width: '65px',
  },
}));

function EditDeploymentBulkMonitorProvisioning({onAddCommand}) {
  const {
    data: enrollments,
    isFetching: fetchingEnrollmentCommands,
    total: totalnew,
  } = useSelector((state) => selectDataForTable(state, ENROLLMENT_COMMANDS));

  const renderHelpfulTip = () => (
    <>
      <div className={classes.fillSpace} />
      <div className={classes.helpfulTipContainer}>
        <div className={classes.helpfulTipIconContainer}>
          <img src={lightbulbSvg} alt="" />
          <div className={classes.helpfulTipText}>Helpful tip:</div>
        </div>
        <div className={classes.disclaimerText}>
          {ENROLLMENT_COMMAND_TEXT_DISCLAIMER}
        </div>
      </div>
    </>
  );

  return (
    <Root>
      <CAMCard>
        <SectionHeader displayText="BULK MONITOR PROVISIONING">
          <AddMonitorProvisioningCommand createCallback={onAddCommand} />
          {renderHelpfulTip()}
        </SectionHeader>
        <CAMTable
          id="monitor-enrollments-table"
          tableTitle="Monitor Enrollments"
          resource={ENROLLMENT_COMMANDS}
          data={enrollments}
          total={totalnew}
          rowsPerPageOptions={[15, 25, 50]}
          loadingData={fetchingEnrollmentCommands}
          noDataMessage="No provisioning command found"
          hideAddButton
          hideTitle
          hideActions
          hideBulkActions
        />
      </CAMCard>
    </Root>
  );
}

EditDeploymentBulkMonitorProvisioning.propTypes = {
  onAddCommand: PropTypes.func,
};

EditDeploymentBulkMonitorProvisioning.defaultProps = {
  onAddCommand: async () => {},
};

export default EditDeploymentBulkMonitorProvisioning;
