import {useSelector} from 'react-redux';
import {DEPLOYMENTS} from 'utils/constants';
import {selectResourceArray} from 'utils/reduxSelectors';

import {isEmpty} from 'utils/utils';

const useVerifyExistingDeployments = () => {
  const {data: deployments, isFetching: fetchingDeployments} = useSelector(
    (state) => selectResourceArray(state, DEPLOYMENTS)
  );

  const verifyExistingDeployments = () =>
    fetchingDeployments || !isEmpty(deployments);

  return verifyExistingDeployments;
};

export default useVerifyExistingDeployments;
