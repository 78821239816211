import {Paper, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import ReactJson from 'react-json-view';
import {useDispatch} from 'react-redux';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import SessionTrackingSwitch from 'components/common/SessionTrackingSwitch';
import SlimTable from 'components/common/SlimTable';
import config from 'config';
import {receiveResource} from 'redux/actions/dataActions';
import {loadTableData} from 'redux/actions/tableDataActions';
import {
  adGroupsTableDescription,
  AD_GROUPS,
  AWM_ADGROUPS_DOCUMENTATION,
  noAdGroupsText,
  TABLE_CELL_TEXT,
  updateConnectorHelpText,
} from 'utils/constants';
import {buildFrontendAdGroupFormat} from 'utils/utils';
import AdGroupsActions from './AdGroupsActions';
import AdGroupsBulkActions from './AdGroupsBulkActions';
import FedAuthForm from './FedAuthForm';
import SearchByUpnSettingForm from './SearchByUpnSettingForm';

const PREFIX = 'EditDeploymentConnectorSettingsTab';

const classes = {
  cardTitle: `${PREFIX}-cardTitle`,
  enableSessionContainer: `${PREFIX}-enableSessionContainer`,
  gridItem: `${PREFIX}-gridItem`,
  description: `${PREFIX}-description`,
  tableContainer: `${PREFIX}-tableContainer`,
  noTableBody: `${PREFIX}-noTableBody`,
  jsonContainer: `${PREFIX}-jsonContainer`,
};

const Root = styled('div')(() => ({
  [`& .${classes.cardTitle}`]: {
    height: '16px',
    color: 'rgba(0,0,0,0.6)',
    width: '148px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '1.25px',
    lineHeight: '16px',
  },
  [`& .${classes.enableSessionContainer}`]: {paddingTop: '14px'},
  [`& .${classes.gridItem}`]: {
    alignSelf: 'center',
  },
  [`& .${classes.description}`]: {
    color: '#514F4D',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '18px',
    margin: '10px 0px',
  },
  [`& .${classes.tableContainer}`]: {
    marginTop: '15px',
    minWidth: '400px',
  },
  [`& .${classes.noTableBody}`]: {
    backgroundColor: '#FAFAF9',
    padding: '50px 40px',
    textAlign: 'center',
    color: '#0076A9',
  },
  [`& .${classes.jsonContainer}`]: {
    lineHeight: '20px',
  },
}));

const adGroupsTableFields = [
  {id: 'groupDN', text: 'Distinguished Name', type: TABLE_CELL_TEXT},
  {id: 'groupGuid', text: 'Group GUID', type: TABLE_CELL_TEXT},
];

const sessionStatsDescription =
  'Enabling this feature allows Anyware Connectors and Monitors to send information about PCoIP sessions going through a Security Gateway to Anyware Manager. This can be used by your administrator to view which users are currently in session on a remote workstation. To allow session management, such as terminating user sessions, Anyware Monitor must be installed on the Workstation. Follow the instruction in Anyware Monitor in the Workstation section for details.';

const sessionStatsDescriptionMonitorDisabled =
  'Enabling this feature allows Anyware Connectors to send information about PCoIP sessions going through a Security Gateway to Anyware Manager. This can be used by your administrator to view which users are currently in session on a remote workstation.';

const searchByUpnDescription =
  'Enabling this feature allows Anyware Connectors to enable entitlements by UPN. UPN is case insensitive.';

function EditDeploymentConnectorSettingsTab({connectorSettings}) {
  const dispatch = useDispatch();

  // When using the old route, the delete option will be present for the deployment ad groups
  const usingLegacyRoute =
    connectorSettings?.path?.includes('connectorSettings');

  const prepareAdGroup = (adGroup) => {
    const newGroupFormat = buildFrontendAdGroupFormat(adGroup);
    return {
      ...newGroupFormat,
      id: newGroupFormat.groupGuid,
    };
  };

  // format groups to display both old- and new-format AD groups
  const prepareAdGroups = (data) => data.map((group) => prepareAdGroup(group));

  useEffect(() => {
    const adGroups = connectorSettings?.deployment?.poolsGroups || [];
    dispatch(receiveResource(AD_GROUPS, adGroups));
    dispatch(loadTableData(AD_GROUPS, adGroups, 0, adGroups.length));
  }, [connectorSettings?.deployment?.poolsGroups]);

  const renderAdGroupContextLink = () => (
    <Typography className={classes.description}>
      {adGroupsTableDescription} Learn more about{' '}
      <a
        href={AWM_ADGROUPS_DOCUMENTATION}
        target="__blank"
        rel="noopener noreferrer"
      >
        AD Groups Configurations
      </a>
    </Typography>
  );

  const renderAdGroupsTable = () => (
    <Paper square className={classes.tableContainer}>
      <SlimTable
        data={prepareAdGroups(connectorSettings?.deployment?.poolsGroups || [])}
        resource={AD_GROUPS}
        fields={adGroupsTableFields}
        actions={AdGroupsActions}
        bulkActions={AdGroupsBulkActions}
        shouldRenderSearch={false}
        noDataMessage={noAdGroupsText}
        shouldRenderKebabs={usingLegacyRoute}
        shouldRenderCheckboxes={usingLegacyRoute}
      />
    </Paper>
  );

  const renderAdGroupsCard = () => (
    <CAMCard>
      <GridN singleColumn>
        <SectionHeader displayText="MANAGE AD GROUPS" />
        {renderAdGroupContextLink()}
        {renderAdGroupsTable()}
      </GridN>
    </CAMCard>
  );

  const renderSessionStatsCard = () => (
    <CAMCard>
      <GridN singleColumn>
        <SectionHeader displayText="SESSION STATISTICS" />
        <Typography className={classes.description}>
          {config.isMonitorEnabled()
            ? sessionStatsDescription
            : sessionStatsDescriptionMonitorDisabled}{' '}
          <strong>
            Session tracking is required to be enabled in order to use the
            floating assignment policy for Workstation Pools.
          </strong>
        </Typography>
        <SessionTrackingSwitch />
      </GridN>
    </CAMCard>
  );

  const renderFedAuthCard = () => (
    <FedAuthForm settings={connectorSettings?.deployment || {}} />
  );

  const renderSearchByUpnCard = () => (
    <CAMCard>
      <GridN singleColumn>
        <SectionHeader displayText="ENABLE ENTITLEMENTS BY UPN" />
        <Typography className={classes.description}>
          {searchByUpnDescription}
          <br />
          This setting is not required if using the Anyware Connector
          (RHEL/Rocky). If using the Cloud Access Connector (Ubuntu), follow the
          instructions below.
          <br />
          <br />
          {updateConnectorHelpText(true, false)}
          <br />
        </Typography>
        <SearchByUpnSettingForm
          deploymentSettings={connectorSettings?.deployment || {}}
        />
      </GridN>
    </CAMCard>
  );

  const renderJsonConnectorSettings = () => (
    <CAMCard>
      <GridN singleColumn>
        <SectionHeader displayText="DEPLOYMENT SETTINGS" />
      </GridN>
      <GridN singleColumn>
        <div className={classes.jsonContainer}>
          <ReactJson
            src={connectorSettings?.deployment || {}}
            name={false}
            displayObjectSize={false}
            displayDataTypes={false}
            displayArrayKey={false}
            quotesOnKeys={false}
          />
        </div>
      </GridN>
    </CAMCard>
  );

  return (
    <Root>
      {renderSessionStatsCard()}
      {renderFedAuthCard()}
      {renderSearchByUpnCard()}
      {renderAdGroupsCard()}
      {renderJsonConnectorSettings()}
    </Root>
  );
}

EditDeploymentConnectorSettingsTab.propTypes = {
  connectorSettings: PropTypes.object,
};

EditDeploymentConnectorSettingsTab.defaultProps = {
  connectorSettings: {
    poolsGroups: [],
  },
};

export default EditDeploymentConnectorSettingsTab;
