import {
  CLOSE_AZURE_ROLE_DIALOG,
  OPEN_AZURE_ROLE_DIALOG,
} from 'redux/actions/azureRoleDialogActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';

const defaultState = {
  open: false,
  roleReport: {},
  canManage: false,
};

function azureRoleDialogReducer(state = defaultState, action) {
  switch (action.type) {
    case OPEN_AZURE_ROLE_DIALOG:
      return {
        open: true,
        roleReport: action.roleReport,
        canManage: action.canManage,
      };
    case CLOSE_AZURE_ROLE_DIALOG:
      return {
        open: false,
        roleReport: {},
        canManage: false,
      };
    case CLEAR_STORE:
      return defaultState;
    default:
      return state;
  }
}

export default azureRoleDialogReducer;
