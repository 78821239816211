import { styled } from '@mui/material/styles';
import {useSelector} from 'react-redux';

import {
  AD_SYNC_CAPABILITY_NAME,
  AD_CONFIG_CAPABILITY_NAME,
} from 'utils/constants';
import ADSyncConfiguration from './ADSyncConfiguration';
import ADIntegrationConfiguration from './ADIntegrationConfiguration';

const PREFIX = 'ActiveDirectoryTab';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    margin: 'auto',
    maxWidth: '1220px',
  }
}));

function ActiveDirectoryTab() {


  const selectedConnector = useSelector(
    (state) => state?.data?.dataByResource?.selectedConnector
  );

  const capabilityList = selectedConnector?.capabilities || [];
  const adSyncCapData = capabilityList.find(
    (cap) => cap.capabilityName === AD_SYNC_CAPABILITY_NAME
  );
  const adConfigCapData = capabilityList.find(
    (cap) => cap.capabilityName === AD_CONFIG_CAPABILITY_NAME
  );

  return (
    <Root className={classes.root}>
      <ADIntegrationConfiguration adConfigCapData={adConfigCapData} />
      <ADSyncConfiguration adSyncCapData={adSyncCapData} />
    </Root>
  );
}

export default ActiveDirectoryTab;
