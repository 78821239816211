import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useState} from 'react';
import ArrowTooltip from './ArrowTooltip';

const PREFIX = 'ArrowTooltipText';

const classes = {
  textStyle: `${PREFIX}-textStyle`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.textStyle}`]: {
    ...theme.createPage.helpQuestion,
    cursor: 'pointer',
  },
}));

function ArrowTooltipText({
  text,
  title,
  placement,
  disableFocusListener,
  disableHoverListener,
  disableTouchListener,
  overrides,
}) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Root>
      <ArrowTooltip
        open={open}
        onClose={handleTooltipClose}
        disableFocusListener={disableFocusListener}
        disableHoverListener={disableHoverListener}
        disableTouchListener={disableTouchListener}
        title={title}
        placement={placement}
        data-testid="arrow-tooltip-text"
        overrides={overrides}
      >
        <span
          className={classes.textStyle}
          role="button"
          onClick={handleTooltipOpen}
        >
          {text}
        </span>
      </ArrowTooltip>
    </Root>
  );
}

ArrowTooltipText.propTypes = {
  text: PropTypes.string,
  title: PropTypes.object,
  placement: PropTypes.string,
  disableFocusListener: PropTypes.bool,
  disableHoverListener: PropTypes.bool,
  disableTouchListener: PropTypes.bool,
  overrides: PropTypes.object,
};

ArrowTooltipText.defaultProps = {
  text: '',
  title: {},
  placement: 'top',
  disableFocusListener: true,
  disableHoverListener: true,
  disableTouchListener: true,
  overrides: {},
};

export default ArrowTooltipText;
