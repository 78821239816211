import {Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useState} from 'react';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import KeyValueInfo from 'components/CAM/text/KeyValueInfo/KeyValueInfo';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import {formatDateTime, isLatestMonitorTelemetryValid} from 'utils/utils';
import ToggleSwitchBox from 'components/CAM/buttons/ToggleSwitchBox/ToggleSwitchBox';
import {
  monitorAlreadyInstalledMessage,
  monitorStatsDescription,
  monitorUpdateMessage,
} from 'utils/constants';
import MonitorInstallInstructions from './MonitorInstallInstructions';
import MonitorUpdateInstructions from './MonitorUpdateInstructions';

const PREFIX = 'MonitorConfigurationCard';

const classes = {
  description: `${PREFIX}-description`,
  generateButton: `${PREFIX}-generateButton`,
  tokenField: `${PREFIX}-tokenField`,
  largeTokenField: `${PREFIX}-largeTokenField`,
  tokenSubtext: `${PREFIX}-tokenSubtext`,
  copyButton: `${PREFIX}-copyButton`,
  success: `${PREFIX}-success`,
  warning: `${PREFIX}-warning`,
  commandsButtons: `${PREFIX}-commandsButtons`,
  updateButton: `${PREFIX}-updateButton`,
  message: `${PREFIX}-message`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.description}`]: {
    color: '#514F4D',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '18px',
    margin: '10px 0px',
  },

  [`& .${classes.generateButton}`]: {
    padding: '12px',
    border: '1px solid #DDDBDA',
    borderRadius: '3px',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.1)',
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
  },

  [`& .${classes.tokenField}`]: {
    border: '1px solid #DDDBDA',
    borderRadius: '3px',
    color: 'rgba(0,0,0,0.38)',
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    height: '30px',
    letterSpacing: '0.1px',
    lineHeight: '24px',
    marginTop: '10px',
    maxHeight: '150px',
    minHeight: '30px',
    resize: 'vertical',
    width: '100%',
  },

  [`& .${classes.largeTokenField}`]: {
    height: '50px',
    minHeight: '50px',
  },

  [`& .${classes.tokenSubtext}`]: {
    color: theme.palette.info.main,
    fontFamily: 'Roboto',
    fontSize: '0.625rem',
    lineHeight: '11px',
  },

  [`& .${classes.copyButton}`]: {
    marginLeft: '1px',
    marginTop: '5px',
    padding: '7px',
  },

  [`& .${classes.success}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.warning}`]: {
    color: theme.palette.warning.main,
  },
  [`& .${classes.updateButton}`]: {
    marginLeft: '16px',
  },
  [`& .${classes.commandsButtons}`]: {
    display: 'flex',
    flexDirection: 'row',
  },
  [`& .${classes.message}`]: {
    [`& p`]: {
      margin: '0',
    },
    marginBottom: '16px',
  },
}));

function MonitorConfigurationCard({
  isSaving,
  handleAgentMonitoredToggle,
  selectedWorkstation,
  workstationServiceAccounts,
  getWorkstationServiceAccounts,
  latestMonitorTelemetry,
}) {
  const [showInstallInstructions, setShowInstallInstructions] = useState(
    workstationServiceAccounts.length === 0
  );
  const [showUpdateInstructions, setShowUpdateInstructions] = useState(false);

  const renderMonitorConnectionStatus = () => {
    if (!selectedWorkstation.agentMonitored) {
      return <span>Disabled</span>;
    }
    if (isLatestMonitorTelemetryValid(latestMonitorTelemetry)) {
      return <span className={classes.success}>Healthy</span>;
    }
    return <span className={classes.warning}>Unhealthy</span>;
  };

  const renderInfoItem = (info) => (
    <KeyValueInfo
      key={info.id}
      label={info.label}
      value={info.value}
      link={info.link}
      toolTipText={info.toolTipText}
      enableCopy={['machineId', 'instanceId'].includes(info.id)}
    />
  );

  const monitorInfo = [
    {
      id: 'monitorVersion',
      label: 'Version',
      value: latestMonitorTelemetry.agentVersion || 'Information not available',
    },
    {
      id: 'monitorConnection',
      label: 'Connection',
      value: renderMonitorConnectionStatus(),
    },
    {
      id: 'lastConnection',
      label: 'Last Connection',
      value:
        formatDateTime(latestMonitorTelemetry.msgReceivedOn, true) || 'Unknown',
    },
  ];

  const renderMonitorInformation = () =>
    monitorInfo.map((info) => renderInfoItem(info));

  const monitorInstallAndUpdateButtons = () => (
    <div>
      <div className={classes.message}>
        <p>{monitorAlreadyInstalledMessage}</p>
        <p>{monitorUpdateMessage}</p>
      </div>

      <div className={classes.commandsButtons}>
        <CAMButton
          buttonText="Show Install Instructions"
          onClick={() => setShowInstallInstructions(true)}
          testId="show-install-instructions"
        />
        <CAMButton
          buttonText="Show Update Instructions"
          onClick={() => setShowUpdateInstructions(true)}
          testId="show-update-instructions"
          customClass={classes.updateButton}
        />
      </div>
    </div>
  );

  const monitorCommands = () => (
    <div>
      {showInstallInstructions ? (
        <MonitorInstallInstructions
          selectedWorkstation={selectedWorkstation}
          workstationServiceAccounts={workstationServiceAccounts}
          getWorkstationServiceAccounts={getWorkstationServiceAccounts}
          latestMonitorTelemetry={latestMonitorTelemetry}
          setShowInstallInstructions={setShowInstallInstructions}
        />
      ) : (
        <MonitorUpdateInstructions
          deploymentId={selectedWorkstation.deploymentId}
          showTitle
          setShowUpdateInstructions={setShowUpdateInstructions}
        />
      )}
    </div>
  );

  const renderMonitorInstallInstructions = () => (
    <CAMCard>
      <GridN singleColumn>
        <SectionHeader displayText="INSTALL INSTRUCTIONS" />
        {workstationServiceAccounts.length === 0 ||
        showInstallInstructions ||
        showUpdateInstructions ? (
          <>{monitorCommands()}</>
        ) : (
          <> {monitorInstallAndUpdateButtons()}</>
        )}
      </GridN>
    </CAMCard>
  );

  return (
    <Root>
      <CAMCard>
        <GridN singleColumn>
          <SectionHeader displayText="ANYWARE MONITOR CONFIGURATION" />
        </GridN>
        {workstationServiceAccounts.length > 0 && (
          <GridN>{renderMonitorInformation()}</GridN>
        )}
        <GridN singleColumn>
          <Typography className={classes.description}>
            {monitorStatsDescription}
          </Typography>
          <ToggleSwitchBox
            isSaving={isSaving}
            onClick={handleAgentMonitoredToggle}
            isOn={selectedWorkstation.agentMonitored}
            displayText="Enable Anyware Monitor"
            tooltipText="No telemetry information is collected when this option is disabled."
            testId="enable-agent-monitored"
          />
        </GridN>
      </CAMCard>
      {selectedWorkstation.agentMonitored && renderMonitorInstallInstructions()}
    </Root>
  );
}

MonitorConfigurationCard.propTypes = {
  isSaving: PropTypes.bool,
  handleAgentMonitoredToggle: PropTypes.func.isRequired,
  selectedWorkstation: PropTypes.object.isRequired,
  workstationServiceAccounts: PropTypes.array.isRequired,
  getWorkstationServiceAccounts: PropTypes.func.isRequired,
  latestMonitorTelemetry: PropTypes.object.isRequired,
};

MonitorConfigurationCard.defaultProps = {
  isSaving: true,
};

export default MonitorConfigurationCard;
