import Checkbox from '@mui/material/Checkbox';
import {styled} from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Tooltip from '@mui/material/Tooltip';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CAMDeleteDialog from 'components/CAM/dialogs/CAMDeleteDialog/CAMDeleteDialog';
import {
  closeDeleteWorkstationDialog,
  toggleDeleteWorkstationFromCsp,
} from 'redux/actions/deleteRemoteWorkstationDialogActions';
import {deleteWorkstation} from 'redux/actions/remoteWorkstationActions';
import {DELETE_FROM_CSP_SUPPORTED, REMOTE_WORKSTATIONS} from 'utils/constants';
import {mapProviders} from 'utils/Mappings';
import {selectProviderCredentials} from 'utils/reduxSelectors';
import {isEmpty} from 'utils/utils';

const PREFIX = 'DeleteRemoteWorkstationDialog';

const classes = {
  tooltip: `${PREFIX}-tooltip`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.tooltip}`]: {
    fontSize: '14px',
  },
}));

function DeleteRemoteWorkstationDialog() {
  const dispatch = useDispatch();

  const [hover, setHover] = useState(false);

  const {open, deleteFromCspEnabled, machineId} = useSelector(
    (state) => state.deleteRemoteWorkstationDialog
  );

  const workstation = useSelector((state) => {
    const {data} = state || {data: {}};
    const {dataByResource} = data || {[REMOTE_WORKSTATIONS]: {}};
    const remoteWorkstations = dataByResource.remoteWorkstations || {data: {}};
    return (
      remoteWorkstations.data[machineId] || {
        machineName: 'machine',
        provider: 'onprem',
      }
    );
  });

  const {creds, fetchingCreds} = useSelector((state) =>
    selectProviderCredentials(state)
  ) || {creds: [], fetchingCreds: false};

  const onConfirm = () => {
    dispatch(closeDeleteWorkstationDialog());
    dispatch(deleteWorkstation(workstation, deleteFromCspEnabled));
  };

  const onCancel = () => {
    dispatch(closeDeleteWorkstationDialog());
  };

  const hasProviderCredentials = () => {
    if (fetchingCreds) {
      return true;
    }
    const {provider} = workstation;
    const providerCreds = creds.find((cred) => cred.provider === provider);
    return !isEmpty(providerCreds);
  };

  const onCheckboxChange = () => {
    if (hasProviderCredentials()) {
      dispatch(toggleDeleteWorkstationFromCsp());
    }
  };

  const disableCheckbox = () => !hasProviderCredentials();

  const checkboxMessage = `Delete workstation from ${
    mapProviders(workstation.provider) || 'public cloud'
  }`;

  const showCheckbox = () =>
    DELETE_FROM_CSP_SUPPORTED.includes(workstation.provider);

  const getTooltipTitle = () => {
    if (fetchingCreds) {
      return `Verifying deployment has ${mapProviders(
        workstation.provider
      )} credentials...`;
    }

    if (creds.length === 0) {
      return `Deployment does not have ${mapProviders(
        workstation.provider
      )} credentials`;
    }

    return '';
  };

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  function renderDeleteFromCspCheckbox() {
    return (
      <FormGroup>
        <Tooltip
          title={getTooltipTitle()}
          open={hover && disableCheckbox()}
          arrow
          placement="top"
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                value={deleteFromCspEnabled}
                color="primary"
                onChange={() => onCheckboxChange()}
                disabled={disableCheckbox()}
                inputProps={{
                  'data-testid': `delete-${workstation.machineId}-from-provider`,
                }}
              />
            }
            label={checkboxMessage}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </Tooltip>
      </FormGroup>
    );
  }

  return (
    <CAMDeleteDialog
      requireTextInputToDelete={deleteFromCspEnabled}
      resourceName={workstation.machineName}
      open={open}
      onOk={onConfirm}
      onCancel={onCancel}
      content={<Root>{showCheckbox() && renderDeleteFromCspCheckbox()}</Root>}
    />
  );
}

export default DeleteRemoteWorkstationDialog;
