import PropTypes from 'prop-types';
import AWMBanner from 'components/CAM/display/AWMBanner/AWMBanner';
import {
  ERROR_STATUS,
  LICENSE_EXPIRED_DETAILS,
  LICENSE_EXPIRED_MESSAGE,
} from 'utils/constants';

function ExpiredLicenseBanner({onClick}) {
  return (
    <AWMBanner
      message={LICENSE_EXPIRED_MESSAGE}
      details={LICENSE_EXPIRED_DETAILS}
      tagName="License"
      variant={ERROR_STATUS}
      dataTestId="license-expired-banner"
      linkObject={{
        href: 'https://hp.com',
        label: 'Learn More',
      }}
      onClick={onClick}
      showIcon={false}
    />
  );
}

ExpiredLicenseBanner.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ExpiredLicenseBanner;
