import {Chip} from '@mui/material';
import {styled} from '@mui/material/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {darken, lighten} from 'polished';
import {
  DEFAULT_STATUS,
  ERROR_STATUS,
  OK_STATUS,
  WARNING_STATUS,
  INFO_STATUS,
} from 'utils/constants';
import {stylesFromFigma} from 'themes/stylesFromFigma';

const PREFIX = 'StatusChip';

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  ok: `${PREFIX}-ok`,
  warning: `${PREFIX}-warning`,
  error: `${PREFIX}-error`,
  info: `${PREFIX}-info`,
  default: `${PREFIX}-default`,
};

const StyledChip = styled(Chip)(({theme}) => ({
  [`&.${classes.root}`]: {
    marginRight: '2px',
    display: 'inlineFlex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.surface.grey,
    borderRadius: '4px',
    fontWeight: 'bold',
    height: stylesFromFigma.statusChip.height,
    minWidth: stylesFromFigma.statusChip.minWidth,
    cursor: (props) => (props.onClick ? 'pointer' : 'default'), // change cursor based on onClick prop
  },

  [`& .${classes.label}`]: {
    ...stylesFromFigma.statusChip.label,
  },

  [`&.${classes.ok}`]: {
    ...stylesFromFigma.statusChip.success,
    '&&:hover': {
      // change background color on hover only if onClick prop is provided
      backgroundColor: (props) =>
        props.onClick
          ? darken(0.1, stylesFromFigma.statusChip.success.backgroundColor)
          : stylesFromFigma.statusChip.success.backgroundColor,
    },
    '&&:focus': {
      backgroundColor: stylesFromFigma.statusChip.success.backgroundColor,
    },
  },

  [`&.${classes.warning}`]: {
    ...stylesFromFigma.statusChip.warning,
    '&&:hover': {
      // change background color on hover only if onClick prop is provided
      backgroundColor: (props) =>
        props.onClick
          ? darken(0.1, stylesFromFigma.statusChip.warning.backgroundColor)
          : stylesFromFigma.statusChip.warning.backgroundColor,
    },
    '&&:focus': {
      backgroundColor: stylesFromFigma.statusChip.warning.backgroundColor,
    },
  },

  [`&.${classes.error}`]: {
    ...stylesFromFigma.statusChip.error,
    '&&:hover': {
      // change background color on hover only if onClick prop is provided
      backgroundColor: (props) =>
        props.onClick
          ? darken(0.1, stylesFromFigma.statusChip.error.backgroundColor)
          : stylesFromFigma.statusChip.error.backgroundColor,
    },
    '&&:focus': {
      backgroundColor: stylesFromFigma.statusChip.error.backgroundColor,
    },
  },

  [`&.${classes.info}`]: {
    color: theme.palette.primary.main,
    borderColor: lighten(0.4, theme.palette.primary.light),
    backgroundColor: lighten(0.4, theme.palette.primary.light),
    '&&:hover': {
      // change background color on hover only if onClick prop is provided
      backgroundColor: (props) =>
        props.onClick
          ? lighten(0.3, theme.palette.primary.light)
          : lighten(0.4, theme.palette.primary.light),
    },
    '&&:focus': {
      backgroundColor: lighten(0.4, theme.palette.primary.light),
    },
  },

  [`&.${classes.default}`]: {
    color: theme.palette.surface.grey,
    borderColor: theme.palette.surface[300],
    backgroundColor: theme.palette.surface[300],
    '&&:hover': {
      // change background color on hover only if onClick prop is provided
      backgroundColor: (props) =>
        props.onClick ? theme.palette.surface[400] : theme.palette.surface[300],
    },
    '&&:focus': {
      backgroundColor: theme.palette.surface[300],
    },
  },
}));

function StatusChip(props) {
  const {label, type, onClick, ...rest} = props;
  const chooseChipClass = () => {
    switch (type) {
      case OK_STATUS:
        return classes.ok;
      case WARNING_STATUS:
        return classes.warning;
      case ERROR_STATUS:
        return classes.error;
      case INFO_STATUS:
        return classes.info;
      case DEFAULT_STATUS:
      default:
        return classes.default;
    }
  };

  return (
    <StyledChip
      label={label}
      classes={{
        label: classes.label,
        root: classNames(classes.root, chooseChipClass(type)),
      }}
      onClick={onClick}
      {...rest}
    />
  );
}

StatusChip.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['ok', 'warning', 'error', 'info', 'default']),
  onClick: PropTypes.func,
};

StatusChip.defaultProps = {
  onClick: null,
  type: 'default',
};

export default StatusChip;
