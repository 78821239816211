import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {post} from 'api/Api';
import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import config from 'config';
import useBulkActionDialog from 'hooks/useBulkActionDialog';
import useDialog from 'hooks/useDialog';
import useSnackbar from 'hooks/useSnackbar';
import {fetchResource, saveVariable} from 'redux/actions/dataActions';
import {openDeleteWorkstationDialog} from 'redux/actions/deleteRemoteWorkstationDialogActions';
import {
  restartMachine,
  startMachine,
  stopMachine,
} from 'redux/actions/remoteWorkstationActions';
import {
  AD_USERS,
  CLOUD_SERVICE_ACCOUNTS,
  ENTITLEMENTS,
  MONITOR_IN_SESSION,
  MONITOR_TELEMETRY_LATEST,
  TABLE_CELL_TEXT,
  dataLossWarningMsg,
} from 'utils/constants';
import {selectData, selectResource} from 'utils/reduxSelectors';
import {
  canStartWorkstation,
  canStopOrRestartWorkstation,
  isItemActive,
  isLatestMonitorTelemetryValid,
} from 'utils/utils';

const PREFIX = 'RemoteWorkstationActions';

const classes = {
  divider: `${PREFIX}-divider`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.divider}`]: {height: '2px'}
}));

const entitleUsersDialogFields = [
  {
    id: 'name',
    text: 'Name',
    type: TABLE_CELL_TEXT,
    sortable: true,
  },
  {
    id: 'userName',
    text: 'User Name',
    type: TABLE_CELL_TEXT,
    sortable: true,
  },
  {
    id: 'userGuid',
    text: 'User GUID',
    type: TABLE_CELL_TEXT,
  },
  {
    id: 'status',
    text: 'Status',
    type: TABLE_CELL_TEXT,
  },
];

function RemoteWorkstationActions({item: workstation, closeMenu}) {

  const dispatch = useDispatch();

  const {triggerDialog} = useDialog();

  const {bulkActionDialog} = useBulkActionDialog();
  const {successSnackbar, errorSnackbar} = useSnackbar();

  const [usersInSession, setUsersInSession] = useState([]);

  const {data: adUsers} = useSelector((state) => selectData(state, AD_USERS));
  const {data: entitlements} = useSelector((state) =>
    selectData(state, ENTITLEMENTS)
  );
  const {data: monitorTelemetry, isFetching: isFetchingMonitorTelemetry} =
    useSelector((state) => selectResource(state, MONITOR_TELEMETRY_LATEST));
  const {data: cloudServiceAccounts} = useSelector((state) =>
    selectData(state, CLOUD_SERVICE_ACCOUNTS)
  ) || {data: []};

  useEffect(() => {
    if (
      config.isMonitorEnabled() &&
      isLatestMonitorTelemetryValid(monitorTelemetry[workstation.machineId])
    ) {
      const {loggedInUsers} = monitorTelemetry[workstation.machineId];

      setUsersInSession(
        loggedInUsers
          ?.filter((user) => user.status === MONITOR_IN_SESSION)
          .map((user) => user.userName)
      );
    }
  }, [JSON.stringify(monitorTelemetry)]);

  const getWorkstationName = () =>
    workstation ? workstation.machineName || workstation.machineId : '';

  const getConfirmMessage = (action) =>
    `Are you sure you want to ${action} remote workstation ${getWorkstationName()}?`;

  const handleStartButtonClick = () => dispatch(startMachine(workstation));

  const handleStopButtonClick = () => {
    triggerDialog({
      title: 'Stop remote workstation?',
      message: getConfirmMessage('stop'),
      onConfirm: () => dispatch(stopMachine(workstation)),
      warningMessage: dataLossWarningMsg,
    });
  };

  const handleRestartButtonClick = () => {
    triggerDialog({
      title: 'Restart remote workstation?',
      message: getConfirmMessage('restart'),
      onConfirm: () => dispatch(restartMachine(workstation)),
      warningMessage: dataLossWarningMsg,
    });
  };

  const handleDeleteButtonClick = () => {
    dispatch(openDeleteWorkstationDialog(workstation.machineId));
  };

  const bulkEntitleUsers = (adusers) => async () => {
    const result = {
      success: 0,
      failed: 0,
    };

    const promises = adusers.map(async (user) => {
      try {
        await post({
          path: `deployments/${workstation.deploymentId}/entitlements`,
          data: {
            machineId: workstation.machineId,
            userGuid: user.userGuid,
          },
        });
        result.success += 1;
      } catch (error) {
        if (error.code === 409) {
          result.success += 1;
          return;
        }
        result.failed += 1;
      }
    });
    await Promise.all(promises);

    if (result.failed > 0) {
      errorSnackbar(
        `Failed to create ${result.failed} entitlement${
          result.failed > 1 ? 's' : ''
        }.`
      );
    } else if (result.success > 0) {
      successSnackbar(
        `Successfully created ${result.success} entitlement${
          result.success > 1 ? 's' : ''
        }.`
      );
    }
    dispatch(fetchResource(ENTITLEMENTS, {rowsPerPage: 1000}));
  };

  const prepareExistingUsers = () =>
    entitlements
      .filter(
        ({entitlementType, resourceId}) =>
          entitlementType === 'user' && resourceId === workstation.machineId
      )
      .map(({userGuid}) => {
        const adUser = adUsers.find((user) => user.userGuid === userGuid);
        return {...adUser, id: adUser._id};
      });

  const openLogoutDialog = (logoutWorkstations) => {
    dispatch(
      saveVariable('monitorLogoutInfo', {
        isLogoutDialogOpen: true,
        logoutWorkstations: [logoutWorkstations],
      })
    );
  };

  return (
    (<Root>
      <KebabMenuItem
        id={`start-workstation-${workstation.machineId}`}
        enableClick={canStartWorkstation(workstation, cloudServiceAccounts)}
        menuText="Start"
        onClick={() => {
          closeMenu();
          handleStartButtonClick();
        }}
      />
      <KebabMenuItem
        id={`stop-workstation-${workstation.machineId}`}
        enableClick={canStopOrRestartWorkstation(
          workstation,
          cloudServiceAccounts
        )}
        menuText="Stop"
        onClick={() => {
          closeMenu();
          handleStopButtonClick();
        }}
      />
      <KebabMenuItem
        id={`restart-workstation-${workstation.machineId}`}
        enableClick={canStopOrRestartWorkstation(
          workstation,
          cloudServiceAccounts
        )}
        menuText="Restart"
        onClick={() => {
          closeMenu();
          handleRestartButtonClick();
        }}
      />
      <KebabMenuItem
        id={`entitle-users-${workstation.machineId}`}
        enableClick={isItemActive(workstation)}
        menuText="Entitle users"
        onClick={() => {
          closeMenu();
          bulkActionDialog({
            bulkAction: bulkEntitleUsers,
            params: {sortKey: 'name'},
            existingItems: prepareExistingUsers(),
            resource: AD_USERS,
            searchPlaceholder: 'Search by name',
            tableFields: entitleUsersDialogFields,
            title: 'Entitle Users',
          });
        }}
      />
      {config.isMonitorEnabled() && (
        <KebabMenuItem
          id={`logout-users-${workstation.machineId}`}
          enableClick={usersInSession.length > 0 && !isFetchingMonitorTelemetry}
          menuText="Logout users"
          onClick={() => {
            closeMenu();
            openLogoutDialog(workstation);
          }}
        />
      )}
      <KebabMenuItem
        id={`delete-workstation-${workstation.machineId}`}
        enableClick={isItemActive(workstation)}
        menuText="Delete"
        onClick={() => {
          closeMenu();
          handleDeleteButtonClick();
        }}
      />
      <Divider className={classes.divider} />
      <Link
        id={`edit-workstation-${workstation.machineId}-link`}
        to={`/app/remoteWorkstations/edit/${workstation.machineId}`}
      >
        <KebabMenuItem
          id={`edit-workstation-${workstation.machineId}`}
          enableClick={isItemActive(workstation)}
          menuText="Edit"
        />
      </Link>
    </Root>)
  );
}

RemoteWorkstationActions.propTypes = {
  item: PropTypes.object,
  closeMenu: PropTypes.func.isRequired,
};

RemoteWorkstationActions.defaultProps = {
  item: {},
};

export default RemoteWorkstationActions;
