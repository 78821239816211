import {useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useQueryClient} from 'react-query';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {styled} from '@mui/material/styles';
import useSnackbar from 'hooks/useSnackbar';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {
  serviceAccountQueryKey,
  useDeleteServiceAccount,
  useQueryDeploymentServiceAccount,
} from 'hooks/serviceAccountHooks';
import CAMPagination from 'components/common/Pagination';
import PageHeader from 'components/CAM/layout/PageHeader/PageHeader';

import {handleApiError} from 'redux/actions/dataActions';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {
  CREATE_DEPLOYMENT_SERVICE_ACCOUNT,
  CREATE_DEPLOYMENT_SERVICE_ACCOUNTS_LINK,
  DEPLOYMENT_SERVICE_ACCOUNT_DESCRIPTION,
} from 'utils/constants';
import {clearDeploymentSACred} from 'redux/actions/deploymentSACredActions';
import ServiceAccountDownload from 'components/deployments/ServiceAccountDownloadModal';
import ServiceAccountsTable from '../common/ServiceAccountsTable';

// Add button style
const PREFIX = 'AddDeploymentSAButton';
const classes = {
  addIcon: `${PREFIX}-addIcon`,
};
const AddButton = styled(Button)(() => ({
  backgroundColor: '#0D47A1',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#0D47A1',
    opacity: 0.8,
  },
  paddingRight: '20px',

  [`& .${classes.addIcon}`]: {marginRight: '12px'},
}));

function DeploymentServiceAccountPage() {
  const {successSnackbar} = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );

  // pagination and displaying service account
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  useEffect(() => {
    setOffset(page * limit);
  }, [limit, page]);
  const {data, isFetching} = useQueryDeploymentServiceAccount(
    deploymentId,
    limit,
    offset,
    {
      placeholderData: {serviceAccounts: [], total: 0},
      onError: (err) => {
        dispatch(handleApiError(err));
      },
    }
  );
  // delete service account
  const {mutate: deleteServiceAccount} = useDeleteServiceAccount({
    onSuccess: () => {
      // Invalidate every query with a key that starts with serviceAccountQueryKey
      queryClient.invalidateQueries({
        queryKey: [serviceAccountQueryKey],
      });
      successSnackbar('Service Account was deleted.');
    },
    onError: (err) => {
      dispatch(handleApiError(err));
    },
  });
  const handleDeleteServiceAccount = (serviceAcc) => {
    dispatch(
      openDialog(
        'Delete Service Account?',
        `Are you sure you want to delete Service Account ${serviceAcc.keyName}?`,
        () => deleteServiceAccount(serviceAcc.keyId)
      )
    );
  };

  // searching service account by keyword
  const [serviceAccountsSearchTerm, setServiceAccountsSearchTerm] =
    useState('');
  const handleSearchInputChange = (event) => {
    const searchInputText = event.target.value || '';
    setServiceAccountsSearchTerm(searchInputText);
  };
  let displayServiceAccounts = data ? data.serviceAccounts : [];
  displayServiceAccounts = displayServiceAccounts.filter((serviceAccount) =>
    serviceAccount.keyName.includes(serviceAccountsSearchTerm.trim())
  );

  // display new deployment service account dialog
  // logics around displaying new deployment service account
  const [newDeploymentSACred, setNewDeploymentSACred] = useState(undefined);
  const {credentials} = useSelector((state) => state.deploymentSACred);
  const [displayAccountDialog, setDisplayAccountDialog] = useState(false);
  useEffect(() => {
    if (credentials) {
      setNewDeploymentSACred(credentials);
      setDisplayAccountDialog(true);
      // clear credential from redux after it is loaded into state
      dispatch(clearDeploymentSACred());
    }
  }, [credentials]);

  const tableHeaders = [
    {
      id: 'keyId',
      label: 'ID',
    },
    {
      id: 'keyName',
      label: 'Name',
    },
    {
      id: 'roleName',
      label: 'Role',
    },
    {
      id: 'roleType',
      label: 'Type',
    },
    {
      id: 'lastSignedIn',
      label: 'Last signed in',
    },
    {
      id: 'expiresOn',
      label: 'Expires on',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'createdOn',
      label: 'Created on',
    },
  ];

  return (
    <>
      <PageHeader
        titleText="Deployment Service Account"
        descriptionText={DEPLOYMENT_SERVICE_ACCOUNT_DESCRIPTION}
        actionButton={
          <AddButton
            data-testid="button-create-deployment-sa"
            onClick={() => {
              history.push(CREATE_DEPLOYMENT_SERVICE_ACCOUNTS_LINK);
            }}
          >
            <AddIcon className={classes.addIcon} />
            {CREATE_DEPLOYMENT_SERVICE_ACCOUNT}
          </AddButton>
        }
      />
      {newDeploymentSACred && (
        <ServiceAccountDownload
          isDeploymentSA
          serviceAccount={newDeploymentSACred}
          open={displayAccountDialog}
          onClose={() => {
            setNewDeploymentSACred(undefined);
            setDisplayAccountDialog(false);
          }}
          onCopyToClipboard={() => {
            successSnackbar(
              'Deployment service account has been copied to your clipboard.'
            );
          }}
          filenameToSave="anyware-manager-deployment-service-account.json"
        />
      )}
      <ServiceAccountsTable
        handleSearchInputChange={handleSearchInputChange}
        serviceAccounts={displayServiceAccounts}
        onRemove={handleDeleteServiceAccount}
        isLoading={isFetching}
        headers={tableHeaders}
      />
      <CAMPagination
        dataTestId="dsa-table-pagination"
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        total={typeof data?.total === 'number' ? data.total : 0}
        rowsPerPage={limit}
        page={page}
        onPageChange={(_, pageNum) => setPage(pageNum)}
        onRowsPerPageChange={({target: {value}}) => {
          const newLimit = parseInt(value, 10);
          setPage(0);
          setLimit(newLimit);
        }}
      />
    </>
  );
}

export default DeploymentServiceAccountPage;
