import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {CircularProgress} from '@mui/material';

const PREFIX = 'CAMButton';

const classes = {
  button: `${PREFIX}-button`,
  progress: `${PREFIX}-progress`
};

const StyledButton = styled(Button)(() => ({
  [`& .${classes.button}`]: {
    width: 'auto',
    position: 'relative',
  },

  [`& .${classes.progress}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

function CAMButton({
  color,
  buttonText,
  onClick,
  disabled,
  size,
  textOnly,
  testId,
  customClass,
  variant,
  startIcon,
  isLoading,
}) {

  const buttonProps = {
    size,
    disabled: disabled || isLoading,
    'data-testid': testId,
    className: customClass || classes.button,
    onClick,
    color,
    variant,
    startIcon,
  };

  if (textOnly) {
    delete buttonProps.variant;
  }

  if (!startIcon) {
    delete buttonProps.startIcon;
  }

  return (
    <StyledButton {...buttonProps}>
      {buttonText}
      {isLoading && <CircularProgress size={24} className={classes.progress} />}
    </StyledButton>
  );
}

CAMButton.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  buttonText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  textOnly: PropTypes.bool,
  testId: PropTypes.string,
  customClass: PropTypes.any,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  startIcon: PropTypes.object,
  isLoading: PropTypes.bool,
};

CAMButton.defaultProps = {
  color: 'primary',
  disabled: false,
  size: 'medium',
  buttonText: 'Ok',
  textOnly: false,
  testId: 'save-button',
  customClass: null,
  variant: 'outlined',
  startIcon: null,
  isLoading: false,
};

export default CAMButton;
