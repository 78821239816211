import {CLEAR_STORE} from 'redux/actions/globalActions';
import {
  DISPLAY_DEPLOYMENT_SA_CRED,
  CLEAR_DEPLOYMENT_SA_CRED,
} from 'redux/actions/deploymentSACredActions';

const defaultState = {
  credentials: undefined,
};

function deploymentSACredReducer(state = defaultState, action) {
  switch (action.type) {
    case DISPLAY_DEPLOYMENT_SA_CRED:
      return {
        credentials: action.credentials,
      };
    case CLEAR_STORE:
    case CLEAR_DEPLOYMENT_SA_CRED:
      return defaultState;
    default:
      return state;
  }
}

export default deploymentSACredReducer;
