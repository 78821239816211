/*

I added this as way to reduce the code duplication while we find time to work
a full restyling of the application with the support of UX team.
When we do this, we will be able to define the reusable styles in a single place.
For now, this allows us to define the styles in a single place and reuse
them on the few pages we are changing.
*/

// These styles were created by copying the styles from Figma and converting them to JSS.
export const stylesFromFigma = {
  textPrimary: {
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },

  typographyBody1: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.150px',
    textAlign: 'left',
  },
  typographyBody2: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.17px',
    textAlign: 'left',
  },
  typographyH4: {
    fontFamily: 'Roboto',
    fontSize: '34px',
    fontWeight: 400,
    lineHeight: '42px',
    letterSpacing: '0.25px',
    textAlign: 'left',
  },

  typographyH6: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '0.150px',
    textAlign: 'left',
  },
  capabilitySubHeader: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
    color: '#23242A',
  },
  capabilitySubHeader2: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '130%',
    color: '#23242A',
  },
  capabilitySectionTitle: {
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '130%',
    color: '#101112',
  },
  dialogTitle: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  card: {
    padding: '16px 0px',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
  },
  helperText: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
    left: '-14px',
    position: 'relative',
  },
  textSubtitle2Styles: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.1px',
    textAlign: 'left',
  },

  codeBlockStyle: {
    fontFamily: 'IBM Plex Mono',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.32px',
    textAlign: 'left',
  },

  noteBlockStye: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '32px',
    textAlign: 'left',
  },
  labelText: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    color: '#555967',
  },
  chipTags: {
    grey: {
      backgroundColor: '#F8F8FB',
      color: '#555967',
      borderColor: '#E3E4E8',
    },
  },
  statusChip: {
    height: '24px',
    minWidth: '64px',
    label: {
      padding: '4px 5px',
      fontWeight: 800,
      fontSize: '12px',
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      leadingTrim: 'both',
      textEdge: 'cap',
    },
    success: {
      backgroundColor: '#EAFAF4',
      color: '#176947',
    },
    warning: {
      backgroundColor: '#FEF3E7',
      color: '#844D0A',
    },
    error: {
      backgroundColor: '#FDEAE8',
      color: '#833226',
    },
    installationFailed: {
      backgroundColor: '#833226',
      color: '#FFFFFF',
    },
  },
  connectorPanelSubheader: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  connectorPanelBodyText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.1px',
    color: '#555967',
  },
  primaryActionButton: {
    backgroundColor: '#0D47A1',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#0a3981',
    },
  },
  deleteButton: {
    color: '#A74132',
    backgroundColor: '#fff',
    border: '1px solid #A74132',
    borderRadius: '4px',
    boxShadow: 'none !important',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  editButton: {
    color: '#101112',
    backgroundColor: '#fff',
    border: '1px solid #C7C9D1',
    borderRadius: '4px',
    boxShadow: 'none !important',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
    textTransform: 'none',
    marginLeft: '8px',
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
};
