import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';

// A theme with custom primary and secondary color.
// It's optional.
export const theme = createTheme({
  palette: {
    primary: {
      light: '#2D74D8',
      main: '#245FB2',
      dark: '#1B4A8C',
      sidebar: '#1B4A8C',
      100: '#b3e4f3',
      200: '#83d3ec',
      300: '#56c1e4',
      400: '#35b4e1',
      50: '#e1f5fa',
      500: '#17a7dd',
      600: '#0d9acf',
      700: '#0087BC',
      800: '#0076a9',
      900: '#005788',
    },
    secondary: {
      // not updated - defaults
      light: '#ff4081',
      main: '#f50057',
      dark: '#c51162',
      contrastText: '#fff',
    },
    // Custom attributes and may be moved to specific component.
    menu: {
      active: '#00467e',
    },
    surface: {
      white: '#fff',
      light: '#fafafa',
      grey: 'rgba(0,0,0,0.6)',
      main: '#f3f4f5',
      dark: '#f5f5f5',
      300: '#f0f0f0',
      400: '#ececec',
      newBackgroundGrey: '#f0f4f8',
    },
    success: {
      light: '#d1e7dd',
      main: '#04844B',
      dark: '#1e7e34',
      100: '#d7edd5',
      200: '#c2e3bf',
    },
    info: {
      main: '#706E6B',
    },
    warning: {
      light: '#ffefd9',
      main: '#FF9600',
      dark: '#cc7800',
      contrastText: '#fff',
      100: '#fff9c4',
      200: '#fff59d',
    },
    error: {
      light: '#f8d7da',
      main: '#C23934',
      200: '#ffc7cc',
    },
    text: {
      default: 'rgba(0,0,0,0.87)',
      secondary: 'rgba(0,0,0,0.60)',
    },
    dialog: {
      warning: '#FF6125',
    },
    divider: '#d8d8d8',
    betaPrimary: {
      main: '#0D47A1',
      dark: '#0a3981',
    },
  },
  sizes: {
    sidebarExpandedWidth: '240px',
    sidebarCollapsedWidth: '55px',
    topBarHeight: '80px',
    topBarElementHeight: '48px',
  },
  typography: {
    useNextVariants: true,
    h5: {
      fontSize: '1.125rem',
    },
    h6: {
      lineHeight: 1.25,
    },
  },
  zIndex: {
    appBar: 1100,
    drawer: 1200,
    mobileStepper: 1000,
    modal: 1300,
    snackbar: 1300,
    tooltip: 1500,
  },
  createPage: {
    createPageContainer: {
      padding: '30px 14px 0px',
      minHeight: '90vh',
    },
    createPageTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      minWidth: '450px',
      height: '72px',
      paddingLeft: '5px',
    },
    createPageTitle: {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: 'Roboto',
      fontSize: '1.5rem',
      lineHeight: '1.75rem',
    },
    inputSectionSubtitle: {
      textTransform: 'uppercase',
      color: 'rgba(0,0,0,0.87)',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '1.25px',
      lineHeight: '16px',
      // marginTop: '25px',
    },
    inputTextFieldLabel: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      letterSpacing: '0.25px',
      lineHeight: '20px',
      marginTop: '20px',
    },
    helpQuestion: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      letterSpacing: '0.25px',
      lineHeight: '20px',
      marginTop: '5px',
      textDecoration: 'underline',
      textDecorationStyle: 'dotted',
    },
    buttonRow: {
      width: '100%',
      display: 'flex',
      gap: '16px',
      justifyContent: 'flex-end',
    },
    button: {
      // margin: '20px 12px',
      height: '36px',
      width: '108px',
    },
  },
  dropDown: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 32,
    paddingRight: 8,
    fontSize: 14,
  },
  loadingSpinner: {
    position: 'absolute',
    right: '50px',
  },
  overrides: {
    MuiDrawer: {
      docked: {
        flex: '0 0 auto',
        height: '100%',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {backgroundColor: 'transparent'},
      },
    },
    MuiListItem: {
      button: {
        '&:focus': {backgroundColor: 'none'},
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiDialog: {
      transitionDuration: 0,
    },
    MuiFormControl: {
      title: '',
    },
    MuiInputBase: {
      spellCheck: false,
    },
  },
});

// Expose the theme as a global variable so people can play with it.
window.theme = theme;

// MuiThemeProvider makes the theme available down the React tree thanks to React context.
export default (Component) =>
  function (props) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Component {...props} />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  };
