import FormControl from '@mui/material/FormControl';
import {styled} from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';

const PREFIX = 'SelectMenu';

const classes = {
  formControl: `${PREFIX}-formControl`,
  selectRoot: `${PREFIX}-selectRoot`,
  selectedItem: `${PREFIX}-selectedItem`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.formControl}`]: {
    width: '100%',
  },

  [`& .${classes.selectRoot}`]: {
    alignItems: 'center',
    display: 'flex',
    height: '36px',
    padding: '0px 14px',
  },

  [`& .${classes.selectedItem}`]: {
    color: theme.palette.primary.main,
  },
}));

function SelectMenu({
  label,
  value,
  menuList,
  tooltipText,
  onChange,
  autoSelectItem,
  testId,
  disabled,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (event) => setAnchorEl(event.currentTarget);
  const onClose = () => setAnchorEl(null);

  useEffect(() => {
    if (autoSelectItem && menuList.length > 0) {
      onChange(menuList[0].value);
    }
  }, [JSON.stringify(menuList)]);

  const menuProps = {
    anchorEl,
    anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
    transformOrigin: {vertical: 'top', horizontal: 'center'},
    open: Boolean(anchorEl),
    style: {maxHeight: '50vh'},
    disableScrollLock: true,
  };

  return (
    <Root>
      <FormControl
        variant="outlined"
        classes={{
          root: classes.formControl,
        }}
        disabled={disabled}
      >
        {label && <InputLabel displayText={label} tooltipText={tooltipText} />}
        <Select
          value={value}
          onChange={(event) => onChange(event.target.value)}
          onOpen={onOpen}
          onClose={onClose}
          MenuProps={menuProps}
          classes={{
            root: classes.selectRoot,
          }}
          IconComponent={KeyboardArrowDown}
          data-testid={testId}
        >
          {menuList.map((item) => (
            <MenuItem
              value={item.value}
              key={`key-${item.value}`}
              classes={{
                selected: classes.selectedItem,
              }}
              data-testid={`${testId}-${item.value}`}
            >
              {item.displayElement}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Root>
  );
}

SelectMenu.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  menuList: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      displayElement: PropTypes.node,
    })
  ),
  autoSelectItem: PropTypes.bool,
  tooltipText: PropTypes.string,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
};

SelectMenu.defaultProps = {
  label: 'Default Label',
  value: 'value',
  menuList: [],
  autoSelectItem: false,
  tooltipText: '',
  testId: 'select-menu',
  disabled: false,
};

export default SelectMenu;
