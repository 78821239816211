import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import validator from 'validator';
import SearchableSelect from 'components/CAM/inputs/SearchableSelect/SearchableSelect';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';
import PartialMatchSelect from 'components/common/PartialMatchSelect';
import {useAbortController} from 'hooks/useAbortController';
import {clearDropdownData, fetchNextPage} from 'redux/actions/dataActions';
import {selectDropdownData, selectVariable} from 'utils/reduxSelectors';
import {AD_USERS} from 'utils/constants';
import {resourceToSearchInfoMap} from 'utils/Mappings';
import ToolbarSelect from 'components/table/ToolbarSelect';
import UsersTable from './UsersTable';

const PREFIX = 'UnwrappedAddRemoveUser';

const classes = {
  root: `${PREFIX}-root`,
  addCell: `${PREFIX}-addCell`,
  leftSideContainer: `${PREFIX}-leftSideContainer`,
  searchContainer: `${PREFIX}-searchContainer`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputTextFieldLabel: `${PREFIX}-inputTextFieldLabel`,
  optionValue: `${PREFIX}-optionValue`,
  infoIcon: `${PREFIX}-infoIcon`,
  errorIcon: `${PREFIX}-errorIcon`
};

const StyledGrid = styled(Grid)({
  [`&.${classes.root}`]: {
    overflow: 'visible',
  },
  [`& .${classes.addCell}`]: {
    marginLeft: '10px',
  },
  [`& .${classes.leftSideContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.searchContainer}`]: {
    flexGrow: 1,
  },
  [`& .${classes.searchIcon}`]: {
    verticalAlign: 'middle',
    opacity: 0.6,
  },
  [`& .${classes.inputTextFieldLabel}`]: {
    fontSize: '1em',
    lineHeight: '15px',
    marginTop: 0,
  },
  [`& .${classes.optionValue}`]: {color: '#444', marginLeft: '10px'},
  [`& .${classes.infoIcon}`]: {
    fontSize: 'small',
    cursor: 'pointer',
    color: 'orange',
    marginLeft: '10px',
    verticalAlign: 'middle',
  },
  [`& .${classes.errorIcon}`]: {
    fontSize: 'small',
    cursor: 'pointer',
    color: 'red',
    marginLeft: '10px',
    verticalAlign: 'middle',
  },
});

function UnwrappedAddRemoveUser({
  addedUsers,
  deploymentId,
  disabled,
  disabledMessage,
  onAddUser,
  onRemoveUser,
  onChangeUser,
  isEditing,
}) {

  const dispatch = useDispatch();
  const [textInput, setTextInput] = useState('');

  const [abortController, resetAbortController] = useAbortController();

  const {data, isFetching: fetchingUsers} = useSelector((state) =>
    selectDropdownData(state, 'adUsers')
  ) || {data: {}, isFetching: false};

  const adUsers = Object.values(data);

  const usePartialSearch =
    useSelector((state) =>
      selectVariable(state, `usePartialSearchBy${AD_USERS}`)
    ) ?? resourceToSearchInfoMap[AD_USERS].defaultUsePartialSearch;
  const searchTermBy =
    useSelector((state) => selectVariable(state, `searchTermBy${AD_USERS}`)) ||
    resourceToSearchInfoMap[AD_USERS].defaultValue;

  const fetchUsers = (text) => {
    resetAbortController();
    dispatch(
      fetchNextPage(
        'adUsers',
        {
          [searchTermBy]: text,
          [`${searchTermBy}Filter`]: usePartialSearch ? 'includes' : '',
          deploymentId,
        },
        100,
        {signal: abortController.current.signal}
      )
    );
  };

  const searchAdUsers = useCallback(
    (text) => {
      if (deploymentId) {
        dispatch(clearDropdownData('adUsers'));
        fetchUsers(text);
      }
    },
    [deploymentId, fetchUsers]
  );

  const [selectedUsers, setSelectedUsers] = useState([]);

  const usersToExclude = isEditing ? addedUsers : selectedUsers;

  const usersToSelect = () => {
    let options = [];
    // filter out added users
    const filteredUsers = adUsers
      .filter(
        (user) =>
          user.deploymentId === deploymentId &&
          !usersToExclude.find((u) => u.userGuid === user.userGuid)
      )
      .map((user) => ({
        label: `${user.name} | ${user.userName}`,
        value: user.userGuid,
        data: user,
      }));
    options = [...filteredUsers];

    if (!fetchingUsers) {
      if (textInput && filteredUsers.length === 0) {
        options.push({value: textInput, data: {upn: textInput}});
      }
    }

    return options;
  };

  useEffect(() => {
    setSelectedUsers([]);
    if (deploymentId) {
      dispatch(clearDropdownData('adUsers'));
      resetAbortController();
      dispatch(fetchNextPage('adUsers', {deploymentId}, 100), {
        signal: abortController.current.signal,
      });
    }
  }, [deploymentId]);

  const addUsers = () => {
    onAddUser(selectedUsers.map((user) => user.data));
    if (isEditing) {
      setSelectedUsers([]);
    }
  };

  const handleChange = (users) => {
    setSelectedUsers(users || []);
    onChangeUser(users);
    if (!isEditing) {
      addUsers();
    }
  };

  const handleTextInput = useCallback((text) => {
    setTextInput(text);
    searchAdUsers(text);
  });

  const handleScrollToBottom = (inputText) => {
    searchAdUsers(inputText);
  };

  const isOptionDisabled = (option) => {
    if (option.data.upn && !option.data.userName) {
      return !validator.isEmail(option.data.upn);
    }
    return false;
  };

  const formatOptionLabel = (option, {context}) => {
    const {name, userName, enabled, upn} = option.data || {};

    const value = (
      <Typography variant="caption" className={classes.optionValue}>
        {`${name || upn}`}
      </Typography>
    );

    const iconDisabled = (
      <Tooltip
        title={`${userName} is currently disabled on the Active Directory`}
        placement="right"
      >
        <ErrorOutlineIcon className={classes.errorIcon} />
      </Tooltip>
    );

    const iconUpn = (
      <Tooltip title="This will add a UPN entitlement." placement="right">
        <InfoOutlined className={classes.infoIcon} />
      </Tooltip>
    );

    // Menu for selection
    if (context === 'menu') {
      if (upn && !userName) {
        let upnLabelStr;
        if (validator.isEmail(upn)) {
          upnLabelStr = 'Entitle UPN:';
        } else {
          upnLabelStr = 'Invalid UPN:';
        }
        return (
          <>
            {upnLabelStr}
            {value}
          </>
        );
      }
      return (
        <>
          {userName}
          {value}
          {!enabled && ' [disabled on the Active Directory]'}
        </>
      );
    }

    // List of elements in the search bar
    return (
      <>
        {userName || upn}
        {upn && !userName ? iconUpn : !enabled && iconDisabled}
      </>
    );
  };

  const SearchBar = (
    <SearchableSelect
      data-testid="search-user"
      disabledMessage={disabledMessage}
      emptyMessage="No Users"
      disabled={disabled}
      isLoading={fetchingUsers}
      isMulti
      onChange={handleChange}
      onTextInput={handleTextInput}
      onMenuScrollToBottom={handleScrollToBottom}
      options={usersToSelect()}
      value={selectedUsers}
      formatOptionLabel={formatOptionLabel}
      isOptionDisabled={isOptionDisabled}
      placeholderText="Search for synced Active Directory users, or enter a valid UPN"
      clearOnSelect={validator.isEmail(textInput)}
    />
  );

  const AddButton = (
    <Button
      data-testid="add-user-button"
      className={classes.addCell}
      onClick={addUsers}
      disabled={selectedUsers.length === 0 || disabled}
      color="primary"
      variant="contained"
    >
      Add
    </Button>
  );
  return (
    <StyledGrid container className={classes.root}>
      <InputLabel displayText="Select users" />
      <Grid item xs={12} justifyContent="space-between" alignItems="center">
        <div className={classes.leftSideContainer}>
          <ToolbarSelect resource={AD_USERS} />
          <PartialMatchSelect resource={AD_USERS} />
          <div className={classes.searchContainer}>{SearchBar}</div>
          {isEditing && AddButton}
        </div>
      </Grid>
      {isEditing && (
        <Grid item xs={12}>
          <UsersTable users={addedUsers} onRemove={onRemoveUser} />
        </Grid>
      )}
    </StyledGrid>
  );
}

UnwrappedAddRemoveUser.propTypes = {
  addedUsers: PropTypes.array,
  onAddUser: PropTypes.func,
  onRemoveUser: PropTypes.func,
  onChangeUser: PropTypes.func,
  disabled: PropTypes.bool,
  deploymentId: PropTypes.string,
  disabledMessage: PropTypes.string,
  isEditing: PropTypes.bool,
};

UnwrappedAddRemoveUser.defaultProps = {
  addedUsers: [],
  disabled: false,
  deploymentId: undefined,
  disabledMessage: 'Connector required',
  isEditing: false,
  onAddUser: () => {},
  onChangeUser: () => {},
  onRemoveUser: () => {},
};

export default UnwrappedAddRemoveUser;
