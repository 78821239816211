import {useEffect, useRef} from 'react';

function useInterval(callback, delay, immediate = false) {
  const savedCallback = useRef();
  const savedId = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (immediate && typeof savedCallback.current === 'function') {
      savedCallback.current();
    }
  }, []);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      savedId.current = setInterval(tick, delay);
    } else {
      // If delay is null, clear the interval.
      clearInterval(savedId.current);
    }
    return () => clearInterval(savedId.current);
  }, [delay]);
}

export default useInterval;
