import PropTypes from 'prop-types';
import AWMBanner from 'components/CAM/display/AWMBanner/AWMBanner';
import {BETA_SNACKBAR_DETAILS, BETA_SNACKBAR_MESSAGE} from 'utils/constants';

function BetaBanner({onClick}) {
  return (
    <AWMBanner
      message={BETA_SNACKBAR_MESSAGE}
      details={BETA_SNACKBAR_DETAILS}
      tagName="Beta"
      variant="warning"
      dataTestId="beta-banner"
      linkObject={{
        href: 'https://www.teradici.com/web-help/cas_manager_as_a_service/beta/overview/',
        label: 'Learn More',
      }}
      onClick={onClick}
      showIcon={false}
    />
  );
}

BetaBanner.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BetaBanner;
