import {ReactComponent as InfoIconBlack} from 'icons/info-icon-black.svg';
import {ReactComponent as InfoIconBlue} from 'icons/info-icon-blue.svg';
import {ReactComponent as SuccessIconGreen} from 'icons/success-icon-green.svg';
import {ReactComponent as WarningIconRed} from 'icons/warning-icon-red.svg';
import {ReactComponent as WarningIconYellow} from 'icons/warning-icon-yellow.svg';
import PropTypes from 'prop-types';
import {
  ERROR_STATUS,
  GENERIC_STATUS,
  INFO_STATUS,
  SUCCESS_STATUS,
  WARNING_STATUS,
} from 'utils/constants';

function AWMColoredFeedbackIcon({variant, ...props}) {
  switch (variant) {
    case ERROR_STATUS:
      return <WarningIconRed {...props} />;
    case WARNING_STATUS:
      return <WarningIconYellow {...props} />;
    case INFO_STATUS:
      return <InfoIconBlue {...props} />;
    case SUCCESS_STATUS:
      return <SuccessIconGreen {...props} />;
    case GENERIC_STATUS:
    default:
      return <InfoIconBlack {...props} />;
  }
}

AWMColoredFeedbackIcon.propTypes = {
  variant: PropTypes.oneOf([
    WARNING_STATUS,
    SUCCESS_STATUS,
    GENERIC_STATUS,
    INFO_STATUS,
    ERROR_STATUS,
  ]),
};

AWMColoredFeedbackIcon.defaultProps = {
  variant: INFO_STATUS,
};

export default AWMColoredFeedbackIcon;
