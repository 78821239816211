import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toggleDateFilter} from 'redux/actions/dateFilterActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {resourceToSearchInfoMap} from 'utils/Mappings';
import {SESSION_AUDIT} from 'utils/constants';
import {
  selectSelectedDeployment,
  selectTableParams,
  selectVariable,
} from 'utils/reduxSelectors';

const useSessionAuditFilter = ({enabled, searchTerm}) => {
  const dispatch = useDispatch();
  const {params} = useSelector((state) =>
    selectTableParams(state, SESSION_AUDIT)
  );
  const {deploymentId} = useSelector(selectSelectedDeployment);
  const {page, rowsPerPage} = useSelector(
    (state) => state.tableData[SESSION_AUDIT]
  ) || {page: 0, rowsPerPage: 10};
  const {enabled: dateFilterEnabled, params: dateFilterParams} = useSelector(
    (state) => state.dateFilter
  ) || {enabled: false, params: {}};
  const usePartialSearch =
    useSelector((state) =>
      selectVariable(state, `usePartialSearchBy${SESSION_AUDIT}`)
    ) ?? resourceToSearchInfoMap[SESSION_AUDIT].defaultUsePartialSearch;

  useEffect(() => {
    if (enabled) {
      const newParams = {};

      // Date filter
      if (dateFilterEnabled) {
        newParams.from = dateFilterParams?.from;
        newParams.to = dateFilterParams?.to;
      }

      dispatch(
        fetchTableData(
          SESSION_AUDIT,
          0,
          rowsPerPage,
          newParams,
          searchTerm,
          usePartialSearch
        )
      );
    }
  }, [
    deploymentId,
    dateFilterEnabled,
    JSON.stringify(dateFilterParams),
    searchTerm,
  ]);

  const clearFilters = () => {
    if (dateFilterEnabled) {
      dispatch(toggleDateFilter());
    }
  };

  const refresh = () => {
    dispatch(
      fetchTableData(
        SESSION_AUDIT,
        page,
        rowsPerPage,
        params,
        searchTerm,
        usePartialSearch
      )
    );
  };

  return {
    refresh,
    clearFilters,
  };
};

export default useSessionAuditFilter;
