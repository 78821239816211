import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import ReactSelect from 'react-select';
import {Card} from '@mui/material/index';
import {post} from 'api/Api';
import GridN from 'components/CAM/layout/GridN/GridN';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';
import config from 'config';
import useNameValidation from 'hooks/useNameValidation';
import usePendingChanges from 'hooks/usePendingChanges';
import useSnackbar from 'hooks/useSnackbar';
import {
  clearResource,
  fetchMultipleConnectorSettings,
  fetchConnectorSettings,
  fetchResource,
  logoutUserFromCAM,
} from 'redux/actions/dataActions';
import {openWarningDialog} from 'redux/actions/errorDialogActions';
import {goBack} from 'redux/ReduxHistory';
import {
  selectData,
  selectResourceItem,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import {isEmpty, sanitizeValues} from 'utils/utils';

import {
  CLONE_CONNECTOR_TOOLTIP,
  CONNECTOR_HEALTHY_STATUS,
  CONNECTORS,
  CONNECTOR_SETTINGS,
  MULTIPLE_CONNECTOR_SETTINGS,
} from 'utils/constants';
import ConnectorInstallInstructions from './ConnectorInstallInstructions';

const PREFIX = 'CreateConnector';

const classes = {
  root: `${PREFIX}-root`,
  dropdownMenuItem: `${PREFIX}-dropdownMenuItem`,
  pageContainer: `${PREFIX}-pageContainer`,
  pageTitleContainer: `${PREFIX}-pageTitleContainer`,
  pageTitle: `${PREFIX}-pageTitle`,
  iconsInSelects: `${PREFIX}-iconsInSelects`,
  inputTextFieldLabel: `${PREFIX}-inputTextFieldLabel`,
  inputSectionTextField: `${PREFIX}-inputSectionTextField`,
  inputSectionStringTextField: `${PREFIX}-inputSectionStringTextField`,
  input: `${PREFIX}-input`,
  itemButtonRow: `${PREFIX}-itemButtonRow`,
  itemButton: `${PREFIX}-itemButton`,
  loadingSpinner: `${PREFIX}-loadingSpinner`,
  domainText: `${PREFIX}-domainText`,
  cloneConnectorSelect: `${PREFIX}-cloneConnectorSelect`,
  unhealthyConnectorWarning: `${PREFIX}-unhealthyConnectorWarning`,
};

const Root = styled(Card)(({theme}) => ({
  [`&.${classes.root}`]: {
    margin: '0.6em 0',
    maxWidth: '1790px',
    overflow: 'visible',
    padding: '10px',
  },
  [`& .${classes.dropdownMenuItem}`]: {
    ...theme.dropDown,
    paddingLeft: '8px',
  },
  [`&.${classes.pageContainer}`]: theme.createPage.createPageContainer,
  [`& .${classes.pageTitleContainer}`]:
    theme.createPage.createPageTitleContainer,
  [`& .${classes.pageTitle}`]: theme.createPage.createPageTitle,

  [`& .${classes.iconsInSelects}`]: {
    width: '18px',
    marginRight: '8px',
  },

  [`& .${classes.inputTextFieldLabel}`]: theme.createPage.inputTextFieldLabel,

  [`& .${classes.inputSectionTextField}`]: {
    backgroundColor: theme.palette.surface.white,
    marginRight: '20px',
  },

  [`& .${classes.inputSectionStringTextField}`]: {
    marginRight: '20px',
    backgroundColor: 'inherit',
  },

  [`& .${classes.input}`]: {
    backgroundColor: theme.palette.surface.white,
    width: '320px',
    height: '36px',
    marginRight: '20px',
  },

  [`& .${classes.itemButtonRow}`]: theme.createPage.buttonRow,
  [`& .${classes.itemButton}`]: theme.createPage.button,
  [`& .${classes.loadingSpinner}`]: theme.loadingSpinner,

  [`& .${classes.domainText}`]: {
    fontWeight: 500,
    padding: '12px 0px 12px 12px',
  },

  [`& .${classes.cloneConnectorSelect}`]: {
    width: '320px',
  },

  [`& .${classes.unhealthyConnectorWarning}`]: {
    color: 'orange',
  },
}));

const domainConfigurationMessage =
  'This deployment is already configured to sync users and computers from the following domain:';
const domainWarningMessage =
  'If the domain specified to the Connector at install time is different from the current configuration, the new connector will attempt to overwrite configuration settings which may cause conflicts.';
const unhealthyConnectorSelectedForClone =
  'Cloning a connector that is not healthy can potentially fail. We recommend selecting a healthy connector to clone from.';

function CreateConnector() {
  const dispatch = useDispatch();

  const {setPendingChanges} = usePendingChanges();
  const {successSnackbar, errorSnackbar} = useSnackbar();
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const [selectedCloneConnector, setSelectedCloneConnector] = useState(null);
  const [connectorName, setConnectorName] = useState('');
  const {data: connectors, isFetching: fetchingConnectors} = useSelector(
    (state) => selectData(state, CONNECTORS)
  );

  const {item: connectorSettings} = useSelector((state) =>
    selectResourceItem(state, CONNECTOR_SETTINGS, deploymentId)
  );

  const {data: connectorsSettings, isFetching: fetchingConnectorSettings} =
    useSelector((state) => selectData(state, MULTIPLE_CONNECTOR_SETTINGS));

  const [warningMessageComponent, setWarningMessageComponent] = useState(null);
  const [connectorToken, setConnectorToken] = useState('');
  const [failure, setFailure] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [nameValidationError, validateName, nameHelperText] =
    useNameValidation();
  const [connectorsToDisplay, setConnectorsToDisplay] = useState([]);

  const inputValidated = Boolean(
    connectorName && !nameValidationError && !failure
  );
  const location = useLocation();
  const setCloneConnectorFromLocation = () => {
    if (location?.state?.cloneConnector) {
      const {cloneConnector} = location.state;
      setSelectedCloneConnector(cloneConnector);
      setConnectorName(`${cloneConnector.connectorName} copy`);
      location.state.cloneConnector = null;
    } else {
      setSelectedCloneConnector(null);
    }
  };

  const validateConnectorSettings = (depConnectorSettings) => {
    if (
      isEmpty(depConnectorSettings) ||
      // eslint-disable-next-line no-prototype-builtins
      !depConnectorSettings.hasOwnProperty('usersDN') ||
      isEmpty(depConnectorSettings.usersDN) ||
      !Array.isArray(depConnectorSettings.usersDN)
    ) {
      return false;
    }
    // check that first userDn is a valid dc string
    const firstUserDN = depConnectorSettings.usersDN[0];
    if (
      typeof firstUserDN !== 'string' ||
      firstUserDN === 'Not available' ||
      firstUserDN.toLowerCase().indexOf('dc=') === -1
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (!deploymentId) {
      return;
    }
    setConnectorToken('');
    setFailure(false);
    setWarningMessageComponent(null);
    setCloneConnectorFromLocation();
    dispatch(fetchConnectorSettings());
    dispatch(clearResource(CONNECTORS));
    dispatch(fetchResource(CONNECTORS, {deploymentId}));
  }, [deploymentId]);

  useEffect(() => {
    setPendingChanges(
      !isEmpty(connectorName) && isEmpty(connectorToken) && !isSaving
    );
  }, [connectorName, connectorToken, isSaving]);

  useEffect(() => {
    if (!fetchingConnectors) {
      dispatch(
        fetchMultipleConnectorSettings(
          connectors.map((connector) => connector.connectorId)
        )
      );
    }
  }, [fetchingConnectors]);

  // When the the connector settings for all connectors become available, the object used to display the
  // connectors for selection is created/changed.
  // This is done to allow the connectors without settings to be disabled.
  // It also changes the names so that the unhealthy or missing settings status of the connector is shown.
  useEffect(() => {
    if (
      !fetchingConnectorSettings &&
      !isEmpty(connectorsSettings) &&
      isEmpty(connectorsToDisplay)
    ) {
      const toDisplay = connectors.map((connector, index) => {
        const newConnector = connector;
        newConnector.isDisabled = false;
        newConnector.isHealthy = true;

        if (isEmpty(connectorsSettings[index].connector)) {
          newConnector.isDisabled = true;
        }
        if (connector.healthStatus !== CONNECTOR_HEALTHY_STATUS) {
          newConnector.isHealthy = false;
        }

        if (newConnector.isDisabled) {
          newConnector.connectorName = `${connector.connectorName} (no settings available)`;
        } else if (!newConnector.isHealthy) {
          newConnector.connectorName = `${connector.connectorName} (not healthy)`;
        }
        return newConnector;
      });
      setConnectorsToDisplay(toDisplay);
    }
  }, [connectorsSettings]);

  useEffect(() => {
    const deploymentConnectorSettings = connectorSettings?.deployment;
    if (validateConnectorSettings(deploymentConnectorSettings)) {
      const usersDnString =
        deploymentConnectorSettings.usersDN[0].toLowerCase();
      const domainStart = usersDnString.indexOf('dc=');
      const domainName = usersDnString
        .slice(domainStart)
        .replace(/dc=/g, '')
        .replace(/,/g, '.');

      const formattedMessage = (
        <>
          <div>{domainConfigurationMessage}</div>
          <div className={classes.domainText}>{domainName}</div>
          <div>{domainWarningMessage}</div>
        </>
      );
      setWarningMessageComponent(formattedMessage);
    }
  }, [connectorSettings]);

  const onWarningClick = () =>
    dispatch(
      openWarningDialog(
        'Deployment domain already configured',
        warningMessageComponent
      )
    );

  const handleErrorResponse = (error) => {
    let errorMessage = '';
    if (!error) {
      errorMessage = 'Unknown error occurred.';
    } else {
      if (error.data) {
        errorMessage = error.data.reason;
      } else {
        errorMessage = error.message;
      }
      if (error.code === 401) {
        dispatch(logoutUserFromCAM());
        return;
      }
    }

    if (!errorMessage && error.code) {
      switch (error.code) {
        case 403:
          errorMessage = 'You do not have sufficient privileges.';
          break;
        case 409:
          errorMessage =
            'A connector with this name already exists for this deployment.';
          break;
        case 500:
          errorMessage = 'Internal error occurred. Please try again.';
          break;
        default:
          errorMessage = 'Unknown error occurred.';
          break;
      }
    }
    errorSnackbar(errorMessage);
  };

  const getConnectorToken = async () => {
    setIsSaving(true);
    const data = {
      deploymentId,
      connectorName: sanitizeValues(connectorName),
    };
    if (selectedCloneConnector) {
      data.connectorId = selectedCloneConnector.connectorId;
    }

    try {
      const resp = await post({
        path: 'auth/tokens/connector',
        data,
      });
      setConnectorToken(resp.data.token);
    } catch (error) {
      setFailure(true);
      handleErrorResponse(error);
    }
    setIsSaving(false);
  };

  const handleCancel = () => dispatch(goBack());

  const onConnectorSelection = (value) => {
    setSelectedCloneConnector(value);
  };

  const renderCloneConnectorSelection = () => {
    let placeholder = 'Select a connector';
    if (fetchingConnectors) {
      placeholder = 'Loading connectors...';
    } else if (isEmpty(connectors)) {
      placeholder = 'No connectors are available';
    }

    return (
      <>
        <InputLabel
          displayText="Copy connector configuration from"
          tooltipText={CLONE_CONNECTOR_TOOLTIP}
        />
        <div
          data-testid="select-clone-connector"
          className={classes.cloneConnectorSelect}
        >
          <ReactSelect
            placeholder={placeholder}
            options={connectorsToDisplay}
            getOptionLabel={(c) => c.connectorName}
            getOptionValue={(c) => c}
            isClearable
            closeMenuOnSelect
            isDisabled={
              fetchingConnectors ||
              fetchingConnectorSettings ||
              isEmpty(connectors)
            }
            onChange={onConnectorSelection}
            value={selectedCloneConnector}
          />
        </div>

        {selectedCloneConnector && !selectedCloneConnector.isHealthy && (
          <p className={classes.unhealthyConnectorWarning}>
            {unhealthyConnectorSelectedForClone}
          </p>
        )}
      </>
    );
  };

  const handleConnectorNameChange = (event) => {
    const {value} = event.target;
    setConnectorName(value);
    setConnectorToken('');
    setFailure(false);
    validateName(value);
  };

  const tokenCopiedSnackbar = () => {
    successSnackbar(
      `The token for connector "${connectorName}" has been copied to your clipboard. This token is only valid for 1 hour.`
    );
  };

  return (
    <Root className={classes.root}>
      <GridN singleColumn>
        <div className={classes.pageTitleContainer}>
          <Typography className={classes.pageTitle}>Connectors</Typography>
          <KeyboardArrowRight />
          <Typography className={classes.pageTitle}>
            Create a new connector
          </Typography>
        </div>

        <Typography className={classes.inputTextFieldLabel}>
          Connector name
        </Typography>
        <TextField
          data-testid="connector-name"
          className={classes.inputSectionStringTextField}
          autoComplete="off"
          required
          error={nameValidationError}
          helperText={nameHelperText}
          onChange={handleConnectorNameChange}
          margin="none"
          variant="outlined"
          value={connectorName}
          InputProps={{
            className: classes.input,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{'data-testid': 'connector-name-input'}}
        />
        {config.isBeta() && renderCloneConnectorSelection()}
        <ConnectorInstallInstructions
          generateToken={getConnectorToken}
          disableGenerate={!inputValidated || isSaving || !!connectorToken}
          connectorToken={connectorToken}
          onCopy={tokenCopiedSnackbar}
          warning={
            !!validateConnectorSettings(connectorSettings?.deployment) &&
            !!warningMessageComponent
          }
          onWarningClick={onWarningClick}
        />
        <div className={classes.itemButtonRow}>
          {isSaving && <CircularProgress mode="indeterminate" />}
          <Button
            variant="outlined"
            color="primary"
            className={classes.itemButton}
            onClick={handleCancel}
          >
            Close
          </Button>
        </div>
      </GridN>
    </Root>
  );
}

export default CreateConnector;
