import {get} from 'api/Api';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {USER_SESSIONS} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {selectSelectedDeployment} from 'utils/reduxSelectors';

const getUserSessions = async (deploymentId, userGuid) => {
  const path = mapResourceToPath(USER_SESSIONS, {deploymentId});
  const params = {userGuid};

  const {data} = await get({path, params});
  return data;
};

const useUserSessions = (userGuid, options = {}) => {
  const {deploymentId} = useSelector(selectSelectedDeployment);
  return useQuery(
    [deploymentId, userGuid, 'sessions'],
    () => getUserSessions(deploymentId, userGuid),
    options
  );
};

export default useUserSessions;
