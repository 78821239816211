/**
 * Params can be either a boolean or a string (reflected by the paramType field).
 * They can also have a "subConfig" field, indicating other config options that
 * are related to the original parameter.
 *
 * For example, Ad Sync Enabled has a subconfig array consisting of the SA Credentials
 * and AD Filters
 */

import validator from 'validator';
import {validateHostname, isEmpty, isDomain} from 'utils/utils';

export const activeDirectorySACredsParams = [
  {
    fieldName: 'serviceAccountUser',
    paramType: 'string',
    isSecret: false,
    flag: '--sa-user',
    label: 'Username',
    placeholder: 'awmAdmin',
    required: true,
    requiredWith: ['serviceAccountPassword'],
    keys: 'domain.serviceAccount.username',
  },
  {
    fieldName: 'serviceAccountPassword',
    paramType: 'string',
    isSecret: true,
    flag: '--sa-password',
    label: 'Password',
    placeholder: 'password',
    required: true,
    requiredWith: ['serviceAccountUser'],
    keys: 'domain.serviceAccount.password',
  },
];
export const activeDirectoryFilterParams = [
  {
    fieldName: 'computersDn',
    paramType: 'string',
    isSecret: false,
    flag: '--computers-dn',
    label: 'Computers DN',
    placeholder: '',
    required: false,
    keys: 'domain.adSync.computersDns',
  },
  {
    fieldName: 'computersFilter',
    paramType: 'string',
    isSecret: false,
    flag: '--computers-filter',
    label: 'Computers Filter',
    placeholder: '',
    required: false,
    keys: 'domain.adSync.computersFilters',
  },
  {
    fieldName: 'usersDn',
    paramType: 'string',
    isSecret: false,
    flag: '--users-dn',
    label: 'Users DN',
    placeholder: '',
    required: false,
    keys: 'domain.adSync.usersDns',
  },
  {
    fieldName: 'usersFilter',
    paramType: 'string',
    isSecret: false,
    flag: '--users-filter',
    label: 'Users Filter',
    placeholder: '',
    required: false,
    keys: 'domain.adSync.usersFilters',
  },
];

export const activeDirectoryConfigParams = [
  {
    fieldName: 'plaintextLdap',
    paramType: 'boolean',
    flag: '--enable-plaintext-ldap',
    default: true,
    keys: 'domain.enableLdapMode',
  },
  {
    fieldName: 'ldapsInsecure',
    paramType: 'boolean',
    flag: '--ldaps-insecure', // Not explicitly set by UI for now. Inferred from plaintextLdap setting
    default: false,
    keys: 'domain.insecure',
  },
  {
    fieldName: 'ldapsCaCert',
    paramType: 'string',
    isSecret: false,
    flag: '--ldaps-ca-cert-string',
    label: 'LDAPS CA Certificate',
    keys: 'domain.caCert',
  },
];

export const activeDirectorySyncParams = [
  {
    fieldName: 'enableAdSync',
    paramType: 'boolean',
    flag: '--enable-ad-sync',
    default: false,
    keys: 'domain.adSync.enable',
    subConfig: [
      ...activeDirectorySACredsParams,
      ...activeDirectoryFilterParams,
    ],
  },
];

export const mfaRadiusParams = [
  {
    fieldName: 'radiusServer',
    paramType: 'string',
    flag: '--radius-server',
    label: 'Hostname (FQDN or IP address)',
    placeholder: 'external.mfa.hp.com',
    required: true,
    isSecret: false,
    isValid: (serverInput) => validateHostname(serverInput),
    helperText: 'Hostname must be a valid FQDN or IP address',
    keys: 'multiFactorAuthentication.server',
  },
  {
    fieldName: 'radiusPort',
    paramType: 'number',
    flag: '--radius-port',
    label: 'RADIUS port',
    placeholder: '1812',
    required: true,
    isSecret: false,
    helperText: 'RADIUS port must be a number between 1 and 65535',
    isValid: (portInput) => {
      const hasNonDigit = /\D/g.test(portInput);
      const parsedRadiusPort = Number(portInput);
      const isPortValid =
        !hasNonDigit && parsedRadiusPort
          ? parsedRadiusPort >= 1 && parsedRadiusPort <= 65535
          : false;
      return isPortValid;
    },
    keys: 'multiFactorAuthentication.port',
  },
  {
    paramType: 'string',
    fieldName: 'radiusSecret',
    flag: '--radius-secret',
    label: 'RADIUS secret',
    placeholder: 'RADIUS secret',
    isSecret: true,
    required: true,
    keys: 'multiFactorAuthentication.sharedSecret',
  },
];

export const mfaConfigParams = [
  {
    fieldName: 'enableMfa',
    paramType: 'boolean',
    flag: '--enable-mfa',
    default: false,
    keys: 'multiFactorAuthentication.enable',
    subConfig: [...mfaRadiusParams],
  },
];

// OAuth configuration
// Reference: https://github.azc.ext.hp.com/Anyware-Manager/connector-installer/blob/master/commands/connector_config.go

export const oauthConfigParams = [
  {
    fieldName: 'idpUrl',
    paramType: 'string',
    flag: '--id-provider-url',
    label: 'OAuth provider authorization URL',
    placeholder: 'https://oauth-provider.com/oauth2/authorize',
    required: true,
    isSecret: false,
    isValid: (input) =>
      validator.isURL(input || '', {
        require_protocol: true,
        protocols: ['https'],
      }),
    helperText:
      'The authorization URL must be a valid URL provided by the OAuth provider, including the protocol (must be https://).',
    keys: 'oauth.idProviderUrl',
  },
  {
    fieldName: 'clientId',
    paramType: 'string',
    flag: '--oauth-client-id',
    label: 'Client configured on the OAuth provider',
    placeholder: 'Client ID',
    required: true,
    isSecret: false,
    isValid: (input) => !isEmpty(input),
    helperText: 'Client ID must be provided.',
    keys: 'oauth.idpAppClientId',
  },
  {
    fieldName: 'oauthServerCaString',
    paramType: 'string',
    isSecret: true,
    required: false,
    flag: '--oauth-server-ca-string',
    label: 'OAuth server CA certificate',
    keys: 'oauth.oauthCaCert',
  },
];

export const configServiceEnableParams = [
  {
    fieldName: 'enableConfigurationService',
    paramType: 'boolean',
    flag: '--enable-configuration-service',
    default: false,
    keys: 'configurationService.enable',
  },
];

export const oauthEnableParams = [
  {
    fieldName: 'enableOauth',
    paramType: 'boolean',
    flag: '--enable-oauth',
    default: false,
    keys: 'oauth.enabled',
    subConfig: [...oauthConfigParams],
  },
];

// SSO configuration

export const ssoWebEnrollmentParams = [
  {
    fieldName: 'ssoEnrollmentUsername',
    paramType: 'string',
    flag: '--sso-enrollment-username',
    label: 'Username',
    placeholder: 'SSO Enrollment Username',
    required: true,
    isSecret: false,
    isValid: (input) => !isEmpty(input),
    helperText: 'SSO Enrollment Username must be provided.',
    keys: 'oauth.ssoEnrollAccount',
  },
  {
    fieldName: 'ssoEnrollmentPassword',
    paramType: 'string',
    flag: '--sso-enrollment-password',
    label: 'Password',
    placeholder: 'SSO Enrollment Password',
    required: true,
    isSecret: true,
    isValid: (input) => !isEmpty(input),
    helperText: 'SSO Enrollment Password must be provided.',
    keys: 'oauth.ssoEnrollPassword',
  },
  {
    fieldName: 'ssoEnrollmentDomain',
    paramType: 'string',
    flag: '--sso-enrollment-domain',
    label: 'Domain',
    placeholder: 'SSO Enrollment Domain',
    required: true,
    isSecret: false,
    isValid: (input) => isDomain(input),
    helperText: 'This must be a valid domain name.',
    keys: 'oauth.ssoEnrollDomain',
  },
  {
    fieldName: 'ssoEnrollmentUrl',
    paramType: 'string',
    flag: '--sso-enrollment-url',
    label: 'URL',
    placeholder: 'SSO Enrollment URL',
    required: true,
    isSecret: false,
    isValid: (input) => validator.isURL(input || '', {require_protocol: true}),
    helperText:
      'SSO Certificate Authority Web Enrollment URL must be a valid URL, including the protocol (e.g. https://).',
    keys: 'oauth.ssoEnrollmentUrl',
  },
  {
    fieldName: 'ssoEnrollmentCertTemplateName',
    paramType: 'string',
    flag: '--sso-enrollment-certificate-template-name',
    label: 'Certificate Template Name',
    placeholder: 'SSO Enrollment Certificate Template Name',
    required: true,
    isSecret: false,
    isValid: (input) => !isEmpty(input),
    helperText: 'SSO Enrollment Certificate Template Name must be provided.',
    keys: 'oauth.ssoEnrollmentCertificateTemplate',
  },
];

export const ssoCACertificateParams = [
  {
    fieldName: 'ssoSigningCsrCaString',
    paramType: 'string',
    isSecret: true,
    required: true,
    flag: '--sso-signing-csr-ca-string',
    label: 'CA certificate to sign CSR from PCoIP Agent for SSO login.',
    keys: 'oauth.ssoCerts.ssoCaCert',
  },
  {
    fieldName: 'ssoSigningCsrKeyString',
    paramType: 'string',
    isSecret: true,
    required: true,
    flag: '--sso-signing-csr-key-string',
    label: 'Private key to sign CSR from PCoIP Agent for SSO login.',
    keys: 'oauth.ssoCerts.ssoCaKey',
  },
  {
    fieldName: 'ssoSigningCrlString',
    paramType: 'string',
    isSecret: true,
    required: true,
    flag: '--sso-signing-crl-string',
    label:
      'certificate revocation list to sign CSR from PCoIP Agent for SSO login.',
    keys: 'oauth.ssoCerts.ssoCaCrl',
  },
];

export const ssoEnableParams = [
  {
    fieldName: 'enableSSO',
    paramType: 'boolean',
    flag: '--enable-sso',
    default: false,
    keys: 'oauth.enableSSO',
  },
];

export const ingressCertParams = [
  {
    fieldName: 'ingressCert',
    required: true,
    paramType: 'string',
    isSecret: false,
    flag: '--tls-cert-string',
    label: 'Ingress Certificate',
    keys: 'tls.cert',
  },
  {
    fieldName: 'ingressKey',
    required: true,
    paramType: 'string',
    isSecret: false,
    flag: '--tls-key-string',
    label: 'Ingress Key',
    keys: 'tls.key',
  },
];

export const configParams = [
  {
    fieldName: 'domainName',
    required: true,
    paramType: 'string',
    isSecret: false,
    flag: '--domain',
    label: 'Active Directory domain name',
    placeholder: 'hp.com',
  },
  ...ingressCertParams,
  ...activeDirectoryConfigParams,
  ...activeDirectorySyncParams,
  ...mfaConfigParams,
  ...oauthEnableParams,
  ...oauthConfigParams,
  ...configServiceEnableParams,
  ...ssoEnableParams,
  ...ssoWebEnrollmentParams,
  ...ssoCACertificateParams,
];

export const stepIndices = {
  configureName: 0,
  configureDomain: 1,
  configureAD: 2,
  configureMFA: 3,
  configureConfigurationService: 4,
  generateInstallCommand: 5,
};

export const NUM_STEPS = Object.keys(stepIndices).length;

export const AWC_INSTALL_REPOSITORY_LINK =
  'https://www.teradici.com/web-help/anyware_manager/current/cloud_access_connector/cas_connector_install_rhel/#1-adding-the-connector-repository';

export const AWC_INSTALL_SELINUX_LINK =
  'https://www.teradici.com/web-help/anyware_manager/current/cloud_access_connector/cas_connector_install_rhel/#2-configuring-selinux-policies';

export const AWC_INSTALL_CONNECTOR_LINK =
  'https://www.teradici.com/web-help/anyware_manager/current/cloud_access_connector/cas_connector_install_rhel/#3-installing-the-connector-rpm';

export const AWC_INSTALL_CONNECTOR_DNS_LINK =
  'https://www.teradici.com/web-help/cas_manager_as_a_service/cloud_access_connector/rhel/casc_dnsname_config/';
