import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import CAMTooltip from 'components/CAM/display/CAMTooltip/CAMTooltip';

const PREFIX = 'InputLabel';

const classes = {
  root: `${PREFIX}-root`,
  displayText: `${PREFIX}-displayText`,
};

const Root = styled('span')(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.displayText}`]: {
    fontSize: (props) => props.displayTextSize,
  },
}));

function InputLabel({displayText, tooltipText}) {
  return (
    <Root className={classes.root}>
      <span className={classes.displayText}>{displayText}</span>
      {tooltipText && <CAMTooltip text={tooltipText} />}
    </Root>
  );
}

InputLabel.propTypes = {
  displayText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
};

InputLabel.defaultProps = {
  tooltipText: null,
};

export default InputLabel;
