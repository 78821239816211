import Switch, {switchClasses} from '@mui/material/Switch';
import {styled} from '@mui/material/styles';

const PREFIX = 'CamSwitch';

const classes = {
  checked: `${PREFIX}-checked`,
  disabled: `${PREFIX}-disabled`,
  root: `${PREFIX}-root`,
  switchBase: `${PREFIX}-switchBase`,
  thumb: `${PREFIX}-thumb`,
  track: `${PREFIX}-track`,
};

const CustomSwitch = styled(Switch)(({theme}) => ({
  [`& .${switchClasses.checked}`]: {},
  [`& .${switchClasses.disabled}`]: {},
  [`&.${switchClasses.root}`]: {
    width: 42,
    height: 24,
    padding: 0,
    display: 'flex',
  },
  [`& .${switchClasses.switchBase}`]: {
    padding: '2.5px',
    [`&.${switchClasses.checked}+.${switchClasses.track}`]: {
      opacity: 1,
      backgroundColor: '#0070D2',
      borderColor: '#0070D2',
    },
    [`&.${switchClasses.disabled}+.${switchClasses.track}`]: {
      backgroundColor: '#000 !important',
      borderColor: '#000 !important',
      opacity: '0.12 !important',
    },
  },
  [`& .${switchClasses.thumb}`]: {
    color: theme.palette.common.white,
    width: 18,
    height: 18,
    boxShadow: 'none',
  },
  [`& .${switchClasses.track}`]: {
    border: '1px solid #B0ADAB',
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: '#B0ADAB',
  },
}));
function CamSwitch(props) {
  return (
    <CustomSwitch
      color="default"
      classes={{
        checked: classes.checked,
        disabled: classes.disabled,
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
      }}
      {...props}
    />
  );
}

export default CamSwitch;
