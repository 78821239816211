import {useEffect, useState} from 'react';
import IDPIcon from 'components/CAM/icons/IDPIcon/IDPIcon';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import KeyValueInfo from 'components/CAM/text/KeyValueInfo/KeyValueInfo';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import useAcceptPolicyTracking from 'hooks/useAcceptPolicyTracking';
import CasmCookies from 'utils/cookies';
import {mapIdentityProviders} from 'utils/Mappings';
import {formatDateTime} from 'utils/utils';
import config from '../../config';

const SAML_LOGIN = 'saml';
const TERMS_NOT_ACCEPTED_MESSAGE = 'Terms not accepted.';
const COMMON_ACCOUNT_INFO = [
  {label: 'User Email', key: 'upn', enableCopy: true},
  {label: 'Identity Provider', key: 'idp', enableCopy: true},
];
const SAML_ACCOUNT_INFO = [
  {label: 'Account Owner Email', key: 'ownerUpn', enableCopy: true},
  {label: 'Account Owner Identity Provider', key: 'ownerIdp', enableCopy: true},
];
const TERMS_ACCOUNT_INFO = [
  {
    label: 'Privacy Policy Acceptance Date',
    key: 'privacyPolicyAcceptedOn',
    enableCopy: true,
  },
  {label: 'Eula Acceptance Date', key: 'eulaAcceptedOn', enableCopy: true},
  {
    label: 'Monitor Eula Acceptance Date',
    key: 'monitorEulaAcceptedOn',
    enableCopy: true,
  },
  {
    label: 'Data Export Acceptance Date',
    key: 'dataExportAcceptedOn',
    enableCopy: true,
  },
];

function AccountInformation() {
  const token = CasmCookies.getDecodedAuthToken();
  const [termsAcceptanceInfo, setTermsAcceptanceInfo] = useState([]);
  const {currentUser} = useAcceptPolicyTracking();

  const setAcceptanceInfo = async () => {
    let acceptanceTermsInfo = TERMS_ACCOUNT_INFO;

    if (!config.PRIVACY_POLICY_TRACKING_ENABLED) {
      acceptanceTermsInfo = acceptanceTermsInfo.filter(
        (terms) => terms.key !== 'privacyPolicyAcceptedOn'
      );
    }

    if (
      !currentUser.requestCountry ||
      currentUser.requestCountry.toUpperCase() !== 'CN'
    ) {
      acceptanceTermsInfo = acceptanceTermsInfo.filter(
        (terms) => terms.key !== 'dataExportAcceptedOn'
      );
    }

    const acceptanceInfo = acceptanceTermsInfo.map((item) => ({
      label: item.label,
      value: currentUser[`${item.key}`] || TERMS_NOT_ACCEPTED_MESSAGE,
      enableCopy: item.enableCopy,
      key: item.key,
    }));

    setTermsAcceptanceInfo(acceptanceInfo);
  };

  useEffect(() => {
    if (currentUser && !(token.idp === SAML_LOGIN)) {
      setAcceptanceInfo();
    }
  }, [JSON.stringify(currentUser)]);

  const getFieldDisplayValue = (item) => {
    let displayVal = item.value || token[`${item.key}`];

    switch (item.key) {
      case 'idp':
      case 'ownerIdp':
        displayVal = (
          <>
            <IDPIcon provider={displayVal} />
            {mapIdentityProviders(displayVal)}
          </>
        );
        break;
      case 'privacyPolicyAcceptedOn':
      case 'eulaAcceptedOn':
      case 'monitorEulaAcceptedOn':
      case 'dataExportAcceptedOn':
        displayVal = formatDateTime(displayVal);
        break;
      default:
        break;
    }
    return displayVal;
  };

  const parseAccountInformation = (accountFields) =>
    accountFields.map((item) => ({
      label: item.label,
      value: getFieldDisplayValue(item),
      enableCopy: item.enableCopy,
    }));

  const getAccountInformation = () => {
    const accountInfo = parseAccountInformation(COMMON_ACCOUNT_INFO);

    if (token.idp === SAML_LOGIN) {
      return accountInfo.concat(parseAccountInformation(SAML_ACCOUNT_INFO));
    }

    return accountInfo.concat(parseAccountInformation(termsAcceptanceInfo));
  };

  const renderAccountInformation = () =>
    getAccountInformation().map((item) => (
      <KeyValueInfo
        key={`accountInfo-${item.label}`}
        label={item.label}
        value={item.value}
        enableCopy={item.shouldEnableCopy}
      />
    ));

  return (
    <CAMCard>
      <GridN singleColumn>
        <SectionHeader displayText="ACCOUNT INFORMATION" />
      </GridN>
      <GridN>{renderAccountInformation()}</GridN>
    </CAMCard>
  );
}

export default AccountInformation;
