import {
  CLOSE_DELETE_CONNECTORS_DIALOG,
  OPEN_DELETE_CONNECTORS_DIALOG,
} from 'redux/actions/DeleteConnectorsDialogActions';

import {CLEAR_STORE} from 'redux/actions/globalActions';

const defaultState = {open: false, connectors: []};

function openDialog(state, action) {
  return {
    ...state,
    open: true,
    connectors: action.connectors,
  };
}

function closeDialog() {
  return defaultState;
}

function clearDialog() {
  return defaultState;
}

const stateChanges = {
  [OPEN_DELETE_CONNECTORS_DIALOG]: openDialog,
  [CLOSE_DELETE_CONNECTORS_DIALOG]: closeDialog,
  [CLEAR_STORE]: clearDialog,
};

function deleteConnectorsDialog(state = defaultState, action) {
  const stateChange = stateChanges[action.type];
  return stateChange ? stateChange(state, action) : state;
}

export default deleteConnectorsDialog;
