import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import {useState} from 'react';
import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import HelpSvg from 'icons/help.svg';
import {
  AWM_API_DOCUMENTATION,
  AWM_DOCUMENTATION_LINK,
  AWM_PRIVACY_STATEMENT,
  AWM_SAAS_DOCUMENTATION_LINK,
  AWM_SERVICE_LEVEL,
  AWM_SERVICE_STATUS,
  contactTechnicalSupportLink,
  LINK_COMMUNITY_DISCUSSIONS,
} from 'utils/constants';
import {getDocumentationLink} from 'utils/utils';
import config from '../../config';
import CAMMenu from '../menu/Menu';

const PREFIX = 'HelpDropDown';

const classes = {
  centerAlign: `${PREFIX}-centerAlign`,
  dateText: `${PREFIX}-dateText`,
  flexContainer: `${PREFIX}-flexContainer`,
  helpMenuDropdown: `${PREFIX}-helpMenuDropdown`,
  listItem: `${PREFIX}-listItem`,
  nested: `${PREFIX}-nested`,
  notesIcon: `${PREFIX}-notesIcon`,
  notesMenuItem: `${PREFIX}-notesMenuItem`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.centerAlign}`]: {
    margin: 'auto 0px',
  },

  [`& .${classes.dateText}`]: {
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontSize: '12px',
    letterSpacing: '0.21px',
    lineHeight: '20px',
  },

  [`& .${classes.flexContainer}`]: {display: 'flex'},

  [`& .${classes.helpMenuDropdown}`]: {
    marginTop: '-10px',
    marginLeft: '-64px',
  },

  [`& .${classes.listItem}`]: {
    width: '10em',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 14,
    color: 'black',
  },

  [`& .${classes.nested}`]: {
    paddingLeft: theme.spacing(4),
  },

  [`& .${classes.notesIcon}`]: {
    color: '#757575',
    height: '18px',
    marginLeft: 'auto',
    width: '18px',
  },

  [`& .${classes.notesMenuItem}`]: {
    display: 'block',
  }
}));

function HelpDropDown() {


  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const menus = [
    {title: 'Documentation', url: getDocumentationLink()},
    {title: 'Support', url: contactTechnicalSupportLink},
    {title: 'Community', url: LINK_COMMUNITY_DISCUSSIONS},
    {title: 'Privacy Statement', url: AWM_PRIVACY_STATEMENT},
    {title: 'Service Status', url: AWM_SERVICE_STATUS},
    {title: 'Service Level', url: AWM_SERVICE_LEVEL},
    {title: 'API Documentation', url: AWM_API_DOCUMENTATION},
    {
      title: 'Admin Guide',
      url: config.STANDALONE
        ? AWM_DOCUMENTATION_LINK
        : AWM_SAAS_DOCUMENTATION_LINK,
    },
  ];

  const menuItems = menus.map((menu) => (
    <KebabMenuItem
      enableClick
      menuText={menu.title}
      key={menu.title}
      width="10em"
      onClick={() => {
        window.open(menu.url);
      }}
    />
  ));

  return (
    <Root>
      <IconButton
        id="icon-button"
        aria-owns="icon-button-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        size="large">
        <img src={HelpSvg} alt="" />
      </IconButton>
      <CAMMenu
        anchorEl={anchorEl}
        customClass={classes.helpMenuDropdown}
        id="icon-button-menu"
        onClose={handleClose}
      >
        {menuItems}
      </CAMMenu>
    </Root>
  );
}

export default HelpDropDown;
