import {Grid, TextField} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import useSnackbar from 'hooks/useSnackbar';
import {get, post} from 'api/Api';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import CAMTooltip from 'components/CAM/display/CAMTooltip/CAMTooltip';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import HorizontalTab from 'components/CAM/tabs/HorizontalTab/HorizontalTab';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import {push} from 'redux/actions/HistoryActions';
import {
  MULTI_ADMIN_SETTINGS_ACTIVE_DIRECTORY,
  MULTI_ADMIN_SETTINGS_SAML,
  MULTI_ADMIN_LINK,
  MULTI_ADMIN_SETTINGS_TITLE,
} from 'utils/constants';
import {
  userCanAccessAdConfiguration,
  userCanAccessSamlConfiguration,
} from 'utils/utils';

const PREFIX = 'AdConfiguration';

const classes = {
  container: `${PREFIX}-container`,
  buttonAlign: `${PREFIX}-buttonAlign`,
  input: `${PREFIX}-input`,
  validationContainer: `${PREFIX}-validationContainer`,
  pageTitleContainer: `${PREFIX}-pageTitleContainer`,
  pageTitle: `${PREFIX}-pageTitle`,
};

const Root = styled('notistack.SnackbarProvider')(({theme}) => ({
  [`& .${classes.container}`]: {
    ...theme.container,
    margin: theme.spacing(4),
  },

  [`& .${classes.buttonAlign}`]: {
    paddingTop: '22px',
  },

  [`& .${classes.input}`]: {
    marginTop: '-8px',
    marginBottom: '15px',
  },

  [`& .${classes.validationContainer}`]: {
    marginTop: '1rem',
  },

  [`& .${classes.pageTitleContainer}`]:
    theme.createPage.createPageTitleContainer,
  [`& .${classes.pageTitle}`]: theme.createPage.createPageTitle,
}));

function TextInput(props) {
  const {name} = props;
  return (
    <TextField
      autoComplete="off"
      required
      margin="none"
      variant="outlined"
      size="small"
      fullWidth
      InputProps={{
        'data-testid': `${name}-field`,
      }}
      {...props}
    />
  );
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
};

const requiredFieldsSave = [
  'domainControllerUrl',
  'adminConnectionDn',
  'adminPassword',
  'searchBaseDn',
  'camGroupDn',
];

const requiredFieldsValidate = [...requiredFieldsSave, 'username', 'password'];

function AdConfiguration() {
  const {successSnackbar, errorSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const [configuration, setConfiguration] = useState({
    domainControllerUrl: '',
    adminConnectionDn: '',
    adminPassword: '',
    searchBaseDn: '',
    camGroupDn: '',
    username: '',
    password: '',
  });

  const [focussed, setFocussed] = useState({
    domainControllerUrl: false,
    adminConnectionDn: false,
    adminPassword: false,
    searchBaseDn: false,
    camGroupDn: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  const disableSave = useMemo(
    () => isSaving || isLoading || isValidating,
    [isSaving, isLoading, isValidating]
  );
  const disableValidate = Boolean(
    requiredFieldsValidate.find((field) => !configuration[field])
  );

  const handleInputChange = (event) => {
    setConfiguration({
      ...configuration,
      [event.target.name]: event.target.value,
    });
  };

  const handleFocus = (event) => {
    setFocussed({...focussed, [event.target.name]: true});
  };

  const handleBlur = (event) => {
    setFocussed({...focussed, [event.target.name]: false});
  };

  const saveConfiguration = async (event) => {
    event.preventDefault();
    setIsSaving(true);
    try {
      const data = {
        domainControllerUrl: configuration.domainControllerUrl,
        adminConnectionDn: configuration.adminConnectionDn,
        adminPassword: configuration.adminPassword,
        searchBaseDn: configuration.searchBaseDn,
        camGroupDn: configuration.camGroupDn,
      };

      await post({path: 'auth/ad/config', data});
      successSnackbar('Saved the AD configuration!');
    } catch (err) {
      switch (err.code) {
        case 400:
        case 412:
          errorSnackbar(err.data.reason);
          break;
        default:
          errorSnackbar(
            'An error occurred while attempting to save an Ad configuration'
          );
          break;
      }
    } finally {
      setIsSaving(false);
    }
  };

  const loadConfiguration = async () => {
    setIsLoading(true);
    try {
      const {data} = await get({path: 'auth/ad/config'});
      setConfiguration({...configuration, ...data});
    } catch (err) {
      switch (err.code) {
        case 404:
          break;
        case 412:
          errorSnackbar(err.data.reason);
          break;
        default:
          errorSnackbar(
            'An error occurred while attempting to load an Ad configuration'
          );
          break;
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadConfiguration();
  }, []);

  const validateConfiguration = async () => {
    setIsValidating(true);

    const data = {
      domainControllerUrl: configuration.domainControllerUrl,
      adminConnectionDn: configuration.adminConnectionDn,
      adminPassword: configuration.adminPassword,
      searchBaseDn: configuration.searchBaseDn,
      camGroupDn: configuration.camGroupDn,
      username: configuration.username,
      password: configuration.password,
    };

    try {
      await post({path: 'auth/ad/config/validate', data, maxRetries: 1});
      successSnackbar('Validated the AD configuration!');
    } catch (err) {
      if (err.code === 400) {
        if (
          err.data &&
          typeof err.data.reason === 'string' &&
          err.data.reason.includes('userPrincipalName')
        ) {
          errorSnackbar(
            'Test user does not have a userPrincipalName. Please add a userPrincipalName to the user and try again.'
          );
        } else {
          errorSnackbar(
            'Unable to log in with test user. Please verify the AD configuration and try again.'
          );
        }
      } else if (
        err.code === 500 &&
        err.message === 'Authentication request timed out'
      ) {
        const message = `The authentication request timed out. Please verify that Anyware Manager is able to communicate with the domain controller at ${data.domainControllerUrl}.`;
        errorSnackbar(message);
      } else {
        errorSnackbar(
          'An error occurred during validation. Please verify the AD configuration and try again.'
        );
      }
    } finally {
      setIsValidating(false);
    }
  };

  const changeTab = (tabIndex) => {
    // Will call the route for the respective tab
    if (userCanAccessSamlConfiguration() && tabIndex === 0) {
      dispatch(push(MULTI_ADMIN_LINK));
    }
  };

  const tabList = () => {
    if (!userCanAccessSamlConfiguration()) {
      return [MULTI_ADMIN_SETTINGS_ACTIVE_DIRECTORY];
    }
    return [MULTI_ADMIN_SETTINGS_SAML, MULTI_ADMIN_SETTINGS_ACTIVE_DIRECTORY];
  };

  if (!userCanAccessAdConfiguration()) {
    return null;
  }

  return (
    <CAMCard>
      <div className={classes.pageTitleContainer}>
        <div className={classes.pageTitle}>{MULTI_ADMIN_SETTINGS_TITLE}</div>
      </div>

      <HorizontalTab
        tabs={tabList()}
        selectedTab={tabList().length - 1}
        setSelectedTab={changeTab}
      />

      <div className={classes.container}>
        <form onSubmit={saveConfiguration}>
          <GridN singleColumn>
            <InputLabel
              displayText="Domain Controller URL"
              tooltipText="This is URL where your domain controller is hosted. Example: ldaps://dc.example.com or ldaps://35.179.234.117:636"
            />
            <TextInput
              name="domainControllerUrl"
              data-testid="domain-controller-url"
              placeholder="Domain Controller URL"
              helperText={
                focussed.domainControllerUrl &&
                'Example: ldaps://dc.example.com or ldaps://35.179.234.117:636'
              }
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={configuration.domainControllerUrl}
              onChange={handleInputChange}
              disabled={isSaving || isLoading}
              className={classes.input}
            />

            <InputLabel
              displayText="Admin Connection DN"
              tooltipText="This is the distinguished name (DN) of a user within your Active Directory that is able to search for users. Microsoft AD supports using UPN format for logging in.
              Example: cn=cam_admin,cn=Users,dc=example,dc=com or cam_admin@example.com"
            />
            <TextInput
              name="adminConnectionDn"
              data-testid="admin-connection-dn"
              placeholder="Admin Connection DN"
              helperText={
                focussed.adminConnectionDn &&
                'Example: cn=cam_admin,cn=Users,dc=example,dc=com or cam_admin@example.com'
              }
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={configuration.adminConnectionDn}
              onChange={handleInputChange}
              disabled={isSaving || isLoading}
              className={classes.input}
            />

            <InputLabel
              displayText="Admin Password"
              tooltipText='This is the password for the admin user defined by "Admin connection DN"'
            />
            <TextInput
              name="adminPassword"
              data-testid="admin-password"
              placeholder="Admin Password"
              value={configuration.adminPassword}
              onChange={handleInputChange}
              disabled={isSaving || isLoading}
              type="password"
              className={classes.input}
            />
            <InputLabel
              displayText="Anyware Manager Group DN"
              tooltipText="This is the DN of a group in your Active Directory. Only users that belong to this group will be able to authenticate to Anyware Manager. Example: cn=Anyware Manager Admins,cn=Users,dc=example,dc=com"
            />

            <TextInput
              name="camGroupDn"
              data-testid="cam-group-dn"
              placeholder="Anyware Manager Group DN"
              helperText={
                focussed.camGroupDn &&
                'Example: cn=Anyware Manager Admins,cn=Users,dc=example,dc=com'
              }
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={configuration.camGroupDn}
              onChange={handleInputChange}
              disabled={isSaving || isLoading}
              className={classes.input}
            />

            <InputLabel
              displayText="Search Base DN"
              tooltipText="This is the DN of the container in your Active Directory where we will search for users to authenticate. Example: cn=Users,dc=example,dc=com"
            />
            <TextInput
              name="searchBaseDn"
              data-testid="search-base-dn"
              placeholder="Search Base DN"
              helperText={
                focussed.searchBaseDn && 'Example: cn=Users,dc=example,dc=com'
              }
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={configuration.searchBaseDn}
              onChange={handleInputChange}
              disabled={isSaving || isLoading}
              className={classes.input}
            />
          </GridN>

          <Grid container direction="row-reverse">
            <SaveButton
              disabled={disableSave}
              saving={isSaving}
              onClick={saveConfiguration}
            />
          </Grid>
        </form>

        <div className={classes.validationContainer}>
          <CAMCard>
            <SectionHeader displayText="VALIDATE CONFIGURATION">
              <CAMTooltip text="Validate the AD configuration by simulating a login using a test user from your AD." />
            </SectionHeader>

            <GridN>
              <GridN singleColumn>
                <InputLabel displayText="User" />

                <TextInput
                  name="username"
                  data-testid="username"
                  placeholder="Test Username"
                  required={false}
                  value={configuration.username}
                  onChange={handleInputChange}
                  disabled={isSaving || isLoading}
                  className={classes.input}
                />
              </GridN>

              <GridN singleColumn>
                <InputLabel displayText="Password" />

                <TextInput
                  name="password"
                  data-testid="password"
                  placeholder="Test Password"
                  required={false}
                  value={configuration.password}
                  type="password"
                  onChange={handleInputChange}
                  disabled={isSaving || isLoading}
                  className={classes.input}
                />
              </GridN>
              <GridN singleColumn>
                <div className={classes.buttonAlign}>
                  <SaveButton
                    onClick={validateConfiguration}
                    disabled={disableValidate}
                    buttonText="Validate"
                    saving={isValidating}
                  />
                </div>
              </GridN>
            </GridN>
          </CAMCard>
        </div>
      </div>
    </CAMCard>
  );
}

export default function () {
  return (
    <Root maxSnack={1} data-testid="snackbar-provider">
      <AdConfiguration />
    </Root>
  );
}
