import Grid from '@mui/material/Grid';
import {styled, alpha} from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import DateRangeSelect from 'components/CAM/inputs/DateRangeSelect/DateRangeSelect';
import PartialMatchSelect from 'components/common/PartialMatchSelect';
import useActivityLogsFilter from 'hooks/useActivityLogsFilter';
import useAdUserFilter from 'hooks/useAdUserFilter';
import useDebounce from 'hooks/useDebounce';
import useRemoteWorkstationFilter from 'hooks/useRemoteWorkstationFilter';
import useSessionAuditFilter from 'hooks/useSessionAuditFilter';
import {setDateFilterParams} from 'redux/actions/dateFilterActions';
import {
  clearTableFetchParams,
  fetchTableData,
} from 'redux/actions/tableDataActions';
import {resourceToSearchInfoMap} from 'utils/Mappings';
import {
  ACTIVITY_LOGS,
  AD_USERS,
  AD_USERS_ENTITLEMENT_FILTER_OPTIONS,
  ENROLLMENT_COMMANDS,
  MONITOR_ENROLLMENTS,
  REMOTE_WORKSTATIONS,
  SESSION_AUDIT,
} from 'utils/constants';
import {
  selectDataIsFetching,
  selectSelectedDeployment,
  selectVariable,
} from 'utils/reduxSelectors';
import CAMMenu from '../menu/Menu';
import BulkActions from './BulkActions';
import ConfigureColumns from './ConfigureColumns';
import ConfigureTable from './ConfigureTable';
import ToolbarSelect from './ToolbarSelect';

const PREFIX = 'TableToolbar';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  selectedText: `${PREFIX}-selectedText`,
  searchByLabelText: `${PREFIX}-searchByLabelText`,
  refreshIcon: `${PREFIX}-refreshIcon`,
  clearFilterButton: `${PREFIX}-clearFilterButton`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  search: `${PREFIX}-search`,
  searchFocussed: `${PREFIX}-searchFocussed`,
  clearIcon: `${PREFIX}-clearIcon`,
  leftSideContainer: `${PREFIX}-leftSideContainer`,
};

const StyledPaper = styled(Paper)(({theme}) => ({
  [`&.${classes.toolbar}`]: {
    backgroundColor: '#FFFFFF',
    marginBottom: '1rem',
    padding: '0.25rem 0.5rem',
    width: '100%',
  },

  [`& .${classes.selectedText}`]: {
    color: '#0076A9',
    fontSize: '14px',
    margin: theme.spacing(1),
  },

  [`& .${classes.searchByLabelText}`]: {
    marginLeft: theme.spacing(0.5),
    minWidth: theme.spacing(9.5),
  },

  [`& .${classes.refreshIcon}`]: {
    color: '#0076A9',
    fontSize: '25px',
    cursor: 'pointer',
  },

  [`& .${classes.clearFilterButton}`]: {
    marginLeft: '1rem',
    color: 'rgba(0, 0, 0, 0.7)',
  },

  [`& .${classes.inputRoot}`]: {
    color: 'inherit',
  },

  [`& .${classes.inputInput}`]: {
    padding: theme.spacing(1, 1, 1, 1),
    width: '100%',
  },

  [`& .${classes.search}`]: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha('#F4F4F4', 0.65),
    '&:hover': {
      backgroundColor: alpha('#F4F4F4', 0.85),
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '8rem',
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      width: '32rem',
    },
  },

  [`& .${classes.searchFocussed}`]: {
    outline: '#0076A9 auto 1px',
  },

  [`& .${classes.clearIcon}`]: {
    cursor: 'pointer',
    width: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#0076A9',
  },

  [`& .${classes.leftSideContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
  },
}));

function TableToolbar({
  resource,
  hideBulkActions,
  hideConfigureTable,
  searchTerm,
  setSearchTerm,
  hideSearch,
}) {
  const dispatch = useDispatch();
  const [searchDebounced] = useDebounce(searchTerm, 1500);
  const [lastSearchTerm, setLastSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const {refresh: refreshAdUsers, clearFilters: clearAdUserFilters} =
    useAdUserFilter(
      {
        enabled: resource === AD_USERS,
        searchTerm: searchDebounced,
      },
      setLastSearchTerm
    );

  const {
    refresh: refreshRemoteWorkstations,
    clearFilters: clearRemoteWorkstationFilters,
  } = useRemoteWorkstationFilter({
    enabled: resource === REMOTE_WORKSTATIONS,
    searchTerm: searchDebounced,
  });

  const {refresh: refreshActivityLogs, clearFilters: clearActivityLogFilters} =
    useActivityLogsFilter({
      enabled: resource === ACTIVITY_LOGS,
      searchTerm: searchDebounced,
    });

  const {refresh: refreshSessionAudit, clearFilters: clearSessionAuditFilters} =
    useSessionAuditFilter({
      enabled: resource === SESSION_AUDIT,
      searchTerm: searchDebounced,
    });

  const {deploymentId} = useSelector(selectSelectedDeployment);
  const {page, rowsPerPage} =
    useSelector((state) => state.tableData[resource]) || {};
  const {params: filter} = useSelector(
    (state) => state.tableData[resource] || {params: {}}
  );
  const isFetching = useSelector((state) =>
    selectDataIsFetching(state, resource)
  );
  const {enabled: dateFilterEnabled} = useSelector(
    (state) => state.dateFilter
  ) || {enabled: false};
  const usePartialSearch =
    (useSelector((state) =>
      selectVariable(state, `usePartialSearchBy${resource}`)
    ) ??
      resourceToSearchInfoMap[resource]?.defaultUsePartialSearch) ||
    false;
  const selectValue =
    useSelector((state) => selectVariable(state, `searchTermBy${resource}`)) ||
    resourceToSearchInfoMap[resource]?.defaultValue;
  const {entitlementsFilter, showDisabledUsers} = useSelector((state) =>
    selectVariable(state, 'adUsersTableFilter')
  ) || {
    entitlementsFilter: {
      filterType: AD_USERS_ENTITLEMENT_FILTER_OPTIONS[3],
      enabled: false,
    },
    showDisabledUsers: false,
  };

  const getLabelFromValue = () => {
    // Get an option object from the resourceToSearchInfoMap
    // that matches the value of the selectValue
    const option = resourceToSearchInfoMap[resource]?.options.find(
      (object) => object.value === selectValue
    );
    // Return the placeholder property of the option object
    return option ? option.placeholder : '';
  };

  const placeholderText = (() => {
    switch (resource) {
      case ACTIVITY_LOGS:
        return 'Filter by operation';
      case MONITOR_ENROLLMENTS:
        return 'Filter by hostname';
      case ENROLLMENT_COMMANDS:
        return 'Filter by name';
      case AD_USERS:
      case SESSION_AUDIT:
        return getLabelFromValue();
      default:
        return 'Search';
    }
  })();

  const open = Boolean(anchorEl);

  const getNumberSelected = (state) => {
    const selected = state.tableSelect[resource] || {};
    return Object.keys(selected).length;
  };
  const numberSelected = useSelector(getNumberSelected);

  const handleSearchInputChange = (event) => setSearchTerm(event.target.value);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const updateTable = async () => {
    if (![REMOTE_WORKSTATIONS, ACTIVITY_LOGS].includes(resource)) {
      dispatch(
        fetchTableData(
          resource,
          page,
          rowsPerPage,
          filter,
          searchTerm,
          usePartialSearch
        )
      );
    }
    setLastSearchTerm(searchDebounced);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      updateTable();
    }
  };

  const handleSearchIconClick = () => {
    if (isFetching) {
      return;
    }
    updateTable();
  };

  const handleRefreshButtonClick = () => {
    if (isFetching) {
      return;
    }
    switch (resource) {
      case AD_USERS:
        refreshAdUsers();
        break;
      case REMOTE_WORKSTATIONS:
        refreshRemoteWorkstations();
        break;
      case ACTIVITY_LOGS:
        refreshActivityLogs();
        break;
      case SESSION_AUDIT:
        refreshSessionAudit();
        break;
      default:
        dispatch(
          fetchTableData(
            resource,
            page,
            rowsPerPage,
            filter,
            searchTerm,
            usePartialSearch
          )
        );
    }
  };

  const handleSearchBlur = () => {
    if (searchDebounced !== lastSearchTerm) {
      updateTable();
    }
  };

  const setDateRange = (range) => {
    dispatch(setDateFilterParams(range));
  };

  const isDefaultFilter = () => {
    switch (resource) {
      case ACTIVITY_LOGS:
        return (
          Object.keys(filter).length === 1 &&
          filter.deploymentId === deploymentId
        );
      case SESSION_AUDIT:
        return Object.keys(filter).length === 1 && filter.fetchRelatedFields;
      case AD_USERS:
        return !entitlementsFilter?.enabled && !showDisabledUsers;
      case REMOTE_WORKSTATIONS:
        return (
          Object.keys(filter).length === 2 &&
          filter.showdeleting &&
          filter.refreshMachineState
        );
      default:
        return true;
    }
  };

  const handleClearFilterClick = () => {
    switch (resource) {
      case AD_USERS:
        clearAdUserFilters();
        break;
      case REMOTE_WORKSTATIONS:
        clearRemoteWorkstationFilters();
        break;
      case ACTIVITY_LOGS:
        clearActivityLogFilters();
        break;
      case SESSION_AUDIT:
        clearSessionAuditFilters();
        break;
      default:
        dispatch(clearTableFetchParams(resource));
        handleRefreshButtonClick();
    }
  };

  return (
    <StyledPaper square className={classes.toolbar}>
      <Grid container justifyContent="space-between" alignItems="center">
        <div className={classes.leftSideContainer}>
          {!hideBulkActions && (
            <Typography className={classes.selectedText}>
              {`${numberSelected} selected`}
            </Typography>
          )}
          {(resource === SESSION_AUDIT || resource === AD_USERS) && (
            <>
              <ToolbarSelect resource={resource} />
              <PartialMatchSelect resource={resource} />
            </>
          )}
          {!hideSearch && (
            <>
              <div className={classes.search}>
                <InputBase
                  fullWidth
                  data-testid={`search-${resource}`}
                  placeholder={placeholderText}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                    focused: classes.searchFocussed,
                  }}
                  onChange={handleSearchInputChange}
                  onKeyDown={handleKeyDown}
                  onBlur={handleSearchBlur}
                  value={searchTerm}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon
                        data-testid="table-search-icon"
                        className={classes.clearIcon}
                        onClick={handleSearchIconClick}
                      />
                    </InputAdornment>
                  }
                />
              </div>

              <IconButton
                data-testid="refresh-table-button"
                size="small"
                onClick={handleRefreshButtonClick}
              >
                <RefreshIcon className={classes.refreshIcon} />
              </IconButton>
            </>
          )}
          {!hideConfigureTable && <ConfigureTable resource={resource} />}
          {!isDefaultFilter() && (
            <CAMButton
              onClick={handleClearFilterClick}
              buttonText="Clear Filters"
              size="small"
              testId="table-clear-filter-button"
            />
          )}
        </div>

        <Grid item>
          <Grid container alignItems="center" justifyContent="flex-end">
            <Hidden mdDown>
              <ConfigureColumns resource={resource} />
            </Hidden>

            <IconButton
              id="icon-button-bulk"
              data-testid="bulk-kebab"
              aria-owns={open ? 'bulk' : null}
              aria-haspopup="true"
              disabled={numberSelected === 0}
              onClick={handleOpen}
              size="large"
            >
              {!hideBulkActions && <MoreVertIcon />}
            </IconButton>
          </Grid>
        </Grid>

        <CAMMenu
          id="bulk-kebab"
          anchorEl={anchorEl}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 10,
          }}
          open={open}
          onClose={handleClose}
        >
          <BulkActions resource={resource} handleClose={handleClose} />
        </CAMMenu>
      </Grid>
      {dateFilterEnabled &&
        (resource === ACTIVITY_LOGS || resource === SESSION_AUDIT) && (
          <DateRangeSelect
            options={['today', 'yesterday', 'last7Days', 'custom']}
            setDateRange={setDateRange}
          />
        )}
    </StyledPaper>
  );
}

TableToolbar.propTypes = {
  resource: PropTypes.string.isRequired,
  hideBulkActions: PropTypes.bool,
  hideConfigureTable: PropTypes.bool,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  hideSearch: PropTypes.bool,
};

TableToolbar.defaultProps = {
  hideBulkActions: false,
  hideConfigureTable: true,
  hideSearch: false,
};

export default TableToolbar;
