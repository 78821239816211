import {Button} from '@mui/material';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useState} from 'react';
import config from 'config';
import BetaSvg from 'icons/beta_icon.svg';
import {theme} from 'themes/default';

const PREFIX = 'BetaDialog';

const classes = {
  container: `${PREFIX}-container`,
  dialogHeader: `${PREFIX}-dialogHeader`,
  dialogBody: `${PREFIX}-dialogBody`,
  functionWarning: `${PREFIX}-functionWarning`,
  warningTitle: `${PREFIX}-warningTitle`,
  buttonRow: `${PREFIX}-buttonRow`,
  buttonRowWithWarning: `${PREFIX}-buttonRowWithWarning`,
  confirmButton: `${PREFIX}-confirmButton`,
  cardRow: `${PREFIX}-cardRow`,
  uiOptionCard: `${PREFIX}-uiOptionCard`,
  defaultCard: `${PREFIX}-defaultCard`,
  selectedCard: `${PREFIX}-selectedCard`,
  optionDescription: `${PREFIX}-optionDescription`,
  betaIcon: `${PREFIX}-betaIcon`,
  titleRow: `${PREFIX}-titleRow`
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.container}`]: {
    borderRadius: '4px 4px 0 0',
    backgroundColor: '#16325C',
    color: theme.palette.common.white,
    fontFamily: 'Roboto, sans-serif',
    width: '40rem',
  },

  [`& .${classes.dialogHeader}`]: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    fontSize: '20px',
    height: '48px',
    lineHeight: '25px',
    paddingLeft: '25px',
    textShadow: '0 0 4px 0 rgba(3,46,97,0.5)',
  },

  [`& .${classes.dialogBody}`]: {
    color: theme.palette.common.white,
    fontSize: '0.875rem',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    margin: '1rem 1.5rem',
  },

  [`& .${classes.functionWarning}`]: {
    alignItems: 'center',
    backgroundColor: '#FFB75D',
    borderRadius: '4px',
    color: 'rgba(0,0,0,0.87)',
    display: 'flex',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.75rem',
    height: '22px',
    letterSpacing: '0.4px',
    lineHeight: '16px',
    margin: '8px 0px',
  },

  [`& .${classes.warningTitle}`]: {
    fontWeight: 'bold',
    paddingLeft: '17px',
    paddingRight: '7px',
  },

  [`& .${classes.buttonRow}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: '38px 0px 0px',
  },

  [`& .${classes.buttonRowWithWarning}`]: {marginTop: '0px'},

  [`& .${classes.confirmButton}`]: {
    borderRadius: '4px',
    backgroundColor: '#0070D2',
    height: '32px',
    textTransform: 'none',
    width: '96px',
  },

  [`& .${classes.cardRow}`]: {marginTop: '1.75rem'},

  [`& .${classes.uiOptionCard}`]: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 500,
    height: '11.75rem',
    letterSpacing: '0.25px',
    lineHeight: '24px',
    width: '17.25rem',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  },

  [`& .${classes.defaultCard}`]: {margin: '4px'},

  [`& .${classes.selectedCard}`]: {
    border: '4px solid #FF5D06',
    borderRadius: '4px',
  },

  [`& .${classes.optionDescription}`]: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    letterSpacing: '0.11px',
    lineHeight: '16px',
    paddingTop: '12px',
  },

  [`& .${classes.betaIcon}`]: {
    height: '24px',
    paddingLeft: '10px',
    width: '42px',
  },

  [`& .${classes.titleRow}`]: {display: 'flex'}
}));

const BETA = 'beta';
const GA = 'ga';
const title = 'Beta: be part of the development process';
const description =
  'You are currently logged into the beta-ui which contains new, experimental and partially completed features. Those features are not supported and may be prone to change, or are considered not yet ready for full production use and you use them at your own risk. All other features maintain the same support level as in the production interface.';
const warningMessage =
  'Functionality in the beta-ui may change frequently without warning.';
const betaOptionDescription =
  'I am up for the adventure! I accept that Teradici will not provide support for the beta, or experimental features provided in the beta-ui and that those features may be prone to change. All other non-beta, non-experimental features that also exist in the production UI are supported.';
const gaOptionDescription =
  'I am not interested in using experimental or partially completed features. So take me back to the production interface.';

function BetaDialog({open, onBetaSelect}) {

  const [selectedOption, setSelectedOption] = useState(GA);

  const handleClick = () => {
    if (selectedOption === GA) {
      window.location.href = `${config.GATEWAY_ADDRESS}/app`;
    } else if (selectedOption === BETA) {
      onBetaSelect();
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth="md">
      <div className={classes.container}>
        <div className={classes.dialogHeader}>{title}</div>
        <div className={classes.dialogBody}>
          {description}
          <Grid
            className={classes.cardRow}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Card
                className={
                  selectedOption === BETA
                    ? classes.selectedCard
                    : classes.defaultCard
                }
                onClick={() => setSelectedOption(BETA)}
                id="beta-card"
              >
                <CardContent className={classes.uiOptionCard}>
                  <div className={classes.titleRow}>
                    Yes, I am in!
                    <img className={classes.betaIcon} src={BetaSvg} alt="" />
                  </div>
                  <div className={classes.optionDescription}>
                    {betaOptionDescription}
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card
                className={
                  selectedOption === GA
                    ? classes.selectedCard
                    : classes.defaultCard
                }
                onClick={() => setSelectedOption(GA)}
                id="production-card"
              >
                <CardContent className={classes.uiOptionCard}>
                  Production as usual.
                  <div className={classes.optionDescription}>
                    {gaOptionDescription}
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {selectedOption === BETA && (
            <div className={classes.functionWarning}>
              <div className={classes.warningTitle}>Heads up!</div>
              {warningMessage}
            </div>
          )}

          <div
            className={classNames(classes.buttonRow, {
              [classes.buttonRowWithWarning]: selectedOption === BETA,
            })}
          >
            <Button
              color="primary"
              classes={{containedPrimary: classes.confirmButton}}
              variant="contained"
              onClick={handleClick}
              id="beta-dialog-button"
            >
              Let's go!
            </Button>
          </div>
        </div>
      </div>
    </StyledDialog>
  );
}

BetaDialog.propTypes = {
  open: PropTypes.bool,
  onBetaSelect: PropTypes.func,
};

BetaDialog.defaultProps = {
  open: true,
  onBetaSelect: () => {},
};

export default BetaDialog;
