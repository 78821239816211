import {applyMiddleware, compose, createStore} from 'redux';
import {createLogger} from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import config from 'config';
import {
  loadColumnsFromLocalStorage,
  loadStateFromLocalStorage,
  saveStateToLocalStorage,
} from 'utils/localStorage';
import {throttle} from 'utils/utils';
import {updateSelectedDeploymentId} from 'redux/actions/selectedDeploymentIdActions';
import rootReducer from '../reducers';
import {
  createBrowserHistory,
  routerMiddleware,
  startListener,
} from '../ReduxHistory';
import pollingSaga from '../sagas/pollingSaga';

// export history bound to browser's history object.
// history will be passed down and set to router.
export const history = createBrowserHistory({
  basename: config.isBeta() ? '/beta-ui/' : '/',
});

const sagaMiddleware = createSagaMiddleware();

// Build the middleware, which intercepts navigation actions
// and calls the corresponding history method.
const middlewares = [
  routerMiddleware(history),
  thunkMiddleware,
  sagaMiddleware,
];

// Conditionally enable redux logger middleware
if (config.ENABLE_REDUX_LOGS) {
  const loggerMiddleware = createLogger();
  middlewares.push(loggerMiddleware);
}

// Enable action dispatch stack trace for dev tools
const devToolsConfig = {
  trace: true,
  traceLimit: 25,
};
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devToolsConfig)) ||
  compose;

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const persistedState = loadStateFromLocalStorage();

const persistedColumns = loadColumnsFromLocalStorage();

// Create the singleton store
const store = createStore(
  rootReducer,
  {
    ...persistedState,
    tableColumns: persistedColumns,
  },
  enhancer
);

function getSelectedDeploymentId() {
  let stateDeployment;

  try {
    stateDeployment = store.getState().data.dataByResource.selectedDeployment;
  } catch {
    stateDeployment = undefined;
  }

  if (
    stateDeployment === undefined &&
    persistedState &&
    persistedState.selectedDeploymentId
  ) {
    return persistedState.selectedDeploymentId;
  }

  if (
    store.getState().data &&
    store.getState().data.dataByResource.selectedDeployment
  ) {
    return store.getState().data.dataByResource.selectedDeployment.deploymentId;
  }

  return undefined;
}

// Call this function to have the app start syncing redux state to local storage
// Returns a function that can be called to cancel this behavior
// The unsubscribe function MUST be called when the app is unmounted,
// otherwise user settings will be lost after logout
export const subscribeSaveStateToLocalStorage = () => {
  store.dispatch(updateSelectedDeploymentId(getSelectedDeploymentId()));

  const unsubscribe = store.subscribe(
    throttle(() => {
      saveStateToLocalStorage({
        notifications: store.getState().notifications,
        polling: store.getState().polling,
        tableColumns: store.getState().tableColumns,
        userPreferences: store.getState().userPreferences,
        selectedDeploymentId: store.getState().selectedDeploymentId,
        displayLeaveEditPageDialog: store.getState().displayLeaveEditPageDialog,
      });
    }, 1000)
  );

  return unsubscribe;
};

// Start the history listener, which automatically
// dispatches actions to keep the store in sync with history.
startListener(history, store);

sagaMiddleware.run(pollingSaga);

export default store;
