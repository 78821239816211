import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ExpandIcon from '@mui/icons-material/KeyboardArrowRight';
import KebabIcon from '@mui/icons-material/MoreVert';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import config from 'config';
import {
  REMOTE_WORKSTATIONS,
  ENROLLMENT_STATUS_WAITING_WORKER_ACCEPT,
} from 'utils/constants';
import {mapResourceToIdField} from 'utils/Mappings';
import {selectDeletingResource} from 'utils/reduxSelectors';
import TableHead from './TableHead';

const PREFIX = 'NormalTable';

const classes = {
  table: `${PREFIX}-table`,
  tableRow: `${PREFIX}-tableRow`,
  array: `${PREFIX}-array`,
  link: `${PREFIX}-link`,
  name: `${PREFIX}-name`,
  object: `${PREFIX}-object`,
  icon: `${PREFIX}-icon`,
  enrollmentButton: `${PREFIX}-enrollmentButton`,
  noDataMessage: `${PREFIX}-noDataMessage`,
  rotate: `${PREFIX}-rotate`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.table}`]: {
    minWidth: '64rem',
  },

  [`& .${classes.tableRow}`]: {
    backgroundColor: 'white',
  },

  [`& .${classes.array}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '16rem',
  },

  [`& .${classes.link}`]: {
    color: '#0076a9',
    textDecoration: 'none',
  },

  [`& .${classes.name}`]: {
    color: '#0076A9',
    fontWeight: 500,
    letterSpacing: '0.015625rem',
  },

  [`& .${classes.object}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '16rem',
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  [`& .${classes.icon}`]: {
    align: 'center',
    textAlign: 'center',
  },

  [`& .${classes.enrollmentButton}`]: {
    align: 'center',
    textAlign: 'center',
  },

  [`& .${classes.noDataMessage}`]: {
    backgroundColor: '#FAFAF9',
    padding: '50px 40px',
    textAlign: 'center',
    color: '#0076A9',
  },

  [`& .${classes.rotate}`]: {transform: 'rotate(90deg)'}
}));

function NormalTable({
  data,
  resource,
  handleCheckAll,
  hideActions,
  hideBulkActions,
  handleCheckbox,
  onClickKebab,
  allChecked,
  indeterminateChecked,
  checkedRows,
  displayField,
  useExpansionTable,
  renderCustomDrawer,
  noDataMessage,
}) {

  const [open, setOpen] = useState(undefined);

  const deletingResource = useSelector((state) =>
    selectDeletingResource(state, resource)
  );
  const headers = useSelector((state) =>
    Object.values(state.tableColumns[resource] || {})
      .filter((header) => header.enabled)
      .sort((a, b) => (a.index < b.index ? -1 : 1))
  );

  // Calculate the number of columns in the table by the different prop options; used
  // to enable expansion drawer to have the same width as the entire table
  const numColumns =
    useExpansionTable + !hideBulkActions + headers.length + !hideActions;
  const idField = mapResourceToIdField(resource);

  const displayFieldHover = (row, header) => {
    const {type} = header;
    const field = row[header.id];

    switch (type) {
      case 'array':
        return field.join(',\n') || null;
      case 'tooltip':
        return row[header.tooltip] || null;
      case 'object':
        return JSON.stringify(field);
      default:
        return null;
    }
  };

  const isActionInProgress = (row) =>
    deletingResource[row[idField]] ||
    ['deleting', 'deleted', ENROLLMENT_STATUS_WAITING_WORKER_ACCEPT].includes(
      row.status
    );

  const isRowChecked = (row) => {
    const id = row[idField];
    return id in checkedRows && !['deleting', 'deleted'].includes(row.status);
  };

  const expandClick = (id) => {
    setOpen(id === open ? undefined : id);
  };

  const renderExpandIcon = (row) => (
    <TableCell padding="checkbox">
      <IconButton
        data-testid={`${row[idField]}-expand-icon`}
        size="small"
        onClick={() => expandClick(row[idField])}
        className={classNames({[classes.rotate]: open === row[idField]})}
      >
        <ExpandIcon />
      </IconButton>
    </TableCell>
  );

  const renderCheckbox = (row, index) =>
    isActionInProgress(row) ? (
      <TableCell padding="checkbox">
        <CircularProgress size={16} />
      </TableCell>
    ) : (
      !hideBulkActions && (
        <TableCell padding="checkbox">
          <Checkbox
            data-testid={`checkbox-${index}`}
            onChange={(e, checked) => handleCheckbox(row, checked, e, index)}
            checked={isRowChecked(row)}
            color="primary"
            inputProps={{
              'data-testid': `checkbox-${index}-input`,
            }}
          />
        </TableCell>
      )
    );

  const renderDataFields = (row) =>
    headers.map((header) => (
      <TableCell
        title={displayFieldHover(row, header)}
        data-testid={`test-${header.id}-${row[idField]}`}
        id={`${header.id}-${row[idField]}`}
        key={header.id}
        className={classNames(
          {
            [classes.array]: header.type === 'array',
            [classes.link]:
              header.type === 'link' || header.type === 'name-link',
            [classes.name]:
              header.type === 'name' || header.type === 'name-link',
            [classes.date]: header.type === 'date',
            [classes.object]: header.type === 'object',
            [classes.icon]: header.type === 'icon',
            [classes.enrollmentButton]: header.type === 'enrollment-button',
          },
          classes.rowText
        )}
      >
        {displayField(row, header)}
      </TableCell>
    ));

  const renderNoDataMessage = () =>
    !data?.length && (
      <div className={classes.noDataMessage}>{noDataMessage}</div>
    );

  const renderActionsKebab = (row, index) => (
    <TableCell align="center">
      <IconButton
        data-testid={`icon-button-${index}`}
        size="small"
        disabled={isActionInProgress(row)}
        onClick={(event) => onClickKebab(event, row)}
      >
        <KebabIcon />
      </IconButton>
    </TableCell>
  );

  const renderDrawer = (row) => (
    <TableCell padding="none" colSpan={numColumns}>
      <Collapse in={open === row[idField]} timeout="auto" unmountOnExit>
        {renderCustomDrawer(row)}
      </Collapse>
    </TableCell>
  );

  return (
    (<Root>
      <Table className={classes.table} size="small" stickyHeader>
        <TableHead
          headers={headers}
          handleCheckAll={handleCheckAll}
          checked={allChecked}
          indeterminate={indeterminateChecked}
          hideActions={hideActions}
          hideBulkActions={hideBulkActions}
          resource={resource}
          addExpandSpacing={useExpansionTable}
        />
        <TableBody>
          {data.map((row, index) => (
            <Fragment key={`${row[idField]}-container`}>
              <TableRow
                key={`${row[idField]}-row`}
                data-testid={row[idField]}
                hover
                className={classes.tableRow}
              >
                {useExpansionTable && renderExpandIcon(row)}
                {renderCheckbox(row, index)}
                {renderDataFields(row)}
                {!hideActions && renderActionsKebab(row, index)}
              </TableRow>
              {useExpansionTable && (
                <TableRow key={`${row[idField]}-drawer`}>
                  {renderDrawer(row)}
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
      {renderNoDataMessage()}
    </Root>)
  );
}

NormalTable.propTypes = {
  data: PropTypes.array,
  resource: PropTypes.string,
  displayField: PropTypes.func,
  onClickKebab: PropTypes.func,
  handleCheckbox: PropTypes.func,
  handleCheckAll: PropTypes.func,
  allChecked: PropTypes.bool,
  indeterminateChecked: PropTypes.bool,
  checkedRows: PropTypes.object,
  hideActions: PropTypes.bool,
  hideBulkActions: PropTypes.bool,
  useExpansionTable: PropTypes.bool,
  renderCustomDrawer: PropTypes.func,
  noDataMessage: PropTypes.string,
};

NormalTable.defaultProps = {
  data: [],
  resource: REMOTE_WORKSTATIONS,
  displayField: (field) => field,
  onClickKebab: () => {},
  handleCheckbox: () => {},
  handleCheckAll: () => {},
  allChecked: false,
  indeterminateChecked: false,
  checkedRows: {},
  hideActions: false,
  hideBulkActions: false,
  useExpansionTable: false,
  renderCustomDrawer: () => {},
  noDataMessage: 'No data available',
};

export default NormalTable;
