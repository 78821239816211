import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';
import {Grid} from '@mui/material';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import {push} from 'redux/ReduxHistory';
import {getEditConnectorTabLink} from 'utils/Mappings';
import {
  AD_CONFIG_CAPABILITY_NAME,
  AD_SYNC_CAPABILITY_NAME,
  MFA_CAPABILITY_NAME,
  CONNECTOR_AD_CONFIG_TAB_STRING,
  CONNECTOR_AUTH_TAB_STRING,
  CONNECTOR_STATUS_PENDING,
} from 'utils/constants';
import {isEmpty} from 'utils/utils';
import CapabilityInfo from './CapabilityInfo';

const PREFIX = 'CapabilitySection';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  item: `${PREFIX}-item`,
  bodyText: `${PREFIX}-bodyText`,
  subheader: `${PREFIX}-subheader`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.wrapper}`]: {
    display: 'flex',
    marginTop: '20px',
  },

  [`& .${classes.item}`]: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    flex: 1,
    padding: '24px 24px 8px 24px',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '370px',
  },

  [`& .${classes.bodyText}`]: {
    ...stylesFromFigma.connectorPanelBodyText,
  },

  [`& .${classes.subheader}`]: {
    ...stylesFromFigma.capabilitySubHeader,
    marginTop: '16px',
  },
}));

const LOADING_STATE = 'loading';
const PENDING_STATE = CONNECTOR_STATUS_PENDING;
const CAPABILITY_UNSUPPORTED_STATE = 'unsupported';
const CAPABILITY_SUPPORTED_STATE = 'supported';

function CapabilitySection() {
  const dispatch = useDispatch();
  const selectedConnector = useSelector(
    (state) => state?.data?.dataByResource?.selectedConnector
  );

  let capabilityDisplayState = '';
  if (isEmpty(selectedConnector)) {
    capabilityDisplayState = LOADING_STATE;
  } else if (
    selectedConnector?.status === CONNECTOR_STATUS_PENDING ||
    selectedConnector?.installationStatus === 'notInstalled'
  ) {
    capabilityDisplayState = PENDING_STATE;
  } else if (
    Array.isArray(selectedConnector?.capabilities) &&
    selectedConnector?.capabilities.length > 0
  ) {
    capabilityDisplayState = CAPABILITY_SUPPORTED_STATE;
  } else {
    capabilityDisplayState = CAPABILITY_UNSUPPORTED_STATE;
  }

  const capabilityList = selectedConnector?.capabilities || [];
  const connectorId = selectedConnector?.connectorId || '';

  const adConfigCapability = capabilityList.find(
    (cap) => cap.capabilityName === AD_CONFIG_CAPABILITY_NAME
  );
  const adSyncCapability = capabilityList.find(
    (cap) => cap.capabilityName === AD_SYNC_CAPABILITY_NAME
  );
  const mfaCapability = capabilityList.find(
    (cap) => cap.capabilityName === MFA_CAPABILITY_NAME
  );

  const adConfigLink = getEditConnectorTabLink(
    CONNECTOR_AD_CONFIG_TAB_STRING,
    connectorId
  );
  const authenticationLink = getEditConnectorTabLink(
    CONNECTOR_AUTH_TAB_STRING,
    connectorId
  );

  const isLoading = isEmpty(selectedConnector);

  const connectorPendingDisplay = (
    <div>
      <Typography className={classes.subheader}>
        Capabilities unavailable
      </Typography>
      <Typography className={classes.bodyText}>
        Information about Connector Capabilities is not available until
        installation has finished.
      </Typography>
    </div>
  );

  const connectorUnsupportedDisplay = (
    <>
      <Typography className={classes.subheader}>
        Capabilities unavailable
      </Typography>
      <Typography className={classes.bodyText}>
        Information about Capabilities is not supported for this version of the
        Connector.
      </Typography>
    </>
  );

  const capabilitySupportedDisplay = (
    <Grid container row className={classes.wrapper}>
      <Grid item className={classes.item}>
        <CapabilityInfo
          capabilityData={adConfigCapability}
          isLoading={isLoading}
          onAction={() => dispatch(push(adConfigLink))}
        />
      </Grid>
      <Grid item className={classes.item}>
        <CapabilityInfo
          capabilityData={adSyncCapability}
          isLoading={isLoading}
          onAction={() => dispatch(push(adConfigLink))}
        />
      </Grid>
      <Grid item className={classes.item}>
        <CapabilityInfo
          capabilityData={mfaCapability}
          isLoading={isLoading}
          onAction={() => dispatch(push(authenticationLink))}
        />
      </Grid>
    </Grid>
  );

  return (
    <Root>
      {capabilityDisplayState === PENDING_STATE && connectorPendingDisplay}
      {capabilityDisplayState === CAPABILITY_UNSUPPORTED_STATE &&
        connectorUnsupportedDisplay}
      {capabilityDisplayState === CAPABILITY_SUPPORTED_STATE &&
        capabilitySupportedDisplay}
    </Root>
  );
}

export default CapabilitySection;
