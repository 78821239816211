import {useDispatch} from 'react-redux';
import {pushNotification} from 'redux/actions/notificationActions';

const usePushNotification = () => {
  const dispatch = useDispatch();

  const notify = (config) => {
    dispatch(pushNotification(config));
  };

  return notify;
};

export default usePushNotification;
