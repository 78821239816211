export const DISPLAY_DEPLOYMENT_SA_CRED = 'DISPLAY_DEPLOYMENT_SA_CRED';
export const CLEAR_DEPLOYMENT_SA_CRED = 'CLEAR_DEPLOYMENT_SA_CRED';

export function updateDeploymentSACred(credentials) {
  return {
    type: DISPLAY_DEPLOYMENT_SA_CRED,
    credentials,
  };
}

export function clearDeploymentSACred() {
  return {
    type: CLEAR_DEPLOYMENT_SA_CRED,
  };
}
