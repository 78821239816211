import PropTypes from 'prop-types';

const defaultColor = '#000';

function CopyIcon(props) {
  const {iconColor, opacity} = props;
  return (
    <svg width="16" height="17">
      <defs>
        <path
          d="M12.375.75h-9c-.825 0-1.5.675-1.5 1.5v10.5h1.5V2.25h9V.75zm2.25 3h-8.25c-.825 0-1.5.675-1.5 1.5v10.5c0 .825.675 1.5 1.5 1.5h8.25c.825 0 1.5-.675 1.5-1.5V5.25c0-.825-.675-1.5-1.5-1.5zm0 12h-8.25V5.25h8.25v10.5z"
          id="copy-path"
        />
      </defs>
      <g transform="translate(-1 -.5)" fill="none" fillRule="evenodd">
        <mask id="copy-mask" fill="#fff">
          <use xlinkHref="#copy-path" />
        </mask>
        <g mask="url(#copy-mask)" fill={iconColor} fillOpacity={opacity}>
          <path d="M0 0h18v18H0z" />
        </g>
      </g>
    </svg>
  );
}

CopyIcon.propTypes = {
  iconColor: PropTypes.string,
  opacity: PropTypes.string,
};

CopyIcon.defaultProps = {
  iconColor: defaultColor,
  opacity: '1',
};

export default CopyIcon;
