import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
const PREFIX = 'PageWrapper';

const classes = {
  wrapper: `${PREFIX}-wrapper`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.wrapper}`]: {
    minWidth: '480px',
    [theme.breakpoints.up('md')]: {
      margin: '0px 48.5px',
      padding: '0px 24px',
    },
  }
}));

function PageWrapper({children}) {

  return <Root className={classes.wrapper}>{children}</Root>;
}

PageWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

PageWrapper.defaultProps = {
  children: null,
};

export default PageWrapper;
