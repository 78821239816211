import PropTypes from 'prop-types';
import {Redirect, Route} from 'react-router-dom';
import CasmCookies from 'utils/cookies';
import AuthenticatedApp from './AuthenticatedApp';

const isUserAuthenticated = () => !!CasmCookies.getItem('AUTH_TOKEN');

function PrivateRoute({component: Component, path}) {
  return (
    <Route
      path={path}
      render={(props) =>
        isUserAuthenticated() ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  path: PropTypes.string.isRequired,
};

PrivateRoute.defaultProps = {
  component: AuthenticatedApp,
};

export default PrivateRoute;
