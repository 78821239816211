import FileCopy from '@mui/icons-material/FileCopyOutlined';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import CopyIcon from 'icons/CopyIcon';
import {theme} from 'themes/default';

const PREFIX = 'CopyToClipboard';

const classes = {
  iconButton: `${PREFIX}-iconButton`,
  grid: `${PREFIX}-grid`,
};

const StyledTooltip = styled(Tooltip)({
  [`& .${classes.iconButton}`]: {
    background: 'none',
    border: 'none',
    padding: 0,
    outline: 'none',
  },
  [`& .${classes.grid}`]: {
    fontSize: '16px',
  },
});

function CopyToClipboard(props) {
  const {
    text,
    onCopy,
    id,
    tooltipProps,
    iconVariant,
    buttonProps,
    showAsText,
    showAsButton,
  } = props;

  const handleCopy = async () => {
    await navigator.clipboard.writeText(text);
    onCopy(null, true);
  };

  const renderText = () => <Grid className={classes.grid}>{text}</Grid>;

  const renderButton = () => (
    <Button
      className={buttonProps.className}
      variant={buttonProps.variant}
      size={buttonProps.size}
      key={buttonProps.key}
      color={buttonProps.color}
      onClick={handleCopy}
      id={id}
      data-testid={id}
      disabled={!text}
    >
      {buttonProps.text}
    </Button>
  );

  const renderIcon = () =>
    iconVariant === 'CopyIcon' ? (
      <CopyIcon opacity={text ? '1' : '0.5'} />
    ) : (
      <FileCopy opacity={text ? '1' : '0.5'} />
    );

  let child;
  if (showAsText) {
    child = renderText();
  } else if (showAsButton) {
    child = renderButton();
  } else {
    child = renderIcon();
  }

  return (
    <StyledTooltip title={tooltipProps.text} placement={tooltipProps.placement}>
      {showAsButton ? (
        <span>{child}</span>
      ) : (
        <span>
          <IconButton
            onClick={handleCopy}
            id={id}
            data-testid={id}
            disabled={!text}
            classes={{
              root: showAsText ? classes.iconButton : buttonProps.className,
            }}
            size={buttonProps.size}
            color={buttonProps.color}
          >
            {child}
          </IconButton>
        </span>
      )}
    </StyledTooltip>
  );
}

CopyToClipboard.propTypes = {
  text: PropTypes.string,
  onCopy: PropTypes.func,
  id: PropTypes.string,
  iconVariant: PropTypes.string,
  tooltipProps: PropTypes.shape({
    text: PropTypes.string,
    placement: PropTypes.string,
    onMouseEnter: PropTypes.func,
  }),
  buttonProps: PropTypes.shape({
    className: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    text: PropTypes.string,
    key: PropTypes.string,
    color: PropTypes.string,
  }),
  showAsText: PropTypes.bool,
  showAsButton: PropTypes.bool,
};

CopyToClipboard.defaultProps = {
  text: '',
  onCopy: () => {},
  id: '',
  iconVariant: 'CopyIcon',
  tooltipProps: {
    text: '',
    placement: 'bottom',
    onMouseEnter: () => {},
  },
  buttonProps: {
    className: '',
    variant: 'contained',
    size: 'small',
    text: '',
    key: '',
    color: theme.palette.text.secondary,
  },
  showAsText: false,
  showAsButton: false,
};

export default CopyToClipboard;
