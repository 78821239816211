import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import useDialog from 'hooks/useDialog';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {removePoolUsers} from 'redux/actions/dataActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {POOL_USERS} from 'utils/constants';
import {selectSelectedTableResource} from 'utils/reduxSelectors';

function PoolUsersBulkActions({onClose, onDelete}) {
  const dispatch = useDispatch();
  const {triggerDialog} = useDialog();

  const poolUsers = useSelector((state) =>
    selectSelectedTableResource(state, POOL_USERS)
  );

  const handleRemoveUsers = async () => {
    onDelete();
    await dispatch(removePoolUsers(poolUsers));
    dispatch(fetchTableData(POOL_USERS));
  };

  const handleRemoveClick = () => {
    const poolUserNames = poolUsers.map((user) => user.name).join(', ');
    const numUsers = poolUsers.length;
    triggerDialog({
      title: `Remove ${numUsers} user${numUsers > 1 ? 's' : ''} from the pool?`,
      message: `Remove ${poolUserNames}? Removing a user will not delete their account, it will only remove
        their entitlement to the pool. This operation cannot be undone.`,
      onConfirm: handleRemoveUsers,
    });
    onClose();
  };

  return (
    <KebabMenuItem
      id="bulk-remove-pool-users"
      dataTestId="bulk-remove-pool-users"
      menuText="Remove"
      onClick={handleRemoveClick}
    />
  );
}

PoolUsersBulkActions.propTypes = {
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

PoolUsersBulkActions.defaultProps = {
  onClose: () => {},
  onDelete: () => {},
};

export default PoolUsersBulkActions;
