import {styled} from '@mui/material/styles';
import {FormControl, FormControlLabel, Typography} from '@mui/material';
import ToggleSwitch from 'components/CAM/buttons/ToggleSwitch/ToggleSwitch';

import {ReactComponent as CliIcon} from 'icons/cli.svg';
import ConfigureStepCard from './ConfigureStepCard';
import {stepIndices} from './wizardConstants';
import {useWizardState} from './wizardConfigState';

const PREFIX = 'ConfigurationServiceCommandCard';

const classes = {
  helpText: `${PREFIX}-helpText`,
};

const Root = styled('div')(() => ({
  [`& .${classes.helpText}`]: {
    marginTop: '8px',
    marginBottom: '8px',
  },
}));

function ConfigurationServiceCommandCard() {
  const {
    activeStepIndex,
    setActiveStepIndex,
    setConfigState,
    configState,
    isCommandLocked,
  } = useWizardState();

  const STEP_INDEX = stepIndices.configureConfigurationService;
  const TITLE = 'Configuration Service';

  const IS_OPEN = activeStepIndex === STEP_INDEX;
  const IS_COMPLETE = activeStepIndex > STEP_INDEX;

  return (
    <Root>
      <ConfigureStepCard
        stepIndex={STEP_INDEX}
        title={TITLE}
        open={IS_OPEN}
        onTitleClick={() => {
          if (activeStepIndex > STEP_INDEX && !isCommandLocked)
            setActiveStepIndex(STEP_INDEX);
        }}
        complete={IS_COMPLETE}
        onContinueClick={() => setActiveStepIndex(STEP_INDEX + 1)}
        onPreviousClick={() => setActiveStepIndex(STEP_INDEX - 1)}
        icon={<CliIcon />}
      >
        <Typography variant="body1" className={classes.helpText}>
          When the Configuration Service is enabled this allows Connector
          configuration changes to be applied automatically via the Admin
          Console.
        </Typography>
        <FormControl>
          <FormControlLabel
            label="Enable Configuration Service"
            control={
              <ToggleSwitch
                name="enableConfigurationService"
                isOn={configState.enableConfigurationService}
                onClick={(event) => {
                  const updatedState = {...configState};
                  const {name, checked} = event.target;
                  updatedState[name] = checked;
                  setConfigState(updatedState);
                }}
              />
            }
          />
        </FormControl>
      </ConfigureStepCard>
    </Root>
  );
}

ConfigurationServiceCommandCard.propTypes = {};

ConfigurationServiceCommandCard.defaultProps = {
  installCommand: '',
};

export default ConfigurationServiceCommandCard;
