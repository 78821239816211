export const OPEN_AZURE_ROLE_DIALOG = 'OPEN_AZURE_ROLE_DIALOG';

export function openAzureRoleDialog(roleReport, canManage) {
  return {
    type: OPEN_AZURE_ROLE_DIALOG,
    roleReport,
    canManage,
  };
}

export const CLOSE_AZURE_ROLE_DIALOG = 'CLOSE_AZURE_ROLE_DIALOG';

export function closeAzureRoleDialog() {
  return {
    type: CLOSE_AZURE_ROLE_DIALOG,
  };
}
