import PropTypes from 'prop-types';

const defaultColor = '#005788';

const UploadIcon = (props) => {
  const {iconColor} = props;

  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M14.2222222,8.44444444 L14.2222222,2.22222222 L1.77777778,2.22222222 L1.77777778,8.44444444 L0,8.44444444 L0,2.22222222 C0,1.24444444 0.8,0.444444444 1.77777778,0.444444444 L14.2222222,0.444444444 C15.2,0.444444444 16,1.24444444 16,2.22222222 L16,8.44444444 L14.2222222,8.44444444 Z M8.88888889,12.1511111 L11.1911111,9.85777778 L12.4444444,11.1111111 L8,15.5555556 L3.55555556,11.1111111 L4.80888889,9.85777778 L7.11111111,12.1511111 L7.11111111,3.55555556 L8.88888889,3.55555556 L8.88888889,12.1511111 Z"
          id="upload-path"
        />
        <rect id="path-3" x="0" y="0" width="21" height="21" />
      </defs>
      <g
        id="Desktop02"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="1.1-dp_ct_drop/-v3"
          transform="translate(-287.000000, -694.000000)"
        >
          <g
            id="icon/content/upload"
            transform="translate(295.000000, 702.000000) scale(1, -1) translate(-295.000000, -702.000000) translate(287.000000, 694.000000)"
          >
            <mask id="upload-mask" fill="white">
              <use xlinkHref="#upload-path" />
            </mask>
            <g
              id="Image-Treatments-/-Blend-Mode-/-Multiply-/-Primary-/-600"
              mask="url(#upload-mask)"
            >
              <g transform="translate(-2.666667, 0.000000)">
                <g
                  id="✱-/-Color-/-Primary-/-900"
                  stroke="none"
                  fill="none"
                  mask="url(#mask-4)"
                  fillRule="evenodd"
                >
                  <rect
                    id="Rectangle-13"
                    fill={iconColor || defaultColor}
                    x="0"
                    y="0"
                    width="21"
                    height="21"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

UploadIcon.propTypes = {
  iconColor: PropTypes.string,
};

UploadIcon.defaultProps = {
  iconColor: defaultColor,
};

export default UploadIcon;
