import {UserAgentApplication} from 'msal';
import config from 'config';

const msalConfig = {
  auth: {
    clientId: config.MICROSOFT_CLIENT_ID,
    redirectUri: config.MSAL_REDIRECT_URL,
  },
};

export const requestObj = {
  scopes: ['user.read'],
  prompt: 'select_account',
};

export function getMsalInstance() {
  return new UserAgentApplication(msalConfig);
}

export async function silentlyAcquireToken(msalInstance) {
  const tokenResponse = await msalInstance.acquireTokenSilent(requestObj);

  return tokenResponse.accessToken;
}

export function msalSignInPopUp(msalInstance) {
  return msalInstance.loginPopup(requestObj);
}

export function msalSignOut(msalInstance) {
  if (msalInstance) {
    msalInstance.clearCache();
  }
}

export function requiresInteraction(errorMessage) {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  );
}
