import {IconButton} from '@mui/material';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Icon from '@mui/material/Icon';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import {useState} from 'react';
import CopyToClipboard from 'components/CAM/buttons/CopyToClipboard/CopyToClipboard';
import useSnackbar from 'hooks/useSnackbar';
import PasswordHidden from 'icons/password_hidden.svg';
import PasswordVisible from 'icons/password_visible.svg';
import WrenchSvg from 'icons/tools_wrench.svg';
import {AWM_API_DOCUMENTATION} from 'utils/constants';
import CasmCookies from 'utils/cookies';

const PREFIX = 'ApiTokenDialog';

const classes = {
  backdrop: `${PREFIX}-backdrop`,
  icon: `${PREFIX}-icon`,
  tokenDialog: `${PREFIX}-tokenDialog`,
  tokenDialogHeader: `${PREFIX}-tokenDialogHeader`,
  tokenDialogTitle: `${PREFIX}-tokenDialogTitle`,
  tokenDialogBody: `${PREFIX}-tokenDialogBody`,
  tokenField: `${PREFIX}-tokenField`,
  copyIcon: `${PREFIX}-copyIcon`,
  inputProps: `${PREFIX}-inputProps`,
  visibilityToggle: `${PREFIX}-visibilityToggle`,
  link: `${PREFIX}-link`,
};

const StyledDialog = styled(Dialog)(({theme}) => ({
  [`& .${classes.backdrop}`]: {
    backgroundColor: 'rgba(255, 255,255,0.6)',
  },

  [`& .${classes.icon}`]: {
    marginLeft: '16px',
    height: '1.3em',
    width: '1.5em',
  },

  [`& .${classes.tokenDialog}`]: {
    width: '31.125rem',
    backgroundColor: theme.palette.menu.active,
  },

  [`& .${classes.tokenDialogHeader}`]: {
    alignItems: 'center',
    backgroundColor: '#0076A9',
    borderRadius: '4px 4px 0 0',
    display: 'flex',
    height: '48px',
  },

  [`& .${classes.tokenDialogTitle}`]: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: '1.25rem',
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: '24px',
    marginLeft: '6px',
  },

  [`& .${classes.tokenDialogBody}`]: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    margin: '1rem 1.5rem',
  },

  [`& .${classes.tokenField}`]: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #DDDBDA',
    borderRadius: '4px',
    height: '34px',
    marginTop: '5px',
    width: '25rem',
  },

  [`& .${classes.copyIcon}`]: {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'inherit',
      color: '#35B4E1',
    },
  },

  [`& .${classes.inputProps}`]: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    paddingLeft: '10px',
  },

  [`& .${classes.visibilityToggle}`]: {padding: '7px'},

  [`& .${classes.link}`]: {
    textDecoration: 'underline',
    color: 'white',
  },
}));

function ApiTokenDialog({open, onClose}) {
  const {successSnackbar} = useSnackbar();

  const [tokenMasked, setTokenMasked] = useState(true);
  const toggleTokenMasked = () => setTokenMasked(!tokenMasked);
  const authToken = CasmCookies.getAuthToken() || '';

  const tokenExpireIn = (token) => {
    const decodedToken = CasmCookies.getDecodedAuthToken(token);
    const secondsToExpire =
      decodedToken && 'exp' in decodedToken
        ? decodedToken.exp - Math.floor(Date.now() / 1000)
        : 3600 * 2;

    const hours = Math.floor(secondsToExpire / 3600);
    const minutes = Math.floor((secondsToExpire % 3600) / 60);
    if (hours === 1) {
      return `${hours} hour and ${`0${minutes}`.slice(-2)} minutes`;
    }
    return `${`0${minutes}`.slice(-2)} minutes`;
  };

  const handleCopy = () => {
    successSnackbar(
      `API token has been copied to your clipboard. This token is valid for ${tokenExpireIn(
        authToken
      )} or until you log out of the Anyware Manager.`
    );
    onClose();
  };

  if (!open) return null;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      // BackdropProps={{ {root: classes.backdrop}}}
    >
      <div className={classes.tokenDialog}>
        <div className={classes.tokenDialogHeader}>
          <Icon className={classes.icon}>
            <img src={WrenchSvg} alt="" />
          </Icon>
          <div className={classes.tokenDialogTitle}>
            Developer API access token
          </div>
        </div>
        <div className={classes.tokenDialogBody}>
          <p>
            API access tokens permit developers to enable other tools to
            interact with Anyware Manager through public APIs.
          </p>
          {`This token will expire in ${tokenExpireIn(
            authToken
          )} or when you log out of the Anyware Manager.`}
          <Input
            className={classes.tokenField}
            readOnly
            type={tokenMasked ? 'password' : 'text'}
            inputProps={{className: classes.inputProps}}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={toggleTokenMasked}
                  className={classes.visibilityToggle}
                  size="large"
                >
                  <img
                    src={tokenMasked ? PasswordHidden : PasswordVisible}
                    alt=""
                  />
                </IconButton>
              </InputAdornment>
            }
            disableUnderline
            value={authToken}
          />
          <CopyToClipboard
            text={authToken}
            onCopy={handleCopy}
            iconVariant="FileCopy"
            buttonProps={{size: 'large', className: classes.copyIcon}}
          />
          <p>
            To learn more about how to use Anyware Manager APIs, please see the
            detailed API specs{' '}
            <a
              href={AWM_API_DOCUMENTATION}
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </p>
        </div>
      </div>
    </StyledDialog>
  );
}

ApiTokenDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

ApiTokenDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

export default ApiTokenDialog;
