import {useSnackbar} from 'notistack';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {removeSnackbar} from 'redux/actions/snackbarActions';
import Snackbar from 'components/CAM/display/Snackbar/Snackbar';

function Notifier() {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackbar || []);
  const {pathname} = useSelector((state) => state.router || {pathname: ''});
  const {enqueueSnackbar: addSnackbar, closeSnackbar} = useSnackbar();

  useEffect(() => {
    if (pathname !== '') {
      // Clear all snackbars on navigation to a different page
      closeSnackbar();
    }
  }, [pathname]);

  const renderSnackbar = (notification, key) => (
    <Snackbar
      message={notification.message}
      variant={notification.options.variant}
      keyId={notification.key}
      onClose={() => {
        closeSnackbar(key);
      }}
    />
  );

  useEffect(() => {
    snackbar.forEach((notification) => {
      // Display snackbar using notistack
      const snackbarKey = addSnackbar(notification.message, {
        ...notification.options,
        content: () => renderSnackbar(notification, snackbarKey),
      });
      // Dispatch action to remove snackbar from redux store
      dispatch(removeSnackbar(notification.key));
    });
  }, [snackbar]);

  return null;
}

export default Notifier;
