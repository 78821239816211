import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import anywareLogo from 'icons/anyware-logo-white.svg';

const PREFIX = 'TextPage';

const classes = {
  anywareIconLarge: `${PREFIX}-anywareIconLarge`,
  header: `${PREFIX}-header`,
  textContainer: `${PREFIX}-textContainer`,
  title: `${PREFIX}-title`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.anywareIconLarge}`]: {
    maxWidth: '300px',
    width: '100%',
    margin: 'auto',
  },

  [`& .${classes.header}`]: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    padding: '12px 0px',
  },

  [`& .${classes.textContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    maxWidth: '1200px',
    padding: '30px 0px',
    width: '80%',
  },

  [`& .${classes.title}`]: {
    margin: 'auto',
  },
}));

function TextPage({pageTitle, children}) {
  return (
    <Root>
      <div className={classes.header}>
        <img
          className={classes.anywareIconLarge}
          alt="Anyware Logo"
          src={anywareLogo}
        />
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.title} variant="h4" component="h4">
          {pageTitle}
        </Typography>
        {children}
      </div>
    </Root>
  );
}

TextPage.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

TextPage.defaultProps = {
  pageTitle: '',
  children: null,
};

export default TextPage;
