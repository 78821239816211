import {Grid, Paper, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  Line,
  Text,
  TSpan,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTheme,
} from 'victory';
import {
  CONNECTOR_CHART_HEADER,
  HALF_HOUR_IN_MS,
  NO_CONNECTOR_LOGS_MESSAGE,
} from 'utils/constants';
import {
  calculateConnectorHealthScore,
  formatTime,
  getEarliestTimeInInterval,
  truncateTimeByStep,
} from 'utils/utils';

const PREFIX = 'ConnectorHealthChart';

const classes = {
  rootContainer: `${PREFIX}-rootContainer`,
  header: `${PREFIX}-header`,
  noDataMessage: `${PREFIX}-noDataMessage`,
  vAxisLine: `${PREFIX}-vAxisLine`,
  vAxisTickLabel: `${PREFIX}-vAxisTickLabel`,
  vAxisGrid: `${PREFIX}-vAxisGrid`
};

const StyledPaper = styled(Paper)(() => ({
  [`&.${classes.rootContainer}`]: {
    height: '100%',
    minWidth: '450px',
    padding: '1rem',
    flexGrow: '1',
  },

  [`& .${classes.header}`]: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 500,
    fontSize: '1.125rem',
  },

  [`& .${classes.noDataMessage}`]: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '1rem',
    fontWeight: 350,
  },

  [`& .${classes.vAxisLine}`]: {
    ...VictoryTheme.grayscale.axis.style.axis,
    stroke: '#cccccc !important',
    strokeDasharray: '2, 2 !important',
  },

  [`& .${classes.vAxisTickLabel}`]: {
    ...VictoryTheme.grayscale.axis.style.tickLabels,
    fontSize: '10px !important',
    padding: '8px !important',
    fill: 'rgba(0, 0, 0, 0.6) !important',
  },

  [`& .${classes.vAxisGrid}`]: {
    fill: 'none !important',
    pointerEvents: 'painted !important',
    stroke: '#cccccc !important',
    strokeDasharray: '2, 2 !important',
  }
}));

const ChartBarStyle = {
  ...VictoryTheme.grayscale.bar.style,
  data: {
    fill: '#2e89ee',
  },
};

const NUM_BARS_IN_CHART = 12;

function ConnectorHealthChart({data, step, interval}) {


  const [selectedBucket, setSelectedBucket] = useState(null);
  const [mutations, setMutations] = useState(null);
  const scores = data
    .map((log) => ({
      id: log.logId,
      createdOn: log.createdOn,
      score: calculateConnectorHealthScore(log),
    }))
    .sort((a, b) => a.createdOn > b.createdOn);

  const chartContainerRef = useRef(null);
  const [dimensions, setDimensions] = useState({});

  // Used to resize the chart if the sidebar is opened or closed
  const {open: sidebarOpen} = useSelector(
    (state) => state.sidebar || {open: true}
  );

  // Use ref to chart's container to set the new chart width and height
  const resizeChart = () =>
    chartContainerRef.current &&
    setDimensions({
      width: chartContainerRef.current.offsetWidth,
      height: chartContainerRef.current.offsetHeight * 0.85,
    });

  useEffect(() => {
    window.addEventListener('resize', resizeChart);
    resizeChart();
  }, []);

  useEffect(() => resizeChart(), [sidebarOpen]);

  const formatXAxis = (x) => formatTime(x);
  const formatYAxis = (y) => `${y}%`;

  const setSelectedColor = (style, condition = true) => ({
    style: {
      ...style,
      fill: condition ? '#00839b' : '#2e89ee',
    },
  });
  const selectBarEvent = (style, index) => [
    {
      target: ['data'],
      eventKey: [selectedBucket],
      mutation: () => setSelectedColor(style, false),
      callback: () => {
        setMutations(null);
        setSelectedBucket(index === selectedBucket ? null : index);
      },
    },
  ];

  const setHoveredBar = ({style}) => setSelectedColor(style);
  const removeHoveredBar = ({style, index}) =>
    setSelectedColor(style, index === selectedBucket);
  const setSelectedBar = ({style, index}) => {
    setMutations(selectBarEvent(style, index));
    return setSelectedColor(style);
  };

  const generateStepTimes = () => {
    const currentTime = new Date();
    const earliestTime = getEarliestTimeInInterval(currentTime, step, interval);
    return Array(NUM_BARS_IN_CHART + 1)
      .fill()
      .map((_, index) => earliestTime + index * step);
  };

  const prepareData = () => {
    // Create storage bins hashed by time rounded to nearest step division
    const bucketKeys = generateStepTimes();
    const timeBuckets = bucketKeys.reduce(
      (result, key) => ({
        ...result,
        [key]: [],
      }),
      {}
    );

    // Populate the time bins with score data from the health logs
    scores.forEach((score) => {
      const scoreDate = new Date(score.createdOn);
      const truncatedScoreTime = truncateTimeByStep(scoreDate, step);
      if (timeBuckets[truncatedScoreTime]) {
        timeBuckets[truncatedScoreTime].push(score);
      }
    });

    // Calculate the total average score at each discrete time step
    return Object.keys(timeBuckets).map((key) => {
      const bucket = timeBuckets[key];
      const averageScore =
        bucket.length > 0
          ? bucket.reduce((total, log) => total + log.score, 0) / bucket.length
          : 0;
      return {
        score: averageScore,
        time: new Date(Number(key)).toISOString(),
      };
    });
  };

  const renderHeader = () => (
    <Typography className={classes.header}>{CONNECTOR_CHART_HEADER}</Typography>
  );

  const renderHealthChart = () => {
    const axisStyleProps = {
      axisComponent: <Line style={{}} className={classes.vAxisLine} />,
      gridComponent: <Line style={{}} className={classes.vAxisGrid} />,
    };

    const labelStyleProps = {
      className: classes.vAxisTickLabel,
      textComponent: <Text className={classes.vAxisTickLabel} />,
      tspanComponent: <TSpan className={classes.vAxisTickLabel} />,
    };

    const barChartEventHandler = (mutation) => () => [
      {target: 'data', mutation},
    ];
    const barChartEvents = [
      {
        target: 'data',
        eventHandlers: {
          onMouseEnter: barChartEventHandler(setHoveredBar),
          onMouseLeave: barChartEventHandler(removeHoveredBar),
          onClick: barChartEventHandler(setSelectedBar),
        },
      },
    ];

    return (
      <VictoryChart
        domainPadding={20}
        height={dimensions.height}
        width={dimensions.width}
      >
        <VictoryAxis
          {...axisStyleProps}
          independentAxis
          tickFormat={formatXAxis}
          tickLabelComponent={
            <VictoryLabel {...labelStyleProps} angle={45} dy={-9} dx={13} />
          }
        />
        <VictoryAxis
          {...axisStyleProps}
          dependentAxis
          tickLabelComponent={<VictoryLabel {...labelStyleProps} />}
          domain={[0, 100]}
          tickFormat={formatYAxis}
        />
        <VictoryBar
          style={ChartBarStyle}
          barRatio={0.4}
          cornerRadius={{topLeft: 1, topRight: 1}}
          data={prepareData()}
          x="time"
          y="score"
          externalEventMutations={mutations}
          events={barChartEvents}
        />
      </VictoryChart>
    );
  };

  const renderNoDataMessage = () => (
    <Typography
      className={classes.noDataMessage}
      data-testid="no-connector-log-message"
    >
      {NO_CONNECTOR_LOGS_MESSAGE}
    </Typography>
  );

  return (
    <StyledPaper
      className={classes.rootContainer}
      component={Grid}
      container
      direction="column"
      wrap="nowrap"
      ref={chartContainerRef}
    >
      <Grid item xs={1} container>
        {renderHeader()}
      </Grid>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        {scores.length > 0 ? renderHealthChart() : renderNoDataMessage()}
      </Grid>
    </StyledPaper>
  );
}

ConnectorHealthChart.propTypes = {
  data: PropTypes.array,
  step: PropTypes.number,
  interval: PropTypes.number,
};

ConnectorHealthChart.defaultProps = {
  data: [],
  step: HALF_HOUR_IN_MS,
  interval: NUM_BARS_IN_CHART * HALF_HOUR_IN_MS,
};

export default ConnectorHealthChart;
