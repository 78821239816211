import Drawer, {drawerClasses} from '@mui/material/Drawer';
import {styled} from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/ArrowBackIos';
import OpenIcon from '@mui/icons-material/ArrowForwardIos';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkstationsIcon from '@mui/icons-material/DesktopWindowsOutlined';
import ConnectorsIcon from '@mui/icons-material/DeviceHub';
import InstallersIcon from '@mui/icons-material/GetApp';
import UsersIcon from '@mui/icons-material/Group';
import TocIcon from '@mui/icons-material/Toc';
import WebhookIcon from '@mui/icons-material/Webhook';
import PoolsIcon from '@mui/icons-material/Waves';
import classNames from 'classnames';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import config from 'config';
import useBreakpoint from 'hooks/useBreakpoint';
import anywareLogo from 'icons/anyware-logo-white.svg';
import anywareIcon from 'icons/anyware-only-logo-white.svg';
import {fetchCasmVersionInfo} from 'redux/actions/dataActions';
import {closeSidebar, toggleSidebar} from 'redux/actions/sidebarActions';
import {
  AD_USERS_LINK,
  AWM_VERSION_INFO,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  CONNECTORS_LINK,
  DASHBOARD_LINK,
  DEPLOYMENT_SERVICE_ACCOUNTS_LINK,
  INSTALLERS_LINK,
  POOLS_LINK,
  SESSION_HISTORY_LINK,
  WEBHOOKS_LINK,
  WORKSTATIONS_LINK,
} from 'utils/constants';
import {selectVariable} from 'utils/reduxSelectors';
import {isEmpty} from 'utils/utils';

const PREFIX = 'Sidebar';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  drawer: `${PREFIX}-drawer`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  logo: `${PREFIX}-logo`,
  logoSmall: `${PREFIX}-logoSmall`,
  listItemText: `${PREFIX}-listItemText`,
  currentListItem: `${PREFIX}-currentListItem`,
  toggleButton: `${PREFIX}-toggleButton`,
  icon: `${PREFIX}-icon`,
  spacer: `${PREFIX}-spacer`,
  versionLabel: `${PREFIX}-versionLabel`,
  versionText: `${PREFIX}-versionText`,
};

const CustomDrawer = styled(Drawer)(({theme}) => ({
  [`&.${drawerClasses.root}`]: {
    [`& .${classes.drawer}`]: {
      width: 240,
      flexShrink: 0,
    },

    [`& .${classes.drawerOpen}`]: {
      width: 240,
      overflowX: 'hidden',
      border: '1px solid rgba(0,0,0,0.12)',
      borderLeft: 0,
      borderBottom: 0,
      borderTop: 0,
      backgroundColor: config.isUpdatedStylingEnabled()
        ? theme.palette.primary.sidebar
        : '#0076A9',
    },

    [`& .${classes.drawerClose}`]: {
      width: 56,
      overflowX: 'hidden',
      backgroundColor: config.isUpdatedStylingEnabled()
        ? theme.palette.primary.sidebar
        : '#0076A9',
    },

    [`&.${classes.drawerHeader}`]: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: 0,
      minHeight: 80,
      cursor: 'pointer',
    },

    [`& .${classes.logo}`]: {
      textAlign: 'left',
      verticalAlign: 'middle',
      margin: 15,
      height: 50,
      whiteSpace: 'nowrap',
    },

    [`& .${classes.logoSmall}`]: {
      height: 45,
      margin: 5,
      marginTop: 16,
    },

    [`& .${classes.listItemText}`]: {
      color: 'white',
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },

    [`& .${classes.currentListItem}`]: {
      backgroundColor: config.isUpdatedStylingEnabled()
        ? theme.palette.primary.main
        : 'rgba(0, 0, 0, 0.06)',
    },
    [`& .${classes.toggleButton}`]: {marginLeft: 'auto'},
    [`& .${classes.icon}`]: {color: 'white'},
    [`& .${classes.spacer}`]: {flexGrow: 1},

    [`& .${classes.versionLabel}`]: {
      color: 'white',
      fontWeight: 600,
      marginLeft: '6px',
    },

    [`& .${classes.versionText}`]: {
      color: 'white',
      margin: '5px 0px 5px 12px',
      wordBreak: 'break-word',
    },
  },
}));

const Root = styled('div')(() => ({
  [`& .${classes.drawer}`]: {
    width: 240,
  },

  [`& .${classes.drawerOpen}`]: {
    width: 240,
  },

  [`& .${classes.drawerClose}`]: {
    width: 56,
  },
}));

function Sidebar() {
  const dispatch = useDispatch();
  const {open} = useSelector((state) => state.sidebar);
  const currentPage = useSelector((state) => state.router.pathname);

  useBreakpoint(BREAKPOINT_SM, () => dispatch(closeSidebar()));
  useBreakpoint(BREAKPOINT_MD, () => dispatch(closeSidebar()), 'down');

  const casmVersionInfo = useSelector((state) =>
    selectVariable(state, AWM_VERSION_INFO)
  );
  useEffect(() => {
    if (config.STANDALONE) {
      dispatch(fetchCasmVersionInfo());
    }
  }, []);

  const listItems = [
    {
      id: 'dashboard',
      name: 'Dashboard',
      link: DASHBOARD_LINK,
      Icon: DashboardIcon,
    },
    {
      id: 'connectors',
      name: 'Connectors',
      link: CONNECTORS_LINK,
      Icon: ConnectorsIcon,
    },
    {
      id: 'remote-workstations',
      name: 'Workstations',
      link: WORKSTATIONS_LINK,
      Icon: WorkstationsIcon,
    },
    {
      id: 'users',
      name: 'Workstation Users',
      link: AD_USERS_LINK,
      Icon: UsersIcon,
    },
    {
      id: 'pools',
      name: 'Workstation Pools',
      link: POOLS_LINK,
      Icon: PoolsIcon,
    },
  ];

  if (config.isRBACSupported()) {
    listItems.push({
      id: 'deployment-service-accounts',
      name: 'Service Accounts',
      link: DEPLOYMENT_SERVICE_ACCOUNTS_LINK,
      Icon: VpnKeyIcon,
    });
  }
  if (config.isSessionAuditEnabled()) {
    listItems.splice(1, 0, {
      id: 'sessions',
      name: 'Sessions',
      link: SESSION_HISTORY_LINK,
      Icon: TocIcon,
    });
  }

  if (config.isWebhooksEnabled()) {
    listItems.push({
      id: 'webhooks',
      name: 'Webhooks',
      link: WEBHOOKS_LINK,
      Icon: WebhookIcon,
    });
  }

  if (config.STANDALONE) {
    listItems.push({
      id: 'installers',
      name: 'Installers',
      link: INSTALLERS_LINK,
      Icon: InstallersIcon,
    });
  }

  const teradiciLogo = () => (
    <img
      src={open ? anywareLogo : anywareIcon}
      className={classNames(classes.logo, {[classes.logoSmall]: !open})}
      alt="Anyware Manager"
    />
  );

  const handleListItemClick = () => {
    if (window.innerWidth <= BREAKPOINT_SM) {
      dispatch(closeSidebar());
    }
  };

  const SideBarList = (
    <>
      <Link to={DASHBOARD_LINK} data-testid="teradici-logo">
        <div className={classes.drawerHeader}>{teradiciLogo()}</div>
      </Link>
      <List>
        {listItems.map((item) => (
          <Tooltip
            key={item.id}
            title={item.name}
            placement="right"
            disableHoverListener={open}
          >
            <Link to={`${item.link}`} data-testid={`${item.id}-sidebar-link`}>
              <ListItem
                button
                disableRipple
                className={classNames({
                  [classes.currentListItem]: currentPage === item.link,
                })}
                data-testid={`${item.id}-sidebar-item`}
                onClick={handleListItemClick}
              >
                <ListItemIcon className={classes.icon}>
                  <item.Icon />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={item.name}
                    classes={{primary: classes.listItemText}}
                  />
                )}
              </ListItem>
            </Link>
          </Tooltip>
        ))}
      </List>
      <div className={classes.spacer} />

      {open && !isEmpty(casmVersionInfo) && (
        <>
          <div className={classes.versionLabel}>Version:</div>
          <div className={classes.versionText}>{casmVersionInfo.version}</div>
          <div className={classes.versionLabel}>Build date:</div>
          <div className={classes.versionText}>{casmVersionInfo.buildDate}</div>
        </>
      )}

      <div className={classes.toggleButton}>
        <IconButton
          onClick={() => dispatch(toggleSidebar())}
          className={classes.icon}
          data-testid="toggle-sidebar"
          size="large"
        >
          {open ? <CloseIcon /> : <OpenIcon />}
        </IconButton>
      </div>
    </>
  );

  return (
    <Root>
      {/* Full-width */}

      <Hidden mdDown>
        <CustomDrawer
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
          variant="permanent"
        >
          {SideBarList}
        </CustomDrawer>
      </Hidden>

      {/* Small */}

      <Hidden mdUp>
        <CustomDrawer
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
          onClose={() => dispatch(closeSidebar())}
          transitionDuration={0}
          variant="temporary"
          PaperProps={{
            elevation: 0,
          }}
        >
          {SideBarList}
        </CustomDrawer>
      </Hidden>
    </Root>
  );
}

export default Sidebar;
