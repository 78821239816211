import {
  Card,
  CardContent,
  Checkbox,
  Chip,
  Typography,
  FormControlLabel,
  Grid,
  InputAdornment,
  Input,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import Select from 'react-select';
import CopyToClipboard from 'components/CAM/buttons/CopyToClipboard/CopyToClipboard';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import CAMTooltip from 'components/CAM/display/CAMTooltip/CAMTooltip';
import GridN from 'components/CAM/layout/GridN/GridN';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';
import CAMTextField from 'components/common/TextField';
import useUpdateCacSettings from 'hooks/useUpdateCacSettings';
import {fetchConnectorSettings} from 'redux/actions/dataActions';
import {isHttpsUrl} from 'utils/utils';
import {updateConnectorHelpText} from 'utils/constants';
import useSnackbar from 'hooks/useSnackbar';

const PREFIX = 'FedAuthForm';

const classes = {
  commandInput: `${PREFIX}-commandInput`,
  description: `${PREFIX}-description`,
  primaryTag: `${PREFIX}-primaryTag`,
  enableFedAuthText: `${PREFIX}-enableFedAuthText`,
  fedAuthCard: `${PREFIX}-fedAuthCard`,
  gridItem: `${PREFIX}-gridItem`,
  saveButton: `${PREFIX}-saveButton`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.commandInput}`]: {
    height: '2.25rem',
    backgroundColor: '#efefefef',
    width: '100%',
    borderRadius: '4px',
    padding: '8px',
    fontWeight: 'bold',
  },
  [`& .${classes.description}`]: {
    color: '#514F4D',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '18px',
    margin: '10px 0px',
  },
  [`& .${classes.primaryTag}`]: {
    marginLeft: '8px',
    background: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  [`& .${classes.enableFedAuthText}`]: {
    marginRight: 0,
  },
  [`& .${classes.fedAuthCard}`]: {
    width: '100%',
  },
  [`& .${classes.gridItem}`]: {
    alignSelf: 'center',
  },
  [`& .${classes.saveButton}`]: {
    width: 'auto',
    marginRight: '16px',
  },
}));

const fedAuthDescription =
  'Enabling this feature allows Anyware Connectors to authenticate PCoIP sessions via a third party IDP.';

function FedAuthForm({
  settings,
  connectorId,
  inheritDeploymentSettings,
  canResetSettings,
}) {
  const dispatch = useDispatch();
  const {successSnackbar} = useSnackbar();

  let oauthConfig;

  const oauthFlowOptions = [
    {value: 'OAUTH_FLOW_CODE_WITH_PKCE', label: 'Authorization Code with PKCE'},
    {value: 'Hybrid', label: 'Hybrid (Not supported)', disabled: true},
    {value: 'Implicit', label: 'Implicit (Not supported)', disabled: true},
  ];
  const defaultOauthFlow = oauthFlowOptions[0];

  const webEnrollmentOption = {
    value: 'CA_WEB_ENROLLMENT',
    label: 'Certification Authority Web Enrollment Service',
  };
  const caCertOption = {value: 'CA_CERT', label: 'CA Certificate'};
  const ssoOptions = [webEnrollmentOption, caCertOption];
  const defaultSSOOption = webEnrollmentOption;

  if (!connectorId) {
    ({oauth: oauthConfig} = settings);
  } else {
    ({oauth: oauthConfig} = settings);
  }
  const [authUrl, setAuthUrl] = useState(oauthConfig?.idProviderUrl || '');
  const [fedAuthChecked, setFedAuthChecked] = useState(
    Boolean(oauthConfig?.enabled)
  );
  const [idpAppClientIdText, setIdpAppClientIdText] = useState(
    oauthConfig?.idpAppClientId || ''
  );
  const [selectedOauthFlow, setSelectedOauthFlow] = useState(
    oauthFlowOptions.find(
      (element) => element.value === oauthConfig?.oauthFlowCode
    ) || oauthFlowOptions[0]
  );
  const [selectedSSOOption, setSelectedSSOOption] = useState(defaultSSOOption);
  const [enableSSOChecked, setEnableSSOChecked] = useState(
    Boolean(oauthConfig?.enableSSO)
  );
  const [ssoEnrollmentUrlText, setSSOEnrollmentUrlText] = useState(
    oauthConfig?.ssoEnrollmentUrl || ''
  );
  const [
    ssoEnrollmentCertificateTemplateText,
    setSSOEnrollmentCertificateTemplateText,
  ] = useState(oauthConfig?.ssoEnrollmentCertificateTemplate || '');

  useEffect(() => {
    setAuthUrl(oauthConfig?.idProviderUrl || '');
    setFedAuthChecked(Boolean(oauthConfig?.enabled));
    setIdpAppClientIdText(oauthConfig?.idpAppClientId || '');
    setSelectedOauthFlow(
      oauthFlowOptions.find(
        (element) => element.value === oauthConfig?.oauthFlowCode
      ) || oauthFlowOptions[0]
    );
    setEnableSSOChecked(Boolean(oauthConfig?.enableSSO));
    setSSOEnrollmentUrlText(oauthConfig?.ssoEnrollmentUrl || '');
    setSSOEnrollmentCertificateTemplateText(
      oauthConfig?.ssoEnrollmentCertificateTemplate || ''
    );
    if (oauthConfig?.enabled && oauthConfig?.enableSSO) {
      if (oauthConfig?.ssoEnrollmentUrl) {
        setSelectedSSOOption(webEnrollmentOption);
      } else {
        setSelectedSSOOption(caCertOption);
      }
    }
  }, [oauthConfig]);

  const {mutate: updateDeploymentCacSettings, isLoading} = useUpdateCacSettings(
    connectorId,
    {
      onSuccess: () => {
        dispatch(fetchConnectorSettings(connectorId));
      },
    }
  );

  useEffect(() => {
    if (!enableSSOChecked) {
      setSSOEnrollmentUrlText('');
      setSSOEnrollmentCertificateTemplateText('');
    }
  }, [enableSSOChecked]);

  useEffect(() => {
    if (selectedSSOOption.value !== webEnrollmentOption.value) {
      setSSOEnrollmentUrlText('');
      setSSOEnrollmentCertificateTemplateText('');
    }
  }, [selectedSSOOption]);

  const handleChangeUrl = (e) => {
    setAuthUrl(e.target.value);
  };

  const handleChangeIdpAppClientId = (e) => {
    setIdpAppClientIdText(e.target.value);
  };

  const urlField = (
    <Grid container direction="column">
      <InputLabel
        displayText="Authorization URL"
        tooltipText="The third party IDP URL that the Connector(s) will use to authenticate and authorize users"
      />
      <CAMTextField
        value={authUrl}
        fieldType="text"
        dataTestId="fed-auth-url"
        isRequired
        isError={authUrl !== '' && !isHttpsUrl(authUrl)}
        onChange={handleChangeUrl}
      />
    </Grid>
  );

  const idpAppClientIdField = (
    <Grid container direction="column">
      <InputLabel displayText="Client ID" />
      <CAMTextField
        value={idpAppClientIdText}
        fieldType="text"
        dataTestId="fed-client-id"
        onChange={handleChangeIdpAppClientId}
      />
    </Grid>
  );

  // field for selecting oauth flow, currently not supported by FA yet
  // const oauthFlowField = (
  //   <>
  //     <InputLabel displayText="Oauth flow" />
  //     <Select
  //       value={selectedOauthFlow}
  //       menuPortalTarget={document.body} // this ensure the menu is on top of the fed auth form
  //       options={oauthFlowOptions}
  //       isOptionDisabled={(option) => option.disabled}
  //       onChange={setSelectedOauthFlow}
  //     />
  //   </>
  // );

  const ssoOptionField = (
    <>
      <InputLabel displayText="SSO Configuration Method" />
      <Select
        value={selectedSSOOption}
        menuPortalTarget={document.body} // this ensure the menu is on top of the fed auth form
        options={ssoOptions}
        isOptionDisabled={(option) => option.disabled}
        onChange={setSelectedSSOOption}
      />
    </>
  );

  const baseUpdateCommand =
    'cloud-access-connector update --pull-connector-config';
  const ssoCaCertCommand = `${baseUpdateCommand} --sso-signing-csr-ca <filepath to CA certificate> --sso-signing-csr-key <filepath to CA private key>  --sso-signing-crl <filepath to certificate revocation list>`;
  const ssoEnrollmentCommand = `${baseUpdateCommand} --sso-enrollment-domain example.com --sso-enrollment-username user1 --sso-enrollment-password password`;

  const ssoCaCertFields = (
    <Grid item xs={12}>
      Please supply CA certificate, key and CRLs file during install/update. Ex.
      <Input
        className={classes.commandInput}
        fullWidth
        readOnly
        variant="outlined"
        type="text"
        data-testid="input-with-cac-cmd"
        endAdornment={
          <InputAdornment position="end">
            <CopyToClipboard
              text={ssoCaCertCommand}
              onCopy={() => {
                successSnackbar('Copied command to clipboard!');
              }}
              id="copy-cac-cmd-button"
            />
          </InputAdornment>
        }
        disableUnderline
        value={`$ ${ssoCaCertCommand}`}
      />
    </Grid>
  );

  const ssoWebEnrollmentFields = (
    <>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <InputLabel
          displayText="Certification Authority Web Enrollment URL"
          tooltipText="The URL of the Certification Authority Web Enrollment. Ex. https://<servername>"
        />
        <CAMTextField
          value={ssoEnrollmentUrlText}
          placeholderText="https://<servername>"
          fieldType="text"
          dataTestId="web-enrollment-url"
          isError={
            ssoEnrollmentUrlText !== '' && !isHttpsUrl(ssoEnrollmentUrlText)
          }
          onChange={(e) => setSSOEnrollmentUrlText(e.target.value)}
        />
      </Grid>
      <Grid item xs={0} sm={6} />
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <InputLabel
          displayText="Certificate Template Name"
          tooltipText="The configured template name which is used to generate login certificate"
        />
        <CAMTextField
          value={ssoEnrollmentCertificateTemplateText}
          fieldType="text"
          dataTestId="certificate-template-name"
          onChange={(e) =>
            setSSOEnrollmentCertificateTemplateText(e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12}>
        Please supply user credentials during install/update. Ex.
        <Input
          className={classes.commandInput}
          fullWidth
          readOnly
          variant="outlined"
          type="text"
          data-testid="input-with-cac-cmd"
          endAdornment={
            <InputAdornment position="end">
              <CopyToClipboard
                text={ssoEnrollmentCommand}
                onCopy={() => {
                  successSnackbar('Copied command to clipboard!');
                }}
                id="copy-cac-cmd-button"
              />
            </InputAdornment>
          }
          disableUnderline
          value={`$ ${ssoEnrollmentCommand}`}
        />
      </Grid>
    </>
  );

  const isValidSSOSetting =
    !enableSSOChecked ||
    selectedSSOOption.value === caCertOption.value ||
    (selectedSSOOption.value === webEnrollmentOption.value &&
      ssoEnrollmentUrlText &&
      ssoEnrollmentCertificateTemplateText &&
      isHttpsUrl(ssoEnrollmentUrlText) &&
      ssoEnrollmentCertificateTemplateText.trim());

  const isSaveDisabled =
    isLoading ||
    authUrl === '' ||
    !isHttpsUrl(authUrl) ||
    !isValidSSOSetting ||
    // check if setting is not changed
    (oauthConfig?.idProviderUrl === authUrl &&
      oauthConfig?.idpAppClientId === idpAppClientIdText &&
      oauthConfig?.oauthFlowCode === selectedOauthFlow.value &&
      oauthConfig?.enableSSO === enableSSOChecked &&
      (oauthConfig?.ssoEnrollmentUrl || '') === (ssoEnrollmentUrlText || '') &&
      (oauthConfig?.ssoEnrollmentCertificateTemplate || '') ===
        (ssoEnrollmentCertificateTemplateText || '')) ||
    idpAppClientIdText === '';

  const handleSaveConfig = () => {
    if (!isSaveDisabled) {
      let ssoEnrollmentUrl = ''; // if enrollmentUrl is '', it indicate the connector should not enable sso by enrollment.
      let ssoEnrollmentCertificateTemplate = '';
      if (ssoEnrollmentUrlText) {
        ssoEnrollmentUrl = ssoEnrollmentUrlText.trim();
      }
      if (ssoEnrollmentCertificateTemplateText) {
        ssoEnrollmentCertificateTemplate =
          ssoEnrollmentCertificateTemplateText.trim();
      }

      const updatedCfg = {
        oauth: {
          enabled: true,
          idProviderUrl: authUrl.trim(),
          idpAppClientId: idpAppClientIdText.trim(),
          oauthFlowCode: selectedOauthFlow.value,
        },
        enableEntitlementsByUpn: true,
      };

      updatedCfg.oauth.enableSSO = enableSSOChecked;
      updatedCfg.oauth.ssoEnrollmentUrl = ssoEnrollmentUrl;
      updatedCfg.oauth.ssoEnrollmentCertificateTemplate =
        ssoEnrollmentCertificateTemplate;

      const payload = {};
      if (!connectorId) {
        payload.deployment = updatedCfg;
      } else {
        payload.connector = updatedCfg;
      }

      updateDeploymentCacSettings(payload);
    }
  };

  const handleChecked = (e) => {
    setFedAuthChecked(e.target.checked);
    if (!e.target.checked) {
      const updatedCfg = {
        oauth: {
          enabled: false,
          idProviderUrl: '',
          idpAppClientId: '',
          oauthFlowCode: '',
        },
      };

      updatedCfg.oauth.enableSSO = false;
      updatedCfg.oauth.ssoEnrollmentUrl = '';
      updatedCfg.oauth.ssoEnrollmentCertificateTemplate = '';

      setAuthUrl('');
      setIdpAppClientIdText('');
      setSelectedOauthFlow(defaultOauthFlow);
      setEnableSSOChecked(false);
      setSSOEnrollmentUrlText('');
      setSSOEnrollmentCertificateTemplateText('');

      const payload = {};
      if (!connectorId) {
        payload.deployment = updatedCfg;
      } else {
        payload.connector = updatedCfg;
      }

      updateDeploymentCacSettings(payload);
    }
  };

  const handleSSOChecked = (e) => {
    setEnableSSOChecked(e.target.checked);
  };

  const handleResetConfig = () => {
    const payload = {connector: {oauth: null}};
    updateDeploymentCacSettings(payload);
  };

  // clicking this button will reset the oauth setting in the connector, which make connector use the deployment' oauth setting instead.
  const resetButton = (
    <CAMButton
      buttonText="Inherit Deployment Setting"
      onClick={handleResetConfig}
      testId="reset-fed-auth-button"
    />
  );

  return (
    <Root>
      <CAMCard>
        <GridN singleColumn>
          <SectionHeader displayText="OAUTH AUTHENTICATION">
            {inheritDeploymentSettings ? (
              <Chip
                size="small"
                label="INHERITED FROM DEPLOYMENT"
                classes={{root: classes.primaryTag}}
              />
            ) : null}
          </SectionHeader>
          <Typography className={classes.description}>
            {fedAuthDescription}
            <br />
            <br />
            {updateConnectorHelpText()}
            <br />
          </Typography>
          <Card
            elevation={0}
            variant="outlined"
            className={classes.fedAuthCard}
          >
            <CardContent>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-end"
              >
                <Grid item xs={12}>
                  <FormControlLabel
                    className={classes.enableFedAuthText}
                    control={
                      <Checkbox
                        inputProps={{
                          'data-testid': 'enable-fed-auth-checkbox',
                        }}
                        color="primary"
                        checked={fedAuthChecked}
                        onChange={handleChecked}
                        name="fed-auth-enabled"
                      />
                    }
                    label="Enable OAuth"
                  />
                  <CAMTooltip text="Enabling OAuth will enable entitlements by UPN" />
                </Grid>
                {fedAuthChecked && (
                  <>
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      {urlField}
                    </Grid>
                    <Grid item xs={0} sm={6} />
                    <Grid item xs={12} sm={3} className={classes.gridItem}>
                      {idpAppClientIdField}
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.gridItem}>
                      {/** disabled for now as there is only 1 oauth flow option available.
                       * oauthFlowField */}
                    </Grid>
                    <Grid item xs={0} sm={6} />
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            inputProps={{
                              'data-testid': 'enable-sso-checkbox',
                            }}
                            color="primary"
                            checked={enableSSOChecked}
                            name="sso-enabled"
                            onChange={handleSSOChecked}
                          />
                        }
                        label="Enable Single Sign-On (SSO)"
                      />
                    </Grid>
                    <Grid item xs={0} sm={6} />
                    {enableSSOChecked && (
                      <>
                        <Grid item xs={12} sm={6} className={classes.gridItem}>
                          {ssoOptionField}
                        </Grid>
                        <Grid item xs={0} sm={6} />
                        {selectedSSOOption.value ===
                          webEnrollmentOption.value && ssoWebEnrollmentFields}
                        {selectedSSOOption.value === caCertOption.value &&
                          ssoCaCertFields}
                      </>
                    )}

                    <Grid item xs={12}>
                      <CAMButton
                        buttonText="Save Setting"
                        disabled={isSaveDisabled}
                        onClick={handleSaveConfig}
                        testId="update-fed-auth-button"
                        customClass={classes.saveButton}
                      />
                      {canResetSettings && resetButton}
                    </Grid>
                  </>
                )}
                {!fedAuthChecked && canResetSettings && (
                  <Grid item xs={12} className={classes.gridItem}>
                    {resetButton}
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </GridN>
      </CAMCard>
    </Root>
  );
}

FedAuthForm.propTypes = {
  settings: PropTypes.object,
  connectorId: PropTypes.string,
  // If connectorId is defined and this is true, it indicate the setting is coming from deployment level.
  inheritDeploymentSettings: PropTypes.bool,
  canResetSettings: PropTypes.bool,
};

FedAuthForm.defaultProps = {
  settings: {},
  connectorId: '',
  inheritDeploymentSettings: false,
  canResetSettings: false,
};

export default FedAuthForm;
