import {Tab, Tabs} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import useDialog from 'hooks/useDialog';
import {saveVariable} from 'redux/actions/dataActions';
import {
  UNSAVED_DATA_DIALOG_MESSAGE,
  UNSAVED_DATA_DIALOG_TITLE,
} from 'utils/constants';
import {selectVariable} from 'utils/reduxSelectors';

const PREFIX = 'HorizontalTab';

const classes = {
  root: `${PREFIX}-root`
};

const StyledTabs = styled(Tabs)({
  [`& .${classes.root}`]: {maxWidth: 'none'},
});

/**
 * @param {*} props.tabs tabs can be an array of string or array of {label,key}
 * @param {number} props.selectedTab index of the selected tab
 * @param {function} props.setSelectedTab callback function to update the selectedTab
 * @returns
 */
export default function HorizontalTab({tabs, selectedTab, setSelectedTab}) {
  const getTestId = (text) =>
    `tab-${text.replace(/[^0-9a-z]/gi, '-').toLowerCase()}`;
  const pendingChanges = useSelector((state) =>
    selectVariable(state, 'pendingChanges')
  );
  const dispatch = useDispatch();

  const {triggerDialog} = useDialog();

  const conditionalSetSelectedTab = (newTab) => {
    if (pendingChanges) {
      triggerDialog({
        title: UNSAVED_DATA_DIALOG_TITLE,
        message: UNSAVED_DATA_DIALOG_MESSAGE,
        onConfirm: async () => {
          await dispatch(saveVariable('pendingChanges', false));
          setSelectedTab(newTab);
        },
      });
    } else {
      setSelectedTab(newTab);
    }
  };

  return (
    <StyledTabs
      value={selectedTab}
      onChange={(_, newTab) => conditionalSetSelectedTab(newTab)}
      indicatorColor="primary"
    >
      {tabs.map((tab) => {
        // If tab is a string, then set it as the label and key
        let label = tab;
        let key = tab;
        let value; // value is undefined unless it is passed explicitly
        if (typeof tab === 'object') {
          label = tab.label;
          key = tab.key;
          value = tab.value;
        }
        return (
          <Tab
            classes={{root: classes.root}}
            key={key}
            label={label}
            value={value || undefined}
            data-testid={getTestId(key)}
          />
        );
      })}
    </StyledTabs>
  );
}

HorizontalTab.propTypes = {
  tabs: PropTypes.array.isRequired,
  selectedTab: PropTypes.any.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};
