import {atom, useAtom} from 'jotai';
import {atomWithReset, RESET} from 'jotai/utils';
import shellEscape from 'shell-escape';
import {isEmpty} from 'utils/utils';
import {configParams} from './wizardConstants';

/**
 * Iterate through a provided array of params and check if each param that is marked as 'required'
 * has a configured value
 * Also checks the params subconfig if the param is enabled.
 * @param {configParamArray} params
 * @param {Object} configState
 * @returns boolean
 */
function checkRequiredParams(params, configState) {
  return params.every((param) => {
    if (isEmpty(configState[param.fieldName])) {
      if (param.required) {
        return false;
      }
    } else {
      if (param.isValid) {
        if (!param.isValid(configState[param.fieldName])) {
          return false;
        }
      }
      if (param.paramType === 'boolean') {
        // Check sub-config if required params are set
        if (param.subConfig) {
          const subParamsSet = checkRequiredParams(
            param.subConfig,
            configState
          );
          if (!subParamsSet) {
            return false;
          }
        }
      }
    }
    return true;
  });
}

/**
 * Given a configState, and an array of parameters,
 * Returns a object with the reset state
 * @param {Array} configParamArray
 * @param {Object} configState
 * @returns {object} resetState
 */
function getClearedConfigState(configParamArray, configState = {}) {
  const initialState = {...configState};
  function getClearedState(configArray, state) {
    let updatedState = {...state};
    configArray.forEach((param) => {
      if (param.paramType === 'boolean') {
        updatedState[param.fieldName] = param.default;
      }
      if (param.paramType === 'string' || param.paramType === 'number') {
        updatedState[param.fieldName] = '';
      }
      if (param.subConfig) {
        updatedState = getClearedState(param.subConfig, updatedState);
      }
    });

    return updatedState;
  }

  return getClearedState(configParamArray, initialState);
}

/**
 * Generates a command string based on user inputted config state
 * which can be ran to configure an AWC.
 * @param {Object} paramObject
 * @param {Array} paramObject.configParamArray - An Array of configuration parameter Objects which define the flags which will be included in the AWC command
 * @param {Object} paramObject.configState - An Object containing the local state of user input for each configuration parameter
 * @param {String} paramObject.baseCommand - A string which calls the AWC executable not including CLI flags
 * @returns {String} A string which can be ran on a Linux machine to configure an AWC
 */
function generateAwcCommand({
  configParamArray = [],
  configState = {},
  baseCommand = 'sudo /usr/local/bin/anyware-connector configure',
}) {
  const includedFields = [];

  function buildCommand(params) {
    const commandParts = [];
    params.forEach((param) => {
      let commandPart = '';
      if (param.paramType === 'string') {
        if (configState[param.fieldName]) {
          const escapedInput = shellEscape([configState[param.fieldName]]);
          commandPart = `${param.flag} ${escapedInput}`;
        }
      } else if (param.paramType === 'boolean') {
        const commandString = `${param.flag}=${configState[param.fieldName]}`;
        commandPart = commandString;
      } else if (param.paramType === 'number') {
        const parsedValue = parseInt(configState[param.fieldName], 10);
        if (parsedValue) {
          commandPart = `${param.flag} ${parsedValue} `;
        }
      }

      if (!isEmpty(commandPart) && !includedFields.includes(param.fieldName)) {
        commandParts.push(commandPart);
      }

      includedFields.push(param.fieldName);

      // If given parameter is boolean and has a subConfig, only include the subConfig if the boolean is true
      // This allows for subConfigs to be included in the command only if the user has enabled the capability
      // i.e.: --enable-mfa is true, include --mfa-secret, --mfa-server, --mfa-port... etc.
      // if --enable-mfa is false, do not include any of the subConfig parameters
      if (param.subConfig) {
        let shouldIncludeSubConfig = true;
        if (param.paramType === 'boolean') {
          if (configState[param.fieldName] !== true) {
            shouldIncludeSubConfig = false;
          }
        }

        if (shouldIncludeSubConfig) {
          const subConfigParts = buildCommand(param.subConfig);
          commandParts.push(...subConfigParts);
        }
      }
    });

    return commandParts;
  }

  const finalCommandParts = [baseCommand];
  finalCommandParts.push(...buildCommand(configParamArray));
  const fullCommand = finalCommandParts.join(' ');
  return fullCommand;
}

const defaultConfigState = getClearedConfigState(configParams);
const configStateAtom = atom(defaultConfigState);
const connectorTokenAtom = atomWithReset('');
const connectorIdAtom = atomWithReset('');
const activeStepIndexAtom = atomWithReset(0);
const connectorNameAtom = atomWithReset('');
const commandCopiedAtom = atomWithReset(false);
const installCommandAtom = atomWithReset('');
const commandLockedAtom = atomWithReset(false);

/**
 * Custom Hook to use state variables across wizard components
 * @returns object with {val, setVal} members
 */
function useWizardState() {
  const [configState, setConfigState] = useAtom(configStateAtom);
  const [connectorToken, setConnectorToken] = useAtom(connectorTokenAtom);
  const [connectorId, setConnectorId] = useAtom(connectorIdAtom);
  const [activeStepIndex, setActiveStepIndex] = useAtom(activeStepIndexAtom);
  const [connectorName, setConnectorName] = useAtom(connectorNameAtom);
  const [commandCopied, setCommandCopied] = useAtom(commandCopiedAtom);
  const [installCommand, setInstallCommand] = useAtom(installCommandAtom);
  const [isCommandLocked, setIsCommandLocked] = useAtom(commandLockedAtom);

  const resetWizardState = () => {
    setConnectorToken(RESET);
    setConnectorId(RESET);
    setActiveStepIndex(RESET);
    setConnectorName(RESET);
    setCommandCopied(RESET);
    setInstallCommand(RESET);
    setIsCommandLocked(RESET);
  };

  return {
    connectorToken,
    setConnectorToken,
    configState,
    setConfigState,
    connectorId,
    setConnectorId,
    activeStepIndex,
    setActiveStepIndex,
    connectorName,
    setConnectorName,
    commandCopied,
    setCommandCopied,
    installCommand,
    setInstallCommand,
    isCommandLocked,
    setIsCommandLocked,
    resetWizardState,
  };
}

export {
  checkRequiredParams,
  getClearedConfigState,
  generateAwcCommand,
  useWizardState,
};
