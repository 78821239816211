import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import useDialog from 'hooks/useDialog';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {removePoolGroups} from 'redux/actions/dataActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {POOL_GROUPS} from 'utils/constants';
import {selectSelectedTableResource} from 'utils/reduxSelectors';

function PoolGroupsBulkActions({onClose, onDelete}) {
  const dispatch = useDispatch();
  const {triggerDialog} = useDialog();

  const poolGroups = useSelector((state) =>
    selectSelectedTableResource(state, POOL_GROUPS)
  );

  const handleRemoveGroups = async () => {
    onDelete();
    await dispatch(removePoolGroups(poolGroups));
    dispatch(fetchTableData(POOL_GROUPS));
  };

  const handleRemoveClick = () => {
    const poolGroupNames = poolGroups
      .map((group) => group.group || group.groupGuid)
      .join(', ');
    const numGroups = poolGroups.length;
    triggerDialog({
      title: `Remove ${numGroups} group${
        numGroups > 1 ? 's' : ''
      } from the pool?`,
      message: `Remove ${poolGroupNames}? Removing a group will revoke access to the pool for users in the group. This operation cannot be undone.`,
      onConfirm: handleRemoveGroups,
    });
    onClose();
  };

  return (
    <KebabMenuItem
      id="bulk-remove-pool-groups"
      dataTestId="bulk-remove-pool-groups"
      menuText="Remove"
      onClick={handleRemoveClick}
    />
  );
}

PoolGroupsBulkActions.propTypes = {
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

PoolGroupsBulkActions.defaultProps = {
  onClose: () => {},
  onDelete: () => {},
};

export default PoolGroupsBulkActions;
