import {cloneElement, useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import OkIcon from '@mui/icons-material/CheckCircle';
import UnknownIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {connectorIsCACv2} from 'utils/utils';

import ArrowTooltip from 'components/common/tooltip/ArrowTooltip';
import {
  WEBHOOK_CAPABILITY_NAME,
  WEBHOOKS_CA_CERT_STATUS_SET,
  WEBHOOKS_CA_CERT_STATUS_NOT_SET,
} from 'utils/constants';
import {fetchSelectedWebhookCACert} from 'redux/actions/dataActions';

const PREFIX = 'WebhookStatus';
const ENABLED_ICON_TITLE = 'Webhook feature is enabled.';
const DISABLED_ICON_TITLE = 'Webhook feature is disabled.';
const UNKNOWN_ICON_TITLE =
  'Webhook feature status is unknown or feature is not supported by the connector.';
const ENABLED_STATUS = 'On';
const DISABLED_STATUS = 'Off';
const UNKNOWN_STATUS = 'Not Available';

const iconStyleMap = {
  disabled: {
    color: '#C23934',
  },
  enabled: {
    color: '#04844B',
  },
  unknown: {
    color: '#706E6B',
  },
};

const classes = {
  centeredGrid: `${PREFIX}-centeredGrid`,
  statusIcon: `${PREFIX}-statusIcon`,
};

const Root = styled('div')(({...props}) => ({
  [`& .${classes.centeredGrid}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.statusIcon}`]: {
    display: 'flex',
    color: iconStyleMap[props.iconType]?.color || '#FFF',
    marginRight: '0.5rem',
    width: '24px',
  },
}));

const determineWebhookStatus = (connector) => {
  let isWebhookCapExisted = false;
  let isWebhooksEnabled = false;
  const cacVersion =
    connector?.components?.version || connector?.components?.cacVersion || '';

  // CACv2 doesn't support Webhook feature
  if (!Array.isArray(connector?.capabilities) || connectorIsCACv2(cacVersion)) {
    return UNKNOWN_STATUS;
  }

  connector.capabilities.forEach((capability) => {
    if (capability?.capabilityName === WEBHOOK_CAPABILITY_NAME) {
      isWebhooksEnabled = !!capability.enabled;
      isWebhookCapExisted = true;
    }
  });

  if (isWebhookCapExisted) {
    return isWebhooksEnabled ? ENABLED_STATUS : DISABLED_STATUS;
  }
  return UNKNOWN_STATUS;
};

function WebhookStatus({fieldId, connector, webhook}) {
  const dispatch = useDispatch();
  const [icon, setIcon] = useState();
  const [iconType, setIconType] = useState();
  const [title, setTitle] = useState();
  const [status, setStatus] = useState();
  const [gridDataTestId, setGridDataTestId] = useState();

  const determineIconAndStatus = (statusFromPayload, iconDataTestId) => {
    switch (statusFromPayload) {
      case ENABLED_STATUS:
        return {
          iconType: 'enabled',
          icon: <OkIcon data-testid={iconDataTestId} />,
          title: ENABLED_ICON_TITLE,
          status: ENABLED_STATUS,
        };
      case WEBHOOKS_CA_CERT_STATUS_SET:
        return {
          iconType: 'enabled',
          icon: <OkIcon data-testid={iconDataTestId} />,
          title: 'CA cert added',
          status: WEBHOOKS_CA_CERT_STATUS_SET,
        };
      case WEBHOOKS_CA_CERT_STATUS_NOT_SET:
        return {
          iconType: 'disabled',
          icon: <ErrorIcon data-testid={iconDataTestId} />,
          title: 'CA cert not added',
          status: WEBHOOKS_CA_CERT_STATUS_NOT_SET,
        };
      case DISABLED_STATUS:
        return {
          iconType: 'disabled',
          icon: <ErrorIcon data-testid={iconDataTestId} />,
          title: DISABLED_ICON_TITLE,
          status: DISABLED_STATUS,
        };
      default:
        return {
          iconType: 'unknown',
          icon: <UnknownIcon data-testid={iconDataTestId} />,
          title: UNKNOWN_ICON_TITLE,
          status: UNKNOWN_STATUS,
        };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const generateTestIds = () => {
        const dataId = fieldId || connector.connectorId || webhook.id;
        return {
          gridDataTestId: `${dataId}-webhook-status-grid`,
          iconDataTestId: `${dataId}-webhook-status-icon`,
        };
      };
      const {gridDataTestId, iconDataTestId} = generateTestIds(
        fieldId,
        connector,
        webhook
      );
      setGridDataTestId(gridDataTestId);
      let statusFromPayload = '';
      if (webhook) {
        statusFromPayload = await dispatch(
          fetchSelectedWebhookCACert(webhook.endpoint)
        );
      } else {
        statusFromPayload = determineWebhookStatus(connector);
      }

      const result = determineIconAndStatus(statusFromPayload, iconDataTestId);

      setIconType(result.iconType);
      setIcon(result.icon);
      setTitle(result.title);
      setStatus(result.status);
    };
    fetchData();
  }, []);

  if (icon) {
  return (
    <Root iconType={iconType}>
      <div className={classes.centeredGrid}>
        <ArrowTooltip title={title} placement="left">
            {cloneElement(icon, {className: classes.statusIcon})}
        </ArrowTooltip>
        <Grid data-testid={gridDataTestId}>{status}</Grid>
      </div>
    </Root>
  );
  }
}

WebhookStatus.propTypes = {
  fieldId: PropTypes.string,
  connector: PropTypes.object,
  webhook: PropTypes.object,
};

WebhookStatus.defaultProps = {
  fieldId: '',
  connector: {
    capabilities: [{}],
  },
  webhook: null,
};

export default WebhookStatus;
export {determineWebhookStatus};