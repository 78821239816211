import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import TextInput from 'components/standalone/TextInput';

const PREFIX = 'CAMDeleteDialog';

const classes = {
  muiDialogPaper: `${PREFIX}-muiDialogPaper`,
  warning: `${PREFIX}-warning`,
};

const StyledDialog = styled(Dialog)(({theme: {palette}}) => ({
  [`& .${classes.muiDialogPaper}`]: {
    overflowY: 'visible',
    display: 'grid',
  },

  [`& .${classes.warning}`]: {
    color: palette.error.main,
  },
}));

function CAMDeleteDialog({
  open,
  content,
  onCancel,
  onOk,
  okDisabled,
  resourceName,
  requireTextInputToDelete,
}) {
  const [confirmationText, setConfirmationText] = useState('');

  const handleClickCancel = () => {
    onCancel();
  };

  const handleDelete = () => {
    onOk();
  };

  const onChangeConfirmationText = (event) => {
    setConfirmationText(event.target.value);
  };

  const confirmationTextMatch =
    !requireTextInputToDelete ||
    (confirmationText &&
      resourceName &&
      confirmationText.toLowerCase().trim() ===
        resourceName.toLowerCase().trim());

  useEffect(() => {
    if (open) {
      setConfirmationText('');
    }
  }, [open]);

  const title = `Are you sure you want to delete ${resourceName}?`;

  if (!open) return null;

  return (
    <StyledDialog
      open={open}
      fullWidth
      data-testid="casm-delete-dialog"
      PaperProps={{className: classes.muiDialogPaper}}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent className={classes.muiDialogPaper}>
        {content}
        <span className={classes.warning}>
          This operation is permanent and irreversible.{' '}
        </span>
      </DialogContent>

      {requireTextInputToDelete && (
        <DialogContent className={classes.muiDialogPaper}>
          <p>
            Type{' '}
            <strong>
              <i>{`${resourceName} `}</i>
            </strong>{' '}
            below to delete the resource(s):
          </p>
          <TextInput
            onChange={onChangeConfirmationText}
            placeholder={resourceName}
            value={confirmationText}
            data-testid="casm-delete-dialog-input"
          />
        </DialogContent>
      )}

      <DialogActions>
        <CAMButton
          onClick={handleClickCancel}
          testId="casm-cancel-dialog-button"
          buttonText="Cancel"
        />

        <SaveButton
          dataTestId="casm-delete-dialog-button"
          onClick={handleDelete}
          buttonText="Delete"
          disabled={okDisabled || !confirmationTextMatch}
        />
      </DialogActions>
    </StyledDialog>
  );
}

CAMDeleteDialog.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  okDisabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resourceName: PropTypes.string,
  requireTextInputToDelete: PropTypes.bool,
};

CAMDeleteDialog.defaultProps = {
  content: '',
  onCancel: () => {},
  okDisabled: false,
  requireTextInputToDelete: false,
  resourceName: '',
};

export default CAMDeleteDialog;
