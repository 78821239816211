import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import {del, post} from 'api/Api';
import ProviderIcon from 'components/CAM/icons/ProviderIcon/ProviderIcon';
import LearnMoreCard from 'components/common/LearnMoreCard';
import ArrowTooltipText from 'components/common/tooltip/ArrowTooltipText';
import usePendingChanges from 'hooks/usePendingChanges';
import NewElement from 'icons/new_element.svg';
import {openAzureRoleDialog} from 'redux/actions/azureRoleDialogActions';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {
  handleApiError,
  fetchCloudServiceAccounts,
} from 'redux/actions/dataActions';
import {
  AZURE,
  AZURE_CLIENT_ID_PLACEHOLDER,
  AZURE_CLIENT_S_PLACEHOLDER,
  AZURE_OBJECT_ID_PLACEHOLDER,
  AZURE_READER_ROLE_ASSIGNMENT,
  AZURE_SUBSCRIPTION_ID_PLACEHOLDER,
  AZURE_TENANT_ID_PLACEHOLDER,
  CLOUD_SERVICE_ACCOUNTS,
} from 'utils/constants';
import {mapProviders} from 'utils/Mappings';
import {selectData} from 'utils/reduxSelectors';
import {isEmpty, sanitizeValues} from 'utils/utils';

const PREFIX = 'AzureCredentials';

const classes = {
  cloudCredentialContainer: `${PREFIX}-cloudCredentialContainer`,
  chevron: `${PREFIX}-chevron`,
  formLabel: `${PREFIX}-formLabel`,
  inputTextFieldLabel: `${PREFIX}-inputTextFieldLabel`,
  credentialInput: `${PREFIX}-credentialInput`,
  inputMultiline: `${PREFIX}-inputMultiline`,
  inputHidden: `${PREFIX}-inputHidden`,
  credentialItemView: `${PREFIX}-credentialItemView`,
  cloudCredentialStatus: `${PREFIX}-cloudCredentialStatus`,
  cloudCredentialHeader: `${PREFIX}-cloudCredentialHeader`,
  cloudCredentialTitle: `${PREFIX}-cloudCredentialTitle`,
  enabled: `${PREFIX}-enabled`,
  disabled: `${PREFIX}-disabled`,
  deleteButton: `${PREFIX}-deleteButton`,
  buttonRow: `${PREFIX}-buttonRow`,
  eyeIcon: `${PREFIX}-eyeIcon`,
  eyeball: `${PREFIX}-eyeball`,
  tooltip: `${PREFIX}-tooltip`,
  noPadding: `${PREFIX}-noPadding`,
  buttonCursor: `${PREFIX}-buttonCursor`,
};

const StyledPaper = styled(Paper)(({theme}) => ({
  [`&.${classes.cloudCredentialContainer}`]: {
    marginTop: '7px',
    padding: '0px 10px',
    boxShadow: 'none',
    border: '1px solid #ccc',
  },

  [`& .${classes.chevron}`]: {
    margin: 'auto 0px auto auto',
  },

  [`& .${classes.formLabel}`]: {
    display: 'flex',
    width: '16.75rem',
  },

  [`& .${classes.inputTextFieldLabel}`]: {
    color: theme.palette.info.main,
    fontFamily: '.SF NS Display',
    fontSize: '0.75rem',
    lineHeight: '15px',
    marginTop: '16px',
  },

  [`& .${classes.credentialInput}`]: {
    margin: '0px',
  },

  [`& .${classes.inputMultiline}`]: {
    padding: '8px 10px',
  },

  [`& .${classes.inputHidden}`]: {
    padding: '8px 0px',
  },

  [`& .${classes.credentialItemView}`]: {
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '1.25px',
    lineHeight: '16px',
    marginTop: '12px',
  },

  [`& .${classes.cloudCredentialStatus}`]: {
    margin: 0,
    fill: 'rgba(0,0,0,0.54)',
  },

  [`& .${classes.cloudCredentialHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    height: '2.25rem',
    cursor: 'pointer',
  },

  [`& .${classes.cloudCredentialTitle}`]: {
    flex: 1,
    marginLeft: '12px',
  },

  [`& .${classes.enabled}`]: {color: theme.palette.success.main},
  [`& .${classes.disabled}`]: {color: theme.palette.error.main},

  [`& .${classes.deleteButton}`]: {
    color: theme.palette.surface.grey,
  },

  [`& .${classes.buttonRow}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '20px',
  },

  [`& .${classes.eyeIcon}`]: {
    marginRight: '5px',
  },

  [`& .${classes.eyeball}`]: {
    fontSize: '18px',
  },

  [`& .${classes.tooltip}`]: {
    fontWeight: '300',
    borderColor: '#0076A9',
    backgroundColor: 'white',
  },

  [`& .${classes.noPadding}`]: {padding: 0},

  [`& .${classes.buttonCursor}`]: ({loading}) => ({
    cursor: loading ? 'wait' : 'pointer',
  }),
}));

function AzureCredentials({
  checkboxClickCallback,
  handleCredentialsChangeCallback,
  deploymentId,
}) {
  const dispatch = useDispatch();
  const {setPendingChanges} = usePendingChanges();

  const [showCredentialFields, setShowCredentialFields] = useState(false);
  const [tenantId, setTenantId] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');
  const [clientId, setClientId] = useState('');
  const [credentialId, setCredentialId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [applicationObjectId, setApplicationObjectId] = useState('');
  const [clientSecretVisibility, setClientSecretVisibility] = useState(false);
  const [credentialsValid, setCredentialsValid] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [editingMode, setEditingMode] = useState(false);
  const provider = AZURE;

  const clearForm = () => {
    setTenantId('');
    setSubscriptionId('');
    setClientId('');
    setClientSecret('');
    setCredentialId('');
    setApplicationObjectId('');
    setCredentialsValid(false);
  };

  const handleClickShowClientSecret = () =>
    setClientSecretVisibility(!clientSecretVisibility);
  const handleMouseDownShowClientSecret = (event) => {
    event.preventDefault();
  };

  const {
    data: cloudServiceAccounts,
    isFetching: isFetchingCloudServiceAccounts,
  } = useSelector((state) => selectData(state, CLOUD_SERVICE_ACCOUNTS));

  useEffect(() => {
    if (!isFetchingCloudServiceAccounts) {
      const account =
        cloudServiceAccounts.find((acc) => acc.provider === AZURE) || {};
      setTenantId(account.tenantId ?? '');
      setSubscriptionId(account.subscriptionId ?? '');
      setApplicationObjectId(account.applicationObjectId ?? '');
      setClientId(account.clientId ?? '');
      setCredentialId(account.id ?? '');
      setCredentialsValid(typeof account.id !== 'undefined');
      setLoading(false);
    } else {
      clearForm();
    }
  }, [JSON.stringify(cloudServiceAccounts)]);

  /** Load Azure credential from a deployment. */
  useEffect(() => {
    if (deploymentId) {
      setEditingMode(true);
    }
    setLoading(isFetchingCloudServiceAccounts);
  }, [deploymentId]);

  useEffect(() => {
    setLoading(isFetchingCloudServiceAccounts);
  }, [isFetchingCloudServiceAccounts]);

  useEffect(() => {
    handleCredentialsChangeCallback({
      tenantId,
      subscriptionId,
      clientId,
      clientSecret,
      applicationObjectId,
      credentialsValid,
    });
  }, [
    credentialsValid,
    tenantId,
    subscriptionId,
    clientId,
    clientSecret,
    applicationObjectId,
  ]);

  useEffect(() => {
    const pendingChanges =
      editingMode &&
      !credentialsValid &&
      [
        tenantId,
        subscriptionId,
        clientId,
        clientSecret,
        applicationObjectId,
      ].some((element) => !isEmpty(element));

    setPendingChanges(pendingChanges);
  }, [
    tenantId,
    subscriptionId,
    clientId,
    clientSecret,
    applicationObjectId,
    loading,
  ]);

  const handleValidateCredentialError = (error) => {
    const {code} = error;
    switch (code) {
      case 400:
        // If 400 occurred because SP does not have Reader role assignment, use Azure error
        if (error.data.reason.includes(AZURE_READER_ROLE_ASSIGNMENT)) {
          dispatch(openAzureRoleDialog({Reader: false}, false));
          return true;
        }
        return false;
      case 403:
        setDialogMessage('Invalid credentials');
        setCredentialsValid(false);
        setDialogOpen(true);
        return true;
      default:
        return false;
    }
  };

  /** Call API to validate credential */
  const validateCredential = async () => {
    const {data} = await post({
      path: 'auth/users/cloudServiceAccount/validate',
      data: {
        provider,
        credential: {
          tenantId: sanitizeValues(tenantId),
          clientId: sanitizeValues(clientId),
          clientSecret: sanitizeValues(clientSecret),
          subscriptionId: sanitizeValues(subscriptionId),
          applicationObjectId: sanitizeValues(applicationObjectId),
        },
      },
    });

    return data;
  };

  const handleValidateClick = async () => {
    try {
      setLoading(true);
      const {report, canManage} = await validateCredential();
      dispatch(openAzureRoleDialog(report, canManage));
      setCredentialsValid(canManage);
    } catch (error) {
      if (!handleValidateCredentialError(error)) {
        dispatch(handleApiError(error));
      }
    }
    setLoading(false);
  };

  const deleteCredential = async () => {
    // Will delete cloud provider key in the backend.
    const path = `deployments/${deploymentId}/cloudServiceAccounts/${credentialId}`;
    try {
      await del({path});
      clearForm();
      setDialogMessage('Credential deleted!');
      dispatch(fetchCloudServiceAccounts({deploymentId}));
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };

  const handleDeleteClick = () =>
    dispatch(
      openDialog(
        'Delete provider service account?',
        "Are you sure you want to delete your provider service account? You won't be able to power manage workstations without it.",
        deleteCredential
      )
    );

  const submitCredential = async () => {
    const path = `deployments/${deploymentId}/cloudServiceAccounts`;
    const {data: credential} = await post({
      path,
      data: {
        provider,
        credential: {
          tenantId: sanitizeValues(tenantId),
          clientId: sanitizeValues(clientId),
          clientSecret: sanitizeValues(clientSecret),
          subscriptionId: sanitizeValues(subscriptionId),
          applicationObjectId: sanitizeValues(applicationObjectId),
        },
      },
    });
    return credential;
  };

  const handleSubmitClick = async () => {
    setLoading(true);
    try {
      const {report, canManage} = await validateCredential();

      if (canManage) {
        const credential = await submitCredential();
        setCredentialId(credential.id);
        setCredentialsValid(true);
        setDialogMessage('Credential added to deployment!');
        setDialogOpen(true);
        dispatch(fetchCloudServiceAccounts({deploymentId}));
      } else {
        dispatch(openAzureRoleDialog(report, canManage));
      }
    } catch (error) {
      if (!handleValidateCredentialError(error)) {
        dispatch(handleApiError(error));
      }
    }
    setLoading(false);
  };

  const handleExpandClick = () => {
    checkboxClickCallback(!showCredentialFields);
    setShowCredentialFields(!showCredentialFields);
  };

  const statusIcon = () => {
    if (loading) {
      return <CircularProgress size={16} />;
    }
    if (credentialsValid) {
      return <CheckIcon className={classes.cloudCredentialStatus} />;
    }
    return (
      <img src={NewElement} className={classes.cloudCredentialStatus} alt="" />
    );
  };

  const renderTenantId = (
    <>
      <Typography className={classes.inputTextFieldLabel}>Tenant Id</Typography>
      <TextField
        data-testid="azure-tenant-id"
        placeholder={AZURE_TENANT_ID_PLACEHOLDER}
        fullWidth
        maxRows={2}
        variant="outlined"
        name="tenantId"
        disabled={credentialsValid}
        value={tenantId}
        onChange={(event) => setTenantId(event.target.value)}
        multiline
        className={classes.credentialInput}
        size="small"
        // InputProps={{ {multiline: classes.inputMultiline}}}
      />
    </>
  );

  const renderSubscriptionId = (
    <>
      <Typography className={classes.inputTextFieldLabel}>
        Subscription Id
      </Typography>
      <TextField
        data-testid="azure-subscription-id"
        placeholder={
          credentialsValid
            ? '(not informed)'
            : AZURE_SUBSCRIPTION_ID_PLACEHOLDER
        }
        fullWidth
        maxRows={2}
        variant="outlined"
        name="subscriptionId"
        disabled={credentialsValid}
        value={subscriptionId}
        onChange={(event) => setSubscriptionId(event.target.value)}
        multiline
        className={classes.credentialInput}
        size="small"
        // InputProps={{ {multiline: classes.inputMultiline}}}
      />
    </>
  );

  const renderApplicationObjectId = (
    <>
      <Typography className={classes.inputTextFieldLabel}>
        Application Object Id (optional)
      </Typography>
      <TextField
        data-testid="azure-application-object-id"
        placeholder={
          credentialsValid ? '(not informed)' : AZURE_OBJECT_ID_PLACEHOLDER
        }
        fullWidth
        maxRows={2}
        variant="outlined"
        name="applicationObjectId"
        disabled={credentialsValid}
        value={applicationObjectId}
        onChange={(event) => setApplicationObjectId(event.target.value)}
        multiline
        className={classes.credentialInput}
        size="small"
        // InputProps={{ {multiline: classes.inputMultiline}}}
      />
      <ArrowTooltipText
        placement="bottom-start"
        overrides={{tooltip: classes.tooltip}}
        title={
          <LearnMoreCard>
            {`This field is required if you want to check expiration dates for secrets to an Azure service principal without Application.Read.All permissions in Microsoft Graph API.

            To get the Application Object Id from the Azure portal, go to "App registrations",
            select the desired App registration and copy the "Object ID".`}
          </LearnMoreCard>
        }
        text="What is the Application Object Id?"
      />
    </>
  );

  const renderClientId = (
    <>
      <Typography className={classes.inputTextFieldLabel}>Client Id</Typography>
      <TextField
        data-testid="azure-client-id"
        placeholder={AZURE_CLIENT_ID_PLACEHOLDER}
        fullWidth
        maxRows={2}
        variant="outlined"
        name="clientId"
        disabled={credentialsValid}
        value={clientId}
        onChange={(event) => setClientId(event.target.value)}
        multiline
        className={classes.credentialInput}
        size="small"
        // InputProps={{ {multiline: classes.inputMultiline}}}
      />
    </>
  );

  const renderClientSecret = (
    <>
      <Typography className={classes.inputTextFieldLabel}>
        Client Secret
      </Typography>
      <TextField
        data-testid="azure-client-secret"
        placeholder={AZURE_CLIENT_S_PLACEHOLDER}
        fullWidth
        maxRows={6}
        variant="outlined"
        margin="normal"
        name="clientSecret"
        disabled={credentialsValid}
        value={clientSecret}
        onChange={(event) => setClientSecret(event.target.value)}
        className={classes.credentialInput}
        type={clientSecretVisibility ? 'text' : 'password'}
        size="small"
        InputProps={{
          className: classes.inputHidden,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className={classes.eyeIcon}
                size="small"
                data-testid="azure-client-secret-toggle"
                onClick={handleClickShowClientSecret}
                onMouseDown={handleMouseDownShowClientSecret}
              >
                {clientSecretVisibility ? (
                  <VisibilityOff className={classes.eyeball} />
                ) : (
                  <Visibility className={classes.eyeball} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );

  const missingFields = !(
    tenantId &&
    clientId &&
    clientSecret &&
    subscriptionId
  );

  return (
    <StyledPaper className={classes.cloudCredentialContainer}>
      <div
        role="button"
        data-testid="expand-azure-credential-header"
        className={classnames(
          classes.cloudCredentialHeader,
          classes.buttonCursor
        )}
        onClick={() => handleExpandClick()}
      >
        {statusIcon()}
        <Typography className={classes.cloudCredentialTitle}>
          <Grid container>
            <ProviderIcon provider={AZURE} />
            {mapProviders(AZURE)}
          </Grid>
        </Typography>
        <IconButton
          data-testid="expand-azure-credential-button"
          onClick={handleExpandClick}
          disableRipple
          disabled={loading}
          className={classes.noPadding}
          size="large"
        >
          {showCredentialFields ? (
            <KeyboardArrowUp className={classes.chevron} />
          ) : (
            <KeyboardArrowRight className={classes.chevron} />
          )}
        </IconButton>
      </div>

      {showCredentialFields && (
        <>
          {renderTenantId}
          {renderClientId}
          {renderSubscriptionId}
          {renderApplicationObjectId}
          {!credentialsValid && renderClientSecret}

          <div className={classes.buttonRow}>
            {editingMode && !credentialsValid && (
              <Button
                data-testid="azure-submit"
                color="primary"
                disabled={missingFields || loading}
                onClick={handleSubmitClick}
                className={classes.actionButton}
              >
                Submit
              </Button>
            )}

            {editingMode && credentialsValid && (
              <Button
                data-testid="azure-delete"
                onClick={handleDeleteClick}
                className={classes.button}
              >
                Delete
              </Button>
            )}

            {!editingMode && !credentialsValid && (
              <Button
                data-testid="azure-validate"
                color="primary"
                disabled={missingFields || credentialsValid || loading}
                onClick={handleValidateClick}
              >
                Validate
              </Button>
            )}

            {editingMode && !credentialsValid && (
              <Button
                data-testid="azure-clear"
                onClick={clearForm}
                className={classes.button}
                disabled={missingFields}
              >
                Clear
              </Button>
            )}

            {!editingMode && credentialsValid && (
              <Button
                data-testid="azure-edit"
                onClick={() => setCredentialsValid(false)}
                className={classes.button}
              >
                Edit
              </Button>
            )}
          </div>

          {dialogOpen && (
            <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              data-testid="azure-dialog"
            >
              <DialogContent>
                <DialogContentText
                  data-testid="azure-dialog-text"
                  variant="h6"
                  color="inherit"
                >
                  {dialogMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  data-testid="azure-dialog-button-ok"
                  onClick={() => setDialogOpen(false)}
                  color="primary"
                  autoFocus
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </>
      )}
    </StyledPaper>
  );
}

AzureCredentials.propTypes = {
  handleCredentialsChangeCallback: PropTypes.func,
  checkboxClickCallback: PropTypes.func,
  deploymentId: PropTypes.string,
};

AzureCredentials.defaultProps = {
  handleCredentialsChangeCallback: () =>
    'Clicked on add credentials, but no callback passed.',
  checkboxClickCallback: () => 'Clicked on checkbox, but no callback passed',
  deploymentId: '',
};

export default AzureCredentials;
