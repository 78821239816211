import {styled} from '@mui/material/styles';
import {useEffect, useState} from 'react';
import ReactJson from 'react-json-view';
import {useDispatch, useSelector} from 'react-redux';
import CopyToClipboard from 'components/CAM/buttons/CopyToClipboard/CopyToClipboard';
import CAMTable from 'components/table/Table';
import {clearTableFetchParams} from 'redux/actions/tableDataActions';
import {ACTIVITY_LOGS} from 'utils/constants';
import {selectDataForTable} from 'utils/reduxSelectors';

const PREFIX = 'ActivityLog';

const classes = {
  copySection: `${PREFIX}-copySection`,
  drawer: `${PREFIX}-drawer`,
  grid: `${PREFIX}-grid`,
};

const StyledLogData = styled('div')(({theme}) => ({
  [`& .${classes.copySection}`]: {
    margin: '8px 16px',
    position: 'absolute',
    right: 0,
    top: 0,
  },

  [`&.${classes.drawer}`]: {
    backgroundColor: theme.palette.surface.light,
    minWidth: '64rem',
    position: 'relative',
  },

  [`& .${classes.grid}`]: {
    minWidth: 'calc(64rem - 50px)',
    padding: '16px',
    width: 'calc(100% - 50px)',
  },
}));

const notCopiedMessage = 'Click to copy to clipboard';
const copiedMessage = 'Copied to clipboard';

function ActivityLog() {
  const dispatch = useDispatch();

  const [copied, setCopied] = useState(false);
  const handleCopy = (_, success) => {
    if (success) {
      setCopied(true);
    }
  };
  const handleMouseEnter = () => setCopied(false);

  const {
    data: activityLogs,
    isFetching,
    total,
  } = useSelector((state) => selectDataForTable(state, ACTIVITY_LOGS));

  useEffect(() => () => dispatch(clearTableFetchParams(ACTIVITY_LOGS)), []);

  const displayProperty = (log) => {
    const {data, operation} = log;
    if (data) {
      return (
        <ReactJson
          src={data || {}}
          name={operation}
          displayObjectSize={false}
          displayDataTypes={false}
          enableClipboard={handleCopy}
        />
      );
    }
    return 'null';
  };

  const formatLogData = (log) => (
    <StyledLogData key={log.id} className={classes.drawer}>
      <div className={classes.grid}>{displayProperty(log)}</div>
      <div className={classes.copySection} onMouseEnter={handleMouseEnter}>
        <CopyToClipboard
          text={JSON.stringify(log.data, null, 4)}
          onCopy={handleCopy}
          id="copy-to-clipboard"
          tooltipProps={{
            text: copied ? copiedMessage : notCopiedMessage,
            placement: 'top',
          }}
        />
      </div>
    </StyledLogData>
  );

  return (
    <CAMTable
      id="activity-logs-table"
      idField="logId"
      resource={ACTIVITY_LOGS}
      data={activityLogs}
      total={total}
      loadingData={isFetching}
      hideAddButton
      hideActions
      hideBulkActions
      hideConfigureTable={false}
      showDownloadButton
      useExpansionTable
      expansionTableDrawer={formatLogData}
      dateFilter
    />
  );
}

export default ActivityLog;
