import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import classnames from 'classnames';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import CAMTooltip from 'components/CAM/display/CAMTooltip/CAMTooltip';
import {sortTableData} from 'redux/actions/tableDataActions';
import {selectTableParams} from 'utils/reduxSelectors';

const PREFIX = 'TableHead';

const classes = {
  columnHeaderCell: `${PREFIX}-columnHeaderCell`,
  detailedErrorCell: `${PREFIX}-detailedErrorCell`,
  columnHeaderText: `${PREFIX}-columnHeaderText`,
  actionCell: `${PREFIX}-actionCell`,
  tooltipIcon: `${PREFIX}-tooltipIcon`
};

const StyledMuiTableHead = styled(MuiTableHead)((
  {
    theme
  }
) => ({
  [`& .${classes.columnHeaderCell}`]: {
    paddingLeft: '0.75rem',
    paddingRight: '0.25rem',
    zIndex: 1,
  },

  [`& .${classes.detailedErrorCell}`]: {
    padding: '0.75rem !important',
    textAlign: 'center !important',
    align: 'center !important',
  },

  [`& .${classes.columnHeaderText}`]: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },

  [`& .${classes.actionCell}`]: {paddingRight: '0.25rem !important', textAlign: 'center'},

  [`& .${classes.tooltipIcon}`]: {
    cursor: 'pointer',
    margin: '0 0 0 6px',
    verticalAlign: 'text-bottom',
    overflow: 'hidden',
    height: '18px',
    color: theme.palette.primary.main,
  }
}));

function TableHead({
  headers,
  handleCheckAll,
  checked,
  indeterminate,
  hideActions,
  hideBulkActions,
  resource,
  addExpandSpacing,
}) {

  const dispatch = useDispatch();
  const {params} = useSelector((state) => selectTableParams(state, resource));
  const {sortKey: currentSortKey, sortAsc: currentSortAsc} = useSelector(
    (state) =>
      state.tableData[resource] || {sortKey: 'createdOn', sortAsc: true}
  );

  const isSortActive = (item) =>
    item.sortable && item.sortId === currentSortKey;

  const handleSort = (header) => {
    // should sort ascending order on click if:
    //   a. We are not currently sorting on that column, or...
    //   b. We are currently sorting on that column, but the sort direction is ascending
    const shouldSortAsc = !(header.sortId === currentSortKey && currentSortAsc);
    dispatch(sortTableData(resource, header.sortId, shouldSortAsc, params));
  };

  const getSortDirection = (header) => {
    if (currentSortKey === header.sortId) {
      return currentSortAsc ? 'asc' : 'desc';
    }

    return 'asc';
  };

  return (
    <StyledMuiTableHead className={classes.tableHeader}>
      <TableRow>
        {/* Adds a blank cell to compensate for 'expand' button */}
        {addExpandSpacing && (
          <TableCell padding="checkbox">
            <IconButton size="small" />
          </TableCell>
        )}
        {!hideBulkActions && (
          <TableCell className={classes.columnHeaderCell} padding="checkbox">
            <Checkbox
              checked={checked}
              indeterminate={indeterminate}
              onChange={handleCheckAll}
              color="primary"
              inputProps={{'data-testid': 'checkbox-select-all'}}
            />
          </TableCell>
        )}
        {headers.map(
          (header) => (
            <TableCell
              className={
                header.centered
                  ? classnames(
                      classes.columnHeaderCell,
                      classes.detailedErrorCell
                    )
                  : classes.columnHeaderCell
              }
              key={header.id}
              align={header.numeric ? 'right' : 'left'}
              padding={header.disablePadding ? 'none' : 'normal'}
              width={header.width}
            >
              {header.headerToolTip ? (
                <CAMTooltip placement="top" text={header.headerToolTip}>
                  <TableSortLabel
                    data-testid={`sort-${header.sortId}`}
                    className={classes.columnHeaderText}
                    disabled={!header.sortable}
                    hideSortIcon={!header.sortable}
                    active={isSortActive(header)}
                    onClick={() => handleSort(header)}
                    direction={getSortDirection(header)}
                  >
                    {header.label}
                    <HelpIcon className={classes.tooltipIcon} />
                  </TableSortLabel>
                </CAMTooltip>
              ) : (
                <TableSortLabel
                  data-testid={`sort-${header.sortId}`}
                  className={classes.columnHeaderText}
                  disabled={!header.sortable}
                  hideSortIcon={!header.sortable}
                  active={isSortActive(header)}
                  onClick={() => handleSort(header)}
                  direction={getSortDirection(header)}
                >
                  {header.label}
                </TableSortLabel>
              )}
            </TableCell>
          ),
          this
        )}
        {!hideActions && (
          <TableCell
            className={classnames(classes.columnHeaderCell, classes.actionCell)}
            key="actions"
            align="left"
          >
            <div className={classes.columnHeaderText}>Actions</div>
          </TableCell>
        )}
      </TableRow>
    </StyledMuiTableHead>
  );
}

TableHead.propTypes = {
  headers: PropTypes.array,
  handleCheckAll: PropTypes.func,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  resource: PropTypes.string,
  hideActions: PropTypes.bool,
  hideBulkActions: PropTypes.bool,
  addExpandSpacing: PropTypes.bool,
};

TableHead.defaultProps = {
  headers: [],
  handleCheckAll: {},
  checked: false,
  indeterminate: false,
  resource: '',
  hideActions: false,
  hideBulkActions: false,
  addExpandSpacing: false,
};

export default TableHead;
