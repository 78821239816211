export const TOGGLE_DISPLAY_LEAVE_EDIT_PAGE_DIALOG =
  'TOGGLE_DISPLAY_LEAVE_EDIT_PAGE_DIALOG';

export const SHOW_LEAVE_EDIT_PAGE_DIALOG = 'SHOW_LEAVE_EDIT_PAGE_DIALOG';

export function showLeaveEditPageDialog() {
  return {
    type: SHOW_LEAVE_EDIT_PAGE_DIALOG,
  };
}

export const CONFIRM_DISPLAY_LEAVE_EDIT_PAGE_DIALOG =
  'CONFIRM_SHOW_LEAVE_EDIT_PAGE_DIALOG';
