import Hidden from '@mui/material/Hidden';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HistoryIcon from '@mui/icons-material/History';
import PeopleIcon from '@mui/icons-material/People';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import APIKeyIcon from '@mui/icons-material/VpnKey';
import PropTypes from 'prop-types';
import {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import AdminSettingsIcon from 'icons/AdminSettingsIcon';
import UserAccountSvg from 'icons/userAccount.svg';
import config from 'config';
import {
  ACCOUNT_INFORMATION_LINK,
  ACCOUNT_INFORMATION_MENU_ENTRY,
  ACTIVITY_LOG_LINK,
  ACTIVITY_LOG_MENU_ENTRY,
  AD_CONFIG,
  AWM_SERVICE_ACCOUNT_LINK,
  AWM_SERVICE_ACCOUNT_MENU_ENTRY,
  DATE_TIME,
  MULTI_ADMIN_LINK,
  USER_PREFERENCES,
  USER_PREFERENCES_LINK,
  MULTI_ADMIN_SETTINGS_TITLE,
  USER_LICENSES,
  USER_LICENSES_LINK,
} from 'utils/constants';
import {
  userCanAccessAdConfiguration,
  userCanAccessSamlConfiguration,
} from 'utils/utils';
import ApiTokenDialog from './ApiTokenDialog';

const PREFIX = 'UserMenu';

const classes = {
  userMenuButton: `${PREFIX}-userMenuButton`,
  userMenuDropdown: `${PREFIX}-userMenuDropdown`,
  userMenuIcon: `${PREFIX}-userMenuIcon`,
  userMenuText: `${PREFIX}-userMenuText`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.userMenuButton}`]: {
    height: theme.sizes.topBarElementHeight,
  },

  [`& .${classes.userMenuDropdown}`]: {
    marginTop: '-10px',
  },

  [`& .${classes.userMenuIcon}`]: {
    color: theme.palette.primary.main,
    height: '20px',
    width: '20px',
  },

  [`& .${classes.userMenuText}`]: {
    color: 'rgba(0,0,0,0.87)',
    padding: '0px 16px',
  }
}));

const buttonOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const menuOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

function UserMenu({handleSignout, userName}) {

  const userMenu = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [tokenDialogOpen, setTokenDialogOpen] = useState(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setUserMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setUserMenuOpen(false);
  };

  const getUserMenuWidth = () =>
    userMenu.current ? userMenu.current.clientWidth : 100;

  const toggleTokenDialog = () => {
    setTokenDialogOpen(!tokenDialogOpen);
    setUserMenuOpen(false);
    setAnchorEl(null);
  };

  const multiAdminConfigurationUrl = () => {
    if (userCanAccessSamlConfiguration()) {
      return MULTI_ADMIN_LINK;
    }
    return `${USER_PREFERENCES_LINK}/${AD_CONFIG}`;
  };

  return (
    <Root ref={userMenu}>
      <ListItem
        data-testid="user-menu"
        button
        onClick={handleOpen}
        className={classes.userMenuButton}
      >
        <img src={UserAccountSvg} alt="" />
        <Hidden mdDown>
          <ListItemText
            className={classes.userMenuText}
            primary={userName || ''}
          />
        </Hidden>
        {userMenuOpen ? (
          <ExpandLess className={classes.userMenuIcon} />
        ) : (
          <ExpandMore className={classes.userMenuIcon} />
        )}
      </ListItem>
      <Menu
        className={classes.userMenuDropdown}
        classes={{paper: classes.paper}}
        anchorEl={anchorEl}
        anchorOrigin={buttonOrigin}
        transformOrigin={menuOrigin}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <KebabMenuItem
          enableClick
          icon={<APIKeyIcon />}
          menuText="Get API token"
          width={getUserMenuWidth()}
          onClick={toggleTokenDialog}
        />
        <Link to={ACTIVITY_LOG_LINK} onClick={handleClose}>
          <KebabMenuItem
            enableClick
            icon={<HistoryIcon />}
            menuText={ACTIVITY_LOG_MENU_ENTRY}
            width={getUserMenuWidth()}
            onClick={handleClose}
          />
        </Link>
        <Link to={ACCOUNT_INFORMATION_LINK} onClick={handleClose}>
          <KebabMenuItem
            enableClick
            menuText={ACCOUNT_INFORMATION_MENU_ENTRY}
            icon={<AccountCircleIcon />}
            width={getUserMenuWidth()}
            onClick={handleClose}
          />
        </Link>
        <Link
          to={`${USER_PREFERENCES_LINK}/${DATE_TIME}`}
          onClick={handleClose}
        >
          <KebabMenuItem
            enableClick
            icon={<SettingsApplicationsIcon />}
            menuText={USER_PREFERENCES}
            width={getUserMenuWidth()}
          />
        </Link>
        {config.isBeta() && config.isLicensingEnabled() && (
          <Link to={`${USER_LICENSES_LINK}`} onClick={handleClose}>
            <KebabMenuItem
              enableClick
              icon={<AssignmentIcon />}
              menuText={USER_LICENSES}
              width={getUserMenuWidth()}
            />
          </Link>
        )}
        <Link to={AWM_SERVICE_ACCOUNT_LINK} onClick={handleClose}>
          <KebabMenuItem
            enableClick
            menuText={AWM_SERVICE_ACCOUNT_MENU_ENTRY}
            icon={<PeopleIcon />}
            width={getUserMenuWidth()}
            onClick={handleClose}
          />
        </Link>
        {(userCanAccessSamlConfiguration() ||
          userCanAccessAdConfiguration()) && (
          <Link to={multiAdminConfigurationUrl()} onClick={handleClose}>
            <KebabMenuItem
              enableClick
              menuText={MULTI_ADMIN_SETTINGS_TITLE}
              icon={<AdminSettingsIcon />}
              width={getUserMenuWidth()}
            />
          </Link>
        )}
        <KebabMenuItem
          enableClick
          menuText="Sign out"
          width={getUserMenuWidth()}
          onClick={handleSignout}
        />
      </Menu>
      <ApiTokenDialog open={tokenDialogOpen} onClose={toggleTokenDialog} />
    </Root>
  );
}

UserMenu.propTypes = {
  handleSignout: PropTypes.func.isRequired,
  userName: PropTypes.string,
};

UserMenu.defaultProps = {
  userName: '',
};

export default UserMenu;
