import PropTypes from 'prop-types';

const defaultColor = '#04844B';

const SuccessIcon = (props) => {
  const {backgroundColor, className} = props;

  return (
    <div className={className}>
      <svg
        width="20"
        height="20"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <path
            d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z"
            id="success-path"
          />
        </defs>
        <g fill="#fff" fillRule="evenodd" transform="translate(-2, -2)">
          <mask id="success-mask">
            <use xlinkHref="#success-path" />
          </mask>
          <g
            id="✱-/-Color-/-Icons-/-White-/-Active"
            mask="url(#success-mask)"
            fill={backgroundColor}
            fillRule="evenodd"
          >
            <rect id="Rectangle-13" x="0" y="0" width="24" height="24" />
          </g>
        </g>
      </svg>
    </div>
  );
};

SuccessIcon.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
};

SuccessIcon.defaultProps = {
  backgroundColor: defaultColor,
  className: '',
};

export default SuccessIcon;
