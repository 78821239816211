import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import {useDispatch} from 'react-redux';
import {useState} from 'react';
import {push} from 'redux/ReduxHistory';
import {CONNECTOR_SELECT_TYPE_LINK} from 'utils/constants';
import {ReactComponent as Coins} from 'icons/coins.svg';
import TokenDialog from 'components/connectors/TokenDialog';

const PREFIX = 'AddConnectorButton';

const classes = {
  button: `${PREFIX}-button`,
  provisionConnectorBtn: `${PREFIX}-provisionConnectorBtn`,
  addIcon: `${PREFIX}-addIcon`,
  menuItem: `${PREFIX}-menuItem`,
  generateTokenButton: `${PREFIX}-generateTokenButton`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.button}`]: {
    backgroundColor: '#0D47A1',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#0D47A1',
      opacity: 0.8,
    },
    paddingRight: '20px',
  },

  [`& .${classes.provisionConnectorBtn}`]: {
    marginRight: '10px',
  },

  [`& .${classes.addIcon}`]: {marginRight: '12px'},

  [`& .${classes.menuItem}`]: {
    lineHeight: '1.2',
  },

  [`& .${classes.generateTokenButton}`]: {
    color: '#245FB2',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'none',
    align: 'center',
    padding: '10px 16px',
    margin: '0px 8px',
  },
}));

function AddConnectorButton() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const openNewConnectorWizard = () => {
    dispatch(push(CONNECTOR_SELECT_TYPE_LINK));
  };

  return (
    <StyledGrid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <Button
          variant="text"
          className={classes.generateTokenButton}
          onClick={() => {
            setOpen(true);
          }}
        >
          <Coins />
          &nbsp; Generate a token
        </Button>
        <Button className={classes.button} onClick={openNewConnectorWizard}>
          <AddIcon className={classes.addIcon} />
          Add connector
        </Button>
      </Grid>
      <TokenDialog open={open} onClose={() => setOpen(false)} />
    </StyledGrid>
  );
}
export default AddConnectorButton;
