import {useState} from 'react';
import {styled} from '@mui/material/styles';
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {createOptionsObject} from 'utils/utils';

const PREFIX = 'useBetaDialog';

const classes = {
  paper: `${PREFIX}-paper`,
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
  primaryActionButton: `${PREFIX}-primaryActionButton`,
  secondaryActionButton: `${PREFIX}-secondaryActionButton`,
  userInputContainer: `${PREFIX}-userInputContainer`,
};

const Root = styled(Dialog)(({theme}) => ({
  [`& .${classes.paper}`]: {
    width: '560px',
    padding: '24px',
    borderRadius: '8px',
    boxShadow:
      '0px 16px 24px 0px rgba(50, 50, 71, 0.08), 0px 12px 12px 0px rgba(50, 50, 71, 0.08)',
  },

  [`& .${classes.title}`]: {},
  [`& .${classes.content}`]: {},

  [`& .${classes.primaryActionButton}`]: {
    backgroundColor: theme.palette.betaPrimary.main,
    color: theme.palette.surface.white,
    '&:hover': {
      backgroundColor: theme.palette.betaPrimary.dark,
    },
  },

  [`& .${classes.secondaryActionButton}`]: {
    color: theme.palette.betaPrimary.main,
  },

  [`& .${classes.userInputContainer}`]: {marginTop: '12px'},
}));

/**
 * Hook to allow simple use of a Dialog component
 * @param {Object} config - Config object to use for dialog
 * @param {String} config.title - Title of dialog
 * @param {String} config.message - Main text body of dialog
 * @param {String} config.primaryActionText - Text to display on primary action button
 * @param {Function} config.onPrimaryAction - Function to call when the primary action button is clicked
 * @param {String} config.secondaryActionText - Text to display on secondary action button
 * @param {Function} config.onSecondaryAction - Function to call when the secondary action button is clicked
 * @param {React.ReactElement} config.userInputForm - React element to render in the dialog which will be used to collect user input
 * @param {Boolean} config.userInputValidated - Boolean to indicate whether the user input form has been validated
 * @returns {Object} Returns an object containing a function to render the Dialog component in the parent
 *                   and a toggle function to hide/show it.
 */
const useBetaDialog = (config = {}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [actionsDisabled, setActionsDisabled] = useState(false);
  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);

  let inputConfig = config;
  if (typeof inputConfig !== 'object' || !inputConfig) {
    inputConfig = {};
  }

  const handleClose = (__, reason = '') => {
    if (reason !== 'backdropClick') {
      toggleDialog();
    }
  };

  const defaultConfig = {
    title: '',
    message: '',
    primaryActionText: 'Confirm',
    onPrimaryAction: () => {},
    secondaryActionText: 'Cancel',
    onSecondaryAction: handleClose,
    userInputForm: null,
    userInputValidated: false,
  };

  const {
    title,
    message,
    primaryActionText,
    onPrimaryAction,
    secondaryActionText,
    onSecondaryAction,
    userInputForm,
    userInputValidated,
  } = createOptionsObject(defaultConfig, inputConfig);

  const userInputRequired = userInputForm && !userInputValidated;

  const handlePrimaryAction = async (event) => {
    setActionsDisabled(true);

    await onPrimaryAction(event);

    toggleDialog();
    setActionsDisabled(false);
  };

  const StyledDialog = (
    <Root
      open={isDialogOpen}
      onClose={handleClose}
      classes={{paper: classes.paper}}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Typography className={classes.message} gutterBottom>
          {message}
        </Typography>
        {userInputForm && (
          <div className={classes.userInputContainer}>{userInputForm}</div>
        )}
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        <Button
          disabled={actionsDisabled}
          onClick={onSecondaryAction}
          className={classes.secondaryActionButton}
          variant="text"
        >
          {secondaryActionText}
        </Button>
        <Button
          disabled={actionsDisabled || userInputRequired}
          onClick={handlePrimaryAction}
          className={classes.primaryActionButton}
          variant="contained"
        >
          {primaryActionText}
        </Button>
      </DialogActions>
    </Root>
  );

  return {
    toggleDialog,
    renderDialog: () => StyledDialog,
  };
};

export default useBetaDialog;
