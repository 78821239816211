import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import PeopleIcon from '@mui/icons-material/People';
import classNames from 'classnames';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import validator from 'validator';
import PropTypes from 'prop-types';
import {del, post} from 'api/Api';
import Button from 'components/CAM/buttons/SaveButton/SaveButton';
import DeleteIcon from 'components/CAM/icons/DeleteIcon/DeleteIcon';
import TextInput from 'components/CAM/inputs/TextInput/TextInput';
import GridN from 'components/CAM/layout/GridN/GridN';
import usePendingChanges from 'hooks/usePendingChanges';
import useSnackbar from 'hooks/useSnackbar';
import {
  fetchSamlAllowedUsers,
  handleApiError,
  saveVariable,
} from 'redux/actions/dataActions';
import {SAML_ALLOWED_USERS} from 'utils/constants';
import {selectVariable} from 'utils/reduxSelectors';
import {formatDateTime, isEmpty, sanitizeValues} from 'utils/utils';

const PREFIX = 'SamlUsersConfiguration';

const classes = {
  button: `${PREFIX}-button`,
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  inputSectionSubtitle: `${PREFIX}-inputSectionSubtitle`,
  grid: `${PREFIX}-grid`,
  container: `${PREFIX}-container`,
  textField: `${PREFIX}-textField`,
  gridItem: `${PREFIX}-gridItem`,
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  tableRow: `${PREFIX}-tableRow`,
  tableCell: `${PREFIX}-tableCell`,
  tableRowEven: `${PREFIX}-tableRowEven`,
  tableRowOdd: `${PREFIX}-tableRowOdd`,
  noUsersBox: `${PREFIX}-noUsersBox`,
  peopleIcon: `${PREFIX}-peopleIcon`,
  noUsersFoundTitle: `${PREFIX}-noUsersFoundTitle`,
  noUsersFoundText: `${PREFIX}-noUsersFoundText`,
  iconColumn: `${PREFIX}-iconColumn`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
};

const StyledGrid = styled(Grid)(({theme}) => ({
  [`& .${classes.button}`]: theme.createPage.button,

  [`&.${classes.root}`]: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    backgroundColor: 'transparent',
    padding: '2px',
    display: 'flex',
    width: '100%',
  },

  [`& .${classes.input}`]: {
    width: '85%',
    paddingRight: '10px',
  },

  [`& .${classes.inputSectionSubtitle}`]: {
    ...theme.createPage.inputSectionSubtitle,
    marginBottom: '1rem',
  },

  [`& .${classes.grid}`]: {
    minWidth: 'calc(64rem - 50px)',
  },

  [`& .${classes.container}`]: {
    flexWrap: 'wrap',
    padding: '6px 3px',
    marginRight: theme.spacing(8),
  },

  [`& .${classes.textField}`]: {
    width: 200,
    height: 56,
    padding: 3,
  },

  [`& .${classes.gridItem}`]: {
    marginRight: '3.1rem',
    marginBottom: '1rem',
  },

  [`& .${classes.table}`]: {
    width: '100%',
  },

  [`& .${classes.tableHead}`]: {
    backgroundColor: '#FAFAF9',
    height: '28px',
  },

  [`& .${classes.tableRow}`]: {
    height: '28px',
  },

  [`& .${classes.tableCell}`]: {
    borderBottom: '1px solid lightgrey',
  },

  [`& .${classes.tableRowEven}`]: {
    backgroundColor: '#FFFFFF',
  },

  [`& .${classes.tableRowOdd}`]: {
    backgroundColor: '#f4f4f4',
  },

  [`& .${classes.noUsersBox}`]: {
    backgroundColor: '#FAFAF9',
    padding: '50px 40px',
    width: '100%',
  },

  [`& .${classes.peopleIcon}`]: {
    marginRight: '10px',
    marginBottom: '-7px',
    color: '#666',
  },

  [`& .${classes.noUsersFoundTitle}`]: {
    color: '#0076A9',
    textAlign: 'center',
  },

  [`& .${classes.noUsersFoundText}`]: {
    color: '#999',
    fontFamily: 'Roboto',
    fontSize: '0.75em',
    letterSpacing: '0.4px',
    lineHeight: '16px',
  },

  [`& .${classes.iconColumn}`]: {
    textAlign: 'center',
    width: '30px',
  },
  [`& .${classes.buttonWrapper}`]: {
    marginTop: '24px',
  },
}));

const allowedAdminTooltip =
  'The emails of allowed admins are matched against the NameID property of the SAML Assertion.';

function SamlUsersConfiguration({configurationId}) {
  const dispatch = useDispatch();
  const {setPendingChanges} = usePendingChanges();
  const {successSnackbar, infoSnackbar} = useSnackbar();
  const deletingSamlUsers = useSelector(
    (state) => selectVariable(state, 'deletingSamlUsers') || []
  );

  const [newUserEmail, setNewUserEmail] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const path = `auth/saml/${configurationId}/allowedUsers`;

  const allowedUsers = useSelector((state) => {
    const users = state.data.dataByResource[SAML_ALLOWED_USERS] || {data: {}};
    return Object.keys(users.data).map((userId) => users.data[userId]);
  });

  const isUpnValid = () => validator.isEmail(newUserEmail);

  const loadSamlAllowedUsers = () => {
    // Fetch emails of allowed users
    dispatch(fetchSamlAllowedUsers(configurationId));
  };

  // Delete a single user from the list
  const handleDeleteUserClick = async (user) => {
    setIsSaving(true);
    dispatch(
      saveVariable('deletingSamlUsers', [...deletingSamlUsers, user.id])
    );
    try {
      await del({path: `${path}/${user.id}`});
      successSnackbar(`User ${user.upn} can not login via SAML anymore.`);
      loadSamlAllowedUsers();
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      setIsSaving(false);
    }
    dispatch(
      saveVariable(
        'deletingSamlUsers',
        deletingSamlUsers.filter((uid) => uid !== user.id)
      )
    );
  };

  // Add a single user email to the list
  const handleAddUserClick = async () => {
    setIsSaving(true);
    try {
      await post({
        path,
        data: {upn: sanitizeValues(newUserEmail)},
      });
      setNewUserEmail('');
      loadSamlAllowedUsers();
      successSnackbar(`User ${newUserEmail} is now allowed to login via SAML.`);
    } catch (err) {
      if (err.code === 409) {
        infoSnackbar(
          `User ${newUserEmail} is already allowed to login via SAML.`
        );
      } else {
        dispatch(handleApiError(err));
      }
    }
    setIsSaving(false);
  };

  const addButtonEnabled = () => isUpnValid();

  useEffect(() => {
    if (configurationId) {
      loadSamlAllowedUsers();
    }
  }, [configurationId]);

  useEffect(() => {
    setPendingChanges(!isEmpty(newUserEmail));
  }, [newUserEmail]);

  const fields = [
    {id: 'upn', text: 'Email', type: 'text'},
    {id: 'createdOn', text: 'Created On', type: 'text'},
  ];

  function displayTableValue(element, header) {
    switch (header.id) {
      case 'createdOn':
        return formatDateTime(element[header.id]);
      default:
        return element[header.id];
    }
  }

  const renderTableHeader = () => (
    <TableHead>
      <TableRow className={classes.tableHead}>
        {fields.map((field) => (
          <TableCell
            key={`header-${field.id}`}
            className={classNames(classes.headerText, classes.tableCell)}
          >
            {field.text}
          </TableCell>
        ))}
        <TableCell
          key="remove"
          className={classNames(
            classes.headerText,
            classes.tableCell,
            classes.iconColumn
          )}
        >
          Delete
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const renderTableBody = (
    <TableBody>
      {allowedUsers.map((user, index) => (
        <TableRow
          hover
          key={user.id}
          className={classNames(
            classes.tableRow,
            index % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd
          )}
          data-testid="allowed-user-row"
        >
          {fields.map((header) => (
            <TableCell
              key={`${user.id}-${header.id}`}
              className={classNames(classes.tableCell, classes.rowText)}
            >
              {displayTableValue(user, header)}
            </TableCell>
          ))}
          <TableCell
            className={classNames(classes.tableCell, classes.iconColumn)}
          >
            <DeleteIcon
              testId={`delete-user-${user.id}`}
              onClick={() => handleDeleteUserClick(user)}
              isDeleting={deletingSamlUsers.includes(user.id)}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  const shouldDisplayErrorOnEmail = !isEmpty(newUserEmail) && !isUpnValid();

  const tableBodyNoUsers = (
    <div className={classes.noUsersBox}>
      <div className={classes.noUsersFoundTitle}>
        <PeopleIcon className={classes.peopleIcon} />
        No allowed admins found
      </div>
      <Typography align="center" className={classes.noUsersFoundText}>
        You need to add an admin to the list
        <br />
        by clicking the ADD ADMIN button above.
      </Typography>
    </div>
  );

  const allowedAdminProps = {
    label: 'New admin email',
    value: newUserEmail,
    onChange: (value) => setNewUserEmail(value),
    isError: shouldDisplayErrorOnEmail,
    placeholder: 'Enter the new admin email to be allowed',
    disabled: isEmpty(configurationId) || isSaving,
    tooltipText: allowedAdminTooltip,
  };
  if (shouldDisplayErrorOnEmail) {
    allowedAdminProps.helperText = 'Must be a valid SAML upn or admin email';
  }

  return (
    <StyledGrid container className={classes.root}>
      <Grid item className={classes.gridItem} xs={12}>
        <Typography className={classes.inputSectionSubtitle}>
          Add a new admin
        </Typography>
        <GridN>
          <TextInput {...allowedAdminProps} />
          <div className={classes.buttonWrapper}>
            <Button
              buttonText="Add admin"
              buttonTextSaving="Adding admin"
              onClick={handleAddUserClick}
              disabled={!addButtonEnabled()}
              saving={isSaving}
            />
          </div>
        </GridN>
      </Grid>

      <Grid item className={classes.gridItem} xs={12}>
        <Typography className={classes.inputSectionSubtitle}>
          Admins allowed to login via Identity Provider
        </Typography>

        {isEmpty(allowedUsers) ? (
          tableBodyNoUsers
        ) : (
          <Table className={classes.table}>
            {renderTableHeader()}
            {renderTableBody}
          </Table>
        )}
      </Grid>
    </StyledGrid>
  );
}

SamlUsersConfiguration.propTypes = {
  configurationId: PropTypes.string.isRequired,
};

export default SamlUsersConfiguration;
