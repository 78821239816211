import propTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import ToggleSwitch from 'components/CAM/buttons/ToggleSwitch/ToggleSwitch';

import {ReactComponent as DirectoryIcon} from 'icons/directory.svg';
import ConfigureStepCard from './ConfigureStepCard';
import {
  getClearedConfigState,
  checkRequiredParams,
  useWizardState,
} from './wizardConfigState';
import {
  stepIndices,
  activeDirectoryConfigParams,
  activeDirectorySACredsParams,
  activeDirectoryFilterParams,
  activeDirectorySyncParams,
} from './wizardConstants';

const PREFIX = 'ActiveDirectoryConfigCard';

const classes = {
  cardSubtitle: `${PREFIX}-cardSubtitle`,
  sectionSubtitle: `${PREFIX}-sectionSubtitle`,
  cardSectionBody: `${PREFIX}-cardSectionBody`,
  radio: `${PREFIX}-radio`,
  secondaryText: `${PREFIX}-secondaryText`,
  textField: `${PREFIX}-textField`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.cardSubtitle}`]: {
    marginTop: '24px',
    marginBottom: '16px',
  },

  [`& .${classes.sectionSubtitle}`]: {
    marginTop: '24px',
    marginBottom: '16px',
    fontSize: '1rem',
  },

  [`& .${classes.cardSectionBody}`]: {
    fontSize: '0.875rem',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },

  [`& .${classes.radio}`]: {
    paddingLeft: '12px',
  },

  [`& .${classes.secondaryText}`]: {
    color: theme.palette.text.secondary,
  },

  [`& .${classes.textField}`]: {
    marginTop: '8px',
    marginBottom: '8px',
  },
}));

function ActiveDirectoryConfigCard({onChange}) {
  const {
    activeStepIndex,
    setActiveStepIndex,
    configState,
    setConfigState,
    isCommandLocked,
  } = useWizardState();

  const STEP_INDEX = stepIndices.configureAD;
  const TITLE = 'Set up Active Directory';

  const IS_OPEN = activeStepIndex === stepIndices.configureAD;
  const IS_COMPLETE = activeStepIndex > stepIndices.configureAD;

  return (
    <Root>
      <ConfigureStepCard
        stepIndex={STEP_INDEX}
        title={TITLE}
        open={IS_OPEN}
        onTitleClick={() => {
          if (activeStepIndex > stepIndices.configureAD && !isCommandLocked)
            setActiveStepIndex(stepIndices.configureAD);
        }}
        complete={IS_COMPLETE}
        optional
        continueDisabled={
          !checkRequiredParams(
            [...activeDirectoryConfigParams, ...activeDirectorySyncParams],
            configState
          )
        }
        onContinueClick={() => setActiveStepIndex(stepIndices.configureAD + 1)}
        onSkipClick={() => {
          const clearedParams = getClearedConfigState(
            [...activeDirectoryConfigParams, ...activeDirectorySyncParams],
            configState
          );
          setConfigState(clearedParams);
          setActiveStepIndex(STEP_INDEX + 1);
        }}
        onPreviousClick={() => setActiveStepIndex(STEP_INDEX - 1)}
        icon={<DirectoryIcon />}
      >
        <div>
          <Typography className={classes.cardSubtitle} variant="h5">
            Integration Method
          </Typography>
          <Typography variant="body1" className={classes.cardSectionBody}>
            Choose your preferred integration method:
          </Typography>
          <FormControl>
            <RadioGroup
              name="plaintextLdap"
              onChange={(event) => {
                const updatedState = {...configState};
                const {name, value} = event.target;
                updatedState[name] = value;
                // Also need to update ldapsInsecure, as there is no way to explicitly set this for now
                updatedState.ldapsInsecure = String(value !== 'true');
                setConfigState(updatedState);
              }}
              value={String(configState.plaintextLdap)}
            >
              <FormControlLabel
                className={classes.radio}
                control={<Radio color="primary" />}
                label="LDAPS"
                value="false"
              />
              <Typography variant="body2" className={classes.secondaryText}>
                Choose LDAPS for secure, encrypted communication with your
                Active Directory. Ideal for production deployments. You may also
                optionally provide the domain controller certificate to be used
                for certificate verification.
              </Typography>
              <FormControlLabel
                className={classes.radio}
                control={<Radio color="primary" />}
                label="LDAP"
                value="true"
              />
              <Typography className={classes.secondaryText} variant="body2">
                Choose LDAP for unencrypted communication. Not recommended for
                production; use for testing or non-sensitive environments.
              </Typography>
            </RadioGroup>
          </FormControl>
        </div>
        <Typography className={classes.cardSubtitle} variant="h5">
          Active Directory Sync
        </Typography>

        <Typography variant="body1" className={classes.cardSectionBody}>
          Enable Active Directory Sync to sync your user and workstation
          information from your Active Directory into Anyware Manager. &nbsp;
          This step is optional and can be configured later.
        </Typography>
        <FormControl>
          <FormControlLabel
            label="Enable AD Sync"
            control={
              <ToggleSwitch
                name="enableAdSync"
                isOn={configState.enableAdSync}
                onClick={(event) => {
                  const updatedState = {...configState};
                  const {name, checked} = event.target;
                  updatedState[name] = checked;
                  setConfigState(updatedState);
                }}
              />
            }
          />
        </FormControl>

        {configState.enableAdSync && (
          <>
            <Typography className={classes.sectionSubtitle} variant="h6">
              Active Directory Service Account Credentials
            </Typography>
            <Typography className={classes.cardSectionBody} variant="body2">
              Enter the username and password for your Active Directory service
              account. This account should have the necessary permissions to
              access and manage objects within your Active Directory domain.
            </Typography>
            <Grid container spacing={2}>
              {activeDirectorySACredsParams.map((param) => (
                <Grid item xs={6}>
                  <TextField
                    className={classes.textField}
                    label={param.label}
                    value={configState[param.fieldName]}
                    placeholder={param.placeholder}
                    type={param.isSecret ? 'password' : 'text'}
                    name={param.fieldName}
                    data-testid={`field - ${param.fieldName} `}
                    onChange={onChange}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
            <Typography className={classes.sectionSubtitle} variant="h6">
              Active Directory Filters (Optional)
            </Typography>
            <Typography variant="body2" className={classes.secondaryText}>
              To find specific users and computers in AD, use the DN to indicate
              who to search for. You can also narrow down the results by
              applying a filter. This step is optional and can be configured
              later. These settings are shared across all Connectors under this
              Deployment.
              <br />
              <strong>
                If you have not specified any filters, all Active Directory
                users and computers will be synced.
              </strong>
            </Typography>
            <Grid container spacing={2}>
              {activeDirectoryFilterParams.map((param) => (
                <Grid key={`grid - ${param.fieldName} `} item xs={6}>
                  <TextField
                    className={classes.textField}
                    label={param.label}
                    value={configState[param.fieldName]}
                    placeholder={param.placeholder}
                    type={param.isSecret ? 'password' : 'text'}
                    name={param.fieldName}
                    data-testid={`field - ${param.fieldName} `}
                    onChange={onChange}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </ConfigureStepCard>
    </Root>
  );
}

ActiveDirectoryConfigCard.propTypes = {
  onChange: propTypes.func.isRequired,
};

ActiveDirectoryConfigCard.defaultProps = {};

export default ActiveDirectoryConfigCard;
