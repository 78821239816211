import Switch from '@mui/material/Switch';
import {styled} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

const PREFIX = 'ToggleSwitch';

const classes = {
  tooltip: `${PREFIX}-tooltip`,
  arrow: `${PREFIX}-arrow`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.tooltip}`]: {
    fontSize: '0.75rem',
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.arrow}`]: {
    color: theme.palette.primary.main,
  },
}));

function ToggleSwitch({
  isOn,
  displayText,
  onClick,
  saving,
  disabled,
  tooltipText,
  testId,
  name,
}) {
  return (
    <Root>
      <Tooltip
        arrow
        placement="bottom-start"
        title={tooltipText}
        classes={{
          arrow: classes.arrow,
          tooltip: classes.tooltip,
        }}
      >
        <span>
          <Switch
            color="primary"
            checked={isOn}
            onChange={onClick}
            disabled={saving || disabled}
            data-testid={testId}
            name={name}
          />
        </span>
      </Tooltip>
      {saving ? 'Saving changes...' : displayText}
    </Root>
  );
}

ToggleSwitch.propTypes = {
  isOn: PropTypes.bool,
  saving: PropTypes.bool,
  disabled: PropTypes.bool,
  displayText: PropTypes.string,
  tooltipText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string,
  name: PropTypes.string,
};

ToggleSwitch.defaultProps = {
  isOn: false,
  saving: false,
  disabled: false,
  displayText: '',
  tooltipText: '',
  testId: '',
  name: '',
};

export default ToggleSwitch;
