import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useCallback, useState} from 'react';
import disabledIcon from 'icons/lock-bg.svg';
import linkIcon from 'icons/new_window.svg';

const PREFIX = 'WelcomeCardItem';

const classes = {
  card: `${PREFIX}-card`,
  icon: `${PREFIX}-icon`,
  textContainer: `${PREFIX}-textContainer`,
  title: `${PREFIX}-title`,
  text: `${PREFIX}-text`,
  disabled: `${PREFIX}-disabled`,
  loading: `${PREFIX}-loading`,
  tooltip: `${PREFIX}-tooltip`,
  link: `${PREFIX}-link`
};

const StyledPaper = styled(Paper)(() => ({
  [`&.${classes.card}`]: {
    margin: '16px',
    height: '100px',
    display: 'flex',
    cursor: 'pointer',
    position: 'relative',
  },

  [`& .${classes.icon}`]: {
    width: '36px',
    height: '36px',
    color: '#0076A9',
  },

  [`& .${classes.textContainer}`]: {
    padding: '0 16px',
    borderLeft: '1px solid #DDDBDA',
    color: '#706E6B',
  },

  [`& .${classes.title}`]: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
    margin: 0,
    color: 'rgba(0,0,0,0.87)',
  },

  [`& .${classes.text}`]: {
    fontSize: '12px',
    lineHeight: '18px',
    margin: 0,
  },

  [`& .${classes.disabled}`]: {
    color: 'rgba(0,0,0,0.24)',
  },

  [`& .${classes.loading}`]: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },

  [`& .${classes.tooltip}`]: {
    fontSize: '14px',
  },

  [`& .${classes.link}`]: {
    position: 'absolute',
    width: '14px',
    height: '14px',
    opacity: '0.6',
    bottom: '0.5rem',
    right: '0.5rem',
  }
}));

function WelcomeCardItem({
  title,
  text,
  Icon,
  onClick,
  disabled,
  disabledMessage,
  loading,
  isLink,
  id,
}) {

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  const disabledBadge = (
    <img className={classes.disabledIcon} src={disabledIcon} alt="disabled" />
  );

  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  return (
    <StyledPaper
      data-testid={`${id}`}
      className={classes.card}
      elevation={hover ? 4 : 2}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Grid container alignItems="center">
        <Grid item xs={3}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Tooltip
                title={disabledMessage}
                placement="top"
                open={hover && disabled}
                classes={{
                  tooltip: classes.tooltip,
                }}
              >
                <Badge
                  overlap="rectangular"
                  badgeContent={disabledBadge}
                  invisible={!disabled}
                >
                  <Icon
                    className={classNames(classes.icon, {
                      [classes.disabled]: disabled,
                    })}
                  />
                </Badge>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.textContainer} item xs={9}>
          <p className={classes.title}>{title}</p>
          <p className={classes.text}>{text}</p>
        </Grid>
      </Grid>
      {isLink && <img className={classes.link} src={linkIcon} alt="link" />}
      {loading && (
        <LinearProgress
          className={classes.loading}
          value={50}
          color="primary"
        />
      )}
    </StyledPaper>
  );
}

WelcomeCardItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  disabledMessage: PropTypes.string,
  isLink: PropTypes.bool,
  id: PropTypes.string,
};

WelcomeCardItem.defaultProps = {
  title: 'Title',
  text: 'Some sample text.',
  Icon: null,
  onClick: () => {},
  disabled: false,
  loading: false,
  disabledMessage: 'Placeholder',
  isLink: false,
  id: 'welcome-card-item',
};

export default WelcomeCardItem;
