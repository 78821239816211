import {TablePagination} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const PREFIX = 'Pagination';

const classes = {
  pagination: `${PREFIX}-pagination`,
  rowsPerPage: `${PREFIX}-rowsPerPage`,
  rowsPerPageIcon: `${PREFIX}-rowsPerPageIcon`
};

const StyledTablePagination
 = styled(TablePagination
)((
  {
    theme
  }
) => ({
  [`& .${classes.pagination}`]: {
    color: theme.palette.surface.grey,
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.1px',
    lineHeight: '1.5rem',
  },

  [`& .${classes.rowsPerPage}`]: {
    color: '#0076A9',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.1px',
    lineHeight: '1.5rem',
  },

  [`& .${classes.rowsPerPageIcon}`]: {
    top: '7px',
    left: '20px',
  }
}));

function Pagination({
  page,
  rowsPerPage,
  rowsPerPageOptions,
  total,
  onPageChange,
  onRowsPerPageChange,
  dataTestId,
}) {


  const backButtonProps = {'aria-label': 'Previous Page'};
  const nextButtonProps = {'aria-label': 'Next Page'};

  return (
    <TablePagination
      component="div"
      count={total}
      data-testid={dataTestId}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      backIconButtonProps={backButtonProps}
      nextIconButtonProps={nextButtonProps}
      classes={{
        caption: classes.pagination,
        select: classes.rowsPerPage,
        selectIcon: classes.rowsPerPageIcon,
      }}
    />
  );
}

Pagination.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  total: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  dataTestId: PropTypes.string,
};

Pagination.defaultProps = {
  page: 0,
  rowsPerPage: 15,
  rowsPerPageOptions: [15, 25, 50],
  total: 0,
  onPageChange: () => {},
  onRowsPerPageChange: () => {},
  dataTestId: 'pagination',
};

export default Pagination;
