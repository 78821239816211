/* eslint-disable max-lines */
/* eslint-disable max-len */

// GCP provisioningStatus states

export const GCP_PROVISIONING_STATE_UNKNOWN = 'unknown';
export const GCP_PROVISIONING_STATE_CREATING = 'creating';
export const GCP_PROVISIONING_STATE_RUNNING = 'updating';
export const GCP_PROVISIONING_STATE_FAILED = 'failed';
export const GCP_PROVISIONING_STATE_SUCCEEDED = 'succeeded';

// Remote workstation power states

export const WORKSTATION_POWERSTATE_STARTING = 'starting';
export const WORKSTATION_POWERSTATE_RUNNING = 'running';
export const WORKSTATION_POWERSTATE_STOPPING = 'stopping';
export const WORKSTATION_POWERSTATE_STOPPED = 'stopped';
export const WORKSTATION_POWERSTATE_RESTARTING = 'restarting';
export const WORKSTATION_POWERSTATE_UPDATING = 'updating';
export const AZURE_WORKSTATION_POWERSTATE_DEALLOCATED = 'deallocated';
export const AZURE_WORKSTATION_POWERSTATE_DEALLOCATING = 'deallocating';

// Remote workstation power states specific to AMT

export const AMT_POWERSTATE_UNKNOWN = 'Unknown';
export const AMT_POWERSTATE_OTHER = 'Other';
export const AMT_POWERSTATE_ON = 'On';
export const AMT_POWERSTATE_SLEEP_LIGHT = 'SleepLight';
export const AMT_POWERSTATE_SLEEP_DEEP = 'SleepDeep';
export const AMT_POWERSTATE_PC_OFF_SOFT = 'PowerCycleOffSoft';
export const AMT_POWERSTATE_OFF_HARD = 'OffHard';
export const AMT_POWERSTATE_HIBERNATE_OFF_SOFT = 'HibernateOffSoft';
export const AMT_POWERSTATE_OFF_SOFT = 'OffSoft';
export const AMT_POWERSTATE_PC_OFF_HARD = 'PowerCycleOffHard';
export const AMT_POWERSTATE_MASTERBUS_RESET = 'MasterBusReset';
export const AMT_POWERSTATE_DIAGN_NMI = 'DiagnosticInterruptNMI';
export const AMT_POWERSTATE_OFF_SOFT_GRACEFUL = 'OffSoftGraceful';
export const AMT_POWERSTATE_OFF_HARD_GRACEFUL = 'OffHardGraceful';
export const AMT_POWERSTATE_MASTERBUS_RESET_GRACEFUL = 'MasterBusResetGraceful';
export const AMT_POWERSTATE_PC_OFF_SOFT_GRACEFUL = 'PowerCycleOffSoftGraceful';
export const AMT_POWERSTATE_PC_OFF_HARD_GRACEFUL = 'PowerCycleOffHardGraceful';
export const AMT_POWERSTATE_DIAGN_INIT = 'DiagnosticInterruptINIT';

// Workstation providers
export const AWS = 'aws';
export const AZURE = 'azure';
export const GCP = 'gcp';
export const AMT = 'intelamt';
export const ONPREM = 'onprem';
export const RSM = 'rsm';
export const AME = 'ame';
export const CLOUD_PROVIDERS = [AWS, AZURE, GCP, AMT, ONPREM, RSM, '-'];

// Polling Interval Constants
export const POLLING_INTERVAL_DEFAULT_VALUE = 30;

// IDP
export const SAML = 'saml';
export const GOOGLE = 'google';

// These should be ordered pseudo-alphabetically except with US regions first
// US Gov is after other regions since it is not available for most customers
export const AWS_REGIONS = {
  'us-east-1': 'US East (N. Virginia)',
  'us-east-2': 'US East (Ohio)',
  'us-west-1': 'US West (N. California)',
  'us-west-2': 'US West (Oregon)',
  'us-gov-east-1': 'US Gov East 1',
  'us-gov-west-1': 'US Gov West 1',
  'af-south-1': 'Africa (Cape Town)',
  'ap-east-1': 'Asia Pacific (Hong Kong)',
  'ap-south-1': 'Asia Pacific (Mumbai)',
  'ap-northeast-3': 'Asia Pacific (Osaka)',
  'ap-northeast-2': 'Asia Pacific (Seoul)',
  'ap-southeast-1': 'Asia Pacific (Singapore)',
  'ap-southeast-2': 'Asia Pacific (Sydney)',
  'ap-northeast-1': 'Asia Pacific (Tokyo)',
  'ca-central-1': 'Canada (Central)',
  'eu-central-1': 'EU (Frankfurt)',
  'eu-west-1': 'EU (Ireland)',
  'eu-west-2': 'EU (London)',
  'eu-south-1': 'Europe (Milan)',
  'eu-west-3': 'EU (Paris)',
  'eu-north-1': 'EU (Stockholm)',
  'me-south-1': 'Middle East (Bahrain)',
  'sa-east-1': 'South America (São Paulo)',
};

// Login Errors

export const googleAllowListError =
  'This account is not authorized to sign in. Sign in using Google requires a Google Workspace or Cloud Identity account. To sign in with a Gmail personal account, contact ';
export const popupError =
  'Signing in with Microsoft requires popups to be allowed. Please disable your popup blocker to continue using our site.';
export const allowListErrorLinkText = 'Technical Support.';
export const contactTechnicalSupportLink = 'https://help.teradici.com/s/';
export const googleAllowListErrorTitle = 'Personal email not allowed';
export const defaultLoginError =
  'An error occurred while logging in. Please make sure that cookies are enabled on your browser. If using a pop-up blocker, please disable it for this site.';
export const defaultLoginErrorTitle = 'Login error occurred';
export const samlAllowListError =
  'This account is not in the list of users authorized to sign in.';
export const samlInvalidConfigError =
  "This configuration either doesn't exist or is disabled.";
export const adGroupsTableDescription =
  'Active Directory groups can be added to Anyware Manager to entitle a group of users to a remote workstation pool.';
export const monitorStatsDescription =
  'Enabling this feature allows Anyware Monitor to send information about workstation telemetry to Anyware Manager. This can be used by your administrator to view which users are currently in session and end sessions. Session Tracking must be enabled in Edit Deployment -> Connector Settings to view the session information.';
export const monitorAlreadyInstalledMessage =
  "Looks like you already have an Anyware Monitor installed on this workstation. If you need to see the install instructions again, click the 'Show Install Instructions' button.";
export const monitorUpdateMessage =
  "If you want to update the Anyware Monitor to the latest version, click the 'Show Update Instructions' button.";

export const ERROR_INVALID_PARAMS = 'Invalid parameters provided.';

// Urls
export const LINK_COMMUNITY_DISCUSSIONS =
  'https://help.teradici.com/s/knowledge';
export const LINK_TERADICI_LIFECYCLE = 'https://docs.teradici.com/lifecycle';
export const LINK_CONTACT_ANYWARE_SUPPORT =
  'https://help.teradici.com/s/contactsupport';
export const LINK_TRANSITIONING_CONNECTORS =
  'https://www.teradici.com/web-help/cas_manager_as_a_service/reference/transitioning_connectors';
export const PCOIP_CLIENT_SESSION =
  'https://www.teradici.com/web-help/anyware_manager/current/cloud_access_connector/cas_connector_install/#4-connecting-to-a-remote-workstation-with-a-pcoip-client';
export const LINK_GET_REGISTRATION_CODE =
  'https://www.teradici.com/web-help/pcoip_cloud_access_manager/CACv2/troubleshooting/getting_your_registration_code/';
export const AWM_SAAS_DOCUMENTATION_LINK =
  'https://www.teradici.com/web-help/cas_manager_as_a_service/cam_admin_console/admin_console/';
export const AWM_SAAS_BETA_DOCUMENTATION_LINK =
  'https://www.teradici.com/web-help/cas_manager_as_a_service/beta/overview/';
export const AWM_DOCUMENTATION_LINK =
  'https://www.teradici.com/web-help/anyware_manager/';
export const AWM_RELEASE_NOTES_FEED_URL =
  'https://docs.teradici.com/documentation/anyware-manager-admin-console/release-notes/feed';
export const AWM_STANDALONE_RELEASE_NOTES_FEED_URL =
  '/api/v1/releasenotes/release-notes.xml';
export const CAC_V2_RELEASE_NOTES_FEED_URL =
  'https://docs.teradici.com/documentation/cloud-access-connector/release-notes/feed';
export const CAC_V2_DOCUMENTATION_LINK =
  'https://www.teradici.com/web-help/cas_manager/current/cloud_access_connector/overview/';
export const AWM_PRIVACY_STATEMENT =
  'https://teradici.com/privacy-faq/anyware-manager';
export const LINK_SAML_DOCUMENTATION =
  'https://www.teradici.com/web-help/cas_manager_as_a_service/cam_admin_console/saml_configuration/';
export const AWM_ADGROUPS_DOCUMENTATION =
  'https://www.teradici.com/web-help/cas_manager/current/cloud_access_connector/active_directory/#configuring-active-directory-pool-groups';
export const CREATE_CAC_LINK =
  'https://www.teradici.com/web-help/cas_manager/current/cloud_access_connector/cas_connector_server/#creating-the-connector-server';
export const VERIFY_CAC_LINK =
  'https://www.teradici.com/web-help/cas_manager/current/cloud_access_connector/cas_connector_server/#verifying-the-connector-server';
export const DOWNLOAD_CAC_LINK =
  'https://www.teradici.com/web-help/cas_manager/current/cloud_access_connector/cas_connector_install/#downloading-the-connector';
export const UPDATE_CAC_LINK =
  'https://www.teradici.com/web-help/cas_manager/current/cloud_access_connector/cas_connector_update/';
export const ENABLE_EXTERNAL_ACCESS_CAC_LINK =
  'https://www.teradici.com/web-help/cas_manager/current/cloud_access_connector/external_network_access/';
export const CAC_TOKEN_LINK =
  'https://www.teradici.com/web-help/cas_manager/current/cloud_access_connector/cas_connector_install/#generating-a-connector-token';
export const INSTALL_CAC_LINK =
  'https://www.teradici.com/web-help/cas_manager/current/cloud_access_connector/cas_connector_install/#installing-the-connector';
export const INSTALL_RHEL_CONNECTOR_LINK =
  'https://www.teradici.com/web-help/cas_manager_as_a_service/cloud_access_connector/rhel/cas_connector_install_rhel/';
export const UPDATE_RHEL_CONNECTOR_LINK =
  'https://www.teradici.com/web-help/cas_manager_as_a_service/cloud_access_connector/rhel/cas_connector_linux_update/';
export const AWC_NETWORK_REQUIREMENTS_LINK =
  'https://www.teradici.com/web-help/anyware_manager/current/cloud_access_connector/casc_connector_server/#network-requirements';
export const AWC_SYSTEM_REQUIREMENTS_LINK =
  'https://www.teradici.com/web-help/anyware_manager/current/cloud_access_connector/casc_connector_server/#minimum-requirements';

export const AWM_EULA_LINK = '/EULA.pdf';
export const AWM_SERVICE_LEVEL = '/service-level';
export const AWM_SERVICE_STATUS = 'https://status.teradici.com';
export const AWM_SERVICE_SCRIPT_URL =
  'https://fqhtj8gm9dms.statuspage.io/embed/script.js';

export const AWM_API_DOCUMENTATION = '/api/docs';

export const AZURE_SP_ROLE_ASSIGNMENT_LINK =
  'https://docs.microsoft.com/en-us/azure/role-based-access-control/role-assignments-portal';
export const AWS_CREATE_ROLE_DOC_LINK =
  'https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_create_for-user.html#roles-creatingrole-user-console';
export const AWS_ADD_ROLE_PERMISSIONS_LINK =
  'https://docs.aws.amazon.com/IAM/latest/UserGuide/roles-managingrole-editing-console.html#roles-modify_permissions-policy';
export const AWS_ADD_USER_PERMISSIONS_LINK =
  'https://docs.aws.amazon.com/IAM/latest/UserGuide/id_users_change-permissions.html';

export const AZURE_SERVICE_ACCOUNT_PERMISSION_LINK =
  'https://www.teradici.com/web-help/pcoip_cloud_access_manager/CACv2/reference/cam_power_management/#service-account-permission-requirements-microsoft-azure';

export const SESSION_HISTORY_LINK = '/app/sessionHistory';
export const DASHBOARD_LINK = '/app/dashboard';
export const DEPLOYMENTS_LINK = '/app/deployments';
export const CONNECTORS_LINK = '/app/connectors';
export const WORKSTATIONS_LINK = '/app/remoteWorkstations';
export const AD_USERS_LINK = '/app/adUsers';
export const ACTIVITY_LOG_LINK = '/app/activityLog';
export const POOLS_LINK = '/app/pools';
export const WEBHOOKS_LINK = '/app/webhooks';
export const INSTALLERS_LINK = '/app/installers';
export const DEPLOYMENT_SERVICE_ACCOUNTS_LINK =
  '/app/deployment-service-accounts';
export const CREATE_DEPLOYMENT_SERVICE_ACCOUNTS_LINK = `${DEPLOYMENT_SERVICE_ACCOUNTS_LINK}/create`;

export const EDIT_CONNECTOR_LINK = '/app/connectors/edit/:connectorId?/:tabId?';
export const EDIT_REMOTE_WORKSTATION_LINK = '/app/remoteWorkstations/edit';
export const EDIT_DEPLOYMENT_LINK = '/app/deployments/edit';
export const EDIT_POOL_LINK = '/app/pools/edit';
export const VIEW_AD_USER_LINK = '/app/adUsers/view';
export const EDIT_WEBHOOK_LINK = '/app/webhooks/edit';

export const RELEASE_NOTES_LINK = '/app/releaseNotes';
export const USER_PREFERENCES_LINK = '/app/preferences';
export const MULTI_ADMIN_LINK = '/app/preferences/multiAdmin';
export const ADD_SAML_CONFIG_LINK = '/app/preferences/multiAdmin/createConfig';
export const ACCOUNT_INFORMATION_LINK = '/app/accountInformation';
export const USER_LICENSES_LINK = '/app/licenses';

export const CREATE_DEPLOYMENT_LINK = `${DEPLOYMENTS_LINK}/create`;
export const CREATE_CONNECTOR_LINK = `${CONNECTORS_LINK}/create`;
export const CREATE_WEBHOOK_LINK = `${WEBHOOKS_LINK}/create`;
export const PROVISION_CONNECTOR_LINK = `${CONNECTORS_LINK}/provision`;
export const CONNECTOR_SELECT_TYPE_LINK = `${CREATE_CONNECTOR_LINK}/selectType`;
export const CONNECTOR_WIZARD_GETTING_STARTED_LINK = `${CONNECTORS_LINK}/createWizard`;
export const CONNECTOR_WIZARD_CONFIGURE_LINK = `${CREATE_CONNECTOR_LINK}/configure`;
export const CONNECTOR_WIZARD_CONFIGURE_CAC_LINK = `${CREATE_CONNECTOR_LINK}/configureCac`;

export const CREATE_REMOTE_WORKSTATION_LINK = `${WORKSTATIONS_LINK}/create`;
export const CREATE_REMOTE_WORKSTATION_LINK_GCP = `${WORKSTATIONS_LINK}/create/gcp`;
export const ADD_REMOTE_WORKSTATION_LINK = `${WORKSTATIONS_LINK}/addExisting`;
export const CREATE_POOL_LINK = `${POOLS_LINK}/create`;

export const PENDING_MONITOR_PROVISIONS_LINK = `${WORKSTATIONS_LINK}/pendingMonitorProvisions`;

export const AWM_SERVICE_ACCOUNT_LINK = '/app/CASManagerServiceAccounts';
export const SAML_CONFIGURATION_LINK = '/app/samlConfiguration';

// License expired messages
export const LICENSE_EXPIRED_MESSAGE =
  'Anyware Manager Enterprise license expired.';
export const LICENSE_EXPIRED_DETAILS =
  'Your Anyware Manager Enterprise license expired. Some features may be disabled. Please contact your HP representative to renew your license.';

// Beta-ui messages
export const BETA_SNACKBAR_MESSAGE = 'Beta version warning.';
export const BETA_SNACKBAR_DETAILS =
  'Please note that you are currently using a beta version. Expect some features to be incomplete or unstable. Use at your own risk.';

// Beta-ui messages
export const SESSION_TRACKING_BANNER_MESSAGE = 'Session tracking not enabled.';
export const SESSION_TRACKING_BANNER_DETAILS =
  "Access detailed session histories by enabling 'Session Tracking' under the 'Session Statistics' category in 'Settings'.";

// Home page text
export const HOME_PAGE_INFO_TEXT =
  'Anyware Manager is the connection management plane for HP\xAE Anyware deployments to secure, broker, provision, and monitor remote desktops and workstations in any on-premises, cloud or hybrid environment through a unified interface.';

export const BACKEND_ERROR_MESSAGE =
  'An error occurred in our backend, please try again. This failure has been logged so we can improve performance.';

// Resource types
export const ALL_CONNECTORS = 'allConnectors';
export const ALL_DEPLOYMENTS = 'allDeployments';
export const DEPLOYMENTS = 'deployments';
export const CONNECTORS = 'connectors';
export const CONNECTOR_EVENTS = 'connectorEvents';
export const CONNECTOR_EVENTS_LATEST = 'latestConnectorEvents';
export const EXISTING_CONNECTOR_TOKEN = 'existingConnectorToken';
export const REMOTE_WORKSTATIONS = 'remoteWorkstations';
export const ENROLLMENT_COMMANDS = 'enrollmentCommands';
export const AD_COMPUTERS = 'adComputers';
export const AD_USERS = 'adUsers';
export const AD_GROUPS = 'adGroups';
export const POOL_GROUPS = 'poolGroups';
export const ACTIVITY_LOGS = 'activityLogs';
export const CONNECTOR_HEALTH_LOGS = 'connectorHealthLogs';
export const ENTITLEMENTS = 'entitlements';
export const POOL_ENTITLEMENTS = 'poolEntitlements';
export const MACHINE_ENTITLEMENTS = 'machineEntitlements';
export const MACHINE_SESSIONS = 'machineSessions';
export const MACHINE_SESSION_ATTEMPTS = 'machineSessionAttempts';
export const MONITOR_TELEMETRY = 'monitorTelemetry';
export const MONITOR_TELEMETRY_LATEST = 'latestMonitorTelemetry';
export const MONITOR_LOGOUT_USER = 'monitorLogoutUser';
export const MONITOR_NOTIFY_USER = 'monitorNotifyUser';
export const MONITOR_ENROLLMENTS = 'monitorEnrollments';
export const ACCEPT_ENROLLMENTS = 'acceptEnrollments';
export const REJECT_ENROLLMENTS = 'rejectEnrollments';
export const UPDATE_ENROLLMENTS = 'updateEnrollments';
export const USER_GROUPS = 'userGroups';
export const USER_SESSIONS = 'userSessions';
export const USER_SESSION_ATTEMPTS = 'userSessionAttempts';
export const CLOUD_SERVICE_ACCOUNTS = 'cloudServiceAccounts';
export const GCP_ZONES = 'zones';
export const TEMPLATES = 'templates';
export const ACCELERATOR_TYPES = 'acceleratorTypes';
export const MACHINE_TYPES = 'machineTypes';
export const DISK_TYPES = 'diskTypes';
export const AZURE_RESOURCE_GROUPS = 'azureResourceGroups';
export const AZURE_SUBNETS = 'azureSubnets';
export const AWS_INSTANCES = 'awsInstances';
export const AZURE_INSTANCES = 'azureInstances';
export const AZURE_LOCATIONS = 'azureLocations';
export const AZURE_VM_SIZES = 'azureVmSizes';
export const GCP_REGIONS = 'regions';
export const GCP_INSTANCES = 'gcpInstances';
export const GCP_NETWORKS = 'gcpNetworks';
export const GCP_SUBNETS = 'gcpSubnets';
export const CLOUD_SERVICE_ACCOUNTS_EXPIRY_INFO =
  'cloudServiceAccountsExpiryInfo';
export const POOL_MACHINES_USERS = 'poolMachinesUsers';
export const SAML_CONFIGURATION = 'samlConfiguration';
export const SAML_ALLOWED_USERS = 'samlAllowedUsers';
export const SAML_ALLOWED_GROUPS = 'samlAllowedGroups';
export const CONNECTOR_SETTINGS = 'connectorSettings';
export const MULTIPLE_CONNECTOR_SETTINGS = 'multipleConnectorSettings';
export const TELEMETRY_SETTINGS = 'telemetrySettings';
export const AWM_VERSION_INFO = 'casmVersionInformation';
export const SESSION_AUDIT = 'sessions';
export const MACHINE_MGMT_CREDENTIALS = 'machineMgmtCredentials';
export const POLLING_SETTINGS = 'pollingSettings';

// Days before to show service account expiration warning
export const CLOUD_SERVICE_ACCOUNTS_EXPIRATION_WARN_IF_LESS = 30;
export const POOLS = 'pools';
export const POOL_MACHINES = 'poolMachines';
export const POOL_USERS = 'poolUsers';

// Webhooks
export const WEBHOOKS = 'webhooks';
export const WEBHOOKS_EVENT_TYPE_AUTHENTICATE = 'authenticate';
export const WEBHOOKS_EVENT_TYPE_GET_RESOURCE_LIST = 'get-resource-list';
export const WEBHOOKS_EVENT_TYPE_ALLOCATE_RESOURCE = 'allocate-resource';
export const WEBHOOKS_EVENT_TYPE_SESSION_END = 'session-end';
export const WEBHOOKS_EVENT_TYPE_SESSION_START = 'session-start';
export const WEBHOOKS_CA_CERT_STATUS_SET = 'CA Cert Set';
export const WEBHOOKS_CA_CERT_STATUS_NOT_SET = 'CA Cert Not Set';
export const WEBHOOKS_CA_CERT_STATUS_NOT_KNOWN = 'CA Cert Not Known';
// Enrollment actions
export const ENROLLMENT_ACCEPT = 'accept';
export const ENROLLMENT_REJECT = 'reject';
export const ENROLLMENT_LINK = 'link';
export const ENROLLMENT_ADD = 'add';
export const ENROLLMENT_UPDATE = 'update';

// Enrollment status
export const ENROLLMENT_STATUS_WAITING_WORKER_ACCEPT = 'waitingWorkerAccept';

// Enrollment status message
export const ENROLLMENT_STATUS_MESSAGE_WORKSTATION_NOT_FOUND =
  'A workstation with the same hostname existed when the monitor was installed but was deleted. Please, to refresh the matched workstation, click the UPDATE button.';

export const MONITOR_EULA_TITLE_LABEL = 'HP Anyware End User License Agreement';
export const MONITOR_EULA_GUIDE_LABEL =
  /Please accept the HP Anyware end-user license agreement in order to use the Anyware Monitor feature/;
export const MONITOR_EULA_ACCEPTANCE_LABEL =
  /I have read and accepted the terms of the HP Anyware end-user license agreement/;

// Power actions
export const START = 'start';
export const STOP = 'stop';
export const RESTART = 'restart';

// Date & time options
export const TIME_FORMAT_LIST = ['12 hour', '24 hour'];

export const DATE_FORMAT_LIST = [
  {format: 'MMM D, YYYY', example: 'Jul 3, 2019'},
  {format: 'MM-DD-YY', example: '07-03-19'},
  {format: 'DD-MM-YY', example: '03-07-19'},
];

// Filter options for entitlement resource on the ad users table
export const AD_USERS_ENTITLEMENT_FILTER_OPTIONS = [
  'Not entitled',
  'Entitled to remote workstations',
  'Entitled to pools',
  'Entitled to remote workstations or pools',
];

// Filter options for entitlement resource on the remote workstations table
export const REMOTE_WORKSTATIONS_ENTITLEMENT_FILTER_OPTIONS = [
  'Not entitled to users',
  'Entitled to users',
];

export const AWM_MONITOR_DISABLED_STATUS = 'Disabled';
export const AWM_MONITOR_HEALTHY_STATUS = 'Healthy';
export const AWM_MONITOR_UNHEALTHY_STATUS = 'Unhealthy';

export const REMOTE_WORKSTATIONS_AWM_MONITOR_STATUS_FILTER_OPTIONS = [
  AWM_MONITOR_DISABLED_STATUS,
  AWM_MONITOR_HEALTHY_STATUS,
  AWM_MONITOR_UNHEALTHY_STATUS,
];

export const MAX_WORKSTATIONS_BULK_ADD = 50;

export const DEFAULT_ROWS_PER_PAGE = 15;
export const DEFAULT_RESOURCE_ROWS_PER_PAGE = {
  [SESSION_AUDIT]: {page: 0, rowsPerPage: 10},
};

export const DEFAULT_ROWS_DOWNLOAD = 100000;

export const BREAKPOINT_XS = 0;
export const BREAKPOINT_SM = 600;
export const BREAKPOINT_MD = 960;
export const BREAKPOINT_LG = 1280;
export const BREAKPOINT_XL = 1920;

export const HALF_HOUR_IN_MS = 1800000;
export const ONE_HOUR_IN_MS = 3600000;
export const SIX_HOURS_IN_MS = 21600000;

export const failedToGetIpDcCertText =
  'Failed to get host IP addresses for domain controller.';
export const failedToGetExpiryDateDcCertText =
  'Could not retrieve domain controller certificate expiry for all hosts.';
export const someUnknownDcCertText =
  'Could not retrieve domain controller certificate expiry for one or more hosts.';
export const expiredDcCertText =
  'All domain controller certificates have expired; connector may not work properly.';
export const someExpiredDcCertText =
  'At least one domain controller certificate has expired. If all certificates expire, connector may not work properly.';
export const expiringSoonDcCertText =
  'One or more domain controller certificates will expire in less than one week. Connector may not work properly if all certificates expired.';
export const okDcCertText = 'All domain controller certificates look ok.';
export const unknownDcCertText = 'No domain controller certificates found.';
export const noAdGroupsText =
  'No groups were found in the connector settings for this deployment';
export const minNegotiableTLSVersion = 'TLSv1.1';
export const dataLossWarningMsg = 'Warning: all unsaved work will be lost!';

export const azureSpMissingRoles =
  'Service principal is missing required role assignments';
export const azureSpMissingRolesInstructions =
  'The roles listed below must be assigned to the service principal in the specified subscription. Instructions detailing how to add role assignments to Azure service principals can be found ';

export const AWS_ROLE_ARN_PLACEHOLDER = 'Enter AWS Role ARN';

export const AZURE_READER_ROLE_ASSIGNMENT =
  'Microsoft.Authorization/roleAssignments/read';
export const AZURE_TENANT_ID_PLACEHOLDER = 'Enter Azure tenant id';
export const AZURE_CLIENT_ID_PLACEHOLDER = 'Enter Azure client id';
export const AZURE_SUBSCRIPTION_ID_PLACEHOLDER = 'Enter Azure subscription id';
export const AZURE_OBJECT_ID_PLACEHOLDER = 'Enter Azure Application object id';
export const AZURE_CLIENT_S_PLACEHOLDER = 'Enter Azure client secret';

export const NO_GCP_ACCOUNT_HEADER = 'This deployment has no GCP account';
export const NO_GCP_ACCOUNT_MESSAGE =
  'You need to add a valid GCP account to create a remote workstation. \nPlease provide a GCP account and then try again.';
export const NO_CONNECTOR_HEADER = 'This deployment has no connectors';
export const NO_CONNECTOR_MESSAGE =
  'You need at least one connector to add a remote workstation. \nPlease install a connector first and then try again.';
export const NO_CONNECTOR_LOGS_MESSAGE = 'No recent health data found';
export const NO_CERTIFICATES_MESSAGE = 'No certificate data found';
export const NO_CONNECTOR_STATUS_MESSAGE = 'No connector status data found';

export const CONNECTOR_CHART_HEADER = 'Tracking';
export const CONNECTOR_STATUS_HEADER = 'Status Breakdown';
export const CONNECTOR_STATUS_FOOTER = 'VIEW LOG';
export const CONNECTOR_SCORE_HEADER = 'Health';
export const CONNECTOR_CERTIFICATE_HEADER = 'Certificate';

export const CONNECTOR_HEALTHY_STATUS = 'Healthy';
export const CONNECTOR_UNHEALTHY_STATUS = 'Unhealthy';
export const CONNECTOR_UNKNOWN_STATUS = 'Unknown';

export const DEPLOYMENT_DELETE_CONFIRMATION_MESSAGE =
  'Deleting a deployment also deletes all workstations, connectors, pools, entitlements, and service accounts associated with that deployment.';
export const DEPLOYMENT_DELETE_ERROR_MESSAGE = `A previous attempt to delete this deployment failed because at least one machine within the deployment could not be deleted.
  Please add valid provider credentials to the deployment and try to delete it again.
  You can also delete the deployment without deleting the remote workstations from the provider.
  In this case you'll have to remove the workstations directly from their providers later.
  `;

export const CHROME_COOKIE_LINK =
  'https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&oco=1';
export const FIREFOX_COOKIE_LINK =
  'https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer';

export const DELETE_FROM_CSP_SUPPORTED = [AWS, AZURE, GCP];

export const EDIT_DEPLOYMENT = 'Edit Deployment';
export const EDIT_CONNECTOR = 'Edit Connector';
export const EDIT_REMOTE_WORKSTATION = 'Edit Remote Workstation';
export const VIEW_WORKSTATION_USER = 'View Workstation User';
export const CREATE_DEPLOYMENT = 'Create Deployment';
export const CREATE_REMOTE_WORKSTATION = 'Create Remote Workstation';
export const CREATE_CONNECTOR = 'Create Connector';
export const ADD_REMOTE_WORKSTATIONS = 'Add Remote Workstations';
export const DEPLOYMENTS_TABLE = 'Deployments';
export const CONNECTORS_TABLE = 'Connectors';
export const REMOTE_WORKSTATIONS_TABLE = 'Remote Workstations';
export const WORKSTATION_USERS_TABLE = 'Workstation Users';
export const DASHBOARD = 'Dashboard';
export const RELEASE_NOTES = 'Release Notes';
export const USER_PREFERENCES = 'Preferences';
export const ACTIVITY_LOG = 'Activity Log';
export const POOLS_TABLE = 'Pools';
export const CREATE_POOL = 'Create Pool';
export const EDIT_POOL = 'Edit Pool';
export const MULTI_ADMIN_SETTINGS_SAML = 'SAML';
export const MULTI_ADMIN_SETTINGS_ACTIVE_DIRECTORY = 'Active Directory';
export const USER_LICENSES = 'Licenses';

export const ACTIVITY_LOG_MENU_ENTRY = 'Activity Log';
export const AWM_SERVICE_ACCOUNT_MENU_ENTRY = 'Anyware Manager Service Account';
export const ACCOUNT_INFORMATION_MENU_ENTRY = 'Account Information';

export const DEVELOPMENT = 'development';
export const PRODUCTION = 'production';
export const STAGING = 'staging';

// Component status types
export const OK_STATUS = 'ok';
export const ERROR_STATUS = 'error';
export const WARNING_STATUS = 'warning';
export const DEFAULT_STATUS = 'default';
export const GENERIC_STATUS = 'generic';
export const SUCCESS_STATUS = 'success';
export const INFO_STATUS = 'info';

// Release note types
export const RELEASE_NOTES_GA = 'release-notes-ga';
export const RELEASE_NOTES_CAC = 'release-notes-cac';
export const RELEASE_NOTES_BETA = 'release-notes-beta';
export const RELEASE_NOTES_STANDALONE = 'release-notes-standalone';

// Key codes
export const ENTER_KEY = 13;
export const ESCAPE_KEY = 27;

// Operations
export const UPDATE_CONNECTOR_HEALTH_STATUS = 'UpdateConnectorHealthStatus';
export const UPDATE_POOL_MACHINE = 'UpdatePoolMachine';

export const AD_CONFIG = 'adConfig';
export const DATE_TIME = 'dateTime';

export const TEXT_REG_CODE_DEFAULT = 'ABCDEFGHIJKL@82F0-2EB4-7C4B-63E7';

export const FORMAT_AD_GROUP_OLD = 'oldFormat';
export const FORMAT_AD_GROUP_NEW = 'newFormat';

// Operating systems
export const OS = {
  windows: 'windows',
  linux: 'linux',
};

// Table cell types
export const TABLE_CELL_TEXT = 'tableCellText';
export const TABLE_CELL_LINK = 'tableCellLink';

// Add and edit remote workstations
export const USER_SELECTION_DISABLED = '';
export const USER_SELECTION_BY_ENTITLEMENTS = 'entitlement';
export const USER_SELECTION_BY_POOL = 'pool';

// Workstation PCoIP session states
export const IN_SESSION = 'In-Session';
export const NOT_IN_SESSION = 'Not-In-Session';
export const SESSION_UNKNOWN = 'Unknown';

// Workstation Anyware Monitor session states
export const MONITOR_IN_SESSION = 'In Session';
export const MONITOR_ENDING_SESSION = 'Ending Session';
export const MONITOR_DELAYED_ENDING_SESSION = 'Ending Session scheduled for ';

// Session table constants
export const SESSION_HISTORY_DISCLAIMER_MESSAGE =
  'The session history data below is based on data from available allocation requests, security gateways and registered Anyware Monitors. To ensure system efficiency, the data is retained for 30 days before being automatically removed. The Sessions page provides the most accurate information for the state of sessions. Some pages, such as those for Pools, Workstations, and Users, might present different states. In case of any discrepancies regarding what state a session is in, the Sessions page should be regarded as more accurate.';

// Tooltips
export const IP_FQDN_TOOLTIP =
  'This will be used by the connector to reach this remote workstation.';
export const AMT_IP_FQDN_TOOLTIP =
  'All AMT operations will use this IP/FQDN instead of the workstation IP/FQDN.';
export const FLOATING_POOL_POLICY_TOOLTIP =
  'The time while the remote workstations in this pool will remain assigned to a user after the PCoIP session has been disconnected.';
export const CLONE_CONNECTOR_TOOLTIP =
  'A new connector can be prepopulated with settings copied from an existing one to simplify installation.';

// Unsaved message warning
export const UNSAVED_DATA_DIALOG_TITLE = 'Leave page?';
export const UNSAVED_DATA_DIALOG_MESSAGE =
  'There are unsaved changes on this page. Are you sure you want to leave and lose the changes you have made?';

export const CLONE_CONNECTOR_WARNING =
  'You can clone connector only if it has stored settings ("CONNECTOR SETTINGS" tab is not empty).';

// Redirection constants
export const EDIT_PAGE_PATHS = [
  'deployments/edit',
  'connectors/edit',
  'remoteWorkstations/edit',
  'pools/edit',
  'remoteWorkstations/addExisting',
];

export const ENROLLMENT_COMMAND_TEXT_DISCLAIMER = `Bulk monitor provisioning command can be generated for supported Operating Systems,
it can be used to install Anyware Monitor on multiple workstations and enroll them to this deployment in Anyware Manager.
Approval is required to complete the registration for enrolled monitors while a unique service account will be issued to each Monitor.
Revoking a command in the list will invalidate it from being used for new installations and will reject the pending approval Monitors enrolled using the command.
Monitors already approved and registered are not impacted. `;

export const LOGOUT_OPTIONS = {
  Immediately: {
    text: 'Immediately',
    value: 0,
  },
  In_5_min: {
    text: 'In 5 minutes',
    value: 5,
  },
  In_10_min: {
    text: 'In 10 minutes',
    value: 10,
  },
  In_15_min: {
    text: 'In 15 minutes',
    value: 15,
  },
};

export const READ_ONLY_PAGE_PATHS = ['adUsers/view'];

// Error types
export const ERROR_TYPE_AWM = 'awmError';
export const ERROR_TYPE_HW = 'hwError';

export const SNACKBAR_ERROR_TIMEOUT = 10 * 1000;
export const SNACKBAR_INFO_TIMEOUT = 8 * 1000;
export const SNACKBAR_SUCCESS_TIMEOUT = 5 * 1000;

// Pool constants - The minimum holding time is set by FLOATING_POOL_IDLE_MINUTES in config.js
export const PERSISTENT_POOL_POLICY = 'persistent';
export const FLOATING_POOL_POLICY = 'floating';
export const DEFAULT_ASSIGNMENT_HOLDING_TIME = 20;
export const MAX_ASSIGNMENT_HOLDING_TIME = 1576800;
export const POOL_BANNER_MESSAGE = 'Notice:';
export const POOL_BANNER_DETAILS =
  'Pools use a separate data source for updating sessions. In some cases, sessions shown here will not match sessions on the Sessions page.';
export const POOL_BANNER_LINK = '';

export const TIME_MULTIPLIER_OPTIONS = {
  Minutes: {
    text: 'Minutes',
    value: 1,
  },
  Hours: {
    text: 'Hours',
    value: 60,
  },
  Days: {
    text: 'Days',
    value: 60 * 24,
  },
};

// Pool insights
export const ERROR_FETCHING_TELEMETRY = 'ERROR_FETCHING_TELEMETRY';
export const TELEMETRY_DISABLED = 'TELEMETRY_DISABLED';
export const ERROR_FETCHING_CONNECTORS = 'ERROR_FETCHING_CONNECTORS';
export const NO_HEALTHY_CACS = 'NO_HEALTHY_CACS';
export const ERROR_FETCHING_WORKSTATION_TELEMETRY =
  'ERROR_FETCHING_WORKSTATION_TELEMETRY';
export const NO_WORKSTATION_TELEMETRY = 'NO_WORKSTATION_TELEMETRY';
export const TELEMETRY_ACTIVE = 'TELEMETRY_ACTIVE';

export const AMT_AVAILABLE_POWER_OP_TO_HUMAN_READABLE_MAP = {
  On: 'Power On',
  PowerCycleOffSoft: 'Reset',
  OffSoft: 'Power Off',
  OffSoftGraceful: 'Graceful Shutdown',
  MasterBusResetGraceful: 'Graceful Restart',
};

export const AMT_POWER_STATE_TO_HUMAN_READABLE_MAP = {
  Unknown: 'Unknown',
  On: 'Powered On',
  HibernateOffSoft: 'Hibernate',
  OffSoft: 'Powered Off',
  OffHard: 'Powered Off',
  SleepLight: 'Sleep',
  SleepDeep: 'Sleep',
};

export const updateConnectorHelpText = (
  isCloudAccess = true,
  isAnyware = true
) => (
  <>
    After changing this setting, you must update the Connector by using :
    <br />
    {isCloudAccess && (
      <>
        <code>
          sudo /usr/sbin/cloud-access-connector update --pull-connector-config
        </code>{' '}
        for the Cloud Access Connector (Ubuntu).
        <a
          href="https://www.teradici.com/web-help/cas_manager_as_a_service/cloud_access_connector/updating_cas_connector/"
          target="_blank"
          rel="noopener noreferrer"
        >
          More info
        </a>
        <br />
      </>
    )}
    {isAnyware && (
      <>
        <code>
          sudo /usr/local/bin/anyware-connector configure
          --pull-config-from-manager
        </code>{' '}
        for the Anyware Connector (RHEL/Rocky).
        <a
          href={UPDATE_RHEL_CONNECTOR_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          More info
        </a>
        <br />
      </>
    )}
  </>
);

export const COLUMN_TOOLTIP_MANAGED =
  'A managed workstation enables Anyware Manager to perform power management operations (start/stop/restart) from the Admin Console or during PCoIP Session Establishment';
export const COLUMN_TOOLTIP_VALIDATE_CERTIFICATE =
  'Warning - Disabling this validation ignores certificate validation and allows self-signed certificates.';
export const COLUMN_TOOLTIP_PCOIP_SESSION_STATE =
  'Is the workstation in an active PCoIP Session';
export const COLUMN_TOOLTIP_CURRENT_USER =
  'User that the workstation is assigned to';
export const COLUMN_TOOLTIP_SESSIONS_USERS =
  'Logged in users detected by the operating system at any point during the session';
export const COLUMN_TOOLTIP_SESSIONS_EVENTS =
  "By clicking on the status, it's possible to see the session's progression and understand the various state transitions that occurred";
export const COLUMN_TOOLTIP_WEBHOOKS_STATUS =
  'Status of the Webhook service. To toggle service on/off, please use the appropriate commands.';
export const COLUMN_TOOLTIP_WEBHOOKS_CERT_STATUS = 'Status of the Webhook CA certificate';
export const COLUMN_TOOLTIP_REQUIRES_MONITOR =
  'Anyware Monitor must be healthy to retrieve PCoIP Agent status';
export const LEGACY_SA_ROLE = 'legacy-sa';
export const LEGACY_SA_ROLE_PERMISSIONS = {
  keys: {
    actions: ['create', 'read', 'delete'],
  },
  roles: {
    actions: ['read'],
  },
  deployment: {
    actions: ['read', 'update'],
  },
  deployment_settings: {
    actions: ['create', 'read', 'update', 'delete'],
  },
  machine: {
    actions: ['create', 'read', 'update', 'delete'],
  },
  user: {
    actions: ['create', 'read', 'update'],
  },
  entitlements: {
    actions: ['create', 'read', 'delete'],
  },
  connectors: {
    actions: ['create', 'read', 'update', 'delete'],
  },
  pools: {
    actions: ['create', 'read', 'update', 'delete'],
  },
  logs: {
    actions: ['read'],
  },
  telemetry: {
    actions: ['create', 'read'],
  },
  // enrollment keys needs to be on this SA because it doesn't have
  // the create deployment scope which is necessary to create enrollment keys
  enrollment_keys: {
    actions: ['create', 'read', 'delete'],
  },
  enrollment: {
    actions: ['read', 'delete'],
  },
};

export const MULTI_ADMIN_SETTINGS_TITLE = 'Multi admin settings';

export const CREATE_DEPLOYMENT_SERVICE_ACCOUNT =
  'Create Deployment Service Account';
export const DEPLOYMENT_SERVICE_ACCOUNT_DESCRIPTION =
  'Deployment Service Accounts allow developers to grant permission for external tools to interact with Anyware Manager using public APIs. ' +
  'To use the Anyware Manager APIs, you need to be a member of the Teradici Advantage Partner Program or have received prior approval from Teradici.';

// TABS for EDIT deployment page
export const TAB_DEPLOYMENT_OVERVIEW = 'overview';
export const TAB_DEPLOYMENT_SERVICE_ACCOUNTS = 'deploymentServiceAccounts';
export const TAB_CLOUD_SERVICE_ACCOUNTS = 'providerServiceAccounts';
export const TAB_CONNECTOR_SETTINGS = 'connectorSettings';
export const TAB_ANYWARE_MONITOR = 'anywareMonitor';
export const TAB_LINK_SERVICE = 'linkService';

// TABS for BETA EDIT connector page
export const CONNECTOR_OVERVIEW_TAB_STRING = 'overview';
export const CONNECTOR_INSTALLATION_TAB_STRING = 'installation';
export const CONNECTOR_AD_CONFIG_TAB_STRING = 'activeDirectoryConfig';
export const CONNECTOR_AUTH_TAB_STRING = 'auth';
export const CONNECTOR_CERT_TAB_STRING = 'certificates';
export const CONNECTOR_CONFIG_TAB_STRING = 'configuration';
export const CONNECTOR_SETTINGS_TAB_STRING = 'connectorSettings';

// Note! These event must be kept in sync between the front end and the connector installer
export const CONNECTOR_EVENT_TYPE_CONFIGURATION_STARTED = 'commandStarted';
export const CONNECTOR_EVENT_TYPE_CONFIGURATION_SUCCESSFUL = 'commandSucceeded';
export const CONNECTOR_EVENT_TYPE_CONFIGURATION_FAILED = 'commandFailed';
export const CONNECTOR_EVENT_TYPE_TIMEOUT = 'commandTimeout';

export const CONNECTOR_EVENT_POLLING_INTERVAL_MS = 10000;

// This is a **float number** of the minimum version of the connector that supports AWC capabilities
export const AWC_MINIMUM_VERSION_WITH_CAPABILITIES = 23.08;

// This is a **float number** of the minimum version of the connector that is recommended for customers to use in production.
// Any version below this will be marked as "out of date" in the UI.
// The reasoning behind the choice of which version to consider outdated depends on many factors, including:
// - the number of known security vulnerabilities in the current version
// - if the version is still supported by HP, considering the support policy and release cadence, etc...
// It must be constantly updated too ensure customers are nagged to update to the latest version.
export const AWC_MINIMUM_RECOMMENDED_VERSION = 23.08;

// CONSTANTS for identifying Connector capabilities
export const AD_CONFIG_CAPABILITY_NAME = 'activeDirectory';
export const AD_SYNC_CAPABILITY_NAME = 'activeDirectorySync';
export const MFA_CAPABILITY_NAME = 'mfa';
export const AUTH_CAPABILITY_NAME = 'authentication';
export const INGRESS_CERT_CAPABILITY_NAME = 'providedIngressCertificate';
export const FED_AUTH_CAPABILITY_NAME = 'fedAuth';
export const SSO_CAPABILITY_NAME = 'fedAuthSso';
export const WEBHOOK_CAPABILITY_NAME = 'webhook';
export const CONFIGURATION_CAPABILITY_NAME = 'configurationService';

// CONSTANTS for identifying Connector capability state
export const CAPABILITY_ENABLED_STATE = 'Enabled';
export const CAPABILITY_INCOMPLETE_STATE = 'Incomplete';
export const CAPABILITY_DISABLED_STATE = 'Not configured';

// CONSTANTS for displaying overall Connector State
export const CONNECTOR_STATUS_HEALTHY = 'HEALTHY';
export const CONNECTOR_STATUS_OPTIMAL = 'OPTIMAL';
export const CONNECTOR_STATUS_WARNING = 'WARNING';
export const CONNECTOR_STATUS_CRITICAL = 'CRITICAL';
export const CONNECTOR_STATUS_INSTALLATION_FAILED = 'INSTALLATION FAILED';
export const CONNECTOR_STATUS_INSTALLATION_PENDING = 'PENDING INSTALL';
export const CONNECTOR_STATUS_UNKNOWN = 'UNKNOWN';

// CONSTANTS for Connector Certificate Health
export const CONNECTOR_CERTS_HEALTHY = 'Valid';
export const CONNECTOR_CERTS_EXPIRING = 'Expiring';
export const CONNECTOR_CERTS_EXPIRED = 'Expired';
export const CONNECTOR_CERTS_UNKNOWN = 'Unknown';

export const CAC_HEALTH_SUCCESS = 'success';
export const CAC_HEALTH_FAIL = 'fail';

// CONSTANTS for Connector Installation Status
export const CONNECTOR_STATUS_PENDING = 'pending';
export const CONNECTOR_STATUS_ACTIVE = 'active';

// Connector installation is considered timed out if no events are received for CONNECTOR_NO_EVENT_TIMEOUT_MS
export const CONNECTOR_NO_EVENT_TIMEOUT_MS = 1000 * 60 * 25;
// The interval in which the list of events that are considered "installation" events will be evaluated for the
// last event time to determine if the installation is timed out
export const CONNECTOR_EVENT_VERIFICATION_INTERVAL = 4000;

export const CONNECTOR_REPO_NAME = 'anyware-manager';

export const CERTIFICATES_EXTENSIONS = [
  '.pem',
  '.crt',
  '.cert',
  '.cer',
  '.key',
];