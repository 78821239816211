import {CLOUD_SERVICE_ACCOUNTS, DELETE_FROM_CSP_SUPPORTED} from './constants';

export function selectData(state, resource) {
  const {dataByResource} = state.data;
  const {data, isFetching} = dataByResource[resource] || {
    data: {},
    isFetching: false,
  };
  return {
    data: Object.values(data),
    isFetching,
  };
}

// select data to be displayed in a table
// We are essentially filtering our data store
//   by the ids in tableData[resource].
export function selectDataForTable(state, resource) {
  const {data: tableData, total} = state.tableData[resource] || {
    data: [],
    total: 0,
  };
  const {dataByResource} = state.data;
  const {data, isFetching} = dataByResource[resource] || {
    data: {},
    isFetching: false,
  };

  // filter first to prevent returning undefined in data array
  return {
    data: tableData
      .filter((item) => Boolean(data[item]))
      .map((item) => data[item]),
    isFetching,
    total,
  };
}

export function selectTableParams(state, resource) {
  const {params} = state.tableData[resource] || {params: {}};
  return {params};
}

export function selectDataForBulkActions(state, resource) {
  const dataByResource = state.data.dataByResource || {};
  const {data, isFetching} = dataByResource[resource] || {
    data: {},
    isFetching: false,
  };

  const bulkActionsDialog = state.bulkActionsDialog || {};
  const resourceData = bulkActionsDialog[resource] || {};
  const dataIds = resourceData.data || [];

  const result = dataIds
    .filter((id) => Boolean(data[id]))
    .map((id) => ({...data[id], id}));

  return {
    data: result,
    isFetching,
    total: resourceData.total || 0,
  };
}

export function selectDataIsFetching(state, resource) {
  const {dataByResource} = state.data;
  const {isFetching} = dataByResource[resource] || {
    data: {},
    isFetching: false,
  };

  return isFetching;
}

export function selectPathname(state) {
  const {pathname} = state.router || {pathname: '/'};
  return pathname;
}

export function selectDeletingResource(state, resource) {
  const deleting = state.deleting || {};
  return deleting[resource] || {};
}
export function selectSelectedDeployment(state) {
  const data = state.data || {};
  const dataByResource = data.dataByResource || {};
  const selectedDeployment = dataByResource.selectedDeployment || {};
  return selectedDeployment;
}

export function selectSelectedPool(state) {
  const data = state.data || {};
  const dataByResource = data.dataByResource || {};
  return dataByResource.selectedPool || {};
}
export function selectProviderCredentials(
  state,
  providers = DELETE_FROM_CSP_SUPPORTED
) {
  const selectedDeployment = selectSelectedDeployment(state);
  const deploymentId = selectedDeployment.deploymentId || '';

  const {data: cloudServiceAccounts, isFetching: fetchingCreds} = selectData(
    state,
    CLOUD_SERVICE_ACCOUNTS
  );

  const creds =
    cloudServiceAccounts.filter(
      (csa) =>
        csa.deploymentId === deploymentId && providers.includes(csa.provider)
    ) || [];

  return {
    creds,
    fetchingCreds,
  };
}
export function selectVariable(state, name) {
  const data = state.data || {};
  const dataByResource = data.dataByResource || {};
  return dataByResource[name];
}

export function selectDeletingPoolPolls(state) {
  const deletePoolsDialog = state.deletePoolsDialog || {};
  return deletePoolsDialog.pollingDeletions || {};
}

export function selectCheckedRows(state, resource) {
  const tableSelect = state.tableSelect || {};
  return tableSelect[resource] || {};
}

export function selectDropdownData(state, resource) {
  const dropdownData = state.dropdownData || {};
  const resourceData = dropdownData[resource] || {};
  const data = resourceData.data || {};
  return {
    data: Object.values(data) || [],
    page: resourceData.page || 1,
    isFetching: resourceData.isFetching || false,
  };
}

export function selectResource(state, resource) {
  const dataByResource = state.data.dataByResource || {};
  return dataByResource[resource] || {data: {}, isFetching: false, total: 0};
}

export function selectResourceArray(state, resource) {
  const resourceData = selectResource(state, resource);
  return {
    ...resourceData,
    data: Object.values(resourceData.data),
  };
}

export function selectResourceItem(state, resource, itemId) {
  const {data, isFetching} = selectResource(state, resource);
  return {
    item: data[itemId] || {},
    isFetching,
  };
}

export function selectSelectedTableResource(state, resource) {
  const selectedResource = state.tableSelect[resource] || {};
  return Object.values(selectedResource) || [];
}

export function selectBulkActionsDialogProps(state, resource) {
  const bulkActionsDialog = state.bulkActionsDialog || {};
  const resourceData = bulkActionsDialog[resource] || {};
  const dialogProps = resourceData.dialogProps || {};
  return {
    bulkAction: dialogProps.bulkAction || (() => {}),
    existingItems: dialogProps.existingItems || [],
    infoText: dialogProps.infoText || '',
    searchPlaceholder: dialogProps.searchPlaceholder || 'Search',
    tableFields: dialogProps.tableFields || [],
    title: dialogProps.title || '',
  };
}

export function selectBulkActionsDialogTableParams(state, resource) {
  const bulkActionsDialog = state.bulkActionsDialog || {};
  const resourceData = bulkActionsDialog[resource] || {};
  return resourceData.tableParams || {};
}
