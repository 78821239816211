/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
// import {makeStyles} from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import {useState} from 'react';
import Box from '@mui/material/Box';
import {PermissionDialog} from 'components/deployments/PermissionDialog';
import {
  StyledTable,
  StyledTableRow,
  StyledTableCell,
  StyledTableHead,
  StyledTableBody,
} from 'components/deployments/StyledTable';

const StyledGeneralNote = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 4,
  background: '#EDEDF2',
  padding: 8,
  gap: '8px',
  '& .icon': {
    width: 16,
    height: 16,
    display: 'flex',
  },
}));

function GeneralNote({children}) {
  return (
    <StyledGeneralNote>
      <InfoIcon className="icon" />
      {children}
    </StyledGeneralNote>
  );
}

GeneralNote.propTypes = {
  children: PropTypes.element.isRequired,
};

const Root = styled('div')(() => ({
  '& .name': {
    '&::first-letter': {
      textTransform: 'capitalize',
    },
  },
  '& .checkmarkIcon': {
    stroke: '#0076A9',
    strokeWidth: 2,
  },
  '& .toolTipIcon': {
    cursor: 'pointer',
    margin: '0 1px',
    verticalAlign: 'text-bottom',
    overflow: 'hidden',
    height: '18px',
  },
  '& .leftAlign': {
    textAlign: 'left',
  },
}));

// list of known resource/permission name and description
const permissionsDict = {
  deployment: {
    name: 'Deployment',
    description: (
      <>
        <Box>
          Grant access to manage deployments and view information (Ex.
          instances, network, region) on cloud providers.
        </Box>
        <Box mt={1.5}>
          <GeneralNote>
            <span>
              To manage deployment settings, the "Deployment Settings"
              permission is required instead.
            </span>
          </GeneralNote>
        </Box>
      </>
    ),
  },
  deployment_settings: {
    name: 'Deployment Settings',
    description: (
      <>
        <Box>Grant access to manage deployment settings.</Box>
        <Box mt={1.5}>
          Deployment settings include a variety of settings that apply to the
          deployment (Ex. connector settings).
        </Box>
      </>
    ),
  },
  connectors: {
    name: 'Connectors',
    description: (
      <>
        <Box>Grant access to manage connectors.</Box>
        <Box mt={1.5}>
          To install a connector, a connector token needs to be generated and
          supplied to the connector installer. Create “Connectors' and create
          “Service Accounts” permissions are required to generate a connector
          token.{' '}
        </Box>
        <Box mt={1.5}>
          <GeneralNote>
            <span>
              To manage connector settings, the "Deployment Settings" permission
              is required instead.
            </span>
          </GeneralNote>
        </Box>
      </>
    ),
  },
  machine: {
    name: 'Workstations',
    description: (
      <Box>
        Grant access to manage workstation resources, this includes power
        management of the workstation.
      </Box>
    ),
  },
  pools: {
    name: 'Pools',
    description: (
      <>
        <Box>Grants access to manage pools and pool workstations.</Box>
        <Box mt={1.5}>
          However, to manage user assignment to the pool, Entitlements
          permission is used instead.
        </Box>
      </>
    ),
  },
  entitlements: {
    name: 'Entitlements',
    description: (
      <Box>
        Grant access to manage workstation users and machine/pool assignments.
      </Box>
    ),
  },
  logs: {
    name: 'Activity Logs',
    description: (
      <Box>
        Grant access to view records of all activity and operations performed in
        your Anyware Manager environment. (Ex. creating service account)
      </Box>
    ),
  },
  telemetry: {
    name: 'Stats',
    description: (
      <>
        <Box>Grant access to stats reporting and ingesting.</Box>
        <Box mt={1.5}>
          Anyware Manager consumes statistics from components(Ex. Security
          gateway) in the service, and utilize it for reporting and supporting
          other functionalities (Ex. floating pool).
        </Box>
      </>
    ),
  },
  enrollment_keys: {
    name: 'Anyware Monitor Bulk Installation Command',
    description: (
      <Box>
        Grant access to manage Anyware Monitor bulk installation commands, which
        can be used to install Anyware Monitor on multiple workstations and
        enroll them to this deployment.
      </Box>
    ),
  },
  enrollment: {
    name: 'Anyware Monitor Bulk Installation',
    description: (
      <>
        <Box>
          Grant access to enroll a Anyware Monitor installation with Anyware
          Manager.
        </Box>
        <Box mt={1.5}>
          <b>create:enrollment</b> allows the Monitor to enroll with the Anyware
          Manager.
        </Box>
        <Box mt={1.5}>
          <b>delete:enrollment</b> grant permissions to the administrator to
          approve or deny these enrollments made by the Monitor.
        </Box>
      </>
    ),
  },
  user: {
    name: 'Users and Provider Service Accounts',
    description: (
      <>
        <Box>Grant access to manage users and provider service accounts.</Box>
        <Box mt={1.5}>
          Provider service accounts are credentials used by Anyware Manager to
          perform actions on the cloud environment (Ex. AWS/GCP/Azure).
        </Box>
      </>
    ),
  },
  roles: {
    name: 'Roles',
    description: <Box>Grant access to view existing roles.</Box>,
  },
  keys: {
    name: 'Service Accounts',
    description: (
      <>
        Grant programmatic access to perform operations on Anyware Manager
        resources using public APIs.
      </>
    ),
  },
};

class Resource {
  /**
   * @param {string} resourceKey
   * @param {} resource
   */
  constructor(resourceKey, resource) {
    // _resource might be null if it is not defined in the dictiionary yet
    const _resource = permissionsDict?.[resourceKey];
    /** @type {string} */
    this.name = _resource?.name || resourceKey;
    /** @type {*} */
    this.description = _resource?.description || '';
    /** @type {string} */
    this.key = resourceKey;
    /** @type {[string]} */
    this.actions = resource.actions || [];
  }
}

/** Given permissions (Ex. {'resourceA':{actions:['create]}}) return a list of resources */
function getResources(permissions) {
  const allPossibleResources = Object.keys(permissionsDict); // ['deployment','pool', etc]
  const allPermissions = {};
  allPossibleResources.forEach((resource) => {
    allPermissions[resource] = permissions[resource] || [];
  });
  const resources = Object.entries(allPermissions);
  return resources.map(([key, value]) => new Resource(key, value));
}

const actions = ['create', 'read', 'update', 'delete'];
const actionDescription = {
  create: 'Create or add new resources to the system',
  read: 'Retrieve or read existing resources from the system',
  update: 'Modify or replace existing resources in the system',
  delete: 'Delete existing resources from the system',
};

function PermissionTable({permissions}) {
  const resources = getResources(permissions);
  return (
    <Root>
      <StyledTable stickyHeader aria-label="permissions table" size="small">
        <StyledTableHead>
          <StyledTableRow>
            <StyledTableCell>Permissions</StyledTableCell>
            {actions.map((action) => (
              <StyledTableCell
                key={`header-${action}`}
                className="name"
                align="center"
              >
                {action}
                <Tooltip
                  title={actionDescription[action] || ''}
                  aria-label={action}
                >
                  <InfoIcon className="toolTipIcon" color="action" />
                </Tooltip>
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </StyledTableHead>
        <StyledTableBody>
          {resources.map((resource) => (
            <ResourceRow key={resource.key} resource={resource} />
          ))}
        </StyledTableBody>
      </StyledTable>
    </Root>
  );
}

function ResourceRow({resource}) {
  const [openPermissionDialog, setOpenPermissionDialog] = useState(false);

  return (
    <>
      <PermissionDialog
        open={openPermissionDialog}
        onClose={() => setOpenPermissionDialog(false)}
        name={resource.name}
        scopeName={resource.key}
        description={resource.description}
      />
      <StyledTableRow key={resource.name}>
        <StyledTableCell className="name">
          <Link
            type="button"
            data-testid={`btn-permission-${resource.key}`}
            component="button"
            className="leftAlign"
            onClick={() => {
              setOpenPermissionDialog(true);
            }}
          >
            {resource.name}
          </Link>
        </StyledTableCell>
        {actions.map((action) => (
          <StyledTableCell
            key={`cell-${resource.key}-${action}`}
            align="center"
          >
            {resource.actions.includes(action) ? (
              <CheckIcon
                className="checkmarkIcon"
                color="primary"
                data-testid={`check-${resource.key}-${action}`}
              />
            ) : (
              <RemoveIcon data-testid={`uncheck-${resource.key}-${action}`} />
            )}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </>
  );
}

ResourceRow.propTypes = {
  resource: PropTypes.object.isRequired,
};
PermissionTable.propTypes = {
  permissions: PropTypes.object.isRequired,
};

export default PermissionTable;
