import {
  CLEAR_DROPDOWN_DATA,
  LOAD_NEXT_PAGE,
  REQUEST_NEXT_PAGE,
} from 'redux/actions/dataActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';
import {mapResourceToIdField} from 'utils/Mappings';
import {convertArrayToObject} from 'utils/utils';

const defaultState = {};

// This reducer handles data listed in dropdown selectors
function reducerByResource(
  state = {
    data: [],
    isFetching: false,
    page: 0,
  },
  action
) {
  switch (action.type) {
    case REQUEST_NEXT_PAGE:
      return {...state, isFetching: true};
    case LOAD_NEXT_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          ...convertArrayToObject(
            action.data,
            mapResourceToIdField(action.resource)
          ),
        },
        isFetching: false,
        page: state.page + 1,
      };
    case CLEAR_DROPDOWN_DATA:
      return {
        data: [],
        isFetching: false,
        page: 0,
      };
    default:
      return state;
  }
}

function dropdownReducer(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_NEXT_PAGE:
    case LOAD_NEXT_PAGE:
    case CLEAR_DROPDOWN_DATA:
      return {
        ...state,
        [action.resource]: reducerByResource(state[action.resource], action),
      };
    case CLEAR_STORE:
      return defaultState;
    default:
      return state;
  }
}

export default dropdownReducer;
