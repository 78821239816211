import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {styled} from '@mui/material/styles';
import CAMDialog from 'components/CAM/dialogs/CAMDialog/CAMDialog';

const PREFIX = 'DialogTextInput';

const classes = {
  dialogTextInput: `${PREFIX}-dialogTextInput`,
};

const StyledTextField = styled(TextField)(() => ({
  [`&.${classes.dialogTextInput}`]: {
    borderColor: '#C7C9D1',
    backgroundColor: '#F8F8FB',
  },
}));
function DialogTextInput({
  open,
  title,
  subtitle,
  onCancel,
  onOk,
  okLabel,
  placeholder,
  onInputChange,
  errorHelperText,
  allowLineBreaks,
}) {
  const [input, setInput] = useState('');

  const handleClickCancel = () => {
    setInput('');
    onCancel();
  };

  const isInputValid = (value) => {
    // Checks for empty or white spaces
    return value !== null && value.match(/^(\r\n|\n|\r| )*$/) === null;
  };

  const handleClickOk = () => {
    if (isInputValid(input) && !errorHelperText) {
      onOk(input);
      setInput('');
    }
  };

  const handleInputChange = (value) => {
    // Remove line breaks
    const newValue = allowLineBreaks
      ? value
      : value.replace(/(\r\n|\n|\r)/gm, '');
    setInput(newValue);
    onInputChange(newValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleClickOk();
    }
  };
  if (!open) {
    return null;
  }

  const content = (
    <>
      {subtitle}

      <StyledTextField
        className={classes.dialogTextInput}
        variant="outlined"
        autoComplete="off"
        required
        value={input}
        onChange={(event) => handleInputChange(event.target.value)}
        error={!!errorHelperText}
        helperText={errorHelperText}
        placeholder={placeholder}
        onKeyPress={handleKeyPress}
        autoFocus
        multiline
        data-testid="dialog-text-input"
        size="small"
        sx={{
          '& .MuiOutlinedInput-root': {
            border: '1px solid #C7C9D1',
            ':focus-within': {border: '0.5px solid #C7C9D1 !important'},
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      />
    </>
  );

  return (
    <CAMDialog
      content={content}
      open={open}
      onCancel={handleClickCancel}
      onOk={handleClickOk}
      title={title}
      okLabel={okLabel}
      okDisabled={!isInputValid(input) || !!errorHelperText}
    />
  );
}

DialogTextInput.propTypes = {
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.any,
  onCancel: PropTypes.func,
  onInputChange: PropTypes.func,
  errorHelperText: PropTypes.string,
  allowLineBreaks: PropTypes.bool,
  okLabel: PropTypes.string,
};

DialogTextInput.defaultProps = {
  placeholder: 'Type here...',
  title: '',
  subtitle: null,
  onCancel: () => {},
  onInputChange: () => {},
  errorHelperText: '',
  allowLineBreaks: true,
  okLabel: 'Ok',
};

export default DialogTextInput;
