/** This file contain functions that generate path */
import {generatePath} from 'react-router-dom';
import {TAB_DEPLOYMENT_OVERVIEW} from './constants';

export const LINK_EDIT_DEPLOYMENT =
  '/app/deployments/edit/:deploymentId/:tabId?';

export function getDeploymentPath(
  deploymentId,
  tabId = TAB_DEPLOYMENT_OVERVIEW
) {
  return generatePath(LINK_EDIT_DEPLOYMENT, {
    deploymentId,
    tabId,
  });
}
