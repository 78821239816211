import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';
import {del, get, post} from 'api/Api';
import CAMPagination from 'components/common/Pagination';
import useSnackbar from 'hooks/useSnackbar';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {handleApiError} from 'redux/actions/dataActions';
import LicenseTable from 'components/licenses/LicenseTable';
import AddLicense from './AddLicense';

const PREFIX = 'Licenses';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
};

const StyledGrid = styled(Grid)(({theme}) => ({
  [`&.${classes.root}`]: {
    margin: theme.spacing(4),
    backgroundColor: 'transparent',
    padding: '2px',
  },

  [`& .${classes.container}`]: {
    flexWrap: 'wrap',
    padding: '6px 3px',
    marginRight: theme.spacing(8),
  },
}));

function Licenses() {
  const dispatch = useDispatch();
  const {successSnackbar, errorSnackbar} = useSnackbar();

  const [licenses, setLicenses] = useState([]);
  const [isLoadingLicenses, setLoadingLicenses] = useState(true);

  // Pagination
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (_, newPage) => setCurrentPage(newPage);
  const handleRowsPerPageChange = ({target: {value}}) => {
    const newRowsPerPage = parseInt(value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0);
  };

  const getLicenses = async () => {
    try {
      const limit = rowsPerPage;
      const offset = rowsPerPage * currentPage;

      setLoadingLicenses(true);

      // Limit and offset are not supported by the API yet
      const response = await get({path: 'licenses'});
      const fetchedLicenses = response.data.slice(offset, offset + limit);

      setTotal(response.data.length);
      setLicenses(fetchedLicenses);
    } catch (error) {
      setLoadingLicenses(false);
      dispatch(handleApiError(error));
    }

    setLoadingLicenses(false);
  };

  useEffect(() => {
    getLicenses();
  }, [currentPage, rowsPerPage]);

  const handleAddLicense = async (regcode) => {
    let newLicense;
    try {
      const response = await post({
        path: 'licenses',
        data: {registrationCode: regcode},
      });
      newLicense = response.data;
      getLicenses();
      successSnackbar(`License ${newLicense.id} was added.`);
    } catch (error) {
      if (error.code === 400) {
        errorSnackbar(error.data.errors[0]);
      } else {
        dispatch(handleApiError(error));
      }
      newLicense = {};
    }
    return newLicense;
  };

  const deleteLicense = async (license) => {
    try {
      await del({path: `licenses/${license.id}`});
    } catch (error) {
      if (error.code === 400) {
        errorSnackbar(
          `License '${license.id}' is associated to a deployment and cannot be deleted.`
        );
        return;
      }
      dispatch(handleApiError(error));
      return;
    }
    successSnackbar(`License ${license.id} was deleted.`);
    getLicenses();
  };

  const handleDeleteLicenseButtonClick = (license) => {
    dispatch(
      openDialog(
        'Delete License?',
        `Are you sure you want to delete the license '${license.id}'?`,
        () => deleteLicense(license)
      )
    );
  };

  const renderPagination = () => (
    <CAMPagination
      dataTestId="license-table-pagination"
      rowsPerPageOptions={[10, 25, 50]}
      component="div"
      total={total}
      rowsPerPage={rowsPerPage}
      page={currentPage}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  );

  return (
    <StyledGrid container className={classes.root}>
      <Grid container justifyContent="flex-start" alignItems="center">
        <Typography variant="h5" id="tableTitle">
          Licenses
        </Typography>
        <AddLicense addCallback={handleAddLicense} />
      </Grid>

      <Grid container data-testid="license-table">
        <Grid item xs={12} className={classes.container}>
          <LicenseTable
            licenses={licenses}
            onRemove={handleDeleteLicenseButtonClick}
            isLoading={isLoadingLicenses}
          />
          {renderPagination()}
        </Grid>
      </Grid>
    </StyledGrid>
  );
}

export default Licenses;
