import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Router, Switch} from 'react-router-dom';
import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';
import AuthenticatedApp from 'components/common/AuthenticatedApp';
import PageNotFound from 'components/common/PageNotFound';
import PrivateRoute from 'components/common/PrivateRoute';
import ServiceLevelPage from 'components/home/ServiceLevelPage';
import UnauthenticatedApp from 'components/home/UnauthenticatedApp';
import config from 'config';
import withTheme from 'themes/default';
import {AWM_SERVICE_SCRIPT_URL} from 'utils/constants';
import {mapPathnameToPageTitle} from 'utils/Mappings';
import './App.css';

const cache = createCache({
  key: 'key-emotion-css',

  nonce:
    document.querySelector('meta[property="csp-nonce"]')?.content ||
    '__SERVER__NONCE__',

  prepend: true,
});

// Creates a script tag for the provided URL and adds it to the document
// Return the script so that it can be removed later
const createScript = (url) => {
  const script = document.createElement('script');

  // Define settings for script tag
  script.async = true;
  script.defer = true;
  script.src = url;

  document.body.appendChild(script);
  return script;
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusPageScript: null,
    };
  }

  componentDidMount() {
    const {pathname} = this.props;
    document.title = `${mapPathnameToPageTitle(pathname)}${
      config.isBeta() ? ' (Beta)' : ''
    }`;

    window.addEventListener('beforeunload', (ev) => {
      if (this.shouldPreventClose()) {
        ev.preventDefault();
        ev.returnValue =
          'Are you sure you want to leave this page? Changes you made may not be saved!';
        return ev.returnValue;
      }
    });

    if (!config.STANDALONE) {
      this.setState({
        statusPageScript: createScript(AWM_SERVICE_SCRIPT_URL),
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {pathname: newPathname} = this.props;
    const {pathname: oldPathname} = prevProps;

    if (oldPathname !== newPathname) {
      document.title = `${mapPathnameToPageTitle(newPathname)}${
        config.isBeta() ? ' (Beta)' : ''
      }`;
    }
  }

  componentWillUnmount() {
    const {statusPageScript} = this.state;
    if (statusPageScript) {
      document.body.removeChild(statusPageScript);
    }
  }

  shouldPreventClose() {
    const {shouldPreventClosingApp} = this.props;
    return shouldPreventClosingApp;
  }

  render() {
    const {history} = this.props;
    return (
      // Wrap DOM in <CacheProvider /> to pass nonce value
      <CacheProvider value={cache}>
        <Router history={history}>
          <Switch>
            <Route
              path="/"
              render={(routeProps) => (
                <UnauthenticatedApp {...routeProps} {...this.props} />
              )}
              exact
            />
            <Route
              path="/service-level/"
              render={(routeProps) => (
                <ServiceLevelPage {...routeProps} {...this.props} />
              )}
              exact
            />
            <PrivateRoute path="/app" component={AuthenticatedApp} />
            <Route component={PageNotFound} />
          </Switch>
        </Router>
      </CacheProvider>
    );
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  shouldPreventClosingApp: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {dataByResource} = state.data;
  const shouldPreventClosingApp = (dataByResource.backgroundActions || 0) > 0;
  const {pathname} = state.router || {pathname: '/'};
  return {
    shouldPreventClosingApp,
    pathname,
  };
}

export default withTheme(connect(mapStateToProps)(App));
