import {useEffect} from 'react';
import {styled} from '@mui/material/styles';
import {Typography} from '@mui/material';

import {useDispatch, useSelector} from 'react-redux';
import {ReactComponent as CliIcon} from 'icons/cli.svg';
import {addPollingItem} from 'redux/actions/pollingActions';
import {mapResourceToPath} from 'utils/Mappings';
import {
  checkEventsInstallStatus,
  copyToClipboard,
  sanitizeValues,
  isEmpty,
} from 'utils/utils';
import {
  CONNECTOR_EVENTS_LATEST,
  CONNECTOR_EVENT_TYPE_CONFIGURATION_SUCCESSFUL,
  CONNECTOR_EVENT_TYPE_CONFIGURATION_FAILED,
  CONNECTOR_STATUS_PENDING,
  CONNECTOR_EVENT_TYPE_TIMEOUT,
} from 'utils/constants';
import {pushNotification} from 'redux/actions/notificationActions';
import useBetaDialog from 'hooks/useBetaDialog';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {post} from 'api/Api';
import {handleApiError} from 'redux/actions/dataActions';
import config from 'config';
import useSnackbar from 'hooks/useSnackbar';
import ConfigureStepCard from './ConfigureStepCard';
import {stepIndices, configParams, mfaRadiusParams} from './wizardConstants';
import CopyAwcCommand from './CopyAwcCommand';
import {generateAwcCommand, useWizardState} from './wizardConfigState';

const sortAscQueryString = '?sortAsc=true';

const PREFIX = 'GenerateCommandCard';

const classes = {
  helpText: `${PREFIX}-helpText`,
};

const Root = styled('div')(() => ({
  [`& .${classes.helpText}`]: {
    marginTop: '8px',
    marginBottom: '8px',
  },
}));

// TODO: update text to indicate that command must be generated with confirmation & copy does not happen right away
// TODO: is it possible to show dialog right away without adding an extra button click?
function GenerateCommandCard() {
  const dispatch = useDispatch();
  const {
    activeStepIndex,
    setActiveStepIndex,
    setCommandCopied,
    connectorName,
    installCommand,
    setInstallCommand,
    connectorId,
    setConnectorId,
    setConnectorToken,
    isCommandLocked,
    setIsCommandLocked,
    configState,
  } = useWizardState();
  const {successSnackbar} = useSnackbar();
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );

  const STEP_INDEX = stepIndices.generateInstallCommand;
  const TITLE = 'Generate installation command';

  const CONNECTOR_PANEL_LINK = `/app/connectors/edit/${connectorId}/installation`;
  const IS_OPEN = activeStepIndex === STEP_INDEX;

  const connectorInstallFinished = (input, error) => {
    // If there was an error, return true to remove polling item
    if (error) {
      return true;
    }
    if (input) {
      if (input.code === 200 && input.total > 0) {
        const installStatus = checkEventsInstallStatus(input.data);
        if (installStatus === CONNECTOR_EVENT_TYPE_CONFIGURATION_SUCCESSFUL) {
          dispatch(
            pushNotification({
              title: 'Connector installation completed',
              moreInfo:
                'You can check the details of the installation in the Connector page',
              timePosted: Date.now(),
              link: CONNECTOR_PANEL_LINK,
              type: 'Connector Install Progress',
            })
          );
          return true;
        }
        if (installStatus === CONNECTOR_EVENT_TYPE_CONFIGURATION_FAILED) {
          dispatch(
            pushNotification({
              title: 'Connector installation failed',
              moreInfo:
                'You can check the details of the installation in the Connector page',
              timePosted: Date.now(),
              link: CONNECTOR_PANEL_LINK,
              type: 'Connector Install Progress',
            })
          );
          return true;
        }
        if (installStatus === CONNECTOR_EVENT_TYPE_TIMEOUT) {
          dispatch(
            pushNotification({
              title: 'Connector installation timed out',
              moreInfo:
                'You can check the details of the installation in the Connector page',
              timePosted: Date.now(),
              link: CONNECTOR_PANEL_LINK,
              type: 'Connector Install Progress',
            })
          );
          return true;
        }
      }
    }
    return false;
  };

  const connectorInstallStarted = (input, error) => {
    // If there was an error, return true to remove polling item
    if (error) {
      return true;
    }

    if (input) {
      if (input.code === 200 && input.total > 0) {
        dispatch(
          pushNotification({
            title: 'Connector installation started.',
            moreInfo: `You will receive updates regarding the progress of the installation of Connector ${connectorName}`,
            stylingIndex: 12,
            wordStyling: {fontWeight: 'bold'},
            timePosted: Date.now(),
            link: CONNECTOR_PANEL_LINK,
            type: 'Connector Install Progress',
          })
        );

        dispatch(
          addPollingItem({
            key: `${CONNECTOR_EVENTS_LATEST}-${connectorId}-finished`,
            path:
              mapResourceToPath(CONNECTOR_EVENTS_LATEST, {connectorId}) +
              sortAscQueryString,
            endFunctions: [connectorInstallFinished],
          })
        );
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    // Poll for starting install when a new connector is created
    if (connectorId) {
      dispatch(
        addPollingItem({
          key: `${CONNECTOR_EVENTS_LATEST}-${connectorId}-started`,
          path:
            mapResourceToPath(CONNECTOR_EVENTS_LATEST, {connectorId}) +
            sortAscQueryString,
          endFunctions: [connectorInstallStarted],
        })
      );
    }
  }, [connectorId]);

  const handleCreateClick = async () => {
    const generateConnectorToken = async () => {
      let token = '';
      const data = {deploymentId};
      data.connectorName = sanitizeValues(connectorName);
      try {
        const resp = await post({path: 'auth/tokens/connector', data});
        token = resp.data.token;
        setConnectorToken(token);
      } catch (error) {
        dispatch(handleApiError(error));
      }
      return token;
    };

    const createConnector = async (token) => {
      // Do not perform again if connector was previously created
      if (connectorId) {
        return;
      }

      try {
        const data = {connectorToken: token, status: CONNECTOR_STATUS_PENDING};
        const resp = await post({path: 'deployments/connectors', data});
        setConnectorId(resp.data.connectorId);
      } catch (error) {
        dispatch(handleApiError(error));
      }
    };

    const token = await generateConnectorToken();
    if (token) {
      await createConnector(token);
    }

    return token;
  };

  const handleCommandCreation = async () => {
    const token = await handleCreateClick();
    if (!token) {
      return;
    }

    let baseCommand = `sudo /usr/local/bin/anyware-connector configure --manager-url ${config.GATEWAY_ADDRESS} --accept-policies --token ${token}`;

    // The Installable/Standalone manager gets installed with a self-signed certificate, and the
    // connector won't trust it unless the --manager-insecure flag is set
    // TODO: This is a temporary solution until we can detect if the manager is installed with a valid certificate
    if (config.isStandalone()) {
      baseCommand += ' --manager-insecure';
    }

    // Handle flags that don't have an explicit boolean toggle

    // If any of the MFA Radius params are set, enable MFA
    if (
      mfaRadiusParams.some((param) => !isEmpty(configState[param.fieldName]))
    ) {
      configState.enableMfa = true;
    }

    const fullCommand = generateAwcCommand({
      baseCommand,
      configParamArray: configParams,
      configState,
    });

    setIsCommandLocked(true);
    setInstallCommand(fullCommand);
    copyToClipboard(fullCommand);
    setCommandCopied(true);
    successSnackbar('Installation command copied to clipboard');
  };

  const {renderDialog, toggleDialog} = useBetaDialog({
    title: 'Generate installation command?',
    message:
      'Once you generate the installation command you will be unable to change any configuration options until after your Connector is installed and running.',
    onPrimaryAction: handleCommandCreation,
    secondaryActionText: 'Edit details',
  });

  const onGenerateClick = () => {
    if (installCommand) {
      copyToClipboard(installCommand);
    } else {
      toggleDialog();
    }
  };

  const onPreviousClick = () => {
    if (!isCommandLocked) {
      setActiveStepIndex(STEP_INDEX - 1);
    }
  };

  return (
    <Root>
      <ConfigureStepCard
        stepIndex={STEP_INDEX}
        title={TITLE}
        open={IS_OPEN}
        onPreviousClick={onPreviousClick}
        previousDisabled={isCommandLocked}
        icon={<CliIcon />}
      >
        <Typography variant="body1" className={classes.helpText}>
          Please copy the generated command using the copy button, and enter it
          on your Connector&lsquo;s terminal.
        </Typography>
        <Typography>
          <strong>NOTE:</strong> Please exercise caution when copying/pasting
          the command, as it may contain sensitive information.
        </Typography>
        <CopyAwcCommand
          isCopyEnabled
          alternateCopyFunction={onGenerateClick}
          commandToCopy={installCommand}
          commandType="install"
        />
        {renderDialog()}
      </ConfigureStepCard>
    </Root>
  );
}

GenerateCommandCard.propTypes = {};

GenerateCommandCard.defaultProps = {
  installCommand: '',
};

export default GenerateCommandCard;
