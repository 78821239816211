import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {isEmpty} from 'utils/utils';
import {stylesFromFigma} from 'themes/stylesFromFigma';

const styles = {
  editButton: stylesFromFigma.editButton,
  deleteButton: stylesFromFigma.deleteButton,
};

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'buttonType',
})(({buttonType}) => ({
  ...styles[buttonType],
}));

export function WebhookButton({buttonType, onClick, selectedWebhook}) {
  const buttonText = buttonType === 'editButton' ? 'Edit' : 'Delete Webhook';

  return (
    <StyledButton
      buttonType={buttonType}
      disabled={isEmpty(selectedWebhook)}
      variant="contained"
      onClick={onClick}
    >
      {buttonText}
    </StyledButton>
  );
}

WebhookButton.propTypes = {
  buttonType: PropTypes.string,
  selectedWebhook: PropTypes.object,
  onClick: PropTypes.func,
};

WebhookButton.defaultProps = {
  buttonType: 'editButton',
  selectedWebhook: {},
  onClick: () => {},
};
