import {useEffect, useState} from 'react';

// This hook detects whether a node's children are overflowing

function useDetectOverflow(refEl = null) {
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    if (refEl && refEl.current) {
      const element = refEl.current;
      setOverflow(
        element.offsetHeight < element.scrollHeight ||
          element.offsetWidth < element.scrollWidth
      );
    }
  }, [refEl.current]);

  return overflow;
}

export default useDetectOverflow;
