import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import {useDispatch} from 'react-redux';
import {push} from 'redux/ReduxHistory';
import {CREATE_WEBHOOK_LINK} from 'utils/constants';

const PREFIX = 'AddWebhookButton';

const classes = {
  button: `${PREFIX}-button`,
  addIcon: `${PREFIX}-addIcon`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.button}`]: {
    backgroundColor: '#0D47A1',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#0D47A1',
      opacity: 0.8,
    },
    paddingRight: '20px',
  },

  [`& .${classes.addIcon}`]: {marginRight: '12px'},
}));

function AddWebhookButton() {
  const dispatch = useDispatch();

  const createNewWebhookDispatch = () => {
    dispatch(push(CREATE_WEBHOOK_LINK));
  };

  return (
    <StyledGrid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <Button className={classes.button} onClick={createNewWebhookDispatch}>
          <AddIcon className={classes.addIcon} />
          Add webhook
        </Button>
      </Grid>
    </StyledGrid>
  );
}
export default AddWebhookButton;
