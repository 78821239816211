import {Grid, Tooltip} from '@mui/material';
import {styled} from '@mui/material/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import IconButton from 'components/CAM/buttons/IconButton/IconButton';
import DateRangeSelect from 'components/CAM/inputs/DateRangeSelect/DateRangeSelect';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import config from 'config';
import {saveVariable} from 'redux/actions/dataActions';
import {
  IN_SESSION,
  MACHINE_SESSION_ATTEMPTS,
  MONITOR_IN_SESSION,
  MONITOR_TELEMETRY_LATEST,
  VIEW_AD_USER_LINK,
} from 'utils/constants';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {
  convertSecondsToDisplay,
  formatDateTime,
  isEmpty,
  isLatestMonitorTelemetryValid,
} from 'utils/utils';
import MiniTable from '../../miniTable/MiniTable';
import MonitorLogoutDialog from '../LogoutDialogContent';

const PREFIX = 'WorkstationSessionCard';

const classes = {
  cardTitle: `${PREFIX}-cardTitle`,
  tableDelay: `${PREFIX}-tableDelay`,
  infoLine: `${PREFIX}-infoLine`,
  icon: `${PREFIX}-icon`,
  link: `${PREFIX}-link`,
  sessionTrackingCard: `${PREFIX}-sessionTrackingCard`,
  success: `${PREFIX}-success`,
  warning: `${PREFIX}-warning`,
  tokenSubtext: `${PREFIX}-tokenSubtext`,
  actions: `${PREFIX}-actions`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.cardTitle}`]: {
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '1.25px',
    lineHeight: '16px',
  },

  [`& .${classes.tableDelay}`]: {
    paddingLeft: '20px',
    paddingRight: '5px',
  },

  [`& .${classes.infoLine}`]: {
    marginTop: '12px',
    marginBottom: '2px',
  },

  [`& .${classes.icon}`]: {
    verticalAlign: '-4px',
    paddingRight: '5px',
  },

  [`& .${classes.link}`]: {
    color: '#0076a9',
    cursor: 'pointer',
    textDecoration: 'none',
  },

  [`& .${classes.sessionTrackingCard}`]: {
    height: '100px',
    width: '320px',
  },

  [`& .${classes.success}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.warning}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.tokenSubtext}`]: {
    color: theme.palette.info.main,
    fontFamily: 'Roboto',
    fontSize: '0.625rem',
    lineHeight: '11px',
    marginTop: '5px',
  },

  [`& .${classes.actions}`]: {
    display: 'flex',
  },
}));

const sessionHeaders = [
  {id: 'userName', value: 'User Name', type: 'link'},
  {id: 'success', value: 'Status', type: 'success'},
  {id: 'pcoipSessionId', value: 'Session ID'},
  {id: 'sessionAttemptedOn', value: 'Attempt Time', type: 'date'},
];

const monitorSessionHeaders = [
  {id: 'userName', value: 'User Name'},
  {id: 'status', value: 'Status'},
  {id: 'actions', value: 'Actions'},
];

function WorkstationSessionCard({
  loadingSessionAttempts,
  loadingSessionStatus,
  loadingUsers,
  sessionAttempts,
  sessionStatus,
  users,
  setDateRange,
  isTelemetryEnabled,
  selectedWorkstation,
  latestMonitorTelemetry,
  loadingMonitorTelemetry,
}) {
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const [isMonitorLogoutDialogOpen, setIsMonitorLogoutDialogOpen] =
    useState(false);
  const [userInSession, setUserInSession] = useState([]);

  const statsAvailable = () => !loadingSessionStatus && !isEmpty(sessionStatus);
  const isWorkstationInSession = () =>
    !isEmpty(sessionStatus) && sessionStatus.pcoipSessionState === IN_SESSION;

  const openLogoutDialog = (userName) => {
    setUserInSession(userName);
    setIsMonitorLogoutDialogOpen(true);
  };

  const canLogoutWorkstation = (user) =>
    config.isMonitorEnabled() &&
    isLatestMonitorTelemetryValid(latestMonitorTelemetry) &&
    user.status === MONITOR_IN_SESSION;

  const getUserInfo = (userGuid) => {
    if (loadingUsers) {
      return 'Loading...';
    }
    if (!userGuid || isEmpty(users)) {
      return 'Unknown';
    }

    const user = users.find((currentUser) => currentUser.userGuid === userGuid);
    if (user) {
      const userPageLink = `${VIEW_AD_USER_LINK}/${user.userGuid}/${deploymentId}`;
      return {
        name: user.name || user.userName,
        pageLink: userPageLink,
      };
    }
    return {name: 'Unknown', pageLink: null};
  };

  const getCurrentUserLink = () => {
    const userInfo = getUserInfo(sessionStatus.userGuid);
    return userInfo.pageLink ? (
      <Link to={userInfo.pageLink} className={classes.link}>
        {userInfo.name}
      </Link>
    ) : (
      userInfo.name
    );
  };

  const renderSessionState = () => (
    <Grid container direction="row" spacing={3}>
      <Grid item>Session State:</Grid>
      <Grid item>{sessionStatus.pcoipSessionState}</Grid>
    </Grid>
  );

  const renderSessionTime = () =>
    isWorkstationInSession() && (
      <Grid container direction="row" spacing={3}>
        <Grid item>Session Length:</Grid>
        <Grid item>
          {convertSecondsToDisplay(sessionStatus.clientSessionTime)}
        </Grid>
      </Grid>
    );

  const renderCurrentUser = () =>
    isWorkstationInSession() && (
      <Grid container direction="row" spacing={3}>
        <Grid item>Current User:</Grid>
        <Grid item>{getCurrentUserLink()}</Grid>
      </Grid>
    );

  const formatData = (session) => {
    const userInfo = getUserInfo(session.userGuid);
    return {
      ...session,
      userName: userInfo.name,
      link: userInfo.pageLink,
      success: session.clientSessionTime > 0,
    };
  };

  const prepareData = (sessions = []) =>
    sessions.map((session) => formatData(session));

  const renderTable = () => (
    <MiniTable
      resource={MACHINE_SESSION_ATTEMPTS}
      headers={sessionHeaders}
      data={prepareData(sessionAttempts)}
      isLoading={loadingSessionAttempts || loadingUsers}
      noDataMessage="No session attempts found for this workstation"
    />
  );

  const renderActions = (userName, disabled) => (
    <div className={classes.actions}>
      <Tooltip placement="left" title="Logout">
        <div>
          <IconButton
            icon={ExitToAppIcon}
            onClick={() => openLogoutDialog(userName)}
            disabled={disabled}
            data-testid={`logout-user-button-${userName}`}
            size="large"
          />
        </div>
      </Tooltip>
    </div>
  );

  const renderUserSessionStatus = (userStatus) => (
    <span
      className={
        userStatus === MONITOR_IN_SESSION ? classes.success : classes.warning
      }
    >
      {userStatus}
    </span>
  );

  const prepareMonitorData = (loggedInUsers = []) =>
    loggedInUsers.map((user) => ({
      id: `${user.userName}-row`,
      userName: user.userName,
      status: renderUserSessionStatus(user.status),
      actions: renderActions(user.userName, !canLogoutWorkstation(user)),
    }));

  const renderMonitorTable = () => (
    <MiniTable
      resource={MONITOR_TELEMETRY_LATEST}
      headers={monitorSessionHeaders}
      data={prepareMonitorData(latestMonitorTelemetry.loggedInUsers)}
      isLoading={loadingMonitorTelemetry}
      noDataMessage="No active users found for this workstation"
    />
  );

  const renderSessionInfo = () => {
    // Monitor telemetry takes priority
    if (
      config.isMonitorEnabled() &&
      selectedWorkstation.agentMonitored &&
      isLatestMonitorTelemetryValid(latestMonitorTelemetry)
    ) {
      return (
        <div>
          {renderMonitorTable()}
          <div className={classes.tokenSubtext}>
            Updated at{' '}
            {formatDateTime(latestMonitorTelemetry.msgReceivedOn, true)}
          </div>
        </div>
      );
    }
    if (isTelemetryEnabled && statsAvailable()) {
      return (
        <>
          {renderSessionState()}
          {renderSessionTime()}
          {renderCurrentUser()}
        </>
      );
    }
    return 'Session information not available.';
  };

  const handleCloseMonitorLogoutDialog = () => {
    setIsMonitorLogoutDialogOpen(false);
  };

  return (
    <Root>
      {config.isMonitorEnabled() && (
        <MonitorLogoutDialog
          isOpen={isMonitorLogoutDialogOpen}
          username={userInSession}
          workstations={[selectedWorkstation]}
          onClose={handleCloseMonitorLogoutDialog}
        />
      )}
      <CAMCard>
        <GridN singleColumn>
          <SectionHeader displayText="SESSION STATUS" />
          {renderSessionInfo()}
        </GridN>
      </CAMCard>
      {isTelemetryEnabled && config.isBeta() && (
        <CAMCard>
          <GridN singleColumn>
            <SectionHeader displayText="PCoIP SESSION ATTEMPTS" />
            <GridN singleColumn>
              <DateRangeSelect setDateRange={setDateRange} />
              {renderTable()}
            </GridN>
          </GridN>
        </CAMCard>
      )}
    </Root>
  );
}

WorkstationSessionCard.propTypes = {
  loadingSessionAttempts: PropTypes.bool,
  loadingSessionStatus: PropTypes.bool,
  loadingUsers: PropTypes.bool,
  sessionAttempts: PropTypes.array,
  sessionStatus: PropTypes.object,
  users: PropTypes.array,
  setDateRange: PropTypes.func.isRequired,
  isTelemetryEnabled: PropTypes.bool,
  selectedWorkstation: PropTypes.object.isRequired,
  latestMonitorTelemetry: PropTypes.object,
  loadingMonitorTelemetry: PropTypes.bool,
};

WorkstationSessionCard.defaultProps = {
  loadingSessionAttempts: true,
  loadingSessionStatus: true,
  loadingUsers: true,
  sessionAttempts: [],
  sessionStatus: {},
  users: [],
  isTelemetryEnabled: false,
  loadingMonitorTelemetry: true,
  latestMonitorTelemetry: {},
};

export default WorkstationSessionCard;
