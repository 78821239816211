import {CircularProgress, Grid, TableCell as MuiTableCell} from '@mui/material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import CopyToClipboard from 'components/CAM/buttons/CopyToClipboard/CopyToClipboard';
import Tag from 'components/CAM/display/Tag/Tag';
import {TABLE_CELL_LINK, TABLE_CELL_TEXT} from 'utils/constants';

const PREFIX = 'TableCell';

const classes = {
  tableLink: `${PREFIX}-tableLink`
};

const StyledMuiTableCell = styled(MuiTableCell)(() => ({
  [`& .${classes.tableLink}`]: {
    color: '#0076a9',
    cursor: 'pointer',
    textDecoration: 'none',
  }
}));

const notCopiedMessage = 'Click to copy to clipboard';
const copiedMessage = 'Copied to clipboard';

function TableCell({
  displayText,
  dataTestId,
  row,
  type,
  styling,
  copyable,
  onClick,
  deleting,
  selected,
}) {

  const [copied, setCopied] = useState(false);

  const handleCopy = (_, success) => {
    if (success) {
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    }
  };

  const renderCellText = () => displayText;

  const renderCellLink = () => {
    const {link} = row;
    if (link) {
      return (
        <Link to={link} className={classes.tableLink}>
          {displayText}
        </Link>
      );
    }

    return (
      <Grid
        spacing={1}
        container
        justifyContent="flex-start"
        alignItems="center"
      >
        {deleting && (
          <Grid item>
            <CircularProgress size="1.1rem" />
          </Grid>
        )}
        <Grid item>
          <span className={classes.tableLink}>{displayText}</span>
        </Grid>
        {!deleting && selected && (
          <Grid item>
            <Tag color="info" displayText="CURRENT" small />
          </Grid>
        )}
        {deleting && (
          <Grid item>
            <Tag color="error" displayText="DELETING" small />
          </Grid>
        )}
      </Grid>
    );
  };

  const cellTypeRenderers = {
    [TABLE_CELL_TEXT]: renderCellText,
    [TABLE_CELL_LINK]: renderCellLink,
  };

  const renderCellContent = () => {
    let renderCellType = cellTypeRenderers[type];
    if (!renderCellType) {
      renderCellType = renderCellText;
    }

    const cellContent = renderCellType();

    if (copyable) {
      return (
        <CopyToClipboard
          text={cellContent}
          onCopy={handleCopy}
          tooltipProps={{
            text: copied ? copiedMessage : notCopiedMessage,
            placement: 'right',
          }}
          showAsText
        />
      );
    }

    return cellContent;
  };

  return (
    <StyledMuiTableCell
      data-testid={dataTestId}
      classes={{
        root: classNames({
          [styling.muiTableCellRoot]: Boolean(styling.muiTableCellRoot),
        }),
      }}
      onClick={onClick}
    >
      {renderCellContent()}
    </StyledMuiTableCell>
  );
}

TableCell.propTypes = {
  displayText: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  type: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  styling: PropTypes.object,
  copyable: PropTypes.bool,
  onClick: PropTypes.func,
  deleting: PropTypes.bool,
  selected: PropTypes.bool,
};

TableCell.defaultProps = {
  dataTestId: '',
  styling: {},
  copyable: false,
  onClick: () => {},
  deleting: false,
  selected: false,
};

export default TableCell;
