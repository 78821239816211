import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import {push} from 'redux/ReduxHistory';

function AdUsersActions({item: user}) {
  const dispatch = useDispatch();

  const handleViewUser = () => {
    const url = `/app/adUsers/view/${user.userGuid}/${user.deploymentId}`;
    dispatch(push(url));
  };

  return (
    <KebabMenuItem
      id={`more-info-ad-user-${user.userGuid}`}
      menuText="View user"
      onClick={handleViewUser}
    />
  );
}

AdUsersActions.propTypes = {
  item: PropTypes.object,
};

AdUsersActions.defaultProps = {
  item: {},
};

export default AdUsersActions;
