import {UPDATE_SELECTED_DEPLOYMENT_ID} from 'redux/actions/selectedDeploymentIdActions';

// eslint-disable-next-line default-param-last
const selectedDeploymentIdReducer = (state = null, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_DEPLOYMENT_ID:
      return action.payload !== undefined ? action.payload : state;
    default:
      return state;
  }
};

export default selectedDeploymentIdReducer;
