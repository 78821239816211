import {getEditRwLink} from 'helpers/core';
import {
  AZURE_WORKSTATION_POWERSTATE_DEALLOCATED,
  GCP_PROVISIONING_STATE_FAILED,
  GCP_PROVISIONING_STATE_SUCCEEDED,
  POOL_MACHINES,
  REMOTE_WORKSTATIONS,
  WORKSTATION_POWERSTATE_RUNNING,
  WORKSTATION_POWERSTATE_STOPPED,
} from 'utils/constants';
import CasmCookies from 'utils/cookies';

export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';

export function pushNotification(notification) {
  const {oid} = CasmCookies.getDecodedAuthToken();
  return {
    type: PUSH_NOTIFICATION,
    notification: {...notification, oid},
  };
}

export const READ_NOTIFICATION = 'READ_NOTIFICATION';

export function readNotification(key) {
  return {
    type: READ_NOTIFICATION,
    key,
  };
}

export const READ_NOTIFICATION_ALL = 'READ_NOTIFICATION_ALL';

export function readNotificationAll() {
  return {
    type: READ_NOTIFICATION_ALL,
  };
}

export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export function removeNotification(key) {
  return {
    type: REMOVE_NOTIFICATION,
    key,
  };
}

function machinePowerStateNotification(machine) {
  let notification = {};
  switch (machine.powerState) {
    case AZURE_WORKSTATION_POWERSTATE_DEALLOCATED:
      notification = {
        title: `${machine.machineName} has been deallocated.`,
        moreInfo: '',
      };
      break;
    case WORKSTATION_POWERSTATE_RUNNING:
      notification = {
        title: `${machine.machineName} is running`,
        moreInfo: '',
      };
      break;
    case WORKSTATION_POWERSTATE_STOPPED:
      notification = {
        title: `${machine.machineName} has stopped`,
        moreInfo: '',
      };
      break;
    default:
      break;
  }

  return pushNotification({
    type: 'Remote Workstations',
    timePosted: Date.now(),
    actionText: 'Mark as read',
    link: getEditRwLink(machine.machineId),
    ...notification,
  });
}

function machineProvisioningStatusNotification(machine) {
  let notification = {};
  switch (machine.provisioningStatus.state) {
    case GCP_PROVISIONING_STATE_SUCCEEDED:
      notification = {
        title: `${machine.machineName} has been created`,
        moreInfo: '',
      };
      break;
    case GCP_PROVISIONING_STATE_FAILED:
      notification = {
        title: `Provisioning ${machine.machineName} has failed`,
        moreInfo: machine.provisioningStatus.message,
      };
      break;
    default:
      break;
  }

  return pushNotification({
    type: 'Remote Workstations',
    timePosted: Date.now(),
    actionText: 'Mark as read',
    link: getEditRwLink(machine.machineId),
    ...notification,
  });
}

export function updateNotification(resourceType, resource, property) {
  switch (`${resourceType}-${property}`) {
    case `${POOL_MACHINES}-powerState`:
    case `${REMOTE_WORKSTATIONS}-powerState`:
      return machinePowerStateNotification(resource);
    case `${REMOTE_WORKSTATIONS}-provisioningStatus.state`:
      return machineProvisioningStatusNotification(resource);
    default:
      throw new Error('notification type not yet implemented');
  }
}
