import {FormControlLabel, List, Radio, RadioGroup} from '@mui/material';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import Tune from '@mui/icons-material/Tune';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import config from 'config';
import {saveVariable} from 'redux/actions/dataActions';
import {toggleDateFilter} from 'redux/actions/dateFilterActions';
import {toggleDeploymentFilter} from 'redux/actions/deploymentFilterActions';
import {
  ACTIVITY_LOGS,
  AD_USERS,
  AD_USERS_ENTITLEMENT_FILTER_OPTIONS,
  REMOTE_WORKSTATIONS,
  REMOTE_WORKSTATIONS_AWM_MONITOR_STATUS_FILTER_OPTIONS,
  REMOTE_WORKSTATIONS_ENTITLEMENT_FILTER_OPTIONS,
  SESSION_AUDIT,
} from 'utils/constants';
import {selectVariable} from 'utils/reduxSelectors';

const PREFIX = 'ConfigureTable';

const classes = {
  filterMenuOption: `${PREFIX}-filterMenuOption`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.filterMenuOption}`]: {
    minWidth: '30px',
  }
}));

function ConfigureTable({resource}) {

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {enabled: deploymentFilterEnabled} = useSelector(
    (state) => state.deploymentFilter
  ) || {enabled: true};
  const {enabled: dateFilterEnabled} = useSelector(
    (state) => state.dateFilter
  ) || {enabled: false};

  const adUsersTableFilter = useSelector((state) =>
    selectVariable(state, 'adUsersTableFilter')
  ) || {
    entitlementsFilter: {enabled: false, filterType: ''},
    showDisabledUsers: false,
  };
  const remoteWorkstationsTableFilter = useSelector((state) =>
    selectVariable(state, 'remoteWorkstationsTableFilter')
  ) || {
    entitlementsFilter: {enabled: false, filterType: ''},
    awmMonitorStatusFilter: {enabled: false, filterType: ''},
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleDeploymentFilter = () => {
    dispatch(toggleDeploymentFilter());
  };

  const handleToggleDateFilter = () => {
    dispatch(toggleDateFilter());
  };

  const resetAdUsersTableFilter = () => {
    dispatch(
      saveVariable('adUsersTableFilter', {
        entitlementsFilter: {enabled: false, filterType: ''},
        showDisabledUsers: false,
      })
    );
  };

  const handleSelectAdUserEntitlementResourceRadio = (event) => {
    dispatch(
      saveVariable('adUsersTableFilter', {
        ...adUsersTableFilter,
        entitlementsFilter: {
          ...adUsersTableFilter.entitlementsFilter,
          filterType: event.target.value,
        },
      })
    );
  };

  const resetRemoteWorkstationEntitlementsFilter = () => {
    dispatch(
      saveVariable('remoteWorkstationsTableFilter', {
        entitlementsFilter: {enabled: false, filterType: ''},
        awmMonitorStatusFilter: {enabled: false, filterType: ''},
      })
    );
  };

  const handleSelectRemoteWorkstationEntitlementResourceRadio = (event) => {
    dispatch(
      saveVariable('remoteWorkstationsTableFilter', {
        ...remoteWorkstationsTableFilter,
        entitlementsFilter: {
          ...remoteWorkstationsTableFilter.entitlementsFilter,
          filterType: event.target.value,
        },
      })
    );
  };

  const handleSelectRemoteWorkstationAwmMonitorStatusResourceRadio = (
    event
  ) => {
    dispatch(
      saveVariable('remoteWorkstationsTableFilter', {
        ...remoteWorkstationsTableFilter,
        awmMonitorStatusFilter: {
          ...remoteWorkstationsTableFilter.awmMonitorStatusFilter,
          filterType: event.target.value,
        },
      })
    );
  };

  const radioOptionsAdUsersWithEntitlements = (
    <RadioGroup
      data-testid="entitlement-resource"
      name="entitlement-resource"
      value={adUsersTableFilter.entitlementsFilter.filterType}
      onChange={handleSelectAdUserEntitlementResourceRadio}
    >
      {AD_USERS_ENTITLEMENT_FILTER_OPTIONS.map((opt) => (
        <FormControlLabel
          value={opt}
          label={opt}
          key={opt}
          control={
            <Radio
              color="primary"
              size="small"
              data-testid={`radio-${opt.toLowerCase().replace(/ /g, '-')}`}
            />
          }
        />
      ))}
    </RadioGroup>
  );

  const radioOptionsRemoteWorkstationsWithEntitlements = (
    <RadioGroup
      data-testid="entitlement-resource-rw"
      name="entitlement-resource-rw"
      value={remoteWorkstationsTableFilter.entitlementsFilter.filterType}
      onChange={handleSelectRemoteWorkstationEntitlementResourceRadio}
    >
      {REMOTE_WORKSTATIONS_ENTITLEMENT_FILTER_OPTIONS.map((opt) => (
        <FormControlLabel
          value={opt}
          label={opt}
          key={opt}
          control={
            <Radio
              color="primary"
              size="small"
              data-testid={`radio-${opt.toLowerCase().replace(/ /g, '-')}`}
            />
          }
        />
      ))}
    </RadioGroup>
  );

  const radioOptionsRemoteWorkstationsWithAwmMonitorStatus = (
    <RadioGroup
      data-testid="awm-monitor-status"
      name="awm-monitor-status"
      value={remoteWorkstationsTableFilter.awmMonitorStatusFilter.filterType}
      onChange={handleSelectRemoteWorkstationAwmMonitorStatusResourceRadio}
    >
      {REMOTE_WORKSTATIONS_AWM_MONITOR_STATUS_FILTER_OPTIONS.map((opt) => (
        <FormControlLabel
          value={opt}
          label={opt}
          key={opt}
          control={
            <Radio
              color="primary"
              size="small"
              data-testid={`radio-${opt.toLowerCase().replace(/ /g, '-')}`}
            />
          }
        />
      ))}
    </RadioGroup>
  );

  const onAdUsersFilterByEntitlementCheck = () => {
    dispatch(
      saveVariable('adUsersTableFilter', {
        ...adUsersTableFilter,
        entitlementsFilter: {
          ...adUsersTableFilter.entitlementsFilter,
          enabled: !adUsersTableFilter.entitlementsFilter.enabled,
        },
      })
    );
  };

  const onAdUsersShowDisabledUsersCheck = () => {
    dispatch(
      saveVariable('adUsersTableFilter', {
        ...adUsersTableFilter,
        showDisabledUsers: !adUsersTableFilter.showDisabledUsers,
      })
    );
  };

  const onRemoteWorkstationsEntitlementsFilterCheck = () => {
    dispatch(
      saveVariable('remoteWorkstationsTableFilter', {
        ...remoteWorkstationsTableFilter,
        entitlementsFilter: {
          ...remoteWorkstationsTableFilter.entitlementsFilter,
          enabled: !remoteWorkstationsTableFilter.entitlementsFilter.enabled,
        },
      })
    );
  };

  const onRemoteWorkstationsAwmMonitorStatusFilterCheck = () => {
    dispatch(
      saveVariable('remoteWorkstationsTableFilter', {
        ...remoteWorkstationsTableFilter,
        awmMonitorStatusFilter: {
          ...remoteWorkstationsTableFilter.awmMonitorStatusFilter,
          enabled:
            !remoteWorkstationsTableFilter.awmMonitorStatusFilter.enabled,
        },
      })
    );
  };

  const checkBoxOptions = [
    {
      id: 'enable-deployment-filter',
      label: 'Enable deployment filter',
      onCheck: handleToggleDeploymentFilter,
      checked: deploymentFilterEnabled,
      // The resources property defines on which tables the given option will be displayed
      resources: [ACTIVITY_LOGS],
    },
    {
      id: 'enable-date-filter',
      label: 'Enable date filter',
      onCheck: handleToggleDateFilter,
      checked: dateFilterEnabled,
      // The resources property defines on which tables the given option will be displayed
      resources: [ACTIVITY_LOGS, SESSION_AUDIT],
    },
    {
      id: 'with-entitlements-filter',
      label: 'Filter by entitlement status',
      onCheck: onAdUsersFilterByEntitlementCheck,
      checked: adUsersTableFilter.entitlementsFilter.enabled,
      children: radioOptionsAdUsersWithEntitlements,
      resources: [AD_USERS],
    },
    {
      id: 'show-disabled-users-filter',
      label: 'Show disabled users',
      onCheck: onAdUsersShowDisabledUsersCheck,
      checked: adUsersTableFilter.showDisabledUsers,
      resources: [AD_USERS],
    },
    {
      id: 'with-entitlements-filter-rw',
      label: 'Filter by entitlement status',
      onCheck: onRemoteWorkstationsEntitlementsFilterCheck,
      checked: remoteWorkstationsTableFilter.entitlementsFilter.enabled,
      children: radioOptionsRemoteWorkstationsWithEntitlements,
      resources: [REMOTE_WORKSTATIONS],
    },
  ];

  if (config.isMonitorEnabled()) {
    checkBoxOptions.push({
      id: 'anyware-monitor-status-filter',
      label: 'Filter by Monitor status',
      onCheck: onRemoteWorkstationsAwmMonitorStatusFilterCheck,
      checked: remoteWorkstationsTableFilter.awmMonitorStatusFilter.enabled,
      children: radioOptionsRemoteWorkstationsWithAwmMonitorStatus,
      resources: [REMOTE_WORKSTATIONS],
    });
  }

  const renderCheckboxOptions = () => {
    const options = checkBoxOptions.filter(({resources}) =>
      resources.includes(resource)
    );
    return options.map((opt) => {
      const {id, label, onCheck, checked, children} = opt;
      // eslint-disable-next-line consistent-return
      const getSubOptions = (isChecked, theChildren) => {
        if (isChecked && theChildren) {
          return (
            <ListItem key={`filter-sub-options-${id}`}>{theChildren}</ListItem>
          );
        }
      };

      return [
        <ListItem key={id}>
          <ListItemIcon className={classes.filterMenuOption}>
            <Checkbox
              data-testid={id}
              edge="start"
              tabIndex={-1}
              disableRipple
              checked={checked}
              onChange={onCheck}
              color="primary"
            />
          </ListItemIcon>
          <ListItemText primary={label} />
        </ListItem>,
        getSubOptions(checked, children),
      ];
    });
  };

  useEffect(() => {
    switch (resource) {
      case AD_USERS:
        resetAdUsersTableFilter();
        break;
      case REMOTE_WORKSTATIONS:
        resetRemoteWorkstationEntitlementsFilter();
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (!adUsersTableFilter.entitlementsFilter.enabled) {
      dispatch(
        saveVariable('adUsersTableFilter', {
          ...adUsersTableFilter,
          entitlementsFilter: {
            ...adUsersTableFilter.entitlementsFilter,
            filterType: '',
          },
        })
      );
    }
  }, [adUsersTableFilter.entitlementsFilter.enabled]);

  useEffect(() => {
    if (!adUsersTableFilter.showDisabledUsers) {
      dispatch(
        saveVariable('adUsersTableFilter', {
          ...adUsersTableFilter,
          showDisabledUsers: false,
        })
      );
    }
  }, [adUsersTableFilter.showDisabledUsers]);

  useEffect(() => {
    if (!remoteWorkstationsTableFilter.entitlementsFilter.enabled) {
      dispatch(
        saveVariable('remoteWorkstationsTableFilter', {
          ...remoteWorkstationsTableFilter,
          entitlementsFilter: {
            ...remoteWorkstationsTableFilter.entitlementsFilter,
            filterType: '',
          },
        })
      );
    }
  }, [remoteWorkstationsTableFilter.entitlementsFilter.enabled]);

  useEffect(() => {
    if (!remoteWorkstationsTableFilter.awmMonitorStatusFilter.enabled) {
      dispatch(
        saveVariable('remoteWorkstationsTableFilter', {
          ...remoteWorkstationsTableFilter,
          awmMonitorStatusFilter: {
            ...remoteWorkstationsTableFilter.awmMonitorStatusFilter,
            filterType: '',
          },
        })
      );
    }
  }, [remoteWorkstationsTableFilter.awmMonitorStatusFilter.enabled]);

  return (
    <Root>
      <IconButton
        data-testid="configure-table"
        onClick={handleClick}
        color="primary"
        size="large">
        <Tune />
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        autoFocus={false}
        component={List}
      >
        {renderCheckboxOptions()}
      </Menu>
    </Root>
  );
}

ConfigureTable.propTypes = {
  resource: PropTypes.string,
};

ConfigureTable.defaultProps = {
  resource: ACTIVITY_LOGS,
};

export default ConfigureTable;
