import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import CodeEditor from '@uiw/react-textarea-code-editor';

const Root = styled('div')(() => ({
  width: '100%',
  height: '100%',
}));

const StyledCodeEditor = styled(CodeEditor)(() => ({
  width: '100%',
  height: '100%',
}));

function CodeTextArea(props) {
  const {language, colorMode, code, onChange, placeholder, ...otherProps} =
    props;

  const [value, setValue] = useState(code);
  const textRef = React.useRef();

  useEffect(() => {
    setValue(code);
  }, [code]);

  const handleCodeChange = (newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Root data-color-mode={colorMode}>
      <StyledCodeEditor
        value={value}
        ref={textRef}
        language={language}
        data-testid="code-text-area"
        placeholder={placeholder}
        onChange={(event) => handleCodeChange(event.target.value)}
        style={{
          fontFamily:
            'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
          fontSize: 12,
        }}
        {...otherProps}
      />
    </Root>
  );
}

CodeTextArea.propTypes = {
  language: PropTypes.string,
  colorMode: PropTypes.string,
  code: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

CodeTextArea.defaultProps = {
  language: 'json',
  colorMode: 'light',
  code: '',
  onChange: null,
  placeholder: '',
};

export default CodeTextArea;
