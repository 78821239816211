import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import config from 'config';
import AWMBanner from 'components/CAM/display/AWMBanner/AWMBanner';
import {
  EDIT_POOL_LINK,
  POOL_BANNER_DETAILS,
  POOL_BANNER_LINK,
  POOL_BANNER_MESSAGE,
} from 'utils/constants';
import usePool from 'hooks/usePool';
import {selectSelectedPool} from 'utils/reduxSelectors';

function PoolBanner() {
  const {poolId} = useSelector((state) => selectSelectedPool(state));
  const {pool} = usePool(poolId);
  const [isPoolBannerVisible, setIsPoolBannerVisible] = useState(false);
  const isPoolsPage = !!window.location.pathname.match(EDIT_POOL_LINK);
  useEffect(() => {
    if (Object.keys(pool).length > 0) {
      setIsPoolBannerVisible(true);
    }
  }, [pool]);
  if (
    !isPoolsPage ||
    !isPoolBannerVisible ||
    !config.isSessionHistoryUpdatedUiEnabled()
  )
    return null;
  return (
    <AWMBanner
      message={POOL_BANNER_MESSAGE}
      details={POOL_BANNER_DETAILS}
      variant="info"
      dataTestId="pool-banner"
      linkObject={{
        href: POOL_BANNER_LINK,
        label: 'Learn more',
      }}
      onClick={() => setIsPoolBannerVisible(false)}
    />
  );
}

export default PoolBanner;
