import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@mui/material';
import {stylesFromFigma} from '../../themes/stylesFromFigma';

const PREFIX = 'ConnectorNameField';

const classes = {
  textField: `${PREFIX}-textField`,
  disabled: `${PREFIX}-disabled`,
  labelText: `${PREFIX}-labelText`,
  helperText: `${PREFIX}-helperText`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.textField}`]: {
    marginTop: '8px',
    marginBottom: '8px',
    '&.Mui-disabled': {
      backgroundColor: '#F2F2F2',
    },
  },

  [`& .${classes.disabled}`]: {
    backgroundColor: '#E5F0FF',
  },

  [`& .${classes.labelText}`]: {
    ...stylesFromFigma.labelText,
  },

  [`& .${classes.helperText}`]: {
    ...stylesFromFigma.helperText,
  }
}));

const NAME_ERROR_DEFAULT_STATE = {error: false, reason: ''};
const NAME_TOO_LONG_ERROR = {
  error: true,
  reason: 'Connector name is longer than 64 characters, please shorten.',
};

function ConnectorNameField({connectorName, onNameValidate, error, disabled}) {


  const [nameError, setNameError] = useState(NAME_ERROR_DEFAULT_STATE);
  const HELPER_TEXT = nameError.reason || 'Enter a unique name.';

  const handleNameChange = (event) => {
    const newConnectorName = event.target.value;
    let result = NAME_ERROR_DEFAULT_STATE;

    if (newConnectorName && newConnectorName.length >= 64) {
      result = NAME_TOO_LONG_ERROR;
    }

    onNameValidate(newConnectorName, result);
    setNameError(result);
  };

  useEffect(() => {
    setNameError(error);
  }, [error]);

  return (
    (<Root>
      <Typography className={classes.labelText}>Connector name</Typography>
      <TextField
        hiddenLabel
        value={connectorName}
        className={classes.textField}
        onChange={handleNameChange}
        type="text"
        autoComplete="off"
        error={error || nameError.error}
        helperText={error || HELPER_TEXT}
        fullWidth
        disabled={disabled}
        InputLabelProps={{shrink: true}}
        FormHelperTextProps={{
          className: classes.helperText,
        }}
        InputProps={{
          classes: {
            disabled: classes.disabled,
          },
        }}
        variant="outlined"
        placeholder="Connector 1"
      />
    </Root>)
  );
}

ConnectorNameField.propTypes = {
  connectorName: PropTypes.string.isRequired,
  onNameValidate: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

ConnectorNameField.defaultProps = {
  onNameValidate: () => {},
  error: '',
  disabled: false,
};

export default ConnectorNameField;
