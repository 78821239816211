export const CHECK_ITEM = 'CHECK_ITEM';

export function checkItem(resource, item) {
  return {
    type: CHECK_ITEM,
    resource,
    item,
  };
}

export const UNCHECK_ITEM = 'UNCHECK_ITEM';

export function uncheckItem(resource, item) {
  return {
    type: UNCHECK_ITEM,
    resource,
    item,
  };
}

export const CHECK_ITEMS = 'CHECK_ITEMS';

export function checkItems(resource, items) {
  return {
    type: CHECK_ITEMS,
    resource,
    items,
  };
}

export const UNCHECK_ALL = 'UNCHECK_ALL';

export function uncheckAll(resource) {
  return {
    type: UNCHECK_ALL,
    resource,
  };
}
