import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';

const PREFIX = 'CAMDialog';

const classes = {
  muiDialogPaper: `${PREFIX}-muiDialogPaper`,
  muiDialogTitle: `${PREFIX}-muiDialogTitle`,
  closeIcon: `${PREFIX}-closeIcon`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.muiDialogPaper}`]: {
    overflowY: 'visible',
    display: 'grid',
    color: '#555967',
    fontSize: 12,
    fontWeight: '500',
    wordWrap: 'break-word',
  },
  [`& .${classes.muiDialogTitle}`]: {
    color: '#23242A',
    fontSize: 16,
    fontWeight: '700',
    wordWrap: 'break-word',
  },
  [`& .${classes.closeIcon}`]: {
    position: 'relative',
    padding: '0px',
    width: '20px',
    height: '20px',
    float: 'right',
  },
}));

function CAMDialog({
  open,
  title,
  content,
  onCancel,
  onOk,
  okDisabled,
  okLabel,
  cancelLabel,
}) {
  const handleClickCancel = () => {
    onCancel();
  };

  const handleClickOk = () => {
    onOk();
  };

  if (!open) {
    return null;
  }

  return (
    <StyledDialog
      open={open}
      fullWidth
      PaperProps={{className: classes.muiDialogPaper}}
    >
      {title && (
        <DialogTitle className={classes.muiDialogTitle}>
          {title}
          {onCancel && (
            <IconButton
              onClick={handleClickCancel}
              className={classes.closeIcon}
              size="large"
              data-testid="test-close-button"
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}

      <DialogContent className={classes.muiDialogPaper}>
        {content}
      </DialogContent>

      <DialogActions>
        {onCancel && (
          <CAMButton
            onClick={handleClickCancel}
            buttonText={cancelLabel}
            data-testId="dialog-cancel-button"
            variant={onOk ? 'outlined' : 'contained'}
          />
        )}
        {onOk && (
          <SaveButton
            onClick={handleClickOk}
            buttonText={okLabel}
            disabled={okDisabled}
            dataTestId="dialog-ok-button"
          />
        )}
      </DialogActions>
    </StyledDialog>
  );
}

CAMDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  okDisabled: PropTypes.bool,
  okLabel: PropTypes.string,
  title: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CAMDialog.defaultProps = {
  title: '',
  content: '',
  okDisabled: false,
  okLabel: 'Ok',
  cancelLabel: 'Cancel',
};

export default CAMDialog;
