import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {
  CONNECTOR_STATUS_HEALTHY,
  CONNECTOR_STATUS_OPTIMAL,
  CONNECTOR_STATUS_UNKNOWN,
  CONNECTOR_STATUS_WARNING,
  CONNECTOR_STATUS_CRITICAL,
  CONNECTOR_HEALTHY_STATUS,
  CONNECTOR_UNHEALTHY_STATUS,
  CONNECTOR_UNKNOWN_STATUS,
  CONNECTOR_STATUS_INSTALLATION_FAILED,
  CONNECTOR_STATUS_INSTALLATION_PENDING,
  CONNECTOR_STATUS_PENDING,
} from 'utils/constants';
import {openConnectorHealthDialog} from 'redux/actions/connectorHealthDialogActions';

import {
  isCertExpired,
  isCertAboutToExpire,
  connectorIsCACv2,
  healthUpdatesAreOld,
} from 'utils/utils';
import ArrowTooltip from 'components/common/tooltip/ArrowTooltip';
import StatusChip from 'components/CAM/display/StatusChip/StatusChip';

const PREFIX = 'ConnectorStatus';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));

/**
 * Checks the connector's components for certificate expiry information
 * @param {Components Object} components
 * @returns an object containing three arrays
 * {
 *      expiringCerts:[],
 *      expiredCerts:[],
 *      unknownCerts:[],
 *      validCerts:[]
 * }
 */
function getCertInfo(components) {
  const ret = {
    expiringCerts: [],
    expiredCerts: [],
    validCerts: [],
    unknownCerts: [],
  };
  if (!components || !components.dcCertExpiry) {
    return ret;
  }

  let {dcCertExpiry} = components;

  if (typeof dcCertExpiry === 'string') {
    dcCertExpiry = [
      {
        host: 'unknown',
        expiryDate: dcCertExpiry,
      },
    ];
  }

  if (Array.isArray(dcCertExpiry)) {
    for (const cert of dcCertExpiry) {
      const {expiry_date: expiryDate, host} = cert;
      if (!expiryDate) {
        continue;
      }
      if (expiryDate === 'unavailable') {
        ret.unknownCerts.push({host, expiryDate});
      } else if (isCertExpired(expiryDate)) {
        ret.expiredCerts.push({host, expiryDate});
      } else if (isCertAboutToExpire(expiryDate)) {
        ret.expiringCerts.push({host, expiryDate});
      } else {
        ret.validCerts.push({host, expiryDate});
      }
    }
  }
  return ret;
}

/**
 * Checks the critical components to determine the health status
 * @param {Connector criticalComponents Object} components
 * @returns one of CONNECTOR HEALTH STATUS constants
 */
function getConnectorHealthStatus(criticalComponents) {
  if (!criticalComponents || Object.keys(criticalComponents).length === 0) {
    return CONNECTOR_UNKNOWN_STATUS;
  }

  // It is called 'Anyware Broker' in AWC
  const isCACv2Connector = 'broker' in criticalComponents;

  // Having 'Security Gateway' is not enough, as if the gateway is disabled, it will still be included
  // but won't have a status field. Therefore check if it is included and has a status field
  const isSecurityGatewayIncluded =
    ('Security Gateway' in criticalComponents &&
      criticalComponents['Security Gateway'].status) ||
    'externalConnectionManager' in criticalComponents;

  const isFederatedAuthenticationEnabled =
    'Federated Authentication' in criticalComponents &&
    criticalComponents['Federated Authentication'].status;

  let componentNames = [];
  if (isCACv2Connector) {
    componentNames = [
      'broker',
      ...(isSecurityGatewayIncluded
        ? ['connectionManager', 'externalConnectionManager']
        : ['connectionManager']),
    ];
  } else {
    componentNames = [
      'Anyware Broker',
      'Connector Ingress',
      ...(isSecurityGatewayIncluded
        ? ['Security Gateway', 'Connection Manager with Security Gateway']
        : ['Connection Manager']),
      ...(isFederatedAuthenticationEnabled ? ['Federated Authentication'] : []),
    ];
  }

  const areAllCriticalComponentsHealthy = componentNames.every(
    (componentName) => criticalComponents[componentName]?.status === 'success'
  );

  return areAllCriticalComponentsHealthy
    ? CONNECTOR_HEALTHY_STATUS
    : CONNECTOR_UNHEALTHY_STATUS;
}

/**
 * Filters the components object to only use the critical ones
 * @param {Object} components
 * @returns {Object} containing only the critical health components
 */
function getCriticalComponents(components) {
  if (!components) {
    return {};
  }

  const criticalComponentNames = [
    // CACv2
    'activeDirectory',
    'connectionManager',
    'externalConnectionManager',
    'broker',
    'externalBroker',
    'gatewayPCoIP',

    // AWC
    'Connector Ingress',
    'Security Gateway',
    'Anyware Broker',
    'Connection Manager with Security Gateway',
    'Connection Manager',
  ];

  // if the Federated Authentication component has the property 'status', it means it is enabled and should be included as a critical component
  if (components['Federated Authentication']?.status) {
    criticalComponentNames.push('Federated Authentication');
  }

  const criticalComponents = {};
  const nonCriticalComponents = {};

  for (const name of criticalComponentNames) {
    if (components.hasOwnProperty(name)) {
      criticalComponents[name] = components[name];
    }
  }
  // If something's not critical, it's non-critical
  for (const [name, comp] of Object.entries(components)) {
    if (criticalComponentNames.includes(name) || !comp.status) {
      continue;
    }
    nonCriticalComponents[name] = comp;
  }

  return {criticalComponents, nonCriticalComponents};
}

function ConnectorStatus({connector}) {
  const [overallStatus, setOverallStatus] = useState(CONNECTOR_STATUS_UNKNOWN);
  const [tooltipText, setToolTipText] = useState('');
  const [criticalComponents, setCriticalComponents] = useState({});
  const [nonCriticalComponents, setNonCriticalComponents] = useState({});
  const [healthStatus, setHealthStatus] = useState(CONNECTOR_UNKNOWN_STATUS);

  const dispatch = useDispatch();

  const getStatusType = (status) => {
    switch (status) {
      case CONNECTOR_STATUS_OPTIMAL:
      case CONNECTOR_STATUS_HEALTHY:
        return 'ok';
      case CONNECTOR_STATUS_WARNING:
      case CONNECTOR_STATUS_INSTALLATION_PENDING:
        return 'warning';
      case CONNECTOR_STATUS_CRITICAL:
      case CONNECTOR_STATUS_INSTALLATION_FAILED:
        return 'error';
      default:
        return 'default';
    }
  };

  /**
   * Determines the overall connector status and tooltip info
   * @param {String} connectorHealthStatus
   * @param {Array} certInfo
   * @param {bool} enterpriseReadiness
   * @returns {status, tooltip}
   */
  function determineConnectorStatusAndTooltip(
    components,
    connectorHealthStatus,
    healthStatusUpdatedOn,
    certInfo,
    enterpriseReadiness,
    capabilities
  ) {
    // Connector status is unknown if every indicator is unknown
    if (
      !components ||
      Object.keys(components).length === 0 ||
      enterpriseReadiness == null
    ) {
      return {
        status: CONNECTOR_STATUS_UNKNOWN,
        tooltip: "Connector status isn't known.",
      };
    }

    if (healthUpdatesAreOld(healthStatusUpdatedOn)) {
      return {
        status: CONNECTOR_STATUS_UNKNOWN,
        tooltip:
          'Health status is unknown. The last health status update was more than 1 hour ago.',
      };
    }

    // If the health status is unhealthy, or a certificate has expired
    if (
      connectorHealthStatus === CONNECTOR_UNHEALTHY_STATUS ||
      certInfo.expiredCerts.length > 0
    ) {
      return {
        status: CONNECTOR_STATUS_CRITICAL,
        tooltip:
          'One or more critical components is unhealthy. Your ability to establish a PCoIP session may be impacted!\n',
      };
    }

    if (certInfo.expiringCerts.length > 0 || enterpriseReadiness === false) {
      let tooltip = '';
      tooltip +=
        'Your Connector is running in a sub-optimal state, and/or some certificates may be expiring soon.\n';
      const incompleteCaps = capabilities?.filter(
        (cap) => cap.enterpriseReadiness === false
      );
      if (incompleteCaps.length > 0) {
        tooltip += 'Incomplete capabilities:\n';
        incompleteCaps.forEach((cap) => {
          tooltip += `${cap.displayName} ` + '\n';
        });
      }
      return {status: CONNECTOR_STATUS_WARNING, tooltip};
    }

    if (connectorIsCACv2(connector?.components?.cacVersion)) {
      return {
        status: CONNECTOR_STATUS_HEALTHY,
        tooltip: 'Your Connector is healthy.',
      };
    }

    return {
      status: CONNECTOR_STATUS_OPTIMAL,
      tooltip: 'Your Connector is running optimally.',
    };
  }

  useEffect(() => {
    const {
      components,
      enterpriseReadiness,
      capabilities,
      healthStatusUpdatedOn,
    } = connector;

    if (connector.status === CONNECTOR_STATUS_PENDING) {
      setOverallStatus(CONNECTOR_STATUS_INSTALLATION_PENDING);
      setToolTipText('Connector installation is pending.');
      return;
    }

    const certInfo = getCertInfo(components);
    const {criticalComponents, nonCriticalComponents} = getCriticalComponents(components);
    const healthStatus = getConnectorHealthStatus(criticalComponents);
    const {status: overallStatus, tooltip} = determineConnectorStatusAndTooltip(
      criticalComponents,
      healthStatus,
      healthStatusUpdatedOn,
      certInfo,
      enterpriseReadiness,
      capabilities
    );

    setOverallStatus(overallStatus);
    setHealthStatus(healthStatus);
    setToolTipText(tooltip);
    setCriticalComponents(criticalComponents);
    setNonCriticalComponents(nonCriticalComponents);
  }, [JSON.stringify(connector)]);

  const openHealthDialog = () => {
    // TODO: Build a new component which displays the critical health status as well as the
    // capability status

    const con = {
      ...connector,
      components: criticalComponents,
      nonCriticalComponents,
      healthStatus,
    };
    dispatch(openConnectorHealthDialog(con));
  };

  return (
    <Root className={classes.root}>
      <Grid>
        <ArrowTooltip
          title={<span style={{whiteSpace: 'pre-line'}}>{tooltipText}</span>}
          placement="left"
        >
          <StatusChip
            data-testid={`${connector.connectorId}-connector-status-chip`}
            type={getStatusType(overallStatus)}
            label={overallStatus}
            onClick={() => openHealthDialog()}
          />
        </ArrowTooltip>
      </Grid>
    </Root>
  );
}

ConnectorStatus.propTypes = {
  connector: PropTypes.object.isRequired,
  tooltipDelay: PropTypes.number,
};

ConnectorStatus.defaultProps = {
  tooltipDelay: 0,
};

export default ConnectorStatus;

export {getConnectorHealthStatus, getCriticalComponents};
