import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useDebounce from 'hooks/useDebounce';
import {
  closeBulkActionsDialog,
  saveBulkActionsDialog,
  updateTableBulkActionsDialog,
} from 'redux/actions/bulkActionsDialogActions';
import {uncheckAll} from 'redux/actions/tableSelectActions';
import {AD_GROUPS, DEFAULT_ROWS_PER_PAGE} from 'utils/constants';
import {
  selectBulkActionsDialogProps,
  selectDataForBulkActions,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import CAMPagination from './Pagination';
import CAMSearchField from './SearchField';
import SlimTable from './SlimTable';

const PREFIX = 'BulkActionsDialog';

const classes = {
  actionsContainer: `${PREFIX}-actionsContainer`,
  bottomContainer: `${PREFIX}-bottomContainer`,
  buttonsContainer: `${PREFIX}-buttonsContainer`,
  contentContainer: `${PREFIX}-contentContainer`,
  dialogActions: `${PREFIX}-dialogActions`,
  horizontalRule: `${PREFIX}-horizontalRule`,
  paginationContainer: `${PREFIX}-paginationContainer`,
  root: `${PREFIX}-root`,
  tableContainer: `${PREFIX}-tableContainer`,
  title: `${PREFIX}-title`,
};

const StyledDialog = styled(Dialog)(({theme}) => ({
  [`& .${classes.actionsContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '1rem',
    width: '100%',
  },

  [`& .${classes.bottomContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  [`& .${classes.buttonsContainer}`]: {
    display: 'flex',
    flexWrap: 'nowrap',
  },

  [`& .${classes.contentContainer}`]: {
    paddingTop: 0,
    paddingBottom: 0,
  },

  [`& .${classes.dialogActions}`]: {
    paddingBottom: 0,
  },

  [`& .${classes.horizontalRule}`]: {
    color: theme.palette.surface.grey,
    margin: '0.5rem 0 1rem 0',
  },

  [`& .${classes.paginationContainer}`]: {
    width: '100%',
  },

  [`& .${classes.root}`]: {
    minWidth: '40vw',
    maxWidth: '90vw',
    padding: '1rem',
  },

  [`& .${classes.tableContainer}`]: {
    maxHeight: '45vh',
  },

  [`& .${classes.title}`]: {
    color: theme.palette.primary.main,
    paddingBottom: 0,
  },
}));

const OPTIONS_ROWS_PER_PAGE = [5, 15, 25];

const defaultParams = {
  page: 0,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  searchKey: '',
  deploymentId: '',
  sortAsc: true,
};

function BulkActionsDialog() {
  const dispatch = useDispatch();

  const [isClosing, setIsClosing] = useState(false);
  const [searchDisplayText, setSearchDisplayText] = useState('');
  const [searchDebounced, setSearchDebounced] = useDebounce(
    searchDisplayText,
    1500
  );

  const [params, setParams] = useState(defaultParams);

  const {open: isOpen, resource} = useSelector(
    (state) => state.bulkActionsDialog
  );
  const {existingItems, infoText, searchPlaceholder, tableFields, title} =
    useSelector((state) => selectBulkActionsDialogProps(state, resource));

  const {data, isFetching, total} = useSelector((state) =>
    selectDataForBulkActions(state, resource)
  );
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );

  const setDefaultState = () => {
    if (deploymentId) {
      defaultParams.deploymentId = deploymentId;
    }
    setSearchDisplayText('');
    setParams(defaultParams);
  };

  const handleSave = async () => {
    setIsClosing(true);
    setDefaultState();
    await dispatch(saveBulkActionsDialog(resource));
    dispatch(uncheckAll(resource));
    setIsClosing(false);
  };

  const handleClose = async () => {
    setIsClosing(true);
    setDefaultState();
    await dispatch(closeBulkActionsDialog(resource));
    dispatch(uncheckAll(resource));
    setIsClosing(false);
  };

  const handleSearchChange = (event) => {
    setSearchDisplayText(event.target.value || '');
  };

  const handleSearch = () => {
    setSearchDebounced(searchDisplayText);
    setParams({
      ...params,
      page: 0,
    });
  };
  const handleClear = () => {
    setSearchDisplayText('');
    setParams({
      ...params,
      page: 0,
    });
  };

  const handleChangeSorting = (header) => {
    if (header.id === params.sortKey) {
      // Clicked the current sorted by field, just revert order
      setParams({
        ...params,
        sortAsc: !params.sortAsc,
        page: 0,
        searchKey: searchDisplayText,
      });
    } else {
      setParams({
        ...params,
        sortKey: header.id,
        sortAsc: true,
        page: 0,
        searchKey: searchDisplayText,
      });
    }
  };

  const handleChangePage = (_, newPage) =>
    setParams({...params, page: newPage});

  const handleChangeRowsPerPage = (event) =>
    setParams({
      ...params,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });

  const prepareTableData = () => {
    const disabledIds = existingItems.map((item) => item.id).join(',');
    return data.map((row) => ({
      ...row,
      isDisabled: disabledIds.includes(row.id),
    }));
  };

  useEffect(() => {
    if (deploymentId) {
      setParams({
        ...params,
        deploymentId,
      });
    }
  }, [deploymentId]);

  useEffect(() => {
    if (resource && !isClosing) {
      const {
        deploymentId: deploymentIdParam,
        page,
        rowsPerPage,
        sortAsc,
        sortKey,
      } = params;

      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      const tableParams = {
        limit,
        offset,
        searchKey: searchDebounced,
        sortAsc,
        sortKey,
      };
      if (deploymentIdParam) {
        tableParams.deploymentId = deploymentIdParam;
      }

      dispatch(updateTableBulkActionsDialog({resource, tableParams}));
    }
  }, [resource, JSON.stringify(params), searchDebounced, isClosing]);

  useEffect(() => {
    if (resource) {
      dispatch(uncheckAll(resource));
    }
  }, [resource]);

  useEffect(() => {
    if (tableFields) {
      const firstSortableField = tableFields.find(
        (field) => field.sortable === true
      );
      if (firstSortableField) {
        setParams({
          ...params,
          sortKey: firstSortableField.id,
        });
      }
    }
  }, [JSON.stringify(tableFields)]);

  // until AD groups have their own backend DB object, we can't search
  // or paginate them unless we do it manually in the frontend
  const renderHeader = () => (
    <DialogTitle classes={{root: classes.title}}>
      {title || ''}
      <hr className={classes.horizontalRule} />
      {resource !== AD_GROUPS && (
        <CAMSearchField
          id={`${resource}-bulk-search-field`}
          value={searchDisplayText}
          placeholder={searchPlaceholder}
          onChange={handleSearchChange}
          onSearch={handleSearch}
          onClear={handleClear}
        />
      )}
    </DialogTitle>
  );

  // until AD groups have their own backend DB object, we can't search
  // or paginate them unless we do it manually in the frontend
  const renderPagination = () => (
    <div className={classes.paginationContainer}>
      {resource !== AD_GROUPS && (
        <CAMPagination
          page={params.page}
          rowsPerPage={params.rowsPerPage}
          rowsPerPageOptions={OPTIONS_ROWS_PER_PAGE}
          total={total}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          dataTestId={`${resource}-bulk-pagination`}
        />
      )}
    </div>
  );

  const renderFooter = () => (
    <DialogActions className={classes.dialogActions}>
      <div className={classes.bottomContainer}>
        {renderPagination()}
        <div className={classes.actionsContainer}>
          <div>{infoText || ''}</div>
          <div className={classes.buttonsContainer}>
            <Button onClick={handleClose} disabled={isClosing}>
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              disabled={isFetching || isClosing}
              data-testid="ba-dlg-save"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </DialogActions>
  );

  if (!isOpen) return null;

  return (
    <StyledDialog
      open={isOpen}
      onClose={handleClose}
      classes={{paper: classes.root}}
    >
      {renderHeader()}
      <DialogContent classes={{root: classes.contentContainer}}>
        <div className={classes.tableContainer}>
          <SlimTable
            isFetching={isFetching}
            data={prepareTableData(data)}
            resource={resource}
            fields={tableFields}
            onClear={handleClear}
            shouldRenderKebabs={false}
            handleChangeSorting={handleChangeSorting}
            sorting={{sortKey: params.sortKey, sortAsc: params.sortAsc}}
          />
        </div>
      </DialogContent>
      {renderFooter()}
    </StyledDialog>
  );
}

export default BulkActionsDialog;
