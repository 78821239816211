/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import classNames from 'classnames';
import PropTypes from 'prop-types';

function LoadingDots({className}) {
  return (
    <div className="cs-loader">
      <div className={classNames('cs-loader-inner', className)}>
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
      </div>
    </div>
  );
}

LoadingDots.propTypes = {
  className: PropTypes.string.isRequired,
};
export default LoadingDots;
