import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import {stylesFromFigma} from 'themes/stylesFromFigma';

const PREFIX = 'Note';

const classes = {
  noteBlock: `${PREFIX}-noteBlock`,
  noteTitle: `${PREFIX}-noteTitle`,
  container: `${PREFIX}-container`,
  icon: `${PREFIX}-icon`
};

const StyledBox = styled(Box)((
  {
    theme
  }
) => ({
  [`&.${classes.noteBlock}`]: {
    ...stylesFromFigma.noteBlockStye,
    margin: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    fontSize: '14px',
    color: '#23242B',
    display: 'block',
    whiteSpace: 'pre-wrap',
    borderRadius: '4px',
    flex: 1,
  },

  [`& .${classes.noteTitle}`]: {
    ...stylesFromFigma.typographyH6,
    margin: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    fontSize: '14px',
    fontWeight: '700',
    color: '#35373f',
    position: 'relative',
    left: '-14px',
  },

  [`& .${classes.container}`]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  [`& .${classes.icon}`]: {
    position: 'relative',
    left: '-9px',
    width: '14px',
    height: '14px',
  }
}));

function Note({note, title}) {

  return (
    <StyledBox component="div" bgcolor="#EDEDF2" className={classes.noteBlock}>
      <Container maxWidth={false} className={classes.container}>
        <Typography className={classes.noteTitle}>
          <InfoIcon className={classes.icon} />
          {title}
        </Typography>
        {note && <Typography className={classes.noteBlock}>{note}</Typography>}
      </Container>
    </StyledBox>
  );
}

Note.propTypes = {
  note: PropTypes.string,
  title: PropTypes.string,
};

Note.defaultProps = {
  note: '',
  title: 'Note',
};

export default Note;
