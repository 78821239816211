import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {openDeletePoolsDialog} from 'redux/actions/DeletePoolsDialogActions';

function PoolsActions({item: pool, closeMenu}) {
  const dispatch = useDispatch();

  const handleDeleteClick = () =>
    dispatch(openDeletePoolsDialog({pools: [pool]}));

  return (
    <KebabMenuItem
      id={`delete-pool-${pool.poolId}`}
      dataTestId={`delete-${pool.poolId}`}
      menuText="Delete"
      onClick={() => {
        closeMenu();
        handleDeleteClick();
      }}
    />
  );
}

PoolsActions.propTypes = {
  item: PropTypes.object,
  closeMenu: PropTypes.func,
};

PoolsActions.defaultProps = {
  item: {},
  closeMenu: () => {},
};

export default PoolsActions;
