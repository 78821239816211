import {useGoogleLogin} from '@react-oauth/google';
import {useDispatch} from 'react-redux';
import {post} from 'api/Api';
import googleLogo from 'icons/g_logo.svg';
import {saveVariable} from 'redux/actions/dataActions';
import {openErrorDialog} from 'redux/actions/errorDialogActions';
import {push} from 'redux/ReduxHistory';
import {
  allowListErrorLinkText,
  contactTechnicalSupportLink,
  googleAllowListError,
} from 'utils/constants';
import CasmCookies from 'utils/cookies';
import {DefaultLoginError, WhiteListLoginError} from 'utils/ErrorClasses';
import {setLastLoginDate} from 'utils/utils';
import LoginButton from './LoginButton';

function UnwrappedGoogleLoginButton() {
  const dispatch = useDispatch();

  const parseErrorResponse = (error) => {
    const code = error.code || 500;
    let reason;
    switch (code) {
      case 403:
        ({reason} = error.data);
        if (reason.includes('Personal google accounts are not allowed')) {
          return new WhiteListLoginError();
        }
        return new DefaultLoginError();
      default:
        return new DefaultLoginError();
    }
  };

  const handleErrorObject = (errorObject) => {
    if (errorObject instanceof WhiteListLoginError) {
      const errorText = (
        <>
          {googleAllowListError}
          <a
            href={contactTechnicalSupportLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            {allowListErrorLinkText}
          </a>
        </>
      );
      dispatch(openErrorDialog('Login Error', errorText));
    } else {
      dispatch(openErrorDialog('Login Error', errorObject.message));
    }
  };

  const handleLoginSuccess = async (googleResponse) => {
    dispatch(saveVariable('loginLoading', true));
    try {
      const response = await post({
        path: 'auth/googlesignin',
        headers: {
          authorization: googleResponse.access_token,
        },
      });
      CasmCookies.setAuthToken(response.data.token);
      setLastLoginDate();
      dispatch(push('/app'));
    } catch (error) {
      const errorObject = parseErrorResponse(error);
      handleErrorObject(errorObject);
    }
    dispatch(saveVariable('loginLoading', false));
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => handleLoginSuccess(tokenResponse),
    onError: (response) => console.log({errorResponse: response}),
  });

  return (
    <LoginButton
      idp="google"
      iconSrc={googleLogo}
      onClick={googleLogin}
      text="Sign in with Google"
    />
  );
}

export default UnwrappedGoogleLoginButton;
