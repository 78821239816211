import {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import {openErrorDialog} from 'redux/actions/errorDialogActions';
import {
  validatePEM,
  validateCertificate,
  verifyCertKeyPair,
  isEmpty,
} from 'utils/utils';
import {
  INGRESS_CERT_CAPABILITY_NAME,
  CERTIFICATES_EXTENSIONS,
} from 'utils/constants';
import CopyAwcCommand from '../createWizard/CopyAwcCommand';
import {
  checkRequiredParams,
  useWizardState,
  generateAwcCommand,
} from '../createWizard/wizardConfigState';
import {ingressCertParams} from '../createWizard/wizardConstants';
import FileInputDropbox from '../createWizard/FileDropBox';
import CapabilityStatusDisplay from './CapabilityStatus';

const PREFIX = 'CertificatesTab';

const classes = {
  root: `${PREFIX}-root`,
  padding: `${PREFIX}-padding`,
  flexRow: `${PREFIX}-flexRow`,
  fileRow: `${PREFIX}-fileRow`,
  propTitle: `${PREFIX}-propTitle`,
  statusIcon: `${PREFIX}-statusIcon`,
  statusChip: `${PREFIX}-statusChip`,
  label: `${PREFIX}-label`,
  bodyText: `${PREFIX}-bodyText`,
  subheader: `${PREFIX}-subheader`,
  subheader2: `${PREFIX}-subheader2`,
  form: `${PREFIX}-form`,
  textField: `${PREFIX}-textField`,
  subHeading: `${PREFIX}-subHeading`,
  errorText: `${PREFIX}-errorText`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    margin: 'auto',
    maxWidth: '1220px',
  },

  [`& .${classes.padding}`]: {
    padding: '16px',
  },

  [`& .${classes.flexRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10px 0px',
    '&:first-child': {
      marginTop: '0px',
    },
    padding: '16px',
  },

  [`& .${classes.fileRow}`]: {
    padding: '8px 0px',
  },

  [`& .${classes.propTitle}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '200px',
  },

  [`& .${classes.statusIcon}`]: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginRight: '16.5px',
  },

  [`& .${classes.statusChip}`]: {
    backgroundColor: '#EBF3FA',
    color: '#1E3BB8',
  },

  [`& .${classes.label}`]: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: 'rgba(0, 0, 0, 0.87)',
  },

  [`& .${classes.bodyText}`]: {
    ...stylesFromFigma.connectorPanelBodyText,
    padding: '16px',
  },

  [`& .${classes.subheader}`]: {
    ...stylesFromFigma.capabilitySubHeader,
    padding: '16px',
  },

  [`& .${classes.subheader2}`]: {
    ...stylesFromFigma.capabilitySubHeader2,
    padding: '16px',
  },

  [`& .${classes.form}`]: {marginTop: '12px'},

  [`& .${classes.textField}`]: {
    width: '100%',
    maxWidth: '1000px',
    margin: '6px 0px',
    padding: '16px',
  },

  [`& .${classes.subHeading}`]: {},
  [`& .${classes.errorText}`]: {color: 'red'},
}));

function CertificatesTab() {
  const dispatch = useDispatch();

  const [allowCommandCopy, setAllowCommandCopy] = useState(false);
  const [displayedError, setDisplayedError] = useState('');
  const [configureCommand, setConfigureCommand] = useState('');
  const {configState, setConfigState} = useWizardState();
  const [certName, setCertName] = useState('');
  const [keyName, setKeyName] = useState('');
  const selectedConnector = useSelector(
    (state) => state?.data?.dataByResource?.selectedConnector
  );

  const allFieldsFilled = checkRequiredParams(ingressCertParams, configState);

  const capabilityList = selectedConnector?.capabilities || [];
  const capabilityData = capabilityList.find(
    (cap) => cap.capabilityName === INGRESS_CERT_CAPABILITY_NAME
  );

  const createConfigureCommand = () => {
    const fullCommand = generateAwcCommand({
      configParamArray: ingressCertParams,
      configState,
    });
    setConfigureCommand(fullCommand);
  };

  const displayPemLoadErrorMessage = (errorMessage) => {
    const defaultErrorMessage = 'Invalid PEM file';
    dispatch(
      openErrorDialog(
        'File does not contain a valid certificate or private key',
        errorMessage || defaultErrorMessage
      )
    );
  };

  const handleCertString = (file, fileName) => {
    const updatedState = {...configState};
    updatedState.ingressCert = file;
    setConfigState(updatedState);
    setCertName(fileName);
  };

  const handleKeyString = (file, fileName) => {
    const updatedState = {...configState};
    updatedState.ingressKey = file;
    setConfigState(updatedState);
    setKeyName(fileName);
  };

  const handlePemFile = (file, fileName) => {
    // We know the PEM file is either a valid certificate or key
    // because it was validated as such during the upload
    // Check if its a certificate, if an error is returned, assume its a key
    const error = validateCertificate(file);
    if (error) {
      handleKeyString(file, fileName);
    } else {
      handleCertString(file, fileName);
    }
  };

  // Update configure command any time one of the config params change
  useEffect(
    () => {
      if (allFieldsFilled) {
        if (
          verifyCertKeyPair(configState.ingressCert, configState.ingressKey)
        ) {
          createConfigureCommand();
          setAllowCommandCopy(true);
          setDisplayedError('');
        } else {
          setAllowCommandCopy(false);
          setDisplayedError(
            "Certificate and private key do not match and can't be used by the Anyware Connector"
          );
        }
      } else {
        setAllowCommandCopy(false);
      }
    },
    ingressCertParams.map((param) => configState[param.fieldName])
  );

  return (
    <Root className={classes.root}>
      <div className={classes.padding}>
        <CapabilityStatusDisplay
          capabilityData={capabilityData}
          isLoading={isEmpty(selectedConnector)}
        />
      </div>
      <Typography className={classes.subheader}>Ingress Certificate</Typography>
      <Typography className={classes.bodyText}>
        To protect your authentication process with the Anyware Connector, use
        the Ingress Certificate. This certificate encrypts TLS communication
        during user authentication.
        <br />
        By default, the Anyware Connector uses a self-signed certificate
        generated during installation. However, in enterprise environments,
        it&lsquo;s recommended to replace it with a domain-signed certificate
        for better security.
      </Typography>

      <Typography className={classes.subheader2}>
        To configure the Ingress Certificate:
      </Typography>
      <Typography className={classes.bodyText}>
        <ol style={{padding: '0', listStylePosition: 'inside'}}>
          <li>
            Upload your certificate file and private key (in PEM format) using
            the provided form.
          </li>
          <li>
            We&lsquo;ll generate a configuration command for you to apply the
            changes on the Connector server.
          </li>
          <li>
            Rest assured, your files stay secure as they won&lsquo;t leave the
            browser or be stored in Anyware Manager.
          </li>
          <li>
            After running the configuration command, your Anyware Connector will
            save the data securely.
          </li>
        </ol>
      </Typography>

      <Typography className={classes.subheader2} variant="h5">
        Select your certificate file and private key (in PEM format)
      </Typography>

      <FileInputDropbox
        acceptedMimeType="application/x-pem-file"
        acceptedExtensions={CERTIFICATES_EXTENSIONS}
        fileResultCallback={handlePemFile}
        validateAcceptedFile={validatePEM}
        onAcceptedFileError={displayPemLoadErrorMessage}
      />
      <div>
        <Typography className={classes.bodyText}>
          <div className={classes.fileRow}>
            <ul>
              <li>Certificate file: {certName || 'not selected'}</li>
              <li>Key file: {keyName || 'not selected'}</li>
            </ul>
          </div>
        </Typography>
      </div>
      {displayedError && (
        <Typography className={classes.errorText}>{displayedError}</Typography>
      )}
      <CopyAwcCommand
        isCopyEnabled={allowCommandCopy}
        commandToCopy={configureCommand}
        desiredState={configState}
        isApplyEnabled
        commandType="configure"
        configParamArray={ingressCertParams}
      />
    </Root>
  );
}

export default CertificatesTab;
