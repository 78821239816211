import PropTypes from 'prop-types';
import CamSwitch from 'components/common/CamSwitch';
import {useDispatch} from 'react-redux';
import {ENROLLMENT_COMMANDS} from 'utils/constants';
import {put} from 'api/Api';
import {handleApiError} from 'redux/actions/dataActions';
import {fetchTableData} from 'redux/actions/tableDataActions';

function EnrollmentTLSToggle({enrollment}) {
  const dispatch = useDispatch();

  const onClick = async (enrollmentParam) => {
    try {
      await put({
        path: `auth/keys/messaging/enrollment/${enrollmentParam.enrollmentKeyId}`,
        data: {
          validateCertificate: !enrollmentParam.validateCertificate,
        },
      });
    } catch (error) {
      dispatch(handleApiError(error));
      return;
    }
    dispatch(fetchTableData(ENROLLMENT_COMMANDS));
  };

  return (
    <CamSwitch
      checked={enrollment.validateCertificate}
      onClick={() => {
        onClick(enrollment);
      }}
      data-testId="enrollment-tls-toggle"
    />
  );
}

EnrollmentTLSToggle.propTypes = {
  enrollment: PropTypes.object,
};

EnrollmentTLSToggle.defaultProps = {
  enrollment: {},
};

export default EnrollmentTLSToggle;
