import {Grid, TextField, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import CopyToClipboard from 'components/CAM/buttons/CopyToClipboard/CopyToClipboard';
import CAMTooltip from 'components/CAM/display/CAMTooltip/CAMTooltip';
import {getDomain} from 'utils/utils';

const PREFIX = 'SamlConfigInfo';

const classes = {
  input: `${PREFIX}-input`,
  inputSectionSubtitle: `${PREFIX}-inputSectionSubtitle`,
  idpConfigSubtitle: `${PREFIX}-idpConfigSubtitle`,
  idpConfigBodyText: `${PREFIX}-idpConfigBodyText`,
  gridItem: `${PREFIX}-gridItem`,
  copyIcon: `${PREFIX}-copyIcon`,
  titleRow: `${PREFIX}-titleRow`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  width: '100%',

  [`& .${classes.input}`]: {
    width: '90%',
  },

  [`& .${classes.inputSectionSubtitle}`]: {
    ...theme.createPage.inputSectionSubtitle,
    marginBottom: '1rem',
  },

  [`& .${classes.idpConfigSubtitle}`]: {
    ...theme.createPage.inputSectionSubtitle,
  },

  [`& .${classes.idpConfigBodyText}`]: {
    color: theme.palette.text.secondary,
    marginBottom: '0.5rem',
  },

  [`& .${classes.gridItem}`]: {
    marginRight: '3.1rem',
    marginBottom: '1rem',
  },

  [`& .${classes.copyIcon}`]: {
    display: 'inline-flex',
    paddingTop: '4px',
    '&:hover': {
      backgroundColor: 'inherit',
      color: '#35B4E1',
    },
  },

  [`& .${classes.titleRow}`]: {display: 'flex', flexDirection: 'row'},
}));

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  style: {maxHeight: '50vh'},
};

const selectProps = {
  MenuProps: menuProps,
  IconComponent: KeyboardArrowDown,
  displayEmpty: true,
};

const inputProps = {
  style: {
    fontFamily: 'Roboto',
    fontSize: '0.9rem',
    width: '100%',
    height: '32px',
  },
};

/**
 * Given configuration Id, return the corresponding AWM login url
 * @param {string} configurationId
 * @returns AWM login url
 */
const getAWMLoginUrl = (configurationId) => {
  const domain = getDomain();
  const method = domain.includes('localhost') ? 'http' : 'https';
  return `${method}://${domain}/?configurationId=${configurationId}`;
};

function SamlConfigInfo({
  userLoginUrl,
  callbackUrl,
  entityId,
  configurationId,
}) {
  const awmLoginUrl = getAWMLoginUrl(configurationId);

  return (
    <Root>
      <Grid container>
        <Grid item className={classes.gridItem} xs={12}>
          <Typography className={classes.inputSectionSubtitle}>
            SAML Login URLs
          </Typography>
          <Grid item xs={12}>
            <div className={classes.titleRow}>
              <div className={classes.label}>Anyware Manager login page</div>
              <CAMTooltip text="Webpage where admins can login via SAML" />
            </div>
            <TextField
              className={classes.input}
              disabled
              data-testid="cam-saml-user-login-url"
              placeholder="cam login URL"
              autoComplete="off"
              required
              margin="none"
              variant="outlined"
              SelectProps={selectProps}
              InputProps={{...inputProps, name: 'awmLoginUrl'}}
              value={awmLoginUrl}
            />
            <CopyToClipboard
              text={awmLoginUrl}
              buttonProps={{size: 'large', className: classes.copyIcon}}
              iconVariant="FileCopy"
            />
          </Grid>
        </Grid>

        <Grid item className={classes.gridItem} xs={12}>
          <Grid item xs={12}>
            <div className={classes.titleRow}>
              <div className={classes.label}>
                Direct login via identity provider
              </div>
              <CAMTooltip text="API endpoint which initializes a SAML login" />
            </div>
            <TextField
              className={classes.input}
              disabled
              data-testid="saml-user-login-url"
              placeholder="user login URL"
              autoComplete="off"
              required
              margin="none"
              variant="outlined"
              SelectProps={selectProps}
              InputProps={{...inputProps, name: 'userLoginUrl'}}
              value={userLoginUrl}
            />
            <CopyToClipboard
              text={userLoginUrl}
              buttonProps={{size: 'large', className: classes.copyIcon}}
              iconVariant="FileCopy"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item className={classes.gridItem} xs={12}>
          <Typography className={classes.idpConfigSubtitle}>
            Identity Provider Configuration
          </Typography>
          <Typography variant="body2" className={classes.idpConfigBodyText}>
            Configure your Identity Provider with the following settings
          </Typography>
          <div className={classes.titleRow}>
            <div className={classes.label}>
              Assertion Consumer Service URL (callback URL)
            </div>
            <CAMTooltip text="API endpoint which validates the SAML assertion from your IDP" />
          </div>
          <TextField
            className={classes.input}
            disabled
            data-testid="saml-callback-url"
            placeholder="callback URL"
            autoComplete="off"
            required
            margin="none"
            variant="outlined"
            SelectProps={selectProps}
            InputProps={{...inputProps, name: 'callBackUrl'}}
            value={callbackUrl}
          />
          <CopyToClipboard
            text={callbackUrl}
            buttonProps={{size: 'large', className: classes.copyIcon}}
            iconVariant="FileCopy"
          />
        </Grid>

        <Grid item className={classes.gridItem} xs={12}>
          <Grid item xs={12}>
            <div className={classes.titleRow}>
              <div className={classes.label}>Audience URL (Entity ID)</div>
              <CAMTooltip text="Unique identifier for service provider (Anyware Manager) in your IDP" />
            </div>
            <TextField
              className={classes.input}
              disabled
              data-testid="saml-entity-id"
              placeholder="entity ID"
              autoComplete="off"
              required
              margin="none"
              variant="outlined"
              SelectProps={selectProps}
              InputProps={{...inputProps, name: 'entityId'}}
              value={entityId}
            />
            <CopyToClipboard
              text={entityId}
              buttonProps={{size: 'large', className: classes.copyIcon}}
              iconVariant="FileCopy"
            />
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
}

SamlConfigInfo.propTypes = {
  userLoginUrl: PropTypes.string.isRequired,
  callbackUrl: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  configurationId: PropTypes.string.isRequired,
};

export default SamlConfigInfo;
