import debounce from 'awesome-debounce-promise';
import {useCallback, useEffect, useState} from 'react';

function useSearchableTextField(search) {
  const [inputText, setInputText] = useState('');

  const debouncedSearch = useCallback(debounce(search, 500), [search]);

  useEffect(() => {
    debouncedSearch(inputText);
  }, [inputText]);

  return [inputText, setInputText];
}

export default useSearchableTextField;
