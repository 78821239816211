import {
  SHOW_LEAVE_EDIT_PAGE_DIALOG,
  TOGGLE_DISPLAY_LEAVE_EDIT_PAGE_DIALOG,
  CONFIRM_DISPLAY_LEAVE_EDIT_PAGE_DIALOG,
} from 'redux/actions/displayLeaveEditPageDialogActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';

const defaultState = {
  show: true,
};

function displayLeaveEditPageDialog(state = defaultState, action) {
  switch (action.type) {
    case SHOW_LEAVE_EDIT_PAGE_DIALOG:
      return {
        show: true,
      };
    case TOGGLE_DISPLAY_LEAVE_EDIT_PAGE_DIALOG:
      return {
        show: !state.show,
      };
    case CONFIRM_DISPLAY_LEAVE_EDIT_PAGE_DIALOG:
      return state;
    case CLEAR_STORE:
      return defaultState;
    default:
      return state;
  }
}

export default displayLeaveEditPageDialog;
