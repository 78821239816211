import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import MachineName from 'components/CAM/display/MachineName/MachineName';
import CAMPagination from 'components/common/Pagination';
import CAMSearchField from 'components/common/SearchField';
import SlimTable from 'components/common/SlimTable';
import useDebounce from 'hooks/useDebounce';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {
  DEFAULT_ROWS_PER_PAGE,
  POOL_MACHINES,
  TABLE_CELL_LINK,
  TABLE_CELL_TEXT,
} from 'utils/constants';
import {selectDataForTable} from 'utils/reduxSelectors';
import {formatDateTime} from 'utils/utils';

const PREFIX = 'SelectMachineDialog';

const classes = {
  title: `${PREFIX}-title`,
  button: `${PREFIX}-button`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.title}`]: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '20px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: '24px',
  },

  [`& .${classes.button}`]: {
    width: '80px',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
  },
}));

const tableFields = [
  {id: 'machineName', text: 'Name', type: TABLE_CELL_LINK},
  {id: 'machineId', text: 'Workstation ID', type: TABLE_CELL_TEXT},
  {id: 'createdOn', text: 'Created On', type: TABLE_CELL_TEXT},
];

const DEFAULT_PARAMS = {
  page: 0,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
};

function SelectMachineDialog({open, setOpen, user, onSelectMachine}) {
  const dispatch = useDispatch();

  const [params, setParams] = useState(DEFAULT_PARAMS);
  const [search, setSearch] = useState('');
  const [searchDebounced] = useDebounce(search, 1500);
  const {
    data: poolMachines,
    total,
    isFetching,
  } = useSelector((state) => selectDataForTable(state, POOL_MACHINES));

  const onClose = () => setOpen(false);
  const handleChangePage = (_, newPage) =>
    setParams((oldParams) => ({...oldParams, page: newPage}));
  const handleChangeRowsPerPage = (e) =>
    setParams((oldParams) => ({
      ...oldParams,
      page: 0,
      rowsPerPage: e.target.value,
    }));

  const handleSearchKeyClear = () => setSearch('');
  const handleSearchKeyChange = (e) => setSearch(e.target.value);

  useEffect(() => {
    if (!open) {
      setParams(DEFAULT_PARAMS);
      setSearch('');
    }
  }, [open]);

  useEffect(() => {
    dispatch(
      fetchTableData(
        POOL_MACHINES,
        params.page,
        params.rowsPerPage,
        {},
        searchDebounced
      )
    );
  }, [JSON.stringify(params), searchDebounced]);

  const preparePoolMachines = () =>
    poolMachines.map((poolMachine) => ({
      ...poolMachine,
      createdOn: formatDateTime(poolMachine.createdOn),
      machineName: <MachineName {...poolMachine} />,
    }));

  if (!open) return null;

  return (
    <StyledDialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      data-test-id="pool-user-machine-assignment-dialog"
    >
      <DialogTitle>
        <Typography className={classes.title}>
          Assign a remote workstation to &ldquo;
          {user.name}
          &ldquo;
        </Typography>
      </DialogTitle>
      <DialogContent>
        <CAMSearchField
          id="pool-user-machine-assignment-search-field"
          value={search}
          placeholder="Search remote workstations by name"
          onChange={handleSearchKeyChange}
          onClear={handleSearchKeyClear}
        />
        <SlimTable
          resource={POOL_MACHINES}
          shouldRenderCheckboxes={false}
          shouldRenderKebabs={false}
          data={preparePoolMachines()}
          fields={tableFields}
          total={total}
          isFetching={isFetching}
          noDataMessage="No remote workstations found in pool"
          onCellClick={onSelectMachine}
        />
        <CAMPagination
          page={params.page}
          rowsPerPage={params.rowsPerPage}
          total={total}
          rowsPerPageOptions={[15]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          dataTestId="pool-user-machine-assignment-table-pagination"
        />
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="close-pool-user-machine-assignment-dialog"
          className={classes.button}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

SelectMachineDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  onSelectMachine: PropTypes.func.isRequired,
};

export default SelectMachineDialog;
