import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'api/Api';
import CAMPagination from 'components/common/Pagination';
import CAMSearchField from 'components/common/SearchField';
import SlimTable from 'components/common/SlimTable';
import {capitalize} from 'helpers/core';
import useDebounce from 'hooks/useDebounce';
import {handleApiError} from 'redux/actions/dataActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {
  AD_USERS,
  DEFAULT_ROWS_PER_PAGE,
  POOL_MACHINES,
  POOL_USERS,
  TABLE_CELL_LINK,
  TABLE_CELL_TEXT,
} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {
  selectDataForTable,
  selectSelectedDeployment,
  selectSelectedPool,
} from 'utils/reduxSelectors';
import {isEmpty} from 'utils/utils';

const PREFIX = 'SelectUserDialog';

const classes = {
  title: `${PREFIX}-title`,
  button: `${PREFIX}-button`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.title}`]: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '20px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: '24px',
  },

  [`& .${classes.button}`]: {
    width: '80px',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
  },
}));

const tableFields = [
  {id: 'name', text: 'Name', type: TABLE_CELL_LINK},
  {id: 'userName', text: 'Username', type: TABLE_CELL_TEXT},
  {id: 'status', text: 'Status', type: TABLE_CELL_TEXT},
  {id: 'machineName', text: 'Assigned To Machine', type: TABLE_CELL_TEXT},
];

const DEFAULT_PARAMS = {
  page: 0,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
};

function SelectUserDialog({open, setOpen, machine, onSelectUser}) {
  const dispatch = useDispatch();

  const [params, setParams] = useState(DEFAULT_PARAMS);
  const [search, setSearch] = useState('');
  const [searchDebounced] = useDebounce(search, 1500);
  const {
    data: poolUsers,
    total,
    isFetching,
  } = useSelector((state) => selectDataForTable(state, POOL_USERS));
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {poolId} = useSelector((state) => selectSelectedPool(state));
  const [adUsers, setAdUsers] = useState([]);

  useEffect(() => {
    if (!open) {
      setParams(DEFAULT_PARAMS);
      setSearch('');
    }
  }, [open]);

  useEffect(() => {
    dispatch(
      fetchTableData(
        POOL_USERS,
        params.page,
        params.rowsPerPage,
        {},
        searchDebounced
      )
    );
  }, [JSON.stringify(params), searchDebounced]);

  const onClose = () => {
    setOpen(false);
  };

  const handleChangePage = (_, newPage) =>
    setParams((oldParams) => ({...oldParams, page: newPage}));
  const handleChangeRowsPerPage = (e) =>
    setParams((oldParams) => ({
      ...oldParams,
      page: 0,
      rowsPerPage: e.target.value,
    }));

  const handleSearchKeyClear = () => setSearch('');
  const handleSearchKeyChange = (e) => setSearch(e.target.value);

  const getAdUsersInPool = async () => {
    const userGuids = poolUsers.map((user) => user.userGuid).join(',');
    const path = mapResourceToPath(AD_USERS);
    const getParams = {
      deploymentId,
      userGuid: userGuids,
      limit: params.rowsPerPage,
    };

    let result = [];
    try {
      const resp = await get({path, params: getParams});
      result = resp.data || [];
    } catch (err) {
      handleApiError(err);
    }

    return result;
  };

  const createUserTableObject = (adUser, poolUser) => ({
    ...poolUser,
    name: adUser.name || '',
    userName: adUser.userName || '',
    status: capitalize(poolUser.status),
  });

  const mapUsersToMachines = async (users) => {
    const path = mapResourceToPath(POOL_MACHINES, {deploymentId, poolId});

    const mappedUsers = await Promise.all(
      users.map(async (user) => {
        try {
          const response = await get({
            path,
            params: {assignedTo: user.userGuid},
          });
          const [assignedMachine] = response.data;
          if (!isEmpty(assignedMachine)) {
            if (user.userGuid === assignedMachine.assignedTo) {
              return {
                ...user,
                machineId: assignedMachine.machineId,
                machineName: assignedMachine.machineName,
              };
            }
          }
        } catch (err) {
          // Errors here should not be displayed to user
        }
        return user;
      })
    );

    return mappedUsers;
  };

  const hashAdUsersByGuid = (data) =>
    data.reduce(
      (result, user) => ({
        ...result,
        [user.userGuid]: user,
      }),
      {}
    );

  const mapPoolUsersToAdUsers = async (users) => {
    try {
      const data = await getAdUsersInPool();
      const hashedAdUsers = hashAdUsersByGuid(data);
      const mappedUsers = users.map((user) =>
        createUserTableObject(hashedAdUsers[user.userGuid] || {}, user)
      );
      return mappedUsers;
    } catch (err) {
      return users;
    }
  };

  const mapPoolUsersToMachines = async () => {
    let mappedUsers = [];
    if (!isFetching) {
      mappedUsers = await mapPoolUsersToAdUsers(poolUsers);
      mappedUsers = await mapUsersToMachines(mappedUsers);
    }
    setAdUsers(mappedUsers);
  };

  useEffect(() => {
    if (poolUsers && !isEmpty(poolUsers)) {
      mapPoolUsersToMachines();
    } else if (isEmpty(poolUsers)) {
      setAdUsers([]);
    }
  }, [JSON.stringify(poolUsers)]);

  if (!open) return null;

  return (
    <StyledDialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      data-test-id="pool-user-user-assignment-dialog"
    >
      <DialogTitle>
        <Typography className={classes.title}>
          Assign a user to &ldquo;
          {machine.machineName}
          &ldquo;
        </Typography>
      </DialogTitle>
      <DialogContent>
        <CAMSearchField
          id="pool-user-user-assignment-search-field"
          value={search}
          placeholder="Search pool users by name"
          onChange={handleSearchKeyChange}
          onClear={handleSearchKeyClear}
        />
        <SlimTable
          resource={POOL_USERS}
          shouldRenderCheckboxes={false}
          shouldRenderKebabs={false}
          data={adUsers}
          fields={tableFields}
          total={total}
          isFetching={isFetching}
          noDataMessage="No users found in pool"
          onCellClick={onSelectUser}
        />
        <CAMPagination
          page={params.page}
          rowsPerPage={params.rowsPerPage}
          total={total}
          rowsPerPageOptions={[15]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          dataTestId="pool-user-user-assignment-table-pagination"
        />
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="close-pool-user-user-assignment-dialog"
          className={classes.button}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

SelectUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  machine: PropTypes.object.isRequired,
  onSelectUser: PropTypes.func.isRequired,
};

export default SelectUserDialog;
