import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {RiShieldKeyholeFill} from 'react-icons/ri';
import {ReactComponent as GoogleIcon} from 'icons/g_logo.svg';
import {ReactComponent as MicrosoftIcon} from 'icons/Microsoft_logo.svg';
import {theme} from 'themes/default';
import {AZURE, GCP, GOOGLE, SAML} from 'utils/constants';
import {mapIdentityProviders} from 'utils/Mappings';

const PREFIX = 'IDPIcon';

const classes = {
  idpIcon: `${PREFIX}-idpIcon`,
};

const StyledIcon = styled(({icon: Icon, ...props}) => <Icon {...props} />)(
  () => ({
    [`&.${classes.idpIcon}`]: {
      width: '24px',
      maxHeight: '16px',
      verticalAlign: 'middle',
      marginRight: '10px',
      color: theme.palette.primary.main,
    },
  })
);

export default function IDPIcon({provider}) {
  const iconProps = {
    alt: mapIdentityProviders(provider),
    className: classes.idpIcon,
  };

  switch (provider) {
    case GCP:
    case GOOGLE:
    case mapIdentityProviders(GCP):
      return <StyledIcon icon={GoogleIcon} {...iconProps} />;
    case AZURE:
    case mapIdentityProviders(AZURE):
      return <StyledIcon icon={MicrosoftIcon} {...iconProps} />;
    case SAML:
    case mapIdentityProviders(SAML):
      return <StyledIcon icon={RiShieldKeyholeFill} {...iconProps} />;
    default:
      return null;
  }
}

IDPIcon.propTypes = {
  provider: PropTypes.string.isRequired,
};
