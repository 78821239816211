import Breadcrumbs from '@mui/material/Breadcrumbs';
import {useState, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';
import GenericLink from 'components/common/GenericLink';
import Note from 'components/common/Note';
import {handleApiError} from 'redux/actions/dataActions';
import {goBack, push} from 'redux/ReduxHistory';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {
  AWC_NETWORK_REQUIREMENTS_LINK,
  AWC_SYSTEM_REQUIREMENTS_LINK,
  CONNECTORS_LINK,
  CREATE_CONNECTOR_LINK,
  CONNECTOR_REPO_NAME,
} from 'utils/constants';
import config from 'config';

import {getDownloadToken} from 'utils/apiUtils';
import {ReactComponent as RequirementsIcon} from 'icons/requirements.svg';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import useSnackbar from 'hooks/useSnackbar';

import CodeBoxWithClipboard from 'components/CAM/display/CodeBoxWithClipboard/CodeBoxWithClipboard';
import {
  AWC_INSTALL_REPOSITORY_LINK,
  AWC_INSTALL_CONNECTOR_LINK,
  AWC_INSTALL_SELINUX_LINK,
  AWC_INSTALL_CONNECTOR_DNS_LINK,
} from './wizardConstants';

const PREFIX = 'WizardGettingStarted';

const classes = {
  spacing: `${PREFIX}-spacing`,
  text: `${PREFIX}-text`,
  nextStepsText: `${PREFIX}-nextStepsText`,
  containerWrap: `${PREFIX}-containerWrap`,
  pageTitle: `${PREFIX}-pageTitle`,
  cardTitle: `${PREFIX}-cardTitle`,
  startInstallButton: `${PREFIX}-startInstallButton`,
  list: `${PREFIX}-list`,
  connectorLink: `${PREFIX}-connectorLink`,
  currentBreadcrumb: `${PREFIX}-currentBreadcrumb`,
  mainContainer: `${PREFIX}-mainContainer`,
  divider: `${PREFIX}-divider`,
  mainButton: `${PREFIX}-mainButton`,
  button: `${PREFIX}-button`,
  innerTitle: `${PREFIX}-innerTitle`,
  headerTitle: `${PREFIX}-headerTitle`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.spacing}`]: {
    margin: theme.spacing(4),
  },

  [`& .${classes.text}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.nextStepsText}`]: {
    marginTop: theme.spacing(4),
  },

  [`&.${classes.containerWrap}`]: {
    margin: theme.spacing(4),
    maxWidth: '1136px',
    paddingLeft: '96px',
    marginLeft: '0px',
    marginTop: '16px',
  },

  [`& .${classes.pageTitle}`]: {
    ...stylesFromFigma.typographyH4,
    marginTop: '8px',
  },

  [`& .${classes.cardTitle}`]: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
  },

  [`& .${classes.startInstallButton}`]: {
    main: '#003350',
  },

  [`& .${classes.list}`]: {
    listStyleSize: '16px',
    '& li': {
      marginBottom: '10px',
    },
  },

  [`& .${classes.connectorLink}`]: {
    ...stylesFromFigma.typographyBody1,
    color: 'inherit',
  },

  [`& .${classes.currentBreadcrumb}`]: {
    color: 'rgba(0, 0, 0, 0.87)',
  },

  [`& .${classes.mainContainer}`]: {
    padding: '0px',
    border: '0px',
    margin: '0px',
    marginTop: '24px',
  },

  [`& .${classes.divider}`]: {
    marginTop: '18px',
    width: '100%',
  },

  [`& .${classes.mainButton}`]: {
    color: '#FFFFFF',
    background: '#0D47A1',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#0D47A1',
    },
  },

  [`& .${classes.button}`]: {
    color: '#0D47A1',
  },

  [`& .${classes.innerTitle}`]: {
    ...stylesFromFigma.typographyH6,
    marginLeft: '24px',
  },

  [`& .${classes.headerTitle}`]: {
    ...stylesFromFigma.typographyH6,
    marginTop: '32px',
    marginBottom: '32px',
  },
}));

function WizardGettingStarted() {
  const dispatch = useDispatch();

  const {successSnackbar} = useSnackbar();
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const [downloadToken, setDownloadToken] = useState('');
  const downloadRepo = CONNECTOR_REPO_NAME;

  const openNextPage = () => {
    // Open connector duplicate page...
    dispatch(push(`${CREATE_CONNECTOR_LINK}/configure`));
  };

  // This could be moved somewhere else as part of https://jira.teradici.com/browse/TSW-187003
  const firewallCmdList = [
    'sudo firewall-cmd --permanent --add-port=6443/tcp',
    'sudo firewall-cmd --permanent --add-port=4172/udp',
    'sudo firewall-cmd --permanent --zone=trusted --add-source=10.42.0.0/16',
    'sudo firewall-cmd --permanent --zone=trusted --add-source=10.43.0.0/16',
    'sudo firewall-cmd --reload',
  ];
  const portScript = firewallCmdList.join(' && \\\n');

  const downloadAndInstallSetupRpmCommand = (token, repo) =>
    `curl -1sLf 'https://dl.anyware.hp.com/${token}/${repo}/setup.rpm.sh' | sudo -E distro=el codename=8 bash`;

  const installSelinuxCommand =
    'sudo dnf install -y https://github.com/k3s-io/k3s-selinux/releases/download/v1.1.stable.1/k3s-selinux-1.1-1.el8.noarch.rpm';

  const installAwcCommands =
    'sudo dnf install -y anyware-connector anyware-manager-selinux';

  const setupScript = (token, repo) => `${portScript}; \\
${
  token && token.length > 0
    ? `${downloadAndInstallSetupRpmCommand(token, repo)} && \\`
    : ''
}
${installSelinuxCommand} && \\
${installAwcCommands} `;

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getDownloadToken(deploymentId, false, downloadRepo);
        setDownloadToken(token);
      } catch (error) {
        // If we fail to get download token, set token to null and hide the download button and point to docs only.
        // Auth service returns 500 in standalone mode if token isn't set (eg, Darksite AWM) so suppress the error.
        if (!config.STANDALONE) {
          dispatch(handleApiError(error));
        }
        setDownloadToken(null);
      }
    };
    if (deploymentId) {
      getToken();
    }
  }, [deploymentId]);

  return (
    <Root className={classes.containerWrap}>
      <Breadcrumbs separator=">">
        <Link
          underline="hover"
          href={CONNECTORS_LINK}
          onClick={(event) => {
            event.preventDefault();
            dispatch(push(CONNECTORS_LINK));
          }}
          className={classes.connectorLink}
        >
          Connectors
        </Link>
        <Typography className={classes.currentBreadcrumb}>
          Add Connector
        </Typography>
      </Breadcrumbs>

      <Typography className={classes.pageTitle}>Add Connector</Typography>

      <Paper className={classes.mainContainer} variant="outlined">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{borderBottom: '1px solid #0000001F', paddingBottom: '24px'}}
        >
          <Grid item>
            <RequirementsIcon alt="Requirements" />
          </Grid>
          <Grid item>
            <Typography className={classes.innerTitle}>
              Pre-installation Checklist
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography
              className={`${classes.headerTitle} ${classes.text} `}
              variant="h6"
            >
              Linux machine requirements
            </Typography>
            <Typography>
              Before adding a new Connector to your Anyware Manager deployment,
              ensure you have the following prerequisites:
            </Typography>
            <Grid>
              <ul className={classes.list}>
                <li>
                  <Typography>
                    A Linux based machine (physical or virtual) - Rocky or RHEL
                    distributions are supported. &nbsp;
                    <GenericLink
                      url={AWC_SYSTEM_REQUIREMENTS_LINK}
                      underline="always"
                    >
                      Learn more about supported distributions.
                    </GenericLink>
                  </Typography>
                </li>
                <li>
                  <Typography>
                    The machine must be able to communicate with both your
                    domain and Anyware Manager. &nbsp;
                    <GenericLink
                      url={AWC_INSTALL_CONNECTOR_DNS_LINK}
                      underline="always"
                    >
                      Learn more about DNS requirements.
                    </GenericLink>
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Administrator can access the machine (eg, SSH or terminal).
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Note note="You will need this machine to run the generated installation command at a later stage." />
          <Grid>
            <Typography
              className={`${classes.headerTitle} ${classes.text} `}
              variant="h6"
            >
              Commands to run
            </Typography>
            <Typography>
              After preparing your Linux machine, execute these commands on the
              machine to install the necessary components and set up the
              required configurations. You can copy all commands at once or can
              select each command.
            </Typography>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  setupScript(downloadToken, downloadRepo)
                );
                successSnackbar('Script copied to clipboard');
              }}
              className={classes.mainButton}
              variant="contained"
              style={{marginTop: '24px', marginBottom: '24px'}}
              disabled={!downloadToken}
            >
              Copy all commands
            </Button>
            <Typography>Or copy commands separately:</Typography>

            <Container style={{marginTop: '24px'}}>
              <Typography style={{color: '#00000099'}}>
                <GenericLink
                  url={AWC_NETWORK_REQUIREMENTS_LINK}
                  color="inherit"
                  underline="always"
                >
                  Required ports
                </GenericLink>{' '}
                Configuration
              </Typography>
              <CodeBoxWithClipboard
                code={portScript}
                tooltipText="Click to copy required firewall-cmd commands to run on machine"
              />
            </Container>

            <Container style={{marginTop: '24px'}}>
              <Typography style={{color: '#00000099'}}>
                Anyware{' '}
                <GenericLink
                  url={AWC_INSTALL_REPOSITORY_LINK}
                  color="inherit"
                  underline="always"
                >
                  installation repository setup
                </GenericLink>
              </Typography>
              {downloadToken && (
                <CodeBoxWithClipboard
                  code={downloadAndInstallSetupRpmCommand(
                    downloadToken,
                    downloadRepo
                  )}
                  renderedCode={downloadAndInstallSetupRpmCommand(
                    downloadToken,
                    downloadRepo
                  )}
                  tooltipText="Click to copy Anyware repository setup commands to run on machine"
                />
              )}
            </Container>

            <Container style={{marginTop: '24px'}}>
              <Typography style={{color: '#00000099'}}>
                <GenericLink
                  url={AWC_INSTALL_SELINUX_LINK}
                  color="inherit"
                  underline="always"
                >
                  Required K3S SELinux Polices
                </GenericLink>{' '}
                installation
              </Typography>
              <CodeBoxWithClipboard
                code={installSelinuxCommand}
                tooltipText="Click to copy K3S SELinux policy installation commands to run on machine"
              />
            </Container>

            <Container style={{marginTop: '24px'}}>
              <Typography style={{color: '#00000099'}}>
                Required{' '}
                <GenericLink
                  url={AWC_INSTALL_CONNECTOR_LINK}
                  color="inherit"
                  underline="always"
                >
                  Anyware software packages
                </GenericLink>
              </Typography>
              <CodeBoxWithClipboard
                code={installAwcCommands}
                tooltipText="Click to copy Anyware software packages installation commands to run on machine"
              />
            </Container>
          </Grid>
        </Grid>
      </Paper>
      <Grid container justifyContent="flex-end">
        <Divider className={classes.divider} />

        <Grid container item xs={6}>
          <Button
            onClick={() => dispatch(goBack())}
            className={classes.button}
            variant="text"
          >
            Back
          </Button>
        </Grid>
        <Grid container item justifyContent="flex-end" xs={6}>
          <Button
            onClick={() => openNextPage()}
            className={classes.mainButton}
            variant="contained"
          >
            Generate installation command
          </Button>
        </Grid>
      </Grid>
    </Root>
  );
}

export default WizardGettingStarted;
