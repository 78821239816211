import {CLEAR_STORE} from 'redux/actions/globalActions';
import {
  CLOSE_VIEW_MACHINE_ASSIGNMENT_DIALOG,
  OPEN_VIEW_MACHINE_ASSIGNMENT_DIALOG,
} from 'redux/actions/viewMachineAssignmentDialogActions';

const defaultState = {
  open: false,
  poolUser: {},
};

function openDialog(state, action) {
  return {
    ...state,
    open: true,
    poolUser: action.poolUser || {},
  };
}

function closeDialog(state) {
  return {
    ...state,
    open: false,
    poolUser: {},
  };
}

function clearDialog() {
  return defaultState;
}

const stateChanges = {
  [OPEN_VIEW_MACHINE_ASSIGNMENT_DIALOG]: openDialog,
  [CLOSE_VIEW_MACHINE_ASSIGNMENT_DIALOG]: closeDialog,
  [CLEAR_STORE]: clearDialog,
};

function viewMachineAssignmentDialog(state = defaultState, action) {
  const stateChange = stateChanges[action.type];
  return stateChange ? stateChange(state, action) : state;
}

export default viewMachineAssignmentDialog;
