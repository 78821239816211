export const OPEN_CONNECTOR_HEALTH_DIALOG = 'OPEN_CONNECTOR_HEALTH_DIALOG';

export function openConnectorHealthDialog(connector) {
  return {
    type: OPEN_CONNECTOR_HEALTH_DIALOG,
    connector,
  };
}

export const CLOSE_CONNECTOR_HEALTH_DIALOG = 'CLOSE_CONNECTOR_HEALTH_DIALOG';

export function closeConnectorHealthDialog() {
  return {
    type: CLOSE_CONNECTOR_HEALTH_DIALOG,
  };
}
