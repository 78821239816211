import Link from '@mui/material/Link';
import PropTypes from 'prop-types';

function GenericLink(props) {
  const {url, children, ...otherProps} = props;
  return (
    <Link href={url} target="_blank" rel="noopener noreferrer" {...otherProps}>
      {children || url}
    </Link>
  );
}

GenericLink.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

GenericLink.defaultProps = {
  children: null,
};

export default GenericLink;
