import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useState} from 'react';
import ErrorModal from 'components/common/ErrorModal';
import useAcceptPolicyTracking from 'hooks/useAcceptPolicyTracking';
import {isEmpty} from 'utils/utils';

const PREFIX = 'MonitorEulaDialog';

const classes = {
  dialogTitleBar: `${PREFIX}-dialogTitleBar`,
  dialogTitleText: `${PREFIX}-dialogTitleText`,
  dialogContent: `${PREFIX}-dialogContent`,
  dialogContentText: `${PREFIX}-dialogContentText`,
  buttons: `${PREFIX}-buttons`,
  buttonUnlockLoading: `${PREFIX}-buttonUnlockLoading`,
  loadingSpinner: `${PREFIX}-loadingSpinner`,
  eulaContent: `${PREFIX}-eulaContent`,
  eula: `${PREFIX}-eula`,
  eulaMessage: `${PREFIX}-eulaMessage`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.dialogTitleBar}`]: {
    backgroundColor: '#164A85',
    padding: 0,
  },

  [`& .${classes.dialogTitleText}`]: {
    color: '#FFF',
    fontSize: '1.25rem',
    lineHeight: '25px',
    textShadow: '0 0 4px 0 rgba(3,46,97,0.5)',
    margin: '12px 25px',
  },

  [`& .${classes.dialogContent}`]: {
    backgroundColor: '#FFFFFF',
  },

  [`& .${classes.dialogContentText}`]: {
    fontSize: '1rem',
  },

  [`& .${classes.buttons}`]: {
    textTransform: 'none',
    fontWeight: 'normal',
    letterSpacing: '0',
    lineHeight: '0.75rem',
    padding: '9px 2px',
    fontSize: '0.8125rem',
  },

  [`& .${classes.buttonUnlockLoading}`]: {
    backgroundColor: '#FFF',
    color: '#0070D2',
    padding: '9px 14px',
    cursor: 'wait',
    '&:hover': {
      backgroundColor: '#FFF',
      color: '#0070D2',
    },
  },

  [`& .${classes.loadingSpinner}`]: {
    width: '12px',
    height: '12px',
    marginRight: '10px',
  },

  [`& .${classes.eulaContent}`]: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderTop: `7px solid ${theme.palette.primary.main}`,
    borderRadius: '1%',
    overflow: 'hidden',
  },

  [`& .${classes.eula}`]: {
    height: '20rem',
    overflow: 'auto',
    padding: '0px',
    width: '100%',
    borderWidth: '0px',
    marginBottom: '-6px',
  },

  [`& .${classes.eulaMessage}`]: {
    display: 'flex',
    color: theme.palette.info.main,
    backgroundColor: '#ede8e8',
    margin: '0',
    padding: '8px',
  }
}));

function MonitorEulaDialog({handleCancel}) {


  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [acceptedEula, setAcceptedEula] = useState(false);
  const [unlockButtonLoading, setUnlockButtonLoading] = useState(false);

  const {updateCurrentUser, currentUser} = useAcceptPolicyTracking();

  const showErrorModal = () => {
    setUnlockButtonLoading(false);
    setErrorMessage(
      'An error occurred while accepting the license agreement. Please try again later. If this issue persists contact Anyware support.'
    );
    setShowError(true);
  };

  const sendEulaAcceptance = async (event) => {
    event.preventDefault();

    setUnlockButtonLoading(true);

    const acceptanceRequestBody = {};

    if (acceptedEula) {
      acceptanceRequestBody.monitorEulaAccepted = acceptedEula;
    }

    if (!isEmpty(acceptanceRequestBody)) {
      try {
        await updateCurrentUser(acceptanceRequestBody);
        // if the user accepted eula but the 'monitorEulaAcceptedOn' property is not in the 'currentUser'
        if (!currentUser.monitorEulaAcceptedOn && acceptedEula) {
          showErrorModal();
        }
      } catch (error) {
        showErrorModal();
      }
    }
  };

  const closeErrorMessage = () => {
    setShowError(false);
    setErrorMessage('');
  };

  const toggleAcceptedEula = () => setAcceptedEula(!acceptedEula);

  const renderEulaReq = () => (
    <div className={classes.eulaContent}>
      <iframe
        title="EULA"
        src="/EULA.html"
        className={classes.eula}
        data-testid="eula-iframe"
      />
      <div className={classes.eulaMessage}>
        <Checkbox
          color="primary"
          onChange={() => toggleAcceptedEula(!acceptedEula)}
          checked={acceptedEula}
          size="small"
          inputProps={{'data-testid': 'accept-eula-checkbox'}}
        />
        <p>
          I have read and accepted the terms of the HP Anyware end-user license
          agreement.
        </p>
      </div>
    </div>
  );

  return (
    <StyledDialog open className={classes.root}>
      <MuiDialogTitle disableTypography className={classes.dialogTitleBar}>
        <Typography className={classes.dialogTitleText}>
          HP Anyware End User License Agreement
        </Typography>
      </MuiDialogTitle>

      <form onSubmit={sendEulaAcceptance}>
        <MuiDialogContent className={classes.dialogContent}>
          <Typography className={classes.dialogContentText}>
            Please accept the HP Anyware end-user license agreement in order to
            use the Anyware Monitor feature:
            <br />
          </Typography>
          {renderEulaReq()}
        </MuiDialogContent>

        <MuiDialogActions>
          {!unlockButtonLoading && (
            <Button onClick={handleCancel} variant="contained">
              Cancel
            </Button>
          )}

          {!unlockButtonLoading && (
            <Button
              data-testid="confirm-button"
              disabled={!acceptedEula}
              color="primary"
              type="submit"
              variant="contained"
            >
              Confirm
            </Button>
          )}
          {unlockButtonLoading && (
            <Button
              className={classNames(
                classes.buttons,
                classes.buttonUnlockLoading
              )}
              variant="contained"
            >
              <>
                <CircularProgress className={classes.loadingSpinner} />
                Accepting EULA
              </>
            </Button>
          )}
        </MuiDialogActions>
      </form>
      <ErrorModal
        open={showError}
        errorHeader="Error"
        errorMessage={errorMessage}
        handleClose={closeErrorMessage}
      />
    </StyledDialog>
  );
}

MonitorEulaDialog.propTypes = {
  handleCancel: PropTypes.func.isRequired,
};

export default MonitorEulaDialog;
