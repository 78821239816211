import {CLEAR_STORE} from 'redux/actions/globalActions';
import {UPDATE_USER_PREFERENCES} from 'redux/actions/userPreferencesActions';

const defaultState = {};

export default function userPreferencesReducer(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_USER_PREFERENCES:
      return {
        ...state,
        ...action.preferences,
      };
    case CLEAR_STORE:
      return defaultState;
    default:
      return state;
  }
}
