import {all, call, delay, put, race, select, take} from 'redux-saga/effects';
import {get} from 'api/Api';
import {defaultNotificationPollingRate} from 'config';
import {
  updatePoolWorkstation,
  updateWorkstation,
} from 'redux/actions/dataActions';
import {updateNotification} from 'redux/actions/notificationActions';
import {
  FINISH_POLLING,
  REMOVE_POLLING_ITEM,
  START_POLLING,
} from 'redux/actions/pollingActions';
import {POOLS, POOL_MACHINES, REMOTE_WORKSTATIONS} from 'utils/constants';
import {isEmpty} from 'utils/utils';

// check if status is in array of possible end states
function checkStatusChange(property, endStates, data) {
  const value = property
    .split('.')
    .reduce((object, index) => object[index], data);
  return endStates && endStates.includes(value);
}

function checkEndFunctions(endFunctions, input) {
  if (!isEmpty(endFunctions) && endFunctions.some((f) => !isEmpty(f))) {
    // If some function is satisfied, the polling will stop.
    return endFunctions.some((f) => typeof f === 'function' && f(input));
  }
  // No function was satisfied.
  return false;
}

// This function handles a request to poll.
//   Algorithm:
//   Fetch the item from the backend.
//   Check whether the necessary status change has occurred.
//   If so, we issue push a notification to the store and remove
//   the item from the polling queue.
//   If an error occurs, we push a notification and remove the
//   item from the polling queue

export function* pollItem({
  resourceType,
  resourceId,
  property,
  endStates,
  endFunctions,
  key,
  path,
}) {
  const requestPath = path || `${resourceType}/${resourceId}`;
  try {
    const response = yield get({path: requestPath});
    const {data} = response;

    // If any endFunctions were specified for the polling item and the function is satisfied
    // it will stop the polling. Any side effects must be done within the function itself.
    if (checkEndFunctions(endFunctions, response)) {
      yield put({
        type: REMOVE_POLLING_ITEM,
        key,
        resourceId,
        info: {
          value: data[property],
        },
      });
    }

    if (property && checkStatusChange(property, endStates, data)) {
      switch (resourceType) {
        case POOLS:
          break;
        default:
          yield put(updateNotification(resourceType, data, property));
          break;
      }
      yield put({
        type: REMOVE_POLLING_ITEM,
        key,
        resourceId,
        info: {
          value: data[property],
        },
      });
      switch (resourceType) {
        case POOLS:
          break;
        case POOL_MACHINES:
          yield put(updatePoolWorkstation(data));
          break;
        case REMOTE_WORKSTATIONS:
          yield put(updateWorkstation(data));
          break;
        default:
          break;
      }
    }
  } catch (error) {
    /* If any endFunctions were specified for the polling item and at least one of the functions are
    satisfied than it will it will stop the polling.
    Any side effects must be done within the function itself.
    If no endFunctions are specified, that it will stop the polling as the endStates
    approach do not deal with API error response. */
    if (!isEmpty(endFunctions)) {
      if (checkEndFunctions(endFunctions, error)) {
        yield put({
          type: REMOVE_POLLING_ITEM,
          key,
          resourceId,
          info: {
            error,
          },
        });
      }
    } else {
      // TODO:
      // Push an error notification

      yield put({
        type: REMOVE_POLLING_ITEM,
        key,
        resourceId,
        info: {
          error,
        },
      });
    }
  }
}

// This function issues poll requests for every polling item currently in the store.
// The poller loops until it is cancelled via a FINISH_POLLING action, as described below.

function* poller() {
  while (true) {
    const toPoll = yield select((state) => state.polling);
    yield all(Object.values(toPoll).map((item) => call(pollItem, item)));
    yield delay(defaultNotificationPollingRate());
  }
}

// This function manages the app's polling mechanism.
// This function can be thought of as an 'on/off' switch for polling.

//   Algorithm:
//   Listen for a START_POLLING action. When a START_POLLING action is
//   received, we issue two functions simultaneously. The first function
//   handles polling. The second function listens for a FINISH_POLLING action.
//   When a FINISH_POLLING action is received, we cancel the polling function
//   and move back to the top of the loop where we listen for another START_POLLING action.

function* pollingSaga() {
  while (true) {
    yield take(START_POLLING);
    yield race([call(poller), take(FINISH_POLLING)]);
  }
}

export default pollingSaga;
