import {Button, Grid, TextField} from '@mui/material';
import {styled} from '@mui/material/styles';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PeopleIcon from '@mui/icons-material/People';
import classNames from 'classnames';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {del, post} from 'api/Api';
import ToggleSwitch from 'components/CAM/buttons/ToggleSwitch/ToggleSwitch';
import CAMTooltip from 'components/CAM/display/CAMTooltip/CAMTooltip';
import useSnackbar from 'hooks/useSnackbar';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {
  clearResource,
  fetchResource,
  handleApiError,
  mapResourceToAPI,
  fetchSamlConfigSettings,
} from 'redux/actions/dataActions';
import {
  LINK_SAML_DOCUMENTATION,
  SAML_CONFIGURATION,
  ADD_SAML_CONFIG_LINK,
} from 'utils/constants';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {getDomain, isEmpty} from 'utils/utils';
import useUpdateSamlConfig from 'hooks/useUpdateSamlConfig';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import {push} from 'redux/actions/HistoryActions';
import config from 'config';
import SamlConfigInfo from './samlConfigInfo';

const PREFIX = 'SamlSettingsConfiguration';

const classes = {
  button: `${PREFIX}-button`,
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  inputSectionSubtitle: `${PREFIX}-inputSectionSubtitle`,
  gridItem: `${PREFIX}-gridItem`,
  noUsersBox: `${PREFIX}-noUsersBox`,
  addIcon: `${PREFIX}-addIcon`,
  disabledIcon: `${PREFIX}-disabledIcon`,
  peopleIcon: `${PREFIX}-peopleIcon`,
  noConfigsFoundTitle: `${PREFIX}-noConfigsFoundTitle`,
  createConfigButton: `${PREFIX}-createConfigButton`,
  createConfigButtonContainer: `${PREFIX}-createConfigButtonContainer`,
  fillSpace: `${PREFIX}-fillSpace`,
  configEnabledContainer: `${PREFIX}-configEnabledContainer`,
  helpIcon: `${PREFIX}-helpIcon`,
  openDocumentationButton: `${PREFIX}-openDocumentationButton`,
  openDocumentationIcon: `${PREFIX}-openDocumentationIcon`,
  titleRow: `${PREFIX}-titleRow`,
  saveNameBtn: `${PREFIX}-saveNameBtn`,
  configNameField: `${PREFIX}-configNameField`,
};

const StyledGrid = styled(Grid)(({theme}) => ({
  [`& .${classes.button}`]: theme.createPage.button,

  [`&.${classes.root}`]: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    backgroundColor: 'transparent',
    padding: '2px',
  },

  [`& .${classes.input}`]: {
    width: '90%',
  },

  [`& .${classes.inputSectionSubtitle}`]: {
    ...theme.createPage.inputSectionSubtitle,
    marginBottom: '1rem',
  },

  [`& .${classes.gridItem}`]: {
    marginRight: '3.1rem',
    marginBottom: '1rem',
  },

  [`& .${classes.noUsersBox}`]: {
    backgroundColor: '#FAFAF9',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '50px 40px',
    width: '100%',
  },

  [`& .${classes.addIcon}`]: {
    marginRight: '10px',
    color: '#666',
  },

  [`& .${classes.disabledIcon}`]: {
    color: '#b9b9b8',
  },

  [`& .${classes.peopleIcon}`]: {
    marginRight: '10px',
    marginBottom: '-7px',
    color: '#666',
  },

  [`& .${classes.noConfigsFoundTitle}`]: {
    color: '#0076A9',
    paddingBottom: '0.5rem',
    textAlign: 'center',
  },

  [`& .${classes.createConfigButton}`]: {
    alignItems: 'center',
    color: '#666',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.9rem',
    justifyContent: 'center',
    lineHeight: '1.2rem',
    padding: '0.5rem 1rem',
  },

  [`& .${classes.createConfigButtonContainer}`]: {
    display: 'flex',
    paddingTop: '0.5rem',
  },

  [`& .${classes.fillSpace}`]: {
    flexGrow: 1,
  },

  [`& .${classes.configEnabledContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '0.95rem',
  },

  [`& .${classes.helpIcon}`]: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: '0.5rem',
    padding: '1px',
  },

  [`& .${classes.openDocumentationButton}`]: {
    color: 'rgba(0,0,0,0.6)',
    fontSize: '0.9rem',
    fontWeight: 350,
    marginLeft: 0,
    width: '9rem',
  },

  [`& .${classes.openDocumentationIcon}`]: {
    marginRight: '4px',
    paddingRight: '4px',
  },

  [`& .${classes.titleRow}`]: {display: 'flex', flexDirection: 'row'},

  [`& .${classes.saveNameBtn}`]: {
    height: '32px',
  },

  [`& .${classes.configNameField}`]: {
    width: '90%',
    maxWidth: '400px',
    paddingRight: theme.spacing(2),
  },
}));

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  style: {maxHeight: '50vh'},
};

const selectProps = {
  MenuProps: menuProps,
  IconComponent: KeyboardArrowDown,
  displayEmpty: true,
};

const inputProps = {
  style: {
    fontFamily: 'Roboto',
    fontSize: '0.9rem',
    width: '100%',
    height: '32px',
  },
};

function SamlSettingsConfiguration({configurationId}) {
  const dispatch = useDispatch();
  const {successSnackbar, errorSnackbar} = useSnackbar();

  const [configName, setConfigName] = useState('');
  const [signInUrl, setSignInUrl] = useState('');
  const [userLoginUrl, setUserLoginUrl] = useState('');
  const [callbackUrl, setCallbackUrl] = useState('');
  const [idpCertificate, setIdpCertificate] = useState('');
  const [entityId, setEntityId] = useState('');
  const [configEnabled, setConfigEnabled] = useState(false);

  const [inputModified, setInputModified] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingName, setIsSavingName] = useState(false);
  const domain = getDomain();

  const {data: samlConfigurations, isFetching: isFetchingConfiguration} =
    useSelector((state) => {
      const samlConfig = state.data.dataByResource[SAML_CONFIGURATION] || {
        data: {},
      };
      return samlConfig;
    });

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );

  const clearAllFields = () => {
    setConfigName('');
    setSignInUrl('');
    setIdpCertificate('');
    setUserLoginUrl('');
    setCallbackUrl('');
    setEntityId('');
  };

  const loadInput = () => {
    const samlConfig = samlConfigurations[configurationId];
    if (isEmpty(samlConfig)) {
      clearAllFields();
      setInputModified(false);
    } else {
      setConfigName(samlConfig.name || '');
      setSignInUrl(samlConfig.signInUrl || '');
      setCallbackUrl(samlConfig.callbackUrl);
      setIdpCertificate(samlConfig.idpCertificate || '');
      setUserLoginUrl(samlConfig.userLoginUrl);
      setEntityId(samlConfig.entityId);
      setConfigEnabled(samlConfig.enabled || false);
    }
  };

  const handleInputChange = (event) => {
    setConfigEnabled(event.target.checked);
    setInputModified(true);
  };

  const fetchConfiguration = () => {
    dispatch(fetchResource(SAML_CONFIGURATION));
  };

  const deleteConfiguration = async () => {
    const path = `${mapResourceToAPI(SAML_CONFIGURATION)}/${configurationId}`;
    await del({path});
    clearAllFields();
    setInputModified(false);
    dispatch(clearResource(SAML_CONFIGURATION));
    fetchConfiguration();
  };

  const handleDeleteClick = () => {
    dispatch(
      openDialog(
        'Delete SAML configuration?',
        `Are you sure you want to delete this SAML configuration?
        You will have to change the configuration on your Identity Provider when you create another SAML configuration later.`,
        deleteConfiguration
      )
    );
    return true;
  };

  const createDisabledConfig = async () => {
    setIsSaving(true);
    try {
      const data = {domain};
      const path = mapResourceToAPI(SAML_CONFIGURATION);
      await post({path, data});

      setInputModified(false);
      fetchConfiguration();
    } catch (err) {
      dispatch(handleApiError(err));
    }
    setIsSaving(false);
  };

  const createSamlConfig = () => {
    if (config.multiSamlSupported()) {
      dispatch(push(ADD_SAML_CONFIG_LINK));
    } else {
      createDisabledConfig();
    }
  };
  const {mutate: updateSamlConfig} = useUpdateSamlConfig(configurationId, {
    onMutate: () => {
      setIsSaving(true);
    },
    onError: (err) => {
      if (
        err.code === 400 &&
        err.data &&
        err.data.reason ===
          'SAML configuration requires a valid sign-in URL to be enabled.'
      ) {
        const newErrorMessage =
          'SAML configuration requires a valid sign-in URL to be enabled. Go to IDP settings and add the required values.';
        successSnackbar(newErrorMessage);
      } else {
        dispatch(handleApiError(err));
      }
    },
    onSettled: () => {
      setInputModified(false);
      dispatch(fetchSamlConfigSettings());
      setIsSaving(false);
    },
  });

  const saveConfiguration = async () => {
    const data = {enabled: configEnabled};
    updateSamlConfig(data);
  };

  const saveConfigName = () => {
    setIsSavingName(true);
    const data = {name: configName};
    updateSamlConfig(data, {
      onSuccess: () => {
        successSnackbar('Configuration name is updated.');
      },
      onError: () => {
        errorSnackbar('Failed to update configuration name');
      },
      onSettled: () => {
        setIsSavingName(false);
      },
    });
  };

  useEffect(() => {
    if (deploymentId) {
      loadInput();
    }
  }, [deploymentId]);

  useEffect(() => {
    loadInput();
  }, [configurationId]);

  useEffect(() => {
    if (configurationId && inputModified) {
      saveConfiguration();
    }
  }, [inputModified]);

  useEffect(() => {
    if (!isFetchingConfiguration) {
      loadInput();
    }
  }, [isFetchingConfiguration]);

  return (
    <StyledGrid container className={classes.root}>
      {!configurationId && isFetchingConfiguration === false && (
        <Grid item className={classes.gridItem} xs={12}>
          <div className={classes.noUsersBox}>
            <div className={classes.noConfigsFoundTitle}>
              <PeopleIcon className={classes.peopleIcon} />
              No Multi Admin configuration found
            </div>

            <div className={classes.createConfigButtonContainer}>
              <span className={classes.fillSpace} />
              <Button
                data-testid="create-config-button"
                className={classes.createConfigButton}
                onClick={createSamlConfig}
                disabled={isSaving || Boolean(configurationId)}
              >
                <span>
                  <AddIcon
                    className={classNames(classes.addIcon, {
                      [classes.disabledIcon]:
                        isSaving || Boolean(configurationId),
                    })}
                  />
                </span>
                <span>Create Multi Admin configuration</span>
              </Button>
              <span className={classes.fillSpace} />
            </div>
          </div>
        </Grid>
      )}

      {configurationId && (
        <>
          {config.multiSamlSupported() && (
            <Grid className={classes.gridItem} item xs={12}>
              <div className={classes.titleRow}>
                <div className={classes.label}>Configuration Name</div>
              </div>
              <TextField
                className={classes.configNameField}
                data-testid="saml-config-name-input"
                placeholder="SAML Configuration Name"
                autoComplete="off"
                required
                margin="none"
                variant="outlined"
                SelectProps={selectProps}
                InputProps={{...inputProps, name: 'samlConfigName'}}
                value={configName}
                onChange={(event) => setConfigName(event.target.value)}
              />
              <SaveButton
                disabled={
                  configName === '' ||
                  configName === samlConfigurations[configurationId]?.name
                }
                saving={isSavingName}
                onClick={saveConfigName}
                customClass={classes.saveNameBtn}
                dataTestId="save-config-name"
              />
            </Grid>
          )}

          <SamlConfigInfo
            userLoginUrl={userLoginUrl}
            callbackUrl={callbackUrl}
            entityId={entityId}
            configurationId={configurationId}
          />

          <Grid item className={classes.gridItem} xs={12}>
            <div className={classes.configEnabledContainer}>
              <ToggleSwitch
                isOn={configEnabled}
                displayText={
                  configEnabled
                    ? 'Configuration is enabled'
                    : 'Configuration is disabled'
                }
                onClick={handleInputChange}
                saving={isSaving}
                tooltipText={
                  !signInUrl || !idpCertificate
                    ? 'Configuration can only be enabled if IDP information is provided.'
                    : ''
                }
              />

              {!configEnabled && (
                <CAMTooltip text="Users cannot log into Anyware Manager using this configuration when it is disabled." />
              )}
            </div>
          </Grid>

          <Grid
            container
            direction="row-reverse"
            justifyContent="space-between"
            className={classes.gridItem}
          >
            <span>
              {configurationId && (
                <Button
                  data-testid="delete-button"
                  variant="outlined"
                  color="primary"
                  disabled={!configurationId}
                  className={classes.button}
                  onClick={handleDeleteClick}
                >
                  Delete
                </Button>
              )}
            </span>
            <Button
              data-testid="learn-more-button"
              className={classNames(
                classes.button,
                classes.openDocumentationButton
              )}
              target="_blank"
              href={LINK_SAML_DOCUMENTATION}
            >
              <OpenInNewIcon className={classes.openDocumentationIcon} />
              Learn More
            </Button>
          </Grid>
        </>
      )}
    </StyledGrid>
  );
}

SamlSettingsConfiguration.propTypes = {
  configurationId: PropTypes.string,
};

SamlSettingsConfiguration.defaultProps = {
  configurationId: null, // null indicate no saml configuration exist yet, this will display a section that ask user to create one
};

export default SamlSettingsConfiguration;
