import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import PeopleIcon from '@mui/icons-material/People';
import classNames from 'classnames';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {del, post} from 'api/Api';
import Button from 'components/CAM/buttons/SaveButton/SaveButton';
import DeleteIcon from 'components/CAM/icons/DeleteIcon/DeleteIcon';
import SelectMenu from 'components/CAM/inputs/SelectMenu/SelectMenu';
import TextInput from 'components/CAM/inputs/TextInput/TextInput';
import TextInputSubmission from 'components/CAM/inputs/TextInputSubmission/TextInputSubmission';
import GridN from 'components/CAM/layout/GridN/GridN';
import usePendingChanges from 'hooks/usePendingChanges';
import useSnackbar from 'hooks/useSnackbar';
import {
  fetchSamlAllowedGroups,
  handleApiError,
  saveVariable,
  fetchSamlConfigSettings,
} from 'redux/actions/dataActions';
import {SAML_ALLOWED_GROUPS, SAML_CONFIGURATION} from 'utils/constants';
import {mapSamlClaimTypeToDisplay} from 'utils/Mappings';
import {selectVariable} from 'utils/reduxSelectors';
import {formatDateTime, isEmpty, sanitizeValues} from 'utils/utils';
import useUpdateSamlConfig from 'hooks/useUpdateSamlConfig';

const PREFIX = 'SamlGroupsConfiguration';

const classes = {
  root: `${PREFIX}-root`,
  inputSectionSubtitle: `${PREFIX}-inputSectionSubtitle`,
  spacer: `${PREFIX}-spacer`,
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  tableRow: `${PREFIX}-tableRow`,
  tableCell: `${PREFIX}-tableCell`,
  tableRowEven: `${PREFIX}-tableRowEven`,
  tableRowOdd: `${PREFIX}-tableRowOdd`,
  noGroupsBox: `${PREFIX}-noGroupsBox`,
  peopleIcon: `${PREFIX}-peopleIcon`,
  noGroupsFoundTitle: `${PREFIX}-noGroupsFoundTitle`,
  noGroupsFoundText: `${PREFIX}-noGroupsFoundText`,
  iconColumn: `${PREFIX}-iconColumn`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    backgroundColor: 'transparent',
    padding: '34px',
    paddingTop: '10px',
    width: '100%',
  },

  [`& .${classes.inputSectionSubtitle}`]: {
    ...theme.createPage.inputSectionSubtitle,
    marginBottom: '12px',
  },

  [`& .${classes.spacer}`]: {
    marginBottom: '1rem',
  },

  [`& .${classes.table}`]: {
    width: '100%',
  },

  [`& .${classes.tableHead}`]: {
    backgroundColor: '#FAFAF9',
    height: '28px',
  },

  [`& .${classes.tableRow}`]: {
    height: '28px',
  },

  [`& .${classes.tableCell}`]: {
    borderBottom: '1px solid lightgrey',
  },

  [`& .${classes.tableRowEven}`]: {
    backgroundColor: '#FFFFFF',
  },

  [`& .${classes.tableRowOdd}`]: {
    backgroundColor: '#f4f4f4',
  },

  [`& .${classes.noGroupsBox}`]: {
    backgroundColor: '#FAFAF9',
    padding: '50px 40px',
    width: '100%',
  },

  [`& .${classes.peopleIcon}`]: {
    marginRight: '10px',
    marginBottom: '-7px',
    color: '#666',
  },

  [`& .${classes.noGroupsFoundTitle}`]: {
    color: '#0076A9',
    textAlign: 'center',
  },

  [`& .${classes.noGroupsFoundText}`]: {
    color: '#999',
    fontFamily: 'Roboto',
    fontSize: '0.75em',
    letterSpacing: '0.4px',
    lineHeight: '16px',
  },

  [`& .${classes.iconColumn}`]: {
    textAlign: 'center',
    width: '30px',
  },

  [`& .${classes.buttonWrapper}`]: {marginTop: '24px'},
}));

const claimTypes = [
  {value: 'groupName', displayElement: mapSamlClaimTypeToDisplay('groupName')},
  {value: 'groupId', displayElement: mapSamlClaimTypeToDisplay('groupId')},
];

const claimTypeTooltip =
  'The claim type must match how the group is returned in the SAML Assertion by your IDP.';
const groupClaimTooltip =
  'The group claim must exactly match a group returned in the SAML Assertion, otherwise users will not be able to log in. Please verify the group claim before submitting.';
const groupAttributeTooltip =
  'The group attribute name specifies the Attribute tag from the SAML Assertion that allowed groups will be matched against.';

function SamlGroupsConfiguration({configurationId}) {
  const dispatch = useDispatch();
  const {successSnackbar, infoSnackbar} = useSnackbar();
  const {setPendingChanges} = usePendingChanges();

  const [claimType, setClaimType] = useState('');
  const [groupClaim, setGroupClaim] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const deletingSamlGroups = useSelector(
    (state) => selectVariable(state, 'deletingSamlGroups') || []
  );

  const samlConfig = useSelector((state) => {
    const {data} = state.data.dataByResource[SAML_CONFIGURATION] || {data: {}};
    if (!isEmpty(data)) {
      return data[configurationId];
    }
    return {};
  });

  const path = `auth/saml/${configurationId}/allowedGroups`;

  const allowedGroups = useSelector((state) => {
    const groups = state.data.dataByResource[SAML_ALLOWED_GROUPS] || {data: {}};
    return Object.keys(groups.data).map((groupId) => groups.data[groupId]);
  });

  const loadSamlAllowedGroups = (clearGroups = false) =>
    dispatch(fetchSamlAllowedGroups(configurationId, clearGroups));
  const {mutate: updateSamlConfig} = useUpdateSamlConfig(configurationId);

  useEffect(() => {
    if (configurationId) {
      loadSamlAllowedGroups(true); // the allowedGroups need to be cleared since they might belong to other configuration
    }
  }, [configurationId]);

  useEffect(() => {
    setPendingChanges(!isEmpty(groupClaim));
  }, [groupClaim]);

  // Delete a single group from the list
  const handleDeleteGroupClick = async (group) => {
    dispatch(
      saveVariable('deletingSamlGroups', [...deletingSamlGroups, group.id])
    );
    setIsSaving(true);
    try {
      await del({path: `${path}/${group.id}`});
      successSnackbar(
        `Members of group ${group.claim} can not login via SAML anymore.`
      );
      loadSamlAllowedGroups(true);
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      setIsSaving(false);
    }
    dispatch(
      saveVariable(
        'deletingSamlGroups',
        deletingSamlGroups.filter((gid) => gid !== group.id)
      )
    );
  };

  // Add a single group to the list
  const handleAddGroupClick = async () => {
    setIsSaving(true);
    try {
      await post({
        path,
        data: {
          claim: sanitizeValues(groupClaim),
          claimType,
        },
      });
      setGroupClaim('');
      loadSamlAllowedGroups();
      successSnackbar(
        `Members of group ${groupClaim} are now allowed to login via SAML.`
      );
    } catch (err) {
      if (err.code === 409) {
        infoSnackbar(
          `Members of ${groupClaim} are already allowed to login via SAML.`
        );
      } else {
        dispatch(handleApiError(err));
      }
    }
    setIsSaving(false);
  };

  const handleUpdateGroupAttribute = async (value) => {
    const data = {groupAttributeName: value};
    updateSamlConfig(data, {
      onSuccess: () => {
        dispatch(fetchSamlConfigSettings());
        successSnackbar(
          `Allowed groups will now be matched against SAML attribute "${value}".`
        );
      },
      onError: (error) => {
        dispatch(handleApiError(error));
      },
    });
  };

  const addButtonEnabled = () => claimType && groupClaim.length > 0;

  const fields = [
    {id: 'claim', text: 'Group claim', type: 'text'},
    {id: 'claimType', text: 'Claim type', type: 'text'},
    {id: 'createdOn', text: 'Created On', type: 'text'},
  ];

  function displayTableValue(element, header) {
    switch (header.id) {
      case 'createdOn':
        return formatDateTime(element[header.id]);
      case 'claimType':
        return mapSamlClaimTypeToDisplay(element[header.id]);
      default:
        return element[header.id];
    }
  }

  const renderTableHeader = () => (
    <TableHead>
      <TableRow className={classes.tableHead}>
        {fields.map((field) => (
          <TableCell
            key={`header-${field.id}`}
            className={classNames(classes.headerText, classes.tableCell)}
          >
            {field.text}
          </TableCell>
        ))}
        <TableCell
          key="remove"
          className={classNames(
            classes.headerText,
            classes.tableCell,
            classes.iconColumn
          )}
        >
          Delete
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const renderTableBody = (
    <TableBody>
      {allowedGroups.map((group, index) => (
        <TableRow
          hover
          key={group.id}
          className={classNames(
            classes.tableRow,
            index % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd
          )}
          data-testid={`allowed-group-row-${group.id}`}
        >
          {fields.map((header) => (
            <TableCell
              key={`${group.id}-${header.id}`}
              className={classNames(classes.tableCell, classes.rowText)}
            >
              {displayTableValue(group, header)}
            </TableCell>
          ))}
          <TableCell
            className={classNames(classes.tableCell, classes.iconColumn)}
          >
            <DeleteIcon
              testId={`delete-group-${group.id}`}
              onClick={() => handleDeleteGroupClick(group)}
              isDeleting={deletingSamlGroups.includes(group.id)}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  const tableBodyNoGroups = (
    <div className={classes.noGroupsBox}>
      <div className={classes.noGroupsFoundTitle}>
        <PeopleIcon className={classes.peopleIcon} />
        No allowed groups found
      </div>
      <Typography align="center" className={classes.noGroupsFoundText}>
        You can add a user group to the list
        <br />
        by clicking the ADD GROUP button above.
      </Typography>
    </div>
  );

  return (
    <Root className={classes.root}>
      <GridN singleColumn>
        <Typography className={classes.inputSectionSubtitle}>
          Update group login settings
        </Typography>

        <TextInputSubmission
          label="Group attribute name"
          tooltip={groupAttributeTooltip}
          defaultValue={samlConfig.groupAttributeName}
          onSubmit={handleUpdateGroupAttribute}
        />
      </GridN>

      <div className={classes.spacer} />

      <GridN singleColumn>
        <Typography className={classes.inputSectionSubtitle}>
          Add a new group
        </Typography>
      </GridN>

      <GridN>
        <SelectMenu
          label="Claim type"
          value={claimType}
          menuList={claimTypes}
          onChange={(value) => setClaimType(value)}
          autoSelectItem
          tooltipText={claimTypeTooltip}
        />

        <TextInput
          value={groupClaim}
          onChange={(value) => setGroupClaim(value)}
          label="Group claim"
          disabled={isEmpty(configurationId) || isSaving}
          placeholder={`Enter the ${
            claimType === 'groupName' ? 'group name' : 'group ID'
          } to be allowed`}
          tooltipText={groupClaimTooltip}
        />

        <div className={classes.buttonWrapper}>
          <Button
            buttonText="Add group"
            buttonTextSaving="Adding group"
            onClick={handleAddGroupClick}
            disabled={!addButtonEnabled()}
            saving={isSaving}
          />
        </div>
      </GridN>
      <div className={classes.spacer} />

      <GridN singleColumn>
        <Typography className={classes.inputSectionSubtitle}>
          Groups allowed to login via Identity Provider
        </Typography>

        {isEmpty(allowedGroups) ? (
          tableBodyNoGroups
        ) : (
          <Table className={classes.table}>
            {renderTableHeader()}
            {renderTableBody}
          </Table>
        )}
      </GridN>
    </Root>
  );
}

SamlGroupsConfiguration.propTypes = {
  configurationId: PropTypes.string.isRequired,
};
export default SamlGroupsConfiguration;
