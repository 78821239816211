import {Link as RouterLink, useLocation} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Skeleton from '@mui/material/Skeleton';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {isEmpty} from 'utils/utils';

import {push} from 'redux/ReduxHistory';

const PREFIX = 'PageHeader';

const classes = {
  spacing: `${PREFIX}-spacing`,
  breadcrumbLink: `${PREFIX}-breadcrumbLink`,
  currentBreadcrumb: `${PREFIX}-currentBreadcrumb`,
  title: `${PREFIX}-title`,
  tab: `${PREFIX}-tab`,
  divider: `${PREFIX}-divider`,
  descriptionText: `${PREFIX}-descriptionText`,
  flexRow: `${PREFIX}-flexRow`,
  actionButtonWrapper: `${PREFIX}-actionButtonWrapper`,
  badge: `${PREFIX}-badge`,
  warning: `${PREFIX}-warning`,
  error: `${PREFIX}-error`,
  success: `${PREFIX}-success`,
  info: `${PREFIX}-info`,
  circle: `${PREFIX}-circle`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.spacing}`]: {
    marginTop: theme.spacing(4),
    padding: 0,
  },

  [`& .${classes.breadcrumbLink}`]: {
    color: 'inherit',
  },

  [`& .${classes.currentBreadcrumb}`]: {
    color: 'rgba(0, 0, 0, 0.87)',
  },

  [`& .${classes.title}`]: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '34px',
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '8px',
    minWidth: '200px',
  },

  [`& .${classes.tab}`]: {
    minWidth: 'unset',
    maxWidth: 'unset',
  },

  [`& .${classes.divider}`]: {
    height: '1px',
    width: '100%',
    backgroundColor: '#dadde2',
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.descriptionText}`]: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: '11px',
  },

  [`& .${classes.flexRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  [`& .${classes.actionButtonWrapper}`]: {
    marginLeft: 'auto',
  },

  [`& .${classes.badge}`]: {
    right: '4px',
    top: '22px',
    height: '8px',
    width: '8px',
  },

  [`& .${classes.warning}`]: {
    backgroundColor: '#A8620D',
    color: '#A8620D',
  },

  [`& .${classes.error}`]: {
    backgroundColor: '#A74132',
    color: '#A74132',
  },

  [`& .${classes.success}`]: {
    backgroundColor: '#176947',
    color: '#176947',
  },

  [`& .${classes.info}`]: {
    backgroundColor: '#1E6EB8',
    color: '#1E6EB8',
  },

  [`& .${classes.circle}`]: {
    display: 'flex',
    width: '8px',
    height: '8px',
    backgroundColor: 'green',
    borderRadius: '50%',
  },
}));

const WARNING = 'warning';
const ERROR = 'error';
const SUCCESS = 'success';
const INFO = 'info';

function PageHeader({
  titleText,
  descriptionText,
  breadcrumbs,
  tabs,
  editButton,
  actionButton,
}) {
  const dispatch = useDispatch();

  const location = useLocation();

  const renderDescriptionText = () =>
    descriptionText ? (
      <Typography className={classes.descriptionText}>
        {descriptionText}
      </Typography>
    ) : null;

  const renderBreadcrumbs = () => {
    if (!isEmpty(breadcrumbs)) {
      return (
        <Breadcrumbs separator="/">
          {breadcrumbs.map((breadcrumb, index) => {
            if (index < breadcrumbs.length - 1) {
              return (
                <Link
                  underline="hover"
                  href={breadcrumb.link}
                  onClick={(event) => {
                    event.preventDefault();
                    dispatch(push(breadcrumb.link));
                  }}
                  className={classes.breadcrumbLink}
                  key={`${breadcrumbs.text}-breadcrumb-link`}
                >
                  {breadcrumb.text}
                </Link>
              );
            }
            return (
              <Typography
                className={classes.currentBreadcrumb}
                key={`${breadcrumbs.text}-final-breadcrumb`}
              >
                {breadcrumb.text}
              </Typography>
            );
          })}
        </Breadcrumbs>
      );
    }
    return null;
  };

  const renderTabs = () => {
    if (!isEmpty(tabs)) {
      return (
        <Tabs
          value={location.pathname}
          indicatorColor="primary"
          variant="scrollable"
        >
          {tabs.map((tabData) => {
            const tabContent = (
              <Tab
                value={tabData.link}
                to={tabData.link}
                key={`${tabData.link}-tab`}
                label={tabData.text}
                classes={{root: classes.tab}}
                notification={tabData.notification}
                disabled={tabData.disabled}
                component={RouterLink}
              />
            );

            // Conditionally display the badge
            if (tabData.notification?.display) {
              return (
                <Badge
                  value={tabData.link}
                  overlap="rectangular"
                  variant="dot"
                  color="primary"
                  classes={{
                    badge: classNames(
                      classes.badge,
                      classes[tabData.notification?.severity]
                    ),
                  }}
                >
                  {tabContent}
                </Badge>
              );
            }
            return tabContent;
          })}
        </Tabs>
      );
    }
    return null;
  };
  return (
    <Root>
      <div className={classes.spacing}>
        {renderBreadcrumbs()}
        <div className={classes.flexRow}>
          <Typography variant="h1" className={classes.title}>
            {titleText || <Skeleton />}
          </Typography>
          <div className={classes.actionButtonWrapper}>
            {actionButton}
            {editButton}
          </div>
        </div>
        {renderDescriptionText()}
        {renderTabs()}
      </div>
      <div className={classes.divider} />
    </Root>
  );
}

PageHeader.propTypes = {
  titleText: PropTypes.string.isRequired,
  descriptionText: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string,
    })
  ),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      notification: PropTypes.shape({
        display: PropTypes.bool.isRequired,
        severity: PropTypes.oneOf([ERROR, WARNING, INFO, SUCCESS]),
      }),
      disabled: PropTypes.bool,
    })
  ),
  actionButton: PropTypes.node,
  editButton: PropTypes.node,
};

PageHeader.defaultProps = {
  descriptionText: '',
  breadcrumbs: [],
  tabs: [],
  actionButton: null,
  editButton: null,
};

export default PageHeader;
