import React from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import OkIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import UnknownIcon from '@mui/icons-material/Help';
import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import ArrowTooltip from 'components/common/tooltip/ArrowTooltip';
import {
  expiredDcCertText,
  expiringSoonDcCertText,
  failedToGetExpiryDateDcCertText,
  failedToGetIpDcCertText,
  okDcCertText,
  someExpiredDcCertText,
  someUnknownDcCertText,
  unknownDcCertText,
  CONNECTOR_CERTS_HEALTHY,
  CONNECTOR_CERTS_EXPIRED,
  CONNECTOR_CERTS_EXPIRING,
  CONNECTOR_CERTS_UNKNOWN,
} from 'utils/constants';
import {isCertAboutToExpire, isCertExpired, isEmpty} from 'utils/utils';
import config from 'config';
import StatusChip from 'components/CAM/display/StatusChip/StatusChip';

const iconStyleMap = {
  error: {
    color: '#C23934',
  },
  warning: {
    color: '#FF9600',
  },
  ok: {
    color: '#04844B',
  },
  unknown: {
    color: '#706E6B',
  },
};
const PREFIX = 'CertExpiryStatus';

const classes = {
  centeredGrid: `${PREFIX}-centeredGrid`,
  statusIcon: `${PREFIX}-statusIcon`,
};

const Root = styled('div')(({...props}) => ({
  [`& .${classes.centeredGrid}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.statusIcon}`]: {
    display: 'flex',
    color: iconStyleMap[props.iconType]?.color || '#FFF',
    marginRight: '0.5rem',
    width: '24px',
  },
}));

const getCertChipType = (status) => {
  switch (status) {
    case CONNECTOR_CERTS_HEALTHY:
      return 'ok';
    case CONNECTOR_CERTS_EXPIRED:
      return 'error';
    case CONNECTOR_CERTS_EXPIRING:
      return 'warning';
    default:
      return 'default';
  }
};

function getConnectivityHealth(connector) {
  const result = {
    ok: [],
    warning: [],
    error: [],
    unknownHostAndExpiryDate: [],
    hostWithUnknownExpiryDate: [],
  };

  if (!isEmpty(connector.components)) {
    let {dcCertExpiry} = connector.components;

    if (typeof dcCertExpiry === 'string') {
      dcCertExpiry = [
        {
          host: 'unknown',
          expiryDate: dcCertExpiry,
        },
      ];
    }

    if (Array.isArray(dcCertExpiry)) {
      dcCertExpiry.forEach((cert) => {
        const {expiry_date: expiryDate, host} = cert;

        if (expiryDate === 'unavailable' && host === 'unavailable') {
          result.unknownHostAndExpiryDate.push(host);
        } else if (expiryDate === 'unavailable') {
          result.hostWithUnknownExpiryDate.push(host);
        } else if (isCertExpired(expiryDate)) {
          result.error.push(host);
        } else if (isCertAboutToExpire(expiryDate)) {
          result.warning.push(host);
        } else {
          result.ok.push(host);
        }
      });
    }
  }

  return result;
}

function CertExpiryStatus({fieldId, connector}) {
  let icon;
  let title;
  let status;
  let iconType;

  const {
    error,
    warning,
    ok,
    unknownHostAndExpiryDate,
    hostWithUnknownExpiryDate,
  } = getConnectivityHealth(connector);
  const testId = fieldId || connector.connectorId;
  const iconTestId = `${testId}-cert-expiry-icon`;
  const textTestId = `${testId}-cert-expiry-text`;

  const hasUnknownHostsAndExpiryDates = unknownHostAndExpiryDate.length > 0;
  const hasHostsWithUnknownExpiryDates = hostWithUnknownExpiryDate.length > 0;
  const hasErrors = error.length > 0;
  const hasWarnings = warning.length > 0;
  const hasOk = ok.length > 0;

  if (
    hasUnknownHostsAndExpiryDates &&
    !hasHostsWithUnknownExpiryDates &&
    !hasErrors &&
    !hasWarnings &&
    !hasOk
  ) {
    // dc certs have unknown hosts and expiry dates
    icon = <ErrorIcon data-testid={iconTestId} />;
    iconType = 'error';
    title = failedToGetIpDcCertText;
    status = CONNECTOR_CERTS_EXPIRED;
  } else if (
    hasHostsWithUnknownExpiryDates &&
    !hasErrors &&
    !hasWarnings &&
    !hasOk
  ) {
    // dc certs have hosts but all have unknown expiry dates
    icon = <ErrorIcon data-testid={iconTestId} />;
    iconType = 'error';
    title = failedToGetExpiryDateDcCertText;
    status = CONNECTOR_CERTS_EXPIRED;
  } else if (hasErrors && !hasWarnings && !hasOk) {
    // all dc certs are expired
    icon = <ErrorIcon data-testid={iconTestId} />;
    iconType = 'error';
    title = expiredDcCertText;
    status = CONNECTOR_CERTS_EXPIRED;
  } else if (hasErrors) {
    // At least one dc cert is expired
    icon = <WarningIcon data-testid={iconTestId} />;
    iconType = 'warning';
    title = someExpiredDcCertText;
    status = CONNECTOR_CERTS_EXPIRED;
  } else if (
    (hasWarnings || hasOk) &&
    (hasUnknownHostsAndExpiryDates || hasHostsWithUnknownExpiryDates)
  ) {
    // all known dc certs are ok or about to expire but has unknown certs
    icon = <WarningIcon data-testid={iconTestId} />;
    iconType = 'warning';
    title = someUnknownDcCertText;
    status = CONNECTOR_CERTS_HEALTHY;
  } else if (hasWarnings) {
    // At least one dc cert is about to expire
    icon = <WarningIcon data-testid={iconTestId} />;
    iconType = 'warning';
    title = expiringSoonDcCertText;
    status = CONNECTOR_CERTS_EXPIRING;
  } else if (hasOk) {
    // all dc certs are ok
    icon = <OkIcon data-testid={iconTestId} />;
    iconType = 'ok';
    title = okDcCertText;
    status = CONNECTOR_CERTS_HEALTHY;
  } else {
    // No data found
    icon = <UnknownIcon data-testid={iconTestId} />;
    iconType = 'unknown';
    title = unknownDcCertText;
    status = CONNECTOR_CERTS_UNKNOWN;
  }

  return (
    <Root iconType={iconType}>
      {config.isConnectorWizardEnabled() ? (
        <div className={classes.centeredGrid}>
          <Grid data-testid={textTestId}>
            <ArrowTooltip title={title} placement="left">
              <StatusChip label={status} type={getCertChipType(status)} />
            </ArrowTooltip>
          </Grid>
        </div>
      ) : (
        <div className={classes.centeredGrid}>
          <ArrowTooltip title={title} placement="left">
            {React.cloneElement(icon, {className: classes.statusIcon})}
          </ArrowTooltip>
          <Grid data-testid={textTestId}>{status}</Grid>
        </div>
      )}
    </Root>
  );
}

CertExpiryStatus.propTypes = {
  fieldId: PropTypes.string,
  connector: PropTypes.object,
};

CertExpiryStatus.defaultProps = {
  fieldId: '',
  connector: {
    components: {},
  },
};

export default CertExpiryStatus;
