import {useState} from 'react';
import Chip from '@mui/material/Chip';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Link} from 'react-router-dom';
import {stylesFromFigma} from 'themes/stylesFromFigma';

const PREFIX = 'ChipDropdownSelector';

const classes = {
  root: `${PREFIX}-root`,
  outlined: `${PREFIX}-outlined`,
  icon: `${PREFIX}-icon`,
  grey: `${PREFIX}-grey`,
  link: `${PREFIX}-link`,
};

const customColor = 'white';

const StyledChip = styled(({chip: ChipComponent, ...props}) => (
  <ChipComponent {...props} />
))(() => ({
  [`&.${classes.root}`]: {
    backgroundColor: `${customColor}`,
  },

  [`&.${classes.grey}`]: {
    ...stylesFromFigma.chipTags.grey,
  },

  marginRight: '2px',
}));

const Root = styled('div')(({theme: {palette}}) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  justifyContent: 'flex-start',

  [`& .${classes.link}`]: {
    color: palette.surface.grey,
    '&:hover': {
      color: palette.primary.main,
    },
    cursor: 'pointer',
    textDecoration: 'none',
  },
}));

function ConditionalLink({children, to, toggleClickable, ...rest}) {
  if (toggleClickable) {
    return (
      <Link to={to} className={classes.link} {...rest}>
        {children}
      </Link>
    );
  }
  return children;
}

function ChipDropdownSelector(props) {
  const {items, size, variant, toggleClickable} = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const remainingItems = items.slice(2);
  const showDropdownButton = remainingItems.length > 0;

  return (
    <Root>
      {items.slice(0, 2).map((item) => (
        <ConditionalLink
          key={item.id}
          to={item.link}
          toggleClickable={toggleClickable}
        >
          <StyledChip
            chip={Chip}
            key={item.id}
            label={item.label}
            classes={{
              root: classNames(classes.root, classes.grey),
            }}
            size={size}
            variant={variant}
            onClick={toggleClickable ? () => {} : undefined}
          />
        </ConditionalLink>
      ))}
      {showDropdownButton && (
        <>
          <StyledChip
            chip={Chip}
            label={`+${remainingItems.length}`}
            classes={{
              root: classNames(classes.root, classes.grey),
            }}
            size={size}
            variant={variant}
            onClick={handleClick}
          />
          <Menu
            id="dropdown-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {remainingItems.map((item) => (
              <ConditionalLink
                key={item.id}
                to={item.link}
                toggleClickable={toggleClickable}
                style={{color: 'inherit', textDecoration: 'none'}}
              >
                <MenuItem
                  key={item.id}
                  disableRipple={!toggleClickable}
                  style={{cursor: toggleClickable ? 'pointer' : 'default'}}
                >
                  {item.label}
                </MenuItem>
              </ConditionalLink>
            ))}
          </Menu>
        </>
      )}
    </Root>
  );
}

ChipDropdownSelector.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
  size: PropTypes.string,
  variant: PropTypes.string,
  toggleClickable: PropTypes.bool,
};

ChipDropdownSelector.defaultProps = {
  size: 'small',
  variant: 'outlined',
  toggleClickable: false,
};

export default ChipDropdownSelector;
