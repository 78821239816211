import {Grid, IconButton, Typography, Tooltip} from '@mui/material';
import PropTypes from 'prop-types';
import styled from '@mui/material/styles/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useState} from 'react';
import {debounce} from 'lodash';
import CodeTextArea from 'components/CAM/display/CodeTextArea/CodeTextArea';
import CopyIcon from 'icons/CopyIcon';
import useSnackbar from 'hooks/useSnackbar';

const PREFIX = 'PayloadSection';

const classes = {
  expandButton: `${PREFIX}-expandButton`,
  codeTextArea: `${PREFIX}-codeTextArea`,
};

const PayloadContainer = styled(Grid)(({error}) => ({
  backgroundColor: '#F8F8FB',
  borderRadius: '4px',
  padding: '12px',
  width: '100%',
  overflow: 'auto',
  border: error ? '1px solid #C23934' : '1px solid #C7C9D1',

  [`& .${classes.expandButton}`]: {
    color: '#555967',
  },
}));

const CodeTextAreaContainer = styled(Grid)(({showMore}) => ({
  width: '100%',
  maxHeight: !showMore ? 'none' : '300px',
  overflow: 'auto',
  margin: '24px 0',
  [`& .${classes.codeTextArea}`]: {
    width: '100%',
    height: 'auto',
    overflow: 'auto',
  },
}));

const ShowText = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: '16px',
  letterSpacing: '0.03em',
  textAlign: 'left',
  color: '#555967',
  padding: '0px',
  margin: '0px',
}));

export default function PayloadSection({
  selectedWebhookPayload,
  setSelectedWebhookPayload,
  isPayloadValid,
  setIsPayloadValid,
}) {
  const {successSnackbar} = useSnackbar();
  const [showMore, setShowMore] = useState(false);

  const handleClick = async () => {
    await navigator.clipboard.writeText(selectedWebhookPayload);
    successSnackbar('Copied to clipboard');
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const payloadErrorMessage = (value) => {
    if (value && value.length > 4096) {
      setIsPayloadValid(false);
      return 'Length must be between 1 and 4096 characters';
    }

    if (!isPayloadValid) {
      return 'Invalid JSON';
    }

    return '';
  };

  return (
    <>
      <PayloadContainer
        container
        position="relative"
        gap="16px"
        error={!!payloadErrorMessage()}
      >
        <Tooltip title="Copy to clipboard">
          <IconButton
            onClick={handleClick}
            sx={{position: 'absolute', right: '0px', top: '0px'}}
          >
            <CopyIcon iconColor="#555967" size="small" />
          </IconButton>
        </Tooltip>
        <CodeTextAreaContainer showMore={showMore}>
          <CodeTextArea
            code={selectedWebhookPayload}
            className={classes.codeTextArea}
            onChange={debounce((payload) => {
              try {
                const parsedPayload = JSON.parse(payload);
                setSelectedWebhookPayload(
                  JSON.stringify(parsedPayload, null, 4)
                );
                setIsPayloadValid(true);
              } catch (err) {
                setIsPayloadValid(!payload);
                setSelectedWebhookPayload(payload);
              }
            }, 500)}
          />
        </CodeTextAreaContainer>

        <IconButton
          onClick={toggleShowMore}
          sx={{
            position: 'absolute',
            right: '0px',
            bottom: '0px',
            '&:disabled': {
              opacity: 0.5,
            },
          }}
          disableRipple
        >
          {!showMore ? (
            <Grid container alignItems="center">
              <ShowText>SHOW LESS</ShowText>
              <ExpandLessIcon className={classes.expandButton} />
            </Grid>
          ) : (
            <Grid container alignItems="center">
              <ShowText>SHOW MORE</ShowText>
              <ExpandMoreIcon className={classes.expandButton} />
            </Grid>
          )}
        </IconButton>
      </PayloadContainer>
      {!!payloadErrorMessage(selectedWebhookPayload) && (
        <Typography color="error" sx={{fontSize: '13px'}}>
          {payloadErrorMessage(selectedWebhookPayload)}
        </Typography>
      )}
    </>
  );
}

PayloadSection.propTypes = {
  selectedWebhookPayload: PropTypes.string,
  setSelectedWebhookPayload: PropTypes.func,
  setIsPayloadValid: PropTypes.func.isRequired,
  isPayloadValid: PropTypes.bool,
};

PayloadSection.defaultProps = {
  selectedWebhookPayload: '',
  setSelectedWebhookPayload: () => {},
  isPayloadValid: true,
};
