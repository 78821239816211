import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import TableSortLabel from '@mui/material/TableSortLabel';
import KebabIcon from '@mui/icons-material/MoreVert';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  checkItem,
  checkItems,
  uncheckAll,
  uncheckItem,
} from 'redux/actions/tableSelectActions';
import {
  DEPLOYMENTS,
  POOL_MACHINES,
  POOL_USERS,
  WEBHOOKS,
} from 'utils/constants';
import {mapResourceToIdField} from 'utils/Mappings';
import {
  selectCheckedRows,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import CAMMenu from '../menu/Menu';
import CAMTableCell from './TableCell';

const PREFIX = 'SlimTable';

const classes = {
  disabledCell: `${PREFIX}-disabledCell`,
  disabledRow: `${PREFIX}-disabledRow`,
  deploymentsTableRow: `${PREFIX}-deploymentsTableRow`,
  muiTableHead: `${PREFIX}-muiTableHead`,
  muiTableHeadText: `${PREFIX}-muiTableHeadText`,
  tableBulkKebab: `${PREFIX}-tableBulkKebab`,
  tableCellIcon: `${PREFIX}-tableCellIcon`,
  tableCheckbox: `${PREFIX}-tableCheckbox`,
  tableKebab: `${PREFIX}-tableKebab`,
  noDataMessage: `${PREFIX}-noDataMessage`,
};

const Root = styled('div')(({theme, ...props}) => ({
  [`& .${classes.disabledCell}`]: {
    color: 'rgba(0,0,0,0.5)',
  },
  [`& .${classes.disabledRow}`]: {
    backgroundColor: theme.palette.surface.light,
  },
  [`& .${classes.deploymentsTableRow}`]: {
    height: '42px',
  },
  [`& .${classes.muiTableHead}`]: {
    backgroundColor: theme.palette.surface.main,
    textTransform: 'uppercase',
  },
  [`& .${classes.muiTableHeadText}`]: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  [`& .${classes.tableBulkKebab}`]: {
    color: theme.palette.primary.main,
    padding: 0,
    margin: '0 1rem 0 0',
  },
  [`& .${classes.tableCellIcon}`]: {
    width: '50px',
  },
  [`& .${classes.tableCheckbox}`]: {
    padding: '0',
  },
  [`& .${classes.tableKebab}`]: {
    padding: 0,
    margin: '0 1rem 0 0',
  },
  [`& .${classes.noDataMessage}`]: {
    backgroundColor: '#FAFAF9',
    padding: '50px 40px',
    textAlign: 'center',
    color: '#0076A9',
    height: props.isDeploymentTable ? '630px' : 'inherit',
  },
}));

function SlimTable({
  isFetching,
  data,
  resource,
  fields,
  actions,
  bulkActions,
  shouldRenderKebabs,
  noDataMessage,
  loadingMessage,
  handleChangeSorting,
  sorting,
  shouldRenderCheckboxes,
  onCellClick,
}) {
  const isDeploymentTable = resource === DEPLOYMENTS;
  const isPoolMachinesTable = resource === POOL_MACHINES;
  const isPoolUsersTable = resource === POOL_USERS;
  const isPoolWebhooksTable = resource === WEBHOOKS;

  const dispatch = useDispatch();

  const [kebabItem, setKebabItem] = useState({});

  const [bulkActionsAnchorEl, setBulkActionsAnchorEl] = useState(null);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [isBulkActionsOpen, setIsBulkActionsOpen] = useState(false);
  const [isActionsOpen, setIsActionsOpen] = useState(false);

  const Actions = actions;
  const BulkActions = bulkActions;

  const idField = mapResourceToIdField(resource);

  // Deployment filter related state
  const selectedDeployment = useSelector((state) =>
    selectSelectedDeployment(state)
  );

  const checkedItems = useSelector((state) =>
    selectCheckedRows(state, resource)
  );
  const numCheckedItems = data.filter((item) => checkedItems[item.id]).length;

  const handleCloseBulkActionsMenu = () => {
    setIsBulkActionsOpen(false);
    setBulkActionsAnchorEl(null);
  };

  const handleDeleteAction = (item) => {
    dispatch(uncheckItem(resource, item));
  };

  const handleDeleteBulkAction = () => {
    dispatch(uncheckAll(resource));
  };

  const handleCloseActionsMenu = () => {
    setActionsAnchorEl(null);
    setIsActionsOpen(false);
  };

  const callHandleChangeSorting = (field) => {
    if (!field.sortable || isFetching) {
      return;
    }
    handleChangeSorting(field);
  };

  const onClickBulkKebab = (event) => {
    event.stopPropagation();
    setBulkActionsAnchorEl(event.currentTarget);
  };

  const onClickKebab = (event, item) => {
    event.stopPropagation();
    setActionsAnchorEl(event.currentTarget);
    setKebabItem(item);
  };

  const isEveryItemChecked = () =>
    data.length === numCheckedItems && data.length > 0;
  const isAnyItemChecked = () => numCheckedItems > 0;
  const isItemDeleting = (item) =>
    ['deleting', 'deleted'].includes(item.status);

  const isItemChecked = (item) => {
    const itemIsChecked = checkedItems[item.id] || false;
    return itemIsChecked && !isItemDeleting(item) && !item.isDisabled;
  };

  const handleCheckItem = (item, checked) => {
    if (checked) {
      dispatch(checkItem(resource, item));
    } else {
      dispatch(uncheckItem(resource, item));
    }
  };

  const toggleAllChecked = () => {
    if (!isEveryItemChecked()) {
      dispatch(
        checkItems(
          resource,
          data.filter((item) => !isItemDeleting(item) && !item.isDisabled)
        )
      );
    } else {
      dispatch(uncheckAll(resource));
    }
  };

  const renderBulkActionsMenu = () =>
    shouldRenderKebabs && (
      <CAMMenu
        anchorEl={bulkActionsAnchorEl}
        open={isBulkActionsOpen}
        onClose={handleCloseBulkActionsMenu}
        anchorOrigin={{vertical: 'bottom', horizontal: 10}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <BulkActions
          onClose={handleCloseBulkActionsMenu}
          onDelete={handleDeleteBulkAction}
        />
      </CAMMenu>
    );

  const renderActionsMenu = () =>
    shouldRenderKebabs && (
      <CAMMenu
        anchorEl={actionsAnchorEl}
        open={isActionsOpen}
        onClose={handleCloseActionsMenu}
        anchorOrigin={{vertical: 'bottom', horizontal: 10}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <Actions
          item={kebabItem}
          onClose={handleCloseActionsMenu}
          onDelete={handleDeleteAction}
        />
      </CAMMenu>
    );

  const renderCheckbox = (options) => (
    <TableCell align="center" className={classes.tableCellIcon}>
      <Checkbox
        color="primary"
        onChange={options.handleChange}
        checked={options.isChecked}
        indeterminate={options.isIndeterminate}
        disabled={options.isDisabled}
        size="small"
        inputProps={{'data-testid': options.id}}
        classes={{root: classes.tableCheckbox}}
      />
    </TableCell>
  );

  const renderBulkActionsKebab = () =>
    shouldRenderKebabs && (
      <IconButton
        data-testid="kebab-header"
        size="small"
        onClick={onClickBulkKebab}
        classes={{root: classes.tableBulkKebab}}
      >
        <KebabIcon />
      </IconButton>
    );

  const renderActionsKebab = (row) => (
    <TableCell align="right" className={classes.tableCellIcon}>
      {shouldRenderKebabs && (
        <IconButton
          data-testid={`kebab-${row.id}`}
          size="small"
          onClick={(event) => onClickKebab(event, row)}
          classes={{root: classes.tableKebab}}
          disabled={isFetching}
        >
          <KebabIcon />
        </IconButton>
      )}
    </TableCell>
  );

  const renderTableHeader = () => (
    <TableHead>
      <TableRow classes={{root: classes.muiTableHead}}>
        {shouldRenderCheckboxes &&
          renderCheckbox({
            id: 'checkbox-head',
            handleChange: toggleAllChecked,
            isChecked: isEveryItemChecked(),
            isIndeterminate: isAnyItemChecked() && !isEveryItemChecked(),
            isDisabled: data.length === 0,
          })}
        {fields.map((field) => (
          <TableCell
            key={`header-${field.id}`}
            classes={{root: classes.muiTableHeadText}}
          >
            <TableSortLabel
              data-testid={`sort-${field.id}`}
              className={classes.columnHeaderText}
              hideSortIcon
              active={field.sortable === true && field.id === sorting.sortKey}
              onClick={() => callHandleChangeSorting(field)}
              direction={sorting.sortAsc === true ? 'asc' : 'desc'}
            >
              {field.text}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right" className={classes.tableCellIcon}>
          {isAnyItemChecked() && renderBulkActionsKebab()}
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const renderTableCell = (row, field) => {
    const isCopyable =
      isDeploymentTable &&
      field.id === 'deploymentId' &&
      row.status === 'active';

    const cellProps = {
      key: field.id,
      dataTestId: `${field.id}-${row[field.id]}`,
      displayText: row[field.id] || '',
      type: field.type,
      row,
      copyable: isCopyable,
      styling: {
        muiTableCellRoot: classNames({
          [classes.disabledCell]: row.isDisabled,
        }),
      },
    };

    if (isDeploymentTable && field.id === 'deploymentName') {
      cellProps.onClick = () => {
        onCellClick(row);
      };
      cellProps.deleting = row.status === 'deleting';
      cellProps.selected =
        selectedDeployment?.deploymentId === row.deploymentId;
    }

    if (isPoolMachinesTable && field.id === 'machineName') {
      cellProps.onClick = () => onCellClick(row);
    }

    if ((isPoolUsersTable || isPoolWebhooksTable) && field.id === 'name') {
      cellProps.onClick = () => onCellClick(row);
    }

    return <CAMTableCell {...cellProps} />;
  };

  const renderTableRow = (row) => (
    <TableRow
      key={`row-${row[idField]}`}
      data-testid={`row-${row[idField]}`}
      hover={!row.isDisabled}
      classes={{
        root: classNames({
          [classes.disabledRow]: row.isDisabled,
          [classes.deploymentsTableRow]: isDeploymentTable,
        }),
      }}
    >
      {shouldRenderCheckboxes &&
        renderCheckbox({
          id: `checkbox-${row.id}`,
          handleChange: (_, checked) => handleCheckItem(row, checked),
          isChecked: isItemChecked(row),
          isIndeterminate: false,
          isDisabled: row.isDisabled || false,
        })}
      {fields.map((field) => renderTableCell(row, field))}
      {renderActionsKebab(row)}
    </TableRow>
  );

  const renderLoadingMessage = () =>
    isFetching && <div className={classes.noDataMessage}>{loadingMessage}</div>;

  const renderNoDataMessage = () =>
    !isFetching &&
    !data?.length && (
      <div className={classes.noDataMessage}>{noDataMessage}</div>
    );

  return (
    <Root isDeploymentTable={isDeploymentTable}>
      <Table size="small">
        {renderTableHeader()}
        {!isFetching && (
          <TableBody data-testid="slim-table-body">
            {data.map((user) => renderTableRow(user))}
          </TableBody>
        )}
      </Table>
      {renderActionsMenu()}
      {renderBulkActionsMenu()}
      {renderLoadingMessage()}
      {renderNoDataMessage()}
    </Root>
  );
}

SlimTable.propTypes = {
  isFetching: PropTypes.bool,
  data: PropTypes.array,
  resource: PropTypes.string,
  fields: PropTypes.array,
  actions: PropTypes.func,
  bulkActions: PropTypes.func,
  shouldRenderKebabs: PropTypes.bool,
  noDataMessage: PropTypes.string,
  handleChangeSorting: PropTypes.func,
  sorting: PropTypes.object,
  loadingMessage: PropTypes.string,
  shouldRenderCheckboxes: PropTypes.bool,
  onCellClick: PropTypes.func,
};

SlimTable.defaultProps = {
  isFetching: false,
  data: [],
  resource: PropTypes.string,
  fields: [],
  actions: () => null,
  bulkActions: () => null,
  shouldRenderKebabs: true,
  noDataMessage: 'No data available',
  loadingMessage: 'Loading ...',
  handleChangeSorting: () => null,
  sorting: {},
  shouldRenderCheckboxes: true,
  onCellClick: () => {},
};

export default SlimTable;
