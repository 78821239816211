export const OPEN_DEPLOYMENT_FILTER_DIALOG = 'OPEN_DEPLOYMENT_FILTER_DIALOG';

export const openDeploymentFilterDialog = () => ({
  type: OPEN_DEPLOYMENT_FILTER_DIALOG,
});

export const CLOSE_DEPLOYMENT_FILTER_DIALOG = 'CLOSE_DEPLOYMENT_FILTER_DIALOG';

export const closeDeploymentFilterDialog = () => ({
  type: CLOSE_DEPLOYMENT_FILTER_DIALOG,
});
