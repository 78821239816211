import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import classNames from 'classnames';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import PropTypes from 'prop-types';
import {useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import connectorInstallFailedIcon from 'icons/connectorInstallFailed_24px.svg';
import connectorInstallProgressIcon from 'icons/connectorProgress_24px.svg';
import computerIcon from 'icons/computer_24px.svg';
import useDetectOverflow from 'hooks/useDetectOverflow';
import {push} from 'redux/actions/HistoryActions';
import {
  readNotification,
  removeNotification,
} from 'redux/actions/notificationActions';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';

const PREFIX = 'NotificationItem';

const classes = {
  root: `${PREFIX}-root`,
  lessInfo: `${PREFIX}-lessInfo`,
  typeText: `${PREFIX}-typeText`,
  titleText: `${PREFIX}-titleText`,
  infoText: `${PREFIX}-infoText`,
  actionText: `${PREFIX}-actionText`,
  expandIcon: `${PREFIX}-expandIcon`,
  clearNotificationButton: `${PREFIX}-clearNotificationButton`,
  readNotification: `${PREFIX}-readNotification`,
  noPadding: `${PREFIX}-noPadding`,
  detailsButton: `${PREFIX}-detailsButton`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    marginBottom: '0.5em',
    marginTop: '1em',
  },

  [`& .${classes.lessInfo}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  [`& .${classes.typeText}`]: {
    fontSize: '0.75em',
    color: theme.palette.surface.grey,
    letterSpacing: '0.025em',
    lineHeight: '1em',
  },

  [`& .${classes.titleText}`]: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '0.875em',
    letterSpacing: '0.016em',
    lineHeight: '1.25em',
    cursor: 'pointer',
    'word-wrap': 'break-word',
    display: 'inline',
  },

  [`& .${classes.infoText}`]: {
    color: theme.palette.surface.grey,
    fontSize: '0.875em',
    fontWeight: 400,
    letterSpacing: '0.006em',
    lineHeight: '1.5em',
    marginTop: '0.25rem',
    marginBottom: 0,
  },

  [`& .${classes.actionText}`]: {
    color: '#0076A9',
    fontSize: '0.875em',
    fontWeight: 700,
    lineHeight: '1.5em',
    cursor: 'pointer',
  },

  [`& .${classes.expandIcon}`]: {
    cursor: 'pointer',
    verticalAlign: 'text-bottom',
    opacity: 0.5,
    marginBottom: '-5px',
  },

  [`& .${classes.clearNotificationButton}`]: {
    color: theme.palette.surface.grey,
    fontSize: '0.89em',
    fontWeight: '500',
    float: 'right',
    padding: '0',
    cursor: 'pointer',
  },

  [`& .${classes.readNotification}`]: {
    opacity: 0.5,
  },

  [`& .${classes.noPadding}`]: {padding: 0},

  [`& .${classes.detailsButton}`]: {
    padding: '4px 8px',
    fontSize: '0.75rem',
    width: '100%',
  }
}));

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('en-US');

function NotificationItem({notification}) {
  const {
    type,
    timePosted,
    title,
    actionText,
    key,
    link,
    moreInfo,
    read,
    stylingIndex,
    wordStyling,
  } = notification;


  const dispatch = useDispatch();

  const readNotificationAction = () => dispatch(readNotification(key));
  const removeNotificationAction = () => dispatch(removeNotification(key));
  const redirect = () => dispatch(push(link));

  const [showLess, setShowLess] = useState(true);
  const textEl = useRef(null);
  const showArrow = useDetectOverflow(textEl);

  const arrows =
    showArrow &&
    (showLess ? (
      <ExpandMore
        className={classes.expandIcon}
        data-testid={`show-more-${key}`}
        onClick={() => setShowLess(false)}
      />
    ) : (
      <ExpandLess
        className={classes.expandIcon}
        data-testid={`show-less-${key}`}
        onClick={() => setShowLess(true)}
      />
    ));

  const getNotificationIcon = () => {
    // Icons look best when they are 24x24px
    switch (type) {
      case 'Deployments': {
        return computerIcon;
      }
      case 'Connector Install Progress': {
        return connectorInstallProgressIcon;
      }
      case 'Connector Install Failed': {
        return connectorInstallFailedIcon;
      }
      default: {
        return computerIcon;
      }
    }
  };

  const readNotificationAndRedirect = () => {
    readNotificationAction(key);
    redirect(link);
  };

  return (
    <Root className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid
          item
          container
          xs={1}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <img
            src={getNotificationIcon()}
            alt="computer icon"
            className={classNames({[classes.readNotification]: read})}
          />
        </Grid>

        <Grid item xs={9}>
          <Grid container>
            <Grid item>
              <Grid container>
                <Grid item xs={12} className={classes.noPadding}>
                  <Typography className={classes.typeText}>
                    {type}・{timeAgo.format(timePosted)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={11}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    data-testid={`title-text-${key}`}
                    className={classNames(classes.titleText, {
                      [classes.readNotification]: read,
                    })}
                    onClick={readNotificationAndRedirect}
                  >
                    {title}
                  </Typography>
                  {arrows}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {moreInfo && (
                  <p
                    data-testid={`more-info-${key}`}
                    className={classNames(classes.infoText, {
                      [classes.lessInfo]: showLess,
                    })}
                    ref={textEl}
                  >
                    {moreInfo.split(' ').map((word, index) => (
                      <span
                        style={index === stylingIndex ? wordStyling : {}}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                      >
                        {word}{' '}
                      </span>
                    ))}
                  </p>
                )}
                {link && !showLess && (
                  <>
                    <br />
                    <CAMButton
                      onClick={readNotificationAndRedirect}
                      buttonText="Check Details"
                      color="primary"
                      variant="contained"
                      customClass={classes.detailsButton}
                    />
                  </>
                )}
              </Grid>
            </Grid>

            {!read && (
              <Grid item xs={12}>
                <Typography
                  data-testid={`action-text-${key}`}
                  className={classes.actionText}
                  onClick={() => readNotificationAction(key)}
                >
                  {actionText}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={1}>
          <Typography
            data-testid={`close-notification-button-${key}`}
            className={classes.clearNotificationButton}
            onClick={() => removeNotificationAction(key)}
          >
            Clear
          </Typography>
        </Grid>
      </Grid>
    </Root>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object,
};

NotificationItem.defaultProps = {
  notification: {
    title: 'Notification Title',
    type: 'Notification Type',
    timePosted: Date.now(),
    moreInfo: '',
    actionText: 'Mark as read',
    link: '',
  },
};

export default NotificationItem;
