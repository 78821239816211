import {useMutation} from 'react-query';
import {put} from 'api/Api';
import {useSelector} from 'react-redux';
import {mapResourceToPath} from 'utils/Mappings';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {CONNECTOR_SETTINGS} from 'utils/constants';

const updateCacSettings = async (deploymentId, connectorId, settings) => {
  let path = mapResourceToPath(CONNECTOR_SETTINGS, {deploymentId});

  if (connectorId) {
    path += `/${connectorId}`;
  }

  await put({path, data: settings});
};

const useUpdateCacSettings = (connectorId, options = {}) => {
  const {deploymentId} = useSelector(selectSelectedDeployment);
  return useMutation(
    (settings) => updateCacSettings(deploymentId, connectorId, settings),
    options
  );
};

export default useUpdateCacSettings;
