import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CAMPagination from 'components/common/Pagination';
import CAMSearchField from 'components/common/SearchField';
import SlimTable from 'components/common/SlimTable';
import useDebounce from 'hooks/useDebounce';
import useSnackbar from 'hooks/useSnackbar';
import {setDeploymentFilter} from 'redux/actions/deploymentFilterActions';
import {closeDeploymentFilterDialog} from 'redux/actions/deploymentFilterDialogActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {
  DEFAULT_ROWS_PER_PAGE,
  DEPLOYMENTS,
  TABLE_CELL_LINK,
  TABLE_CELL_TEXT,
} from 'utils/constants';
import {
  selectDataForTable,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import {formatDateTime} from 'utils/utils';

const PREFIX = 'DeploymentFilterDialog';

const classes = {
  title: `${PREFIX}-title`,
  button: `${PREFIX}-button`,
  dialogRoot: `${PREFIX}-dialogRoot`,
  dialogContentRoot: `${PREFIX}-dialogContentRoot`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.title}`]: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '20px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: '24px',
  },

  [`& .${classes.button}`]: {
    width: '80px',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
  },

  [`& .${classes.dialogRoot}`]: {
    heigh: '50vh',
  },

  [`& .${classes.dialogContentRoot}`]: {
    height: '770px',
  },
}));

const tableFields = [
  {id: 'deploymentName', text: 'Name', type: TABLE_CELL_LINK},
  {id: 'deploymentId', text: 'Deployment ID', type: TABLE_CELL_TEXT},
  {id: 'createdOn', text: 'Created On', type: TABLE_CELL_TEXT},
];

const DEFAULT_PARAMS = {
  page: 0,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
};

function DeploymentFilterDialog() {
  const dispatch = useDispatch();

  const {successSnackbar} = useSnackbar();
  const [params, setParams] = useState(DEFAULT_PARAMS);
  const [search, setSearch] = useState('');
  const [searchDebounced] = useDebounce(search, 1500);
  const {open} = useSelector(
    (state) => state.deploymentFilterDialog || {open: false}
  );
  const {deploymentId, deploymentName} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {
    data: deployments,
    total,
    isFetching,
  } = useSelector((state) => selectDataForTable(state, DEPLOYMENTS));

  const onClose = () => {
    dispatch(closeDeploymentFilterDialog());
  };

  const handleChangePage = (_, newPage) =>
    setParams((oldParams) => ({...oldParams, page: newPage}));
  const handleChangeRowsPerPage = (e) =>
    setParams((oldParams) => ({
      ...oldParams,
      page: 0,
      rowsPerPage: e.target.value,
    }));

  const handleSearchKeyClear = () => {
    setSearch('');
  };

  const handleSearchKeyChange = (e) => {
    setSearch(e.target.value);
  };

  const handleEnterPress = async () => {
    if (!isFetching && total === 1) {
      dispatch(setDeploymentFilter(deployments[0]));
    }
  };

  useEffect(() => {
    if (!deploymentId) {
      return;
    }

    if (open) {
      successSnackbar(`Viewing resources for deployment "${deploymentName}"`);
    }

    dispatch(closeDeploymentFilterDialog());
  }, [deploymentId]);

  useEffect(() => {
    if (!open) {
      setParams(DEFAULT_PARAMS);
      setSearch('');
    }
  }, [open]);

  useEffect(() => {
    dispatch(
      fetchTableData(
        DEPLOYMENTS,
        params.page,
        params.rowsPerPage,
        {},
        searchDebounced
      )
    );
  }, [JSON.stringify(params), searchDebounced]);

  const prepareDeployments = () =>
    deployments.map((deployment) => ({
      ...deployment,
      createdOn: formatDateTime(deployment.createdOn),
    }));

  const onDeploymentSelect = (deployment) => {
    dispatch(setDeploymentFilter(deployment));
  };

  if (!open) return null;

  return (
    <StyledDialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      data-test-id="deployment-filter-dialog"
      classes={{
        root: classes.dialogRoot,
      }}
    >
      <DialogTitle>
        <Typography className={classes.title}>Select Deployment</Typography>
      </DialogTitle>
      <DialogContent classes={{root: classes.dialogContentRoot}}>
        <CAMSearchField
          id="deployment-filter-search-field"
          value={search}
          placeholder="Search deployments by name"
          onChange={handleSearchKeyChange}
          onSearch={handleEnterPress}
          onClear={handleSearchKeyClear}
        />
        <SlimTable
          resource={DEPLOYMENTS}
          shouldRenderCheckboxes={false}
          shouldRenderKebabs={false}
          data={prepareDeployments()}
          fields={tableFields}
          total={total}
          isFetching={isFetching}
          noDataMessage="No deployments found"
          onCellClick={onDeploymentSelect}
        />
        <CAMPagination
          page={params.page}
          rowsPerPage={params.rowsPerPage}
          total={total}
          rowsPerPageOptions={[15]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          dataTestId="deployments-table-pagination"
        />
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="close-deployment-filter"
          className={classes.button}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default DeploymentFilterDialog;
