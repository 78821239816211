import propTypes from 'prop-types';

import {styled} from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {ReactComponent as DomainIcon} from 'icons/network.svg';
import ConfigureStepCard from './ConfigureStepCard';

import {useWizardState} from './wizardConfigState';
import {stepIndices} from './wizardConstants';

const PREFIX = 'DomainConfigCard';

const classes = {
  cardSectionBody: `${PREFIX}-cardSectionBody`,
  list: `${PREFIX}-list`,
  textField: `${PREFIX}-textField`,
};

const Root = styled('div')(() => ({
  [`& .${classes.cardSectionBody}`]: {
    fontSize: '0.875rem',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },

  [`& .${classes.list}`]: {
    padding: 'inherit',
    paddingTop: '0px',
    paddingBottom: '0px',
    listStylePosition: 'inside',
  },

  [`& .${classes.textField}`]: {
    marginTop: '8px',
    marginBottom: '8px',
  },
}));

function DomainConfigCard({onChange}) {
  const {activeStepIndex, setActiveStepIndex, configState, isCommandLocked} =
    useWizardState();

  const STEP_INDEX = stepIndices.configureDomain;
  const TITLE = 'Configure Domain';

  const IS_OPEN = activeStepIndex === STEP_INDEX;
  const IS_COMPLETE = activeStepIndex > STEP_INDEX;

  return (
    <Root>
      <ConfigureStepCard
        stepIndex={STEP_INDEX}
        title={TITLE}
        open={IS_OPEN}
        onTitleClick={() => {
          if (activeStepIndex > STEP_INDEX && !isCommandLocked)
            setActiveStepIndex(STEP_INDEX);
        }}
        complete={IS_COMPLETE}
        optional={false}
        continueDisabled={!configState.domainName}
        onContinueClick={() => setActiveStepIndex(STEP_INDEX + 1)}
        onPreviousClick={() => setActiveStepIndex(STEP_INDEX - 1)}
        icon={<DomainIcon />}
      >
        <Typography
          component="span"
          variant="body1"
          className={classes.cardSectionBody}
        >
          <ul className={classes.list}>
            <li>
              Enter your organization&lsquo;s domain name to link the Connector
              with your network.
            </li>
            <li>
              Make sure to enter the correct domain name to ensure successful
              installation of the Connector.
            </li>
          </ul>
        </Typography>
        <Grid container item>
          <TextField
            className={classes.textField}
            value={configState.domainName}
            name="domainName"
            onChange={onChange}
            label="Domain name"
            type="text"
            variant="outlined"
            autoComplete="off"
            fullWidth
          />
        </Grid>
      </ConfigureStepCard>
    </Root>
  );
}

DomainConfigCard.propTypes = {
  onChange: propTypes.func.isRequired,
};

DomainConfigCard.defaultProps = {};

export default DomainConfigCard;
