import {
  Button,
  CircularProgress,
  Paper,
  Popover,
  TextField,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {useState} from 'react';
import useSnackbar from 'hooks/useSnackbar';
import {isEmpty} from 'utils/utils';
import ServiceAccountDownloadModal from './ServiceAccountDownloadModal';

const PREFIX = 'AddServiceAccount';

const classes = {
  bodyText: `${PREFIX}-bodyText`,
  textField: `${PREFIX}-textField`,
  addServiceAccountButton: `${PREFIX}-addServiceAccountButton`,
  itemButton: `${PREFIX}-itemButton`,
  link: `${PREFIX}-link`,
  serviceAccountPopup: `${PREFIX}-serviceAccountPopup`,
  disabled: `${PREFIX}-disabled`,
};

const CustomCircleIcon = styled(AddCircleIcon)(() => ({
  marginLeft: '10px',
  fontSize: '28px',
  '&:hover': {
    cursor: 'pointer',
    color: '#005788',
  },
}));

const Root = styled('div')(({theme}) => ({
  [`& .${classes.bodyText}`]: {
    fontWeight: 500,
  },

  [`& .${classes.textField}`]: {
    margin: theme.spacing(1),
    width: 200,
    height: 56,
    padding: 3,
  },

  [`& .${classes.addServiceAccountButton}`]: {
    marginLeft: '10px',
    fontSize: '28px',
    '&:hover': {
      cursor: 'pointer',
      color: '#005788',
    },
  },

  [`& .${classes.itemButton}`]: {
    margin: '20px 12px',
    height: '36px',
    width: '96px',
  },

  [`& .${classes.link}`]: {
    textDecoration: 'underline',
    color: 'white',
  },

  [`& .${classes.serviceAccountPopup}`]: {
    paddingTop: 15,
    paddingBottom: 5,
  },

  [`& .${classes.disabled}`]: {pointerEvents: 'none'},
}));

function AddServiceAccount({createCallback, serviceAccountType}) {
  const {successSnackbar} = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [showNameInput, setShowNameInput] = useState(false);

  const [
    openServiceAccountDownloadWindow,
    setOpenServiceAccountDownloadWindow,
  ] = useState(false);
  const [createdServiceAccount, setCreatedServiceAccount] = useState();

  const isAwmServiceAccount = serviceAccountType === 'awm';

  const handleCloseInput = () => {
    setAnchorEl(null);
    setShowNameInput(false);
    setAccountName('');
  };

  const handleCloseDownloadWindow = () => {
    setOpenServiceAccountDownloadWindow(false);
  };

  const handleCopyServiceAccountToClipboard = () => {
    successSnackbar(
      `${
        isAwmServiceAccount ? 'Anyware Manager' : 'Deployment'
      } service account has been copied to your clipboard.`
    );
  };

  const handleCreate = async () => {
    setIsSaving(true);
    setShowNameInput(false);

    const svcAcc = await createCallback(accountName);

    if (!isEmpty(svcAcc)) {
      setCreatedServiceAccount(svcAcc);
      setOpenServiceAccountDownloadWindow(true);
      setAccountName('');
    }

    setIsSaving(false);
  };

  const serviceAccountDownloadModal = (
    <ServiceAccountDownloadModal
      isDeploymentSA={!isAwmServiceAccount}
      serviceAccount={createdServiceAccount}
      open={openServiceAccountDownloadWindow}
      onClose={handleCloseDownloadWindow}
      onCopyToClipboard={handleCopyServiceAccountToClipboard}
      filenameToSave={`anyware-manager-${
        isAwmServiceAccount ? '' : 'deployment-'
      }service-account.json`}
    />
  );

  const nameInput = (
    <Popover
      anchorOrigin={{vertical: 'center', horizontal: 'right'}}
      transformOrigin={{vertical: 'center', horizontal: 'left'}}
      onClose={handleCloseInput}
      open={showNameInput}
      anchorEl={anchorEl}
    >
      <Root>
        <Paper className={classes.serviceAccountPopup}>
          <TextField
            data-testid="service-account-name"
            value={accountName}
            required
            label="Account name"
            variant="outlined"
            onChange={(event) => setAccountName(event.target.value)}
            InputProps={{className: classes.textField}}
          />
          <Button
            className={classes.itemButton}
            variant="outlined"
            color="primary"
            onClick={handleCreate}
            disabled={accountName.length === 0}
          >
            Create
          </Button>
        </Paper>
      </Root>
    </Popover>
  );

  return (
    <>
      <CustomCircleIcon
        data-testid="add-service-account-button"
        onClick={
          isSaving
            ? null
            : (event) => {
                setShowNameInput(true);
                setAnchorEl(event.currentTarget);
              }
        }
        size="small"
        color={isSaving ? 'disabled' : 'primary'}
        className={classnames(classes.addServiceAccountButton, {
          [classes.disabled]: isSaving,
        })}
      />
      {isSaving && (
        <CircularProgress data-testid="add-service-account-spinner" size={14} />
      )}
      {showNameInput && nameInput}
      {serviceAccountDownloadModal}
    </>
  );
}

AddServiceAccount.propTypes = {
  createCallback: PropTypes.func.isRequired,
  serviceAccountType: PropTypes.oneOf(['deployment', 'awm']).isRequired,
};

export default AddServiceAccount;
