export function capitalize(string) {
  if (typeof string !== 'string') {
    return '';
  }
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

export function getEditRwLink(machineId) {
  return `/app/remoteWorkstations/edit/${machineId}`;
}

export function getEditDeploymentLink(deploymentId) {
  return `/app/deployments/edit/${deploymentId}`;
}
