import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Divider from '@mui/material/Divider';
import {styled} from '@mui/material/styles';
import {Link} from 'react-router-dom';
import {del} from 'api/Api';
import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import useSnackbar from 'hooks/useSnackbar';
import {
  handleApiError,
  receiveDeleteResource,
  requestDeleteResource,
} from 'redux/actions/dataActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {uncheckItem} from 'redux/actions/tableSelectActions';
import {EDIT_WEBHOOK_LINK, WEBHOOKS} from 'utils/constants';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {mapResourceToPath} from 'utils/Mappings';
import {openDialog} from 'redux/actions/confirmationDialogActions';

const PREFIX = 'WebhookActions';

const classes = {
  divider: `${PREFIX}-divider`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.divider}`]: {height: '2px'},
}));

function WebhookActions({item: webhook, closeMenu}) {
  const dispatch = useDispatch();
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {page, rowsPerPage} = useSelector((state) => state.tableData[WEBHOOKS]);
  const {successSnackbar} = useSnackbar();

  const removeWebhook = async () => {
    dispatch(requestDeleteResource(WEBHOOKS, webhook.id));
    try {
      const basePath = mapResourceToPath(WEBHOOKS, {deploymentId});
      const path = `${basePath}/${webhook.id}`;
      await del({path});
      dispatch(receiveDeleteResource(WEBHOOKS, webhook.id));
      successSnackbar(`Webhook has been deleted`);
    } catch (err) {
      handleApiError(err);
    }
    dispatch(fetchTableData(WEBHOOKS, page, rowsPerPage));
    dispatch(uncheckItem(WEBHOOKS, webhook));
  };

  const handleDeleteClick = () => {
    dispatch(
      openDialog(
        'Delete Webhook?',
        `Are you sure you want to delete the webhook ${webhook.name}?`,
        removeWebhook
      )
    );
  };

  return (
    <Root>
      <Link
        id={`edit-webhook-${webhook.id}-link`}
        to={`${EDIT_WEBHOOK_LINK}/${webhook.id}`}
      >
        <KebabMenuItem id={`edit-webhook-${webhook.id}`} menuText="Edit" />
      </Link>
      <Divider className={classes.divider} />
      <KebabMenuItem
        id={`delete-webhook-${webhook.id}`}
        dataTestId={`delete-${webhook.id}`}
        menuText="Delete"
        onClick={() => {
          closeMenu();
          handleDeleteClick();
        }}
      />
    </Root>
  );
}
WebhookActions.propTypes = {
  item: PropTypes.object,
  closeMenu: PropTypes.func,
};

WebhookActions.defaultProps = {
  item: {},
  closeMenu: () => {},
};

export default WebhookActions;
