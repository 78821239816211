import {Grid, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import validator from 'validator';
import {useEffect} from 'react';
import {isEmpty} from 'utils/utils';
import {StyledLabelText, StyledTextField} from './StyledTextField';

export function LabeledTextField({label, required, ...props}) {
  const textFieldTestId = `${label.toLowerCase().replace(' ', '-')}-text-field`;

  const requiredText = required ? '' : ' (optional)';

  return (
    <Grid container direction="column" gap={0.5}>
      <StyledLabelText>
        {label}
        <Typography variant="body2" color="#8E93A4" component="span">
          {requiredText}
        </Typography>
      </StyledLabelText>
      <StyledTextField
        variant="outlined"
        data-testid={textFieldTestId}
        size="small"
        name={label}
        {...props}
      />
    </Grid>
  );
}

LabeledTextField.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

export function LabeledUrlTextField({
  onValidityChange,
  label,
  value,
  error,
  helperText,
  ...props
}) {
  const textFieldTestId = `${label.toLowerCase().replace(' ', '-')}-text-field`;

  const isURLValid = (url) => {
    const requireTld = true;
    const protocols = ['https'];

    return validator.isURL(url, {
      protocols,
      require_tld: requireTld,
      require_protocol: true,
      require_valid_protocol: true,
    });
  };

  useEffect(() => {
    const valid = isURLValid(value);
    onValidityChange(valid);
  }, [value, onValidityChange]);

  const hasUrlError = !isEmpty(value) && !isURLValid(value);

  const hasError = error || hasUrlError;

  const helperTextValue = hasUrlError
    ? 'Must be a valid HTTPS URL'
    : helperText;

  return (
    <Grid container direction="column" gap={0.5}>
      <StyledLabelText>{label}</StyledLabelText>
      <StyledTextField
        data-testid={textFieldTestId}
        size="small"
        name={label}
        value={value}
        variant="outlined"
        helperText={helperTextValue}
        error={hasError}
        {...props}
      />
    </Grid>
  );
}

LabeledUrlTextField.propTypes = {
  onValidityChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onBlur: PropTypes.func,
};

LabeledUrlTextField.defaultProps = {
  placeholder: '',
  value: '',
  onChange: () => {},
  error: false,
  helperText: '',
  onBlur: () => {},
};
