import PropTypes from 'prop-types';
import {CONFIGURATION_CAPABILITY_NAME} from 'utils/constants';

const ENABLED_STATUS = 'Enabled';
const DISABLED_STATUS = 'Disabled';
const UNKNOWN_STATUS = 'Unknown';

function ConfigServiceStatus({connector}) {
  let doesCapabilityExist = false;
  let isConfigServiceEnabled = false;

  if (connector?.capabilities === undefined) {
    return UNKNOWN_STATUS;
  }

  connector.capabilities.forEach((capability) => {
    if (capability?.capabilityName === CONFIGURATION_CAPABILITY_NAME) {
      isConfigServiceEnabled = capability.enabled;
      doesCapabilityExist = true;
    }
  });

  if (doesCapabilityExist) {
    return isConfigServiceEnabled ? ENABLED_STATUS : DISABLED_STATUS;
  }
  return UNKNOWN_STATUS;
}

ConfigServiceStatus.propTypes = {
  connector: PropTypes.object,
};

ConfigServiceStatus.defaultProps = {
  connector: {
    components: {},
  },
};

export default ConfigServiceStatus;
