import propTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import {ReactComponent as MfaIcon} from 'icons/mfa.svg';
import ConfigureStepCard from './ConfigureStepCard';
import {
  getClearedConfigState,
  checkRequiredParams,
  useWizardState,
} from './wizardConfigState';
import {stepIndices, mfaRadiusParams, mfaConfigParams} from './wizardConstants';

const PREFIX = 'MfaConfigCard';

const classes = {
  textField: `${PREFIX}-textField`,
};

const Root = styled('div')(() => ({
  [`& .${classes.textField}`]: {
    marginTop: '8px',
    marginBottom: '8px',
  },
}));

function MfaConfigCard({onChange}) {
  const {
    activeStepIndex,
    setActiveStepIndex,
    configState,
    setConfigState,
    isCommandLocked,
  } = useWizardState();

  const STEP_INDEX = stepIndices.configureMFA;
  const TITLE = 'Multi-factor Authentication';

  const IS_OPEN = activeStepIndex === STEP_INDEX;
  const IS_COMPLETE = activeStepIndex > STEP_INDEX;

  return (
    <Root>
      <ConfigureStepCard
        stepIndex={STEP_INDEX}
        title={TITLE}
        open={IS_OPEN}
        onTitleClick={() => {
          if (activeStepIndex > stepIndices.configureMFA && !isCommandLocked)
            setActiveStepIndex(stepIndices.configureMFA);
        }}
        complete={IS_COMPLETE}
        optional
        continueDisabled={!checkRequiredParams(mfaRadiusParams, configState)}
        onContinueClick={() => setActiveStepIndex(STEP_INDEX + 1)}
        onPreviousClick={() => setActiveStepIndex(STEP_INDEX - 1)}
        onSkipClick={() => {
          const clearedParams = getClearedConfigState(
            mfaConfigParams,
            configState
          );
          setConfigState(clearedParams);
          setActiveStepIndex(STEP_INDEX + 1);
        }}
        icon={<MfaIcon />}
      >
        <Grid container spacing={2} direction="column">
          {mfaRadiusParams.map((param) => {
            // Validate input if a function is available & user has provided a value
            const isParamValid =
              typeof param.isValid === 'function' &&
              param.isValid(configState[param.fieldName]);
            const isValueProvided = Boolean(configState[param.fieldName]);
            const helperText =
              isValueProvided && !isParamValid && param.helperText
                ? param.helperText
                : '';

            return (
              <Grid key={`grid - ${param.fieldName} `} item>
                <TextField
                  className={classes.textField}
                  value={configState[param.fieldName]}
                  label={param.label}
                  helperText={helperText}
                  error={Boolean(helperText)}
                  placeholder={param.placeholder}
                  type={param.isSecret ? 'password' : 'text'}
                  name={param.fieldName}
                  onChange={onChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            );
          })}
        </Grid>
      </ConfigureStepCard>
    </Root>
  );
}

MfaConfigCard.propTypes = {
  onChange: propTypes.func.isRequired,
};

MfaConfigCard.defaultProps = {};

export default MfaConfigCard;
