import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';

const PREFIX = 'ErrorModal';

const classes = {
  dialogText: `${PREFIX}-dialogText`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.dialogText}`]: {
    whiteSpace: 'pre-wrap',
  },
}));

function ErrorModal(props) {
  const {errorHeader, errorMessage, open, handleClose} = props;

  if (!open) return null;

  return (
    <StyledDialog open={open} aria-labelledby="error-modal">
      <DialogTitle>{errorHeader}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogText}>
          {errorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          color="primary"
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

ErrorModal.propTypes = {
  errorHeader: PropTypes.string,
  errorMessage: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

ErrorModal.defaultProps = {
  errorHeader: 'Error',
  errorMessage: '',
  open: false,
};

export default ErrorModal;
