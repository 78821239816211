/* eslint-disable max-lines */
import {styled} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {RiLockPasswordFill} from 'react-icons/ri';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {del, get, post, put} from 'api/Api';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import ToggleSwitch from 'components/CAM/buttons/ToggleSwitch/ToggleSwitch';
import CAMDeleteDialog from 'components/CAM/dialogs/CAMDeleteDialog/CAMDeleteDialog';
import CAMTooltip from 'components/CAM/display/CAMTooltip/CAMTooltip';
import AssignmentPolicyIcon from 'components/CAM/icons/AssignmentPolicyIcon/AssignmentPolicyIcon';
import ProviderIcon from 'components/CAM/icons/ProviderIcon/ProviderIcon';
import ProviderSelector from 'components/CAM/inputs/ProviderSelector/ProviderSelector';
import TextInput from 'components/CAM/inputs/TextInput/TextInput';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import HorizontalTab from 'components/CAM/tabs/HorizontalTab/HorizontalTab';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';
import KeyValueInfo from 'components/CAM/text/KeyValueInfo/KeyValueInfo';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import CAMTextField from 'components/common/TextField';
import {capitalize} from 'helpers/core';
import useDialog from 'hooks/useDialog';
import usePendingChanges from 'hooks/usePendingChanges';
import useSnackbar from 'hooks/useSnackbar';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {
  fetchAwsInstances,
  fetchAzureInstances,
  fetchCloudServiceAccounts,
  fetchConnectorWithId,
  fetchGcpInstances,
  fetchGcpOptions,
  fetchLatestMonitorTelemetry,
  fetchMachineSessions,
  fetchOneRemoteWorkstation,
  fetchResource,
  fetchTelemetrySettings,
  handleApiError,
  saveVariable,
} from 'redux/actions/dataActions';
import {push} from 'redux/actions/HistoryActions';
import {doSafeRequest} from 'utils/apiUtils';
import {
  AD_USERS,
  AME,
  AMT,
  AWS,
  AWS_INSTANCES,
  AZURE,
  AZURE_INSTANCES,
  CLOUD_SERVICE_ACCOUNTS,
  EDIT_POOL_LINK,
  EDIT_REMOTE_WORKSTATION_LINK,
  ENTITLEMENTS,
  GCP,
  GCP_INSTANCES,
  AMT_IP_FQDN_TOOLTIP,
  IP_FQDN_TOOLTIP,
  MACHINE_MGMT_CREDENTIALS,
  MACHINE_SESSIONS,
  MACHINE_SESSION_ATTEMPTS,
  MONITOR_TELEMETRY_LATEST,
  ONPREM,
  POOLS,
  REMOTE_WORKSTATIONS,
  TELEMETRY_SETTINGS,
  RSM,
  COLUMN_TOOLTIP_REQUIRES_MONITOR,
} from 'utils/constants';
import {mapProviders, mapResourceToPath} from 'utils/Mappings';
import {
  selectData,
  selectResource,
  selectResourceArray,
  selectResourceItem,
  selectSelectedDeployment,
  selectVariable,
} from 'utils/reduxSelectors';
import {
  formatDateTime,
  hostNameErrorMessage,
  isEmpty,
  isLatestMonitorTelemetryValid,
  linkTo,
  truncateTimeByStep,
  validateHostname,
  validateMachineName,
  validCloudServiceAccountForWorkstation,
} from 'utils/utils';
import {useQueryRsmDevices} from 'hooks/rsmHooks';
import config from '../../../config';

import AddRemoveUser from '../AddRemoveUser';
import RemoteWorkstationPoolInfo from '../RemoteWorkstationPoolInfo';
import EditRemoteWorkstationAppBar from './EditRemoteWorkstationAppBar';
import MonitorConfigurationCard from './MonitorConfigurationCard';
import WorkstationSessionCard from './WorkstationSessionCard';

const PREFIX = 'EditRemoteWorkstation';

const classes = {
  inputTextFieldLabel: `${PREFIX}-inputTextFieldLabel`,
  pageContainer: `${PREFIX}-pageContainer`,
  editProviderSection: `${PREFIX}-editProviderSection`,
  editProviderButton: `${PREFIX}-editProviderButton`,
  itemButton: `${PREFIX}-itemButton`,
  itemButtonRowWithBreakPoint: `${PREFIX}-itemButtonRowWithBreakPoint`,
  itemButtonRow: `${PREFIX}-itemButtonRow`,
  flexGrowOne: `${PREFIX}-flexGrowOne`,
  dropbox: `${PREFIX}-dropbox`,
  credentialsItemsRow: `${PREFIX}-credentialsItemsRow`,
  credentialsIconDiv: `${PREFIX}-credentialsIconDiv`,
  credentialsIcon: `${PREFIX}-credentialsIcon`,
  errorInputLabel: `${PREFIX}-errorInputLabel`,
  gridRow: `${PREFIX}-gridRow`,
  gridRowItem: `${PREFIX}-gridRowItem`,
  itemFlexEnd: `${PREFIX}-itemFlexEnd`,
  itemEnd: `${PREFIX}-itemEnd`,
  success: `${PREFIX}-success`,
  warning: `${PREFIX}-warning`,
  buttonFill: `${PREFIX}-buttonFill`,
  inlineBlock: `${PREFIX}-inlineBlock`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.inputTextFieldLabel}`]: {
    color: theme.palette.info.main,
    fontFamily: '.SF NS Display',
    fontSize: '0.75rem',
    lineHeight: '15px',
    marginTop: '4px',
  },

  [`&.${classes.pageContainer}`]: theme.createPage.createPageContainer,

  [`& .${classes.editProviderSection}`]: {
    marginTop: '10px',
  },

  [`& .${classes.editProviderButton}`]: {
    marginTop: '10px',
  },

  [`& .${classes.itemButton}`]: {
    whiteSpace: 'nowrap',
  },

  [`& .${classes.itemButtonRowWithBreakPoint}`]: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.between('sm', 'lg') || theme.breakpoints.up('md')]: {
      /* styles for medium and large screen */
      alignItems: 'flex-start',
    },
  },

  [`& .${classes.itemButtonRow}`]: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },

  [`& .${classes.flexGrowOne}`]: {flexGrow: 1},

  [`& .${classes.dropbox}`]: {
    maxWidth: '900px',
    padding: '5px 10px',
  },

  [`& .${classes.credentialsItemsRow}`]: {
    width: '100%',
    display: 'flex',
    flex: 'auto',
    justifyContent: 'flex-start',
  },

  [`& .${classes.credentialsIconDiv}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.credentialsIcon}`]: {
    alignSelf: 'center',
    marginRight: '8px',
    fontSize: '30px',
    color: theme.palette.primary.main,
  },

  [`& .${classes.errorInputLabel}`]: {
    color: '#C23934',
    marginLeft: '14px',
    marginRight: '14px',
    fontSize: '0.75rem',
  },

  [`& .${classes.gridRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },

  [`& .${classes.gridRowItem}`]: {
    marginRight: '4px',
  },

  [`& .${classes.itemFlexEnd}`]: {
    alignSelf: 'flex-end',
  },

  [`& .${classes.itemEnd}`]: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },

  [`& .${classes.success}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.warning}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.buttonFill}`]: {width: '100%'},

  [`& .${classes.inlineBlock}`]: {
    display: 'inline-block',
  },
}));

const TAB_OVERVIEW = 'overview';
const TAB_USER_MANAGEMENT = 'userManagement';
const TAB_MONITOR_CONFIGURATION = 'monitorConfiguration';
const TAB_SESSION_INFO = 'sessionInfo';

const tabs = [TAB_OVERVIEW, TAB_USER_MANAGEMENT];
if (config.isMonitorEnabled()) {
  tabs.push(TAB_MONITOR_CONFIGURATION);
}
tabs.push(TAB_SESSION_INFO);

function EditRemoteWorkstation({match}) {
  const dispatch = useDispatch();
  const {setPendingChanges} = usePendingChanges();
  const {successSnackbar, errorSnackbar} = useSnackbar();
  const {tab} = useParams();

  const getTabIndex = () => {
    const index = tabs.findIndex((t) => t === tab);
    if (index < 0) {
      return 0;
    }
    return index;
  };

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );

  const [selectedTab, setSelectedTab] = useState(getTabIndex());

  const selectedMachineId = match && match.params && match.params.machineId;
  const [updatedMachineName, setUpdatedMachineName] = useState('');
  const [machineNameError, setMachineNameError] = useState('');

  const [updatedMachineHostname, setUpdatedMachineHostname] = useState('');
  const [updatedMachineManaged, setUpdatedMachineManaged] = useState(false);

  const [selectedInstance, setSelectedInstance] = useState({});
  const [assignedPool, setAssignedPool] = useState({});

  const [showDeleteAmtCredsDialog, setShowDeleteAmtCredsDialog] =
    useState(false);

  const [isSavingAmtCredentials, setIsSavingAmtCredentials] = useState(false);
  const [isEditingAmtCredentials, setIsEditingAmtCredentials] = useState(false);

  const [updatedAmtHostname, setUpdatedAmtHostname] = useState('');
  const [enteredAmtUsername, setEnteredAmtUsername] = useState('');
  const [enteredAmtPassword, setEnteredAmtPassword] = useState('');

  // We use undefined as a default value here because legacy CAM machines have provider: undefined
  const [updatedProvider, setUpdatedProvider] = useState(undefined);
  const [entitlementsTable, setEntitlementsTable] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditingProvider, setIsEditingProvider] = useState(false);

  // session attempts date range
  const [saDateRange, setSaDateRange] = useState({});

  // workstation service accounts
  const [workstationServiceAccounts, setWorkstationServiceAccounts] = useState(
    []
  );

  const {item: selectedWorkstation, isFetching} = useSelector((state) =>
    selectResourceItem(state, REMOTE_WORKSTATIONS, selectedMachineId)
  );

  const connector = useSelector((state) =>
    selectResource(state, 'editPageConnector')
  );

  const {entitlements} = selectedWorkstation;
  const {triggerDialog} = useDialog();

  const {data: awsInstances, isFetching: fetchingAwsInstances} = useSelector(
    (state) => selectData(state, AWS_INSTANCES)
  );
  const {data: azureInstances, isFetching: fetchingAzureInstances} =
    useSelector((state) => selectData(state, AZURE_INSTANCES));
  const {data: gcpInstances, isFetching: fetchingGcpInstances} = useSelector(
    (state) => selectData(state, GCP_INSTANCES)
  );
  const {data: _rsmInstances, isFetching: fetchingRsmInstances} =
    useQueryRsmDevices(deploymentId, {enabled: updatedProvider === RSM});
  let rsmInstances = [];
  if (Array.isArray(_rsmInstances)) {
    rsmInstances = _rsmInstances;
  }

  let fetchingInstances =
    fetchingAwsInstances || fetchingAzureInstances || fetchingGcpInstances;
  if (updatedProvider === RSM) {
    fetchingInstances = fetchingRsmInstances;
  }

  const deletingEntitlements = useSelector(
    (state) => selectVariable(state, 'deletingEntitlements') || []
  );

  const {data: cloudServiceAccounts} = useSelector((state) =>
    selectData(state, CLOUD_SERVICE_ACCOUNTS)
  ) || {data: []};

  const {item: sessionStatus, isFetching: fetchingSessionStats} = useSelector(
    (state) => selectResourceItem(state, MACHINE_SESSIONS, selectedMachineId)
  );

  const {data: sessionAttempts, isFetching: fetchingSessionAttempts} =
    useSelector((state) =>
      selectResourceArray(state, MACHINE_SESSION_ATTEMPTS)
    );

  const {data: adUsers, isFetching: fetchingAdUsers} = useSelector((state) =>
    selectResourceArray(state, AD_USERS)
  );

  const {item: telemetrySettings} = useSelector((state) =>
    selectResourceItem(
      state,
      TELEMETRY_SETTINGS,
      selectedWorkstation && selectedWorkstation.deploymentId
    )
  );

  const isTab = (tabName) => tabName === tabs[selectedTab];

  const loadWorkstationData = (entitlementsOnly = false) =>
    dispatch(
      fetchOneRemoteWorkstation(
        isEmpty(selectedWorkstation)
          ? {machineId: selectedMachineId}
          : selectedWorkstation,
        deploymentId,
        entitlementsOnly,
        true
      )
    );

  const requestMonitorUpdate = (refreshMachineState = truncateTimeByStep) =>
    dispatch(async () => {
      try {
        await post({
          path: `machines/${selectedMachineId}/messaging/requestUpdate`,
          params: {selectedMachineId, refreshMachineState},
        });
      } catch (err) {
        dispatch(handleApiError(err));
      }
    });

  const emailUserName = (email) => {
    if (typeof email !== 'string') {
      return '';
    }
    return email.substring(0, email.indexOf('@'));
  };
  // initialize table with entitlements listed for the selected workstation which have a user key defined
  const initializeEntitlementsTable = () => {
    setEntitlementsTable(
      entitlements
        // userEntitlement can just be an upn and not linked to active directory user
        .filter(
          (ent) =>
            !['deleting', 'deleted'].includes(ent.status) &&
            ent.entitlementType === 'user'
        )
        .map((ent) => ({
          ...ent.user,
          isEntitled: true,
          entitlementId: ent.entitlementId,
          userGuid: ent.userGuid,
          upn: ent.upn,
          name: ent.user?.name || emailUserName(ent.upn) || ent.userGuid,
          userName: ent.user?.userName || ent.upn,
          enabled: ent.user ? ent.user.enabled : true,
        }))
    ); // user is enabled if it is not an active directory user.
  };

  const changeSelectedTab = (tabIndex) => {
    dispatch(
      push(
        `${EDIT_REMOTE_WORKSTATION_LINK}/${selectedMachineId}/${tabs[tabIndex]}`
      )
    );
    setSelectedTab(tabIndex);
  };

  const fetchOnePool = async (poolId) => {
    const path = `${mapResourceToPath(POOLS, {deploymentId})}${poolId}`;
    const resp = await doSafeRequest(get, {path});
    if (resp.status === 'success') {
      return resp.data;
    }
    dispatch(handleApiError(resp));
    return {};
  };

  const isActivePoolMachine = (poolMachine) =>
    poolMachine &&
    !['deleted', 'deleting', 'error'].includes(poolMachine.status);

  const fetchAssignedPool = async () => {
    if (selectedWorkstation.deploymentId) {
      const path = `${mapResourceToPath(POOLS, {deploymentId})}machines`;
      const params = {machineId: selectedMachineId};
      const resp = await doSafeRequest(get, {path, params});
      if (resp.status === 'success') {
        const [poolMachine] = resp.data;
        if (isActivePoolMachine(poolMachine)) {
          const {poolId} = poolMachine;
          const pool = await fetchOnePool(poolId);
          setAssignedPool(pool);
        }
      } else {
        dispatch(handleApiError(resp));
      }
    }
  };

  const basicInformationUpdated = () => {
    if (isFetching) {
      return false;
    }
    return (
      selectedWorkstation.hostName !== updatedMachineHostname ||
      selectedWorkstation.machineName !== updatedMachineName
    );
  };

  const alternateAmtHostnameUpdated = () => {
    if (isFetching) {
      return false;
    }
    return (
      (selectedWorkstation.providerAlternateHostname || '') !==
      updatedAmtHostname
    );
  };

  const undoPendingPageChanges = () => {
    setUpdatedMachineHostname(selectedWorkstation.hostName);
    setUpdatedMachineName(selectedWorkstation.machineName);
  };

  const getWorkstationServiceAccounts = async () => {
    if (config.isMonitorEnabled() && selectedWorkstation.deploymentId) {
      try {
        // Sort service accounts by createdOn, last created first
        const response = await get({
          path: 'auth/keys/messaging/registration',
          params: {
            deploymentId,
            machineId: selectedMachineId,
          },
        });

        const receivedServiceAccounts = response.data.map((account) => {
          const newAccount = account;
          newAccount.keyName = account.apiKeyName || account.keyName || '';
          return newAccount;
        });

        receivedServiceAccounts
          .sort((a, b) => b.createdOn.localeCompare(a.createdOn))
          .filter((acct) => acct.deploymentId === deploymentId);
        setWorkstationServiceAccounts(receivedServiceAccounts);
      } catch (error) {
        dispatch(handleApiError(error));
      }
    }
  };

  useEffect(() => {
    const fetchPool = async () => {
      await fetchAssignedPool();
    };

    fetchPool();
  }, [selectedWorkstation]);

  useEffect(() => {
    setSelectedTab(getTabIndex());
  }, [tab]);

  useEffect(() => {
    if (!fetchingInstances) {
      const instancesToSelectFrom = [
        awsInstances,
        azureInstances,
        gcpInstances,
        rsmInstances,
      ][[AWS, AZURE, GCP, RSM].indexOf(updatedProvider)];

      // find the instance that matches the machine name for the selected provider
      const matchInstance =
        instancesToSelectFrom &&
        instancesToSelectFrom.find((instance) =>
          [instance.name, instance.instanceName, instance.instanceId].includes(
            selectedWorkstation.machineName
          )
        );
      setSelectedInstance(matchInstance);
    }
  }, [fetchingInstances]);

  useEffect(() => {
    setSelectedInstance({});
  }, [updatedProvider]);

  useEffect(() => {
    if (!config.isBetaOrStandalone()) {
      return () => {};
    }

    if (!isEmpty(telemetrySettings) && telemetrySettings.enabled) {
      const {from, to} = saDateRange;
      if (from && to) {
        // Fetch session stats for the workstation for the first time
        dispatch(
          fetchMachineSessions([selectedMachineId], {
            machineActive: false,
            range: saDateRange,
          })
        );

        const interval = setInterval(
          () => {
            dispatch(
              fetchMachineSessions([selectedMachineId], {
                machineActive: false,
                range: saDateRange,
              })
            );
          },
          (Math.random() * 60 + 60) * 1000
        );
        return () => clearInterval(interval);
      }
    }
    return () => {};
  }, [JSON.stringify(saDateRange)]);

  // Set interval to update session status of machine
  // Refreshes randomly between 1-2 minutes
  useEffect(() => {
    if (!config.isBetaOrStandalone()) {
      return () => {};
    }

    if (!isEmpty(telemetrySettings) && telemetrySettings.enabled) {
      // Fetch session stats for the workstation for the first time
      dispatch(fetchMachineSessions([selectedMachineId], {range: saDateRange}));

      const interval = setInterval(
        () => {
          dispatch(
            fetchMachineSessions([selectedMachineId], {range: saDateRange})
          );
        },
        (Math.random() * 60 + 60) * 1000
      );
      return () => clearInterval(interval);
    }
    return () => {};
  }, [JSON.stringify(telemetrySettings)]);

  // when selectedWorkstation is loaded, initialize entitlements table
  useEffect(() => {
    if (selectedWorkstation.entitlements) {
      initializeEntitlementsTable();
    }
  }, [selectedWorkstation.entitlements]);

  useEffect(() => {
    if (
      selectedWorkstation.provider === GCP &&
      validCloudServiceAccountForWorkstation(
        selectedWorkstation,
        cloudServiceAccounts
      )
    ) {
      dispatch(fetchGcpOptions(deploymentId));
    }
  }, [JSON.stringify(cloudServiceAccounts)]);

  useEffect(() => {
    if (!isEmpty(selectedWorkstation)) {
      if (selectedWorkstation.connectorId) {
        dispatch(fetchConnectorWithId(selectedWorkstation.connectorId));
      }
      setUpdatedMachineName(selectedWorkstation.machineName);
      setUpdatedMachineHostname(selectedWorkstation.hostName);
      setUpdatedAmtHostname(
        selectedWorkstation.providerAlternateHostname || ''
      );
      setUpdatedMachineManaged(selectedWorkstation.managed);
    }
  }, [selectedWorkstation]);

  useEffect(() => {
    setPendingChanges(basicInformationUpdated());
  }, [updatedMachineHostname, updatedMachineName, isSaving]);

  useEffect(() => {
    undoPendingPageChanges();
  }, [selectedTab]);

  const removeEntitlement = async (entitlement) => {
    const info = selectedWorkstation.agentMonitored
      ? `Are you sure you want to remove user assignment to the workstation ${selectedWorkstation.machineName}? This will not delete the user or the remote workstation, but remove the assignment between them. If the user is logged in to the workstation, they will be logged out.`
      : `Are you sure you want to remove user assignment to the workstation ${selectedWorkstation.machineName}? This will not delete the user or the remote workstation, but remove the assignment between them.`;
    triggerDialog({
      title: 'Remove user assignment from the workstation?',
      message: info,
      onConfirm: async () => {
        dispatch(
          saveVariable('deletingEntitlements', [
            ...deletingEntitlements,
            entitlement.entitlementId,
          ])
        );
        try {
          await del({
            path: `deployments/${deploymentId}/entitlements/${entitlement.entitlementId}`,
          });
        } catch (error) {
          dispatch(handleApiError(error));
        }
        await loadWorkstationData(true);
        dispatch(
          saveVariable(
            'deletingEntitlements',
            deletingEntitlements.filter(
              (entitlementId) => entitlementId !== entitlement.entitlementId
            )
          )
        );
        dispatch(fetchResource(ENTITLEMENTS, {rowsPerPage: 1000}));
      },
    });
  };

  // Returns true if the update provider to GCP errors were handled
  const handleUpdateProviderErrorsGCP = (error, zone) => {
    const data = error.data || {reason: ''};
    const {reason} = data;
    if (typeof reason === 'string' && reason.includes('does not exist')) {
      dispatch(
        openDialog(
          'Workstation could not be found in provider',
          `${
            selectedWorkstation.machineName
          } could not be found in ${mapProviders(
            GCP
          )} at ${zone}. Please verify that your workstation is in the selected cloud at the correct zone and try again.`,
          () => {},
          false
        )
      );
      return true;
    }
    return false;
  };

  // Returns true if the update provider to Azure errors were handled
  const handleUpdateProviderErrorsAzure = (
    error,
    resourceGroup,
    subscriptionId
  ) => {
    const data = error.data || {reason: ''};
    const {reason} = data;
    let message = '';

    if (typeof reason === 'string') {
      if (reason.includes('was not found')) {
        message = `${
          selectedWorkstation.machineName
        } could not be found in ${mapProviders(
          AZURE
        )} at resource group ${resourceGroup} in subscription ${subscriptionId}. Please verify that your workstation is in the selected cloud at the correct resource group and subscription and then try again.`;
      } else if (
        reason.includes('subscription') &&
        reason.includes('not be found')
      ) {
        message = `The subscription '${subscriptionId}' could not be found.`;
      } else if (
        reason.includes('Resource group') &&
        reason.includes('not be found')
      ) {
        message = `Resource group '${resourceGroup}' could not be found.`;
      } else if (reason.includes('does not have authorization')) {
        message = `The Azure service principal associated with this deployment does not have authorization to retrieve the requested workstations at ${resourceGroup} in subscription ${subscriptionId}.`;
      }
    }

    if (message) {
      dispatch(
        openDialog(
          'Unable to update provider information',
          message,
          () => {},
          false
        )
      );
      return true;
    }
    return false;
  };

  // Returns true if the update provider errors were handled
  const handleUpdateProviderErrors = (
    error,
    provider,
    {zone, resourceGroup, subscriptionId}
  ) => {
    switch (provider) {
      case GCP:
        return handleUpdateProviderErrorsGCP(error, zone);
      case AZURE:
        return handleUpdateProviderErrorsAzure(
          error,
          resourceGroup,
          subscriptionId
        );
      default:
        return false;
    }
  };

  const saveProvider = async () => {
    const data = {provider: updatedProvider, managed: true};

    switch (updatedProvider) {
      case AWS:
        data.instanceId = selectedInstance.instanceId;
        data.region = selectedInstance.region;
        data.hostName = selectedInstance.privateIP;
        break;
      case AZURE:
        data.resourceGroup = selectedInstance.resourceGroup;
        data.subscriptionId = selectedInstance.subscriptionId;
        data.hostName = selectedInstance.privateIp;
        break;
      case RSM:
        data.deviceId = selectedInstance.deviceId;
        break;
      case GCP:
        data.zone = selectedInstance.zone;
        data.projectId = selectedInstance.projectId;
        data.hostName = selectedInstance.privateIp;
        break;
      default:
        break;
    }

    setIsSaving(true);

    try {
      const path = `machines/${selectedWorkstation.machineId}`;
      await put({path, data, params: {verifyExistsInProvider: true}});
      successSnackbar('Remote Workstation has been updated.');
      loadWorkstationData();
      setUpdatedProvider('');
    } catch (error) {
      if (!handleUpdateProviderErrors(error, updatedProvider, {...data})) {
        dispatch(handleApiError(error));
      }
    }
    setIsEditingProvider(false);
    setIsSaving(false);
  };

  const saveUserEntitlements = async (usersToBePosted) => {
    setIsSaving(true);
    const promises = [];
    const {machineId} = selectedWorkstation;
    usersToBePosted.forEach((user) => {
      const entitlement = {
        userGuid: user.userGuid,
        upn: user.upn,
        machineId,
      };
      const promise = post({
        path: `deployments/${deploymentId}/entitlements`,
        data: entitlement,
      });
      promises.push(promise);
    });

    try {
      await Promise.all(promises);
      successSnackbar('Users entitled to the remote workstation.');
    } catch (error) {
      const errCode = error.code;
      // Ignore 404 and 409 errors from entitlements already existing/deleted
      if (errCode !== 404 && errCode !== 409) {
        errorSnackbar('Remote Workstation cannot be updated.');
      }
    }
    loadWorkstationData(true);
    setIsSaving(false);
  };

  const handleMachineNameChange = (machineName) => {
    const {provider} = selectedWorkstation;
    const {machineNameErrorMessage} = validateMachineName(
      machineName,
      provider
    );
    setMachineNameError(machineNameErrorMessage);
    setUpdatedMachineName(machineName);
  };

  const getDisabledMessage = () => {
    if (isFetching) {
      return 'Loading workstation...';
    }

    if (isSaving) {
      return 'Saving changes to workstation...';
    }
    return '';
  };

  const {item: latestMonitorTelemetry, isFetching: fetchingMonitorTelemetry} =
    useSelector((state) =>
      selectResourceItem(state, MONITOR_TELEMETRY_LATEST, selectedMachineId)
    );

  // Effect to fetch workstation & service account data
  useEffect(() => {
    if (deploymentId) {
      loadWorkstationData();
      dispatch(fetchCloudServiceAccounts({deploymentId}));
      if (config.isBetaOrStandalone()) {
        dispatch(fetchTelemetrySettings());
        getWorkstationServiceAccounts();
      }
    }

    return () => {
      // on unmount, clear selectedWorkstation
      dispatch(saveVariable('selectedWorkstation', {}));
    };
  }, [deploymentId]);

  // Effect to fetch & update monitor telemetry
  useEffect(() => {
    if (!config.isMonitorEnabled() || !deploymentId) {
      return () => {};
    }
    // Fetch session stats for the workstation for the first time
    dispatch(fetchLatestMonitorTelemetry([selectedMachineId]));

    // Update every 15 to 30 seconds
    const interval = setInterval(
      () => {
        dispatch(fetchLatestMonitorTelemetry([selectedMachineId]));
      },
      (Math.random() * 15 + 15) * 1000
    );
    return () => clearInterval(interval);
  }, [deploymentId]);

  useEffect(() => {
    if (!config.isMonitorEnabled() || !selectedWorkstation.agentMonitored) {
      return () => {};
    }

    // request monitor updates every 20~40 seconds
    const interval = setInterval(
      () => {
        if (selectedWorkstation.agentMonitored) {
          requestMonitorUpdate();
        }
      },
      (Math.random() * 20 + 20) * 1000
    );

    return () => clearInterval(interval);
  }, [selectedWorkstation.agentMonitored]);

  const renderMonitorConnectionStatus = () =>
    isLatestMonitorTelemetryValid(latestMonitorTelemetry) ? (
      <span className={classes.success}>Healthy</span>
    ) : (
      <div>
        <span className={classes.warning}>Unhealthy</span>
        <CAMTooltip
          isWarning
          text={
            latestMonitorTelemetry.msgReceivedOn
              ? `Last connection was on ${formatDateTime(
                  latestMonitorTelemetry.msgReceivedOn
                )}`
              : 'Unknown last connection date'
          }
        />
      </div>
    );

  const preparePcoipAgentStatus = () => {
    if (fetchingMonitorTelemetry) {
      return 'Loading...';
    }

    if (
      selectedWorkstation.agentMonitored &&
      isLatestMonitorTelemetryValid(latestMonitorTelemetry)
    ) {
      if (
        latestMonitorTelemetry.numberActivePcoipSessions === null ||
        latestMonitorTelemetry.numberActivePcoipSessions >= 0
      ) {
        return <span className={classes.success}>Running</span>;
      } else if (latestMonitorTelemetry.numberActivePcoipSessions === -1) {
        return <span className={classes.warning}>Not Running</span>;
      }
    }
    return 'Unknown';
  };

  const getConnectorLink = () =>
    linkTo(`/app/connectors/edit/${selectedWorkstation.connectorId}`);
  const getAssignedPoolLink = () =>
    linkTo(`${EDIT_POOL_LINK}/${assignedPool?.poolId}`);
  const workstationInfo = [
    {
      id: 'machineId',
      label: 'Workstation ID',
      value: selectedWorkstation.machineId,
    },
    {
      id: 'createdOn',
      label: 'Created On',
      value: formatDateTime(selectedWorkstation.createdOn),
    },
    {
      id: 'updatedOn',
      label: 'Last modified on',
      value: formatDateTime(selectedWorkstation.updatedOn),
    },
  ];

  let osDisplay = '';
  if (
    selectedWorkstation.osInfo &&
    selectedWorkstation.osInfo.publisher &&
    selectedWorkstation.osInfo.publisher !== 'unknown'
  ) {
    osDisplay = selectedWorkstation.osInfo.publisher;
  }
  if (
    selectedWorkstation.osInfo &&
    selectedWorkstation.osInfo.offer &&
    selectedWorkstation.osInfo.offer !== 'unknown'
  ) {
    osDisplay = `${osDisplay} ${selectedWorkstation.osInfo.offer}`;
  }
  if (osDisplay !== '') {
    workstationInfo.push({
      id: 'osInfo',
      label: 'OS info',
      value: osDisplay.toUpperCase(),
    });
  }

  if (selectedWorkstation.provider === AMT) {
    if (selectedWorkstation.providerMetadata?.manufacturer) {
      workstationInfo.push({
        id: 'manufacturerId',
        label: 'Manufacturer',
        value: selectedWorkstation.providerMetadata.manufacturer,
      });
    }
    if (selectedWorkstation.providerMetadata?.model) {
      workstationInfo.push({
        id: 'modelId',
        label: 'Model',
        value: selectedWorkstation.providerMetadata.model,
      });
    }
    if (selectedWorkstation.providerMetadata?.serialNumber) {
      workstationInfo.push({
        id: 'serialNumberId',
        label: 'Serial Number',
        value: selectedWorkstation.providerMetadata.serialNumber,
      });
    }
    if (selectedWorkstation.providerMetadata?.version) {
      workstationInfo.push({
        id: 'amtVersionId',
        label: 'AMT Version',
        value: selectedWorkstation.providerMetadata.version,
      });
    }
  }

  if (selectedWorkstation.provider === AME) {
    if (selectedWorkstation.siteName) {
      workstationInfo.push({
        id: 'siteName',
        label: 'Site',
        value: selectedWorkstation.siteName,
      });
    }
  }

  if (
    config.isMonitorEnabled &&
    selectedWorkstation.agentMonitored &&
    latestMonitorTelemetry
  ) {
    workstationInfo.push({
      id: 'monitorVersion',
      label: 'Anyware Monitor Version',
      value: latestMonitorTelemetry.agentVersion || 'Information not available',
    });
    workstationInfo.push({
      id: 'monitorConnection',
      label: 'Anyware Monitor Connection',
      value: renderMonitorConnectionStatus(),
    });
    workstationInfo.push({
      id: 'pcoipAgentStatus',
      label: 'PCoIP Agent Status',
      value: preparePcoipAgentStatus(),
      toolTipText: COLUMN_TOOLTIP_REQUIRES_MONITOR,
    });
  }

  const getAssignedPoolInfo = (poolName, policyType) => (
    <Grid container direction="row" alignItems="center">
      <AssignmentPolicyIcon policyType={policyType} />
      {capitalize(poolName)}
    </Grid>
  );

  if (assignedPool.poolName && assignedPool?.policies?.assignment?.policyType) {
    const {
      poolName,
      policies: {
        assignment: {policyType},
      },
    } = assignedPool;
    workstationInfo.push({
      id: 'associatedPool',
      label: 'Associated Pool',
      value: getAssignedPoolInfo(poolName, policyType),
      link: getAssignedPoolLink(),
    });
  }

  if (
    !isEmpty(selectedWorkstation.connectorId) &&
    !isEmpty(connector.connectorName)
  ) {
    if (!['deleting', 'deleted'].includes(connector.status)) {
      workstationInfo.push({
        id: 'connectorId',
        label: 'Connector',
        value: connector.connectorName,
        link: getConnectorLink(),
      });
    }
  }

  const saveWorkstationBasicInfo = async () => {
    setIsSaving(true);
    const path = `machines/${selectedWorkstation.machineId}`;
    try {
      await put({
        path,
        data: {
          machineName: updatedMachineName,
          hostName: updatedMachineHostname,
        },
      });
    } catch (error) {
      dispatch(handleApiError(error));
      return false;
    }
    loadWorkstationData();
    setIsSaving(false);
    return true;
  };

  const onCloudSelection = (selection) => {
    const {provider, awsRegion, gcpZone, azureResourceGroup} = selection;
    setUpdatedProvider(provider);

    switch (provider) {
      case AWS:
        dispatch(fetchAwsInstances(deploymentId, awsRegion));
        break;
      case AZURE:
        dispatch(fetchAzureInstances(deploymentId, azureResourceGroup));
        break;
      case GCP:
        dispatch(fetchGcpInstances(deploymentId, gcpZone));
        break;
      default:
        break;
    }
  };

  const onProviderChange = () => {
    setUpdatedProvider('');
    setSelectedInstance({});
  };

  const renderTabs = () => {
    const tabsToRender = ['Overview', 'User management'];

    if (config.isMonitorEnabled()) {
      tabsToRender.push('ANYWARE MONITOR');
    }

    if (
      telemetrySettings &&
      telemetrySettings.enabled
    ) {
      tabsToRender.push('SESSION INFORMATION');
    }

    return (
      <HorizontalTab
        tabs={tabsToRender}
        selectedTab={selectedTab}
        setSelectedTab={changeSelectedTab}
      />
    );
  };

  const renderSectionHeader = (displayText) => (
    <SectionHeader displayText={displayText} />
  );

  const renderInfoItem = (info) => (
    <KeyValueInfo
      key={info.id}
      label={info.label}
      value={info.value}
      link={info.link}
      toolTipText={info.toolTipText}
      enableCopy={['machineId', 'instanceId'].includes(info.id)}
    />
  );

  const handleAgentMonitoredToggle = async (isAgentMonitored) => {
    setIsSaving(true);
    const path = `machines/${selectedWorkstation.machineId}`;
    try {
      await put({
        path,
        data: {
          agentMonitored: isAgentMonitored,
        },
      });
    } catch (error) {
      dispatch(handleApiError(error));
      return false;
    }
    loadWorkstationData();
    setIsSaving(false);
    return true;
  };

  const renderWorkstationInformation = () =>
    workstationInfo.map((info) => renderInfoItem(info));
  const isAWSProvider = selectedWorkstation.provider === AWS;

  const renderInfoSection = () => {
    if (!isTab(TAB_OVERVIEW)) {
      return null;
    }
    return (
      <>
        <CAMCard>
          <GridN singleColumn>
            {renderSectionHeader('WORKSTATION INFORMATION')}
          </GridN>
          <GridN>{renderWorkstationInformation()}</GridN>
        </CAMCard>

        <CAMCard>
          <GridN singleColumn>
            {renderSectionHeader('WORKSTATION SETTINGS')}
          </GridN>
          <div className={classes.dropbox}>
            <GridN sm={12} md={6} lg={6}>
              <div>
                <div className={classNames(classes.flexGrowOne)}>
                  <KeyValueInfo label="LAN IP/FQDN" value={IP_FQDN_TOOLTIP} />
                </div>
                <div className={classNames(classes.flexGrowOne)}>
                  <TextInput
                    disabled={isFetching || isSaving}
                    value={updatedMachineHostname || ''}
                    onChange={setUpdatedMachineHostname}
                    isError={
                      !isFetching &&
                      updatedMachineHostname &&
                      !validateHostname(updatedMachineHostname)
                    }
                    helperText={
                      !isFetching
                        ? hostNameErrorMessage(updatedMachineHostname)
                        : ''
                    }
                    placeholder="Enter workstation IP/FQDN"
                  />
                </div>
              </div>

              {isAWSProvider && (
                <div className={classes.itemEnd}>
                  <div
                    className={classNames(
                      classes.flexGrowOne,
                      classes.itemFlexEnd
                    )}
                  >
                    <KeyValueInfo
                      label="Workstation name"
                      value={machineNameError}
                    />
                    <TextInput
                      disabled={isFetching || isSaving}
                      tooltipText="It can be different from the EC2 instance name on AWS."
                      value={updatedMachineName || ''}
                      onChange={handleMachineNameChange}
                      isError={!isFetching && !isEmpty(machineNameError)}
                      placeholder="Enter workstation name"
                    />
                  </div>
                </div>
              )}
              <div className={classes.itemEnd}>
                <div
                  className={classNames(
                    classes.itemButton,
                    classes.itemButtonRow,
                    classes.itemEnd
                  )}
                >
                  <SaveButton
                    onClick={saveWorkstationBasicInfo}
                    disabled={
                      !basicInformationUpdated() ||
                      !isEmpty(machineNameError) ||
                      !validateHostname(updatedMachineHostname)
                    }
                    saving={isSaving || isSavingAmtCredentials}
                  />
                </div>
              </div>
            </GridN>
          </div>
        </CAMCard>
      </>
    );
  };

  const renderSessionStatsSection = () => {
    if (!isTab(TAB_SESSION_INFO)) {
      return null;
    }
    return (
      <WorkstationSessionCard
        loadingSessionAttempts={fetchingSessionAttempts}
        loadingSessionStatus={fetchingSessionStats}
        loadingUsers={fetchingAdUsers}
        sessionAttempts={sessionAttempts}
        sessionStatus={sessionStatus}
        users={adUsers}
        setDateRange={setSaDateRange}
        isTelemetryEnabled={telemetrySettings && telemetrySettings.enabled}
        selectedWorkstation={selectedWorkstation}
        latestMonitorTelemetry={latestMonitorTelemetry}
        loadingMonitorTelemetry={fetchingMonitorTelemetry}
      />
    );
  };

  const renderMonitorConfigurationSection = () => {
    if (!isTab(TAB_MONITOR_CONFIGURATION)) {
      return null;
    }
    return (
      <MonitorConfigurationCard
        handleAgentMonitoredToggle={handleAgentMonitoredToggle}
        isSaving={isSaving}
        selectedWorkstation={selectedWorkstation}
        workstationServiceAccounts={workstationServiceAccounts}
        latestMonitorTelemetry={latestMonitorTelemetry}
        getWorkstationServiceAccounts={getWorkstationServiceAccounts}
      />
    );
  };

  const handleToggleManaged = async (event) => {
    setIsSaving(true);
    const newManaged = event.target.checked;
    setUpdatedMachineManaged(newManaged);

    const data = {managed: newManaged};

    try {
      const path = `machines/${selectedWorkstation.machineId}`;
      await put({path, data});
      selectedWorkstation.managed = newManaged;
      successSnackbar('Remote Workstation has been updated.');
    } catch (error) {
      dispatch(handleApiError(error));
      setUpdatedMachineManaged(!newManaged);
      selectedWorkstation.managed = !newManaged;
    }
    selectedWorkstation.powerState = 'unknown';
    setIsSaving(false);
  };

  const providers = [GCP, AZURE, AWS];
  if (config.AMT_SUPPORTED) {
    providers.push(AMT);
  }
  if (config.RSM_SUPPORTED) {
    providers.push(RSM);
  }

  const renderProviderEditing = () => (
    <ProviderSelector
      providers={providers}
      providerOnly={false}
      deploymentId={selectedWorkstation.deploymentId}
      onSelectionFinish={onCloudSelection}
      onProviderChange={onProviderChange}
    />
  );

  const handleAmtUsernameChange = (event) => {
    const newAmtUsername = event.target.value;
    setEnteredAmtUsername(newAmtUsername);
  };

  const handleAmtPasswordChange = (event) => {
    const newAmtPassword = event.target.value;
    setEnteredAmtPassword(newAmtPassword);
  };

  const saveAmtAlternateHostname = async () => {
    setIsSaving(true);

    let hostnameToSend = updatedAmtHostname;
    if (isEmpty(updatedAmtHostname)) {
      hostnameToSend = '';
    }

    const path = `machines/${selectedWorkstation.machineId}`;
    try {
      await put({
        path,
        data: {
          machineName: updatedMachineName,
          providerAlternateHostname: hostnameToSend,
        },
      });
    } catch (error) {
      dispatch(handleApiError(error));
      setIsSaving(false);
      return false;
    }
    loadWorkstationData();
    setIsSaving(false);
    return true;
  };

  const saveAmtCredentials = async () => {
    setIsSavingAmtCredentials(true);

    const {machineId} = selectedWorkstation;
    const path = `${mapResourceToPath(MACHINE_MGMT_CREDENTIALS, {
      machineId,
    })}?verifyExistsInProvider=true`;
    try {
      await put({
        path,
        data: {
          username: enteredAmtUsername,
          password: enteredAmtPassword,
        },
      });
      setEnteredAmtUsername('');
      setEnteredAmtPassword('');
    } catch (error) {
      dispatch(handleApiError(error));
      setIsSavingAmtCredentials(false);
      setIsEditingAmtCredentials(false);
      return false;
    }
    loadWorkstationData();
    setIsSavingAmtCredentials(false);
    setIsEditingAmtCredentials(false);
    return true;
  };

  const deleteAmtCredentials = async () => {
    const {machineId} = selectedWorkstation;
    const path = `${mapResourceToPath(MACHINE_MGMT_CREDENTIALS, {machineId})}`;
    try {
      await del({
        path,
      });
    } catch (error) {
      dispatch(handleApiError(error));
      return false;
    }
    loadWorkstationData();
    setShowDeleteAmtCredsDialog(false);
    return true;
  };

  const renderAmtAlternateHostname = () => (
    <div className={classes.dropbox}>
      <div className={classes.gridRow}>
        <KeyValueInfo
          label="Optional Alternate AMT IP/FQDN"
          value={AMT_IP_FQDN_TOOLTIP}
        />
      </div>
      <div className={classes.gridRow}>
        <div className={classNames(classes.flexGrowOne, classes.gridRowItem)}>
          <TextInput
            disabled={isFetching || isSaving}
            value={updatedAmtHostname || ''}
            onChange={setUpdatedAmtHostname}
            isError={
              !isFetching &&
              updatedAmtHostname &&
              !validateHostname(updatedAmtHostname)
            }
            helperText={
              !isFetching && updatedAmtHostname
                ? hostNameErrorMessage(updatedAmtHostname)
                : ''
            }
            placeholder="Enter alternate AMT IP/FQDN"
          />
        </div>
        <div className={classes.itemButton}>
          <SaveButton
            disabled={!alternateAmtHostnameUpdated()}
            onClick={saveAmtAlternateHostname}
            saving={isSaving}
            dataTestId="alternate-hostname-save-button"
          />
        </div>
      </div>
    </div>
  );

  const renderSavedAmtCredentials = () =>
    selectedWorkstation.hasManagementCredentials && !isEditingAmtCredentials ? (
      <div className={classes.dropbox}>
        <GridN>
          <>
            <Typography className={classes.inputTextFieldLabel}>
              Stored Intel AMT Credentials
            </Typography>
            <div>
              <div className={classes.credentialsItemsRow}>
                <Tooltip title="Intel AMT credentials stored">
                  <div className={classes.credentialsIconDiv}>
                    <RiLockPasswordFill className={classes.credentialsIcon} />
                  </div>
                </Tooltip>

                <div className={classes.itemButton}>
                  <div className={classes.gridRowItem}>
                    <CAMButton
                      buttonText="Edit Credentials"
                      testId="edit-credentials-button"
                      onClick={() => setIsEditingAmtCredentials(true)}
                    />
                  </div>
                </div>

                <div className={classes.itemButton}>
                  <CAMButton
                    buttonText="Delete Credentials"
                    testId="delete-credentials-button"
                    onClick={() => setShowDeleteAmtCredsDialog(true)}
                  />
                </div>
              </div>
            </div>
          </>
        </GridN>
      </div>
    ) : null;

  const renderDeleteAmtCredentialsDialog = () =>
    showDeleteAmtCredsDialog ? (
      <CAMDeleteDialog
        resourceName="Intel AMT credentials"
        open={showDeleteAmtCredsDialog}
        onOk={deleteAmtCredentials}
        onCancel={() => {
          setShowDeleteAmtCredsDialog(false);
        }}
        content={
          <>By deleting credentials Intel AMT management will stop working.</>
        }
      />
    ) : null;

  const renderSetAmtCredentials = () =>
    selectedWorkstation.provider === AMT &&
    (isEditingAmtCredentials ||
      !selectedWorkstation.hasManagementCredentials) ? (
      <div className={classes.dropbox}>
        <GridN lg={4}>
          <div className={classes.flexGrowOne}>
            <Typography className={classes.inputTextFieldLabel}>
              Intel AMT Username
            </Typography>
            <CAMTextField
              value={enteredAmtUsername}
              placeholderText="Enter Intel AMT username"
              fieldType="text"
              isRequired
              disabled={isSavingAmtCredentials}
              onChange={handleAmtUsernameChange}
            />
          </div>
          <div>
            <Typography className={classes.inputTextFieldLabel}>
              Intel AMT Password
            </Typography>
            <CAMTextField
              value={enteredAmtPassword}
              placeholderText="Enter Intel AMT password"
              fieldType="password"
              isRequired
              disabled={isSavingAmtCredentials}
              onChange={handleAmtPasswordChange}
            />
          </div>
          <div className={classes.itemEnd}>
            <div
              className={[
                classes.itemButton,
                isEditingAmtCredentials
                  ? classes.itemButtonRowWithBreakPoint
                  : classes.itemButtonRow,
                classes.itemEnd,
                classes.gridRowItem,
              ].join(' ')}
            >
              <SaveButton
                buttonText="Save"
                disabled={
                  !(
                    enteredAmtUsername.length > 0 &&
                    enteredAmtPassword.length > 0
                  )
                }
                onClick={saveAmtCredentials}
                saving={isSavingAmtCredentials || isSaving}
                dataTestId="credentials-save-button"
                customClass={
                  isEditingAmtCredentials ? classes.buttonFill : null
                }
              />
            </div>
            {isEditingAmtCredentials ? (
              <div
                className={classNames(
                  classes.itemButton,
                  classes.itemButtonRowWithBreakPoint,
                  classes.itemEnd
                )}
              >
                <CAMButton
                  buttonText="Cancel"
                  testId="cancel-edit-credentials-button"
                  onClick={() => {
                    setEnteredAmtUsername('');
                    setEnteredAmtPassword('');
                    setIsEditingAmtCredentials(false);
                  }}
                  customClass={classes.buttonFill}
                />
              </div>
            ) : null}
          </div>
        </GridN>
      </div>
    ) : null;

  const renderAmtCredentialsNeededError = () =>
    updatedMachineManaged &&
    !selectedWorkstation.hasManagementCredentials &&
    !isEditingAmtCredentials ? (
      <div className={classes.errorInputLabel}>
        <InputLabel displayText="This workstation cannot be managed by Intel AMT without administrator credentials." />
      </div>
    ) : null;

  const renderProviderDisplay = () => {
    if (isFetching || !deploymentId) {
      return <GridN>Loading provider information...</GridN>;
    }
    const {
      provider,
      subscriptionId,
      instanceId,
      zone,
      resourceGroup,
      region,
      provisioned,
      // fields in rsm machine
      deviceId,
    } = selectedWorkstation;

    const providerInfo = [
      {
        id: 'provider',
        label: 'Provider',
        value: (
          <Grid container direction="row" alignItems="center">
            <ProviderIcon provider={provider || ''} />
            {mapProviders(provider)}
          </Grid>
        ),
      },
    ];

    switch (provider) {
      case GCP:
        providerInfo.push({id: 'zone', label: 'GCP zone', value: zone});
        break;
      case AWS:
        providerInfo.push({
          id: 'instanceId',
          label: 'AWS Instance ID',
          value: instanceId,
        });
        providerInfo.push({id: 'region', label: 'AWS region', value: region});
        break;
      case AZURE:
        providerInfo.push({
          id: 'subscriptionId',
          label: 'Subscription Id',
          value: subscriptionId,
        });
        providerInfo.push({
          id: 'resourceGroup',
          label: 'Resource Group',
          value: resourceGroup,
        });
        break;
      case RSM:
        providerInfo.push({
          id: 'deviceId',
          label: 'Device ID',
          value: deviceId,
        });
        break;
      default:
        break;
    }
    if (provisioned) {
      providerInfo.push({
        id: 'provisioned',
        label: 'Provisioned by',
        value: 'Anyware Manager',
        toolTipText: `This machine was provisioned on ${mapProviders(
          provider
        )} by using Anyware Manager.`,
      });
    }

    const managedDescription =
      selectedWorkstation.provider !== AMT
        ? 'Allows Anyware Manager to make state changes to this remote workstation in the Public Cloud.'
        : 'Enable Intel AMT management for this remote workstation.';

    return provider === ONPREM ? (
      <>
        <GridN singleColumn>
          If this remote workstation exists in a supported provider, you can
          edit the provider in order to enable power actions. You will be to
          power manage the workstation via Anyware Manager while keeping the
          same Active Directory users entitled to it.
        </GridN>

        <GridN singleColumn>
          <div className={classes.editProviderSection}>
            {providerInfo.map((info) => renderInfoItem(info))}
            <div className={classes.editProviderButton}>
              <CAMButton
                size="small"
                buttonText="Edit provider"
                onClick={() => setIsEditingProvider(true)}
              />
            </div>
          </div>
        </GridN>
      </>
    ) : (
      <>
        <GridN>
          {providerInfo.map((info) => (
            <div className={classes.dropbox}>{renderInfoItem(info)}</div>
          ))}
        </GridN>
        <div className={classNames(classes.inlineBlock, classes.dropbox)}>
          <div className={classes.gridRow}>
            <KeyValueInfo
              label="Managed by Anyware Manager"
              value={managedDescription}
            />
            <ToggleSwitch
              isOn={updatedMachineManaged}
              onClick={handleToggleManaged}
              saving={isSaving}
              testId="machine-managed-toggle"
            />
          </div>
        </div>
        {provider === AMT ? (
          <>
            {renderAmtAlternateHostname()}
            {renderSavedAmtCredentials()}
            {renderDeleteAmtCredentialsDialog()}
            {renderSetAmtCredentials()}
            {renderAmtCredentialsNeededError()}
          </>
        ) : null}
      </>
    );
  };

  const renderInstanceResult = () => {
    const wrapRender = (response) => <CAMCard>{response}</CAMCard>;

    if (isEmpty(updatedProvider)) {
      return null;
    }

    if (fetchingInstances) {
      return wrapRender(<>Fetching instances...</>);
    }

    if (isEmpty(selectedInstance) && updatedProvider !== AMT) {
      return wrapRender(
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {`No instance found on ${mapProviders(
            updatedProvider
          )} with the name: ${selectedWorkstation.machineName}.`}
        </>
      );
    }

    return wrapRender(
      <GridN singleColumn>
        {updatedProvider !== AMT
          ? `Found instance on ${mapProviders(updatedProvider)} matching ${
              selectedWorkstation.machineName
            }.`
          : `Found an ${mapProviders(updatedProvider)} workstation matching ${
              selectedWorkstation.machineName
            }`}
        <SaveButton
          buttonText={`Set provider to ${mapProviders(updatedProvider)}`}
          onClick={saveProvider}
        />
      </GridN>
    );
  };

  const renderProvidersSection = () => {
    if (!isTab(TAB_OVERVIEW)) {
      return null;
    }

    return (
      <CAMCard>
        <>
          {isEditingProvider ? (
            <>
              <GridN singleColumn>{renderSectionHeader('PROVIDER')}</GridN>
              {renderProviderEditing()}
            </>
          ) : (
            <>
              <GridN singleColumn>
                {renderSectionHeader('PROVIDER INFORMATION')}
              </GridN>
              {renderProviderDisplay()}
            </>
          )}
          {renderInstanceResult()}
        </>
      </CAMCard>
    );
  };

  const renderEntitlementsSection = () => {
    if (!isTab(TAB_USER_MANAGEMENT)) {
      return null;
    }
    return (
      <CAMCard>
        <GridN singleColumn>
          {renderSectionHeader('MANAGE USER ENTITLEMENTS FOR WORKSTATION')}
        </GridN>
        <GridN singleColumn>
          <AddRemoveUser
            id="add-remove-user"
            addedUsers={entitlementsTable}
            onAddUser={saveUserEntitlements}
            onRemoveUser={removeEntitlement}
            isFetching={isFetching}
            deploymentId={selectedWorkstation.deploymentId}
            disabled={isSaving || isFetching}
            disabledMessage={getDisabledMessage()}
            isEditing
          />
        </GridN>
      </CAMCard>
    );
  };

  const renderPoolInfo = () => {
    if (!isTab(TAB_USER_MANAGEMENT)) {
      return null;
    }
    return (
      <RemoteWorkstationPoolInfo
        selectedMachine={selectedWorkstation}
        isFetchingMachines={isFetching}
      />
    );
  };

  return (
    <Root className={classes.pageContainer}>
      <EditRemoteWorkstationAppBar
        selectedWorkstation={selectedWorkstation}
        fetchingWorkstation={isSaving || isFetching}
        reloadWorkstation={loadWorkstationData}
      />
      {renderTabs()}
      {renderInfoSection()}

      {config.isMonitorEnabled() && renderMonitorConfigurationSection()}

      {renderSessionStatsSection()}

      {renderProvidersSection()}
      {renderEntitlementsSection()}
      {renderPoolInfo()}
    </Root>
  );
}

EditRemoteWorkstation.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EditRemoteWorkstation;
