import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

const PREFIX = 'ResourceProperty';

const classes = {
  container: `${PREFIX}-container`,
  iconWrapper: `${PREFIX}-iconWrapper`,
  valueWrapper: `${PREFIX}-valueWrapper`,
  propertyValue: `${PREFIX}-propertyValue`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    flexDirection: 'row',
  },

  [`& .${classes.iconWrapper}`]: {
    marginRight: '16.5px',
  },

  [`& .${classes.valueWrapper}`]: {
    marginTop: '8px',
  },

  [`& .${classes.propertyValue}`]: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '157%',
    letterSpacing: '0.1px',
    color: 'rgba(0, 0, 0, 0.87)',
  }
}));

function ResourceProperty({label, propertyValue, icon}) {

  const RenderedIcon = icon;

  const renderPropertyValue = () => {
    if (!propertyValue) {
      return <Skeleton />;
    }
    if (typeof propertyValue === 'string') {
      return (
        <Typography className={classes.propertyValue}>
          {propertyValue}
        </Typography>
      );
    }
    return propertyValue;
  };

  return (
    <Root className={classes.container}>
      <div className={classes.iconWrapper}>
        <RenderedIcon />
      </div>
      <div>
        <Typography className={classes.label}>{label}</Typography>
        <div className={classes.valueWrapper}>{renderPropertyValue()}</div>
      </div>
    </Root>
  );
}

ResourceProperty.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  // propertyValue can be falsy while resource objects are being fetched
  propertyValue: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

ResourceProperty.defaultProps = {
  propertyValue: null,
};

export default ResourceProperty;
