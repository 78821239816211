import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import {styled} from '@mui/material/styles';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';
import PoolBanner from 'components/pools/PoolBanner';
import BetaDialog from 'components/betaInfo/BetaDialog';
import BetaBanner from 'components/betaInfo/BetaBanner';
import UnlockCAM from 'components/deployments/UnlockCAM';
import BulkDeleteRemoteWorkstationDialog from 'components/remoteWorkstations/BulkDeleteRemoteWorkstationsDialog';
import DeleteRemoteWorkstationDialog from 'components/remoteWorkstations/DeleteRemoteWorkstationDialog';
import RemoteWorkstationErrorDialog from 'components/remoteWorkstations/RemoteWorkstationErrorDialog';
import DeploymentFilterDialog from 'components/topbar/DeploymentFilterDialog';
import ConnectorHealthDialog from 'components/connectors/ConnectorHealthDialog';
import TopBar from 'components/topbar/TopBar';
import config from 'config';
import useIdleMonitor from 'hooks/useIdleMonitor';
import {hideSessionExpired, logoutUserFromCAM} from 'redux/actions/dataActions';
import {startPolling} from 'redux/actions/pollingActions';
import {push} from 'redux/ReduxHistory';
import CasmCookies from 'utils/cookies';
import {selectVariable} from 'utils/reduxSelectors';
import {isEmpty} from 'utils/utils';
import {get} from 'api/Api';

// START: This code is a temporary fix to pass nonce value to react-select
// Issue: https://github.com/JedWatson/react-select/issues/4631

import useAcceptPolicyTracking from 'hooks/useAcceptPolicyTracking';
import useVerifyExistingDeployments from 'hooks/useVerifyExistingDeployments';
import {subscribeSaveStateToLocalStorage} from 'redux/store/store';
import ExpiredLicenseBanner from 'components/licenses/ExpiredLicenseBanner';
import SessionTrackingBanner from 'components/sessionTrackingBanner/SessionTrackingBanner';
import AzureRoleDialog from './AzureRoleDialog';
import BulkActionsDialog from './BulkActionsDialog';
import ConfirmationDialog from './ConfirmationDialog';
import ErrorDialog from './ErrorDialog';
import ErrorModal from './ErrorModal';
import MainPane from './MainPane';
import SideBar from './sidebar/SideBar';

const PREFIX = 'AuthenticatedApp';

const classes = {
  root: `${PREFIX}-root`,
  flex: `${PREFIX}-flex`,
  appFrame: `${PREFIX}-appFrame`,
  grid: `${PREFIX}-grid`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  mainPane: `${PREFIX}-mainPane`,
  logo: `${PREFIX}-logo`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.root}`]: {
    width: '100%',
    height: '100%',
    zIndex: 0,
    overflow: 'hidden',
    position: 'absolute',
    backgroundColor: config.isUpdatedStylingEnabled()
      ? theme.palette.surface.white
      : theme.palette.surface.main,
  },

  [`& .${classes.flex}`]: {
    flex: 1,
  },

  [`& .${classes.appFrame}`]: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },

  [`& .${classes.grid}`]: {
    flexGrow: 1,
    container: {
      justifyContent: 'justify-xs-flex-end',
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: 280,
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.up('md')]: {
      // width: drawerWidth,
      position: 'relative',
      height: '100%',
    },
  },

  [`& .${classes.content}`]: {
    width: '100%',
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    height: `calc(100% - ${theme.sizes.topBarHeight})`,
    marginTop: theme.sizes.topBarHeight,
    overflow: 'auto',
  },

  [`& .${classes.mainPane}`]: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.logo}`]: {
    marginTop: 5,
    marginBottom: 5,
    height: 40,
  },
}));

const cache = createCache({
  key: 'key-emotion-css',

  nonce:
    document.querySelector('meta[property="csp-nonce"]')?.content ||
    '__SERVER__NONCE__',

  prepend: true,
});
// END

export default function AuthenticatedApp() {
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const shouldDisplaySessionExpired = useSelector((state) =>
    Boolean(selectVariable(state, 'shouldDisplaySessionExpired'))
  );
  const [betaAccepted, setBetaAccepted] = useState(
    localStorage.getItem('betaAccepted') === 'true'
  );
  const [privacyPolicyRequired, setPrivacyPolicyRequired] = useState(false);
  const [eulaRequired, setEulaRequired] = useState(false);
  const [dataExportRequired, setDataExportRequired] = useState(false);
  const [isBetaBannerVisible, setIsBetaBannerVisible] = useState(
    config.isBeta()
  );
  const [isExpiredLicenseVisible, setIsExpiredLicenseVisible] = useState(false);

  useIdleMonitor();

  const verifyExistingDeployments = useVerifyExistingDeployments();
  const {currentUser} = useAcceptPolicyTracking();
  const isTenantUser = currentUser !== null;

  const storeSamlConfigurationId = () => {
    const samlConfigurationId = CasmCookies.getItem('samlConfigurationId');
    if (!isEmpty(samlConfigurationId)) {
      CasmCookies.removeItem('samlConfigurationId');
      localStorage.setItem('samlConfigurationId', samlConfigurationId);
    }
  };

  useEffect(() => {
    storeSamlConfigurationId();
    dispatch(hideSessionExpired());
    dispatch(startPolling());
  }, []);

  // Handle logic for saving redux state to local storage
  useEffect(() => {
    const unsubscribe = subscribeSaveStateToLocalStorage();

    return function cleanup() {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (isTenantUser) {
      if (config.PRIVACY_POLICY_TRACKING_ENABLED) {
        setPrivacyPolicyRequired(
          !currentUser.privacyPolicyAcceptedOn ||
            (currentUser.privacyPolicyAcceptedOn &&
              currentUser.privacyPolicyAcceptedOn <
                config.PRIVACY_POLICY_UPDATED_ON)
        );
      }
      setEulaRequired(
        !currentUser.eulaAcceptedOn ||
          (currentUser.eulaAcceptedOn &&
            currentUser.eulaAcceptedOn < config.EULA_UPDATED_ON)
      );
      if (
        currentUser.requestCountry &&
        currentUser.requestCountry.toUpperCase() === 'CN'
      ) {
        setDataExportRequired(!currentUser.dataExportAcceptedOn);
      }
    } else {
      setPrivacyPolicyRequired(false);
      setEulaRequired(false);
      setDataExportRequired(false);
    }
  }, [JSON.stringify(currentUser)]);

  const handleSignin = () => {
    const samlConfigurationId = localStorage.getItem('samlConfigurationId');

    if (!isEmpty(samlConfigurationId)) {
      dispatch(push(`/?configurationId=${samlConfigurationId}`));
    } else {
      dispatch(push('/'));
    }
  };

  const handleErrorModalClose = () => setError(false);

  const signOut = () => {
    dispatch(logoutUserFromCAM());
    dispatch(push('/'));
  };

  const handleSignOut = async () => {
    const info = CasmCookies.getDecodedAuthToken();

    switch (info.idp) {
      case 'azure':
      case 'google':
        signOut();
        break;
      case 'saml': {
        dispatch(logoutUserFromCAM());
        const samlConfigurationId = localStorage.getItem('samlConfigurationId');
        dispatch(push(`/?configurationId=${samlConfigurationId}`));
        break;
      }
      default:
        dispatch(logoutUserFromCAM());
        dispatch(push('/'));
        break;
    }
  };

  const handleBetaSelect = () => {
    localStorage.setItem('betaAccepted', true);
    setBetaAccepted(true);
  };

  const getAnywareManagerEnterpriseLicenseStatus = async () => {
    if (!config.isBeta() || !config.isLicensingEnabled()) {
      return;
    }

    try {
      const licenseEntitlementResponse = await get({
        path: 'licenses/features/entitlements',
      });

      if (licenseEntitlementResponse.data.licenseMode === 'legacy') {
        setIsExpiredLicenseVisible(false);
        return;
      }

      const licenseResponse = await get({path: 'licenses'});
      const licenses = licenseResponse.data;
      const anywareManagerEnterpriseLicense = licenses.find((license) => {
        if (!license.data) {
          return false;
        }

        return license.data.features.find((feature) => {
          const featureFinalExpiry = new Date(feature.finalExpiry);
          return (
            feature.name === 'AnywareManagerEnterprise' &&
            featureFinalExpiry > new Date()
          );
        });
      });

      if (anywareManagerEnterpriseLicense) {
        setIsExpiredLicenseVisible(false);
      } else {
        setIsExpiredLicenseVisible(true);
      }
    } catch {
      setIsExpiredLicenseVisible(false);
    }
  };

  useEffect(() => {
    getAnywareManagerEnterpriseLicenseStatus();
  }, []);

  const renderMainComponent = () => {
    if (config.isBeta() && !betaAccepted) {
      return <BetaDialog open onBetaSelect={handleBetaSelect} />;
    }
    if (
      !verifyExistingDeployments() ||
      privacyPolicyRequired ||
      eulaRequired ||
      dataExportRequired
    ) {
      return <UnlockCAM handleSignout={handleSignOut} />;
    }
    return (
      <>
        {isBetaBannerVisible && (
          <BetaBanner onClick={() => setIsBetaBannerVisible(false)} />
        )}
        {isExpiredLicenseVisible && (
          <ExpiredLicenseBanner
            onClick={() => {
              setIsExpiredLicenseVisible(false);
            }}
          />
        )}
        <PoolBanner />
        <SessionTrackingBanner />
        <div className={classes.mainPane}>
          <MainPane />
        </div>
      </>
    );
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      dispatch(hideSessionExpired());
    }
  };

  return (
    // Wrap DOM in <CacheProvider /> to pass nonce value
    <CacheProvider value={cache}>
      <Root>
        <div className={classes.root}>
          <div className={classes.appFrame}>
            <TopBar handleSignout={handleSignOut} />
            <SideBar />
            {shouldDisplaySessionExpired && (
              <Dialog
                onClose={handleClose}
                disableEscapeKeyDown
                open={shouldDisplaySessionExpired}
                aria-labelledby="timeout-dialog-title"
              >
                <DialogTitle id="timeout-dialog-title">
                  Your session has expired!
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    You have been signed out of your account. In order to
                    re-sign in again, click on the following button.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={handleSignin}
                    color="primary"
                  >
                    GO BACK TO HOME
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            <main className={classes.content}>{renderMainComponent()}</main>
            <ErrorModal
              open={error}
              errorHeader="Signout Error"
              errorMessage="An error occurred while signing out."
              handleClose={handleErrorModalClose}
            />
            <ConfirmationDialog />
            <ErrorDialog />
            <ConnectorHealthDialog />
            <AzureRoleDialog />
            <DeleteRemoteWorkstationDialog />
            <RemoteWorkstationErrorDialog />
            <BulkDeleteRemoteWorkstationDialog />
            <DeploymentFilterDialog />
            <BulkActionsDialog />
          </div>
        </div>
      </Root>
    </CacheProvider>
  );
}
