import {Button, Grid, Paper, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import OkIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import UnknownIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {capitalize} from 'helpers/core';
import {updateTableFetchParams} from 'redux/actions/tableDataActions';
import {
  ACTIVITY_LOGS,
  ACTIVITY_LOG_LINK,
  CONNECTOR_STATUS_FOOTER,
  CONNECTOR_STATUS_HEADER,
  NO_CONNECTOR_STATUS_MESSAGE,
  UPDATE_CONNECTOR_HEALTH_STATUS,
} from 'utils/constants';
import {mapComponentNameToDisplayName} from 'utils/Mappings';
import {
  formatDateTime,
  unpackConnectorStatuses,
  healthUpdatesAreOld,
} from 'utils/utils';

const PREFIX = 'ConnectorStatusSummary';

const classes = {
  rootContainer: `${PREFIX}-rootContainer`,
  statusContainer: `${PREFIX}-statusContainer`,
  statusName: `${PREFIX}-statusName`,
  header: `${PREFIX}-header`,
  statusSummary: `${PREFIX}-statusSummary`,
  statusSummaryText: `${PREFIX}-statusSummaryText`,
  footer: `${PREFIX}-footer`,
  noDataMessage: `${PREFIX}-noDataMessage`,
  greyText: `${PREFIX}-greyText`,
  reducedHeight: `${PREFIX}-reducedHeight`,
};

const StyledPaper = styled(Paper)(() => ({
  [`&.${classes.rootContainer}`]: {
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '1rem',
    height: '100%',
    minWidth: '315px',
  },

  [`& .${classes.statusContainer}`]: {
    paddingTop: '0.6rem',
    paddingBottom: '0.6rem',
  },

  [`& .${classes.statusName}`]: {
    fontSize: '0.825rem',
    lineHeight: '1.1rem',
    minHeight: '2.1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },

  [`& .${classes.header}`]: {
    height: '10%',
    fontWeight: '450',
  },

  [`& .${classes.statusSummary}`]: {
    width: '5rem',
    lineHeight: '1.1rem',
    minHeight: '2rem',
  },

  [`& .${classes.statusSummaryText}`]: {
    fontSize: '0.825rem',
    paddingLeft: '0.25rem',
  },

  [`& .${classes.footer}`]: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    paddingRight: '0.25rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },

  [`& .${classes.noDataMessage}`]: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '1rem',
    fontWeight: 350,
  },

  [`& .${classes.greyText}`]: {color: 'rgba(0, 0, 0, 0.6)'},
  [`& .${classes.reducedHeight}`]: {height: '90%'},
}));

function ConnectorStatusSummary({connector, data}) {
  const dispatch = useDispatch();
  const statuses = unpackConnectorStatuses(connector);

  const handleGoToLogs = () => {
    dispatch(
      updateTableFetchParams(ACTIVITY_LOGS, {
        connectorId: data.connectorId || '',
        operation: UPDATE_CONNECTOR_HEALTH_STATUS,
        sortKey: 'createdOn',
      })
    );
  };

  const mapStatusToIcon = (connectorStatus) => {
    if (connectorStatus && connectorStatus.status) {
      const {status, component} = connectorStatus || {
        status: '',
        component: '',
      };
      switch (status.toLowerCase()) {
        case 'success':
          return (
            <OkIcon
              htmlColor="#036502"
              data-testid={`${component}-success-icon`}
            />
          );
        case 'fail':
          return (
            <ErrorIcon
              htmlColor="#f44336"
              data-testid={`${component}-fail-icon`}
            />
          );
        default:
          return (
            <UnknownIcon
              htmlColor="#706e6b"
              data-testid={`${component}-unknown-icon`}
            />
          );
      }
    }
    return <UnknownIcon htmlColor="#706e6b" data-testid="bad-status-icon" />;
  };

  const lastHealthUpdate = formatDateTime(connector.healthStatusUpdatedOn);

  const renderHeader = () => (
    <>
      <Typography variant="subtitle1" className={classes.header}>
        {CONNECTOR_STATUS_HEADER}
      </Typography>
      <Typography className={classes.healthUpdateTime} variant="caption">
        {!lastHealthUpdate
          ? 'No health update time found'
          : `Last updated on ${lastHealthUpdate}`}
      </Typography>
    </>
  );

  const renderStatusItem = (connectorStatus, index) => (
    <Grid
      key={`status-${index}`}
      container
      wrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography className={classes.statusName}>
        {mapComponentNameToDisplayName(connectorStatus.component)}
      </Typography>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        className={classes.statusSummary}
      >
        {mapStatusToIcon(connectorStatus)}
        <Typography
          className={classes.statusSummaryText}
          data-testid={`${connectorStatus.component}-status`}
        >
          {capitalize(connectorStatus.status)}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderFooter = () => (
    <Grid container justifyContent="flex-end" alignItems="center">
      <Button
        component={Link}
        to={`${ACTIVITY_LOG_LINK}`}
        onClick={handleGoToLogs}
      >
        <Typography className={classes.footer}>
          {CONNECTOR_STATUS_FOOTER}
        </Typography>
        <OpenInNewIcon className={classes.greyText} />
      </Button>
    </Grid>
  );

  const renderStatuses = () => (
    <>
      <Grid
        item
        container
        direction="column"
        className={classes.statusContainer}
      >
        {statuses.map((status, index) => renderStatusItem(status, index))}
      </Grid>
      {renderFooter()}
    </>
  );

  const renderNoDataMessage = () => (
    <Typography className={classes.noDataMessage}>
      {NO_CONNECTOR_STATUS_MESSAGE}
    </Typography>
  );
  const renderBody = () => {
    if (healthUpdatesAreOld(connector?.healthStatusUpdatedOn)) {
      return (
        <Typography variant="h6">
          {`Connector has not received a health update in over an hour.
           The connector may be in an unhealthy state.`}
        </Typography>
      );
    }

    if (statuses.length > 0) {
      return renderStatuses();
    }

    return renderNoDataMessage();
  };

  return (
    <StyledPaper
      component={Grid}
      container
      direction="column"
      justifyContent="space-between"
      wrap="nowrap"
      className={classes.rootContainer}
    >
      {renderHeader()}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.reducedHeight}
      >
        {renderBody()}
      </Grid>
    </StyledPaper>
  );
}

ConnectorStatusSummary.propTypes = {
  connector: PropTypes.object,
  data: PropTypes.object,
};

ConnectorStatusSummary.defaultProps = {
  connector: {
    healthStatusUpdatedOn: null,
  },
  data: {},
};

export default ConnectorStatusSummary;
