import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import {useState} from 'react';
import ReactSelect from 'react-select';
import {Grid} from '@mui/material/index';
import useSearchableTextField from 'hooks/useSearchableTextField';

function ClearIndicator(props) {
  const {
    innerProps: {ref, ...restInnerProps},
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        data-testid="clear-select"
      >
        Clear all
      </Button>
    </div>
  );
}

ClearIndicator.propTypes = {
  innerProps: PropTypes.object.isRequired,
};

function SearchableSelect({
  disabledMessage,
  emptyMessage,
  isDisabled,
  isLoading,
  isMulti,
  isSearchable,
  className,
  onChange,
  onTextInput,
  onMenuScrollToBottom,
  formatOptionLabel,
  options,
  value,
  placeholderText,
  isOptionDisabled,
  clearOnSelect,
}) {
  const [searchFocussed, setSearchFocussed] = useState(false);
  const [inputText, setInputText] = useSearchableTextField(onTextInput);

  const handleFocus = () => setSearchFocussed(true);
  const handleBlur = () => {
    setSearchFocussed(false);
  };

  const handleChange = (newValue) => {
    onChange(newValue);
    if (!isMulti || clearOnSelect) {
      setInputText('');
    }
  };

  const handleInputChange = (text, action) => {
    if (
      action.action !== 'input-blur' &&
      action.action !== 'menu-close' &&
      action.action !== 'set-value'
    ) {
      setInputText(text);
    }
  };

  const placeholder = (() => {
    if (searchFocussed) {
      return '';
    }

    if (isDisabled) {
      return disabledMessage;
    }

    return (
      <Grid container alignItems="center" gap={1}>
        <SearchIcon />
        {placeholderText}
      </Grid>
    );
  })();

  const handleScrollToBottom = () => onMenuScrollToBottom(inputText);

  return (
    <ReactSelect
      data-testid="react-select"
      components={{DropdownIndicator: null, ClearIndicator}}
      filterOption={false} // disable client-side filtering
      inputValue={inputText}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={isMulti}
      isSearchable={isSearchable}
      noOptionsMessage={() => emptyMessage}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      onInputChange={handleInputChange}
      onMenuScrollToBottom={handleScrollToBottom}
      options={options}
      placeholder={placeholder}
      formatOptionLabel={formatOptionLabel}
      value={value}
      name="searchable-select"
      inputId="searchable-select"
      menuIsOpen={searchFocussed}
      closeMenuOnSelect={false}
      menuShouldBlockScroll
      menuPortalTarget={document.querySelector('main')}
      isOptionDisabled={isOptionDisabled}
      styles={{
        menuPortal: (base) => ({...base, zIndex: 9999}),
        menu: (base) => ({...base, zIndex: 9999}),
        container: (base) => ({
          ...base,
          width: '100%',
        }),
        control: (base) => ({
          ...base,
          backgroundColor: '#FFFFFF',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderRadius: '4px',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          ...className,
        }),
        singleValue: (base, state) => ({
          ...base,
          opacity: state.selectProps.menuIsOpen ? 0.5 : 1,
        }),
        indicatorsContainer: (base) => ({
          ...base,
          margin: '2px',
          alignItems: 'flex-end',
        }),
        placeholder: (base) => ({
          ...base,
          color: 'hsl(0, 0%, 40%)',
          marginLeft: '6px',
        }),
        multiValueRemove: (base) => ({
          ...base,
          cursor: 'pointer',
        }),
      }}
    />
  );
}

SearchableSelect.propTypes = {
  disabledMessage: PropTypes.string,
  emptyMessage: PropTypes.string,
  placeholderText: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onTextInput: PropTypes.func.isRequired,
  onMenuScrollToBottom: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.any,
  formatOptionLabel: PropTypes.func,
  isOptionDisabled: PropTypes.func,
  clearOnSelect: PropTypes.bool,
  className: PropTypes.any,
};

SearchableSelect.defaultProps = {
  disabledMessage: 'Disabled',
  emptyMessage: 'No options',
  placeholderText: '',
  isDisabled: false,
  isLoading: false,
  isMulti: false,
  isSearchable: true,
  clearOnSelect: false,
  options: [],
  value: {},
  isOptionDisabled: () => false,
  formatOptionLabel: (option) => option.label,
  onMenuScrollToBottom: () => {},
  className: {},
};

export default SearchableSelect;
