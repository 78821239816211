import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

import SelectMenu from 'components/CAM/inputs/SelectMenu/SelectMenu';
import {filterByDateQueryParams} from 'utils/utils';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';

const dateRangeSelectOptions = [
  {
    displayElement: 'Today',
    value: 'today',
  },
  {
    displayElement: 'Yesterday',
    value: 'yesterday',
  },
  {
    displayElement: 'Last 7 days',
    value: 'last7Days',
  },
  {
    displayElement: 'Last 14 days',
    value: 'last14Days',
  },
  {
    displayElement: 'This month',
    value: 'month',
  },
  {
    displayElement: 'Last month',
    value: 'lastMonth',
  },
  {
    displayElement: 'Custom Range',
    value: 'custom',
  },
];

const FROM_DATEPICKER_LABEL = 'Start Date';
const TO_DATEPICKER_LABEL = 'End Date';
const TO_DATEPICKER_MESSAGE = 'Should not be before "From" date';
const MAX_DATE_MESSAGE = 'Date cannot be in the future';

export default function DateRangeSelect({setDateRange, options}) {
  const [from, setFromDate] = useState(dayjs().toDate());
  const [to, setToDate] = useState(dayjs().toDate());
  const [selectedRange, setSelectedRange] = useState('today');
  const [customFilter, setCustomFilter] = useState(false);

  const handleFromDateChange = (newDate) => {
    if (newDate?.toDate()) {
      const newFrom = newDate.toDate();
      if (newFrom > to) {
        setToDate(newFrom);
      }
      setFromDate(newFrom);
    }
  };
  const handleToDateChange = (newDate) => {
    if (newDate?.toDate()) {
      const newTo = newDate.toDate();
      if (from > to) {
        setFromDate(newTo);
      }
      setToDate(newTo);
    }
  };

  const handleRangeSelectChange = (value) => {
    setCustomFilter(value === 'custom');
    setSelectedRange(value);
    const range = filterByDateQueryParams(value, from, to);
    const {from: fromDate, to: toDate} = range;
    setFromDate(dayjs(fromDate).toDate());
    setToDate(dayjs(toDate).toDate());
  };

  useEffect(() => {
    if (from <= to) {
      const range = filterByDateQueryParams(selectedRange, from, to);
      setDateRange(range);
    }
  }, [from, to]);

  const rangeMenuOptions = () => {
    if (!options.length) {
      return dateRangeSelectOptions;
    }
    return dateRangeSelectOptions.filter(({value}) => options.includes(value));
  };

  const renderDateFilters = () => (
    <SelectMenu
      label="Filter by Date"
      menuList={rangeMenuOptions()}
      onChange={handleRangeSelectChange}
      value={selectedRange}
    />
  );

  const renderDatePicker = (label, dateValue, onChange) => {
    const datePickerProps = {
      value: dayjs(dateValue),
      onChange,
      format: 'YYYY/MM/DD',
      disabled: !customFilter,
      disableFuture: true,
      autoOk: true,
      variant: 'inline',
      fullWidth: true,
      maxDateMessage: MAX_DATE_MESSAGE,
      slotProps: {textField: {size: 'small'}},
    };

    if (label === TO_DATEPICKER_LABEL) {
      datePickerProps.minDate = dayjs(from);
      datePickerProps.minDateMessage = TO_DATEPICKER_MESSAGE;
    }

    return (
      <>
        {label && <InputLabel displayText={label} />}
        <DatePicker {...datePickerProps} />
      </>
    );
  };

  const fromDatePicker = renderDatePicker(
    FROM_DATEPICKER_LABEL,
    from,
    handleFromDateChange
  );
  const toDatePicker = renderDatePicker(
    TO_DATEPICKER_LABEL,
    to,
    handleToDateChange
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={4} md={3}>
          {renderDateFilters()}
        </Grid>
        <Grid item xs={12} sm={2}>
          {fromDatePicker}
        </Grid>
        <Grid item xs={12} sm={2}>
          {toDatePicker}
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

DateRangeSelect.propTypes = {
  setDateRange: PropTypes.func,
  options: PropTypes.array,
};

DateRangeSelect.defaultProps = {
  setDateRange: () => {},
  options: [],
};
