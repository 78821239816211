import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchData} from 'redux/actions/dataActions';
import {POOLS} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {selectData, selectSelectedDeployment} from 'utils/reduxSelectors';
import {convertArrayToObject, isEmpty} from 'utils/utils';

const PREFIX = 'SelectPool';

const classes = {
  inputSectionTextField: `${PREFIX}-inputSectionTextField`
};

const StyledTextField = styled(TextField)((
  {
    theme
  }
) => ({
  [`&.${classes.inputSectionTextField}`]: {
    backgroundColor: theme.palette.surface.white,
    marginRight: '20px',
  }
}));

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  style: {maxHeight: '50vh'},
};

const selectProps = {
  MenuProps: menuProps,
  IconComponent: KeyboardArrowDown,
  displayEmpty: true,
};

const inputProps = {
  style: {
    width: '320px',
    height: '36px',
  },
};

function SelectPool({selectedPool, setSelectedPool}) {

  const dispatch = useDispatch();

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {data: pools, isFetching} = useSelector((state) =>
    selectData(state, POOLS)
  );
  const hashedPools = convertArrayToObject(pools, 'poolId');

  const isDisabled = () => isFetching || !pools || pools.length === 0;
  const isPoolDeleting = (pool) =>
    ['deleting', 'deleted'].includes(pool.status);

  const handlePoolChange = (event) => {
    const poolId = event.target.value;
    setSelectedPool(hashedPools[poolId]);
  };

  useEffect(() => {
    if (deploymentId) {
      dispatch(
        fetchData(POOLS, {
          path: mapResourceToPath(POOLS, {deploymentId}),
        })
      );
    }
  }, [deploymentId]);

  const renderPoolItems = () =>
    pools.map((pool) => (
      <MenuItem
        key={pool.poolId}
        value={isEmpty(pool) ? null : pool.poolId}
        disabled={isPoolDeleting(pool)}
        className={classes.dropdownMenuItem}
      >
        {pool.poolName}
      </MenuItem>
    ));
  return (
    <StyledTextField
      data-testid="select-pool"
      select
      disabled={isDisabled()}
      SelectProps={selectProps}
      InputProps={inputProps}
      variant="outlined"
      value={isEmpty(selectedPool) ? '' : selectedPool.poolId}
      onChange={handlePoolChange}
      className={classes.inputSectionTextField}
    >
      {renderPoolItems()}
    </StyledTextField>
  );
}

SelectPool.propTypes = {
  selectedPool: PropTypes.object,
  setSelectedPool: PropTypes.func.isRequired,
};

SelectPool.defaultProps = {
  selectedPool: null,
};

export default SelectPool;
