// Global action type labels.
// imported into reducers, action creators and middleware.
// Redux History labels
export const PUSH = 'ROUTER/PUSH';
export const REPLACE = 'ROUTER/REPLACE';
export const GO = 'ROUTER/GO';
export const GO_BACK = 'ROUTER/GO_BACK';
export const GO_FORWARD = 'ROUTER/GO_FORWARD';
export const LOCATION_CHANGE = 'ROUTER/LOCATION_CHANGE';
// other labels
