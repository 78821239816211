import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import FileCopy from '@mui/icons-material/FileCopyOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {saveAs} from 'file-saver';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import Link from '@mui/material/Link';
import DialogActions from '@mui/material/DialogActions';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import {DEPLOYMENT_SERVICE_ACCOUNT_DESCRIPTION} from 'utils/constants';

const PREFIX = 'ServiceAccountDownload';

const classes = {
  dialogPaper: `${PREFIX}-dialogPaper`,
  title: `${PREFIX}-title`,
  titleText: `${PREFIX}-titleText`,
  vpnKeyIcon: `${PREFIX}-vpnKeyIcon`,
  closeIcon: `${PREFIX}-closeIcon`,
  copyIcon: `${PREFIX}-copyIcon`,
  bodyBox: `${PREFIX}-bodyBox`,
  orText: `${PREFIX}-orText`,
  downloadButton: `${PREFIX}-downloadButton`,
  leftMargin: `${PREFIX}-leftMargin`,
  bodyText: `${PREFIX}-bodyText`,
  bodyText2: `${PREFIX}-bodyText2`,
  link: `${PREFIX}-link`,
  warningNoteDiv: `${PREFIX}-warningNoteDiv`,
  warningInfoIcon: `${PREFIX}-warningInfoIcon`,
  warningNoteBody: `${PREFIX}-warningNoteBody`,
  orDivider: `${PREFIX}-orDivider`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.dialogPaper}`]: {
    maxWidth: '900px', // Set your width here
  },

  [`& .${classes.title}`]: {
    color: '#FFFFFF',
    backgroundColor: '#0076A9',
    padding: '16px 9px 9px 16px',
    fontWeight: '500',
    fontSize: '1.25em',
  },

  [`& .${classes.titleText}`]: {
    color: '#FFF',
    padding: '0px',
    fontFamily: 'Roboto',
    fontSize: '20px',
  },

  [`& .${classes.vpnKeyIcon}`]: {
    float: 'left',
    color: 'white',
    marginRight: '10px',
  },

  [`& .${classes.closeIcon}`]: {
    padding: '0',
    color: '#FFF',
    float: 'right',
  },

  [`& .${classes.copyIcon}`]: {
    padding: '6px',
  },

  [`& .${classes.bodyBox}`]: {
    // color: 'white',
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    // backgroundColor: 'grey',
  },

  [`& .${classes.orText}`]: {
    padding: '24px 16px 24px 16px',
    textAlign: 'center',
    fontFamily: 'Forma DJR UI',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#8E93A4',
  },

  [`& .${classes.downloadButton}`]: {
    borderRadius: '4px',
    padding: '8px',
    fontSize: '15px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '26px',
  },

  [`& .${classes.leftMargin}`]: {marginLeft: '10px'},

  [`& .${classes.bodyText}`]: {
    ...stylesFromFigma.typographyBody2,
    color: 'rgba(0,0,0,0.6)',
  },

  [`& .${classes.bodyText2}`]: {
    ...stylesFromFigma.typographyBody2,
    color: 'rgba(0,0,0,0.87)',
  },

  [`& .${classes.link}`]: {
    textDecoration: 'underline',
  },

  [`& .${classes.warningNoteDiv}`]: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
    gap: '8px',
    background: '#FFF2E5',
    borderRadius: '4px',
  },

  [`& .${classes.warningInfoIcon}`]: {
    color: '#E06C00',
    width: '16px',
    height: '16px',
  },

  [`& .${classes.warningNoteBody}`]: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '130%',
    color: '#7D3C00',
  },

  [`& .${classes.orDivider}`]: {
    borderBottom: '2px solid lightgray',
    width: '100%',
  },
}));

function ServiceAccountDownload({
  isDeploymentSA,
  serviceAccount,
  onClose,
  onCopyToClipboard,
  onDownload,
  filenameToSave,
  open,
}) {
  const downloadServiceAccount = () => {
    const blob = new Blob([JSON.stringify(serviceAccount)], {
      type: 'application/json;charset=utf-8',
    });
    saveAs(blob, filenameToSave);
    onDownload();
  };

  if (!open) return null;

  return (
    <StyledDialog
      open={open}
      data-testid="download-service-account-window"
      classes={{paper: classes.dialogPaper}}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: '900px', // Set your width here
          },
        },
      }}
    >
      <DialogTitle>
        {`${
          isDeploymentSA ? 'Deployment' : 'Anyware Manager'
        } Service Account Created`}
      </DialogTitle>

      <DialogContent className={classes.bodyBox}>
        <Box className={classes.bodyText}>
          {isDeploymentSA
            ? DEPLOYMENT_SERVICE_ACCOUNT_DESCRIPTION
            : 'An Anyware Manager Service Account allows you to create a deployment and a Deployment Service Account for the deployment. ' +
              'Then you can use the Deployment Service Account to interact with your deployment.'}
        </Box>
        <Box className={classes.bodyText2} mt={3} mb={3}>
          {'For more information, please '}
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://connect.teradici.com/getstarted"
            className={classes.link}
          >
            contact Teradici.
          </Link>
        </Box>
        <Paper className={classes.warningNoteDiv} elevation={0}>
          <Box justifyContent="center" alignItems="center" display="flex">
            <InfoIcon className={classes.warningInfoIcon} />
          </Box>
          <div>
            <div className={classes.warningNoteBody}>
              Download or copy the API access tokens now and securely store the
              file, as the key cannot be recovered if lost.
            </div>
          </div>
        </Paper>
        <Box display="flex" mt={3}>
          <Box flexGrow={1}>
            <TextField
              fullWidth
              readOnly
              data-testid="input-with-service-account"
              label="Service Account Credentials"
              variant="outlined"
              value={JSON.stringify(serviceAccount)}
            />
          </Box>
          <CopyToClipboard
            text={JSON.stringify(serviceAccount)}
            onCopy={onCopyToClipboard}
          >
            <IconButton
              className={classes.copyIcon}
              data-testid="copy-service-account-button"
              size="large"
            >
              <FileCopy />
            </IconButton>
          </CopyToClipboard>
        </Box>
        <Box display="flex" alignItems="center" className={classes.orContainer}>
          <div className={classes.orDivider} />
          <span className={classes.orText}>OR</span>
          <div className={classes.orDivider} />
        </Box>

        <Button
          className={classes.downloadButton}
          fullWidth
          onClick={downloadServiceAccount}
          variant="contained"
          color="primary"
          data-testid="download-service-account-button"
          startIcon={<SaveAltIcon className={classes.leftMargin} />}
        >
          Download JSON file
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

ServiceAccountDownload.propTypes = {
  isDeploymentSA: PropTypes.bool.isRequired,
  filenameToSave: PropTypes.string.isRequired,
  serviceAccount: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onCopyToClipboard: PropTypes.func,
  onDownload: PropTypes.func,
};

ServiceAccountDownload.defaultProps = {
  serviceAccount: {},
  onCopyToClipboard: () => {},
  onDownload: () => {},
  open: false,
};

export default ServiceAccountDownload;
