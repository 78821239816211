/* eslint-disable no-param-reassign */
import mqtt from 'mqtt';
import {v4 as uuid} from 'uuid';
import config from '../config';
import {isEmpty} from 'utils/utils';

export default class MqttBaseClass {
  constructor({getToken, connectorId}) {
    this.subscriptions = {};
    this.clientId = `admin-console-${connectorId}-${uuid()}`;
    this.getToken = getToken;
  }

  async publish({topic, message, options}) {
    if (isEmpty(topic)) {
      throw new Error(
        'A topic is required to send a message. Please provide a topic.'
      );
    }
    if (!this.mqttClient || !this.mqttClient.connected) {
      await this.connect();
    }
    await this.mqttClient.publishAsync(topic, message, options);
  }

  async connect() {
    const mqttOptions = {
      // This identifies any clients that connects to the broker from the Admin Console.
      // If necessary, it can be updated to add specific details related to the instance of the client or admin console
      clientId: this.clientId,
      connectTimeout: 60000, // This is super slow on my dev cluster?
      reconnectPeriod: 0,
      clean: true,
      protocolId: 'MQTT',
      protocolVersion: 5,
      rejectUnauthorized: false,
      username: 'any',
      password: await this.getToken(),
      resubscribe: true,
      ...config.MQTT_CONFIG,
    };

    const mqttClient = await mqtt.connectAsync(
      mqttOptions
    );

    mqttClient.on('connect', () => {
      console.log('MQTT client connected.');
    });

    mqttClient.on('close', async () => {
      mqttOptions.password = await this.getToken();
      console.log('MQTT client closed.');
    });

    mqttClient.on('error', (error) => {
      console.log('MQTT client error.', error);
    });

    mqttClient.on('message', (topic, message) => {
      this.callback(topic, message);
    });

    this.mqttClient = mqttClient;
  }

  callback(topic, message) {
    // Call callback function if it exists, otherwise log the message
    if (this.subscriptions[topic]) {
      this.subscriptions[topic](topic, message);
    } else {
      console.log(`Received message on topic ${topic}: ${message.toString()}`);
    }
  }

  async disconnect() {
    if (this.mqttClient) {
      await this.mqttClient.endAsync();
    }
  }

  isConnected() {
    return this.mqttClient?.connected;
  }

  async subscribe(topic, callback) {
    if (this.mqttClient && this.mqttClient.connected) {
      await this.mqttClient.subscribeAsync(topic);
    }
    this.subscriptions[topic] = callback;
  }

  async unsubscribe(topic) {
    if (this.mqttClient && this.mqttClient.connected) {
      await this.mqttClient.unsubscribeAsync(topic);
    }
    delete this.subscriptions[topic];
  }
}
