import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {del} from 'api/Api';
import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import useDialog from 'hooks/useDialog';
import useSnackbar from 'hooks/useSnackbar';
import {
  changeUserAssignedToPoolMachine,
  fetchResource,
  handleApiError,
  receiveDeleteResource,
  requestDeleteResource,
  saveVariable,
  checkAgentMonitored,
} from 'redux/actions/dataActions';

import {
  restartMachine,
  startMachine,
  stopMachine,
} from 'redux/actions/remoteWorkstationActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {uncheckItem} from 'redux/actions/tableSelectActions';
import {
  CLOUD_SERVICE_ACCOUNTS,
  dataLossWarningMsg,
  POOL_MACHINES,
  REMOTE_WORKSTATIONS,
} from 'utils/constants';
import {selectData, selectSelectedDeployment} from 'utils/reduxSelectors';

import {canStartWorkstation, canStopOrRestartWorkstation} from 'utils/utils';

import SelectUserDialog from './SelectUserDialog';

const PREFIX = 'PoolMachinesActions';

const classes = {
  divider: `${PREFIX}-divider`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.divider}`]: {height: '2px'}
}));

function PoolMachinesActions({item: machine, closeMenu}) {

  const dispatch = useDispatch();
  const {triggerDialog} = useDialog();
  const {successSnackbar} = useSnackbar();

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {poolId, poolName} = useSelector(
    (state) => state.data.dataByResource.selectedPool
  );
  const {page, rowsPerPage} = useSelector(
    (state) => state.tableData[POOL_MACHINES]
  );
  const {userSelectDialogOpen} = useSelector(
    (state) => state.data.dataByResource || {userSelectDialogOpen: false}
  );
  const setUserSelectDialogOpen = (value) =>
    dispatch(saveVariable('userSelectDialogOpen', value));

  const {data: cloudServiceAccounts} = useSelector((state) =>
    selectData(state, CLOUD_SERVICE_ACCOUNTS)
  ) || {data: []};

  const getWorkstationName = () =>
    machine ? machine.machineName || machine.machineId : '';

  const getConfirmMessage = (action) =>
    `Are you sure you want to ${action} the remote workstation ${getWorkstationName()}?`;

  const handleStartButtonClick = () => dispatch(startMachine(machine, true));

  const handleStopButtonClick = () => {
    triggerDialog({
      title: 'Stop remote workstation?',
      message: getConfirmMessage('stop'),
      onConfirm: () => dispatch(stopMachine(machine, true)),
      warningMessage: dataLossWarningMsg,
    });
  };

  const handleRestartButtonClick = () => {
    triggerDialog({
      title: 'Restart remote workstation?',
      message: getConfirmMessage('restart'),
      onConfirm: () => dispatch(restartMachine(machine, true)),
      warningMessage: dataLossWarningMsg,
    });
  };

  const deletePoolMachine = async () => {
    dispatch(requestDeleteResource(POOL_MACHINES, machine.machineId));
    try {
      await del({
        path: `deployments/${deploymentId}/pools/${poolId}/machines`,
        data: {machineId: machine.machineId},
      });
      dispatch(receiveDeleteResource(POOL_MACHINES, machine.machineId));
      successSnackbar(
        `Workstation ${machine.machineName} was removed from the pool.`
      );
    } catch (err) {
      handleApiError(err);
    }
    dispatch(fetchTableData(POOL_MACHINES, page, rowsPerPage));
    dispatch(fetchResource(REMOTE_WORKSTATIONS, {filterPoolMachines: true}));
    dispatch(uncheckItem(POOL_MACHINES, machine));
  };

  const handleRemoveWorkstationFromPoolClick = () => {
    triggerDialog({
      title: 'Remove remote workstation from the pool?',
      message: `Are you sure you want to remove ${machine.machineName} from ${poolName}?
    This will not delete the remote workstation, it will only remove the workstation from the pool.`,
      onConfirm: deletePoolMachine,
    });
  };

  const reassignUserToRW = async (user) => {
    const oldMachineId = user.machineId;
    const newMachineId = machine.machineId;
    try {
      await changeUserAssignedToPoolMachine({
        deploymentId,
        machineId: oldMachineId,
        poolId,
        userGuid: null,
      });
      await changeUserAssignedToPoolMachine({
        deploymentId,
        machineId: newMachineId,
        poolId,
        userGuid: user.userGuid,
      });
      successSnackbar('User was reassigned.');
    } catch (err) {
      handleApiError(err);
    }
    dispatch(fetchTableData(POOL_MACHINES, page, rowsPerPage));
  };

  const handleReassigningWorkstation = async (user) => {
    try {
      const info = (await checkAgentMonitored(user.machineId))
        ? `Are you sure you want to reassign ${user.name} from ${user.machineName} to ${machine.machineName}? If the user is logged in to the ${user.machineName} workstation, they will be logged out.`
        : `Are you sure you want to reassign ${user.name} from ${user.machineName} to ${machine.machineName}?`;
      triggerDialog({
        title: 'Reassign workstation to user?',
        message: info,
        onConfirm: () => reassignUserToRW(user),
      });
    } catch (err) {
      handleApiError(err);
    }
  };

  const unassignUserFromRW = async () => {
    try {
      const {machineId} = machine;
      await changeUserAssignedToPoolMachine({
        deploymentId,
        machineId,
        poolId,
        userGuid: null,
      });
      successSnackbar('User assignment was removed from the machine.');
    } catch (err) {
      handleApiError(err);
    }
    dispatch(fetchTableData(POOL_MACHINES, page, rowsPerPage));
  };

  const handleRemoveUserAssignmentFromWorkstation = () => {
    const info = machine.agentMonitored
      ? `Are you sure you want to remove user assignment to the workstation ${machine.machineName}? This will not delete the user or the remote workstation, but remove the assignment between them. If the user is logged in to the workstation, they will be logged out.`
      : `Are you sure you want to remove user assignment to the workstation ${machine.machineName}? This will not delete the user or the remote workstation, but remove the assignment between them.`;
    triggerDialog({
      title: 'Remove user assignment from the workstation?',
      message: info,
      onConfirm: unassignUserFromRW,
    });
  };

  const actionMenuItems = [
    {
      id: `start-pool-machine-${machine.machineId}`,
      dataTestId: `start-pool-machine-${machine.machineId}`,
      enableClick: canStartWorkstation(machine, cloudServiceAccounts),
      menuText: 'Start',
      onClick: () => {
        closeMenu();
        handleStartButtonClick();
      },
    },
    {
      id: `restart-pool-machine-${machine.machineId}`,
      dataTestId: `restart-pool-machine-${machine.machineId}`,
      enableClick: canStopOrRestartWorkstation(machine, cloudServiceAccounts),
      menuText: 'Restart',
      onClick: () => {
        closeMenu();
        handleRestartButtonClick();
      },
    },
    {
      id: `stop-pool-machine-${machine.machineId}`,
      dataTestId: `stop-pool-machine-${machine.machineId}`,
      enableClick: canStopOrRestartWorkstation(machine, cloudServiceAccounts),
      menuText: 'Stop',
      onClick: () => {
        closeMenu();
        handleStopButtonClick();
      },
    },
  ];

  const onSelectUser = async (user) => {
    const {machineId, machineName} = machine;
    const {userGuid} = user;

    dispatch(saveVariable('userSelectDialogOpen', false));
    try {
      if (user.machineId) {
        await handleReassigningWorkstation(user);
      } else {
        await changeUserAssignedToPoolMachine({
          machineId,
          userGuid,
          deploymentId,
          poolId,
        });
        successSnackbar(`User ${machineName} was assigned to the machine`);
        dispatch(fetchTableData(POOL_MACHINES, page, rowsPerPage));
      }
    } catch (err) {
      handleApiError(err);
    }
  };

  const renderSelectUserDialog = () => (
    <SelectUserDialog
      open={userSelectDialogOpen}
      setOpen={setUserSelectDialogOpen}
      machine={machine}
      onSelectUser={onSelectUser}
    />
  );

  return (
    (<Root>
      {actionMenuItems.map((item) => (
        <KebabMenuItem key={item.id} {...item} />
      ))}
      <Divider className={classes.divider} />
      <KebabMenuItem
        id={`remove-pool-machine-${machine.machineId}`}
        dataTestId={`remove-pool-machine-${machine.machineId}`}
        menuText="Remove from pool"
        onClick={() => {
          closeMenu();
          handleRemoveWorkstationFromPoolClick();
        }}
      />
      {machine.assignedTo && (
        <>
          <Divider className={classes.divider} />
          <KebabMenuItem
            id={`remove-user-machine-${machine.machineId}`}
            dataTestId={`remove-user-machine-${machine.machineId}`}
            menuText="Remove assigned user"
            onClick={() => {
              closeMenu();
              handleRemoveUserAssignmentFromWorkstation();
            }}
          />
        </>
      )}
      {!machine.assignedTo && (
        <>
          <Divider className={classes.divider} />
          <KebabMenuItem
            id={`assign-user-machine-${machine.machineId}`}
            dataTestId={`assign-user-machine-${machine.machineId}`}
            menuText="Assign a user to this machine"
            onClick={() => {
              // closeMenu();
              // TODO: if closeMenu is called, then the dialog window does not render properly.
              // We will need to figure out a way around this
              setUserSelectDialogOpen(true);
            }}
          />
          {userSelectDialogOpen && renderSelectUserDialog()}
        </>
      )}
    </Root>)
  );
}

PoolMachinesActions.propTypes = {
  item: PropTypes.object,
  closeMenu: PropTypes.func,
};

PoolMachinesActions.defaultProps = {
  item: {},
  closeMenu: () => {},
};

export default PoolMachinesActions;
