import {combineReducers} from 'redux';
import azureRoleDialog from './azureRoleDialogReducer';
import bulkActionsDialog from './bulkActionsDialogReducer';
import bulkDeleteRemoteWorkstationsDialog from './bulkDeleteRemoteWorkstationsDialogReducer';
import confirmationDialog from './confirmationDialogReducer';
import connectorHealthDialog from './connectorHealthDialogReducer';
import data from './dataReducers';
import dateFilter from './dateFilterReducer';
import deleting from './deletingResourcesReducer';
import deletePoolsDialog from './deletePoolsDialogReducer';
import deleteConnectorsDialog from './deleteConnectorsDialogReducer';
import deleteRemoteWorkstationDialog from './deleteRemoteWorkstationDialogReducer';
import remoteWorkstationErrorDialog from './remoteWorkstationErrorDialogReducer';
import deploymentFilter from './deploymentFilterReducer';
import displayLeaveEditPageDialog from './displayLeaveEditPageDialogReducer';
import dropdownData from './dropdownReducer';
import errorDialog from './errorDialogReducer';
import machineAssignmentDialog from './machineAssignmentDialogReducer';
import deleteWebhooksDialog from './deleteWebhooksDialogReducer';
import notifications from './notificationReducers';
import polling from './pollingReducer';
import remoteWorkstations from './remoteWorkstationsReducer';
import sidebar from './sidebarReducer';
import snackbar from './snackbarReducer';
import tableColumns from './tableColumnsReducer';
import tableData from './tableDataReducer';
import tableSelect from './tableSelectReducer';
import userPreferences from './userPreferencesReducer';
import viewMachineAssignmentDialog from './viewMachineAssignmentDialogReducer';
import deploymentFilterDialog from './deploymentFilterDialogReducer';
import {routerReducer as router} from '../ReduxHistory';
import deploymentSACred from './deploymentSACredReducer';
import selectedDeploymentIdReducer from './selectedDeploymentIdReducer';

const rootReducers = combineReducers({
  azureRoleDialog,
  bulkActionsDialog,
  bulkDeleteRemoteWorkstationsDialog,
  confirmationDialog,
  connectorHealthDialog,
  data,
  dateFilter,
  deleting,
  deleteConnectorsDialog,
  deletePoolsDialog,
  deleteRemoteWorkstationDialog,
  deploymentSACred,
  remoteWorkstationErrorDialog,
  deploymentFilter,
  deploymentFilterDialog,
  dropdownData,
  displayLeaveEditPageDialog,
  errorDialog,
  machineAssignmentDialog,
  deleteWebhooksDialog,
  notifications,
  polling,
  remoteWorkstations,
  router,
  sidebar,
  snackbar,
  tableColumns,
  tableData,
  tableSelect,
  userPreferences,
  viewMachineAssignmentDialog,
  selectedDeploymentId: selectedDeploymentIdReducer,
});

export default rootReducers;
