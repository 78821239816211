/* eslint-disable max-lines */
import moment from 'moment';
import {batchRequest, del, get, post, put} from 'api/Api';
import config from 'config';
import {
  addPollingItem,
  finishPolling,
  clearPollingItems,
} from 'redux/actions/pollingActions';
import {
  doSafeBulkRequest,
  doSafeRequest,
  getConnectorSettings,
} from 'utils/apiUtils';
import {
  AD_GROUPS,
  AD_USERS,
  AWS,
  AWS_INSTANCES,
  AZURE,
  AZURE_INSTANCES,
  AZURE_LOCATIONS,
  AZURE_RESOURCE_GROUPS,
  AZURE_SUBNETS,
  AZURE_VM_SIZES,
  AWM_VERSION_INFO,
  CLOUD_SERVICE_ACCOUNTS,
  CLOUD_SERVICE_ACCOUNTS_EXPIRATION_WARN_IF_LESS,
  CLOUD_SERVICE_ACCOUNTS_EXPIRY_INFO,
  CONNECTORS_LINK,
  CONNECTOR_SETTINGS,
  DASHBOARD_LINK,
  DEFAULT_ROWS_PER_PAGE,
  DEPLOYMENTS,
  ENTITLEMENTS,
  ERROR_INVALID_PARAMS,
  GCP,
  GCP_INSTANCES,
  GCP_NETWORKS,
  GCP_REGIONS,
  GCP_SUBNETS,
  GCP_ZONES,
  LOGOUT_OPTIONS,
  MACHINE_SESSIONS,
  MACHINE_SESSION_ATTEMPTS,
  MONITOR_DELAYED_ENDING_SESSION,
  MONITOR_ENDING_SESSION,
  MONITOR_IN_SESSION,
  MONITOR_LOGOUT_USER,
  MONITOR_NOTIFY_USER,
  MONITOR_TELEMETRY_LATEST,
  POLLING_SETTINGS,
  POOL_GROUPS,
  MULTIPLE_CONNECTOR_SETTINGS,
  POOL_MACHINES,
  POOL_MACHINES_USERS,
  POOL_USERS,
  REMOTE_WORKSTATIONS,
  SAML_ALLOWED_GROUPS,
  SAML_ALLOWED_USERS,
  SAML_CONFIGURATION,
  TELEMETRY_SETTINGS,
  USER_SESSIONS,
  USER_SESSION_ATTEMPTS,
  WORKSTATIONS_LINK,
  POOLS_LINK,
  WEBHOOKS,
  WEBHOOKS_CA_CERT_STATUS_SET,
  WEBHOOKS_CA_CERT_STATUS_NOT_SET,
  WEBHOOKS_CA_CERT_STATUS_NOT_KNOWN,
} from 'utils/constants';
import CasmCookies from 'utils/cookies';
import {mapResourceToPath} from 'utils/Mappings';
import {
  selectResource,
  selectResourceItem,
  selectSelectedDeployment,
  selectSelectedPool,
} from 'utils/reduxSelectors';
import {
  buildFrontendAdGroupFormat,
  createOptionsObject,
  encodeBase64,
  isCloudProvider,
  isEmpty,
  reverseFrontendAdGroupFormat,
  webhookCACertKeyFromUrl,
} from 'utils/utils';
import {msalSignOut} from '../../msal/msal.request';
import {openDialog} from './confirmationDialogActions';
import {push, replace} from './HistoryActions';
import {pushNotification} from './notificationActions';
import {enqueueSnackbar} from './snackbarActions';

export const CLEAR_RESOURCE = 'CLEAR_RESOURCE';

export function clearResource(resource) {
  return {
    type: CLEAR_RESOURCE,
    resource,
  };
}

export const REQUEST_RESOURCE = 'REQUEST_RESOURCE';

export function requestResource(resource) {
  return {
    type: REQUEST_RESOURCE,
    resource,
  };
}

export const RECEIVE_RESOURCE = 'RECEIVE_RESOURCE';

export function receiveResource(resource, responseData) {
  return {
    type: RECEIVE_RESOURCE,
    resource,
    data: responseData,
    receivedAt: Date.now(),
  };
}

export const SAVE_VARIABLE = 'SAVE_VARIABLE';

export function saveVariable(name, value) {
  return {
    type: SAVE_VARIABLE,
    name,
    value,
  };
}

export function mapResourceToAPI(resource) {
  switch (resource) {
    case 'deployments':
    case 'allDeployments':
    case 'selectDeployments':
      return 'deployments';
    case 'remoteWorkstations':
      return 'machines';
    case 'connectors':
    case 'allConnectors':
      return 'deployments/connectors';
    case 'adComputers':
      return 'machines/entitlements/adcomputers';
    case 'adUsers':
      return 'machines/entitlements/adusers';
    case 'gcpOptions':
      return 'machines/cloudproviders/gcp';
    case 'cloudServiceAccount':
      return 'cloudServiceAccounts';
    case CLOUD_SERVICE_ACCOUNTS_EXPIRY_INFO:
      return 'auth/users/cloudServiceAccount/checkExpiration';
    case 'templates':
      return 'resource-templates';
    case 'activityLogs':
      return 'logs';
    case AWS_INSTANCES:
      return 'machines/cloudproviders/aws/instances';
    case AZURE_INSTANCES:
      return 'machines/cloudproviders/azure/instances';
    case GCP_INSTANCES:
      return 'machines/cloudproviders/gcp/instances';
    case POOL_MACHINES_USERS:
      return 'machines/entitlements/adusers';
    case SAML_CONFIGURATION:
      return 'auth/saml';
    default:
      return '';
  }
}

export const showSessionExpired = () => async (dispatch) => {
  dispatch(saveVariable('shouldDisplaySessionExpired', true));
};

export const hideSessionExpired = () => async (dispatch) => {
  dispatch(saveVariable('shouldDisplaySessionExpired', false));
};

export const logoutUserFromCAM = () => async (dispatch) => {
  dispatch(saveVariable('pendingChanges', false));
  try {
    if (CasmCookies.isTokenValid()) {
      await post({path: 'auth/tokens/revoke'});
    }
  } catch (error) {
    // If error occurs revoking token after retries, continue by removing it
  }
  CasmCookies.clearAuthToken();
  msalSignOut();
  dispatch(finishPolling());
  // Clear all polling items
  dispatch(clearPollingItems());
};

export function handleApiError(error, autoHideDuration = undefined) {
  return async (dispatch) => {
    if (error.name === 'SyntaxError') {
      // Mask errors resulting from non-JSON API responses
      console.error('Received a non-JSON API response');
      return;
    }

    let httpErrorCode;
    let httpErrorMessage;
    try {
      httpErrorCode = error.code;
      httpErrorMessage = error.message || (error.data && error.data.reason);
    } catch {
      console.log('Could not extract HTTP status code from API response.');
      throw error;
    }

    let message =
      httpErrorMessage ||
      {
        400: ERROR_INVALID_PARAMS,
        401: 'Access token is invalid. Login and try again.',
        403: 'You do not have sufficient privileges.',
        412: 'Invalid credentials for provider.',
        500: 'Internal error occurred. Please try again.',
      }[httpErrorCode];

    // Ignore (by now) 500 errors that are returned by Apigee
    if (message === 'APM: Proxy Server Error') {
      message = '';
    }

    // Additional default actions
    if (httpErrorCode === 401) {
      dispatch(saveVariable('shouldDisplaySessionExpired', true));
      dispatch(logoutUserFromCAM());
      return;
    }

    if (httpErrorCode === 402) {
      dispatch(
        openDialog(
          'Anyware Manager Enterprise Required',
          'This features requires an active Anyware Manager Enterprise license. Contact your HP representative for more information.',
          () => {},
          false
        )
      );
    }

    if (message) {
      dispatch(
        enqueueSnackbar({
          message,
          options: {
            variant: 'error',
            // If autoHideDuration === undefined then reducer will use default timeout
            autoHideDuration,
          },
        })
      );
      return;
    }
    // Fallback to console error message if no error message is displayed to the user.
    console.log(error);
  };
}

export function fetchData(resource, request) {
  return async (dispatch) => {
    dispatch(requestResource(resource));

    let data;
    let total;
    try {
      const response = await get(request);
      data = response.data;
      total = response.total;
      dispatch(receiveResource(resource, data));
    } catch (error) {
      dispatch(handleApiError(error));
    }
    return {
      data: data || [],
      total: total || 0,
    };
  };
}

function makeGetCall(
  resource,
  params = {page: 0, rowsPerPage: DEFAULT_ROWS_PER_PAGE},
  deploymentId,
  poolId,
  {signal} = {}
) {
  const {page, rowsPerPage, ...requestParams} = params;

  return get({
    path: mapResourceToPath(resource, {deploymentId, poolId}),
    params: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      ...requestParams,
    },
    signal,
  });
}

// Get entitlements for a list of machines
//   and then get all of the adUsers for those entitlements.
// Load all of the data in the redux store
export function fetchMachineEntitlements(machinesResponse, fetchOne = false) {
  return async (dispatch, getState) => {
    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);

    const machines = machinesResponse.data;
    let machineId;

    if (fetchOne) {
      [{machineId}] = machines;
    } else {
      machineId = machines.map((machine) => machine.machineId);
    }

    try {
      let entitlements;
      let info;
      if (fetchOne) {
        ({data: entitlements} = await get({
          path: mapResourceToPath(ENTITLEMENTS, {deploymentId}),
          params: {
            offset: 0,
            limit: 1000,
            machineId,
          },
        }));
      } else {
        ({info, data: entitlements} = await batchRequest({
          path: mapResourceToPath(ENTITLEMENTS, {deploymentId}),
          queryParams: {
            offset: 0,
            limit: 1000,
            machineId,
          },
          batchingKey: 'machineId',
        }));
      }

      if (fetchOne || info.successfulRequestCount > 0) {
        dispatch(receiveResource(ENTITLEMENTS, entitlements));

        const userGuid = entitlements.map((e) => e.userGuid);

        const uniqueUserGuids = [...new Set(userGuid)].join(',');

        const {data: adUsers} = await get({
          path: mapResourceToPath(AD_USERS),
          params: {
            deploymentId,
            offset: 0,
            limit: 1000,
            userGuid: uniqueUserGuids,
          },
        });

        dispatch(receiveResource(AD_USERS, adUsers));
      } else {
        dispatch(receiveResource(ENTITLEMENTS, []));
      }
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };
}

function convertMachineResponseToIds(machineResponse) {
  const machines = machineResponse.data;
  let machineId;
  if (Array.isArray(machines)) {
    machineId = machines.map((machine) => machine.machineId);
  } else {
    machineId = [machines?.machineId];
  }
  return machineId;
}

export function fetchUserSessions(userGuid) {
  return async (dispatch, getState) => {
    dispatch(clearResource(USER_SESSIONS));
    dispatch(clearResource(REMOTE_WORKSTATIONS));

    dispatch(requestResource(USER_SESSIONS));
    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);

    try {
      const {data: sessions} = await get({
        path: mapResourceToPath(USER_SESSIONS, {deploymentId}),
        params: {
          userGuid,
        },
      });
      dispatch(receiveResource(USER_SESSIONS, sessions));

      // Get information about workstations in sessions
      if (sessions.length > 0) {
        const machineId = sessions.map((session) => session.machineId);

        dispatch(requestResource(REMOTE_WORKSTATIONS));
        const {data: machines} = await batchRequest({
          path: mapResourceToPath(REMOTE_WORKSTATIONS),
          queryParams: {
            offset: 0,
            limit: 1000,
            machineId,
          },
          batchingKey: 'machineId',
        });
        dispatch(receiveResource(REMOTE_WORKSTATIONS, machines));
      }
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };
}

export function fetchUserSessionAttempts(userGuid, range = {}) {
  return async (dispatch, getState) => {
    dispatch(clearResource(USER_SESSION_ATTEMPTS));
    dispatch(requestResource(USER_SESSION_ATTEMPTS));
    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);

    try {
      const params = {limit: 200};
      const {from, to} = range;
      if (from) {
        params.from = from;
      }
      if (to) {
        params.to = to;
      }

      const {data: sessions} = await get({
        path: mapResourceToPath(USER_SESSION_ATTEMPTS, {
          deploymentId,
          userGuid,
        }),
        params,
      });
      dispatch(receiveResource(USER_SESSION_ATTEMPTS, sessions));

      // Return information about machines in sessions
      if (sessions.length > 0) {
        const machineIds = new Set();
        sessions.forEach((session) => machineIds.add(session.machineId));
        return [...machineIds];
      }
      return [];
    } catch (error) {
      dispatch(handleApiError(error));
      return [];
    }
  };
}

const isEndingSession = (userName, user) =>
  user.userName === userName && user.status.includes(MONITOR_ENDING_SESSION);

const getStatus = (userName, machineId, localTelemetry) => {
  const machineTelemetry = localTelemetry[machineId] || {loggedInUsers: []};
  const userWithEndingSession = machineTelemetry.loggedInUsers.find((user) =>
    isEndingSession(userName, user)
  );
  if (userWithEndingSession) {
    return userWithEndingSession.status === MONITOR_ENDING_SESSION
      ? MONITOR_ENDING_SESSION
      : userWithEndingSession.status;
  }
  return MONITOR_IN_SESSION;
};

/**
 * Transform the received data into a format that can be used by the components.
 * Checks if any local changes are mada and apply to the new data.
 * @param {*} latestTelemetry the new data
 * @param {*} localTelemetry the local data
 * @returns returns the new data with local changes
 */
const prepareMonitorTelemetryData = (latestTelemetry, localTelemetry) =>
  latestTelemetry.map((machineTelemetry) => ({
    ...machineTelemetry,
    loggedInUsers: machineTelemetry.loggedInUsers.map((userName) => ({
      userName,
      status: getStatus(userName, machineTelemetry.machineId, localTelemetry),
    })),
  }));

export function fetchLatestMonitorTelemetry(machineId) {
  return async (dispatch, getState) => {
    dispatch(requestResource(MONITOR_TELEMETRY_LATEST));
    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);
    const {data: localMachineTelemetry} = selectResource(
      state,
      MONITOR_TELEMETRY_LATEST
    );

    try {
      const {data: latestTelemetry} = await batchRequest({
        path: mapResourceToPath(MONITOR_TELEMETRY_LATEST, {deploymentId}),
        queryParams: {
          machineId,
        },
        batchingKey: 'machineId',
      });
      dispatch(
        receiveResource(
          MONITOR_TELEMETRY_LATEST,
          prepareMonitorTelemetryData(
            latestTelemetry,
            localMachineTelemetry || {}
          )
        )
      );
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };
}

const monitorUpdateUserStatus =
  (machineId, userName, status) => async (dispatch, getState) => {
    const state = getState();
    const {item: machineTelemetry} = selectResourceItem(
      state,
      MONITOR_TELEMETRY_LATEST,
      machineId
    );
    const updatedUserStatus = {
      ...machineTelemetry,
      loggedInUsers: machineTelemetry.loggedInUsers.map((user) => ({
        ...user,
        status: user.userName === userName ? status : user.status,
      })),
    };

    dispatch(receiveResource(MONITOR_TELEMETRY_LATEST, [updatedUserStatus]));
  };

const monitorCheckUserLogout =
  (machineId, userName) => async (dispatch, getState) => {
    const state = getState();
    const {item: machineTelemetry} = selectResourceItem(
      state,
      MONITOR_TELEMETRY_LATEST,
      machineId
    );
    const {item: selectedWorkstation} = selectResourceItem(
      state,
      REMOTE_WORKSTATIONS,
      machineId
    );

    const isUserStillEndingSession = machineTelemetry.loggedInUsers.some(
      (user) =>
        user.userName === userName &&
        user.status.includes(MONITOR_ENDING_SESSION)
    );
    if (!isUserStillEndingSession) return;

    dispatch(monitorUpdateUserStatus(machineId, userName, MONITOR_IN_SESSION));

    const bellNotification = {
      type: 'Remote Workstations',
      timePosted: Date.now(),
      link: `${WORKSTATIONS_LINK}/edit/${machineId}/sessionInfo`,
      title: `Failed to logout user ${userName}`,
      moreInfo: `An error ocurred when logging out the user ${userName} from workstation ${selectedWorkstation.machineName}. Please try again.`,
    };
    dispatch(pushNotification(bellNotification));
  };

export const monitorUserLogout =
  (machineId, userName, timeDelay = 0) =>
  async (dispatch) => {
    try {
      const secondsBeforeSend = moment
        .duration(timeDelay, 'minutes')
        .asSeconds();
      const title = 'MONITOR: End session warning';
      const body = `The Anyware Manager will terminate your session in ${timeDelay} minutes. Save your work to avoid losses.`;
      const delayedLogoutStatus =
        MONITOR_DELAYED_ENDING_SESSION +
        moment().add(timeDelay, 'minutes').format('LT');
      await post({
        path: mapResourceToPath(MONITOR_LOGOUT_USER, {machineId}),
        data: {userName, secondsBeforeSend},
      });
      if (timeDelay > 0) {
        await post({
          path: mapResourceToPath(MONITOR_NOTIFY_USER, {machineId}),
          data: {title, body},
        });
      }
      dispatch(
        monitorUpdateUserStatus(
          machineId,
          userName,
          timeDelay === LOGOUT_OPTIONS.Immediately.value
            ? MONITOR_ENDING_SESSION
            : delayedLogoutStatus
        )
      );
      setTimeout(
        () => dispatch(monitorCheckUserLogout(machineId, userName)),
        moment.duration(5 + timeDelay, 'minutes').asMilliseconds()
      );
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };

export const monitorNotifyUser =
  (machineId, title, body) => async (dispatch) => {
    try {
      await post({
        path: mapResourceToPath(MONITOR_NOTIFY_USER, {machineId}),
        data: {title, body},
      });
      dispatch(
        enqueueSnackbar({
          message: 'Message sent successfully',
          options: {
            variant: 'success',
          },
        })
      );
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };

export function fetchAdUsers(userGuidList) {
  return async (dispatch, getState) => {
    dispatch(requestResource(AD_USERS));

    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);
    const userGuid = userGuidList.join(',');

    try {
      const {data: adUsers} = await get({
        path: mapResourceToPath(AD_USERS),
        params: {
          offset: 0,
          limit: 1000,
          userGuid,
          deploymentId,
        },
      });
      dispatch(receiveResource(AD_USERS, adUsers));
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };
}

export function fetchMachineActiveSessions(machineId) {
  return async (dispatch, getState) => {
    dispatch(clearResource(MACHINE_SESSIONS));
    dispatch(requestResource(MACHINE_SESSIONS));
    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);

    const {info, data: sessions} = await batchRequest({
      path: mapResourceToPath(MACHINE_SESSIONS, {deploymentId}),
      queryParams: {
        machineId,
      },
      batchingKey: 'machineId',
    });
    dispatch(receiveResource(MACHINE_SESSIONS, sessions));

    // Return information about users in sessions
    if (info.successfulRequestCount > 0 && sessions.length > 0) {
      const userGuidList = sessions.map((session) => session.userGuid);
      return userGuidList;
    }
    return [];
  };
}

export function fetchMachineSessionAttempts(machineId, range = {}) {
  return async (dispatch, getState) => {
    dispatch(clearResource(MACHINE_SESSION_ATTEMPTS));
    dispatch(requestResource(MACHINE_SESSION_ATTEMPTS));
    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);

    try {
      const params = {limit: 200};

      const {from, to} = range;

      if (from) {
        params.from = from;
      }

      if (to) {
        params.to = to;
      }

      const {data: sessions} = await get({
        path: mapResourceToPath(MACHINE_SESSION_ATTEMPTS, {
          deploymentId,
          machineId,
        }),
        params,
      });
      dispatch(receiveResource(MACHINE_SESSION_ATTEMPTS, sessions));

      // Return information about users in sessions
      if (sessions.length > 0) {
        const userGuidList = sessions.map((session) => session.userGuid);
        return userGuidList;
      }
      return [];
    } catch (error) {
      dispatch(handleApiError(error));
      return [];
    }
  };
}

/**
 * Fetches session information for a machine
 * @param {String} machineId - MachineId to fetch sessions for
 * @param {Object} sessionsRequired - Specifies what sessions to fetch for machine
 * @param {Boolean} sessionRequired.machineActive - Fetch active sessions for machine
 * @param {Boolean} sessionRequired.machineAttempts - Fetch sessions attempts for machine
 */
export function fetchMachineSessions(machineId, sessionsRequired = {}) {
  return async (dispatch) => {
    const options = createOptionsObject(
      {
        machineActive: true,
        machineAttempts: true,
        range: {},
      },
      sessionsRequired
    );

    const promises = [];
    if (options.machineActive) {
      promises.push(dispatch(fetchMachineActiveSessions(machineId)));
    }
    if (options.machineAttempts) {
      promises.push(
        dispatch(fetchMachineSessionAttempts(machineId, options.range))
      );
    }

    try {
      const promiseResp = await Promise.all(promises);
      const userGuidList = Array.prototype.concat(...promiseResp);
      const uniqueUserGuids = [...new Set(userGuidList)];
      dispatch(fetchAdUsers(uniqueUserGuids));
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };
}

export function fetchMachineRelatedElements(machinesResponse) {
  return async (dispatch) => {
    dispatch(fetchMachineEntitlements(machinesResponse));
    const machineId = convertMachineResponseToIds(machinesResponse);
    dispatch(fetchMachineSessions(machineId, {machineAttempts: false}));
    if (config.isMonitorEnabled()) {
      dispatch(fetchLatestMonitorTelemetry(machineId));
    }
  };
}

export function fetchOneRemoteWorkstation(
  machineData,
  deploymentId,
  entitlementsOnly = false,
  refreshMachineState = true,
  forceRefresh = true
) {
  return async (dispatch) => {
    let machine;
    const {machineId} = machineData;
    if (entitlementsOnly) {
      machine = machineData;
    } else {
      dispatch(requestResource('remoteWorkstations'));
      let response;

      try {
        response = await get({
          path: 'machines',
          params: {machineId, refreshMachineState, forceRefresh},
        });

        if (response.total === 0) {
          dispatch(
            openDialog(
              'Unable to retrieve workstation',
              `The workstation with id ${machineId} could not be found.`,
              () => dispatch(push(`${WORKSTATIONS_LINK}`)),
              false
            )
          );
          return;
        }
        [machine] = response.data;

        try {
          if (config.isMonitorEnabled() && machine.agentMonitored) {
            await post({
              path: `machines/${machineId}/messaging/requestUpdate`,
              params: {machineId, refreshMachineState},
            });
          }
        } catch (error) {
          dispatch(
            enqueueSnackbar({
              message: 'Failed to fetch workstation update',
              options: {
                variant: 'error',
              },
            })
          );
        }
      } catch (error) {
        const errorMessage = `An error occurred while retrieving workstation ${machineId}.`;

        dispatch(
          openDialog(
            'Unable to retrieve workstation',
            errorMessage,
            () => dispatch(push(`${WORKSTATIONS_LINK}`)),
            false
          )
        );
        return;
      }
    }

    const entitlementsResponse = await get({
      path: `deployments/${deploymentId}/entitlements`,
      params: {machineId, limit: 1000},
    });

    const entitlements = entitlementsResponse.data;

    const userGuid = entitlements.map((ent) => ent.userGuid).join(',');

    const path = mapResourceToPath(AD_USERS);
    const params = {
      userGuid,
      limit: 1000,
      deploymentId: machine.deploymentId,
    };

    const usersResponse = await doSafeRequest(get, {path, params});

    if (usersResponse.status === 'success') {
      const users = usersResponse.data;
      entitlements.map((entitlement) => {
        const user = users.find(
          (adUser) => adUser.userGuid === entitlement.userGuid
        );
        const updatedEntitlement = entitlement;
        if (user) {
          updatedEntitlement.user = user;
        }
        return updatedEntitlement;
      });
    } else {
      dispatch(handleApiError(usersResponse));
    }

    machine.entitlements = entitlements;

    dispatch(receiveResource(REMOTE_WORKSTATIONS, [machine]));
  };
}

export function fetchResource(resource, options = {}) {
  return (dispatch, getState) => {
    const state = getState();

    const params = createOptionsObject(
      {
        page: 0,
        rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      },
      options
    );

    const {deploymentId} = selectSelectedDeployment(state);
    const {poolId} = selectSelectedPool(state);

    dispatch(clearResource(resource));
    dispatch(requestResource(resource));

    return makeGetCall(resource, params, deploymentId, poolId).then(
      (response) => {
        switch (resource) {
          case REMOTE_WORKSTATIONS:
            if (!isEmpty(response.data)) {
              dispatch(fetchMachineEntitlements(response));
            }
            dispatch(receiveResource(resource, response.data));
            break;
          default:
            dispatch(receiveResource(resource, response.data));
            break;
        }
      },
      (error) => dispatch(handleApiError(error))
    );
  };
}

// Actions for handling dropdown selector data

export const REQUEST_NEXT_PAGE = 'REQUEST_NEXT_PAGE';

function requestNextPage(resource) {
  return {
    type: REQUEST_NEXT_PAGE,
    resource,
  };
}

export const LOAD_NEXT_PAGE = 'LOAD_NEXT_PAGE';

function loadNextPage(resource, data) {
  return {
    type: LOAD_NEXT_PAGE,
    resource,
    data,
  };
}

export function fetchNextPage(
  resource,
  params,
  rowsPerPage = 25,
  {signal} = {}
) {
  return async (dispatch, getState) => {
    dispatch(requestNextPage(resource));
    try {
      const state = getState();
      const page = state.dropdownData[resource].page || 0;
      const {deploymentId} = selectSelectedDeployment(state);
      const {poolId} = selectSelectedPool(state);
      const response = await makeGetCall(
        resource,
        {...params, page, rowsPerPage},
        deploymentId,
        poolId,
        {signal}
      );
      dispatch(loadNextPage(resource, response.data));
    } catch (error) {
      // an request might be aborted because another request is sent and the older request is no longer needed
      if (signal && signal.aborted) {
        return;
      }
      dispatch(handleApiError(error));
    }
  };
}

export const CLEAR_DROPDOWN_DATA = 'CLEAR_DROPDOWN_DATA';

export function clearDropdownData(resource) {
  return {
    type: CLEAR_DROPDOWN_DATA,
    resource,
  };
}

export function searchAdComputers(text, params, {signal} = {}) {
  return async (dispatch) => {
    dispatch(requestNextPage('adComputers'));
    try {
      const response = await makeGetCall(
        'adComputers',
        {...params, computerName: `includes:${text}`, rowsPerPage: 25},
        undefined,
        undefined,
        {signal}
      );
      dispatch(loadNextPage('adComputers', response.data));
    } catch (error) {
      // an request might be aborted because another request is sent and the older request is no longer needed
      if (signal && signal.aborted) {
        return;
      }
      dispatch(handleApiError(error));
    }
  };
}

export function setSelectedConnector(connector) {
  return saveVariable('selectedConnector', connector);
}

export function fetchConnectorWithId(connectorId) {
  return async (dispatch) => {
    try {
      const response = await get({
        path: `deployments/connectors/${connectorId}`,
      });
      dispatch(saveVariable('editPageConnector', response.data));
    } catch (error) {
      dispatch(
        saveVariable('editPageConnector', {connectorName: '', connectorId: ''})
      );
    }
  };
}

export function fetchSelectedConnector(connectorId) {
  return async (dispatch) => {
    try {
      const response = await get({
        path: `deployments/connectors/${connectorId}`,
      });
      dispatch(saveVariable('selectedConnector', response.data));
    } catch (error) {
      let errorMessage = `An error occurred while retrieving connector ${connectorId}.`;

      switch (error.code) {
        case 404:
          errorMessage = `The connector with id ${connectorId} could not be found.`;
          break;
        default:
          break;
      }

      dispatch(
        openDialog(
          'Unable to retrieve connector',
          errorMessage,
          () => dispatch(push(`${CONNECTORS_LINK}`)),
          false
        )
      );
    }
  };
}

export function fetchSelectedWebhook(webhookId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {deploymentId} = selectSelectedDeployment(state);

      const basePath = mapResourceToPath(WEBHOOKS, {deploymentId});
      const path = `${basePath}/${webhookId}`;

      const response = await get({path});
      dispatch(saveVariable('selectedWebhook', response.data));
    } catch (error) {
      let errorMessage = `An error occurred while retrieving webhook ${webhookId}.`;

      switch (error.code) {
        case 404:
          errorMessage = `The webhook with id ${webhookId} could not be found.`;
          break;
        default:
          break;
      }

      dispatch(
        openDialog(
          'Unable to retrieve webhook',
          errorMessage,
          () => dispatch(push(`${POOLS_LINK}`)),
          false
        )
      );
    }
  };
}

export function fetchSelectedWebhookCACert(webhookURL) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {deploymentId} = selectSelectedDeployment(state);
      const webhookCACertKey = webhookCACertKeyFromUrl(webhookURL);
      const path = `deployments/${deploymentId}/settings/${webhookCACertKey}`;
      const response = await get({path});
      if (response.data) {
        return WEBHOOKS_CA_CERT_STATUS_SET;
      }
    } catch (error) {
      switch (error.code) {
        case 404:
          return WEBHOOKS_CA_CERT_STATUS_NOT_KNOWN;
        default:
          return WEBHOOKS_CA_CERT_STATUS_NOT_SET;
      }
    }
    return WEBHOOKS_CA_CERT_STATUS_NOT_SET;
  };
}

export function fetchEditDeployment(deploymentId) {
  return async (dispatch) => {
    try {
      const response = await get({
        path: `deployments/${deploymentId}`,
      });

      dispatch(saveVariable('selectedDeployment', response.data));
    } catch (error) {
      let errorMessage = `An error occurred while retrieving deployment ${deploymentId}.`;

      switch (error.code) {
        case 404:
          errorMessage = `The deployment with id ${deploymentId} could not be found.`;
          break;
        default:
          break;
      }

      dispatch(
        openDialog(
          'Unable to retrieve deployment',
          errorMessage,
          () => dispatch(push(`${DASHBOARD_LINK}`)),
          false
        )
      );
    }
  };
}

export function fetchGcpOptions(deploymentId, errorHandler) {
  return (dispatch) => {
    dispatch(requestResource('zones'));
    return get({
      path: 'machines/cloudproviders/gcp',
      params: {deploymentId},
    }).then(
      (response) => {
        dispatch(receiveResource('zones', response.data.zones));
      },
      (error) => {
        // it is necessary to clear the flag that indicates that
        // the element is fetching if an error is returned.
        dispatch(clearResource('zones'));

        if (errorHandler) {
          errorHandler(error);
          return;
        }
        dispatch(handleApiError(error));
      }
    );
  };
}

export function fetchGcpRegions(deploymentId) {
  return (dispatch) => {
    dispatch(requestResource(GCP_REGIONS));
    return get({
      path: `machines/cloudproviders/gcp/regions?deploymentId=${deploymentId}`,
    })
      .then((response) => {
        dispatch(receiveResource(GCP_REGIONS, response.data.regions));
      })
      .catch((error) => dispatch(handleApiError(error)));
  };
}

export function fetchGcpZones(deploymentId, region, errorHandler) {
  return (dispatch) => {
    dispatch(requestResource(GCP_ZONES));
    return get({
      path: `machines/cloudproviders/gcp/regions/${region}/zones`,
      params: {deploymentId},
    }).then(
      (response) => {
        dispatch(receiveResource(GCP_ZONES, response.data.zones));
      },
      (error) => {
        // it is necessary to clear the flag that indicates that
        // the element is fetching if an error is returned.
        dispatch(clearResource(GCP_ZONES));

        if (errorHandler) {
          errorHandler(error);
          return;
        }
        dispatch(handleApiError(error));
      }
    );
  };
}

export function fetchMachineTypes(zone, params = {}) {
  return (dispatch) => {
    dispatch(requestResource('machineTypes'));
    return get({
      path: `machines/cloudproviders/gcp/zones/${zone}/machineTypes`,
      params,
    })
      .then((response) => {
        dispatch(receiveResource('machineTypes', response.data.machineTypes));
      })
      .catch((error) => dispatch(handleApiError(error)));
  };
}

export function fetchAcceleratorTypes(deploymentId, zone) {
  return (dispatch) => {
    dispatch(requestResource('acceleratorTypes'));
    return get({
      path: `machines/cloudproviders/gcp/zones/${zone}/acceleratorTypes?deploymentId=${deploymentId}`,
    })
      .then((response) => {
        dispatch(
          receiveResource('acceleratorTypes', response.data.acceleratorTypes)
        );
      })
      .catch((error) => dispatch(handleApiError(error)));
  };
}

export function fetchDiskTypes(deploymentId, zone) {
  return (dispatch) => {
    dispatch(requestResource('diskTypes'));
    return get({
      path: `machines/cloudproviders/gcp/zones/${zone}/bootabledisktypes?deploymentId=${deploymentId}`,
    })
      .then((response) => {
        dispatch(receiveResource('diskTypes', response.data.diskTypes));
      })
      .catch((error) => dispatch(handleApiError(error)));
  };
}

export function fetchGcpNetworks() {
  return async (dispatch, getState) => {
    await dispatch(clearResource(GCP_NETWORKS));
    dispatch(requestResource(GCP_NETWORKS));

    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);

    try {
      const {data} = await get({
        path: mapResourceToPath(GCP_NETWORKS),
        params: {deploymentId},
      });
      const sortedNetworks = data.networks.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      dispatch(receiveResource(GCP_NETWORKS, sortedNetworks));
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };
}

export function fetchGcpSubnets(gcpRegion, gcpNetwork) {
  return async (dispatch, getState) => {
    await dispatch(clearResource(GCP_SUBNETS));
    dispatch(requestResource(GCP_SUBNETS));

    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);

    try {
      const {data} = await get({
        path: mapResourceToPath(GCP_SUBNETS, {gcpRegion}),
        params: {deploymentId, network: gcpNetwork},
      });
      const sortedSubnets = data.subnetworks.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      dispatch(receiveResource(GCP_SUBNETS, sortedSubnets));
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };
}

function processCloudServiceAccountsExpiration(accounts) {
  return accounts.map((account) => {
    const newAccount = account;
    newAccount.expired = moment(account.endDate).isBefore();
    newAccount.expiresSoon =
      !newAccount.expired &&
      moment(account.endDate).isBefore(
        moment().add(CLOUD_SERVICE_ACCOUNTS_EXPIRATION_WARN_IF_LESS, 'days')
      );
    newAccount.expiresIn = newAccount.expired
      ? 0
      : moment(account.endDate).diff(moment(), 'days');
    return account;
  });
}

export function fetchCloudServiceAccountsExpiryInfo(params = {}) {
  return (dispatch) => {
    dispatch(requestResource(CLOUD_SERVICE_ACCOUNTS_EXPIRY_INFO));
    const {deploymentId} = params;
    return get({
      path: mapResourceToPath(CLOUD_SERVICE_ACCOUNTS_EXPIRY_INFO, {
        deploymentId,
      }),
    })
      .then((response) => {
        const accounts = processCloudServiceAccountsExpiration(response.data);
        const azureSecretsExpiring =
          Object.entries(accounts).filter(
            (account) => account[1].expiresSoon || account[1].expired
          ).length || 0;

        dispatch(saveVariable('azureSecretsExpiring', azureSecretsExpiring));
        dispatch(receiveResource(CLOUD_SERVICE_ACCOUNTS_EXPIRY_INFO, accounts));
      })
      .catch((error) => console.log(error));
  };
}

export function fetchCloudServiceAccounts(params = {}) {
  return async (dispatch, getState) => {
    dispatch(clearResource(CLOUD_SERVICE_ACCOUNTS));
    dispatch(clearResource(CLOUD_SERVICE_ACCOUNTS_EXPIRY_INFO));
    dispatch(saveVariable('azureSecretsExpiring', 0));
    dispatch(requestResource(CLOUD_SERVICE_ACCOUNTS));
    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);
    const path = `deployments/${deploymentId}/cloudServiceAccounts`;
    try {
      const {data: cloudServiceAccounts} = await get({path});
      dispatch(receiveResource(CLOUD_SERVICE_ACCOUNTS, cloudServiceAccounts));
      if (cloudServiceAccounts.find((account) => account.provider === AZURE)) {
        dispatch(fetchCloudServiceAccountsExpiryInfo(params));
      }
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };
}

export function searchAdUsers(term, deploymentId) {
  return async (dispatch) => {
    dispatch(requestResource('adUsers'));
    try {
      const params = {
        name: `includes:${term}`,
        deploymentId,
        rowsPerPage: 1000,
      };

      const response = await makeGetCall('adUsers', params);

      const {data} = response;
      dispatch(receiveResource('adUsers', data));
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };
}

// Update the machine if it is in the store
// The machine may either be in selectedWorkstation or remoteWorkstations

export const UPDATE_WORKSTATION = 'UPDATE_WORKSTATION';

export function updateWorkstation(updatedMachine) {
  return {
    type: UPDATE_WORKSTATION,
    updatedMachine,
  };
}

export const UPDATE_POOL_WORKSTATION = 'UPDATE_POOL_WORKSTATION';

export function updatePoolWorkstation(updatedPoolMachine) {
  return {
    type: UPDATE_POOL_WORKSTATION,
    updatedMachine: updatedPoolMachine,
  };
}

export const REQUEST_DELETE_RESOURCE = 'REQUEST_DELETE_RESOURCE';

export function requestDeleteResource(resource, id) {
  return {
    type: REQUEST_DELETE_RESOURCE,
    resource,
    id,
  };
}

export const RECEIVE_DELETE_RESOURCE = 'RECEIVE_DELETE_RESOURCE';

export function receiveDeleteResource(resource, id) {
  return {
    type: RECEIVE_DELETE_RESOURCE,
    resource,
    id,
  };
}

export const RECEIVE_DELETE_RESOURCE_ERROR = 'RECEIVE_DELETE_RESOURCE_ERROR';

export function receiveDeleteResourceError(resource, id) {
  return {
    type: RECEIVE_DELETE_RESOURCE_ERROR,
    resource,
    id,
  };
}

export const fetchDeployments = () => async (dispatch) => {
  const path = mapResourceToPath(DEPLOYMENTS);
  dispatch(
    fetchData(DEPLOYMENTS, {
      path,
      params: {
        offset: 0,
        limit: 200,
        showdeleting: true,
      },
    })
  );
};

export const setDeletedDeploymentPolling = (deployment) => async (dispatch) => {
  const {deploymentId, deploymentName} = deployment;

  const checkDeploymentDeletedSuccessfully = (input) => {
    if (input.code === 404) {
      dispatch(
        pushNotification({
          title: `Deployment ${deploymentName} was deleted.`,
          type: 'Deployments',
          moreInfo: `Deployment ${deploymentName} was deleted successfully.`,
          timePosted: Date.now(),
        })
      );
      dispatch(receiveDeleteResource(DEPLOYMENTS, deploymentId));
      return true;
    }
    return false;
  };

  const checkDeploymentFailedToDelete = (input) => {
    if (input.data && input.data.status && input.data.status !== 'deleting') {
      dispatch(
        pushNotification({
          title: `Failed to delete deployment ${deploymentName}`,
          type: 'Deployments',
          moreInfo:
            'Check if the provider credentials added to the deployment are still valid and able to delete remote workstations.',
          timePosted: Date.now(),
        })
      );
      dispatch(fetchDeployments());
      return true;
    }
    return false;
  };

  dispatch(
    addPollingItem({
      key: `${DEPLOYMENTS}-${deploymentId}`,
      path: `${mapResourceToPath(DEPLOYMENTS)}${deploymentId}`,
      property: 'status',
      resourceId: deploymentId,
      resourceType: DEPLOYMENTS,

      // This wil receive the response object OR the error object of the API call
      endFunctions: [
        checkDeploymentDeletedSuccessfully,
        checkDeploymentFailedToDelete,
      ],
    })
  );
};

export function deleteDeployment({
  deploymentId,
  deploymentName,
  deleteFromProvider,
}) {
  return async (dispatch, getState) => {
    dispatch(requestDeleteResource());
    // send request to delete deployment on backend
    // Upon success:
    //   redirect to dashboard
    //   remove deployment from list
    //   if deployment was selected, set a new deployment as selected

    const message = `Deployment "${deploymentName}" is being deleted. ${
      deleteFromProvider
        ? 'Workstations will also be deleted from public cloud providers.'
        : 'Workstations will NOT be deleted from public cloud providers and will continue to incur costs until they are deleted.'
    }`;

    try {
      await del({
        path: `deployments/${deploymentId}`,
        data: {deleteFromProvider},
      });
      dispatch(replace(`${DASHBOARD_LINK}`));
      dispatch(
        enqueueSnackbar({
          message,
          options: {
            variant: 'success',
          },
        })
      );

      const {dataByResource} = getState().data || {
        dataByResource: {[DEPLOYMENTS]: {data: {}}, selectedDeployment: {}},
      };

      const {selectedDeployment, [DEPLOYMENTS]: deployments} = dataByResource;

      dispatch(setDeletedDeploymentPolling({...selectedDeployment}));

      // if the deployment was selected, set a new active deployment as selected
      if (
        selectedDeployment &&
        selectedDeployment.deploymentId === deploymentId
      ) {
        const newSelectedDeployment = Object.values(deployments.data).find(
          (deployment) =>
            deployment.status === 'active' &&
            deployment.deploymentId !== selectedDeployment.deploymentId
        );
        if (newSelectedDeployment) {
          dispatch(saveVariable('selectedDeployment', newSelectedDeployment));
        }
        // fetches the deployments again so that a deployment that was set to deleting will have its status updated so that
        // it can be displayed as deleting on the filter. Also, it will ensure that if any other deployments were being deleted
        // already, they will have its status updated. It makes things a little more responsive.
        dispatch(fetchDeployments());
      }
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };
}

export function preventClosingApp(shouldPrevent) {
  return async (dispatch, getState) => {
    let backgroundActions = getState().data.dataByResource.parallelActions || 0;

    if (shouldPrevent) {
      backgroundActions += 1;
    } else if (backgroundActions > 0) {
      backgroundActions -= 1;
    }
    dispatch(saveVariable('backgroundActions', backgroundActions));
  };
}

export function addRemoteWorkstationsFromMultipleProviders({machines, users}) {
  return async (dispatch) => {
    dispatch(preventClosingApp(true));

    const machinesWithErrors = [];
    const machinesWithPoolErrors = [];

    await Promise.all(
      machines.map(async (machine) => {
        const {deploymentId} = machine;
        try {
          const {data: response, code} = await post({
            path: 'machines',
            data: machine,
            params: {
              verifyExistsInProvider: isCloudProvider(machine.provider),
            },
          });

          const {machineId} = response;

          if (code === 207) {
            machinesWithPoolErrors.push(machine.machineName);
          }

          const promises = [];
          users.forEach((user) => {
            const {userGuid, upn} = user;

            const entitlement = {
              userGuid,
              upn,
              machineId,
            };
            const promise = post({
              path: `deployments/${deploymentId}/entitlements`,
              data: entitlement,
            });
            promises.push(promise);
          });
          await Promise.all(promises);
        } catch (error) {
          machinesWithErrors.push(machine.machineName);
        }
      })
    );

    const bellNotification = {
      type: 'Remote Workstations',
      timePosted: Date.now(),
      link: WORKSTATIONS_LINK,
      moreInfo: '',
    };

    if (machinesWithErrors.length) {
      const moreInfo = `We encountered errors when adding the following ${
        machinesWithErrors.length
      } remote workstations: ${machinesWithErrors.join(', ')}.`;

      dispatch(
        pushNotification({
          ...bellNotification,
          title: `Failed to add ${
            machinesWithErrors.length
          } remote workstation${machinesWithErrors.length > 1 ? 's' : ''}.`,
          moreInfo,
        })
      );
    } else {
      dispatch(
        pushNotification({
          ...bellNotification,
          title:
            machines.length > 1
              ? `${machines.length} Remote Workstations were added.`
              : '1 Remote workstation was added.',
        })
      );
    }

    if (machinesWithPoolErrors.length) {
      const numErrors = machinesWithPoolErrors.length;
      const machineNames = machinesWithPoolErrors.join(', ');
      const rwText =
        numErrors > 1 ? 'remote workstations' : 'remote workstation';

      dispatch(
        pushNotification({
          ...bellNotification,
          title: `Failed to add ${numErrors} ${rwText} to the pool.`,
          moreInfo: `Errors occurred when adding ${numErrors} ${rwText} to the pool: ${machineNames}`,
        })
      );
    }

    dispatch(preventClosingApp(false));
  };
}

export function fetchAzureResourceGroups(deploymentId, subscriptionId) {
  return async (dispatch) => {
    dispatch(requestResource(AZURE_RESOURCE_GROUPS));
    try {
      const response = await get({
        path: 'machines/cloudproviders/azure/resourceGroups',
        params: {deploymentId, subscriptionId},
      });
      dispatch(receiveResource(AZURE_RESOURCE_GROUPS, response.data));
    } catch (error) {
      dispatch(clearResource(AZURE_RESOURCE_GROUPS));
      dispatch(handleApiError(error));
    }
  };
}

export function fetchAzureVNetSubnets(deploymentId, resourceGroup) {
  return async (dispatch) => {
    dispatch(clearResource(AZURE_SUBNETS));
    dispatch(requestResource(AZURE_SUBNETS));
    try {
      const path = mapResourceToPath(AZURE_SUBNETS);
      const response = await get({
        path,
        params: {deploymentId, resourceGroup},
      });
      dispatch(receiveResource(AZURE_SUBNETS, response.data));
    } catch (error) {
      dispatch(clearResource(AZURE_SUBNETS));
      dispatch(handleApiError(error));
    }
  };
}

export function fetchAzureLocations(deploymentId) {
  return async (dispatch) => {
    dispatch(requestResource(AZURE_LOCATIONS));
    try {
      const path = mapResourceToPath(AZURE_LOCATIONS);
      const response = await get({
        path,
        params: {deploymentId},
      });
      dispatch(receiveResource(AZURE_LOCATIONS, response.data));
    } catch (error) {
      dispatch(clearResource(AZURE_LOCATIONS));
      dispatch(handleApiError(error));
    }
  };
}

// TODO: Placeholder argument for fetching only graphics size machine types for azure
// eslint-disable-next-line no-unused-vars
export function fetchAzureVmSizes(deploymentId, location, graphicsSizesOnly) {
  return async (dispatch) => {
    dispatch(requestResource(AZURE_VM_SIZES));
    try {
      const path = mapResourceToPath(AZURE_VM_SIZES);
      const response = await get({
        path,
        params: {
          deploymentId,
          location,
        },
      });
      dispatch(receiveResource(AZURE_VM_SIZES, response.data));
    } catch (error) {
      dispatch(clearResource(AZURE_VM_SIZES));
      dispatch(handleApiError(error));
    }
  };
}

export function fetchAwsInstances(deploymentId, region) {
  return async (dispatch) => {
    dispatch(clearResource(AWS_INSTANCES));
    dispatch(requestResource(AWS_INSTANCES));
    try {
      const response = await get({
        path: mapResourceToAPI(AWS_INSTANCES),
        params: {deploymentId, region, filterAdded: true},
      });
      const awsInstances = response.data.map((instance) => ({
        ...instance,
        id: instance.instanceId,
        provider: AWS,
      }));
      dispatch(receiveResource(AWS_INSTANCES, awsInstances));
    } catch (error) {
      dispatch(clearResource(AWS_INSTANCES));
      dispatch(handleApiError(error));
    }
  };
}

export function fetchAzureInstances(deploymentId, resourceGroup) {
  return async (dispatch) => {
    dispatch(clearResource(AZURE_INSTANCES));
    dispatch(requestResource(AZURE_INSTANCES));
    try {
      const response = await get({
        path: mapResourceToAPI(AZURE_INSTANCES),
        params: {deploymentId, resourceGroup, filterAdded: true},
      });
      const instances = response.data.map((instance) => ({
        ...instance,
        id: instance.name,
        provider: AZURE,
      }));

      dispatch(receiveResource(AZURE_INSTANCES, instances));
    } catch (error) {
      dispatch(clearResource(AZURE_INSTANCES));
      dispatch(handleApiError(error));
    }
  };
}

export function fetchGcpInstances(deploymentId, zone) {
  return async (dispatch) => {
    dispatch(clearResource(GCP_INSTANCES));
    dispatch(requestResource(GCP_INSTANCES));
    try {
      const response = await get({
        path: mapResourceToAPI(GCP_INSTANCES),
        params: {deploymentId, zone, filterAdded: true},
      });
      const instances = response.data.map((instance) => ({
        ...instance,
        id: instance.name,
        provider: GCP,
      }));
      dispatch(receiveResource(GCP_INSTANCES, instances));
    } catch (error) {
      dispatch(clearResource(GCP_INSTANCES));
      dispatch(handleApiError(error));
    }
  };
}

export function fetchUserPoolMachines(pools, userGuid) {
  const buildPoolMachineGetRequest = (pool) => {
    const {deploymentId, poolId} = pool;
    const path = mapResourceToPath(POOL_MACHINES, {deploymentId, poolId});
    return {
      path,
      params: {assignedTo: userGuid},
    };
  };

  return async (dispatch) => {
    dispatch(clearResource(POOL_MACHINES));
    dispatch(requestResource(POOL_MACHINES));

    const responses = await doSafeBulkRequest(
      get,
      pools,
      buildPoolMachineGetRequest
    );
    const failures = responses.filter(
      (response) => response.status !== 'success'
    );

    if (failures.length) {
      dispatch(
        enqueueSnackbar({
          message: `An error occurred while retrieving ${failures.length} assigned workstations.`,
          options: {
            variant: 'error',
          },
        })
      );
    }

    const poolMachines = responses.map((response, index) => {
      const [poolMachine] = response.data || [{}];
      return {
        ...poolMachine,
        poolId: pools[index].poolId,
      };
    });
    dispatch(receiveResource(POOL_MACHINES, poolMachines));
  };
}

export function fetchSamlAllowedUsers(configurationId) {
  return async (dispatch) => {
    dispatch(clearResource(SAML_ALLOWED_USERS));
    dispatch(requestResource(SAML_ALLOWED_USERS));
    try {
      const response = await get({
        path: `auth/saml/${configurationId}/allowedUsers`,
      });
      dispatch(receiveResource(SAML_ALLOWED_USERS, response.data));
    } catch (error) {
      dispatch(clearResource(SAML_ALLOWED_USERS));
      dispatch(handleApiError(error));
    }
  };
}

export function fetchAdGroups() {
  return async (dispatch, getState) => {
    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);

    dispatch(requestResource(AD_GROUPS));

    let adGroups = [];
    try {
      const settings = await getConnectorSettings(deploymentId);
      const poolGroups = settings?.deployment?.poolsGroups || [];
      adGroups = poolGroups.map((adGroup) =>
        buildFrontendAdGroupFormat(adGroup)
      );
    } catch (err) {
      dispatch(handleApiError(err));
    }

    dispatch(receiveResource(AD_GROUPS, adGroups));
    return {
      data: adGroups,
      total: adGroups.length,
    };
  };
}

// used to delete an AD group from Anyware, expects 'groups' to be passed
// in as formatted by the utility 'buildFrontendAdGroupFormat'
export function deleteAdGroups(groups) {
  return async (dispatch, getState) => {
    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);
    // const path = mapResourceToPath(AD_GROUPS, { deploymentId });

    // get all connector settings from backend
    let connectorSettings = {};
    try {
      connectorSettings = await getConnectorSettings(deploymentId);
    } catch (err) {
      dispatch(handleApiError(err));
      return;
    }

    // pull out AD groups from connector settings and format them
    const allAdGroups = connectorSettings.deployment.poolsGroups || [];
    const allAdGroupsFormatted = allAdGroups.map((group) =>
      buildFrontendAdGroupFormat(group)
    );

    // determine the IDs of groups to delete, then form a new poolGroups
    // object that doesn't include those groups
    const groupIdsToDelete = groups.map((group) => group.groupGuid);
    const remainingAdGroups = allAdGroupsFormatted.filter(
      (group) => !groupIdsToDelete.includes(group.groupGuid)
    );

    // undo the frontend AD group format and re-encode the data
    const reversedGroupData = remainingAdGroups.map((group) =>
      reverseFrontendAdGroupFormat(group)
    );

    // Use the path in the connector settings object to detect if it is
    // the legacy of the new one.
    // The path is added when connector settings are fetched.
    const originalPath = connectorSettings.path;

    delete connectorSettings.path;
    delete connectorSettings.deploymentId;

    let data;
    // Legacy and new rotes have different formats for the connector settings
    if (originalPath.includes('connectorSettings')) {
      // Legacy route: supports updating pools groups in the deployment settings
      connectorSettings.poolsGroups = reversedGroupData;
      data = {value: encodeBase64(connectorSettings)};
    } else {
      // New route: does not support updating deployment settings only
      // A story created to do it in the backend: https://jira.teradici.com/browse/TSW-147065
      // This code is not reachable yet as the delete option in the deployment settings
      // is disabled if the new route returned the deployment settings.
      connectorSettings.deployment.poolsGroups = reversedGroupData;
      data = connectorSettings;
    }

    // update the AD groups in the backend
    try {
      await put({
        path: originalPath,
        data,
      });
    } catch (err) {
      dispatch(handleApiError(err));
      return;
    }

    dispatch(
      enqueueSnackbar({
        message: `Successfully deleted AD group${
          groups.length > 1 ? 's' : ''
        } from deployment`,
        options: {
          variant: 'success',
        },
      })
    );
  };
}

export function removePoolUsers(poolUsers) {
  return async (dispatch, getState) => {
    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);

    const basePath = mapResourceToPath(POOL_USERS, {deploymentId});

    const requests = poolUsers
      .filter((user) => !['deleting', 'deleted'].includes(user.status))
      .map(async (user) => {
        let resp;
        try {
          resp = await del({
            path: `${basePath}${user.entitlementId}`,
          });
        } catch (err) {
          resp = err;
        }
        return resp;
      });
    const responses = await Promise.all(requests);

    const numFailures = responses.filter(
      (response) => response.status !== 'success'
    ).length;

    if (numFailures > 0) {
      dispatch(
        enqueueSnackbar({
          message: `An error occurred while removing ${numFailures} users from the pool.`,
          options: {
            variant: 'error',
          },
        })
      );
    } else {
      dispatch(
        enqueueSnackbar({
          message: 'Successfully removed users from the pool.',
          options: {
            variant: 'success',
          },
        })
      );
    }
  };
}

export function removePoolGroups(poolGroups) {
  return async (dispatch, getState) => {
    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);

    const basePath = mapResourceToPath(POOL_GROUPS, {deploymentId});

    const requests = poolGroups
      .filter((group) => !['deleting', 'deleted'].includes(group.status))
      .map(async (group) => {
        let resp;
        try {
          resp = await del({
            path: `${basePath}${group.entitlementId}`,
          });
        } catch (err) {
          resp = err;
        }
        return resp;
      });
    const responses = await Promise.all(requests);

    const numFailures = responses.filter(
      (response) => response.status !== 'success'
    ).length;

    if (numFailures > 0) {
      dispatch(
        enqueueSnackbar({
          message: `An error occurred while removing ${numFailures} groups from the pool.`,
          options: {
            variant: 'error',
          },
        })
      );
    } else {
      dispatch(
        enqueueSnackbar({
          message: 'Successfully removed groups from the pool.',
          options: {
            variant: 'success',
          },
        })
      );
    }
  };
}

function formatConnectorSettingsResponse(inputConnectorSettings, deploymentId) {
  return {
    ...inputConnectorSettings,
    deployment: {
      computersDN: ['Not available'],
      syncInterval: '',
      usersDN: ['Not available'],
      ...inputConnectorSettings?.deployment,
    },
    deploymentId,
  };
}

export function fetchConnectorSettings(connectorId) {
  return async (dispatch, getState) => {
    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);
    let data = {};

    dispatch(requestResource(CONNECTOR_SETTINGS));
    data = await getConnectorSettings(deploymentId, connectorId);

    const connectorSettings = formatConnectorSettingsResponse(
      data,
      deploymentId
    );
    dispatch(receiveResource(CONNECTOR_SETTINGS, [connectorSettings]));
  };
}

export function fetchMultipleConnectorSettings(connectorIds) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {deploymentId} = selectSelectedDeployment(state);

      await dispatch(requestResource(MULTIPLE_CONNECTOR_SETTINGS));

      // Use Promise.all to concurrently fetch connector settings for all connectorIds
      const connectorSettingsPromises = connectorIds.map((connectorId) =>
        getConnectorSettings(deploymentId, connectorId)
      );

      const connectorSettings = await Promise.all(connectorSettingsPromises);

      // Transform the data and dispatch the action.
      // This transformation is necessary to return the data in the same format used by the fetchConnectorSettings.
      const transformedConnectorSettings = connectorSettings.map((data) =>
        formatConnectorSettingsResponse(data, deploymentId)
      );

      const mergedSettings = transformedConnectorSettings.map(
        (settings, index) => ({
          connectorId: connectorIds[index],
          ...settings,
        })
      );

      dispatch(receiveResource(MULTIPLE_CONNECTOR_SETTINGS, mergedSettings));
    } catch (error) {
      console.error('Failed to fetch connector settings:', error);
    }
  };
}

/** This is useful for updating saml configuration.
 *  Unlike fetchResource, this does not clear SAML_CONFIGURATION from store before fetching. */
export function fetchSamlConfigSettings() {
  return async (dispatch) => {
    dispatch(requestResource(SAML_CONFIGURATION));
    const {data} = await get({
      path: mapResourceToPath(SAML_CONFIGURATION),
    });
    dispatch(receiveResource(SAML_CONFIGURATION, data));
  };
}

export function fetchTelemetrySettings() {
  return async (dispatch, getState) => {
    dispatch(requestResource(TELEMETRY_SETTINGS));

    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);
    const path = mapResourceToPath(TELEMETRY_SETTINGS, {deploymentId});

    try {
      const response = await get({path});
      const data = {...response.data, deploymentId};
      dispatch(receiveResource(TELEMETRY_SETTINGS, [data]));
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };
}

export function fetchPollingSettings() {
  return async (dispatch, getState) => {
    dispatch(requestResource(POLLING_SETTINGS));

    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);
    const path = mapResourceToPath(POLLING_SETTINGS, {deploymentId});

    try {
      const response = await get({path});
      dispatch(receiveResource(POLLING_SETTINGS, [response.data]));
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };
}

export function fetchSamlAllowedGroups(configurationId, clearGroups = false) {
  return async (dispatch) => {
    if (clearGroups) {
      dispatch(clearResource(SAML_ALLOWED_GROUPS));
    }
    dispatch(requestResource(SAML_ALLOWED_GROUPS));
    try {
      const response = await get({
        path: `auth/saml/${configurationId}/allowedGroups`,
      });
      dispatch(receiveResource(SAML_ALLOWED_GROUPS, response.data));
    } catch (error) {
      dispatch(clearResource(SAML_ALLOWED_GROUPS));
      dispatch(handleApiError(error));
    }
  };
}

export async function changeUserAssignedToPoolMachine({
  machineId,
  userGuid,
  deploymentId,
  poolId,
}) {
  await put({
    path: `deployments/${deploymentId}/pools/${poolId}/machines`,
    data: {machineId, assignedTo: userGuid},
  });
}

export const checkAgentMonitored = async (userMachineId) => {
  const path = mapResourceToPath(REMOTE_WORKSTATIONS);
  const response = await get({
    path,
    params: {machineId: userMachineId},
  });

  if (response.status === 'success') {
    const machine = response.data[0];
    return machine.agentMonitored;
  }
  return false;
};

export function fetchCasmVersionInfo() {
  return async (dispatch) => {
    if (!config.STANDALONE) {
      return;
    }

    try {
      const response = await get({path: mapResourceToPath(AWM_VERSION_INFO)});
      dispatch(saveVariable(AWM_VERSION_INFO, response.data));
    } catch (error) {
      dispatch(saveVariable(AWM_VERSION_INFO, {}));
      dispatch(handleApiError(error));
    }
  };
}