import {Chip} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {mapStatusToColor, mapStatusToText} from 'utils/Mappings';

const PREFIX = 'PoolInsights';

const classes = {
  chipRoot: `${PREFIX}-chipRoot`,
};

const CustomChip = styled(
  ({...props}) => <Chip {...props} />,
  {}
)(({theme, ...props}) => {
  const backgroundHex = theme.palette[mapStatusToColor(props.status)].main;
  const color = theme.palette.getContrastText(
    theme.palette[mapStatusToColor(props.status)].main
  );

  return {
    [`&.${classes.chipRoot}`]: {
      background: backgroundHex,
      color,
    },
  };
});

function PoolInsights({status}) {
  const chipProps = {
    size: 'small',
    label: mapStatusToText(status),
    classes: {root: classes.chipRoot},
    status,
  };

  return <CustomChip {...chipProps} />;
}

PoolInsights.propTypes = {
  status: PropTypes.string.isRequired,
};

export default PoolInsights;
