import {Button, Grid} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import usePendingChanges from 'hooks/usePendingChanges';
import useSnackbar from 'hooks/useSnackbar';
import {
  handleApiError,
  fetchSamlConfigSettings,
} from 'redux/actions/dataActions';
import {SAML_CONFIGURATION} from 'utils/constants';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {isEmpty} from 'utils/utils';
import useUpdateSamlConfig from 'hooks/useUpdateSamlConfig';
import SamlIdpSettingsInput from './SamlIdpSettingsInput';

const PREFIX = 'SamlIdpSettings';

const classes = {
  button: `${PREFIX}-button`,
  cancelButton: `${PREFIX}-cancelButton`,
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  inputSectionSubtitle: `${PREFIX}-inputSectionSubtitle`,
  gridItem: `${PREFIX}-gridItem`,
  dropbox: `${PREFIX}-dropbox`,
  dropboxActive: `${PREFIX}-dropboxActive`,
  fileRow: `${PREFIX}-fileRow`,
  buttonText: `${PREFIX}-buttonText`,
  dropText: `${PREFIX}-dropText`,
  fileIcon: `${PREFIX}-fileIcon`,
  disabled: `${PREFIX}-disabled`,
  dropboxDivideContainer: `${PREFIX}-dropboxDivideContainer`,
  dropboxDivider: `${PREFIX}-dropboxDivider`,
  dropboxDivideText: `${PREFIX}-dropboxDivideText`,
};

const StyledGrid = styled(Grid)(({theme}) => ({
  [`& .${classes.button}`]: theme.createPage.button,

  [`& .${classes.cancelButton}`]: {
    ...theme.createPage.button,
    marginLeft: theme.spacing(1),
  },

  [`&.${classes.root}`]: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    backgroundColor: 'transparent',
    padding: '2px',
  },

  [`& .${classes.input}`]: {
    width: '100%',
  },

  [`& .${classes.inputSectionSubtitle}`]: {
    ...theme.createPage.inputSectionSubtitle,
    marginBottom: '1rem',
  },

  [`& .${classes.gridItem}`]: {
    marginRight: '3.1rem',
    marginBottom: '1rem',
  },

  [`& .${classes.dropbox}`]: {
    width: '100%',
    boxSizing: 'border-box',
    border: '1px solid #DDDBDA',
    borderRadius: '4px',
    padding: '4px',
  },

  [`& .${classes.dropboxActive}`]: {
    border: '1px solid #0076DE',
    boxShadow: '0 0 3px 0 rgba(0,118,222,0.5)',
  },

  [`& .${classes.fileRow}`]: {
    display: 'flex',
    margin: '4px 0px',
  },

  [`& .${classes.buttonText}`]: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    letterSpacing: '0.25px',
    marginLeft: '8px',
    textTransform: 'none',
  },

  [`& .${classes.dropText}`]: {
    margin: '8px',
    fontSize: '0.75rem',
  },

  [`& .${classes.fileIcon}`]: {
    color: theme.palette.surface.grey,
  },

  [`& .${classes.disabled}`]: {
    color: '#999',
  },

  [`& .${classes.dropboxDivideContainer}`]: {
    alignItems: 'center',
    marginTop: '12px',
  },

  [`& .${classes.dropboxDivider}`]: {
    color: theme.palette.divider,
  },

  [`& .${classes.dropboxDivideText}`]: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '1.25px',
    lineHeight: '1rem',
    textAlign: 'center',
  },
}));

function SamlIdpSettings({configurationId}) {
  const dispatch = useDispatch();
  const {setPendingChanges} = usePendingChanges();
  const {successSnackbar} = useSnackbar();

  const [signInUrl, setSignInUrl] = useState('');
  const [idpCertificate, setIdpCertificate] = useState('');

  const [inputModified, setInputModified] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const {mutate: updateSamlConfig} = useUpdateSamlConfig(configurationId, {
    onMutate: () => {
      setIsSaving(true);
      setEditMode(false);
    },
    onSuccess: () => {
      setInputModified(false);
      dispatch(fetchSamlConfigSettings());
      successSnackbar('SSO IDP settings successfully saved!');
      setPendingChanges(false);
    },
    onError: (err) => {
      dispatch(handleApiError(err));
    },
    onSettled: () => {
      setIsSaving(false);
    },
  });

  const samlConfig = useSelector((state) => {
    const {data} = state.data.dataByResource[SAML_CONFIGURATION] || {data: {}};
    if (!isEmpty(data)) {
      const samlConfiguration = data[configurationId];
      return samlConfiguration;
    }
    return {};
  });

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );

  const clearAllFields = () => {
    setSignInUrl('');
    setIdpCertificate('');
  };

  const loadInput = () => {
    if (isEmpty(samlConfig)) {
      clearAllFields();
      setInputModified(false);
    } else {
      setSignInUrl(samlConfig.signInUrl || '');
      setIdpCertificate(samlConfig.idpCertificate || '');
      setInputModified(false);
      setEditMode(!samlConfig.signInUrl);
    }
  };

  const [validIdpSetting, setValidIdpSetting] = useState(false);

  const handleEditClick = () => {
    setEditMode(true);
    return true;
  };

  const handleCancelUpdateClick = () => {
    setEditMode(false);
    loadInput();
    return true;
  };

  const saveButtonEnabled = () => {
    if (!validIdpSetting) {
      return false;
    }
    if (!inputModified) {
      return false;
    }
    if (isSaving) {
      return false;
    }
    if (configurationId) {
      if (!editMode) {
        return false;
      }
    }
    return true;
  };

  const saveConfiguration = async () => {
    const data = {
      signInUrl,
      idpCertificate,
    };
    updateSamlConfig(data);
  };

  useEffect(() => {
    if (deploymentId) {
      loadInput();
    }
  }, [deploymentId]);

  useEffect(() => {
    loadInput();
  }, [configurationId, JSON.stringify(samlConfig)]);

  useEffect(() => {
    const compareSignInUrl = samlConfig.signInUrl || '';
    const compareIdpCert = samlConfig.idpCertificate || '';

    const modified =
      !(signInUrl === compareSignInUrl && idpCertificate === compareIdpCert) &&
      editMode;
    setInputModified(modified);
    // Put the IDP settings page on update mode if settings are empty
    // It avoids the user having to click "edit" for an empty configuration
    if (!isEmpty(configurationId) && isEmpty(samlConfig.signInUrl)) {
      setEditMode(true);
    }

    setPendingChanges(modified);
  }, [signInUrl, idpCertificate, editMode, JSON.stringify(samlConfig)]);

  return (
    <StyledGrid container className={classes.root}>
      {configurationId && (
        <>
          <SamlIdpSettingsInput
            idpCertificate={idpCertificate}
            signInUrl={signInUrl}
            setIdpCertificate={setIdpCertificate}
            setSignInUrl={setSignInUrl}
            setIsValid={setValidIdpSetting}
            disabled={!editMode}
            labelOnField={false} // set as false for now since other tabs (Configuration Info, IDP Settings, etc) have same style for label
          />

          <Grid
            container
            direction="row-reverse"
            justifyContent="space-between"
            className={classes.gridItem}
          >
            <span>
              <SaveButton
                disabled={!saveButtonEnabled()}
                onClick={saveConfiguration}
                saving={isSaving}
              />
              {!editMode && configurationId && (
                <Button
                  data-testid="edit-button"
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={handleEditClick}
                  disabled={isSaving}
                >
                  Edit
                </Button>
              )}

              {editMode && (
                <Button
                  data-testid="cancel-button"
                  variant="outlined"
                  color="primary"
                  className={classes.cancelButton}
                  onClick={handleCancelUpdateClick}
                >
                  Cancel
                </Button>
              )}
            </span>
          </Grid>
        </>
      )}
    </StyledGrid>
  );
}

SamlIdpSettings.propTypes = {
  configurationId: PropTypes.string.isRequired,
};

export default SamlIdpSettings;
