import {useDispatch} from 'react-redux';

import {post} from 'api/Api';
import msLogo from 'icons/Microsoft_logo.svg';
import {saveVariable} from 'redux/actions/dataActions';
import {openErrorDialog} from 'redux/actions/errorDialogActions';
import {push} from 'redux/ReduxHistory';
import {defaultLoginError, popupError} from 'utils/constants';
import CasmCookies from 'utils/cookies';
import {setLastLoginDate} from 'utils/utils';
import {
  getMsalInstance,
  requestObj,
  requiresInteraction,
} from '../../msal/msal.request';
import LoginButton from './LoginButton';

function MicrosoftLoginButton() {
  const dispatch = useDispatch();

  // Catch MSAL errors if user has all cookies blocked
  let msalInstance;
  try {
    msalInstance = getMsalInstance();
  } catch (err) {
    console.log('MSAL instantiation failed:', err);
  }

  const getLoginErrorMessage = (err) => {
    const {errorCode} = err || {};
    let message = '';

    if (typeof errorCode !== 'undefined') {
      // check 'errorCode' exists (isn't undefined / won't throw an error)
      if (errorCode === 'popup_window_error') {
        message = popupError;
        return message;
      }
    }
    message = defaultLoginError;
    return message;
  };

  const acquireToken = async () => {
    let tokenResponse = null;

    try {
      tokenResponse = await msalInstance.acquireTokenSilent(requestObj);
    } catch (error) {
      if (requiresInteraction(error.errorCode)) {
        tokenResponse = await msalInstance.acquireTokenPopup(requestObj);
      } else {
        throw error;
      }
    }

    return tokenResponse;
  };

  const microsoftLogin = async () => {
    try {
      await msalInstance.loginPopup(requestObj);
      dispatch(saveVariable('loginLoading', true));

      const tokenResponse = await acquireToken();

      const camResponse = await post({
        path: 'auth/aadsignin',
        headers: {
          authorization: tokenResponse.accessToken,
        },
      });

      const {token: authToken} = camResponse.data;
      CasmCookies.setAuthToken(authToken);
      setLastLoginDate();
      dispatch(push('/app'));
    } catch (error) {
      if (error.message !== 'User cancelled the flow.') {
        const errorMessage = getLoginErrorMessage(error);
        dispatch(openErrorDialog('Login Error', errorMessage));
      }
    }
    dispatch(saveVariable('loginLoading', false));
  };

  return (
    <LoginButton
      idp="azure"
      onClick={microsoftLogin}
      iconSrc={msLogo}
      text="Sign in with Microsoft"
    />
  );
}

export default MicrosoftLoginButton;
