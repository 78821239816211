import {useState} from 'react';
import {styled} from '@mui/material/styles';
import {useDropzone} from 'react-dropzone';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Grid, Typography, ButtonBase} from '@mui/material';

import {ReactComponent as CloudIcon} from 'icons/upload-cloud.svg';

const PREFIX = 'FileDropBox';

const classes = {
  dropbox: `${PREFIX}-dropbox`,
  dropboxActive: `${PREFIX}-dropboxActive`,
  buttonText: `${PREFIX}-buttonText`,
  dropText: `${PREFIX}-dropText`,
  extensions: `${PREFIX}-extensions`,
  acceptedFile: `${PREFIX}-acceptedFile`,
  successColor: `${PREFIX}-successColor`,
  errorColor: `${PREFIX}-errorColor`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.dropbox}`]: {
    width: '100%',
    height: '136px',
    boxSizing: 'border-box',
    border: '1px dashed #DDDBDA',
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    position: 'relative',
    display: 'flex',
    backgroundColor: '#f8f8fb',
  },

  [`& .${classes.dropboxActive}`]: {
    border: '1px solid #0076DE',
    boxShadow: '0 0 3px 0 rgba(0,118,222,0.5)',
  },

  [`& .${classes.buttonText}`]: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    letterSpacing: '0.25px',
    marginLeft: '8px',
    textTransform: 'none',
  },

  [`& .${classes.dropText}`]: {
    marginLeft: '8px',
    fontSize: '0.75rem',
  },

  [`& .${classes.extensions}`]: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#8E93A4',
  },

  [`& .${classes.acceptedFile}`]: {
    // TODO: define styles for base file text
  },

  [`& .${classes.successColor}`]: {color: 'green'},
  [`& .${classes.errorColor}`]: {color: 'red'},
}));

const EIGHT_KB = 8192;

function FileDropBox({
  acceptedMimeType,
  acceptedExtensions,
  validateAcceptedFile,
  fileResultCallback,
  onAcceptedFileError,
  disabled,
}) {
  const [fileError, setFileError] = useState('');

  const acceptedFileTypes = {
    [acceptedMimeType]: acceptedExtensions,
  };

  const onDropAccepted = (acceptedFiles) => {
    let fileName = '';
    const reader = new window.FileReader();
    reader.onerror = () => {
      onAcceptedFileError();
    };
    reader.onload = () => {
      const error = validateAcceptedFile(reader.result);
      if (error) {
        fileResultCallback('');
        setFileError(error);
      } else {
        fileResultCallback(reader.result, fileName);
      }
    };
    acceptedFiles.forEach((file) => {
      fileName = file.name;
      setFileError('');
      reader.readAsText(file);
    });
  };

  const onDropRejected = (rejectedFiles) => {
    let rejectReason;
    if (rejectedFiles.length > 1) {
      rejectReason =
        'Multiple files not supported. Upload only individual files.';
    } else {
      rejectedFiles.map((file) => {
        if (file.size > EIGHT_KB) {
          rejectReason =
            'The file you are trying to upload exceeds the maximum file size allowed.' +
            ' Please select a file that is smaller than 8KB and try again';
        } else if (file?.errors?.length === 1) {
          if (file.errors[0].code === 'file-invalid-type') {
            rejectReason = `This file type is invalid, try again with a file using one of the following extensions: ${acceptedExtensions.join(
              ', '
            )}`;
          } else {
            rejectReason = file?.errors[0].message;
          }
        } else {
          rejectReason = 'Unexpected error, please try again later.';
        }
        return true;
      });
    }
    fileResultCallback('');
    setFileError(rejectReason);
  };

  const {
    getRootProps,
    getInputProps,
    open: openFileViewer,
    isDragActive,
  } = useDropzone({
    accept: acceptedFileTypes,
    maxSize: EIGHT_KB,
    multiple: false,
    noClick: true,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <Root>
      <div
        className={classNames(classes.dropbox, {
          [classes.dropboxActive]: isDragActive,
        })}
        style={{cursor: disabled ? 'not-allowed' : 'pointer'}}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Grid
          container
          alignItems="center"
          direction="column"
          justifyContent="center"
          onClick={disabled ? null : openFileViewer}
        >
          <Grid item>
            <CloudIcon />
          </Grid>
          <Grid item>
            <Typography className={classes.dropText}>
              Drag & drop or{' '}
              <ButtonBase
                className={classes.dropText}
                style={{
                  color: disabled ? '#000' : '#245FB2',
                  cursor: disabled ? 'not-allowed' : 'pointer',
                  margin: '0',
                  top: '-1px',
                }}
              >
                choose file
              </ButtonBase>{' '}
              to upload
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.extensions}>
              {acceptedExtensions.join(', ')}
            </Typography>
          </Grid>
        </Grid>
      </div>
      {
        /* Show reason why file upload failed */
        fileError && (
          <Typography
            className={classNames(classes.acceptedFile, classes.errorColor)}
          >
            {fileError}
          </Typography>
        )
      }
    </Root>
  );
}

FileDropBox.propTypes = {
  acceptedMimeType: PropTypes.string.isRequired,
  acceptedExtensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  fileResultCallback: PropTypes.func,
  // Should return string with message on validation error
  // Should return null or empty string on validation success
  validateAcceptedFile: PropTypes.func,
  onAcceptedFileError: PropTypes.func,
  disabled: PropTypes.bool,
};

FileDropBox.defaultProps = {
  fileResultCallback: () => {},
  validateAcceptedFile: () => {},
  onAcceptedFileError: () => {},
  disabled: false,
};

export default FileDropBox;
