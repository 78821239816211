import {useQuery} from 'react-query';
import {get} from 'api/Api';
import config from '../config';

// Placing these helper functions in class help with mocking
class RsmHelper {
  /**
   * Given a list of rsm devices, filter out the ones that are already added to AWM, and return the rest
   * @param {string} deploymentId
   * @param {[RsmDevice]} rsmDevices
   * @returns
   */
  static _filterDeviceByNotAdded = async (deploymentId, rsmDevices) => {
    if (rsmDevices.length === 0) {
      return [];
    }
    const resp = await get({
      path: 'machines',
      params: {
        deploymentId,
        deviceId: rsmDevices.map((device) => device.deviceId).join(','),
        limit: rsmDevices.length,
      },
    });
    const awmMachines = resp.data;
    const deviceNotInAwm = (device) =>
      awmMachines.find((machine) => machine.deviceId === device.deviceId) ===
      undefined;
    return rsmDevices.filter((device) => deviceNotInAwm(device));
  };

  static fetchAllNotAddedRsmDevices = async (deploymentId) => {
    if (!config.RSM_SUPPORTED) {
      return [];
    }

    let output = [];
    let haveNextPage = true;
    let currentOffset = 0;
    const pageLimit = 100; // This cannot go higher than 100 due to API restriction.
    while (haveNextPage) {
      // eslint-disable-next-line no-await-in-loop
      const resp = await get({
        path: 'machines/cloudproviders/rsm/workstations',
        params: {deploymentId, offset: currentOffset, limit: pageLimit},
      });
      output = output.concat(resp.data);

      if (
        currentOffset + pageLimit >= resp.total ||
        resp.data.length < pageLimit
      ) {
        haveNextPage = false;
      }
      currentOffset += pageLimit;
    }

    // filter by device that are already added to awm
    let filteredDevices = [];
    for (let index = 0; index < output.length; index += pageLimit) {
      const deviceSubset = output.slice(index, index + pageLimit);
      filteredDevices = filteredDevices.concat(
        // eslint-disable-next-line no-await-in-loop
        await RsmHelper._filterDeviceByNotAdded(deploymentId, deviceSubset)
      );
    }
    return filteredDevices;
  };
}

const useQueryRsmDevices = (deploymentId, options = {}) =>
  useQuery(
    ['rsm-devices', deploymentId],
    () => RsmHelper.fetchAllNotAddedRsmDevices(deploymentId),
    {
      // This ensure no caching is performed, since the result can change base on user action.
      // Alternatively, when a rsm machine is added/removed, the cache can be updated/invalidated.
      cacheTime: 0,
      ...options,
    }
  );

export {RsmHelper, useQueryRsmDevices};
