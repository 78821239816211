import config from 'config';
import {CLEAR_STORE} from 'redux/actions/globalActions';
import {MOVE_COLUMN, TOGGLE_COLUMN} from 'redux/actions/tableColumnActions';
import {
  ACTIVITY_LOGS,
  AD_USERS,
  COLUMN_TOOLTIP_CURRENT_USER,
  COLUMN_TOOLTIP_MANAGED,
  COLUMN_TOOLTIP_PCOIP_SESSION_STATE,
  COLUMN_TOOLTIP_VALIDATE_CERTIFICATE,
  COLUMN_TOOLTIP_SESSIONS_USERS,
  COLUMN_TOOLTIP_REQUIRES_MONITOR,
  CONNECTORS,
  DEPLOYMENTS,
  ENROLLMENT_COMMANDS,
  MONITOR_ENROLLMENTS,
  POOLS,
  POOL_GROUPS,
  POOL_MACHINES,
  POOL_USERS,
  REMOTE_WORKSTATIONS,
  SESSION_AUDIT,
  COLUMN_TOOLTIP_SESSIONS_EVENTS,
  WEBHOOKS,
  COLUMN_TOOLTIP_WEBHOOKS_STATUS,
  COLUMN_TOOLTIP_WEBHOOKS_CERT_STATUS,
} from 'utils/constants';

const isConnectorWizardEnabled = config.isConnectorWizardEnabled();

export const deploymentColumns = {
  deploymentName: {
    id: 'deploymentName',
    label: 'Name',
    type: 'name-link',
    enabled: true,
    configurable: false,
  },
  deploymentId: {
    id: 'deploymentId',
    label: 'Deployment ID',
    enabled: true,
    configurable: true,
  },
  createdOn: {
    id: 'createdOn',
    label: 'Created On',
    type: 'date',
    enabled: false,
    configurable: true,
  },
};

export const connectorsColumns = {
  connectorName: {
    id: 'connectorName',
    label: 'Name',
    type: 'name-link',
    enabled: true,
    configurable: false,
    sortable: true,
    sortId: 'connectorName',
    index: 0,
  },
  ...(isConnectorWizardEnabled && {
    connectorStatus: {
      id: 'connectorStatus',
      label: 'Status',
      enabled: true,
      configurable: false,
      type: 'connector-status',
      index: 1,
    },
  }),
  ...(!isConnectorWizardEnabled && {
    connectorHealth: {
      id: 'connectorHealth',
      label: 'Health',
      enabled: true,
      configurable: true,
      type: 'connector-health',
      index: 2,
    },
  }),
  certExpiry: {
    id: 'certExpiry',
    label: 'Certificate Expiry',
    enabled: true,
    configurable: true,
    type: 'cert-expiry',
    index: 3,
  },
  webhook: {
    id: 'webhooks',
    label: 'Webhooks',
    type: 'webhook-status',
    enabled: true,
    configurable: true,
    headerToolTip: COLUMN_TOOLTIP_WEBHOOKS_STATUS,
    index: 4,
  },
  ...(isConnectorWizardEnabled && {
    authentication: {
      id: 'displayAuthentication',
      label: 'Authentication',
      enabled: true,
      configurable: true,
      index: 5,
    },
  }),
  configService: {
    id: 'configService',
    label: 'Configuration Service',
    type: 'config-service-status',
    enabled: true,
    configurable: true,
    index: 6,
  },
  internalIp: {
    id: 'internalIp',
    label: 'Internal IP',
    enabled: true,
    configurable: true,
    index: 7,
  },
  externalIp: {
    id: 'externalIp',
    label: 'External IP',
    enabled: true,
    configurable: true,
    index: 8,
  },
  createdOn: {
    id: 'createdOn',
    label: 'Created On',
    type: 'date',
    enabled: false,
    configurable: true,
    sortable: true,
    sortId: 'createdOn',
    index: 9, // , tooltip: 'custom tooltip message'
  },
  updatedOn: {
    id: 'updatedOn',
    label: 'Updated On',
    type: 'date',
    enabled: false,
    configurable: true,
    sortable: true,
    sortId: 'updatedOn',
    index: 10,
  },
  version: {
    id: 'version',
    label: 'Version',
    type: 'number',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'version',
    index: 11,
  },
};

export const remoteWorkstationColumns = {
  detailedError: {
    id: 'detailedError',
    label: 'Status',
    type: 'icon',
    enabled: true,
    configurable: false,
    sortId: 'detailedError',
    index: 0,
    centered: true,
    sortable: true,
  },
  displayMachineName: {
    id: 'displayMachineName',
    label: 'Name',
    type: 'name-link',
    enabled: true,
    configurable: false,
    sortable: true,
    sortId: 'machineName',
    index: 1,
  },
  displayManaged: {
    id: 'displayManaged',
    label: 'Managed',
    enabled: false,
    configurable: true,
    sortable: true,
    sortId: 'managed',
    headerToolTip: COLUMN_TOOLTIP_MANAGED,
    index: 2,
  },
  displayProvider: {
    id: 'displayProvider',
    label: 'Provider',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'provider',
    index: 3,
  },
  powerStatus: {
    id: 'powerStatus',
    label: 'Power Status',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'powerState',
    index: 4,
  },
  createdOn: {
    id: 'createdOn',
    label: 'Created On',
    type: 'date',
    enabled: false,
    configurable: true,
    sortable: true,
    sortId: 'createdOn',
    index: 5,
  },
  updatedOn: {
    id: 'updatedOn',
    label: 'Updated On',
    type: 'date',
    enabled: false,
    configurable: true,
    sortable: true,
    sortId: 'updatedOn',
    index: 6,
  },
  entitledUsers: {
    id: 'entitledUsers',
    label: 'Users',
    type: 'node',
    fixedWidth: true,
    enabled: false,
    configurable: true,
    index: 7,
  },
  sessions: {
    id: 'sessions',
    label: 'Sessions',
    fixedWidth: true,
    enabled: true,
    configurable: true,
    index: 8,
  },
  associatedPool: {
    id: 'associatedPool',
    label: 'Associated Pool',
    type: 'string',
    fixedWidth: true,
    enabled: false,
    configurable: true,
    index: 9,
  },
};

if (config.isMonitorEnabled()) {
  remoteWorkstationColumns.awmMonitorStatus = {
    id: 'awmMonitorStatus',
    label: 'Monitor Status',
    fixedWidth: true,
    enabled: false,
    configurable: true,
    index: 10,
  };
  remoteWorkstationColumns.pcoipAgentStatus = {
    id: 'pcoipAgentStatus',
    label: 'PCoIP Agent Status',
    fixedWidth: true,
    headerToolTip: COLUMN_TOOLTIP_REQUIRES_MONITOR,
    enabled: false,
    configurable: true,
    index: 11,
  };
}

export const poolsColumns = {
  poolName: {
    id: 'poolName',
    label: 'Name',
    type: 'name-link',
    enabled: true,
    configurable: false,
    sortable: false,
    sortId: 'poolName',
    index: 0,
  },
  assignmentPolicy: {
    id: 'assignmentPolicy',
    label: 'Assignment Policy',
    type: 'node',
    enabled: true,
    configurable: false,
    sortable: false,
    index: 1,
  },
};

export const poolMachinesColumns = {
  status: {
    id: 'detailedError',
    label: 'Status',
    type: 'icon',
    enabled: true,
    configurable: false,
    sortId: 'detailedError',
    index: 0,
    centered: true,
    sortable: true,
  },
  machineName: {
    id: 'machineName',
    label: 'Name',
    type: 'name-link',
    enabled: true,
    configurable: false,
    sortable: false,
    sortId: 'machineName',
    index: 1,
  },
  displayManaged: {
    id: 'displayManaged',
    label: 'Managed',
    enabled: false,
    configurable: true,
    sortable: false,
    sortId: 'managed',
    headerToolTip: COLUMN_TOOLTIP_MANAGED,
    index: 2,
  },
  displayProvider: {
    id: 'displayProvider',
    label: 'Provider',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'provider',
    index: 3,
  },
  powerStatus: {
    id: 'powerStatus',
    label: 'Power Status',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'powerState',
    index: 4,
  },
  pcoipSessionState: {
    id: 'pcoipSessionState',
    label: 'Session State',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'sessionState',
    headerToolTip: COLUMN_TOOLTIP_PCOIP_SESSION_STATE,
    index: 5,
  },
  pcoipSessionStateLastChangedOn: {
    id: 'pcoipSessionStateLastChangedOn',
    label: 'Session State Changed On',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'sessionStateChange',
    index: 6,
  },
  createdOn: {
    id: 'createdOn',
    label: 'Created On',
    type: 'date',
    enabled: false,
    configurable: true,
    sortable: false,
    sortId: 'createdOn',
    index: 7,
  },
  updatedOn: {
    id: 'updatedOn',
    label: 'Updated On',
    type: 'date',
    enabled: false,
    configurable: true,
    sortable: false,
    sortId: 'updatedOn',
    index: 8,
  },
  msgLastReceivedOn: {
    id: 'msgLastReceivedOn',
    label: 'Last Telemetry Update',
    type: 'date',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'msgLastReceivedOn',
    index: 9,
  },
  currentUser: {
    id: 'currentUser',
    label: 'Assigned to',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'currentUser',
    headerToolTip: COLUMN_TOOLTIP_CURRENT_USER,
    index: 9,
  },
};

export const adUsersColumns = {
  name: {
    id: 'name',
    label: 'Name',
    type: 'name-link',
    enabled: true,
    configurable: false,
    sortable: true,
    sortId: 'name',
    index: 0,
  },
  nameOfUser: {
    id: 'userName',
    label: 'User Name',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'userName',
    index: 1,
  },
  userGuid: {
    id: 'userGuid',
    label: 'User GUID',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'userGuid',
    index: 2,
  },
  upn: {
    id: 'upn',
    label: 'Upn',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'upn',
    index: 3,
  },
  enabled: {
    id: 'enabled',
    label: 'Directory Status',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'enabled',
    index: 4,
  },
  groups: {
    id: 'groups',
    label: 'Groups',
    type: 'array',
    enabled: true,
    configurable: true,
    index: 5,
  },
  createdOn: {
    id: 'createdOn',
    label: 'Created On',
    type: 'date',
    enabled: false,
    configurable: true,
    sortable: true,
    sortId: 'createdOn',
    index: 6,
  },
};

export const poolUsersColumns = {
  name: {
    id: 'name',
    label: 'Name',
    type: 'name-link',
    enabled: true,
    configurable: false,
    sortable: true,
    sortId: 'name',
    index: 0,
  },
  nameOfUser: {
    id: 'userName',
    label: 'User Name',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'userName',
    index: 1,
  },
  userGuid: {
    id: 'userGuid',
    label: 'User Guid',
    type: 'string',
    enabled: true,
    configurable: false,
    sortable: false,
    sortId: 'name',
    index: 2,
  },
};

export const activityLogsColumns = {
  operation: {
    id: 'operation',
    label: 'Operation',
    type: 'string',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'operation',
    index: 0,
  },
  createdOn: {
    id: 'createdOn',
    label: 'Date',
    type: 'date',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'createdOn',
    index: 1,
  },
  upn: {
    id: 'upn',
    label: 'User',
    type: 'string',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'upn',
    index: 2,
  },
  source: {
    id: 'source',
    label: 'Source',
    type: 'string',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'source',
    index: 3,
  },
};

export const poolGroupsColumns = {
  name: {
    id: 'groupDN',
    label: 'Distinguished Name',
    type: 'string',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'groupDN',
    index: 0,
  },
  guid: {
    id: 'groupGuid',
    label: 'Group GUID',
    type: 'string',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'groupGuid',
    index: 1,
  },
};

export const EnrollmentCommandColumns = {
  keyName: {
    id: 'keyName',
    label: 'Name',
    type: 'string',
    enabled: true,
    sortable: true,
    sortId: 'keyName',
    index: 0,
  },
  action: {
    id: 'enrollmentKeyId',
    label: 'Provisioning Command',
    type: 'command-button',
    enabled: true,
    sortable: false,
    sortId: 'enrollment',
    index: 2,
  },
  createdOn: {
    id: 'createdOn',
    label: 'Created on',
    type: 'date',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'createdOn',
    index: 3,
  },
  expiresOn: {
    id: 'expiresOn',
    label: 'Expires on',
    type: 'date',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'expiresOn',
    index: 4,
  },
  lastUsed: {
    id: 'lastUsed',
    label: 'Last Used',
    type: 'date',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'lastUsed',
    index: 5,
  },
  usageCount: {
    id: 'usageCount',
    label: 'Use Count',
    type: 'string',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'usageCount',
    index: 6,
  },
  pendingApprovals: {
    id: 'pendingApprovals',
    label: 'Pending Approval',
    type: 'string',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'pendingApprovals',
    index: 7,
  },
  revokeCommmand: {
    id: 'revokeCommmand',
    label: 'Revoke',
    type: 'iconDelete',
    enabled: true,
    sortable: false,
    sortId: 'revokeCommmand',
    index: 8,
  },
};

// ENROLLMENT_COMMANDS table should only show validateCertificate column for STANDALONE
if (config.STANDALONE) {
  EnrollmentCommandColumns.validateCertificate = {
    id: 'validateCertificate',
    label: 'Validate TLS',
    type: 'tlsToggle',
    enabled: true,
    sortable: true,
    sortId: 'validateCertificate',
    headerToolTip: COLUMN_TOOLTIP_VALIDATE_CERTIFICATE,
    index: 1,
  };
}
export const pendingMonitorProvisionsColumns = {
  hostName: {
    id: 'hostName',
    label: 'Host Name',
    type: 'name-status',
    enabled: true,
    configurable: false,
    sortable: true,
    sortId: 'enrollmentDetails.hostName',
    index: 0,
  },
  ipAddress: {
    id: 'ipAddress',
    label: 'Ip Address',
    type: 'string',
    enabled: true,
    configurable: false,
    sortable: false,
    sortId: 'ipAddress',
    index: 1,
  },
  displayProvider: {
    id: 'displayProvider',
    label: 'Provider',
    type: 'string',
    enabled: true,
    configurable: false,
    sortable: false,
    sortId: 'provider',
    index: 2,
  },
  workstationName: {
    id: 'workstationName',
    label: 'Workstation',
    type: 'name-link',
    enabled: true,
    configurable: false,
    sortable: true,
    sortId: 'matchedMachineName',
    index: 3,
  },
  createdOn: {
    id: 'createdOn',
    label: 'Created',
    type: 'string',
    enabled: true,
    configurable: false,
    sortId: 'createdOn',
    index: 4,
    sortable: true,
  },
  action: {
    id: 'action',
    label: 'Actions',
    type: 'enrollment-button',
    enabled: true,
    configurable: false,
    sortId: 'action',
    index: 5,
    centered: true,
    sortable: false,
  },
};

export const sessionsColumns = {
  pcoipSessionId: {
    id: 'pcoipSessionId',
    label: 'Session ID',
    type: 'string',
    enabled: false,
    configurable: true,
    sortId: 'pcoipSessionId',
    sortable: false,
    index: 0,
  },
  userName: {
    id: 'userName',
    label: 'User',
    type: 'name-link',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'userName',
    index: 1,
  },
  userUsername: {
    id: 'userUsername',
    label: 'User Name',
    type: 'name-link',
    enabled: false,
    configurable: true,
    sortable: false,
    sortId: 'userUsername',
    index: 2,
  },
  userUpn: {
    id: 'userUpn',
    label: 'UPN',
    type: 'name-link',
    enabled: false,
    configurable: true,
    sortable: false,
    sortId: 'userUpn',
    index: 3,
  },
  userGuid: {
    id: 'userGuid',
    label: 'User Guid',
    type: 'string',
    enabled: false,
    configurable: true,
    sortable: false,
    sortId: 'userGuid',
    index: 4,
  },
  detectedUsers: {
    id: 'detectedUsers',
    label: 'Detected Users',
    headerToolTip: COLUMN_TOOLTIP_SESSIONS_USERS,
    enabled: false,
    configurable: true,
    sortable: false,
    fixedWidth: true,
    sortId: 'detectedUsers',
    index: 5,
  },
  sessionState: {
    id: 'sessionState',
    label: 'Status',
    type: 'session-status',
    enabled: true,
    configurable: true,
    sortId: 'sessionState',
    headerToolTip: COLUMN_TOOLTIP_SESSIONS_EVENTS,
    centered: false,
    width: '135px',
    sortable: false,
    index: 6,
  },
  startedOn: {
    id: 'startedOn',
    label: 'Start Time',
    type: 'date',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'startedOn',
    index: 7,
  },
  lastUpdatedOn: {
    id: 'lastUpdatedOn',
    label: 'Last Update Time',
    type: 'date',
    enabled: false,
    configurable: true,
    sortable: false,
    sortId: 'startedOn',
    index: 8,
  },
  endedOn: {
    id: 'endedOn',
    label: 'End Time',
    type: 'date',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'endedOn',
    index: 9,
  },
  approximateDurationInMs: {
    id: 'approximateDurationInMs',
    label: 'Duration',
    type: 'number',
    enabled: true,
    configurable: true,
    sortable: false,
    sortId: 'approximateDurationInMs',
    index: 10,
  },
  connectorName: {
    id: 'connectorName',
    label: 'Connector',
    type: 'name-link',
    enabled: true,
    configurable: true,
    sortId: 'connectorId',
    sortable: false,
    index: 11,
  },
  connectorId: {
    id: 'connectorId',
    label: 'Connector ID',
    type: 'name-link',
    enabled: false,
    configurable: true,
    sortId: 'connectorId',
    sortable: false,
    index: 12,
  },
  machineName: {
    id: 'machineName',
    label: 'Workstation',
    type: 'name-link',
    enabled: true,
    configurable: true,
    sortId: 'machineName',
    sortable: false,
    index: 13,
  },
  machineId: {
    id: 'machineId',
    label: 'Machine ID',
    type: 'string',
    enabled: false,
    configurable: true,
    sortable: false,
    sortId: 'machineId',
    index: 14,
  },
};

export const webhooksColumns = {
  id: {
    id: 'id',
    label: 'Webhook ID',
    type: 'string',
    enabled: false,
    configurable: true,
    sortId: 'id',
    sortable: false,
    index: 0,
  },
  name: {
    id: 'name',
    label: 'Name',
    type: 'name-link',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'name',
    index: 1,
  },
  eventType: {
    id: 'eventType',
    label: 'Trigger',
    type: 'string',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'eventType',
    index: 2,
  },
  associatedResources: {
    id: 'associatedResources',
    label: 'Associated Pool(s)',
    type: 'node',
    enabled: true,
    configurable: true,
    sortable: false,
    index: 3,
  },
  endpoint: {
    id: 'endpoint',
    label: 'URL Domain',
    type: 'string',
    enabled: true,
    configurable: true,
    sortable: true,
    sortId: 'endpoint',
    index: 4,
  },
  webhook: {
    id: 'caCertStatus',
    label: 'CA Certificate',
    type: 'webhook-status',
    enabled: true,
    configurable: true,
    headerToolTip: COLUMN_TOOLTIP_WEBHOOKS_CERT_STATUS,
    index: 5,
  },
};

const defaultState = {
  [DEPLOYMENTS]: deploymentColumns,
  [CONNECTORS]: connectorsColumns,
  [REMOTE_WORKSTATIONS]: remoteWorkstationColumns,
  [AD_USERS]: adUsersColumns,
  [POOLS]: poolsColumns,
  [ACTIVITY_LOGS]: activityLogsColumns,
  [POOL_MACHINES]: poolMachinesColumns,
  [POOL_USERS]: poolUsersColumns,
  [POOL_GROUPS]: poolGroupsColumns,
  [MONITOR_ENROLLMENTS]: pendingMonitorProvisionsColumns,
  [ENROLLMENT_COMMANDS]: EnrollmentCommandColumns,
  [SESSION_AUDIT]: sessionsColumns,
  [WEBHOOKS]: webhooksColumns,

  // ATTENTION TO THIS: when adding a news set of columns here, remember to also update the file localstorage.jsx
  // to ensure the columns will be displayed when the app reloads.
};

// shift all indexes >= newIndex up 1
// assign column with columnId an index of newIndex
function updateIndexes(columns, columnKey, columnId, newIndex) {
  const result = {};

  const dir = newIndex > columns[columnKey].index ? 'up' : 'down';

  Object.values(columns).forEach((column) => {
    let shiftedIndex = 0;

    if (column.id === columnId) {
      shiftedIndex = newIndex;
    } else if (
      dir === 'up' &&
      column.index <= newIndex &&
      column.index > columns[columnKey].index
    ) {
      // moving the column up
      shiftedIndex = column.index - 1;
    } else if (
      dir === 'down' &&
      column.index >= newIndex &&
      column.index < columns[columnKey].index
    ) {
      // moving the column down
      shiftedIndex = column.index + 1;
    } else {
      shiftedIndex = column.index;
    }
    const resultKey = column.id === 'userName' ? 'nameOfUser' : column.id;

    result[resultKey] = {
      ...column,
      index: shiftedIndex,
    };
  });
  return result;
}

function tableColumnsByResource(state, action) {
  // TSW-99772 required changing the name of the column from the adUser property name
  const columnKey =
    action.resource === 'adUsers' && action.columnId === 'userName'
      ? 'nameOfUser'
      : action.columnId;

  switch (action.type) {
    case TOGGLE_COLUMN:
      return {
        ...state,
        [columnKey]: {
          ...state[columnKey],
          enabled: !state[columnKey].enabled,
        },
      };
    case MOVE_COLUMN:
      return updateIndexes(state, columnKey, action.columnId, action.newIndex);
    default:
      return state;
  }
}

function tableColumns(state = defaultState, action) {
  switch (action.type) {
    case MOVE_COLUMN:
    case TOGGLE_COLUMN:
      return {
        ...state,
        [action.resource]: tableColumnsByResource(
          state[action.resource],
          action
        ),
      };
    case CLEAR_STORE:
      return defaultState;
    default:
      return state;
  }
}

export default tableColumns;