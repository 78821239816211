export const ADD_POLLING_ITEM = 'ADD_POLLING_ITEM';

export function addPollingItem(item) {
  return {
    type: ADD_POLLING_ITEM,
    item,
  };
}

export const REMOVE_POLLING_ITEM = 'REMOVE_POLLING_ITEM';
export const START_POLLING = 'START_POLLING';

export function startPolling() {
  return {
    type: START_POLLING,
  };
}

export const FINISH_POLLING = 'FINISH_POLLING';

export function finishPolling() {
  return {
    type: FINISH_POLLING,
  };
}

export const CLEAR_POLLING_ITEMS = 'CLEAR_POLLING_ITEMS';
export function clearPollingItems() {
  return {
    type: CLEAR_POLLING_ITEMS,
  };
}
