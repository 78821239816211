import {get} from 'api/Api';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {MACHINE_ENTITLEMENTS} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {selectSelectedDeployment} from 'utils/reduxSelectors';

const getAdUser = async (deploymentId, params) => {
  const path = mapResourceToPath(MACHINE_ENTITLEMENTS, {deploymentId});
  const resp = await get({path, params});
  return resp;
};

const useEntitlements = (params, options = {}) => {
  const {deploymentId} = useSelector(selectSelectedDeployment);
  return useQuery(
    [deploymentId, 'entitlements', params],
    () => getAdUser(deploymentId, params),
    options
  );
};

export default useEntitlements;
