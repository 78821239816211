import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {
  mapErrorTypeToErrorName,
  mapErrorArrayToIcon,
} from 'components/remoteWorkstations/utils';

const PREFIX = 'DetailedErrorCardItem';

const classes = {
  card: `${PREFIX}-card`,
  icon: `${PREFIX}-icon`,
  textContainer: `${PREFIX}-textContainer`,
  errorMessage: `${PREFIX}-errorMessage`,
  errorIconContainer: `${PREFIX}-errorIconContainer`,
  errorType: `${PREFIX}-errorType`,
  recommendedAction: `${PREFIX}-recommendedAction`,
  iconErrorContainer: `${PREFIX}-iconErrorContainer`,
  cardContentContainer: `${PREFIX}-cardContentContainer`
};

const StyledPaper = styled(Paper)(() => ({
  [`&.${classes.card}`]: {
    margin: '16px',
    minHeight: '80px',
    display: 'flex',
    position: 'relative',
  },

  [`& .${classes.icon}`]: {
    width: '36px',
    height: '36px',
    color: '#C23934',
    verticalAlign: 'middle',
  },

  [`& .${classes.textContainer}`]: {
    padding: '0 16px',
    color: '#706E6B',
    borderLeft: '1px solid #DDDBDA',
  },

  [`& .${classes.errorMessage}`]: {
    textAlign: 'justify',
    fontSize: '14px',
    lineHeight: '24px',
    width: '18rem',
    fontWeight: 500,
    margin: 0,
    color: 'rgba(0,0,0,0.87)',
  },

  [`& .${classes.errorIconContainer}`]: {
    marginLeft: '8px',
    marginRight: '8px',
  },

  [`& .${classes.errorType}`]: {
    marginRight: '16px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
    margin: 0,
    color: '#C23934',
    textAlign: 'justify',
  },

  [`& .${classes.recommendedAction}`]: {
    textAlign: 'justify',
    width: '18rem',
    fontSize: '12px',
    lineHeight: '18px',
    margin: 0,
  },

  [`& .${classes.iconErrorContainer}`]: {
    alignItems: 'center',
    justifyContent: 'left',
  },

  [`& .${classes.cardContentContainer}`]: {
    margin: '8px 0px',
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  }
}));

function DetailedErrorCardItem({errorObject}) {


  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  const Icon = mapErrorArrayToIcon([errorObject]);

  const errorMessage = errorObject.message;
  const {recommendedAction} = errorObject;
  const errorType = mapErrorTypeToErrorName(errorObject.errorType);

  return (
    <StyledPaper
      className={classes.card}
      elevation={hover ? 4 : 2}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Grid container className={classes.cardContentContainer}>
        <Grid item>
          <Grid container className={classes.iconErrorContainer}>
            <Grid item className={classes.errorIconContainer}>
              <Icon className={classes.icon} data-testid="error-card-icon" />
            </Grid>
            <Grid item>
              <p className={classes.errorType}>{errorType}</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.textContainer} item>
          <p className={classes.errorMessage}>{errorMessage}</p>
          {recommendedAction && (
            <p className={classes.recommendedAction}>{recommendedAction}</p>
          )}
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

DetailedErrorCardItem.propTypes = {
  errorObject: PropTypes.shape({
    errorType: PropTypes.string,
    message: PropTypes.string,
    recommendedAction: PropTypes.string,
  }).isRequired,
};

export default DetailedErrorCardItem;
