import {CLEAR_STORE} from 'redux/actions/globalActions';
import {
  CLOSE_SIDEBAR,
  OPEN_SIDEBAR,
  TOGGLE_SIDEBAR,
} from 'redux/actions/sidebarActions';
import {BREAKPOINT_MD} from 'utils/constants';

const defaultState = {
  open: window.innerWidth > BREAKPOINT_MD,
};

function sideBarReducer(state = defaultState, action) {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return {
        open: true,
      };
    case CLOSE_SIDEBAR:
      return {
        open: false,
      };
    case TOGGLE_SIDEBAR:
      return {
        open: !state.open,
      };
    case CLEAR_STORE:
      return defaultState;
    default:
      return state;
  }
}

export default sideBarReducer;
