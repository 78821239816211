import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {downloadTableData} from 'redux/actions/tableDataActions';
import {selectVariable} from 'utils/reduxSelectors';

function DownloadButton({resource}) {
  const dispatch = useDispatch();
  const downloadingTableData = useSelector((state) =>
    selectVariable(state, 'downloadingTableData')
  );

  return (
    <Button
      onClick={() => {
        dispatch(downloadTableData(resource));
      }}
      disabled={downloadingTableData}
    >
      Download CSV
    </Button>
  );
}

DownloadButton.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default DownloadButton;
