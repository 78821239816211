export const OPEN_VIEW_MACHINE_ASSIGNMENT_DIALOG =
  'OPEN_VIEW_MACHINE_ASSIGNMENT_DIALOG';
export const CLOSE_VIEW_MACHINE_ASSIGNMENT_DIALOG =
  'CLOSE_VIEW_MACHINE_ASSIGNMENT_DIALOG';

export function closeViewMachineAssignmentDialog() {
  return {
    type: CLOSE_VIEW_MACHINE_ASSIGNMENT_DIALOG,
  };
}
