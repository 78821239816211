import {useState} from 'react';

function useNameValidation(minLength = 2, maxLength = 32) {
  const [nameValidationError, setNameValidationError] = useState(false);

  const validateName = (value) => {
    const regex = /[`~!@#$%^&*()|+=÷¿?;:'",.<>{}[\]\\/]/;
    setNameValidationError(
      regex.test(value) || value.length < minLength || value.length > maxLength
    );
  };

  const nameErrorText = `Length must be between ${minLength} and ${maxLength} characters. The following characters are not allowed: ~!@#$%^&*()|+=÷¿?;:"',.<>{}[]/`;
  return [nameValidationError, validateName, nameErrorText];
}

export default useNameValidation;
