import Button from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const PREFIX = 'IconButton';

const classes = {
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`
};

const StyledButton = styled(Button)(() => ({
  [`&.${classes.button}`]: {
    padding: '2px',
  },

  [`& .${classes.icon}`]: {
    fontSize: '32px',
  }
}));

function IconButton({
  // Icon must be a Material UI icon object
  icon,
  onClick,
  disabled,
  dataTestId,
}) {

  const ProvidedIcon = icon;
  return (
    <StyledButton
      data-testid={dataTestId || `icon-button-${icon.displayName}`}
      className={classNames(classes.button)}
      onClick={onClick}
      color="primary"
      disabled={disabled}
    >
      <ProvidedIcon className={classes.icon} />
    </StyledButton>
  );
}

IconButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
};

IconButton.defaultProps = {
  disabled: false,
  icon: null,
  dataTestId: null,
};

export default IconButton;
