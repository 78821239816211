import propTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import SvgIcon from '@mui/material/SvgIcon';

import GridN from 'components/CAM/layout/GridN/GridN';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';

const PREFIX = 'ConfigureStepCard';

const classes = {
  container: `${PREFIX}-container`,
  card: `${PREFIX}-card`,
  cardGridContainer: `${PREFIX}-cardGridContainer`,
  activeCardIndicator: `${PREFIX}-activeCardIndicator`,
  activeCardTitleSpacer: `${PREFIX}-activeCardTitleSpacer`,
  inactiveCardTitleSpacer: `${PREFIX}-inactiveCardTitleSpacer`,
  activeCardTitleText: `${PREFIX}-activeCardTitleText`,
  inactiveCardTitleText: `${PREFIX}-inactiveCardTitleText`,
  svgText: `${PREFIX}-svgText`,
  optionalDivider: `${PREFIX}-optionalDivider`,
  activeOptionalDivider: `${PREFIX}-activeOptionalDivider`,
  inactiveOptionalDivider: `${PREFIX}-inactiveOptionalDivider`,
  stepTextRow: `${PREFIX}-stepTextRow`,
  inactiveStepText: `${PREFIX}-inactiveStepText`,
  activeStepText: `${PREFIX}-activeStepText`,
  buttonRow: `${PREFIX}-buttonRow`,
  buttonMargin: `${PREFIX}-buttonMargin`,
};

const baseTitleText = {
  fontStyle: 'normal',
  letterSpacing: '0.5px',
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.container}`]: {marginTop: '16px'},

  [`& .${classes.card}`]: {
    padding: '24px',
    width: '1000px',
  },

  [`& .${classes.cardGridContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  [`& .${classes.activeCardIndicator}`]: {
    width: '64px',
    height: '64px',
    background: '#FEE0B8',
  },

  [`& .${classes.activeCardTitleSpacer}`]: {marginLeft: '24px'},
  [`& .${classes.inactiveCardTitleSpacer}`]: {marginLeft: '8px'},

  [`& .${classes.activeCardTitleText}`]: {
    ...baseTitleText,
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px',
  },

  [`& .${classes.inactiveCardTitleText}`]: {
    ...baseTitleText,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },

  [`& .${classes.svgText}`]: {
    fill: '#fff',
    fontSize: '0.75rem',
  },

  [`& .${classes.optionalDivider}`]: {
    width: '1px',
    height: '20px',
    margin: '0px 8px',
  },

  [`& .${classes.activeOptionalDivider}`]: {background: '#2B6586'},
  [`& .${classes.inactiveOptionalDivider}`]: {background: '#B9DDFF'},
  [`& .${classes.stepTextRow}`]: {display: 'flex'},
  [`& .${classes.inactiveStepText}`]: {color: 'rgba(0, 0, 0, 0.6)'},
  [`& .${classes.activeStepText}`]: {color: '#262626'},

  [`& .${classes.buttonRow}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '24px',
  },

  [`& .${classes.buttonMargin}`]: {margin: '0px 12px'},
}));
// copied from connector/ConfigureSetupCard, with some slight difference
// Display save button on final step
// continue button name can be renamed (Ex. Save/Confirm)
// Cancel, save, skip button can be hidden (Removed logic to hide these button on last step)
function ConfigureStepCard({
  stepIndex,
  totalSteps,
  title,
  optional,
  open,
  children,
  onTitleClick,
  complete,
  onContinueClick,
  continueDisabled,
  onCancelClick,
  onSkipClick,
  cancelBtnName,
  continueBtnName,
  hideContinue,
  hideCancel,
  hideSkip,
}) {
  const isLastStep = stepIndex === totalSteps - 1;

  const activeCardTitle = (
    <div>
      {
        // <div className={classes.activeCardIndicator} />
      }
      <div className={classes.activeCardTitleSpacer} />
      <GridN singleColumn>
        <div
          className={classNames(classes.stepTextRow, classes.activeStepText)}
        >
          <Typography variant="body1">
            Step&nbsp;
            {stepIndex + 1}
            &nbsp;of&nbsp;
            {totalSteps}
          </Typography>
          {optional && (
            <>
              <div
                className={classNames(
                  classes.optionalDivider,
                  classes.activeOptionalDivider
                )}
              />
              <Typography variant="body1">Optional</Typography>
            </>
          )}
        </div>
        <Typography
          variant="h5"
          component="h2"
          className={classes.activeCardTitleText}
        >
          {title}
        </Typography>
      </GridN>
    </div>
  );

  const inactiveCardTitle = (
    <>
      <SvgIcon htmlColor={complete ? '#169F67' : '#5B6071'}>
        <circle cx="12" cy="12" r="12" />
        <text className={classes.svgText} x="12" y="16" textAnchor="middle">
          {stepIndex + 1}
        </text>
      </SvgIcon>
      <div className={classes.inactiveCardTitleSpacer} />
      <GridN singleColumn>
        <div
          className={classNames(classes.stepTextRow, classes.inactiveStepText)}
        >
          <Typography variant="body1">
            Step&nbsp;
            {stepIndex + 1}
            &nbsp;of&nbsp;
            {totalSteps}
          </Typography>
          {optional && (
            <>
              <div
                className={classNames(
                  classes.optionalDivider,
                  classes.inactiveOptionalDivider
                )}
              />
              <Typography variant="body1">Optional</Typography>
            </>
          )}
        </div>
        <Typography
          variant="h6"
          component="h2"
          className={classes.inactiveCardTitleText}
        >
          {title}
        </Typography>
      </GridN>
    </>
  );

  return (
    <Root className={classes.container}>
      <Paper className={classes.card} variant="outlined" onClick={onTitleClick}>
        <div className={classes.cardGridContainer}>
          {open ? activeCardTitle : inactiveCardTitle}
        </div>
      </Paper>
      {open && (
        <Paper className={classes.card} variant="outlined">
          {children}
          <div className={classes.buttonRow}>
            {!hideContinue && (
              <CAMButton
                onClick={onContinueClick}
                buttonText={continueBtnName}
                color="primary"
                customClass={classes.buttonMargin}
                disabled={continueDisabled}
              />
            )}
            {optional && !hideSkip && (
              <CAMButton
                onClick={onSkipClick}
                buttonText="Skip this step"
                color="primary"
                variant="contained"
                customClass={classes.buttonMargin}
              />
            )}
            {!hideCancel && (
              <CAMButton
                onClick={onCancelClick}
                buttonText={cancelBtnName}
                variant="text"
                customClass={classes.buttonMargin}
              />
            )}
          </div>
        </Paper>
      )}
    </Root>
  );
}

ConfigureStepCard.propTypes = {
  stepIndex: propTypes.number,
  totalSteps: propTypes.number,
  title: propTypes.string,
  optional: propTypes.bool,
  open: propTypes.bool,
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.arrayOf(propTypes.node),
  ]),
  onTitleClick: propTypes.func,
  complete: propTypes.bool,
  onContinueClick: propTypes.func,
  continueDisabled: propTypes.bool,
  onCancelClick: propTypes.func,
  onSkipClick: propTypes.func,
  cancelBtnName: propTypes.string,
  continueBtnName: propTypes.string,
  hideContinue: propTypes.bool,
  hideCancel: propTypes.bool,
  hideSkip: propTypes.bool,
};

ConfigureStepCard.defaultProps = {
  stepIndex: 0,
  totalSteps: 1,
  title: 'Card title',
  optional: false,
  open: false,
  children: <></>,
  onTitleClick: () => {},
  complete: false,
  onContinueClick: () => {},
  continueDisabled: false,
  onCancelClick: () => {},
  onSkipClick: () => {},
  cancelBtnName: 'Cancel',
  continueBtnName: 'Continue',
  hideContinue: false,
  hideCancel: false,
  hideSkip: false,
};

export default ConfigureStepCard;
