import {styled} from '@mui/material/styles';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import Typography from '@mui/material/Typography';
import {CERTIFICATES_EXTENSIONS} from 'utils/constants';
import usePendingChanges from 'hooks/usePendingChanges';
import {validatePEM, validateCertificate} from 'utils/utils';
import {openErrorDialog} from 'redux/actions/errorDialogActions';
import FileInputDropbox from '../connectors/createWizard/FileDropBox';
import {useWizardState} from '../connectors/createWizard/wizardConfigState';

const PREFIX = 'CertificateDropbox';

const classes = {
  formWrapper: `${PREFIX}-formWrapper`,
  divider: `${PREFIX}-divider`,
  saveButton: `${PREFIX}-saveButton`,
  cancelButton: `${PREFIX}-cancelButton`,
};

const Root = styled('div')(() => ({
  margin: '20px 0',
}));

export function CertificateDropbox() {
  usePendingChanges();
  const dispatch = useDispatch();
  const [certName, setCertName] = useState('');
  const {configState, setConfigState} = useWizardState();

  const handleCertString = (file, fileName) => {
    const updatedState = {...configState};
    updatedState.CertificateDropbox = file;
    setConfigState(updatedState);
    setCertName(fileName);
  };

  const handlePemFile = (file, fileName) => {
    // We know the PEM file is a valid certificate
    // because it was validated as such during the upload
    const error = validateCertificate(file);
    if (error) {
      dispatch(
        openErrorDialog('Error', error.message || 'Invalid certificate')
      );
    } else {
      handleCertString(file, fileName);
    }
  };

  const displayPemLoadErrorMessage = (errorMessage) => {
    const defaultErrorMessage = 'Invalid PEM file';
    dispatch(
      openErrorDialog(
        'File does not contain a valid certificate',
        errorMessage || defaultErrorMessage
      )
    );
  };

  return (
    <Root>
      <Typography className={classes.subheader2} variant="h5">
        Select your CA certificate file (in PEM format)
      </Typography>

      <FileInputDropbox
        acceptedMimeType="application/x-pem-file"
        acceptedExtensions={CERTIFICATES_EXTENSIONS}
        fileResultCallback={handlePemFile}
        validateAcceptedFile={validatePEM}
        onAcceptedFileError={displayPemLoadErrorMessage}
      />
      <div>
        <Typography className={classes.bodyText}>
          <div className={classes.fileRow}>
            <ul>
              <li>Certificate file: {certName || 'not selected'}</li>
            </ul>
          </div>
        </Typography>
      </div>
    </Root>
  );
}
