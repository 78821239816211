import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment-timezone';
import {styled} from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import {useHistory} from 'react-router-dom';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import {formatDateTime, getElapsedTimeString} from 'utils/utils';
import {
  StyledTable,
  StyledTableRow,
  StyledTableCell,
} from 'components/deployments/StyledTable';
import useRoles from 'hooks/useRoles';
import RoleDialog from 'components/deployments/RoleDialog';
import {LEGACY_SA_ROLE} from 'utils/constants';
import ServiceAccountExpiryStatus from './ServiceAccountsTablExpiryStatus';

const PREFIX = 'ServiceAccountsTable';

const classes = {
  tableContainer: `${PREFIX}-tableContainer`,
  iconTextDiv: `${PREFIX}-iconTextDiv`,
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  tableRow: `${PREFIX}-tableRow`,
  removeIcon: `${PREFIX}-removeIcon`,
  noServiceAccountsBox: `${PREFIX}-noServiceAccountsBox`,
  leftAlign: `${PREFIX}-leftAlign`,
  loadingServiceAccountsBox: `${PREFIX}-loadingServiceAccountsBox`,
  vpnKeyIcon: `${PREFIX}-vpnKeyIcon`,
  noServiceAccountsFoundTitle: `${PREFIX}-noServiceAccountsFoundTitle`,
  noServiceAccountsFoundText: `${PREFIX}-noServiceAccountsFoundText`,
  inputProps: `${PREFIX}-inputProps`,
  deleteColumn: `${PREFIX}-deleteColumn`,
  fullWidth: `${PREFIX}-fullWidth`,
  rightMargin: `${PREFIX}-rightMargin`,
  greyText: `${PREFIX}-greyText`,
  toolTipIcon: `${PREFIX}-toolTipIcon`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.tableContainer}`]: {
    marginTop: '1.5rem',
  },
  [`& .${classes.iconTextDiv}`]: {
    // allow icon and text to be aligned together
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  [`& .${classes.table}`]: {},
  [`& .${classes.tableHead}`]: {
    height: '28px',
  },
  [`& .${classes.tableRow}`]: {
    height: '28px',
  },
  [`& .${classes.removeIcon}`]: {
    width: '22px',
    height: '22px',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  [`& .${classes.noServiceAccountsBox}`]: {
    backgroundColor: '#FAFAF9',
    padding: '50px 40px',
  },
  [`& .${classes.leftAlign}`]: {
    textAlign: 'left',
  },
  [`& .${classes.loadingServiceAccountsBox}`]: {
    backgroundColor: '#FAFAF9',
    padding: '50px 40px',
    textAlign: 'center',
    width: '100%',
  },
  [`& .${classes.vpnKeyIcon}`]: {
    marginRight: '10px',
    marginBottom: '-7px',
    color: '#666',
  },
  [`& .${classes.noServiceAccountsFoundTitle}`]: {
    color: '#0076A9',
    textAlign: 'center',
  },
  [`& .${classes.noServiceAccountsFoundText}`]: {
    color: '#999',
    fontFamily: 'Roboto',
    fontSize: '0.75em',
    letterSpacing: '0.4px',
    lineHeight: '16px',
  },
  [`& .${classes.inputProps}`]: {
    backgroundColor: theme.palette.surface.white,
    fontSize: '0.875rem',
    height: '2.25rem',
    width: '100%',
  },
  [`& .${classes.deleteColumn}`]: {textAlign: 'center', width: '30px'},
  [`& .${classes.fullWidth}`]: {width: '100%'},
  [`& .${classes.rightMargin}`]: {marginRight: '10px'},
  [`& .${classes.greyText}`]: {color: '#706E6B'},
  [`& .${classes.toolTipIcon}`]: {
    cursor: 'pointer',
    verticalAlign: 'text-bottom',
    overflow: 'hidden',
    height: '18px',
  },
}));

let history;

function navigateToBulkMonitorProvisioning(account) {
  if (history && account.roleName === 'Anyware Monitor Bulk Installer') {
    history.push(
      `/app/deployments/edit/${account.deploymentId}/anywareMonitor`
    );
  }
}

function ServiceAccountRow({account, onRemove, headers}) {
  const {data: roles} = useRoles();

  const [openRoleDialog, setOpenRoleDialog] = useState(false);

  function displayValue(accessKey, header) {
    switch (header.id) {
      case 'status':
        return (
          <ServiceAccountExpiryStatus expiresOnString={accessKey.expiresOn} />
        );
      case 'expiresOn':
        return formatDateTime(accessKey[header.id]);
      case 'lastSignedIn':
        // eslint-disable-next-line no-case-declarations
        const lastSignedIn = accessKey[header.id];
        if (!lastSignedIn || !moment(lastSignedIn, moment.ISO_8601).isValid()) {
          return 'N/A';
        }
        return getElapsedTimeString(new Date(lastSignedIn));
      case 'createdOn':
        return formatDateTime(accessKey[header.id]);
      case 'roleName':
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            type="button"
            data-testid={`role-${accessKey.keyId}`}
            component="button"
            className={classes.leftAlign}
            onClick={() => setOpenRoleDialog(true)}
          >
            {accessKey.roleName}
          </Link>
        );
      case 'roleType':
        if (accessKey.roleId && Array.isArray(roles)) {
          const assignedRole = roles.find(
            (role) => role.roleId === accessKey.roleId
          );
          return assignedRole?.roleType || 'built-in';
        }
        if (
          accessKey.roleName === LEGACY_SA_ROLE &&
          accessKey.roleId === undefined
        ) {
          return 'built-in';
        }
        return '';
      default:
        return accessKey[header.id];
    }
  }
  return (
    <>
      {headers.find((header) => header.id === 'roleName') !== undefined && (
        <RoleDialog
          open={openRoleDialog}
          roleId={
            account.roleId ||
            (account.roleName === LEGACY_SA_ROLE ? LEGACY_SA_ROLE : undefined)
          }
          displayOneRole
          setClosed={() => setOpenRoleDialog(false)}
        />
      )}
      <StyledTableRow
        hover
        className={classNames(classes.tableRow)}
        data-testid="service-account-row"
      >
        {headers.map((header) => (
          <StyledTableCell key={`${account.keyId}-${header.id}`}>
            {displayValue(account, header)}
          </StyledTableCell>
        ))}
        <StyledTableCell className={classNames(classes.deleteColumn)}>
          {account.roleName === 'Anyware Monitor Bulk Installer' ? (
            <CAMButton
              testId="account-manage-button"
              textOnly
              buttonText="Manage"
              onClick={() => navigateToBulkMonitorProvisioning(account)}
            />
          ) : (
            <CAMButton
              testId="account-delete-button"
              textOnly
              buttonText="Delete"
              onClick={() => onRemove(account)}
            />
          )}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}
ServiceAccountRow.propTypes = {
  account: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  headers: PropTypes.array.isRequired,
};

function ServiceAccountsTable({
  serviceAccounts,
  onRemove,
  isLoading,
  handleSearchInputChange,
  headers,
  showCreateServiceAccountTip,
}) {
  history = useHistory();

  const [openRoleDialog, setOpenRoleDialog] = useState(false);

  const tableBodyWithServiceAccounts = serviceAccounts.map((serviceAcc) => (
    <ServiceAccountRow
      account={serviceAcc}
      key={serviceAcc.keyId}
      onRemove={onRemove}
      headers={headers}
    />
  ));

  const tableBodyNoServiceAccounts = (
    <div className={classes.noServiceAccountsBox}>
      <div className={classes.noServiceAccountsFoundTitle}>
        <VpnKeyIcon className={classes.vpnKeyIcon} />
        No accounts found
      </div>
      {showCreateServiceAccountTip && (
        <Typography
          align="center"
          className={classes.noServiceAccountsFoundText}
        >
          You need to create a new account
          <br />
          by clicking the button above.
        </Typography>
      )}
    </div>
  );

  const tableBodyLoadingServiceAccounts = (
    <div className={classes.loadingServiceAccountsBox}>
      <div className={classes.noServiceAccountsFoundTitle}>
        <CircularProgress className={classes.rightMargin} size={14} />
        Loading service accounts ...
      </div>
    </div>
  );

  const accountSearchBar = (
    <TextField
      id="search-account"
      autoComplete="off"
      onChange={handleSearchInputChange}
      variant="outlined"
      placeholder="Search Account Name"
      className={classes.fullWidth}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start" className={classes.greyText}>
            <SearchIcon />
          </InputAdornment>
        ),
        className: classes.inputProps,
      }}
    />
  );

  const renderTableBody = () => {
    if (!isLoading && serviceAccounts.length > 0) {
      return tableBodyWithServiceAccounts;
    }
    return null;
  };

  const renderLoadingServiceAccounts = () => {
    if (isLoading) {
      return tableBodyLoadingServiceAccounts;
    }
    return null;
  };

  const renderNoTableBody = () => {
    if (!isLoading && !serviceAccounts.length) {
      return tableBodyNoServiceAccounts;
    }
    return null;
  };

  return (
    <Root>
      {accountSearchBar}
      <RoleDialog
        open={openRoleDialog}
        setClosed={() => {
          setOpenRoleDialog(false);
        }}
      />
      <div className={classes.tableContainer}>
        <StyledTable className={classes.table}>
          <TableHead>
            <StyledTableRow className={classes.tableHead}>
              {headers.map((header) => (
                <StyledTableCell key={header.id}>
                  {header.id === 'roleName' ? (
                    <div className={classes.iconTextDiv}>
                      Role
                      <IconButton
                        data-testid="role-helper-icon"
                        size="small"
                        disableRipple
                        onClick={() => {
                          setOpenRoleDialog(true);
                        }}
                      >
                        <InfoIcon
                          className={classes.toolTipIcon}
                          color="action"
                        />
                      </IconButton>
                    </div>
                  ) : (
                    header.label
                  )}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key="remove"
                className={classNames(classes.deleteColumn)}
              >
                Actions
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </StyledTable>
        {renderLoadingServiceAccounts()}
        {renderNoTableBody()}
      </div>
    </Root>
  );
}

ServiceAccountsTable.propTypes = {
  serviceAccounts: PropTypes.array,
  onRemove: PropTypes.func,
  handleSearchInputChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  headers: PropTypes.array,
  showCreateServiceAccountTip: PropTypes.bool,
};

ServiceAccountsTable.defaultProps = {
  serviceAccounts: [],
  onRemove: () => {},
  isLoading: false,
  headers: [
    {
      id: 'keyId',
      label: 'ID',
    },
    {
      id: 'keyName',
      label: 'Name',
    },
    {
      id: 'createdOn',
      label: 'Created on',
    },
  ],
  showCreateServiceAccountTip: true,
};

export default ServiceAccountsTable;
