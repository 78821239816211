import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {saveVariable} from 'redux/actions/dataActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {
  DEFAULT_ROWS_PER_PAGE,
  REMOTE_WORKSTATIONS,
  REMOTE_WORKSTATIONS_ENTITLEMENT_FILTER_OPTIONS,
  AWM_MONITOR_DISABLED_STATUS,
  AWM_MONITOR_HEALTHY_STATUS,
  AWM_MONITOR_UNHEALTHY_STATUS,
} from 'utils/constants';
import {
  selectVariable,
  selectTableParams,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';

const useRemoteWorkstationFilter = ({enabled, searchTerm}) => {
  const dispatch = useDispatch();
  const {deploymentId} = useSelector(selectSelectedDeployment) || {
    deploymentId: '',
  };
  const {params} = useSelector((state) =>
    selectTableParams(state, REMOTE_WORKSTATIONS)
  );
  const {page, rowsPerPage} = useSelector(
    (state) => state.tableData[REMOTE_WORKSTATIONS]
  ) || {page: 0, rowsPerPage: DEFAULT_ROWS_PER_PAGE};
  const {entitlementsFilter, awmMonitorStatusFilter} = useSelector((state) =>
    selectVariable(state, 'remoteWorkstationsTableFilter')
  ) || {
    entitlementsFilter: {enabled: false, filterType: ''},
    awmMonitorStatusFilter: {enabled: false, filterType: ''},
  };

  const shouldEntitlementFilterChange =
    entitlementsFilter.enabled && entitlementsFilter.filterType;
  const shouldAwmMonitorStatusFilterChange =
    awmMonitorStatusFilter.enabled && awmMonitorStatusFilter.filterType;

  useEffect(() => {
    if (enabled && deploymentId) {
      const newParams = {};
      if (entitlementsFilter.enabled) {
        switch (entitlementsFilter.filterType) {
          case REMOTE_WORKSTATIONS_ENTITLEMENT_FILTER_OPTIONS[0]:
            newParams.withEntitlements = false;
            break;
          case REMOTE_WORKSTATIONS_ENTITLEMENT_FILTER_OPTIONS[1]:
            newParams.withEntitlements = true;
            break;
          default:
            break;
        }
      }
      if (awmMonitorStatusFilter.enabled) {
        switch (awmMonitorStatusFilter.filterType) {
          case AWM_MONITOR_DISABLED_STATUS:
            newParams.agentMonitored = false;
            break;
          case AWM_MONITOR_HEALTHY_STATUS:
            newParams.agentMonitored = true;
            newParams.withRecentMonitorTelemetry = true;
            break;
          case AWM_MONITOR_UNHEALTHY_STATUS:
            newParams.agentMonitored = true;
            newParams.withRecentMonitorTelemetry = false;
            break;
          default:
            break;
        }
      }
      newParams.showdeleting = true;
      newParams.refreshMachineState = true;

      dispatch(
        fetchTableData(
          REMOTE_WORKSTATIONS,
          0,
          rowsPerPage,
          newParams,
          searchTerm
        )
      );
    }
  }, [
    deploymentId,
    entitlementsFilter.enabled,
    shouldEntitlementFilterChange,
    awmMonitorStatusFilter.enabled,
    shouldAwmMonitorStatusFilterChange,
    searchTerm,
  ]);

  const refresh = () => {
    dispatch(
      fetchTableData(REMOTE_WORKSTATIONS, page, rowsPerPage, params, searchTerm)
    );
  };

  const clearFilters = () => {
    dispatch(
      saveVariable('remoteWorkstationsTableFilter', {
        entitlementsFilter: {enabled: false, filterType: ''},
        awmMonitorStatusFilter: {enabled: false, filterType: ''},
      })
    );
  };

  return {
    refresh,
    clearFilters,
  };
};

export default useRemoteWorkstationFilter;
