import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {closeErrorDialog} from 'redux/actions/errorDialogActions';
import {
  CHROME_COOKIE_LINK,
  defaultLoginError,
  ERROR_STATUS,
  FIREFOX_COOKIE_LINK,
} from 'utils/constants';

const PREFIX = 'ErrorDialog';

const classes = {
  errorContainer: `${PREFIX}-errorContainer`,
  title: `${PREFIX}-title`,
  errorTitle: `${PREFIX}-errorTitle`,
  warningTitle: `${PREFIX}-warningTitle`,
  icon: `${PREFIX}-icon`,
  closeButton: `${PREFIX}-closeButton`,
  actionButton: `${PREFIX}-actionButton`,
  errorButton: `${PREFIX}-errorButton`,
  warningButton: `${PREFIX}-warningButton`,
  additionalInfo: `${PREFIX}-additionalInfo`,
};

const StyledDialog = styled(Dialog)(({theme}) => ({
  [`& .${classes.errorContainer}`]: {
    minWidth: '22.625rem',
    boxShadow: '0 2px 3px 0 rgba(0,0,0,0.28)',
  },

  [`& .${classes.title}`]: {
    alignItems: 'center',
    color: 'white',
    display: 'flex',
    height: '2.875rem',
    borderRadius: '4px 4px 0 0',
    padding: '16px 8px',
  },

  [`& .${classes.errorTitle}`]: {backgroundColor: theme.palette.error.main},
  [`& .${classes.warningTitle}`]: {
    backgroundColor: theme.palette.dialog.warning,
  },

  [`& .${classes.icon}`]: {
    margin: '0px 12px',
  },

  [`& .${classes.closeButton}`]: {
    color: theme.palette.common.white,
    marginLeft: 'auto',
  },

  [`& .${classes.actionButton}`]: {
    border: '2px solid',
    padding: '2px 8px',
    width: '5rem',
  },

  [`& .${classes.errorButton}`]: {
    borderColor: '#C23934',
    color: '#C23934',
    '&:hover': {
      backgroundColor: '#ffd4d4',
    },
  },

  [`& .${classes.warningButton}`]: {
    borderColor: theme.palette.dialog.warning,
    color: theme.palette.dialog.warning,
    '&:hover': {
      backgroundColor: 'rgba(255, 97, 37, 0.4)',
    },
  },

  [`& .${classes.additionalInfo}`]: {
    marginTop: '1rem',
  },
}));

const chromeLink = (
  <a href={CHROME_COOKIE_LINK} data-testid="chrome-cookie-link">
    {' '}
    Chrome{' '}
  </a>
);
const firefoxLink = (
  <a href={FIREFOX_COOKIE_LINK} data-testid="firefox-cookie-link">
    {' '}
    Firefox{' '}
  </a>
);

function ErrorDialog() {
  const dispatch = useDispatch();
  const {open, title, message, status} = useSelector(
    (state) => state.errorDialog
  );

  const statusIcon =
    status === ERROR_STATUS ? (
      <ErrorIcon className={classes.icon} />
    ) : (
      <WarningIcon className={classes.icon} />
    );

  if (!open) return null;

  return (
    <StyledDialog
      open={open}
      onClose={() => dispatch(closeErrorDialog())}
      maxWidth="md"
    >
      <DialogTitle
        className={classNames(
          classes.title,
          status === ERROR_STATUS ? classes.errorTitle : classes.warningTitle
        )}
      >
        {statusIcon}
        <Typography variant="h6">{title}</Typography>
        <IconButton
          className={classes.closeButton}
          size="small"
          onClick={() => dispatch(closeErrorDialog())}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography mt={2}>
          {message}
          {message === defaultLoginError && (
            <div className={classes.additionalInfo}>
              Visit the
              {chromeLink}
              or
              {firefoxLink}
              websites for more information on cookies. Otherwise, please
              consult the support website for your browser.
            </div>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="error-dialog-ok-button"
          className={classNames(
            classes.actionButton,
            status === ERROR_STATUS
              ? classes.errorButton
              : classes.warningButton
          )}
          onClick={() => dispatch(closeErrorDialog())}
        >
          OK
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default ErrorDialog;
