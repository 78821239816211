import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {Fragment} from 'react';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import config from 'config';
import lightbulbSvg from 'icons/lightbulb_24px_outlined.svg';
import AmtCredentialsBox from './AmtCredentials';
import AwsCredentialsBox from './AwsCredentials';
import AzureCredentialsBox from './AzureCredentials';
import EditGCPCredentials from './EditGCPCredentials';
import RsmCredentialBox from './RsmCredentialBox';

const PREFIX = 'EditDeploymentCloudServiceAccountsTab';

const classes = {
  disclaimerText: `${PREFIX}-disclaimerText`,
  fillSpace: `${PREFIX}-fillSpace`,
  helpfulTipContainer: `${PREFIX}-helpfulTipContainer`,
  helpfulTipIconContainer: `${PREFIX}-helpfulTipIconContainer`,
  helpfulTipText: `${PREFIX}-helpfulTipText`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.disclaimerText}`]: {
    color: theme.palette.surface.grey,
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    letterSpacing: '0.4px',
    lineHeight: '16px',
    marginLeft: '12px',
    width: '28.875rem',
  },
  [`& .${classes.fillSpace}`]: {
    flexGrow: 1,
    width: '100%',
    margin: '10px 0',
  },
  [`& .${classes.helpfulTipContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    marginTop: '4px',
  },
  [`& .${classes.helpfulTipIconContainer}`]: {
    alignItems: 'center',
    display: 'flex',
  },
  [`& .${classes.helpfulTipText}`]: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '12px',
    letterSpacing: '0.4px',
    lineHeight: '16px',
    marginLeft: '4px',
  },
}));

const TEXT_DISCLAIMER = `Service account credentials can be used by Anyware Manager
  to interact directly with remote workstations, such as for powering them on and off. `;

function EditDeploymentCloudServiceAccountsTab({
  deploymentId,
  gcpCredentials,
  isLoadingGcpCredentials,
  onGcpChange,
}) {
  const handleGcpChange = (newCredentials, id) =>
    onGcpChange(newCredentials, id);

  const cloudServiceAccountComponents = [
    {
      component: <AwsCredentialsBox deploymentId={deploymentId} />,
      key: 'aws-credentials-box',
    },
    {
      component: <AzureCredentialsBox deploymentId={deploymentId} />,
      key: 'azure-credentials-box',
    },
    {
      component: (
        <EditGCPCredentials
          handleCredentialsChangeCallback={handleGcpChange}
          gcpCredentials={gcpCredentials}
          deploymentId={deploymentId}
          credentialId={gcpCredentials.id}
          isLoading={isLoadingGcpCredentials}
        />
      ),
      key: 'gcp-credentials-box',
    },
    {
      component: <AmtCredentialsBox deploymentId={deploymentId} />,
      key: 'amt-credentials-box',
    },
    {
      component: <RsmCredentialBox deploymentId={deploymentId} />,
      key: 'rsm-credentials-box',
    },
  ];

  const renderCloudServiceAccountComponent = (item) => (
    <Fragment key={item.key}>{item.component}</Fragment>
  );

  const renderHelpfulTip = () => (
    <>
      <div className={classes.fillSpace} />
      <div className={classes.helpfulTipContainer}>
        <div className={classes.helpfulTipIconContainer}>
          <img src={lightbulbSvg} alt="" />
          <div className={classes.helpfulTipText}>Helpful tip:</div>
        </div>
        <div className={classes.disclaimerText}>{TEXT_DISCLAIMER}</div>
      </div>
    </>
  );

  return (
    <Root>
      <CAMCard>
        <GridN singleColumn>
          <SectionHeader displayText="PROVIDER SERVICE ACCOUNTS">
            {renderHelpfulTip()}
          </SectionHeader>
        </GridN>
        <GridN>
          {cloudServiceAccountComponents
            .filter((csa) => config.AMT_SUPPORTED || !csa.key.includes('amt'))
            .filter(
              (csa) =>
                (config.RSM_SUPPORTED && config.isBeta()) ||
                !csa.key.includes('rsm')
            )
            .map((item) => renderCloudServiceAccountComponent(item))}
        </GridN>
      </CAMCard>
    </Root>
  );
}

EditDeploymentCloudServiceAccountsTab.propTypes = {
  deploymentId: PropTypes.string,
  gcpCredentials: PropTypes.object,
  isLoadingGcpCredentials: PropTypes.bool,
  onGcpChange: PropTypes.func,
};

EditDeploymentCloudServiceAccountsTab.defaultProps = {
  deploymentId: '',
  gcpCredentials: {},
  isLoadingGcpCredentials: false,
  onGcpChange: () => {},
};

export default EditDeploymentCloudServiceAccountsTab;
