/* eslint-disable react/prop-types */
import {styled} from '@mui/material/styles';
import {
  Typography,
  Fade,
  Paper,
  ClickAwayListener,
  IconButton,
  Popper,
  Grid,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {StyledLabelText} from './StyledTextField';

const PREFIX = 'PayloadTooltip';

const classes = {
  noteText: `${PREFIX}-noteText`,
  tooltipContainer: `${PREFIX}-tooltipContainer`,
  tooltipHeader: `${PREFIX}-tooltipHeader`,
  title: `${PREFIX}-title`,
  closeButton: `${PREFIX}-closeButton`,
};

const Root = styled(Paper)(() => ({
  [`& .${classes.noteText}`]: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#8E93A4',
  },
  [`& .${classes.tooltipContainer}`]: {
    padding: '16px',
  },
  [`& .${classes.tooltipHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  [`& .${classes.title}`]: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '130%',
  },
  [`& .${classes.closeButton}`]: {
    padding: '0px',
    marginLeft: 'auto',
  },
}));

const CustomColorIcon = styled(InfoOutlinedIcon)({
  color: '#8E93A4',
});

export function PayloadTooltip({
  open,
  anchorRef,
  togglePopper,
  handleClosePopper,
  popperModifiers,
}) {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <StyledLabelText>
        Payload
        <Typography variant="body2" color="#8E93A4" component="span">
          {' '}
          (optional)
        </Typography>
      </StyledLabelText>
      <IconButton ref={anchorRef} onClick={togglePopper} size="small">
        <CustomColorIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="right"
        transition
        modifiers={popperModifiers}
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <Root>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <div className={classes.tooltipContainer}>
                  <div className={classes.tooltipHeader}>
                    <Typography className={classes.title}>Payload</Typography>
                    <IconButton
                      onClick={handleClosePopper}
                      className={classes.closeButton}
                      size="large"
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                  <Typography className={classes.noteText}>
                    <strong>Note:</strong> Payload must be a valid JSON object
                  </Typography>
                </div>
              </ClickAwayListener>
            </Root>
          </Fade>
        )}
      </Popper>
    </Grid>
  );
}
