import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toggleDateFilter} from 'redux/actions/dateFilterActions';
import {toggleDeploymentFilter} from 'redux/actions/deploymentFilterActions';
import {
  clearTableFetchParams,
  fetchTableData,
} from 'redux/actions/tableDataActions';
import {ACTIVITY_LOGS, DEFAULT_ROWS_PER_PAGE} from 'utils/constants';
import {
  selectSelectedDeployment,
  selectTableParams,
} from 'utils/reduxSelectors';

const useActivityLogsFilter = ({enabled, searchTerm}) => {
  const dispatch = useDispatch();
  const {params} = useSelector((state) =>
    selectTableParams(state, ACTIVITY_LOGS)
  );
  const {page, rowsPerPage} = useSelector(
    (state) => state.tableData[ACTIVITY_LOGS]
  ) || {page: 0, rowsPerPage: DEFAULT_ROWS_PER_PAGE};
  const {deploymentId} = useSelector(selectSelectedDeployment);
  const {enabled: deploymentFilterEnabled} = useSelector(
    (state) => state.deploymentFilter
  ) || {enabled: true};
  const {enabled: dateFilterEnabled, params: dateFilterParams} = useSelector(
    (state) => state.dateFilter
  ) || {enabled: false, params: {}};

  useEffect(() => {
    if (enabled) {
      const newParams = {};

      // Date filter
      if (dateFilterEnabled) {
        newParams.from = dateFilterParams?.from;
        newParams.to = dateFilterParams?.to;
      }

      // Deployment filter
      if (deploymentFilterEnabled && deploymentId) {
        newParams.deploymentId = deploymentId;
      }

      // Operation search
      if (searchTerm) {
        newParams.operation = searchTerm;
      }

      // Connector log params
      if (params.connectorId) {
        newParams.connectorId = params.connectorId;
        newParams.operation = params.operation;
        newParams.sortKey = params.sortKey;
      }

      dispatch(fetchTableData(ACTIVITY_LOGS, 0, rowsPerPage, newParams));
    }
  }, [
    deploymentId,
    deploymentFilterEnabled,
    dateFilterEnabled,
    JSON.stringify(dateFilterParams),
    JSON.stringify(params),
    searchTerm,
  ]);

  const clearFilters = () => {
    if (params.connectorId) {
      dispatch(clearTableFetchParams(ACTIVITY_LOGS));
    }

    if (!deploymentFilterEnabled) {
      dispatch(toggleDeploymentFilter());
    }

    if (dateFilterEnabled) {
      dispatch(toggleDateFilter());
    }
  };

  const refresh = () => {
    dispatch(
      fetchTableData(ACTIVITY_LOGS, page, rowsPerPage, params, searchTerm)
    );
  };

  return {
    refresh,
    clearFilters,
  };
};

export default useActivityLogsFilter;
