import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const PREFIX = 'CAMMenu';

const classes = {
  paper: `${PREFIX}-paper`
};

const StyledMenu = styled(Menu)(() => ({
  [`&.${classes.paper}`]: {
    borderRadius: '0.25em',
    boxShadow:
      '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)',
    paddingTop: 2,
    paddingBottom: 4,
  }
}));

function CAMMenu({
  anchorEl,
  children,
  testId,
  onClose,
  anchorOrigin,
  transformOrigin,
  customClass,
}) {

  return (
    <StyledMenu
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      className={classNames(classes.paper, customClass)}
      classes={{
        paper: classes.paper,
      }}
      data-testid={testId}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {children}
    </StyledMenu>
  );
}

CAMMenu.propTypes = {
  anchorEl: PropTypes.object,
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  customClass: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const buttonOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const menuOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

CAMMenu.defaultProps = {
  anchorEl: null,
  testId: 'cam-menu',
  anchorOrigin: buttonOrigin,
  transformOrigin: menuOrigin,
  customClass: {},
};

export default CAMMenu;
