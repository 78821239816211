import {
  CLOSE_DELETE_WEBHOOKS_DIALOG,
  OPEN_DELETE_WEBHOOKS_DIALOG,
} from 'redux/actions/DeleteWebhooksDialogActions';

import {CLEAR_STORE} from 'redux/actions/globalActions';

const defaultState = {open: false, webhooks: []};

function openDialog(state, action) {
  return {
    ...state,
    open: true,
    webhooks: action.webhooks,
  };
}

function closeDialog() {
  return defaultState;
}

function clearDialog() {
  return defaultState;
}

const stateChanges = {
  [OPEN_DELETE_WEBHOOKS_DIALOG]: openDialog,
  [CLOSE_DELETE_WEBHOOKS_DIALOG]: closeDialog,
  [CLEAR_STORE]: clearDialog,
};

function deleteWebhooksDialog(state = defaultState, action) {
  const stateChange = stateChanges[action.type];
  return stateChange ? stateChange(state, action) : state;
}

export default deleteWebhooksDialog;
