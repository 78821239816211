/* eslint-disable react/no-unstable-nested-components */
import Button from '@mui/material/Button';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {get, put} from 'api/Api';
import AWMBanner from 'components/CAM/display/AWMBanner/AWMBanner';
import PageHeader from 'components/CAM/layout/PageHeader/PageHeader';
import EditConnectorSettingsTab from 'components/connectors/EditConnectorSettings';
import DialogTextInput from 'components/CAM/dialogs/DialogTextInput/DialogTextInput';
import ResourceProperty from 'components/CAM/display/ResourceProperty/ResourceProperty';
import PageWrapper from 'components/CAM/layout/PageWrapper/PageWrapper';
import ResourcePropertyBar from 'components/CAM/layout/ResourcePropertyBar/ResourcePropertyBar';
import useSnackbar from 'hooks/useSnackbar';
import DateIcon from 'icons/awmDesignSystem/DateIcon';
import ExternalIpIcon from 'icons/awmDesignSystem/ExternalIpIcon';
import InternalIpIcon from 'icons/awmDesignSystem/InternalIpIcon';
import UniqueIdIcon from 'icons/awmDesignSystem/UniqueIdIcon';
import VersionIcon from 'icons/awmDesignSystem/VersionIcon';
import {ReactComponent as HealthStatusIcon} from 'icons/health_status.svg';
import {useMqttConnector} from 'mqttUtil/mqttConnector';
import {push} from 'redux/ReduxHistory';
import {openDeleteConnectorsDialog} from 'redux/actions/DeleteConnectorsDialogActions';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {
  fetchConnectorSettings,
  fetchSelectedConnector,
  handleApiError,
  saveVariable,
} from 'redux/actions/dataActions';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import {getEditConnectorTabLink, mapResourceToPath} from 'utils/Mappings';
import {
  isEmpty,
  formatDate,
  getCapability,
  checkEventsInstallStatus,
  unpackConnectorCertificates,
  connectorIsCACv2,
  connectorIsAWC,
  awcVersionAsFloat,
} from 'utils/utils';
import {
  AWC_MINIMUM_RECOMMENDED_VERSION,
  CONNECTOR_SETTINGS,
  CONNECTORS_LINK,
  CONNECTOR_EVENTS_LATEST,
  CONNECTOR_EVENT_TYPE_CONFIGURATION_FAILED,
  CONNECTOR_EVENT_TYPE_CONFIGURATION_SUCCESSFUL,
  AD_CONFIG_CAPABILITY_NAME,
  AD_SYNC_CAPABILITY_NAME,
  AWC_MINIMUM_VERSION_WITH_CAPABILITIES,
  CONNECTOR_AD_CONFIG_TAB_STRING,
  CONNECTOR_AUTH_TAB_STRING,
  CONNECTOR_CERT_TAB_STRING,
  CONNECTOR_CONFIG_TAB_STRING,
  CONNECTOR_INSTALLATION_TAB_STRING,
  CONNECTOR_OVERVIEW_TAB_STRING,
  CONNECTOR_SETTINGS_TAB_STRING,
  CONNECTOR_STATUS_PENDING,
  INGRESS_CERT_CAPABILITY_NAME,
  CONFIGURATION_CAPABILITY_NAME,
  INSTALL_RHEL_CONNECTOR_LINK,
  AUTH_CAPABILITY_NAME as MFA_CAPABILITY_NAME,
  UPDATE_RHEL_CONNECTOR_LINK,
} from 'utils/constants';
import {
  selectResourceItem,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import config from '../../../config';
import ConnectorStatus from '../ConnectorStatus';
import DeleteConnectorsDialog from '../DeleteConnectorsDialog';
import ActiveDirectoryTab from './ActiveDirectoryTab';
import AuthenticationTab from './AuthenticationTab';
import BetaCertificateSection from './BetaCertificatesSection';
import CapabilitySection from './CapabilitySection';
import CertificatesTab from './CertificatesTab';
import InstallationTab from './InstallationTab';
import ConfigServiceTab from './ConfigServiceTab';
import {ConnectorMqttContext} from './MqttContext';

const PREFIX = 'BetaConnectorPanel';

const classes = {
  editButton: `${PREFIX}-editButton`,
  deleteButton: `${PREFIX}-deleteButton`,
  deleteIcon: `${PREFIX}-deleteIcon`,
  outdatedBanner: `${PREFIX}-outdatedBanner`,
};

const DISABLED_NOTIFICATION = {display: false};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.editButton}`]: {...stylesFromFigma.editButton},
  [`& .${classes.deleteButton}`]: {...stylesFromFigma.deleteButton},
  [`& .${classes.deleteIcon}`]: {...stylesFromFigma.deleteIcon},
  [`& .${classes.outdatedBanner}`]: {
    marginTop: '16px',
  },
}));

function ConnectorPanel() {
  const dispatch = useDispatch();
  const {connectorId, tabId} = useParams();

  const {successSnackbar, infoSnackbar, errorSnackbar} = useSnackbar();

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const selectedConnector = useSelector(
    (state) => state?.data?.dataByResource?.selectedConnector
  );

  const {item: connectorSettings} = useSelector((state) =>
    selectResourceItem(
      state,
      CONNECTOR_SETTINGS,
      selectedConnector?.deploymentId
    )
  );

  const [latestEvents, setLatestEvents] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [installationNotification, setInstallationNotification] = useState(
    DISABLED_NOTIFICATION
  );
  const [activeDirectoryNotification, setActiveDirectoryNotification] =
    useState(DISABLED_NOTIFICATION);
  const [mfaNotification, setMfaNotification] = useState(DISABLED_NOTIFICATION);
  const [certificatesNotification, setCertificatesNotification] = useState(
    DISABLED_NOTIFICATION
  );
  const [configurationNotification, setConfigurationNotification] = useState(
    DISABLED_NOTIFICATION
  );

  const [isAwc, setIsAwc] = useState(false);
  const [isCACv2, setIsCACv2] = useState(false);

  const [isConnectorOutdated, setIsConnectorOutdated] = useState(true);

  const [isEditConnectorDialogOpen, setEditConnectorDialogOpen] =
    useState(false);

  const [connectorName, setConnectorName] = useState('');

  const [connectorMqttClient, setConnectorMqttClient] = useState(null);

  const overviewLink = getEditConnectorTabLink(
    CONNECTOR_OVERVIEW_TAB_STRING,
    connectorId
  );
  const installationLink = getEditConnectorTabLink(
    CONNECTOR_INSTALLATION_TAB_STRING,
    connectorId
  );
  const adConfigLink = getEditConnectorTabLink(
    CONNECTOR_AD_CONFIG_TAB_STRING,
    connectorId
  );
  const authenticationLink = getEditConnectorTabLink(
    CONNECTOR_AUTH_TAB_STRING,
    connectorId
  );
  const certificatesLink = getEditConnectorTabLink(
    CONNECTOR_CERT_TAB_STRING,
    connectorId
  );
  const configurationLink = getEditConnectorTabLink(
    CONNECTOR_CONFIG_TAB_STRING,
    connectorId
  );
  const connectorSettingsLink = getEditConnectorTabLink(
    CONNECTOR_SETTINGS_TAB_STRING,
    connectorId
  );

  const connectorBreadcrumbs = [
    {text: 'Connectors', link: CONNECTORS_LINK},
    {text: 'Connector panel'},
  ];

  // check the installStatus to ensure we don't block the tabs on a failed reconfigure
  const notInstalled = selectedConnector?.installationStatus === 'notInstalled';

  const capabilityTabsDisabled =
    notInstalled ||
    !selectedConnector?.status ||
    selectedConnector?.status === CONNECTOR_STATUS_PENDING;

  const awcVersion = awcVersionAsFloat(
    selectedConnector?.components?.cacVersion
  );
  let updateConnectorMessageDetails =
    'This connector is outdated. We recommend updating to the latest version that runs on RHEL or Rocky Linux 8.';
  let updateConnectorLink = INSTALL_RHEL_CONNECTOR_LINK;

  if (isAwc) {
    updateConnectorMessageDetails =
      'This connector is outdated. We recommend updating to the latest version.';
    updateConnectorLink = UPDATE_RHEL_CONNECTOR_LINK;
  }

  let connectorTabs = [
    {text: 'Overview', link: overviewLink},
    {
      text: 'Installation',
      link: installationLink,
      notification: installationNotification,
    },
  ];

  // Display all capability tabs if the connector is AWC version is >= AWC_MINIMUM_VERSION_WITH_CAPABILITIES
  if (isAwc && awcVersion >= AWC_MINIMUM_VERSION_WITH_CAPABILITIES) {
    connectorTabs = connectorTabs.concat([
      {
        text: 'Active Directory Configuration',
        link: adConfigLink,
        notification: activeDirectoryNotification,
        disabled: capabilityTabsDisabled,
      },
      {
        text: 'Authentication',
        link: authenticationLink,
        notification: mfaNotification,
        disabled: capabilityTabsDisabled,
      },
      {
        text: 'Certificates',
        link: certificatesLink,
        notification: certificatesNotification,
        disabled: capabilityTabsDisabled,
      },
      {
        text: 'Configuration Service',
        link: configurationLink,
        notification: configurationNotification,
        disabled: capabilityTabsDisabled,
      },
    ]);
  }

  // Show connector settings if the connector is CACv2
  if (isCACv2) {
    connectorTabs = connectorTabs.concat([
      {
        text: 'Connector Settings',
        link: connectorSettingsLink,
      },
    ]);
  }

  const isCapabilityEnterpriseReady = (capabilityName) => {
    if (selectedConnector?.capabilities) {
      const cap = getCapability(
        selectedConnector?.capabilities,
        capabilityName
      );
      if (cap) {
        return cap.enterpriseReadiness;
      }
    }
    return false;
  };

  const getConnectorEvents = async () => {
    const eventsUrl = mapResourceToPath(CONNECTOR_EVENTS_LATEST, {connectorId});
    try {
      const response = await get({path: eventsUrl, params: {sortAsc: 'true'}});
      if (response.code === 200 && response.data.length > 0) {
        if (JSON.stringify(response.data) !== JSON.stringify(latestEvents)) {
          setLatestEvents(response.data);
        }
      }
    } catch (error) {
      if (error?.code === 401) {
        dispatch(handleApiError(error));
      } else {
        // Silently fail on other errors
        console.error('Error polling for connector events. ', error);
      }
    }
  };

  const {getConnectorMqttClient} = useMqttConnector({
    connector: selectedConnector,
  });

  // Fetches connector on first render if data not found in redux
  // Opens error dialog if connectorId is not provided in url
  useEffect(() => {
    const urlConnectorId = connectorId || '';
    const selectedConnectorId = selectedConnector?.connectorId || '';
    if (!urlConnectorId) {
      dispatch(
        openDialog(
          'Unable to retrieve Connector',
          `No Connector with ID=${urlConnectorId} was found`,
          () => dispatch(push(`${CONNECTORS_LINK}`)),
          false
        )
      );
    }
    if (urlConnectorId !== selectedConnectorId) {
      dispatch(fetchSelectedConnector(urlConnectorId));
    }
    return function cleanup() {
      dispatch(saveVariable('selectedConnector', {}));
    };
  }, []);

  useEffect(() => {
    if (!config.isConnectorMqttFeatureEnabled()) {
      return;
    }
    if (selectedConnector?.connectorId && !connectorMqttClient) {
      const getMqttClient = async () => {
        await infoSnackbar(
          `Establishing connection to connector ${selectedConnector.connectorName}...`
        );
        try {
          await setConnectorMqttClient(await getConnectorMqttClient());
          await successSnackbar(
            `Connected to connector ${selectedConnector.connectorName}`
          );
        } catch (error) {
          await errorSnackbar(`Failed to connect to the connector ${error}`);
        }
      };
      getMqttClient();
      return () => {
        // Disconnect from MQTT broker when the component is unmounted
        const cleanup = async () => {
          await connectorMqttClient?.disconnect();
        };
        cleanup();
      };
    }
  }, [selectedConnector?.connectorId]);

  useEffect(() => {
    if (connectorMqttClient) {
      console.log(
        'connectorMqttClient is now available inside this component, ready to be used!'
      );
      console.log({connectorMqttClient});
    }
  }, [connectorMqttClient]);

  useEffect(() => {
    getConnectorEvents();
  }, [selectedConnector?.connectorId]);

  // Ends loading state of page when connector data is found in redux
  useEffect(() => {
    if (!isEmpty(selectedConnector) && deploymentId) {
      dispatch(fetchConnectorSettings(selectedConnector.connectorId));
    }
    setIsAwc(connectorIsAWC(selectedConnector?.components?.cacVersion));
    setIsCACv2(connectorIsCACv2(selectedConnector?.components?.cacVersion));
  }, [selectedConnector, deploymentId]);

  // set connector outdated state based on the connector type, awc or cacv2
  useEffect(() => {
    setIsConnectorOutdated(
      !isEmpty(selectedConnector) &&
        selectedConnector?.status !== CONNECTOR_STATUS_PENDING &&
        (isCACv2 || (isAwc && awcVersion < AWC_MINIMUM_RECOMMENDED_VERSION))
    );
  }, [isAwc, isCACv2]);

  // Overview route will be default if there is no other tab provided
  // in url
  useEffect(() => {
    if (selectedConnector?.connectorId && !tabId) {
      dispatch(push(overviewLink));
    }
    setConnectorName(selectedConnector?.connectorName || '');
  }, [selectedConnector?.connectorId]);

  // Check for changes in installation events & update state
  useEffect(() => {
    if (selectedConnector?.connectorId && latestEvents.length > 0) {
      // Installation notification
      // use checkEventsInstallStatus to check if the installation failed
      // if it did, set the notification to true, if it succeeded, clear the notification
      const installStatus = checkEventsInstallStatus(latestEvents);
      if (installStatus === CONNECTOR_EVENT_TYPE_CONFIGURATION_FAILED) {
        setInstallationNotification({display: true, severity: 'error'});
        dispatch(fetchSelectedConnector(selectedConnector.connectorId));
      } else if (
        installStatus === CONNECTOR_EVENT_TYPE_CONFIGURATION_SUCCESSFUL
      ) {
        setInstallationNotification({display: false});
        dispatch(fetchSelectedConnector(selectedConnector.connectorId));
      }
    }
  }, [selectedConnector?.connectorId, JSON.stringify(latestEvents)]);

  // Update certificates when Connector components change
  useEffect(() => {
    if (!isEmpty(selectedConnector?.components)) {
      setCertificates(unpackConnectorCertificates(selectedConnector));
    }
  }, [JSON.stringify(selectedConnector?.components)]);

  useEffect(() => {
    // Build notifications for each tab
    // uses selectedConnector.capabilities for the capability notifications

    if (
      !isEmpty(selectedConnector) &&
      !isEmpty(selectedConnector?.capabilities)
    ) {
      if (
        !isCapabilityEnterpriseReady(AD_CONFIG_CAPABILITY_NAME) ||
        !isCapabilityEnterpriseReady(AD_SYNC_CAPABILITY_NAME)
      ) {
        setActiveDirectoryNotification({display: true, severity: 'warning'});
      }
      if (!isCapabilityEnterpriseReady(MFA_CAPABILITY_NAME)) {
        setMfaNotification({display: true, severity: 'warning'});
      }
      if (!isCapabilityEnterpriseReady(INGRESS_CERT_CAPABILITY_NAME)) {
        setCertificatesNotification({display: true, severity: 'warning'});
      }
      if (!isCapabilityEnterpriseReady(CONFIGURATION_CAPABILITY_NAME)) {
        setConfigurationNotification({display: true, severity: 'warning'});
      }
    }
  }, [selectedConnector]);

  const handleDeleteConnector = async () => {
    dispatch(openDeleteConnectorsDialog({connectors: [selectedConnector]}));
  };

  const handleEditConnectorName = async (newConnectorName) => {
    try {
      await put({
        path: `deployments/connectors/${selectedConnector.connectorId}`,
        data: {connectorName: newConnectorName.trim()},
      });
      successSnackbar(`Connector name was successfully updated.`);
      setConnectorName(newConnectorName.trim());
      setEditConnectorDialogOpen(false);
    } catch (error) {
      handleApiError(error);
    }
  };

  const renameConnectorDialog = () => {
    return (
      <DialogTextInput
        open={isEditConnectorDialogOpen}
        title="Rename Connector"
        subtitle="Name"
        onCancel={() => setEditConnectorDialogOpen(false)}
        onOk={handleEditConnectorName}
        placeholder="New connector name"
        errorHelperText=""
        allowLineBreaks={false}
      />
    );
  };

  function DeleteConnectorButton() {
    return (
      <Button
        onClick={handleDeleteConnector}
        disabled={isEmpty(selectedConnector)}
        className={classes.deleteButton}
        variant="contained"
      >
        Delete Connector
      </Button>
    );
  }

  function EditConnectorButton() {
    return (
      <Button
        onClick={() => setEditConnectorDialogOpen(true)}
        disabled={isEmpty(selectedConnector)}
        className={classes.editButton}
        variant="contained"
      >
        Edit
      </Button>
    );
  }
  function OverviewTab() {
    return (
      <>
        <ResourcePropertyBar>
          <ResourceProperty
            label="Connector status"
            icon={HealthStatusIcon}
            // Conditionally set property value as null so Skeleton
            // renders correctly while fetching connector data
            propertyValue={
              selectedConnector ? (
                <ConnectorStatus connector={selectedConnector} />
              ) : null
            }
          />
          <ResourceProperty
            label="Connector ID"
            icon={UniqueIdIcon}
            propertyValue={selectedConnector?.connectorId}
          />

          <ResourceProperty
            label="Date created"
            icon={DateIcon}
            propertyValue={formatDate(selectedConnector?.createdOn)}
          />

          <ResourceProperty
            label="Version"
            icon={VersionIcon}
            // Conditionally set property value as null so Skeleton
            // renders correctly while fetching connector data
            propertyValue={
              selectedConnector?.components
                ? selectedConnector.components.version ||
                  selectedConnector.components.cacVersion ||
                  'Not available'
                : null
            }
          />

          <ResourceProperty
            label="Internal IP"
            icon={InternalIpIcon}
            // Conditionally set property value as null so Skeleton
            // renders correctly while fetching connector data
            propertyValue={
              selectedConnector?.components
                ? selectedConnector.components.internalIp || 'Not available'
                : null
            }
          />

          <ResourceProperty
            label="External IP"
            icon={ExternalIpIcon}
            // Conditionally set property value as null so Skeleton
            // renders correctly while fetching connector data
            propertyValue={
              selectedConnector?.components
                ? selectedConnector.components.externalIp || 'Not available'
                : null
            }
          />
        </ResourcePropertyBar>
        <CapabilitySection />
        <BetaCertificateSection certificates={certificates} />
      </>
    );
  }

  const renderTabSection = () => {
    switch (tabId) {
      case CONNECTOR_INSTALLATION_TAB_STRING:
        return <InstallationTab />;
      case CONNECTOR_AD_CONFIG_TAB_STRING:
        return <ActiveDirectoryTab />;
      case CONNECTOR_AUTH_TAB_STRING:
        return <AuthenticationTab />;
      case CONNECTOR_CERT_TAB_STRING:
        return <CertificatesTab />;
      case CONNECTOR_CONFIG_TAB_STRING:
        return <ConfigServiceTab />;
      case CONNECTOR_SETTINGS_TAB_STRING:
        return (
          <EditConnectorSettingsTab
            connectorId={selectedConnector?.connectorId}
            deploymentSettings={connectorSettings?.deployment || {}}
            connectorSettings={connectorSettings?.connector || {}}
          />
        );
      case CONNECTOR_OVERVIEW_TAB_STRING:
      default:
        return <OverviewTab />;
    }
  };

  return (
    <Root>
      <PageWrapper>
        {isConnectorOutdated && (
          <div className={classes.outdatedBanner}>
            <AWMBanner
              message="New version available!"
              details={updateConnectorMessageDetails}
              tagName="Update"
              variant="info"
              dataTestId="update-connector-banner"
              linkObject={{
                href: updateConnectorLink,
                label: 'Update Now',
              }}
              onClick={() => setIsConnectorOutdated(false)}
              showIcon={false}
            />
          </div>
        )}

        <PageHeader
          titleText={connectorName || selectedConnector?.connectorId || ''}
          breadcrumbs={connectorBreadcrumbs}
          tabs={connectorTabs}
          actionButton={<DeleteConnectorButton />}
          editButton={
            <>
              <EditConnectorButton />
            </>
          }
        />
        {/* Set MQTT Client via context so everything within context can access it */}
        <ConnectorMqttContext.Provider value={connectorMqttClient}>
          {renderTabSection()}
        </ConnectorMqttContext.Provider>
      </PageWrapper>
      <DeleteConnectorsDialog />
      {renameConnectorDialog()}
    </Root>
  );
}

export default ConnectorPanel;
