import {useRef} from 'react';

export function useAbortController() {
  const abortController = useRef(new AbortController());

  const resetAbortController = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };
  return [abortController, resetAbortController];
}
