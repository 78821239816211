import {useState} from 'react';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useSelector, useDispatch} from 'react-redux';
import {ReactComponent as ThumbprintIcon} from 'icons/thumbprint.svg';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {handleApiError} from 'redux/actions/dataActions';

import {verifyIfConnectorNameIsUnique} from 'utils/apiUtils';
import ConfigureStepCard from './ConfigureStepCard';
import {stepIndices} from './wizardConstants';
import {useWizardState} from './wizardConfigState';
import ConnectorNameField from '../ConnectorNameField';

const PREFIX = 'ConnectorNameConfigCard';

const classes = {
  cardSectionBody: `${PREFIX}-cardSectionBody`,
  list: `${PREFIX}-list`,
  textField: `${PREFIX}-textField`,
};

const Root = styled('div')(() => ({
  [`& .${classes.cardSectionBody}`]: {
    fontSize: '0.875rem',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },

  [`& .${classes.list}`]: {
    padding: 'inherit',
    paddingTop: '0px',
    paddingBottom: '0px',
    listStylePosition: 'inside',
  },

  [`& .${classes.textField}`]: {
    marginTop: '8px',
    marginBottom: '8px',
  },
}));

function ConnectorNameConfigCard() {
  const dispatch = useDispatch();
  const {
    activeStepIndex,
    setActiveStepIndex,
    connectorName,
    setConnectorName,
    isCommandLocked,
  } = useWizardState();
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const [nameValidationResult, setNameValidationResult] = useState(false);
  const [isVerifyingName, setIsVerifyingName] = useState(false);
  const [nameIsUnique, setNameIsUnique] = useState(true);
  const [failedConnectorName, setFailedConnectorName] = useState('');
  const STEP_INDEX = stepIndices.configureName;
  const TITLE = 'Choose Connector Name';

  const IS_OPEN = activeStepIndex === stepIndices.configureName;
  const IS_COMPLETE = activeStepIndex > stepIndices.configureName;

  const continueDisabled =
    // Case where name is not provided
    !connectorName ||
    // Case where name uniqueness is being verified with the backend
    isVerifyingName ||
    // Case where name is not unique
    !nameIsUnique ||
    // Case where name failed validation
    nameValidationResult.error;

  const handleNameValidationResult = (name, result) => {
    setConnectorName(name);
    setNameValidationResult(result);
    if (result.error) {
      setFailedConnectorName(name);
    }

    if (name !== failedConnectorName) {
      setNameIsUnique(true);
    }
  };

  const handleContinueClick = async () => {
    setIsVerifyingName(true);
    const result = await verifyIfConnectorNameIsUnique(
      connectorName,
      deploymentId
    );

    if (result.error != null) {
      dispatch(handleApiError(result.error));
    }

    if (result.isUnique) {
      setNameIsUnique(true);
      setActiveStepIndex(stepIndices.configureDomain);
    } else {
      setNameIsUnique(false);
    }

    setIsVerifyingName(false);
  };

  return (
    <Root>
      <ConfigureStepCard
        stepIndex={STEP_INDEX}
        title={TITLE}
        open={IS_OPEN}
        complete={IS_COMPLETE}
        optional={false}
        continueDisabled={continueDisabled}
        onContinueClick={handleContinueClick}
        onTitleClick={() => {
          if (activeStepIndex > STEP_INDEX && !isCommandLocked) {
            setActiveStepIndex(STEP_INDEX);
          }
        }}
        icon={<ThumbprintIcon />}
      >
        <Typography
          component="span"
          variant="body1"
          className={classes.cardSectionBody}
        >
          <ul className={classes.list}>
            <li>
              Enter a unique name for the Connector that will help you identify
              it in the future.
            </li>
            <li>Please keep the name for the Connector under 64 characters</li>
          </ul>
        </Typography>

        <ConnectorNameField
          connectorName={connectorName}
          onNameValidate={handleNameValidationResult}
          error={
            nameIsUnique
              ? ''
              : 'A Connector with that name already exists in this deployment.'
          }
        />
      </ConfigureStepCard>
    </Root>
  );
}

export default ConnectorNameConfigCard;
