import {Button, Grid, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import KeyValueInfo from 'components/CAM/text/KeyValueInfo/KeyValueInfo';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import CAMTextField from 'components/common/TextField';
import useNameValidation from 'hooks/useNameValidation';
import usePendingChanges from 'hooks/usePendingChanges';
import {goBack} from 'redux/ReduxHistory';
import {TEXT_REG_CODE_DEFAULT} from 'utils/constants';
import {formatDateTime, isEmpty, isRegCodeFormat} from 'utils/utils';

const PREFIX = 'EditDeploymentOverviewTab';

const classes = {
  inputTextFieldLabel: `${PREFIX}-inputTextFieldLabel`,
  formControl: `${PREFIX}-formControl`,
  itemButton: `${PREFIX}-itemButton`,
  itemButtonRow: `${PREFIX}-itemButtonRow`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.inputTextFieldLabel}`]: theme.createPage.inputTextFieldLabel,

  [`& .${classes.formControl}`]: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '10%',
    },
  },

  [`& .${classes.itemButton}`]: {
    marginRight: '12px',
    height: '36px',
    width: '96px',
  },

  [`& .${classes.itemButtonRow}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const TEXT_REG_CODE_HELPER = 'Invalid registration code';

const camFieldExtraProps = {
  size: 'small',
  fullWidth: true,
};

function EditDeploymentOverviewTab({
  deployment,
  createdOn,
  connectorSettings,
  onSaveDeploymentInfo,
}) {
  const dispatch = useDispatch();
  const {setPendingChanges} = usePendingChanges();

  const {deploymentId, deploymentName} = deployment;

  const [registrationCode, setRegistrationCode] = useState(
    TEXT_REG_CODE_DEFAULT
  );
  const [isSavingDeployment, setIsSavingDeployment] = useState(false);
  const [enteredDeploymentName, setEnteredDeploymentName] = useState('');
  const [isDeploymentNameError, validateName, nameHelperText] =
    useNameValidation();
  const [enteredRegistrationCode, setEnteredRegistrationCode] =
    useState(registrationCode);
  const [isRegistrationCodeError, setIsRegistrationCodeError] = useState(false);

  const getNameHelperText = () => (isDeploymentNameError ? nameHelperText : '');
  const getRegCodeHelperText = () =>
    isRegistrationCodeError ? TEXT_REG_CODE_HELPER : '';
  const getSyncIntervalText = (settings) =>
    settings && settings.syncInterval
      ? `${settings.syncInterval} minutes`
      : 'Not available';

  const isDataUpdated = () =>
    deploymentName !== enteredDeploymentName ||
    registrationCode !== enteredRegistrationCode;
  const isSaveButtonDisabled = () =>
    isSavingDeployment ||
    isDeploymentNameError ||
    isRegistrationCodeError ||
    !isDataUpdated();

  const validateDeploymentName = (name) => {
    const nameToValidate = name.trim();
    validateName(nameToValidate);
  };

  const handleDeploymentNameChange = (event) => {
    const newDeploymentName = event.target.value;
    setEnteredDeploymentName(newDeploymentName);
    validateDeploymentName(newDeploymentName);
  };

  const handleRegCodeChange = (event) => {
    const newRegistrationCode = event.target.value;
    setEnteredRegistrationCode(newRegistrationCode);
    setIsRegistrationCodeError(!isRegCodeFormat(event.target.value));
  };

  const handleCancelEdit = () => {
    setPendingChanges(false);
    dispatch(goBack());
  };

  const handleSave = () => {
    setIsSavingDeployment(true);
    onSaveDeploymentInfo(
      enteredDeploymentName,
      enteredRegistrationCode,
      setIsRegistrationCodeError
    );
    setRegistrationCode(enteredRegistrationCode);
    setIsSavingDeployment(false);
    setPendingChanges(false);
  };

  useEffect(() => {
    if (deploymentName) {
      setEnteredDeploymentName(deploymentName);
      setRegistrationCode(enteredRegistrationCode);
    }
  }, [deploymentName]);

  useEffect(() => {
    setPendingChanges(isDataUpdated());
  }, [enteredDeploymentName, enteredRegistrationCode]);

  const renderComputersDN = (settings) =>
    !isEmpty(settings) &&
    settings.computersDN &&
    settings.computersDN.map((dn) => (
      <div key={`computers-${dn}`}>
        {dn}
        <br />
      </div>
    ));

  const renderUsersDN = (settings) =>
    !isEmpty(settings) &&
    settings.usersDN &&
    settings.usersDN.map((dn) => (
      <div key={`users-${dn}`}>
        {dn}
        <br />
      </div>
    ));

  const deploymentInfo = [
    {
      label: 'Deployment ID',
      value: deploymentId,
      shouldEnableCopy: true,
    },
    {
      label: 'Created On',
      value: formatDateTime(createdOn),
      shouldEnableCopy: true,
    },
    {
      label: 'Computers DN',
      value: renderComputersDN(connectorSettings?.deployment),
      shouldEnableCopy: false,
    },
    {
      label: 'Users DN',
      value: renderUsersDN(connectorSettings?.deployment),
      shouldEnableCopy: false,
    },
    {
      label: 'Sync Interval',
      value: getSyncIntervalText(connectorSettings?.deployment),
      shouldEnableCopy: false,
    },
  ];

  const renderDeploymentInfo = () =>
    deploymentInfo.map((item) => (
      <KeyValueInfo
        key={`deployment-${item.label}`}
        label={item.label}
        value={item.value}
        enableCopy={item.shouldEnableCopy}
      />
    ));

  const renderTextFields = () => (
    <GridN>
      <>
        <Typography className={classes.inputTextFieldLabel}>
          Deployment name
        </Typography>
        <CAMTextField
          value={enteredDeploymentName}
          helperText={getNameHelperText()}
          placeholderText="Edit deployment name"
          fieldType="text"
          dataTestId="field-deployment-name"
          isRequired
          isError={isDeploymentNameError}
          onChange={handleDeploymentNameChange}
          extraProps={camFieldExtraProps}
        />
      </>

      <>
        <Typography className={classes.inputTextFieldLabel}>
          Registration code
        </Typography>
        <CAMTextField
          value={enteredRegistrationCode}
          helperText={getRegCodeHelperText()}
          placeholderText="Edit registration code"
          fieldType="password"
          dataTestId="field-registration-code"
          isRequired
          isError={isRegistrationCodeError}
          onChange={handleRegCodeChange}
          extraProps={camFieldExtraProps}
        />
      </>
    </GridN>
  );

  const renderCancelButton = () => (
    <Button
      className={classes.itemButton}
      variant="outlined"
      color="primary"
      onClick={handleCancelEdit}
    >
      Cancel
    </Button>
  );

  const renderSaveButton = () => (
    <SaveButton
      onClick={handleSave}
      disabled={isSaveButtonDisabled()}
      saving={isSavingDeployment}
    />
  );

  const renderButtonRow = () => (
    <Grid container>
      <div className={classes.itemButtonRow}>
        {renderCancelButton()}
        {renderSaveButton()}
      </div>
    </Grid>
  );

  return (
    <Root>
      <CAMCard>
        <GridN singleColumn>
          <SectionHeader displayText="DEPLOYMENT INFORMATION" />
        </GridN>
        <GridN>{renderDeploymentInfo()}</GridN>

        <form onSubmit={handleSave}>
          <GridN singleColumn>{renderTextFields()}</GridN>
          <GridN singleColumn>{renderButtonRow()}</GridN>
        </form>
      </CAMCard>
    </Root>
  );
}

EditDeploymentOverviewTab.propTypes = {
  deployment: PropTypes.object,
  createdOn: PropTypes.string,
  connectorSettings: PropTypes.object,
  onSaveDeploymentInfo: PropTypes.func,
};

EditDeploymentOverviewTab.defaultProps = {
  deployment: {
    deploymentId: '',
    deploymentName: '',
  },
  createdOn: '',
  connectorSettings: {},
  onSaveDeploymentInfo: () => {},
};

export default EditDeploymentOverviewTab;
