import Slider, {sliderClasses} from '@mui/material/Slider';
import {Box, Grid} from '@mui/material';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';

const PREFIX = 'AWMSlider';

const classes = {
  label: `${PREFIX}-label`,
  slider: `${PREFIX}-slider`,
};

const StyledTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {color: theme.palette.primary.main},
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${classes.label}`]: {
    textAlign: 'center',
  },
}));

const StyledSlider = styled(Slider)(({theme}) => ({
  [`& .${sliderClasses.valueLabel}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

function AWMSlider({
  value,
  onChange,
  onChangeCommitted,
  disabled,
  tooltipText,
  testIdPrefix,
  size,
  hideTrack,
  defaultValue,
  marksObject,
  labelFormatter,
}) {
  const {marksArray, step, minMark, maxMark} = marksObject;

  return (
    <StyledTooltip arrow placement="bottom-start" title={tooltipText}>
      <Grid container>
        <StyledSlider
          aria-label="Restricted values"
          defaultValue={defaultValue}
          step={step}
          disabled={disabled}
          valueLabelDisplay="auto"
          marks={marksArray}
          track={!hideTrack}
          min={minMark}
          max={maxMark}
          value={value}
          size={size}
          data-testid={`${testIdPrefix}-slider`}
          onChange={onChange}
          onChangeCommitted={onChangeCommitted}
          valueLabelFormat={(value) => (
            <Box className={classes.label}>{labelFormatter(value)}</Box>
          )}
        />
      </Grid>
    </StyledTooltip>
  );
}

AWMSlider.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
  disabled: PropTypes.bool,
  tooltipText: PropTypes.string,
  testIdPrefix: PropTypes.string,
  size: PropTypes.string,
  hideTrack: PropTypes.bool,
  defaultValue: PropTypes.number,
  marksObject: PropTypes.shape({
    marksArray: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    minMark: PropTypes.number.isRequired,
    maxMark: PropTypes.number.isRequired,
    step: PropTypes.number,
  }).isRequired,
  labelFormatter: PropTypes.func,
};

AWMSlider.defaultProps = {
  onChange: () => {},
  onChangeCommitted: () => {},
  defaultValue: 30,
  disabled: false,
  tooltipText: '',
  testIdPrefix: 'test',
  size: 'small',
  hideTrack: false,
  labelFormatter: (value) => {
    return value;
  },
};

export default AWMSlider;
