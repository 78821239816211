import {get} from 'api/Api';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {USER_SESSION_ATTEMPTS} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {selectSelectedDeployment} from 'utils/reduxSelectors';

const getUserSessionAttempts = async (deploymentId, userGuid, range) => {
  const path = mapResourceToPath(USER_SESSION_ATTEMPTS, {
    deploymentId,
    userGuid,
  });

  const params = {limit: 200};
  const {from, to} = range;
  if (from) {
    params.from = from;
  }
  if (to) {
    params.to = to;
  }

  const {data} = await get({path, params});
  return data;
};

const useUserSessionAttempts = (userGuid, range = {}, options = {}) => {
  const {deploymentId} = useSelector(selectSelectedDeployment);
  return useQuery(
    [deploymentId, userGuid, 'session-attempts', range],
    () => getUserSessionAttempts(deploymentId, userGuid, range),
    options
  );
};

export default useUserSessionAttempts;
