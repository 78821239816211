import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import FormControl from '@mui/material/FormControl';
import {saveVariable} from 'redux/actions/dataActions';
import {resourceToSearchInfoMap} from 'utils/Mappings';
import {selectVariable} from 'utils/reduxSelectors';

function ToolbarSelect({resource}) {
  const dispatch = useDispatch();

  const resourceInfo = resourceToSearchInfoMap[resource] || {};
  const {options = [], defaultValue = ''} = resourceInfo;

  const value =
    useSelector((state) => selectVariable(state, `searchTermBy${resource}`)) ||
    defaultValue;

  const onChange = (event) => {
    dispatch(saveVariable(`searchTermBy${resource}`, event.target.value));
  };

  return (
    <FormControl size="small">
      <Select defaultValue={defaultValue} onChange={onChange} value={value}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

ToolbarSelect.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default ToolbarSelect;
