import {useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import AssignmentPolicyIcon from 'components/CAM/icons/AssignmentPolicyIcon/AssignmentPolicyIcon';
import CAMTable from 'components/table/Table';
import {capitalize} from 'helpers/core';
import {EDIT_POOL_LINK, POOLS} from 'utils/constants';
import {
  selectDataForTable,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import {linkTo} from 'utils/utils';
import DeletePoolsDialog from './DeletePoolsDialog';

function Pools() {
  const {data, total, isFetching} = useSelector((state) =>
    selectDataForTable(state, POOLS)
  );

  const getEditLink = (pool) => linkTo(`${EDIT_POOL_LINK}/${pool.poolId}`);

  const {deploymentName} = useSelector(selectSelectedDeployment);

  const prepareAssignmentPolicy = (policyType) => (
    <Grid container alignItems="center" direction="row">
      <AssignmentPolicyIcon policyType={policyType} />
      {capitalize(policyType)}
    </Grid>
  );

  const prepareData = (pools) =>
    pools.map((pool) => ({
      ...pool,
      assignmentPolicy: prepareAssignmentPolicy(
        pool.policies.assignment.policyType
      ),
      link: getEditLink(pool),
    }));

  return (
    <>
      <CAMTable
        resource={POOLS}
        data={prepareData(data)}
        total={total}
        loadingData={isFetching}
        noDataMessage={`You do not have any pools in ${deploymentName}`}
      />
      <DeletePoolsDialog />
    </>
  );
}

export default Pools;
