import Checkbox from '@mui/material/Checkbox';
import {styled} from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import CAMDeleteDialog from 'components/CAM/dialogs/CAMDeleteDialog/CAMDeleteDialog';
import MachineName from 'components/CAM/display/MachineName/MachineName';
import {
  closeBulkDeleteWorkstationsDialog,
  toggleBulkDeleteWorkstationsFromCsp,
} from 'redux/actions/bulkDeleteRemoteWorkstationsDialogActions';
import {deleteWorkstations} from 'redux/actions/remoteWorkstationActions';
import {DELETE_FROM_CSP_SUPPORTED} from 'utils/constants';
import {mapProviders, mapProvidersToEnum} from 'utils/Mappings';
import {selectProviderCredentials} from 'utils/reduxSelectors';
import {isEmpty} from 'utils/utils';

const PREFIX = 'BulkDeleteRemoteWorkstationDialog';

const classes = {
  title: `${PREFIX}-title`,
  message: `${PREFIX}-message`,
  button: `${PREFIX}-button`,
  tooltip: `${PREFIX}-tooltip`,
  dialogRoot: `${PREFIX}-dialogRoot`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.title}`]: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '20px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: '24px',
  },

  [`& .${classes.message}`]: {
    color: 'rgba(0,0,0,0.6)',
    letterSpacing: '0.5px',
    lineHeight: '22px',
    marginBottom: '1rem',
  },

  [`& .${classes.button}`]: {
    width: '80px',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
  },

  [`& .${classes.tooltip}`]: {
    fontSize: '14px',
  },

  [`& .${classes.dialogRoot}`]: {
    minHeight: '50vh',
    maxHeight: '70vh',
  },
}));

// Sort function to sort list of workstations by provider (AWS -> AZURE -> GCP -> ONPREM)
const sortMachinesByProvider = ({provider: a}, {provider: b}) => {
  const providerA = mapProvidersToEnum(a);
  const providerB = mapProvidersToEnum(b);
  return providerA.localeCompare(providerB);
};

function DeleteMachinesList() {
  const {workstations} = useSelector(
    (state) => state.bulkDeleteRemoteWorkstationsDialog
  );
  const [expanded, setExpanded] = useState(false);
  const longList = workstations.length > 5;

  const renderListItem = (w) => (
    <ListItem key={w.machineId}>
      <MachineName
        testId={`bulk-delete-${w.machineId}`}
        machineName={w.machineName}
        provider={mapProvidersToEnum(w.provider)}
        instanceId={w.instanceId}
      />
    </ListItem>
  );

  let listItems = [...workstations]
    .sort(sortMachinesByProvider)
    .map(renderListItem);
  if (longList && !expanded) {
    listItems = listItems.slice(0, 5);
  }

  return (
    <List dense>
      {longList && !expanded ? listItems.slice(0, 5) : listItems}
      {longList && (
        <CAMButton
          onClick={() => setExpanded(!expanded)}
          textOnly
          size="small"
          buttonText={
            expanded ? 'Show Less' : `+${workstations.length - 5} more`
          }
          data-testid="bulk-delete-show-more"
        />
      )}
    </List>
  );
}

function BulkDeleteRemoteWorkstationDialog() {
  const dispatch = useDispatch();

  const {open, deleteFromCspEnabled, workstations} = useSelector(
    (state) => state.bulkDeleteRemoteWorkstationsDialog
  );

  const {creds, fetchingCreds} = useSelector((state) =>
    selectProviderCredentials(state)
  ) || {creds: [], fetchingCreds: false};

  const onConfirm = () => {
    dispatch(closeBulkDeleteWorkstationsDialog());
    dispatch(deleteWorkstations(workstations, deleteFromCspEnabled));
  };

  const onCancel = () => {
    dispatch(closeBulkDeleteWorkstationsDialog());
  };

  const hasProviderCredentials = (provider) => {
    if (fetchingCreds) {
      return true;
    }

    const providerCreds = creds.find((cred) => cred.provider === provider);
    return !isEmpty(providerCreds);
  };

  const onCheckboxChange = (provider) => {
    if (hasProviderCredentials(provider)) {
      dispatch(toggleBulkDeleteWorkstationsFromCsp(provider));
    }
  };

  const disableCheckbox = (provider) => !hasProviderCredentials(provider);

  // Check if at least one workstation is in the specified provider
  const hasProvider = (provider) => {
    const providerToFind = provider === 'onprem' ? 'private cloud' : provider;
    const providerWorkstation = workstations.find(
      (wk) => wk.provider.toLowerCase() === providerToFind
    );
    return !isEmpty(providerWorkstation);
  };

  const renderMessage = () => (
    <div>
      <Typography className={classes.message} gutterBottom>
        The following workstations will be deleted from Anyware Manager. If you
        wish to delete the underlying workstations from their public cloud
        provider, please check the options below accordingly.
      </Typography>
      <DeleteMachinesList />
    </div>
  );

  const getCheckboxMessage = (provider) =>
    `Delete from ${mapProviders(provider)}`;

  const getTooltipTitle = (provider) => {
    if (fetchingCreds) {
      return `Verifying deployment has ${mapProviders(
        provider
      )} credentials...`;
    }

    if (creds.length === 0) {
      return `Deployment does not have ${mapProviders(provider)} credentials`;
    }

    return '';
  };

  function renderDeleteFromCspCheckbox(provider) {
    return (
      <Tooltip
        title={getTooltipTitle(provider)}
        placement="top-start"
        classes={{
          tooltip: classes.tooltip,
        }}
        key={provider}
      >
        <FormControlLabel
          control={
            <Checkbox
              value={deleteFromCspEnabled[provider]}
              color="primary"
              onChange={() => onCheckboxChange(provider)}
              inputProps={{
                'data-testid': `delete-workstation-from-${provider}`,
              }}
            />
          }
          label={getCheckboxMessage(provider)}
          disabled={disableCheckbox(provider)}
        />
      </Tooltip>
    );
  }

  return (
    <Root>
      <CAMDeleteDialog
        open={open}
        onCancel={onCancel}
        onOk={onConfirm}
        resourceName={`${workstations.length} workstations`}
        requireTextInputToDelete={workstations.length > 1}
        content={
          <>
            {renderMessage()}
            <FormGroup>
              {DELETE_FROM_CSP_SUPPORTED.map(
                (provider) =>
                  hasProvider(provider) && renderDeleteFromCspCheckbox(provider)
              )}
            </FormGroup>
          </>
        }
      />
    </Root>
  );
}

export default BulkDeleteRemoteWorkstationDialog;
