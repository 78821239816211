import {Grid} from '@mui/material';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useQueryClient} from 'react-query';
import {styled} from '@mui/material/styles';
import {
  linkServiceQueryKey,
  useDeleteLinkService,
  useQueryLinkService,
} from 'hooks/linkServiceHooks';
import {handleApiError} from 'redux/actions/dataActions';
import GridN from 'components/CAM/layout/GridN/GridN';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import KeyValueInfo from 'components/CAM/text/KeyValueInfo/KeyValueInfo';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import useSnackbar from 'hooks/useSnackbar';

const PREFIX = 'LinkServiceInfoTab';
const classes = {
  itemButtonRow: `${PREFIX}-itemButtonRow`,
};
const Root = styled('div')(() => ({
  [`& .${classes.itemButtonRow}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

function LinkServiceInfoTab({deploymentId}) {
  const queryClient = useQueryClient();
  const {successSnackbar} = useSnackbar();
  const dispatch = useDispatch();

  const {mutate: deleteLinkService} = useDeleteLinkService({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [linkServiceQueryKey],
      });
      successSnackbar('Link service is deleted');
    },
    onError: (err) => {
      dispatch(handleApiError(err));
    },
  });

  const {data} = useQueryLinkService(deploymentId, {
    refetchInterval: 5000, // refresh every 5 seconds by default
  });

  const infos = [
    {
      label: 'Link ID',
      value: data?.id,
      shouldEnableCopy: true,
    },
    {
      label: 'Broker URL',
      value: data?.fqdn,
      shouldEnableCopy: true,
    },
  ];
  return (
    <Root>
      <GridN singleColumn>
        <SectionHeader displayText="LINK INFORMATION" />
      </GridN>
      <GridN>
        {infos.map((item) => (
          <KeyValueInfo
            label={item.label}
            value={item.value}
            enableCopy={item.shouldEnableCopy}
          />
        ))}
      </GridN>
      <GridN singleColumn>
        <Grid container>
          <div className={classes.itemButtonRow}>
            <CAMButton
              onClick={() => deleteLinkService(deploymentId)}
              buttonText="Delete"
            />
          </div>
        </Grid>
      </GridN>
    </Root>
  );
}
LinkServiceInfoTab.propTypes = {
  deploymentId: PropTypes.string.isRequired,
};

export default LinkServiceInfoTab;
