import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import {Box, Toolbar} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import {lighten} from 'polished';
import PropTypes from 'prop-types';
import AWMColoredFeedbackIcon from 'components/CAM/icons/AWMColoredFeedbackIcon/AWMColoredFeedbackIcon';
import GenericLink from 'components/common/GenericLink';
import {
  ERROR_STATUS,
  GENERIC_STATUS,
  INFO_STATUS,
  SUCCESS_STATUS,
  WARNING_STATUS,
} from 'utils/constants';

const PREFIX = 'AWMBanner';

const classes = {
  panel: `${PREFIX}-panel`,
  summary: `${PREFIX}-summary`,
  tag: `${PREFIX}-tag`,
  message: `${PREFIX}-message`,
  details: `${PREFIX}-details`,
  flexDiv: `${PREFIX}-flexDiv`,
  icon: `${PREFIX}-icon`,
  link: `${PREFIX}-link`,
  closeIcon: `${PREFIX}-closeIcon`,
  noPointerEvents: `${PREFIX}-noPointerEvents`
};

const StyledBox = styled(Box)((
  {
    theme
  }
) => ({
  [`&.${classes.panel}`]: {
    position: 'relative',
    borderRadius: '4px',
    width: '100%',
    height: 'fit-content',
  },

  [`& .${classes.summary}`]: {
    minHeight: '40px !important',
    borderRadius: '10px',
    marginTop: '5px',
    padding: '0px',
    paddingLeft: '24px',
    paddingRight: '16px',
  },

  [`& .${classes.tag}`]: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '24px',
    padding: '0px 10px',
    borderRadius: '24px',
    textAlign: 'center',
    fontWeight: 'normal',
    color: '#ffffff',
    fontSize: '14px',
  },

  [`& .${classes.message}`]: {
    // width: 'max-content',
    fontSize: '14px',
    fontWeight: '700',
    left: 0,
    letterSpacing: 0,
    lineHeight: '18.2px',
  },

  [`& .${classes.details}`]: {
    marginRight: '10px',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginBottom: '8px',
    },
    fontSize: '14px',
  },

  [`& .${classes.flexDiv}`]: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  [`& .${classes.icon}`]: {
    position: 'relative',
    padding: '0px',
    width: '20px',
    height: '20px',
  },

  [`& .${classes.link}`]: {
    fontSize: '14px',
    fontWeight: '550',
    lineHeight: '18px',
  },

  [`& .${classes.closeIcon}`]: {
    marginLeft: 'auto',
  },

  [`& .${classes.noPointerEvents}`]: {
    pointerEvents: 'none',
  }
}));

function AWMBanner({
  message,
  details,
  variant,
  tagName,
  customColor,
  customIcon,
  dataTestId,
  linkObject,
  onClick,
  showIcon,
  showCloseButton,
}) {


  // This function is used to derive the colors for the banner based on the color provided
  // when the banner is used as a custom banner.
  const getDerivedColors = (color) => {
    const tagColor = lighten(0.1, color);
    const borderColor = lighten(0.35, color);
    const lightenedColor = lighten(0.55, color);
    return {
      tagColor,
      borderColor,
      lightenedColor,
    };
  };

  const getCustomBannerTheme = (color) => ({color, ...getDerivedColors(color)});

  const getPredefinedBannerTheme = () => {
    switch (variant) {
      case ERROR_STATUS:
        return {
          mainColor: '#833226',
          tagColor: '#A74132',
          borderColor: '#F38C7E',
          lightenedColor: '#FDEAE8',
        };
      case WARNING_STATUS:
        return {
          mainColor: '#844D0A',
          tagColor: '#A8620D',
          borderColor: '#F5AF59',
          lightenedColor: '#FEF3E7',
        };
      case INFO_STATUS:
        return {
          mainColor: '#1B4A8C',
          tagColor: '#245FB2',
          borderColor: '#72ADFF',
          lightenedColor: '#E5F0FF',
        };
      case SUCCESS_STATUS:
        return {
          mainColor: '#176947',
          tagColor: '#1D855A',
          borderColor: '#6AD1A7',
          lightenedColor: '#EAFAF4',
        };
      case GENERIC_STATUS:
      default:
        return {
          mainColor: '#23242A',
          tagColor: '#8E93A4',
          borderColor: '#C7C9D1',
          lightenedColor: '#F8F8FB',
        };
    }
  };

  const {mainColor, tagColor, borderColor, lightenedColor} = customColor
    ? {...getCustomBannerTheme(customColor), mainColor: customColor}
    : getPredefinedBannerTheme();

  // To handle the case where the icon is a custom icon, we need to wrap it in a div
  // with the color set to the mainColor
  const iconToRender = customIcon || (
    <AWMColoredFeedbackIcon variant={variant} />
  );

  const {href, label: linkLabel, labelOnClick} = linkObject;

  return (
    <StyledBox className={classes.panel} id={dataTestId}>
      <Toolbar
        className={classes.summary}
        style={{
          backgroundColor: lightenedColor,
          color: mainColor,
          border: `${borderColor} 1px solid`,
        }}
      >
        <div>
          {showIcon && (
            <IconButton
              className={classNames(classes.noPointerEvents, classes.icon)}
              style={{
                pointerEvents: 'none',
                color: mainColor,
              }}
              size="large"
            >
              {iconToRender}
            </IconButton>
          )}
          {tagName && (
            <span
              className={classes.tag}
              style={{
                background: tagColor,
              }}
            >
              {tagName}
            </span>
          )}
        </div>
        <div className={classes.flexDiv}>
          <div className={classes.message}>{message}</div>
          <div className={classNames(classes.flexDiv, classes.details)}>
            {details}
          </div>
          {(href || labelOnClick) && linkLabel && (
            <GenericLink
              url={href}
              className={classes.link}
              style={{
                color: '#245FB2',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (labelOnClick) {
                  labelOnClick();
                  // avoid href from being followed
                  return false;
                }
              }}
            >
              {linkLabel}
            </GenericLink>
          )}
        </div>
        {showCloseButton && (
          <IconButton
            onClick={onClick}
            className={classNames(classes.icon, classes.closeIcon)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
      </Toolbar>
    </StyledBox>
  );
}

AWMBanner.propTypes = {
  message: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    WARNING_STATUS,
    SUCCESS_STATUS,
    GENERIC_STATUS,
    INFO_STATUS,
    ERROR_STATUS,
  ]),
  customIcon: PropTypes.object,
  showIcon: PropTypes.bool,
  tagName: PropTypes.string,
  customColor: PropTypes.string,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  linkObject: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    labelOnClick: PropTypes.func,
  }),
  showCloseButton: PropTypes.bool,
};

AWMBanner.defaultProps = {
  variant: PropTypes.oneOf([
    WARNING_STATUS,
    SUCCESS_STATUS,
    GENERIC_STATUS,
    INFO_STATUS,
    ERROR_STATUS,
  ]),
  tagName: '',
  customIcon: null,
  showIcon: true,
  dataTestId: 'anyware-banner',
  onClick: () => {},
  linkObject: {
    href: '',
    label: '',
  },
  customColor: '',
  showCloseButton: true,
};

export default AWMBanner;
