import {useSelector, useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import AWMBanner from 'components/CAM/display/AWMBanner/AWMBanner';
import {getEditDeploymentLink} from 'helpers/core';
import {
  CONNECTOR_SETTINGS,
  DASHBOARD_LINK,
  SESSION_HISTORY_LINK,
  SESSION_TRACKING_BANNER_DETAILS,
  SESSION_TRACKING_BANNER_MESSAGE,
  TELEMETRY_SETTINGS,
} from 'utils/constants';
import {
  selectSelectedDeployment,
  selectResourceItem,
} from 'utils/reduxSelectors';
import {isEmpty} from 'utils/utils';
import {fetchTelemetrySettings} from 'redux/actions/dataActions';
import config from 'config';

function SessionTrackingBanner() {
  const dispatch = useDispatch();
  const [isSessionTrackingBannerVisible, setIsSessionTrackingBannerVisible] =
    useState(false);
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {item: telemetrySettings, isFetching: fetchingTelemetrySettings} =
    useSelector((state) =>
      selectResourceItem(state, TELEMETRY_SETTINGS, deploymentId)
    );

  const isSessionsPage = !!window.location.pathname.match(SESSION_HISTORY_LINK);
  let link = DASHBOARD_LINK;
  if (deploymentId) {
    link = `${config.isBeta() ? '/beta-ui' : ''}${getEditDeploymentLink(
      deploymentId
    )}/${CONNECTOR_SETTINGS}`;
  }

  useEffect(() => {
    if (deploymentId) {
      dispatch(fetchTelemetrySettings());
    }
  }, [deploymentId]);

  useEffect(() => {
    if (!fetchingTelemetrySettings && !isEmpty(telemetrySettings)) {
      setIsSessionTrackingBannerVisible(!telemetrySettings.enabled);
    }
  }, [JSON.stringify(telemetrySettings)]);

  if (!isSessionsPage || !isSessionTrackingBannerVisible) return null;

  return (
    <AWMBanner
      message={SESSION_TRACKING_BANNER_MESSAGE}
      details={SESSION_TRACKING_BANNER_DETAILS}
      variant="info"
      dataTestId="session-tracking-banner"
      linkObject={{
        href: link,
        label: 'Activate Session Tracking',
      }}
      onClick={() => setIsSessionTrackingBannerVisible(false)}
    />
  );
}

SessionTrackingBanner.propTypes = {};

export default SessionTrackingBanner;
