import {useDispatch} from 'react-redux';
import {post} from 'api/Api';
import {handleApiError} from 'redux/actions/dataActions';
import MqttBaseClass from './mqtt';

// MQTT Client used to communicate with the connector
class MqttConnector extends MqttBaseClass {
  constructor({connectorId, getToken}) {
    super({
      getToken,
      connectorId
    });
  }
}

export const useMqttConnector = ({connector}) => {
  const dispatch = useDispatch();
  if (!connector) {
    return false;
  }

  const {deploymentId, connectorId} = connector;
  const role = 'connectorController';
  const getMqttConnectorToken = async () => {
    try {
      const resp = await post({
        path: 'auth/tokens/mqtt',
        data: {role, connectorId},
      });
      const token = resp?.data?.token;
      return token;
    } catch (err) {
      dispatch(handleApiError(err));
    }
    return false;
  };

  const getConnectorMqttClient = async () => {
    if (!(deploymentId && connectorId)) {
      return false;
    }
    const mqttClient = new MqttConnector({
      getToken: getMqttConnectorToken,
      connectorId,
    });
    await mqttClient.connect();
    return mqttClient;
  };

  return {getConnectorMqttClient};
};
