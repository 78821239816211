import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import config from 'config';

const cookies = new Cookies();

class CasmCookies {
  static getItem(name) {
    return cookies.get(name);
  }

  static setItem(name, value, maxAge, path, domain, secure, sameSite) {
    cookies.set(name, value, {
      secure,
      maxAge,
      path,
      domain,
      sameSite,
    });
    return true;
  }

  static removeItem(name) {
    cookies.remove(name);
  }

  static getAuthToken() {
    return this.getItem('AUTH_TOKEN');
  }

  static decodeJWT(token) {
    try {
      return jwtDecode(token);
    } catch (error) {
      if (error.message !== 'Invalid token specified') {
        // eslint-disable-next-line no-console
        console.log(error);
      }
      return false;
    }
  }

  static getDecodedAuthToken() {
    const cookie = this.getAuthToken();
    return this.decodeJWT(cookie);
  }

  static setAuthToken(token) {
    this.setItem(
      'AUTH_TOKEN',
      token,
      config.COOKIES_MAX_AGE,
      '/',
      window.location.hostname,
      true,
      'Strict'
    );
  }

  static clearAuthToken = () => {
    cookies.remove('AUTH_TOKEN', {path: '/'});
  };

  static isTokenValid = () => {
    const token = this.getAuthToken() || '';
    if (token) {
      const decodedToken = this.decodeJWT(token);
      const secondsToExpire =
        decodedToken && 'exp' in decodedToken
          ? decodedToken.exp - Math.floor(Date.now() / 1000)
          : 0;
      return secondsToExpire > 0;
    }
    return false;
  };
}

export default CasmCookies;
