import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';

import RecommendedActionsIcon from '@mui/icons-material/TrendingUp';
import {Typography, Chip} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import {ReactComponent as CapabilityStatusIcon} from 'icons/CapabilityStatusIcon.svg';
import {
  CAPABILITY_DISABLED_STATE,
  CAPABILITY_ENABLED_STATE,
  CAPABILITY_INCOMPLETE_STATE,
} from 'utils/constants';
import {getCapabilityStatus} from 'utils/utils';

const statusStyleMap = {
  [CAPABILITY_ENABLED_STATE]: {
    backgroundColor: '#EAFAF4',
    color: '#176947',
  },
  [CAPABILITY_INCOMPLETE_STATE]: {
    backgroundColor: '#FDEAE8',
    color: '#A74132',
  },
  [CAPABILITY_DISABLED_STATE]: {
    backgroundColor: '#EBF3FA',
    color: '#1E3BB8',
  },
};

const PREFIX = 'CapabilityStatus';

const classes = {
  flexRow: `${PREFIX}-flexRow`,
  propTitle: `${PREFIX}-propTitle`,
  statusIcon: `${PREFIX}-statusIcon`,
  statusChip: `${PREFIX}-statusChip`,
  label: `${PREFIX}-label`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.flexRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10px 0px',
  },

  [`& .${classes.propTitle}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '200px',
  },

  [`& .${classes.statusIcon}`]: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginRight: '16.5px',
    fontSize: '20px',
  },

  [`& .${classes.label}`]: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

const StyledChip = styled(({...props}) => <Chip {...props} />)(
  ({...props}) => ({
    [`&.${classes.statusChip}`]: {
      backgroundColor: statusStyleMap[props.label]?.backgroundColor || '#FFF',
      color: statusStyleMap[props.label]?.color || '#000',
    },
  })
);

function CapabilityStatus({capabilityData = {}, isLoading}) {
  const {enabled, enterpriseReadiness, recommendedActions} = capabilityData;

  const capabilityStatus = getCapabilityStatus(enabled, enterpriseReadiness);
  return (
    <Root>
      <div className={classes.flexRow}>
        <div className={classes.propTitle}>
          <CapabilityStatusIcon className={classes.statusIcon} />
          <Typography variant="body1" className={classes.label}>
            Capability status
          </Typography>
        </div>
        {isLoading ? (
          <Skeleton width="100px" />
        ) : (
          <StyledChip label={capabilityStatus} className={classes.statusChip} />
        )}
      </div>
      {recommendedActions?.length > 0 && (
        <>
          <div className={classes.flexRow}>
            <div className={classes.propTitle}>
              <RecommendedActionsIcon className={classes.statusIcon} />
              <Typography variant="body1" className={classes.label}>
                Recommended actions
              </Typography>
            </div>
          </div>
          <ul>
            {recommendedActions.map((action) => (
              <li key={action}>{action}</li>
            ))}
          </ul>
        </>
      )}
    </Root>
  );
}

CapabilityStatus.propTypes = {
  capabilityData: PropTypes.shape({
    enabled: PropTypes.bool,
    enterpriseReadiness: PropTypes.bool,
    recommendedActions: PropTypes.arrayOf(PropTypes.string),
  }),
  isLoading: PropTypes.bool,
};

CapabilityStatus.defaultProps = {
  capabilityData: {},
  isLoading: true,
};

export default CapabilityStatus;
