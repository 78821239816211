import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import SelectPool from 'components/common/select/SelectPool';
import {
  USER_SELECTION_BY_ENTITLEMENTS,
  USER_SELECTION_BY_POOL,
  USER_SELECTION_DISABLED,
} from 'utils/constants';
import AddRemoveUser from '../AddRemoveUser';

const PREFIX = 'PageUserManagement';

const classes = {
  inputOutputGroups: `${PREFIX}-inputOutputGroups`,
  invisible: `${PREFIX}-invisible`
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.inputOutputGroups}`]: {
    padding: '20px',
    height: '100%',
  },

  [`& .${classes.invisible}`]: {
    display: 'none',
  }
}));

function PageUserManagement({
  deploymentId,
  selectedPool,
  setSelectedPool,
  selectedUsers,
  setSelectedUsers,
  setUserManagementMethod,
  userManagementMethod,
  visible,
}) {


  const removePool = () => {
    setSelectedPool(null);
  };

  const changeUser = (users) => {
    setSelectedUsers(users);
  };

  const renderManagementSelection = () => (
    <FormControl component="fieldset" color="primary">
      <RadioGroup
        name="userManagementOption"
        value={userManagementMethod}
        onChange={(event) => setUserManagementMethod(event.target.value)}
      >
        <FormControlLabel
          value={USER_SELECTION_DISABLED}
          control={<Radio color="primary" />}
          label="Do not assign users now."
        />
        <FormControlLabel
          value={USER_SELECTION_BY_ENTITLEMENTS}
          control={<Radio color="primary" />}
          label="Individually select users."
        />
        <FormControlLabel
          value={USER_SELECTION_BY_POOL}
          control={<Radio color="primary" />}
          label="Add workstations to a pool. Users will be managed by pool membership."
        />
      </RadioGroup>
    </FormControl>
  );

  const renderEntitlementsInput = () => (
    <AddRemoveUser
      data-testid="add-remove-user"
      deploymentId={deploymentId}
      addedUsers={
        selectedUsers
          ? Object.keys(selectedUsers).map((i) => selectedUsers[i])
          : []
      }
      onChangeUser={changeUser}
      disabled={false}
      isFetching={false}
      disabledMessage=""
    />
  );

  const renderPoolSelection = () => (
    <SelectPool setSelectedPool={setSelectedPool} selectedPool={selectedPool} />
  );

  return (
    <StyledGrid container spacing={1} className={!visible ? classes.invisible : null}>
      <Grid item xs={12} lg={5}>
        <Paper className={classes.inputOutputGroups} variant="outlined">
          <Typography variant="h6">
            How users will be managed for these remote workstation?
          </Typography>
          <p>
            You can change this later by editing each remote workstation
            individually.
          </p>
          <Grid container>{renderManagementSelection()}</Grid>
        </Paper>
      </Grid>

      <Grid
        item
        xs={12}
        lg={7}
        className={userManagementMethod === 'pool' ? null : classes.invisible}
      >
        <Paper className={classes.inputOutputGroups} variant="outlined">
          <Typography variant="h6">
            Add these remote workstations to a pool
          </Typography>
          <p>
            A pool is a group of remote workstations that you can associate to
            users later
          </p>
          <Grid container>
            {renderPoolSelection()}
            <Button onClick={removePool}>Clear pool</Button>
          </Grid>
        </Paper>
      </Grid>

      <Grid
        item
        xs={12}
        lg={7}
        className={
          userManagementMethod === 'entitlement' ? null : classes.invisible
        }
      >
        <Paper className={classes.inputOutputGroups} variant="outlined">
          <Typography variant="h6">
            Add users from the Active Directory (
            {selectedUsers ? selectedUsers.length : '0'} selected)
          </Typography>

          <Grid container>{renderEntitlementsInput()}</Grid>
        </Paper>
      </Grid>

      <Grid
        item
        xs={12}
        lg={7}
        className={userManagementMethod === '' ? null : classes.invisible}
      >
        <Paper className={classes.inputOutputGroups} variant="outlined">
          <Typography variant="h6">User management</Typography>
          <p>
            No users will be assigned to the selected remote workstations. You
            can always edit it later and assign users or pools.
          </p>
        </Paper>
      </Grid>
    </StyledGrid>
  );
}

PageUserManagement.propTypes = {
  deploymentId: PropTypes.string.isRequired,
  selectedPool: PropTypes.object,
  setSelectedPool: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  setUserManagementMethod: PropTypes.func.isRequired,
  userManagementMethod: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};

PageUserManagement.defaultProps = {
  selectedPool: null,
};

export default PageUserManagement;
