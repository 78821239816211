import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'api/Api';
import {
  clearResource,
  fetchResource,
  handleApiError,
} from 'redux/actions/dataActions';
import {closeViewMachineAssignmentDialog} from 'redux/actions/viewMachineAssignmentDialogActions';
import {doSafeRequest} from 'utils/apiUtils';
import {
  ACTIVITY_LOGS,
  POOL_MACHINES,
  UPDATE_POOL_MACHINE,
} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {
  selectData,
  selectSelectedDeployment,
  selectSelectedPool,
} from 'utils/reduxSelectors';
import {formatDateTime} from 'utils/utils';

function ViewMachineAssignmentDialog() {
  const dispatch = useDispatch();

  const [existingAssignments, setExistingAssignments] = useState([]);
  const [isFetchingAssignments, setIsFetchingAssignments] = useState(false);

  const {open, poolUser} = useSelector(
    (state) => state.viewMachineAssignmentDialog
  );
  const {data: logs, isFetching: isFetchingLogs} = useSelector((state) =>
    selectData(state, ACTIVITY_LOGS)
  );
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {poolId} = useSelector((state) => selectSelectedPool(state));

  const handleClose = () => {
    dispatch(closeViewMachineAssignmentDialog());
  };

  const getUpdatedMachine = (log) => {
    const data = log.data || {};
    return data.machine || {};
  };

  const formatMachineLogs = (data) =>
    data.map((log) => ({
      createdOn: log.createdOn || '',
      machine: getUpdatedMachine(log),
    }));

  const filterUserLogs = (data, user) =>
    data.filter(
      (logMachine) => logMachine.machine.assignedTo === user.userGuid
    );

  const getMostRecentAssignmentLog = (data, user) => {
    if (data && user) {
      const logMachines = formatMachineLogs(data);
      const applicableLogs = filterUserLogs(logMachines, user);
      return applicableLogs[0] || {};
    }
    return {};
  };

  const getLogAssignedTo = (log) => {
    const machine = log.machine || {};
    return machine.assignedTo || '';
  };

  const getUserAssignmentLog = (data, user) => {
    if (data && user) {
      const mostRecentLog = getMostRecentAssignmentLog(data, user);
      const assignedTo = getLogAssignedTo(mostRecentLog);
      return assignedTo ? mostRecentLog : {};
    }

    return {};
  };

  const fetchExistingAssignments = async () => {
    setIsFetchingAssignments(true);

    const path = mapResourceToPath(POOL_MACHINES, {deploymentId, poolId});
    const params = {assignedTo: poolUser.userGuid};
    const response = await doSafeRequest(get, {path, params});

    if (response.status === 'success') {
      const assignedMachines = response.data || [];
      setExistingAssignments(assignedMachines);
    } else {
      dispatch(handleApiError(response));
    }

    setIsFetchingAssignments(false);
  };

  useEffect(() => {
    if (open) {
      dispatch(clearResource(ACTIVITY_LOGS));
      dispatch(
        fetchResource(ACTIVITY_LOGS, {
          operation: UPDATE_POOL_MACHINE,
          sortAsc: false,
          sortKey: 'createdOn',
          page: 0,
          rowsPerPage: 100,
        })
      );
    }
  }, [open]);

  useEffect(() => {
    if (open && deploymentId && poolId) {
      fetchExistingAssignments();
    }
  }, [open, deploymentId, poolId]);

  const renderNoAssignmentsText = () => (
    <Typography>No machine assignments found in the current pool.</Typography>
  );

  const renderNoLogDataText = () => (
    <Typography>
      No log data found for user&apos;s machine assignment.
    </Typography>
  );

  const renderSpinner = () => (
    <Grid container direction="row" justifyContent="center">
      <CircularProgress size="2rem" />
    </Grid>
  );

  const renderUserAssignment = () => {
    if (!existingAssignments || !existingAssignments.length) {
      return renderNoAssignmentsText();
    }

    const assignmentLog = getUserAssignmentLog(logs, poolUser);
    const machine = assignmentLog.machine || {};
    const createdOn = assignmentLog.createdOn || '';

    if (!machine.machineId || !createdOn) {
      return renderNoLogDataText();
    }

    const matchingAssignment = existingAssignments.find(
      (assignment) => assignment.machineId === machine.machineId
    );

    const machineDisplayName =
      matchingAssignment.machineName || machine.machineId;

    return (
      <Typography>
        {`Assigned to ${machineDisplayName} on ${formatDateTime(createdOn)}.`}
      </Typography>
    );
  };

  const renderTitle = () => <DialogTitle>View Machine Assignment</DialogTitle>;

  const renderContent = () => (
    <DialogContent>
      {isFetchingLogs || isFetchingAssignments
        ? renderSpinner()
        : renderUserAssignment()}
    </DialogContent>
  );

  const renderActions = () => (
    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
    </DialogActions>
  );

  if (!open) return null;

  return (
    <Dialog open={open} onClose={handleClose}>
      {renderTitle()}
      {renderContent()}
      {renderActions()}
    </Dialog>
  );
}

export default ViewMachineAssignmentDialog;
