/* eslint-disable max-lines */
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {del, post} from 'api/Api';
import ProviderIcon from 'components/CAM/icons/ProviderIcon/ProviderIcon';
import usePendingChanges from 'hooks/usePendingChanges';
import useSnackbar from 'hooks/useSnackbar';
import NewElement from 'icons/new_element.svg';
import SuccessIcon from 'icons/SuccessIcon';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {
  handleApiError,
  receiveDeleteResource,
  requestDeleteResource,
} from 'redux/actions/dataActions';
import {CLOUD_SERVICE_ACCOUNTS, GCP} from 'utils/constants';
import {mapProviders} from 'utils/Mappings';
import {isEmpty, stripNull} from 'utils/utils';
import CredentialDropbox from '../credentials/CredentialDropbox';

const PREFIX = 'EditGCPCredentials';

const classes = {
  cloudCredentialContainer: `${PREFIX}-cloudCredentialContainer`,
  chevron: `${PREFIX}-chevron`,
  cloudCredentialHeader: `${PREFIX}-cloudCredentialHeader`,
  cloudCredentialTitle: `${PREFIX}-cloudCredentialTitle`,
  cloudCredentialStatus: `${PREFIX}-cloudCredentialStatus`,
  inputTextFieldLabel: `${PREFIX}-inputTextFieldLabel`,
  inputMultiline: `${PREFIX}-inputMultiline`,
  inputHidden: `${PREFIX}-inputHidden`,
  credentialInput: `${PREFIX}-credentialInput`,
  enabled: `${PREFIX}-enabled`,
  disabled: `${PREFIX}-disabled`,
  svcAccSubtitle: `${PREFIX}-svcAccSubtitle`,
  svcPermissionsItem: `${PREFIX}-svcPermissionsItem`,
  actionButton: `${PREFIX}-actionButton`,
  revertButton: `${PREFIX}-revertButton`,
  deleteButton: `${PREFIX}-deleteButton`,
  buttonRow: `${PREFIX}-buttonRow`,
  helpLink: `${PREFIX}-helpLink`,
  dropboxDivideContainer: `${PREFIX}-dropboxDivideContainer`,
  dropboxDivider: `${PREFIX}-dropboxDivider`,
  dropboxDivideText: `${PREFIX}-dropboxDivideText`,
  viewContainer: `${PREFIX}-viewContainer`,
  viewTextFieldLabel: `${PREFIX}-viewTextFieldLabel`,
  viewAccountText: `${PREFIX}-viewAccountText`,
  viewAccountField: `${PREFIX}-viewAccountField`,
  gcpPrivateKeyVisibilityIcon: `${PREFIX}-gcpPrivateKeyVisibilityIcon`,
  eyeball: `${PREFIX}-eyeball`,
  centeredText: `${PREFIX}-centeredText`,
  unstyledList: `${PREFIX}-unstyledList`,
  topMargin: `${PREFIX}-topMargin`,
  noPadding: `${PREFIX}-noPadding`,
  buttonCursor: `${PREFIX}-buttonCursor`,
};

const StyledPaper = styled(Paper)(({theme}) => ({
  [`&.${classes.cloudCredentialContainer}`]: {
    marginTop: '7px',
    padding: '0px 10px',
    boxShadow: 'none',
    border: '1px solid #ccc',
  },

  [`& .${classes.chevron}`]: {
    margin: 'auto 0px auto auto',
  },

  [`& .${classes.cloudCredentialHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    height: '2.25rem',
  },

  [`& .${classes.cloudCredentialTitle}`]: {
    flex: 1,
    marginLeft: '12px',
  },

  [`& .${classes.cloudCredentialStatus}`]: {
    margin: 0,
    fill: 'rgba(0,0,0,0.54)',
  },

  [`& .${classes.inputTextFieldLabel}`]: {
    color: theme.palette.info.main,
    fontFamily: '.SF NS Display',
    fontSize: '0.75rem',
    lineHeight: '15px',
    marginTop: '4px',
  },

  [`& .${classes.inputMultiline}`]: {
    padding: '8px 10px',
  },

  [`& .${classes.inputHidden}`]: {
    padding: '8px 0px',
  },

  [`& .${classes.credentialInput}`]: {
    margin: '0px',
  },

  [`& .${classes.enabled}`]: {color: theme.palette.success.main},
  [`& .${classes.disabled}`]: {color: theme.palette.error.main},

  [`& .${classes.svcAccSubtitle}`]: {
    margin: '12px 0px',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '500',
    color: theme.palette.surface.grey,
  },

  [`& .${classes.svcPermissionsItem}`]: {
    marginLeft: '16px',
  },

  [`& .${classes.actionButton}`]: {
    margin: '10px 0px',
  },

  [`& .${classes.revertButton}`]: {
    padding: '4px',
  },

  [`& .${classes.deleteButton}`]: {
    color: theme.palette.surface.grey,
  },

  [`& .${classes.buttonRow}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },

  [`& .${classes.helpLink}`]: {
    height: '1rem',
    color: theme.palette.surface.grey,
    fontFamily: 'Roboto',
    fontSize: '0.625rem',
    letterSpacing: '0.33px',
    lineHeight: '1rem',
    textDecoration: 'underline dotted',
    paddingLeft: '18px',
  },

  [`& .${classes.dropboxDivideContainer}`]: {
    alignItems: 'center',
    marginTop: '12px',
  },

  [`& .${classes.dropboxDivider}`]: {
    color: theme.palette.divider,
  },

  [`& .${classes.dropboxDivideText}`]: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '1.25px',
    lineHeight: '1rem',
    textAlign: 'center',
  },

  [`& .${classes.viewContainer}`]: {
    marginBottom: '10px',
  },

  [`& .${classes.viewTextFieldLabel}`]: {
    color: '#706E6B',
    fontFamily: '.SF NS Display',
    fontSize: '0.75rem',
    lineHeight: '15px',
    marginTop: '16px',
  },

  [`& .${classes.viewAccountText}`]: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    letterSpacing: '0.4px',
    lineHeight: '16px',
    margin: '22px 15px 38px',
  },

  [`& .${classes.viewAccountField}`]: {
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '1.25px',
    lineHeight: '16px',
    marginTop: '12px',
  },

  [`& .${classes.gcpPrivateKeyVisibilityIcon}`]: {
    marginRight: '5px',
  },

  [`& .${classes.eyeball}`]: {
    fontSize: '18px',
  },

  [`& .${classes.centeredText}`]: {textAlign: 'center'},
  [`& .${classes.unstyledList}`]: {listStyleType: 'none'},
  [`& .${classes.topMargin}`]: {marginTop: '12px'},
  [`& .${classes.noPadding}`]: {padding: 0},
  [`& .${classes.buttonCursor}`]: ({isLoading}) => ({
    cursor: isLoading ? 'wait' : 'pointer',
  }),
}));

const GCP_GRANT_ROLES_SUPPORT_LINK =
  'https://cloud.google.com/iam/docs/granting-roles-to-service-accounts';
const GCP_ENABLE_API_SUPPORT_LINK =
  'https://cloud.google.com/apis/docs/enable-disable-apis';
const EDIT = 'edit';
const VIEW = 'view';

function EditGCPCredentials({
  handleCredentialsChangeCallback,
  gcpCredentials,
  deploymentId,
  credentialId,
  isLoading,
}) {
  const dispatch = useDispatch();
  const {setPendingChanges} = usePendingChanges();
  const {successSnackbar, errorSnackbar} = useSnackbar();

  const [showCredentialFields, setShowCredentialFields] = useState(false);
  const [gcpEmail, setGcpEmail] = useState('');
  const [gcpProjectId, setGcpProjectId] = useState('');
  const [gcpPrivateKey, setGcpPrivateKey] = useState('');
  const [gcpPrivateKeyVisibility, setGcpPrivateKeyVisibility] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const [displayedSvcAccPermissions, setDisplayedSvcAccountPermissions] =
    useState(null);
  const [displayFile, setDisplayFile] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [hasCredentials, setHasCredentials] = useState(false);
  const [displayMode, setDisplayMode] = useState(EDIT);
  const dataUpdated = !(
    gcpEmail === gcpCredentials.clientEmail &&
    gcpProjectId === gcpCredentials.projectId &&
    !gcpPrivateKey
  );

  // Show update icon if deployment has provider service account, otherwise show add icon.
  const [credentialStatus, setCredentialStatus] = useState('add');

  const handleClickShowPrivateKey = () =>
    setGcpPrivateKeyVisibility(!gcpPrivateKeyVisibility);
  const handleMouseDownShowGcpPrivateKey = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setPendingChanges(false);
  }, []);

  useEffect(() => {
    let pendingChanges = false;
    if (hasCredentials) {
      pendingChanges = dataUpdated;
    } else if (
      !isEmpty(gcpEmail) ||
      !isEmpty(gcpProjectId) ||
      !isEmpty(gcpPrivateKey)
    ) {
      pendingChanges = true;
    }

    setPendingChanges(pendingChanges);
  }, [gcpEmail, gcpProjectId, gcpPrivateKey, isSaving, dataUpdated]);

  useEffect(() => {
    if (gcpCredentials && gcpCredentials.projectId) {
      setGcpEmail(gcpCredentials.clientEmail || '');
      setGcpProjectId(gcpCredentials.projectId || '');
      setGcpPrivateKey('');
      setHasCredentials(true);
      setDisplayMode(VIEW);
      setCredentialStatus('valid');
    } else {
      setHasCredentials(false);
      setCredentialStatus('add');
    }
  }, [gcpCredentials]);

  const clearForm = () => {
    setGcpEmail('');
    setGcpProjectId('');
    setGcpPrivateKey('');
    setDisplayFile(null);
    setDisplayMode(EDIT);
    setHasCredentials(false);
  };

  /** Sanitize user entered private key removing comment header, footer and newlines.
   * This is necessary to avoid the request from being blocked by Teradici`s firewall. */
  const sanitizePrivateKey = (pKey) => {
    let key = pKey;

    // Deal with newline characters inside the private key
    key = key.replace(/\\n/g, '\n');
    key = key.replace(/\\r/g, '\r');

    key = key.replace('-----BEGIN PRIVATE KEY-----\n', '');
    key = key.replace('\n-----END PRIVATE KEY-----\n', '');
    key = key.trim();
    return key;
  };

  const receiveCredentialsFromFile = (credObject) => {
    setDisplayMode(EDIT);
    setGcpEmail(credObject.gcpEmail || '');
    setGcpProjectId(credObject.gcpProjectId || '');
    setGcpPrivateKey(sanitizePrivateKey(credObject.gcpPrivateKey || ''));
  };

  /** Returns false if the API is disabled or missing permissions,
   *  Modifies the actionList provided to contain help text describing
   *  the failure and a link to a support page from GCP.  */
  const verifyPermissions = (api, actionList = []) => {
    let rv = true;
    if (!api.enabled) {
      rv = false;
      actionList.push({
        helpText: `Enable ${api.title}`,
        helpLink: GCP_ENABLE_API_SUPPORT_LINK,
        linkDescription: 'Learn how to enable API',
      });
    } else if (api.roles) {
      api.roles.forEach((role) => {
        if (!role.enabled) {
          rv = false;
          actionList.push({
            helpText: `Add ${role.title} role to service account`,
            helpLink: GCP_GRANT_ROLES_SUPPORT_LINK,
            linkDescription: 'Learn how to grant roles to service account',
          });
        }
      });
    }
    return rv;
  };

  const formatServiceAccountPermissions = (permissions) => {
    const requiredActions = [];

    const displayedApiAccess = permissions.map((api) => (
      <Grid container alignItems="center" key={api.title}>
        <Grid item xs={8}>
          <Typography className={classes.svcPermissionsItem}>
            {api.title}
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.centeredText}>
          {verifyPermissions(api, requiredActions) ? (
            <SuccessIcon />
          ) : (
            <ErrorIcon className={classes.disabled} />
          )}
        </Grid>
      </Grid>
    ));
    const displayedActions = requiredActions.map((action) => (
      <Typography key={action.helpText} className={classes.svcPermissionsItem}>
        <li>{`${action.helpText}`}</li>
        <li className={classes.unstyledList}>
          <Link
            href={action.helpLink}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.helpLink}
          >
            {`${action.linkDescription}`}
          </Link>
        </li>
      </Typography>
    ));

    setDisplayedSvcAccountPermissions(
      <>
        <Grid container>
          <Grid item xs={8}>
            <Typography className={classes.svcAccSubtitle}>
              Required APIs
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" className={classes.svcAccSubtitle}>
              Access available
            </Typography>
          </Grid>
        </Grid>
        {displayedApiAccess}
        <Divider className={classes.topMargin} />
        <Typography className={classes.svcAccSubtitle}>
          Perform the following actions and try again:
        </Typography>
        {displayedActions}
      </>
    );
  };

  /** Validate credential using API */
  const gcpValidateCredential = async () => {
    setIsSaving(true);
    // Call API to validate user credentials
    let credentialsValidated = false;
    try {
      await post({
        path: 'auth/users/cloudServiceAccount/validate',
        data: {
          provider: 'gcp',
          credential: {
            clientEmail: stripNull(gcpEmail),
            projectId: stripNull(gcpProjectId),
            privateKey: stripNull(gcpPrivateKey),
          },
        },
      });
      credentialsValidated = true;
    } catch (error) {
      if ([400, 403].includes(error.code)) {
        if (error.data && error.data.condition) {
          formatServiceAccountPermissions(error.data.condition);
          setDialogMessage('Missing account access');
        } else {
          setDialogMessage('Invalid credentials');
          setDisplayedSvcAccountPermissions(null);
        }
        setDialogOpen(true);
      } else {
        dispatch(handleApiError(error));
      }
    }
    setIsSaving(false);
    return credentialsValidated;
  };

  const handleDelete = async () => {
    setIsSaving(true);
    // Call API to validate user credentials
    const path = `deployments/${deploymentId}/cloudServiceAccounts/${credentialId}`;
    try {
      dispatch(requestDeleteResource(CLOUD_SERVICE_ACCOUNTS, credentialId));
      await del({path});
      dispatch(receiveDeleteResource(CLOUD_SERVICE_ACCOUNTS, credentialId));

      setCredentialStatus('add');
      successSnackbar('Provider service account successfully deleted.');
      clearForm();
    } catch (error) {
      errorSnackbar('Provider service account could not be deleted.');
    }
    setIsSaving(false);
    setPendingChanges(false);
  };

  const handleSubmit = async () => {
    if (await gcpValidateCredential()) {
      setIsSaving(true);
      const credential = {
        clientEmail: stripNull(gcpEmail),
        projectId: stripNull(gcpProjectId),
        privateKey: stripNull(gcpPrivateKey),
      };
      try {
        const path = `deployments/${deploymentId}/cloudServiceAccounts`;
        const response = await post({
          path,
          data: {
            provider: 'gcp',
            credential,
          },
        });

        const keyId = response.data.id;
        setCredentialStatus('valid');
        handleCredentialsChangeCallback(credential, keyId);
        setDisplayMode(VIEW);
        successSnackbar('Provider service account added.');
        setHasCredentials(true);
        setPendingChanges(false);
      } catch (error) {
        errorSnackbar('Provider service account could not be added.');
      }
      setIsSaving(false);
    }
  };

  /** Handle every text input change.
   * The name of the text input must match the name of the state being updated. */
  const handlePrivateKeyChange = (event) => {
    const keyValue = sanitizePrivateKey(event.target.value);
    setGcpPrivateKey(keyValue);
  };

  const handleExpand = () => {
    setShowCredentialFields(!showCredentialFields);
  };

  /** Return true if action button must be disabled */
  const disableSubmit = () =>
    !(gcpEmail && gcpProjectId && gcpPrivateKey) || isSaving || !dataUpdated;

  const disableDelete = () => !(hasCredentials && credentialId) || isSaving;

  const disableClear = () =>
    !(gcpEmail || gcpProjectId || gcpPrivateKey) || isSaving;

  const statusIcon = (status) => {
    const iconLoading = <CircularProgress size={16} />;
    if (isLoading) {
      return iconLoading;
    }
    switch (status) {
      case 'add':
        return (
          <img
            src={NewElement}
            className={classes.cloudCredentialStatus}
            alt=""
          />
        );
      case 'valid':
        return <CheckIcon className={classes.cloudCredentialStatus} />;
      default:
        return null;
    }
  };

  const handleDeleteButtonClick = () => {
    dispatch(
      openDialog(
        'Delete provider service account?',
        "Are you sure you want to delete your provider service account? You won't be able to power manage workstations without it.",
        handleDelete
      )
    );
  };

  return (
    <StyledPaper className={classes.cloudCredentialContainer}>
      <div
        role="button"
        className={classNames(
          classes.cloudCredentialHeader,
          classes.buttonCursor
        )}
        onClick={() => (isLoading ? null : handleExpand())}
      >
        {statusIcon(credentialStatus)}
        <Typography className={classes.cloudCredentialTitle}>
          <Grid container>
            <ProviderIcon provider={GCP} />
            {mapProviders(GCP)}
          </Grid>
        </Typography>

        <IconButton
          data-testid="expand-gcp-credential"
          onClick={handleExpand}
          disableRipple
          disabled={isLoading}
          className={classes.noPadding}
          size="large"
        >
          {showCredentialFields ? (
            <KeyboardArrowUp className={classes.chevron} />
          ) : (
            <KeyboardArrowRight className={classes.chevron} />
          )}
        </IconButton>
      </div>

      {showCredentialFields && (
        <>
          {credentialStatus !== 'valid' && (
            <>
              <CredentialDropbox
                cloudProvider="gcp"
                setCredentialCallback={receiveCredentialsFromFile}
                setDisplayFile={setDisplayFile}
              />
              {displayFile}
              <Grid container className={classes.dropboxDivideContainer}>
                <Grid item xs={5}>
                  <Divider className={classes.dropboxDivider} />
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.dropboxDivideText}>
                    OR
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Divider className={classes.dropboxDivider} />
                </Grid>
              </Grid>
            </>
          )}

          <Typography className={classes.inputTextFieldLabel}>
            Client email
          </Typography>
          <TextField
            data-testid="gcp-email"
            placeholder="Enter GCP client e-mail"
            fullWidth
            maxRows={2}
            margin="normal"
            variant="outlined"
            name="gcpEmail"
            value={gcpEmail}
            onChange={(event) => setGcpEmail(event.target.value)}
            multiline
            disabled={credentialStatus === 'valid' || isSaving}
            className={classes.credentialInput}
            size="small"
            // InputProps={{ {multiline: classes.inputMultiline}}}
          />
          <Typography className={classes.inputTextFieldLabel}>
            Project Id
          </Typography>
          <TextField
            data-testid="gcp-project-id"
            placeholder="Enter GCP project id"
            fullWidth
            maxRows={2}
            margin="normal"
            variant="outlined"
            name="gcpProjectId"
            value={gcpProjectId}
            onChange={(event) => setGcpProjectId(event.target.value)}
            multiline
            disabled={credentialStatus === 'valid' || isSaving}
            className={classes.credentialInput}
            size="small"
            // InputProps={{ {multiline: classes.inputMultiline}}}
          />

          {credentialStatus !== 'valid' && (
            <>
              <Typography className={classes.inputTextFieldLabel}>
                Private Key
              </Typography>
              <TextField
                data-testid="gcp-private-key"
                placeholder="Enter GCP private key"
                fullWidth
                maxRows={4}
                variant="outlined"
                margin="normal"
                name="gcpPrivateKey"
                value={gcpPrivateKey}
                onChange={handlePrivateKeyChange}
                className={classes.credentialInput}
                type={gcpPrivateKeyVisibility ? 'text' : 'password'}
                disabled={credentialStatus === 'valid' || isSaving}
                size="small"
                InputProps={{
                  className: classes.inputHidden,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className={classes.gcpPrivateKeyVisibilityIcon}
                        size="small"
                        data-testid="gcp-private-key-toggle"
                        onClick={handleClickShowPrivateKey}
                        onMouseDown={handleMouseDownShowGcpPrivateKey}
                      >
                        {gcpPrivateKeyVisibility ? (
                          <VisibilityOff className={classes.eyeball} />
                        ) : (
                          <Visibility className={classes.eyeball} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <div className={classes.buttonRow}>
                <Button
                  data-testid="gcp-submit"
                  color="primary"
                  disabled={disableSubmit()}
                  onClick={handleSubmit}
                  // className={classes.actionButton}
                >
                  Submit
                </Button>
                <Button
                  data-testid="gcp-clear"
                  disabled={disableClear()}
                  onClick={clearForm}
                  className={classNames(
                    classes.deleteButton,
                    classes.actionButton
                  )}
                >
                  Clear
                </Button>
              </div>
            </>
          )}

          {displayMode !== EDIT && (
            <div className={classes.buttonRow}>
              <Button
                data-testid="gcp-delete"
                disabled={disableDelete()}
                onClick={handleDeleteButtonClick}
                className={classNames(
                  classes.deleteButton,
                  classes.actionButton
                )}
              >
                Delete
              </Button>
            </div>
          )}

          {dialogOpen && (
            <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              data-testid="gcp-dialog"
            >
              <DialogContent>
                <DialogContentText
                  data-testid="gcp-dialog-text"
                  variant="h6"
                  color="inherit"
                >
                  {dialogMessage}
                </DialogContentText>
                {displayedSvcAccPermissions}
              </DialogContent>
              <DialogActions>
                <Button
                  data-testid="gcp-dialog-button-ok"
                  onClick={() => setDialogOpen(false)}
                  color="primary"
                  autoFocus
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </>
      )}
    </StyledPaper>
  );
}

EditGCPCredentials.propTypes = {
  handleCredentialsChangeCallback: PropTypes.func,
  gcpCredentials: PropTypes.object,
  deploymentId: PropTypes.string,
  credentialId: PropTypes.string,
  isLoading: PropTypes.bool,
};

EditGCPCredentials.defaultProps = {
  handleCredentialsChangeCallback: () => {},
  gcpCredentials: {},
  deploymentId: '',
  credentialId: '',
  isLoading: false,
};

export default EditGCPCredentials;
