import {SnackbarProvider} from 'notistack';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import Notifier from './Notifier';

function NotificationManager({children}) {
  return (
    <SnackbarProvider
      maxSnack={3}
      preventDuplicate
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
      TransitionComponent={Fade}
    >
      <Notifier />
      {children}
    </SnackbarProvider>
  );
}

NotificationManager.propTypes = {
  children: PropTypes.element,
};

NotificationManager.defaultProps = {
  children: null,
};

export default NotificationManager;
