import {get} from 'api/Api';
import {useQuery} from 'react-query';

const getRoles = async () => {
  const {data} = await get({path: 'roles'});
  return data;
};

const useRoles = (options = {}) =>
  useQuery(['roles'], () => getRoles(), options);

export default useRoles;
