import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  IconButton,
  Table,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KebabIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import KeyValueInfo from 'components/CAM/text/KeyValueInfo/KeyValueInfo';
import {REMOTE_WORKSTATIONS} from 'utils/constants';
import {mapResourceToIdField, mapResourceToNameField} from 'utils/Mappings';
import ExpansionTableHead from './ExpansionTableHead';

const PREFIX = 'ExpansionTable';

const classes = {
  root: `${PREFIX}-root`,
  expanded: `${PREFIX}-expanded`,
  summaryRoot: `${PREFIX}-summaryRoot`,
  content: `${PREFIX}-content`,
  rightMargin: `${PREFIX}-rightMargin`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 'auto',
    },
  },

  [`& .${classes.expanded}`]: {},

  [`& .${classes.summaryRoot}`]: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    height: 43,
    padding: '0 24px 0 11px',
    minHeight: 43,
    '&.Mui-expanded': {
      minHeight: 43,
    },
  },

  [`& .${classes.content}`]: {
    '&.Mui-expanded': {
      margin: '12px 0',
    },
  },

  [`& .${classes.rightMargin}`]: {marginRight: '1rem'}
}));

function ExpansionTable({
  data,
  resource,
  displayField,
  onClickKebab,
  handleCheckbox,
  handleCheckAll,
  allChecked,
  indeterminateChecked,
  hideActions,
  hideBulkActions,
  checkedRows,
}) {


  const defaultObject = {};

  const headers = useSelector(
    (state) => state.tableColumns[resource] || defaultObject
  );

  const idField = mapResourceToIdField(resource);
  let nameField = mapResourceToNameField(resource);
  // TSW-204387 This workaround is added so that expansion table don't break when the resource is REMOTE_WORKSTATIONS
  // modifying mapResourceToNameField doesn't work since it will break searching machine name functionality.
  // Seems like there is a special case where this expect the field to be called 'displayMachineName' when the resource is REMOTE_WORKSTATIONS
  if (resource === REMOTE_WORKSTATIONS) {
    nameField = 'displayMachineName';
  }

  const isRowChecked = (row) => {
    const id = row[idField];
    return id in checkedRows && !['deleting', 'deleted'].includes(row.status);
  };

  return (
    (<Root>
      <Table>
        <ExpansionTableHead
          hideBulkActions={hideBulkActions}
          handleCheckAll={handleCheckAll}
          allChecked={allChecked}
          indeterminateChecked={indeterminateChecked}
        />
      </Table>
      {data.map((row, i) => (
        <Accordion
          key={row[idField]}
          square
          classes={{
            root: classes.root,
            expanded: classes.expanded,
          }}
        >
          <AccordionSummary
            classes={{
              root: classes.summaryRoot,
              expanded: classes.expanded,
              content: classes.content,
            }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container alignItems="center">
                  {!hideBulkActions && (
                    <Grid item>
                      <Checkbox
                        onChange={(e, checked) =>
                          handleCheckbox(row, checked, e, i)
                        }
                        onClick={(e) => e.stopPropagation()}
                        checked={isRowChecked(row)}
                        disabled={['deleting', 'deleted'].includes(row.status)}
                        color="primary"
                        size="small"
                        className={classes.rightMargin}
                      />
                    </Grid>
                  )}

                  <Grid item>{displayField(row, headers[nameField])}</Grid>
                </Grid>
              </Grid>
              {!hideActions && (
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={(event) => onClickKebab(event, row)}
                  >
                    <KebabIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container direction="column">
              {Object.values(headers).map((header) => (
                <KeyValueInfo
                  key={header.id}
                  label={header.label}
                  type={header.type}
                  value={displayField(row, header)}
                />
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Root>)
  );
}

ExpansionTable.propTypes = {
  data: PropTypes.array,
  resource: PropTypes.string,
  displayField: PropTypes.func,
  onClickKebab: PropTypes.func,
  handleCheckbox: PropTypes.func,
  handleCheckAll: PropTypes.func,
  allChecked: PropTypes.bool,
  indeterminateChecked: PropTypes.bool,
  hideActions: PropTypes.bool,
  hideBulkActions: PropTypes.bool,
  checkedRows: PropTypes.object,
};

ExpansionTable.defaultProps = {
  data: [],
  resource: REMOTE_WORKSTATIONS,
  displayField: (field) => field,
  onClickKebab: () => {},
  handleCheckbox: () => {},
  handleCheckAll: () => {},
  allChecked: false,
  indeterminateChecked: false,
  hideActions: false,
  hideBulkActions: false,
  checkedRows: {},
};

export default ExpansionTable;
