import {Grid, MenuItem} from '@mui/material';
import PropTypes from 'prop-types';
import {StyledLabelText, StyledTextField} from './StyledTextField';

export function TriggerEventDropdown({
  value,
  onChange,
  error,
  onBlur,
  helperText,
}) {
  const events = [
    {value: 'session-start', label: 'Session start'},
    {value: 'session-end', label: 'Session end'},
    {value: 'allocate-resource', label: 'Allocate resource'},
  ];

  return (
    <Grid container direction="column" gap={0.5}>
      <StyledLabelText>Trigger event</StyledLabelText>
      <StyledTextField
        error={error}
        helperText={helperText}
        onBlur={onBlur}
        data-testid="select-trigger-event"
        select
        variant="outlined"
        size="small"
        value={value}
        InputLabelProps={value ? {} : {shrink: false}}
        label={value ? '' : 'Select'}
        onChange={onChange}
      >
        {events.map((event) => (
          <MenuItem
            key={event.value}
            value={event.value}
            disabled={event.disabled}
            data-testid={`select-trigger-event-${event.value}`}
          >
            <Grid container alignItems="center">
              {event.label}
            </Grid>
          </MenuItem>
        ))}
      </StyledTextField>
    </Grid>
  );
}

TriggerEventDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  onBlur: PropTypes.func,
  helperText: PropTypes.string,
};

TriggerEventDropdown.defaultProps = {
  value: '',
  onChange: () => {},
  error: false,
  onBlur: () => {},
  helperText: '',
};
