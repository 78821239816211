import {mapResourceToIdField} from 'utils/Mappings';
import {
  LOAD_TABLE_DATA,
  UPDATE_TABLE_FETCH_PARAMS,
  CLEAR_TABLE_FETCH_PARAMS,
} from 'redux/actions/tableDataActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';
import {DEFAULT_ROWS_PER_PAGE} from 'utils/constants';

const defaultState = {};

function tableDataByResource(
  state = {
    data: [],
    page: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    total: 0,
    searchTerm: '',
    sortKey: '',
    sortAsc: true,
    params: {},
  },
  action
) {
  switch (action.type) {
    case LOAD_TABLE_DATA:
      return {
        ...state,
        data: action.data.map(
          (item) => item[mapResourceToIdField(action.resource)]
        ),
        page: action.page,
        rowsPerPage: action.rowsPerPage,
        total: action.total,
        searchTerm: action.searchTerm,
        sortKey: action.sortKey,
        sortAsc: action.sortAsc,
        params: action.params,
      };
    case UPDATE_TABLE_FETCH_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case CLEAR_TABLE_FETCH_PARAMS:
      return {
        ...state,
        params: {},
      };
    default:
      return state;
  }
}

/* This reducer contains information about what data
/* should be displayed in the tables for each resource.
/*
/* For each resource, we store:
/*   data: array
/*     This contains a list of ids that point to what
/*     records we would like to display in the table.
/*     We store the ids rather than the objects themselves
/*     to decouple the data from the table. This gives us
/*     the flexibility to update records without having to
/*     load new table data.
/*
/* In the future, we could expand this reducer to make the data
/* more reusable.
*/

function tableDataReducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_TABLE_DATA:
    case UPDATE_TABLE_FETCH_PARAMS:
    case CLEAR_TABLE_FETCH_PARAMS:
      return {
        ...state,
        [action.resource]: tableDataByResource(state[action.resource], action),
      };
    case CLEAR_STORE:
      return defaultState;
    default:
      return state;
  }
}

export default tableDataReducer;
