import {Fragment} from 'react';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {stylesFromFigma} from 'themes/stylesFromFigma';

const PREFIX = 'ResourcePropertyBar';

const classes = {
  card: `${PREFIX}-card`,
  item: `${PREFIX}-item`,
  container: `${PREFIX}-container`,
};

const StyledPaper = styled(Paper)(() => ({
  [`&.${classes.card}`]: {
    ...stylesFromFigma.card,
    padding: '16px 24px',
    minWidth: '270px',
    marginBottom: '24px',
  },
  [`& .${classes.item}`]: {minWidth: '240px'},
  [`& .${classes.container}`]: {
    width: 'auto',
  },
}));

function ResourcePropertyBar({children}) {
  const childrenList = Array.isArray(children) ? children : [children];

  return (
    <StyledPaper className={classes.card} variant="outlined">
      <Grid
        container
        className={classes.container}
        justifyContent="space-between"
        rowSpacing={2}
      >
        {childrenList.map((child) => (
          <Fragment key={`${child.props.label}-resource-fragment`}>
            <Grid item xs={12} sm={6} md={4} lg={2} className={classes.item}>
              {child}
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </StyledPaper>
  );
}

ResourcePropertyBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default ResourcePropertyBar;
