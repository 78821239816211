import {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useSelector} from 'react-redux';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleSwitch from 'components/CAM/buttons/ToggleSwitch/ToggleSwitch';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import {CONFIGURATION_CAPABILITY_NAME} from 'utils/constants';
import {isEmpty} from 'utils/utils';
import CopyAwcCommand from '../createWizard/CopyAwcCommand';
import {
  useWizardState,
  generateAwcCommand,
} from '../createWizard/wizardConfigState';
import {configServiceEnableParams} from '../createWizard/wizardConstants';
import CapabilityStatusDisplay from './CapabilityStatus';

const PREFIX = 'CertificatesTab';

const classes = {
  root: `${PREFIX}-root`,
  padding: `${PREFIX}-padding`,
  flexRow: `${PREFIX}-flexRow`,
  fileRow: `${PREFIX}-fileRow`,
  propTitle: `${PREFIX}-propTitle`,
  statusIcon: `${PREFIX}-statusIcon`,
  statusChip: `${PREFIX}-statusChip`,
  label: `${PREFIX}-label`,
  bodyText: `${PREFIX}-bodyText`,
  subheader: `${PREFIX}-subheader`,
  subheader2: `${PREFIX}-subheader2`,
  form: `${PREFIX}-form`,
  textField: `${PREFIX}-textField`,
  subHeading: `${PREFIX}-subHeading`,
  errorText: `${PREFIX}-errorText`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    margin: 'auto',
    maxWidth: '1220px',
  },

  [`& .${classes.padding}`]: {
    padding: '16px',
  },

  [`& .${classes.flexRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10px 0px',
    '&:first-child': {
      marginTop: '0px',
    },
    padding: '16px',
  },

  [`& .${classes.fileRow}`]: {
    padding: '8px 0px',
  },

  [`& .${classes.propTitle}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '200px',
  },

  [`& .${classes.statusIcon}`]: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginRight: '16.5px',
  },

  [`& .${classes.statusChip}`]: {
    backgroundColor: '#EBF3FA',
    color: '#1E3BB8',
  },

  [`& .${classes.label}`]: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: 'rgba(0, 0, 0, 0.87)',
  },

  [`& .${classes.bodyText}`]: {
    ...stylesFromFigma.connectorPanelBodyText,
    padding: '16px',
  },

  [`& .${classes.subheader}`]: {
    ...stylesFromFigma.capabilitySubHeader,
    padding: '16px',
  },

  [`& .${classes.subheader2}`]: {
    ...stylesFromFigma.capabilitySubHeader2,
    padding: '16px',
  },

  [`& .${classes.form}`]: {marginTop: '12px'},

  [`& .${classes.textField}`]: {
    width: '100%',
    maxWidth: '1000px',
    margin: '6px 0px',
    padding: '16px',
  },

  [`& .${classes.subHeading}`]: {},
  [`& .${classes.errorText}`]: {color: 'red'},
}));

function ConfigServiceTab() {
  const [allowCommandCopy, setAllowCommandCopy] = useState(false);
  const [configureCommand, setConfigureCommand] = useState('');
  const {configState, setConfigState} = useWizardState();
  const selectedConnector = useSelector(
    (state) => state?.data?.dataByResource?.selectedConnector
  );

  const capabilityList = selectedConnector?.capabilities || [];
  const capabilityData = capabilityList.find(
    (cap) => cap.capabilityName === CONFIGURATION_CAPABILITY_NAME
  );

  const createConfigureCommand = () => {
    const fullCommand = generateAwcCommand({
      configParamArray: configServiceEnableParams,
      configState,
    });
    setConfigureCommand(fullCommand);
  };

  // Update configure command any time one of the config params change
  useEffect(() => {
    createConfigureCommand();
    setAllowCommandCopy(true);
  }, [...configServiceEnableParams.map((param) => configState[param.fieldName])]);

  const toggleSwitch = (
    label,
    name,
    toggleClickable = true,
    hoverText = ''
  ) => (
    <FormControl>
      <FormControlLabel
        label={label}
        title={hoverText}
        control={
          <ToggleSwitch
            name={name}
            title={hoverText}
            isOn={configState[name]}
            onClick={(event) => {
              const updatedState = {...configState};
              const {name: eventName, checked} = event.target;
              updatedState[eventName] = checked;
              setConfigState(updatedState);
            }}
            disabled={!toggleClickable}
          />
        }
      />
    </FormControl>
  );

  return (
    <Root className={classes.root}>
      <div className={classes.padding}>
        <CapabilityStatusDisplay
          capabilityData={capabilityData}
          isLoading={isEmpty(selectedConnector)}
        />
      </div>
      <Typography className={classes.subheader}>Configuration Service</Typography>
      <Typography className={classes.bodyText}>
        Enable the Configuration Service to allow Connector configuration changes to be
        applied automatically via Admin Console.
      </Typography>

      {toggleSwitch('Enable Configuration Service', 'enableConfigurationService')}

      <CopyAwcCommand
        isCopyEnabled={allowCommandCopy}
        commandToCopy={configureCommand}
        desiredState={configState}
        isApplyEnabled={false}
        commandType="configure"
        configParamArray={configServiceEnableParams}
      />
    </Root>
  );
}

export default ConfigServiceTab;
