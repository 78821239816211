import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FileIcon from '@mui/icons-material/AttachFile';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import checkSvg from 'icons/check-outlined.svg';
import errorSvg from 'icons/error-outlined.svg';
import UploadIcon from 'icons/UploadIcon';

const PREFIX = 'FileInputDropbox';

const classes = {
  root: `${PREFIX}-root`,
  dropbox: `${PREFIX}-dropbox`,
  dropboxActive: `${PREFIX}-dropboxActive`,
  gridContainer: `${PREFIX}-gridContainer`,
  dropboxDivideContainer: `${PREFIX}-dropboxDivideContainer`,
  gridItem: `${PREFIX}-gridItem`,
  fileRow: `${PREFIX}-fileRow`,
  buttonText: `${PREFIX}-buttonText`,
  dropText: `${PREFIX}-dropText`,
  fileIcon: `${PREFIX}-fileIcon`,
  statusIcon: `${PREFIX}-statusIcon`,
  errorText: `${PREFIX}-errorText`
};

const Root = styled('span')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    padding: '16px',
  },

  [`& .${classes.dropbox}`]: {
    width: '100%',
    boxSizing: 'border-box',
    border: '1px solid #DDDBDA',
    borderRadius: '4px',
  },

  [`& .${classes.dropboxActive}`]: {
    border: '1px solid #0076DE',
    boxShadow: '0 0 3px 0 rgba(0,118,222,0.5)',
  },

  [`& .${classes.gridContainer}`]: {
    padding: '4px',
  },

  [`& .${classes.dropboxDivideContainer}`]: {
    alignItems: 'center',
    marginTop: '12px',
  },

  [`& .${classes.gridItem}`]: {
    margin: 'auto 0px',
  },

  [`& .${classes.fileRow}`]: {
    display: 'flex',
    margin: '4px 0px',
    alignItems: 'center',
  },

  [`& .${classes.buttonText}`]: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    letterSpacing: '0.25px',
    marginLeft: '8px',
    textTransform: 'none',
  },

  [`& .${classes.dropText}`]: {
    marginLeft: '8px',
    fontSize: '0.75rem',
  },

  [`& .${classes.fileIcon}`]: {
    color: theme.palette.surface.grey,
    marginRight: '4px',
  },

  [`& .${classes.statusIcon}`]: {
    marginLeft: 'auto',
  },

  [`& .${classes.errorText}`]: {
    color: 'rgba(255,4,4,0.87)',
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    letterSpacing: '0.21px',
    lineHeight: '20px',
  }
}));

function FileInputDropbox({fileInputParameters, credentialCallback}) {


  const [data, setData] = useState('');
  const [file, setFile] = useState({});
  const [rejectReason, setRejectReason] = useState('');
  const [fileSubmited, setFileSubmited] = useState(false);

  const onDropAccepted = useCallback((acceptedFiles) => {
    const reader = new window.FileReader();
    reader.onabort = () => {}; // TODO: handle this scenario
    reader.onerror = () => {}; // TODO: handle this scenario
    reader.onload = () => {
      try {
        setFile(reader.file);
        setData(reader.result);
        credentialCallback(reader.result);
        setRejectReason('');
      } catch (error) {
        setData('');
        credentialCallback('');
      }
      setFileSubmited(true);
    };
    const acceptedFile = acceptedFiles[0];
    reader.file = acceptedFile;
    reader.readAsText(acceptedFile);
  }, []);

  const onDropRejected = useCallback((rejectedFiles) => {
    credentialCallback('');
    setFileSubmited(true);
    setData('');

    const rejectedFile = rejectedFiles[0].file;
    setFile(rejectedFile);

    if (rejectedFiles.length > 1) {
      setRejectReason(
        'Multiple files not supported. Upload only individual files.'
      );
    } else if (!(fileInputParameters.mimetype === rejectedFile.type)) {
      setRejectReason(
        `Invalid file. Must be a supported file format (${fileInputParameters.extensions.join(
          ', '
        )}).`
      );
    } else if (rejectedFile.size > fileInputParameters.maxsize) {
      setRejectReason('Invalid file. Exceeds maximum allowed size.');
    } else {
      setRejectReason('Unexpected error, please try again later.');
    }
    return true;
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileViewer,
  } = useDropzone({
    accept: {[fileInputParameters.mimetype]: fileInputParameters.extensions},
    maxSize: fileInputParameters.maxsize,
    multiple: false,
    noClick: true,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <Root className={classes.root}>
      <Grid container className={classes.dropboxDivideContainer}>
        <div
          className={classNames(classes.dropbox, {
            [classes.dropboxActive]: isDragActive,
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Grid container className={classes.gridContainer}>
            <Grid item className={classes.gridItem}>
              <Button variant="outlined" onClick={openFileViewer}>
                <UploadIcon />
                <Typography className={classes.buttonText}>
                  Select File
                </Typography>
              </Button>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Typography className={classes.dropText}>
                or Drop Certificate File
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {fileSubmited && (
        <>
          <div className={classes.fileRow}>
            <FileIcon className={classes.fileIcon} fontSize="inherit" />
            <Typography noWrap title={file.name}>
              {file.name}
            </Typography>
            {!rejectReason && (
              <img
                src={checkSvg}
                alt=""
                data-testid="check-image"
                className={classes.statusIcon}
              />
            )}
          </div>
          <div className={classes.fileRow}>
            {!rejectReason && !data && (
              <Typography className={classes.errorText}>
                Invalid file. Must be a supported file format ($
                {fileInputParameters.extensions.join(', ')}
                ).
              </Typography>
            )}
            {rejectReason && (
              <Typography className={classes.errorText}>
                {`${rejectReason}`}
              </Typography>
            )}
            {rejectReason && (
              <img
                src={errorSvg}
                alt=""
                data-testid="check-image"
                className={classes.statusIcon}
              />
            )}
          </div>
        </>
      )}
    </Root>
  );
}

FileInputDropbox.propTypes = {
  fileInputParameters: PropTypes.shape({
    extensions: PropTypes.arrayOf(PropTypes.string),
    maxsize: PropTypes.number,
    mimetype: PropTypes.string,
  }).isRequired,
  credentialCallback: PropTypes.func,
};

FileInputDropbox.defaultProps = {
  credentialCallback: () => 'No credential callback provided',
};

export default FileInputDropbox;
