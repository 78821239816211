import CAMDeleteDialog from 'components/CAM/dialogs/CAMDeleteDialog/CAMDeleteDialog';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'redux/ReduxHistory';
import {
  closeDeleteConnectorsDialog,
  deleteConnectors,
} from 'redux/actions/DeleteConnectorsDialogActions';
import {uncheckAll} from 'redux/actions/tableSelectActions';
import {CONNECTORS, CONNECTORS_LINK} from 'utils/constants';

function DeleteConnectorsDialog() {
  const dispatch = useDispatch();

  const {open, connectors} = useSelector(
    (state) =>
      (state && state.deleteConnectorsDialog) || {open: false, connectors: []}
  );

  const connectorsDisplayText =
    connectors && connectors.length > 1 ? 'connectors' : 'connector';

  const handleClose = () => {
    dispatch(closeDeleteConnectorsDialog());
  };

  const handleConfirm = () => {
    dispatch(deleteConnectors());
    dispatch(uncheckAll(CONNECTORS));
    dispatch(push(CONNECTORS_LINK));
  };

  const getConnectorDisplayText = (connectorId) => {
    const connectorToFind =
      connectors.find((connector) => connector.connectorId === connectorId) ||
      {};
    return connectorToFind.connectorName || connectorId;
  };

  const resourceNameForDeleteDialog =
    connectors.length === 1
      ? getConnectorDisplayText(connectors[0].connectorId)
      : `${connectors.length} ${connectorsDisplayText}`;

  return (
    <CAMDeleteDialog
      resourceName={resourceNameForDeleteDialog}
      open={open}
      onOk={handleConfirm}
      onCancel={handleClose}
      // Always require confirmation as deleting the wrong connector can disrupt service
      // and recovering a deleted connector can take a lot of time and disrupt service
      requireTextInputToDelete
    />
  );
}

export default DeleteConnectorsDialog;
