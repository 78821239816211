import {Button, Checkbox, CircularProgress, Card} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import ReactSelect from 'react-select';
import {del, get, post} from 'api/Api';
import GridN from 'components/CAM/layout/GridN/GridN';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import usePendingChanges from 'hooks/usePendingChanges';
import useSnackbar from 'hooks/useSnackbar';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {
  clearResource,
  fetchData,
  handleApiError,
} from 'redux/actions/dataActions';
import {doSafeRequest} from 'utils/apiUtils';
import {
  AD_USERS,
  EDIT_POOL_LINK,
  ENTITLEMENTS,
  POOLS,
  POOL_MACHINES,
  VIEW_AD_USER_LINK,
} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {
  selectResourceArray,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import {isEmpty, linkTo} from 'utils/utils';

const PREFIX = 'RemoteWorkstationPoolInfo';

const classes = {
  checkbox: `${PREFIX}-checkbox`,
  checkboxContainer: `${PREFIX}-checkboxContainer`,
  contentContainer: `${PREFIX}-contentContainer`,
  dropdown: `${PREFIX}-dropdown`,
  dropdownContainer: `${PREFIX}-dropdownContainer`,
  dualButtonRow: `${PREFIX}-dualButtonRow`,
  forwardButtonRow: `${PREFIX}-forwardButtonRow`,
  message: `${PREFIX}-message`,
  removeButton: `${PREFIX}-removeButton`,
  removePoolContainer: `${PREFIX}-removePoolContainer`,
  root: `${PREFIX}-root`,
  spaceFiller: `${PREFIX}-spaceFiller`,
  spinner: `${PREFIX}-spinner`,
};

const Root = styled(Card)(() => ({
  [`&.${classes.root}`]: {
    margin: '0.6em 0',
    maxWidth: '1790px',
    overflow: 'visible',
    padding: '16px',
  },

  [`& .${classes.checkbox}`]: {
    padding: '0 0.5rem 0 0',
  },

  [`& .${classes.checkboxContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: '1rem',
  },

  [`& .${classes.contentContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },

  [`& .${classes.dropdown}`]: {
    width: '65%',
  },

  [`& .${classes.dropdownContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1024,
    paddingBottom: '1rem',
  },

  [`& .${classes.dualButtonRow}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.forwardButtonRow}`]: {
    display: 'flex',
  },

  [`& .${classes.message}`]: {
    paddingBottom: '1rem',
  },

  [`& .${classes.removeButton}`]: {
    borderColor: '#C23934',
    color: '#C23934',
    '&:hover': {
      backgroundColor: '#ffd4d4',
    },
  },

  [`& .${classes.removePoolContainer}`]: {
    alignItems: 'center',
    display: 'flex',
  },

  [`& .${classes.root}`]: {
    maxWidth: 600,
    minWidth: 400,
    padding: '1rem',
  },

  [`& .${classes.spaceFiller}`]: {
    flexGrow: 1,
  },

  [`& .${classes.spinner}`]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 200,
  },
}));

function RemoteWorkstationPoolInfo({selectedMachine, isFetchingMachines}) {
  const dispatch = useDispatch();

  const {setPendingChanges} = usePendingChanges();
  const {successSnackbar} = useSnackbar();

  // init to null so placeholder text displays when nothing is selected
  const [selectedPool, setSelectedPool] = useState(null);
  const [selectedEntitlement, setSelectedEntitlement] = useState(null);
  const [isMigrating, setIsMigrating] = useState(false);
  const [isOnUsersPage, setIsOnUsersPage] = useState(false);

  const [assignedPoolId, setAssignedPoolId] = useState('');
  const [isFetchingPoolMachine, setIsFetchingPoolMachine] = useState(true);
  const [isFetchingAssignedPool, setIsFetchingAssignedPool] = useState(false);

  const [assignedUser, setAssignedUser] = useState({});
  const [isFetchingAssignedUser, setIsFetchingAssignedUser] = useState(false);

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {data: pools, isFetching: isFetchingPools} = useSelector((state) =>
    selectResourceArray(state, POOLS)
  );

  const isLoading = () =>
    isFetchingMachines ||
    isFetchingPools ||
    isFetchingPoolMachine ||
    isFetchingAssignedPool ||
    isFetchingAssignedUser;

  const isNextButtonDisabled = () => !selectedPool;
  const isMigrateButtonDisabled = () => {
    if (isOnUsersPage) {
      return !selectedEntitlement;
    }
    return !selectedPool;
  };

  const isMigrationCheckboxDisabled = (machine) =>
    !machine || !machine.entitlements || machine.entitlements.length < 1;

  const isPoolActive = (pool) =>
    pool && !['deleting', 'deleted', 'error'].includes(pool.status);

  const isPoolMachineActive = (poolMachine) =>
    poolMachine &&
    !['deleting', 'deleted', 'error'].includes(poolMachine.status);

  const handlePoolSelect = (dropdownItem) => setSelectedPool(dropdownItem);
  const handleUserSelect = (dropdownItem) =>
    setSelectedEntitlement(dropdownItem);

  const handleCheckMigrating = (event) => {
    const {checked} = event.target;
    setIsMigrating(checked);
  };

  const hasMoreThanOneEntitlement = () =>
    selectedMachine &&
    selectedMachine.entitlements &&
    selectedMachine.entitlements.length > 1;

  const getNoOptionsMessagePool = () => 'No Pools in Deployment';
  const getNoOptionsMessageUser = () => 'No User Entitlements for Workstation';
  const getAssignedPoolLink = () =>
    linkTo(`${EDIT_POOL_LINK}/${selectedPool.poolId}`);
  const getAssignedUserLink = () =>
    linkTo(`${VIEW_AD_USER_LINK}/${assignedUser.userGuid}/${deploymentId}`);

  const getSelectedPoolId = () => {
    const data = selectedPool.data || {};
    return data.poolId || '';
  };

  const getSelectedUserGuid = () => {
    let userGuid = null;

    if (isMigrating && !hasMoreThanOneEntitlement()) {
      const [entitlement] = selectedMachine.entitlements || [];
      userGuid = entitlement.userGuid || null;
    } else if (isMigrating && hasMoreThanOneEntitlement()) {
      const entitlementData = selectedEntitlement.data || {};
      userGuid = entitlementData.userGuid || null;
    }

    return userGuid;
  };
  const fetchAssignedUser = async (userGuid) => {
    setIsFetchingAssignedUser(true);

    const path = `${mapResourceToPath(AD_USERS)}`;
    const params = {userGuid};

    const resp = await doSafeRequest(get, {path, params});

    if (resp.status === 'success') {
      const [adUser] = resp.data;
      setAssignedUser(adUser);
    } else {
      dispatch(handleApiError(resp));
    }

    setIsFetchingAssignedUser(false);
  };

  const fetchAssignedPool = async (poolId) => {
    setIsFetchingAssignedPool(true);

    const path = `${mapResourceToPath(POOLS, {deploymentId})}${poolId}`;

    const resp = await doSafeRequest(get, {path});

    if (resp.status === 'success') {
      const pool = resp.data || {};
      setSelectedPool(pool);
    } else {
      dispatch(handleApiError(resp));
    }

    setIsFetchingAssignedPool(false);
  };

  const updateAssignedPool = (poolMachine) => {
    const poolId = (poolMachine && poolMachine.poolId) || '';
    setAssignedPoolId(poolId);

    if (poolMachine && poolMachine.assignedTo) {
      fetchAssignedUser(poolMachine.assignedTo);
    }
    if (poolId) {
      fetchAssignedPool(poolId);
    }
  };

  const fetchAssignedPoolMachine = async () => {
    setIsFetchingPoolMachine(true);

    const path = `${mapResourceToPath(POOLS, {deploymentId})}machines`;
    const params = {machineId: selectedMachine.machineId};

    const resp = await doSafeRequest(get, {path, params});

    if (resp.status === 'success') {
      const [poolMachine] = resp.data;
      if (isPoolMachineActive(poolMachine)) {
        updateAssignedPool(poolMachine);
      }
    } else {
      dispatch(handleApiError(resp));
    }

    setIsFetchingPoolMachine(false);
  };

  const createUserPoolEntitlement = async () => {
    const path = mapResourceToPath(ENTITLEMENTS, {deploymentId});
    const data = {
      poolId: getSelectedPoolId(),
      userGuid: getSelectedUserGuid(),
    };

    return doSafeRequest(post, {path, data});
  };

  const assignMachineToPool = async () => {
    const userGuid = getSelectedUserGuid();
    const poolId = getSelectedPoolId();
    const path = mapResourceToPath(POOL_MACHINES, {deploymentId, poolId});
    const data = {
      machineId: selectedMachine.machineId,
      entitlement: {userGuid},
    };

    const resp = await doSafeRequest(post, {path, data});

    if (resp.status === 'success') {
      setAssignedPoolId(poolId);
      setIsMigrating(false);
      setIsOnUsersPage(false);

      await fetchAssignedPoolMachine();

      successSnackbar(
        `Assigned ${selectedMachine.machineName} to pool ${selectedPool.data.poolName}.`
      );
    } else {
      dispatch(handleApiError(resp));
    }
  };

  const handleNextClick = () => setIsOnUsersPage(true);
  const handlePreviousClick = () => {
    setIsOnUsersPage(false);
    setSelectedEntitlement(null);
  };

  // if there's one entitled user or there are 2 or more entitled users
  // and a user has been selected, create a user-pool entitlement first
  const handleMigrateClick = async () => {
    if (getSelectedUserGuid()) {
      const entitlementsResp = await createUserPoolEntitlement();

      let user;
      if (entitlementsResp.status === 'success') {
        user =
          selectedEntitlement &&
          selectedEntitlement.data &&
          selectedEntitlement.data.user;
      } else if (entitlementsResp.code !== 409) {
        dispatch(handleApiError(entitlementsResp));
        return;
      }
      setAssignedUser(user || {});
    }

    assignMachineToPool();
  };

  const handleRemoveFromPool = async () => {
    const path = mapResourceToPath(POOL_MACHINES, {
      deploymentId,
      poolId: assignedPoolId,
    });
    const data = {machineId: selectedMachine.machineId};

    const resp = await doSafeRequest(del, {path, data});

    if (resp.status === 'success') {
      successSnackbar(
        `Removed ${selectedMachine.machineName} from its assigned pool.`
      );
      setAssignedPoolId('');
      setAssignedUser({});
      setSelectedPool(null);
      setSelectedEntitlement(null);
    } else {
      dispatch(handleApiError(resp));
    }
  };

  const handleRemoveClick = async () => {
    dispatch(
      openDialog(
        'Remove machine from pool?',
        <>
          {`Removing ${selectedMachine.machineName} from its assigned pool will delete the machine's
          configuration from the pool, and will revoke the access of assigned pool users.`}
          <br />
          <br />
          This will not remove the assigned user from the pool. This action
          cannot be undone.
        </>,
        handleRemoveFromPool
      )
    );
  };

  const preparePools = (data) =>
    data
      ? data
          .filter((pool) => isPoolActive(pool))
          .map((pool) => ({
            label: pool.poolName,
            value: pool.poolName,
            data: pool,
          }))
      : [];

  const prepareEntitlements = (data) =>
    data
      ? data.map((entitlement) => ({
          label: entitlement.user.name,
          value: entitlement.user.name,
          data: entitlement,
        }))
      : [];

  useEffect(() => {
    setPendingChanges(!isMigrateButtonDisabled() && !assignedPoolId);
  }, [selectedPool, isMigrating]);

  useEffect(() => {
    if (deploymentId) {
      dispatch(clearResource(POOLS));
      dispatch(
        fetchData(POOLS, {
          path: mapResourceToPath(POOLS, {deploymentId}),
        })
      );
    }
  }, [deploymentId]);

  useEffect(() => {
    if (deploymentId && selectedMachine.machineId) {
      fetchAssignedPoolMachine();
    }
  }, [deploymentId, selectedMachine.machineId]);

  const getAssignedPoolDisplayLink = (assignedPoolName) => (
    <>
      {'Machine is currently a member of pool '}
      <Link to={getAssignedPoolLink()}>{assignedPoolName}</Link>.
    </>
  );
  const getAssignedUserDisplayLink = (assignedUserName) => (
    <>
      {'Machine is currently assigned to user '}
      <Link to={getAssignedUserLink()}>{assignedUserName}</Link>.
    </>
  );

  const renderAssignedPoolName = () => {
    const assignedPoolName = selectedPool && selectedPool.poolName;
    let displayComponent = 'Machine is currently assigned to a pool.';
    if (assignedPoolName) {
      displayComponent = getAssignedPoolDisplayLink(assignedPoolName);
    }
    return <div>{displayComponent}</div>;
  };

  const renderAssignedPoolUser = () => {
    const assignedUserName = assignedUser && assignedUser.name;
    let displayComponent = 'Machine is currently assigned to a pool user.';
    if (assignedUserName) {
      displayComponent = getAssignedUserDisplayLink(assignedUserName);
    }
    return <div>{displayComponent}</div>;
  };

  const renderPoolInfoComponent = () => (
    <div className={classes.removePoolContainer}>
      <div className={classes.contentContainer}>
        {renderAssignedPoolName()}
        {!isEmpty(assignedUser) && renderAssignedPoolUser()}
      </div>
      <div className={classes.spaceFiller} />
      <div className={classes.forwardButtonRow}>
        <Button
          data-testid="cancel-button"
          onClick={handleRemoveClick}
          variant="outlined"
          size="small"
          className={classes.removeButton}
        >
          Remove
        </Button>
      </div>
    </div>
  );

  const renderNoPoolHeader = () => (
    <div className={classes.message}>
      This machine is not assigned to a pool. Select a pool below to create an
      assignment.
    </div>
  );

  const renderSelectPool = () => (
    <div data-testid="select-pool" className={classes.dropdownContainer}>
      <ReactSelect
        data-testid="select-pool"
        isLoading={isLoading()}
        isMulti={false}
        isSearchable={false}
        isClearable
        noOptionsMessage={getNoOptionsMessagePool}
        onChange={handlePoolSelect}
        options={preparePools(pools)}
        placeholder="Select a pool"
        value={selectedPool}
        closeMenuOnSelect
        className={classes.dropdown}
      />
    </div>
  );

  const renderCheckboxMigration = () => (
    <div className={classes.checkboxContainer}>
      <Checkbox
        checked={isMigrating}
        color="primary"
        onChange={handleCheckMigrating}
        size="small"
        classes={{root: classes.checkbox}}
        inputProps={{'data-testid': 'migrate-entitlements-checkbox'}}
        disabled={isMigrationCheckboxDisabled(selectedMachine)}
      />
      Migrate user entitlements to pool
    </div>
  );

  const renderButton = (text, onClick, isDisabled) => (
    <Button
      data-testid={`${text.toLowerCase()}-pool-button`}
      onClick={onClick}
      color="primary"
      disabled={isDisabled}
      variant="outlined"
      size="small"
    >
      {text}
    </Button>
  );

  const renderNoPoolButtons = () => (
    <div className={classes.forwardButtonRow}>
      <div className={classes.spaceFiller} />
      {hasMoreThanOneEntitlement() && isMigrating
        ? renderButton(
            'Next: select user',
            handleNextClick,
            isNextButtonDisabled()
          )
        : renderButton(
            'Migrate machine',
            handleMigrateClick,
            isMigrateButtonDisabled()
          )}
    </div>
  );

  const renderPoolsPage = () => (
    <div className={classes.contentContainer}>
      {renderNoPoolHeader()}
      {renderSelectPool()}
      {renderCheckboxMigration()}
      <div className={classes.spaceFiller} />
      {renderNoPoolButtons()}
    </div>
  );

  const renderSelectUserHeader = () => (
    <>
      <div className={classes.message}>
        {`${selectedPool.data.poolName} uses persistent assignments.`}
      </div>
      <InputLabel displayText="Select one user to migrate to the pool." />
    </>
  );

  const renderSelectUser = () => (
    <div data-testid="select-user" className={classes.dropdownContainer}>
      <ReactSelect
        isLoading={isLoading()}
        isMulti={false}
        isSearchable={false}
        isClearable
        noOptionsMessage={getNoOptionsMessageUser}
        onChange={handleUserSelect}
        options={prepareEntitlements(selectedMachine.entitlements)}
        placeholder="Select a user"
        value={selectedEntitlement}
        closeMenuOnSelect
        className={classes.dropdown}
      />
    </div>
  );

  const renderUsersButtons = () => (
    <div className={classes.dualButtonRow}>
      {renderButton('Previous', handlePreviousClick, false)}
      {renderButton(
        'Migrate machine and user',
        handleMigrateClick,
        isMigrateButtonDisabled()
      )}
    </div>
  );

  const renderUsersPage = () => (
    <div className={classes.contentContainer}>
      {renderSelectUserHeader()}
      {renderSelectUser()}
      <div className={classes.spaceFiller} />
      {renderUsersButtons()}
    </div>
  );

  const renderPoolAssignmentComponent = () =>
    isOnUsersPage ? renderUsersPage() : renderPoolsPage();

  const renderContent = () =>
    assignedPoolId
      ? renderPoolInfoComponent()
      : renderPoolAssignmentComponent();

  const renderSpinner = () => (
    <div className={classes.spinner}>
      <CircularProgress color="primary" />
    </div>
  );

  if (isEmpty(pools)) {
    return null;
  }

  return (
    <Root className={classes.root}>
      <GridN singleColumn>
        <SectionHeader displayText="MANAGE POOL MEMBERSHIP FOR WORKSTATION" />
        {isLoading() ? renderSpinner() : renderContent()}
      </GridN>
    </Root>
  );
}

RemoteWorkstationPoolInfo.propTypes = {
  selectedMachine: PropTypes.object,
  isFetchingMachines: PropTypes.bool,
};

RemoteWorkstationPoolInfo.defaultProps = {
  selectedMachine: {},
  isFetchingMachines: false,
};

export default RemoteWorkstationPoolInfo;
