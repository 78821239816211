import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Grid} from '@mui/material';
import {del, post} from 'api/Api';
import ProviderIcon from 'components/CAM/icons/ProviderIcon/ProviderIcon';
import usePendingChanges from 'hooks/usePendingChanges';
import NewElement from 'icons/new_element.svg';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {
  fetchCloudServiceAccounts,
  handleApiError,
} from 'redux/actions/dataActions';
import {CLOUD_SERVICE_ACCOUNTS, RSM} from 'utils/constants';
import {mapProviders} from 'utils/Mappings';
import {selectData} from 'utils/reduxSelectors';
import {isEmpty, sanitizeValues} from 'utils/utils';

const PREFIX = 'RsmCredentialBox';

const classes = {
  cloudCredentialContainer: `${PREFIX}-cloudCredentialContainer`,
  chevron: `${PREFIX}-chevron`,
  inputTextFieldLabel: `${PREFIX}-inputTextFieldLabel`,
  credentialInput: `${PREFIX}-credentialInput`,
  inputMultiline: `${PREFIX}-inputMultiline`,
  inputHidden: `${PREFIX}-inputHidden`,
  cloudCredentialStatus: `${PREFIX}-cloudCredentialStatus`,
  cloudCredentialHeader: `${PREFIX}-cloudCredentialHeader`,
  cloudCredentialTitle: `${PREFIX}-cloudCredentialTitle`,
  disabled: `${PREFIX}-disabled`,
  buttonRow: `${PREFIX}-buttonRow`,
  eyeIcon: `${PREFIX}-eyeIcon`,
  eyeball: `${PREFIX}-eyeball`,
  noPadding: `${PREFIX}-noPadding`,
  buttonCursor: `${PREFIX}-buttonCursor`,
};

const StyledPaper = styled(Paper)(({theme}) => ({
  [`&.${classes.cloudCredentialContainer}`]: {
    marginTop: '7px',
    padding: '0px 10px',
    boxShadow: 'none',
    border: '1px solid #ccc',
  },

  [`& .${classes.chevron}`]: {
    margin: 'auto 0px auto auto',
  },

  [`& .${classes.inputTextFieldLabel}`]: {
    color: theme.palette.info.main,
    fontFamily: '.SF NS Display',
    fontSize: '0.75rem',
    lineHeight: '15px',
    marginTop: '16px',
  },

  [`& .${classes.credentialInput}`]: {
    margin: '0px',
  },

  [`& .${classes.inputMultiline}`]: {
    padding: '8px 10px',
  },

  [`& .${classes.inputHidden}`]: {
    padding: '8px 0px',
  },

  [`& .${classes.cloudCredentialStatus}`]: {
    margin: 0,
    fill: 'rgba(0,0,0,0.54)',
  },

  [`& .${classes.cloudCredentialHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    height: '2.25rem',
    cursor: 'pointer',
  },

  [`& .${classes.cloudCredentialTitle}`]: {
    flex: 1,
    marginLeft: '12px',
  },

  [`& .${classes.disabled}`]: {color: theme.palette.error.main},

  [`& .${classes.buttonRow}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '20px',
  },

  [`& .${classes.eyeIcon}`]: {
    marginRight: '5px',
  },

  [`& .${classes.eyeball}`]: {
    fontSize: '18px',
  },

  [`& .${classes.noPadding}`]: {padding: 0},

  [`& .${classes.buttonCursor}`]: ({loading}) => ({
    cursor: loading ? 'wait' : 'pointer',
  }),
}));

function RsmCredentialBox({deploymentId}) {
  const dispatch = useDispatch();
  const {setPendingChanges} = usePendingChanges();

  const [isMinimized, setIsMinimized] = useState(true);
  const [credentialId, setCredentialId] = useState(''); // credentialId is generated by AWM
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [clientSecretVisibility, setClientSecretVisibility] = useState(false);
  const [credentialsValid, setCredentialsValid] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [editingMode, setEditingMode] = useState(false);
  const provider = RSM;

  const clearForm = () => {
    setCredentialId('');
    setClientId('');
    setClientSecret('');
    setCredentialsValid(false);
  };

  const {
    data: cloudServiceAccounts,
    isFetching: isFetchingCloudServiceAccounts,
  } = useSelector((state) => selectData(state, CLOUD_SERVICE_ACCOUNTS));

  useEffect(() => {
    if (!isFetchingCloudServiceAccounts) {
      const account =
        cloudServiceAccounts.find((acc) => acc.provider === RSM) || {};
      setCredentialId(account.id);
      setClientId(account.clientId);
      setCredentialsValid(typeof account.id !== 'undefined');
      setLoading(false);
    } else {
      clearForm();
    }
  }, [JSON.stringify(cloudServiceAccounts)]);

  useEffect(() => {
    if (deploymentId) {
      setEditingMode(true);
    }
    setLoading(isFetchingCloudServiceAccounts);
  }, [deploymentId]);

  useEffect(() => {
    setLoading(isFetchingCloudServiceAccounts);
  }, [isFetchingCloudServiceAccounts]);

  useEffect(() => {
    const pendingChanges =
      editingMode &&
      !credentialsValid &&
      [clientId, clientSecret].some((element) => !isEmpty(element));
    setPendingChanges(pendingChanges);
  }, [clientId, clientSecret, loading]);

  const handleValidateCredentialError = (error) => {
    const {code} = error;
    switch (code) {
      case 400:
        return false;
      case 403:
        setDialogMessage('Invalid credentials');
        setCredentialsValid(false);
        setDialogOpen(true);
        return true;
      default:
        return false;
    }
  };

  /** Call API to validate credential */
  const validateCredential = async () => {
    const {data} = await post({
      path: 'auth/users/cloudServiceAccount/validate',
      data: {
        provider,
        credential: {
          clientId: sanitizeValues(clientId),
          clientSecret: sanitizeValues(clientSecret),
        },
      },
    });

    return data;
  };

  const deleteCredential = async () => {
    // Will delete cloud provider key in the backend.
    const path = `deployments/${deploymentId}/cloudServiceAccounts/${credentialId}`;
    try {
      await del({path});
      clearForm();
      setDialogMessage('Credential deleted!');
      setDialogOpen(true);
      dispatch(fetchCloudServiceAccounts({deploymentId}));
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };

  const handleDeleteClick = () =>
    dispatch(
      openDialog(
        'Delete provider service account?',
        "Are you sure you want to delete your provider service account? You won't be able to power manage workstations without it.",
        deleteCredential
      )
    );

  const submitCredential = async () => {
    const path = `deployments/${deploymentId}/cloudServiceAccounts`;
    const {data: credential} = await post({
      path,
      data: {
        provider,
        credential: {
          clientId: sanitizeValues(clientId),
          clientSecret: sanitizeValues(clientSecret),
        },
      },
    });
    return credential;
  };

  const handleSubmitClick = async () => {
    setLoading(true);
    try {
      await validateCredential();
      const credential = await submitCredential();
      setCredentialId(credential.id);
      setCredentialsValid(true);
      setDialogMessage('Credential added to deployment!');
      setDialogOpen(true);
      dispatch(fetchCloudServiceAccounts({deploymentId}));
    } catch (error) {
      if (!handleValidateCredentialError(error)) {
        dispatch(handleApiError(error));
      }
    }
    setLoading(false);
  };

  const handleExpandClick = () => {
    setIsMinimized(!isMinimized);
  };

  const statusIcon = () => {
    if (loading) {
      return <CircularProgress size={16} />;
    }
    if (credentialsValid) {
      return <CheckIcon className={classes.cloudCredentialStatus} />;
    }
    return (
      <img src={NewElement} className={classes.cloudCredentialStatus} alt="" />
    );
  };

  const renderClientId = (
    <>
      <Typography className={classes.inputTextFieldLabel}>Client Id</Typography>
      <TextField
        data-testid="rsm-client-id"
        placeholder="Enter client id"
        fullWidth
        maxRows={2}
        variant="outlined"
        name="clientId"
        disabled={credentialsValid}
        value={clientId}
        onChange={(event) => setClientId(event.target.value)}
        multiline
        className={classes.credentialInput}
        // InputProps={{ {multiline: classes.inputMultiline}}}
      />
    </>
  );

  const renderClientSecret = (
    <>
      <Typography className={classes.inputTextFieldLabel}>
        Client Secret
      </Typography>
      <TextField
        data-testid="rsm-client-secret"
        placeholder="Enter client secret"
        fullWidth
        maxRows={6}
        variant="outlined"
        margin="normal"
        name="clientSecret"
        disabled={credentialsValid}
        value={clientSecret}
        onChange={(event) => setClientSecret(event.target.value)}
        className={classes.credentialInput}
        type={clientSecretVisibility ? 'text' : 'password'}
        InputProps={{
          className: classes.inputHidden,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className={classes.eyeIcon}
                size="small"
                data-testid="rsm-client-secret-toggle"
                onClick={() =>
                  setClientSecretVisibility(!clientSecretVisibility)
                }
                onMouseDown={(event) => event.preventDefault()}
              >
                {clientSecretVisibility ? (
                  <VisibilityOff className={classes.eyeball} />
                ) : (
                  <Visibility className={classes.eyeball} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );

  const missingFields = !(clientId && clientSecret);

  return (
    <StyledPaper className={classes.cloudCredentialContainer}>
      <div
        role="button"
        data-testid="expand-rsm-credential-header"
        className={classnames(
          classes.cloudCredentialHeader,
          classes.buttonCursor
        )}
        onClick={() => handleExpandClick()}
      >
        {statusIcon()}
        <Typography className={classes.cloudCredentialTitle}>
          <Grid container direction="row" alignItems="center">
            <ProviderIcon provider={RSM} />
            {mapProviders(RSM)}
          </Grid>
        </Typography>
        <IconButton
          data-testid="expand-rsm-credential-button"
          onClick={handleExpandClick}
          disableRipple
          disabled={loading}
          className={classes.noPadding}
          size="large"
        >
          {isMinimized ? (
            <KeyboardArrowRight className={classes.chevron} />
          ) : (
            <KeyboardArrowUp className={classes.chevron} />
          )}
        </IconButton>
      </div>

      {!isMinimized && (
        <>
          {renderClientId}
          {!credentialsValid && renderClientSecret}

          <div className={classes.buttonRow}>
            {editingMode && !credentialsValid && (
              <Button
                data-testid="rsm-credential-submit"
                color="primary"
                disabled={missingFields || loading}
                onClick={handleSubmitClick}
                className={classes.actionButton}
              >
                Submit
              </Button>
            )}

            {editingMode && credentialsValid && (
              <Button
                data-testid="rsm-credential-delete"
                onClick={handleDeleteClick}
                className={classes.button}
              >
                Delete
              </Button>
            )}
            {editingMode && !credentialsValid && (
              <Button
                data-testid="rsm-credential-clear"
                onClick={clearForm}
                className={classes.button}
                disabled={missingFields}
              >
                Clear
              </Button>
            )}
          </div>

          {dialogOpen && (
            <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              data-testid="rsm-credential-dialog"
            >
              <DialogContent>
                <DialogContentText
                  data-testid="rsm-credential-dialog-text"
                  variant="h6"
                  color="inherit"
                >
                  {dialogMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  data-testid="rsm-dialog-button-ok"
                  onClick={() => setDialogOpen(false)}
                  color="primary"
                  autoFocus
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </>
      )}
    </StyledPaper>
  );
}

RsmCredentialBox.propTypes = {
  deploymentId: PropTypes.string,
};

RsmCredentialBox.defaultProps = {
  deploymentId: '',
};

export default RsmCredentialBox;
