import {Link} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {useSelector} from 'react-redux';
import {PENDING_MONITOR_PROVISIONS_LINK} from 'utils/constants';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import {selectVariable} from 'utils/reduxSelectors';

const PREFIX = 'EnrollmentsButton';

const classes = {
  warningLine: `${PREFIX}-warningLine`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [`& .${classes.warningLine}`]: {
    color: 'white',
    textAlign: 'center',
    marginRight: '4px',
    border: '2px solid #FF9600',
    backgroundColor: '#FF9600',
    borderRadius: '4px',
  },
}));

function EnrollmentsButton() {
  const pendentMonitorsLength = useSelector((state) =>
    selectVariable(state, 'pendentMonitorsLength')
  );

  const warningMessage = `You have ${pendentMonitorsLength} new monitor provisions to approve.`;
  const buttonText = 'SEE HOSTNAMES';
  return (
    <Root>
      <p className={classes.warningLine}>{warningMessage}</p>
      <Link to={`${PENDING_MONITOR_PROVISIONS_LINK}`}>
        <CAMButton
          data-testid="see-hostnames-button"
          buttonText={buttonText}
          size="small"
        />
      </Link>
    </Root>
  );
}

export default EnrollmentsButton;
