import Breadcrumbs from '@mui/material/Breadcrumbs';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useDispatch} from 'react-redux';
import Chip from '@mui/material/Chip';

import {goBack, push} from 'redux/ReduxHistory';
import {
  CONNECTORS_LINK,
  CONNECTOR_WIZARD_CONFIGURE_CAC_LINK,
  CONNECTOR_WIZARD_GETTING_STARTED_LINK,
  LINK_CONTACT_ANYWARE_SUPPORT,
  LINK_TRANSITIONING_CONNECTORS,
} from 'utils/constants';

import {stylesFromFigma} from 'themes/stylesFromFigma';

import UbuntuImage from 'icons/Ubuntu.svg';
import RockyImage from 'icons/RockyLinux.svg';
import { margin } from '../../../../node_modules/polished/lib/index';

const PREFIX = 'ConnectorTypeSelection';

const classes = {
  containerWrap: `${PREFIX}-containerWrap`,
  pageTitle: `${PREFIX}-pageTitle`,
  cardTitle: `${PREFIX}-cardTitle`,
  connectorLink: `${PREFIX}-connectorLink`,
  currentBreadcrumb: `${PREFIX}-currentBreadcrumb`,
  mainContainer: `${PREFIX}-mainContainer`,
  divider: `${PREFIX}-divider`,
  mainButton: `${PREFIX}-mainButton`,
  secondaryButton: `${PREFIX}-secondaryButton`,
  button: `${PREFIX}-button`,
  innerTitle: `${PREFIX}-innerTitle`,
  container: `${PREFIX}-container`,
  cardRow: `${PREFIX}-cardRow`,
  connectorCardContent: `${PREFIX}-connectorCardContent`,
  defaultCard: `${PREFIX}-defaultCard`,
  selectedCard: `${PREFIX}-selectedCard`,
  sectionSubtitle: `${PREFIX}-sectionSubtitle`,
  optionDescription: `${PREFIX}-optionDescription`,
  verticalDivider: `${PREFIX}-verticalDivider`,
  titleRow: `${PREFIX}-titleRow`,
  cardBox: `${PREFIX}-cardBox`,
  boxHeader: `${PREFIX}-boxHeader`,
  recommendedChip: `${PREFIX}-recommendedChip`,
  warningBox: `${PREFIX}-warningBox`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.containerWrap}`]: {
    margin: theme.spacing(4),
    maxWidth: '1136px',
    paddingLeft: '96px',
    marginLeft: '0px',
    marginTop: '16px',
  },

  [`& .${classes.pageTitle}`]: {
    ...stylesFromFigma.typographyH4,
    marginTop: '8px',
  },

  [`& .${classes.cardTitle}`]: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
  },

  [`& .${classes.connectorLink}`]: {
    ...stylesFromFigma.typographyBody1,
    color: 'inherit',
  },

  [`& .${classes.currentBreadcrumb}`]: {
    color: 'rgba(0, 0, 0, 0.87)',
  },

  [`& .${classes.mainContainer}`]: {
    padding: '0px',
    border: '0px',
    margin: '0px',
    marginTop: '24px',
  },

  [`& .${classes.divider}`]: {
    marginTop: '18px',
    width: '100%',
  },

  [`& .${classes.mainButton}`]: {
    color: '#FFFFFF',
    background: '#0D47A1',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#0D47A1',
    },
  },

  [`& .${classes.secondaryButton}`]: {
    marginTop: '20px',
  },

  [`& .${classes.button}`]: {
    color: '#0D47A1',
  },

  [`& .${classes.innerTitle}`]: {
    ...stylesFromFigma.typographyH6,
    marginLeft: '24px',
  },

  [`& .${classes.container}`]: {
    borderRadius: '4px 4px 0 0',
    backgroundColor: '#16325C',
    color: theme.palette.common.white,
  },

  [`& .${classes.cardRow}`]: {marginTop: '1.75rem'},

  [`& .${classes.connectorCardContent}`]: {
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 500,
    padding: '0',
    maxWidth: '480px',
    boxShadow: 'none',
    margin: '0 auto',
    paddingBottom: '24px',
  },

  [`& .${classes.defaultCard}`]: {margin: '4px'},

  [`& .${classes.selectedCard}`]: {
    border: '4px solid #FF5D06',
    borderRadius: '4px',
  },

  [`& .${classes.sectionSubtitle}`]: {
    borderBottom: '1px solid #0000001F',
    paddingBottom: '24px',
  },

  [`& .${classes.optionDescription}`]: {
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '19px',
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.verticalDivider}`]: {
    margin: '0 24px',
  },

  [`& .${classes.titleRow}`]: {display: 'flex'},

  [`& .${classes.cardBox}`]: {
    boxShadow: 'none',
    border: '1px solid #ddd',
    borderRadius: '8px',
  },

  [`& .${classes.boxHeader}`]: {
    ...stylesFromFigma.typographyH6,
    padding: '24px',
    fontWeight: 700,
  },

  [`& .${classes.recommendedChip}`]: {
    backgroundColor: '#E5F0FF',
    color: '#1E636B',
    fontWeight: 400,
    float: 'right',
  },

  [`& .${classes.warningBox}`]: {
    backgroundColor: '#FFD7B5',
    color: '#FF5D06',
    padding: '12px',
    borderRadius: '8px',
    fontWeight: 700,
    marginBottom: '12px',
  },
}));

function ConnectorTypeSelection() {
  const dispatch = useDispatch();
  // eslint-disable-next-line react/no-unstable-nested-components
  function connectorBox({title, description, image, link, recommended}) {
    return (
      <Grid item xs alignItems="center">
        <Card
          id={`${title.toLowerCase().replace(/ /g, '-')}-card`}
          className={classes.cardBox}
        >
          <CardContent className={classes.connectorCardContent}>
            <div className={classes.boxHeader}>
              {title}
              {recommended && (
                <Chip label="Recommended" className={classes.recommendedChip} />
              )}
            </div>

            <Divider />
            <div className={classes.optionDescription}>
              <img className={classes.boxImage} src={image} alt={title} />
              <br />
              {description}
            </div>

            <Grid container alignItems="center" justifyContent="center">
              <Button
                onClick={() => dispatch(push(link))}
                className={classes.mainButton}
              >
                Select
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Root className={classes.containerWrap}>
      <Breadcrumbs separator=">">
        <Link
          underline="hover"
          href={CONNECTORS_LINK}
          onClick={(event) => {
            event.preventDefault();
            dispatch(push(CONNECTORS_LINK));
          }}
          className={classes.connectorLink}
        >
          Connectors
        </Link>
        <Typography className={classes.currentBreadcrumb}>
          Add Connector
        </Typography>
      </Breadcrumbs>

      <Typography className={classes.pageTitle}>Add Connector</Typography>

      <Paper className={classes.mainContainer} variant="outlined">
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.sectionSubtitle}
        >
          Select the connector type you wish to add below.
        </Grid>

        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Grid
              className={classes.cardRow}
              container
              justifyContent="space-around"
              alignItems="flex-start"
            >
              {connectorBox({
                title: 'Rocky Linux or RHEL',
                recommended: true,
                image: RockyImage,
                description: (
                  <div>
                    For use on Rocky Linux or Red Hat Enterprise Linux (RHEL).
                    <ul>
                      <li>
                        The Rocky Linux or RHEL connector is recommended for
                        most users.
                      </li>
                      <li>Simple installation and updating.</li>
                      <li>Supports more features.</li>
                    </ul>
                  </div>
                ),
                link: CONNECTOR_WIZARD_GETTING_STARTED_LINK,
              })}
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                className={classes.verticalDivider}
              >
                or
              </Divider>
              {connectorBox({
                title: 'Ubuntu',
                image: UbuntuImage,
                description: (
                  <div>
                    For use on Ubuntu only.
                    <Card className={classes.warningBox}>
                      The Ubuntu-based Cloud Access Connector has been discontinued, and will not
                      receive new features or enhancements. Critical bug and security fixes will be
                      provided until the official End of Support in December 2024; after that date,
                      no further updates will be released.
                      <Divider className={classes.divider} />
                      
                      Customers using the Ubuntu-based Cloud Access Connector should migrate to the
                      RHEL/Rocky-based Anyware Connector before the End of Support date in December 2024.
                      For migration details, see &nbsp;
                      <Link href={LINK_TRANSITIONING_CONNECTORS}>
                        this link.
                      </Link>
                      If you have questions or need assistance with migration to the Anyware Connector, please
                      reach out to the &nbsp;
                      <Link href={LINK_CONTACT_ANYWARE_SUPPORT}>
                        HP Anyware Support team.
                      </Link>
                    </Card>
                  </div>
                ),
                link: CONNECTOR_WIZARD_CONFIGURE_CAC_LINK,
              })}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid container justifyContent="flex-start">
        <Divider className={classes.divider} />

        <Grid container item xs={6}>
          <Button
            onClick={() => dispatch(goBack())}
            className={classes.button}
            variant="text"
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </Root>
  );
}

export default ConnectorTypeSelection;
