import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import HorizontalTab from 'components/CAM/tabs/HorizontalTab/HorizontalTab';
import usePendingChanges from 'hooks/usePendingChanges';
import useSnackbar from 'hooks/useSnackbar';
import {goBack} from 'redux/actions/HistoryActions';
import {updateUserPreferences} from 'redux/actions/userPreferencesActions';
import {DATE_FORMAT_LIST, TIME_FORMAT_LIST} from 'utils/constants';
import timezoneList from './timezones.json';

const PREFIX = 'DateTimeTab';

const classes = {
  button: `${PREFIX}-button`,
  buttonRow: `${PREFIX}-buttonRow`,
  container: `${PREFIX}-container`,
  gridItem: `${PREFIX}-gridItem`,
  label: `${PREFIX}-label`,
  pageTitleContainer: `${PREFIX}-pageTitleContainer`,
  pageTitle: `${PREFIX}-pageTitle`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.button}`]: {
    ...theme.createPage.button,
    marginRight: theme.spacing(1),
  },
  [`& .${classes.buttonRow}`]: {marginTop: '15rem'},

  [`& .${classes.container}`]: {
    ...theme.container,
    margin: theme.spacing(4),
  },

  [`& .${classes.gridItem}`]: {
    marginRight: '3.1rem',
    marginTop: '2.5rem',
  },

  [`& .${classes.label}`]: {
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontSize: '0.8rem',
  },

  [`& .${classes.pageTitleContainer}`]:
    theme.createPage.createPageTitleContainer,
  [`& .${classes.pageTitle}`]: theme.createPage.createPageTitle,
}));

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  style: {maxHeight: '50vh'},
};

const selectProps = {
  MenuProps: menuProps,
  IconComponent: KeyboardArrowDown,
  displayEmpty: true,
};

const inputProps = {
  style: {
    fontFamily: 'Roboto',
    fontSize: '0.9rem',
    width: '286px',
    height: '32px',
  },
};

function DateTimeTab() {
  const dispatch = useDispatch();
  const {setPendingChanges} = usePendingChanges();
  const {successSnackbar} = useSnackbar();

  const previousPreferences = useSelector(
    (state) => state.userPreferences || {}
  );
  const [timeZone, setTimeZone] = useState('local');
  const [timeFormat, setTimeFormat] = useState(TIME_FORMAT_LIST[0]);
  const [dateFormat, setDateFormat] = useState(DATE_FORMAT_LIST[0]);
  const [loading, setLoading] = useState(true);
  const [preferencesUpdated, setPreferencesUpdated] = useState(false);
  const timezones = Object.keys(timezoneList);

  const mapDisplayToTimezone = (key) => timezoneList[key] || 'local';

  const findTimezone = (zone) => {
    const zoneArray = Object.entries(timezoneList).find(
      (znArr) => znArr[1] === zone
    );
    return zoneArray ? zoneArray[0] : 'local';
  };

  const findDateFormat = (format) =>
    DATE_FORMAT_LIST.find((item) => item.format === format) ||
    DATE_FORMAT_LIST[0];

  useEffect(() => {
    if (
      previousPreferences.timeZone &&
      previousPreferences.timeFormat &&
      previousPreferences.dateFormat
    ) {
      setTimeZone(findTimezone(previousPreferences.timeZone));
      setTimeFormat(previousPreferences.timeFormat);
      setDateFormat(findDateFormat(previousPreferences.dateFormat));
    }
  }, [previousPreferences]);

  const updatePreferences = () => {
    const newPreferences = {
      timeZone: mapDisplayToTimezone(timeZone),
      timeFormat,
      dateFormat: dateFormat.format,
    };
    dispatch(updateUserPreferences(newPreferences));
    successSnackbar('User preferences updated.');
    setPendingChanges(false);
  };

  const handleCancel = () => {
    dispatch(goBack());
    setPendingChanges(false);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    const updated =
      findTimezone(previousPreferences.timeZone) !== timeZone ||
      previousPreferences.timeFormat !== timeFormat ||
      findDateFormat(previousPreferences.dateFormat) !== dateFormat;
    setPreferencesUpdated(updated);
    setPendingChanges(updated && !loading);
  }, [dateFormat, timeZone, timeFormat]);

  return (
    <Root>
      <CAMCard>
        <div className={classes.pageTitleContainer}>
          <div className={classes.pageTitle}>Preferences</div>
        </div>

        <HorizontalTab
          tabs={['Date & Time']}
          selectedTab={0}
          setSelectedTab={() => {}}
        />

        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.gridItem}>
            <div className={classes.label}>Date format</div>
            <TextField
              value={dateFormat.format}
              onChange={(event) =>
                setDateFormat(findDateFormat(event.target.value))
              }
              select
              SelectProps={selectProps}
              InputProps={inputProps}
              variant="outlined"
            >
              {DATE_FORMAT_LIST.map((format) => (
                <MenuItem key={format.format} value={format.format}>
                  {`${format.format} (${format.example})`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item className={classes.gridItem}>
            <div className={classes.label}>Time zone</div>
            <TextField
              value={timeZone}
              onChange={(event) => setTimeZone(event.target.value)}
              select
              SelectProps={{
                ...selectProps,
                MenuProps: {
                  ...menuProps,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                },
              }}
              InputProps={inputProps}
              variant="outlined"
            >
              <MenuItem key="local" value="local">
                Local (use browser time)
              </MenuItem>
              {timezones.map((zone) => (
                <MenuItem key={zone} value={zone}>
                  {zone}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item className={classes.gridItem}>
            <div className={classes.label}>Time format</div>
            <TextField
              value={timeFormat}
              onChange={(event) => setTimeFormat(event.target.value)}
              select
              SelectProps={selectProps}
              InputProps={inputProps}
              variant="outlined"
            >
              {TIME_FORMAT_LIST.map((format) => (
                <MenuItem key={format} value={format}>
                  {format}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container direction="row-reverse" className={classes.buttonRow}>
          <SaveButton
            disabled={!preferencesUpdated || loading}
            onClick={updatePreferences}
          />
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Grid>
      </CAMCard>
    </Root>
  );
}

export default DateTimeTab;
