import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CopyIcon from 'icons/CopyIcon';
import {
  CAC_TOKEN_LINK,
  CREATE_CAC_LINK,
  DOWNLOAD_CAC_LINK,
  ENABLE_EXTERNAL_ACCESS_CAC_LINK,
  INSTALL_CAC_LINK,
  VERIFY_CAC_LINK,
} from 'utils/constants';

const PREFIX = 'ConnectorInstallInstructions';

const classes = {
  installInstructionsContainer: `${PREFIX}-installInstructionsContainer`,
  inputTextFieldLabel: `${PREFIX}-inputTextFieldLabel`,
  stepLine: `${PREFIX}-stepLine`,
  stepCircle: `${PREFIX}-stepCircle`,
  installInstructionsGrid: `${PREFIX}-installInstructionsGrid`,
  installGridRow: `${PREFIX}-installGridRow`,
  installTitle: `${PREFIX}-installTitle`,
  moreInfoText: `${PREFIX}-moreInfoText`,
  tokenField: `${PREFIX}-tokenField`,
  largeTokenField: `${PREFIX}-largeTokenField`,
  tokenSubtext: `${PREFIX}-tokenSubtext`,
  copyButton: `${PREFIX}-copyButton`,
  generateButton: `${PREFIX}-generateButton`,
  warningIcon: `${PREFIX}-warningIcon`,
  warningButton: `${PREFIX}-warningButton`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.installInstructionsContainer}`]: {
    width: '33.875rem',
  },

  [`& .${classes.inputTextFieldLabel}`]: theme.createPage.inputTextFieldLabel,

  [`& .${classes.stepLine}`]: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    minHeight: '34px',
    margin: '4.5px auto 0px',
    width: '2px',
  },

  [`& .${classes.stepCircle}`]: {
    backgroundColor: theme.palette.common.white,
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: '100%',
    boxSizing: 'border-box',
    height: '13px',
    margin: 'auto',
    width: '13px',
  },

  [`& .${classes.installInstructionsGrid}`]: {
    padding: '1rem 0rem',
    flexWrap: 'nowrap',
  },

  [`& .${classes.installGridRow}`]: {
    marginTop: '5.5px',
    overflowY: 'hidden',
  },

  [`& .${classes.installTitle}`]: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    lineHeight: '20px',
  },

  [`& .${classes.moreInfoText}`]: {
    color: theme.palette.info.main,
    fontFamily: 'Roboto',
    fontSize: '0.8125rem',
    lineHeight: '15px',
    paddingLeft: '16px',
    paddingTop: '5px',
  },

  [`& .${classes.tokenField}`]: {
    border: '1px solid #DDDBDA',
    borderRadius: '3px',
    color: 'rgba(0,0,0,0.38)',
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    height: '30px',
    letterSpacing: '0.1px',
    lineHeight: '24px',
    marginTop: '10px',
    maxHeight: '150px',
    minHeight: '30px',
    resize: 'vertical',
    width: '100%',
  },

  [`& .${classes.largeTokenField}`]: {
    height: '50px',
    minHeight: '50px',
  },

  [`& .${classes.tokenSubtext}`]: {
    color: theme.palette.info.main,
    fontFamily: 'Roboto',
    fontSize: '0.625rem',
    lineHeight: '11px',
  },

  [`& .${classes.copyButton}`]: {
    marginLeft: '3px',
    marginTop: '56px',
    padding: '7px',
  },

  [`& .${classes.generateButton}`]: {
    marginLeft: '12px',
    padding: '4px',
    border: '1px solid #DDDBDA',
    borderRadius: '3px',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.1)',
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
  },

  [`& .${classes.warningIcon}`]: {
    color: theme.palette.dialog.warning,
  },

  [`& .${classes.warningButton}`]: {
    marginLeft: '10px',
    padding: '4px',
  },
}));

function ConnectorInstallInstructions({
  onCopy,
  connectorToken,
  generateToken,
  disableGenerate,
  warning,
  onWarningClick,
}) {
  const installSteps = [
    {
      title: '1. Create the Anyware Connector server',
      secondary:
        'Create a dedicated server for GCP, AWS, and Private Cloud with the necessary specifications.',
      supportLink: CREATE_CAC_LINK,
    },
    {
      title: '2. Verify the Anyware Connector server',
      secondary:
        'You need to SSH into the machine and ping the domain and a remote workstation in the domain to verify.',
      supportLink: VERIFY_CAC_LINK,
    },
    {
      title: '3. Enable external access',
      secondary:
        'Only required if you want to enable remote access to the workstations without requiring a VPN.',
      supportLink: ENABLE_EXTERNAL_ACCESS_CAC_LINK,
    },
    {
      title: '4. Download the Anyware Connector server',
      secondary:
        'Follow 2 simple steps to connect to the machine and download the Connector installer.',
      supportLink: DOWNLOAD_CAC_LINK,
    },
    {
      title: (
        <div>
          <Link href={CAC_TOKEN_LINK} target="_blank" rel="noopener noreferrer">
            5. Get connector token
          </Link>
          <Button
            className={classes.generateButton}
            data-testid="generate-button"
            color="primary"
            disabled={disableGenerate}
            onClick={generateToken}
          >
            Generate
          </Button>
          {warning && (
            <IconButton
              className={classes.warningButton}
              onClick={onWarningClick}
              data-testid="create-connector-warning"
              size="large"
            >
              <WarningIcon className={classes.warningIcon} />
            </IconButton>
          )}
        </div>
      ),
      secondary: (
        <div>
          <div className={classes.moreInfoText}>
            Copy the token to be used when installing the Anyware Connector.
          </div>
          <textarea
            className={classNames(classes.tokenField, {
              [classes.largeTokenField]: connectorToken,
            })}
            readOnly
            data-testid="connector-token-output"
            value={connectorToken}
          />
          <div className={classes.tokenSubtext}>
            (token is only valid for 1 hour.)
          </div>
        </div>
      ),
      action: (
        <CopyToClipboard
          id="copy-to-clipboard"
          text={connectorToken}
          onCopy={onCopy}
        >
          <IconButton
            className={classes.copyButton}
            disabled={!connectorToken}
            size="large"
          >
            <CopyIcon opacity={connectorToken ? '1' : '0.5'} />
          </IconButton>
        </CopyToClipboard>
      ),
    },
    {
      title: '6. Install the Anyware Connector',
      secondary:
        'When the installer completes, the IP address of the Anyware Connector will be displayed.',
      supportLink: INSTALL_CAC_LINK,
    },
  ];
  const lastStep = installSteps.length - 1;

  return (
    <Root>
      <Typography className={classes.inputTextFieldLabel}>
        Private cloud install instructions
      </Typography>
      <Paper className={classes.installInstructionsContainer}>
        <Grid
          container
          direction="column"
          className={classes.installInstructionsGrid}
        >
          {installSteps.map((step, index) => (
            <Grid item className={classes.installGridRow} key={step.secondary}>
              <Grid container>
                <Grid item xs={1}>
                  <div className={classes.stepCircle} />
                  {index !== lastStep && <div className={classes.stepLine} />}
                </Grid>
                <Grid item xs={10} className={classes.installTitle}>
                  {step.supportLink ? (
                    <Link
                      href={step.supportLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {step.title}
                    </Link>
                  ) : (
                    step.title
                  )}
                  {typeof step.secondary === 'string' ? (
                    <div className={classes.moreInfoText}>{step.secondary}</div>
                  ) : (
                    step.secondary
                  )}
                </Grid>
                <Grid item xs={1}>
                  {step.action}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Root>
  );
}

ConnectorInstallInstructions.propTypes = {
  onCopy: PropTypes.func,
  connectorToken: PropTypes.string,
  generateToken: PropTypes.func,
  disableGenerate: PropTypes.bool,
  warning: PropTypes.bool,
  onWarningClick: PropTypes.func,
};

ConnectorInstallInstructions.defaultProps = {
  connectorToken: '',
  generateToken: () => 'No function provided',
  onCopy: () => 'No function provided',
  disableGenerate: true,
  warning: false,
  onWarningClick: () => 'No function provided',
};

export default ConnectorInstallInstructions;
