import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {post} from 'api/Api';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';
import {capitalize} from 'helpers/core';
import useNameValidation from 'hooks/useNameValidation';
import usePendingChanges from 'hooks/usePendingChanges';
import useSnackbar from 'hooks/useSnackbar';
import {logoutUserFromCAM, receiveResource} from 'redux/actions/dataActions';
import {setDeploymentFilter} from 'redux/actions/deploymentFilterActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {goBack, push} from 'redux/ReduxHistory';
import {
  DEPLOYMENTS,
  ERROR_INVALID_PARAMS,
  LINK_GET_REGISTRATION_CODE,
} from 'utils/constants';
import {isEmpty, isRegCodeFormat, sanitizeValues} from 'utils/utils';

const PREFIX = 'CreateDeployment';

const classes = {
  createPageContainer: `${PREFIX}-createPageContainer`,
  createPageTitleContainer: `${PREFIX}-createPageTitleContainer`,
  createPageTitle: `${PREFIX}-createPageTitle`,
  inputTextFieldLabel: `${PREFIX}-inputTextFieldLabel`,
  buttonRow: `${PREFIX}-buttonRow`,
  button: `${PREFIX}-button`,
  dropdownMenuItem: `${PREFIX}-dropdownMenuItem`,
  inputSectionTextField: `${PREFIX}-inputSectionTextField`,
  loadingSpinner: `${PREFIX}-loadingSpinner`,
  inputProps: `${PREFIX}-inputProps`,
  helpTipContainer: `${PREFIX}-helpTipContainer`,
  helpTipText: `${PREFIX}-helpTipText`,
  disclaimerText: `${PREFIX}-disclaimerText`,
  helpLink: `${PREFIX}-helpLink`,
  tooltip: `${PREFIX}-tooltip`,
  tooltipIcon: `${PREFIX}-tooltipIcon`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.createPageContainer}`]: theme.createPage.createPageContainer,
  [`& .${classes.createPageTitleContainer}`]:
    theme.createPage.createPageTitleContainer,
  [`& .${classes.createPageTitle}`]: theme.createPage.createPageTitle,
  [`& .${classes.inputTextFieldLabel}`]: theme.createPage.inputTextFieldLabel,
  [`& .${classes.buttonRow}`]: theme.createPage.buttonRow,

  [`& .${classes.button}`]: {
    ...theme.createPage.button,
    marginRight: '10px',
  },

  [`& .${classes.dropdownMenuItem}`]: theme.dropDown,

  [`& .${classes.inputSectionTextField}`]: {
    backgroundColor: 'inherit',
    marginRight: '20px',
    width: '100%',
  },

  [`& .${classes.loadingSpinner}`]: theme.loadingSpinner,

  [`& .${classes.inputProps}`]: {
    height: '2.25rem',
    backgroundColor: theme.palette.surface.white,
  },

  [`& .${classes.helpTipContainer}`]: {
    marginTop: '4px',
  },

  [`& .${classes.helpTipText}`]: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '12px',
    letterSpacing: '0.4px',
    lineHeight: '16px',
    marginLeft: '4px',
    padding: '10px 0',
  },

  [`& .${classes.disclaimerText}`]: {
    color: theme.palette.surface.grey,
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    letterSpacing: '0.4px',
    lineHeight: '16px',
    marginLeft: '12px',
    width: '28.875rem',
  },

  [`& .${classes.helpLink}`]: {
    fontSize: '0.875rem',
  },

  [`& .${classes.tooltip}`]: {
    fontWeight: '450',
    borderColor: '#0076A9',
    backgroundColor: '#0076A9',
  },

  [`& .${classes.tooltipIcon}`]: {
    marginLeft: '0.5rem',
  },
}));

const regCodeHelperText = 'Invalid registration code';
const getRegCodeText = "Don't have a code? Talk to us now.";

function CreateDeployment() {
  const dispatch = useDispatch();
  const {setPendingChanges} = usePendingChanges();
  const {successSnackbar, errorSnackbar} = useSnackbar();

  const [deploymentName, setDeploymentName] = useState('');
  const [registrationCode, setRegistrationCode] = useState('');
  const [attemptingCreate, setAttemptingCreate] = useState(false);
  const [registrationCodeError, setRegistrationCodeError] = useState(false);
  const [deploymentNameError, validateName, nameHelperText] =
    useNameValidation();

  const handleDeploymentNameChange = (event) => {
    const {value} = event.target;
    setDeploymentName(value);
    validateName(value);
  };

  const handleRegCodeChange = (event) => {
    setRegistrationCode(event.target.value);
    setRegistrationCodeError(!isRegCodeFormat(event.target.value));
  };

  const handleError = (error) => {
    const {code} = error;

    let errorMessage;
    if (code === 401) {
      dispatch(logoutUserFromCAM());
      return;
    }

    switch (code) {
      case 400:
        if (error.data.reason) {
          errorMessage = error.data.reason;
          if (errorMessage === 'invalid registration code') {
            setRegistrationCodeError(true);
          }
          errorMessage = capitalize(errorMessage);
        } else {
          errorMessage = ERROR_INVALID_PARAMS;
        }
        break;
      case 403:
        errorMessage = 'You do not have sufficient privileges.';
        break;
      case 409:
        errorMessage = 'A deployment with this name already exists.';
        break;
      case 500:
        if (error.message) {
          errorMessage = capitalize(error.message);
        } else {
          errorMessage = 'Internal error occurred. Please try again.';
        }
        break;
      default:
        errorMessage = 'Unknown error occurred.';
        break;
    }

    setAttemptingCreate(false);
    errorSnackbar(errorMessage);
  };

  const disableCreate = () =>
    !(
      deploymentName &&
      registrationCode &&
      !deploymentNameError &&
      !registrationCodeError &&
      !attemptingCreate
    );

  const addDeployment = async () => {
    setAttemptingCreate(true);
    let response;
    try {
      response = await post({
        path: 'deployments',
        data: {
          deploymentName: sanitizeValues(deploymentName),
          registrationCode: sanitizeValues(registrationCode),
        },
      });
    } catch (error) {
      return handleError(error);
    }

    const deployment = response.data;
    const {deploymentId} = deployment;

    dispatch(receiveResource(DEPLOYMENTS, [deployment]));
    dispatch(setDeploymentFilter(deployment));

    const path = `/app/deployments/edit/${deploymentId}`;
    dispatch(push(path));
    successSnackbar(
      `Deployment "${response.data.deploymentName}" has been created.`
    );

    dispatch(fetchTableData(DEPLOYMENTS));

    return true;
  };

  const handleCancel = () => {
    setPendingChanges(false);
    dispatch(goBack());
  };

  useEffect(() => {
    const havePendingChanges =
      !isEmpty(deploymentName) || !isEmpty(registrationCode);

    setPendingChanges(havePendingChanges && !attemptingCreate);
  }, [deploymentName, registrationCode, attemptingCreate, setPendingChanges]);

  return (
    <Root>
      <CAMCard>
        <GridN singleColumn>
          <div className={classes.createPageTitleContainer}>
            <Typography className={classes.createPageTitle}>
              Deployments
            </Typography>
            <KeyboardArrowRight />
            <Typography className={classes.createPageTitle}>
              Create Deployment
            </Typography>
          </div>

          <GridN>
            <>
              <InputLabel displayText="Deployment name" />
              <TextField
                data-testid="deployment-name"
                variant="outlined"
                autoComplete="off"
                required
                value={deploymentName}
                onChange={(event) => handleDeploymentNameChange(event)}
                error={deploymentNameError}
                helperText={deploymentNameError ? nameHelperText : ''}
                InputProps={{className: classes.inputProps}}
                className={classes.inputSectionTextField}
                placeholder="Enter deployment name"
              />
            </>
            <>
              <InputLabel
                displayText="PCoIP registration code"
                tooltipText="Please store the registration code in a secure location as it cannot be retrieved later."
              />

              <TextField
                data-testid="registration-code"
                variant="outlined"
                autoComplete="off"
                required
                value={registrationCode}
                onChange={(event) => handleRegCodeChange(event)}
                error={registrationCodeError}
                helperText={registrationCodeError ? regCodeHelperText : ''}
                InputProps={{
                  className: classes.inputProps,
                }}
                className={classes.inputSectionTextField}
                placeholder="Enter registration code"
                type="password"
              />
              <Link
                className={classes.helpLink}
                href={LINK_GET_REGISTRATION_CODE}
                target="_blank"
                rel="noopener noreferrer"
              >
                {getRegCodeText}
              </Link>
            </>
          </GridN>
        </GridN>

        <div className={classes.buttonRow}>
          <Button
            data-testid="cancel-button"
            className={classes.button}
            variant="outlined"
            color="primary"
            disabled={attemptingCreate}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <SaveButton
            data-testid="save-button"
            disabled={disableCreate()}
            onClick={addDeployment}
            saving={attemptingCreate}
            buttonText="Create"
            buttonTextSaving="Creating"
          />
        </div>
      </CAMCard>
    </Root>
  );
}

export default CreateDeployment;
