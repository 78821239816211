import PropTypes from 'prop-types';

function ConditionalWrapper({condition, wrapper, children}) {
  return condition ? wrapper(children) : children;
}

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool,
  wrapper: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

ConditionalWrapper.defaultProps = {
  condition: false,
  wrapper: () => {},
  children: <></>,
};

export default ConditionalWrapper;
