import {Card, CardContent, Grid} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {put} from 'api/Api';
import AWMSlider from 'components/CAM/inputs/AWMSlider/AWMSlider';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';
import {fetchPollingSettings, handleApiError} from 'redux/actions/dataActions';
import {
  POLLING_SETTINGS,
  POLLING_INTERVAL_DEFAULT_VALUE,
} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {
  selectResourceItem,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import {isEmpty} from 'utils/utils';

const PREFIX = 'PollingIntervalSlider';

const classes = {
  gridItem: `${PREFIX}-gridItem`,
};

const StyledCard = styled(Card)(() => ({
  width: '100%',
  flexDirection: 'row',
  display: 'flex',
  flexGrow: 1,

  [`& .${classes.gridItem}`]: {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    flexGrow: 1,
  },
}));

const pollingIntervalDescription =
  'Configure the interval to get state updates for all managed AMT workstations (in minutes).';
const pollingIntervalTooltip = `The default value is ${POLLING_INTERVAL_DEFAULT_VALUE} minutes.`;

const step = 5;
const minMark = 5;
const maxMark = 60;

function PollingIntervalSlider() {
  const dispatch = useDispatch();

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const [isSaving, setIsSaving] = useState(false);

  const {item: pollingSettings, isFetching: fetchingPollingSettings} =
    useSelector((state) =>
      selectResourceItem(state, POLLING_SETTINGS, deploymentId)
    );

  const [pollingInterval, setPollingInterval] = useState(null);

  useEffect(() => {
    if (deploymentId) {
      dispatch(fetchPollingSettings());
    }
  }, [deploymentId]);

  useEffect(() => {
    if (!fetchingPollingSettings && !isEmpty(pollingSettings)) {
      setPollingInterval(pollingSettings.interval);
    }
  }, [JSON.stringify(pollingSettings)]);

  const updatePollingInterval = async (newValue) => {
    setIsSaving(true);
    const data = {interval: newValue};
    const path = mapResourceToPath(POLLING_SETTINGS, {deploymentId});

    try {
      await put({path, data});
      dispatch(fetchPollingSettings());
    } catch (err) {
      handleApiError(err);
    }
    setIsSaving(false);
  };

  const handleValueChange = (_, newValue) => {
    setPollingInterval(newValue);
  };

  const handleChangeCommitted = (_, newValue) => {
    updatePollingInterval(newValue);
  };

  const generateSliderArray = () => {
    const marksArray = [];
    for (let i = minMark; i <= maxMark; i += step) {
      marksArray.push({
        value: i,
        label: i.toString(),
      });
    }
    return marksArray;
  };

  const marksObject = {
    marksArray: generateSliderArray(),
    minMark,
    maxMark,
    step,
  };

  const labelFormatter = (value) => `${value} min`;

  return (
    <StyledCard elevation={0} variant="outlined" className={classes.gridItem}>
      <CardContent className={classes.gridItem}>
        <Grid container direction="row" className={classes.gridItem}>
          <div className={classes.gridItem}>
            <InputLabel
              displayText={pollingIntervalDescription}
              tooltipText={pollingIntervalTooltip}
            />
          </div>
          <Grid item className={classes.gridItem}>
            {pollingInterval && (
              <AWMSlider
                className={classes.gridItem}
                marksObject={marksObject}
                value={pollingInterval}
                hideTrack
                onChange={handleValueChange}
                labelFormatter={labelFormatter}
                onChangeCommitted={handleChangeCommitted}
                testIdPrefix="polling-interval"
                disabled={isSaving}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
}

export default PollingIntervalSlider;
