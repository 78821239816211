import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import CAMTable from 'components/table/Table';
import {
  EDIT_WEBHOOK_LINK,
  WEBHOOKS,
  WEBHOOKS_EVENT_TYPE_ALLOCATE_RESOURCE,
  WEBHOOKS_EVENT_TYPE_AUTHENTICATE,
  WEBHOOKS_EVENT_TYPE_GET_RESOURCE_LIST,
  WEBHOOKS_EVENT_TYPE_SESSION_END,
  WEBHOOKS_EVENT_TYPE_SESSION_START,
} from 'utils/constants';
import {linkTo} from 'utils/utils';
import {selectDataForTable} from 'utils/reduxSelectors';
import config from 'config';
import PageHeader from 'components/CAM/layout/PageHeader/PageHeader';
import DeleteWebhooksDialog from 'components/webhooks/DeleteWebhooksDialog';
import AddWebhookButton from './AddWebhookButton';
import AssociatedResourcesChipDropdownSelector from './AssociatedResourcesChipDropdownSelector';

function Webhooks() {
  const {
    data: webhooks,
    isFetching: isFetchingWebhooks,
    total,
  } = useSelector((state) => selectDataForTable(state, WEBHOOKS));

  const mapEventTypeToLabel = (eventType) => {
    switch (eventType) {
      case WEBHOOKS_EVENT_TYPE_AUTHENTICATE:
        return 'Authentication';
      case WEBHOOKS_EVENT_TYPE_GET_RESOURCE_LIST:
        return 'Resource List';
      case WEBHOOKS_EVENT_TYPE_ALLOCATE_RESOURCE:
        return 'Allocate Resource';
      case WEBHOOKS_EVENT_TYPE_SESSION_END:
        return 'Session End';
      case WEBHOOKS_EVENT_TYPE_SESSION_START:
        return 'Session Start';
      default:
        return '';
    }
  };
  const getEditWebhooksLink = (webhook) =>
    linkTo(`${EDIT_WEBHOOK_LINK}/${webhook.id}`);

  const renderAssociatedResourcesChipDropdownSelector = (
    associatedResources
  ) => {
    return (
      <AssociatedResourcesChipDropdownSelector
        associatedResources={associatedResources}
        toggleClickable
      />
    );
  };

  const prepareWebhookConfig = useCallback(
    (webhook) => ({
      ...webhook,
      eventType: mapEventTypeToLabel(webhook.eventType),
      associatedResources: renderAssociatedResourcesChipDropdownSelector(
        webhook.associatedResources
      ),
      endpoint: webhook.endpoint,
      link: getEditWebhooksLink(webhook),
    }),
    []
  );

  const prepareData = (data) =>
    data?.map((webhook) => prepareWebhookConfig(webhook));

  return (
    <>
      {config.isBeta() && (
        <PageHeader
          titleText="Webhooks"
          descriptionText="Create, configure, and view webhooks in this section."
          actionButton={<AddWebhookButton />}
        />
      )}
      <CAMTable
        id="pool-webhooks-table"
        tableTitle="Webhooks"
        resource={WEBHOOKS}
        idField="id"
        data={prepareData(webhooks)}
        total={total}
        loadingData={isFetchingWebhooks}
        hideTitle={config.isBeta()}
      />
      <DeleteWebhooksDialog />
    </>
  );
}

export default Webhooks;