import {Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import CopyToClipboard from 'components/CAM/buttons/CopyToClipboard/CopyToClipboard';
import BetaSvg from 'icons/beta_icon.svg';
import useSnackbar from 'hooks/useSnackbar';
import {
  CAC_V2_DOCUMENTATION_LINK,
  CAC_V2_RELEASE_NOTES_FEED_URL,
  AWM_RELEASE_NOTES_FEED_URL,
  AWM_SAAS_BETA_DOCUMENTATION_LINK,
  AWM_SAAS_DOCUMENTATION_LINK,
  RELEASE_NOTES_BETA,
  RELEASE_NOTES_CAC,
  RELEASE_NOTES_GA,
} from 'utils/constants';
import config from '../../config';
import ReleaseNotes from './ReleaseNotes';

const PREFIX = 'ReleaseNotesPage';

const classes = {
  betaIcon: `${PREFIX}-betaIcon`,
  betaInfoContainer: `${PREFIX}-betaInfoContainer`,
  betaInfoText: `${PREFIX}-betaInfoText`,
  container: `${PREFIX}-container`,
  standaloneCard: `${PREFIX}-standaloneCard`,
  floatButton: `${PREFIX}-floatButton`,
};

const StyledGrid = styled(Grid)(({theme}) => ({
  [`& .${classes.betaIcon}`]: {
    height: '24px',
    paddingRight: '10px',
    width: '42px',
  },

  [`& .${classes.betaInfoContainer}`]: {
    backgroundColor: '#FFB75D',
    borderRadius: '4px',
    marginBottom: '0.5rem',
    padding: '20px',
  },

  [`& .${classes.betaInfoText}`]: {
    fontSize: '0.875rem',
  },

  [`&.${classes.container}`]: {
    margin: '2rem 0px 6.5rem 2rem',
    width: '1000px',
    [theme.breakpoints.down('lg')]: {
      width: '800px',
    },
    [theme.breakpoints.down('md')]: {
      width: '450px',
    },
  },

  [`& .${classes.standaloneCard}`]: {padding: '20px 20px 10px 20px'},
  [`& .${classes.floatButton}`]: {float: 'right'},
}));

const ADMIN_CONSOLE_TAB = 0;
const BETA_ADMIN_CONSOLE_TAB = 1;
const CACV2_TAB = 2;

const CAM_BETA_LINK = `${config.GATEWAY_ADDRESS}/beta-ui/app`;

const saasTabs = {
  [ADMIN_CONSOLE_TAB]: {
    key: ADMIN_CONSOLE_TAB,
    label: 'Anyware Manager',
    feedUrl: AWM_RELEASE_NOTES_FEED_URL,
    type: RELEASE_NOTES_GA,
    documentationUrl: AWM_SAAS_DOCUMENTATION_LINK,
  },
  [BETA_ADMIN_CONSOLE_TAB]: {
    key: BETA_ADMIN_CONSOLE_TAB,
    label: 'Anyware Manager (Beta)',
    feedUrl: AWM_RELEASE_NOTES_FEED_URL,
    type: RELEASE_NOTES_BETA,
    documentationUrl: AWM_SAAS_BETA_DOCUMENTATION_LINK,
  },
  [CACV2_TAB]: {
    key: CACV2_TAB,
    label: 'Anyware Connector',
    feedUrl: CAC_V2_RELEASE_NOTES_FEED_URL,
    type: RELEASE_NOTES_CAC,
    documentationUrl: CAC_V2_DOCUMENTATION_LINK,
  },
};

const tabs = config.STANDALONE ? [] : saasTabs;

const setTabPath = (tabIndex) => {
  let path = window.location.pathname.split('/');
  if (path.length === 4) {
    path = path.slice(0, path.length - 1);
  }
  path.push(tabs[tabIndex].type);
  window.history.replaceState(null, 'Release Notes', path.join('/'));
};

const getTabIndexFromPath = () => {
  const path = window.location.pathname.split('/');
  if (path.length === 3) {
    return ADMIN_CONSOLE_TAB;
  }
  const tabPath = path[path.length - 1];
  switch (tabPath) {
    case RELEASE_NOTES_BETA:
      return BETA_ADMIN_CONSOLE_TAB;
    case RELEASE_NOTES_CAC:
      return CACV2_TAB;
    default:
      return ADMIN_CONSOLE_TAB;
  }
};

function ReleaseNotesPage() {
  const {releaseNoteType} = useParams();

  const {successSnackbar} = useSnackbar();
  const [selectedTab, setSelectedTab] = useState(getTabIndexFromPath());
  const {feedUrl, documentationUrl, type: releaseType} = tabs[selectedTab];

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
    setTabPath(newValue);
  };

  useEffect(() => {
    let tabIndex = ADMIN_CONSOLE_TAB;
    if (releaseNoteType) {
      switch (releaseNoteType) {
        case RELEASE_NOTES_BETA:
          tabIndex = BETA_ADMIN_CONSOLE_TAB;
          break;
        case RELEASE_NOTES_CAC:
          tabIndex = CACV2_TAB;
          break;
        default:
          tabIndex = ADMIN_CONSOLE_TAB;
      }
      setTabPath(tabIndex);
    }
  }, [releaseNoteType]);

  const handleCopy = () => {
    successSnackbar('Release notes RSS feed url was copied to clipboard.');
  };

  const renderBetaInfo = () => (
    <Grid
      container
      alignItems="center"
      wrap="nowrap"
      className={classes.betaInfoContainer}
    >
      <img src={BetaSvg} alt="" className={classes.betaIcon} />
      <Typography className={classes.betaInfoText}>
        {'The '}
        <Link href={CAM_BETA_LINK}>beta version</Link>
        {` of the Anyware Manager includes unsupported features that may be incomplete
         and considered not ready for production.`}
      </Typography>
    </Grid>
  );

  return (
    <StyledGrid
      container
      direction="column"
      spacing={3}
      className={classes.container}
    >
      <Grid item>
        <Typography variant="h5">Release Notes</Typography>
      </Grid>
      <Grid item>
        <Tabs
          data-testid="release-notes-tabs"
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
        >
          {Object.values(tabs).map((tab) => (
            <Tab key={tab.key} label={tab.label} />
          ))}
        </Tabs>
      </Grid>
      {!config.STANDALONE && (
        <>
          {releaseType === RELEASE_NOTES_BETA && (
            <Grid item>{renderBetaInfo()}</Grid>
          )}
          <Grid item>
            <Card className={classes.standaloneCard} elevation={0}>
              You can also subscribe to release notes via RSS feed.
              <CopyToClipboard
                text={feedUrl}
                onCopy={handleCopy}
                showAsButton
                buttonProps={{
                  size: 'small',
                  variant: 'contained',
                  className: classes.floatButton,
                  text: 'Copy feed URL',
                }}
              />
            </Card>
          </Grid>
        </>
      )}
      <Grid item>
        <Card className={classes.standaloneCard} elevation={0}>
          See {tabs[selectedTab].label} documentation
          <Button
            className={classes.floatButton}
            variant="contained"
            size="small"
            href={documentationUrl}
            target="_blank"
          >
            Documentation
          </Button>
        </Card>
      </Grid>
      <Grid item>
        <ReleaseNotes releaseNotesFeedUrl={feedUrl} releaseType={releaseType} />
      </Grid>
    </StyledGrid>
  );
}

export default ReleaseNotesPage;
