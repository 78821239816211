import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import WarningIcon from '@mui/icons-material/ReportProblem';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CopyToClipboard from 'components/CAM/buttons/CopyToClipboard/CopyToClipboard';
import {del, get, post} from 'api/Api';
import CamButton from 'components/CAM/buttons/CAMButton/CAMButton';
import ProviderIcon from 'components/CAM/icons/ProviderIcon/ProviderIcon';
import TextInput from 'components/CAM/inputs/TextInput/TextInput';
import config from 'config';
import usePendingChanges from 'hooks/usePendingChanges';
import NewElement from 'icons/new_element.svg';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {
  handleApiError,
  fetchCloudServiceAccounts,
} from 'redux/actions/dataActions';
import {
  AWS,
  AWS_ADD_ROLE_PERMISSIONS_LINK,
  AWS_ADD_USER_PERMISSIONS_LINK,
  AWS_CREATE_ROLE_DOC_LINK,
  AWS_ROLE_ARN_PLACEHOLDER,
  CLOUD_SERVICE_ACCOUNTS,
} from 'utils/constants';
import {mapProviders} from 'utils/Mappings';
import {selectData} from 'utils/reduxSelectors';
import {isEmpty, sanitizeValues} from 'utils/utils';

const PREFIX = 'AwsCredentials';

const classes = {
  textButton: `${PREFIX}-textButton`,
  copyButton: `${PREFIX}-copyButton`,
  buttonRow: `${PREFIX}-buttonRow`,
  chevronButton: `${PREFIX}-chevronButton`,
  cloudCredentialContainer: `${PREFIX}-cloudCredentialContainer`,
  cloudCredentialHeader: `${PREFIX}-cloudCredentialHeader`,
  cloudCredentialStatus: `${PREFIX}-cloudCredentialStatus`,
  cloudCredentialTitle: `${PREFIX}-cloudCredentialTitle`,
  credentialInput: `${PREFIX}-credentialInput`,
  cursorPointer: `${PREFIX}-cursorPointer`,
  cursorWait: `${PREFIX}-cursorWait`,
  dialogHelpText: `${PREFIX}-dialogHelpText`,
  flexGrow: `${PREFIX}-flexGrow`,
  generateRow: `${PREFIX}-generateRow`,
  helperText: `${PREFIX}-helperText`,
  inputMultiline: `${PREFIX}-inputMultiline`,
  inputTextFieldLabel: `${PREFIX}-inputTextFieldLabel`,
  roleField: `${PREFIX}-roleField`,
  roleFieldLabel: `${PREFIX}-roleFieldLabel`,
  roleHelpLink: `${PREFIX}-roleHelpLink`,
  divider: `${PREFIX}-divider`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  alignCenter: `${PREFIX}-alignCenter`,
  column: `${PREFIX}-column`,
  displayContainer: `${PREFIX}-displayContainer`,
  primaryContainer: `${PREFIX}-primaryContainer`,
  gridContainer: `${PREFIX}-gridContainer`,
  gridRow: `${PREFIX}-gridRow`,
  stepCircle: `${PREFIX}-stepCircle`,
  stepContainer: `${PREFIX}-stepContainer`,
  stepLine: `${PREFIX}-stepLine`,
  svcAccSubtitle: `${PREFIX}-svcAccSubtitle`,
  warningIcon: `${PREFIX}-warningIcon`,
  actionButton: `${PREFIX}-actionButton`,
};

const StyledPaper = styled(Paper)(({theme}) => ({
  [`& .${classes.textButton}`]: {
    fontSize: '0.75rem',
    padding: '3px 6px',
    textTransform: 'none',
  },

  [`& .${classes.copyButton}`]: {
    padding: '9px',
  },

  [`& .${classes.buttonRow}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '20px',
  },

  [`& .${classes.chevronButton}`]: {padding: 0},

  [`&.${classes.cloudCredentialContainer}`]: {
    marginTop: '7px',
    padding: '0px 10px',
    boxShadow: 'none',
    border: '1px solid #ccc',
  },

  [`& .${classes.cloudCredentialHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    height: '2.25rem',
    cursor: 'pointer',
  },

  [`& .${classes.cloudCredentialStatus}`]: {
    margin: 0,
    fill: 'rgba(0,0,0,0.54)',
  },

  [`& .${classes.cloudCredentialTitle}`]: {
    flex: 1,
    marginLeft: '12px',
  },

  [`& .${classes.credentialInput}`]: {
    marginRight: '12px',
  },

  [`& .${classes.cursorPointer}`]: {cursor: 'pointer'},
  [`& .${classes.cursorWait}`]: {cursor: 'wait'},

  [`& .${classes.dialogHelpText}`]: {
    color: theme.palette.text.default,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: '500',
    marginTop: '16px',
  },

  [`& .${classes.flexGrow}`]: {flexGrow: 1},

  [`& .${classes.generateRow}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },

  [`& .${classes.helperText}`]: {
    color: theme.palette.surface.grey,
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    letterSpacing: '0.4px',
  },

  [`& .${classes.inputMultiline}`]: {
    padding: '8px 10px',
  },

  [`& .${classes.inputTextFieldLabel}`]: {
    color: theme.palette.info.main,
    fontFamily: '.SF NS Display',
    fontSize: '0.75rem',
    lineHeight: '15px',
  },

  [`& .${classes.roleField}`]: {
    color: 'rgba(0,0,0,0.38)',
    fontFamily: 'Roboto',
    fontWeight: 500,
    height: '35px',
    marginRight: '12px',
    padding: '0px',
  },

  [`& .${classes.roleFieldLabel}`]: {
    color: theme.palette.info.main,
    fontFamily: '.SF NS Display',
    fontSize: '0.75rem',
    lineHeight: '15px',
  },

  [`& .${classes.roleHelpLink}`]: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
  },

  [`& .${classes.divider}`]: {
    backgroundColor: theme.palette.divider,
    marginTop: '12px',
  },

  [`& .${classes.sectionTitle}`]: {
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
  },

  [`& .${classes.alignCenter}`]: {
    alignSelf: 'center',
  },

  [`& .${classes.column}`]: {width: '100%'},

  [`& .${classes.displayContainer}`]: {
    margin: '12px 0px',
  },

  [`& .${classes.primaryContainer}`]: {
    marginLeft: '4px',
    width: '100%',
  },

  [`& .${classes.gridContainer}`]: {
    marginBottom: '10px',
    marginTop: '3px',
  },

  [`& .${classes.gridRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },

  [`& .${classes.stepCircle}`]: {
    backgroundColor: theme.palette.common.white,
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: '100%',
    boxSizing: 'border-box',
    height: '13px',
    margin: '3px auto 0px',
    width: '13px',
  },

  [`& .${classes.stepContainer}`]: {
    minWidth: '13px',
    overflowY: 'hidden',
  },

  [`& .${classes.stepLine}`]: {
    backgroundColor: '#d2d9e7',
    height: '100%',
    margin: 'auto',
    width: '2px',
  },

  [`& .${classes.svcAccSubtitle}`]: {
    margin: '12px 0px 8px',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '500',
    color: theme.palette.text.default,
  },

  [`& .${classes.warningIcon}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.actionButton}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '12px',
  },
}));

const notCopiedMessage = 'Click to copy to clipboard';
const copiedMessage = 'Copied to clipboard';
const roleHelperText =
  'You must create a Role in your AWS account which Anyware Manager is able to assume before the service account can be added. Learn more about creating a Role in AWS';

function AwsCredentials({deploymentId}) {
  const dispatch = useDispatch();
  const {setPendingChanges} = usePendingChanges();

  const [showCredentialFields, setShowCredentialFields] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [externalId, setExternalId] = useState('');
  const [roleArn, setRoleArn] = useState('');
  const [accessKeyId, setAccessKeyId] = useState('');
  const [secretAccessKey, setSecretAccessKey] = useState('');
  const [credentialId, setCredentialId] = useState('');
  const [credentialsValid, setCredentialsValid] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [unmetRequirements, setUnmetRequirements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const provider = AWS;

  const clearForm = () => {
    setAccountId('');
    setExternalId('');
    setRoleArn('');
    setAccessKeyId('');
    setSecretAccessKey('');
    setCredentialId('');
    setCredentialsValid(false);
  };

  const {
    data: cloudServiceAccounts,
    isFetching: isFetchingCloudServiceAccounts,
  } = useSelector((state) => selectData(state, CLOUD_SERVICE_ACCOUNTS));

  useEffect(() => {
    setLoading(isFetchingCloudServiceAccounts);
  }, [deploymentId]);

  useEffect(() => {
    setLoading(isFetchingCloudServiceAccounts);
  }, [isFetchingCloudServiceAccounts]);

  useEffect(() => {
    const awsAccount =
      cloudServiceAccounts.find((acc) => acc.provider === AWS) || {};
    if (!isEmpty(awsAccount)) {
      if (config.STANDALONE) {
        setAccessKeyId(awsAccount.accessKeyId);
      } else {
        setRoleArn(awsAccount.roleArn);
      }
    }
    setCredentialId(awsAccount.id);
    setCredentialsValid(typeof awsAccount.id !== 'undefined');
    setLoading(isFetchingCloudServiceAccounts);
  }, [JSON.stringify(cloudServiceAccounts)]);

  useEffect(() => {
    setPendingChanges(roleArn && !loading && !credentialsValid);
  }, [roleArn, loading, credentialsValid]);

  const submitCredential = async () => {
    const path = `deployments/${deploymentId}/cloudServiceAccounts`;
    const credential = {};
    if (config.STANDALONE) {
      credential.accessKeyId = sanitizeValues(accessKeyId);
      credential.secretAccessKey = sanitizeValues(secretAccessKey);
    } else {
      credential.roleArn = sanitizeValues(roleArn);
    }

    const {data} = await post({
      path,
      data: {
        provider,
        credential,
      },
    });
    return data;
  };

  const deleteCredential = async () => {
    const path = `deployments/${deploymentId}/cloudServiceAccounts/${credentialId}`;
    try {
      await del({path});
      clearForm();
      setDialogMessage('Credential deleted!');
      setDialogOpen(true);
      dispatch(fetchCloudServiceAccounts({deploymentId}));
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };

  const handleGenerateClick = async () => {
    setLoading(true);
    const path = `deployments/${deploymentId}/cloudServiceAccounts/awsRole`;
    try {
      const {data} = await get({path});
      setAccountId(data.camAccountId);
      setExternalId(data.externalId);
    } catch (error) {
      dispatch(handleApiError(error));
    }
    setLoading(false);
  };

  const handleSubmitClick = async () => {
    setLoading(true);
    try {
      const credential = await submitCredential();
      setCredentialId(credential.id);
      setCredentialsValid(true);
      setDialogMessage('Credential added to deployment!');
      setUnmetRequirements([]);
      setDialogOpen(true);
      dispatch(fetchCloudServiceAccounts({deploymentId}));
    } catch (error) {
      const {requirements} = error.data || {};
      if (requirements) {
        setUnmetRequirements(requirements);
        const errorTitle = `Missing permissions: AWS ${
          config.STANDALONE ? 'user' : 'role'
        }`;
        setDialogMessage(errorTitle);
        setDialogOpen(true);
      } else {
        dispatch(handleApiError(error));
      }
    }
    setLoading(false);
  };

  const handleDeleteClick = () =>
    dispatch(
      openDialog(
        'Delete provider service account?',
        "Are you sure you want to delete your provider service account? You won't be able to power manage AWS workstations without it.",
        deleteCredential
      )
    );

  const handleCopy = (_, success) => {
    if (success) {
      setCopied(true);
    }
  };

  const handleMouseEnter = () => setCopied(false);

  const createRoleField = (label, value) => {
    let title;
    if (value) {
      title = copied ? copiedMessage : notCopiedMessage;
    } else {
      title = '';
    }
    return (
      <>
        <Typography className={classes.roleFieldLabel}>{label}</Typography>
        <div className={classes.generateRow}>
          <OutlinedInput
            className={classes.roleField}
            fullWidth
            disabled
            readOnly
            value={value}
            endAdornment={
              <CopyToClipboard
                text={value}
                onCopy={handleCopy}
                id="copy-to-clipboard"
                tooltipProps={{
                  text: title,
                  placement: 'top',
                  onMouseEnter: handleMouseEnter,
                }}
              />
            }
          />
        </div>
      </>
    );
  };

  const statusIcon = () => {
    if (loading) {
      return <CircularProgress size={16} />;
    }
    if (credentialsValid) {
      return <CheckIcon className={classes.cloudCredentialStatus} />;
    }
    return (
      <img src={NewElement} className={classes.cloudCredentialStatus} alt="" />
    );
  };

  const renderButton = (
    <div className={classes.alignCenter}>
      {credentialsValid ? (
        <Button
          data-testid="aws-delete"
          // color='primary'
          disabled={loading}
          onClick={handleDeleteClick}
          className={classes.textButton}
          variant="outlined"
          size="small"
        >
          Delete
        </Button>
      ) : (
        <Button
          data-testid="aws-submit"
          color="primary"
          disabled={!roleArn || loading}
          onClick={handleSubmitClick}
          className={classes.textButton}
          variant="outlined"
          size="small"
        >
          Submit
        </Button>
      )}
    </div>
  );

  const renderRoleHelpText = !credentialsValid && (
    <div className={classes.helperText}>
      {roleHelperText}{' '}
      <a
        href={AWS_CREATE_ROLE_DOC_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.roleHelpLink}
      >
        here.
      </a>
    </div>
  );

  const renderPermissionRequirements = () => {
    const missingPermissions = unmetRequirements.map((req) => req.permission);
    const displayedPermissions = missingPermissions.map((permission) => {
      const [serviceName, actionName] = permission.split(':');
      return (
        <Grid container alignItems="center" key={permission}>
          <Grid item xs={6}>
            <Typography>{serviceName}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{actionName}</Typography>
          </Grid>
        </Grid>
      );
    });

    return (
      <>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.svcAccSubtitle}>Service</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.svcAccSubtitle}>Action</Typography>
          </Grid>
        </Grid>
        {displayedPermissions}
        <Divider className={classes.divider} />

        {config.STANDALONE ? (
          <Typography className={classes.dialogHelpText}>
            The AWS user must have permissions to perform the specified actions.
            <br />
            {'Click '}
            <a
              href={AWS_ADD_USER_PERMISSIONS_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.roleHelpLink}
            >
              {'here '}
            </a>
            for instructions on how to add permissions to AWS users.
          </Typography>
        ) : (
          <Typography className={classes.dialogHelpText}>
            The AWS role must have permissions to perform the specified actions.
            <br />
            {'Click '}
            <a
              href={AWS_ADD_ROLE_PERMISSIONS_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.roleHelpLink}
            >
              {'here '}
            </a>
            for instructions on how to add permissions to AWS roles.
          </Typography>
        )}
      </>
    );
  };

  const renderDialog = !dialogOpen ? null : (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      data-testid="aws-dialog"
    >
      <DialogContent>
        <DialogContentText
          data-testid="aws-dialog-text"
          variant="h6"
          color="inherit"
        >
          {dialogMessage}
        </DialogContentText>
        {unmetRequirements.length > 0 && (
          <>
            <Grid container direction="row" spacing={1}>
              <Grid item className={classes.alignCenter}>
                <WarningIcon className={classes.warningIcon} />
              </Grid>
              <Grid item className={classes.alignCenter}>
                You need to add the permissions listed below.
              </Grid>
            </Grid>
            {renderPermissionRequirements()}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="aws-dialog-button-ok"
          onClick={() => setDialogOpen(false)}
          color="primary"
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderRoleInfoRow = (
    <div className={classes.gridRow}>
      <div className={classes.stepContainer}>
        <div className={classes.stepCircle} />
        <div className={classes.stepLine} />
      </div>
      <div className={classes.column}>
        <div className={classes.gridRow}>
          <div className={classes.primaryContainer}>
            <div className={classes.sectionTitle}>Generate role for AWS</div>
            {createRoleField('Anyware Manager Account ID', accountId)}
            {createRoleField('External ID', externalId)}
          </div>
          <div>
            <Button
              data-testid="aws-generate-role"
              color="primary"
              disabled={loading}
              onClick={handleGenerateClick}
              className={classes.textButton}
              variant="outlined"
              size="small"
            >
              Generate
            </Button>
          </div>
        </div>
        <Divider className={classes.divider} />
      </div>
    </div>
  );

  const renderRoleArn = (
    <>
      <Typography className={classes.inputTextFieldLabel}>Role ARN</Typography>
      <div className={classes.gridRow}>
        <TextField
          data-testid="aws-role-arn"
          placeholder={AWS_ROLE_ARN_PLACEHOLDER}
          fullWidth
          variant="outlined"
          name="roleArn"
          multiline
          maxRows={2}
          disabled={credentialsValid}
          value={roleArn}
          onChange={(event) => setRoleArn(event.target.value)}
          className={classes.credentialInput}
          size="small"
          // InputProps={{ {multiline: classes.inputMultiline}}}
        />
        {renderButton}
      </div>
    </>
  );

  const renderAccessKeyId = () => (
    <TextInput
      label="Access Key ID"
      value={accessKeyId}
      onChange={(value) => setAccessKeyId(value)}
      placeholder="Enter Access Key ID"
      disabled={credentialsValid}
    />
  );

  const renderSecretAccessKey = () => (
    <TextInput
      label="Secret Access Key"
      value={secretAccessKey}
      onChange={(value) => setSecretAccessKey(value)}
      placeholder="Enter Secret Access Key"
      disabled={credentialsValid}
      secret
    />
  );

  const renderRoleArnRow = (
    <div className={classes.gridRow}>
      <div className={classes.stepContainer}>
        <div className={classes.stepCircle} />
        <div className={classes.stepLine} />
      </div>
      <div className={classes.primaryContainer}>
        <div className={classes.sectionTitle}>Add Role to Anyware Manager</div>
        {renderRoleArn}
      </div>
    </div>
  );

  const renderEditCredentials = () => {
    if (config.STANDALONE) {
      const isDisabled = !accessKeyId || !secretAccessKey || loading;
      return (
        <form>
          {renderAccessKeyId()}
          {renderSecretAccessKey()}
          <div className={classes.actionButton}>
            <CamButton
              buttonText="Submit"
              onClick={handleSubmitClick}
              disabled={isDisabled}
            />
          </div>
        </form>
      );
    }
    return (
      <>
        {renderRoleHelpText}
        <div className={classes.gridContainer}>
          {renderRoleInfoRow}
          {renderRoleArnRow}
        </div>
      </>
    );
  };

  const renderViewCredentials = () => {
    if (config.STANDALONE) {
      return (
        <>
          {renderAccessKeyId()}
          <div className={classes.actionButton}>
            <CamButton
              buttonText="Delete"
              onClick={handleDeleteClick}
              disabled={loading}
            />
          </div>
        </>
      );
    }
    return renderRoleArn;
  };

  return (
    <StyledPaper className={classes.cloudCredentialContainer}>
      <div
        role="button"
        data-testid="expand-aws-credential-header"
        className={classNames(classes.cloudCredentialHeader, {
          [classes.cursorWait]: loading,
          [classes.cursorPoint]: !loading,
        })}
        disabled={loading}
        onClick={() => setShowCredentialFields(!showCredentialFields)}
      >
        {statusIcon()}
        <Typography className={classes.cloudCredentialTitle}>
          <Grid container>
            <ProviderIcon provider={AWS} />
            {mapProviders(AWS)}
          </Grid>
        </Typography>
        <IconButton
          className={classes.chevronButton}
          data-testid="expand-aws-credential-button"
          disableRipple
          disabled={loading}
          onClick={() => setShowCredentialFields(!showCredentialFields)}
          size="large"
        >
          {showCredentialFields ? (
            <KeyboardArrowUp className={classes.chevron} />
          ) : (
            <KeyboardArrowRight className={classes.chevron} />
          )}
        </IconButton>
      </div>

      {showCredentialFields &&
        (deploymentId ? (
          <>
            {!credentialsValid && (
              <div className={classes.displayContainer}>
                {renderEditCredentials()}
              </div>
            )}
            {credentialsValid && (
              <div className={classes.displayContainer}>
                {renderViewCredentials()}
              </div>
            )}
            {renderDialog}
          </>
        ) : (
          <div className={classes.displayContainer}>
            A deployment must be created before an AWS service account can be
            added.
          </div>
        ))}
    </StyledPaper>
  );
}

AwsCredentials.propTypes = {
  deploymentId: PropTypes.string,
};

AwsCredentials.defaultProps = {
  deploymentId: '',
};

export default AwsCredentials;
