import {useQuery, useMutation} from 'react-query';
import {del, get, post} from 'api/Api';

const linkServiceQueryKey = 'link-service-query-key';

const fetchLinkService = async (deploymentId) => {
  try {
    const response = await get({
      path: `deployments/${deploymentId}/link`,
    });
    return response.data;
  } catch (e) {
    if (e?.code === 404) {
      return null;
    }
    throw e;
  }
};

const useQueryLinkService = (deploymentId, options = {}) =>
  useQuery(
    [linkServiceQueryKey, {deploymentId}],
    () => fetchLinkService(deploymentId),
    {
      // This ensure no caching is performed, since the result can change base on user action.
      // Alternatively, when a rsm machine is added/removed, the cache can be updated/invalidated.
      cacheTime: 0,
      ...options,
    }
  );

const createLinkService = async ({deploymentId, name}) => {
  await post({
    path: `deployments/${deploymentId}/link`,
    data: {name},
  });
};

const useCreateLinkService = (options = {}) =>
  useMutation(
    ({deploymentId, name}) => createLinkService({deploymentId, name}),
    options
  );

const deleteLinkService = async (deploymentId) => {
  await del({
    path: `deployments/${deploymentId}/link`,
  });
};

const useDeleteLinkService = (options = {}) =>
  useMutation((deploymentId) => deleteLinkService(deploymentId), options);

export {
  useQueryLinkService,
  useCreateLinkService,
  useDeleteLinkService,
  linkServiceQueryKey,
};
