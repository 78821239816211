import {Divider, Grid, Paper, Tab, Tabs, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {del, get} from 'api/Api';
import MachineName from 'components/CAM/display/MachineName/MachineName';
import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import CAMPagination from 'components/common/Pagination';
import CAMSearchField from 'components/common/SearchField';
import SlimTable from 'components/common/SlimTable';
import {capitalize} from 'helpers/core';
import {useAbortController} from 'hooks/useAbortController';
import useDebounce from 'hooks/useDebounce';
import useDialog from 'hooks/useDialog';
import useSnackbar from 'hooks/useSnackbar';
import {
  changeUserAssignedToPoolMachine,
  handleApiError,
  receiveDeleteResource,
  requestDeleteResource,
  saveVariable,
} from 'redux/actions/dataActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {uncheckItem} from 'redux/actions/tableSelectActions';
import {
  AD_USERS,
  DEFAULT_ROWS_PER_PAGE,
  POOL_GROUPS,
  POOL_MACHINES,
  POOL_USERS,
  TABLE_CELL_LINK,
  TABLE_CELL_TEXT,
  VIEW_AD_USER_LINK,
} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {
  selectDataForTable,
  selectSelectedDeployment,
  selectSelectedPool,
} from 'utils/reduxSelectors';
import {isEmpty, linkTo} from 'utils/utils';
import MachineAssignmentDialog from './MachineAssignmentDialog';
import PoolGroupsActions from './PoolGroupsActions';
import PoolGroupsBulkActions from './PoolGroupsBulkActions';
import PoolUsersBulkActions from './PoolUsersBulkActions';
import SelectMachineDialog from './SelectMachineDialog';
import ViewMachineAssignmentDialog from './ViewMachineAssignmentDialog';

const PREFIX = 'PoolUsersTab';

const classes = {
  inputSectionSubtitle: `${PREFIX}-inputSectionSubtitle`,
  muiTabRoot: `${PREFIX}-muiTabRoot`,
  muiSearchBarInput: `${PREFIX}-muiSearchBarInput`,
  rootContainer: `${PREFIX}-rootContainer`,
  tableContainer: `${PREFIX}-tableContainer`,
  tabsContainer: `${PREFIX}-tabsContainer`,
  tabsEmptySpaceFiller: `${PREFIX}-tabsEmptySpaceFiller`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.inputSectionSubtitle}`]: theme.createPage.inputSectionSubtitle,

  [`& .${classes.muiTabRoot}`]: {
    backgroundColor: 'white',
  },

  [`& .${classes.muiSearchBarInput}`]: {
    height: '2.25rem',
    backgroundColor: theme.palette.surface.white,
  },

  [`& .${classes.rootContainer}`]: {
    padding: '1rem',
  },

  [`& .${classes.tableContainer}`]: {
    width: '60%',
    minWidth: '400px',
  },

  [`& .${classes.tabsContainer}`]: {
    margin: '1rem 0',
  },

  [`& .${classes.tabsEmptySpaceFiller}`]: {
    flexGrow: '1',
  },
}));

const USERS_TAB = 0;
const GROUPS_TAB = 1;

const tableFields = {
  [USERS_TAB]: [
    {id: 'name', text: 'Name', type: TABLE_CELL_LINK},
    {id: 'userName', text: 'User Name', type: TABLE_CELL_TEXT},
    {id: 'userGuid', text: 'User GUID', type: TABLE_CELL_TEXT},
    {id: 'status', text: 'Status', type: TABLE_CELL_TEXT},
    {
      id: 'displayMachineName',
      text: 'Assigned workstation',
      type: TABLE_CELL_TEXT,
    },
  ],
  [GROUPS_TAB]: [
    {id: 'group', text: 'Distinguished Name', type: TABLE_CELL_TEXT},
    {id: 'groupGuid', text: 'Group GUID', type: TABLE_CELL_TEXT},
  ],
};

const tableResources = {
  [USERS_TAB]: POOL_USERS,
  [GROUPS_TAB]: POOL_GROUPS,
};

const DEFAULT_PARAMS = {
  page: 0,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
};

function PoolUsersTab() {
  const [mapUsersAbortController, resetMapUsersAbortController] =
    useAbortController();
  const dispatch = useDispatch();

  const {successSnackbar} = useSnackbar();

  const [selectedTab, setSelectedTab] = useState(USERS_TAB);
  const [adUsers, setAdUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchDebounce, setSearchDebounce] = useDebounce(searchText, 1500);
  const [params, setParams] = useState(DEFAULT_PARAMS);

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {poolId} = useSelector((state) => selectSelectedPool(state));

  const {
    data: poolUsers,
    total: totalPoolUsers,
    isFetching: isFetchingUsers,
  } = useSelector((state) => selectDataForTable(state, POOL_USERS));

  const {
    data: poolGroups,
    total: totalPoolGroups,
    isFetching: isFetchingGroups,
  } = useSelector((state) => selectDataForTable(state, POOL_GROUPS));

  const {listSelectDialogOpen} = useSelector(
    (state) => state.data.dataByResource || {listSelectDialogOpen: false}
  );
  const {selectedPoolUser} = useSelector(
    (state) => state.data.dataByResource || {selectedPoolUser: false}
  );

  const handleSearchInputChange = (event) => setSearchText(event.target.value);
  const handleChangePage = (_, newPage) =>
    setParams({...params, page: newPage});
  const handleChangeRowsPerPage = (event) =>
    setParams({
      ...params,
      rowsPerPage: event.target.value,
      page: 0,
    });

  const handleChangeTabs = (_, newTab) => {
    setSelectedTab(newTab);
    setSearchText('');
    setParams({
      ...params,
      page: 0,
    });
  };

  const handleClear = () => {
    setSearchText('');
    setParams(DEFAULT_PARAMS);
  };

  const handleSearch = () => {
    setSearchDebounce(searchText);
  };

  const hashAdUsersByGuid = (data) =>
    data.reduce(
      (result, user) => ({
        ...result,
        [user.userGuid]: user,
      }),
      {}
    );

  const createUserTableObject = (adUser, poolUser) => ({
    ...poolUser,
    link: linkTo(
      `${VIEW_AD_USER_LINK}/${adUser.userGuid}/${adUser.deploymentId}`
    ),
    name: adUser.name || '',
    userName: adUser.userName || '',
    id: poolUser.entitlementId,
    status: capitalize(poolUser.status),
  });

  const createGroupTableObject = (group) => ({
    ...group,
    id: group.entitlementId,
  });

  const getAdUsersInPool = async () => {
    const userGuids = poolUsers.map((user) => user.userGuid).join(',');
    const path = mapResourceToPath(AD_USERS);
    const getParams = {
      deploymentId,
      userGuid: userGuids,
      limit: params.rowsPerPage,
    };

    let result = [];
    try {
      const resp = await get({path, params: getParams});
      result = resp.data || [];
    } catch (err) {
      handleApiError(err);
    }

    return result;
  };

  const mapEntitlementsToMachineData = async (users) => {
    const path = mapResourceToPath(POOL_MACHINES, {deploymentId, poolId});

    await dispatch(saveVariable('fetchingPoolsUsersMachines', true));
    const mappedUsers = await Promise.all(
      users.map(async (user) => {
        try {
          const response = await get({
            path,
            params: {assignedTo: user.userGuid},
          });
          const [machine] = response.data;
          if (!isEmpty(machine)) {
            if (user.userGuid === machine.assignedTo) {
              return {
                ...user,
                machineId: machine.machineId,
                machineName: machine.machineName,
                displayMachineName: <MachineName {...machine} />,
              };
            }
          }
        } catch (err) {
          // Errors here should not be displayed to user here
        }
        return user;
      })
    );
    await dispatch(saveVariable('fetchingPoolsUsersMachines', false));
    return mappedUsers;
  };

  const mapEntitlementsToUserData = async (users) => {
    try {
      const data = await getAdUsersInPool();
      const hashedAdUsers = hashAdUsersByGuid(data);
      const mappedUsers = users.map((user) =>
        createUserTableObject(hashedAdUsers[user.userGuid] || {}, user)
      );
      return mappedUsers;
    } catch (err) {
      return users;
    }
  };

  const mapUsers = async () => {
    if (!isFetchingUsers) {
      resetMapUsersAbortController();
      const newAbortController = mapUsersAbortController.current;
      let mappedUsers = await mapEntitlementsToUserData(poolUsers);
      if (newAbortController.signal.aborted) {
        return;
      }
      mappedUsers = await mapEntitlementsToMachineData(mappedUsers);
      if (!newAbortController.signal.aborted) {
        setAdUsers(mappedUsers);
      }
    }
  };

  const fetchUsersGroupsData = async () => {
    const selectedResource = tableResources[selectedTab];
    const {page, rowsPerPage} = params;
    let query = {};

    if (selectedResource === POOL_USERS) {
      query = {userGuid: searchDebounce};
    } else {
      query = {groupGuid: searchDebounce};
    }

    await dispatch(fetchTableData(selectedResource, page, rowsPerPage, query));
  };

  const prepareGroups = () => {
    if (!isFetchingGroups) {
      return poolGroups.map((group) => createGroupTableObject(group));
    }
    return [];
  };

  const setListSelectDialogOpen = (value) =>
    dispatch(saveVariable('listSelectDialogOpen', value));

  function PoolUsersActions({item: poolUser, onClose}) {
    const {triggerDialog} = useDialog();
    const fetchingPoolsUsersMachines = useSelector(
      (state) => state.data.dataByResource.fetchingPoolsUsersMachines
    );
    const [user, setUser] = useState(poolUser);

    const {poolName} = useSelector(
      (state) => state.data.dataByResource.selectedPool
    );
    const {page, rowsPerPage} = useSelector(
      (state) => state.tableData[POOL_USERS]
    );

    const deletePoolUser = async () => {
      dispatch(requestDeleteResource(POOL_USERS, poolUser.entitlementId));
      try {
        await del({
          path: `deployments/${deploymentId}/entitlements/${poolUser.entitlementId}`,
          data: {machineId: poolUser.machineId},
        });
        dispatch(receiveDeleteResource(POOL_USERS, poolUser.entitlementId));
        successSnackbar(
          `The entitlement for ${
            user.name || user.userGuid
          } was removed from the pool.`
        );
      } catch (err) {
        handleApiError(err);
      }
      dispatch(fetchTableData(POOL_USERS, page, rowsPerPage));
      dispatch(uncheckItem(POOL_USERS, poolUser));
    };

    const handleRemoveClick = () => {
      triggerDialog({
        title: 'Remove user from pool?',
        message: `Are you sure you want to remove ${
          user.name || user.userGuid
        } from ${poolName}?
        This will not delete the user's account, it will only remove their entitlement to the pool.`,
        onConfirm: deletePoolUser,
      });
    };

    const fetchCurrentUser = async () => {
      const path = mapResourceToPath(AD_USERS);
      const response = await get({
        path,
        params: {userGuid: poolUser.userGuid},
      });
      if (response.status === 'success') {
        const usersData = response.data;
        setUser(usersData[0]);
      } else {
        dispatch(handleApiError(response));
      }
    };

    const handleRemoveWorkstationAssignedToUser = () => {
      const unAssignRWFromUser = async () => {
        try {
          const {machineId} = poolUser;
          await changeUserAssignedToPoolMachine({
            machineId,
            poolId,
            deploymentId,
            userGuid: null,
          });
          successSnackbar('Machine was unassigned from the user.');
        } catch (err) {
          handleApiError(err);
        }
        await mapUsers();
      };
      triggerDialog({
        title: 'Remove workstation assigned to this user?',
        message: `Are you sure you want to remove user assignment to the workstation ${poolUser.machineName}?
        This will not delete the user or the remote workstation, but remove the assignment between them.`,
        onConfirm: unAssignRWFromUser,
      });
    };

    const addOrRemoveMachineAssignmentMenu = () => {
      if (isEmpty(poolUser.machineName)) {
        return (
          <KebabMenuItem
            dataTestId={`assign-${poolUser.entitlementId}`}
            menuText="Assign a workstation to this user"
            onClick={() => {
              onClose();
              dispatch(saveVariable('selectedPoolUser', poolUser));
              dispatch(saveVariable('listSelectDialogOpen', true));
            }}
          />
        );
      }
      return (
        <KebabMenuItem
          dataTestId={`remove-machine-user-${poolUser.userGuid}`}
          menuText="Remove assigned workstation"
          onClick={() => {
            onClose();
            handleRemoveWorkstationAssignedToUser();
          }}
        />
      );
    };

    useEffect(() => {
      fetchCurrentUser();
    }, []);

    return (
      <>
        {!fetchingPoolsUsersMachines && (
          <>
            {addOrRemoveMachineAssignmentMenu()}
            <Divider />
          </>
        )}
        <KebabMenuItem
          id={`remove-pool-user-${poolUser.entitlementId}`}
          dataTestId={`remove-${poolUser.entitlementId}`}
          menuText="Remove user from pool"
          onClick={() => {
            onClose();
            handleRemoveClick();
          }}
        />
      </>
    );
  }

  PoolUsersActions.propTypes = {
    item: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  useEffect(() => {
    fetchUsersGroupsData();
  }, [params.page, params.rowsPerPage, searchDebounce, selectedTab]);

  // when the values of the passed-in pool users changes, if there are users available,
  // map them to the user objects stored in the backend
  useEffect(() => {
    if (poolUsers && !isEmpty(poolUsers)) {
      mapUsers();
    } else if (isEmpty(poolUsers)) {
      setAdUsers([]);
    }
  }, [JSON.stringify(poolUsers)]);

  const noUsersFoundMessage = 'No users assigned to this pool';
  const noGroupsFoundMessage = 'No groups assigned to this pool';

  const renderPoolUsersTable = () => (
    <>
      <CAMSearchField
        id="pool-users-search-field"
        value={searchText}
        placeholder="Search Users by GUID"
        onChange={handleSearchInputChange}
        onSearch={handleSearch}
        onClear={handleClear}
      />
      <SlimTable
        data={adUsers}
        resource={POOL_USERS}
        fields={tableFields[selectedTab]}
        actions={PoolUsersActions}
        bulkActions={PoolUsersBulkActions}
        noDataMessage={noUsersFoundMessage}
        isFetching={isFetchingUsers}
      />
    </>
  );

  const renderPoolGroupsTable = () => (
    <>
      <CAMSearchField
        id="pool-groups-search-field"
        value={searchText}
        placeholder="Search Groups by GUID"
        onChange={handleSearchInputChange}
        onSearch={handleSearch}
        onClear={handleClear}
      />
      <SlimTable
        data={prepareGroups(poolGroups)}
        resource={POOL_GROUPS}
        fields={tableFields[selectedTab]}
        actions={PoolGroupsActions}
        bulkActions={PoolGroupsBulkActions}
        noDataMessage={noGroupsFoundMessage}
        isFetching={isFetchingGroups}
      />
    </>
  );

  const tableComponents = {
    [USERS_TAB]: renderPoolUsersTable,
    [GROUPS_TAB]: renderPoolGroupsTable,
  };

  const tableTotals = {
    [USERS_TAB]: totalPoolUsers,
    [GROUPS_TAB]: totalPoolGroups,
  };

  const renderSectionHeader = (headerText) => (
    <Grid item xs={12}>
      <Typography className={classes.inputSectionSubtitle}>
        {headerText}
      </Typography>
    </Grid>
  );

  const renderTabs = () => (
    <Grid item container>
      <Paper className={classes.tabsContainer}>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTabs}
          indicatorColor="primary"
        >
          <Tab label="Users" classes={{root: classes.muiTabRoot}} />
          <Tab label="Groups" classes={{root: classes.muiTabRoot}} />
        </Tabs>
      </Paper>
      <div className={classes.tabsEmptySpaceFiller} />
    </Grid>
  );

  const renderPagination = () => (
    <CAMPagination
      page={params.page}
      rowsPerPage={params.rowsPerPage}
      total={tableTotals[selectedTab]}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      dataTestId="pool-table-pagination"
    />
  );

  const onSelectMachine = async (machine) => {
    const {machineId, machineName} = machine;
    const {userGuid} = selectedPoolUser;

    dispatch(saveVariable('listSelectDialogOpen', false));
    try {
      await changeUserAssignedToPoolMachine({
        machineId,
        userGuid,
        deploymentId,
        poolId,
      });
      successSnackbar(`Workstation ${machineName} was assigned to the user`);
    } catch (err) {
      handleApiError(err);
    }
    await mapUsers();
  };

  const renderDialogSelectMachine = () => (
    <SelectMachineDialog
      open={listSelectDialogOpen}
      setOpen={setListSelectDialogOpen}
      user={selectedPoolUser}
      onSelectMachine={onSelectMachine}
    />
  );

  return (
    <Root>
      <Grid container direction="column" className={classes.rootContainer}>
        {renderSectionHeader('Select Users and Groups')}
        <Grid container direction="column" className={classes.tableContainer}>
          {renderTabs()}
          <Paper square>{tableComponents[selectedTab]()}</Paper>
          {renderPagination()}
        </Grid>
        <MachineAssignmentDialog />
        <ViewMachineAssignmentDialog />
      </Grid>
      {listSelectDialogOpen && renderDialogSelectMachine()}
    </Root>
  );
}

export default PoolUsersTab;
