import {mapPathnameToPageTitle, mapResourceToPath} from 'utils/Mappings';

import {
  WORKSTATION_POWERSTATE_RUNNING,
  WORKSTATION_POWERSTATE_STOPPED,
  WORKSTATION_POWERSTATE_STARTING,
  WORKSTATION_POWERSTATE_STOPPING,
  WORKSTATION_POWERSTATE_RESTARTING,
  AZURE_WORKSTATION_POWERSTATE_DEALLOCATED,
  AMT_POWERSTATE_OFF_SOFT,
  AMT_POWERSTATE_ON,
  REMOTE_WORKSTATIONS,
  EDIT_REMOTE_WORKSTATION,
  REMOTE_WORKSTATIONS_TABLE,
  WORKSTATIONS_LINK,
  POOL_MACHINES,
} from 'utils/constants';
import {post, del} from 'api/Api';
import {selectPathname} from 'utils/reduxSelectors';
import {
  updateWorkstation,
  updatePoolWorkstation,
  handleApiError,
  requestDeleteResource,
  receiveDeleteResource,
  receiveDeleteResourceError,
} from './dataActions';
import {enqueueSnackbar} from './snackbarActions';
import {addPollingItem} from './pollingActions';
import {fetchTableData} from './tableDataActions';
import {uncheckItem, uncheckAll} from './tableSelectActions';
import {replace} from './HistoryActions';
import {pushNotification} from './notificationActions';
import {getSingleWorkstationSnackbarMessage} from '../../utils/utils';

function makePostCall(machineId, action, data) {
  const path = `machines/${machineId}/${action}`;
  return post({path, data});
}

export const REQUEST_START_MACHINE = 'REQUEST_START_MACHINE';

export function requestStartMachine(machineId) {
  return {
    type: REQUEST_START_MACHINE,
    machineId,
  };
}

export const RESOLVE_START_MACHINE = 'RESOLVE_START_MACHINE';

export function resolveStartMachine(machine) {
  return {
    type: RESOLVE_START_MACHINE,
    machineId: machine.machineId,
  };
}

export const ERROR_START_MACHINE = 'ERROR_START_MACHINE';

export function errorStartMachine(machineId) {
  return {
    type: ERROR_START_MACHINE,
    machineId,
  };
}

export function pollForWorkstationRunning(machine) {
  return addPollingItem({
    endStates: [WORKSTATION_POWERSTATE_RUNNING, AMT_POWERSTATE_ON],
    key: `${REMOTE_WORKSTATIONS}-${machine.machineId}-powerState`,
    path: `${mapResourceToPath(REMOTE_WORKSTATIONS)}${machine.machineId}`,
    property: 'powerState',
    resourceId: machine.machineId,
    resourceType: REMOTE_WORKSTATIONS,
  });
}

export function pollForPoolMachineRunning(machine) {
  return addPollingItem({
    endStates: [WORKSTATION_POWERSTATE_RUNNING, AMT_POWERSTATE_ON],
    key: `${POOL_MACHINES}-${machine.machineId}-powerState`,
    path: `${mapResourceToPath(REMOTE_WORKSTATIONS)}${machine.machineId}`,
    property: 'powerState',
    resourceId: machine.machineId,
    resourceType: POOL_MACHINES,
  });
}

function workstationStartSnackbar(machineName) {
  return enqueueSnackbar({
    message: getSingleWorkstationSnackbarMessage('starting', machineName),
    options: {
      variant: 'success',
    },
  });
}

export function startMachine(machine, isPoolMachine = false) {
  const {machineId} = machine;
  return (dispatch) => {
    dispatch(requestStartMachine(machineId));

    return makePostCall(machineId, 'start').then(
      () => {
        dispatch(resolveStartMachine(machine));
        dispatch(workstationStartSnackbar(machine.machineName));
        if (isPoolMachine) {
          dispatch(
            updatePoolWorkstation({
              ...machine,
              powerState: WORKSTATION_POWERSTATE_STARTING,
            })
          );
          dispatch(pollForPoolMachineRunning(machine));
        } else {
          dispatch(
            updateWorkstation({
              ...machine,
              powerState: WORKSTATION_POWERSTATE_STARTING,
            })
          );
          dispatch(pollForWorkstationRunning(machine));
        }
      },
      (error) => {
        dispatch(errorStartMachine(machineId));
        dispatch(handleApiError(error));
      }
    );
  };
}

export const REQUEST_STOP_MACHINE = 'REQUEST_STOP_MACHINE';

export function requestStopMachine(machineId) {
  return {
    type: REQUEST_STOP_MACHINE,
    machineId,
  };
}

export const RESOLVE_STOP_MACHINE = 'RESOLVE_STOP_MACHINE';

function workstationStopSnackbar(machineName) {
  return enqueueSnackbar({
    message: getSingleWorkstationSnackbarMessage('stopping', machineName),
    options: {
      variant: 'success',
    },
  });
}

export const ERROR_STOP_MACHINE = 'ERROR_STOP_MACHINE';

export function errorStopMachine(machineId) {
  return {
    type: ERROR_STOP_MACHINE,
    machineId,
  };
}

export function pollForWorkstationStopped(machine) {
  return addPollingItem({
    endStates: [
      WORKSTATION_POWERSTATE_STOPPED,
      AZURE_WORKSTATION_POWERSTATE_DEALLOCATED,
      AMT_POWERSTATE_OFF_SOFT,
    ],
    key: `${REMOTE_WORKSTATIONS}-${machine.machineId}-powerState`,
    path: `${mapResourceToPath(REMOTE_WORKSTATIONS)}${machine.machineId}`,
    property: 'powerState',
    resourceId: machine.machineId,
    resourceType: REMOTE_WORKSTATIONS,
  });
}

export function pollForPoolMachineStopped(machine) {
  return addPollingItem({
    endStates: [
      WORKSTATION_POWERSTATE_STOPPED,
      AZURE_WORKSTATION_POWERSTATE_DEALLOCATED,
      AMT_POWERSTATE_OFF_SOFT,
    ],
    key: `${POOL_MACHINES}-${machine.machineId}-powerState`,
    path: `${mapResourceToPath(REMOTE_WORKSTATIONS)}${machine.machineId}`,
    property: 'powerState',
    resourceId: machine.machineId,
    resourceType: POOL_MACHINES,
  });
}

export function resolveStopMachine(machine) {
  return {
    type: RESOLVE_STOP_MACHINE,
    machineId: machine.machineId,
  };
}

export function stopMachine(machine, isPoolMachine = false, stopState) {
  const {machineId} = machine;
  const data = stopState ? {stopState} : {};
  return (dispatch) => {
    dispatch(requestStopMachine(machineId));

    return makePostCall(machineId, 'stop', data).then(
      () => {
        dispatch(resolveStopMachine(machine));
        dispatch(workstationStopSnackbar(machine.machineName));
        if (isPoolMachine) {
          dispatch(
            updatePoolWorkstation({
              ...machine,
              powerState: WORKSTATION_POWERSTATE_STOPPING,
            })
          );
          dispatch(pollForPoolMachineStopped(machine));
        } else {
          dispatch(
            updateWorkstation({
              ...machine,
              powerState: WORKSTATION_POWERSTATE_STOPPING,
            })
          );
          dispatch(pollForWorkstationStopped(machine));
        }
      },
      (error) => {
        dispatch(errorStopMachine(machineId));
        dispatch(handleApiError(error));
      }
    );
  };
}

export const REQUEST_RESTART_MACHINE = 'REQUEST_RESTART_MACHINE';

export function requestRestartMachine(machineId) {
  return {
    type: REQUEST_RESTART_MACHINE,
    machineId,
  };
}

export const RESOLVE_RESTART_MACHINE = 'RESOLVE_RESTART_MACHINE';

export function resolveRestartMachine(machineId) {
  return {
    type: RESOLVE_RESTART_MACHINE,
    machineId,
  };
}

export const ERROR_RESTART_MACHINE = 'ERROR_RESTART_MACHINE';

export function errorRestartMachine(machineId) {
  return {
    type: ERROR_RESTART_MACHINE,
    machineId,
  };
}

function workstationRestartSnackbar(machineName) {
  return enqueueSnackbar({
    message: getSingleWorkstationSnackbarMessage('restarting', machineName),
    options: {
      variant: 'success',
    },
  });
}

export function restartMachine(machine, isPoolMachine = false, restartState) {
  const {machineId} = machine;
  const data = restartState ? {restartState} : {};
  return (dispatch) => {
    dispatch(requestRestartMachine(machineId));

    return makePostCall(machineId, 'restart', data).then(
      () => {
        dispatch(resolveRestartMachine(machineId));
        dispatch(workstationRestartSnackbar(machine.machineName));
        if (isPoolMachine) {
          dispatch(
            updatePoolWorkstation({
              ...machine,
              powerState: WORKSTATION_POWERSTATE_RESTARTING,
            })
          );
          dispatch(pollForPoolMachineRunning(machine));
        } else {
          dispatch(
            updateWorkstation({
              ...machine,
              powerState: WORKSTATION_POWERSTATE_RESTARTING,
            })
          );
          dispatch(pollForWorkstationRunning(machine));
        }
      },
      (error) => {
        dispatch(errorRestartMachine(machineId));
        dispatch(handleApiError(error));
      }
    );
  };
}

export function deleteWorkstation(workstation, deleteFromProvider) {
  return async (dispatch, getState) => {
    dispatch(requestDeleteResource(REMOTE_WORKSTATIONS, workstation.machineId));

    try {
      await del({
        path: `machines/${workstation.machineId}`,
        data: {deleteFromProvider},
      });
      dispatch(
        receiveDeleteResource(REMOTE_WORKSTATIONS, workstation.machineId)
      );

      dispatch(
        enqueueSnackbar({
          message: `Remote Workstation "${workstation.machineName}" is being deleted.`,
          options: {
            variant: 'success',
          },
        })
      );

      const state = getState();
      const pathname = selectPathname(state);
      const currentPage = mapPathnameToPageTitle(pathname);

      switch (currentPage) {
        case EDIT_REMOTE_WORKSTATION: {
          dispatch(replace(`${WORKSTATIONS_LINK}`));
          break;
        }
        case REMOTE_WORKSTATIONS_TABLE: {
          const {page, rowsPerPage, params} =
            state.tableData[REMOTE_WORKSTATIONS];
          dispatch(
            fetchTableData(REMOTE_WORKSTATIONS, page, rowsPerPage, params)
          );
          dispatch(uncheckItem(REMOTE_WORKSTATIONS, workstation));
          break;
        }
        default:
          break;
      }
    } catch (error) {
      dispatch(handleApiError(error));
      dispatch(
        receiveDeleteResource(REMOTE_WORKSTATIONS, workstation.machineId)
      );
    }
  };
}

export function deleteWorkstations(workstations, deleteFromProvider) {
  return async (dispatch, getState) => {
    dispatch(uncheckAll(REMOTE_WORKSTATIONS));
    dispatch(
      enqueueSnackbar({
        message: 'Remote Workstations are being deleted.',
        options: {
          variant: 'success',
        },
      })
    );

    const promises = workstations.map(async (workstation) => {
      dispatch(
        requestDeleteResource(REMOTE_WORKSTATIONS, workstation.machineId)
      );
      try {
        await del({
          path: `machines/${workstation.machineId}`,
          data: {
            deleteFromProvider:
              deleteFromProvider[workstation.provider.toLowerCase()],
          },
        });
        dispatch(
          receiveDeleteResource(REMOTE_WORKSTATIONS, workstation.machineId)
        );
      } catch (error) {
        dispatch(
          receiveDeleteResourceError(REMOTE_WORKSTATIONS, workstation.machineId)
        );
        dispatch(
          pushNotification({
            title: 'Delete failed',
            type: 'Remote Workstations',
            moreInfo: `An error occurred while deleting workstation ${workstation.machineName}. If attempting to delete from a public cloud, please verify that this deployment has valid credentials for that cloud.`,
            timePosted: Date.now(),
            actionText: 'Mark as read',
            link: `${WORKSTATIONS_LINK}`,
          })
        );
      }
    });

    await Promise.all(promises);

    const state = getState();
    const pathname = selectPathname(state);
    const currentPage = mapPathnameToPageTitle(pathname);

    if (currentPage === REMOTE_WORKSTATIONS_TABLE) {
      const {page, rowsPerPage, params} = state.tableData[REMOTE_WORKSTATIONS];
      dispatch(fetchTableData(REMOTE_WORKSTATIONS, page, rowsPerPage, params));
    }
  };
}
