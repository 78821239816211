import PropTypes from 'prop-types';

const defaultColor = '#1B1C20';

function ExternalIpIcon({iconColor}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={iconColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.80094 10.0184C3.64789 10.6539 3.56682 11.3175 3.56682 12C3.56682 12.6825 3.64789 13.3461 3.80094 13.9816H7.70178C7.60165 13.3342 7.5439 12.6778 7.53012 12.0163C7.5299 12.0054 7.5299 11.9946 7.53012 11.9837C7.5439 11.3222 7.60165 10.6658 7.70178 10.0184H3.80094ZM4.34745 8.45161H8.03128C8.47944 6.77436 9.2188 5.18393 10.2194 3.75514C7.60807 4.31641 5.44582 6.08682 4.34745 8.45161ZM12 3.98916C10.938 5.32049 10.1466 6.83661 9.65994 8.45161H14.3401C13.8534 6.83661 13.062 5.32049 12 3.98916ZM14.7105 10.0184H9.28948C9.17654 10.6698 9.11169 11.332 9.09695 12C9.11169 12.668 9.17654 13.3302 9.28948 13.9816H14.7105C14.8235 13.3302 14.8883 12.668 14.903 12C14.8883 11.332 14.8235 10.6698 14.7105 10.0184ZM16.2982 13.9816C16.3983 13.3342 16.4561 12.6778 16.4699 12.0163C16.4701 12.0054 16.4701 11.9946 16.4699 11.9837C16.4561 11.3222 16.3983 10.6658 16.2982 10.0184H20.1991C20.3521 10.6539 20.4332 11.3175 20.4332 12C20.4332 12.6825 20.3521 13.3461 20.1991 13.9816H16.2982ZM14.3401 15.5484H9.65994C10.1466 17.1634 10.938 18.6795 12 20.0108C13.062 18.6795 13.8534 17.1634 14.3401 15.5484ZM10.2194 20.2449C9.2188 18.8161 8.47944 17.2256 8.03128 15.5484H4.34745C5.44582 17.9132 7.60807 19.6836 10.2194 20.2449ZM13.7806 20.2449C14.7812 18.8161 15.5206 17.2256 15.9687 15.5484H19.6526C18.5542 17.9132 16.3919 19.6836 13.7806 20.2449ZM19.6526 8.45161H15.9687C15.5206 6.77436 14.7812 5.18393 13.7806 3.75514C16.3919 4.31641 18.5542 6.08682 19.6526 8.45161ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
      />
    </svg>
  );
}

ExternalIpIcon.propTypes = {
  iconColor: PropTypes.string,
};

ExternalIpIcon.defaultProps = {
  iconColor: defaultColor,
};

export default ExternalIpIcon;
