import {post} from 'api/Api';
import { styled } from '@mui/material/styles';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import {capitalize} from 'helpers/core';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {handleApiError, saveVariable} from 'redux/actions/dataActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {uncheckAll} from 'redux/actions/tableSelectActions';
import {doSafeRequest} from 'utils/apiUtils';
import {
  ACCEPT_ENROLLMENTS,
  ENROLLMENT_LINK,
  MONITOR_ENROLLMENTS,
  ENROLLMENT_REJECT,
  REJECT_ENROLLMENTS,
  ENROLLMENT_ADD,
  ENROLLMENT_STATUS_WAITING_WORKER_ACCEPT,
  ENROLLMENT_UPDATE,
  ENROLLMENT_STATUS_MESSAGE_WORKSTATION_NOT_FOUND,
  UPDATE_ENROLLMENTS,
} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {selectDataIsFetching, selectVariable} from 'utils/reduxSelectors';
import {enrollmentToActionType} from 'utils/utils';

const PREFIX = 'MonitorEnrollmentsButtons';

const classes = {
  addOrLinkButton: `${PREFIX}-addOrLinkButton`,
  updateButton: `${PREFIX}-updateButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.addOrLinkButton}`]: {
    marginRight: '2px',
    width: 'auto',
  },

  [`& .${classes.updateButton}`]: {
    marginRight: '2px',
    width: 'auto',
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    '&:hover': {
      color: theme.palette.warning.main,
      backgroundColor: theme.palette.warning.light,
      borderColor: theme.palette.warning.dark,
    },
  }
}));

function MonitorEnrollmentsButtons({enrollment}) {

  const dispatch = useDispatch();

  const isFetching = useSelector((state) =>
    selectDataIsFetching(state, MONITOR_ENROLLMENTS)
  );
  const {page, rowsPerPage} =
    useSelector((state) => state.tableData[MONITOR_ENROLLMENTS]) || {};
  const {params: filter} = useSelector(
    (state) => state.tableData[MONITOR_ENROLLMENTS] || {params: {}}
  );
  const {searchTerm} = useSelector(
    (state) => state.tableData[MONITOR_ENROLLMENTS] || {searchTerm: ''}
  );
  const isDoingBulkEnrollmentAction = useSelector(
    (state) => selectVariable(state, 'isDoingBulkEnrollmentAction') || false
  );
  const doingSingleEnrollmentAction = useSelector(
    (state) => selectVariable(state, 'doingSingleEnrollmentAction') || false
  );
  const isAcceptingEnrollment =
    enrollment?.status === ENROLLMENT_STATUS_WAITING_WORKER_ACCEPT;

  const {deploymentId} = enrollment;
  const {enrollmentId} = enrollment;

  const refreshEnrollments = () => {
    if (isFetching) {
      return;
    }
    dispatch(
      fetchTableData(
        MONITOR_ENROLLMENTS,
        page,
        rowsPerPage,
        filter,
        searchTerm,
        false
      )
    );
  };

  const doRequest = async (resource) => {
    const path = `${mapResourceToPath(resource, {deploymentId, enrollmentId})}`;

    const resp = await doSafeRequest(post, {path});
    if (resp.status !== 'success') {
      dispatch(handleApiError(resp));
    }
  };

  const acceptEnrollment = async () => {
    dispatch(saveVariable('doingSingleEnrollmentAction', true));
    await doRequest(ACCEPT_ENROLLMENTS);
    refreshEnrollments();
    dispatch(uncheckAll(MONITOR_ENROLLMENTS));
    dispatch(saveVariable('doingSingleEnrollmentAction', false));
  };

  const rejectEnrollment = async () => {
    dispatch(saveVariable('doingSingleEnrollmentAction', true));
    await doRequest(REJECT_ENROLLMENTS);
    refreshEnrollments();
    dispatch(uncheckAll(MONITOR_ENROLLMENTS));
    dispatch(saveVariable('doingSingleEnrollmentAction', false));
  };

  const updateEnrollment = async () => {
    dispatch(saveVariable('doingSingleEnrollmentAction', true));
    await doRequest(UPDATE_ENROLLMENTS);
    refreshEnrollments();
    dispatch(uncheckAll(MONITOR_ENROLLMENTS));
    dispatch(saveVariable('doingSingleEnrollmentAction', false));
  };

  const handleButtonClick = (buttonText) => {
    switch (buttonText) {
      case ENROLLMENT_ADD:
      case ENROLLMENT_LINK:
        return acceptEnrollment();
      case ENROLLMENT_REJECT:
        return rejectEnrollment();
      case ENROLLMENT_UPDATE:
        return updateEnrollment();
    }
  };

  const chooseButtonClass = (buttonText) => {
    switch (buttonText) {
      case ENROLLMENT_ADD:
        return classes.addOrLinkButton;
      case ENROLLMENT_LINK:
        return classes.addOrLinkButton;
      case ENROLLMENT_UPDATE:
        return classes.updateButton;
    }
  };

  const renderButton = (buttonText) => (
    <CAMButton
      customClass={chooseButtonClass(buttonText)}
      testId={`${buttonText}-action-button`}
      buttonText={capitalize(buttonText)}
      size="small"
      disabled={
        isFetching ||
        doingSingleEnrollmentAction ||
        isDoingBulkEnrollmentAction ||
        isAcceptingEnrollment
      }
      onClick={async () => {
        handleButtonClick(buttonText);
      }}
      color={buttonText === ENROLLMENT_REJECT ? 'secondary' : 'primary'}
    />
  );

  return (
    (<Root>
      {renderButton(enrollmentToActionType(enrollment))}
      {renderButton(ENROLLMENT_REJECT)}
    </Root>)
  );
}

MonitorEnrollmentsButtons.propTypes = {
  enrollment: PropTypes.object,
};

MonitorEnrollmentsButtons.defaultProps = {
  enrollment: {},
};

export default MonitorEnrollmentsButtons;
