import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import useSnackbar from 'hooks/useSnackbar';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {addRemoteWorkstationsFromMultipleProviders} from 'redux/actions/dataActions';
import {showLeaveEditPageDialog} from 'redux/actions/displayLeaveEditPageDialogActions';
import {push} from 'redux/ReduxHistory';
import {
  AWS,
  AZURE,
  GCP,
  RSM,
  USER_SELECTION_BY_ENTITLEMENTS,
  USER_SELECTION_BY_POOL,
  USER_SELECTION_DISABLED,
  WORKSTATIONS_LINK,
} from 'utils/constants';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {
  hostNameErrorMessage,
  isCloudProvider,
  isEmpty,
  validateHostname,
} from 'utils/utils';
import PageChooseRemoteWorkstations from './PageChooseRemoteWorkstations';
import PageHowToConnect from './PageHowToConnect';
import PageUserManagement from './PageUserManagement';

const PREFIX = 'AddRemoteWorkstation';

const classes = {
  pageContainer: `${PREFIX}-pageContainer`,
  pageTitleContainer: `${PREFIX}-pageTitleContainer`,
  pageTitle: `${PREFIX}-pageTitle`,
  createPageContainer: `${PREFIX}-createPageContainer`,
  bodyText: `${PREFIX}-bodyText`,
  topMenu: `${PREFIX}-topMenu`,
  breadcrumbLink: `${PREFIX}-breadcrumbLink`,
  disabledBreadcrumbLink: `${PREFIX}-disabledBreadcrumbLink`,
  selectedBreadcrumbLink: `${PREFIX}-selectedBreadcrumbLink`,
  link: `${PREFIX}-link`,
  rootContainer: `${PREFIX}-rootContainer`,
  internalPageContainer: `${PREFIX}-internalPageContainer`,
  itemButtonRow: `${PREFIX}-itemButtonRow`,
  itemButton: `${PREFIX}-itemButton`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.pageContainer}`]: {
    ...theme.createPage.createPageContainer,
    minHeight: 'unset',
  },

  [`& .${classes.pageTitleContainer}`]: {
    ...theme.createPage.createPageTitleContainer,
  },

  [`& .${classes.pageTitle}`]: theme.createPage.createPageTitle,
  [`& .${classes.createPageContainer}`]: theme.createPage.createPageContainer,

  [`& .${classes.bodyText}`]: {
    color: '#D8D8D8',
    fontWeight: 500,
  },

  [`& .${classes.topMenu}`]: {
    marginBottom: '20px',
  },

  [`& .${classes.breadcrumbLink}`]: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },

  [`& .${classes.disabledBreadcrumbLink}`]: {
    pointerEvents: 'none',
    color: 'grey',
    textDecoration: 'none',
  },

  [`& .${classes.selectedBreadcrumbLink}`]: {
    pointerEvents: 'none',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },

  [`& .${classes.link}`]: {
    textDecoration: 'underline',
    color: 'white',
  },

  [`& .${classes.rootContainer}`]: {
    paddingTop: '0.5rem',
  },

  [`& .${classes.internalPageContainer}`]: {
    paddingBottom: '80px',
  },

  [`& .${classes.itemButtonRow}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    bottom: '0',
    borderTop: '1px solid #888',
    padding: '20px',
    width: '100%',
  },

  [`& .${classes.itemButton}`]: {
    margin: '0 5px',
  },
}));

function AddRemoteWorkstation() {
  const dispatch = useDispatch();
  const {successSnackbar} = useSnackbar();

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const [isSaving, setIsSaving] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedInstances, setSelectedInstances] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedPool, setSelectedPool] = useState(null);
  const [topMenuItems, setTopMenuItems] = useState([]);
  const [userManagementMethod, setUserManagementMethod] = useState(
    USER_SELECTION_DISABLED
  );

  const pages = [
    'Select remote workstations',
    'Define how to connect',
    'User management',
    'Review',
  ];

  useEffect(() => {
    setTopMenuItems(
      pages.map((title, index) => ({title, index, enabled: !index}))
    );
    dispatch(showLeaveEditPageDialog());
  }, []);

  const pageChooseRemoteWorkstations = () => (
    <PageChooseRemoteWorkstations
      deploymentId={deploymentId}
      selectedProvider={selectedProvider}
      setSelectedProvider={setSelectedProvider}
      selectedInstances={selectedInstances}
      setSelectedInstances={setSelectedInstances}
      visible
    />
  );

  const pageHowToConnect = () => (
    <PageHowToConnect
      selectedInstances={selectedInstances}
      setSelectedInstances={setSelectedInstances}
      visible
    />
  );

  const pageUserManagement = () => (
    <PageUserManagement
      deploymentId={deploymentId}
      selectedPool={selectedPool}
      setSelectedPool={setSelectedPool}
      selectedUsers={selectedUsers}
      setSelectedUsers={setSelectedUsers}
      setUserManagementMethod={setUserManagementMethod}
      userManagementMethod={userManagementMethod}
      visible
    />
  );

  const userManagementStateIsValid = () => {
    if (userManagementMethod === 'pool' && isEmpty(selectedPool)) {
      return false;
    }
    if (userManagementMethod === 'entitlement' && isEmpty(selectedUsers)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isEmpty(topMenuItems)) {
      return;
    }

    // Validate connection info for the first time for each machine
    if (
      selectedInstances.some(
        (instance) => instance.data.validConnectionInfo === undefined
      )
    ) {
      setSelectedInstances(
        selectedInstances.map((instance) => {
          const newInstance = instance;
          newInstance.data.validConnectionInfo = validateHostname(
            instance.data.connectionInfo
          );
          newInstance.data.connectionInfoErrorMessage = hostNameErrorMessage(
            instance.data.connectionInfo
          );
          return newInstance;
        })
      );
    }

    setTopMenuItems(
      topMenuItems &&
        topMenuItems.map((item) => {
          // Select instances page
          if (!item.index) {
            return item;
          }
          const newItem = {
            ...item,
            enabled: !isEmpty(selectedInstances),
          };
          // Review page only if user management state is valid
          if (item.index === topMenuItems.length - 1) {
            newItem.enabled =
              !isEmpty(selectedInstances) && userManagementStateIsValid();
          }
          return newItem;
        })
    );
  }, [selectedInstances, userManagementMethod, selectedPool, selectedUsers]);

  const saveDisabled = () => {
    if (isEmpty(selectedInstances)) {
      return true;
    }
    if (
      selectedInstances.some(
        (instance) => instance && instance.data.validConnectionInfo === false
      )
    ) {
      return true;
    }

    if (!userManagementStateIsValid()) {
      return true;
    }

    return false;
  };

  const handleCancel = () => {
    if (isEmpty(selectedInstances)) {
      dispatch(push(WORKSTATIONS_LINK));
      return;
    }
    dispatch(
      openDialog(
        'Cancel?',
        `Are you sure you want to cancel?
      The selected remote workstations will not be added and any configuration done on this page will be lost.`,
        () => dispatch(push(WORKSTATIONS_LINK))
      )
    );
  };

  const handleSave = () => {
    setIsSaving(true);

    const machines = selectedInstances.map((instance) => {
      const {
        connectionInfo: hostName,
        displayName: machineName,
        instanceId,
        projectId,
        provider,
        region,
        resourceGroup,
        subscriptionId,
        zone,
        // fields in rsm machine
        deviceId,
      } = instance.data;

      let machine = {
        deploymentId,
        hostName,
        machineName,
        managed: isCloudProvider(provider),
        provider,
      };

      if (userManagementMethod === USER_SELECTION_BY_POOL && selectedPool) {
        machine.poolId = selectedPool.poolId;
      }

      switch (provider) {
        case AWS:
          machine = {...machine, instanceId, region};
          break;
        case AZURE:
          machine = {...machine, resourceGroup, subscriptionId};
          break;
        case GCP:
          machine = {...machine, projectId, zone};
          break;
        case RSM:
          machine = {...machine, deviceId};
          break;
        default:
          break;
      }
      return machine;
    });

    let users = [];
    if (
      userManagementMethod === USER_SELECTION_BY_ENTITLEMENTS &&
      selectedUsers
    ) {
      users = selectedUsers.map((user) => user.data);
    }

    dispatch(addRemoteWorkstationsFromMultipleProviders({machines, users}));

    dispatch(push(WORKSTATIONS_LINK));

    successSnackbar(
      'Your remote workstations are being added. You will be notified when it finishes.'
    );

    setIsSaving(false);
  };

  const renderBottomButtons = () => (
    <div className={classes.itemButtonRow}>
      <Button
        color="primary"
        className={classes.itemButton}
        onClick={() => handleCancel()}
      >
        Cancel
      </Button>

      <SaveButton
        onClick={handleSave}
        saving={isSaving}
        disabled={saveDisabled()}
      />
    </div>
  );

  return (
    <Root className={classes.pageContainer}>
      {/* TODO: this should be extracted as a component and replaced
        on every page, also including it on the storybook (another attempt) */}
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.pageTitle}>
          Adding existing remote workstations
        </Typography>
      </div>

      {deploymentId && (
        <div className={classes.internalPageContainer}>
          {pageChooseRemoteWorkstations()}
          <br />
          {pageHowToConnect()}
          <br />
          {pageUserManagement()}
          <br />
        </div>
      )}

      {renderBottomButtons()}
    </Root>
  );
}

export default AddRemoteWorkstation;
