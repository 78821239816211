import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {mapResourceToPath} from 'utils/Mappings';

import CAMTable from 'components/table/Table';
import {loadBulkActionsData} from 'redux/actions/bulkActionsDialogActions';
import {fetchData} from 'redux/actions/dataActions';
import {AD_USERS, DEPLOYMENTS, REMOTE_WORKSTATIONS} from 'utils/constants';
import {
  selectBulkActionsDialogTableParams,
  selectDataForTable,
} from 'utils/reduxSelectors';
import {isEmpty} from 'utils/utils';

function AdUsers() {
  const dispatch = useDispatch();

  const {
    data: adUsers,
    isFetching,
    total,
  } = useSelector((state) => selectDataForTable(state, AD_USERS));

  const {data: deployments} = useSelector(
    (state) => state.data.dataByResource[DEPLOYMENTS] || {data: {}}
  );

  const machinesDialogParams = useSelector((state) =>
    selectBulkActionsDialogTableParams(state, REMOTE_WORKSTATIONS)
  );
  const {resource: bulkDialogResource} = useSelector(
    (state) => state.bulkActionsDialog
  );

  const getDeploymentForUser = (user) => {
    const deployment = deployments[user.deploymentId];
    if (deployment) {
      return deployment.deploymentName || deployment.deploymentId;
    }
    return user.deploymentId;
  };

  const getDetailedViewLink = (user) =>
    `/app/adUsers/view/${user.userGuid}/${user.deploymentId}`;

  const prepareData = (users) =>
    users.map((user) => ({
      ...user,
      deployment: getDeploymentForUser(user),
      enabled: user.enabled ? 'Enabled' : 'Disabled',
      link: getDetailedViewLink(user),
    }));

  const updateDialogMachines = async (params) => {
    const path = mapResourceToPath(REMOTE_WORKSTATIONS);
    const machineParams = {
      ...params,
      machineName: params.searchKey,
      machineNameFilter: 'includes',
    };

    // fetch remote workstations that are not assigned to a pool, wait for the async action's promise
    // to resolve, and inform the BulkActionsDialog which remote workstations to display in table
    const {data: fetchedMachines, total: totalMachines} = await dispatch(
      fetchData(REMOTE_WORKSTATIONS, {path, params: machineParams})
    );
    const machineIds = fetchedMachines.map((machine) => machine.machineId);

    dispatch(
      loadBulkActionsData({
        data: machineIds,
        resource: REMOTE_WORKSTATIONS,
        total: totalMachines,
      })
    );
  };

  useEffect(() => {
    if (
      !isEmpty(machinesDialogParams) &&
      bulkDialogResource === REMOTE_WORKSTATIONS
    ) {
      updateDialogMachines(machinesDialogParams);
    }
  }, [JSON.stringify(machinesDialogParams), bulkDialogResource]);

  return (
    <CAMTable
      id="adusers-table"
      resource={AD_USERS}
      data={prepareData(adUsers)}
      total={total}
      loadingData={isFetching}
      hideAddButton
      hideConfigureTable={false}
    />
  );
}

export default AdUsers;
