import {useDispatch} from 'react-redux';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import {useEffect, useState} from 'react';
import {push} from 'redux/ReduxHistory';
import {
  MULTI_ADMIN_LINK,
  MULTI_ADMIN_SETTINGS_TITLE,
  SAML_CONFIGURATION,
} from 'utils/constants';
import {getDomain} from 'utils/utils';
import useSnackbar from 'hooks/useSnackbar';
import {mapResourceToAPI} from 'redux/actions/dataActions';
import {post, put} from 'api/Api';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import ConfigureStepCard from './ConfigureStepCard';
import SamlIdpSettingsInput from './SamlIdpSettingsInput';
import SamlConfigInfo from './samlConfigInfo';

const PREFIX = 'AddSamlConfigPage';

const classes = {
  nameInput: `${PREFIX}-nameInput`,
  cardSubtitle: `${PREFIX}-cardSubtitle`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  spacing: `${PREFIX}-spacing`,
  link: `${PREFIX}-link`,
  currentBreadcrumb: `${PREFIX}-currentBreadcrumb`,
  pageTitle: `${PREFIX}-pageTitle`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.nameInput}`]: {
    maxWidth: '400px',
    width: '60%',
    minWidth: '300px',
  },

  [`& .${classes.cardSubtitle}`]: {
    marginTop: '24px',
    marginBottom: '16px',
  },

  [`& .${classes.sectionTitle}`]: {
    marginTop: '24px',
  },

  [`&.${classes.spacing}`]: {
    margin: theme.spacing(4),
  },

  [`& .${classes.link}`]: {
    color: 'inherit',
  },

  [`& .${classes.currentBreadcrumb}`]: {
    color: 'rgba(0, 0, 0, 0.87)',
  },

  [`& .${classes.pageTitle}`]: {
    ...stylesFromFigma.typographyH4,
    marginTop: '8px',
  },
}));

const totalSteps = 3;
const nameMaxLength = 64;

function AddSamlConfigPage() {
  const dispatch = useDispatch();

  const {successSnackbar, errorSnackbar} = useSnackbar();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [configuration, setConfiguration] = useState(null);

  // step 1 [ Enter name ]
  const [configName, setConfigName] = useState('');
  const [configNameError, setConfigNameError] = useState('');
  const [savingConfig, setSavingConfig] = useState(false);

  useEffect(() => {
    if (configName?.length > nameMaxLength) {
      setConfigNameError(`Maximum characters is ${nameMaxLength}`);
      return;
    }
    setConfigNameError('');
  }, [configName]);

  // step2 [Configure IDP settings]
  const [idpCertificate, setIdpCertificate] = useState('');
  const [signInUrl, setSignInUrl] = useState('');
  const [validIdpSetting, setValidIdpSetting] = useState(false);
  const [updatingIdpSetting, setUpdatingIdpSetting] = useState(false);

  const completeWizard = () => {
    dispatch(push(`${MULTI_ADMIN_LINK}/${configuration.configurationId}`));
  };

  const onCreateConfig = async () => {
    if (savingConfig) {
      return;
    }
    setSavingConfig(true);
    const data = {domain: getDomain(), name: configName};
    const path = mapResourceToAPI(SAML_CONFIGURATION);
    try {
      const resp = await post({path, data});
      const newConfig = resp.data;
      successSnackbar(`SAML configuration "${configName}" has been created.`);
      setCurrentStepIndex(1);
      setConfiguration(newConfig);
    } catch (err) {
      errorSnackbar('Failed to create SAML configuration');
      setSavingConfig(false);
    }
  };

  const onUpdatingIdpSetting = async () => {
    if (updatingIdpSetting) {
      return;
    }
    setUpdatingIdpSetting(true);
    const data = {idpCertificate, signInUrl};
    const path = `${mapResourceToAPI(SAML_CONFIGURATION)}/${
      configuration.configurationId
    }`;
    try {
      await put({path, data});
      successSnackbar('Updated IDP information');
      completeWizard();
    } catch (err) {
      errorSnackbar('Failed to update IDP information');
      setUpdatingIdpSetting(false);
    }
  };

  const onCancel = async () => {
    dispatch(push(MULTI_ADMIN_LINK));
  };

  return (
    <Root className={classes.spacing}>
      <Breadcrumbs separator=">">
        <Link
          underline="hover"
          href={MULTI_ADMIN_LINK}
          onClick={(event) => {
            event.preventDefault();
            dispatch(push(MULTI_ADMIN_LINK));
          }}
          className={classes.link}
        >
          {MULTI_ADMIN_SETTINGS_TITLE}
        </Link>
        <Typography className={classes.currentBreadcrumb}>
          {' '}
          Create SAML Configuration
        </Typography>
      </Breadcrumbs>

      <Typography className={classes.pageTitle} variant="h4" component="h1">
        Create SAML Configuration
      </Typography>

      <ConfigureStepCard
        stepIndex={0}
        totalSteps={totalSteps}
        open={currentStepIndex === 0}
        continueBtnName="Create"
        onContinueClick={onCreateConfig}
        continueDisabled={configNameError || !configName}
        title="Choose Configuration Name"
        complete={currentStepIndex > 0}
        onCancelClick={onCancel}
      >
        <TextField
          value={configName}
          data-testid="config-name-input"
          onChange={(event) => setConfigName(event.target.value)}
          label="Configuration name"
          type="text"
          error={!!configNameError}
          variant="outlined"
          autoComplete="off"
          helperText={configNameError}
          className={classes.nameInput}
        />
      </ConfigureStepCard>

      <ConfigureStepCard
        stepIndex={1}
        totalSteps={totalSteps}
        open={currentStepIndex === 1}
        onContinueClick={() => {
          setCurrentStepIndex(2);
        }}
        title="Configuration Info"
        hideCancel
        complete={currentStepIndex > 1}
      >
        <SamlConfigInfo
          userLoginUrl={configuration?.userLoginUrl || ''}
          callbackUrl={configuration?.callbackUrl || ''}
          entityId={configuration?.entityId || ''}
          configurationId={configuration?.configurationId}
        />
      </ConfigureStepCard>

      <ConfigureStepCard
        stepIndex={2}
        totalSteps={totalSteps}
        open={currentStepIndex === 2}
        continueDisabled={!validIdpSetting}
        optional
        onContinueClick={onUpdatingIdpSetting}
        title="Identity Provider Information"
        hideCancel
        onSkipClick={completeWizard}
      >
        <SamlIdpSettingsInput
          idpCertificate={idpCertificate}
          signInUrl={signInUrl}
          setIdpCertificate={setIdpCertificate}
          setSignInUrl={setSignInUrl}
          setIsValid={setValidIdpSetting}
        />
      </ConfigureStepCard>
    </Root>
  );
}

export default AddSamlConfigPage;
