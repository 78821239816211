import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {mapResourceToSubtitle} from 'utils/Mappings';
import TableAddButton from './TableAddButton';
import TableDownloadButton from './TableDownloadButton';
import EnrollmentsButton from './TableEnrollmentsButton';

const PREFIX = 'TableTitle';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  toolbar: `${PREFIX}-toolbar`,
  spacer: `${PREFIX}-spacer`,
  subtitle: `${PREFIX}-subtitle`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    // Increase the specificity to override TableCell.
    '&:last-child': {
      padding: 0,
    },
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.toolbar}`]: {
    height: 56,
    minHeight: 56,
    paddingRight: 2,
  },

  [`& .${classes.spacer}`]: {
    flex: '1 1 0%',
  },

  [`& .${classes.subtitle}`]: {
    color: theme.palette.surface.grey,
    fontFamily: 'Roboto',
    fontSize: '0.85rem',
    letterSpacing: '0.4px',
    lineHeight: '16px',
    marginBottom: '12px',
    marginTop: '4px',
  }
}));

function TableTitle({
  tableTitle,
  hideAddButton,
  showDownloadButton,
  loadingData,
  resource,
  onAdd,
  showEnrollmentsButton,
  subtitle,
}) {

  return (
    (<Root>
      <Toolbar disableGutters>
        <Typography variant="h5" id="tableTitle">
          {tableTitle}
        </Typography>

        {!hideAddButton && (
          <TableAddButton resource={resource} onClick={onAdd} />
        )}

        <div className={classes.spacer} />

        {showDownloadButton && <TableDownloadButton resource={resource} />}

        {loadingData && <CircularProgress mode="indeterminate" />}
        {showEnrollmentsButton && <EnrollmentsButton />}
      </Toolbar>
      {subtitle && (
        <Typography
          variant="subtitle1"
          id="tableSubtitle"
          className={classes.subtitle}
        >
          {subtitle}
        </Typography>
      )}
    </Root>)
  );
}

TableTitle.propTypes = {
  tableTitle: PropTypes.string,
  hideAddButton: PropTypes.bool,
  loadingData: PropTypes.bool,
  showDownloadButton: PropTypes.bool,
  resource: PropTypes.string,
  onAdd: PropTypes.func,
  showEnrollmentsButton: PropTypes.bool,
  subtitle: PropTypes.string,
};

TableTitle.defaultProps = {
  tableTitle: 'Title',
  hideAddButton: false,
  loadingData: false,
  showDownloadButton: false,
  resource: '',
  onAdd: () => {},
  showEnrollmentsButton: false,
  subtitle: '',
};

export default TableTitle;
