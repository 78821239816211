import {del} from 'api/Api';
import {doSafeBulkRequest} from 'utils/apiUtils';
import {CONNECTORS} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {enqueueSnackbar} from './snackbarActions';

export const OPEN_DELETE_CONNECTORS_DIALOG = 'OPEN_DELETE_CONNECTORS_DIALOG';

export function openDeleteConnectorsDialog({connectors}) {
  return {
    type: OPEN_DELETE_CONNECTORS_DIALOG,
    connectors,
  };
}

export const CLOSE_DELETE_CONNECTORS_DIALOG = 'CLOSE_DELETE_CONNECTORS_DIALOG';

export function closeDeleteConnectorsDialog() {
  return {
    type: CLOSE_DELETE_CONNECTORS_DIALOG,
  };
}
export function deleteConnectors() {
  return async (dispatch, getState) => {
    const state = getState();
    const {deploymentId} = selectSelectedDeployment(state);
    const {connectors} = state.deleteConnectorsDialog;

    const buildDeleteConnectorRequest = (connector) => {
      const basePath = mapResourceToPath(CONNECTORS, {deploymentId});
      const path = `${basePath}${connector.connectorId}`;
      return {path};
    };

    const responses = await doSafeBulkRequest(
      del,
      connectors,
      buildDeleteConnectorRequest
    );
    const failedResponses = responses.filter(
      (response) => response.status !== 'success'
    );

    if (!failedResponses.length) {
      dispatch(
        enqueueSnackbar({
          message:
            responses.length > 1
              ? 'Selected connectors are being deleted.'
              : 'Selected connector is being deleted.',
          options: {
            variant: 'success',
          },
        })
      );
    } else {
      dispatch(
        enqueueSnackbar({
          message: 'An error occurred while deleting the selected connectors.',
          options: {
            variant: 'error',
          },
        })
      );
    }

    dispatch(closeDeleteConnectorsDialog());
  };
}
