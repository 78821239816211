import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';

export const StyledTable = styled(Table)(() => ({
  // place holder for now, this is added so that if some style moved from StyledTableCell to StyledTable, the table will still look as expected.
}));

export const StyledTableHead = styled(TableHead)(() => ({
  // place holder for now, this is added so that if some style moved from StyledTableCell to StyledTable, the table will still look as expected.
}));

export const StyledTableBody = styled(TableBody)(() => ({
  // place holder for now, this is added so that if some style moved from StyledTableCell to StyledTable, the table will still look as expected.
}));

export const StyledTableRow = styled(TableRow)(() => ({
  [`&.MuiTableRow-head`]: {
    background: '#EDEDF2',
  },
}));

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.MuiTableCell-root`]: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '14px',
    letterSpacing: '0.17px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '& > *': {
      // apply font styling on children
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontSize: '14px',
      letterSpacing: '0.17px',
    },
  },
  [`&.MuiTableCell-head`]: {
    backgroundColor: '#EDEDF2',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#000000DE',
    padding: '6px 16px',
  },
  [`&.MuiTableCell-body`]: {
    fontWeight: '400',
  },
}));
