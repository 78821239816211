import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const PREFIX = 'MiniNormalTable';

const classes = {
  tableRowEven: `${PREFIX}-tableRowEven`,
  tableRowOdd: `${PREFIX}-tableRowOdd`,
  rowCell: `${PREFIX}-rowCell`,
  link: `${PREFIX}-link`
};

const StyledTableBody = styled(TableBody)(() => ({
  [`& .${classes.tableRowEven}`]: {
    backgroundColor: '#FFFFFF',
  },

  [`& .${classes.tableRowOdd}`]: {
    backgroundColor: '#F4F4F4',
  },

  [`& .${classes.rowCell}`]: {
    fontSize: '12px',
    lineHeight: '15px',
    paddingTop: '6px',
    paddingBottom: '6px',
  },

  [`& .${classes.link}`]: {
    color: '#0076a9',
    cursor: 'pointer',
  }
}));

function MiniNormalTable({headers, data, handleCellClick, displayField}) {


  const renderTableRow = (row, index) => (
    <TableRow
      key={`${row.id}-${index}`}
      hover
      className={index % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd}
    >
      {headers.map((header) => (
        <TableCell
          key={`${row.id}-${header.id}`}
          onClick={() => handleCellClick(row, header)}
          className={classNames(classes.rowCell, {
            [classes.link]: header.type === 'link',
          })}
        >
          {displayField(row, header)}
        </TableCell>
      ))}
    </TableRow>
  );

  return <StyledTableBody>{data.map(renderTableRow)}</StyledTableBody>;
}

MiniNormalTable.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
  handleCellClick: PropTypes.func,
  displayField: PropTypes.func,
};

MiniNormalTable.defaultProps = {
  headers: [],
  data: [],
  handleCellClick: () => {},
  displayField: () => {},
};

export default MiniNormalTable;
