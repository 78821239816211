export const OPEN_WORKSTATION_DIALOG_ERROR = 'OPEN_WORKSTATION_DIALOG_ERROR';

export function openWorkstationDialogError(workstation) {
  return {
    type: OPEN_WORKSTATION_DIALOG_ERROR,
    workstation,
  };
}

export const CLOSE_WORKSTATION_DIALOG_ERROR = 'CLOSE_WORKSTATION_DIALOG_ERROR';

export function closeWorkstationDialogError() {
  return {
    type: CLOSE_WORKSTATION_DIALOG_ERROR,
  };
}
