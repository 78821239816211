import {useDispatch, useSelector} from 'react-redux';
import CAMDeleteDialog from 'components/CAM/dialogs/CAMDeleteDialog/CAMDeleteDialog';
import {
  closeDeleteWebhooksDialog,
  deleteWebhooks,
} from 'redux/actions/DeleteWebhooksDialogActions';
import {uncheckAll} from 'redux/actions/tableSelectActions';
import {WEBHOOKS} from 'utils/constants';

function DeleteWebhooksDialog() {
  const dispatch = useDispatch();

  const {open, webhooks} = useSelector(
    (state) =>
      (state && state.deleteWebhooksDialog) || {open: false, webhooks: []}
  );

  const webhooksDisplayText =
    webhooks && webhooks?.length > 1 ? 'webhooks' : 'webhook';

  const handleClose = () => {
    dispatch(closeDeleteWebhooksDialog());
  };

  const handleConfirm = () => {
    dispatch(deleteWebhooks());
    dispatch(uncheckAll(WEBHOOKS));
  };

  const getWebhookDisplayText = (webhookId) => {
    const webhookToFind =
      webhooks.find((webhook) => webhook.id === webhookId) || {};
    return webhookToFind.name || webhookId;
  };

  const resourceNameForDeleteDialog =
    webhooks?.length === 1
      ? getWebhookDisplayText(webhooks[0].id)
      : `${webhooks?.length} ${webhooksDisplayText}`;

  return (
    <CAMDeleteDialog
      resourceName={resourceNameForDeleteDialog}
      open={open}
      onOk={handleConfirm}
      onCancel={handleClose}
      requireTextInputToDelete
    />
  );
}

export default DeleteWebhooksDialog;
