import {Grid, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import CAMTooltip from 'components/CAM/display/CAMTooltip/CAMTooltip';

const PREFIX = 'SectionHeader';

const classes = {
  inputSectionSubtitle: `${PREFIX}-inputSectionSubtitle`,
  rootContainer: `${PREFIX}-rootContainer`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.inputSectionSubtitle}`]: {
    ...theme.createPage.inputSectionSubtitle,
    textTransform: 'none',
  },

  [`&.${classes.rootContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    padding: 0,
    margin: 0,
    paddingBottom: '0.5em',
  }
}));

function SectionHeader({displayText, tooltipText, children}) {


  return (
    <StyledGrid
      container
      alignItems="center"
      // Disabled until other pages are refactored to use the cards and grids
      className={classes.rootContainer}
    >
      <Typography component="div" className={classes.inputSectionSubtitle}>
        {displayText}
      </Typography>
      {tooltipText && <CAMTooltip text={tooltipText} />}
      {children}
    </StyledGrid>
  );
}

SectionHeader.propTypes = {
  displayText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  children: PropTypes.element,
};

SectionHeader.defaultProps = {
  tooltipText: '',
  children: null,
};

export default SectionHeader;
