import {get, post} from 'api/Api';
import config from 'config';
import {CONNECTOR_SETTINGS, CONNECTOR_REPO_NAME} from './constants';
import {mapResourceToPath} from './Mappings';
import {decodeBase64ToJson, isEmpty, sanitizeValues} from './utils';

export async function doSafeRequest(operation, request) {
  let response;
  try {
    response = await operation(request);
  } catch (err) {
    response = err;
  }
  return response;
}

export async function doSafeBulkRequest(operation, data, buildRequest) {
  const requests = data.map(async (item) =>
    doSafeRequest(operation, buildRequest(item))
  );
  const responses = await Promise.all(requests);

  return responses;
}

export async function getConnectorSettings(deploymentId, connectorId = '') {
  // legacy route (base64 encoded results): /api/v1/deployments/{deployment_id}/settings/connectorSettings/{connector_id}
  // current route: /api/v1/deployments/{deployment_id}/settings/connectors/{connector_id}
  // {connector_id} is optional and if not provided will return the deployment connector settings.

  // Fetches the legacy

  // Fetches the current

  // Wait for both to settle and then:
  // - returns the legacy if current is empty and legacy has any information
  // - returns current otherwise

  let path = mapResourceToPath(CONNECTOR_SETTINGS, {deploymentId});
  if (!isEmpty(connectorId)) {
    path = `${path}/${connectorId}`;
  }
  const legacyPath = path.replace('/connectors', '/connectorSettings');

  const respPromise = get({path});
  const legacyRespPromise = get({path: legacyPath});

  const [resp, legacyResp] = await Promise.allSettled([
    respPromise,
    legacyRespPromise,
  ]);

  let finalResponse;
  if (
    resp?.status === 'fulfilled' &&
    !(
      // at least one is not empty (deployment or connector)
      (
        isEmpty(resp?.value?.data?.deployment) &&
        isEmpty(resp?.value?.data?.connector)
      )
    )
  ) {
    finalResponse = resp?.value?.data || {};
    // Save the path in the response to make it possible to detect
    //  to which endpoint do updates: legacy x new
    finalResponse.path = path;
  }

  // Some legacy settings like ad groups are still fetched from legacy route
  // need to merge the settings from both endpoints in to one
  if (legacyResp?.status === 'fulfilled') {
    try {
      const decodedData = decodeBase64ToJson(legacyResp?.value?.data) || {};
      if (!isEmpty(decodedData)) {
        finalResponse = {
          ...finalResponse,
          deployment: {
            ...decodedData,
            ...finalResponse?.deployment,
          },
          // Save the path in the response to make it possible to detect
          //  to which endpoint do updates: legacy x new
          path: legacyPath,
        };
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(
        `Failed to b64 decode connector settings received from ${path}`
      );
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  return {
    ...finalResponse,
    deploymentId,
  };
}
export async function getDownloadToken(
  deploymentId,
  legacyApi = true,
  repo = CONNECTOR_REPO_NAME
) {
  const path =
    legacyApi || !config.isDownloadTokenEnabled()
      ? `deployments/${deploymentId}/settings/cloudsmithtoken`
      : `deployments/${deploymentId}/downloadTokens/${repo}`;

  const respPromise = await get({path});

  switch (typeof respPromise?.data) {
    case 'object':
      return respPromise.data?.downloadToken;
    case 'string':
      return respPromise.data;
    default:
      return '';
  }
}

/**
 * Retrieves a download token with fallback option.
 *
 * @param {string} deploymentId - The ID of the deployment.
 * @param {string} repo - The repository name. It is optional and defaults to `config.getDownloadRepositoryName()`
 * @returns {Promise<string>} The download token.
 * @description This function retrieves a download token for a deployment.
 * It has a fallback option where it first tries to retrieve the token using
 * the new API endpoint, and if that fails, it falls back to the legacy API endpoint.
 */
export async function getDownloadTokenWithFallback(deploymentId, repo) {
  let token;
  try {
    token = await getDownloadToken(
      deploymentId,
      false,
      repo || config.getDownloadRepositoryName()
    );
  } catch (err) {
    token = await getDownloadToken(deploymentId, true);
  }

  return token;
}

/**
 * Verifies the uniqueness of a connector name and deployment ID.
 * @async
 * @param {string} connectorName - The name of the connector to verify.
 * @param {string} deploymentId - The deployment ID to verify against.
 * @returns {Promise<{Error, boolean}>} - A Promise that resolves to an objecting containing the error and a boolean indicating if the name is unique.
 */
export async function verifyIfConnectorNameIsUnique(
  connectorName,
  deploymentId
) {
  const params = {connectorName, deploymentId};
  const result = {error: null, isUnique: false};

  try {
    const {data: matchingConnectors} = await get({
      path: 'deployments/connectors',
      params,
    });
    if (matchingConnectors.length > 0) {
      result.isUnique = false;
    } else {
      result.isUnique = true;
    }
  } catch (error) {
    result.error = error;
  }

  return result;
}

/**
 * Generates a connector token for the given connector name and deployment ID.
 *
 * @async
 * @function generateConnectorToken
 * @param {string} connectorName - The name of the connector.
 * @param {string} deploymentId - The ID of the deployment.
 * @returns {Promise<{ error: Error | null, token: string | null }>} A promise that resolves to an object containing the error or the token.
 */
export async function generateConnectorToken(connectorName, deploymentId) {
  const result = {error: null, token: null};
  const data = {
    deploymentId,
    connectorName: sanitizeValues(connectorName),
  };

  try {
    const resp = await post({
      path: 'auth/tokens/connector',
      data,
    });
    result.token = resp.data.token;
  } catch (error) {
    result.error = error;
  }

  return result;
}
