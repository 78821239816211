import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {saveVariable} from 'redux/actions/dataActions';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {resourceToSearchInfoMap} from 'utils/Mappings';
import {
  AD_USERS,
  AD_USERS_ENTITLEMENT_FILTER_OPTIONS,
  DEFAULT_ROWS_PER_PAGE,
} from 'utils/constants';
import {
  selectSelectedDeployment,
  selectTableParams,
  selectVariable,
} from 'utils/reduxSelectors';

const useAdUserFilter = ({enabled, searchTerm}, setLastSearchTerm) => {
  const dispatch = useDispatch();
  const {params} = useSelector((state) => selectTableParams(state, AD_USERS));
  const {deploymentId} = useSelector(selectSelectedDeployment) || {
    deploymentId: '',
  };
  const {page, rowsPerPage} = useSelector(
    (state) => state.tableData[AD_USERS]
  ) || {page: 0, rowsPerPage: DEFAULT_ROWS_PER_PAGE};
  const {entitlementsFilter, showDisabledUsers} = useSelector((state) =>
    selectVariable(state, 'adUsersTableFilter')
  ) || {
    entitlementsFilter: {
      filterType: AD_USERS_ENTITLEMENT_FILTER_OPTIONS[3],
      enabled: false,
    },
    showDisabledUsers: false,
  };

  const partialSearchKey = `usePartialSearchBy${AD_USERS}`;
  const partialSearchValue = useSelector((state) =>
    selectVariable(state, partialSearchKey)
  );
  const defaultPartialSearch =
    resourceToSearchInfoMap[AD_USERS].defaultUsePartialSearch;
  const usePartialSearch = partialSearchValue ?? defaultPartialSearch;

  useEffect(() => {
    if (enabled && deploymentId) {
      const newParams = {};
      if (entitlementsFilter?.enabled) {
        switch (entitlementsFilter?.filterType) {
          case AD_USERS_ENTITLEMENT_FILTER_OPTIONS[0]:
            newParams.withEntitlements = false;
            break;
          case AD_USERS_ENTITLEMENT_FILTER_OPTIONS[1]:
            newParams.withEntitlements = true;
            newParams.entitlementResourceType = 'machine';
            break;
          case AD_USERS_ENTITLEMENT_FILTER_OPTIONS[2]:
            newParams.withEntitlements = true;
            newParams.entitlementResourceType = 'pool';
            break;
          case AD_USERS_ENTITLEMENT_FILTER_OPTIONS[3]:
            newParams.withEntitlements = true;
            break;
          default:
            break;
        }
      }

      if (!showDisabledUsers) {
        newParams.enabled = true;
      }
      dispatch(
        fetchTableData(
          AD_USERS,
          0,
          rowsPerPage,
          newParams,
          searchTerm,
          usePartialSearch
        )
      );
      setLastSearchTerm(searchTerm);
    }
  }, [
    deploymentId,
    entitlementsFilter.enabled,
    entitlementsFilter,
    searchTerm,
    showDisabledUsers,
  ]);

  const refresh = () => {
    dispatch(
      fetchTableData(
        AD_USERS,
        page,
        rowsPerPage,
        params,
        searchTerm,
        usePartialSearch
      )
    );
  };

  const clearFilters = () => {
    dispatch(
      saveVariable('adUsersTableFilter', {
        entitlementsFilter: {enabled: false, filterType: ''},
        showDisabledUsers: false,
      })
    );
  };

  return {
    refresh,
    clearFilters,
  };
};

export default useAdUserFilter;
