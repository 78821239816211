import {
  TOGGLE_DATE_FILTER,
  SET_DATE_FILTER_PARAMS,
} from 'redux/actions/dateFilterActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';

const defaultState = {
  enabled: false,
  params: {},
};

function dateFilterReducer(state = defaultState, action) {
  switch (action.type) {
    case TOGGLE_DATE_FILTER:
      return {
        ...state,
        enabled: !state.enabled,
      };
    case SET_DATE_FILTER_PARAMS:
      return {
        ...state,
        params: action.params,
      };
    case CLEAR_STORE:
      return defaultState;
    default:
      return state;
  }
}

export default dateFilterReducer;
