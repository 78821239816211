import Checkbox from '@mui/material/Checkbox';
import {styled} from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Popover from '@mui/material/Popover';
import ReorderIcon from '@mui/icons-material/Reorder';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {List as MovableList} from 'react-movable';
import {useDispatch, useSelector} from 'react-redux';
import config from 'config';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import {moveColumn, toggleColumn} from 'redux/actions/tableColumnActions';
import {REMOTE_WORKSTATIONS} from 'utils/constants';

const PREFIX = 'ConfigureColumns';

const classes = {
  cursorNotDragged: `${PREFIX}-cursorNotDragged`,
  cursorDragged: `${PREFIX}-cursorDragged`,
  draggedItem: `${PREFIX}-draggedItem`,
  popover: `${PREFIX}-popover`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.cursorNotDragged}`]: {
    cursor: 'grab',
  },

  [`& .${classes.cursorDragged}`]: {
    cursor: 'grabbing',
  },

  [`& .${classes.draggedItem}`]: {
    listStyleType: 'none',
    opacity: 0.7,
  },

  [`& .${classes.popover}`]: {zIndex: '0 !important'},
}));

function ConfigureColumns({resource}) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const columns = useSelector((state) => {
    const unsortedColumns = Object.values(state.tableColumns[resource] || {});

    return unsortedColumns.sort((a, b) => (a.index < b.index ? -1 : 1));
  });

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Root>
      <CAMButton
        testId="configure-column-button"
        onClick={handleOpen}
        buttonText="Columns"
        size="small"
      />
      <Popover
        className={classes.popover}
        data-testid="configure-column-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MovableList
          values={columns}
          onChange={({oldIndex, newIndex}) =>
            dispatch(moveColumn(resource, columns[oldIndex].id, newIndex))
          }
          renderList={({children, props}) => <List {...props}>{children}</List>}
          renderItem={({value: column, props, isDragged}) => (
            <div {...props}>
              <ListItem
                key={column.id}
                classes={{
                  container: classNames({[classes.draggedItem]: isDragged}),
                }}
              >
                <ListItemIcon
                  className={classNames({
                    [classes.cursorDragged]: isDragged,
                    [classes.cursorNotDragged]: !isDragged,
                  })}
                >
                  <ReorderIcon />
                </ListItemIcon>
                {column.label}
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={() => dispatch(toggleColumn(resource, column.id))}
                    checked={column.enabled}
                    color="primary"
                    disabled={!column.configurable || isDragged}
                    data-testid={`configure-column-${column.id}`}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </div>
          )}
        />
      </Popover>
    </Root>
  );
}

ConfigureColumns.propTypes = {
  resource: PropTypes.string,
};

ConfigureColumns.defaultProps = {
  resource: REMOTE_WORKSTATIONS,
};

export default ConfigureColumns;
