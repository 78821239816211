import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactSelect from 'react-select';
import useSnackbar from 'hooks/useSnackbar';
import {get, put} from 'api/Api';
import {
  clearDropdownData,
  fetchNextPage,
  handleApiError,
} from 'redux/actions/dataActions';
import {closeMachineAssignmentDialog} from 'redux/actions/machineAssignmentDialogActions';
import {doSafeBulkRequest, doSafeRequest} from 'utils/apiUtils';
import {POOL_MACHINES} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {
  selectDropdownData,
  selectSelectedDeployment,
  selectSelectedPool,
} from 'utils/reduxSelectors';
import {isEmpty} from 'utils/utils';

const PREFIX = 'MachineAssignmentDialog';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  muiDialogPaper: `${PREFIX}-muiDialogPaper`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.root}`]: {
    minWidth: '40vw',
  },

  [`& .${classes.content}`]: {
    padding: '0 1rem',
  },

  [`& .${classes.muiDialogPaper}`]: {
    overflowY: 'visible',
  },
}));

function MachineAssignmentDialog() {
  const dispatch = useDispatch();

  const {successSnackbar, errorSnackbar} = useSnackbar();

  // init to null so placeholder displays properly when nothing is selected
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [existingAssignments, setExistingAssignments] = useState([]);
  const [isFetchingExistingAssignments, setIsFetchingExistingAssignments] =
    useState(false);

  const {open, poolUser} = useSelector(
    (state) => state.machineAssignmentDialog
  );
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {poolId} = useSelector((state) => selectSelectedPool(state));
  const {data: machines, isFetching} = useSelector((state) =>
    selectDropdownData(state, POOL_MACHINES)
  );

  const getAssignmentMachineId = (assignment) => {
    if (!assignment) {
      return '';
    }
    const data = assignment.data || {};
    return data.machineId || '';
  };

  const isSelectDisabled = () => !machines || machines.length < 1;

  const isSelectedAlreadyAssigned = () => {
    const selectedMachineId = getAssignmentMachineId(selectedMachine);

    return existingAssignments.some(
      (assignment) => getAssignmentMachineId(assignment) === selectedMachineId
    );
  };

  const buildRemoveAssignmentRequest = (assignment) => {
    const path = mapResourceToPath(POOL_MACHINES, {deploymentId, poolId});
    const machineId = getAssignmentMachineId(assignment);
    const assignedTo = null;
    return {
      path,
      data: {
        machineId,
        assignedTo,
      },
    };
  };

  const removePreviousAssignments = async () => {
    const responses = await doSafeBulkRequest(
      put,
      existingAssignments,
      buildRemoveAssignmentRequest
    );
    const numFailures = responses.filter(
      (response) => response.status !== 'success'
    ).length;

    // if errors are detected, display an error snackbar; otherwise, if the user has cleared
    // their selection, display a success snackbar for clearing assignments
    if (numFailures) {
      errorSnackbar(
        `An error occurred while removing ${numFailures} machine assignments`
      );
    } else if (!selectedMachine || isEmpty(selectedMachine)) {
      successSnackbar(
        `Successfully cleared machine assignments for ${
          poolUser.name || poolUser.userGuid
        }`
      );
    }
  };

  const updatePoolMachine = async () => {
    const path = mapResourceToPath(POOL_MACHINES, {deploymentId, poolId});
    const machineId = getAssignmentMachineId(selectedMachine);
    const assignedTo = poolUser.userGuid || '';
    const data = {machineId, assignedTo};

    const response = await doSafeRequest(put, {path, data});

    if (response.status === 'success') {
      successSnackbar(
        `Successfully assigned ${
          poolUser.name || poolUser.userGuid
        } to selected machine`
      );
    } else {
      dispatch(handleApiError(response));
    }
  };

  const handleClose = () => {
    setSelectedMachine(null);
    dispatch(closeMachineAssignmentDialog());
  };

  const handleSave = async () => {
    setIsSaving(true);

    removePreviousAssignments();

    // if the user hasn't cleared their selection, add a new assignment
    if (selectedMachine && !isEmpty(selectedMachine)) {
      updatePoolMachine();
    }

    setIsSaving(false);
    handleClose();
  };

  const handleScrollToBottom = () => {
    dispatch(fetchNextPage(POOL_MACHINES, 25));
  };

  const handleMachineSelect = (eventMachine) =>
    setSelectedMachine(eventMachine);

  const prepareMachines = (data) =>
    data.map((machine) => ({
      label: machine.machineName,
      value: machine.machineName,
      data: machine,
    }));

  const fetchAssignedMachine = async () => {
    setIsFetchingExistingAssignments(true);

    const path = mapResourceToPath(POOL_MACHINES, {deploymentId, poolId});
    const params = {assignedTo: poolUser.userGuid};
    const response = await doSafeRequest(get, {path, params});

    if (response.status === 'success') {
      const assignedMachines = prepareMachines(response.data || []);
      setExistingAssignments(assignedMachines);
      setSelectedMachine(assignedMachines[0] || null);
    } else {
      dispatch(handleApiError(response));
    }
    setIsFetchingExistingAssignments(false);
  };

  useEffect(() => {
    if (deploymentId && poolId && poolUser.userGuid && open) {
      dispatch(clearDropdownData(POOL_MACHINES));
      dispatch(fetchNextPage(POOL_MACHINES, {page: 0, rowsPerPage: 25}));
      fetchAssignedMachine();
    }
  }, [deploymentId, poolId, poolUser.userGuid, open]);

  const renderTitle = () => <DialogTitle>Assign User to Machine</DialogTitle>;

  const renderContent = () => (
    <ReactSelect
      data-testid="select-pool-machine"
      isDisabled={isSelectDisabled()}
      isLoading={isFetching || isFetchingExistingAssignments}
      isMulti={false}
      isSearchable={false}
      isClearable
      noOptionsMessage="No Workstations Available"
      onMenuScrollToBottom={handleScrollToBottom}
      onChange={handleMachineSelect}
      options={prepareMachines(machines)}
      placeholder="Select a workstation"
      value={selectedMachine}
      closeMenuOnSelect
    />
  );

  const renderActions = () => (
    <DialogActions>
      <Button onClick={handleClose} disabled={isSaving}>
        Cancel
      </Button>
      <Button
        onClick={handleSave}
        disabled={isFetching || isSaving || isSelectedAlreadyAssigned()}
      >
        Save
      </Button>
    </DialogActions>
  );

  if (!open) return null;

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      PaperProps={{className: classes.muiDialogPaper}}
    >
      <DialogContent className={classes.muiDialogPaper}>
        {renderTitle()}
        {renderContent()}
        {renderActions()}
      </DialogContent>
    </StyledDialog>
  );
}

export default MachineAssignmentDialog;
