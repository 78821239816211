import { styled } from '@mui/material/styles';
import {Link} from 'react-router-dom';

const PREFIX = 'PageNotFound';

const classes = {
  greyText: `${PREFIX}-greyText`
};

const Root = styled('div')(() => ({
  [`& .${classes.greyText}`]: {color: 'grey'}
}));

function PageNotFound() {

  return (
    <Root className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <div className="jumbotron text-center">
            <h1>Page Not Found</h1>
            <h2 className={classes.greyText}>404</h2>
            <br />
            <p>
              The page you requested could not be found. Use your browser&apos;s{' '}
              <b>Back</b> button to navigate to the page you have previously
              come from
            </p>
            <p>
              <b>Or you could just press this neat little button:</b>
            </p>
            <Link className="btn btn-large btn-info" to="/">
              <span>Take Me Home</span>
            </Link>
          </div>
        </div>
      </div>
    </Root>
  );
}
export default PageNotFound;
