import {useDispatch, useSelector} from 'react-redux';
import {openBulkActionsDialog} from 'redux/actions/bulkActionsDialogActions';
import {selectSelectedDeployment} from 'utils/reduxSelectors';

const DEFAULT_PARAMS = {
  limit: 15,
  offset: 0,
  sortAsc: true,
};

const useBulkActionDialog = () => {
  const dispatch = useDispatch();
  const {deploymentId} = useSelector(selectSelectedDeployment);

  const bulkActionDialog = ({params, ...rest}) => {
    dispatch(
      openBulkActionsDialog({
        ...rest,
        defaultParams: {
          ...DEFAULT_PARAMS,
          ...params,
          deploymentId,
        },
      })
    );
  };

  return {bulkActionDialog};
};

export default useBulkActionDialog;
