import {DEVELOPMENT, PRODUCTION, STAGING} from 'utils/constants';

const enableReduxLogs = false;

let environment = '';

if (!window.env) {
  window.env = {};
}

let googleClientId = window.env.GOOGLE_CLIENT_ID || '';
let microsoftClientId = window.env.MICROSOFT_CLIENT_ID || ' ';

const gatewayAddress = window.location.origin;
const domain = gatewayAddress.split('://')[1];
const mqttPort = window.env.MQTT_PORT || 443;
const mqttConfig = {
  protocol: 'wss',
  hostname: domain,
  port: mqttPort,
  path: '/api/mqtt',
};

const googleRedirectUrl = window.location.origin;
const msalRedirectUrl = window.location.origin;

const containersStarter = window.env.CONTAINERS_STARTER || 'docker-compose up';

const HELP_MANAGING_REMOTE_WORKSTATIONS_URL =
  'https://www.teradici.com/web-help/pcoip_cloud_access_manager/CACv2/overview_cac_V2/';

const ANYWARE_MONITOR_VERSION = '24.03.1-1';

switch (window.env.ENVIRONMENT) {
  case STAGING:
  case 'staging-azure':
    environment = STAGING;
    break;

  case PRODUCTION:
  case 'production-azure':
    environment = PRODUCTION;
    break;

  case DEVELOPMENT:
  case 'development-azure':
    environment = DEVELOPMENT;
    break;

  // development
  default:
    if (process.env.REACT_APP_LIVE_RELOAD) {
      googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
      microsoftClientId = process.env.REACT_APP_MICROSOFT_CLIENT_ID || '';
    }

    environment = DEVELOPMENT;
    // enableReduxLogs = true;
    break;
}

// dev cluster load these variable from process.env. For production, these variables are loaded as window.env through env.js
const STANDALONE =
  process.env.REACT_APP_STANDALONE === 'true' ||
  window.env.STANDALONE === 'true';
const AMT_SUPPORTED =
  (process.env.REACT_APP_AMT_SUPPORTED === 'true' ||
    window.env.AMT_SUPPORTED === 'true') &&
  STANDALONE;
const MONITOR_ENABLED =
  process.env.REACT_APP_MONITOR_ENABLED === 'true' ||
  window.env.MONITOR_ENABLED === 'true';
const CONNECTOR_MQTT_FEATURE_ENABLED =
  process.env.REACT_APP_CONNECTOR_MQTT_FEATURE_ENABLED === 'true' ||
  window.env.CONNECTOR_MQTT_FEATURE_ENABLED === 'true';
const CAM_SL_PREVIEW_MODE =
  process.env.REACT_APP_CAM_SL_PREVIEW_MODE === 'true' ||
  window.env.CAM_SL_PREVIEW_MODE === 'true';
const PRIVACY_POLICY_TRACKING_ENABLED =
  !STANDALONE &&
  (window.env.PRIVACY_POLICY_TRACKING_ENABLED ||
    process.env.REACT_APP_PRIVACY_POLICY_TRACKING_ENABLED === 'true');
const ANYWARE_MONITOR_DOWNLOAD_URL =
  process.env.REACT_APP_ANYWARE_MONITOR_DOWNLOAD_URL ||
  (window.env.ANYWARE_MONITOR_DOWNLOAD_URL === 'undefined'
    ? undefined
    : window.env.ANYWARE_MONITOR_DOWNLOAD_URL);
const PRIVACY_POLICY_UPDATED_ON =
  process.env.REACT_APP_PRIVACY_POLICY_UPDATED_ON ||
  window.env.PRIVACY_POLICY_UPDATED_ON;
const EULA_UPDATED_ON =
  process.env.REACT_APP_EULA_UPDATED_ON || window.env.EULA_UPDATED_ON;
const MONITOR_EULA_UPDATED_ON =
  process.env.REACT_APP_MONITOR_EULA_UPDATED_ON ||
  window.env.MONITOR_EULA_UPDATED_ON;
const SESSION_HISTORY_ENABLED =
  process.env.REACT_APP_SESSION_HISTORY_ENABLED === 'true' ||
  window.env.SESSION_HISTORY_ENABLED === 'true';
const CLOUDSMITH_CHANNEL =
  process.env.REACT_APP_CLOUDSMITH_CHANNEL ||
  (window.env.CLOUDSMITH_CHANNEL === 'undefined'
    ? undefined
    : window.env.CLOUDSMITH_CHANNEL) ||
  'stable';
const PROVISION_CONNECTOR_ENABLED =
  process.env.REACT_APP_PROVISION_CONNECTOR_ENABLED === 'true' ||
  window.env.PROVISION_CONNECTOR_ENABLED === 'true';
const FLOATING_POOL_IDLE_MINUTES =
  Number.parseInt(process.env.REACT_APP_FLOATING_POOL_IDLE_MINUTES, 10) ||
  Number.parseInt(window.env.FLOATING_POOL_IDLE_MINUTES, 10) ||
  20;

const DOWNLOAD_TOKEN_ROUTES_ENABLED =
  process.env.REACT_APP_DOWNLOAD_TOKEN_ROUTES_ENABLED === 'true' ||
  window.env.DOWNLOAD_TOKEN_ROUTES_ENABLED === 'true';

const CONNECTOR_WIZARD_ENABLED =
  process.env.REACT_APP_CONNECTOR_WIZARD_ENABLED === 'true' ||
  window.env.CONNECTOR_WIZARD_ENABLED === 'true';

const WEBHOOKS_ENABLED =
  process.env.REACT_APP_WEBHOOKS_ENABLED === 'true' ||
  window.env.WEBHOOKS_ENABLED === 'true';

const ENABLE_UPDATED_STYLING =
  process.env.REACT_APP_ENABLE_UPDATED_STYLING === 'true' ||
  window.env.ENABLE_UPDATED_STYLING === 'true';

const SESSION_HISTORY_UPDATED_UI =
  process.env.REACT_APP_SESSION_HISTORY_UPDATED_UI === 'true' ||
  window.env.SESSION_HISTORY_UPDATED_UI === 'true';

// The default polling rate varies from 30 to 60 seconds.
export const defaultNotificationPollingRate = () =>
  (30 + Math.random() * 30) * 1000;

function isBeta() {
  if (STANDALONE) {
    return false;
  }
  return window.location.pathname.startsWith('/beta-ui');
}

function isConnectorMqttFeatureEnabled() {
  return CONNECTOR_MQTT_FEATURE_ENABLED && isBeta();
}

function isStandalone() {
  return STANDALONE;
}

// if RSM is supported, allow user to manage RSM credential and add RSM machine to AWM
const RSM_SUPPORTED =
  (process.env.REACT_APP_RSM_SUPPORTED === 'true' ||
    window.env.RSM_SUPPORTED === 'true') &&
  isBeta();
// This feature is in Proof of Concept only
function pothosSupported() {
  return isBeta() && PROVISION_CONNECTOR_ENABLED;
}

function isBetaOrStandalone() {
  if (STANDALONE) {
    return true;
  }
  return window.location.pathname.startsWith('/beta-ui');
}

// this is helpful when we move RBAC feature out of beta
function isRBACSupported() {
  return isBeta();
}

// feature flag around TSW-179278 epic
function multiSamlSupported() {
  return isBeta();
}

function isDownloadTokenEnabled() {
  return DOWNLOAD_TOKEN_ROUTES_ENABLED;
}
function isConnectorWizardEnabled() {
  return isBeta() || CONNECTOR_WIZARD_ENABLED;
}

function isWebhooksEnabled() {
  return WEBHOOKS_ENABLED;
}

function isUpdatedStylingEnabled() {
  return ENABLE_UPDATED_STYLING;
}

// This is created as a function so that it can be easily updated to include
// for a more complex logic, based for example, on a deployment property.
function isMonitorEnabled() {
  return MONITOR_ENABLED;
}

function isSessionAuditEnabled() {
  return SESSION_HISTORY_ENABLED;
}

function isDevelopment() {
  return environment === DEVELOPMENT;
}
function isLicensingEnabled() {
  const enableLicensing =
    (process.env.REACT_APP_ENABLE_LICENSING?.toLowerCase() === 'true' ||
      window.env.ENABLE_LICENSING?.toLowerCase() === 'true') &&
    isBeta();
  return enableLicensing;
}

function getCloudsmithChannel(useBetaToggle = false) {
  const overrideStableWithBeta =
    useBetaToggle && isBeta() && CLOUDSMITH_CHANNEL === 'stable';
  if (overrideStableWithBeta) {
    return 'beta';
  }
  return CLOUDSMITH_CHANNEL;
}

function getDownloadRepositoryName(channel = getCloudsmithChannel()) {
  if (channel === 'stable') {
    return 'anyware-manager';
  }
  return `anyware-manager-${channel}`;
}

function isSessionHistoryUpdatedUiEnabled() {
  return SESSION_HISTORY_UPDATED_UI && isSessionAuditEnabled();
}

const config = {
  isBeta,
  isBetaOrStandalone,
  isConnectorMqttFeatureEnabled,
  isStandalone,
  isRBACSupported,
  isMonitorEnabled,
  isSessionAuditEnabled,
  multiSamlSupported,
  isDownloadTokenEnabled,
  pothosSupported,
  isDevelopment,
  isLicensingEnabled,
  isConnectorWizardEnabled,
  isUpdatedStylingEnabled,
  isWebhooksEnabled,
  getCloudsmithChannel,
  getDownloadRepositoryName,
  isSessionHistoryUpdatedUiEnabled,
  RSM_SUPPORTED,
  GATEWAY_ADDRESS: gatewayAddress,
  CONNECTOR_MQTT_FEATURE_ENABLED,
  MQTT_CONFIG: mqttConfig,
  CONTAINERS_STARTER: containersStarter,
  DEFAULT_PAGE_LIMIT: 15,
  COOKIES_MAX_AGE: 2 * 60 * 60,
  ENABLE_REDUX_LOGS: enableReduxLogs,
  MSAL_REDIRECT_URL: msalRedirectUrl,
  GOOGLE_REDIRECT_URL: googleRedirectUrl,
  GOOGLE_CLIENT_ID: googleClientId,
  MICROSOFT_CLIENT_ID: microsoftClientId,
  PREREQUISITES_EXISTING_LEARN_MORE_URL: HELP_MANAGING_REMOTE_WORKSTATIONS_URL,
  ENVIRONMENT: environment,
  STANDALONE,
  CAM_SL_PREVIEW_MODE,
  PRIVACY_POLICY_TRACKING_ENABLED,
  PRIVACY_POLICY_UPDATED_ON,
  EULA_UPDATED_ON,
  MONITOR_EULA_UPDATED_ON,
  AMT_SUPPORTED,
  ANYWARE_MONITOR_DOWNLOAD_URL,
  ANYWARE_MONITOR_VERSION,
  FLOATING_POOL_IDLE_MINUTES,
};

export default config;
