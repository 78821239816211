import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import BackwardIcon from '@mui/icons-material/ChevronLeft';
import ForwardIcon from '@mui/icons-material/ChevronRight';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {
  CONNECTOR_CERTIFICATE_HEADER,
  NO_CERTIFICATES_MESSAGE,
} from 'utils/constants';
import {isCertExpired, unpackConnectorCertificates} from 'utils/utils';

const PREFIX = 'ConnectorCertificates';

const classes = {
  rootContainer: `${PREFIX}-rootContainer`,
  bodyContainer: `${PREFIX}-bodyContainer`,
  tableCellRoot: `${PREFIX}-tableCellRoot`,
  tableCellHead: `${PREFIX}-tableCellHead`,
  tableCellBody: `${PREFIX}-tableCellBody`,
  certificateName: `${PREFIX}-certificateName`,
  moreInfoButton: `${PREFIX}-moreInfoButton`,
  detailsHeader: `${PREFIX}-detailsHeader`,
  detailName: `${PREFIX}-detailName`,
  detailValue: `${PREFIX}-detailValue`,
  loadMoreButton: `${PREFIX}-loadMoreButton`,
  noDataMessage: `${PREFIX}-noDataMessage`,
  certExpiry: `${PREFIX}-certExpiry`,
  redText: `${PREFIX}-redText`,
  greyText: `${PREFIX}-greyText`,
  bold: `${PREFIX}-bold`,
  evenPadding: `${PREFIX}-evenPadding`,
  unevenPadding: `${PREFIX}-unevenPadding`
};

const StyledPaper = styled(Paper)(() => ({
  [`&.${classes.rootContainer}`]: {
    height: '100%',
    width: '100%',
    minWidth: '300px',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '1rem',
  },

  [`& .${classes.bodyContainer}`]: {
    flex: '1',
    overflow: 'auto',
    justifyContent: 'center',
  },

  [`& .${classes.tableCellRoot}`]: {
    padding: '0.2rem 0.25rem',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '0.75rem',
  },

  [`& .${classes.tableCellHead}`]: {
    fontWeight: '450',
  },

  [`& .${classes.tableCellBody}`]: {
    fontWeight: '400',
  },

  [`& .${classes.certificateName}`]: {
    color: 'rgba(0, 0, 0, 0.8)',
    paddingLeft: '0.1rem',
    maxWidth: '7rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  [`& .${classes.moreInfoButton}`]: {
    width: '20px',
    height: '20px',
  },

  [`& .${classes.detailsHeader}`]: {
    color: 'rgba(0, 0, 0, 0.8)',
    maxWidth: '100%',
    paddingLeft: '0.5rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  [`& .${classes.detailName}`]: {
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: '0.825rem',
    width: '50%',
  },

  [`& .${classes.detailValue}`]: {
    fontSize: '0.825rem',
    width: '50%',
  },

  [`& .${classes.loadMoreButton}`]: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '0.825rem',
    padding: '0 0.25rem',
    lineHeight: '0',
  },

  [`& .${classes.noDataMessage}`]: {
    fontSize: '0.9rem',
    fontWeight: 350,
    alignSelf: 'center',
  },

  [`& .${classes.certExpiry}`]: {minWidth: '5rem'},
  [`& .${classes.redText}`]: {color: '#f44336'},
  [`& .${classes.greyText}`]: {color: 'rgba(0, 0, 0, 0.6)'},
  [`& .${classes.bold}`]: {fontWeight: '450'},
  [`& .${classes.evenPadding}`]: {padding: '0.1rem'},
  [`& .${classes.unevenPadding}`]: {padding: '0.5rem 2rem'}
}));

const TABLE_HEADERS = ['Name', 'Expiry Date', 'More'];
const CERTIFICATE_DETAILS = [
  {displayName: 'Type', fieldName: 'type'},
  {displayName: 'Host', fieldName: 'host'},
  {displayName: 'Expiry Date', fieldName: 'expiry'},
  {displayName: 'Expiry Status', fieldName: 'status'},
];

function ConnectorCertificates({data, elevation}) {

  const [certificates, setCertificates] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [moreDataAvailable, setMoreDataAvailable] = useState(false);

  const muiTableCellStyling = {
    root: classes.tableCellRoot,
    head: classes.tableCellHead,
    body: classes.tableCellBody,
  };

  useEffect(() => setCertificates(unpackConnectorCertificates(data)), [data]);
  useEffect(
    () => setMoreDataAvailable(certificates.length > 3),
    [certificates]
  );

  const handleMoreClick = (certificate) => setSelectedCertificate(certificate);
  const handleBackClick = () => setSelectedCertificate(null);
  const handleLoadMoreClick = () => setMoreDataAvailable(false);

  const prepareRowData = (certificate) => [
    <div
      id="cert-name"
      data-testid={`${certificate.id}-name`}
      className={classes.certificateName}
    >
      {certificate.name}
    </div>,
    <div
      id="cert-expiry"
      data-testid={`${certificate.id}-expiry`}
      className={classnames(
        classes.certExpiry,
        isCertExpired(certificate.expiry) ? classes.redText : classes.greyText
      )}
    >
      {certificate.expiry}
    </div>,
    <IconButton
      id="cert-more-button"
      data-testid={`${certificate.id}-more-button`}
      size="small"
      className={classes.moreInfoButton}
      onClick={() => handleMoreClick(certificate)}
    >
      <ForwardIcon />
    </IconButton>,
  ];

  const renderHeader = () => (
    <Typography variant="subtitle1" className={classes.bold}>
      {CONNECTOR_CERTIFICATE_HEADER}
    </Typography>
  );

  const renderTableHead = () => (
    <TableHead>
      <TableRow>
        {TABLE_HEADERS.map((header) => (
          <TableCell key={header} classes={muiTableCellStyling}>
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderTableRow = (rowData, rowIndex) => (
    <TableRow key={`cert-row-${rowIndex}`}>
      {rowData.map((rowItem) => (
        <TableCell key={rowItem.props.id} classes={muiTableCellStyling}>
          {rowItem}
        </TableCell>
      ))}
    </TableRow>
  );

  const renderTableBody = () => {
    const availableData = moreDataAvailable
      ? certificates.slice(0, 3)
      : certificates;
    return (
      <TableBody>
        {availableData.map((certificate, index) =>
          renderTableRow(prepareRowData(certificate), index)
        )}
      </TableBody>
    );
  };

  const renderCertificatesTable = () => (
    <>
      <Table>
        {renderTableHead()}
        {renderTableBody()}
      </Table>
      {moreDataAvailable && (
        <Button
          data-testid="load-more-button"
          onClick={handleLoadMoreClick}
          classes={{text: classes.loadMoreButton}}
        >
          Load more
        </Button>
      )}
    </>
  );

  const renderDetailsHeader = () => (
    <Grid container alignItems="center" wrap="nowrap">
      <IconButton
        data-testid="back-button"
        size="small"
        className={classes.moreButton}
        onClick={() => handleBackClick()}
      >
        <BackwardIcon />
      </IconButton>
      <div data-testid="detail-header" className={classes.detailsHeader}>
        {selectedCertificate.name}
      </div>
    </Grid>
  );

  const renderDetailsRow = (detail, index) => (
    <Grid
      key={`detail-${index}`}
      container
      wrap="nowrap"
      className={classes.evenPadding}
    >
      <Typography noWrap className={classes.detailName}>
        {detail.displayName}
      </Typography>
      <Tooltip
        title={selectedCertificate[detail.fieldName]}
        placement="right"
        arrow
      >
        <Typography
          data-testid={`detail-${detail.fieldName}`}
          noWrap
          className={classes.detailValue}
        >
          {selectedCertificate[detail.fieldName]}
        </Typography>
      </Tooltip>
    </Grid>
  );

  const renderCertificateDetails = () => (
    <>
      {renderDetailsHeader()}
      <Grid container direction="column" className={classes.unevenPadding}>
        {CERTIFICATE_DETAILS.map((detail, index) =>
          renderDetailsRow(detail, index)
        )}
      </Grid>
    </>
  );

  const renderCertificatesBody = () =>
    selectedCertificate
      ? renderCertificateDetails()
      : renderCertificatesTable();

  const renderNoDataMessage = () => (
    <Typography className={classes.noDataMessage}>
      {NO_CERTIFICATES_MESSAGE}
    </Typography>
  );

  return (
    <StyledPaper
      id="connector-certificate"
      className={classes.rootContainer}
      component={Grid}
      container
      direction="column"
      wrap="nowrap"
      elevation={elevation}
    >
      {renderHeader()}
      <Grid className={classes.bodyContainer} container>
        {certificates.length > 0
          ? renderCertificatesBody()
          : renderNoDataMessage()}
      </Grid>
    </StyledPaper>
  );
}

ConnectorCertificates.propTypes = {
  data: PropTypes.object,
  elevation: PropTypes.number,
};

ConnectorCertificates.defaultProps = {
  data: {},
  elevation: 1,
};

export default ConnectorCertificates;
