import Avatar from '@mui/material/Avatar';
import {styled} from '@mui/material/styles';
import Chip, {chipClasses} from '@mui/material/Chip';
import PropTypes from 'prop-types';

const allowedColors = [
  'primary',
  'secondary',
  'info',
  'success',
  'warning',
  'error',
];

const customChipProps = {
  tagColor: 'tagColor',
  fontColor: 'fontColor',
  isSmall: 'isSmall',
};

const getColorHexFromTheme = (color, theme) => {
  if (allowedColors.includes(color)) {
    // Use colors from MUI theme
    return theme.palette[color].main;
  }
  // Use the hex color if not one of the MUI theme colors
  if (color[0] === '#' && [3, 6].includes(color.slice(1).length)) {
    return color;
  }
  // Else use the info color by default
  return theme.palette.info.main;
};

const CustomChip = styled(({...props}) => <Chip {...props} />, {
  shouldForwardProp: (prop) => !Object.values(customChipProps).includes(prop),
})(({theme, ...props}) => {
  const backgroundHex = getColorHexFromTheme(
    props[customChipProps.tagColor],
    theme
  );

  return {
    [`& .${chipClasses.label}`]: {
      borderRadius: '0.8em',
      background: backgroundHex,
      color: props[customChipProps.fontColor]
        ? getColorHexFromTheme(props[customChipProps.fontColor], theme)
        : theme.palette.getContrastText(backgroundHex),
      fontSize: props[customChipProps.isSmall] ? '0.7em' : 'inherit',
      padding: '6px',
    },
  };
});

function Tag({displayText, small, color: tagColor, fontColor, idTag}) {
  const chipSize = small ? 'small' : 'medium';

  const customProps = {
    [customChipProps.fontColor]: fontColor,
    [customChipProps.tagColor]: tagColor,
    [customChipProps.isSmall]: small,
  };

  if (idTag) {
    return (
      <CustomChip
        size={chipSize}
        variant="outlined"
        label={displayText}
        avatar={<Avatar>ID</Avatar>}
        {...customProps}
      />
    );
  }

  return <CustomChip size={chipSize} label={displayText} {...customProps} />;
}

Tag.propTypes = {
  displayText: PropTypes.string.isRequired,
  small: PropTypes.bool,
  color: PropTypes.string,
  fontColor: PropTypes.string,
  idTag: PropTypes.bool,
};

Tag.defaultProps = {
  small: false,
  color: 'info',
  fontColor: '',
  idTag: false,
};

export default Tag;
