import {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import {useSelector} from 'react-redux';
import {Grid, TextField, Typography, Divider} from '@mui/material';

import Select from 'react-select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleSwitch from 'components/CAM/buttons/ToggleSwitch/ToggleSwitch';
import {ReactComponent as DirectoryIcon} from 'icons/directory.svg';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import {
  validatePEM,
  validateCertificate,
  validatePrivateKey,
  verifyCertKeyPair,
  isEmpty,
} from 'utils/utils';

import {
  MFA_CAPABILITY_NAME,
  FED_AUTH_CAPABILITY_NAME,
  SSO_CAPABILITY_NAME,
  CERTIFICATES_EXTENSIONS,
} from 'utils/constants';
import {
  configParams,
  mfaConfigParams,
  mfaRadiusParams,
  oauthEnableParams,
  oauthConfigParams,
  ssoEnableParams,
  ssoWebEnrollmentParams,
  ssoCACertificateParams,
} from '../createWizard/wizardConstants';
import {
  getClearedConfigState,
  useWizardState,
  checkRequiredParams,
  generateAwcCommand,
} from '../createWizard/wizardConfigState';
import CopyAwcCommand from '../createWizard/CopyAwcCommand';
import CapabilityStatusDisplay from './CapabilityStatus';
import FileInputDropbox from '../createWizard/FileDropBox';

const PREFIX = 'AuthenticationTab';

const classes = {
  root: `${PREFIX}-root`,
  flexRow: `${PREFIX}-flexRow`,
  propTitle: `${PREFIX}-propTitle`,
  statusIcon: `${PREFIX}-statusIcon`,
  statusChip: `${PREFIX}-statusChip`,
  label: `${PREFIX}-label`,
  bodyText: `${PREFIX}-bodyText`,
  header: `${PREFIX}-header`,
  subheader: `${PREFIX}-subheader`,
  form: `${PREFIX}-form`,
  textField: `${PREFIX}-textField`,
  errorText: `${PREFIX}-errorText`,
  fileRow: `${PREFIX}-fileRow`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    margin: 'auto',
    maxWidth: '1220px',
  },

  [`& .${classes.flexRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '32px',
    marginBottom: '16px',
  },

  [`& .${classes.propTitle}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '200px',
  },

  [`& .${classes.statusIcon}`]: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginRight: '16.5px',
  },

  [`& .${classes.statusChip}`]: {
    backgroundColor: '#EBF3FA',
    color: '#1E3BB8',
  },

  [`& .${classes.label}`]: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: 'rgba(0, 0, 0, 0.87)',
  },

  [`& .${classes.bodyText}`]: {
    ...stylesFromFigma.connectorPanelBodyText,
  },

  [`& .${classes.header}`]: {
    ...stylesFromFigma.capabilitySectionTitle,
    marginLeft: '16px',
  },

  [`& .${classes.subheader}`]: {
    ...stylesFromFigma.capabilitySubHeader,
    marginTop: '16px',
  },

  [`& .${classes.form}`]: {marginTop: '12px'},

  [`& .${classes.textField}`]: {
    width: '100%',
    maxWidth: '1000px',
    margin: '6px 0px',
  },

  [`& .${classes.errorText}`]: {color: 'red'},

  [`& .${classes.fileRow}`]: {
    padding: '8px 0px',
  },
}));

// Configuration sections
const MFA = 'mfa';
const OAUTH = 'oauth';
const SSO = 'sso';

const SSO_CONFIG_WEB_ENROLLMENT = 'SSO_CONFIG_WEB_ENROLLMENT';
const SSO_CONFIG_CA_CERT = 'SSO_CONFIG_CA_CERT';

const sections = [MFA, OAUTH, SSO];
const defaultCommandInfo = {};
sections.forEach((section) => {
  defaultCommandInfo[section] = {commandToCopy: '', isCopyEnabled: false};
});

// SSO options
const webEnrollmentOption = {
  value: SSO_CONFIG_WEB_ENROLLMENT,
  label: 'Web Enrollment Service',
};
const caCertOption = {value: SSO_CONFIG_CA_CERT, label: 'CA Certificate'};
const ssoConfigOptions = [webEnrollmentOption, caCertOption];

function AuthenticationTab() {
  const selectedConnector = useSelector(
    (state) => state?.data?.dataByResource?.selectedConnector
  );
  const capabilityList = selectedConnector?.capabilities || [];
  const capability = (capabilityName) =>
    capabilityList.find((cap) => cap?.capabilityName === capabilityName);

  const [fedAuthError, setFedAuthError] = useState('');
  const [ssoError, setSSOError] = useState('');
  const [oAuthCaCertFileName, setOAuthCaCertFileName] = useState('');
  const [ssoCaCertFileName, setSSOCaCertFileName] = useState('');
  const [ssoCrlFileName, setSSOCrlFileName] = useState('');
  const [ssoPrivateKeyFileName, setSSOPrivateKeyFileName] = useState('');

  const {configState, setConfigState} = useWizardState();
  const [commandInfo, setCommandInfo] = useState(defaultCommandInfo);
  const [ssoConfigMethod, setSSOConfigMethod] = useState(SSO_CONFIG_CA_CERT);

  const clearSSOParams = () => {
    const updatedState = {...configState};
    ssoCACertificateParams.forEach((param) => {
      updatedState[param.fieldName] = '';
    });
    ssoWebEnrollmentParams.forEach((param) => {
      updatedState[param.fieldName] = '';
    });
    setConfigState(updatedState);
    setSSOCaCertFileName('');
    setSSOPrivateKeyFileName('');
    setSSOCrlFileName('');
  };

  // OAUTH
  // TODO: There is a hack here to include the enable-ad-auth flag in the command
  //      This is because the enable-ad-auth is not configurable via the UI but it needs to be disabled
  //      when oauth is enabled for the regular use case
  //      todo: Separate the AD Auth and FedAuth flags
  const adjustOAuthEnableParams = () => {
    return [
      ...oauthEnableParams,
      {
        fieldName: 'enableAdAuth',
        paramType: 'boolean',
        flag: '--enable-ad-authentication',
        keys: 'domain.enableAuthentication',
      },
    ];
  };

  const adjustOAuthEnableState = () => {
    return {...configState, enableAdAuth: !configState.enableOauth};
  };

  const createConfigureCommand = () => {
    const baseCommand = 'sudo /usr/local/bin/anyware-connector configure';
    const newCommandInfo = {...commandInfo};
    // MFA
    newCommandInfo[MFA].commandToCopy = generateAwcCommand({
      baseCommand,
      configParamArray: [...mfaConfigParams],
      configState,
    });

    newCommandInfo[OAUTH].commandToCopy = generateAwcCommand({
      baseCommand,
      configParamArray: adjustOAuthEnableParams(),
      configState: adjustOAuthEnableState(),
    });
    // SSO
    newCommandInfo[SSO].commandToCopy = generateAwcCommand({
      baseCommand,
      configParamArray: [
        ...ssoEnableParams,
        ...(ssoConfigMethod === SSO_CONFIG_CA_CERT
          ? ssoCACertificateParams
          : ssoWebEnrollmentParams),
      ],
      configState,
    });
    setCommandInfo(newCommandInfo);
  };

  // Enable copy button if all required fields are filled or if the section is disabled (to allow copy of the disable command)
  const toggleCopyButtonStatus = () => {
    const newCommandInfo = {...commandInfo};
    // MFA
    newCommandInfo[MFA].isCopyEnabled =
      !configState.enableMfa ||
      (configState.enableMfa &&
        checkRequiredParams(mfaConfigParams, configState));
    // OAUTH
    newCommandInfo[OAUTH].isCopyEnabled =
      !configState.enableOauth ||
      (configState.enableOauth &&
        checkRequiredParams(oauthConfigParams, configState));
    // SSO
    newCommandInfo[SSO].isCopyEnabled =
      !configState.enableSSO ||
      (configState.enableSSO &&
        ((ssoConfigMethod === SSO_CONFIG_WEB_ENROLLMENT &&
          checkRequiredParams(ssoWebEnrollmentParams, configState)) ||
          (ssoConfigMethod === SSO_CONFIG_CA_CERT &&
            checkRequiredParams(ssoCACertificateParams, configState))));
    setCommandInfo(newCommandInfo);
  };

  const handleChange = (events) => {
    const eventArray = Array.isArray(events) ? events : [events];

    const updatedState = {...configState};
    eventArray.forEach((event) => {
      const {name, value} = event.target;
      updatedState[name] = value;
    });
    setConfigState(updatedState);
  };

  const handleSSOCertificateSelection = (fileContents, fileName) => {
    const updatedState = {...configState};

    if (!fileContents) {
      return;
    }

    // Check the fileContents to see if the contents contain a PEM header indicating a CRL
    if (fileContents.includes('-----BEGIN X509 CRL-----')) {
      // TODO: Add some CRL validation here
      const pemError = '';
      const isValid = true;
      if (isValid) {
        updatedState.ssoSigningCrlString = fileContents;
        setSSOError('');
        setConfigState(updatedState);
        setSSOCrlFileName(fileName);
      } else {
        updatedState.ssoSigningCrlString = null;
        setSSOError(
          `File ${fileName} does not contain a valid PEM-encoded CRL. ${pemError}`
        );
      }
    } else {
      // Any other file, try to to validate it as a PEM encoded certificate or key

      const pemError = validatePEM(fileContents);
      const isValidPEM = isEmpty(pemError);
      if (!isValidPEM) {
        setSSOError(`File ${fileName}: ${pemError}`);
        return;
      }

      setSSOError('');

      const isCertificate = isEmpty(validateCertificate(fileContents));
      const isPrivateKey = isEmpty(validatePrivateKey(fileContents));

      if (isCertificate) {
        updatedState.ssoSigningCsrCaString = fileContents;
        setSSOCaCertFileName(fileName);
      } else if (isPrivateKey) {
        updatedState.ssoSigningCsrKeyString = fileContents;
        setSSOPrivateKeyFileName(fileName);
      }

      // If the certificate and the key is already uploaded, verify the cert-key pair
      if (
        !isEmpty(updatedState.ssoSigningCsrCaString) &&
        !isEmpty(updatedState.ssoSigningCsrKeyString)
      ) {
        const isValidPair = verifyCertKeyPair(
          updatedState.ssoSigningCsrCaString,
          updatedState.ssoSigningCsrKeyString
        );
        if (!isValidPair) {
          setSSOError(
            'The certificate and key pair is invalid. Please upload a valid key and certificate pair again.'
          );
          updatedState.ssoSigningCsrCaString = null;
          updatedState.ssoSigningCsrKeyString = null;
        }
      }
      setConfigState(updatedState);
    }
  };

  const handleOauthCertificateSelection = (fileContents, fileName) => {
    const updatedState = {...configState};
    const pemError = validateCertificate(fileContents);
    const isValid = isEmpty(pemError);
    if (isValid) {
      updatedState.oauthServerCaString = fileContents;
      setOAuthCaCertFileName(fileName);
      setFedAuthError('');
    } else {
      updatedState.oauthServerCaString = null;
      setFedAuthError(`File ${fileName}: ${pemError}`);
    }
    setConfigState(updatedState);
  };

  useEffect(() => {
    setConfigState(getClearedConfigState(configParams, configState));
    return function cleanup() {
      setConfigState(getClearedConfigState(configParams, configState));
    };
  }, []);

  // Update configure command any time one of the MFA parameters changes
  useEffect(() => {
    createConfigureCommand();
    toggleCopyButtonStatus();
  }, [JSON.stringify(configState)]);

  const displayFieldsList = (fieldsList, disabled = false) => (
    <Grid container spacing={2} className={classes.form}>
      {fieldsList.map((param) => {
        // Validate input if a function is available & user has provided a value
        const isValueProvided = Boolean(configState[param.fieldName]);
        const isParamValid =
          isValueProvided &&
          typeof param.isValid === 'function' &&
          param.isValid(configState[param.fieldName]);
        const helperText =
          isValueProvided && !isParamValid && param.helperText
            ? param.helperText
            : '';

        return (
          <Grid key={`grid - ${param.fieldName} `} item xs={12}>
            <TextField
              className={classes.textField}
              value={configState[param.fieldName]}
              label={param.label}
              disabled={disabled}
              required={param.required}
              helperText={helperText}
              error={Boolean(helperText)}
              placeholder={param.placeholder}
              type={param.isSecret ? 'password' : 'text'}
              name={param.fieldName}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
        );
      })}
    </Grid>
  );

  const handleSSOConfigMethodChange = (selectedOption) => {
    clearSSOParams();
    setSSOConfigMethod(selectedOption.value);
  };

  const toggleSwitch = (
    label,
    name,
    toggleClickable = true,
    hoverText = ''
  ) => (
    <FormControl>
      <FormControlLabel
        label={label}
        title={hoverText}
        control={
          <ToggleSwitch
            name={name}
            title={hoverText}
            isOn={configState[name]}
            onClick={(event) => {
              const updatedState = {...configState};
              const {name: eventName, checked} = event.target;
              updatedState[eventName] = checked;
              setConfigState(updatedState);
            }}
            disabled={!toggleClickable}
          />
        }
      />
    </FormControl>
  );

  const fileSelectedText = (fileName) => (
    <span> {fileName || 'not selected'}</span>
  );

  return (
    <Root className={classes.root}>
      <div className={classes.flexRow}>
        <DirectoryIcon />
        <Typography className={classes.header}>
          Multi-factor Authentication
        </Typography>
      </div>
      <Divider />

      <CapabilityStatusDisplay
        capabilityData={capability(MFA_CAPABILITY_NAME)}
        isLoading={isEmpty(selectedConnector)}
      />

      <Typography className={classes.bodyText}>
        Enhance the security of your workstations with Multi-factor
        Authentication (MFA). It is highly recommended to enable MFA for
        connectors that support external PCoIP connections, as it provides an
        extra layer of protection against unauthorized access.
      </Typography>

      <Typography className={classes.bodyText}>
        Configure MFA on your Anyware Connector by entering the RADIUS server
        information below.
      </Typography>

      {toggleSwitch('Enable MFA', 'enableMfa')}

      {displayFieldsList(mfaRadiusParams, !configState.enableMfa)}

      <CopyAwcCommand
        isCopyEnabled={commandInfo[MFA].isCopyEnabled}
        commandToCopy={commandInfo[MFA].commandToCopy}
        desiredState={configState}
        isApplyEnabled
        commandType="configure"
        configParamArray={mfaConfigParams}
      />

      <div className={classes.flexRow}>
        <DirectoryIcon />
        <Typography className={classes.header}>
          Federated Authentication
        </Typography>
      </div>
      <Divider />

      <CapabilityStatusDisplay
        capabilityData={capability(FED_AUTH_CAPABILITY_NAME)}
        isLoading={isEmpty(selectedConnector)}
      />

      <Typography className={classes.bodyText}>
        Enable Federated Authentication to allow users to authenticate using a
        third-party identity provider.
      </Typography>

      {toggleSwitch('Enable Federated Authentication', 'enableOauth')}

      {/* Display all oauthConfigPrams fields, except the one with fieldName=oauthServerCaString */}
      {displayFieldsList(
        oauthConfigParams.filter(
          (param) => param.fieldName !== 'oauthServerCaString'
        ),
        !configState.enableOauth
      )}

      <Typography className={classes.bodyText}>
        You may optionally upload the OAuth server&lsquo;s CA certificate to
        validate its identity.
        <div className={classes.fileRow}>
          <ul>
            <li>
              CA certificate:
              {fileSelectedText(oAuthCaCertFileName)}
            </li>
          </ul>
        </div>
        {fedAuthError && (
          <Typography className={classes.errorText}>{fedAuthError}</Typography>
        )}
        Please select the certificate file below:
        <br />
      </Typography>

      <FileInputDropbox
        style={{padding: '16px'}}
        acceptedMimeType="application/x-pem-file"
        acceptedExtensions={CERTIFICATES_EXTENSIONS}
        disabled={!configState.enableOauth}
        fileResultCallback={handleOauthCertificateSelection}
      />
      <CopyAwcCommand
        isCopyEnabled={commandInfo[OAUTH].isCopyEnabled}
        commandToCopy={commandInfo[OAUTH].commandToCopy}
        desiredState={adjustOAuthEnableState()}
        isApplyEnabled
        commandType="configure"
        configParamArray={adjustOAuthEnableParams()}
      />

      <div className={classes.flexRow}>
        <DirectoryIcon />
        <Typography className={classes.header}>Single-Sign-On</Typography>
      </div>
      <Divider />

      <CapabilityStatusDisplay
        capabilityData={capability(SSO_CAPABILITY_NAME)}
        isLoading={isEmpty(selectedConnector)}
      />

      <Typography className={classes.bodyText}>
        Single-Sign-On is a sub-feature of Federated Authentication.
        <br />
        Enable Single-Sign-On (SSO) to allow users to sign in directly to their
        desktop using their credentials from a third-party identity provider.
      </Typography>

      {toggleSwitch(
        'Enable Single-Sign-On',
        'enableSSO',
        configState.enableOauth,
        configState.enableOauth
          ? ''
          : 'You must enable Federated Authentication to enable Single-Sign-On'
      )}

      <Typography className={classes.subheader}>
        SSO Configuration Method
      </Typography>

      <Select
        value={ssoConfigOptions.find(
          (option) => option.value === ssoConfigMethod
        )}
        className={classes.textField}
        // this ensure the menu is on top of the form
        menuPortalTarget={document.body}
        options={ssoConfigOptions}
        isSearchable={false}
        isDisabled={!configState.enableSSO}
        onChange={handleSSOConfigMethodChange}
      />

      {ssoConfigMethod === SSO_CONFIG_WEB_ENROLLMENT && (
        <>
          <Typography className={classes.subheader}>
            Web enrollment service
          </Typography>
          {displayFieldsList(ssoWebEnrollmentParams, !configState.enableSSO)}
        </>
      )}

      {ssoConfigMethod === SSO_CONFIG_CA_CERT && (
        <>
          <Typography className={classes.subheader}>Certificates</Typography>

          <Typography className={classes.bodyText}>
            You must upload 3 files to sign CSR from PCoIP Agent for SSO login:
            <div className={classes.fileRow}>
              <ul>
                <li>
                  CA certificate:
                  {fileSelectedText(ssoCaCertFileName)}
                </li>
                <li>
                  Private key:
                  {fileSelectedText(ssoPrivateKeyFileName)}
                </li>
                <li>
                  Certificate revocation list:
                  {fileSelectedText(ssoCrlFileName)}
                </li>
              </ul>
            </div>
            {ssoError && (
              <Typography className={classes.errorText}>{ssoError}</Typography>
            )}
            Please select each certificate file below, one by one:
          </Typography>

          <FileInputDropbox
            acceptedMimeType="application/x-pem-file"
            acceptedExtensions={[...CERTIFICATES_EXTENSIONS, '.crl']}
            disabled={!configState.enableSSO}
            fileResultCallback={handleSSOCertificateSelection}
          />
        </>
      )}
      <CopyAwcCommand
        isCopyEnabled={commandInfo[SSO].isCopyEnabled}
        commandToCopy={commandInfo[SSO].commandToCopy}
        desiredState={configState}
        isApplyEnabled
        commandType="configure"
        configParamArray={ssoEnableParams.concat(
          ssoConfigMethod === SSO_CONFIG_WEB_ENROLLMENT
            ? ssoWebEnrollmentParams
            : ssoCACertificateParams
        )}
      />
    </Root>
  );
}

export default AuthenticationTab;
