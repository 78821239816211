/* eslint-disable max-lines */
import moment from 'moment-timezone';
import forge from 'node-forge';
import * as pkijs from 'pkijs';
import * as asn1js from 'asn1js';
import validator from 'validator';
import {PEMObject} from 'pem-ts';
import config from 'config';
import {
  AD_GROUPS,
  AMT,
  AMT_POWERSTATE_ON,
  AMT_POWERSTATE_UNKNOWN,
  AWM_DOCUMENTATION_LINK,
  AWM_SAAS_DOCUMENTATION_LINK,
  AWS,
  AZURE,
  AZURE_WORKSTATION_POWERSTATE_DEALLOCATED,
  AZURE_WORKSTATION_POWERSTATE_DEALLOCATING,
  CAPABILITY_DISABLED_STATE,
  CAPABILITY_ENABLED_STATE,
  CAPABILITY_INCOMPLETE_STATE,
  CONNECTORS,
  CONNECTOR_NO_EVENT_TIMEOUT_MS,
  DATE_FORMAT_LIST,
  DEPLOYMENTS,
  EDIT_PAGE_PATHS,
  ENROLLMENT_ADD,
  ENROLLMENT_LINK,
  ENROLLMENT_STATUS_MESSAGE_WORKSTATION_NOT_FOUND,
  ENROLLMENT_UPDATE,
  CONNECTOR_EVENT_TYPE_CONFIGURATION_SUCCESSFUL,
  CONNECTOR_EVENT_TYPE_CONFIGURATION_FAILED,
  FORMAT_AD_GROUP_NEW,
  FORMAT_AD_GROUP_OLD,
  GCP,
  RSM,
  GCP_PROVISIONING_STATE_UNKNOWN,
  MAX_ASSIGNMENT_HOLDING_TIME,
  MONITOR_ENROLLMENTS,
  OS,
  POOLS,
  POOL_GROUPS,
  POOL_MACHINES,
  POOL_USERS,
  READ_ONLY_PAGE_PATHS,
  REMOTE_WORKSTATIONS,
  SESSION_AUDIT,
  TIME_FORMAT_LIST,
  TIME_MULTIPLIER_OPTIONS,
  WORKSTATION_POWERSTATE_RESTARTING,
  WORKSTATION_POWERSTATE_RUNNING,
  WORKSTATION_POWERSTATE_STARTING,
  WORKSTATION_POWERSTATE_STOPPED,
  WORKSTATION_POWERSTATE_STOPPING,
  WORKSTATION_POWERSTATE_UPDATING,
  CONNECTOR_EVENT_TYPE_TIMEOUT,
  ONE_HOUR_IN_MS,
} from './constants';
import CasmCookies from './cookies';
import {loadStateFromLocalStorage} from './localStorage';

export function getMachineMemory(memoryMb) {
  if (memoryMb >= 1000000) {
    return `${(Math.round(memoryMb / 102400) / 10).toFixed(1)} TB`;
  }

  let memory;
  if (memoryMb >= 10000) {
    memory = Math.round(memoryMb / 1024);
  } else {
    memory = Math.round(memoryMb / 102.4) / 10;
  }

  return `${memory} GB`;
}

export function getMachineVCPUs(guestCpus) {
  if (guestCpus === 1) {
    return `${guestCpus} vCPU`;
  }

  return `${guestCpus} vCPUs`;
}

export const getAzureVmSizeDisplayText = (vmSize) => {
  const memory = getMachineMemory(vmSize.ram);
  const vcpus = getMachineVCPUs(vmSize.vCpus);
  return `${vmSize.name} (${vcpus} - ${memory})`;
};

export const getGcpMachineTypeDisplayText = (vmSize) => {
  const memory = getMachineMemory(vmSize.memoryMb);
  const vcpus = getMachineVCPUs(vmSize.guestCpus);
  return `${vmSize.name} (${vcpus} - ${memory})`;
};

export function convertArrayToObject(array, idField) {
  const obj = {};
  if (array && Array.isArray(array)) {
    array.forEach((element, index) => {
      let id;
      if (typeof element !== 'object') {
        // Key element on array index instead
        id = index;
      } else {
        id = element[idField];
      }
      obj[id] = element;
    });
  }
  return obj;
}

export function stripNull(input) {
  return input.replace(/\0/g, '');
}

export function isRegCodeFormat(input) {
  const regex = /^[a-zA-Z0-9]{12}@([a-fA-F0-9]{4}-){3}[a-fA-F0-9]{4}$/;
  return regex.test(input);
}

export function delay(ms) {
  /* eslint-disable no-promise-executor-return */
  return new Promise((_) => setTimeout(_, ms));
}

export function stripTimeValues(inputMoment) {
  return inputMoment.hours(0).minutes(0).seconds(0).milliseconds(0);
}

export function filterByDateQueryParams(
  range,
  startDate = null,
  endDate = null
) {
  const yesterday = moment().subtract(1, 'days');
  const lastMonth = moment().subtract(1, 'months');

  let from = '';
  let to = '';

  switch (range) {
    case 'today':
      from = moment().startOf('day').toISOString();
      to = moment().endOf('day').toISOString();
      break;
    case 'yesterday':
      from = moment(yesterday).startOf('day').toISOString();
      to = moment(yesterday).endOf('day').toISOString();
      break;
    case 'last7Days':
      from = moment().subtract(7, 'days').startOf('day').toISOString();
      to = moment().endOf('day').toISOString();
      break;
    case 'last14Days':
      from = moment().subtract(14, 'days').startOf('day').toISOString();
      to = moment().endOf('day').toISOString();
      break;
    case 'month':
      from = moment().startOf('month').toISOString();
      to = moment().endOf('day').toISOString();
      break;
    case 'lastMonth':
      from = moment(lastMonth).startOf('month').toISOString();
      to = moment(lastMonth).endOf('month').toISOString();
      break;
    case 'custom':
      from = moment(startDate).startOf('day').toISOString();
      to = moment(endDate).endOf('day').toISOString();
      break;
    default:
      break;
  }
  return {from, to};
}

export function setLastLoginDate() {
  const loginObject = {};
  const previousLoginDates = localStorage.getItem('loginDates');
  loginObject.previous = previousLoginDates
    ? JSON.parse(previousLoginDates).current
    : moment().valueOf();
  loginObject.current = moment().valueOf();
  localStorage.setItem('loginDates', JSON.stringify(loginObject));
}

export function getLastLoginDate() {
  let loginDates = localStorage.getItem('loginDates');
  let lastLogin = moment();
  if (loginDates) {
    loginDates = JSON.parse(loginDates);
    lastLogin = moment(loginDates.previous);
  }
  return stripTimeValues(lastLogin);
}

export function formatDateTime(dateTime, includeSeconds = false) {
  if (!dateTime) {
    return '';
  }

  if (!moment(dateTime, moment.ISO_8601).isValid()) {
    return dateTime === '-' ? dateTime : '';
  }

  const reduxState = loadStateFromLocalStorage() || {};
  const userPreferences = reduxState.userPreferences || {};
  const dateFormat = userPreferences.dateFormat || DATE_FORMAT_LIST[0].format;
  const defaultTimeFormat = userPreferences.timeFormat || TIME_FORMAT_LIST[0];
  const timeZone = userPreferences.timeZone || 'local';
  const secondsFormat = includeSeconds ? ':ss' : '';
  const timeFormat = defaultTimeFormat.includes('12')
    ? `hh:mm${secondsFormat} A`
    : `HH:mm${secondsFormat}`;

  const formattedDate = moment(dateTime);
  if (timeZone.includes('local')) {
    formattedDate.tz(moment.tz.guess());
  } else {
    formattedDate.tz(timeZone);
  }

  const outputDate = formattedDate.format(`${dateFormat} ${timeFormat} z`);
  return outputDate;
}

/**
 * Given a date, calculates the time elapsed since that date and returns a human-readable string.
 * Ex. 15 sec ago => 15 seconds ago
 * Ex. 61 min ago => 1 minute ago
 * @param {Date} beforeDate
 * @returns {string} A human-readable string representing the time elapsed since the given date.
 */
export function getElapsedTimeString(beforeDate) {
  const now = new Date().getTime();
  const timeDifference = now - beforeDate.getTime();

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return 'Just now';
  }
  if (minutes === 1) {
    return '1 minute ago';
  }
  if (minutes < 60) {
    return `${minutes} minutes ago`;
  }
  if (hours === 1) {
    return '1 hour ago';
  }
  if (hours < 24) {
    return `${hours} hours ago`;
  }
  if (days === 1) {
    return '1 day ago';
  }
  return `${days} days ago`;
}

export function formatDate(dateTime) {
  const formattedDateTime = formatDateTime(dateTime) || '';
  const [formattedDate] = formattedDateTime.match('\\w{3} \\d+, \\d{4}') || [
    '',
  ];
  return formattedDate;
}

export function formatTime(dateTime) {
  const formattedDateTime = formatDateTime(dateTime) || '';
  const [formattedTime] = formattedDateTime.match('\\d{2}:\\d{2} \\w{2}') || [
    '',
  ];
  return formattedTime[0] === '0' ? formattedTime.slice(1) : formattedTime;
}

export function truncateTimeByStep(time, step) {
  return step * Math.floor(time / step);
}

export function getEarliestTimeInInterval(time, step, interval) {
  return truncateTimeByStep(time, step) - interval;
}

export function convertSecondsToDisplay(totalSeconds) {
  let time = totalSeconds;
  if (!time) {
    return '0 seconds';
  }
  const days = Math.floor(time / 60 / 60 / 24);
  time -= days * 60 * 60 * 24;

  const hours = Math.floor(time / 60 / 60);
  time -= hours * 60 * 60;

  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;

  const dDisplay = days > 0 ? days + (days === 1 ? ' day' : ' days') : '';
  const hDisplay = hours > 0 ? hours + (hours === 1 ? ' hour' : ' hours') : '';
  const mDisplay =
    minutes > 0 ? minutes + (minutes === 1 ? ' minute' : ' minutes') : '';
  const sDisplay =
    seconds > 0 ? seconds + (seconds === 1 ? ' second' : ' seconds') : '';

  if (dDisplay) {
    return [dDisplay, hDisplay].filter((el) => el !== '').join(', ');
  }
  if (hDisplay) {
    return [hDisplay, mDisplay].filter((el) => el !== '').join(', ');
  }
  return [mDisplay, sDisplay].filter((el) => el !== '').join(', ');
}

export function isItemActive(item) {
  if (!item?.status) {
    return true;
  }

  return !['deleting', 'deleted'].includes(item?.status);
}

export function workstationIsRunning(workstation) {
  let powerState = workstation?.powerState || '';

  if (
    workstation?.provider?.toLowerCase().replace(/ /g, '') === AMT &&
    Boolean(workstation?.providerMetadata?.amtPowerState)
  ) {
    powerState = workstation?.providerMetadata?.amtPowerState;
  }

  return [
    WORKSTATION_POWERSTATE_STARTING,
    WORKSTATION_POWERSTATE_RUNNING,
    AMT_POWERSTATE_ON.toLowerCase(),
  ].includes(powerState.toLowerCase());
}

export function workstationIsManaged(workstation) {
  return workstation.managed || false;
}

export function getDeploymentName(deployment) {
  return deployment.deploymentName || deployment.deploymentId;
}

export function getConnectorName(connector) {
  return connector.connectorName || connector.connectorId;
}

export function getWorkstationName(workstation) {
  return workstation.machineName || workstation.machineId;
}

export function getPoolName(pool) {
  return pool.poolName || pool.poolId;
}

export function getPoolUserName(poolUser) {
  return poolUser.userGuid || poolUser.entitlementId;
}

export function getPoolGroupName(poolGroup) {
  return poolGroup.group;
}

export function getAdGroupName(adGroup) {
  return adGroup.groupDN;
}

export function getEnrollmentName(enrollment) {
  const {enrollmentDetails} = enrollment;
  return enrollmentDetails.hostName;
}

export function getItemName(resource, item) {
  switch (resource) {
    case DEPLOYMENTS:
      return getDeploymentName(item);
    case CONNECTORS:
      return getConnectorName(item);
    case REMOTE_WORKSTATIONS:
    case POOL_MACHINES:
    case SESSION_AUDIT:
      return getWorkstationName(item);
    case POOLS:
      return getPoolName(item);
    case POOL_USERS:
      return getPoolUserName(item);
    case POOL_GROUPS:
      return getPoolGroupName(item);
    case AD_GROUPS:
      return getAdGroupName(item);
    case MONITOR_ENROLLMENTS:
      return getEnrollmentName(item);
    default:
      return '';
  }
}

/* Sanitize user entered elements by removing newlines and other undesired characters. */
export const sanitizeValues = (inputText) => {
  if (inputText) {
    return stripNull(inputText).trim();
  }
  return inputText;
};

export function isCertExpired(expiryDate) {
  return !expiryDate || expiryDate === 'unavailable'
    ? true
    : new Date(expiryDate).getTime() < Date.now();
}

export function isCertAboutToExpire(expiryDate) {
  const nextWeek = new Date();
  nextWeek.setDate(nextWeek.getDate() + 7);
  return new Date(expiryDate).getTime() < nextWeek.getTime();
}

export function isCloudProvider(provider) {
  return [AWS, AZURE, GCP, RSM].includes(provider);
}

export function deploymentHasConnectors(connectors, selectedDeployment) {
  if (selectedDeployment) {
    const numConnectorsInDeployment = connectors.filter(
      (connector) => connector.deploymentId === selectedDeployment.deploymentId
    ).length;
    return numConnectorsInDeployment > 0;
  }
  return false;
}

// GCP filter will be removed once Azure RW creation is implemented
export function isCreateWorkstationAvailable(
  connectors,
  cloudServiceAccounts,
  selectedDeployment
) {
  if (selectedDeployment) {
    const numAccountsInDeployment = cloudServiceAccounts
      .filter(
        (account) => account.deploymentId === selectedDeployment.deploymentId
      )
      .filter((account) => [AZURE, GCP].includes(account.provider)).length;
    return (
      numAccountsInDeployment > 0 &&
      deploymentHasConnectors(connectors, selectedDeployment)
    );
  }
  return false;
}

// Connector utility functions

// Given a connector, returns only the components that correspond to a specific status
// item; eg. provides information on the broker while leaving out certificate information
// Also prevents Management Interface from showing up in status as it will soon be deprecated
export function unpackConnectorStatuses(connector) {
  const components = connector.components || {};
  return Object.keys(components)
    .filter(
      (key) =>
        key !== 'gatewayManagementInterface' &&
        components[key].status !== undefined
    )
    .map((key) => ({...components[key], component: key}));
}

// Unpacks the expiry date of a certificate into a date-only string for display, handling
// the case where a host is not provided, or if the expiry date was not well-defined
function formatCertDate(certExpiry) {
  const isAlreadyUnpacked =
    typeof certExpiry === 'string' || certExpiry instanceof Date;
  const dateTime = isAlreadyUnpacked ? certExpiry : certExpiry.expiry_date;
  if (!dateTime || dateTime === 'unavailable') {
    return 'unavailable';
  }
  return formatDate(dateTime);
}

// Maps the expiry date of a certificate to its health status. This will be unhealthy
// if the expiry date is not defined, unavailable, or expired; otherwise it's healthy.
function getCertStatus(certExpiry) {
  return !certExpiry ||
    certExpiry === 'unavailable' ||
    isCertExpired(certExpiry)
    ? 'Unhealthy'
    : 'Healthy';
}

// Packages various certificate information into a concise object
function createCertObject(name, certificate, index) {
  return {
    id: `${
      name.includes('Gateway') ? 'gateway' : 'domain-controller'
    }-${index}`,
    name,
    expiry: formatCertDate(certificate),
    type: name,
    host: certificate.host || '',
    status: getCertStatus(certificate.expiry_date),
  };
}

// Parses a given connector to build an array of certificate objects while leaving out
// any status information (eg. broker, AD) found in its components. The object includes
// a unique ID, the certificate name and type, expiry date, host, and status
export function unpackConnectorCertificates(connector) {
  const components = connector.components || {};
  const {gatewayCertExpiry, dcCertExpiry} = components;
  const result = [];

  const gatewayCerts =
    gatewayCertExpiry instanceof Array
      ? gatewayCertExpiry
      : [gatewayCertExpiry];
  const dcCerts = dcCertExpiry instanceof Array ? dcCertExpiry : [dcCertExpiry];

  [...gatewayCerts, ...dcCerts]
    .filter((item) => Boolean(item))
    .forEach((certificate, index) =>
      result.push(
        createCertObject(
          index < gatewayCerts.length ? 'Gateway' : 'Domain Controller',
          certificate,
          index
        )
      )
    );

  return result;
}

// Given a connector's health log, unpacks the component status information and returns
// the percent of statuses that are successful out of the total
export function calculateConnectorHealthScore(log) {
  if (log) {
    const connector = log.data || {};
    const statuses = unpackConnectorStatuses(connector) || [];
    const numSuccessStatuses = statuses.filter(
      (status) => status.status === 'success'
    ).length;
    return Math.round(100 * (numSuccessStatuses / statuses.length));
  }
  return NaN;
}

export function isEmpty(obj = {}) {
  return (
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length
  );
}

export function isUndefined(value) {
  return typeof value === 'undefined';
}

export function size(obj = {}) {
  return Object.keys(obj).length;
}

export function throttle(func, timeFrame) {
  let lastTime = 0;
  return () => {
    const now = new Date();
    if (now - lastTime >= timeFrame) {
      func();
      lastTime = now;
    }
  };
}

// Given a Anyware Manager destination string, appends mocking argument if mocking is enabled
export function linkTo(destination) {
  return `${destination}`;
}

// helper to encode base64-encoded data objects
export function encodeBase64(data) {
  const jsonData = JSON.stringify(data);
  return window.btoa(jsonData);
}

export function decodeBase64ToJson(encodedData) {
  const decodedData = window.atob(encodedData) || '';
  return JSON.parse(decodedData);
}

// helper to unpack base64-encoded deployment settings
export function unpackDeploymentSetting(connectorSettings, setting) {
  return connectorSettings[setting];
}

// helper to initialize an AD groups object
export function unpackAdGroups(connectorSettings, deploymentId) {
  const adGroups =
    unpackDeploymentSetting(connectorSettings, 'poolsGroups') || [];
  return adGroups.map((adGroup) => {
    // if returned AD group is in the old format, set the GUID as a modified DN.
    // otherwise, set GUID and DN to their proper values
    if (!adGroup.groupGuid) {
      return {
        groupGuid: `guid-${adGroup}`,
        groupDN: adGroup,
        deploymentId,
      };
    }
    return {
      ...adGroup,
      deploymentId,
    };
  });
}

export function isAdGroupOldFormat(adGroup) {
  return typeof adGroup === 'string' || adGroup instanceof String;
}

// AD groups used to be a single string and are now an object. this is
// a helper to create a unified format to use in Anyware
export function buildFrontendAdGroupFormat(adGroup) {
  if (isAdGroupOldFormat(adGroup)) {
    return {
      formatType: FORMAT_AD_GROUP_OLD,
      groupDN: adGroup,
      groupGuid: `guid-${adGroup}`,
    };
  }
  return {
    ...adGroup,
    formatType: FORMAT_AD_GROUP_NEW,
  };
}

export function reverseFrontendAdGroupFormat(adGroup) {
  if (adGroup.formatType === FORMAT_AD_GROUP_OLD) {
    return adGroup.groupDN;
  }
  return {
    groupGuid: adGroup.groupGuid,
    groupDN: adGroup.groupDN,
  };
}

// helper to determine whether an entitlement is for a machine or pool
export function getEntitlementResourceType(entitlement) {
  if (!entitlement || !entitlement.resourceType) {
    return '';
  }

  const resource = entitlement.resourceType;
  const resourceTypes = {
    machine: REMOTE_WORKSTATIONS,
    pool: POOLS,
  };

  return resourceTypes[resource] ? resourceTypes[resource] : '';
}

// helper to map various machine statuses to common statuses for internal logic
export function getMachineStatus(machine) {
  const statusMappings = {
    [AZURE_WORKSTATION_POWERSTATE_DEALLOCATED]: WORKSTATION_POWERSTATE_STOPPED,
    [AZURE_WORKSTATION_POWERSTATE_DEALLOCATING]:
      WORKSTATION_POWERSTATE_STOPPING,
  };

  const status = (machine && machine.status) || '';
  const mappedStatus = statusMappings[status];
  return mappedStatus || status;
}

export const validateMachineName = (machineName, provider) => {
  let isValid = true;
  let errorMessage = '';

  if (typeof provider === 'string') {
    const providersRules = {
      gcp: [
        ['Machine name must begin with a lowercase letter.', /^[a-z].*/],
        [
          'Only numbers, hyphens and lowercase letters are accepted.',
          /^[a-z0-9-]*$/,
        ],
        ['Machine name can not end with a hyphen.', /.*[^-]$/],
        ['Machine name must be at most 15 characters.', /^.{0,15}$/],
      ],
      azure: [
        [
          'Machine name must begin with a letter or a number.',
          /^[a-z0-9A-Z]{1}.*/,
        ],
        ['Machine name must end with a letter or a number.', /.*[a-zA-z0-9]$/],
        ['Machine name can not contain only numbers.', /[^0-9]+/],
        ['Only numbers, hyphens and letters are accepted.', /^[a-zA-Z0-9-]*$/],
        ['Machine name must be at most 15 characters.', /^.{0,15}$/],
      ],
      aws: [['Machine name must be at most 63 characters.', /^.{0,63}$/]],
    };
    const generalRules = [['You must specify a machine name.', /^.+$/]];

    let rules = generalRules;
    if (provider.toLowerCase() in providersRules) {
      rules = [...rules, ...providersRules[provider.toLowerCase()]];
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [message, regex] of rules) {
      if (!regex.test(machineName)) {
        errorMessage = message;
        isValid = false;
        break;
      }
    }
  }
  return {machineNameError: !isValid, machineNameErrorMessage: errorMessage};
};

function userHasScopes(requiredScopes) {
  let tokenScopes;
  let decodedAuthToken;

  const foundScopes = [];
  try {
    decodedAuthToken = CasmCookies.getDecodedAuthToken();
    tokenScopes = decodedAuthToken.scopes;

    requiredScopes.forEach((scope) => {
      const [action, resource] = scope.split(':');
      if (tokenScopes[resource]) {
        if (tokenScopes[resource].actions.includes(action)) {
          foundScopes.push(scope);
        }
      }
    });
  } catch (error) {
    return false;
  }
  return foundScopes.length === requiredScopes.length;
}

export function userCanAccessSamlConfiguration() {
  const requiredScopes = [
    'create:signin_config',
    'update:signin_config',
    'read:signin_config',
    'delete:signin_config',
  ];
  return userHasScopes(requiredScopes);
}

export function userCanAccessAdConfiguration() {
  if (!config.STANDALONE) {
    return false;
  }

  const requiredScopes = ['create:ad_config', 'read:ad_config'];
  return userHasScopes(requiredScopes);
}

export function getDomain() {
  const {href} = window.location;
  const domain = href.split('/')[2];
  return domain;
}

/** Removes any occurrence of the certificate header and footer in the format  ----- ANYTHING ----
 * It also strips spaces and new lines from the certificate
 * * @param {certificate} string The certificate to be processed
 * * @returns Certificate without headers */
export const removeX509PEMCertificateHeader = (certificate) =>
  certificate
    .replace(/(-+[^$-]*-+)/g, '')
    .replace(' ', '')
    .replace('\n', '');

/** Validates the machine hostName when adding a remote workstation
 * Accepts underscores as they are accepted by windows AD.
 * * @param {hostName} string The hostName to be validated
 * * @returns Boolean validation status */
export const validateHostname = (hostName) => {
  if (!hostName) {
    return false;
  }

  if (
    validator.isFQDN(hostName, {
      require_tld: false,
      allow_underscores: true,
      allow_trailing_dot: false,
    })
  ) {
    // If the "valid"FQDN is likely to be an invalid IP, blocks it
    // Ex.: 192.168.1.666 will be blocked here
    if (hostName.match(/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/)) {
      if (!validator.isIP(hostName)) {
        return false;
      }
    }
    return true;
  }

  if (validator.isURL(hostName)) {
    return true;
  }

  if (validator.isIP(hostName)) {
    return true;
  }

  // TODO: Valid if is a valid AD computer hostname
  // (a more relaxed rule might still be a good thing to have, although the backend does not validate it.)

  return false;
};

/** Returns an uniform error message for machine hostNames */
export const hostNameErrorMessage = (hostName) => {
  if (validateHostname(hostName)) {
    return '';
  }
  if (isEmpty(hostName)) {
    return 'Hostname can not be empty';
  }
  return `Not a valid hostname: ${hostName.slice(0, 30)}`;
};

/**
 * Create an options object for functions with default values for unspecified values
 * @param {Object} defaults - Object of default values for options
 * @param {Object} options - Object of options provided to function
 * @returns {Object} An options object to use in a function
 */
export const createOptionsObject = (defaults = {}, options = {}) => ({
  ...defaults,
  ...options,
});

/**
 * Get page type from current pathname
 * @param {String} pathname
 * @returns {String} one of 'edit', 'view' or 'general'
 */
export const getPageTypeByPathname = (pathname) => {
  if (EDIT_PAGE_PATHS.find((p) => pathname.includes(p))) {
    return 'edit';
  }

  if (READ_ONLY_PAGE_PATHS.find((p) => pathname.includes(p))) {
    return 'view';
  }

  return 'general';
};

export const getDocumentationLink = () =>
  config.STANDALONE ? AWM_DOCUMENTATION_LINK : AWM_SAAS_DOCUMENTATION_LINK;

export const isHttpsUrl = (url) => {
  // additional check on the url that bypass URL(...). Ex. new URL('http:/google.ca)
  if (!url.startsWith('https://')) {
    return false;
  }

  let parsedUrl;
  try {
    parsedUrl = new URL(url);
  } catch (_) {
    return false;
  }

  return parsedUrl.protocol === 'https:';
};

export const validateHoldingTime = (holdingTime, timeMultiplier) => {
  const positiveIntegerRegex = /^[1-9]\d*$/; // starts with 1-9 is followed by any digit
  let message = '';
  let messageType = '';
  if (
    holdingTime >= config.FLOATING_POOL_IDLE_MINUTES &&
    holdingTime < 20 &&
    !config.isStandalone() // this will be removed on SaaS after the next AWC release support 5min holding time(TSW-217530)
  ) {
    messageType = 'warning';
    message = (
      <>
        Holding time under 20 minutes is in beta. If Linux Workstations use{' '}
        <a
          href="https://www.teradici.com/web-help/anyware_manager/current/admin_console/workstation_pools/#auto-log-off-service"
          target="_blank"
          rel="noopener noreferrer"
          style={{color: 'currentcolor', textDecoration: 'underline'}}
        >
          Auto Log-Off
        </a>{' '}
        Service, MinutesIdleBeforeLogoff must match the pool holding time.
      </>
    );
  } else if (holdingTime < config.FLOATING_POOL_IDLE_MINUTES) {
    messageType = 'error';
    message = `Assignment holding time cannot be less than ${config.FLOATING_POOL_IDLE_MINUTES} minutes`;
  } else if (holdingTime > MAX_ASSIGNMENT_HOLDING_TIME) {
    messageType = 'error';
    message = `Assignment holding time cannot be greater than ${
      MAX_ASSIGNMENT_HOLDING_TIME / TIME_MULTIPLIER_OPTIONS.Days.value / 365
    } years`;
  }

  if (!positiveIntegerRegex.test(holdingTime / timeMultiplier)) {
    messageType = 'error';
    message = 'Assignment holding time must be a positive integer';
  }
  return {messageType, message};
};

// Removes the property from the object if its value is empty
// eslint-disable-next-line no-unused-vars
export const removeEmptyProperties = (obj) =>
  Object.fromEntries(
    /* eslint-disable no-unused-vars */
    Object.entries(obj).filter(([_, propertyValue]) => {
      // Do not remove explicit false booleans
      if (propertyValue === false) {
        return true;
      }
      return !isEmpty(propertyValue);
    })
  );

export const removeUnwantedProperties = (obj, properties) => {
  const clone = {...obj};
  properties.forEach((key) => delete clone[key]);
  return clone;
};

export function validCloudServiceAccountForWorkstation(
  workstation,
  cloudServiceAccounts
) {
  let validCsa =
    cloudServiceAccounts &&
    !isEmpty(
      cloudServiceAccounts.find(
        (item) =>
          item?.provider?.toLowerCase() ===
          workstation?.provider?.toLowerCase().replace(/ /g, '')
      )
    );

  if (validCsa && workstation?.provider === AMT) {
    validCsa = workstation.hasManagementCredentials;
  }

  return validCsa;
}

export function getAmtWorkstationPowerState(workstation) {
  const updatedWorkstation = workstation;

  if (
    workstation.provider === AMT &&
    workstation.powerState !== AMT_POWERSTATE_UNKNOWN.toLowerCase() &&
    workstation.powerState !== WORKSTATION_POWERSTATE_STARTING.toLowerCase() &&
    workstation.powerState !== WORKSTATION_POWERSTATE_STOPPING.toLowerCase() &&
    workstation.powerState !==
      WORKSTATION_POWERSTATE_RESTARTING.toLowerCase() &&
    Boolean(workstation.providerMetadata?.amtPowerState)
  ) {
    updatedWorkstation.powerState = workstation.providerMetadata?.amtPowerState;
  }

  return updatedWorkstation;
}

export function isChangingPowerState(workstation) {
  return [
    AZURE_WORKSTATION_POWERSTATE_DEALLOCATING,
    WORKSTATION_POWERSTATE_RESTARTING,
    WORKSTATION_POWERSTATE_STOPPING,
    WORKSTATION_POWERSTATE_UPDATING,
    WORKSTATION_POWERSTATE_STARTING,
  ].includes(workstation?.powerState?.toLowerCase());
}

export function powerStateUnknown(workstation) {
  return [
    GCP_PROVISIONING_STATE_UNKNOWN,
    WORKSTATION_POWERSTATE_RESTARTING,
    AMT_POWERSTATE_UNKNOWN,
  ].includes(workstation?.powerState);
}

export function canPerformPowerActions(workstation, cloudServiceAccounts) {
  return (
    isItemActive(workstation) &&
    workstationIsManaged(workstation) &&
    validCloudServiceAccountForWorkstation(workstation, cloudServiceAccounts) &&
    !isChangingPowerState(workstation)
  );
}

export function canStartWorkstation(workstation, cloudServiceAccounts) {
  return (
    isItemActive(workstation) &&
    !workstationIsRunning(workstation) &&
    canPerformPowerActions(workstation, cloudServiceAccounts)
  );
}

export function canStopOrRestartWorkstation(workstation, cloudServiceAccounts) {
  return (
    isItemActive(workstation) &&
    canPerformPowerActions(workstation, cloudServiceAccounts) &&
    workstationIsRunning(workstation)
  );
}

export function canPerformPowerAction(
  workstation,
  cloudServiceAccounts,
  action
) {
  const canFunction = {
    stop: canStopOrRestartWorkstation,
    start: canStartWorkstation,
    restart: canStopOrRestartWorkstation,
  }[action];

  return (
    isItemActive(workstation) &&
    workstationIsManaged(workstation) &&
    validCloudServiceAccountForWorkstation(workstation, cloudServiceAccounts) &&
    canFunction(workstation, cloudServiceAccounts)
  );
}

export const sanitizeCertificate = (certificate) => {
  const cert = certificate;
  const header = '-----BEGIN CERTIFICATE-----';
  const footer = '-----END CERTIFICATE-----';
  // If the certificate file has one or more header or footer
  // It will strip the string that matches the header
  // and strip spaces and new lines from the certificate
  if (cert.includes(header) && cert.includes(footer)) {
    const sanitizedCert = cert
      .substring(cert.indexOf(header) + header.length, cert.lastIndexOf(footer))
      .replace(/ /g, '')
      .replace(/\n/g, '');

    return `${header}\n${sanitizedCert}\n${footer}`;
  }
  const sanitizedCert = cert.replace(/ /g, '').replace(/\n/g, '');
  return `${header}\n${sanitizedCert}\n${footer}`;
};

/**
 * Truncates a string to a maximum length and adds a suffix if truncated.
 * @function
 * @param {string} str - The string to truncate.
 * @param {number} maxLimit - The maximum length of the truncated string.
 * @returns {string} The truncated string with a suffix if truncated.
 */
export const truncateLabelAndAddSuffix = (str, maxLimit) => {
  if (typeof str !== 'string') {
    return '';
  }
  if (typeof maxLimit !== 'number' || Number.isNaN(maxLimit) || maxLimit < 0) {
    return '';
  }

  const previousLength = str ? str.length : 0;
  const truncatedLabel = str ? str.substring(0, maxLimit) : '';
  const actualLength = truncatedLabel.length;
  return previousLength > actualLength
    ? `${truncatedLabel}...`
    : truncatedLabel;
};

/**
 * Calculates the absolute difference in milliseconds between two dates.
 * @function
 * @param {string|Date} startDate - The start date.
 * @param {string|Date} endDate - The end date.
 * @returns {number} The absolute difference in milliseconds.
 */
export const calculateTimeDifference = (startDate, endDate) => {
  const differenceInMs = Math.abs(new Date(endDate) - new Date(startDate));
  return differenceInMs;
};

/**
 * Formats a duration in milliseconds to a human-readable string.
 * @function
 * @param {number} milliseconds - The duration in milliseconds.
 * @returns {string} The formatted duration string.
 */
export const millisecondsToReadableTime = (milliseconds) => {
  const sign = milliseconds <= 0 ? '-' : '';
  const absMilliseconds = Math.abs(milliseconds);
  const hours = Math.floor(absMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((absMilliseconds / 1000 / 60) % 60);
  const seconds = Math.floor((absMilliseconds / 1000) % 60);

  const parts = [];

  if (hours > 0) {
    parts.push(`${hours}hour`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}min`);
  }
  if (seconds > 0) {
    parts.push(`${seconds}s`);
  }

  return sign + parts.join(' ');
};

const readPem = (pemString) => {
  return PEMObject.parse(pemString);
};

export const validateCertificate = (input) => {
  let certs = input;
  if (typeof input === 'string') {
    try {
      certs = readPem(certs);
    } catch (error) {
      return `This is not a valid PEM-encoded X.509 certificate: ${error}`;
    }
  }
  if (!Array.isArray(certs)) {
    return 'Input is not valid';
  }
  if (certs.length === 0) {
    return 'This is not a valid PEM-encoded X.509 certificate';
  }
  for (const cert of certs) {
    if (!cert._label.includes('CERTIFICATE')) {
      return 'This is not a valid PEM-encoded X.509 certificate';
    }
    const ber = asn1js.fromBER(cert.data);
    const certificate = new pkijs.Certificate({schema: ber.result});
    const now = new Date();
    const {notBefore, notAfter} = certificate;
    if (now.getTime() < notBefore.value.getTime()) {
      return `This certificate is not yet valid. It will be valid after ${notBefore.value}`;
    }
    if (now.getTime() > notAfter.value.getTime()) {
      return `This certificate has expired. It was valid before ${notAfter.value}`;
    }
  }
  return '';
};

export const validatePrivateKey = (input) => {
  let keys = input;
  if (typeof keys === 'string') {
    try {
      keys = readPem(keys);
    } catch (error) {
      return `This is not a valid PEM-encoded X.509 key: ${error}`;
    }
  }
  if (!Array.isArray(keys)) {
    return 'Input is not valid';
  }
  if (keys.length === 0) {
    return 'This is not a valid PEM-encoded X.509 key';
  }
  for (const key of keys) {
    if (key._label.includes('KEY')) {
      const ber = asn1js.fromBER(key.data);
      // Throws exception if key is not valid
      try {
        if (key._label.includes('EC')) {
          const priv = new pkijs.ECPrivateKey({schema: ber.result});
          if (priv?.privateKey?.error) {
            return `This is not a valid private key ${priv.privateKey.error}`;
          }
          return '';
        }
        forge.pki.privateKeyFromPem(key.encoded);
        return '';
      } catch (error) {
        return `This is not a valid private key ${error}${
          error.errors ? `${error.errors}` : ``
        }`;
      }
    }
  }
  return 'This is not a valid PEM private key';
};

/**
 * Validates a PEM-encoded X.509 certificate or private key.
 * @param {string} pemString - The PEM-encoded certificate or private key to validate.
 * @returns {string} An error message if the input is not valid, or an empty string if it is valid.
 */
export const validatePEM = (pemString) => {
  let pems;
  try {
    pems = readPem(pemString);
  } catch (error) {
    return `This is not a valid PEM-encoded X.509 certificate or private key: ${error}`;
  }
  if (pems.length === 0) {
    return 'This is not a valid PEM-encoded X.509 certificate or private key';
  }
  for (const cert of pems) {
    if (cert._label.includes('CERTIFICATE')) {
      const result = validateCertificate([cert]);
      if (result) {
        return result;
      }
    } else if (cert._label.includes('KEY')) {
      const result = validatePrivateKey([cert]);
      if (result) {
        return result;
      }
    } else {
      console.log(`Unknown type of PEM: ${cert._label}`);
    }
  }
  return '';
};

// We use PKIJS to validate EDCSA cert+key and node-forge to validate RSA cert+key
export const verifyCertKeyPair = (certificate, privateKey) => {
  try {
    let result = validateCertificate(certificate);
    if (result) {
      throw result;
    }
    result = validatePrivateKey(privateKey);
    if (result) {
      throw result;
    }
    const certs = readPem(certificate);
    const keys = readPem(privateKey);
    let key;
    let cert;
    let isEcc = false;
    for (const k of keys) {
      if (k._label.includes('KEY')) {
        const ber = asn1js.fromBER(k.data);
        if (k._label.includes('EC')) {
          isEcc = true;
          key = new pkijs.ECPrivateKey({schema: ber.result});
        } else {
          isEcc = false;
          key = forge.pki.privateKeyFromPem(k.encoded);
        }
      }
    }
    // Certificate might be chain, check if key matches one of the certs
    for (const c of certs) {
      if (c._label.includes('CERTIFICATE')) {
        const ber = asn1js.fromBER(c.data);
        cert = new pkijs.Certificate({schema: ber.result});
        if (
          isEcc &&
          cert.subjectPublicKeyInfo.parsedKey.toString() ===
            key.publicKey.toString()
        ) {
          return true;
        }
        // Check if private key and public key's RSA Primes are the same
        if (
          !isEcc &&
          cert.subjectPublicKeyInfo.parsedKey
            .toSchema()
            .valueBlock.value[0].valueBlock.toString() === key.n.toString()
        ) {
          return true;
        }
      }
    }

    // No match found
    return false;
  } catch (error) {
    // Any error here indicates the cert-key pair could not encrypt & decrypt a message
    return false;
  }
};

export const enrollmentToActionType = (enrollment) => {
  if (enrollment.matchedMachineId) {
    if (
      enrollment.statusMessage ===
      ENROLLMENT_STATUS_MESSAGE_WORKSTATION_NOT_FOUND
    ) {
      return ENROLLMENT_UPDATE;
    }
    return ENROLLMENT_LINK;
  }
  return ENROLLMENT_ADD;
};

const OS_PROPS = {
  linux: {
    downloadTokenParam: ' download_token={downloadToken}',
    ignoreCertParam: ' ignore_cert=true',
    tokenParam: ' token={token}',
    modeParam: ' mode={mode}',
    skipRegistrationParam: ' skip_registration=true',
    monitorVersionParam: ` monitor_version=${config.ANYWARE_MONITOR_VERSION}`,
    ignoreCertCommand: ' --insecure',
    command:
      'curl{IGNORE_CERT_COMMAND} -1sLfS {scriptUrl} | sudo -E manager_uri={manager_uri} channel={channel}{tokenParam}{ignoreCertParam}{modeParam}{downloadTokenParam}{monitorVersionParam}{skipRegistrationParam} bash',
  },
  windows: {
    downloadTokenParam: ' -download_token {downloadToken}',
    ignoreCertParam: ' -ignore_cert 1',
    tokenParam: ' -token {token}',
    modeParam: ' -mode {mode}',
    skipRegistrationParam: ' -skip_registration 1',
    monitorVersionParam: ` -monitor_version ${config.ANYWARE_MONITOR_VERSION}`,
    ignoreCertCommand:
      "add-type 'using System.Net; using System.Security.Cryptography.X509Certificates; public class TrustAllCertsPolicy : ICertificatePolicy { public bool CheckValidationResult(ServicePoint srvPoint, X509Certificate certificate, WebRequest request, int certificateProblem) { return true; } } ';[System.Net.ServicePointManager]::CertificatePolicy= New-Object TrustAllCertsPolicy;",
    command:
      'powershell.exe -noexit "{IGNORE_CERT_COMMAND}. { Set-Variable ProgressPreference SilentlyContinue; Invoke-WebRequest -useb {scriptUrl} } | Invoke-Expression; install -manager_uri {manager_uri} -channel {channel}{tokenParam}{ignoreCertParam}{modeParam}{downloadTokenParam}{monitorVersionParam}{skipRegistrationParam};exit"',
  },
};

export const getFullRegistrationCommand = ({
  os,
  monitorToken,
  useSecureConnection,
  downloadToken,
  mode = 'register',
  skipRegistration,
}) => {
  let scriptUrl;

  const scriptExtension = os === OS.windows ? 'ps1' : 'sh';

  if (config.ANYWARE_MONITOR_DOWNLOAD_URL) {
    scriptUrl = `${config.ANYWARE_MONITOR_DOWNLOAD_URL.replaceAll(
      '{manager_uri}',
      window.location.origin.toString()
    )}/versions/latest/anyware-monitor_latest.${scriptExtension}`;
  } else {
    const channel = config.getCloudsmithChannel(true);
    const repoName = config.getDownloadRepositoryName(channel);
    scriptUrl = `https://dl.anyware.hp.com/${downloadToken}/${repoName}/raw/names/anyware-monitor-${scriptExtension}/versions/latest/anyware-monitor_latest.${scriptExtension}`;
  }

  return OS_PROPS[os].command
    .replaceAll('{manager_uri}', window.location.origin.toString())
    .replace('{tokenParam}', monitorToken ? OS_PROPS[os].tokenParam : '')
    .replace('{token}', monitorToken)
    .replace(
      '{IGNORE_CERT_COMMAND}',
      useSecureConnection ? '' : OS_PROPS[os].ignoreCertCommand
    )
    .replace('{scriptUrl}', scriptUrl)
    .replace(
      '{ignoreCertParam}',
      useSecureConnection ? '' : OS_PROPS[os].ignoreCertParam
    )
    .replace('{modeParam}', skipRegistration ? '' : OS_PROPS[os].modeParam)
    .replace('{mode}', mode)
    .replace('{channel}', config.getCloudsmithChannel(true))
    .replace(
      '{skipRegistrationParam}',
      skipRegistration ? OS_PROPS[os].skipRegistrationParam : ''
    )
    .replace(
      '{downloadTokenParam}',
      downloadToken === 'CHANGEME' || !downloadToken
        ? ''
        : OS_PROPS[os].downloadTokenParam
    )
    .replace('{downloadToken}', downloadToken)
    .replace(
      '{monitorVersionParam}',
      config.STANDALONE ? OS_PROPS[os].monitorVersionParam : ''
    );
};

export const getInstallCommand = ({
  os,
  downloadToken,
  monitorToken,
  useSecureConnection,
}) => {
  return getFullRegistrationCommand({
    os,
    downloadToken,
    monitorToken,
    useSecureConnection,
  });
};

export const getUpdateCommand = ({os, downloadToken, useSecureConnection}) => {
  return getFullRegistrationCommand({
    os,
    downloadToken,
    skipRegistration: true,
    useSecureConnection,
  });
};

export const getSingleWorkstationSnackbarMessage = (action, workstationName) =>
  `Workstation '${workstationName}' is ${action}.`;

export const getMultipleWorkstationSnackbarMessage = (
  action,
  numberOfWorkstations
) =>
  `${action} ${numberOfWorkstations} workstation${
    numberOfWorkstations === 1 ? '' : 's'
  }`;

export const getSingleEnrollmentSnackbarMessage = (action, enrollmentName) =>
  `Enrollment '${enrollmentName}' ${action}.`;

export const getMultipleEnrollmentSnackbarMessage = (
  action,
  numberOfEnrollments
) =>
  `${action} ${numberOfEnrollments} enrollment${
    numberOfEnrollments === 1 ? '' : 's'
  }`;

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const isLatestMonitorTelemetryValid = (
  monitorTelemetry,
  afterTimestamp = null
) => {
  if (monitorTelemetry?.connectionStatus?.toLowerCase() === 'disconnected') {
    return false;
  }
  return (
    monitorTelemetry &&
    monitorTelemetry.msgReceivedOn &&
    moment(monitorTelemetry.msgReceivedOn).isSameOrAfter(
      afterTimestamp || moment().subtract(10, 'minutes')
    )
  );
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

/**
 * Get a status string for a capability to determine styling & display state
 * @param {Boolean} capabilityEnabled
 * @param {Boolean} enterpriseReadiness
 * @returns {String} A string constant defining the status of the capability
 */
export const getCapabilityStatus = (capabilityEnabled, enterpriseReadiness) => {
  if (!capabilityEnabled) {
    return CAPABILITY_DISABLED_STATE;
  }
  if (enterpriseReadiness) {
    return CAPABILITY_ENABLED_STATE;
  }
  return CAPABILITY_INCOMPLETE_STATE;
};

// Returns the capability with the specified name from the array of capabilities
// passed in as the first parameter. If the capability is not found, undefined is returned.
export const getCapability = (capabilities, capabilityName) => {
  if (!Array.isArray(capabilities)) {
    return undefined;
  }

  const capability = capabilities.find(
    (c) => c.capabilityName === capabilityName
  );
  return capability;
};

/**
 * Checks the events to see if the connector installation has finished
 * Returns one of the following:
 * CONNECTOR_EVENT_TYPE_CONFIGURATION_FAILED
 * CONNECTOR_EVENT_TYPE_CONFIGURATION_SUCCESSFUL
 * CONNECTOR_EVENT_TYPE_TIMEOUT
 * undefined
 * @param {list} events
 * @returns
 */
export function checkEventsInstallStatus(events) {
  const LEGACY_CONNECTOR_EVENT_FAILED = 'Configuration Failed';
  const LEGACY_CONNECTOR_EVENT_SUCCESSFUL = 'Configuration Succeeded';
  if (!Array.isArray(events)) {
    return undefined;
  }

  if (events) {
    let foundEvent = events.find(
      (event) =>
        event.eventType === CONNECTOR_EVENT_TYPE_CONFIGURATION_SUCCESSFUL ||
        event.eventType === CONNECTOR_EVENT_TYPE_CONFIGURATION_FAILED
    );
    if (foundEvent) {
      return foundEvent.eventType;
    }

    // Check timing of last received event to determine if we should display a timeout message
    // Assumes events are already sorted because we requested them in chronological order
    const lastEvent = events.at(-1);
    if (lastEvent) {
      const timeSinceEvent = new Date() - new Date(lastEvent.timestamp);

      // Connector is considered timed out if no events are received for CONNECTOR_NO_EVENT_TIMEOUT_MS
      if (timeSinceEvent >= CONNECTOR_NO_EVENT_TIMEOUT_MS) {
        return CONNECTOR_EVENT_TYPE_TIMEOUT;
      }
    }

    // The event structure was changed for the AWC 23.12 release
    // This is to support the legacy event structure until AWC 23.08 is EOL
    // TODO: Remove this once AWC 23.08 is EOL
    foundEvent = events.find(
      (event) =>
        event.summary === LEGACY_CONNECTOR_EVENT_FAILED ||
        event.summary === LEGACY_CONNECTOR_EVENT_SUCCESSFUL
    );
    if (foundEvent) {
      return foundEvent.summary === LEGACY_CONNECTOR_EVENT_FAILED
        ? CONNECTOR_EVENT_TYPE_CONFIGURATION_FAILED
        : CONNECTOR_EVENT_TYPE_CONFIGURATION_SUCCESSFUL;
    }
  }

  return undefined;
}

/**
 * Checks if the input parameter is a valid domain name
 * Returns true if the input is a valid domain name
 * @param {string} domain
 * @returns {boolean}
 */
export function isDomain(domain) {
  // This regex matches a string that starts with one or more alphanumeric characters, hyphens, or dots, followed by a literal dot, and ending with two or more alphabetical characters. It is  used to validate domain names
  const domainRegex = /^[a-zA-Z0-9-.]+\.[a-zA-Z]{2,}$/;
  return domainRegex.test(domain || '');
}

/**
 * Check if a version string is a valid CACv2 version.
 *
 * @param {string} version - The version string to check.
 * @returns {boolean} True if the version string is a valid CACv2 version (an incrementing number), false otherwise.
 *
 * @example
 * // Returns true
 * connectorIsCACv2('1')
 *
 * @example
 * // Returns false
 * connectorIsCACv2('1.2.3')
 */
export const connectorIsCACv2 = (version) => Number.isInteger(Number(version));

/**
 * Check if a version string is a valid AWC version.
 * @param {string} version - The version string to check.
 * @returns {boolean} True if the version string is a valid AWC version (in the format x.y.z), false otherwise.
 *
 * @example
 * // Returns true
 * connectorIsAWC('1.2.3')
 *
 * @example
 * // Returns false
 * connectorIsAWC('invalid version')
 */
export const connectorIsAWC = (version) => {
  const regex = /^\d+\.\d+\..*$/;
  return regex.test(version);
};

/**
 * Convert an AWC version string to a float.
 * @param {string} version - The AWC version string to convert. The fort is expected to start with major and minor version numbers separated by a period, followed by any other characters.
 * @returns {number} The base version number as a float. Returns 0 if version is not formatted as expected.
 *
 * @example
 * // Returns 23.12
 * awcVersionAsFloat('23.120rc7')
 *
 * @example
 * // Returns 0
 * awcVersionAsFloat('invalid version format')
 */
export const awcVersionAsFloat = (version) => {
  if (!connectorIsAWC(version)) {
    return 0;
  }
  try {
    const versionParts = version.split('.');
    return parseFloat(`${versionParts[0]}.${versionParts[1]}`);
  } catch (error) {
    return 0;
  }
};

/**
 * Checks if the health updates are considered old based on the last update timestamp.
 * @param {string} lastUpdate - The timestamp of the last update.
 * @returns {boolean} - Returns true if the health updates are considered old, false otherwise.
 */
export function healthUpdatesAreOld(lastUpdate) {
  if (!lastUpdate) {
    return false;
  }
  return Date.now() - new Date(lastUpdate).getTime() > ONE_HOUR_IN_MS;
}

export const hostnameFromUrl = (url) => {
  try {
    const path = new URL(url);
    return path.hostname;
  } catch (error) {
    return '';
  }
};

export const webhookCACertKeyFromUrl = (url) => {
  try {
    const hostname = hostnameFromUrl(url);
    if (hostname) {
      return `webhook-trust-ca-${hostname.replaceAll('.', '_').trim()}`;
    }
  } catch (error) {
    return '';
  }
  return '';
};
