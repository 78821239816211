import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';

const PREFIX = 'ExpansionTableHead';

const classes = {
  columnHeaderCell: `${PREFIX}-columnHeaderCell`,
  columnHeaderText: `${PREFIX}-columnHeaderText`
};

const StyledTableHead = styled(TableHead)(() => ({
  [`& .${classes.columnHeaderCell}`]: {
    paddingLeft: '0.75rem',
    paddingRight: '0.25rem',
    zIndex: 1,
  },

  [`& .${classes.columnHeaderText}`]: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  }
}));

function ExpansionTableHead({
  hideBulkActions,
  handleCheckAll,
  allChecked,
  indeterminateChecked,
}) {


  return (
    <StyledTableHead className={classes.tableHeader}>
      <TableRow>
        {!hideBulkActions && (
          <TableCell className={classes.columnHeaderCell} padding="checkbox">
            <Checkbox
              checked={allChecked}
              indeterminate={indeterminateChecked}
              onChange={handleCheckAll}
              color="primary"
            />
          </TableCell>
        )}
        <TableCell className={classes.columnHeaderCell}>
          <TableSortLabel
            data-testid="sort-name"
            className={classes.columnHeaderText}
            disabled
            hideSortIcon
          >
            Name
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
}

ExpansionTableHead.propTypes = {
  hideBulkActions: PropTypes.bool,
  handleCheckAll: PropTypes.func,
  allChecked: PropTypes.bool,
  indeterminateChecked: PropTypes.bool,
};

ExpansionTableHead.defaultProps = {
  hideBulkActions: false,
  handleCheckAll: () => {},
  allChecked: false,
  indeterminateChecked: false,
};

export default ExpansionTableHead;
