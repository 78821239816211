import {useEffect, useState} from 'react';

// Custom hook to handle field validation
// often the getValues must be used within a useCallback to avoid unnecessary re-renders
export const useFieldValidation = (getValues, validationRules) => {
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const values = getValues();

    const newErrors = Object.keys(values).reduce((acc, key) => {
      let error = '';
      if (touched[key]) {
        if (
          validationRules[key] &&
          validationRules[key].required &&
          values[key] === ''
        ) {
          error = 'This field is required';
        } else if (
          validationRules[key] &&
          validationRules[key].maxLength &&
          values[key].length > validationRules[key].maxLength
        ) {
          error = `Length must be between 1 and ${validationRules[key].maxLength} characters.`;
        }
      }
      return {...acc, [key]: error};
    }, {});

    setErrors(newErrors);
  }, [touched, getValues, validationRules]);

  const handleBlur = (field) => () => {
    setTouched({...touched, [field]: true});
  };

  const hasError = (field) => errors[field];

  return {handleBlur, hasError};
};
