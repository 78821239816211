import {styled} from '@mui/material/styles';
import {TextField, Typography} from '@mui/material';

const classes = {
  muiFormHelperTextRoot: 'MuiFormHelperText-root',
  muiError: 'Mui-error',
  muiFormHelperTextContained: 'MuiFormHelperText-contained',
};

export const StyledTextField = styled(TextField)(() => ({
  borderColor: '#C7C9D1',
  backgroundColor: '#F8F8FB',
  borderRadius: '4px',

  [`& .${classes.muiFormHelperTextRoot}`]: {
    backgroundColor: '#f3f4f5',
  },
  [`& .${classes.muiError}`]: {
    backgroundColor: '#f3f4f5',
  },
  [`& .${classes.muiFormHelperTextContained}`]: {
    marginLeft: 0,
    marginRight: 0,
  },
}));

export const StyledLabelText = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '14px',
  color: '#555967',
}));
