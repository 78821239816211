import PropTypes from 'prop-types';

const defaultColor = '#1B1C20';

function VersionIcon({iconColor}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={iconColor}
        d="M18.323 11.4391C18.5573 11.1222 18.5098 10.6599 18.2169 10.4064C17.9239 10.1529 17.4965 10.2043 17.2622 10.5211L15.0192 12.8393L14.1974 11.9504C13.9321 11.6635 13.502 11.6635 13.2368 11.9504C12.9715 12.2373 12.9715 12.7025 13.2368 12.9894L14.4164 14.2653C14.7981 14.6782 15.4268 14.6405 15.764 14.1846L18.323 11.4391Z"
      />
      <path
        fill={iconColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5282 2H20.1132C21.1509 2 22 2.91837 22 4.04082V19.9592C22 21.0816 21.1509 22 20.1132 22H11.5282C10.4905 22 9.64142 21.0816 9.64142 19.9592V4.04082C9.64142 2.91837 10.4905 2 11.5282 2ZM11.5282 19.9592H20.1132V4.04082H11.5282V19.9592Z"
      />
      <path
        fill={iconColor}
        d="M7.56604 3.53061H8.22649V5.57143H7.56604V18.4286H8.22649V20.4694H7.56604C6.5283 20.4694 5.67924 19.551 5.67924 18.4286V5.57143C5.67924 4.44898 6.5283 3.53061 7.56604 3.53061Z"
      />
      <path
        fill={iconColor}
        d="M4.26417 5.06122H3.88681C2.84906 5.06122 2 5.97959 2 7.10204V16.898C2 18.0204 2.84906 18.9388 3.88681 18.9388H4.26417V16.898H3.88681V7.10204H4.26417V5.06122Z"
      />
    </svg>
  );
}

VersionIcon.propTypes = {
  iconColor: PropTypes.string,
};

VersionIcon.defaultProps = {
  iconColor: defaultColor,
};

export default VersionIcon;
