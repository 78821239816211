import {
  OPEN_CONNECTOR_HEALTH_DIALOG,
  CLOSE_CONNECTOR_HEALTH_DIALOG,
} from 'redux/actions/connectorHealthDialogActions';

const defaultState = {
  open: false,
  connector: {
    connectorName: '',
    components: {},
    healthStatus: '',
  },
};

function connectorHealthDialog(state = defaultState, action) {
  switch (action.type) {
    case OPEN_CONNECTOR_HEALTH_DIALOG:
      return {
        open: true,
        connector: action.connector,
      };
    case CLOSE_CONNECTOR_HEALTH_DIALOG:
      return defaultState;
    default:
      return state;
  }
}

export default connectorHealthDialog;
