import {capitalize} from 'helpers/core';
import {sessionsColumns} from 'redux/reducers/tableColumnsReducer';
import config from '../config';
import {
  ACCELERATOR_TYPES,
  ACCEPT_ENROLLMENTS,
  ACTIVITY_LOG,
  ACTIVITY_LOGS,
  ADD_REMOTE_WORKSTATIONS,
  AD_COMPUTERS,
  AD_GROUPS,
  AD_USERS,
  ALL_CONNECTORS,
  ALL_DEPLOYMENTS,
  AMT,
  AMT_AVAILABLE_POWER_OP_TO_HUMAN_READABLE_MAP,
  AMT_POWER_STATE_TO_HUMAN_READABLE_MAP,
  AWM_VERSION_INFO,
  AME,
  AWS,
  AZURE,
  AZURE_LOCATIONS,
  AZURE_SUBNETS,
  AZURE_VM_SIZES,
  AZURE_WORKSTATION_POWERSTATE_DEALLOCATED,
  CLOUD_SERVICE_ACCOUNTS,
  CLOUD_SERVICE_ACCOUNTS_EXPIRY_INFO,
  CONNECTORS,
  CONNECTOR_EVENTS,
  CONNECTOR_EVENTS_LATEST,
  CONNECTORS_TABLE,
  CONNECTOR_HEALTH_LOGS,
  CONNECTOR_SETTINGS,
  CONNECTORS_LINK,
  CREATE_CONNECTOR,
  CREATE_DEPLOYMENT,
  CREATE_POOL,
  CREATE_REMOTE_WORKSTATION,
  DASHBOARD,
  DEFAULT_STATUS,
  DEPLOYMENTS,
  DEPLOYMENTS_TABLE,
  DISK_TYPES,
  EDIT_CONNECTOR,
  EDIT_DEPLOYMENT,
  EDIT_POOL,
  EDIT_REMOTE_WORKSTATION,
  ENROLLMENT_COMMANDS,
  ENTITLEMENTS,
  ERROR_FETCHING_CONNECTORS,
  ERROR_FETCHING_TELEMETRY,
  ERROR_FETCHING_WORKSTATION_TELEMETRY,
  ERROR_STATUS,
  GCP,
  GCP_NETWORKS,
  GCP_REGIONS,
  GCP_SUBNETS,
  GCP_ZONES,
  MACHINE_ENTITLEMENTS,
  MACHINE_MGMT_CREDENTIALS,
  MACHINE_SESSIONS,
  MACHINE_SESSION_ATTEMPTS,
  MACHINE_TYPES,
  MONITOR_ENROLLMENTS,
  MONITOR_LOGOUT_USER,
  MONITOR_NOTIFY_USER,
  MONITOR_TELEMETRY,
  MONITOR_TELEMETRY_LATEST,
  MULTIPLE_CONNECTOR_SETTINGS,
  NO_HEALTHY_CACS,
  NO_WORKSTATION_TELEMETRY,
  OK_STATUS,
  INFO_STATUS,
  ONPREM,
  POLLING_SETTINGS,
  POOLS,
  POOLS_TABLE,
  POOL_ENTITLEMENTS,
  POOL_GROUPS,
  POOL_MACHINES,
  POOL_MACHINES_USERS,
  POOL_USERS,
  REJECT_ENROLLMENTS,
  RELEASE_NOTES,
  REMOTE_WORKSTATIONS,
  REMOTE_WORKSTATIONS_TABLE,
  SAML_CONFIGURATION,
  SESSION_AUDIT,
  TELEMETRY_ACTIVE,
  TELEMETRY_DISABLED,
  TELEMETRY_SETTINGS,
  TEMPLATES,
  UPDATE_ENROLLMENTS,
  USER_PREFERENCES,
  USER_SESSIONS,
  USER_SESSION_ATTEMPTS,
  VIEW_WORKSTATION_USER,
  WARNING_STATUS,
  WORKSTATION_USERS_TABLE,
  SESSION_HISTORY_DISCLAIMER_MESSAGE,
  CONNECTOR_OVERVIEW_TAB_STRING,
  CONNECTOR_INSTALLATION_TAB_STRING,
  CONNECTOR_AD_CONFIG_TAB_STRING,
  CONNECTOR_AUTH_TAB_STRING,
  CONNECTOR_CERT_TAB_STRING,
  CONNECTOR_CONFIG_TAB_STRING,
  CONNECTOR_SETTINGS_TAB_STRING,
  EXISTING_CONNECTOR_TOKEN,
  RSM,
  WEBHOOKS,
} from './constants';
import {createOptionsObject, getItemName} from './utils';

export function mapRemoteWorkstationStatus(
  powerState = '',
  provisioningStatus = {},
  managed = true
) {
  if (!provisioningStatus.state) {
    return capitalize(powerState);
  }

  if (!managed) {
    return 'Unavailable';
  }

  switch (provisioningStatus.state.toLowerCase()) {
    case 'creating':
    case 'running':
      return 'Deploying';
    case 'updating':
      switch (powerState.toLowerCase()) {
        case AZURE_WORKSTATION_POWERSTATE_DEALLOCATED:
          return 'Stopped (Deallocated)';
        case 'starting':
        case 'stopping':
        case 'stopped':
        case 'deallocating':
          return capitalize(powerState);
        default:
          return 'Deploying';
      }
    case 'failed':
      return `${powerState ? capitalize(powerState) : 'Unknown power state'}`;
    case 'succeeded':
    case 'unknown':
      return capitalize(powerState);
    default:
      return 'Unknown';
  }
}

export function mapProviders(provider) {
  if (typeof provider !== 'string') {
    return '-';
  }

  switch (provider.toLowerCase()) {
    case AME:
      return 'AME';
    case AWS:
      return 'AWS';
    case AZURE:
      return 'Azure';
    case GCP:
      return 'GCP';
    case 'intel amt':
    case AMT:
      return 'Intel AMT';
    case RSM:
      return 'Remote System Management';
    case 'private cloud':
    case ONPREM:
    case '-':
      return 'Private Cloud';
    default:
      return '-';
  }
}

export function mapIdentityProviders(provider) {
  if (typeof provider !== 'string') {
    return '-';
  }

  switch (provider.toLowerCase()) {
    case 'azure':
      return 'Microsoft';
    case 'gcp':
    case 'google':
      return 'Google';
    case 'saml':
    case '-':
      return 'SAML';
    default:
      return '-';
  }
}

export function mapProvidersToEnum(provider) {
  if (typeof provider !== 'string') {
    return '-';
  }

  switch (provider.toLowerCase()) {
    case AWS:
      return AWS;
    case AZURE:
      return AZURE;
    case GCP:
      return GCP;
    case 'intel amt':
    case AMT:
      return AMT;
    case 'private cloud':
    case ONPREM:
    case '-':
      return ONPREM;
    default:
      return '-';
  }
}

export function mapResourceToIdField(resource) {
  switch (resource) {
    case ALL_DEPLOYMENTS:
    case DEPLOYMENTS:
    case CONNECTOR_SETTINGS:
    case TELEMETRY_SETTINGS:
    case POLLING_SETTINGS:
      return 'deploymentId';
    case MULTIPLE_CONNECTOR_SETTINGS:
    case ALL_CONNECTORS:
    case CONNECTORS:
      return 'connectorId';
    case REMOTE_WORKSTATIONS:
    case POOL_MACHINES:
    case MACHINE_SESSIONS:
    case MONITOR_TELEMETRY:
    case MONITOR_TELEMETRY_LATEST:
      return 'machineId';
    case AD_COMPUTERS:
      return 'computerName';
    case ACTIVITY_LOGS:
    case CONNECTOR_HEALTH_LOGS:
      return 'logId';
    case POOLS:
      return 'poolId';
    case AD_USERS:
    case POOL_MACHINES_USERS:
      return '_id';
    case ENTITLEMENTS:
    case MACHINE_ENTITLEMENTS:
    case POOL_ENTITLEMENTS:
    case POOL_USERS:
    case POOL_GROUPS:
      return 'entitlementId';
    case CLOUD_SERVICE_ACCOUNTS_EXPIRY_INFO:
      return 'keyId';
    case AZURE_VM_SIZES:
    case ACCELERATOR_TYPES:
    case DISK_TYPES:
    case MACHINE_TYPES:
    case GCP_NETWORKS:
    case GCP_SUBNETS:
    case GCP_REGIONS:
    case GCP_ZONES:
      return 'name';
    case AD_GROUPS:
      return 'groupGuid';
    case SAML_CONFIGURATION:
      return 'configurationId';
    case MACHINE_SESSION_ATTEMPTS:
    case USER_SESSIONS:
    case USER_SESSION_ATTEMPTS:
      return 'pcoipSessionId';
    case MONITOR_ENROLLMENTS:
      return 'enrollmentId';
    case ENROLLMENT_COMMANDS:
      return 'enrollmentKeyId';
    case SESSION_AUDIT:
      return 'pcoipSessionId';
    case CLOUD_SERVICE_ACCOUNTS:
    case TEMPLATES:
    case WEBHOOKS:
    default:
      return 'id';
  }
}

/**
 * Formats resource and path parameters in the correct Anyware API route to call
 * @param {String} resource - Constant referring to resource type in Redux
 * @param {Object} pathParams
 * @param {String=} pathParams.deploymentId
 * @param {String=} pathParams.machineId
 * @param {String=} pathParams.poolId
 * @param {String=} pathParams.connectorId
 * @returns {String} - API route to call based on parameters
 */
export function mapResourceToPath(resource, pathParams = {}) {
  const options = createOptionsObject(
    {
      deploymentId: '',
      machineId: '',
      poolId: '',
      userGuid: '',
      gcpRegion: '',
      enrollmentId: '',
      connectorId: '',
    },
    pathParams
  );
  const {
    deploymentId,
    machineId,
    poolId,
    userGuid,
    gcpRegion,
    enrollmentId,
    connectorId,
  } = options;

  switch (resource) {
    case AZURE_VM_SIZES:
      return 'machines/cloudproviders/azure/vmsizes';
    case AZURE_SUBNETS:
      return 'machines/cloudproviders/azure/subnets';
    case AZURE_LOCATIONS:
      return 'machines/cloudproviders/azure/locations';
    case DEPLOYMENTS:
      return 'deployments/';
    case CONNECTORS:
      return 'deployments/connectors/';
    case CONNECTOR_EVENTS:
      return `deployments/connectors/${connectorId}/events`;
    case CONNECTOR_EVENTS_LATEST:
      return `deployments/connectors/${connectorId}/events/latest`;
    case EXISTING_CONNECTOR_TOKEN:
      return `auth/tokens/connector/${connectorId}`;
    case REMOTE_WORKSTATIONS:
      return 'machines/';
    case MACHINE_MGMT_CREDENTIALS:
      return `machines/${machineId}/management-credentials`;
    case AD_USERS:
      return 'machines/entitlements/adusers';
    case GCP_NETWORKS:
      return 'machines/cloudproviders/gcp/networks';
    case GCP_SUBNETS:
      return `machines/cloudproviders/gcp/regions/${gcpRegion}/subnetworks`;
    case ACTIVITY_LOGS:
    case CONNECTOR_HEALTH_LOGS:
      return 'logs/';
    case POOLS:
      return `deployments/${deploymentId}/pools/`;
    case POOL_MACHINES:
      return `deployments/${deploymentId}/pools/${poolId}/machines`;
    case ENTITLEMENTS:
    case POOL_USERS:
    case POOL_GROUPS:
    case POOL_ENTITLEMENTS:
    case MACHINE_ENTITLEMENTS:
      return `deployments/${deploymentId}/entitlements/`;
    case CLOUD_SERVICE_ACCOUNTS_EXPIRY_INFO:
      return `deployments/${deploymentId}/cloudServiceAccounts/checkExpiration`;
    case POOL_MACHINES_USERS:
      return 'machines/entitlements/adusers';
    case AD_GROUPS:
    case CONNECTOR_SETTINGS:
      return `deployments/${deploymentId}/settings/connectors`;
    case TELEMETRY_SETTINGS:
      return `deployments/${deploymentId}/settings/telemetry`;
    case AD_COMPUTERS:
      return 'machines/entitlements/adcomputers';
    case TEMPLATES:
      return 'resource-templates';
    case SAML_CONFIGURATION:
      return 'auth/saml';
    case MACHINE_SESSIONS:
      return `deployments/${deploymentId}/stats/pcoip/sessions`;
    case MACHINE_SESSION_ATTEMPTS:
      return `deployments/${deploymentId}/stats/pcoip/machines/${machineId}`;
    case USER_SESSIONS:
      return `deployments/${deploymentId}/stats/pcoip/active-sessions`;
    case USER_SESSION_ATTEMPTS:
      return `deployments/${deploymentId}/stats/pcoip/users/${userGuid}`;
    case AWM_VERSION_INFO:
      return 'versionInformation';
    case MONITOR_TELEMETRY:
      return `deployments/${deploymentId}/stats/monitor`;
    case MONITOR_TELEMETRY_LATEST:
      return `deployments/${deploymentId}/stats/monitor/latest`;
    case MONITOR_LOGOUT_USER:
      return `machines/${machineId}/messaging/logout`;
    case MONITOR_NOTIFY_USER:
      return `machines/${machineId}/messaging/notifyUser`;
    case POLLING_SETTINGS:
      return `deployments/${deploymentId}/settings/polling`;
    case MONITOR_ENROLLMENTS:
      return `deployments/${deploymentId}/messaging/enrollments`;
    case ACCEPT_ENROLLMENTS:
      return `deployments/${deploymentId}/messaging/enrollments/${enrollmentId}/accept`;
    case REJECT_ENROLLMENTS:
      return `deployments/${deploymentId}/messaging/enrollments/${enrollmentId}/reject`;
    case UPDATE_ENROLLMENTS:
      return `deployments/${deploymentId}/messaging/enrollments/${enrollmentId}/update`;
    case ENROLLMENT_COMMANDS:
      return 'auth/keys/messaging/enrollment';
    case SESSION_AUDIT:
      return `deployments/${deploymentId}/stats/sessions/history`;
    case WEBHOOKS:
      return `deployments/${deploymentId}/settings/webhooks`;
    default:
      return '/';
  }
}

export function mapResourceToDownloadPath(resource) {
  switch (resource) {
    case ACTIVITY_LOGS:
    case CONNECTOR_HEALTH_LOGS:
      return 'logs/download/';
    default:
      console.log(
        `mapResourceToDownloadPath could not find a path for ${resource}`
      );
      return '/';
  }
}

export function mapResourceToURL(resource) {
  const baseUrl = `${config.GATEWAY_ADDRESS}/api/v1/`;
  const path = mapResourceToPath(resource);
  return `${baseUrl}${path}`;
}

export function mapResourceToText(resource) {
  switch (resource) {
    case DEPLOYMENTS:
      return 'deployment';
    case CONNECTORS:
      return 'connector';
    case REMOTE_WORKSTATIONS:
      return 'remote workstation';
    case AD_USERS:
      return 'ad users';
    case ENTITLEMENTS:
      return 'entitlements';
    case ACTIVITY_LOGS:
      return 'activity log';
    case CONNECTOR_HEALTH_LOGS:
      return 'connector health log';
    case POOLS:
      return 'pool';
    case POOL_MACHINES:
      return 'pool machine';
    case POOL_USERS:
      return 'pool user';
    case POOL_GROUPS:
      return 'pool group';
    case AD_GROUPS:
      return 'AD group';
    default:
      return 'resource';
  }
}

export function mapResourceToTableTitle(resource) {
  switch (resource) {
    case DEPLOYMENTS:
      return 'Deployments';
    case REMOTE_WORKSTATIONS:
      return 'Remote Workstations';
    case CONNECTORS:
      return 'Connectors';
    case AD_USERS:
      return 'Workstation Users';
    case ACTIVITY_LOGS:
      return 'Activity Logs';
    case POOLS:
      return 'Pools';
    case AD_GROUPS:
      return 'User Groups';
    case SESSION_AUDIT:
      return 'Sessions';
    case WEBHOOKS:
      return 'Webhooks';
    default:
      return '';
  }
}

export function mapResourceToSubtitle(resource) {
  switch (resource) {
    case SESSION_AUDIT:
      return !config.isBeta() && SESSION_HISTORY_DISCLAIMER_MESSAGE;
    default:
      return '';
  }
}

export function mapResourceToNameField(resource) {
  switch (resource) {
    case DEPLOYMENTS:
      return 'deploymentName';
    case CONNECTORS:
      return 'connectorName';
    case REMOTE_WORKSTATIONS:
    case POOL_MACHINES:
    case SESSION_AUDIT:
      return 'machineName';
    case ACTIVITY_LOGS:
    case CONNECTOR_HEALTH_LOGS:
      return 'operation';
    case POOLS:
      return 'poolName';
    case MONITOR_ENROLLMENTS:
      return 'hostName';
    case ENROLLMENT_COMMANDS:
      return 'keyName';
    case WEBHOOKS:
    case AD_USERS:
    case POOL_USERS:
    default:
      return 'name';
  }
}
export function mapResourceToStatusField(resource) {
  switch (resource) {
    case SESSION_AUDIT:
      return 'sessionState';
    default:
      return 'status';
  }
}

export const resourceToLabelStatusTypeMap = {
  [SESSION_AUDIT]: {
    Unknown: {
      type: WARNING_STATUS,
      label: 'UNKNOWN',
    },
    'Session-Attempted': {
      type: INFO_STATUS,
      label: 'ATTEMPTED',
    },
    'In-Session': {
      type: OK_STATUS,
      label: 'ACTIVE',
    },
    'Session-Failed': {
      type: ERROR_STATUS,
      label: 'FAILED',
    },
    'Session-Ended': {
      type: DEFAULT_STATUS,
      label: 'ENDED',
    },
  },
};

const userGuidUserPath = {
  fieldName: 'userGuid',
  path: (fieldId, deploymentId) =>
    `/app/adUsers/view/${fieldId}/${deploymentId}`,
};

const resourceToFieldLinkMap = {
  [SESSION_AUDIT]: {
    machineName: {
      fieldName: 'machineId',
      path: (fieldId) => `/app/remoteWorkstations/edit/${fieldId}`,
    },
    connectorId: {
      fieldName: 'connectorId',
      path: (fieldId) => `/app/connectors/edit/${fieldId}`,
    },
    deploymentName: {
      fieldName: 'deploymentId',
      path: (fieldId) => `/app/deployments/edit/${fieldId}`,
    },
    userName: userGuidUserPath,
    userUsername: userGuidUserPath,
    userUpn: userGuidUserPath,
    connectorName: {
      fieldName: 'connectorId',
      path: (fieldId) => `/app/connectors/edit/${fieldId}`,
    },
  },
};
// TABS for BETA EDIT connector page
export const getEditConnectorTabLink = (tab, connectorId) => {
  const validTabs = new Set([
    CONNECTOR_OVERVIEW_TAB_STRING,
    CONNECTOR_INSTALLATION_TAB_STRING,
    CONNECTOR_AD_CONFIG_TAB_STRING,
    CONNECTOR_AUTH_TAB_STRING,
    CONNECTOR_CERT_TAB_STRING,
    CONNECTOR_CONFIG_TAB_STRING,
    CONNECTOR_SETTINGS_TAB_STRING,
  ]);
  if (!validTabs.has(tab)) {
    throw new Error('unknown tab value');
  }
  return `${CONNECTORS_LINK}/edit/${connectorId}/${tab}`;
};

export const resourceToSearchInfoMap = {
  [AD_USERS]: {
    options: [
      {label: 'Filter by Name', value: 'name', placeholder: 'Name'},
      {label: 'Filter by Username', value: 'userName', placeholder: 'Username'},
    ],
    defaultValue: 'name',
    defaultUsePartialSearch: false,
  },
  [SESSION_AUDIT]: {
    options: [
      {
        label: 'Filter by Workstation',
        value: sessionsColumns.machineName.id,
        placeholder: 'Workstation Name',
      },
      {
        label: 'Filter by User Name',
        value: sessionsColumns.userName.id,
        placeholder: 'User Name',
      },
    ],
    defaultValue: sessionsColumns.machineName.id,
    defaultUsePartialSearch: true,
  },
};

/**
 * Maps a field to a link field value for a given resource and row.
 * @function
 * @param {string} resource - The resource to map.
 * @param {string} field - The field to map.
 * @param {Object} row - The row to map.
 * @returns {string} The mapped link field value.
 */
export function mapFieldToLink(resource, field, row) {
  const fieldLink = resourceToFieldLinkMap[resource]?.[field];
  if (fieldLink) {
    const {fieldName} = fieldLink;
    return row[fieldName];
  }
  return row.link;
}

/**
 * Maps a field to a link path for a given resource and row.
 * @function
 * @param {string} resource - The resource to map.
 * @param {string} field - The field to map.
 * @param {Object} row - The row to map.
 * @returns {string} The mapped link path.
 */
export function mapFieldToLinkPath(resource, field, row) {
  const fieldLink = resourceToFieldLinkMap[resource]?.[field];
  if (fieldLink) {
    const {fieldName, path} = fieldLink;
    return path(row[fieldName], row.deploymentId ?? null);
  }
  return row.link;
}

export function mapResourceToBulkDeleteDialogTitle(resource, items = []) {
  const numberOfItems = items.length;
  const actionText = [POOL_MACHINES, POOL_USERS, POOL_GROUPS].includes(resource)
    ? 'Remove'
    : 'Delete';
  return `${actionText} ${numberOfItems} ${mapResourceToText(resource)}${
    numberOfItems !== 1 ? 's' : ''
  }?`;
}

export function mapResourceToBulkDeleteDialogMessage(resource, items = []) {
  const itemNames = items
    .map((item) => getItemName(resource, item))
    .join(',\n');
  const actionText = [POOL_MACHINES, POOL_USERS, POOL_GROUPS].includes(resource)
    ? 'Remove'
    : 'Delete';
  const messageStart = `${actionText} ${itemNames}?`;
  const noUndo = 'This operation cannot be undone.';
  let messageMiddle = '';
  switch (resource) {
    case DEPLOYMENTS:
      messageMiddle =
        'Deleting a deployment also deletes all workstations, connectors, and entitlements associated with that deployment.';
      break;
    case CONNECTORS:
      break;
    case REMOTE_WORKSTATIONS:
      messageMiddle =
        'Deleting a workstation will also delete all of its entitlements.';
      break;
    case POOLS:
      messageMiddle =
        'Deleting a pool will not delete the associated machines or users, it will only remove their entitlements to the pool.';
      break;
    case POOL_MACHINES:
      messageMiddle =
        'Removing a remote workstation will not delete it, it will only remove the workstation from the pool.';
      break;
    case POOL_USERS:
      messageMiddle =
        'Removing a user will not delete their account, it will only remove their entitlement to the pool.';
      break;
    case POOL_GROUPS:
      messageMiddle =
        'Removing a group will revoke access to the pool for users in the group.';
      break;
    default:
      break;
  }

  return `${messageStart} ${messageMiddle} ${noUndo}`;
}

export function mapPathnameToPageTitle(pathname = '') {
  let title = 'Anyware Manager';

  if (pathname.includes('edit')) {
    if (pathname.includes('deployments')) {
      title = EDIT_DEPLOYMENT;
    } else if (pathname.includes('connectors')) {
      title = EDIT_CONNECTOR;
    } else if (pathname.includes('remoteWorkstations')) {
      title = EDIT_REMOTE_WORKSTATION;
      if (pathname.includes('overview')) {
        title = `${title} - Overview`;
      } else if (pathname.includes('sessionInfo')) {
        title = `${title} - Session information`;
      } else if (pathname.includes('userManagement')) {
        title = `${title} - User management`;
      }
    } else if (pathname.includes('pool')) {
      title = EDIT_POOL;
    }
  } else if (pathname.includes('view')) {
    if (pathname.includes('adUsers')) {
      title = VIEW_WORKSTATION_USER;
    }
  } else if (pathname.includes('create')) {
    if (pathname.includes('deployment')) {
      title = CREATE_DEPLOYMENT;
    }
    if (pathname.includes('remoteWorkstations')) {
      title = CREATE_REMOTE_WORKSTATION;
    }
    if (pathname.includes('connectors')) {
      title = CREATE_CONNECTOR;
    }
    if (pathname.includes('pools')) {
      title = CREATE_POOL;
    }
  } else if (pathname.includes('add')) {
    if (pathname.includes('remoteWorkstations')) {
      title = ADD_REMOTE_WORKSTATIONS;
    }
  } else if (pathname.includes('deployments')) {
    title = DEPLOYMENTS_TABLE;
  } else if (pathname.includes('connectors')) {
    title = CONNECTORS_TABLE;
  } else if (pathname.includes('remoteWorkstations')) {
    title = REMOTE_WORKSTATIONS_TABLE;
  } else if (pathname.includes('adUsers')) {
    title = WORKSTATION_USERS_TABLE;
  } else if (pathname.includes('dashboard') || pathname === '/app') {
    title = DASHBOARD;
  } else if (pathname.includes('releaseNotes')) {
    title = RELEASE_NOTES;
  } else if (pathname.includes('preferences')) {
    title = USER_PREFERENCES;
  } else if (pathname.includes('activityLog')) {
    title = ACTIVITY_LOG;
  } else if (pathname.includes('pools')) {
    title = POOLS_TABLE;
  }
  return title;
}

export function mapComponentNameToDisplayName(componentName) {
  switch (componentName) {
    case 'activeDirectory':
      return 'Active Directory';
    case 'broker':
      return 'Broker';
    case 'connectionManager':
      return 'Connection Manager';
    case 'externalBroker':
      return 'External Broker';
    case 'externalConnectionManager':
      return 'External Connection Manager';
    case 'gatewayManagementInterface':
      return 'Gateway Management Interface';
    case 'gatewayPCoIP':
      return 'Gateway PCoIP';
    case 'fedAuth':
      return 'Federated Auth';
    default:
      return componentName;
  }
}

export function mapSamlClaimTypeToDisplay(claimType = '') {
  switch (claimType) {
    case 'groupName':
      return 'Group name';
    case 'groupId':
      return 'Group ID';
    default:
      return claimType;
  }
}

// Ex. iLoveMicrosoft -> I Love Microsoft
export function mapCamelCaseToDisplay(text) {
  return text
    .replace(/([A-Z])/g, (match) => ` ${match}`) // add space between each capital letter
    .replace(/^./, (match) => match.toUpperCase()) // capitalize first letter
    .trim();
}

export function mapStatusToColor(status) {
  switch (status) {
    case ERROR_FETCHING_TELEMETRY:
    case ERROR_FETCHING_CONNECTORS:
    case ERROR_FETCHING_WORKSTATION_TELEMETRY:
      return 'error';
    case TELEMETRY_DISABLED:
    case NO_HEALTHY_CACS:
    case NO_WORKSTATION_TELEMETRY:
      return 'warning';
    case TELEMETRY_ACTIVE:
      return 'success';
    default:
      return 'info';
  }
}

export function mapStatusToText(status) {
  switch (status) {
    case ERROR_FETCHING_TELEMETRY:
      return 'Error fetching telemetry';
    case ERROR_FETCHING_CONNECTORS:
      return 'Error fetching connectors';
    case ERROR_FETCHING_WORKSTATION_TELEMETRY:
      return 'Error fetching workstation telemetry';
    case TELEMETRY_DISABLED:
      return 'Telemetry settings disabled';
    case NO_HEALTHY_CACS:
      return 'No healthy connectors in the deployment';
    case NO_WORKSTATION_TELEMETRY:
      return 'No workstation telemetry in the last 3 hours';
    case TELEMETRY_ACTIVE:
      return 'Active pool telemetry';
    default:
      return 'Unknown telemetry status';
  }
}

export function mapAmtAvailablePowerOperations(powerOperation) {
  const resolvedPowerOperation =
    AMT_AVAILABLE_POWER_OP_TO_HUMAN_READABLE_MAP[powerOperation];

  if (resolvedPowerOperation) {
    return resolvedPowerOperation;
  }

  return powerOperation;
}

export function mapAmtPowerStateToHumanReadable(powerState) {
  const resolvedPowerState = AMT_POWER_STATE_TO_HUMAN_READABLE_MAP[powerState];

  if (resolvedPowerState) {
    return resolvedPowerState;
  }

  return powerState;
}
