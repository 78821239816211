import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';

import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {closeConnectorHealthDialog} from 'redux/actions/connectorHealthDialogActions';
import {
  CAPABILITY_ENABLED_STATE,
  CAPABILITY_INCOMPLETE_STATE,
  CAPABILITY_DISABLED_STATE,
} from 'utils/constants';
import {mapComponentNameToDisplayName} from 'utils/Mappings';
import {
  formatDateTime,
  isEmpty,
  getCapabilityStatus,
  healthUpdatesAreOld,
} from 'utils/utils';
import {
  StatusIconText,
  StatusIcon,
} from 'components/connectors/edit/StatusIconText';
import CAMTooltip from 'components/CAM/display/CAMTooltip/CAMTooltip';
import config from 'config';

const PREFIX = 'ConnectorHealthDialog';

const classes = {
  status: `${PREFIX}-status`,
  itemTitle: `${PREFIX}-itemTitle`,
  healthy: `${PREFIX}-healthy`,
  unhealthy: `${PREFIX}-unhealthy`,
  unknown: `${PREFIX}-unknown`,
  icon: `${PREFIX}-icon`,
  healthyIcon: `${PREFIX}-healthyIcon`,
  unhealthyIcon: `${PREFIX}-unhealthyIcon`,
  button: `${PREFIX}-button`,
  healthyButton: `${PREFIX}-healthyButton`,
  unhealthyButton: `${PREFIX}-unhealthyButton`,
  unknownButton: `${PREFIX}-unknownButton`,
  healthUpdateTime: `${PREFIX}-healthUpdateTime`,
  section: `${PREFIX}-section`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  itemDetailsBlock: `${PREFIX}-itemDetailsBlock`,
  itemStatus: `${PREFIX}-itemStatus`,
  requiredLabel: `${PREFIX}-requiredLabel`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.status}`]: {
    marginBottom: '8px',
  },

  [`& .${classes.itemTitle}`]: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'bold',
    marginLeft: '2rem',
    marginTop: '8px',
  },

  [`& .${classes.healthy}`]: {
    backgroundColor: '#04844B',
    color: 'white',
  },

  [`& .${classes.unhealthy}`]: {
    backgroundColor: '#ff4343',
    color: 'white',
  },

  [`& .${classes.unknown}`]: {
    backgroundColor: '#706E6B',
    color: 'white',
  },

  [`& .${classes.icon}`]: {
    marginLeft: '0.5rem',
  },

  [`& .${classes.healthyIcon}`]: {
    color: '#04844B',
  },

  [`& .${classes.unhealthyIcon}`]: {
    color: '#ff4343',
  },

  [`& .${classes.button}`]: {
    width: '80px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
  },

  [`& .${classes.healthyButton}`]: {
    borderColor: '#04844B',
    '&:hover': {
      backgroundColor: '#04844b6b',
    },
  },

  [`& .${classes.unhealthyButton}`]: {
    borderColor: '#ff4343',
    '&:hover': {
      backgroundColor: '#ffd4d4',
    },
  },

  [`& .${classes.unknownButton}`]: {
    borderColor: '#706E6B',
  },

  [`& .${classes.healthUpdateTime}`]: {
    marginRight: '0.5rem',
  },

  [`& .${classes.section}`]: {
    marginBottom: '1.5rem',
  },

  [`& .${classes.sectionTitle}`]: {
    marginBottom: '0.5rem',
  },

  [`& .${classes.itemDetailsBlock}`]: {
    marginLeft: '2rem',
  },

  [`& .${classes.itemStatus}`]: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'bold',
    marginLeft: '8px',
    marginTop: '18px',
  },

  [`& .${classes.requiredLabel}`]: {
    marginLeft: '12px',
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    color: '#706E6B',
  },
}));

const HealthStatusSectionDiv = styled('div')(() => ({
  [`&.${classes.status}`]: {
    marginBottom: '8px',
  },
  [`& .${classes.itemTitle}`]: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'bold',
    marginLeft: '2rem',
    marginTop: '8px',
  },
  [`& .${classes.itemDetailsBlock}`]: {
    marginLeft: '2rem',
  },
}));

function filterComponentsWithoutStatus(components) {
  const result = {};
  Object.keys(components).forEach((key) => {
    if (components[key].status) {
      result[key] = components[key];
    }
  });

  return result;
}

function getCapabilitiesSectionData(connectorCapabilities) {
  return (
    connectorCapabilities
      .map((capability) => {
        const capData = {};
        capData.capabilityStatus = getCapabilityStatus(
          capability.enabled,
          capability.enterpriseReadiness
        );
        capData.displayName = capability.displayName;
        capData.recommendedActions = capability.recommendedActions;
        return capData;
      })
      // Sort by displayName
      .sort((a, b) => a.displayName.localeCompare(b.displayName))
      .sort((a, b) => {
        // Sort capabilitiesSectionData by capabilityStatus in the following order: Enabled, Incomplete, Disabled
        const statusOrder = {
          [CAPABILITY_ENABLED_STATE]: 0,
          [CAPABILITY_INCOMPLETE_STATE]: 1,
          [CAPABILITY_DISABLED_STATE]: 2,
        };
        return (
          statusOrder[a.capabilityStatus] - statusOrder[b.capabilityStatus]
        );
      })
  );
}

function ConnectorHealthDialog() {
  const dispatch = useDispatch();
  const {connector, open} = useSelector(
    (state) => state?.connectorHealthDialog || false
  );
  const {
    connectorId,
    connectorName,
    components,
    nonCriticalComponents,
    healthStatus,
    healthStatusUpdatedOn,
  } = connector;

  const componentsWithStatus = filterComponentsWithoutStatus(components || {});
  const nonCriticalComponentsWithStatus = filterComponentsWithoutStatus(nonCriticalComponents || {});
  const lastHealthUpdate = formatDateTime(healthStatusUpdatedOn);

  function renderDialogContent() {
    if (isEmpty(componentsWithStatus)) {
      return (
        <Typography variant="h6">No connector health data found</Typography>
      );
    }
    if (!healthStatusUpdatedOn) {
      return (
        <Typography variant="h6">
          No health updates have been received for this connector.
        </Typography>
      );
    }
    if (healthUpdatesAreOld(connector?.healthStatusUpdatedOn)) {
      return (
        <Typography variant="h6">
          {`Connector has not received a health update in over an hour.
           The connector may be in an unhealthy state.`}
        </Typography>
      );
    }

    // Health status to be displayed
    const healthStatusComponent = (components) => Object.keys(components).map((key) => (
      <HealthStatusSectionDiv
        key={`${connectorId}-${key}`}
        className={classes.status}
      >
        <Typography variant="body2" className={classes.itemTitle}>
          {mapComponentNameToDisplayName(key)}
          &nbsp;
          <StatusIcon capabilityStatus={components[key].status} />
        </Typography>
        <div className={classes.itemDetailsBlock}>
          <Typography variant="caption" display="block">
            {components[key].reason}
          </Typography>
        </div>
      </HealthStatusSectionDiv>
    ));

    const healthStatusSection = healthStatusComponent(componentsWithStatus);
    const nonCriticalHealthStatusSection = healthStatusComponent(nonCriticalComponentsWithStatus);

    // Connector capabilities to be displayed
    const connectorCapabilities = connector?.capabilities || [];

    // Usage
    const capabilitiesSectionData = getCapabilitiesSectionData(
      connectorCapabilities
    );
    let latestHealthStatus = null;

    const capabilitiesSection = capabilitiesSectionData?.map((capData) => {
      const {capabilityStatus, displayName, recommendedActions} = capData;

      const sectionElement = (
        <>
          {latestHealthStatus !== capabilityStatus && (
            <Typography variant="body2" className={classes.itemStatus}>
              <StatusIconText capabilityStatus={capabilityStatus} />
              {!isEmpty(recommendedActions) && (
                <Typography variant="caption" className={classes.requiredLabel}>
                  Recommended by HP for production environments
                </Typography>
              )}
              <br />
            </Typography>
          )}

          <Typography variant="body2" className={classes.itemTitle}>
            {displayName}
          </Typography>
          {recommendedActions.map((action) => (
            <div className={classes.itemDetailsBlock}>
              <Typography variant="caption" display="block">
                {action}
              </Typography>
            </div>
          ))}
        </>
      );

      latestHealthStatus = capabilityStatus;
      return sectionElement;
    });

    let elementsToDisplay = [
      [healthStatusSection, 'Health Status', healthStatusSection],
    ];
    if (config.isConnectorWizardEnabled()) {
      elementsToDisplay = [
        [connectorCapabilities, 'Connector Capabilities', capabilitiesSection],
        [healthStatusSection, 'Connection Health Status', healthStatusSection, "Health statuses for connector components related to session connectivity."],
        [nonCriticalHealthStatusSection, 'Other Health Status', nonCriticalHealthStatusSection, "Health statuses for connector components that do not affect session connectivity."],
      ];
    }
    return elementsToDisplay.map(
      ([sectionData, sectionTitle, sectionContent, sectionTooltip]) => {
        if (isEmpty(sectionData)) {
          return '';
        }
        return (
          <div className={classes.section}>
            <Typography variant="h6" className={classes.sectionTitle}>
              {sectionTitle}{!isEmpty(sectionTooltip) && <CAMTooltip text={sectionTooltip}></CAMTooltip>}
            </Typography>
            {sectionContent}
          </div>
        );
      }
    );
  }

  if (!open) return null;

  return (
    <StyledDialog
      open={open}
      onClose={() => dispatch(closeConnectorHealthDialog())}
    >
      <DialogTitle
        className={classNames({
          [classes.healthy]: healthStatus === 'Healthy',
          [classes.unhealthy]: healthStatus === 'Unhealthy',
          [classes.unknown]: healthStatus === 'Unknown',
        })}
      >
        {`Health report for ${connectorName}`}
      </DialogTitle>
      <DialogContent dividers>{renderDialogContent()}</DialogContent>
      <DialogActions>
        <Typography className={classes.healthUpdateTime} variant="caption">
          {!lastHealthUpdate
            ? 'No health update time found'
            : `Last updated on ${lastHealthUpdate}`}
        </Typography>
        <Button
          variant="outlined"
          onClick={() => dispatch(closeConnectorHealthDialog())}
          className={classNames(classes.button, {
            [classes.healthyButton]: healthStatus === 'Healthy',
            [classes.unhealthyButton]: healthStatus === 'Unhealthy',
            [classes.unknownButton]:
              healthStatus === 'Unknown' || !healthStatus,
          })}
        >
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default ConnectorHealthDialog;
