import {
  CLOSE_BULK_ACTIONS_DIALOG,
  OPEN_BULK_ACTIONS_DIALOG,
  LOAD_BULK_ACTIONS_DATA,
  UPDATE_TABLE_BULK_ACTIONS_DIALOG,
} from 'redux/actions/bulkActionsDialogActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';

const defaultState = {
  open: false,
  resource: '',
};

// Opens a new BulkActionsDialog and saves the 'existing items'; ie. the subset
// of data that shouldn't be acted upon (eg. machines that are already in the pool)
function openDialog(state, action) {
  return {
    ...state,
    open: true,
    resource: action.resource,
    [action.resource]: {
      bulkAction: action.bulkAction,
      data: action.data,
      isFetching: action.isFetching,
      total: action.total,
      dialogProps: action.dialogProps,
      tableParams: {
        searchKey: '',
        ...action.defaultParams,
      },
    },
  };
}

// Loads the IDs of fetched resource data into the store
function loadDialogTableData(state, action) {
  return {
    ...state,
    [action.resource]: {
      ...state[action.resource],
      data: action.data,
      total: action.total,
    },
  };
}

// updates the table's state to track GET parameters
function updateDialogTableParams(state, action) {
  return {
    ...state,
    [action.resource]: {
      ...state[action.resource],
      tableParams: action.tableParams,
    },
  };
}

// removes current resource data and closes the dialog
function closeDialog(state, action) {
  const {[action.resource]: omitted, ...restOfState} = state;
  return {
    ...restOfState,
    ...defaultState,
  };
}

function clearDialog() {
  return defaultState;
}

const stateChanges = {
  [OPEN_BULK_ACTIONS_DIALOG]: openDialog,
  [CLOSE_BULK_ACTIONS_DIALOG]: closeDialog,
  [LOAD_BULK_ACTIONS_DATA]: loadDialogTableData,
  [UPDATE_TABLE_BULK_ACTIONS_DIALOG]: updateDialogTableParams,
  [CLEAR_STORE]: clearDialog,
};

function bulkActionsDialog(state = defaultState, action) {
  const stateChange = stateChanges[action.type];
  return stateChange ? stateChange(state, action) : state;
}

export default bulkActionsDialog;
