import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import useSnackbar from 'hooks/useSnackbar';
import {ENROLLMENT_COMMANDS, OS} from 'utils/constants';
import {get} from 'api/Api';
import {getDownloadTokenWithFallback} from 'utils/apiUtils';
import {copyToClipboard, getFullRegistrationCommand} from 'utils/utils';
import {mapResourceToPath} from 'utils/Mappings';
import {selectSelectedDeployment, selectVariable} from 'utils/reduxSelectors';
import {saveVariable} from 'redux/actions/dataActions';
import config from 'config';

const PREFIX = 'EnrollmentCommandsButtons';

const classes = {
  CommandButton: `${PREFIX}-CommandButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.CommandButton}`]: {
    marginRight: '2px',
  }
}));

function EnrollmentCommandsButtons({enrollmentKeyId}) {

  const {successSnackbar, errorSnackbar} = useSnackbar();
  const dispatch = useDispatch();

  const [enrollCommand, setEnrollCommand] = useState('');
  const [OSselected, setOSselected] = useState();
  const [isFetchingOS, setIsFetchingOS] = useState('');

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {isFetching} = useSelector((state) =>
    selectVariable(state, 'enrollmentCommandsInfo')
  ) ?? {isFetching: false};

  const getCopiedMessage = (os) => {
    const capitalizedOs = os.charAt(0).toUpperCase() + os.slice(1);
    const copiedCommandMessage = `The Anyware Monitor enrollment command for ${capitalizedOs} has been copied to your clipboard.`;
    return copiedCommandMessage;
  };

  const setStates = (value, bool) => {
    setIsFetchingOS(value);
    setOSselected(value);
    dispatch(saveVariable('enrollmentCommandsInfo', {isFetching: bool}));
  };

  useEffect(() => {
    if (enrollCommand !== '') {
      try {
        copyToClipboard(enrollCommand);
      } catch {
        errorSnackbar('Failed to copy enrollment command to clipboard.');
        setStates('', false);
      }
      successSnackbar(getCopiedMessage(OSselected));
      setStates('', false);
    }
  }, [enrollCommand]);

  const prepareEnrollmentCommandData = async (value) => {
    setStates(value, true);
    try {
      const [key, downloadToken] = await Promise.all([
        get({
          path: `${mapResourceToPath(ENROLLMENT_COMMANDS)}/${enrollmentKeyId}`,
        }),
        getDownloadTokenWithFallback(
          deploymentId,
          config.getDownloadRepositoryName(config.getCloudsmithChannel(true))
        ),
      ]);
      let enrollmentCommand = '';
      enrollmentCommand = getFullRegistrationCommand({
        os: value,
        monitorToken: key.data.key,
        useSecureConnection: key.data.validateCertificate,
        downloadToken,
        mode: 'enroll',
      });
      setEnrollCommand(enrollmentCommand);
    } catch (error) {
      setStates('', false);
      errorSnackbar('Failed to copy enrollment command to clipboard.');
    }
  };

  return (
    (<Root>
      <CAMButton
        isLoading={isFetchingOS === 'windows'}
        disabled={isFetching}
        customClass={classes.CommandButton}
        testId="windows-copy-enrollment-command"
        buttonText="WINDOWS"
        onClick={() => prepareEnrollmentCommandData(OS.windows)}
      />
      <CAMButton
        isLoading={isFetchingOS === 'linux'}
        disabled={isFetching}
        customClass={classes.CommandButton}
        testId="linux-copy-enrollment-command"
        buttonText="LINUX"
        onClick={() => prepareEnrollmentCommandData(OS.linux)}
      />
    </Root>)
  );
}

EnrollmentCommandsButtons.propTypes = {
  enrollmentKeyId: PropTypes.string,
};

EnrollmentCommandsButtons.defaultProps = {
  enrollmentKeyId: '',
};

export default EnrollmentCommandsButtons;