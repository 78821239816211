import {
  activityLogsColumns,
  adUsersColumns,
  connectorsColumns,
  deploymentColumns,
  pendingMonitorProvisionsColumns,
  poolGroupsColumns,
  poolMachinesColumns,
  poolsColumns,
  poolUsersColumns,
  remoteWorkstationColumns,
  EnrollmentCommandColumns,
  sessionsColumns,
  webhooksColumns,
} from 'redux/reducers/tableColumnsReducer';
import {
  ACTIVITY_LOGS,
  AD_USERS,
  CONNECTORS,
  DEPLOYMENTS,
  MONITOR_ENROLLMENTS,
  POOLS,
  POOL_GROUPS,
  POOL_MACHINES,
  POOL_USERS,
  REMOTE_WORKSTATIONS,
  ENROLLMENT_COMMANDS,
  SESSION_AUDIT,
  WEBHOOKS,
} from './constants';

export function loadStateFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
}

function getColumnsState(defaultColumns, localState) {
  const result = {};

  // if any of the indexes for the columns in localState are undefined, reset the column order
  // if you think of a better solution, please replace this current one :)

  let shouldResetIndexes = false;

  Object.values(localState).forEach((column) => {
    if (typeof column.index === 'undefined') {
      shouldResetIndexes = true;
    }
  });

  Object.keys(defaultColumns).forEach((key) => {
    result[key] = {
      ...defaultColumns[key],
      enabled: localState[key].enabled,
      index: shouldResetIndexes
        ? defaultColumns[key].index
        : localState[key].index,
    };
  });
  return result;
}

export function loadColumnsFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    const localData = JSON.parse(serializedState).tableColumns || {
      [DEPLOYMENTS]: {},
      [CONNECTORS]: {},
      [REMOTE_WORKSTATIONS]: {},
      [MONITOR_ENROLLMENTS]: {},
      [AD_USERS]: {},
    };

    const tableColumns = {
      [DEPLOYMENTS]: getColumnsState(deploymentColumns, localData[DEPLOYMENTS]),
      [CONNECTORS]: getColumnsState(connectorsColumns, localData[CONNECTORS]),
      [REMOTE_WORKSTATIONS]: getColumnsState(
        remoteWorkstationColumns,
        localData[REMOTE_WORKSTATIONS]
      ),
      [AD_USERS]: getColumnsState(adUsersColumns, localData[AD_USERS]),
      [ACTIVITY_LOGS]: getColumnsState(
        activityLogsColumns,
        localData[ACTIVITY_LOGS]
      ),
      [POOLS]: getColumnsState(poolsColumns, localData[POOLS]),
      [POOL_MACHINES]: getColumnsState(
        poolMachinesColumns,
        localData[POOL_MACHINES]
      ),
      [POOL_USERS]: getColumnsState(poolUsersColumns, localData[POOL_USERS]),
      [POOL_GROUPS]: getColumnsState(poolGroupsColumns, localData[POOL_GROUPS]),
      [MONITOR_ENROLLMENTS]: getColumnsState(
        pendingMonitorProvisionsColumns,
        localData[MONITOR_ENROLLMENTS]
      ),
      [ENROLLMENT_COMMANDS]: getColumnsState(
        EnrollmentCommandColumns,
        localData[ENROLLMENT_COMMANDS]
      ),
      [SESSION_AUDIT]: getColumnsState(
        sessionsColumns,
        localData[SESSION_AUDIT]
      ),
      [WEBHOOKS]: getColumnsState(webhooksColumns, localData[WEBHOOKS]),
    };

    return tableColumns;
  } catch (error) {
    return undefined;
  }
}

export function saveStateToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (error) {
    console.error(error);
  }
}
