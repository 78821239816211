import Breadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import AWMBanner from 'components/CAM/display/AWMBanner/AWMBanner';
import GenericLink from 'components/common/GenericLink';
import TokenDialog from 'components/connectors/TokenDialog';
import {ReactComponent as RequirementsIcon} from 'icons/requirements.svg';
import {goBack, push} from 'redux/ReduxHistory';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import {
  CONNECTORS_LINK,
  CONNECTOR_WIZARD_GETTING_STARTED_LINK,
  CREATE_CAC_LINK,
  DOWNLOAD_CAC_LINK,
  ENABLE_EXTERNAL_ACCESS_CAC_LINK,
  INSTALL_CAC_LINK,
  VERIFY_CAC_LINK,
} from 'utils/constants';

const PREFIX = 'CacV2GettingStarted';

const classes = {
  text: `${PREFIX}-text`,
  containerWrap: `${PREFIX}-containerWrap`,
  pageTitle: `${PREFIX}-pageTitle`,
  cardTitle: `${PREFIX}-cardTitle`,
  list: `${PREFIX}-list`,
  connectorLink: `${PREFIX}-connectorLink`,
  currentBreadcrumb: `${PREFIX}-currentBreadcrumb`,
  mainContainer: `${PREFIX}-mainContainer`,
  divider: `${PREFIX}-divider`,
  mainButton: `${PREFIX}-mainButton`,
  button: `${PREFIX}-button`,
  innerTitle: `${PREFIX}-innerTitle`,
  headerTitle: `${PREFIX}-headerTitle`,
  codeBlock: `${PREFIX}-codeBlock`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.text}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`&.${classes.containerWrap}`]: {
    margin: theme.spacing(4),
    maxWidth: '1136px',
    paddingLeft: '96px',
    marginLeft: '0px',
    marginTop: '16px',
  },

  [`& .${classes.pageTitle}`]: {
    ...stylesFromFigma.typographyH4,
    marginTop: '8px',
  },

  [`& .${classes.cardTitle}`]: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
  },

  [`& .${classes.list}`]: {
    listStyleSize: '16px',
    '& li': {
      marginBottom: '10px',
    },
  },

  [`& .${classes.connectorLink}`]: {
    ...stylesFromFigma.typographyBody1,
    color: 'inherit',
  },

  [`& .${classes.currentBreadcrumb}`]: {
    color: 'rgba(0, 0, 0, 0.87)',
  },

  [`& .${classes.mainContainer}`]: {
    padding: '0px',
    border: '0px',
    margin: '0px',
    marginTop: '24px',
  },

  [`& .${classes.divider}`]: {
    marginTop: '18px',
    width: '100%',
  },

  [`& .${classes.mainButton}`]: {
    color: '#FFFFFF',
    background: '#0D47A1',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#0D47A1',
    },
  },

  [`& .${classes.button}`]: {
    color: '#0D47A1',
  },

  [`& .${classes.innerTitle}`]: {
    ...stylesFromFigma.typographyH6,
    marginLeft: '24px',
  },

  [`& .${classes.headerTitle}`]: {
    ...stylesFromFigma.typographyH6,
    marginTop: '32px',
    marginBottom: '32px',
  },

  [`& .${classes.codeBlock}`]: {
    ...stylesFromFigma.codeBlockStyle,
    color: '#000000DE',
  }
}));

function CacV2GettingStarted() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [displayRecommendation, setDisplayRecommendation] = useState(true);

  return (
    <Root className={classes.containerWrap}>
      <Breadcrumbs separator=">">
        <Link
          underline="hover"
          href={CONNECTORS_LINK}
          onClick={(event) => {
            event.preventDefault();
            dispatch(push(CONNECTORS_LINK));
          }}
          className={classes.connectorLink}
        >
          Connectors
        </Link>
        <Typography className={classes.currentBreadcrumb}>
          Add Connector
        </Typography>
      </Breadcrumbs>

      <Typography className={classes.pageTitle}>
        Add Connector (legacy)
      </Typography>

      <Paper className={classes.mainContainer} variant="outlined">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{borderBottom: '1px solid #0000001F', paddingBottom: '24px'}}
        >
          <Grid item>
            <RequirementsIcon alt="Requirements" />
          </Grid>
          <Grid item>
            <Typography className={classes.innerTitle}>
              Pre-installation Checklist
            </Typography>
          </Grid>
        </Grid>

        {displayRecommendation && (
          <div className={classes.outdatedBanner}>
            <AWMBanner
              message="This is a legacy product!"
              details="HP recommends installing the Anyware Connector that runs on RHEL or Rocky Linux."
              tagName="Recommendation"
              variant="warning"
              dataTestId="legacy-connector-banner"
              linkObject={{
                label: 'Switch',
                labelOnClick: () =>
                  dispatch(push(CONNECTOR_WIZARD_GETTING_STARTED_LINK)),
              }}
              onClick={() => setDisplayRecommendation(false)}
              showIcon={false}
            />
          </div>
        )}

        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography
              className={`${classes.headerTitle} ${classes.text} `}
              variant="h6"
            >
              Linux machine requirements
            </Typography>
            <Typography>
              Before adding a new Connector to your Anyware Manager deployment,
              ensure you have the following prerequisites:
            </Typography>
            <Grid>
              <ul className={classes.list}>
                <li>
                  <Typography>
                    A Linux based machine (physical or virtual) - Ubuntu
                    distributions are supported. &nbsp;
                    <GenericLink url={CREATE_CAC_LINK} underline="always">
                      Learn more about supported distributions.
                    </GenericLink>
                  </Typography>
                </li>
                <li>
                  <Typography>
                    The machine must be able to communicate with both your
                    domain and Anyware Manager. &nbsp;
                    <GenericLink url={VERIFY_CAC_LINK} underline="always">
                      Learn more about communication requirements.
                    </GenericLink>
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Administrator can access the machine (eg, SSH or terminal).
                    <br />
                    You will need to run terminal commands on this machine at a
                    later stage.
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid>
            <Typography
              className={`${classes.headerTitle} ${classes.text} `}
              variant="h6"
            >
              Steps to install
            </Typography>

            <ul className={classes.list}>
              <li>
                <Typography>
                  If you want to enable remote access to the workstations
                  without requiring a VPN,&nbsp;
                  <GenericLink
                    url={ENABLE_EXTERNAL_ACCESS_CAC_LINK}
                    underline="always"
                  >
                    configure external access.
                  </GenericLink>
                </Typography>
              </li>

              <li>
                <Typography>
                  Download the &nbsp;
                  <GenericLink url={DOWNLOAD_CAC_LINK} underline="always">
                    Anyware connector installer.
                  </GenericLink>
                </Typography>
              </li>

              <li>
                <Typography>
                  Get the connector token: &nbsp;
                  <br />
                  <Button
                    className={classes.mainButton}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Generate a token
                  </Button>
                </Typography>
              </li>
              <li>
                <Typography>
                  Follow &nbsp;
                  <GenericLink url={INSTALL_CAC_LINK} underline="always">
                    the Anyware connector installation instructions.
                  </GenericLink>
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Paper>
      <Grid container justifyContent="flex-stat">
        <Divider className={classes.divider} />

        <Grid container item xs={6}>
          <Button
            onClick={() => dispatch(goBack())}
            className={classes.button}
            variant="text"
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <TokenDialog open={open} onClose={() => setOpen(false)} />
    </Root>
  );
}

export default CacV2GettingStarted;
