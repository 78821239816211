import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const PREFIX = 'SaveButton';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  buttonSaving: `${PREFIX}-buttonSaving`,
  savingCircularProgress: `${PREFIX}-savingCircularProgress`
};

const StyledButton = styled(Button)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
  },

  [`&.${classes.button}`]: {
    ...theme.createPage.button,
    width: 'auto',
  },

  [`&.${classes.buttonSaving}`]: {
    color: '#0076A9',
  },

  [`& .${classes.savingCircularProgress}`]: {
    marginRight: '10px',
  }
}));

function SaveButton({
  buttonText,
  buttonTextSaving,
  onClick,
  disabled,
  saving,
  dataTestId,
  customClass,
}) {


  return (
    <StyledButton
      data-testid={dataTestId}
      className={classNames(customClass, classes.button, {
        [classes.buttonSaving]: saving,
      })}
      variant={saving || disabled ? 'outlined' : 'contained'}
      onClick={onClick}
      color="primary"
      disabled={disabled || saving}
    >
      {saving && (
        <CircularProgress
          className={classes.savingCircularProgress}
          size={12}
        />
      )}
      {saving ? buttonTextSaving : buttonText}
    </StyledButton>
  );
}

SaveButton.propTypes = {
  disabled: PropTypes.bool,
  saving: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonTextSaving: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  customClass: PropTypes.any,
};

SaveButton.defaultProps = {
  disabled: false,
  saving: false,
  buttonText: 'Save',
  buttonTextSaving: 'Saving',
  dataTestId: 'save-button',
  customClass: null,
};

export default SaveButton;
