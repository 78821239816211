import propTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import SvgIcon from '@mui/material/SvgIcon';
import Divider from '@mui/material/Divider';
import {ReactComponent as CompleteIcon} from 'icons/green_checked_circle.svg';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import {stepIndices, NUM_STEPS} from './wizardConstants';

const PREFIX = 'ConfigureStepCard';

const classes = {
  card: `${PREFIX}-card`,
  cardTitle: `${PREFIX}-cardTitle`,
  cardBody: `${PREFIX}-cardBody`,
  cardContent: `${PREFIX}-cardContent`,
  stepTextRow: `${PREFIX}-stepTextRow`,
  stepTextDivider: `${PREFIX}-stepTextDivider`,
  stepIndicatorText: `${PREFIX}-stepIndicatorText`,
  stepInactiveText: `${PREFIX}-stepInactiveText`,
  stepActiveOptionalText: `${PREFIX}-stepActiveOptionalText`,
  stepCompleteText: `${PREFIX}-stepCompleteText`,
  stepActiveRequiredText: `${PREFIX}-stepActiveRequiredText`,
  activeCardTitleSpacer: `${PREFIX}-activeCardTitleSpacer`,
  inactiveCardTitleSpacer: `${PREFIX}-inactiveCardTitleSpacer`,
  activeCardTitleText: `${PREFIX}-activeCardTitleText`,
  inactiveCardTitleText: `${PREFIX}-inactiveCardTitleText`,
  svgText: `${PREFIX}-svgText`,
  inactiveOptionalDivider: `${PREFIX}-inactiveOptionalDivider`,
  buttonRow: `${PREFIX}-buttonRow`,
  proceedButton: `${PREFIX}-proceedButton`,
  previousButton: `${PREFIX}-previousButton`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => {
  const stepIndicatorText = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.4px',
  };
  const baseTitleText = {
    fontStyle: 'normal',
    letterSpacing: '0.5px',
  };
  return {
    [`& .${classes.card}`]: {
      padding: '24px',
      width: '1136px',
      marginBottom: '12px',
      marginTop: '12px',
    },
    [`& .${classes.cardTitle}`]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    [`& .${classes.cardBody}`]: {
      marginTop: '24px',
      flex: '1',
    },
    [`& .${classes.cardContent}`]: {
      marginLeft: '8px',
      marginRight: '8px',
    },
    [`& .${classes.stepTextRow}`]: {
      display: 'flex',
      height: '20px',
    },
    [`& .${classes.stepIndicatorText}`]: stepIndicatorText,
    [`& .${classes.stepInactiveText}`]: {
      ...stepIndicatorText,
      color: '#8E93A4',
    },
    [`& .${classes.stepActiveOptionalText}`]: {
      ...stepIndicatorText,
      color: '#0081FE',
    },
    [`& .${classes.stepCompleteText}`]: {
      ...stepIndicatorText,
      color: '#22B774',
    },
    [`& .${classes.stepActiveRequiredText}`]: {
      ...stepIndicatorText,
      color: '#FF613D',
    },
    [`& .${classes.activeCardTitleSpacer}`]: {marginLeft: '24px'},
    [`& .${classes.inactiveCardTitleSpacer}`]: {marginLeft: '8px'},
    [`& .${classes.activeCardTitleText}`]: {
      ...baseTitleText,
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '32px',
    },
    [`& .${classes.inactiveCardTitleText}`]: {
      ...baseTitleText,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    [`& .${classes.svgText}`]: {
      fill: '#fff',
      fontSize: '0.75rem',
    },

    [`& .${classes.inactiveOptionalDivider}`]: {background: '#B9DDFF'},

    [`& .${classes.buttonRow}`]: {
      marginBottom: '8px',
    },
    [`& .${classes.proceedButton}`]: {
      marginLeft: '4px',
      marginRight: '4px',
    },

    [`& .${classes.previousButton}`]: {marginLeft: '6px'},
  };
});

const StyledDivider = styled(Divider)(() => ({
  [`&.${classes.stepTextDivider}`]: {
    background: '#0000001f',
    width: '1px',
    margin: '0px 8px',
    height: '100%',
  },
}));

function ConfigureStepCard({
  stepIndex,
  title,
  optional,
  open,
  children,
  onPreviousClick,
  previousDisabled,
  complete,
  onContinueClick,
  continueDisabled,
  onSkipClick,
  icon,
  onTitleClick,
}) {
  const isFirstStep = stepIndex === stepIndices.configureName;
  const isLastStep = stepIndex === stepIndices.generateInstallCommand;

  function StepStatus() {
    let className;
    let statusText;
    if (complete) {
      className = classes.stepCompleteText;
      statusText = 'Done';
    } else if (optional) {
      className = open
        ? classes.stepActiveOptionalText
        : classes.stepInactiveText;
      statusText = 'Optional';
    } else {
      className = open
        ? classes.stepActiveRequiredText
        : classes.stepInactiveText;
      statusText = 'Required';
    }

    return (
      <Typography variant="body2" className={className}>
        {statusText}
      </Typography>
    );
  }

  function IncompleteIcon() {
    return (
      <SvgIcon htmlColor={complete ? '#169F67' : '#5B6071'}>
        <circle cx="12" cy="12" r="12" />
        <text className={classes.svgText} x="12" y="16" textAnchor="middle">
          {stepIndex + 1}
        </text>
      </SvgIcon>
    );
  }

  function StepIndicatorText() {
    return (
      <Grid container item direction="row">
        <StyledDivider className={classes.stepTextDivider} />
        <StepStatus />
      </Grid>
    );
  }

  function StepInformation() {
    return (
      <div className={classes.stepTextRow}>
        <Typography variant="body2" className={classes.stepIndicatorText}>
          Step&nbsp;
          {stepIndex + 1}
          &nbsp;of&nbsp;
          {NUM_STEPS}
        </Typography>
        {!isLastStep && <StepIndicatorText />}
      </div>
    );
  }

  function ActiveCardTitle() {
    return (
      <>
        {icon}
        <div className={classes.activeCardTitleSpacer} />
        <GridN singleColumn spacing={0}>
          <StepInformation />
          <Typography
            variant="h5"
            component="h2"
            className={classes.activeCardTitleText}
          >
            {title}
          </Typography>
        </GridN>
      </>
    );
  }
  function InactiveCardTitle() {
    return (
      <>
        {complete ? <CompleteIcon /> : <IncompleteIcon />}
        <div className={classes.activeCardTitleSpacer} />
        <GridN singleColumn spacing={0}>
          <StepInformation />
          <Typography
            variant="h5"
            component="h2"
            className={classes.inactiveCardTitleText}
          >
            {title}
          </Typography>
        </GridN>
      </>
    );
  }

  function CardTitle() {
    return (
      <div className={classes.cardTitle}>
        {open ? <ActiveCardTitle /> : <InactiveCardTitle />}
      </div>
    );
  }

  const ActiveCardContent = (
    <div className={classes.cardBody}>
      <div className={classes.cardContent}>{children}</div>
      <Grid container className={classes.buttonRow}>
        <Grid container item direction="row" xs={6}>
          <Grid item>
            {!isFirstStep && (
              <CAMButton
                onClick={onPreviousClick}
                disabled={previousDisabled}
                buttonText="Previous"
                variant="text"
                customClass={classes.previousButton}
              />
            )}
          </Grid>
        </Grid>
        <Grid container item direction="row-reverse" xs={6}>
          <Grid item>
            {!isLastStep && (
              <CAMButton
                onClick={onContinueClick}
                buttonText="Continue"
                variant="contained"
                customClass={classes.proceedButton}
                disabled={continueDisabled}
              />
            )}
          </Grid>
          <Grid item>
            {optional && (
              <CAMButton
                onClick={onSkipClick}
                buttonText="Skip this step"
                variant="contained"
                customClass={classes.proceedButton}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Root>
      <Paper className={classes.card} elevation={3} onClick={onTitleClick}>
        <CardTitle />
        {open && ActiveCardContent}
      </Paper>
    </Root>
  );
}

ConfigureStepCard.propTypes = {
  stepIndex: propTypes.number,
  title: propTypes.string,
  optional: propTypes.bool,
  open: propTypes.bool,
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.arrayOf(propTypes.node),
  ]),
  complete: propTypes.bool,
  onContinueClick: propTypes.func,
  continueDisabled: propTypes.bool,
  onPreviousClick: propTypes.func,
  previousDisabled: propTypes.bool,
  onSkipClick: propTypes.func,
  icon: propTypes.element,
  onTitleClick: propTypes.func,
};

ConfigureStepCard.defaultProps = {
  stepIndex: 0,
  title: 'Card title',
  optional: false,
  open: false,
  children: <></>,
  complete: false,
  onContinueClick: () => {},
  continueDisabled: false,
  onPreviousClick: () => {},
  previousDisabled: false,
  onSkipClick: () => {},
  icon: <></>,
  onTitleClick: () => {},
};

export default ConfigureStepCard;
