import PropTypes from 'prop-types';

const defaultColor = '#1B1C20';

function InternalIpIcon({iconColor}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={iconColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.60273 11.2166H7.5659C7.76085 8.5287 8.68157 5.95102 10.2194 3.75514C6.66523 4.51905 3.94298 7.52288 3.60273 11.2166ZM12 3.98916C10.3488 6.05921 9.35153 8.57598 9.13733 11.2166H14.8627C14.6485 8.57598 13.6512 6.05921 12 3.98916ZM14.8627 12.7834C14.6485 15.424 13.6512 17.9408 12 20.0108C10.3488 17.9408 9.35153 15.424 9.13733 12.7834H14.8627ZM7.5659 12.7834H3.60273C3.94298 16.4771 6.66523 19.4809 10.2194 20.2449C8.68157 18.049 7.76085 15.4713 7.5659 12.7834ZM13.7806 20.2449C15.3184 18.049 16.2391 15.4713 16.4341 12.7834H20.3973C20.057 16.4771 17.3348 19.4809 13.7806 20.2449ZM20.3973 11.2166H16.4341C16.2391 8.5287 15.3184 5.95102 13.7806 3.75514C17.3348 4.51905 20.057 7.52288 20.3973 11.2166ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
      />
    </svg>
  );
}

InternalIpIcon.propTypes = {
  iconColor: PropTypes.string,
};

InternalIpIcon.defaultProps = {
  iconColor: defaultColor,
};

export default InternalIpIcon;
