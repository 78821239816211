export const OPEN_BULK_DELETE_WORKSTATIONS_DIALOG =
  'OPEN_BULK_DELETE_WORKSTATIONS_DIALOG';

export function openBulkDeleteWorkstationsDialog(workstations) {
  return {
    type: OPEN_BULK_DELETE_WORKSTATIONS_DIALOG,
    workstations,
  };
}

export const CLOSE_BULK_DELETE_WORKSTATIONS_DIALOG =
  'CLOSE_BULK_DELETE_WORKSTATIONS_DIALOG';

export function closeBulkDeleteWorkstationsDialog() {
  return {
    type: CLOSE_BULK_DELETE_WORKSTATIONS_DIALOG,
  };
}

export const TOGGLE_BULK_DELETE_WORKSTATIONS_FROM_CSP =
  'TOGGLE_BULK_DELETE_WORKSTATIONS_FROM_CSP';

export function toggleBulkDeleteWorkstationsFromCsp(provider) {
  return {
    type: TOGGLE_BULK_DELETE_WORKSTATIONS_FROM_CSP,
    provider,
  };
}
