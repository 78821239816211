import {Grid, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import styled from '@mui/material/styles/styled';
import SearchableSelect from 'components/CAM/inputs/SearchableSelect/SearchableSelect';
import {StyledLabelText} from './StyledTextField';

const commonContainerStyles = {
  borderColor: '#C7C9D1',
  backgroundColor: '#F8F8FB',
  borderRadius: '4px',
  height: '40px',
  width: '100%',
};

const PREFIX = 'SearchableAssociatedPools';

const classes = {
  noteText: `${PREFIX}-noteText`,
};

const Root = styled(Grid)(() => ({
  [`& .${classes.noteText}`]: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#8E93A4',
  },
}));

export default function SearchableAssociatedPools({label, note, ...props}) {
  return (
    <Root container direction="column" gap={0.5}>
      <StyledLabelText className={classes.labelText}>
        {label}
        <Typography variant="body2" color="#8E93A4" component="span">
          {' '}
          (optional)
        </Typography>
      </StyledLabelText>
      <SearchableSelect {...props} className={commonContainerStyles} />
      <Typography className={classes.noteText}>
        <strong>Note:</strong> {note}
      </Typography>
    </Root>
  );
}

SearchableAssociatedPools.propTypes = {
  label: PropTypes.string,
  note: PropTypes.string,
};

SearchableAssociatedPools.defaultProps = {
  label: '',
  note: '',
};
