import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {del, post, put} from 'api/Api';
import ToggleSwitch from 'components/CAM/buttons/ToggleSwitch/ToggleSwitch';
import ProviderIcon from 'components/CAM/icons/ProviderIcon/ProviderIcon';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';
import PollingIntervalSlider from 'components/deployments/PollingIntervalSlider';
import usePendingChanges from 'hooks/usePendingChanges';
import NewElement from 'icons/new_element.svg';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {
  fetchCloudServiceAccounts,
  handleApiError,
  fetchPollingSettings,
} from 'redux/actions/dataActions';
import {
  AMT,
  CLOUD_SERVICE_ACCOUNTS,
  minNegotiableTLSVersion,
  POLLING_INTERVAL_DEFAULT_VALUE,
  POLLING_SETTINGS,
} from 'utils/constants';
import {mapProviders, mapResourceToPath} from 'utils/Mappings';
import {selectData} from 'utils/reduxSelectors';
import {isEmpty, sanitizeCertificate, validateCertificate} from 'utils/utils';
import UploadFileDropbox from '../uploadFileDropbox/UploadFileDropbox';

const PREFIX = 'AmtCredentials';

const classes = {
  textButton: `${PREFIX}-textButton`,
  deleteButton: `${PREFIX}-deleteButton`,
  chevronButton: `${PREFIX}-chevronButton`,
  cloudCredentialContainer: `${PREFIX}-cloudCredentialContainer`,
  dropboxDivideContainer: `${PREFIX}-dropboxDivideContainer`,
  dropboxDivider: `${PREFIX}-dropboxDivider`,
  dropbox: `${PREFIX}-dropbox`,
  dropboxDivideText: `${PREFIX}-dropboxDivideText`,
  cloudCredentialHeader: `${PREFIX}-cloudCredentialHeader`,
  cloudCredentialStatus: `${PREFIX}-cloudCredentialStatus`,
  cloudCredentialTitle: `${PREFIX}-cloudCredentialTitle`,
  cursorPointer: `${PREFIX}-cursorPointer`,
  cursorWait: `${PREFIX}-cursorWait`,
  flexGrow: `${PREFIX}-flexGrow`,
  inputMultiline: `${PREFIX}-inputMultiline`,
  inputTextFieldLabel: `${PREFIX}-inputTextFieldLabel`,
  credentialField: `${PREFIX}-credentialField`,
  divider: `${PREFIX}-divider`,
  displayContainer: `${PREFIX}-displayContainer`,
  primaryContainer: `${PREFIX}-primaryContainer`,
  gridContainer: `${PREFIX}-gridContainer`,
  gridRow: `${PREFIX}-gridRow`,
  stepContainer: `${PREFIX}-stepContainer`,
  buttonRow: `${PREFIX}-buttonRow`,
};

const StyledPaper = styled(Paper)(({theme}) => ({
  [`& .${classes.textButton}`]: {
    fontSize: '0.75rem',
    textTransform: 'none',
    margin: '0px 0px',
    marginTop: '0px',
  },

  [`& .${classes.deleteButton}`]: {
    color: theme.palette.surface.grey,
  },

  [`& .${classes.chevronButton}`]: {padding: 0},

  [`&.${classes.cloudCredentialContainer}`]: {
    marginTop: '7px',
    padding: '0px 10px',
    boxShadow: 'none',
    border: '1px solid #ccc',
  },

  [`& .${classes.dropboxDivideContainer}`]: {
    alignItems: 'center',
    marginTop: '12px',
  },

  [`& .${classes.dropboxDivider}`]: {
    color: theme.palette.divider,
  },

  [`& .${classes.dropbox}`]: {
    width: '100%',
    boxSizing: 'border-box',
    border: '1px solid #DDDBDA',
    borderRadius: '4px',
    padding: '5px 10px',
    marginBottom: '10px',
  },

  [`& .${classes.dropboxDivideText}`]: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '1.25px',
    lineHeight: '1rem',
    textAlign: 'center',
  },

  [`& .${classes.cloudCredentialHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    height: '2.25rem',
    cursor: 'pointer',
  },

  [`& .${classes.cloudCredentialStatus}`]: {
    margin: 0,
    fill: 'rgba(0,0,0,0.54)',
  },

  [`& .${classes.cloudCredentialTitle}`]: {
    flex: 1,
    marginLeft: '12px',
  },

  [`& .${classes.cursorPointer}`]: {cursor: 'pointer'},
  [`& .${classes.cursorWait}`]: {cursor: 'wait'},
  [`& .${classes.flexGrow}`]: {flexGrow: 1},

  [`& .${classes.inputMultiline}`]: {
    padding: '8px 10px',
  },

  [`& .${classes.inputTextFieldLabel}`]: {
    color: theme.palette.info.main,
    fontFamily: '.SF NS Display',
    fontSize: '0.75rem',
    lineHeight: '15px',
    marginTop: '4px',
  },

  [`& .${classes.credentialField}`]: {
    color: 'rgba(0,0,0,0.38)',
    fontFamily: 'Roboto',
    fontWeight: 500,
    height: '35px',
    marginRight: '12px',
    padding: '0px',
  },

  [`& .${classes.divider}`]: {
    backgroundColor: theme.palette.divider,
    marginTop: '12px',
  },

  [`& .${classes.displayContainer}`]: {
    margin: '12px 0px',
  },

  [`& .${classes.primaryContainer}`]: {
    width: '100%',
  },

  [`& .${classes.gridContainer}`]: {
    marginBottom: '10px',
  },

  [`& .${classes.gridRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '10px',
  },

  [`& .${classes.stepContainer}`]: {
    minWidth: '13px',
    overflowY: 'hidden',
  },

  [`& .${classes.buttonRow}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '10px',
  },
}));

const fileInputParameters = {
  maxsize: 5000,
  mimetype: 'application/x-pem-file',
  extensions: ['.pem'],
};

const lowerTLSVersionDesc = `Allow ${minNegotiableTLSVersion} connection protocol for AMT 11 or older.`;

function AmtCredentials({deploymentId}) {
  const dispatch = useDispatch();

  const {setPendingChanges} = usePendingChanges();

  const [credentialId, setCredentialId] = useState('');
  const [caCert, setCaCert] = useState('');
  const [credentialValid, setCredentialValid] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allowOlderTLS, setAllowOlderTLS] = useState(false);
  const [showCredentialField, setShowCredentialField] = useState(false);

  const [certificateErrorMessage, setCertificateErrorMessage] = useState();
  const [certificateValid, setCertificateValid] = useState();

  const clearForm = () => {
    setCaCert('');
    setCredentialId('');
    setCredentialValid(false);
    setCertificateValid();
    setCertificateErrorMessage();
    setAllowOlderTLS(false);
  };

  const {
    data: cloudServiceAccounts,
    isFetching: isFetchingCloudServiceAccounts,
  } = useSelector((state) => selectData(state, CLOUD_SERVICE_ACCOUNTS));

  useEffect(() => {
    setLoading(isFetchingCloudServiceAccounts);
  }, [deploymentId]);

  useEffect(() => {
    if (!isEmpty(caCert)) {
      const errorMessage = validateCertificate(sanitizeCertificate(caCert));

      setCertificateErrorMessage(errorMessage);
      setCertificateValid(errorMessage === '');
    }
  }, [caCert]);

  useEffect(() => {
    if (!isFetchingCloudServiceAccounts) {
      const account =
        cloudServiceAccounts.find((acc) => acc.provider === AMT) || {};
      setCaCert(account.caCert);
      setCredentialId(account.id);
      setAllowOlderTLS(account.minTLSVersion === minNegotiableTLSVersion);

      setCredentialValid(typeof account.id !== 'undefined');
      setLoading(false);
    } else {
      clearForm();
    }
  }, [JSON.stringify(cloudServiceAccounts)]);

  useEffect(() => {
    setLoading(isFetchingCloudServiceAccounts);
  }, [isFetchingCloudServiceAccounts]);

  useEffect(() => {
    // Will have pending changes if certificate is not informed or invalid
    // also if its loading or any of those variables are empty
    const pendingChanges =
      caCert &&
      !loading &&
      !certificateValid &&
      [caCert, certificateValid, credentialId].some(
        (element) => !isEmpty(element)
      );

    setPendingChanges(pendingChanges);
  }, [caCert, loading, certificateValid, credentialId]);

  const receiveCredentialFromFile = (certValue) => {
    setCaCert(sanitizeCertificate(certValue));
  };

  const submitCredential = async () => {
    const path = `deployments/${deploymentId}/cloudServiceAccounts`;

    const credential = {caCert: sanitizeCertificate(caCert)};

    if (allowOlderTLS) {
      credential.minTLSVersion = minNegotiableTLSVersion;
    }

    const {data: response} = await post({
      path,
      data: {
        provider: AMT,
        credential,
      },
    });

    return response;
  };

  const updateCredential = async (minTLSVersion) => {
    const path = `deployments/${deploymentId}/cloudServiceAccounts/${credentialId}`;

    try {
      await put({
        path,
        data: {
          minTLSVersion,
        },
      });
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };

  const setDefaultPollingInterval = async () => {
    const data = {interval: POLLING_INTERVAL_DEFAULT_VALUE};
    const path = mapResourceToPath(POLLING_SETTINGS, {deploymentId});

    try {
      await put({path, data});
      dispatch(fetchPollingSettings());
    } catch (err) {
      handleApiError(err);
    }
  };

  const deleteCredential = async () => {
    // Set the polling interval value to default since AMT provider were deleted
    setDefaultPollingInterval();

    const path = `deployments/${deploymentId}/cloudServiceAccounts/${credentialId}`;

    try {
      await del({path});
      clearForm();
      setDialogMessage('Certificate deleted!');
      dispatch(fetchCloudServiceAccounts({deploymentId}));
      setDialogOpen(true);
    } catch (error) {
      dispatch(handleApiError(error));
    }
  };

  const handleSubmitClick = async () => {
    setLoading(true);

    try {
      const credential = await submitCredential();
      setCredentialId(credential.id);
      setCredentialValid(true);
      setDialogMessage('Certificate added to deployment!');
      dispatch(fetchCloudServiceAccounts({deploymentId}));
      setDialogOpen(true);
    } catch (error) {
      dispatch(handleApiError(error));
    }
    setLoading(false);
  };

  const changeTLSVersionAllowance = () => {
    setAllowOlderTLS(!allowOlderTLS);
    if (credentialValid) {
      // !allowOlderTls is required below instead of allowOlderTls, since the value of allowOlderTls is not updated when we are here
      const minTLSVersion = !allowOlderTLS
        ? minNegotiableTLSVersion
        : 'TLSv1.2';
      updateCredential(minTLSVersion);
    }
  };

  const handleToggleTLS = () => {
    if (!allowOlderTLS) {
      dispatch(
        openDialog(
          'Enable support for AMT 11 or lower?',
          `Are you sure you want to enable support for AMT 11 or lower? Please be aware that this allows the use of ${minNegotiableTLSVersion} protocol, which poses security risks.`,
          changeTLSVersionAllowance
        )
      );
    } else {
      changeTLSVersionAllowance();
    }
  };

  const handleDeleteClick = () => {
    dispatch(
      openDialog(
        'Delete the credentials?',
        "Are you sure you want to delete your provider service account? You won't be able to power manage Intel AMT workstations without it.",
        deleteCredential
      )
    );
  };

  const statusIcon = () => {
    if (loading) {
      return <CircularProgress size={16} />;
    }
    if (credentialValid) {
      return <CheckIcon className={classes.cloudCredentialStatus} />;
    }
    return (
      <img src={NewElement} className={classes.cloudCredentialStatus} alt="" />
    );
  };

  const renderButton = (
    <div className={classes.buttonRow}>
      {credentialValid ? (
        <Button
          data-testid="amt-delete"
          disabled={loading}
          onClick={handleDeleteClick}
          className={classes.deleteButton}
        >
          Delete
        </Button>
      ) : (
        <Button
          data-testid="amt-submit"
          color="primary"
          disabled={!caCert || loading || !certificateValid}
          onClick={handleSubmitClick}
          className={classes.actionButton}
        >
          Submit
        </Button>
      )}
    </div>
  );

  const renderTLSSetting = () => (
    <>
      <Typography className={classes.inputTextFieldLabel}>
        Older TLS version support
      </Typography>
      <div className={classes.dropbox}>
        <div className={classes.gridRow}>
          <InputLabel
            displayText="Enable support for AMT 11 or lower."
            tooltipText={lowerTLSVersionDesc}
          />
        </div>
        <ToggleSwitch
          onClick={handleToggleTLS}
          isOn={allowOlderTLS}
          testId="tls-toggle"
        />
      </div>
    </>
  );
  const renderSlider = (
    <>
      {credentialValid && (
        <>
          <Typography className={classes.inputTextFieldLabel}>
            Polling Interval
          </Typography>
          <div className={classes.gridRow}>
            <PollingIntervalSlider />
          </div>
        </>
      )}
    </>
  );

  const renderCredential = (
    <>
      {renderSlider}
      <Typography className={classes.inputTextFieldLabel}>
        AMT CA Root Certificate
      </Typography>
      <div className={classes.gridRow}>
        <TextField
          data-testid="amt-certificate"
          placeholder="Enter AMT certificate"
          fullWidth
          variant="outlined"
          name="caCert"
          multiline
          maxRows={6}
          disabled={credentialValid}
          value={caCert}
          onChange={(event) => setCaCert(event.target.value)}
          // InputProps={{ {multiline: classes.inputMultiline}}}
          error={!isEmpty(caCert) && !certificateValid}
          helperText={!certificateValid && certificateErrorMessage}
        />
      </div>
      {renderButton}
    </>
  );

  const renderInputFile = (
    <>
      <UploadFileDropbox
        fileInputParameters={fileInputParameters}
        credentialCallback={receiveCredentialFromFile}
      />
      <Grid container className={classes.dropboxDivideContainer}>
        <Grid item xs={5}>
          <Divider className={classes.dropboxDivider} />
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.dropboxDivideText}>OR</Typography>
        </Grid>
        <Grid item xs={5}>
          <Divider className={classes.dropboxDivider} />
        </Grid>
      </Grid>
    </>
  );

  const renderCredentialRow = (
    <div className={classes.gridRow}>
      <div className={classes.primaryContainer}>
        {renderInputFile}
        {renderCredential}
      </div>
    </div>
  );

  const renderDialog = !dialogOpen ? null : (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      data-testid="amt-dialog"
    >
      <DialogContent>
        <DialogContentText
          data-testid="amt-dialog-text"
          variant="h6"
          color="inherit"
        >
          {dialogMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="amt-dialog-button-ok"
          onClick={() => setDialogOpen(false)}
          color="primary"
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderEditCredential = () => (
    <div className={classes.gridContainer}>{renderCredentialRow}</div>
  );

  return (
    <StyledPaper className={classes.cloudCredentialContainer}>
      <div
        role="button"
        data-testid="expand-amt-credential-header"
        className={classNames(classes.cloudCredentialHeader, {
          [classes.cursorWait]: loading,
          [classes.cursorPointer]: !loading,
        })}
        disabled={loading}
        onClick={() => setShowCredentialField(!showCredentialField)}
      >
        {statusIcon()}
        <Typography className={classes.cloudCredentialTitle}>
          <Grid container alignItems="center">
            <ProviderIcon provider={AMT} />
            {mapProviders(AMT)}
          </Grid>
        </Typography>
        <IconButton
          className={classes.chevronButton}
          data-testid="expand-amt-credential-button"
          disableRipple
          disabled={loading}
          onClick={() => setShowCredentialField(!showCredentialField)}
          size="large"
        >
          {showCredentialField ? (
            <KeyboardArrowUp className={classes.chevron} />
          ) : (
            <KeyboardArrowRight className={classes.chevron} />
          )}
        </IconButton>
      </div>

      {showCredentialField &&
        (deploymentId ? (
          <>
            {renderTLSSetting()}
            {!credentialValid && (
              <div className={classes.displayContainer}>
                {renderEditCredential()}
              </div>
            )}
            {credentialValid && (
              <div className={classes.displayContainer}>{renderCredential}</div>
            )}
            {renderDialog}
          </>
        ) : (
          <div className={classes.displayContainer}>
            A deployment must be created before an Intel AMT service account can
            be added.
          </div>
        ))}
    </StyledPaper>
  );
}

AmtCredentials.propTypes = {
  deploymentId: PropTypes.string,
};

AmtCredentials.defaultProps = {
  deploymentId: '',
};

export default AmtCredentials;
