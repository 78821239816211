import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {formatDateTime} from 'utils/utils';
import {SESSION_AUDIT} from 'utils/constants';
import {resourceToLabelStatusTypeMap} from 'utils/Mappings';
import StatusChip from 'components/CAM/display/StatusChip/StatusChip';
import CAMDialog from 'components/CAM/dialogs/CAMDialog/CAMDialog';

const basename = 'SessionEventsDialog';

const classes = {
  tableContainer: `${basename}-tableContainer`,
  tableBody: `${basename}-tableBody`,
  tableHead: `${basename}-tableHead`,
  tableHeadRow: `${basename}-tableHeadRow`,
  tableRow: `${basename}-tableRow`,
};

const Root = styled('div')(() => ({
  [`& .${classes.tableContainer}`]: {
    boxShadow: 'none',
    maxHeight: '400px',
    width: '540px',
  },
  [`& .${classes.tableBody}`]: {
    boxShadow: 'none',
    overflowY: 'auto',
  },
  [`& .${classes.tableHead}`]: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#ffffff',
    zIndex: 1,
  },
  [`& .${classes.tableHeadRow}`]: {
    fontSize: '16px',
    padding: '0px',
  },
  [`& .${classes.tableRow}`]: {
    height: '35px',
    padding: '0px',
  },
}));

function SessionEventsDialog({isOpen, onClose, machineName, sessionEvents}) {
  const handleCloseSessionEventsDialog = () => {
    onClose();
  };

  const sessionStates = (event) => (
    <div className={classes.stateTable}>
      <StatusChip
        label={resourceToLabelStatusTypeMap[SESSION_AUDIT]?.[event.state].label}
        type={resourceToLabelStatusTypeMap[SESSION_AUDIT]?.[event.state].type}
        className={classes.StatusChip}
      />
    </div>
  );

  const tableHead = () => (
    <TableHead className={classes.tableHead}>
      <TableRow className={classes.tableRow}>
        <TableCell className={classes.tableHeadRow}>State</TableCell>
        <TableCell className={classes.tableHeadRow}>Source</TableCell>
        <TableCell className={classes.tableHeadRow}>Time</TableCell>
      </TableRow>
    </TableHead>
  );

  const tableBody = () => (
    <TableBody className={classes.tableBody}>
      {sessionEvents.map((session) => (
        <TableRow className={classes.tableRow}>
          <TableCell className={classes.tableRow}>
            {sessionStates(session)}
          </TableCell>
          <TableCell className={classes.tableRow}>{session.source}</TableCell>
          <TableCell className={classes.tableRow}>
            {formatDateTime(session.time)}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  const table = () => (
    <Root>
      <TableContainer
        component={Paper}
        classes={{root: classes.tableContainer}}
      >
        <Table>
          {sessionEvents.length === 0 ? (
            <Typography>No session events found for {machineName}.</Typography>
          ) : (
            <>
              {tableHead()}
              {tableBody()}
            </>
          )}
        </Table>
      </TableContainer>
    </Root>
  );

  return (
    <CAMDialog
      content={table()}
      open={isOpen}
      onCancel={handleCloseSessionEventsDialog}
      title={`Session events for ${machineName}`}
      cancelLabel="Close"
    />
  );
}

SessionEventsDialog.propTypes = {
  isOpen: PropTypes.bool,
  machineName: PropTypes.string,
  sessionEvents: PropTypes.array,
  onClose: PropTypes.func.isRequired,
};

SessionEventsDialog.defaultProps = {
  isOpen: false,
  machineName: '',
  sessionEvents: [],
};

export default SessionEventsDialog;
