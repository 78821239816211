import {useState, useRef} from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TableContainer from '@mui/material/TableContainer';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import useRoles from 'hooks/useRoles';
import {LEGACY_SA_ROLE, LEGACY_SA_ROLE_PERMISSIONS} from 'utils/constants';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import {stylesFromFigma} from 'themes/stylesFromFigma';
import PermissionTable from './PermissionTable';

const PREFIX = 'RoleDialog';

const classes = {
  bodyText: `${PREFIX}-bodyText`,
  formControl: `${PREFIX}-formControl`,
  grid: `${PREFIX}-grid`,
};

const StyledDialog = styled(Dialog)(({theme}) => ({
  [`& .${classes.bodyText}`]: {
    ...stylesFromFigma.typographyBody1,
  },

  [`& .${classes.formControl}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },

  [`& .${classes.grid}`]: {
    display: 'grid',
  },
}));

function RoleDialog({open, setClosed, roleId, displayOneRole}) {
  const {data: roles} = useRoles();

  // state for dialog
  const [selectedRoleId, setSelectedRoleId] = useState(roleId);
  const roleLabelRef = useRef();
  const roleLabelWidth = roleLabelRef.current
    ? roleLabelRef.current.clientWidth
    : 32;

  const handleChangeRole = (event) => {
    setSelectedRoleId(event.target.value);
  };

  const closeModal = () => {
    setClosed(true);
  };

  let _roles = roles;
  if (!Array.isArray(_roles)) {
    _roles = [];
  }
  if (roleId === LEGACY_SA_ROLE) {
    _roles = JSON.parse(JSON.stringify(_roles)); // deep copy to prevent modification on roles
    _roles.push({
      roleId: LEGACY_SA_ROLE,
      roleName: LEGACY_SA_ROLE,
      type: 'built-in',
      permissions: LEGACY_SA_ROLE_PERMISSIONS,
    });
  }

  const selectedRole = _roles.find((role) => role.roleId === selectedRoleId);
  const dialogContent = (
    <>
      {!displayOneRole && (
        <>
          <div className={classes.bodyText}>
            Role represents a collection of permissions or privileges that
            define the actions an entity can perform within a system
          </div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={roleLabelRef} id="role-label">
              Role
            </InputLabel>
            <Select
              labelId="role-label"
              id="input-select-role"
              value={selectedRoleId}
              onChange={handleChangeRole}
              labelWidth={roleLabelWidth}
              label="Role"
            >
              {_roles.map((role) => (
                <MenuItem key={role.roleId} value={role.roleId}>
                  {role.roleName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}

      {selectedRole && (
        <>
          {selectedRole?.description && (
            <Box mb={1} className={classes.bodyText}>
              {selectedRole?.description}
            </Box>
          )}
          <TableContainer>
            <PermissionTable permissions={selectedRole.permissions} />
          </TableContainer>
        </>
      )}
    </>
  );

  let title = 'Roles';
  if (displayOneRole) {
    title = selectedRole?.roleName || 'Role';
  }

  if (!open) return null;

  return (
    <StyledDialog open={open} fullWidth scroll="paper">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.grid}>{dialogContent}</DialogContent>
      <DialogActions>
        <CAMButton
          onClick={() => {
            closeModal();
          }}
          buttonText="Close"
          testId="dialog-close-button"
        />
      </DialogActions>
    </StyledDialog>
  );
}

RoleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setClosed: PropTypes.func.isRequired,
  roleId: PropTypes.string,
  displayOneRole: PropTypes.bool,
};
RoleDialog.defaultProps = {
  roleId: undefined,
  displayOneRole: false,
};

export default RoleDialog;
