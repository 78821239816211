import KebabMenuItem from 'components/common/kebab-menu/KebabMenuItem';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {
  deleteAdGroups,
  fetchConnectorSettings,
} from 'redux/actions/dataActions';
import {AD_GROUPS} from 'utils/constants';
import {
  selectSelectedDeployment,
  selectSelectedTableResource,
} from 'utils/reduxSelectors';

function AdGroupsBulkActions({onClose, onDelete}) {
  const dispatch = useDispatch();

  const deployment = useSelector((state) => selectSelectedDeployment(state));
  const adGroups = useSelector((state) =>
    selectSelectedTableResource(state, AD_GROUPS)
  );

  const getDeploymentDisplayText = () =>
    deployment && (deployment.deploymentName || deployment.deploymentId);

  const getAdGroupDisplayNames = () =>
    adGroups && (
      <ul>
        {adGroups.map((group) => (
          <li>{`${group.groupDN || group.groupGuid}`}</li>
        ))}
      </ul>
    );

  const getDeleteDialogTitle = () =>
    `Delete ${adGroups.length} AD group${adGroups.length > 1 ? 's' : ''}?`;

  const getDeleteDialogMessage = () => (
    <>
      <div>{`Delete AD groups from deployment ${getDeploymentDisplayText()}? `}</div>
      <br />
      {getAdGroupDisplayNames()}
      <br />
      <div>
        These groups will also be removed from any pool assignments they are
        part of.
      </div>
      <div>This operation cannot be undone.</div>
    </>
  );

  const handleDeleteAdGroups = async () => {
    await dispatch(deleteAdGroups(adGroups));
    onDelete();
    dispatch(fetchConnectorSettings());
  };

  const handleDeleteClick = () => {
    onClose();
    dispatch(
      openDialog(
        getDeleteDialogTitle(),
        getDeleteDialogMessage(),
        handleDeleteAdGroups
      )
    );
  };

  return (
    <KebabMenuItem
      id="bulk-delete-ad-groups"
      dataTestId="bulk-delete-ad-groups"
      menuText="Delete selected"
      onClick={handleDeleteClick}
    />
  );
}

AdGroupsBulkActions.propTypes = {
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

AdGroupsBulkActions.defaultProps = {
  onClose: () => {},
  onDelete: () => {},
};

export default AdGroupsBulkActions;
