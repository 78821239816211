import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {closeDialog} from 'redux/actions/confirmationDialogActions';

const PREFIX = 'ConfirmationDialog';

const classes = {
  backdrop: `${PREFIX}-backdrop`,
  title: `${PREFIX}-title`,
  message: `${PREFIX}-message`,
  warningMessage: `${PREFIX}-warningMessage`,
  button: `${PREFIX}-button`,
  dontShowMessage: `${PREFIX}-dontShowMessage`,
  actionContainer: `${PREFIX}-actionContainer`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.backdrop}`]: {
    backgroundColor: 'rgba(245, 246, 250, 0.3)',
  },

  [`& .${classes.title}`]: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: '24px',
  },

  [`& .${classes.message}`]: {
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '22px',
  },

  [`& .${classes.warningMessage}`]: {
    color: '#C23934',
    marginRight: '14px',
    fontSize: '0.75rem',
    fontFamily: 'Roboto',
    letterSpacing: '0.5px',
    lineHeight: '22px',
  },

  [`& .${classes.button}`]: {
    minWidth: '80px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
  },

  [`& .${classes.dontShowMessage}`]: {
    top: '10px',
    marginRight: 'auto',
  },

  [`& .${classes.actionContainer}`]: {padding: '8px 13px'},
}));

function ConfirmationDialog() {
  const {
    open,
    message,
    handleConfirm,
    title,
    cancelEnabled,
    handleCancel,
    displayDontShowAgainCheckbox,
    handleCheckboxChange,
    confirmationText,
    warningMessage,
  } = useSelector((state) => state.confirmationDialog);

  const dispatch = useDispatch();

  const onConfirm = useCallback(() => {
    handleConfirm();
    dispatch(closeDialog());
  }, [handleConfirm]);

  const onCancel = useCallback(
    (event, reason) => {
      if (handleCancel && reason !== 'backdropClick' && cancelEnabled) {
        handleCancel();
        dispatch(closeDialog());
      }
    },
    [handleCancel]
  );

  const onCheckboxChange = useCallback(
    (event) => {
      const {checked} = event.target;
      handleCheckboxChange(checked);
    },
    [handleCheckboxChange]
  );

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onConfirm();
    }
  };

  function renderDontShowAgainCheckbox() {
    if (displayDontShowAgainCheckbox) {
      return (
        <div className={classes.dontShowMessage}>
          <Checkbox
            color="primary"
            inputProps={{
              'data-testid': 'do-not-show-again-checkbox',
            }}
            onChange={onCheckboxChange}
          />
          Don&apos;t show this message again.
        </div>
      );
    }

    return '';
  }

  function renderWarningMessage() {
    if (warningMessage) {
      return (
        <Typography className={classes.warningMessage}>
          {warningMessage}
        </Typography>
      );
    }
    return '';
  }

  if (!open) return null;

  return (
    <StyledDialog
      open={open}
      onClose={onCancel}
      onKeyPress={handleKeyPress}
      // PaperProps={{ {root: classes.container}}}
      // BackdropProps={{ {root: classes.backdrop}}}
      data-testid="confirmation-dialog"
    >
      {title && (
        <DialogTitle>
          <Typography className={classes.title}>{title}</Typography>
        </DialogTitle>
      )}
      <DialogContent>
        <Typography className={classes.message} gutterBottom>
          {message}
        </Typography>
        {renderWarningMessage()}
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        {renderDontShowAgainCheckbox()}
        {cancelEnabled && (
          <Button className={classes.button} onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button
          className={classes.button}
          onClick={onConfirm}
          variant="contained"
          color="primary"
          dataTestId="confirmation-dialog-confirm-button"
        >
          {confirmationText || 'OK'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default ConfirmationDialog;
