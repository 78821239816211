import {
  OPEN_WORKSTATION_DIALOG_ERROR,
  CLOSE_WORKSTATION_DIALOG_ERROR,
} from 'redux/actions/remoteWorkstationErrorDialogActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';

const defaultState = {
  open: false,
  workstation: {},
};

function remoteWorkstationErrorDialog(state = defaultState, action) {
  switch (action.type) {
    case OPEN_WORKSTATION_DIALOG_ERROR:
      return {
        ...state,
        open: true,
        workstation: action.workstation,
      };
    case CLEAR_STORE:
    case CLOSE_WORKSTATION_DIALOG_ERROR:
      return defaultState;
    default:
      return state;
  }
}

export default remoteWorkstationErrorDialog;
