import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import classNames from 'classnames';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {post} from 'api/Api';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import AssignmentPolicyIcon from 'components/CAM/icons/AssignmentPolicyIcon/AssignmentPolicyIcon';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';
import KeyValueInfo from 'components/CAM/text/KeyValueInfo/KeyValueInfo';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import SessionTrackingSwitch from 'components/common/SessionTrackingSwitch';
import useNameValidation from 'hooks/useNameValidation';
import usePendingChanges from 'hooks/usePendingChanges';
import useSnackbar from 'hooks/useSnackbar';
import {handleApiError} from 'redux/actions/dataActions';
import {goBack, push} from 'redux/ReduxHistory';
import {
  FLOATING_POOL_POLICY,
  FLOATING_POOL_POLICY_TOOLTIP,
  MAX_ASSIGNMENT_HOLDING_TIME,
  DEFAULT_ASSIGNMENT_HOLDING_TIME,
  PERSISTENT_POOL_POLICY,
  POOLS,
  POOLS_LINK,
  TELEMETRY_SETTINGS,
  TIME_MULTIPLIER_OPTIONS,
} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {
  selectResourceItem,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import {
  isEmpty,
  linkTo,
  sanitizeValues,
  validateHoldingTime,
} from 'utils/utils';
import config from 'config';

const PREFIX = 'CreatePool';

const classes = {
  root: `${PREFIX}-root`,
  pageHeader: `${PREFIX}-pageHeader`,
  headerText: `${PREFIX}-headerText`,
  buttonRow: `${PREFIX}-buttonRow`,
  button: `${PREFIX}-button`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  inputField: `${PREFIX}-inputField`,
  inputFieldHoldingTime: `${PREFIX}-inputFieldHoldingTime`,
  inputSelect: `${PREFIX}-inputSelect`,
  holdingTimeDiv: `${PREFIX}-holdingTimeDiv`,
  unitOfTimeDiv: `${PREFIX}-unitOfTimeDiv`,
  displayFlex: `${PREFIX}-displayFlex`,
  unitOfTimeSelect: `${PREFIX}-unitOfTimeSelect`,
  section: `${PREFIX}-section`,
  helperTextProps: `${PREFIX}-helperTextProps`,
  warningLine: `${PREFIX}-warningLine`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.root}`]: {
    ...theme.createPage.createPageContainer,
    minHeight: 'unset',
  },

  [`& .${classes.pageHeader}`]: theme.createPage.createPageTitleContainer,
  [`& .${classes.headerText}`]: theme.createPage.createPageTitle,
  [`& .${classes.buttonRow}`]: theme.createPage.buttonRow,

  [`& .${classes.button}`]: {
    ...theme.createPage.button,
    marginRight: '10px',
  },

  [`& .${classes.buttonContainer}`]: {
    maxHeight: '5rem',
    alignItems: 'center',
    marginBottom: '2.5rem',
  },

  [`& .${classes.inputField}`]: {
    backgroundColor: theme.palette.surface.white,
    marginRight: '20px',
    width: '320px',
    height: '36px',
  },

  [`& .${classes.inputFieldHoldingTime}`]: {
    width: '100%',
  },

  [`& .${classes.inputSelect}`]: {
    width: '100%',
  },

  [`& .${classes.holdingTimeDiv}`]: {
    maxWidth: '220px',
    marginRight: '10px',
  },

  [`& .${classes.unitOfTimeDiv}`]: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
  },

  [`& .${classes.displayFlex}`]: {
    display: 'flex',
  },

  [`& .${classes.unitOfTimeSelect}`]: {
    height: '36px',
  },

  [`& .${classes.section}`]: {
    padding: '20px',
    height: '100%',
  },

  [`& .${classes.helperTextProps}`]: {
    display: 'inline-block',
    width: '330px',
    textAlign: 'justify',
  },

  [`& .${classes.warningLine}`]: {
    color: '#FF9600',
  },
}));

function CreatePool() {
  const dispatch = useDispatch();
  const {setPendingChanges} = usePendingChanges();
  const {successSnackbar} = useSnackbar();

  const [poolName, setPoolName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [assignmentPolicy, setAssignmentPolicy] = useState(
    PERSISTENT_POOL_POLICY
  );
  const [assignmentHoldingTime, setAssignmentHoldingTime] = useState(
    DEFAULT_ASSIGNMENT_HOLDING_TIME
  );
  const [holdingTimeMessageType, setHoldingTimeMessageType] = useState('');
  const [holdingTimeErrorMessage, setHoldingTimeErrorMessage] = useState('');
  const [timeMultiplier, setTimeMultiplier] = useState(
    TIME_MULTIPLIER_OPTIONS.Minutes.value
  );

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const {item: telemetrySettings} = useSelector((state) =>
    selectResourceItem(state, TELEMETRY_SETTINGS, deploymentId)
  );
  const [poolNameError, validateName, nameHelperText] = useNameValidation(
    2,
    64
  );

  const moveToPools = () => dispatch(push(linkTo(POOLS_LINK)));
  const getPoolsApiPath = () => mapResourceToPath(POOLS, {deploymentId});
  const createPoolObject = () => {
    const time = assignmentHoldingTime * timeMultiplier;
    return {
      poolName: sanitizeValues(poolName),
      assignmentPolicy,
      assignmentHoldingTime: time,
    };
  };

  const handleCancelClick = () => {
    setPendingChanges(false);
    dispatch(goBack());
  };

  const handleNameFieldChange = (event) => {
    const {value} = event.target;
    setPoolName(value);
    validateName(value);
  };

  const handleAssignmentHoldingTimeChange = (event) =>
    setAssignmentHoldingTime(event.target.value);

  const handleCreate = async () => {
    setIsSaving(true);

    try {
      const resp = await post({
        path: getPoolsApiPath(),
        data: createPoolObject(),
      });
      successSnackbar(`Pool "${resp.data.poolName}" has been created.`);
      moveToPools();
    } catch (err) {
      setIsSaving(false);
      dispatch(handleApiError(err));
    }
  };

  const handleAssignmentPolicyChange = ({target: {value}}) =>
    setAssignmentPolicy(value);

  useEffect(() => {
    const {messageType, message} = validateHoldingTime(
      assignmentHoldingTime * timeMultiplier,
      timeMultiplier
    );
    setHoldingTimeMessageType(messageType);
    setHoldingTimeErrorMessage(message);
  }, [assignmentHoldingTime, timeMultiplier]);

  useEffect(() => {
    const haveFieldsChanged =
      !isEmpty(poolName) ||
      assignmentHoldingTime !== config.FLOATING_POOL_IDLE_MINUTES;
    setPendingChanges(haveFieldsChanged && !isSaving);
  }, [poolName, assignmentHoldingTime, isSaving]);

  const renderPoolNameInputField = () => (
    <>
      <KeyValueInfo label="Unique name for the pool" />
      <TextField
        data-testid="pool-name-field"
        autoComplete="off"
        required
        onChange={handleNameFieldChange}
        value={poolName}
        margin="none"
        variant="outlined"
        error={poolNameError}
        helperText={poolNameError ? nameHelperText : ''}
        InputProps={{
          id: 'pool-name-field',
          className: classes.inputField,
        }}
      />
    </>
  );

  const handleTimeChange = (event) => {
    setTimeMultiplier(event.target.value);
  };

  const assignmentHoldingTimeInputProps = () => ({
    max: MAX_ASSIGNMENT_HOLDING_TIME / timeMultiplier,
    min: Math.ceil(config.FLOATING_POOL_IDLE_MINUTES / timeMultiplier),
  });

  const renderAssignmentHoldingTimeField = () => (
    <div className={classes.displayFlex}>
      <div className={classes.holdingTimeDiv}>
        <InputLabel
          displayText="Assignment holding time"
          tooltipText={`${FLOATING_POOL_POLICY_TOOLTIP} This time period must be longer than ${config.FLOATING_POOL_IDLE_MINUTES} minutes. Small variations to the specified time are possible due to the multiple factors outside of our direct control. `}
        />
        <TextField
          autoComplete="off"
          required
          type="number"
          value={assignmentHoldingTime}
          onChange={handleAssignmentHoldingTimeChange}
          margin="none"
          error={holdingTimeMessageType === 'error'}
          helperText={
            <span
              className={
                holdingTimeMessageType === 'warning'
                  ? classNames(classes.helperTextProps, classes.warningLine)
                  : classes.helperTextProps
              }
            >
              {holdingTimeErrorMessage}
            </span>
          }
          variant="outlined"
          className={classes.inputSelect}
          FormHelperTextProps={{
            style: {
              margin: 0,
              padding: 0,
            },
          }}
          InputProps={{
            id: 'pool-holding-time',
            className: classNames(
              classes.inputField,
              classes.inputFieldHoldingTime
            ),
            inputProps: {
              'data-testid': 'pool-holding-time',
              ...assignmentHoldingTimeInputProps(),
            },
          }}
        />
      </div>
      <div className={`${classes.displayFlex} ${classes.unitOfTimeDiv}`}>
        <InputLabel displayText="Unit of time" />
        <Select
          data-testid="select-time-multiplier"
          onChange={handleTimeChange}
          value={timeMultiplier}
          className={classes.unitOfTimeSelect}
        >
          <MenuItem
            data-testid="select-time-multiplier-minutes"
            value={TIME_MULTIPLIER_OPTIONS.Minutes.value}
          >
            {TIME_MULTIPLIER_OPTIONS.Minutes.text}
          </MenuItem>
          <MenuItem
            data-testid="select-time-multiplier-hours"
            value={TIME_MULTIPLIER_OPTIONS.Hours.value}
          >
            {TIME_MULTIPLIER_OPTIONS.Hours.text}
          </MenuItem>
          <MenuItem
            data-testid="select-time-multiplier-days"
            value={TIME_MULTIPLIER_OPTIONS.Days.value}
          >
            {TIME_MULTIPLIER_OPTIONS.Days.text}
          </MenuItem>
        </Select>
      </div>
    </div>
  );

  const getRadioButtonLabel = (displayText, policyType) => (
    <Grid container alignItems="center">
      <AssignmentPolicyIcon policyType={policyType} />
      <InputLabel displayText={displayText} />
    </Grid>
  );

  const renderPoolPolicyTypeInputField = () => (
    <>
      <div>
        One time selection which determines how workstations are assigned to
        users.{' '}
        <strong>This option cannot be changed once the pool is created.</strong>
      </div>
      <FormControl component="fieldset">
        <RadioGroup
          name="policy-type"
          value={assignmentPolicy}
          onChange={handleAssignmentPolicyChange}
        >
          <FormControlLabel
            data-testid="persistent-radio"
            value={PERSISTENT_POOL_POLICY}
            label={getRadioButtonLabel('Persistent', PERSISTENT_POOL_POLICY)}
            control={<Radio color="primary" size="small" />}
          />
          <FormControlLabel
            data-testid="floating-radio"
            value={FLOATING_POOL_POLICY}
            label={getRadioButtonLabel('Floating', FLOATING_POOL_POLICY)}
            control={<Radio color="primary" size="small" />}
          />
        </RadioGroup>
      </FormControl>
    </>
  );

  const renderSessionSwitch = () => (
    <>
      <br />
      <br />
      <Typography variant="body2" gutterBottom>
        You need to enable session tracking for this deployment to use the
        floating assignment policy
      </Typography>
      <SessionTrackingSwitch />
    </>
  );

  const isCreateDisabled = () => {
    let disableCreate = !poolName || poolNameError || isSaving;
    if (assignmentPolicy === FLOATING_POOL_POLICY) {
      disableCreate =
        !telemetrySettings.enabled ||
        disableCreate ||
        holdingTimeMessageType === 'error';
    }

    return disableCreate;
  };

  const renderButtonRow = () => (
    <div className={classNames(classes.buttonRow, classes.buttonContainer)}>
      <Button
        data-testid="cancel-button"
        className={classes.button}
        variant="outlined"
        color="primary"
        onClick={handleCancelClick}
      >
        Cancel
      </Button>
      <SaveButton
        data-testid="save-button"
        onClick={handleCreate}
        disabled={isCreateDisabled()}
        saving={isSaving}
        buttonText="Create"
        buttonTextSaving="Creating"
      />
    </div>
  );

  return (
    <Root className={classes.root}>
      <div className={classes.pageHeader}>
        <Typography className={classes.headerText}>
          Create a new workstation pool
        </Typography>
      </div>
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        className={classes.gridContainer}
      >
        <Grid item xs={12}>
          <Paper variant="outlined" className={classes.section}>
            <SectionHeader displayText="POOL NAME" />
            {renderPoolNameInputField()}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper variant="outlined" className={classes.section}>
            <SectionHeader displayText="WORKSTATION ASSIGNMENT POLICY" />
            {renderPoolPolicyTypeInputField()}
            {assignmentPolicy === FLOATING_POOL_POLICY && renderSessionSwitch()}
          </Paper>
        </Grid>
        {assignmentPolicy === FLOATING_POOL_POLICY && (
          <Grid item xs={12}>
            <Paper variant="outlined" className={classes.section}>
              <SectionHeader displayText="WORKSTATION HOLDING TIME" />
              {renderAssignmentHoldingTimeField()}
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          {renderButtonRow()}
        </Grid>
      </Grid>
    </Root>
  );
}

export default CreatePool;
