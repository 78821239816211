import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import PropTypes from 'prop-types';

const PREFIX = 'CAMCard';

const classes = {
  root: `${PREFIX}-root`,
  actions: `${PREFIX}-actions`
};

const StyledCard = styled(Card)(() => ({
  [`&.${classes.root}`]: {
    margin: '0.6em 0',
    maxWidth: '1790px',
    overflow: 'visible',
  },

  [`& .${classes.actions}`]: {
    marginLeft: '0.8em',
    marginBottom: '0.8em',
  }
}));

function CAMCard({children, actions}) {

  return (
    <StyledCard className={classes.root}>
      <CardContent>{children}</CardContent>
      {actions && (
        <CardActions className={classes.actions}>{actions}</CardActions>
      )}
    </StyledCard>
  );
}

CAMCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  actions: PropTypes.object,
};

CAMCard.defaultProps = {
  actions: null,
};

export default CAMCard;
