import fileDownload from 'js-file-download';
import moment from 'moment';
import { get } from 'api/Api';
import {
  mapResourceToDownloadPath,
  mapResourceToNameField,
  mapResourceToPath,
  resourceToSearchInfoMap,
} from 'utils/Mappings';
import { getConnectorSettings } from 'utils/apiUtils';
import {
  ACTIVITY_LOGS,
  AD_GROUPS,
  AD_USERS,
  CONNECTORS,
  DEFAULT_RESOURCE_ROWS_PER_PAGE,
  DEFAULT_ROWS_DOWNLOAD,
  DEFAULT_ROWS_PER_PAGE,
  DEPLOYMENTS,
  ENROLLMENT_COMMANDS,
  MACHINE_ENTITLEMENTS,
  POOL_ENTITLEMENTS,
  POOL_GROUPS,
  POOL_MACHINES,
  POOL_MACHINES_USERS,
  POOL_USERS,
  WEBHOOKS,
  REMOTE_WORKSTATIONS,
  SESSION_AUDIT,
} from 'utils/constants';
import { convertArrayToObject, isEmpty, unpackAdGroups } from 'utils/utils';
import {
  fetchMachineRelatedElements,
  fetchResource,
  receiveResource,
  requestResource,
  saveVariable,
} from './dataActions';

export const LOAD_TABLE_DATA = 'LOAD_TABLE_DATA';

export function loadTableData(
  resource,
  data,
  page,
  rowsPerPage,
  searchTerm = '',
  sortKey,
  sortAsc,
  total,
  params = {}
) {
  return {
    type: LOAD_TABLE_DATA,
    resource,
    data,
    page,
    rowsPerPage,
    searchTerm,
    sortKey,
    sortAsc,
    total,
    params,
  };
}

async function makeGetRequest(
  resource,
  page,
  rowsPerPage,
  deploymentId,
  searchTerm,
  sortKey,
  sortAsc,
  params,
  path
) {
  return get({
    path: path || mapResourceToPath(resource, { deploymentId }),
    params: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      deploymentId,
      [mapResourceToNameField(resource)]: searchTerm,
      [`${mapResourceToNameField(resource)}Filter`]: 'includes',
      sortKey,
      sortAsc,
      ...params,
    },
  });
}

const getPendingApprovalEnrollments = async (enrollmentKeyId, deploymentId) => {
  try {
    const enrollmentsResponse = await get({
      path: `deployments/${deploymentId}/messaging/enrollments`,
      params: { enrollmentKeyId },
    });
    return enrollmentsResponse.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const prepareEnrollmentCommandData = async (data, deploymentId) => {
  const dataWithCommands = await Promise.all(
    data.map(async (key) => {
      const newKey = key;
      newKey.pendingApprovalWS = await getPendingApprovalEnrollments(
        key.enrollmentKeyId,
        deploymentId
      );
      return newKey;
    })
  );
  return dataWithCommands;
};

export const RETAIN_MACHINE_ERRORS = 'RETAIN_MACHINE_ERRORS';

export function retainMachineErrors(data) {
  return {
    type: RETAIN_MACHINE_ERRORS,
    data,
  };
}

function getSessionAuditSortKey(sortKey) {
  // Override the default createdOn sortKey for session audit
  if (!sortKey || sortKey === 'createdOn') {
    return 'startedOn';
  }
  return sortKey;
}

export function fetchTableData(
  resource,
  page = 0,
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  params = {},
  searchTerm,
  usePartialSearch = false
) {
  return async (dispatch, getState) => {
    dispatch(requestResource(resource));
    const state = getState();
    const allParams = {
      ...params,
    };

    const { dataByResource } = state.data;
    const { deploymentId } = dataByResource.selectedDeployment || {
      deploymentId: '',
    };
    const { poolId } = dataByResource.selectedPool || { poolId: '' };
    const { defaultValue: defaultSearchTermByResource } = resourceToSearchInfoMap[
      resource
    ] ?? { defaultValue: '' };
    const searchTermBy =
      dataByResource[`searchTermBy${resource}`] || defaultSearchTermByResource;

    switch (resource) {
      case CONNECTORS:
        allParams.showdeleting = true;
        break;
      case POOL_USERS:
        allParams.poolId = poolId;
        allParams.entitlementType = 'user';
        allParams.resourceType = 'pool';
        break;
      case POOL_GROUPS:
        allParams.poolId = poolId;
        allParams.entitlementType = 'group';
        allParams.resourceType = 'pool';
        break;
      case MACHINE_ENTITLEMENTS:
        allParams.resourceType = 'machine';
        break;
      case POOL_ENTITLEMENTS:
        allParams.resourceType = 'pool';
        break;
      case SESSION_AUDIT:
        allParams.fetchRelatedFields = true;
        break;
      case DEPLOYMENTS:
      default:
        break;
    }

    const path = mapResourceToPath(resource, { deploymentId, poolId });
    const { sortAsc } = state.tableData[resource] || { sortAsc: false };

    // if sorting by error, sortKey should have the default value
    // to ensure refreshMachineState on the backend
    let { sortKey } = state.tableData[resource] || { sortKey: 'createdOn' };
    sortKey = sortKey === 'detailedError' ? 'createdOn' : sortKey;

    try {
      let response;

      switch (resource) {
        case POOL_MACHINES:
          response = await get({
            path,
            params: {
              limit: rowsPerPage,
              offset: page * rowsPerPage,
              machineName: searchTerm,
            },
          });
          break;
        case ACTIVITY_LOGS:
          response = await get({
            path,
            params: {
              limit: rowsPerPage,
              offset: page * rowsPerPage,
              ...allParams,
            },
          });
          break;
        case DEPLOYMENTS:
          response = await get({
            path,
            params: {
              limit: rowsPerPage,
              offset: page * rowsPerPage,
              showdeleting: true,
              deploymentName: searchTerm,
              deploymentNameFilter: 'includes',
            },
          });
          break;
        case AD_USERS:
          response = await get({
            path,
            params: {
              limit: rowsPerPage,
              offset: page * rowsPerPage,
              deploymentId,
              [searchTermBy]: searchTerm,
              [`${searchTermBy}Filter`]: usePartialSearch ? 'includes' : '',
              sortKey,
              sortAsc,
              ...allParams,
            },
          });
          break;
        case SESSION_AUDIT:
          response = await get({
            path,
            params: {
              limit: rowsPerPage,
              offset: page * rowsPerPage,
              deploymentId,
              [searchTermBy]: searchTerm,
              [`${searchTermBy}Filter`]: usePartialSearch ? 'includes' : '',
              sortKey: getSessionAuditSortKey(
                state?.tableData[resource]?.sortKey
              ),
              sortAsc,
              ...allParams,
            },
          });
          break;
        case ENROLLMENT_COMMANDS:
          response = await get({
            path,
            params: {
              limit: rowsPerPage,
              offset: page * rowsPerPage,
              keyName: searchTerm,
              keyNameFilter: 'includes',
              deploymentId,
              includePendingApprovals: true,
            },
          });
          break;
        case WEBHOOKS:
          response = await get({
            path,
            params: {
              limit: rowsPerPage,
              offset: page * rowsPerPage,
              name: searchTerm,
            },
          });
          break;
        default:
          response = await makeGetRequest(
            resource,
            page,
            rowsPerPage,
            deploymentId,
            searchTerm,
            sortKey,
            sortAsc,
            allParams,
            path
          );
          break;
      }

      let { data, total } = response;

      if (resource === REMOTE_WORKSTATIONS) {
        if (allParams.refreshMachineState) {
          dispatch(receiveResource(REMOTE_WORKSTATIONS, data));
        } else {
          // update everything about the workstation except for the error property
          dispatch(retainMachineErrors(data));
        }

        if (!isEmpty(data)) {
          dispatch(fetchMachineRelatedElements(response));
        }
      } else if (resource === POOL_USERS) {
        const userGuids = data
          .map((entitlement) => entitlement.userGuid)
          .join(',');
        const adUsersResponse = await makeGetRequest(
          AD_USERS,
          0,
          1000,
          deploymentId,
          searchTerm,
          sortKey,
          sortAsc,
          { userGuid: userGuids }
        );
        const adUsers = adUsersResponse.data || [];
        const hashedAdUsers = adUsers.reduce(
          (result, adUser) => ({
            ...result,
            [adUser.userGuid]: adUser,
          }),
          {}
        );
        const poolUsers = data.map((entitlement) => {
          const adUser = hashedAdUsers[entitlement.userGuid] || {};
          return {
            _id: adUser._id || '',
            entitlementId: entitlement.entitlementId,
            userGuid: entitlement.userGuid,
            deploymentId,
            poolId,
            status: entitlement.status,
          };
        });
        dispatch(receiveResource(POOL_USERS, poolUsers));
      } else if (resource === AD_GROUPS) {
        data = unpackAdGroups(data, deploymentId) || [];
        total = data.length;
        dispatch(receiveResource(AD_GROUPS, data));
      } else if (resource === POOL_MACHINES) {
        dispatch(receiveResource(resource, data));
        if (!isEmpty(data)) {
          // Fetch users for machines
          const userGuids = data
            .filter((machine) => machine.assignedTo !== null)
            .map((machine) => machine.assignedTo);

          if (!isEmpty(userGuids)) {
            dispatch(
              fetchResource(POOL_MACHINES_USERS, {
                deploymentId,
                userGuid: userGuids.join(),
                offset: 0,
                limit: userGuids.length,
              })
            );
          }
        }
      } else if (resource === POOL_GROUPS) {
        const connectorSettingsResponse =
          await getConnectorSettings(deploymentId);

        const encodedGroupData = connectorSettingsResponse?.deployment || '';
        const deploymentGroups = unpackAdGroups(encodedGroupData, deploymentId);
        const deploymentGroupsHash = convertArrayToObject(
          deploymentGroups,
          'groupGuid'
        );

        // for each group entitlement, get the groupGuid from the entitlement and the groupDN
        // from the data fetched from the AD groups stored in connectorSettings
        const groups = data
          .map((groupEntitlement) => ({
            ...groupEntitlement,
            groupGuid: groupEntitlement.group,
            group: deploymentGroupsHash[groupEntitlement.group]
              ? deploymentGroupsHash[groupEntitlement.group].groupDN || ''
              : '',
          }));
        dispatch(receiveResource(POOL_GROUPS, groups));
      } else if (resource === ENROLLMENT_COMMANDS) {
        data = await prepareEnrollmentCommandData(data, deploymentId);
        dispatch(receiveResource(ENROLLMENT_COMMANDS, data));
      } else {
        dispatch(receiveResource(resource, data));
      }
      dispatch(
        loadTableData(
          resource,
          data,
          page,
          rowsPerPage,
          searchTerm,
          sortKey,
          sortAsc,
          total,
          allParams
        )
      );
    } catch (error) {
      console.log(error);
      dispatch(receiveResource(resource, []));
    }
  };
}

export function updateTableData(
  resource,
  page = 0,
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  params = {}
) {
  return async (dispatch, getState) => {
    const state = getState();

    const { dataByResource } = state.data;
    const { deploymentId } = dataByResource.selectedDeployment || {
      deploymentId: '',
    };
    const { poolId } = dataByResource.selectedPool || { poolId: '' };
    const { searchTerm, sortKey, sortAsc } = state.tableData[resource] || {
      searchTerm: '',
      sortKey: 'createdOn',
      sortAsc: false,
    };

    const path = mapResourceToPath(resource, { deploymentId, poolId });
    try {
      const response =
        resource === POOL_MACHINES || resource === WEBHOOKS
          ? await get({
            path,
            params: {
              ...params,
              limit: rowsPerPage,
              offset: page * rowsPerPage,
            },
          })
          : await makeGetRequest(
            resource,
            page,
            rowsPerPage,
            deploymentId,
            searchTerm,
            sortKey,
            sortAsc,
            params,
            path
          );
      const { data } = response;

      if (resource === REMOTE_WORKSTATIONS) {
        if (!isEmpty(data)) {
          dispatch(fetchMachineRelatedElements(response));
        }
      }
      dispatch(receiveResource(resource, data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function sortTableData(resource, sortKey, sortAsc, params = {}) {
  return async (dispatch, getState) => {
    dispatch(requestResource(resource));
    const state = getState();

    let { deploymentId } = state.data.dataByResource.selectedDeployment || {
      deploymentId: '',
    };
    const { enabled } = state.deploymentFilter || { enabled: false };
    const { page, rowsPerPage } = DEFAULT_RESOURCE_ROWS_PER_PAGE[resource] || {
      page: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    };

    if (resource === ACTIVITY_LOGS && !enabled) {
      deploymentId = '';
    }

    if (resource === ENROLLMENT_COMMANDS) {
      params.includePendingApprovals = true;
    }

    if (resource === SESSION_AUDIT) {
      params.fetchRelatedFields = true;
    }

    const { searchTerm } = state.tableData[resource];
    try {
      const response = await makeGetRequest(
        resource,
        page,
        rowsPerPage,
        deploymentId,
        searchTerm,
        sortKey,
        sortAsc,
        params
      );

      let { data } = response;
      const { total } = response;
      if (resource === ENROLLMENT_COMMANDS) {
        data = await prepareEnrollmentCommandData(data, deploymentId);
      }
      if (resource === REMOTE_WORKSTATIONS) {
        if (!isEmpty(data)) {
          dispatch(fetchMachineRelatedElements(response));
        }
      }
      dispatch(receiveResource(resource, data));
      dispatch(
        loadTableData(
          resource,
          data,
          page,
          rowsPerPage,
          searchTerm,
          sortKey,
          sortAsc,
          total,
          params
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
}

export function downloadTableData(
  resource,
  page = 0,
  rowsPerPage = DEFAULT_ROWS_DOWNLOAD,
  params = {}
) {
  return async (dispatch, getState) => {
    const state = getState();
    const { deploymentId } = state.data.dataByResource.selectedDeployment || {
      deploymentId: '',
    };
    const { searchTerm, sortKey, sortAsc } = {
      searchTerm: '',
      sortKey: 'createdOn',
      sortAsc: false,
    };

    try {
      dispatch(saveVariable('downloadingTableData', true));
      const fileData = await get({
        path: mapResourceToDownloadPath(resource),
        isDownload: true,
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          deploymentId,
          [mapResourceToNameField(resource)]: searchTerm,
          [`${mapResourceToNameField(resource)}Filter`]: 'includes',
          sortKey,
          sortAsc,
          ...params,
        },
      });
      fileDownload(fileData, `export-${resource}-${moment().format()}.csv`);
    } catch (error) {
      console.log(error);
    } finally {
      // Waits at least 3 seconds to re-enable to button
      setTimeout(() => {
        dispatch(saveVariable('downloadingTableData', false));
      }, 3000);
    }
  };
}

export const UPDATE_TABLE_FETCH_PARAMS = 'UPDATE_TABLE_FETCH_PARAMS';
export function updateTableFetchParams(resource, params) {
  return {
    type: UPDATE_TABLE_FETCH_PARAMS,
    resource,
    params,
  };
}

export const CLEAR_TABLE_FETCH_PARAMS = 'CLEAR_TABLE_FETCH_PARAMS';
export function clearTableFetchParams(resource) {
  return {
    type: CLEAR_TABLE_FETCH_PARAMS,
    resource,
  };
}
