import {styled} from '@mui/material/styles';
import {Box, Grid, Divider, Button} from '@mui/material';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PageHeader from 'components/CAM/layout/PageHeader/PageHeader';
import PageWrapper from 'components/CAM/layout/PageWrapper/PageWrapper';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import {hostnameFromUrl} from 'utils/utils';
import {POOLS, WEBHOOKS, WEBHOOKS_LINK} from 'utils/constants';
import {
  selectDataForTable,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import useSnackbar from 'hooks/useSnackbar';
import usePendingChanges from 'hooks/usePendingChanges';
import {fetchTableData} from 'redux/actions/tableDataActions';
import {goBack} from 'redux/ReduxHistory';
import {handleApiError} from 'redux/actions/dataActions';
import {mapResourceToPath} from 'utils/Mappings';
import {post, put} from 'api/Api';
import {useFieldValidation} from 'hooks/useFieldValidation';
import {CertificateDropbox} from 'components/common/CertificateDropbox';
import {TriggerEventDropdown} from './components/TriggerEventDropdown';
import {PayloadTooltip} from './components/PayloadTooltip';
import PayloadInput from './components/PayloadSection';
import SearchableAssociatedPools from './components/SearchAssociatedPools';
import {useWizardState} from '../connectors/createWizard/wizardConfigState';
import {
  LabeledTextField,
  LabeledUrlTextField,
} from './components/LabeledTextField';

const PREFIX = 'CreateWebhook';

const classes = {
  formWrapper: `${PREFIX}-formWrapper`,
  divider: `${PREFIX}-divider`,
  saveButton: `${PREFIX}-saveButton`,
  cancelButton: `${PREFIX}-cancelButton`,
};

const Root = styled('div')(() => ({
  maxWidth: '50%',
  [`& .${classes.formWrapper}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '24px',
  },
  [`& .${classes.divider}`]: {
    width: '100%',
  },
  [`& .${classes.saveButton}`]: {
    color: '#FFFFFF',
    background: '#0D47A1',
    '&:hover': {
      backgroundColor: '#0D47A1',
    },
  },
  [`& .${classes.cancelButton}`]: {
    color: '#0D47A1',
    borderColor: '#0D47A1',
    '&:hover': {
      backgroundColor: '#F8F8FB',
    },
  },
}));

export function CreateWebhook() {
  const {setPendingChanges} = usePendingChanges();
  const dispatch = useDispatch();
  const {successSnackbar} = useSnackbar();

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );

  const {data: pools, isFetching} = useSelector((state) =>
    selectDataForTable(state, POOLS)
  );

  const anchorRef = useRef();
  const [textInputFilter, setTextInputFilter] = useState('');
  const [webhookName, setWebhookName] = useState('');
  const [webhookAssociatedResources, setWebhookAssociatedResources] = useState(
    []
  );
  const [webhookBaseUrl, setWebhookBaseUrl] = useState('');
  const [webhookTriggerEvent, setWebhookTriggerEvent] = useState('');
  const [webhookPayload, setWebhookPayload] = useState('');
  const [webhookDescription, setWebhookDescription] = useState('');
  const [isSaving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const [isPayloadValid, setIsPayloadValid] = useState(true);
  const [isUrlValid, setIsUrlValid] = useState(false);
  const {configState} = useWizardState();

  const getValues = useCallback(
    () => ({
      webhookName,
      webhookBaseUrl,
      webhookTriggerEvent,
      webhookDescription,
      webhookPayload,
    }),
    [
      webhookName,
      webhookBaseUrl,
      webhookTriggerEvent,
      webhookDescription,
      webhookPayload,
    ]
  );

  const validationRules = useMemo(
    () => ({
      webhookName: {required: true, maxLength: 512},
      webhookBaseUrl: {required: true},
      webhookTriggerEvent: {required: true},
      webhookDescription: {required: false, maxLength: 1024},
      webhookPayload: {required: false},
    }),
    []
  );

  const {handleBlur, hasError} = useFieldValidation(getValues, validationRules);

  const disableSaveButton = () => {
    const values = getValues();

    const hasErrors = Object.keys(values).some((field) => {
      const rules = validationRules[field];
      return (
        hasError(field) !== '' || (rules && rules.required && !values[field])
      );
    });

    return isSaving || !isUrlValid || !isPayloadValid || hasErrors;
  };

  const handleClosePopper = () => setOpen(false);
  const togglePopper = () => setOpen(!open);

  const breadcrumbs = [
    {text: 'Webhooks', link: WEBHOOKS_LINK},
    {text: 'Add Webhook'},
  ];

  const handleChangeAssociatedResource = (resources) => {
    const associatedResources = resources.map((resource) => ({
      resourceId: resource.value,
      resourceName: resource.label,
    }));

    setWebhookAssociatedResources(associatedResources);
  };

  const handleChangeTriggerEvent = (triggerEvent) => {
    setWebhookTriggerEvent(triggerEvent || '');
  };

  const clearForm = () => {
    setWebhookName('');
    setWebhookAssociatedResources([]);
    setWebhookBaseUrl('');
    setWebhookTriggerEvent('');
    setWebhookPayload('');
    setWebhookDescription('');
  };

  const saveWebhook = async (data) => {
    const basePath = mapResourceToPath(WEBHOOKS, {deploymentId});
    const path = `${basePath}`;
    return post({path, data});
  };

  const saveWebhookCA = async (data) => {
    const hostname = hostnameFromUrl(webhookBaseUrl);
    const path = `deployments/${deploymentId}/settings/webhook-trust-ca-${hostname
      .replaceAll('.', '_')
      .trim()}`;
    try {
      await put({path, data});
    } catch (err) {
      handleApiError(err);
    }
  };

  const handleSaveWebhookCA = async () => {
    if (configState.CertificateDropbox !== undefined) {
      const caData = {
        deploymentId: `${deploymentId}`,
        value: btoa(configState.CertificateDropbox),
      };
      await saveWebhookCA(caData);
    }
  };

  const handleSaveWebhook = async () => {
    setSaving(true);
    try {
      const data = {
        name: webhookName.trim(),
        endpoint: webhookBaseUrl.trim(),
        eventType: webhookTriggerEvent.trim(),
        additionalData: webhookPayload.trim(),
        description: webhookDescription.trim(),
        associatedResources: webhookAssociatedResources.map(
          (resource) => resource.resourceId
        ),
      };

      await saveWebhook(data);

      successSnackbar(`Webhook "${webhookName}" has been created`);
      clearForm();
      dispatch(goBack());
    } catch (error) {
      dispatch(handleApiError(error));
    } finally {
      setSaving(false);
    }
  };

  const onURLValidityChange = useCallback((valid) => {
    setIsUrlValid(valid);
  }, []);

  const handleCancel = () => {
    setPendingChanges(false);
    dispatch(goBack());
  };

  useEffect(() => {
    dispatch(fetchTableData(POOLS));
  }, [dispatch, deploymentId]);

  const associatedPoolsValues = webhookAssociatedResources?.map((resource) => ({
    value: resource.resourceId,
    label: resource.resourceName,
  }));

  const associatedPoolsOptions = useMemo(() => {
    const lowerCaseFilter = textInputFilter.toLowerCase();

    return pools?.reduce((options, pool) => {
      if (pool.poolName.toLowerCase().includes(lowerCaseFilter)) {
        options.push({
          value: pool.poolId,
          label: pool.poolName,
        });
      }

      return options;
    }, []);
  }, [pools, textInputFilter]);

  const popperModifiers = [
    {
      name: 'offset',
      options: {
        offset: [0, 10],
      },
    },
  ];

  return (
    <Root>
      <PageWrapper>
        <PageHeader
          titleText="Add Webhook"
          descriptionText="Add a new webhook to Anyware Manager"
          breadcrumbs={breadcrumbs}
        />

        <Box className={classes.formWrapper}>
          <LabeledTextField
            label="Name"
            placeholder="Webhook name"
            value={webhookName}
            required
            onChange={(e) => setWebhookName(e.target.value)}
            onBlur={handleBlur('webhookName')}
            error={!!hasError('webhookName')}
            helperText={hasError('webhookName')}
          />

          <LabeledTextField
            label="Description"
            placeholder="Brief description of the webhook"
            value={webhookDescription}
            onChange={(e) => setWebhookDescription(e.target.value)}
            onBlur={handleBlur('webhookDescription')}
            error={!!hasError('webhookDescription')}
            helperText={hasError('webhookDescription')}
          />

          <SearchableAssociatedPools
            label="Associated Pool(s)"
            note="No associated pool will render webhook dormant"
            id="webhook-associated-pools"
            data-testid="webhook-associated-pools-select"
            disabledMessage=""
            emptyMessage="No pools found"
            isDisabled={!pools?.length}
            isLoading={isFetching}
            isSearchable
            onChange={handleChangeAssociatedResource}
            onTextInput={(text) => {
              setTextInputFilter(text);
            }}
            onClear={() => {
              setTextInputFilter('');
            }}
            clearOnSelect
            placeholderText="Select..."
            isMulti
            value={associatedPoolsValues}
            options={associatedPoolsOptions}
            classes={classes}
          />

          <LabeledUrlTextField
            label="Base URL"
            placeholder="https://"
            value={webhookBaseUrl}
            onChange={(e) => setWebhookBaseUrl(e.target.value)}
            onValidityChange={onURLValidityChange}
            onBlur={handleBlur('webhookBaseUrl')}
            error={!!hasError('webhookBaseUrl')}
            helperText={hasError('webhookBaseUrl')}
          />

          <TriggerEventDropdown
            value={webhookTriggerEvent}
            onChange={(event) => handleChangeTriggerEvent(event.target.value)}
            error={!!hasError('webhookTriggerEvent')}
            onBlur={handleBlur('webhookTriggerEvent')}
            helperText={hasError('webhookTriggerEvent')}
          />

          <Box height="auto">
            <Grid container direction="column">
              <PayloadTooltip
                open={open}
                anchorRef={anchorRef}
                togglePopper={togglePopper}
                handleClosePopper={handleClosePopper}
                popperModifiers={popperModifiers}
              />

              <PayloadInput
                selectedWebhookPayload={webhookPayload}
                setSelectedWebhookPayload={setWebhookPayload}
                setIsPayloadValid={setIsPayloadValid}
                isPayloadValid={isPayloadValid}
              />

              <CertificateDropbox />
            </Grid>
          </Box>

          <Grid container justifyContent="flex-end" gap={2}>
            <Divider className={classes.divider} />
            <Grid container item justifyContent="flex-end" gap={2}>
              <Button
                className={classes.cancelButton}
                variant="outlined"
                size="medium"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <SaveButton
                className={classes.saveButton}
                variant="contained"
                size="small"
                disabled={disableSaveButton()}
                saving={isSaving}
                onClick={() => {
                  handleSaveWebhook();
                  handleSaveWebhookCA();
                }}
              >
                Save
              </SaveButton>
            </Grid>
          </Grid>
        </Box>
      </PageWrapper>
    </Root>
  );
}
