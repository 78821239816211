import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import FormControl from '@mui/material/FormControl';
import {saveVariable} from 'redux/actions/dataActions';
import {resourceToSearchInfoMap} from 'utils/Mappings';
import {selectVariable} from 'utils/reduxSelectors';

const PREFIX = 'PartialMatchSelect';

const classes = {
  PartialMatchSelect: `${PREFIX}-PartialMatchSelect`
};

const StyledTooltip = styled(Tooltip)((
  {
    theme
  }
) => ({
  [`& .${classes.PartialMatchSelect}`]: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    minWidth: theme.spacing(18),
  }
}));

const TOOLTIP_TEXT =
  'Note: Partial match searches may take longer time to process';

function PartialMatchSelect({resource}) {

  const dispatch = useDispatch();

  const usePartialSearch =
    (useSelector((state) =>
      selectVariable(state, `usePartialSearchBy${resource}`)
    ) ??
      resourceToSearchInfoMap[resource]?.defaultUsePartialSearch) ||
    false;

  const PARTIAL_MATCH = 'Partial Match';
  const EXACT_MATCH = 'Exact Match';
  const selectValue = usePartialSearch ? PARTIAL_MATCH : EXACT_MATCH;

  const handleSearchTypeChange = (event) => {
    dispatch(
      saveVariable(
        `usePartialSearchBy${resource}`,
        event.target.value === PARTIAL_MATCH
      )
    );
  };

  return (
    <StyledTooltip title={TOOLTIP_TEXT} placement="top">
      <FormControl size="small">
        <Select
          defaultValue={EXACT_MATCH}
          className={classes.PartialMatchSelect}
          onChange={handleSearchTypeChange}
          value={selectValue}
        >
          <MenuItem value={EXACT_MATCH}>{EXACT_MATCH}</MenuItem>
          <MenuItem value={PARTIAL_MATCH}>{PARTIAL_MATCH}</MenuItem>
        </Select>
      </FormControl>
    </StyledTooltip>
  );
}

PartialMatchSelect.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default PartialMatchSelect;
