import {styled} from '@mui/material/styles';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Grid, Divider, Button} from '@mui/material';
import {useParams} from 'react-router-dom';
import PageHeader from 'components/CAM/layout/PageHeader/PageHeader';
import PageWrapper from 'components/CAM/layout/PageWrapper/PageWrapper';
import {POOLS, WEBHOOKS, WEBHOOKS_LINK} from 'utils/constants';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import {
  fetchSelectedWebhook,
  handleApiError,
  receiveDeleteResource,
  requestDeleteResource,
  saveVariable,
} from 'redux/actions/dataActions';
import useSnackbar from 'hooks/useSnackbar';
import {del as apiDelete, put} from 'api/Api';
import {isEmpty, hostnameFromUrl} from 'utils/utils';
import {
  selectDataForTable,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import {goBack, push} from 'redux/ReduxHistory';
import usePendingChanges from 'hooks/usePendingChanges';
import {fetchTableData} from 'redux/actions/tableDataActions';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import useDialog from 'hooks/useDialog';
import {mapResourceToPath} from 'utils/Mappings';
import {uncheckItem} from 'redux/actions/tableSelectActions';
import {useFieldValidation} from 'hooks/useFieldValidation';
import {CertificateDropbox} from 'components/common/CertificateDropbox';
import {WebhookButton} from './components/WebhookButton';
import {TriggerEventDropdown} from './components/TriggerEventDropdown';
import {PayloadTooltip} from './components/PayloadTooltip';
import PayloadInput from './components/PayloadSection';
import SearchableAssociatedPools from './components/SearchAssociatedPools';
import {useWizardState} from '../connectors/createWizard/wizardConfigState';
import {
  LabeledTextField,
  LabeledUrlTextField,
} from './components/LabeledTextField';

const PREFIX = 'EditWebhook';

const classes = {
  formWrapper: `${PREFIX}-formWrapper`,
  divider: `${PREFIX}-divider`,
  saveButton: `${PREFIX}-saveButton`,
  cancelButton: `${PREFIX}-cancelButton`,
};

const Root = styled('div')(() => ({
  maxWidth: '50%',
  [`& .${classes.formWrapper}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '24px',
  },
  [`& .${classes.divider}`]: {
    width: '100%',
  },
  [`& .${classes.saveButton}`]: {
    color: '#FFFFFF',
    background: '#0D47A1',
    '&:hover': {
      backgroundColor: '#0D47A1',
    },
  },
  [`& .${classes.cancelButton}`]: {
    color: '#0D47A1',
    borderColor: '#0D47A1',
    '&:hover': {
      backgroundColor: '#F8F8FB',
    },
  },
}));

export function EditWebhook() {
  const {setPendingChanges} = usePendingChanges();
  const dispatch = useDispatch();
  const {successSnackbar} = useSnackbar();
  const {webhookId} = useParams();
  const {triggerDialog} = useDialog();

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );

  const selectedWebhook = useSelector(
    (state) => state?.data?.dataByResource?.selectedWebhook
  );

  const {data: pools, isFetching} = useSelector((state) =>
    selectDataForTable(state, POOLS)
  );

  const anchorRef = useRef();
  const [textInputFilter, setTextInputFilter] = useState('');
  const [selectedWebhookName, setSelectedWebhookName] = useState('');
  const [isPayloadValid, setIsPayloadValid] = useState(true);
  const [webhookAssociatedResources, setSelectedWebhookAssociatedResources] =
    useState([]);
  const [selectedWebhookBaseUrl, setSelectedWebhookBaseUrl] = useState('');
  const [selectedWebhookTriggerEvent, setSelectedWebhookTriggerEvent] =
    useState('');
  const [selectedWebhookPayload, setSelectedWebhookPayload] = useState('');
  const [selectedWebhookDescription, setSelectedWebhookDescription] =
    useState('');
  const [isSaving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const [isUrlValid, setIsUrlValid] = useState(true);
  const {configState} = useWizardState();

  const handleClosePopper = () => setOpen(false);

  const getValues = useCallback(
    () => ({
      webhookName: selectedWebhookName,
      webhookBaseUrl: selectedWebhookBaseUrl,
      webhookTriggerEvent: selectedWebhookTriggerEvent,
      webhookDescription: selectedWebhookDescription,
      webhookPayload: selectedWebhookPayload,
    }),
    [
      selectedWebhookName,
      selectedWebhookBaseUrl,
      selectedWebhookTriggerEvent,
      selectedWebhookDescription,
      selectedWebhookPayload,
    ]
  );

  const validationRules = useMemo(
    () => ({
      webhookName: {required: true, maxLength: 512},
      webhookBaseUrl: {required: true},
      webhookTriggerEvent: {required: true},
      webhookDescription: {required: false, maxLength: 1024},
      webhookPayload: {required: false},
    }),
    []
  );

  const {handleBlur, hasError} = useFieldValidation(getValues, validationRules);
  const togglePopper = () => setOpen(!open);

  const disableSaveButton = () => {
    const values = getValues();

    const hasErrors = Object.keys(values).some((field) => {
      const rules = validationRules[field];
      return (
        hasError(field) !== '' || (rules && rules.required && !values[field])
      );
    });

    return isSaving || !isUrlValid || !isPayloadValid || hasErrors;
  };

  const webhookBreadcrumbs = [
    {text: 'Webhooks', link: WEBHOOKS_LINK},
    {text: selectedWebhook?.name},
  ];

  const removePoolWebhook = async () => {
    dispatch(requestDeleteResource(WEBHOOKS, selectedWebhook?.id));
    try {
      const basePath = mapResourceToPath(WEBHOOKS, {deploymentId});
      const path = `${basePath}/${selectedWebhook?.id}`;
      await apiDelete({path});
      dispatch(receiveDeleteResource(WEBHOOKS, selectedWebhook?.id));
      successSnackbar(`Webhook has been deleted`);
    } catch (err) {
      handleApiError(err);
    }
    dispatch(fetchTableData(WEBHOOKS));
    dispatch(uncheckItem(WEBHOOKS, selectedWebhook));
    dispatch(goBack());
  };

  const handleDeleteWebhook = () => {
    triggerDialog({
      title: 'Delete Webhook?',
      message: `Are you sure you want to delete the webhook ${selectedWebhook?.name}?`,
      onConfirm: removePoolWebhook,
    });
  };

  const handleChangeAssociatedResource = (resources) => {
    const associatedResources = resources.map((resource) => ({
      resourceId: resource.value,
      resourceName: resource.label,
    }));

    setSelectedWebhookAssociatedResources(associatedResources);
  };

  const handleChangeTriggerEvent = (triggerEvent) => {
    setSelectedWebhookTriggerEvent(triggerEvent || '');
  };

  const editWebhook = async (data) => {
    const basePath = mapResourceToPath(WEBHOOKS, {deploymentId});
    const path = `${basePath}/${selectedWebhook?.id}`;
    return put({path, data});
  };

  const hostname = hostnameFromUrl(selectedWebhookBaseUrl);
  const saveWebhookCA = async (data) => {
    const path = `deployments/${deploymentId}/settings/webhook-trust-ca-${hostname
      .replaceAll('.', '_')
      .trim()}`;
    try {
      await put({path, data});
    } catch (err) {
      handleApiError(err);
    }
  };

  const handleEditWebhookCA = async () => {
    if (configState.CertificateDropbox !== undefined) {
      const caData = {
        deploymentId: `${deploymentId}`,
        value: btoa(configState.CertificateDropbox),
      };
      await saveWebhookCA(caData);
    }
  };

  const handleEditWebhook = async () => {
    setSaving(true);
    try {
      const data = {
        name: selectedWebhookName.trim(),
        endpoint: selectedWebhookBaseUrl.trim(),
        eventType: selectedWebhookTriggerEvent.trim(),
        additionalData: selectedWebhookPayload.trim(),
        description: selectedWebhookDescription.trim(),
        associatedResources: webhookAssociatedResources.map(
          (resource) => resource.resourceId
        ),
      };

      await editWebhook(data);

      successSnackbar(`Webhook "${selectedWebhookName}" has been updated.`);
      dispatch(fetchSelectedWebhook(selectedWebhook?.id));
      setPendingChanges(false);
      setSaving(false);
      dispatch(goBack());
    } catch (error) {
      dispatch(handleApiError(error));
    } finally {
      setSaving(false);
    }
  };

  const onURLValidityChange = useCallback((valid) => {
    setIsUrlValid(valid);
  }, []);

  const handleCancel = () => {
    setPendingChanges(false);
    dispatch(goBack());
  };

  useEffect(() => {
    dispatch(fetchTableData(POOLS));
  }, [dispatch, deploymentId]);

  const checkPendingChanges = () => {
    const fields = {
      name: selectedWebhookName,
      associatedResources: webhookAssociatedResources,
      endpoint: selectedWebhookBaseUrl,
      eventType: selectedWebhookTriggerEvent,
      additionalData: selectedWebhookPayload,
      description: selectedWebhookDescription,
    };

    return Object.keys(fields).some(
      (key) => fields[key] !== selectedWebhook?.[key]
    );
  };

  useEffect(() => {
    const hasPendingChanges = checkPendingChanges();

    setPendingChanges(hasPendingChanges);
  }, [
    selectedWebhook,
    selectedWebhookName,
    webhookAssociatedResources,
    selectedWebhookBaseUrl,
    selectedWebhookTriggerEvent,
    selectedWebhookPayload,
    selectedWebhookDescription,
  ]);

  useEffect(() => {
    if (!deploymentId) return undefined;

    const urlWebhookId = webhookId || '';
    const selectedWebhookId = selectedWebhook?.id || '';
    if (!urlWebhookId) {
      dispatch(
        openDialog(
          'Unable to retrieve Webhook',
          `No Webhook with ID=${urlWebhookId} was found`,
          () => dispatch(push(WEBHOOKS_LINK)),
          false
        )
      );
    }

    if (urlWebhookId !== selectedWebhookId) {
      dispatch(fetchSelectedWebhook(urlWebhookId));
    }
    return function cleanup() {
      dispatch(saveVariable('selectedWebhook', {}));
    };
  }, [deploymentId]);

  useEffect(() => {
    if (!isEmpty(selectedWebhook && deploymentId)) {
      setSelectedWebhookName(selectedWebhook?.name);
      setSelectedWebhookAssociatedResources(
        selectedWebhook?.associatedResources
      );
      setSelectedWebhookBaseUrl(selectedWebhook?.endpoint);
      setSelectedWebhookTriggerEvent(selectedWebhook?.eventType);
      setSelectedWebhookPayload(selectedWebhook?.additionalData);
      setSelectedWebhookDescription(selectedWebhook?.description);
    }
  }, [selectedWebhook, deploymentId]);

  const associatedPoolsValues = webhookAssociatedResources?.map((resource) => ({
    value: resource.resourceId,
    label: resource.resourceName,
  }));

  const associatedPoolsOptions = useMemo(() => {
    const lowerCaseFilter = textInputFilter.toLowerCase();

    return pools?.reduce((options, pool) => {
      if (pool.poolName.toLowerCase().includes(lowerCaseFilter)) {
        options.push({
          value: pool.poolId,
          label: pool.poolName,
        });
      }

      return options;
    }, []);
  }, [pools, textInputFilter]);

  if (!selectedWebhook) {
    return null;
  }

  const popperModifiers = [
    {
      name: 'offset',
      options: {
        offset: [0, 10],
      },
    },
  ];

  return (
    <Root>
      <PageWrapper>
        <PageHeader
          titleText={selectedWebhook.name}
          breadcrumbs={webhookBreadcrumbs}
          actionButton={
            <WebhookButton
              buttonType="deleteButton"
              onClick={handleDeleteWebhook}
              selectedWebhook={selectedWebhook}
              disabled={isEmpty(selectedWebhook)}
            />
          }
        />
        <Box className={classes.formWrapper}>
          <LabeledTextField
            label="Name"
            placeholder="Webhook name"
            required
            value={selectedWebhookName}
            onChange={(e) => setSelectedWebhookName(e.target.value)}
            error={!!hasError('webhookName')}
            onBlur={handleBlur('webhookName')}
            helperText={hasError('webhookName')}
          />

          <LabeledTextField
            label="Description"
            placeholder="Brief description of the webhook"
            value={selectedWebhookDescription}
            onChange={(e) => setSelectedWebhookDescription(e.target.value)}
            error={!!hasError('webhookDescription')}
            onBlur={handleBlur('webhookDescription')}
            helperText={hasError('webhookDescription')}
          />

          <SearchableAssociatedPools
            label="Associated Pool(s)"
            note="No associated pool will render webhook dormant"
            id="webhook-associated-pools"
            data-testid="webhook-associated-pools-select"
            disabledMessage=""
            emptyMessage="No pools found"
            isDisabled={!pools?.length}
            isLoading={isFetching}
            isSearchable
            onChange={handleChangeAssociatedResource}
            onTextInput={(text) => {
              setTextInputFilter(text);
            }}
            onClear={() => {
              setTextInputFilter('');
            }}
            clearOnSelect
            placeholderText="Select..."
            isMulti
            value={associatedPoolsValues}
            options={associatedPoolsOptions}
            classes={classes}
          />

          <LabeledUrlTextField
            label="Base URL"
            placeholder="https://"
            value={selectedWebhookBaseUrl}
            onChange={(e) => setSelectedWebhookBaseUrl(e.target.value)}
            onValidityChange={onURLValidityChange}
            error={!!hasError('webhookBaseUrl')}
            onBlur={handleBlur('webhookBaseUrl')}
            helperText={hasError('webhookBaseUrl')}
          />

          <TriggerEventDropdown
            value={selectedWebhookTriggerEvent}
            onChange={(event) => handleChangeTriggerEvent(event.target.value)}
            error={!!hasError('webhookTriggerEvent')}
            onBlur={handleBlur('webhookTriggerEvent')}
            helperText={hasError('webhookTriggerEvent')}
          />

          <Grid container direction="column">
            <PayloadTooltip
              open={open}
              anchorRef={anchorRef}
              togglePopper={togglePopper}
              handleClosePopper={handleClosePopper}
              popperModifiers={popperModifiers}
            />
            <PayloadInput
              selectedWebhookPayload={selectedWebhookPayload}
              setSelectedWebhookPayload={setSelectedWebhookPayload}
              setIsPayloadValid={setIsPayloadValid}
              isPayloadValid={isPayloadValid}
            />
          </Grid>

          <CertificateDropbox />

          <Grid container justifyContent="flex-end" gap={2}>
            <Divider className={classes.divider} />
            <Grid container item justifyContent="flex-end" gap={2}>
              <Button
                className={classes.cancelButton}
                variant="outlined"
                size="medium"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <SaveButton
                className={classes.saveButton}
                variant="contained"
                size="small"
                disabled={disableSaveButton()}
                saving={isSaving}
                onClick={() => {
                  handleEditWebhook();
                  handleEditWebhookCA();
                }}
              >
                Save
              </SaveButton>
            </Grid>
          </Grid>
        </Box>
      </PageWrapper>
    </Root>
  );
}
