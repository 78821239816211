import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {put, get} from 'api/Api';
import {saveVariable, handleApiError} from 'redux/actions/dataActions';
import CasmCookies from 'utils/cookies';
import {SAML} from 'utils/constants';
import {isEmpty} from 'utils/utils';
import config from 'config';

import {selectVariable} from 'utils/reduxSelectors';

const CURRENT_USER_RESOURCE_NAME = 'currentUser';
const LDAP_IDP_NAME = 'ldap';
const INSTALLABLE_AD_USER_TOKEN_TYPE = 'standalone_ad_user';

const useAcceptPolicyTracking = () => {
  const dispatch = useDispatch();

  const token = CasmCookies.getDecodedAuthToken();

  const isSamlUser = token?.idp === SAML;
  const isActiveDirectoryUser =
    token?.idp === LDAP_IDP_NAME &&
    token?.tokenType === INSTALLABLE_AD_USER_TOKEN_TYPE;

  // At this time we do not track if non-admin users (for SAML and AD multi-admin config) have accepted the required policies to use the Admin Console
  const ignoreAcceptanceTracking =
    isEmpty(token) || isSamlUser || isActiveDirectoryUser;

  const userPath = config.STANDALONE
    ? 'auth/ad/admin'
    : `auth/users/${token?.userId}`;

  const currentUser = useSelector(
    (state) => selectVariable(state, CURRENT_USER_RESOURCE_NAME) || null
  );

  const getCurrentUser = async (refresh = false) => {
    if (ignoreAcceptanceTracking) {
      return null;
    }

    if (currentUser && !refresh) {
      return currentUser;
    }

    try {
      const userResponse = await get({path: userPath});
      dispatch(saveVariable(CURRENT_USER_RESOURCE_NAME, userResponse.data));
      return userResponse.data;
    } catch (error) {
      dispatch(handleApiError(error));
      dispatch(saveVariable(CURRENT_USER_RESOURCE_NAME, null));
      return null;
    }
  };

  const updateCurrentUser = async (data) => {
    if (ignoreAcceptanceTracking) {
      return;
    }

    try {
      // Update user record
      await put({path: userPath, data});
      // Fetch updated user record & replace old value
      await getCurrentUser(true);
    } catch (error) {
      dispatch(handleApiError(error));
      // Throw API error so calling component can also perform error logic
      throw error;
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return {
    currentUser,
    updateCurrentUser,
  };
};

export default useAcceptPolicyTracking;
