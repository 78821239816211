import moment from 'moment';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import StatusChip from 'components/CAM/display/StatusChip/StatusChip';

const PREFIX = 'ExpiryStatus';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));
function ServiceAccountExpiryStatus({expiresOnString}) {
  let displayItem = '';
  const twoWeeksFromNow = moment().add(14, 'days');
  const expiresOn = moment(expiresOnString, moment.ISO_8601);
  if (!expiresOn.isValid()) {
    displayItem = ''; // this should not happen unless a bug occur
  } else if (expiresOn.isBefore(moment())) {
    displayItem = <StatusChip type="error" label="Expired" />;
  } else if (expiresOn.isBefore(twoWeeksFromNow)) {
    displayItem = <StatusChip type="warning" label="Expires soon" />;
  } else {
    displayItem = <StatusChip type="ok" label="Active" />;
  }
  return <Root className={classes.root}>{displayItem}</Root>;
}
ServiceAccountExpiryStatus.propTypes = {
  expiresOnString: PropTypes.string.isRequired,
};
export default ServiceAccountExpiryStatus;
