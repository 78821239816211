import {useDispatch} from 'react-redux';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {Grid, Button, Divider} from '@mui/material';
import classNames from 'classnames';
import {useDropzone} from 'react-dropzone';
import {useEffect} from 'react';
import forge from 'node-forge';
import convert from 'xml-js';
import validator from 'validator';
import PropTypes from 'prop-types';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import useSnackbar from 'hooks/useSnackbar';
import {isEmpty, removeX509PEMCertificateHeader} from 'utils/utils';
import UploadIcon from 'icons/UploadIcon';
import {openErrorDialog} from 'redux/actions/errorDialogActions';

const PREFIX = 'SamlIdpSettingsInput';

const classes = {
  input: `${PREFIX}-input`,
  inputSectionSubtitle: `${PREFIX}-inputSectionSubtitle`,
  gridItem: `${PREFIX}-gridItem`,
  dropbox: `${PREFIX}-dropbox`,
  dropboxActive: `${PREFIX}-dropboxActive`,
  fileRow: `${PREFIX}-fileRow`,
  buttonText: `${PREFIX}-buttonText`,
  dropText: `${PREFIX}-dropText`,
  disabled: `${PREFIX}-disabled`,
  fileIcon: `${PREFIX}-fileIcon`,
  dropboxDivideContainer: `${PREFIX}-dropboxDivideContainer`,
  dropboxDivider: `${PREFIX}-dropboxDivider`,
  dropboxDivideText: `${PREFIX}-dropboxDivideText`,
};

const Root = styled('div')(({theme}) => ({
  width: '100%',

  [`& .${classes.input}`]: {
    width: '100%',
  },

  [`& .${classes.inputSectionSubtitle}`]: {
    ...theme.createPage.inputSectionSubtitle,
    marginBottom: '1rem',
  },

  [`& .${classes.gridItem}`]: {
    marginRight: '3.1rem',
    marginBottom: '1rem',
  },

  [`& .${classes.dropbox}`]: {
    width: '100%',
    boxSizing: 'border-box',
    border: '1px solid #DDDBDA',
    borderRadius: '4px',
    padding: '4px',
  },

  [`& .${classes.dropboxActive}`]: {
    border: '1px solid #0076DE',
    boxShadow: '0 0 3px 0 rgba(0,118,222,0.5)',
  },

  [`& .${classes.fileRow}`]: {
    display: 'flex',
    margin: '4px 0px',
  },

  [`& .${classes.buttonText}`]: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    letterSpacing: '0.25px',
    marginLeft: '8px',
    textTransform: 'none',
  },

  [`& .${classes.dropText}`]: {
    margin: '8px',
    fontSize: '0.75rem',
  },

  [`& .${classes.disabled}`]: {
    color: '#999',
  },

  [`& .${classes.fileIcon}`]: {
    color: theme.palette.surface.grey,
  },

  [`& .${classes.dropboxDivideContainer}`]: {
    alignItems: 'center',
    marginTop: '12px',
  },

  [`& .${classes.dropboxDivider}`]: {
    color: theme.palette.divider,
  },

  [`& .${classes.dropboxDivideText}`]: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '1.25px',
    lineHeight: '1rem',
    textAlign: 'center',
  },
}));

const XML_MAX_FILE_SIZE = 500000;
const INVALID_XML_FILE_ERROR_MESSAGE =
  'Invalid file. Must be an IDP metadata file in .xml format.';
const addCertificateHeader = (certificate) =>
  `-----BEGIN CERTIFICATE-----${certificate}-----END CERTIFICATE-----`;

/** Get certificate error message, return null if certificate is valid */
const getCertificateErrorMsg = (certificate) => {
  if (isEmpty(certificate)) {
    return 'Please provide a certificate';
  }
  const {pki} = forge;
  try {
    const cert = pki.certificateFromPem(addCertificateHeader(certificate));

    // Validate certificate date
    const {notBefore} = cert.validity;
    const {notAfter} = cert.validity;

    const now = new Date();

    if (now.getTime() < notBefore.getTime()) {
      return `This certificate is not yet valid. It will be valid after ${notBefore}`;
    }

    if (now.getTime() > notAfter.getTime()) {
      return `This certificate has expired. It was valid before ${notAfter}`;
    }
  } catch (err) {
    return 'This is not a valid PEM-encoded X.509 certificate';
  }
  return null;
};

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  style: {maxHeight: '50vh'},
};

const selectProps = {
  MenuProps: menuProps,
  IconComponent: KeyboardArrowDown,
  displayEmpty: true,
};

const inputProps = {
  style: {
    fontFamily: 'Roboto',
    fontSize: '0.9rem',
    width: '100%',
    height: '32px',
  },
};

function SamlIdpSettingsInput({
  idpCertificate,
  signInUrl,
  setIdpCertificate,
  setSignInUrl,
  setIsValid,
  disabled,
  labelOnField,
}) {
  const dispatch = useDispatch();

  const {successSnackbar} = useSnackbar();

  const isSignInUrlValid = (url) => {
    let requireTld = true;
    const protocols = ['https'];
    if (idpCertificate.includes('localhost')) {
      requireTld = false;
      protocols.push('http');
    }

    return validator.isURL(url, {
      protocols,
      require_tld: requireTld,
      require_protocol: true,
      require_valid_protocol: true,
    });
  };

  useEffect(() => {
    setIsValid(
      isSignInUrlValid(signInUrl) &&
        getCertificateErrorMsg(idpCertificate) === null
    );
  }, [idpCertificate, signInUrl]);

  const clearAllFields = () => {
    setSignInUrl('');
    setIdpCertificate('');
  };

  const displayXmlLoadErrorMessage = (errorMessage) => {
    const defaultErrorMessage = INVALID_XML_FILE_ERROR_MESSAGE;
    dispatch(
      openErrorDialog(
        'Fail to load configuration from file',
        errorMessage || defaultErrorMessage
      )
    );
  };

  const handleXMLFile = (xml) => {
    try {
      // You can set content in state and show it in render.
      const jsonObj = convert.xml2json(xml, {compact: false, spaces: 4});
      const jsonData = JSON.parse(jsonObj);

      const idpSSODescriptor = jsonData.elements
        .find(
          (e) =>
            e.name === 'EntityDescriptor' || e.name === 'md:EntityDescriptor'
        )
        .elements.find(
          (e) =>
            e.name === 'IDPSSODescriptor' || e.name === 'md:IDPSSODescriptor'
        ).elements;

      const signInUrlFromXml = idpSSODescriptor
        .filter(
          (e) =>
            e.name === 'SingleSignOnService' ||
            e.name === 'md:SingleSignOnService'
        )
        .find(
          (e) =>
            e.attributes.Binding ===
            'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect'
        ).attributes.Location;

      const keyDescriptor = idpSSODescriptor.find(
        (e) =>
          (e.name === 'KeyDescriptor' || e.name === 'md:KeyDescriptor') &&
          (!e.attributes || e.attributes.use !== 'encryption')
      ).elements;

      const keyInfo = keyDescriptor.find(
        (e) => e.name === 'KeyInfo' || e.name === 'ds:KeyInfo'
      ).elements;
      const x509Data = keyInfo.find(
        (e) => e.name === 'X509Data' || e.name === 'ds:X509Data'
      ).elements;
      const x509Cert = x509Data.find(
        (e) => e.name === 'X509Certificate' || e.name === 'ds:X509Certificate'
      ).elements;
      const idpCertificateFromXML = x509Cert.find(
        (e) => e.type === 'text'
      ).text;

      setSignInUrl(signInUrlFromXml);
      setIdpCertificate(idpCertificateFromXML);
    } catch (err) {
      clearAllFields();
      displayXmlLoadErrorMessage();
      return;
    }
    successSnackbar('SSO configuration successfully loaded from the XML file!');
  };

  const onDropAccepted = (acceptedFiles) => {
    const reader = new window.FileReader();
    reader.onerror = () => {
      clearAllFields();
      displayXmlLoadErrorMessage();
    };
    reader.onload = () => {
      handleXMLFile(reader.result);
    };
    acceptedFiles.map((file) => {
      reader.file = file;
      reader.readAsText(file);
      return true;
    });
  };

  const onDropRejected = (rejectedFiles) => {
    let rejectReason;
    if (rejectedFiles.length > 1) {
      rejectReason =
        'Multiple files not supported. Upload only individual files.';
    } else {
      rejectedFiles.map((file) => {
        if (file.type !== 'text/xml') {
          rejectReason = INVALID_XML_FILE_ERROR_MESSAGE;
        } else if (file.size > XML_MAX_FILE_SIZE) {
          rejectReason =
            'The file you are trying to upload exceeds the maximum file size allowed.' +
            ` Please select a file that is smaller than ${
              XML_MAX_FILE_SIZE / 1000
            } kb and try again`;
        } else {
          rejectReason = 'Unexpected error, please try again later.';
        }
        return true;
      });
    }
    clearAllFields();
    displayXmlLoadErrorMessage(rejectReason);
  };

  const {
    getRootProps,
    getInputProps,
    open: openFileViewer,
  } = useDropzone({
    accept: {
      'application/xml': ['.xml'],
    },
    maxSize: XML_MAX_FILE_SIZE,
    multiple: false,
    noClick: true,
    disabled,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <Root>
      <Typography className={classes.inputSectionSubtitle}>
        Add your identity provider information
      </Typography>
      <Grid item className={classes.gridItem} xs={12}>
        <div
          data-testid="xmp-drop"
          className={classNames(classes.dropbox, {
            [classes.dropboxActive]: !disabled,
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Grid container className={classes.gridContainer}>
            <Grid item>
              <Button
                disabled={disabled}
                variant="outlined"
                onClick={openFileViewer}
                data-testid="button-select-xml"
              >
                <UploadIcon
                  className={classNames({[classes.disabled]: disabled})}
                  iconColor={!disabled ? '' : '#999'}
                />
                <Typography
                  className={classNames(classes.buttonText, {
                    [classes.disabled]: disabled,
                  })}
                >
                  Select XML File
                </Typography>
              </Button>
            </Grid>

            <Grid item>
              <Typography
                className={classNames(classes.dropText, {
                  [classes.disabled]: disabled,
                })}
              >
                ... or drop the IDP XML Metadata file
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item className={classes.gridItem} xs={12}>
        <Grid container className={classes.dropboxDivideContainer}>
          <Grid item xs={5}>
            <Divider className={classes.dropboxDivider} />
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.dropboxDivideText}>OR</Typography>
          </Grid>
          <Grid item xs={5}>
            <Divider className={classes.dropboxDivider} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.gridItem} xs={12}>
        {!labelOnField && (
          <div className={classes.label}>Identity Provider Login URL</div>
        )}
        <TextField
          disabled={disabled}
          label={labelOnField ? 'Identity Provider Login URL' : ''}
          className={classes.input}
          data-testid="saml-signin-url"
          autoComplete="off"
          required
          margin="none"
          variant="outlined"
          SelectProps={selectProps}
          onChange={(event) => setSignInUrl(event.target.value)}
          InputProps={
            labelOnField
              ? {name: 'signInUrl'}
              : {...inputProps, name: 'signInUrl'}
          }
          value={signInUrl}
          helperText={
            !isSignInUrlValid(signInUrl) && 'Must be a valid HTTPS URL'
          }
          error={!isEmpty(signInUrl) && !isSignInUrlValid(signInUrl)}
          placeholder="Enter the sign-in URL"
        />
      </Grid>
      {!labelOnField && (
        <div className={classes.label}>Identity Provider Certificate</div>
      )}
      <Grid item className={classes.gridItem} xs={12}>
        <TextField
          label={labelOnField ? 'Identity Provider Certificate' : ''}
          disabled={disabled}
          className={classes.input}
          data-testid="saml-idp-certificate"
          autoComplete="off"
          required
          margin="none"
          variant="outlined"
          multiline
          minRows={8}
          SelectProps={selectProps}
          onChange={(event) =>
            setIdpCertificate(
              removeX509PEMCertificateHeader(event.target.value)
            )
          }
          InputProps={{name: 'idpCertificate'}}
          value={idpCertificate}
          placeholder="Enter the IDP certificate"
          error={
            !isEmpty(idpCertificate) &&
            getCertificateErrorMsg(idpCertificate) !== null
          }
          helperText={
            !isEmpty(idpCertificate) && getCertificateErrorMsg(idpCertificate)
          }
        />
      </Grid>
    </Root>
  );
}

SamlIdpSettingsInput.propTypes = {
  idpCertificate: PropTypes.string.isRequired,
  signInUrl: PropTypes.string.isRequired,
  setIdpCertificate: PropTypes.func.isRequired,
  setSignInUrl: PropTypes.func.isRequired,
  setIsValid: PropTypes.func,
  disabled: PropTypes.bool,
  labelOnField: PropTypes.bool,
};

SamlIdpSettingsInput.defaultProps = {
  setIsValid: () => {},
  disabled: false,
  labelOnField: true, // existing IDP setting page have label on the line before the field, probably best to keep it as is for now to keep things consistent.
};

export default SamlIdpSettingsInput;
