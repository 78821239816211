import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Typography from '@mui/material/Typography';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {stylesFromFigma} from 'themes/stylesFromFigma';

import CopyToClipboard from 'components/CAM/buttons/CopyToClipboard/CopyToClipboard';
import useSnackbar from 'hooks/useSnackbar';

const PREFIX = 'CodeBoxWithClipboard';

const classes = {
  expandButton: `${PREFIX}-expandButton`,
  codeBlock: `${PREFIX}-codeBlock`,
};

const Root = styled('div')(() => ({
  [`& .${classes.expandButton}`]: {
    color: '#00000099',
    width: '24px',
    height: '24px',
  },
  [`& .${classes.codeBlock}`]: {
    ...stylesFromFigma.codeBlockStyle,
    color: '#000000DE',
  },
}));

function CodeBoxWithClipboard({
  code,
  tooltipText,
  renderedCode,
  snackbarMessage,
}) {
  const {successSnackbar} = useSnackbar();

  const [open, setOpen] = useState(false);
  const isMultiline = code.split('\n').length > 2;

  return (
    <Root>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          whiteSpace: 'pre-wrap',
          borderRadius: '4px',
        }}
        bgcolor="#F8F8FB"
        maxWidth={800}
        className={classes.codeBlock}
      >
        <Typography
          className={classes.codeBlock}
          style={{
            paddingTop: '12px',
            paddingBottom: '12px',
            paddingLeft: '16px',
            paddingRight: '16px',
            color: '#000000DE',
            minWidth: '50%',
            overflowWrap: 'break-word',
          }}
          data-testid="code-box"
        >
          {isMultiline ? (
            <Collapse collapsedSize="32px" in={open}>
              {code}
            </Collapse>
          ) : (
            <>{renderedCode || code}</>
          )}
        </Typography>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
        >
          <CopyToClipboard
            text={code}
            tooltipProps={{
              text: tooltipText,
              placement: 'right',
            }}
            onCopy={() => successSnackbar(snackbarMessage)}
            id="copy-command-button"
          />
          {isMultiline && (
            <IconButton
              onClick={() => setOpen(!open)}
              style={{
                width: '120px',
                fontFamily: 'Roboto',
                weight: 500,
                fontSize: '13px',
                lineHeight: '22px',
                letterSpacing: '0.46px',
                color: 'rgba(0, 0, 0, 0.6)',
                borderRadius: 'unset',
              }}
              size="small"
            >
              {open ? (
                <>
                  SHOW LESS
                  <ExpandLessIcon className={classes.expandButton} />
                </>
              ) : (
                <>
                  SHOW MORE
                  <ExpandMoreIcon className={classes.expandButton} />
                </>
              )}
            </IconButton>
          )}
        </div>
      </Box>
    </Root>
  );
}

CodeBoxWithClipboard.propTypes = {
  code: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  renderedCode: PropTypes.node,
  snackbarMessage: PropTypes.string,
};

CodeBoxWithClipboard.defaultProps = {
  tooltipText: '',
  renderedCode: null,
  snackbarMessage: 'Copied to the clipboard',
};

export default CodeBoxWithClipboard;
