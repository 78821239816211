import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import {useHistory, useParams} from 'react-router-dom';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import CAMPagination from 'components/common/Pagination';
import AddServiceAccount from 'components/deployments/AddServiceAccount';
import {openDialog} from 'redux/actions/confirmationDialogActions';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import config from 'config';
import {CREATE_DEPLOYMENT_SERVICE_ACCOUNT} from 'utils/constants';
import useSnackbar from 'hooks/useSnackbar';
import {getCreateDeploymentSAPath} from 'utils/paths';
import {clearDeploymentSACred} from 'redux/actions/deploymentSACredActions';
import ServiceAccountsTable from '../common/ServiceAccountsTable';
import ServiceAccountDownloadModal from './ServiceAccountDownloadModal';

const PREFIX = 'EditDeploymentDeploymentServiceAccountsTab';

const classes = {
  link: `${PREFIX}-link`,
  bodyText: `${PREFIX}-bodyText`,
  addButton: `${PREFIX}-addButton`,
};

const Root = styled('div')(() => ({
  [`& .${classes.link}`]: {
    textDecoration: 'underline',
    color: 'white',
  },
  [`& .${classes.bodyText}`]: {
    fontWeight: 500,
  },
  [`& .${classes.addButton}`]: {
    width: 'auto',
    marginLeft: 'auto',
  },
}));

function EditDeploymentDeploymentServiceAccountsTab({
  serviceAccounts,
  total,
  rowsPerPage,
  page,
  isLoadingServiceAccounts,
  onAddServiceAccount,
  onDeleteServiceAccount,
  onPageChange,
  onRowsPerPageChange,
}) {
  const dispatch = useDispatch();
  const {successSnackbar} = useSnackbar();
  const history = useHistory();
  const {deploymentId} = useParams();

  // logics around displaying new deployment service account
  const [newDeploymentSACred, setNewDeploymentSACred] = useState(undefined);
  const {credentials} = useSelector((state) => state.deploymentSACred);
  const [displayAccountDialog, setDisplayAccountDialog] = useState(false);
  useEffect(() => {
    if (credentials) {
      setNewDeploymentSACred(credentials);
      setDisplayAccountDialog(true);
      // clear credential from redux after it is loaded into state
      dispatch(clearDeploymentSACred());
    }
  }, [credentials]);

  const [filteredServiceAccounts, setFilteredServiceAccounts] =
    useState(serviceAccounts);
  const [serviceAccountsSearchTerm, setServiceAccountsSearchTerm] =
    useState('');

  const handleSearchInputChange = (event) => {
    const searchInputText = event.target.value || '';
    setServiceAccountsSearchTerm(searchInputText);
  };

  const handleDeleteServiceAccount = (serviceAcc) => {
    dispatch(
      openDialog(
        'Delete Service Account?',
        `Are you sure you want to delete Service Account ${serviceAcc.keyName}?`,
        () => onDeleteServiceAccount(serviceAcc)
      )
    );
  };

  useEffect(() => {
    setFilteredServiceAccounts(
      serviceAccounts.filter((key) =>
        key.keyName.includes(serviceAccountsSearchTerm.trim())
      )
    );
  }, [serviceAccounts, serviceAccountsSearchTerm]);

  const renderPagination = () => (
    <CAMPagination
      dataTestId="dsa-table-pagination"
      rowsPerPageOptions={[10, 25, 50]}
      component="div"
      total={total}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );

  const tableHeaders = [
    {
      id: 'keyId',
      label: 'ID',
    },
    {
      id: 'keyName',
      label: 'Name',
    },
    {
      id: 'createdOn',
      label: 'Created on',
    },
  ];

  return (
    <Root>
      <CAMCard>
        <GridN singleColumn>
          <SectionHeader displayText="DEPLOYMENT SERVICE ACCOUNTS">
            <>
              {newDeploymentSACred && (
                <ServiceAccountDownloadModal
                  isDeploymentSA
                  serviceAccount={newDeploymentSACred}
                  open={displayAccountDialog}
                  onClose={() => {
                    setNewDeploymentSACred(undefined);
                    setDisplayAccountDialog(false);
                  }}
                  onCopyToClipboard={() => {
                    successSnackbar(
                      'Deployment service account has been copied to your clipboard.'
                    );
                  }}
                  filenameToSave="anyware-manager-deployment-service-account.json"
                />
              )}
              <AddServiceAccount
                createCallback={onAddServiceAccount}
                serviceAccountType="deployment"
              />
            </>
          </SectionHeader>
        </GridN>
        <GridN singleColumn>
          <ServiceAccountsTable
            handleSearchInputChange={handleSearchInputChange}
            serviceAccounts={filteredServiceAccounts}
            onRemove={handleDeleteServiceAccount}
            isLoading={isLoadingServiceAccounts}
            headers={tableHeaders}
          />
          {renderPagination()}
        </GridN>
      </CAMCard>
    </Root>
  );
}

EditDeploymentDeploymentServiceAccountsTab.propTypes = {
  serviceAccounts: PropTypes.array,
  total: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  isLoadingServiceAccounts: PropTypes.bool,
  onAddServiceAccount: PropTypes.func,
  onDeleteServiceAccount: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
};

EditDeploymentDeploymentServiceAccountsTab.defaultProps = {
  serviceAccounts: {},
  total: 0,
  rowsPerPage: 0,
  page: 0,
  isLoadingServiceAccounts: false,
  onAddServiceAccount: async () => {},
  onDeleteServiceAccount: () => {},
  onPageChange: () => {},
  onRowsPerPageChange: () => {},
};

export default EditDeploymentDeploymentServiceAccountsTab;
