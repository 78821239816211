import CircularProgress from '@mui/material/CircularProgress';
import {styled} from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import {
  StyledTable,
  StyledTableRow,
  StyledTableCell,
} from 'components/deployments/StyledTable';
import {formatDateTime} from 'utils/utils';
import StatusChip from 'components/CAM/display/StatusChip/StatusChip';
import {ERROR_STATUS, OK_STATUS} from 'utils/constants';

const PREFIX = 'LicenseTable';

const classes = {
  tableContainer: `${PREFIX}-tableContainer`,
  tableHead: `${PREFIX}-tableHead`,
  tableRow: `${PREFIX}-tableRow`,
  loadingLicenseBox: `${PREFIX}-loadingLicenseBox`,
  noLicenseFoundTitle: `${PREFIX}-noLicenseFoundTitle`,
  deleteColumn: `${PREFIX}-deleteColumn`,
  rightMargin: `${PREFIX}-rightMargin`,
};

const Root = styled('div')(() => ({
  [`& .${classes.tableContainer}`]: {
    marginTop: '1.5rem',
  },

  [`& .${classes.tableHead}`]: {
    height: '28px',
  },

  [`& .${classes.tableRow}`]: {
    height: '28px',
  },

  [`& .${classes.loadingLicenseBox}`]: {
    backgroundColor: '#FAFAF9',
    padding: '50px 40px',
    textAlign: 'center',
    width: '100%',
  },

  [`& .${classes.noLicenseFoundTitle}`]: {
    color: '#0076A9',
    textAlign: 'center',
  },

  [`& .${classes.deleteColumn}`]: {textAlign: 'center', width: '30px'},
  [`& .${classes.rightMargin}`]: {marginRight: '10px'},
}));

function getFeatureList(licenseData) {
  const features = [];
  if (licenseData && licenseData.features) {
    licenseData.features.forEach((feature) => {
      features.push(feature.name);
    });
  }

  if (features.length === 0) {
    return 'N/A';
  }

  return features.join(', ');
}

// Returns the expiry date of the license with the latest expiry date
function getLatestFeatureExpiry(licenseData) {
  let expiry = null;
  if (licenseData && licenseData.features) {
    licenseData.features.forEach((feature) => {
      const finalExpiry = new Date(feature.finalExpiry);
      if (expiry === null || finalExpiry > expiry) {
        expiry = finalExpiry;
      }
    });
  }

  if (!expiry) {
    return 'N/A';
  }

  return formatDateTime(expiry);
}

function isLicenseExpired(license) {
  if (!license || !license.data || !license.data.features) {
    return false;
  }

  const now = new Date();
  let expired = true;
  license.data.features.forEach((feature) => {
    const finalExpiry = new Date(feature.finalExpiry);
    if (finalExpiry > now) {
      expired = false;
    }
  });

  return expired;
}

function getLicenseStatusType(license) {
  return isLicenseExpired(license) ? ERROR_STATUS : OK_STATUS;
}

function getLicenseStatusText(license) {
  return isLicenseExpired(license) ? 'EXPIRED' : 'VALID';
}

function LicenseRow({license, onRemove}) {
  return (
    <StyledTableRow
      hover
      className={classNames(classes.tableRow)}
      data-testid="license-row"
      key={license.id}
    >
      <StyledTableCell data-testid={`${license.id}-license-regcode`}>
        {license.registrationCode}
      </StyledTableCell>
      <StyledTableCell>
        <StatusChip
          data-testid={`${license.id}-license-status-chip`}
          type={getLicenseStatusType(license)}
          label={getLicenseStatusText(license)}
        />
      </StyledTableCell>
      <StyledTableCell data-testid={`${license.id}-license-feature-list`}>
        {getFeatureList(license.data)}
      </StyledTableCell>
      <StyledTableCell data-testid={`${license.id}-license-final-expiry`}>
        {getLatestFeatureExpiry(license.data)}
      </StyledTableCell>
      <StyledTableCell className={classNames(classes.deleteColumn)}>
        <CAMButton
          testId="license-delete-button"
          textOnly
          buttonText="Delete"
          onClick={() => onRemove(license)}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
}
LicenseRow.propTypes = {
  license: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

function LicenseTable({licenses, onRemove, isLoading}) {
  const tableBodyWithLicenses = licenses.map((license) => {
    return <LicenseRow license={license} onRemove={onRemove} />;
  });

  const tableBodyLoadingLicenses = (
    <Root className={classes.loadingLicensesBox}>
      <div className={classes.noLicenseFoundTitle}>
        <CircularProgress className={classes.rightMargin} size={14} />
        Loading licenses...
      </div>
    </Root>
  );

  const renderTableBody = () => {
    if (!isLoading && licenses.length > 0) {
      return tableBodyWithLicenses;
    }
    return null;
  };

  const renderLoadingLicenses = () => {
    if (isLoading) {
      return tableBodyLoadingLicenses;
    }
    return null;
  };

  return (
    <div className={classes.tableContainer}>
      <StyledTable className={classes.table}>
        <TableHead>
          <StyledTableRow className={classes.tableHead}>
            <StyledTableCell key="regcode">Registration Code</StyledTableCell>
            <StyledTableCell key="status">Status</StyledTableCell>
            <StyledTableCell key="name">Features</StyledTableCell>
            <StyledTableCell key="createdOn">Expires</StyledTableCell>
            <StyledTableCell
              key="remove"
              className={classNames(classes.deleteColumn)}
            >
              Actions
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>{renderTableBody()}</TableBody>
      </StyledTable>
      {renderLoadingLicenses()}
    </div>
  );
}

LicenseTable.propTypes = {
  licenses: PropTypes.array,
  onRemove: PropTypes.func,
  isLoading: PropTypes.bool,
};

LicenseTable.defaultProps = {
  licenses: [],
  onRemove: () => {},
  isLoading: false,
};

export default LicenseTable;
