import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {ReactComponent as AwsIcon} from 'icons/aws.svg';
import {ReactComponent as AzureIcon} from 'icons/azure.svg';
import {ReactComponent as GcpIcon} from 'icons/gcp.svg';
import {ReactComponent as AmtIcon} from 'icons/office_computer.svg';
import {ReactComponent as OnPremIcon} from 'icons/onprem.svg';
import {ReactComponent as RsmIcon} from 'icons/hp-logo.svg';
import {AMT, AWS, AZURE, GCP, ONPREM, RSM, AME} from 'utils/constants';
import {mapProviders} from 'utils/Mappings';

const PREFIX = 'ProviderIcon';

const classes = {
  providerIcon: `${PREFIX}-providerIcon`,
};

const Root = styled('div')(() => ({
  [`& .${classes.providerIcon}`]: {
    width: '24px',
    maxHeight: '16px',
    verticalAlign: 'middle',
    marginRight: '10px',
  },
}));

function getProviderIcon(provider, iconProps) {
  switch (provider) {
    case AWS:
    case mapProviders(AWS):
      return <AwsIcon {...iconProps} />;
    case GCP:
    case mapProviders(GCP):
      return <GcpIcon {...iconProps} />;
    case AZURE:
    case mapProviders(AZURE):
      return <AzureIcon {...iconProps} />;
    case AMT:
    case mapProviders(AMT):
      return <AmtIcon {...iconProps} />;
    case RSM:
    case mapProviders(RSM):
      return <RsmIcon {...iconProps} />;
    case ONPREM:
    case mapProviders(ONPREM):
      return <OnPremIcon {...iconProps} />;
    case AME:
    case mapProviders(AME):
      return <OnPremIcon {...iconProps} />;
    default:
      return null;
  }
}

export default function ProviderIcon({provider}) {
  const iconProps = {
    alt: mapProviders(provider),
    className: classes.providerIcon,
    title: mapProviders(provider),
  };

  // Usage
  const icon = getProviderIcon(provider, iconProps);
  return <Root>{icon}</Root>;
}

ProviderIcon.propTypes = {
  provider: PropTypes.string.isRequired,
};
