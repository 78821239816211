import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FileIcon from '@mui/icons-material/AttachFile';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import checkSvg from 'icons/check-outlined.svg';
import errorSvg from 'icons/error-outlined.svg';
import UploadIcon from 'icons/UploadIcon';

const PREFIX = 'CredentialDropbox';

const classes = {
  dropbox: `${PREFIX}-dropbox`,
  dropboxActive: `${PREFIX}-dropboxActive`,
  gridContainer: `${PREFIX}-gridContainer`,
  gridItem: `${PREFIX}-gridItem`,
  fileRow: `${PREFIX}-fileRow`,
  buttonText: `${PREFIX}-buttonText`,
  dropText: `${PREFIX}-dropText`,
  fileIcon: `${PREFIX}-fileIcon`,
  statusIcon: `${PREFIX}-statusIcon`,
  errorText: `${PREFIX}-errorText`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.dropbox}`]: {
    width: '100%',
    boxSizing: 'border-box',
    border: '1px solid #DDDBDA',
    borderRadius: '4px',
  },

  [`&.${classes.dropboxActive}`]: {
    border: '1px solid #0076DE',
    boxShadow: '0 0 3px 0 rgba(0,118,222,0.5)',
  },

  [`& .${classes.gridContainer}`]: {
    padding: '4px',
  },

  [`& .${classes.gridItem}`]: {
    margin: 'auto 0px',
  },

  [`&.${classes.fileRow}`]: {
    display: 'flex',
    margin: '4px 0px',
  },

  [`& .${classes.buttonText}`]: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    letterSpacing: '0.25px',
    marginLeft: '8px',
    textTransform: 'none',
  },

  [`& .${classes.dropText}`]: {
    marginLeft: '8px',
    fontSize: '0.75rem',
  },

  [`& .${classes.fileIcon}`]: {
    color: theme.palette.surface.grey,
  },

  [`& .${classes.statusIcon}`]: {
    marginLeft: 'auto',
    marginRight: '12px',
  },

  [`& .${classes.errorText}`]: {
    color: 'rgba(255,4,4,0.87)',
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    letterSpacing: '0.21px',
    lineHeight: '20px',
  },
}));

function CredentialDropbox({
  cloudProvider,
  setCredentialCallback,
  setDisplayFile,
}) {
  const extractRequiredValues = (file, credObject, provider) => {
    const requiredValues = {};
    switch (provider) {
      case 'gcp':
        if (
          credObject.client_email &&
          credObject.project_id &&
          credObject.private_key
        ) {
          requiredValues.gcpEmail = credObject.client_email;
          requiredValues.gcpProjectId = credObject.project_id;
          requiredValues.gcpPrivateKey = credObject.private_key;
          setCredentialCallback(requiredValues);
          setDisplayFile(
            <div className={classes.fileRow}>
              <FileIcon className={classes.fileIcon} fontSize="inherit" />
              <Typography noWrap title={file.name}>
                {file.name}{' '}
              </Typography>
              <img src={checkSvg} alt="" className={classes.statusIcon} />
            </div>
          );
        } else {
          setCredentialCallback({});
          setDisplayFile(
            <div className={classes.fileRow}>
              <Typography className={classes.errorText}>
                Invalid file. Must be a key file in .json format.
              </Typography>
              <img src={errorSvg} alt="" className={classes.statusIcon} />
            </div>
          );
        }
        break;
      case 'aws':
      case 'azure':
      default:
        break;
    }
  };

  const onDropAccepted = useCallback((acceptedFiles) => {
    const reader = new window.FileReader();
    reader.onabort = () => {
      // TODO: handle abort scenario
    };
    reader.onerror = () => {
      // TODO: handle error scenario
    };
    reader.onload = () => {
      try {
        const credObject = JSON.parse(reader.result);
        extractRequiredValues(reader.file, credObject, cloudProvider);
      } catch (error) {
        setCredentialCallback({});
        setDisplayFile(
          <div className={classes.fileRow}>
            <Typography className={classes.errorText}>
              Invalid file. Must be a key file in .json format.
            </Typography>
            <img src={errorSvg} alt="" className={classes.statusIcon} />
          </div>
        );
      }
    };
    acceptedFiles.map((file) => {
      reader.file = file;
      reader.readAsText(file);
      return true;
    });
  }, []);

  const onDropRejected = useCallback((rejectedFiles) => {
    let rejectReason;
    if (rejectedFiles.length > 1) {
      rejectReason =
        'Multiple files not supported. Upload only individual files.';
    } else {
      rejectedFiles.map((file) => {
        if (file.type !== 'application/json') {
          rejectReason = 'Invalid file. Must be a key file in .json format.';
        } else if (file.size > 5000) {
          rejectReason = 'Invalid file. Exceeds maximum allowed size.';
        } else {
          rejectReason = 'Unexpected error, please try again later.';
        }
        return true;
      });
    }
    setDisplayFile(
      <div className={classes.fileRow}>
        <Typography className={classes.errorText}>
          {`${rejectReason}`}
        </Typography>
        <img src={errorSvg} alt="" className={classes.statusIcon} />
      </div>
    );
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileViewer,
  } = useDropzone({
    accept: {
      'application/json': ['.json'],
    },
    maxSize: 5000,
    multiple: false,
    noClick: true,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <Root
      className={classNames(classes.dropbox, {
        [classes.dropboxActive]: isDragActive,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Grid container className={classes.gridContainer}>
        <Grid item className={classes.gridItem}>
          <Button variant="outlined" onClick={openFileViewer}>
            <UploadIcon />
            <Typography className={classes.buttonText}>Select File</Typography>
          </Button>
        </Grid>
        <Grid item className={classes.gridItem}>
          <Typography className={classes.dropText}>or Drop Key File</Typography>
        </Grid>
      </Grid>
    </Root>
  );
}

CredentialDropbox.propTypes = {
  cloudProvider: PropTypes.oneOf(['gcp', 'azure', 'aws']).isRequired,
  setCredentialCallback: PropTypes.func,
  setDisplayFile: PropTypes.func,
};

CredentialDropbox.defaultProps = {
  setCredentialCallback: () => 'No credential callback provided',
  setDisplayFile: () => 'No display file setter function provided',
};

export default CredentialDropbox;
