import parse from 'html-react-parser';
import moment from 'moment';
import Parser from 'rss-parser/dist/rss-parser';
import striptags from 'striptags';
import {RELEASE_NOTES_BETA} from 'utils/constants';

export const releaseNotesOutputDateFormat = 'MMMM DD, YYYY';

const sanitizeContent = striptags.init_streaming_mode([
  'h3',
  'h4',
  'p',
  'ol',
  'ul',
  'li',
  'br',
  'em',
  'strong',
  'a',
]);

// check if the release note matches the provided release note type
const isNoteTypeMatching = (content, releaseType) => {
  const isBetaNote = content.includes('(Beta)');
  const isBetaType = releaseType === RELEASE_NOTES_BETA;
  return (!isBetaNote && !isBetaType) || (isBetaNote && isBetaType);
};

const parseItemContent = (content, releaseType) => {
  const parsedItems = {};
  parsedItems.notes = [];
  const contentToParse = sanitizeContent(content);

  if (!isNoteTypeMatching(contentToParse, releaseType)) {
    return parsedItems;
  }

  const elements = parse(contentToParse).filter(
    (element) => typeof element === 'object'
  );

  parsedItems.notes = elements;
  return parsedItems;
};

export const loadItemsFromFeed = async (url, releaseType) => {
  const rssParser = new Parser();
  const releaseNotesArray = [];

  let response;
  try {
    response = await window.fetch(url);

    if (response.status === 404) {
      return releaseNotesArray;
    }
  } catch (err) {
    return releaseNotesArray;
  }

  const feedString = await response.text();
  const feed = await rssParser.parseString(feedString);

  feed.items.forEach((item) => {
    const content = parseItemContent(item.content, releaseType) || {};
    if (content.notes && content.notes.length > 0) {
      const itemMoment = moment(item.pubDate, 'YYYY-MM-DD');
      releaseNotesArray.push({
        date: itemMoment.format(releaseNotesOutputDateFormat),
        timestamp: itemMoment.valueOf(),
        ...content,
        releaseType,
      });
    }
  });

  return releaseNotesArray;
};
