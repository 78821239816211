import IconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AutoRenew from '@mui/icons-material/Autorenew';
import CheckCircle from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import StartIcon from '@mui/icons-material/PlayCircleOutline';
import RestartIcon from '@mui/icons-material/Refresh';
import SyncIcon from '@mui/icons-material/Sync';
import Update from '@mui/icons-material/Update';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import {Grid} from '@mui/material/index';
import Paper from '@mui/material/Paper';
import AppBar from 'components/common/AppBar';
import {RemoteWorkstationErrorIndicator} from 'components/remoteWorkstations/utils';
import config from 'config';
import useDialog from 'hooks/useDialog';
import StoppedSVGIcon from 'icons/StoppedIcon';
import {openDeleteWorkstationDialog} from 'redux/actions/deleteRemoteWorkstationDialogActions';
import {
  restartMachine,
  startMachine,
  stopMachine,
} from 'redux/actions/remoteWorkstationActions';
import {
  AMT,
  AMT_AVAILABLE_POWER_OP_TO_HUMAN_READABLE_MAP,
  CLOUD_SERVICE_ACCOUNTS,
  dataLossWarningMsg,
  MONITOR_TELEMETRY_LATEST,
  WORKSTATIONS_LINK,
  WORKSTATION_POWERSTATE_RUNNING,
  WORKSTATION_POWERSTATE_STARTING,
  WORKSTATION_POWERSTATE_STOPPED,
  WORKSTATION_POWERSTATE_STOPPING,
  WORKSTATION_POWERSTATE_UPDATING,
} from 'utils/constants';
import {
  mapAmtAvailablePowerOperations,
  mapRemoteWorkstationStatus,
} from 'utils/Mappings';
import {selectData, selectResourceItem} from 'utils/reduxSelectors';
import {
  canPerformPowerActions,
  canStartWorkstation,
  canStopOrRestartWorkstation,
  getMachineStatus,
  isEmpty,
  isLatestMonitorTelemetryValid,
  powerStateUnknown,
} from 'utils/utils';
import DialogTextInput from 'components/CAM/dialogs/DialogTextInput/DialogTextInput';
import {monitorNotifyUser} from 'redux/actions/dataActions';
import StatusIconWorkstation from '../statusIconWorkstation/StatusIconWorkstation';

const PREFIX = 'EditRemoteWorkstationAppBar';

const classes = {
  grow: `${PREFIX}-grow`,
  actionButton: `${PREFIX}-actionButton`,
  statusText: `${PREFIX}-statusText`,
  machineName: `${PREFIX}-machineName`,
  powerStatus: `${PREFIX}-powerStatus`,
  powerStatusGroup: `${PREFIX}-powerStatusGroup`,
  runningIcon: `${PREFIX}-runningIcon`,
  stoppingIcon: `${PREFIX}-stoppingIcon`,
  updatingIcon: `${PREFIX}-updatingIcon`,
  startingIcon: `${PREFIX}-startingIcon`,
  iconContainer: `${PREFIX}-iconContainer`,
  disabled: `${PREFIX}-disabled`,
  inputSelect: `${PREFIX}-inputSelect`,
  dropdownMenuItem: `${PREFIX}-dropdownMenuItem`,
  btnTooltip: `${PREFIX}-btnTooltip`,
};

const Root = styled(Paper)(({theme}) => ({
  [`& .${classes.grow}`]: {
    flexGrow: 1,
    fontSize: '14px',
  },

  [`& .${classes.actionButton}`]: {
    marginLeft: '0.8rem',
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 0.8,
    },
  },

  [`& .${classes.statusText}`]: {
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.078125rem',
    marginLeft: '0.5rem',
  },

  [`& .${classes.machineName}`]: {
    color: theme.palette.primary.main,
    fontSize: '1.125rem',
    fontWeight: 500,
    letterSpacing: '0.014375rem',
    whiteSpace: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: '100px',
  },

  [`& .${classes.powerStatus}`]: {
    marginRight: '16px',
  },

  [`& .${classes.powerStatusGroup}`]: {
    marginRight: '1.2rem',
  },

  [`& .${classes.runningIcon}`]: {
    color: '#00875A',
  },

  [`& .${classes.stoppingIcon}`]: {
    color: '#FFAB00',
  },

  [`& .${classes.updatingIcon}`]: {
    color: '#00839B',
  },

  [`& .${classes.startingIcon}`]: {
    color: '#00839B',
  },

  [`& .${classes.iconContainer}`]: {
    marginLeft: '10px',
  },

  [`& .${classes.disabled}`]: {
    backgroundColor: 'transparent',
    opacity: 0.5,
  },

  [`& .${classes.inputSelect}`]: {
    width: '185px',
    minWidth: '185px',
    margin: '6px 0px 6px 10px',
  },

  [`& .${classes.dropdownMenuItem}`]: {
    ...theme.dropDown,
    paddingLeft: '8px',
  },

  [`& .${classes.btnTooltip}`]: {
    display: 'inline-block',
  },
}));

function EditRemoteWorkstationAppBar({
  fetchingWorkstation,
  selectedWorkstation,
  reloadWorkstation,
}) {
  const dispatch = useDispatch();
  const {triggerDialog} = useDialog();

  const {machineId, machineName, managed, powerState, provisioningStatus} =
    selectedWorkstation;

  const {data: cloudServiceAccounts} = useSelector((state) =>
    selectData(state, CLOUD_SERVICE_ACCOUNTS)
  ) || {data: []};
  const [selectedPowerOperation, setSelectedPowerOperation] = useState('');
  const {item: latestMonitorTelemetry} = useSelector((state) =>
    selectResourceItem(state, MONITOR_TELEMETRY_LATEST, machineId)
  );

  const isDeleting = selectedWorkstation.status === 'deleting';

  const [isRunningOperation, setIsRunningOperation] = useState(false);

  const isDeleteDisabled = () => isDeleting || isEmpty(selectedWorkstation);

  const isWorkstationLoading = () => isEmpty(selectedWorkstation);
  const isMachineErroring = () =>
    Boolean(selectedWorkstation.error || selectedWorkstation.detailedError);

  const handleStartButtonClick = () =>
    dispatch(startMachine(selectedWorkstation));
  const handleStopButtonClick = () =>
    triggerDialog({
      title: 'Stop remote workstation?',
      message: `Are you sure you want to stop the remote workstation ${selectedWorkstation.machineName}?`,
      onConfirm: () => dispatch(stopMachine(selectedWorkstation)),
      warningMessage: dataLossWarningMsg,
    });

  const handleRestartButtonClick = () =>
    triggerDialog({
      title: 'Restart remote workstation?',
      message: `Are you sure you want to restart the remote workstation ${selectedWorkstation.machineName}?`,
      onConfirm: () => dispatch(restartMachine(selectedWorkstation)),
      warningMessage: dataLossWarningMsg,
    });

  const handleRunOperationButtonClick = () => {
    setIsRunningOperation(true);
    switch (selectedPowerOperation) {
      case 'On':
        dispatch(startMachine(selectedWorkstation)).then(() =>
          setIsRunningOperation(false)
        );
        break;
      case 'OffSoft':
        triggerDialog({
          title: 'Power off remote workstation?',
          message: `Are you sure you want to power off the remote workstation ${selectedWorkstation.machineName}?`,
          onConfirm: () =>
            dispatch(
              stopMachine(selectedWorkstation, false, selectedPowerOperation)
            ).then(() => setIsRunningOperation(false)),
          cancelEnabled: true,
          onCancel: () => setIsRunningOperation(false),
          warningMessage: dataLossWarningMsg,
        });
        break;
      case 'OffSoftGraceful':
        triggerDialog({
          title: 'Gracefully shutdown remote workstation?',
          message: `Are you sure you want to gracefully shutdown the remote workstation ${selectedWorkstation.machineName}?`,
          onConfirm: () =>
            dispatch(
              stopMachine(selectedWorkstation, false, selectedPowerOperation)
            ).then(() => setIsRunningOperation(false)),
          cancelEnabled: true,
          onCancel: () => setIsRunningOperation(false),
          warningMessage: dataLossWarningMsg,
        });
        break;
      case 'PowerCycleOffSoft':
        triggerDialog({
          title: 'Reset remote workstation?',
          message: `Are you sure you want to reset the remote workstation ${selectedWorkstation.machineName}?`,
          onConfirm: () =>
            dispatch(
              restartMachine(selectedWorkstation, false, selectedPowerOperation)
            ).then(() => setIsRunningOperation(false)),
          cancelEnabled: true,
          onCancel: () => setIsRunningOperation(false),
          warningMessage: dataLossWarningMsg,
        });
        break;
      case 'MasterBusResetGraceful':
        triggerDialog({
          title: 'Gracefully restart remote workstation?',
          message: `Are you sure you want to gracefully restart the remote workstation ${selectedWorkstation.machineName}?`,
          onConfirm: () =>
            dispatch(
              restartMachine(selectedWorkstation, false, selectedPowerOperation)
            ).then(() => setIsRunningOperation(false)),
          cancelEnabled: true,
          onCancel: () => setIsRunningOperation(false),
          warningMessage: dataLossWarningMsg,
        });
        break;
      default:
        setIsRunningOperation(false);
        break;
    }
  };

  const handleRefreshWorkstation = () => {
    reloadWorkstation();
  };

  const handleDeleteButtonClick = () =>
    dispatch(openDeleteWorkstationDialog(machineId));

  const statusIcons = {
    [WORKSTATION_POWERSTATE_RUNNING]: (
      <CheckCircle className={classes.runningIcon} />
    ),
    [WORKSTATION_POWERSTATE_STARTING]: (
      <AutoRenew className={classes.startingIcon} />
    ),
    [WORKSTATION_POWERSTATE_UPDATING]: (
      <Update className={classes.updatingIcon} />
    ),
    [WORKSTATION_POWERSTATE_STOPPED]: <StoppedSVGIcon fill="#ffab00" />,
    [WORKSTATION_POWERSTATE_STOPPING]: <StoppedSVGIcon />,
  };

  const getMachineStatusIcon = () => {
    if (isMachineErroring()) {
      return (
        <StatusIconWorkstation
          workstation={selectedWorkstation}
          testIdPrefix={`${selectedWorkstation.machineId}-appbar`}
        />
      );
    }

    const status = getMachineStatus(selectedWorkstation);
    return statusIcons[status] || null;
  };

  const renderBackButton = () => (
    <IconButton
      component={Link}
      to={WORKSTATIONS_LINK}
      data-testid="edit-remote-workstation-appbar-back-button"
      size="large"
    >
      <KeyboardArrowLeft />
    </IconButton>
  );

  const renderMachineName = () => (
    <div className={classes.machineName}>
      {isWorkstationLoading() ? 'Loading workstation...' : machineName}
    </div>
  );

  const renderMachineStatusIcon = () => (
    <div className={classes.iconContainer}>{getMachineStatusIcon()}</div>
  );

  const renderMachineStatus = () => {
    const powerStatusText = mapRemoteWorkstationStatus(
      powerState,
      provisioningStatus,
      managed
    );
    const powerStatusChip = RemoteWorkstationErrorIndicator(
      selectedWorkstation,
      'medium'
    );
    return (
      <div className={classes.powerStatusGroup}>
        <Typography variant="caption" className={classes.powerStatus}>
          {powerStatusText === 'Unknown' && 'Power status: '}
        </Typography>
        {powerStatusChip}
      </div>
    );
  };

  const menuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    style: {maxHeight: '50vh', width: '100%'},
  };

  const selectProps = {
    MenuProps: menuProps,
    IconComponent: KeyboardArrowDown,
    displayEmpty: true,
  };

  const inputProps = {
    style: {
      height: '36px',
      backgroundColor: '#fff',
    },
  };

  const showRunOperationControls = () =>
    selectedWorkstation.provider === AMT &&
    selectedWorkstation.hasManagementCredentials &&
    selectedWorkstation.managed &&
    selectedWorkstation.providerMetadata?.availablePowerStates?.length > 0 &&
    selectedWorkstation.powerState.toLowerCase() !== 'unknown';

  const renderAvailablePowerOptions = () => {
    if (showRunOperationControls()) {
      const mappedAvailablePowerOperations =
        selectedWorkstation.providerMetadata.availablePowerStates.map(
          (availablePowerOperation) =>
            mapAmtAvailablePowerOperations(availablePowerOperation)
        );

      const orderedAvailablePowerOperations = mappedAvailablePowerOperations
        .sort()
        .map((element) =>
          Object.keys(AMT_AVAILABLE_POWER_OP_TO_HUMAN_READABLE_MAP).find(
            (key) =>
              AMT_AVAILABLE_POWER_OP_TO_HUMAN_READABLE_MAP[key] === element
          )
        );

      if (!orderedAvailablePowerOperations.includes(selectedPowerOperation)) {
        setSelectedPowerOperation(orderedAvailablePowerOperations[0]);
      }
      return (
        <TextField
          data-testid="select-power-operation"
          select
          variant="outlined"
          SelectProps={selectProps}
          InputProps={inputProps}
          value={selectedPowerOperation}
          onChange={(event) => setSelectedPowerOperation(event.target.value)}
          className={classes.inputSelect}
          disabled={
            !canPerformPowerActions(
              selectedWorkstation,
              cloudServiceAccounts
            ) ||
            powerStateUnknown(selectedWorkstation) ||
            fetchingWorkstation ||
            isRunningOperation
          }
        >
          {orderedAvailablePowerOperations.map((availablePowerOperation) => (
            <MenuItem
              key={availablePowerOperation}
              value={availablePowerOperation}
              className={classes.dropdownMenuItem}
            >
              {mapAmtAvailablePowerOperations(availablePowerOperation)}
            </MenuItem>
          ))}
        </TextField>
      );
    }
    return null;
  };

  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);
  const [messageInputErrorHelperText, setMessageInputErrorHelperText] =
    useState('');

  const handleMessageInputChange = (value) => {
    const maxLength = 72;
    if (value.length > maxLength) {
      setMessageInputErrorHelperText(
        `The message may not be longer than ${maxLength} characters. (${value.length}/${maxLength})`
      );
    } else {
      setMessageInputErrorHelperText('');
    }
  };

  const handleClearDialog = () => {
    setIsMessageDialogOpen(false);
    setMessageInputErrorHelperText('');
  };

  const handleSendMessage = (value) => {
    dispatch(
      monitorNotifyUser(machineId, 'MONITOR: Message from administrator', value)
    );
    setIsMessageDialogOpen(false);
  };

  const renderMachineInfo = () => (
    <>
      {renderBackButton()}
      {renderMachineName()}
      {renderMachineStatusIcon()}
    </>
  );

  const machineActions = [];

  if (selectedWorkstation.provider !== AMT) {
    machineActions.push(
      {
        testId: 'start-button',
        clickHandler: handleStartButtonClick,
        isDisabled: !canStartWorkstation(
          selectedWorkstation,
          cloudServiceAccounts
        ),
        displayText: 'Start',
        icon: <StartIcon />,
      },
      {
        testId: 'stop-button',
        clickHandler: handleStopButtonClick,
        isDisabled: !canStopOrRestartWorkstation(
          selectedWorkstation,
          cloudServiceAccounts
        ),
        displayText: 'Stop',
        icon: (
          <StoppedSVGIcon
            disabled={
              !canStopOrRestartWorkstation(
                selectedWorkstation,
                cloudServiceAccounts
              )
            }
          />
        ),
      },
      {
        testId: 'restart-button',
        clickHandler: handleRestartButtonClick,
        isDisabled: !canStopOrRestartWorkstation(
          selectedWorkstation,
          cloudServiceAccounts
        ),
        displayText: 'Restart',
        icon: <RestartIcon />,
      }
    );
  }

  if (showRunOperationControls()) {
    machineActions.push({
      testId: 'run-operation-button',
      clickHandler: handleRunOperationButtonClick,
      isDisabled:
        !canPerformPowerActions(selectedWorkstation, cloudServiceAccounts) ||
        powerStateUnknown(selectedWorkstation) ||
        fetchingWorkstation ||
        isRunningOperation,
      displayText: 'Run Operation',
      icon: <StartIcon />,
    });
  }

  const showRefresh =
    (selectedWorkstation.provider === AMT &&
      selectedWorkstation.hasManagementCredentials &&
      selectedWorkstation.managed) ||
    (selectedWorkstation.agentMonitored && config.isMonitorEnabled());
  if (showRefresh) {
    machineActions.push({
      testId: 'refresh-workstation-info',
      clickHandler: handleRefreshWorkstation,
      isDisabled:
        (!canPerformPowerActions(selectedWorkstation, cloudServiceAccounts) &&
          !(selectedWorkstation.agentMonitored && config.isMonitorEnabled())) ||
        fetchingWorkstation ||
        isRunningOperation,
      displayText: 'Refresh',
      icon: <SyncIcon />,
    });
  }

  machineActions.push({
    testId: 'delete-button',
    clickHandler: handleDeleteButtonClick,
    isDisabled: isDeleteDisabled(),
    displayText: 'Delete',
    icon: <DeleteIcon />,
  });

  const showMonitorButtons =
    config.isMonitorEnabled() && selectedWorkstation.agentMonitored;

  if (showMonitorButtons) {
    machineActions.push({
      testId: 'send-message-button',
      clickHandler: () => setIsMessageDialogOpen(true),
      isDisabled:
        !isLatestMonitorTelemetryValid(latestMonitorTelemetry) ||
        latestMonitorTelemetry.agentVersion < '23.01' ||
        latestMonitorTelemetry.loggedInUsers.length <= 0,
      displayText: 'Send Message',
      icon: <MessageIcon />,
      tooltip:
        latestMonitorTelemetry.agentVersion < '23.01'
          ? `The installed Anyware Monitor version (${
              latestMonitorTelemetry.agentVersion
                ? latestMonitorTelemetry.agentVersion
                : 'Unknown'
            }) does not support sending messages. Upgrade to version 23.01 or later to enable messaging. You can upgrade it by following the installation steps on the Anyware Monitor tab.`
          : null,
    });
  }

  const renderMachineAction = (action) => {
    const button = (
      <IconButton
        key={action.testId}
        data-testid={action.testId}
        data-targetid={action.testId}
        onClick={action.clickHandler}
        disabled={action.isDisabled}
        className={classes.actionButton}
        classes={{
          disabled: classes.disabled,
        }}
        size="large"
      >
        {action.icon}
        <div className={classes.statusText}>{action.displayText}</div>
      </IconButton>
    );
    return action.tooltip ? (
      <div className={classes.btnTooltip} key={`${action.testId}-tooltip`}>
        <Tooltip placement="bottom" title={action.tooltip}>
          <div>{button}</div>
        </Tooltip>
      </div>
    ) : (
      button
    );
  };

  const renderMachineActions = (actions) => [
    actions.map((action) => renderMachineAction(action)),
  ];

  return (
    <Root>
      <AppBar loading={fetchingWorkstation || isDeleting}>
        {renderMachineInfo()}
        <div className={classes.grow} />
        {renderMachineStatus()}
        <div>
          {renderAvailablePowerOptions()}
          {renderMachineActions(machineActions)}
        </div>
        <DialogTextInput
          open={isMessageDialogOpen}
          title={`Send message to workstation ${machineName}`}
          subtitle={
            <p>
              This message will be displayed to the user on the desktop
              environment as a notification
            </p>
          }
          onCancel={() => handleClearDialog()}
          onOk={handleSendMessage}
          placeholder="Type the message here"
          onInputChange={handleMessageInputChange}
          errorHelperText={messageInputErrorHelperText}
          allowLineBreaks={false}
        />
      </AppBar>
    </Root>
  );
}

EditRemoteWorkstationAppBar.propTypes = {
  fetchingWorkstation: PropTypes.bool,
  selectedWorkstation: PropTypes.object,
  reloadWorkstation: PropTypes.func,
};
EditRemoteWorkstationAppBar.defaultProps = {
  fetchingWorkstation: false,
  selectedWorkstation: {},
  reloadWorkstation: () => {},
};

export default EditRemoteWorkstationAppBar;
