import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Children} from 'react';

const PREFIX = 'GridN';

const classes = {
  container: `${PREFIX}-container`,
  item: `${PREFIX}-item`,
  alignCenter: `${PREFIX}-alignCenter`
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.container}`]: {
    // It is very useful to keep this to easily enable when debugging grid components
    // border: '1px solid red',
    maxWidth: '1790px',
  },

  [`& .${classes.item}`]: {
    // border: '1px solid green',
    position: 'relative',
  },

  [`&.${classes.alignCenter}`]: {alignItems: 'center'}
}));

function GridN({singleColumn, children, spacing, alignCenter, sm, md, lg}) {


  const calculateSm = () => (singleColumn ? 12 : 6);
  const calculateMd = () => (singleColumn ? 12 : 4);
  const calculateLg = () => (singleColumn ? 12 : 3);

  return (
    <StyledGrid
      container
      className={classNames(classes.container, {
        [classes.alignCenter]: alignCenter,
      })}
      spacing={spacing}
    >
      {Children.map(children || null, (child, i) => {
        if (!child) {
          return null;
        }
        return (
          <Grid
            item
            xs={12}
            sm={sm || calculateSm()}
            md={md || calculateMd()}
            lg={lg || calculateLg()}
            className={classes.item}
          >
            {child}
          </Grid>
        );
      })}
    </StyledGrid>
  );
}

GridN.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  // TODO: ensure 12 is a multiple of it
  singleColumn: PropTypes.bool,
  spacing: PropTypes.number,
  alignCenter: PropTypes.bool,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

GridN.defaultProps = {
  singleColumn: false,
  spacing: 1,
  alignCenter: false,
  sm: undefined,
  md: undefined,
  lg: undefined,
};

export default GridN;
