import {defaultLoginError, googleAllowListError} from './constants';

export class DefaultLoginError {
  constructor(code = 500, status = 'error', message = defaultLoginError) {
    this.code = code;
    this.status = status;
    this.message = message;
  }
}

export class WhiteListLoginError {
  constructor(code = 403, status = 'fail', message = googleAllowListError) {
    this.code = code;
    this.status = status;
    this.message = message;
  }
}
