import {ENQUEUE_SNACKBAR, REMOVE_SNACKBAR} from 'redux/actions/snackbarActions';
import {CLEAR_STORE} from 'redux/actions/globalActions';

const defaultState = [];

export default function notificationReducer(state = defaultState, action) {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return [...state, {...action.notification}];
    case REMOVE_SNACKBAR:
      return state.filter((notification) => notification.key !== action.key);
    case CLEAR_STORE:
      return defaultState;
    default:
      return state;
  }
}
