import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import CopyToClipboard from 'components/CAM/buttons/CopyToClipboard/CopyToClipboard';
import InputLabel from 'components/CAM/text/InputLabel/InputLabel';

const PREFIX = 'KeyValueInfo';

const classes = {
  value: `${PREFIX}-value`,
  link: `${PREFIX}-link`,
  hoverIcon: `${PREFIX}-hoverIcon`,
  object: `${PREFIX}-object`,
};

const Root = styled(Grid)(({theme}) => ({
  [`& .${classes.value}`]: {
    color: 'rgba(0,0,0,0.38)',
  },

  [`& .${classes.link}`]: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },

  [`& .${classes.hoverIcon}`]: {
    fontSize: '1rem',
  },

  [`& .${classes.object}`]: {
    whiteSpace: 'nowrap',
    overflow: 'auto',
    maxWidth: '100%',
  },
}));

const notCopiedMessage = 'Click to copy to clipboard';
const copiedMessage = 'Copied to clipboard';

function KeyValueInfo({label, value, link, type, toolTipText, enableCopy}) {
  const [copied, setCopied] = useState(false);
  const handleCopy = (_, success) => {
    if (success) {
      setCopied(true);
    }
  };

  const handleMouseEnter = () => setCopied(false);

  function renderInfoItem() {
    if (enableCopy) {
      return (
        <CopyToClipboard
          text={value}
          onCopy={handleCopy}
          tooltipProps={{
            text: copied ? copiedMessage : notCopiedMessage,
            placement: 'top',
          }}
          showAsText
        />
      );
    }
    return (
      <Grid data-testid={`${label}-info-text`}>
        <div className={`${classes.value} ${link ? classes.link : ''}`}>
          {value}
        </div>
      </Grid>
    );
  }

  function renderLinkedInfoItem() {
    return <Link to={link}>{renderInfoItem()}</Link>;
  }

  return (
    <Root container>
      <Grid item xs={12} md={12}>
        <InputLabel displayText={label} tooltipText={toolTipText} />
        <Grid
          item
          xs={12}
          md={12}
          className={classNames(
            {
              [classes.object]: type === 'object',
            },
            classes.value
          )}
          onMouseEnter={handleMouseEnter}
        >
          {link === '' ? renderInfoItem() : renderLinkedInfoItem()}
        </Grid>
      </Grid>
    </Root>
  );
}

KeyValueInfo.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  link: PropTypes.string,
  type: PropTypes.string,
  toolTipText: PropTypes.string,
  enableCopy: PropTypes.bool,
};

KeyValueInfo.defaultProps = {
  label: '',
  value: '',
  link: '',
  type: 'string',
  toolTipText: '',
  enableCopy: false,
};

export default KeyValueInfo;
