import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {BiAnchor} from 'react-icons/bi';
import {HiSupport} from 'react-icons/hi';

const PREFIX = 'AssignmentPolicyIcon';

const classes = {
  policyIcon: `${PREFIX}-policyIcon`,
};

const StyledHiSupport = styled('div')(() => ({
  [`& .${classes.policyIcon}`]: {
    width: '24px',
    maxHeight: '18px',
    verticalAlign: 'middle',
    color: '#000000',
  },
}));

export default function AssignmentPolicyIcon({policyType}) {
  return (
    <StyledHiSupport>
      {policyType === 'persistent' && (
        <BiAnchor className={classes.policyIcon} />
      )}
      {policyType === 'floating' && (
        <HiSupport className={classes.policyIcon} />
      )}
    </StyledHiSupport>
  );
}

AssignmentPolicyIcon.propTypes = {
  policyType: PropTypes.oneOf(['persistent', 'floating', '']).isRequired,
};
