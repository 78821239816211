import MenuItem from '@mui/material/MenuItem';
import {styled} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import GridN from 'components/CAM/layout/GridN/GridN';
import ProviderIcon from 'components/CAM/icons/ProviderIcon/ProviderIcon';
import {
  clearResource,
  fetchAzureResourceGroups,
  fetchGcpRegions,
} from 'redux/actions/dataActions';
import {
  AMT,
  AWS,
  AWS_INSTANCES,
  AWS_REGIONS,
  AZURE,
  AZURE_INSTANCES,
  AZURE_RESOURCE_GROUPS,
  CLOUD_PROVIDERS,
  CLOUD_SERVICE_ACCOUNTS,
  GCP,
  GCP_INSTANCES,
  GCP_REGIONS,
  ONPREM,
  RSM,
} from 'utils/constants';
import {mapProviders} from 'utils/Mappings';
import {selectData} from 'utils/reduxSelectors';
import {isEmpty} from 'utils/utils';

const PREFIX = 'ProviderSelector';

const classes = {
  pageContainer: `${PREFIX}-pageContainer`,
  pageTitleContainer: `${PREFIX}-pageTitleContainer`,
  createPageContainer: `${PREFIX}-createPageContainer`,
  bodyText: `${PREFIX}-bodyText`,
  link: `${PREFIX}-link`,
  rootContainer: `${PREFIX}-rootContainer`,
  dropdownMenuItem: `${PREFIX}-dropdownMenuItem`,
  inputSelect: `${PREFIX}-inputSelect`,
};

const StyledGridN = styled(GridN)(({theme}) => ({
  [`& .${classes.pageContainer}`]: theme.createPage.createPageContainer,
  [`& .${classes.pageTitleContainer}`]:
    theme.createPage.createPageTitleContainer,
  [`& .${classes.createPageContainer}`]: theme.createPage.createPageContainer,

  [`& .${classes.bodyText}`]: {
    color: '#D8D8D8',
    fontWeight: 500,
  },

  [`& .${classes.link}`]: {
    textDecoration: 'underline',
    color: 'white',
  },

  [`& .${classes.rootContainer}`]: {
    paddingTop: '0.5rem',
  },

  [`& .${classes.dropdownMenuItem}`]: {
    ...theme.dropDown,
    paddingLeft: '8px',
  },

  [`& .${classes.inputSelect}`]: {
    backgroundColor: theme.palette.surface.white,
    width: '100%',
  },
}));

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  style: {maxHeight: '50vh', width: '100%'},
};

const selectProps = {
  MenuProps: menuProps,
  IconComponent: KeyboardArrowDown,
  displayEmpty: true,
};

const inputProps = {
  style: {
    height: '36px',
    backgroundColor: '#fff',
  },
};

function ProviderSelector({
  initialProvider,
  deploymentId,
  providerOnly,
  onProviderChange,
  onSelectionFinish,
  providers,
}) {
  const dispatch = useDispatch();

  const [selectedProvider, setSelectedProvider] = useState(initialProvider);
  const [selectedAwsRegion, setSelectedAwsRegion] = useState('');
  const [selectedAzureResourceGroup, setSelectedAzureResourceGroup] =
    useState('');
  const [selectedGcpRegion, setSelectedGcpRegion] = useState('');
  const [selectedGcpZone, setSelectedGcpZone] = useState('');

  const {data: cloudServiceAccounts, isFetching: fetchingCloudServiceAccounts} =
    useSelector((state) => selectData(state, CLOUD_SERVICE_ACCOUNTS));

  const _providers = providers.map((provider) => ({
    value: provider,
    name: mapProviders(provider),
    disabled:
      provider === ONPREM
        ? false
        : isEmpty(
            cloudServiceAccounts.find(
              (account) => account.provider === provider
            )
          ) || fetchingCloudServiceAccounts,
  }));

  const {data: azureResourceGroups, isFetching: fetchingAzureResourceGroups} =
    useSelector((state) => selectData(state, AZURE_RESOURCE_GROUPS));

  const {data: gcpRegions, isFetching: fetchingGcpRegions} = useSelector(
    (state) => selectData(state, GCP_REGIONS)
  );

  useEffect(() => {
    if (!providerOnly) {
      switch (selectedProvider) {
        case AZURE:
          dispatch(
            fetchAzureResourceGroups(
              deploymentId,
              cloudServiceAccounts.find((account) => account.provider === AZURE)
                .subscriptionId
            )
          );
          break;

        case GCP:
          if (isEmpty(gcpRegions)) {
            dispatch(fetchGcpRegions(deploymentId));
          }
          break;
        default:
          break;
      }
    }
    setSelectedGcpRegion('');
    setSelectedGcpZone('');
    setSelectedAwsRegion('');
    setSelectedAzureResourceGroup('');
    dispatch(clearResource(AWS_INSTANCES));
    dispatch(clearResource(GCP_INSTANCES));
    dispatch(clearResource(AZURE_INSTANCES));
    onProviderChange(selectedProvider);
  }, [selectedProvider]);

  useEffect(() => {
    setSelectedGcpZone('');
  }, [selectedGcpRegion]);

  useEffect(() => {
    const finalSelection = {provider: selectedProvider};
    if (providerOnly) {
      onSelectionFinish(finalSelection);
    } else {
      switch (selectedProvider) {
        case AWS:
          if (selectedAwsRegion) {
            onSelectionFinish({
              ...finalSelection,
              awsRegion: selectedAwsRegion,
            });
          }
          break;
        case AZURE:
          if (selectedAzureResourceGroup) {
            onSelectionFinish({
              ...finalSelection,
              azureResourceGroup: selectedAzureResourceGroup,
            });
          }
          break;
        case GCP:
          if (selectedGcpZone) {
            onSelectionFinish({...finalSelection, gcpZone: selectedGcpZone});
          }
          break;
        case AMT:
        case RSM:
        case ONPREM:
          onSelectionFinish(finalSelection);
          break;
        default:
          break;
      }
    }
  }, [
    selectedProvider,
    selectedGcpZone,
    selectedAwsRegion,
    selectedAzureResourceGroup,
  ]);

  const renderProviderSelection = () => (
    <TextField
      data-testid="select-provider"
      select
      fullWidth
      variant="outlined"
      SelectProps={selectProps}
      InputProps={inputProps}
      value={selectedProvider}
      onChange={(event) => setSelectedProvider(event.target.value)}
      className={classes.inputSelect}
    >
      {_providers.map((provider) => (
        <MenuItem
          key={provider.value}
          value={provider.value}
          disabled={provider.disabled}
          className={classes.dropdownMenuItem}
        >
          <ProviderIcon provider={provider.value} />
          {provider.name}
          {fetchingCloudServiceAccounts && provider.value !== ONPREM
            ? ' (Verifying credentials...)'
            : ''}
          {!fetchingCloudServiceAccounts && provider.disabled
            ? ' (No credentials available)'
            : ''}
        </MenuItem>
      ))}
    </TextField>
  );

  const renderAwsSpecifSelection = () => (
    <div>
      <Typography className={classes.inputTextFieldLabel}>
        AWS Region
      </Typography>
      <TextField
        data-testid="select-aws-region"
        select
        variant="outlined"
        SelectProps={selectProps}
        InputProps={inputProps}
        value={selectedAwsRegion || ''}
        onChange={(event) => setSelectedAwsRegion(event.target.value)}
        className={classes.inputSelect}
      >
        {Object.entries(AWS_REGIONS).map(([reg, regionName]) => (
          <MenuItem key={reg} value={reg} className={classes.dropdownMenuItem}>
            {`${reg} - ${regionName}`}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );

  const renderAzureSpecifSelection = () => (
    <>
      <Typography className={classes.inputTextFieldLabel}>
        Azure Resource group
      </Typography>
      {fetchingAzureResourceGroups ? (
        'Loading ...'
      ) : (
        <TextField
          data-testid="select-azure-resource-group"
          select
          SelectProps={selectProps}
          InputProps={inputProps}
          variant="outlined"
          value={selectedAzureResourceGroup || ''}
          onChange={(event) =>
            setSelectedAzureResourceGroup(event.target.value)
          }
          className={classes.inputSelect}
        >
          {azureResourceGroups.map((rg) => (
            <MenuItem
              key={rg.name}
              value={rg.name}
              className={classes.dropdownMenuItem}
            >
              {`${rg.name} (${rg.location})`}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );

  const renderGcpSpecifSelection = () => {
    const selectGcpRegion = (
      <>
        <Typography className={classes.inputTextFieldLabel}>
          GCP Region
        </Typography>
        {fetchingGcpRegions ? (
          'Loading ...'
        ) : (
          <TextField
            data-testid="select-gcp-region"
            select
            SelectProps={selectProps}
            InputProps={inputProps}
            variant="outlined"
            value={selectedGcpRegion || ''}
            onChange={(event) => setSelectedGcpRegion(event.target.value)}
            className={classes.inputSelect}
            placeholder="select gcp region"
          >
            {gcpRegions.map((region) => (
              <MenuItem
                key={region.name}
                value={region.name}
                className={classes.dropdownMenuItem}
              >
                {region.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </>
    );

    if (selectedGcpRegion) {
      const gcpZones =
        selectedGcpRegion &&
        gcpRegions.find((region) => region.name === selectedGcpRegion).zones;

      const selectGcpZone = (
        <>
          <Typography className={classes.inputTextFieldLabel}>
            GCP Zone
          </Typography>
          <TextField
            data-testid="select-gcp-zone"
            select
            variant="outlined"
            value={selectedGcpZone || ''}
            onChange={(event) => setSelectedGcpZone(event.target.value)}
            className={classes.inputSelect}
            SelectProps={selectProps}
            InputProps={inputProps}
          >
            {gcpZones.map((zone) => (
              <MenuItem
                key={zone}
                value={zone}
                className={classes.dropdownMenuItem}
              >
                {zone}
              </MenuItem>
            ))}
          </TextField>
        </>
      );

      return [selectGcpRegion, selectGcpZone];
    }
    return selectGcpRegion;
  };

  if (providerOnly) {
    return renderProviderSelection();
  }
  return (
    <StyledGridN>
      {renderProviderSelection()}
      {!providerOnly && selectedProvider === AWS && renderAwsSpecifSelection()}
      {!providerOnly &&
        selectedProvider === AZURE &&
        renderAzureSpecifSelection()}
      {!providerOnly && selectedProvider === GCP && renderGcpSpecifSelection()}
    </StyledGridN>
  );
}

ProviderSelector.propTypes = {
  deploymentId: PropTypes.string.isRequired,
  providers: PropTypes.arrayOf(PropTypes.string),
  initialProvider: PropTypes.string,
  onProviderChange: PropTypes.func,
  onSelectionFinish: PropTypes.func,
  providerOnly: PropTypes.bool,
};

ProviderSelector.defaultProps = {
  initialProvider: '',
  providers: CLOUD_PROVIDERS.filter((value) => value !== '-'),
  onProviderChange: () => {},
  onSelectionFinish: () => {},
  providerOnly: true,
};

export default connect()(ProviderSelector);
