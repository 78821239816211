export const OPEN_MACHINE_ASSIGNMENT_DIALOG = 'OPEN_MACHINE_ASSIGNMENT_DIALOG';

export const CLOSE_MACHINE_ASSIGNMENT_DIALOG =
  'CLOSE_MACHINE_ASSIGNMENT_DIALOG';

export function closeMachineAssignmentDialog() {
  return {
    type: CLOSE_MACHINE_ASSIGNMENT_DIALOG,
  };
}
