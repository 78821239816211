import {get} from 'api/Api';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {AD_USERS} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {selectSelectedDeployment} from 'utils/reduxSelectors';

const getAdUser = async (deploymentId, userGuid) => {
  const path = mapResourceToPath(AD_USERS);
  const params = {
    deploymentId,
    userGuid,
  };
  const {data} = await get({path, params});
  return data[0];
};

const useAdUser = (userGuid, options = {}) => {
  const {deploymentId} = useSelector(selectSelectedDeployment);
  return useQuery(
    [deploymentId, 'adusers', userGuid],
    () => getAdUser(deploymentId, userGuid),
    options
  );
};

export default useAdUser;
