import {Grid, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CloneIcon from '@mui/icons-material/DynamicFeed';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';

import {put} from 'api/Api';
import SaveButton from 'components/CAM/buttons/SaveButton/SaveButton';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import HorizontalTab from 'components/CAM/tabs/HorizontalTab/HorizontalTab';
import KeyValueInfo from 'components/CAM/text/KeyValueInfo/KeyValueInfo';
import AppBar from 'components/common/AppBar';
import DeleteConnectorsDialog from 'components/connectors/DeleteConnectorsDialog';
import config from 'config';
import useSnackbar from 'hooks/useSnackbar';
import useNameValidation from 'hooks/useNameValidation';
import usePendingChanges from 'hooks/usePendingChanges';
import {
  fetchConnectorSettings,
  fetchSelectedConnector,
  handleApiError,
  logoutUserFromCAM,
  saveVariable,
} from 'redux/actions/dataActions';
import {openDeleteConnectorsDialog} from 'redux/actions/DeleteConnectorsDialogActions';
import {openWarningDialog} from 'redux/actions/errorDialogActions';
import {goBack} from 'redux/ReduxHistory';
import {
  CLONE_CONNECTOR_WARNING,
  CONNECTORS_LINK,
  CONNECTOR_SETTINGS,
  CREATE_CONNECTOR_LINK,
} from 'utils/constants';
import {
  selectResourceItem,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import {formatDateTime, isEmpty, stripNull} from 'utils/utils';
import ConnectorOverview from './ConnectorOverview';
import EditConnectorSettingsTab from './EditConnectorSettings';

const PREFIX = 'EditConnector';

const classes = {
  createPageContainer: `${PREFIX}-createPageContainer`,
  inputSectionSubtitle: `${PREFIX}-inputSectionSubtitle`,
  inputTextFieldLabel: `${PREFIX}-inputTextFieldLabel`,
  buttonRow: `${PREFIX}-buttonRow`,
  button: `${PREFIX}-button`,
  rootContainer: `${PREFIX}-rootContainer`,
  overviewContainer: `${PREFIX}-overviewContainer`,
  overviewHeader: `${PREFIX}-overviewHeader`,
  actionButton: `${PREFIX}-actionButton`,
  toolbarText: `${PREFIX}-toolbarText`,
  displayName: `${PREFIX}-displayName`,
  textFieldInput: `${PREFIX}-textFieldInput`,
  flexGrowOne: `${PREFIX}-flexGrowOne`,
  flexGrowTwo: `${PREFIX}-flexGrowTwo`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.createPageContainer}`]: theme.createPage.createPageContainer,
  [`& .${classes.inputSectionSubtitle}`]: theme.createPage.inputSectionSubtitle,
  [`& .${classes.inputTextFieldLabel}`]: theme.createPage.inputTextFieldLabel,
  [`& .${classes.buttonRow}`]: theme.createPage.buttonRow,
  [`& .${classes.button}`]: theme.createPage.button,

  [`& .${classes.rootContainer}`]: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '10%',
    },
    height: '100vh',
  },

  [`& .${classes.overviewContainer}`]: {
    width: '100%',
    minWidth: '1150px',
    backgroundColor: '#ffffff',
    marginTop: '3rem',
  },

  [`& .${classes.overviewHeader}`]: {
    padding: '1rem 0 1rem 1rem',
    textTransform: 'uppercase',
    fontSize: '0.9rem',
    fontWeight: '450',
  },

  [`& .${classes.actionButton}`]: {
    marginLeft: '1.2rem',
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 0.8,
    },
  },

  [`& .${classes.toolbarText}`]: {
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.078125rem',
    marginLeft: '0.5rem',
  },

  [`& .${classes.displayName}`]: {
    color: theme.palette.primary.main,
    fontSize: '1.125rem',
    fontWeight: 500,
    letterSpacing: '0.014375rem',
  },

  [`& .${classes.textFieldInput}`]: {
    width: '20rem',
    height: '2.25rem',
    backgroundColor: theme.palette.surface.white,
  },

  [`& .${classes.flexGrowOne}`]: {flexGrow: 1},
  [`& .${classes.flexGrowTwo}`]: {flexGrow: 2},
}));

const TAB_MAIN = 0;
const TAB_CONNECTOR_SETTINGS = 1;

function EditConnector({match}) {
  const dispatch = useDispatch();
  const {successSnackbar, errorSnackbar} = useSnackbar();

  const [connectorName, setConnectorName] = useState('');
  const [connectorDisplayName, setConnectorDisplayName] = useState('');
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [connectorNameError, validateName, nameHelperText] =
    useNameValidation();
  const {setPendingChanges} = usePendingChanges();
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const selectedConnector =
    useSelector((state) => state.data.dataByResource.selectedConnector) || {};
  const dataUpdated = connectorName.trim() !== connectorDisplayName.trim();
  const [selectedTab, setSelectedTab] = useState(0);
  const history = useHistory();

  const errorCodes = {
    400: 'Invalid parameters provided.',
    403: 'You do not have sufficient privileges.',
    409: 'A connector with this name already exists',
    500: 'Internal error occurred. Please try again.',
    default: 'Unknown error occurred.',
  };

  const connectorInfo = [
    {
      id: 'connectorId',
      label: 'Connector ID',
      value: selectedConnector.connectorId,
    },
    {
      id: 'internalIp',
      label: 'Internal IP',
      value:
        (!isEmpty(selectedConnector) &&
          selectedConnector.components.internalIp) ||
        'Not available',
    },
    {
      id: 'externalIp',
      label: 'External IP',
      value:
        (!isEmpty(selectedConnector) &&
          selectedConnector.components.externalIp) ||
        'Not available',
    },
    {
      id: 'version',
      label: 'Version',
      value:
        (!isEmpty(selectedConnector) &&
          (selectedConnector.components.version ||
            selectedConnector.components.cacVersion)) ||
        'Not available',
    },
    {
      id: 'createdOn',
      label: 'Created date',
      value: formatDateTime(selectedConnector.createdOn),
    },
    {
      id: 'updatedOn',
      label: 'Last modified date',
      value: formatDateTime(selectedConnector.updatedOn),
    },
  ];

  const {item: connectorSettings} = useSelector((state) =>
    selectResourceItem(
      state,
      CONNECTOR_SETTINGS,
      selectedConnector?.deploymentId
    )
  );

  useEffect(() => {
    const urlConnectorId =
      (match && match.params && match.params.connectorId) || '';
    const selectedConnectorId = selectedConnector.connectorId || '';
    if (urlConnectorId !== selectedConnectorId) {
      setLoading(true);
      dispatch(fetchSelectedConnector(urlConnectorId));
    }
    return function cleanup() {
      dispatch(saveVariable('selectedConnector', {}));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedConnector) && deploymentId) {
      setLoading(false);
      setConnectorName(selectedConnector.connectorName);
      setConnectorDisplayName(
        selectedConnector.connectorName || selectedConnector.connectorId
      );
      dispatch(fetchConnectorSettings(selectedConnector.connectorId));
    }
  }, [selectedConnector, deploymentId]);

  useEffect(() => {
    if (connectorName) {
      validateName(connectorName.trim());
    }
  }, [connectorName]);

  useEffect(() => {
    setPendingChanges(connectorName !== connectorDisplayName);
  }, [connectorName, connectorDisplayName]);

  const handleError = (error) => {
    const {code} = error;
    const errorMessage = errorCodes[code] || errorCodes.default;

    if (code === 401) {
      dispatch(logoutUserFromCAM());
      return;
    }
    if (code !== 404) {
      errorSnackbar(errorMessage);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      await put({
        path: `deployments/connectors/${selectedConnector.connectorId}`,
        data: {connectorName: stripNull(connectorName.trim())},
      });
      successSnackbar(`Connector "${connectorDisplayName}" has been updated.`);
      setConnectorName(connectorName.trim());
      setConnectorDisplayName(connectorName.trim());
    } catch (error) {
      if (!handleError(error)) {
        dispatch(handleApiError(error));
      }
    }
    setSaving(false);
  };

  const handleNameFieldChange = (event) => setConnectorName(event.target.value);

  const handleDeleteButtonClick = () =>
    dispatch(openDeleteConnectorsDialog({connectors: [selectedConnector]}));

  const handleCloneButtonClick = () => {
    if (isEmpty(connectorSettings?.connector)) {
      dispatch(openWarningDialog('Clone Connector', CLONE_CONNECTOR_WARNING));
    } else {
      history.push(CREATE_CONNECTOR_LINK, {cloneConnector: selectedConnector});
    }
  };

  const renderAppBar = () => (
    <AppBar loading={loading}>
      <IconButton
        component={Link}
        to={CONNECTORS_LINK}
        id="back-arrow-button"
        size="large"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <div className={classes.displayName}>
        {loading ? 'Loading connector...' : connectorDisplayName}
      </div>
      <div className={classes.flexGrowOne} />
      {config.isBeta() && (
        <IconButton
          onClick={handleCloneButtonClick}
          disabled={loading || saving}
          className={classes.actionButton}
          size="large"
        >
          <CloneIcon />
          <div className={classes.toolbarText}>CLONE</div>
        </IconButton>
      )}
      <IconButton
        onClick={handleDeleteButtonClick}
        disabled={loading || saving}
        className={classes.actionButton}
        size="large"
      >
        <DeleteIcon />
        <div className={classes.toolbarText}>DELETE</div>
      </IconButton>
    </AppBar>
  );

  const renderTextField = () => (
    <div className={classes.flexGrowTwo}>
      <Typography className={classes.inputTextFieldLabel}>
        Connector Name
      </Typography>
      <TextField
        autoComplete="off"
        InputProps={{className: classes.textFieldInput}}
        required
        variant="outlined"
        value={connectorName}
        disabled={saving || loading}
        data-testid="connector-name"
        id="connector-name"
        onChange={handleNameFieldChange}
        error={connectorNameError && dataUpdated}
        helperText={connectorNameError && dataUpdated ? nameHelperText : ''}
      />
    </div>
  );

  const KeyValueInfoDisplay = () =>
    connectorInfo.map((item) => (
      <Grid item key={item.id} xs={12} sm={6} md={4}>
        <KeyValueInfo
          key={item.id}
          label={item.label}
          value={item.value}
          enableCopy={['connectorId', 'internalIp', 'externalIp'].includes(
            item.id
          )}
        />
      </Grid>
    ));

  const handleCancel = () => {
    setPendingChanges(false);
    dispatch(goBack());
  };

  const renderSaveCancelButtons = () => (
    <div className={classes.buttonRow}>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        onClick={handleCancel}
        disabled={saving}
      >
        Cancel
      </Button>
      <SaveButton
        onClick={handleSave}
        disabled={loading || connectorNameError || !dataUpdated}
        saving={saving}
      />
    </div>
  );

  const renderConnectorInformation = () => (
    <CAMCard key="connector-information">
      <Typography className={classes.inputSectionSubtitle}>
        Connector information
      </Typography>
      <Grid container>{KeyValueInfoDisplay()}</Grid>
      <Grid container>{renderTextField()}</Grid>
    </CAMCard>
  );

  const renderConnectorOverview = () => (
    <CAMCard key="connector-overview">
      <GridN singleColumn>
        <Typography className={classes.overviewHeader}>
          Connector Overview
        </Typography>
        <ConnectorOverview connector={selectedConnector} />
      </GridN>
      {renderSaveCancelButtons()}
    </CAMCard>
  );

  const renderMainTab = () => [
    renderConnectorInformation(),
    renderConnectorOverview(),
  ];

  const renderConnectorSettingsTab = () => (
    <EditConnectorSettingsTab
      connectorId={match.params.connectorId || ''}
      deploymentSettings={connectorSettings?.deployment || {}}
      connectorSettings={connectorSettings?.connector || {}}
    />
  );

  const editConnectorTabs = {
    [TAB_MAIN]: renderMainTab(),
    [TAB_CONNECTOR_SETTINGS]: renderConnectorSettingsTab(),
  };

  const renderTabs = () => (
    <div>
      <HorizontalTab
        tabs={['Overview', 'Connector Settings']}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {editConnectorTabs[selectedTab]}
    </div>
  );

  return (
    <Root>
      <div className={classes.createPageContainer}>
        {renderAppBar()}
        {renderTabs()}
      </div>
      <DeleteConnectorsDialog />
    </Root>
  );
}

EditConnector.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EditConnector;
