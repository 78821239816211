import {
  SNACKBAR_ERROR_TIMEOUT,
  SNACKBAR_INFO_TIMEOUT,
  SNACKBAR_SUCCESS_TIMEOUT,
} from 'utils/constants';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';

export const enqueueSnackbar = (notification) => {
  const action = {
    type: ENQUEUE_SNACKBAR,
    notification: {
      key: new Date().getTime() + Math.random(),
      ...notification,
    },
  };

  // Set timeout for each snackbar based on type if no duration is set
  if (typeof notification.options.autoHideDuration === 'undefined') {
    switch (notification.options.variant) {
      case 'error':
      case 'warning':
        action.notification.options.autoHideDuration = SNACKBAR_ERROR_TIMEOUT;
        break;
      case 'info':
        action.notification.options.autoHideDuration = SNACKBAR_INFO_TIMEOUT;
        break;
      case 'success':
      default:
        action.notification.options.autoHideDuration = SNACKBAR_SUCCESS_TIMEOUT;
        break;
    }
  }

  return action;
};

export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key,
});
