export const OPEN_DIALOG = 'OPEN_DIALOG';

export function openDialog(
  title,
  message,
  confirmHandler = () => {},
  cancelEnabled = true,
  cancelHandler = () => {},
  displayDontShowAgainCheckbox = false,
  handleCheckboxChange = () => {},
  confirmationText = 'OK',
  warningMessage = ''
) {
  return {
    type: OPEN_DIALOG,
    title,
    message,
    confirmHandler,
    cancelEnabled,
    cancelHandler,
    displayDontShowAgainCheckbox,
    handleCheckboxChange,
    confirmationText,
    warningMessage,
  };
}

export const CLOSE_DIALOG = 'CLOSE_DIALOG';

export function closeDialog() {
  return {
    type: CLOSE_DIALOG,
  };
}
