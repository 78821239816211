import {ReactComponent as ErrorIcon} from 'icons/error_icon.svg';
import {ReactComponent as InfoIcon} from 'icons/info_icon.svg';
import SuccessIcon from 'icons/SuccessIcon';
import {ReactComponent as WarningIcon} from 'icons/warning_icon.svg';
import PropTypes from 'prop-types';

function FeedbackIcon({variant}) {
  switch (variant) {
    case 'success':
      return <SuccessIcon backgroundColor="#ffffff" />;
    case 'warning':
      return <WarningIcon />;
    case 'info':
      return <InfoIcon />;
    case 'error':
      return <ErrorIcon />;
    default:
      return null;
  }
}

FeedbackIcon.propTypes = {
  variant: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
};

FeedbackIcon.defaultProps = {
  variant: 'info',
};

export default FeedbackIcon;
