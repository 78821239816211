import {Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import Tag from 'components/CAM/display/Tag/Tag';
import ProviderIcon from 'components/CAM/icons/ProviderIcon/ProviderIcon';
import {AWS} from 'utils/constants';

const PREFIX = 'MachineSelectLabel';

const classes = {
  machineName: `${PREFIX}-machineName`,
  root: `${PREFIX}-root`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme: {spacing}}) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.machineName}`]: {
    marginRight: spacing(0.5),
  },
}));

function MachineSelectLabel({instance}) {
  const {provider, instanceName, instanceId, name, computerName} = instance;

  const machineName = name || instanceName || instanceId || computerName;

  const renderMachineName = (displayName) => (
    <Typography display="inline" className={classes.machineName}>
      {displayName}
    </Typography>
  );

  const renderInstanceIdTag = () => (
    <Tag displayText={instanceId} idTag small />
  );

  const providerIcon = <ProviderIcon provider={provider} />;

  const renderContent = () => {
    if (provider === AWS) {
      if (machineName === instanceId) {
        return renderInstanceIdTag();
      }
      return (
        <>
          {renderMachineName(machineName)}
          {renderInstanceIdTag()}
        </>
      );
    }
    return renderMachineName(machineName);
  };

  return (
    <Root className={classes.root}>
      {providerIcon}
      {renderContent()}
    </Root>
  );
}

MachineSelectLabel.propTypes = {
  instance: PropTypes.object.isRequired,
};

export default MachineSelectLabel;
