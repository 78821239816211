import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {Dialog, DialogContent} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Grid from '@mui/material/Grid';
import {stylesFromFigma} from 'themes/stylesFromFigma';

const RootDialogTitle = styled(MuiDialogTitle)(({theme}) => ({
  '&.root': {
    margin: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  '& .closeButton': {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#555967',
  },
  '& .title': {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '130%',
  },
}));

const RootDialogContent = styled(DialogContent)(() => ({
  '& .bodyText': {
    ...stylesFromFigma.typographyBody1,
  },
  '& .formControl': {
    minWidth: 120,
  },
  '&.grid': {
    display: 'grid',
  },
  '& .headerDiv': {
    display: 'flex',
    width: 376,
    alignItems: 'flex-start',
    gap: '8px',
  },
  '& .headerName': {
    display: 'flex',
    width: 100,
    alignItems: 'flex-start',
    gap: '2px',
    flexShrink: 0,
    color: '#555967',
    fontSize: 14,
    lineHeight: '130%',
  },
  '& .headerValue': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
    flex: '1 0 0',
    color: '#23242B',
    fontSize: 14,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '130%',
  },
  '& .description': {
    width: 376,
    fontSize: 14,
    lineHeight: '130%',
  },
  '& .infoIcon': {
    height: 16,
    width: 16,
  },
}));

const CustomTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '14px',
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '19px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
}));

export const scopeDescription =
  'API scopes define access privileges and actions granted to applications or clients when interacting with the API. They are embedded in the authorization token to control permissions and access levels for client applications.';

export function PermissionDialog({
  open,
  onClose,
  name,
  scopeName,
  description,
}) {
  if (!open) return null;

  return (
    <Dialog open={open} onClose={onClose} scroll="paper">
      <RootDialogTitle className="root">
        <Grid
          container
          align="start"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h6" className="title">
              Permission details
            </Typography>
          </Grid>

          {onClose ? (
            <Grid item>
              <IconButton
                aria-label="close"
                data-testid="btn-close-permission-dialog"
                className="closeButton"
                onClick={onClose}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
      </RootDialogTitle>

      <RootDialogContent className="grid">
        <Box className="headerDiv">
          <div className="headerName">Name</div>
          <div data-testid={`permission-name-${name}`} className="headerValue">
            {name}
          </div>
        </Box>
        <Box className="headerDiv" mt={1} mb={1}>
          <div className="headerName">
            API scope
            <CustomTooltip
              data-testid="tooltip-permission-dialog"
              title={scopeDescription}
              placement="right"
            >
              <InfoOutlinedIcon className="infoIcon" />
            </CustomTooltip>
          </div>
          <div data-testid={`scope-name-${name}`} className="headerValue">
            {scopeName}
          </div>
        </Box>
        <Divider />
        <Box className="description" mt={1}>
          {description}
        </Box>
      </RootDialogContent>
    </Dialog>
  );
}

PermissionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  scopeName: PropTypes.string.isRequired,
  description: PropTypes.element.isRequired,
};
