import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import DeleteIcon from 'components/CAM/icons/DeleteIcon/DeleteIcon';
import {selectVariable} from 'utils/reduxSelectors';

const PREFIX = 'UsersTable';

const classes = {
  tableContainer: `${PREFIX}-tableContainer`,
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  tableRow: `${PREFIX}-tableRow`,
  tableCell: `${PREFIX}-tableCell`,
  tableRowEven: `${PREFIX}-tableRowEven`,
  tableRowOdd: `${PREFIX}-tableRowOdd`,
  headerText: `${PREFIX}-headerText`,
  rowText: `${PREFIX}-rowText`,
  infoIcon: `${PREFIX}-infoIcon`,
  tooltip: `${PREFIX}-tooltip`
};

const Root = styled('div')(() => ({
  [`&.${classes.tableContainer}`]: {
    overflow: 'auto',
    maxHeight: '32rem',
    marginTop: '1.5rem',
  },

  [`& .${classes.table}`]: {},

  [`& .${classes.tableHead}`]: {
    backgroundColor: '#FAFAF9',
    height: '28px',
  },

  [`& .${classes.tableRow}`]: {
    height: '28px',
  },

  [`& .${classes.tableCell}`]: {
    borderBottom: '1px solid lightgrey',
  },

  [`& .${classes.tableRowEven}`]: {
    backgroundColor: '#FFFFFF',
  },

  [`& .${classes.tableRowOdd}`]: {
    backgroundColor: '#f4f4f4',
  },

  [`& .${classes.headerText}`]: {
    color: '#080707',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '20px',
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    borderBottom: '1px solid lightgrey',
  },

  [`& .${classes.rowText}`]: {
    fontSize: '12px',
    lineHeight: '15px',
  },

  [`& .${classes.infoIcon}`]: {
    color: 'grey',
  },

  [`& .${classes.tooltip}`]: {
    fontSize: '14px',
  }
}));

const headers = [
  // {
  //   id: 'isEntitled',
  //   label: 'Entitled',
  // },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'userName',
    label: 'User Name',
  },
  {
    id: 'upn',
    label: 'User Principal Name',
  },
  {
    id: 'enabled',
    label: 'Directory Status',
  },
];

function UsersTable({users, onRemove}) {

  const deletingEntitlements = useSelector(
    (state) => selectVariable(state, 'deletingEntitlements') || []
  );

  function displayHeader(header) {
    switch (header.id) {
      case 'enabled':
        return (
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
            }}
            title="Is user enabled in the active directory"
            placement="top"
          >
            <TableSortLabel
              active
              classes={{
                icon: classes.infoIcon,
              }}
              IconComponent={InfoIcon}
            >
              {header.label}
            </TableSortLabel>
          </Tooltip>
        );
      default:
        return header.label;
    }
  }

  function displayValue(user, header) {
    switch (header.id) {
      case 'enabled':
        return user[header.id] ? 'Enabled' : 'Disabled';
      case 'name':
      case 'userName':
      default:
        return user[header.id];
    }
  }

  function renderAdUserRow(user, index) {
    const handleDeleteAdUser = () => onRemove(user);
    return (
      <TableRow
        hover
        key={user.userGuid}
        className={classNames(
          classes.tableRow,
          index % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd
        )}
      >
        {headers.map((header) => (
          <TableCell
            key={`${user.userGuid}-${header.id}`}
            className={classNames(classes.tableCell, classes.rowText)}
          >
            {displayValue(user, header)}
          </TableCell>
        ))}
        <TableCell className={classes.tableCell}>
          <DeleteIcon
            testId={`remove-${user.userGuid}`}
            onClick={handleDeleteAdUser}
            isDeleting={deletingEntitlements.includes(user.entitlementId)}
          />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Root className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHead}>
            {headers.map((header) => (
              <TableCell
                key={header.id}
                className={classNames(classes.headerText, classes.tableCell)}
              >
                {displayHeader(header)}
              </TableCell>
            ))}
            <TableCell
              key="remove"
              className={classNames(classes.headerText, classes.tableCell)}
            />
          </TableRow>
        </TableHead>
        <TableBody>{users.map(renderAdUserRow)}</TableBody>
      </Table>
    </Root>
  );
}

UsersTable.propTypes = {
  users: PropTypes.array,
  onRemove: PropTypes.func,
};

UsersTable.defaultProps = {
  users: [],
  onRemove: () => {},
};

export default UsersTable;
