import MachineStatus from 'components/CAM/display/MachineStatus/MachineStatus';
import {
  mapAmtPowerStateToHumanReadable,
  mapRemoteWorkstationStatus,
} from 'utils/Mappings';
import {AMT, ERROR_TYPE_AWM, ERROR_TYPE_HW} from 'utils/constants';
import {getAmtWorkstationPowerState} from 'utils/utils';
import WarningIcon from '@mui/icons-material/Warning';
import MemoryIcon from '@mui/icons-material/Memory';
import OkIcon from '@mui/icons-material/CheckCircle';

export function RemoteWorkstationErrorIndicator(workstation, size = 'small') {
  const updatedWorkstation = getAmtWorkstationPowerState(workstation);

  const status = mapRemoteWorkstationStatus(
    updatedWorkstation.powerState,
    updatedWorkstation.provisioningStatus,
    updatedWorkstation.managed
  );
  const statusText =
    workstation.provider === AMT
      ? mapAmtPowerStateToHumanReadable(status)
      : status;

  return <MachineStatus statusText={statusText} size={size} />;
}

export function sortErrors(a, b) {
  const errorA = a.errorType;
  const errorB = b.errorType;
  if (errorA === errorB) {
  } else if (errorA === ERROR_TYPE_HW && errorB === ERROR_TYPE_AWM) {
    return -1;
  } else {
    return 1;
  }
}

export function mapErrorArrayToIcon(error) {
  if (!error) {
    return OkIcon;
  }

  const firstIcon = (sorted) => {
    switch (sorted[0].errorType) {
      case ERROR_TYPE_HW:
        return MemoryIcon;
      case ERROR_TYPE_AWM:
      default:
        return WarningIcon;
    }
  };

  return firstIcon(error.sort(sortErrors));
}

export function mapErrorTypeToErrorName(errorType) {
  switch (errorType) {
    case ERROR_TYPE_HW:
      return 'Hardware Error';
    case ERROR_TYPE_AWM:
    default:
      return 'Manager Error';
  }
}
