import {useQuery, useMutation} from 'react-query';
import {del, get} from 'api/Api';

const serviceAccountQueryKey = 'service-account-query-key';

const fetchDeploymentServiceAccount = async (deploymentId, limit, offset) => {
  const response = await get({
    path: 'keys',
    params: {deploymentId, limit, offset},
  });
  const {total} = response;
  const receivedServiceAccounts = response.data.map((account) => {
    const newAccount = account;
    newAccount.keyName = account.apiKeyName || account.keyName || '';
    return newAccount;
  });
  return {serviceAccounts: receivedServiceAccounts, limit, offset, total};
};

const deleteServiceAccount = async (keyId) => {
  await del({
    path: `keys/${keyId}`,
  });
};

const useQueryDeploymentServiceAccount = (
  deploymentId,
  limit,
  offset,
  options = {}
) =>
  useQuery(
    [serviceAccountQueryKey, {deploymentId, limit, offset}],
    () => fetchDeploymentServiceAccount(deploymentId, limit, offset),
    {
      // This ensure no caching is performed, since the result can change base on user action.
      // Alternatively, when a rsm machine is added/removed, the cache can be updated/invalidated.
      cacheTime: 0,
      ...options,
    }
  );
const useDeleteServiceAccount = (options = {}) =>
  useMutation((keyId) => deleteServiceAccount(keyId), options);

export {
  useQueryDeploymentServiceAccount,
  useDeleteServiceAccount,
  serviceAccountQueryKey,
};
