import {useDispatch} from 'react-redux';
import {enqueueSnackbar} from 'redux/actions/snackbarActions';

const useSnackbar = () => {
  const dispatch = useDispatch();

  const snackbarMsg = (message, variant, autoHideDuration) =>
    dispatch(
      enqueueSnackbar({
        message,
        options: {
          variant,
          autoHideDuration,
        },
      })
    );

  const successSnackbar = (message, autoHideDuration = undefined) =>
    snackbarMsg(message, 'success', autoHideDuration);
  const errorSnackbar = (message, autoHideDuration = undefined) =>
    snackbarMsg(message, 'error', autoHideDuration);
  const warningSnackbar = (message, autoHideDuration = undefined) =>
    snackbarMsg(message, 'warning', autoHideDuration);
  const infoSnackbar = (message, autoHideDuration = undefined) =>
    snackbarMsg(message, 'info', autoHideDuration);

  return {
    errorSnackbar,
    infoSnackbar,
    successSnackbar,
    warningSnackbar,
  };
};

export default useSnackbar;
