import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {post, get, put} from 'api/Api';
import {capitalize} from 'helpers/core';
import {handleApiError, saveVariable} from 'redux/actions/dataActions';
import {
  EDIT_POOL_LINK,
  POOLS,
  POOL_MACHINES,
  POOL_GROUPS,
  POOL_USERS,
  WEBHOOKS,
} from 'utils/constants';
import {mapResourceToPath} from 'utils/Mappings';
import {
  selectSelectedDeployment,
  selectSelectedPool,
} from 'utils/reduxSelectors';
import {isEmpty, linkTo} from 'utils/utils';
import usePushNotification from './usePushNotification';
import useSnackbar from './useSnackbar';

const getFailedResponses = (responses) =>
  responses
    .map((res, i) => ({...res, i}))
    .filter(({status}) => status !== 'success');

const creationNotification = {
  [POOL_MACHINES]: {
    name: 'remote workstation',
    success: 'Selected workstations were successfully added to the pool.',
  },
  [POOL_USERS]: {
    name: 'user',
    success: 'Selected users were successfully entitled to the pool',
  },
  [POOL_GROUPS]: {
    name: 'user group',
    success: 'Selected groups were successfully entitled to the pool.',
  },
  [WEBHOOKS]: {
    name: 'webhook',
    success: 'Selected webhooks were successfully associated with the pool.',
  },
};

const usePool = (poolId) => {
  // hooks
  const dispatch = useDispatch();
  const {successSnackbar, errorSnackbar} = useSnackbar();
  const notify = usePushNotification();

  // Pool state
  const [pool, setPool] = useState({});
  const [isFetchingPool, setIsFetchingPool] = useState(false);

  // Redux state
  const {deploymentId} = useSelector(selectSelectedDeployment);
  const selectedPool = useSelector(selectSelectedPool);

  const handleUpdatePoolError = (err) => {
    const {code} = err;
    switch (code) {
      case 400:
        if (err.data.reason) {
          let errMsg = err.data.reason;
          errMsg = capitalize(errMsg);
          errorSnackbar(errMsg);
        }
        break;
      case 409:
        errorSnackbar(
          'A pool with this name already exists in this deployment'
        );
        break;
      default:
        dispatch(handleApiError());
    }
  };

  // Fetch the given pool
  const fetchPool = async () => {
    const path = `${mapResourceToPath(POOLS, {deploymentId})}${poolId}`;
    try {
      setIsFetchingPool(true);
      const {data} = await get({path});
      setPool(data);
      setIsFetchingPool(false);
    } catch (err) {
      setIsFetchingPool(false);
      handleApiError(err);
    }
  };

  const createPoolMachine = async (machine) => {
    const path = mapResourceToPath(POOL_MACHINES, {deploymentId, poolId});
    let response;
    try {
      response = await post({
        path,
        data: {machineId: machine.machineId, entitlement: {userGuid: null}},
      });
    } catch (err) {
      response = err;
    }
    return response;
  };

  const entitlePoolUser = async (user) => {
    const path = `deployments/${deploymentId}/entitlements`;
    let response;
    try {
      response = await post({
        path,
        data: {poolId, userGuid: user.userGuid, deploymentId},
      });
    } catch (err) {
      response = err;
    }
    return response;
  };

  const entitlePoolGroup = async (group) => {
    const path = `deployments/${deploymentId}/entitlements`;
    let response;
    try {
      response = await post({
        path,
        data: {poolId, groupGuid: group.groupGuid, deploymentId},
      });
    } catch (err) {
      response = err;
    }
    return response;
  };

  const associatePoolWebhook = async (webhook) => {
    const basePath = mapResourceToPath(WEBHOOKS, {deploymentId});
    const path = `${basePath}/${webhook.id}`;

    let response;
    try {
      response = await put({
        path,
        data: {
          name: webhook.name,
          endpoint: webhook.endpoint,
          eventType: webhook.eventType,
          additionalData: webhook.additionalData,
          description: webhook.description,
          associatedResources: [
            ...webhook.associatedResources.map(
              (resource) => resource.resourceId
            ),
            poolId,
          ],
        },
      });
    } catch (err) {
      response = err;
    }
    return response;
  };

  const createPoolElements = async (resource, creator, elems) => {
    const requests = elems.map(async (elem) => creator(elem));
    const responses = await Promise.all(requests);
    const failures = getFailedResponses(responses);
    const resourceName = creationNotification[resource].name;

    if (failures.length > 0) {
      errorSnackbar(
        `An error occurred while adding ${failures.length} ${resourceName} to the pool`
      );
      failures.forEach((failure) => {
        const {code, data} = failure || {code: '', data: {}};
        const {reason} = data || {reason: ''};
        notify({
          title: 'Addition to pool failed',
          type: capitalize(resourceName),
          moreInfo: `Addition of ${resourceName} to pool failed. Code ${code}: ${reason}`,
          timePosted: Date.now(),
          actionText: 'Mark as read',
          link: linkTo(`${EDIT_POOL_LINK}/${poolId}`),
        });
      });
    } else {
      successSnackbar(creationNotification[resource].success);
    }
  };

  // Fetch pool from backend on mount and when deploymentId and/or poolId changes
  useEffect(() => {
    if (deploymentId && poolId) {
      fetchPool();
    }
  }, [deploymentId, poolId]);

  // Update pool in redux when pool updates
  useEffect(() => {
    dispatch(saveVariable('selectedPool', pool));
  }, [JSON.stringify(pool)]);

  // Method exposed to component to update pool info
  const updatePoolInfo = async ({poolName, assignmentHoldingTime}) => {
    const path = `${mapResourceToPath(POOLS, {deploymentId})}${poolId}`;

    const body = {};
    if (!isEmpty(poolName) && poolName !== pool.poolName) {
      body.poolName = poolName;
    }

    if (!isEmpty(assignmentHoldingTime)) {
      body.assignmentHoldingTime = assignmentHoldingTime;
    }

    try {
      setIsFetchingPool(true);
      const {data} = await put({path, data: body});
      dispatch(saveVariable('selectedPool', data));
      setPool(data);
      setIsFetchingPool(false);
      successSnackbar(`Pool "${selectedPool.poolName}" has been updated`);
    } catch (err) {
      setIsFetchingPool(false);
      handleUpdatePoolError(err);
    }
  };

  // Method exposed to component to add machines to pool
  const savePoolMachines = async (machines) => {
    setIsFetchingPool(true);
    await createPoolElements(POOL_MACHINES, createPoolMachine, machines);
    setIsFetchingPool(false);
  };

  // Method exposed to component to entitle users to pool
  const savePoolUsers = async (users) => {
    setIsFetchingPool(true);
    await createPoolElements(POOL_USERS, entitlePoolUser, users);
    setIsFetchingPool(false);
  };

  // Method exposed to component to entitle groups to pool
  const savePoolGroups = async (groups) => {
    setIsFetchingPool(true);
    await createPoolElements(POOL_GROUPS, entitlePoolGroup, groups);
    setIsFetchingPool(false);
  };

  // Method exposed to component to associate a webhook to a pool
  const savePoolWebhooks = async (webhooks) => {
    setIsFetchingPool(true);
    await createPoolElements(WEBHOOKS, associatePoolWebhook, webhooks);
    setIsFetchingPool(false);
  };

  return {
    isFetchingPool,
    pool,
    savePoolGroups,
    savePoolMachines,
    savePoolUsers,
    updatePoolInfo,
    savePoolWebhooks,
  };
};

export default usePool;
