import Fab from '@mui/material/Fab';
import {styled} from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {
  CONNECTORS,
  CREATE_CONNECTOR_LINK,
  CREATE_POOL_LINK,
  CREATE_REMOTE_WORKSTATION_LINK,
  CREATE_WEBHOOK_LINK,
  POOLS,
  WEBHOOKS,
  REMOTE_WORKSTATIONS,
} from 'utils/constants';
import {linkTo} from 'utils/utils';

const PREFIX = 'TableAddButton';

const classes = {
  button: `${PREFIX}-button`,
};

const StyledFab = styled(Fab)(() => ({
  [`&.${classes.button}`]: {marginLeft: '10px'},
}));

function TableAddButton({resource, onClick: handleClick}) {
  const mapResourceToAddLink = () => {
    switch (resource) {
      case CONNECTORS:
        return CREATE_CONNECTOR_LINK;
      case REMOTE_WORKSTATIONS:
        return CREATE_REMOTE_WORKSTATION_LINK;
      case POOLS:
        return CREATE_POOL_LINK;
      case WEBHOOKS:
        return CREATE_WEBHOOK_LINK;
      default:
        return '';
    }
  };

  return (
    <StyledFab
      className={classes.button}
      id="add-button"
      data-testid={`${resource}-add-button`}
      aria-haspopup="true"
      size="small"
      color="primary"
      onClick={handleClick}
      component={Link}
      to={linkTo(mapResourceToAddLink())}
    >
      <AddIcon />
    </StyledFab>
  );
}

TableAddButton.propTypes = {
  resource: PropTypes.string,
  onClick: PropTypes.func,
};

TableAddButton.defaultProps = {
  resource: '',
  onClick: () => {},
};

export default TableAddButton;
