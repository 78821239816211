import {Grid, Paper, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  CONNECTOR_SCORE_HEADER,
  NO_CONNECTOR_LOGS_MESSAGE,
} from 'utils/constants';
import {calculateConnectorHealthScore} from 'utils/utils';

const PREFIX = 'ConnectorHealthScore';

const classes = {
  rootContainer: `${PREFIX}-rootContainer`,
  positiveScore: `${PREFIX}-positiveScore`,
  negativeScore: `${PREFIX}-negativeScore`,
  headerScoreIcon: `${PREFIX}-headerScoreIcon`,
  headerTimeInterval: `${PREFIX}-headerTimeInterval`,
  dataMessage: `${PREFIX}-dataMessage`,
  noDataMessage: `${PREFIX}-noDataMessage`,
  healthy: `${PREFIX}-healthy`,
  unhealthy: `${PREFIX}-unhealthy`,
  unknown: `${PREFIX}-unknown`,
  thirdWidth: `${PREFIX}-thirdWidth`,
  twoThirdWidth: `${PREFIX}-twoThirdWidth`,
  bold: `${PREFIX}-bold`
};

const StyledPaper = styled(Paper)(() => ({
  [`&.${classes.rootContainer}`]: {
    minWidth: '275px',
    color: 'rgba(0, 0, 0, 0.6)',
    height: '100%',
    padding: '1rem',
  },

  [`& .${classes.positiveScore}`]: {
    fontWeight: 400,
    color: '#51c81c',
  },

  [`& .${classes.negativeScore}`]: {
    fontWeight: 400,
    color: '#f44336',
  },

  [`& .${classes.headerScoreIcon}`]: {
    width: '1.125rem',
    height: '1.125rem',
    marginRight: '0.3rem',
  },

  [`& .${classes.headerTimeInterval}`]: {
    fontWeight: 350,
    fontSize: '0.7rem',
  },

  [`& .${classes.dataMessage}`]: {
    fontSize: '0.8rem',
    fontWeight: 350,
  },

  [`& .${classes.noDataMessage}`]: {
    width: '60%',
    fontSize: '0.8rem',
    fontWeight: 350,
  },

  [`& .${classes.healthy}`]: {
    color: '#04844B',
  },

  [`& .${classes.unhealthy}`]: {
    color: '#f44336',
  },

  [`& .${classes.unknown}`]: {
    color: '#706E6B',
  },

  [`& .${classes.thirdWidth}`]: {width: '35%'},
  [`& .${classes.twoThirdWidth}`]: {width: '65%'},
  [`& .${classes.bold}`]: {fontWeight: 400}
}));

function ConnectorHealthScore({connector, data}) {

  const newestHealthScore = calculateConnectorHealthScore(
    data[data.length - 1]
  );
  const oldestHealthScore = calculateConnectorHealthScore(data[0]);

  const getScoreChange = () => newestHealthScore - oldestHealthScore;
  const isMostRecentScoreHealthy = () => newestHealthScore === 100;

  const renderHeader = () => (
    <Grid
      item
      container
      direction="column"
      justifyContent="space-between"
      className={classes.thirdWidth}
    >
      <Typography variant="h6" className={classes.bold}>
        {CONNECTOR_SCORE_HEADER}
      </Typography>
    </Grid>
  );

  const generateRadialGauge = (
    width,
    height,
    radius,
    numTicks,
    strokeWidth
  ) => {
    // Obtain the total circle circumference and the circumference of
    // the arc that will be drawn
    const totalCircumference = 2 * Math.PI * radius;
    const arcCircumference = totalCircumference * (200 / 360);

    // Find the width of each tick and each gap between the ticks. 1 is added
    // to make sure the gauge begins and ends with a tick instead of a gap.
    const tickWidth = arcCircumference / (4 * numTicks + 1);
    const gapWidth = tickWidth * 3;

    // Obtain the discrete number of ticks to fill and calculate the
    // circumference along the arc that will be colored
    const numTicksFilled = Math.round(
      (numTicks + 1) * (newestHealthScore / 100)
    );
    const circumferenceFilled = numTicksFilled * (tickWidth + gapWidth);

    // Define the length of the three segments of the circle: the filled gauge,
    // the unfilled gauge, and the blanked-out part of the circle
    const segments = [
      circumferenceFilled,
      arcCircumference - circumferenceFilled,
      totalCircumference - arcCircumference,
    ];

    return (
      <>
        <circle
          cx={width}
          cy={height}
          r={radius}
          fill="none"
          transform="rotate(170, 175, 175)"
          stroke="rgb(0, 0, 0, 0.2)"
          strokeWidth={strokeWidth}
          strokeDasharray={`${segments[0] + segments[1]} ${segments[2]}`}
        />
        <circle
          cx={width}
          cy={height}
          r={radius}
          fill="none"
          transform="rotate(170, 175, 175)"
          stroke="#3693fa"
          strokeWidth={strokeWidth}
          strokeDasharray={`${segments[0]} ${segments[1] + segments[2]}`}
        />
        <circle
          cx={width}
          cy={height}
          r={radius}
          fill="none"
          transform="rotate(170, 175, 175)"
          stroke="#ffffff"
          strokeWidth={strokeWidth + 6}
          strokeDasharray={`${gapWidth} ${tickWidth}`}
          strokeDashoffset={-tickWidth - 0.5}
        />
      </>
    );
  };

  // Unused - to be repurposed for something else later
  const renderGauge = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={175} viewBox="0 0 350 210">
      {generateRadialGauge(175, 175, 130, 25, 50)}
      {generateRadialGauge(175, 175, 90, 25, 5)}
      <text textAnchor="middle" x={175} y={180} fontSize="3em" fill="#3693fa">
        {`${newestHealthScore}%`}
      </text>
    </svg>
  );

  const renderScoreText = () => (
    <>
      <Typography className={classes.dataMessage}>
        Overall health status is
      </Typography>
      <Typography
        data-testid="connector-health-result"
        variant="h5"
        className={classNames({
          [classes.healthy]: connector.healthStatus === 'Healthy',
          [classes.unhealthy]: connector.healthStatus === 'Unhealthy',
          [classes.unknown]: connector.healthStatus === 'Unknown',
        })}
      >
        {connector.healthStatus || 'Unknown'}
      </Typography>
    </>
  );

  const renderNoDataMessage = () => (
    <Typography className={classes.noDataMessage}>
      {NO_CONNECTOR_LOGS_MESSAGE}
    </Typography>
  );

  return (
    <StyledPaper
      className={classes.rootContainer}
      container
      component={Grid}
      justifyContent="space-between"
    >
      {renderHeader()}
      <Grid
        data-testid="connector-health-score"
        container
        justifyContent="center"
        alignItems="center"
        className={classes.twoThirdWidth}
      >
        {!Number.isNaN(getScoreChange())
          ? renderScoreText()
          : renderNoDataMessage()}
      </Grid>
    </StyledPaper>
  );
}

ConnectorHealthScore.propTypes = {
  connector: PropTypes.object,
  data: PropTypes.array,
};

ConnectorHealthScore.defaultProps = {
  connector: {
    healthStatus: 'Unknown',
  },
  data: [],
};

export default ConnectorHealthScore;
