import PropTypes from 'prop-types';
import {useState} from 'react';
import {
  mapResourceToStatusField,
  resourceToLabelStatusTypeMap,
} from 'utils/Mappings';

import SessionEventsDialog from 'components/sessions/SessionEventsDialog';
import {SESSION_AUDIT} from 'utils/constants';
import StatusChip from '../CAM/display/StatusChip/StatusChip';

function SessionStatus({sessionStat}) {
  const {machineName, sessionEvents} = sessionStat;
  const status = sessionStat[mapResourceToStatusField(SESSION_AUDIT)];
  const [isSessionEventsDialogOpen, setIsSessionEventsDialogOpen] =
    useState(false);

  const handleCloseSessionEventsDialog = () => {
    setIsSessionEventsDialogOpen(false);
  };

  const openSessionEventsDialog = () => {
    setIsSessionEventsDialogOpen(true);
  };

  return (
    <>
      <StatusChip
        label={resourceToLabelStatusTypeMap[SESSION_AUDIT]?.[status]?.label}
        type={resourceToLabelStatusTypeMap[SESSION_AUDIT]?.[status]?.type}
        onClick={() => openSessionEventsDialog()}
      />
      <SessionEventsDialog
        isOpen={isSessionEventsDialogOpen}
        machineName={machineName}
        sessionEvents={sessionEvents}
        onClose={handleCloseSessionEventsDialog}
      />
    </>
  );
}

SessionStatus.propTypes = {
  sessionStat: PropTypes.object.isRequired,
};

export default SessionStatus;
