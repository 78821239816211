/* eslint-disable max-lines */
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DisabledIcon from '@mui/icons-material/Cancel';
import EnabledIcon from '@mui/icons-material/CheckCircle';
import BackIcon from '@mui/icons-material/KeyboardArrowLeft';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import GridN from 'components/CAM/layout/GridN/GridN';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import HorizontalTab from 'components/CAM/tabs/HorizontalTab/HorizontalTab';
import KeyValueInfo from 'components/CAM/text/KeyValueInfo/KeyValueInfo';
import SectionHeader from 'components/CAM/text/SectionHeader/SectionHeader';
import AppBar from 'components/common/AppBar';
import NewMiniTable from 'components/miniTable/NewMiniTable';
import config from 'config';
import {capitalize} from 'helpers/core';
import useAdUser from 'hooks/useAdUser';
import useEntitlements from 'hooks/useEntitlements';
import useUserSessionAttempts from 'hooks/useUserSessionAttempts';
import useUserSessions from 'hooks/useUserSessions';
import {
  fetchTelemetrySettings,
  fetchUserPoolMachines,
} from 'redux/actions/dataActions';
import {
  AD_USERS_LINK,
  AMT,
  EDIT_POOL_LINK,
  MACHINE_ENTITLEMENTS,
  POOL_ENTITLEMENTS,
  POOL_MACHINES,
  TELEMETRY_SETTINGS,
  USER_GROUPS,
  WORKSTATIONS_LINK,
} from 'utils/constants';
import {mapAmtPowerStateToHumanReadable} from 'utils/Mappings';
import {
  selectData,
  selectResourceItem,
  selectSelectedDeployment,
} from 'utils/reduxSelectors';
import {
  formatDateTime,
  getAmtWorkstationPowerState,
  isEmpty,
  linkTo,
} from 'utils/utils';
import MiniTable from '../miniTable/MiniTable';
import UserSessionCard from './UserSessionCard';

const PREFIX = 'AdUserView';

const classes = {
  createPageContainer: `${PREFIX}-createPageContainer`,
  propertyName: `${PREFIX}-propertyName`,
  propertyValue: `${PREFIX}-propertyValue`,
  nameOfUser: `${PREFIX}-nameOfUser`,
  appBar: `${PREFIX}-appBar`,
  toolbar: `${PREFIX}-toolbar`,
  icon: `${PREFIX}-icon`,
  enabledIcon: `${PREFIX}-enabledIcon`,
  disabledIcon: `${PREFIX}-disabledIcon`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.createPageContainer}`]: theme.createPage.createPageContainer,

  [`& .${classes.propertyName}`]: {
    fontSize: '14px',
  },

  [`& .${classes.propertyValue}`]: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: '14px',
  },

  [`& .${classes.nameOfUser}`]: {
    color: theme.palette.primary.main,
    fontSize: '1.125rem',
    fontWeight: 500,
    letterSpacing: '0.014375rem',
  },

  [`& .${classes.appBar}`]: {
    marginRight: '20px',
    minWidth: '750px',
  },

  [`& .${classes.toolbar}`]: {
    height: '72px',
    paddingLeft: '5px',
    paddingRight: '1.25rem',
  },

  [`& .${classes.icon}`]: {
    marginLeft: '0.75rem',
    marginRight: '0.25rem',
  },

  [`& .${classes.enabledIcon}`]: {
    color: '#4BCA81',
  },

  [`& .${classes.disabledIcon}`]: {
    color: 'red',
  }
}));

const groupHeaders = [{id: 'name', value: 'Name'}];

const machineHeaders = [
  {id: 'machineName', value: 'Name', type: 'link'},
  {id: 'powerState', value: 'Power State'},
  {id: 'assignedOn', value: 'Assigned On', type: 'date'},
];

const poolHeaders = [
  {id: 'poolName', value: 'Name', type: 'link'},
  {id: 'poolMachines', value: 'Assigned Workstation'},
];

const TAB_OVERVIEW = 0;
const TAB_SESSION_INFO = 1;

function AdUserView({match}) {

  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const {userGuid} = match.params;

  // table pagination sates
  const [machineEntitlementsPage, setMachineEntitlementsPage] = useState(0);
  const [machineEntitlementsRowsPerPage, setMachineEntitlementsRowsPerPage] =
    useState(5);
  const [poolEntitlementsPage, setPoolEntitlementsPage] = useState(0);
  const [poolEntitlementsRowsPerPage, setPoolEntitlementsRowsPerPage] =
    useState(5);

  // session attempts date range
  const [saDateRange, setSaDateRange] = useState({});

  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );

  const {
    data: adUser,
    isLoading: isFetchingAdUser,
    isSuccess: isSuccessAdUser,
  } = useAdUser(userGuid, {enabled: !isEmpty(deploymentId)});

  const {
    data: machineEntitlements,
    isLoading: isFetchingMachineEntitlements,
    isSuccess: isMachineEntitlementsSuccess,
  } = useEntitlements(
    {
      userGuid,
      resourceType: 'machine',
      limit: machineEntitlementsRowsPerPage,
      offset: machineEntitlementsPage * machineEntitlementsRowsPerPage,
    },
    {enabled: !isEmpty(deploymentId) && isSuccessAdUser}
  );

  const {
    data: poolEntitlements,
    isLoading: isFetchingPoolEntitlements,
    isSuccess: isPoolEntitlementsSuccess,
  } = useEntitlements(
    {
      userGuid,
      resourceType: 'pool',
      limit: poolEntitlementsRowsPerPage,
      offset: poolEntitlementsPage * poolEntitlementsRowsPerPage,
    },
    {enabled: !isEmpty(deploymentId) && isSuccessAdUser}
  );

  const {data: poolMachines} = useSelector((state) =>
    selectData(state, POOL_MACHINES)
  );

  const {item: telemetrySettings} = useSelector((state) =>
    selectResourceItem(state, TELEMETRY_SETTINGS, deploymentId)
  );

  const {data: sessionStats, isFetching: isFetchingSessionStats} =
    useUserSessions(userGuid, {
      enabled:
        config.isBetaOrStandalone() &&
        !isEmpty(deploymentId) &&
        telemetrySettings?.enabled &&
        isSuccessAdUser &&
        isMachineEntitlementsSuccess,
      refetchInterval: (Math.random() * 60 + 60) * 1000,
    });

  const {data: sessionAttempts, isLoading: isFetchingSessionAttempts} =
    useUserSessionAttempts(userGuid, saDateRange, {
      enabled:
        config.isBetaOrStandalone() &&
        !isEmpty(deploymentId) &&
        telemetrySettings?.enabled &&
        isSuccessAdUser &&
        isMachineEntitlementsSuccess,
      refetchInterval: (Math.random() * 60 + 60) * 1000,
    });

  useEffect(() => {
    // fetch selected user on load, then fetch their entitlements
    if (deploymentId && config.isBetaOrStandalone()) {
      dispatch(fetchTelemetrySettings());
    }
  }, [deploymentId]);

  // once pool entitlements are loaded, fetch the user's assigned pool machines. also, use
  // JSON.stringify to perform value comparison for entitlements rather than reference comparison
  useEffect(() => {
    if (
      isPoolEntitlementsSuccess &&
      poolEntitlements.data.length &&
      deploymentId
    ) {
      const pools = poolEntitlements.data.map(
        (entitlement) => entitlement.resource
      );
      dispatch(fetchUserPoolMachines(pools, userGuid));
    }
  }, [isPoolEntitlementsSuccess, deploymentId]);

  const getWorkstationLink = (machine) =>
    linkTo(`${WORKSTATIONS_LINK}/edit/${machine.machineId}`);
  const getPoolLink = (pool) => linkTo(`${EDIT_POOL_LINK}/${pool.poolId}`);

  const getAssignedPoolMachine = (pool) => {
    const assignedMachine =
      poolMachines.find((poolMachine) => poolMachine.poolId === pool.poolId) ||
      {};
    return assignedMachine;
  };

  const formatWorkstation = (machine, assignedOn) => {
    if (!machine) {
      return {};
    }

    let newMachine = machine;
    if (machine.provider === AMT) {
      newMachine = getAmtWorkstationPowerState(machine);
      newMachine.powerState = mapAmtPowerStateToHumanReadable(
        machine.powerState
      );
    }

    return {
      id: newMachine.machineId,
      link: getWorkstationLink(newMachine),
      machineName: newMachine.machineName,
      powerState: capitalize(newMachine.powerState),
      assignedOn: assignedOn || '',
    };
  };

  const prepareWorkstations = (entitlements) =>
    entitlements.map((entitlement) =>
      formatWorkstation(entitlement.resource, entitlement.createdOn)
    );

  const preparePools = (entitlements) =>
    entitlements.map((entitlement) => {
      const {resourceId: poolId, resource: pool} = entitlement;
      const assignedMachine = getAssignedPoolMachine(pool);
      return {
        poolId,
        poolName: pool?.poolName || '',
        poolMachines: assignedMachine.machineName || '',
        link: getPoolLink(entitlement.resource),
      };
    });

  const prepareGroups = (data) => {
    if (!data) {
      return [];
    }
    return data.map((group) => ({
      name: group,
      id: group,
    }));
  };

  const renderProperty = (label, value, id) => (
    <Grid item xs={12} sm={6} md={4}>
      <KeyValueInfo
        label={label}
        value={value}
        enableCopy={['deploymentId'].includes(id)}
      />
    </Grid>
  );

  const renderIcon = () => {
    const {enabled} = adUser || {};

    if (typeof enabled === 'undefined') {
      return null;
    }

    if (enabled) {
      return (
        <EnabledIcon
          className={classNames(classes.icon, classes.enabledIcon)}
        />
      );
    }

    return (
      <DisabledIcon
        className={classNames(classes.icon, classes.disabledIcon)}
      />
    );
  };

  const renderToolbar = () => (
    <AppBar loading={isFetchingAdUser}>
      <IconButton
        component={Link}
        to={AD_USERS_LINK}
        data-testid="ad-user-view-back-button"
        size="large">
        <BackIcon />
      </IconButton>
      <Typography className={classes.nameOfUser}>
        {adUser ? adUser.name : 'Loading user...'}
      </Typography>
      {renderIcon()}
      <Typography className={classes.propertyValue}>
        {adUser && (adUser.enabled ? 'Enabled' : 'Disabled')}
      </Typography>
    </AppBar>
  );

  const renderSectionHeader = (displayText) => (
    <SectionHeader displayText={displayText} />
  );

  const renderUserInformation = () => (
    <>
      {renderSectionHeader('USER INFORMATION')}
      <Grid container>
        {renderProperty(
          'User Name',
          adUser ? adUser.userName : 'Loading...',
          'userName'
        )}
        {renderProperty(
          'User GUID',
          adUser ? adUser.userGuid : 'Loading...',
          'userGuid'
        )}
        {renderProperty('Deployment ID', deploymentId, 'deploymentId')}
        {renderProperty(
          'Created On',
          adUser ? formatDateTime(adUser.createdOn) : 'Loading...',
          'createdOn'
        )}
      </Grid>
    </>
  );

  const renderGroupsTable = () => (
    <>
      {renderSectionHeader('GROUPS')}
      <Grid item xs={8}>
        <MiniTable
          resource={USER_GROUPS}
          headers={groupHeaders}
          data={prepareGroups(adUser?.groups)}
          isLoading={isFetchingAdUser}
          total={adUser?.groups?.length || 0}
        />
      </Grid>
    </>
  );

  const overviewTab = () => (
    <>
      <CAMCard>{renderUserInformation()}</CAMCard>

      <CAMCard>{renderGroupsTable()}</CAMCard>

      <CAMCard>
        {renderSectionHeader('ENTITLED WORKSTATIONS')}
        <Grid item xs={8}>
          <NewMiniTable
            resource={MACHINE_ENTITLEMENTS}
            headers={machineHeaders}
            data={prepareWorkstations(machineEntitlements?.data || [])}
            page={machineEntitlementsPage}
            setPage={setMachineEntitlementsPage}
            rowsPerPage={machineEntitlementsRowsPerPage}
            setRowsPerPage={setMachineEntitlementsRowsPerPage}
            isLoading={isFetchingMachineEntitlements}
            total={machineEntitlements?.total || 0}
            usePagination
          />
        </Grid>
      </CAMCard>

      <CAMCard>
        {renderSectionHeader('ENTITLED WORKSTATION POOLS')}
        <Grid item xs={8}>
          <NewMiniTable
            resource={POOL_ENTITLEMENTS}
            headers={poolHeaders}
            data={preparePools(poolEntitlements?.data || [])}
            page={poolEntitlementsPage}
            setPage={setPoolEntitlementsPage}
            rowsPerPage={poolEntitlementsRowsPerPage}
            setRowsPerPage={setPoolEntitlementsRowsPerPage}
            isLoading={isFetchingPoolEntitlements}
            total={poolEntitlements?.total || 0}
            usePagination
          />
        </Grid>
      </CAMCard>
    </>
  );

  const sessionInfoTab = () => (
    <>
      {config.isBetaOrStandalone() && (
        <GridN singleColumn>
          <UserSessionCard
            user={adUser}
            loadingSessions={isFetchingSessionStats}
            loadingWorkstations={isFetchingMachineEntitlements}
            sessionStats={sessionStats || []}
            workstations={
              machineEntitlements?.data.map(({resource}) => resource) || []
            }
            sessionAttempts={sessionAttempts || []}
            loadingSessionAttempts={isFetchingSessionAttempts}
            setDateRange={setSaDateRange}
          />
        </GridN>
      )}
    </>
  );

  const adUserTabs = {
    [TAB_OVERVIEW]: overviewTab(),
    [TAB_SESSION_INFO]: sessionInfoTab(),
  };

  const renderTabs = () => (
    <>
      <HorizontalTab
        tabs={['Overview', 'Session Information']}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {adUserTabs[selectedTab]}
    </>
  );

  return (
    <Root className={classes.createPageContainer}>
      {renderToolbar()}

      {config.isBetaOrStandalone() &&
      telemetrySettings &&
      telemetrySettings.enabled
        ? renderTabs()
        : overviewTab()}
    </Root>
  );
}

AdUserView.propTypes = {
  match: PropTypes.object.isRequired,
};

export default AdUserView;
