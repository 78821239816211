import {Grid} from '@mui/material';
import {styled} from '@mui/material/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchResource} from 'redux/actions/dataActions';
import {
  ACTIVITY_LOGS,
  SIX_HOURS_IN_MS,
  UPDATE_CONNECTOR_HEALTH_STATUS,
} from 'utils/constants';
import {getEarliestTimeInInterval, isEmpty} from 'utils/utils';
import ConnectorCertificates from './ConnectorCertificates';
import ConnectorHealthChart from './ConnectorHealthChart';
import ConnectorHealthScore from './ConnectorHealthScore';
import ConnectorStatusSummary from './ConnectorStatusSummary';

const PREFIX = 'ConnectorOverview';

const classes = {
  rootContainer: `${PREFIX}-rootContainer`,
  statusContainer: `${PREFIX}-statusContainer`,
  chartContainer: `${PREFIX}-chartContainer`,
  stackedContainer: `${PREFIX}-stackedContainer`,
  healthScore: `${PREFIX}-healthScore`,
  certificates: `${PREFIX}-certificates`,
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.rootContainer}`]: {
    height: '35vh',
    minHeight: '400px',
    padding: '1rem',
  },

  [`& .${classes.statusContainer}`]: {
    height: '100%',
    maxWidth: '350px',
    paddingRight: '1.5rem',
  },

  [`& .${classes.chartContainer}`]: {
    flexGrow: '1',
    height: '100%',
  },

  [`& .${classes.stackedContainer}`]: {
    height: '100%',
    marginLeft: '3rem',
    maxWidth: '300px',
  },

  [`& .${classes.healthScore}`]: {
    height: '45%',
    paddingBottom: '1rem',
  },

  [`& .${classes.certificates}`]: {
    height: '55%',
  },
}));

// To be updated from 6 hours to a variable timespan
const NUM_BARS_IN_CHART = 12;
const CURRENT_TIME_STEP = SIX_HOURS_IN_MS / NUM_BARS_IN_CHART;

function ConnectorOverview({connector}) {
  const dispatch = useDispatch();
  const [timeStep, timeInterval] = [CURRENT_TIME_STEP, SIX_HOURS_IN_MS];
  const earliestTimeInInterval = getEarliestTimeInInterval(
    new Date(),
    timeStep,
    timeInterval
  );

  const {data: activityLogs} = useSelector(
    (state) => state.data.dataByResource.activityLogs || {data: {}}
  );
  const validLogs = Object.keys(activityLogs || {})
    .filter(
      (key) =>
        activityLogs[key].operation === UPDATE_CONNECTOR_HEALTH_STATUS &&
        activityLogs[key].data.connectorId === connector.connectorId &&
        new Date(activityLogs[key].createdOn) >=
          new Date(earliestTimeInInterval)
    )
    .map((key) => activityLogs[key])
    .sort((a, b) => a.createdOn > b.createdOn);

  const mostRecentLog = validLogs[validLogs.length - 1] || {data: {}};
  const currentConnector =
    !mostRecentLog || isEmpty(mostRecentLog.data)
      ? connector
      : mostRecentLog.data;

  useEffect(() => {
    dispatch(
      fetchResource(ACTIVITY_LOGS, {
        page: 0,
        rowsPerPage: 200,
        operation: UPDATE_CONNECTOR_HEALTH_STATUS,
        connectorId: connector.connectorId,
        sortKey: 'createdOn',
        from: moment().subtract(6, 'hours').toDate().toISOString(),
      })
    );
  }, []);

  const renderStatusBreakdown = () => (
    <Grid item className={classes.statusContainer}>
      <ConnectorStatusSummary connector={connector} data={currentConnector} />
    </Grid>
  );

  const renderStatusChart = () => (
    <Grid item className={classes.chartContainer}>
      <ConnectorHealthChart
        data={validLogs}
        step={timeStep}
        interval={timeInterval}
      />
    </Grid>
  );

  const renderHealthScore = () => (
    <Grid item className={classes.healthScore}>
      <ConnectorHealthScore
        connector={connector}
        data={validLogs.length > 0 ? validLogs : [{data: currentConnector}]}
      />
    </Grid>
  );

  const renderCertificates = () => (
    <Grid item className={classes.certificates}>
      <ConnectorCertificates data={currentConnector} />
    </Grid>
  );

  return (
    <StyledGrid
      className={classes.rootContainer}
      container
      alignItems="center"
      direction="row"
      justifyContent="center"
      wrap="nowrap"
    >
      {renderStatusBreakdown()}
      {renderStatusChart()}
      <Grid
        className={classes.stackedContainer}
        container
        direction="column"
        justifyContent="space-between"
      >
        {renderHealthScore()}
        {renderCertificates()}
      </Grid>
    </StyledGrid>
  );
}

ConnectorOverview.propTypes = {
  connector: PropTypes.object,
};

ConnectorOverview.defaultProps = {
  connector: {},
};

export default ConnectorOverview;
