import {useState} from 'react';
import {post} from 'api/Api';
import CasmCookies from 'utils/cookies';
import {setLastLoginDate} from 'utils/utils';
import {push} from 'redux/actions/HistoryActions';
import {USER_PREFERENCES_LINK} from 'utils/constants';

export function useStandaloneLogin({dispatch}) {
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [data, setData] = useState({
    username: '',
    password: '',
  });

  const handleLoginChange = (prop) => (event) => {
    setErrorMessage('');
    setData({...data, [prop]: event.target.value});
  };

  const handleStandaloneLogin = async (event) => {
    event.preventDefault();
    try {
      setSaving(true);
      const response = await post({
        path: 'auth/ad/login',
        data,
        maxRetries: 0,
      });
      setSaving(false);

      const {token} = response.data;
      CasmCookies.setAuthToken(token);
      setLastLoginDate();

      const decodedToken = CasmCookies.getDecodedAuthToken(token);

      if (decodedToken.tokenType === 'standalone_admin') {
        dispatch(push(`${USER_PREFERENCES_LINK}/adConfig`));
      } else if (decodedToken.tokenType === 'default_standalone_admin') {
        setShowUpdatePassword(true);
      } else {
        dispatch(push('/app'));
      }
    } catch (err) {
      setSaving(false);
      if (err.code === 401 || err.code === 412 || err.code === 404) {
        setErrorMessage(err.data.reason);
      } else {
        setErrorMessage('An error occurred while logging in');
      }
    }
  };

  return {
    handleStandaloneLogin,
    handleLoginChange,
    loginData: data,
    loginLoading: saving,
    loginErrorMessage: errorMessage,
    showUpdatePassword,
  };
}

export function useStandaloneUpdatePassword({dispatch}) {
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState({
    password: '',
    confirmPassword: '',
  });

  const passwordMeetsMinLength = data.password.length >= 8;
  const passwordsMatch =
    data.password.length > 0 && data.password === data.confirmPassword;
  const updateDisabled = saving || !passwordMeetsMinLength || !passwordsMatch;

  const handlePasswordChange = (prop) => (event) => {
    setErrorMessage('');
    setData({...data, [prop]: event.target.value});
  };

  const handleUpdatePassword = async (event) => {
    event.preventDefault();
    if (updateDisabled) {
      return;
    }

    const {password} = data;

    try {
      setSaving(true);
      const response = await post({
        path: 'auth/ad/adminPassword',
        data: {password},
        maxRetries: 0,
      });
      setSaving(false);

      const {token} = response.data;
      CasmCookies.setAuthToken(token);
      setLastLoginDate();

      dispatch(push(`${USER_PREFERENCES_LINK}/adConfig`));
    } catch (err) {
      setSaving(false);
      if (err && err.code === 412) {
        setErrorMessage(err.data.reason);
      } else {
        setErrorMessage('An error occurred while updating the password');
      }
    }
  };

  return {
    handlePasswordChange,
    handleUpdatePassword,
    passwordErrorMessage: errorMessage,
    passwordMeetsMinLength,
    passwordsMatch,
    passwordUpdateData: data,
    savingPassword: saving,
  };
}
