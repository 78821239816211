import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {CookiesProvider} from 'react-cookie';
import {createRoot} from 'react-dom/client';
import {QueryClient, QueryClientProvider} from 'react-query';
import {Provider} from 'react-redux';
import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';
import NotificationManager from 'components/common/NotificationManager';
import store, {history} from 'redux/store/store';
import {theme} from 'themes/default';
import 'typeface-roboto';
import '@fontsource/ibm-plex-mono';
import config from './config';
import App from './App';
import './index.css';
import {unregister} from './registerServiceWorker';

const container = document.getElementById('root');
const root = createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 2, // consider the result of an API call to be stale in 2 minutes
    },
  },
});

const cache = createCache({
  key: 'key-emotion-css',
  nonce:
    document.querySelector('meta[property="csp-nonce"]')?.content ||
    '__SERVER__NONCE__',
  prepend: true,
});

if (window === window.parent) {
  root.render(
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <Provider store={store}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
                <CssBaseline />
                <CacheProvider value={cache}>
                  <NotificationManager>
                    <App history={history} />
                  </NotificationManager>
                </CacheProvider>
              </GoogleOAuthProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </Provider>
      </CookiesProvider>
      {/* {process.env.NODE_ENV === 'development' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )} */}
    </QueryClientProvider>
  );
}

unregister();
