import {styled} from '@mui/material/styles';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import CAMCard from 'components/CAM/surfaces/CAMCard/CAMCard';
import CAMButton from 'components/CAM/buttons/CAMButton/CAMButton';
import HorizontalTab from 'components/CAM/tabs/HorizontalTab/HorizontalTab';
import {fetchResource} from 'redux/actions/dataActions';
import {push} from 'redux/actions/HistoryActions';
import {
  AD_CONFIG,
  MULTI_ADMIN_SETTINGS_ACTIVE_DIRECTORY,
  MULTI_ADMIN_SETTINGS_SAML,
  SAML_CONFIGURATION,
  USER_PREFERENCES_LINK,
  MULTI_ADMIN_SETTINGS_TITLE,
  MULTI_ADMIN_LINK,
  ADD_SAML_CONFIG_LINK,
} from 'utils/constants';
import {selectSelectedDeployment} from 'utils/reduxSelectors';
import {
  isEmpty,
  userCanAccessAdConfiguration,
  userCanAccessSamlConfiguration,
} from 'utils/utils';
import config from 'config';
import useDialog from 'hooks/useDialog';
import SamlConfigTab from './SamlConfigTab';

const PREFIX = 'MultiAdminTab';

const classes = {
  pageTitleContainer: `${PREFIX}-pageTitleContainer`,
  pageTitle: `${PREFIX}-pageTitle`,
  addConfigButton: `${PREFIX}-addConfigButton`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.pageTitleContainer}`]:
    theme.createPage.createPageTitleContainer,
  [`& .${classes.pageTitle}`]: theme.createPage.createPageTitle,

  [`& .${classes.addConfigButton}`]: {
    width: 'auto',
    marginLeft: 'auto',
  },
}));

const MAX_NUM_CONFIGURATIONS = 5;

function compareSamlConfig(a, b) {
  if (b.configurationId > a.configurationId) {
    return -1;
  }
  return 1;
}

function getSamlConfigId() {
  const path = window.location.pathname;
  const segments = path.split(MULTI_ADMIN_LINK);
  if (segments.length !== 2) {
    return null;
  }
  // strip out forward slash
  const segment = segments[1].replace(/\//g, '');
  const objectIdLength = 24;
  if (segment.length < objectIdLength) {
    return null;
  }
  // using the first 24 character as the configId, so it will work against MULTI_ADMIN_LINK/objectId/subtree as well
  return segment.substring(0, objectIdLength);
}

const createConfigTitle = 'Create SAML Configuration';

function MultiAdminTab() {
  const {triggerDialog} = useDialog();

  const dispatch = useDispatch();
  const {deploymentId} = useSelector((state) =>
    selectSelectedDeployment(state)
  );
  const [navigateToConfigId, setNavigateToConfigId] = useState(
    getSamlConfigId()
  );

  const {samlConfigurations, isFetched} = useSelector((state) => {
    const {data, isFetching} = state.data.dataByResource[
      SAML_CONFIGURATION
    ] || {data: {}};
    let samlConfigs = [];
    let _isFetched = false;
    if (isFetching === false) {
      _isFetched = true;
    }
    if (!isEmpty(data)) {
      samlConfigs = Object.values(data).sort(compareSamlConfig);
    }
    return {samlConfigurations: samlConfigs, isFetched: _isFetched};
  });

  const [selectedSamlConfigId, setSelectedSamlConfigId] = useState(null);
  const [creatingConfig, setCreatingConfig] = useState(false);

  useEffect(() => {
    if (creatingConfig === true && isFetched === true) {
      setCreatingConfig(false);
      if (samlConfigurations.length >= MAX_NUM_CONFIGURATIONS) {
        triggerDialog({
          title: 'Configuration Limit Reached',
          cancelEnabled: false,
          message: `You can create up to ${MAX_NUM_CONFIGURATIONS} configurations. Please delete or modify existing configurations as needed before creating new ones`,
        });
        return;
      }
      dispatch(push(ADD_SAML_CONFIG_LINK));
    }
  }, [isFetched, creatingConfig]);

  const fetchConfiguration = () => {
    dispatch(fetchResource(SAML_CONFIGURATION));
  };

  useEffect(() => {
    setNavigateToConfigId(getSamlConfigId());
  }, [getSamlConfigId()]);

  useEffect(() => {
    if (deploymentId) {
      fetchConfiguration();
    }
  }, [deploymentId]);

  useEffect(() => {
    if (samlConfigurations.length === 0) {
      setSelectedSamlConfigId(null);
      return;
    }

    const configAvailable =
      navigateToConfigId &&
      samlConfigurations.find(
        (samlConfig) => samlConfig.configurationId === navigateToConfigId
      );
    if (configAvailable) {
      setSelectedSamlConfigId(navigateToConfigId);
      setNavigateToConfigId(null);
      return;
    }

    const selectedConfigIsRemoved =
      selectedSamlConfigId &&
      !samlConfigurations.find(
        (samlConfig) => samlConfig.configurationId === selectedSamlConfigId
      );
    if (!selectedSamlConfigId || selectedConfigIsRemoved) {
      setSelectedSamlConfigId(samlConfigurations[0].configurationId);
    }
  }, [JSON.stringify(samlConfigurations), navigateToConfigId]);

  // key to be declare explicitly since these configurations can have the same name, which can cause rendering issue in some cases.
  const tabList = () => {
    const _tabList = samlConfigurations.map((samlConfig) => {
      if (config.multiSamlSupported()) {
        let name = samlConfig?.name || MULTI_ADMIN_SETTINGS_SAML;
        const nameLimit = 30;
        if (name.length > nameLimit) {
          name = `${name.slice(0, nameLimit)}...`;
        }
        return {label: name, key: samlConfig.configurationId};
      }
      return {
        label: MULTI_ADMIN_SETTINGS_SAML,
        key: samlConfig.configurationId,
      };
    });
    // if no saml configuration exist, it should see display the default saml configuration tab(ask user to add a saml configuration)
    if (_tabList.length === 0) {
      _tabList.push({label: MULTI_ADMIN_SETTINGS_SAML, key: 'null-config'});
    }
    if (userCanAccessAdConfiguration()) {
      _tabList.push({
        label: MULTI_ADMIN_SETTINGS_ACTIVE_DIRECTORY,
        key: MULTI_ADMIN_SETTINGS_ACTIVE_DIRECTORY,
      });
    }
    return _tabList;
  };

  const changeTab = (tabIndex) => {
    const tabs = tabList();

    if (tabs[tabIndex]?.key === MULTI_ADMIN_SETTINGS_ACTIVE_DIRECTORY) {
      dispatch(push(`${USER_PREFERENCES_LINK}/${AD_CONFIG}`));
      return;
    }

    const configId = samlConfigurations[tabIndex]?.configurationId;
    setSelectedSamlConfigId(configId);
  };

  if (!userCanAccessSamlConfiguration()) {
    return null;
  }

  let selectedTab = 0;
  if (selectedSamlConfigId) {
    const tabIndex = samlConfigurations.findIndex(
      (samlConfig) => samlConfig.configurationId === selectedSamlConfigId
    );
    if (tabIndex !== -1) {
      selectedTab = tabIndex;
    }
  }

  return (
    <Root>
      <CAMCard>
        <div className={classes.pageTitleContainer}>
          <div className={classes.pageTitle}>{MULTI_ADMIN_SETTINGS_TITLE}</div>
          {config.multiSamlSupported() && (
            <CAMButton
              testId="button-add-configuration"
              variant="contained"
              buttonText={createConfigTitle}
              customClass={classes.addConfigButton}
              onClick={() => {
                setCreatingConfig(true);
              }}
              startIcon={<AddIcon />}
            />
          )}
        </div>

        <HorizontalTab
          tabs={tabList()}
          selectedTab={selectedTab}
          setSelectedTab={changeTab}
        />
        <SamlConfigTab
          configurationId={selectedSamlConfigId}
          key={selectedSamlConfigId}
        />
      </CAMCard>
    </Root>
  );
}

export default MultiAdminTab;
