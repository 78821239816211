import Accordion from '@mui/material/Accordion';
import {styled} from '@mui/material/styles';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import CollapseSvg from 'icons/collapse_all.svg';
import config from 'config';
import {AWM_RELEASE_NOTES_FEED_URL, RELEASE_NOTES_GA} from 'utils/constants';
import {isEmpty, stripTimeValues} from 'utils/utils';
import {
  loadItemsFromFeed,
  releaseNotesOutputDateFormat,
} from './releaseNotesParser';

const PREFIX = 'ReleaseNotes';

const classes = {
  card: `${PREFIX}-card`,
  collapseIconContainer: `${PREFIX}-collapseIconContainer`,
  collapseIcon: `${PREFIX}-collapseIcon`,
  dateGridRow: `${PREFIX}-dateGridRow`,
  dateLine: `${PREFIX}-dateLine`,
  dateSquare: `${PREFIX}-dateSquare`,
  dateText: `${PREFIX}-dateText`,
  divider: `${PREFIX}-divider`,
  dividerContainer: `${PREFIX}-dividerContainer`,
  expansionDetails: `${PREFIX}-expansionDetails`,
  expansionSummary: `${PREFIX}-expansionSummary`,
  gridContainer: `${PREFIX}-gridContainer`,
  noteBody: `${PREFIX}-noteBody`,
  noteTitle: `${PREFIX}-noteTitle`,
  releaseNotesContainer: `${PREFIX}-releaseNotesContainer`,
  settingsGrid: `${PREFIX}-settingsGrid`,
  titleSpacer: `${PREFIX}-titleSpacer`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.card}`]: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    lineHeight: '19px',
    margin: '6.5rem 0px 6.5rem 24px',
    padding: '20px',
    width: '1000px',
    [theme.breakpoints.down('lg')]: {
      width: '800px',
    },
    [theme.breakpoints.down('md')]: {
      width: '450px',
    },
  },

  [`& .${classes.collapseIconContainer}`]: {
    marginRight: '40px',
  },

  [`& .${classes.collapseIcon}`]: {
    height: '18px',
    width: '18px',
  },

  [`& .${classes.dateGridRow}`]: {overflow: 'hidden'},

  [`& .${classes.dateLine}`]: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    margin: '0px auto',
    paddingTop: '12px',
    opacity: 0.7,
    width: '1px',
  },

  [`& .${classes.dateSquare}`]: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px',
    height: '24px',
    margin: 'auto',
    paddingBottom: '12px',
    width: '24px',
    [theme.breakpoints.down('md')]: {
      margin: '0px 20px 0px 20px',
    },
  },

  [`& .${classes.dateText}`]: {
    color: '#1570D2',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    lineHeight: '20px',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '10px',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '30px',
    },
  },

  [`& .${classes.divider}`]: {
    backgroundColor: 'inherit',
    borderTop: '2px dashed #D8D8D8',
  },

  [`& .${classes.dividerContainer}`]: {
    alignItems: 'center',
    margin: '12px 0px 20px 0px',
  },

  [`& .${classes.expansionDetails}`]: {padding: '0px'},

  [`& .${classes.expansionSummary}`]: {
    height: '36px',
    minHeight: '36px',
    padding: '0px 51px 0px 0px',
  },

  [`& .${classes.gridContainer}`]: {
    backgroundColor: 'transparent',
    paddingBottom: '12px',
  },

  [`& .${classes.noteBody}`]: {
    color: '#706E6B',
    margin: '2.5px 0px 10px',
  },

  [`& .${classes.noteTitle}`]: {
    color: '#2B2826',
  },

  [`&.${classes.releaseNotesContainer}`]: {
    backgroundColor: 'white',
  },

  [`& .${classes.settingsGrid}`]: {
    padding: '32px 12px 0px',
    marginLeft: '10px',
  },

  [`& .${classes.titleSpacer}`]: {
    marginTop: '14.5px',
  },
}));

const selectMenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  style: {maxHeight: '50vh'},
};

function ReleaseNotes({releaseNotesFeedUrl, releaseType}) {
  const [releaseDates, setReleaseDates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadFailed, setLoadFailed] = useState(false);
  const [releaseDateExpanded, setReleaseDateExpanded] = useState([]);
  const [filterBy, setFilterBy] = useState('timeline');
  const [currentDate] = useState(stripTimeValues(moment()));

  useEffect(() => {
    const loadFeed = async () => {
      setIsLoading(true);
      const releaseNotesArray = await loadItemsFromFeed(
        releaseNotesFeedUrl,
        releaseType
      );
      setReleaseDates(releaseNotesArray);
      setIsLoading(false);
      setLoadFailed(false);
    };

    if (releaseNotesFeedUrl && !config.STANDALONE) {
      try {
        loadFeed();
      } catch (error) {
        setLoadFailed(true);
      }
    }
  }, [releaseNotesFeedUrl, releaseType]);

  useEffect(() => {
    if (!isEmpty(releaseDates)) {
      setReleaseDateExpanded(releaseDates.map((_, index) => index === 0));
      window.sessionStorage.setItem('releaseNotesViewed', 'true');
    }
  }, [releaseDates]);

  const toggleExpanded = (index) => {
    const newArray = [...releaseDateExpanded];
    newArray[index] = !newArray[index];
    setReleaseDateExpanded(newArray);
  };

  const collapseAll = () => {
    const newArray = releaseDateExpanded.map(() => false);
    setReleaseDateExpanded(newArray);
  };

  if (isLoading) {
    return (
      <Card className={classes.card}>
        Loading release notes... Please wait.
      </Card>
    );
  }

  if (loadFailed) {
    return (
      <Card className={classes.card}>
        Failed to load release notes. Please refresh the page to try again.
      </Card>
    );
  }
  const renderReleaseNote = (releaseDate, dateIndex) => (
    <Grid item className={classes.dateGridRow} key={releaseDate.date}>
      <Accordion
        elevation={0}
        expanded={releaseDateExpanded[dateIndex] ?? false}
        onChange={() => toggleExpanded(dateIndex)}
      >
        <AccordionSummary
          className={classes.expansionSummary}
          expandIcon={<ExpandMoreIcon />}
          id={`expansion-summary-${dateIndex}`}
        >
          <Grid container>
            <Grid item xs={1}>
              <div className={classes.dateSquare} />
            </Grid>
            <Grid item xs={8}>
              <div className={classes.dateText}>{releaseDate.date}</div>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionDetails}>
          <Grid container>
            <Grid item xs={1}>
              <div className={classes.dateLine} />
            </Grid>
            <Grid item xs={8}>
              {releaseDate.notes.map((releaseNote) => releaseNote)}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );

  return (
    <Root className={classes.releaseNotesContainer}>
      <Grid
        container
        justifyContent="space-between"
        className={classes.settingsGrid}
      >
        <Grid item>
          <Select
            value={filterBy}
            onChange={(event) => setFilterBy(event.target.value)}
            MenuProps={selectMenuProps}
          >
            <MenuItem value="timeline">Release timeline</MenuItem>
            <MenuItem value="month">Past month</MenuItem>
          </Select>
        </Grid>
        <Grid item className={classes.collapseIconContainer}>
          <IconButton onClick={collapseAll} id="collapse-all" size="large">
            <img className={classes.collapseIcon} src={CollapseSvg} alt="" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container className={classes.dividerContainer}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid container direction="column" className={classes.gridContainer}>
        {releaseDates
          .filter((releaseDate) => {
            if (filterBy === 'month') {
              const releasedMoment = stripTimeValues(
                moment(releaseDate.date, releaseNotesOutputDateFormat)
              );
              return (
                releasedMoment.valueOf() >=
                currentDate.clone().subtract(1, 'month').valueOf()
              );
            }
            return !isEmpty(releaseDate);
          })
          .map(renderReleaseNote)}
      </Grid>
    </Root>
  );
}

ReleaseNotes.propTypes = {
  releaseNotesFeedUrl: PropTypes.string,
  releaseType: PropTypes.string,
};

ReleaseNotes.defaultProps = {
  releaseNotesFeedUrl: AWM_RELEASE_NOTES_FEED_URL,
  releaseType: RELEASE_NOTES_GA,
};

export default ReleaseNotes;
