import {ERROR_STATUS, WARNING_STATUS} from 'utils/constants';

export const OPEN_ERROR_DIALOG = 'OPEN_ERROR_DIALOG';

export function openErrorDialog(title, message) {
  return {
    type: OPEN_ERROR_DIALOG,
    title,
    message,
    status: ERROR_STATUS,
  };
}

export const CLOSE_ERROR_DIALOG = 'CLOSE_ERROR_DIALOG';

export function closeErrorDialog() {
  return {
    type: CLOSE_ERROR_DIALOG,
  };
}

export const OPEN_WARNING_DIALOG = 'OPEN_WARNING_DIALOG';

export function openWarningDialog(title, message) {
  return {
    type: OPEN_WARNING_DIALOG,
    title,
    message,
    status: WARNING_STATUS,
  };
}

export const CLOSE_WARNING_DIALOG = 'CLOSE_WARNING_DIALOG';
