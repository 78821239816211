import {CONNECTORS, REMOTE_WORKSTATIONS} from 'utils/constants';
import {saveVariable} from './dataActions';
import {uncheckAll} from './tableSelectActions';

export function setDeploymentFilter(deployment) {
  return (dispatch) => {
    // TODO:
    //  We should split out selectedDeployment into its own reducer
    //  with its own actions.
    //  That way we can have other reducers listen for "set selected deployment"
    //  actions so they can respond accordingly.
    //  For example, when we dispatch SET_DEPLOYMENT_FILTER, we can also
    //  reselect the state in the tableSelect reducer.
    //  The following is just a quick fix because we are at the end of the sprint. :)

    dispatch(saveVariable('selectedDeployment', deployment));
    dispatch(uncheckAll(REMOTE_WORKSTATIONS));
    dispatch(uncheckAll(CONNECTORS));
  };
}

export const TOGGLE_DEPLOYMENT_FILTER = 'TOGGLE_DEPLOYMENT_FILTER';

export function toggleDeploymentFilter() {
  return {
    type: TOGGLE_DEPLOYMENT_FILTER,
  };
}
