import {selectSelectedTableResource} from 'utils/reduxSelectors';

// triggered on load - save IDs of fetched data for dialog table
export const LOAD_BULK_ACTIONS_DATA = 'LOAD_BULK_ACTIONS_DATA';

export function loadBulkActionsData({data, resource, total}) {
  return {
    type: LOAD_BULK_ACTIONS_DATA,
    data,
    resource,
    total,
  };
}

// triggered when clicking off the dialog or hitting cancel
export const CLOSE_BULK_ACTIONS_DIALOG = 'CLOSE_BULK_ACTIONS_DIALOG';

export function closeBulkActionsDialog(resource) {
  return {
    type: CLOSE_BULK_ACTIONS_DIALOG,
    resource,
  };
}

// triggered when hitting save - performs the provided bulk action
// on any selected data and handles closing the dialog
export function saveBulkActionsDialog(resource) {
  return async (dispatch, getState) => {
    const state = getState();
    const {bulkAction} = state.bulkActionsDialog[resource];
    const actionItems = selectSelectedTableResource(state, resource);
    await dispatch(bulkAction(actionItems));
    dispatch(closeBulkActionsDialog());
  };
}

// initializes the dialog by setting up the resource's data and any
// information needed to render the dialog
export const OPEN_BULK_ACTIONS_DIALOG = 'OPEN_BULK_ACTIONS_DIALOG';

export function openBulkActionsDialog({
  bulkAction,
  defaultParams,
  existingItems,
  infoText,
  resource,
  searchPlaceholder,
  tableFields,
  title,
}) {
  return {
    type: OPEN_BULK_ACTIONS_DIALOG,
    bulkAction,
    defaultParams,
    resource,
    dialogProps: {
      existingItems,
      infoText,
      searchPlaceholder,
      tableFields,
      title,
    },
    tableParams: defaultParams,
  };
}

export const UPDATE_TABLE_BULK_ACTIONS_DIALOG =
  'UPDATE_TABLE_BULK_ACTIONS_DIALOG';

export function updateTableBulkActionsDialog({resource, tableParams}) {
  return {
    type: UPDATE_TABLE_BULK_ACTIONS_DIALOG,
    resource,
    tableParams,
  };
}
