import PropTypes from 'prop-types';

const enabledFillColor = '#757575';
const disabledFillColor = '#75757596';

// #
const StoppedIcon = (props) => {
  const {fill, disabledFill, disabled} = props;

  return (
    <svg
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
          id="a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g mask="url(#b)" fill={disabled ? disabledFill : fill}>
          <path d="M-2-2h24v24H-2z" />
        </g>
        <rect
          fill={disabled ? disabledFill : fill}
          x="6"
          y="6"
          width="8"
          height="8"
          rx="1"
        />
      </g>
    </svg>
  );
};

StoppedIcon.propTypes = {
  fill: PropTypes.string,
  disabledFill: PropTypes.string,
  disabled: PropTypes.bool,
};

StoppedIcon.defaultProps = {
  fill: enabledFillColor,
  disabledFill: disabledFillColor,
  disabled: false,
};
export default StoppedIcon;
